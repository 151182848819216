import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';
import * as R from 'ramda';

import { useRouter, useHttp, useAccount } from 'hooks';
import { parseDataForVerify } from 'utils/parseData';
import * as userActions from 'actions/user';

import Loader from 'components/UI/Loader';
import Popup from 'components/Popup/Popup';

const ConfirmEmail = () => {
  const dispatch = useDispatch();
  const [redirect, setRedirect] = useState(false);
  const [popupText, setPopupText] = useState(null);
  const { location } = useRouter();

  const { request, loading } = useHttp();
  const { user } = useAccount();

  const getAccount = useCallback(
    () => request(`/Account/${user.accountId}`).then(R.compose(dispatch, userActions.getAccount)),
    [request, dispatch, user.accountId],
  );

  useEffect(() => {
    if (!location.search) {
      setRedirect(true);
    }

    const data = parseDataForVerify(location.search);

    request('/Account/ConfirmEmail', 'POST', data)
      .then(getAccount)
      .then(() => {
        setPopupText('Email confirmed successfully');
      })
      .catch(() => {
        setPopupText('Email confirmation failed. Try to send a new letter');
      });
  }, [dispatch, location.search, request, getAccount]);

  if (loading) {
    return <Loader />;
  }

  const handleClosePopup = () => {
    setPopupText(null);
    setRedirect(true);
  };

  if (popupText) {
    return (
      <Popup
        title="Email confirmation"
        text={popupText}
        open={!!popupText}
        onCloseText="OK"
        onClose={handleClosePopup}
      />
    );
  }

  return redirect ? <Redirect to="/dashboard" /> : null;
};

export default ConfirmEmail;
