import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useStripe } from '@stripe/react-stripe-js';

import { useHttp } from 'hooks';
import { LabelText } from 'components/UI/Text/TextStyles';
import CommonErrorMessage from 'components/FormUI/CommonErrorMessage';
import Modal from 'components/UI/Modal';
import { createProceedPaymentFrom } from 'services/dataContracts/factories';
import useContribution from '../../hooks/useContribution';

const ProceedModal = React.memo(({ isOpen, onClose, onSubmit }) => {
  const contribution = useContribution();

  const stripe = useStripe();
  const { request, loading } = useHttp();

  const [payment, setPayment] = useState(false);
  const [proceedPayment, setProceedPayment] = useState(null);
  const [error, setError] = useState(null);

  const canCancelPurchase = proceedPayment && proceedPayment.isCancellationAllowed;

  const handlePayResponse = useCallback(
    response => {
      if (response.error) {
        setError(response.error.message);

        setTimeout(() => {
          onSubmit();
        }, 3000);
      } else {
        setPayment(false);
        onSubmit();
      }
    },
    [onSubmit],
  );

  // Submitting form when user clicks on proceed button
  const proceedHandler = useCallback(() => {
    setPayment(true);

    stripe.confirmCardPayment(proceedPayment.clientSecret).then(handlePayResponse).catch(console.dir);
  }, [stripe, proceedPayment, handlePayResponse]);

  const cancelHandler = useCallback(() => {
    request('/api/purchase/cancel', 'POST', { contributionId: contribution.id })
      .then(res => {
        if (res.message) {
          setError(res.message);
        } else {
          onSubmit();
        }
      })
      .catch(console.dir);
  }, [request, onSubmit, contribution.id]);

  const buttons = [];
  if (canCancelPurchase) {
    buttons.push({
      buttonTitle: 'Cancel',
      onClick: cancelHandler,
      autoWidth: true,
      disabled: loading || payment,
    });
  }

  useEffect(() => {
    request('/api/purchase/proceed', 'POST', {
      contributionId: contribution.id,
    })
      .then(createProceedPaymentFrom)
      .then(setProceedPayment)
      .catch(console.dir);
  }, [request, contribution.id]);

  return (
    <>
      <Modal
        title="Proceed Purchasing"
        isOpen={isOpen}
        onCancel={onClose}
        onSubmit={proceedHandler}
        submitTitle="Proceed"
        cancelTitle="Close window"
        loading={loading || payment}
        buttons={buttons}
      >
        <LabelText>Proceed</LabelText>
        <p>To do this you need press proceed button, and and confirm your card.</p>
        {canCancelPurchase && (
          <>
            <LabelText>Cancel</LabelText>
            <p>To do this you need press cancel button.</p>
          </>
        )}
        {error && <CommonErrorMessage message={error} />}
      </Modal>
    </>
  );
});

ProceedModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default ProceedModal;
