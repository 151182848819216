import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useTheme, useMediaQuery } from '@material-ui/core';
import { Form } from 'formik';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import Popup from 'components/Popup/Popup';

import { LabelText, PageSubtitle } from 'components/UI/Text/TextStyles';
import { FormikScrollToError } from 'components/FormikScrollToError';
import { useAccount } from 'hooks';
import { formatMoney } from 'utils/datesAndMoney';
import styled from 'styled-components';
import AccountForm from './AccountForm/AccountForm';
import { PAYMENT_OPTIONS } from '../../../../constants';
import { colors } from '../../../../utils/styles';
import ApplyCoupon from '../PurchaseModal/ApplyCoupon';
import { PURCHASE_MODAL_STEPS } from '../PurchaseModal/PurchaseModal.constants';
import useContribution from 'pages/ContributionView/hooks/useContribution';
import { determineColorToUse } from 'services/contributions.service';

const StyledLink = styled.a`
  color: ${props => (props.color ? props.color : colors.darkOceanBlue)};
  font-weight: 600;
`;

const PAYMENT_MAPPING = {
  [PAYMENT_OPTIONS.PER_YEAR]: 'year',
  [PAYMENT_OPTIONS.PER_MONTH]: 'month',
};

const PAYMENT_COST_MAPPING = {
  [PAYMENT_OPTIONS.PER_YEAR]: paymentInfo => paymentInfo?.membershipInfo?.costs?.YearlyMembership,
  [PAYMENT_OPTIONS.PER_MONTH]: paymentInfo => paymentInfo?.membershipInfo?.costs?.MonthlyMembership,
  [PAYMENT_OPTIONS.PER_WEEK]: paymentInfo => paymentInfo?.membershipInfo?.costs?.WeeklyMembership,
  [PAYMENT_OPTIONS.PER_DAY]: paymentInfo => paymentInfo?.membershipInfo?.costs?.DailyMembership,
};

export const PurchaseModalForm = ({
  typeOfPayment,
  setTypeOfPayment,
  summary,
  handleChange,
  handleRedeem,
  setShowTerms,
  errors,
  touched,
  step,
  onCheckoutSessionExpired,
  currentSubscription,
  paymentInfo,
  contribution,
}) => {
  const theme = useTheme();
  const [sessionTimeExpired, setSessionTimeExpired] = useState(false);
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'));
  const { user } = useAccount();

  const {
    paymentInfo: { paymentOptions, membershipInfo },
    customToS,
    serviceProviderName,
    customWaiverId,
    customWaiverTemplateName,
  } = contribution;

  const priceWithoutFee = `Price per ${PAYMENT_MAPPING[typeOfPayment]}: $${formatMoney(
    summary?.dueNowWithCouponDiscountAmount === 0 && summary?.dueLater == 0 ? 0 : summary?.price, //PAYMENT_COST_MAPPING[typeOfPayment](paymentInfo),
  )}`;
  // const contribution = useContribution();
  let colorToUse = determineColorToUse(contribution);
  return (
    <>
      <FormikScrollToError>
        <Form id="credit-card">
          <Grid container spacing={2}>
            <Grid item md={12} xs={12}>
              <LabelText mobileView={mobileView}>Payment Summary</LabelText>
              <RadioGroup value={typeOfPayment} onChange={e => setTypeOfPayment(e.target.value)}>
                {paymentOptions.includes(PAYMENT_OPTIONS.PER_DAY) && (
                  <FormControlLabel
                    disabled={currentSubscription === PAYMENT_OPTIONS.PER_DAY}
                    value={PAYMENT_OPTIONS.PER_DAY}
                    control={<Radio color="primary" />}
                    label="Daily subscription"
                  />
                )}
                {paymentOptions.includes(PAYMENT_OPTIONS.PER_WEEK) && (
                  <FormControlLabel
                    disabled={currentSubscription === PAYMENT_OPTIONS.PER_WEEK}
                    value={PAYMENT_OPTIONS.PER_WEEK}
                    control={<Radio color="primary" />}
                    label="Weekly subscription"
                  />
                )}
                {paymentOptions.includes(PAYMENT_OPTIONS.PER_MONTH) && (
                  <FormControlLabel
                    disabled={currentSubscription === PAYMENT_OPTIONS.PER_MONTH}
                    value={PAYMENT_OPTIONS.PER_MONTH}
                    control={<Radio color="primary" />}
                    label="Monthly subscription"
                  />
                )}
                {paymentOptions.includes(PAYMENT_OPTIONS.PER_YEAR) && (
                  <FormControlLabel
                    disabled={currentSubscription === PAYMENT_OPTIONS.PER_YEAR}
                    value={PAYMENT_OPTIONS.PER_YEAR}
                    control={<Radio color="primary" />}
                    label="Yearly subscription"
                  />
                )}
                {paymentOptions.includes(PAYMENT_OPTIONS.PACKAGE) && (
                  <FormControlLabel
                    disabled={currentSubscription === PAYMENT_OPTIONS.PACKAGE}
                    value={PAYMENT_OPTIONS.PACKAGE}
                    control={<Radio color="primary" />}
                    label={`${membershipInfo.membershipPackage.duration} months subscription with ${membershipInfo.membershipPackage.period} payment`}
                  />
                )}
              </RadioGroup>
            </Grid>
            {!contribution?.isPriceHidden && (
              <Grid item md={12} xs={12}>
                <div>
                  {summary && (
                    <>
                      <PageSubtitle>{priceWithoutFee}</PageSubtitle>
                      {summary.platformFee > 0 && (
                        <PageSubtitle>Processing fee: ${formatMoney(summary.platformFee)}</PageSubtitle>
                      )}
                      {/* {summary.coupon?.discount && (
                      <PageSubtitle>
                        Discount: ${formatMoney(summary.dueNow)}
                      </PageSubtitle>
                    )} */}
                      <PageSubtitle>Price due now: ${formatMoney(summary.dueNowWithCouponDiscountAmount)}</PageSubtitle>
                    </>
                  )}
                </div>
                <Grid item md={6} xs={12}>
                  <ApplyCoupon
                    handleChange={handleChange}
                    errors={errors}
                    touched={touched}
                    handleRedeem={handleRedeem}
                  />
                </Grid>
              </Grid>
            )}
            {isEmpty(user) && (
              <AccountForm
                colorToUse={colorToUse}
                handleChange={handleChange}
                errors={errors}
                touched={touched}
                step={step}
                mobileView={mobileView}
                customWaiverId={customWaiverId}
                customWaiverTemplateName={customWaiverTemplateName}
              />
            )}
          </Grid>
          {step !== PURCHASE_MODAL_STEPS.memberInit && (
            <>
              <Grid item>
                <span className="mr-1">By clicking Reserve, I agree to the</span>
                <StyledLink
                  color={colorToUse?.PrimaryColorCode}
                  href="/"
                  onClick={e => {
                    e.preventDefault();
                    setShowTerms(true);
                  }}
                >
                  Terms and Conditions.
                </StyledLink>
              </Grid>
              {customToS && (
                <Grid item>
                  <span className="mr-1">By clicking Reserve, I also agree to {serviceProviderName}'s</span>
                  <StyledLink color={colorToUse?.PrimaryColorCode} href={customToS}>
                    Terms and Conditions.
                  </StyledLink>
                </Grid>
              )}
            </>
          )}
        </Form>
      </FormikScrollToError>
      <Popup
        open={sessionTimeExpired}
        title="The checkout time has expired"
        text="The checkout time has expired. Please select your session time again to complete registration."
        onCloseText="Select Session"
        onClose={onCheckoutSessionExpired}
      />
    </>
  );
};

PurchaseModalForm.propTypes = {
  typeOfPayment: PropTypes.string.isRequired,
  setTypeOfPayment: PropTypes.func.isRequired,
  summary: PropTypes.shape({
    price: PropTypes.number,
    platformFee: PropTypes.number,
    dueNow: PropTypes.number,
    dueLater: PropTypes.number,
  }),
  handleChange: PropTypes.func.isRequired,
  handleRedeem: PropTypes.func.isRequired,
  touched: PropTypes.bool.isRequired,
  error: PropTypes.shape({}),
  step: PropTypes.string.isRequired,
  monthlySessionSubscriptionInfo: PropTypes.shape({
    sessionCount: PropTypes.number,
    duration: PropTypes.number,
    monthlyPrice: PropTypes.number,
  }),
};

PurchaseModalForm.defaultProps = {
  summary: undefined,
  errors: undefined,
  error: undefined,
  paymentIntentCreated: undefined,
  sessionLifeTimeSeconds: undefined,
};
