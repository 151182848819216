import React from 'react';

const FilterIcon = () => {
  return (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_988_13356)">
        <path
          d="M0.666667 3.40463H2.49067C2.63376 3.93112 2.94612 4.3959 3.37955 4.72725C3.81299 5.05861 4.34341 5.23814 4.889 5.23814C5.43459 5.23814 5.96501 5.05861 6.39845 4.72725C6.83188 4.3959 7.14424 3.93112 7.28733 3.40463H15.3333C15.5101 3.40463 15.6797 3.33439 15.8047 3.20937C15.9298 3.08435 16 2.91478 16 2.73797C16 2.56115 15.9298 2.39159 15.8047 2.26656C15.6797 2.14154 15.5101 2.0713 15.3333 2.0713H7.28733C7.14424 1.54481 6.83188 1.08004 6.39845 0.748678C5.96501 0.41732 5.43459 0.237793 4.889 0.237793C4.34341 0.237793 3.81299 0.41732 3.37955 0.748678C2.94612 1.08004 2.63376 1.54481 2.49067 2.0713H0.666667C0.489856 2.0713 0.320286 2.14154 0.195262 2.26656C0.0702379 2.39159 0 2.56115 0 2.73797C0 2.91478 0.0702379 3.08435 0.195262 3.20937C0.320286 3.33439 0.489856 3.40463 0.666667 3.40463ZM4.88867 1.5713C5.11941 1.5713 5.34497 1.63972 5.53683 1.76792C5.72869 1.89611 5.87822 2.07832 5.96653 2.2915C6.05483 2.50468 6.07793 2.73926 6.03292 2.96557C5.9879 3.19188 5.87679 3.39976 5.71362 3.56292C5.55046 3.72609 5.34258 3.8372 5.11627 3.88222C4.88996 3.92723 4.65538 3.90413 4.4422 3.81583C4.22902 3.72752 4.04681 3.57799 3.91862 3.38613C3.79042 3.19427 3.722 2.96871 3.722 2.73797C3.72235 2.42865 3.84538 2.13211 4.0641 1.9134C4.28281 1.69468 4.57936 1.57165 4.88867 1.5713Z"
          fill="#215C73"
        />
        <path
          d="M15.3333 7.57146H13.5093C13.3665 7.04485 13.0542 6.57991 12.6208 6.24841C12.1874 5.91692 11.657 5.7373 11.1113 5.7373C10.5657 5.7373 10.0352 5.91692 9.60182 6.24841C9.16842 6.57991 8.85619 7.04485 8.71333 7.57146H0.666667C0.489856 7.57146 0.320286 7.6417 0.195262 7.76672C0.0702379 7.89175 0 8.06132 0 8.23813C0 8.41494 0.0702379 8.58451 0.195262 8.70953C0.320286 8.83455 0.489856 8.90479 0.666667 8.90479H8.71333C8.85619 9.4314 9.16842 9.89635 9.60182 10.2278C10.0352 10.5593 10.5657 10.7389 11.1113 10.7389C11.657 10.7389 12.1874 10.5593 12.6208 10.2278C13.0542 9.89635 13.3665 9.4314 13.5093 8.90479H15.3333C15.5101 8.90479 15.6797 8.83455 15.8047 8.70953C15.9298 8.58451 16 8.41494 16 8.23813C16 8.06132 15.9298 7.89175 15.8047 7.76672C15.6797 7.6417 15.5101 7.57146 15.3333 7.57146ZM11.1113 9.40479C10.8806 9.40479 10.655 9.33637 10.4632 9.20817C10.2713 9.07998 10.1218 8.89777 10.0335 8.68459C9.94517 8.47141 9.92207 8.23683 9.96708 8.01052C10.0121 7.78421 10.1232 7.57633 10.2864 7.41317C10.4495 7.25001 10.6574 7.13889 10.8837 7.09388C11.11 7.04886 11.3446 7.07197 11.5578 7.16027C11.771 7.24857 11.9532 7.3981 12.0814 7.58996C12.2096 7.78182 12.278 8.00738 12.278 8.23813C12.2776 8.54744 12.1546 8.84398 11.9359 9.06269C11.7172 9.28141 11.4206 9.40444 11.1113 9.40479Z"
          fill="#215C73"
        />
        <path
          d="M15.3333 13.0718H7.28733C7.14424 12.5453 6.83188 12.0805 6.39845 11.7492C5.96501 11.4178 5.43459 11.2383 4.889 11.2383C4.34341 11.2383 3.81299 11.4178 3.37955 11.7492C2.94612 12.0805 2.63376 12.5453 2.49067 13.0718H0.666667C0.489856 13.0718 0.320286 13.142 0.195262 13.2671C0.0702379 13.3921 0 13.5616 0 13.7385C0 13.9153 0.0702379 14.0848 0.195262 14.2099C0.320286 14.3349 0.489856 14.4051 0.666667 14.4051H2.49067C2.63376 14.9316 2.94612 15.3964 3.37955 15.7277C3.81299 16.0591 4.34341 16.2386 4.889 16.2386C5.43459 16.2386 5.96501 16.0591 6.39845 15.7277C6.83188 15.3964 7.14424 14.9316 7.28733 14.4051H15.3333C15.5101 14.4051 15.6797 14.3349 15.8047 14.2099C15.9298 14.0848 16 13.9153 16 13.7385C16 13.5616 15.9298 13.3921 15.8047 13.2671C15.6797 13.142 15.5101 13.0718 15.3333 13.0718ZM4.88867 14.9051C4.65792 14.9051 4.43236 14.8367 4.2405 14.7085C4.04864 14.5803 3.89911 14.3981 3.81081 14.1849C3.72251 13.9717 3.6994 13.7372 3.74442 13.5109C3.78943 13.2845 3.90055 13.0767 4.06371 12.9135C4.22687 12.7503 4.43475 12.6392 4.66106 12.5942C4.88737 12.5492 5.12195 12.5723 5.33513 12.6606C5.54831 12.7489 5.73052 12.8984 5.85871 13.0903C5.98691 13.2821 6.05533 13.5077 6.05533 13.7385C6.0548 14.0477 5.93172 14.3442 5.71304 14.5628C5.49436 14.7815 5.19792 14.9046 4.88867 14.9051Z"
          fill="#215C73"
        />
      </g>
      <defs>
        <clipPath id="clip0_988_13356">
          <rect width="16" height="16" fill="white" transform="translate(0 0.238281)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default FilterIcon;
