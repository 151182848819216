import React from 'react'
import PropTypes from 'prop-types'
import IconButton from '@material-ui/core/IconButton'
import MuiChatIcon from '@material-ui/icons/Chat'
import MuiChatOutlinedIcon from '@material-ui/icons/ChatOutlined'

import ToolbarAction from './ToolbarAction'

const Chat = ({ enabled, onEnabledChange }) => {
  const ChatIcon = enabled ? MuiChatOutlinedIcon : MuiChatIcon
  const label = enabled ? 'Hide Chat' : 'Chat'

  return (
    <ToolbarAction label={label}>
      <IconButton color="inherit" onClick={() => onEnabledChange(!enabled)}>
        <ChatIcon />
      </IconButton>
    </ToolbarAction>
  )
}

Chat.propTypes = {
  enabled: PropTypes.bool.isRequired,
  onEnabledChange: PropTypes.func.isRequired,
}

export default Chat
