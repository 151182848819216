import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Wrapper } from './Attachments';
import './Attachment.scss';

const OneAttachment = ({ item, index = 0, removeAttachment, handleRemoveAttachment, handleImageClick }) => {
  const { width = 400, height = 400, s3Link = '' } = item;
  const [dimensions, setDimensions] = useState({ width, height });

  return (
    <Wrapper
      index={index}
      removeAttachment={removeAttachment}
      handleRemoveAttachment={handleRemoveAttachment}
      isLandscape={dimensions.width > dimensions.height}
    >
      <div
        className={dimensions.width > dimensions.height ? 'image-wrap-landscape' : 'image-wrap'}
        style={{
          backgroundImage: `url(${s3Link})`,
          backgroundSize: 'cover',
        }}
        onClick={() => handleImageClick(item)}
      >
        <img
          src={s3Link}
          alt=""
          onLoad={e => {
            const { width, height } = e.target;
            setDimensions({ width, height });
          }}
          style={{ display: 'none' }}
        />
      </div>
    </Wrapper>
  );
};

export default OneAttachment;
