import { makeStyles } from '@material-ui/core';

const styles = theme => ({
  accordion: {
    boxShadow: 'none',
    width: '100%',
  },
  summary: {
    color: theme.palette.primary.lightGray,
    fontWeight: theme.typography.fontWeightBold,
    justifyContent: 'flex-start',
    // margin: '10px 0 20px',
  },
  summarySessionTime: {
    color: theme.palette.primary.lightGray,
    backgroundColor: theme.palette.colors.lightGray,
    fontWeight: '900',
    justifyContent: 'flex-start',
    padding: '0px 0px',
    fontSize: '16px',
  },
  summaryContent: {
    flexGrow: 'initial',
  },
  textArea: {
    backgroundColor: theme.palette.colors.lightGray,
    borderColor: theme.palette.colors.borderLightGray,
  },
  textAreaSessionTime: {
    backgroundColor: theme.palette.colors.main,
    borderColor: theme.palette.colors.borderLightGray,
  },
});

export const useStyles = makeStyles(styles);
