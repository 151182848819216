import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useContribution from 'pages/ContributionView/hooks/useContribution';
import { getThemedColors } from 'services/contributions.service';

import './Popup.scss';

const Popup = ({
  title,
  text,
  open,
  onCloseText,
  onClose,
  onSubmit,
  applyTheming,
  closeJoyRide = () => {},
  removedCalendarEmail,
}) => {
  const contribution = useContribution();
  const { themedColor, themedCardBackgroundColor } = getThemedColors(contribution);
  const color = applyTheming ? themedColor : '';
  const backgroundColor = applyTheming ? themedCardBackgroundColor : '';

  const Title = ({ children }) => <div style={{ color }}>{children}</div>;
  useEffect(() => {
    if (open === removedCalendarEmail) {
      closeJoyRide();
    }
  }, [open, removedCalendarEmail]);
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{
        paper: 'dialog-container',
      }}
      PaperProps={{
        style: {
          backgroundColor,
          color,
        },
      }}
    >
      <DialogTitle id="alert-dialog-title" style={{ color: color }}>
        <Title>{title}</Title>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description" style={{ color }}>
          {text}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {onSubmit && (
          <Button onClick={onSubmit} style={{ color }}>
            Yes
          </Button>
        )}
        <Button onClick={onClose} style={{ color }}>
          {onCloseText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

Popup.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  open: PropTypes.bool,
  onCloseText: PropTypes.string,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  applyTheming: PropTypes.bool,
};

Popup.defaultProps = {
  title: '',
  text: '',
  open: false,
  onCloseText: '',
  onClose: undefined,
  onSubmit: undefined,
  applyTheming: false,
};

export default Popup;
