import classes from './payoutsrow.module.scss';
import React, { useEffect, useState } from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import withStyles from '@material-ui/core/styles/withStyles';

const NarrowTableCell = withStyles({
  root: {
    paddingTop: 8,
    paddingBottom: 8,
    borderBottom: 0,
    paddingRight: 0,
    fontFamily: 'Avenir',
    fontSize: '14px',
    fontWeight: '350',
  },
})(TableCell);

export default function PayOutsRow() {
  return (
    <TableRow className={classes.container}>
      <NarrowTableCell align="left" component="th" scope="row">
        $91.98 USD
      </NarrowTableCell>
      <NarrowTableCell align="left" component="th" scope="row">
        *415
      </NarrowTableCell>
      <NarrowTableCell align="left" component="th" scope="row">
        June 2, 2023 16:09
      </NarrowTableCell>
    </TableRow>
  );
}
