import React from 'react';
import Loader from 'components/UI/Loader';
import { useShallowEqualSelector } from 'hooks';

export const ModalsAndLoadersContainer = () => {
  const loadingContributionSelector = state => state?.contributions?.updating;
  const updating = useShallowEqualSelector(loadingContributionSelector);

  if (updating) {
    return <Loader />;
  }
  return <></>;
};
