import React from 'react';
import PropTypes from 'prop-types';
import { SvgIcon } from '@material-ui/core';

function MuteIcon({ fillColor = '#979797' }) {
  return (
    <SvgIcon>
      <svg xmlns="http://www.w3.org/2000/svg" width="21" height="22" viewBox="0 0 21 22" fill="none">
        <path
          d="M2.82694 2.11516C2.67913 2.14191 2.54171 2.20935 2.43012 2.3099C2.31852 2.41045 2.23718 2.54012 2.19523 2.68436C2.15327 2.82859 2.15237 2.98166 2.19263 3.12638C2.23288 3.2711 2.31269 3.40172 2.4231 3.50358L18.5769 19.6574C18.6482 19.7453 18.7372 19.8173 18.838 19.8686C18.9389 19.9199 19.0494 19.9495 19.1624 19.9554C19.2755 19.9612 19.3885 19.9433 19.4941 19.9027C19.5997 19.8621 19.6957 19.7997 19.7757 19.7197C19.8557 19.6396 19.9179 19.5437 19.9585 19.438C19.999 19.3323 20.0169 19.2193 20.0109 19.1063C20.005 18.9933 19.9753 18.8828 19.9239 18.7819C19.8725 18.6811 19.8005 18.5922 19.7126 18.521L13.7308 12.54V3.17485C13.7308 2.15312 12.8383 1.89708 12.1404 2.59493L7.9251 6.73435L3.55952 2.36716C3.47615 2.27836 3.37378 2.20957 3.26006 2.16593C3.14635 2.12229 3.02424 2.10493 2.90286 2.11516C2.87757 2.11397 2.85224 2.11397 2.82694 2.11516ZM4.03848 7.81989C3.59344 7.81989 3.23079 8.18173 3.23079 8.62758V13.4737C3.23079 13.918 3.59344 14.2814 4.03848 14.2814H6.81533L12.1154 19.4547C12.9231 20.2624 13.7308 19.8488 13.7308 18.748V16.6778L4.87121 7.81989H4.03848Z"
          fill={fillColor}
        />
      </svg>
    </SvgIcon>
  );
}

MuteIcon.propTypes = {
  fillColor: PropTypes.string.isRequired,
  style: PropTypes.shape({}).isRequired,
};

export default MuteIcon;
