import { handleActions } from 'redux-actions';

import {
  setCohealerInfoForClient,
  clearCohealerInfoForClient,
  setCohealerIcon,
  clearCohealerIcon,
} from 'actions/cohealerInfo';

const initialState = {
  info: {},
  avatarUrl: null,
};

const cohealerInfo = handleActions(
  {
    [setCohealerInfoForClient]: (state, { payload }) => ({
      ...state,
      info: payload,
    }),
    [clearCohealerInfoForClient]: state => ({
      ...state,
      info: {},
    }),
    [setCohealerIcon]: (state, { payload }) => ({
      ...state,
      avatarUrl: payload,
    }),
    [clearCohealerIcon]: state => ({
      ...state,
      avatarUrl: null,
    }),
  },
  initialState,
);

export default cohealerInfo;
