export const getContributionStatusView = rawStatus => {
  const statusesCollection = {
    Draft: 'Draft',
    InSandbox: 'Draft',
    InReview: 'In Review',
    Revised: 'Revised',
    Approved: 'Live',
    Rejected: 'Rejected',
    ChangeRequired: 'Change Required',
    Completed: 'Archived',
  };

  return statusesCollection[rawStatus] || 'Unknown';
};
