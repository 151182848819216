import React, { useCallback, useState } from 'react';
import classes from './Unsubscribe.module.scss';
import styled from 'styled-components';
import Card from 'components/UnsubCards/Card/Card';
import UnsubCard from 'components/UnsubCards/UnsubCard/UnsubCard';
import ResubCard from 'components/UnsubCards/ResubCard/ResubCard';
import ResubSuccessCard from 'components/UnsubCards/ResubSuccessCard/ResubSuccessCard';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import ErrorCard from 'components/UnsubCards/ErrorCard/ErrorCard';
import Loader from 'components/UI/Loader';
import {
  resubscribeEmailCampaign,
  unsubscribeEmailCampaign,
} from 'pages/CreateCampaign/Services/CreateCampaign.service';
export const EMAIL_SUBSCRIPTION_STATUS = {
  subscribed: 'subscribed',
  unsubscribed: 'unsubscribed',
  resubscribed: 'resubscribed',
  error: 'error',
};
const CenterContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: 20px 10px;
  box-sizing: border-box;
  flex-direction: column;
  background-color: #fafafa;
  ${({ viewportHeight }) => viewportHeight && 'min-height: 100vh'}
`;
const UnsubscribePage = () => {
  const { coachId, clientEmail } = useParams();
  const [status, setStatus] = useState(EMAIL_SUBSCRIPTION_STATUS.subscribed);
  const [lastStatus, setLastStatus] = useState(EMAIL_SUBSCRIPTION_STATUS.subscribed);
  const [lastStatusData, setLastStatusData] = useState({});
  const [loading, setLoading] = useState(false);

  const setStatusTo = useCallback(status => {
    setStatus(status);
  }, []);

  const unsubscribeUser = data => {
    setLoading(true);
    unsubscribeEmailCampaign(data)
      .then(res => {
        setStatusTo(EMAIL_SUBSCRIPTION_STATUS.unsubscribed);
      })
      .catch(err => {
        setStatusTo(EMAIL_SUBSCRIPTION_STATUS.error);
        setLastStatus(EMAIL_SUBSCRIPTION_STATUS.subscribed);
        setLastStatusData(data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const resubscribeUser = data => {
    setLoading(true);

    resubscribeEmailCampaign(data)
      .then(res => {
        setStatusTo(EMAIL_SUBSCRIPTION_STATUS.resubscribed);
      })
      .catch(err => {
        setStatusTo(EMAIL_SUBSCRIPTION_STATUS.error);
        setLastStatus(EMAIL_SUBSCRIPTION_STATUS.unsubscribed);
        setLastStatusData(data);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const onRetry = () => {
    if (lastStatus === EMAIL_SUBSCRIPTION_STATUS.subscribed) {
      unsubscribeUser(lastStatusData);
    } else {
      resubscribeUser(lastStatusData);
    }
  };
  return (
    <CenterContainer viewportHeight>
      {loading && <Loader />}
      {status === EMAIL_SUBSCRIPTION_STATUS.subscribed && (
        <UnsubCard clientEmail={clientEmail} coachId={coachId} onSubmit={unsubscribeUser} />
      )}
      {status === EMAIL_SUBSCRIPTION_STATUS.unsubscribed && (
        <ResubCard clientEmail={clientEmail} coachId={coachId} onResubscribe={resubscribeUser} />
      )}
      {status === EMAIL_SUBSCRIPTION_STATUS.resubscribed && <ResubSuccessCard clientEmail={clientEmail} />}
      {status === EMAIL_SUBSCRIPTION_STATUS.error && (
        <ErrorCard clientEmail={clientEmail} onRetry={onRetry} lastStatus={lastStatus} />
      )}
    </CenterContainer>
  );
};
export default UnsubscribePage;
