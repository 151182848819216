import { SET_ACCOUNT, SET_LAST_SENDER, SET_SENDER_IDENTITIES } from 'actions/emailMarketing.actions';
import {
  GET_DOMAINS_REQUEST,
  GET_DOMAINS_SUCCESS,
  GET_DOMAINS_ERROR,
  SET_DOMAIN_IN_AUTH_PROCESS,
  SET_DOMAINS,
} from 'actions/emailMarketing.actions';

const initialState = {
  isLoading: false,
  error: null,
  account: {},
  domains: [],
  domainInAuthProcess: '',
  lastSenderAdded: '',
};

const emailMarketingReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_DOMAINS_REQUEST:
      return { ...state, isLoading: true };
    case SET_DOMAINS:
    case GET_DOMAINS_SUCCESS:
      return { ...state, isLoading: false, domains: payload };
    case GET_DOMAINS_ERROR:
      return { ...state, isLoading: false, error: payload };
    case SET_ACCOUNT:
      return { ...state, account: payload, error: null };
    case SET_SENDER_IDENTITIES:
      return { ...state, account: { ...state.account, senderIdentities: payload }, error: null };
    case SET_LAST_SENDER:
      return { ...state, lastSenderAdded: payload, error: null };
    case SET_DOMAIN_IN_AUTH_PROCESS:
      return { ...state, domainInAuthProcess: payload, error: null };
    default:
      return state;
  }
};

export default emailMarketingReducer;
