import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useStripe } from '@stripe/react-stripe-js';
import * as paidTier from 'selectors/paidTier';
import { setActivePaidTierPlan, setPaidTierPlans } from 'actions/paidTierOptions';
import usePaidTier from 'hooks/usePaidTier';
import Loader from 'components/UI/Loader';
import PlanCard from '../../components/PlanCard';
import { isPaidTierAnnualSelector } from 'selectors/user';
import {
  getPaidTierOptions,
  createPaidTierOptionsSession,
  upgradePaidTierOptions,
  getCurrentPlan,
} from '../../services/paidTierOptions.service';
import { PAID_TIER_BUTTON_LABEL, PAIDTIER_STATUSES, PAID_TIER_TITLES } from '../../constants';
import useAccount from '../../hooks/useAccount';
import useShallowEqualSelector from 'hooks/useShallowEqualSelector';
import { setCohereAcademyStatus } from 'actions/update-user';

const MOST_POPULAR_PLAN = PAID_TIER_TITLES.impact;

const monthCount = 12;

const useStyles = makeStyles(theme => ({
  planContainer: {
    width: '100%',
    display: 'grid',
    gap: 10,
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridAutoFlow: 'column',
    justifyContent: 'space-between',
    justifyItems: 'center',
    [theme.breakpoints.down(1150)]: {
      gridAutoFlow: 'row',
      gridTemplateRows: '1fr',
      gridTemplateColumns: '1fr',
      justifyItems: 'center',
    },
  },
}));

const SelectingPlan = ({ isModalView, modalsApi }) => {
  const dispatch = useDispatch();
  const stripe = useStripe();
  const isAnnualPlan = useShallowEqualSelector(isPaidTierAnnualSelector);
  const activePaidTierPlan = useShallowEqualSelector(paidTier.activePaidTierPlanSelector);
  const activePaidTierCurrentPlan = useShallowEqualSelector(paidTier.activePaidTierCurrentPlanSelector);
  useEffect(() => {
    if (activePaidTierCurrentPlan?.default) {
      dispatch(setCohereAcademyStatus(true));
    } else {
      dispatch(setCohereAcademyStatus(false));
    }
  }, [activePaidTierCurrentPlan, dispatch]);

  const {
    // isAnnualPlan,
    paidTierPlans,
    // activePaidTierPlan,
    isLoader,
    activePaidTierCurrentPaymentPeriod,
    activePaidTierPlanStatus,
  } = usePaidTier();
  const { user } = useAccount();

  const periodLabel = activePaidTierCurrentPaymentPeriod === 'Monthly' ? 'a monthly' : 'an annual';
  const nextPaymentActionLabel = activePaidTierPlanStatus === 'Cancel' ? 'cancels' : 'renews';

  const classes = useStyles();

  const fetchActivePaidTierPlanAndUpdateStore = () => {
    return getCurrentPlan().then(data => {
      dispatch(setActivePaidTierPlan(data));
      return data;
    });
  };

  // useEffect(() => {
  //   getPaidTierOptions().then(data => dispatch(setPaidTierPlans(data)));
  // }, []);

  const createButtonLabel = item => {
    const isActivePlan = activePaidTierPlan?.paidTierOption;

    const hasBiggerPlan = item.id !== isActivePlan?.id && isActivePlan?.order <= item?.order;
    const hasSmallerPlan = item.id !== isActivePlan?.id && isActivePlan?.order > item?.order;

    if (item.id === activePaidTierPlan?.paidTierOption?.id) {
      item.buttonLabel = PAID_TIER_BUTTON_LABEL.cancel;
    } else if (hasBiggerPlan) {
      item.buttonLabel = PAID_TIER_BUTTON_LABEL.upgrade;
    } else if (hasSmallerPlan) {
      item.buttonLabel = PAID_TIER_BUTTON_LABEL.downgrade;
    }

    return item;
  };

  const handleCancel = (item, isActivePlan) => {
    modalsApi.warning_before_cancellation.setIsShowBeforeCancellationWarning({
      isShow: true,
      label: `Are you sure you wish to cancel your subscription?`,
      userId: user.accountId,
      itemId: item.id,
      activePlanTitle: isActivePlan.displayName,
    });
  };

  const handleUpgrade = (item, period, isActivePlan) => {
    if (isActivePlan?.default) {
      createPaidTierOptionsSession(item.id, period, user.accountId).then(({ data }) => {
        stripe.redirectToCheckout({
          sessionId: data,
        });
      });
    } else {
      upgradePaidTierOptions(user.accountId, item.id, period)
        .then(() => {
          fetchActivePaidTierPlanAndUpdateStore().then(data => {
            modalsApi.upgrade_success.setIsShowSuccessUpgradeModal({
              isShow: true,
              label: `Plan will upgrade to ${data.nextPaidTierOption?.displayName} when your current plan expires.`,
            });
          });
        })
        .catch(err => {
          console.dir(err);
          if (activePaidTierPlanStatus !== PAIDTIER_STATUSES.ACTIVE) {
            modalsApi.upgrade_impossible.setImpossibleToUpgradeModal({
              isShow: true,
              label: `You are currently subscribed to ${periodLabel} plan. Your plan will ${nextPaymentActionLabel} on (${new Date(
                activePaidTierPlan?.endDateTime,
              ).toLocaleDateString(
                'en-US',
              )}). If you wish to upgrade and cancel your ${periodLabel} plan, please email us at support@cohere.live.`,
            });
          }
        });
    }
  };

  const handleDowngrade = (item, period) => {
    upgradePaidTierOptions(user.accountId, item.id, period)
      .then(() => {
        fetchActivePaidTierPlanAndUpdateStore().then(data => {
          modalsApi.upgrade_success.setIsShowSuccessUpgradeModal({
            isShow: true,
            label: `Plan will downgrade to ${data.nextPaidTierOption?.displayName} when your current plan expires.`,
          });
        });
      })
      .catch(err => {
        if (activePaidTierPlan.status !== PAIDTIER_STATUSES.ACTIVE) {
          modalsApi.upgrade_impossible.setImpossibleToUpgradeModal({
            isShow: true,
            label: `You can't downgrade your plan right now. Wait when ${
              activePaidTierPlan?.paidTierOption?.displayName
            } plan expires - ${new Date(activePaidTierPlan?.endDateTime).toLocaleDateString('en-US')}`,
          });
        }
      });
  };

  const addOnclickHandle = item => {
    const isActivePlan = activePaidTierPlan?.paidTierOption;
    const period = isAnnualPlan ? 'Annually' : 'Monthly';

    const hasBiggerPlan = item.id !== isActivePlan?.id && isActivePlan?.order < item?.order;
    const hasSmallerPlan = item.id !== isActivePlan?.id && isActivePlan?.order > item?.order;

    if (item.id === isActivePlan?.id) {
      item.onclick = () => handleCancel(item, isActivePlan);
    } else if (hasBiggerPlan) {
      item.onclick = () => handleUpgrade(item, period, isActivePlan);
    } else if (hasSmallerPlan) {
      item.onclick = () => handleDowngrade(item, period);
    }

    return item;
  };

  if (isLoader) {
    return <Loader />;
  }

  return (
    <div className={classes.planContainer}>
      {paidTierPlans
        ?.filter(el => el.displayName === PAID_TIER_TITLES.impact || el.displayName === PAID_TIER_TITLES.scale)
        .sort((elA, elB) => elA.order - elB.order)
        .map(createButtonLabel)
        .map(addOnclickHandle)
        .map(plan => (
          <PlanCard
            key={plan.id}
            id={plan.id}
            title={plan.displayName}
            price={isAnnualPlan ? plan.pricePerYear?.toFixed() / monthCount : plan.pricePerMonth?.toFixed()}
            fee={plan.fee}
            advantages={plan.advantages}
            expires={plan.id === activePaidTierPlan?.paidTierOption?.id ? activePaidTierPlan?.endDateTime : null}
            canBeUpgraded={plan.canBeUpgraded}
            isDefaultPlan={plan.default}
            isActive={plan.id === activePaidTierPlan?.paidTierOption?.id}
            buttonLabel={plan.buttonLabel}
            popular={isAnnualPlan && plan.displayName === MOST_POPULAR_PLAN}
            onclick={plan.onclick}
            isModalView={isModalView}
            planStatus={activePaidTierPlan?.status}
            paymentPeriod={activePaidTierPlan?.currentPaymentPeriod}
          />
        ))}
    </div>
  );
};

SelectingPlan.propTypes = {
  annualPlan: PropTypes.bool.isRequired,
  isModalView: PropTypes.bool.isRequired,
};

export default SelectingPlan;
