import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { CircularProgress, Grid } from '@material-ui/core';

import UISelect from 'components/UI/Select';
import useContribution from 'pages/ContributionView/hooks/useContribution';
import { memberPodsSelector } from 'selectors/MemberPods';

import { useSetInitialSelectedValues } from './hooks/useSetInitialSelectedValues';
import { useHandlers } from './hooks/useHandlers';
import { useLocalState } from './hooks/useLocalState';
import { useStyles } from './hooks/useStyles';
import { determineDarkThemedColorToUse } from 'services/contributions.service';

const mapData = data => {
  return data.map(item => ({
    ...item,
    title: item.name,
    value: item.id,
  }));
};

export const MemberPodsAssignment = ({ contributionParticipant }) => {
  const { id: contributionId } = useContribution();
  const contribution = useContribution();
  const { id: memberId } = contributionParticipant;
  const { error, isFetching, contributionMemberPods } = useSelector(memberPodsSelector);
  const [state, setState] = useLocalState();
  const isDarkThemeEnabled = determineDarkThemedColorToUse(contribution);
  const classNames = useStyles();
  const onBlurElementRef = useRef(null);

  const handlers = useHandlers({ memberId, onBlurElementRef, setState, state });

  useSetInitialSelectedValues({ memberId, setState, state });

  if (!state.isFeatureAllowed) {
    return null;
  }

  if (isFetching) {
    return (
      <Grid className={classNames.loaderWrapper} container>
        <CircularProgress />
      </Grid>
    );
  }

  if (!Array.isArray(contributionMemberPods?.[contributionId])) {
    return null;
  }
  return (
    <Grid className={classNames.wrapper}>
      {error && 'Error loading Member Pods.'}
      <UISelect
        className={classNames.selectWrapper}
        disabled={!contributionMemberPods[contributionId].length}
        items={mapData(contributionMemberPods[contributionId])}
        label={contributionMemberPods[contributionId].length ? 'Assign member to a Group' : 'Create Member Pod first'}
        multiple
        isDarkThemeEnabled={isDarkThemeEnabled}
        onChange={handlers.onChange}
        onClose={handlers.onClose}
        onExited={handlers.onExited}
        value={state.selectedValues}
      />
      <span ref={onBlurElementRef} tabIndex={-1} />
    </Grid>
  );
};

MemberPodsAssignment.defaultProps = {
  contributionParticipant: {
    id: '',
  },
};

MemberPodsAssignment.propTypes = {
  contributionParticipant: PropTypes.shape({
    id: PropTypes.string,
  }),
};
