import axiosInstance from 'utils/axiosInstance';
import { get } from 'lodash/fp';
import { downloadFile } from 'utils/utils';

export const CLIENTS_LIMIT = 20;

const getClients = ({ pageNumber = 1, clientName = undefined, limit: pageSize = CLIENTS_LIMIT }) => {
  return axiosInstance
    .get('/User/GetAllClientsInfo', { params: { pageNumber, clientName, pageSize } })
    .then(get('data'));
};

const getClientNotes = id => {
  return axiosInstance.get(`/ClientNote/GetClientNotesById/${id}`).then(get('data'));
};
const updateClientNotes = data => {
  return axiosInstance.post('/ClientNote/AddOrUpdateClientNotes', data).then(get('data'));
};

const exportClients = () => {
  return axiosInstance
    .get('/User/ExportAllClientsInfo')
    .then(get('data'))
    .then(res => downloadFile(res, 'Cohere All Clients.csv'));
};

const getClientBasicInfo = id => {
  return axiosInstance.get(`/User/Getclientbasicinfo/?clientId=${id}`).then(get('data'));
};

const getAllUpCommingSessionsOfClient = (clientId, pageNumber, pageSize) =>
  axiosInstance
    .get(
      `/Contribution/GetAllUpCommingSessionsOfClient?clientId=${clientId}&pageNumber=${pageNumber}&pageSize=${pageSize}`,
    )
    .then(get('data'));

const getEnrolledContributionsOfClient = (clientId, pageNumber, pageSize) =>
  axiosInstance
    .get(
      `/Contribution/GetEnrolledContributionsOfClient?clientId=${clientId}&pageNumber=${pageNumber}&pageSize=${pageSize}`,
    )
    .then(get('data'));

const getRecentPaymentsOfClient = (clientId, pageNumber, pageSize) =>
  axiosInstance
    .get(`/Contribution/GetRecentPaymentsOfClient?clientId=${clientId}&pageNumber=${pageNumber}&pageSize=${pageSize}`)
    .then(get('data'));

const getContractAndESignatures = (clientId, pageNumber, pageSize) =>
  axiosInstance
    .get(`/Contribution/GetContractAndESignatures?clientId=${clientId}&pageNumber=${pageNumber}&pageSize=${pageSize}`)
    .then(get('data'));

const sendClientNotesToEmail = ({ note, emails, contributionId }) =>
  axiosInstance.post(`/ClientNote/SendClientNotesToEmail`, {
    emailsList: emails,
    note,
    contributionId: contributionId,
  });

export {
  getClients,
  getClientBasicInfo,
  getAllUpCommingSessionsOfClient,
  getEnrolledContributionsOfClient,
  getRecentPaymentsOfClient,
  getClientNotes,
  updateClientNotes,
  exportClients,
  getContractAndESignatures,
  sendClientNotesToEmail,
};
