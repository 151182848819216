import React from 'react';
import './Attachment.scss';
import { Wrapper } from './Attachments';

const MultipleAttachments = ({ attachments, removeAttachment, handleRemoveAttachment, handleImageClick }) => {
  return (
    <Wrapper index={0} removeAttachment={removeAttachment} handleRemoveAttachment={handleRemoveAttachment}>
      <div
        className="image-wrap-row"
        style={{
          backgroundImage: `url(${attachments[0]?.item?.s3Link || ''})`,
          backgroundSize: 'cover',
        }}
        onClick={() => handleImageClick(attachments[0]?.item || {})}
      ></div>
      <div className="modified-image-container">
        <div
          className="modified-image-wrap-multiple-attachments"
          style={{
            backgroundImage: `url(${attachments[1]?.item?.s3Link || ''})`,
            backgroundSize: 'cover',
          }}
          onClick={() => handleImageClick(attachments[1]?.item || {})}
        ></div>
        <div
          className="modified-image-wrap-multiple-attachments"
          style={{
            backgroundImage: `url(${attachments[2]?.item?.s3Link || ''})`,
            backgroundSize: 'cover',
          }}
          onClick={() => handleImageClick(attachments[2]?.item || {})}
        ></div>
        <div
          className="image-overlay"
          style={{
            backgroundImage: `url(${attachments[3].item.s3Link})`,
            backgroundSize: 'cover',
          }}
          onClick={() => handleImageClick(attachments[3].item)}
        >
          <div className="image-text">
            <span style={{ cursor: 'pointer' }}>+{attachments.length - 3}</span>
          </div>
          <div className="compress-image-overlay"></div>
        </div>
      </div>
    </Wrapper>
  );
};

export default MultipleAttachments;
