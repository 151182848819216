import { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import { DATE_FORMATS } from '../../../../utils/datesAndMoney';

const useDiffDate = (createTime, timeZoneId) => {
  const [createDataDiff, setCreateDataDiff] = useState(null);
  useEffect(() => {
    const admission = moment().format(DATE_FORMATS.POST_TIME);
    const discharge = moment(createTime).format(DATE_FORMATS.POST_TIME);
    const ms = moment(admission, DATE_FORMATS.POST_TIME).diff(moment(discharge, DATE_FORMATS.POST_TIME));
    const d = moment.duration(ms);
    const weeks = Math.floor(d.asWeeks());
    let day = Math.floor(d.asDays());
    let hours = Math.floor(d.asHours());
    let minutes = Math.floor(d.asMinutes());
    if (day < 0) {
      day = 0;
    }
    if (hours < 0) {
      hours = 0;
    }
    if (minutes < 0) {
      minutes = 0;
    }
    if (day === 0 && hours === 0 && minutes === 0) {
      setCreateDataDiff('just now');
    } else if (day === 0 && hours === 0) {
      setCreateDataDiff(`${minutes}m`);
    } else if (day === 0) {
      setCreateDataDiff(`${hours}h`);
    } else if (day < 7 && day > 0) {
      setCreateDataDiff(`${day}d`);
    } else if (weeks >= 1 && weeks < 4) {
      setCreateDataDiff(`${weeks}w`);
    } else {
      setCreateDataDiff(moment(createTime).tz(timeZoneId).format(DATE_FORMATS.DATE_TIME));
    }
  }, [createTime, setCreateDataDiff]);
  return { createDataDiff };
};

export default useDiffDate;
