import React from 'react';
import PropTypes from 'prop-types';
import { Grid, useMediaQuery } from '@material-ui/core';

import usePaidTier from 'hooks/usePaidTier';
import { PAIDTIER_STATUSES } from '../../constants';

import AdvantagesItem from './AdvantagesItem';
import FavBookIcon from './FavBookIcon';
import UpgradeButton from '../UI/UpgradeButton';
import { useStyles, StyledContainer, TitleField, PriceField, FeeField, AdvantagesList, ExpiresLabel } from './styles';
import { PrimaryP, SecondaryP } from 'pages/Account/CohealerBilling/Billing.styles';
import { useLocation } from 'react-router-dom';
import CircleIcon from '@mui/icons-material/Circle';

const Card = ({
  id,
  title,
  price,
  fee,
  advantages,
  expires = undefined,
  canBeUpgraded,
  isActive,
  buttonLabel,
  popular,
  onclick,
  isModalView,
  isDefaultPlan,
  planStatus,
  paymentPeriod,
  fontSize,
  PrimaryAmount,
  Primarytext,
}) => {
  const classes = useStyles();
  // const { isAnnualPlan } = usePaidTier();
  const ResponsivemobileView1500 = useMediaQuery('(max-width: 1550px)');
  const location = useLocation();
  const currentButtonClass = isActive
    ? `${classes.selectPlanButton} ${classes.enable}`
    : `${classes.selectPlanButton} ${classes.disable}`;

  const isUpgradedOrCanceled = planStatus === PAIDTIER_STATUSES.UPGRADE || planStatus === PAIDTIER_STATUSES.CANCEL;

  const hideCancelButton = isActive && isUpgradedOrCanceled;

  const formatedDate = new Date(expires).toLocaleDateString('en-US');

  const isAnnual = paymentPeriod === 'Annually';
  const paymentPeriodLabel = isAnnual ? 'annual' : 'monthly';

  const isCancel = planStatus === 'Cancel';
  const ResponsivemobileView = useMediaQuery('(max-width: 450px)');
  return (
    <>
      {location.pathname == '/account/billing/coach' || location.pathname == '/account/billing/six-month' ? (
        <StyledContainer
          width={'100%'}
          boxShadow="rgba(149, 157, 165, 0.2) 0px 8px 24px"
          padding="25px 30px 31px 25px"
          height={ResponsivemobileView ? 'auto' : ResponsivemobileView1500 ? '1100px' : '800px'}
          border="0px"
          borderRadius="0px"
          className={classes.StyledContainerheightstyle}
        >
          <Grid
            container
            style={{ height: 'auto' }}
            className={ResponsivemobileView ? classes.cardBox : ResponsivemobileView1500 && classes.cardBoxCard}
          >
            <Grid item xs={12} className={classes.topLevelcoach}>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                {/* <div style={{ cursor: 'pointer', borderRadius: '5px', padding: '10px', backgroundColor: '#d4e4ff', color: '#74a6fc' }}> */}
                <div
                  style={{
                    // cursor: 'pointer',
                    borderRadius: '4px',
                    padding: '5px 12px',
                    backgroundColor: ' #F2F5F7',
                    color: '#215C73',
                  }}
                >
                  <TitleField
                    style={{
                      height: '27px',
                      display: 'flex',
                      justifyContent: 'center',
                      paddingTop: '5px',
                      width: '150px',
                      fontWeight: 500,
                      color: '#215C73',
                    }}
                    fontSize={ResponsivemobileView ? '14px' : '15px'}
                    className={classes.titleMedia}
                  >
                    {title}
                  </TitleField>
                </div>
                <PrimaryP
                  style={{
                    lineHeight: '15px',
                    fontFamily: 'Inter',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    width: '161px',
                    height: '50px',
                  }}
                  margin="4px 0px 0px 0px"
                  textAlign="center"
                  textfont="12px"
                >
                  {Primarytext}
                </PrimaryP>
                <div style={{ display: 'flex', marginBottom: '20px' }}>
                  {/* <span
                    style={{
                      paddingBottom: '5px',
                      display: 'flex',
                      alignItems: 'flex-end',
                      color: '#215C73',
                      fontWeight: '600',
                      fontStyle: 'Poppins',
                      fontSize: '16px',
                    }}
                  >
                    $
                  </span>
                  <PrimaryP
                    style={{
                      color: '#215C73',
                      fontWeight: 600,
                      fontSize: '28.3077px',
                      textDecorationLine: title === 'All-In-One-Platform' ? '' : 'line-through',
                    }}
                    className={classes.enabeltext}
                  >
                    {PrimaryAmount}
                  </PrimaryP>
                  {!(title === 'All-In-One-Platform') && (
                    <div
                      style={{
                        width: '50px',
                        height: '15px',
                        borderRadius: '2px',
                        padding: '5px 14px',
                        backgroundColor: '#F2F5F7',
                        color: '#215C73',
                        marginLeft: '4px',
                        marginTop: '3px',
                      }}
                    >
                      <TitleField
                        style={{
                          fontfamily: 'Inter',
                          fontStyle: 'normal',
                          fontWeight: '500',
                          margin: '-5px 0px 0px -8px',
                        }}
                        color="#215C73"
                        fontSize="10px"
                        className={classes.titleMedia}
                      >
                        Included
                      </TitleField>
                    </div>
                  )} */}
                </div>
              </div>
              {/* <PriceField className={classes.priceMedia}>
          ${parseFloat(price)?.toFixed()}{' '}
          <span>/ month {isAnnualPlan && `($${(parseFloat(price) * 12)?.toFixed()} billed annually)`}</span>
        </PriceField> */}
              {/* <FeeField className={classes.feeMedia}>{fee}% Fee</FeeField> */}
            </Grid>
            <Grid item xs={12} className={classes.midLevelcoach}>
              {/* <AdvantagesList>
          {advantages.map(label => (
            <AdvantagesItem key={label} label={label} />
          ))}
        </AdvantagesList> */}
              {advantages.map(label => (
                <AdvantagesList style={{ fontSize: ResponsivemobileView ? '14px' : '15px' }}>
                  <CircleIcon style={{ paddingBottom: '2px', fontSize: '7px' }} /> {label}
                </AdvantagesList>
              ))}
            </Grid>
            <Grid
              container
              item
              xs={12}
              className={classes.bottomLevel}
              justify="center"
              alignContent="flex-end"
              direction="row"
            >
              {isDefaultPlan ? (
                <Grid item>{isActive ? <ExpiresLabel>Current plan</ExpiresLabel> : null}</Grid>
              ) : (
                <Grid item>
                  {expires && isActive && isCancel && (
                    <ExpiresLabel>
                      {' '}
                      Your {paymentPeriodLabel} {title} cancels on {formatedDate}.
                    </ExpiresLabel>
                  )}
                  {expires && isActive && !isCancel && (
                    <ExpiresLabel>
                      Your {paymentPeriodLabel} {title} renews on {formatedDate}.
                    </ExpiresLabel>
                  )}
                  {/* {!hideCancelButton && (
              <div className={classes.upgradeButtonWrapper}>
                <UpgradeButton style={currentButtonClass} onclick={onclick} buttonLabel={buttonLabel} />
              </div>
            )} */}
                </Grid>
              )}
            </Grid>
          </Grid>
        </StyledContainer>
      ) : (
        <></>
      )}
    </>
  );
};

Card.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  fee: PropTypes.number.isRequired,
  advantages: PropTypes.arrayOf(PropTypes.string).isRequired,
  expires: PropTypes.string,
  buttonLabel: PropTypes.string,
  canBeUpgraded: PropTypes.bool.isRequired,
  isDefaultPlan: PropTypes.bool.isRequired,
  isModalView: PropTypes.bool.isRequired,
  planStatus: PropTypes.string.isRequired,
};

export default Card;
