import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, Redirect } from 'react-router-dom';
import AdminContributionsPage from 'pages/Admin/AdminContributionsPage';
import MainContainer from './MainContainer';

const AdminContibutionsContainer = ({ match: { path } }) => {
  return (
    <MainContainer>
      <Switch>
        <Route exact path={path} component={AdminContributionsPage} />
        <Redirect to="/admin/contributions/review" />
      </Switch>
    </MainContainer>
  );
};

AdminContibutionsContainer.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string,
  }).isRequired,
};

export default AdminContibutionsContainer;
