import React from 'react';

export const PlayIcon = ({ color }) => (
  <svg width="78" height="78" viewBox="0 0 78 78" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d_7683_74738)">
      <circle cx="39" cy="35" r="35" fill="white" />
      <path
        d="M55 34.3098C55.6667 34.6947 55.6667 35.6569 55 36.0418L31.75 49.4652C31.0833 49.8501 30.25 49.369 30.25 48.5992L30.25 21.7524C30.25 20.9826 31.0833 20.5015 31.75 20.8864L55 34.3098Z"
        fill={color}
      />
    </g>
    <defs>
      <filter
        id="filter0_d_7683_74738"
        x="0"
        y="0"
        width="78"
        height="78"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_7683_74738" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_7683_74738" result="shape" />
      </filter>
    </defs>
  </svg>
);
