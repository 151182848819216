import { useHeader } from 'hooks/usePageActions';
import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Button from 'components/FormUI/Button';
import InfoIcon from '@material-ui/icons/Info';
import Tooltip from '@material-ui/core/Tooltip';
import useScreenView from 'hooks/useScreenView';
import CohereReferalsDetailPage from './components/CohereReferalsDetailsPage';
import ExportIcon from 'pages/Contacts/Components/Icons/ExportIcon';
import NoCohereAffiliates from './components/NoCohereAffiliates';

function AffiliatesPage() {
  useHeader('Affiliate Program');
  const { mobileView } = useScreenView();
  const [value, setValue] = useState('Cohere Affiliates');
  return (
    <>
      {/* <Box
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
          display: 'flex',
          width: '100%',
          overflowX: mobileView ? 'scroll' : 'unset',
        }}
      >
        {[
          'Cohere Affiliates',
          // , 'My Affiliates'
        ].map((item, index) => {
          return (
            <div
              style={{
                borderBottom: item === value ? `3px solid #215C73` : 'none',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                marginRight: '20px',
              }}
            >
              <Button
                variant="contained"
                value={item}
                style={{
                  backgroundColor: 'transparent',
                  display: 'flex',
                  justifyContent: 'center',
                  fontSize: '16px',
                  fontWeight: value != item ? '400' : '800',
                  color: value != item ? '#000000DE' : '#215C73',
                  minWidth: 'auto',
                  fontFamily: 'Avenir',
                  alignItems: 'center',
                  gap: '5px',
                  padding: '1rem 10px',
                }}
                onClick={e => {
                  if (value != item) {
                    setValue(item);
                  }
                }}
              >
                {item}
              </Button>
            </div>
          );
        })}
      </Box> */}
      {value === 'Cohere Affiliates' && <CohereReferalsDetailPage />}
      {/* {value === 'Cohere Affiliates' && <NoCohereAffiliates />} */}
    </>
  );
}

export default AffiliatesPage;
