import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';

import { useLoadAllUnreadPosts } from 'pages/Conversations/hooks/useLoadAllUnreadPosts';

const useStyles = makeStyles(theme => ({
  wrapper: {
    backgroundColor: theme.palette.colors.darkOceanBlue2,
    borderRadius: 10,
    color: theme.palette.common.white,
    fontSize: 11,
    fontWeight: theme.typography.fontWeightBold,
    height: 20,
    padding: '3px 8px',
  },
}));

export const CommunityUnreadMessagesCount = () => {
  const classNames = useStyles();
  const { unreadPostsCount } = useLoadAllUnreadPosts();
  if (!unreadPostsCount || unreadPostsCount == 0) {
    return null;
  }

  return <Typography className={classNames.wrapper}>{unreadPostsCount}</Typography>;
};
