import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Card as CardUI, CardHeader as CardHeaderUI } from 'components/UI/Card';
// import * as purchaseService from 'services/purchase.service';
import { useAccount, useHttp, useRouter, useClientPreview } from 'hooks';
import { UserRoles, PaymentStatus } from 'helpers/constants';
import useContribution from '../../hooks/useContribution';
import {
  determineColorToUse,
  determineDarkThemedColorToUse,
  getOneToOneSelfBookClient,
} from 'services/contributions.service';
import { PAYMENT_OPTIONS } from '../../../../constants';
import { TemplateType } from 'helpers/constants/templateType';
import {
  Card,
  CardContent,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { useStyles } from './hooks/useStyles';
import { lighten } from 'polished';
import { lightOrDark } from 'utils/utils';
import { getThemedColors } from 'services/contributions.service';
import FreeSingleView from './FreeSingleView';
import FreePackageView from './FreePackageView';

const CardHeaderTab = styled.div`
  color: ${({ color }) => color};
  background-color: ${({ clicked, colorToUse }) => (clicked ? colorToUse?.PrimaryColorCode : '')};
  border-radius: 10px 10px 0px 0px;
  font-family: 'Brandon Text';
  font-style: normal;
  font-size: 17px;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 10px;
  font-weight: 600;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

const CardBody = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const HorizontalLine = styled.hr`
  // margin-top: 1rem;
  // border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
`;

const CustomPurchaseBlockOneToOneClientPreview = () => {
  const contribution = useSelector(s => s.contributions.activeContribution);
  let colorToUse = determineColorToUse(contribution);
  const isDarkThemeEnabled = determineDarkThemedColorToUse(contribution);
  let textColor =
    colorToUse?.TextColorCode === 'Auto'
      ? lightOrDark(colorToUse?.PrimaryColorCode)
      : colorToUse?.TextColorCode === '#000000'
      ? '#000000'
      : '#FFFFFF';
  const {
    clientPackages,
    customToS,
    isPurchased,
    paymentInfo: { paymentOptions },
    enrollment,
    serviceProviderName,
    type,
    defaultCurrency,
    defaultSymbol,
    availabilityTimes,
    title,
    id,
    customTitleForMeetYourCoach,
    isCouponAvailable,
  } = contribution;

  const { clientPreviewMode } = useClientPreview();
  const activeTemplate = contribution?.activeTemplate || TemplateType.TemplateThree;
  const { user, currentRole } = useAccount();
  const { purchaseStatus } = useContribution();

  const onlyFreeSingleSession = paymentOptions?.length === 1 && paymentOptions?.includes(PAYMENT_OPTIONS.FREE);
  const onlyFreeSessionPackage =
    paymentOptions?.length === 1 && paymentOptions?.includes(PAYMENT_OPTIONS.FREESESSIONPACKAGE);
  const onlyFreeSingleAndFreeSessionPackage =
    paymentOptions?.length === 2 &&
    paymentOptions?.includes(PAYMENT_OPTIONS.FREE) &&
    paymentOptions?.includes(PAYMENT_OPTIONS.FREESESSIONPACKAGE);
  const theme = useTheme();
  const downSm = useMediaQuery(theme.breakpoints.down('sm'));
  const { newThemedCardColor } = getThemedColors(contribution);
  const styledProps = {
    cardBackgroundColor: newThemedCardColor,
  };
  const classNames = useStyles();
  const [isFreePackageFailed, setIsFreePackagePuchased] = useState(false);
  const [perSessionStyled, setperSessionStyled] = useState(false);
  const [sessionPackageStyled, setsessionPackageStyled] = useState(false);

  useEffect(() => {
    if (onlyFreeSingleAndFreeSessionPackage) {
      setperSessionStyled(false);
      setsessionPackageStyled(true);
    } else if (onlyFreeSessionPackage) {
      setsessionPackageStyled(true);
    } else if (onlyFreeSingleSession) {
      setperSessionStyled(true);
    }
  }, [onlyFreeSingleSession, onlyFreeSessionPackage, onlyFreeSingleAndFreeSessionPackage]);

  return (
    <Grid className={classNames.wrapper} container>
      <Grid item md={12} xs={12}>
        <CardUI
          style={{ backgroundColor: isDarkThemeEnabled ? 'rgba(0,0,0,0.87)' : 'white' }}
          className={classNames.customSectionWrapper}
        >
          <CardHeaderUI
            CardBgCol="#e9f0f7"
            style={{
              backgroundColor: lighten(0.18, colorToUse?.PrimaryColorCode),
              borderRadius: '10px 10px 0px 0px',
              padding: '0px',
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            {paymentOptions.includes(PAYMENT_OPTIONS.FREE) && (
              <CardHeaderTab
                colorToUse={colorToUse}
                color={textColor}
                clicked={perSessionStyled}
                className={
                  perSessionStyled && activeTemplate == TemplateType.TemplateOne
                    ? 'tabsStyle'
                    : perSessionStyled && activeTemplate == TemplateType.TemplateTwo
                    ? 'tabsStyleTem2'
                    : ''
                }
                onClick={() => {
                  setperSessionStyled(true);
                  setsessionPackageStyled(false);
                }}
                size={downSm ? 'medium' : 'large'}
                variant="text"
              >
                Free Session
              </CardHeaderTab>
            )}
            {paymentOptions.includes(PAYMENT_OPTIONS.FREESESSIONPACKAGE) && (
              <CardHeaderTab
                colorToUse={colorToUse}
                color={textColor}
                clicked={sessionPackageStyled}
                className={
                  sessionPackageStyled && activeTemplate == TemplateType.TemplateOne
                    ? 'tabsStyle'
                    : sessionPackageStyled && activeTemplate == TemplateType.TemplateTwo
                    ? 'tabsStyleTem2'
                    : ''
                }
                onClick={() => {
                  setperSessionStyled(false);
                  setsessionPackageStyled(true);
                }}
                size={downSm ? 'medium' : 'large'}
                variant="text"
              >
                Free Package
              </CardHeaderTab>
            )}
          </CardHeaderUI>
          <CardBody>
            {perSessionStyled && <FreeSingleView />}
            {sessionPackageStyled && <FreePackageView contribution={contribution} />}
          </CardBody>
        </CardUI>
      </Grid>
    </Grid>
  );
};

export default CustomPurchaseBlockOneToOneClientPreview;
