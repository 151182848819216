import Modal from 'components/UI/Modal';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ShareIcon from '@material-ui/icons/Share';
import { Tooltip } from '@material-ui/core';
import CopyToClipboardIcon from 'assets/notes-content-copy.svg';
import './NotesModal.scss';
import InputEmailsComponent from 'components/UI/InputEmailsComponent';
import Loader from 'components/UI/Loader';
import useContribution from 'pages/ContributionView/hooks/useContribution';
import { sendClientNotesToEmail } from 'services/clients.service';
import NotesEditor from './NotesEditor';
import { useSelector } from 'react-redux';

const CHARACTER_LIMIT = 100000;

const NotesModal = ({
  editorData,
  showNotes,
  loading,
  setShowNotes,
  setNotesId,
  onModalClose,
  setEditorDataUpdated,
  onSave,
  applyTheming,
  setLoading,
  isNoteExist,
}) => {
  const [editor, setEditor] = useState();
  const [showEmailPopup, setShowEmailPopup] = useState(false);
  const [emails, setEmails] = useState([]);
  const [emailError, setEmailError] = useState({});
  const [showEmailSentPopup, setShowEmailSentPopup] = useState(false);
  const [showCopied, setShowCopied] = useState(false);
  const contribution = useContribution();
  const [characterCount, setCharacterCount] = useState(0);
  const [loadingEditor, setLoadingEditor] = useState(true);
  const activeContribution = useSelector(state => state.contributions?.activeContribution);
  const onLoaded = () => {
    setLoadingEditor(false);
  };

  const handleCopyClick = () => {
    editor.execCommand('selectAll');
    setTimeout(() => editor.execCommand('copy'), 100);
    setTimeout(() => {
      const selection = editor.getSelection();
      if (selection) {
        selection.removeAllRanges();
      }
      setShowCopied(true);
    }, 200);
  };

  useEffect(() => {
    setEmails([]);
    setEmailError('');
    setShowEmailPopup(false);
    setShowEmailSentPopup(false);
  }, [showNotes]);

  const handleEmailClick = () => {
    if (emails.length === 0) {
      return null;
    }
    setLoading(true);
    sendClientNotesToEmail({ note: editorData, emails, contributionId: activeContribution?.id })
      .then(() => {
        setLoading(false);
        setShowEmailPopup(false);
        setShowEmailSentPopup(true);
      })
      .finally(() => {
        setLoading(false);
        setShowEmailPopup(false);
      });
    return null;
  };

  return (
    <>
      <Modal
        className={`rich-text-notes-editor ${loadingEditor ? 'loading-editor' : ''}`}
        submitTitle={isNoteExist ? 'Update' : 'Save'}
        cancelTitle={isNoteExist ? 'Close' : 'Exit'}
        disableSubmitClick={characterCount > CHARACTER_LIMIT}
        title="Add a Note"
        isOpen={showNotes}
        onCancel={() => {
          if (setNotesId) setNotesId(null);
          if (onModalClose) onModalClose();
          if (setShowNotes) setShowNotes(false);
        }}
        onSubmit={onSave}
        applyTheming={applyTheming}
      >
        <NotesEditor
          editorData={editorData}
          setEditorDataUpdated={setEditorDataUpdated}
          setEditor={setEditor}
          characterLimit={CHARACTER_LIMIT}
          setCharacterCount={setCharacterCount}
          characterCount={characterCount}
          onLoaded={onLoaded}
        />
        <div className="video-chat-notes-panel__statusBar">
          <p style={{ color: characterCount > CHARACTER_LIMIT ? 'red' : '', margin: 'unset' }}>
            {characterCount}/{CHARACTER_LIMIT}
          </p>
        </div>
        <div className="copy-and-share-container">
          <div
            className="share-via-email"
            tabIndex="0"
            role="button"
            onClick={() => setShowEmailPopup(true)}
            onKeyPress={() => setShowEmailPopup(true)}
          >
            <ShareIcon />
            <span>Share via Email</span>
          </div>
          <Tooltip
            className="cursor-pointer"
            title="Copied to clipboard"
            open={showCopied}
            onClose={() => setShowCopied(false)}
            arrow
          >
            <div
              className="copy-to-clipboard"
              tabIndex="0"
              role="button"
              onClick={handleCopyClick}
              onKeyPress={handleCopyClick}
            >
              <img alt="" src={CopyToClipboardIcon} />
              <span>Copy to Clipboard</span>
            </div>
          </Tooltip>
        </div>
      </Modal>
      {(loading || (showNotes && loadingEditor)) && <Loader />}
      <Modal
        className="notes-email-popup"
        isOpen={showEmailPopup}
        title="Share via Email"
        submitTitle="Send"
        hiddenCancel
        onCancel={() => setShowEmailPopup(false)}
        onSubmit={handleEmailClick}
        contribution={contribution}
        applyTheming
      >
        <InputEmailsComponent
          emails={emails}
          handleSetEmails={setEmails}
          emailsError={emailError}
          setEmailsError={setEmailError}
        />
        <p style={{ fontSize: '12px' }}>Separate emails with a comma.</p>
      </Modal>
      <Modal
        className="notes-email-popup"
        isOpen={showEmailSentPopup}
        title="Note Sent"
        submitTitle="Ok"
        hiddenCancel
        onCancel={() => setShowEmailSentPopup(false)}
        onSubmit={() => setShowEmailSentPopup(false)}
        contribution={contribution}
        applyTheming
      >
        <span>Notes sent to selected emails.</span>
      </Modal>
    </>
  );
};
NotesModal.propTypes = {
  editorData: PropTypes.string.isRequired,
  setEditorDataUpdated: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  setNotesId: PropTypes.func.isRequired,
  showNotes: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  setShowNotes: PropTypes.func.isRequired,
  onModalClose: PropTypes.func.isRequired,
  applyTheming: PropTypes.bool.isRequired,
  setLoading: PropTypes.func.isRequired,
  isNoteExist: PropTypes.bool.isRequired,
};

export default NotesModal;
