import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Avatar from '@material-ui/core/Avatar'
import MicIcon from '@material-ui/icons/Mic'
import MicOffIcon from '@material-ui/icons/MicOff'
import VideocamIcon from '@material-ui/icons/Videocam'
import VideocamOffIcon from '@material-ui/icons/VideocamOff'
import VolumeDownIcon from '@material-ui/icons/VolumeDown'
import VolumeUpIcon from '@material-ui/icons/VolumeUp'
import VolumeOffIcon from '@material-ui/icons/VolumeOff'

import VolumeSlider from './VolumeSlider'

import './Participant.scss'

const Participant = ({
  className,
  identity,
  additionalInfo,
  isAudioEnabled,
  audioVolume,
  onAudioVolumeChange,
  isVideoEnabled,
  dominant,
}) => {
  const initials = useMemo(
    () =>
      identity
        .split(' ')
        .map((n) => n[0])
        .join('')
        .toUpperCase(),
    [identity]
  )
  const AudioVolumeIcon = audioVolume >= 0.5 ? VolumeUpIcon : audioVolume > 0 ? VolumeDownIcon : VolumeOffIcon
  const MicrophoneIcon = isAudioEnabled ? MicIcon : MicOffIcon
  const CameraIcon = isVideoEnabled ? VideocamIcon : VideocamOffIcon

  return (
    <div className={classNames(className, 'video-chat-side-panel-participant')}>
      <div className="video-chat-side-panel-participant-info">
        <Avatar>{initials}</Avatar>
        <p
          className="video-chat-side-panel-participant-info__identity"
          title={`${identity}${additionalInfo ? ` ${additionalInfo}` : ''}`}
        >
          {identity} {additionalInfo}
        </p>
      </div>
      <div className="video-chat-side-panel-participant-toolbar">
        {onAudioVolumeChange && (
          <VolumeSlider
            className={classNames('video-chat-side-panel-participant-toolbar__audio-volume', {
              'video-chat-side-panel-participant-toolbar__audio-volume--disabled': audioVolume === 0,
            })}
            volume={audioVolume}
            onVolumeChange={onAudioVolumeChange}
          >
            <AudioVolumeIcon color="inherit" />
          </VolumeSlider>
        )}
        <div
          className={classNames('video-chat-side-panel-participant-toolbar__microphone', {
            'video-chat-side-panel-participant-toolbar__microphone--disabled': !isAudioEnabled,
            'video-chat-side-panel-participant-toolbar__microphone--dominant': dominant,
          })}
        >
          <MicrophoneIcon color="inherit" />
        </div>
        <div
          className={classNames('video-chat-side-panel-participant-toolbar__camera', {
            'video-chat-side-panel-participant-toolbar__camera--disabled': !isVideoEnabled,
          })}
        >
          <CameraIcon color="inherit" />
        </div>
      </div>
    </div>
  )
}

Participant.propTypes = {
  className: PropTypes.string.isRequired,
  identity: PropTypes.string.isRequired,
  additionalInfo: PropTypes.string,
  isAudioEnabled: PropTypes.bool.isRequired,
  audioVolume: PropTypes.number.isRequired,
  onAudioVolumeChange: PropTypes.func,
  isVideoEnabled: PropTypes.bool.isRequired,
  dominant: PropTypes.bool.isRequired,
}

Participant.defaultProps = {
  additionalInfo: null,
  onAudioVolumeChange: null,
}

export default Participant
