import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import useContribution from 'pages/ContributionView/hooks/useContribution';
import { getThemedColors } from 'services/contributions.service';

import avatarPlaceholder from 'assets/placeholder.jpg';

import './TestimonialAvatar.scss';

export const TestimonialAvatar = ({ avatarUrl, handleUpload }) => {
  const contribution = useContribution();
  const { themedColor } = getThemedColors(contribution);

  return (
    <div>
      <p className="profile-avatar-text" style={{ color: themedColor }}>
        Add Photo
      </p>
      <div
        style={{
          backgroundImage: `url(${avatarUrl || avatarPlaceholder})`,
        }}
        className={classnames('mr-4 mb-4 br-50 profile-avatar')}
      >
        <input
          type="file"
          accept="image/*"
          className="w-100 h-100 cursor-pointer profile-input-avatar"
          onChange={handleUpload}
        />
      </div>
    </div>
  );
};

TestimonialAvatar.propTypes = {
  avatarUrl: PropTypes.string,
  handleUpload: PropTypes.func.isRequired,
};

TestimonialAvatar.defaultProps = {
  avatarUrl: avatarPlaceholder,
};
