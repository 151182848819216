import { getCurrentRoomStatus } from 'services/video.service';
import useVideoChat from './useVideoChat';

export const RECORDING_STATUSES = {
  stopped: 'Stopped',
  started: 'Started',
};

export const useVideoChatRecording = () => {
  const { contributionId, roomId, recordingStatus } = useVideoChat();

  return {
    RECORDING_STATUSES,
    contributionId,
    roomId: roomId || window.twilioRoom?.sid,
    recordingStatus: recordingStatus === RECORDING_STATUSES.started,
    getCurrentRoomStatus,
  };
};
