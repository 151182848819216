import React from 'react';
import Grid from '@material-ui/core/Grid';
import { useHttp, useAccount, useVideoChatActions } from 'hooks';
import { UserRoles, ContributionType } from 'helpers/constants';
import Post from './Post/Post';
import map from 'lodash/map';

const PostList = ({ items, pods = [], getActiveContribution, searchedWord, reloadPostsOnEdit, onHashtagClick }) => {
  const { user, currentRole } = useAccount();
  const isCoach = currentRole === UserRoles.cohealer;
  const activeItems = items
    ?.filter(item => !item.isDraft)
    ?.filter(item => !item.isScheduled)
    ?.sort((x, y) => (x.isScheduled === y.isScheduled ? 0 : x.isScheduled ? -1 : 1));
  const activeItemsCoach = items
    ?.filter(item => !item.isDraft)
    ?.sort((x, y) => (x.isScheduled === y.isScheduled ? 0 : x.isScheduled ? -1 : 1));

  return isCoach ? (
    <Grid container style={{ justifyContent: 'center' }}>
      {map(activeItemsCoach, item => {
        return (
          <Post
            modal={false}
            post={item}
            key={item.id}
            pods={pods}
            getActiveContribution={getActiveContribution}
            searchedWord={searchedWord}
            reloadPostsOnEdit={reloadPostsOnEdit}
            onHashtagClick={onHashtagClick}
          />
        );
      })}
    </Grid>
  ) : (
    <Grid container style={{ justifyContent: 'center' }}>
      {map(activeItems, item => {
        return (
          <Post
            modal={false}
            post={item}
            key={item.id}
            pods={pods}
            getActiveContribution={getActiveContribution}
            searchedWord={searchedWord}
            reloadPostsOnEdit={reloadPostsOnEdit}
            onHashtagClick={onHashtagClick}
          />
        );
      })}
    </Grid>
  );
};

export default PostList;
