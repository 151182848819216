import React from 'react';
import classes from './ErrorCard.module.scss';
import Card from '../Card/Card';
import Button from 'components/FormUI/Button';
import { EMAIL_SUBSCRIPTION_STATUS } from 'pages/Unsubscribe/Unsubscribe';
const ErrorCard = ({ clientEmail, onRetry, lastStatus }) => {
  const btnTitle = lastStatus
    ? lastStatus === EMAIL_SUBSCRIPTION_STATUS.subscribed
      ? 'Retry Unsubscribe'
      : 'Retry Resubscribe'
    : 'Retry';
  return (
    <Card header={clientEmail}>
      <div className={classes.body}>
        <div className={classes.error}>Something went wrong</div>
        <Button className={classes.retry_btn} autoWidth onClick={onRetry}>
          {btnTitle}
        </Button>
      </div>
    </Card>
  );
};
export default ErrorCard;
