import React, { useCallback, useEffect, useState } from 'react';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@material-ui/core';
import Button from 'components/FormUI/Button';
import PropTypes from 'prop-types';
import axiosInstance from 'utils/axiosInstance';
import { NOT_ALLOWED_DOMAINS } from 'utils/domainAndSubdomain';
import _ from 'lodash';
import { Formik, Field, Form, yupToFormErrors } from 'formik';
import useRouter from 'hooks/useRouter';
import * as Yup from 'yup';
import CommonErrorMessage from 'components/FormUI/CommonErrorMessage';
import { EN_MESSAGES } from 'constants.js';
import { useAccount, useHttp } from 'hooks/index';
import { useDispatch } from 'react-redux';
import { updateUser } from 'actions/update-user';
import Modal from 'components/UI/Modal';
const ChangeEmailModal = ({ isOpen, setIsOpen, currentDomain, setNewDomain, setSuccessPopUp }) => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState(null);
  const { request } = useHttp();
  const { user } = useAccount();
  const dispatch = useDispatch();
  const initialValues = {
    Password: '',
    Email: '',
  };

  const submitHandler = useCallback(values => {
    const { Password, Email } = values;
    if (Email != user.email) {
      request(`/User/UpdateEmailAddress`, 'PUT', {
        password: Password,
        newEmail: Email,
      })
        .then(res => {
          let u = {
            ...user,
            email: res.requestedEmailToUpdate,
          };
          dispatch(updateUser(u));
          setIsOpen(false);
          setSuccessPopUp(true);
        })
        .catch(err => {
          setApiError(err.response.data);
        });
    } else {
      setApiError('This email address is already being used. Please choose a different email address.');
    }
  }, []);

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="domain-name-dialog"
        classes={{
          paper: 'dialog-container',
        }}
      >
        <DialogTitle id="alert-dialog-title">Change Email Address</DialogTitle>
        <DialogContent>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={Yup.object().shape({
              Password: Yup.string()
                .strict(true)
                .trim('Please remove spaces after your password')
                .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/g, EN_MESSAGES.validation.password)
                // .required(<Translate id="validation.required" />),
                .required(EN_MESSAGES.validation.required),
              Email: Yup.string().email('Invalid Email').required(EN_MESSAGES.validation.required),
            })}
            onSubmit={submitHandler}
          >
            {({ values, errors, handleBlur, touched, handleChange }) => (
              <Form>
                <Box
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <span style={{ width: '100%' }}>
                    <TextField
                      className="input-field email-error"
                      id="outlined-basic"
                      margin="normal"
                      variant="outlined"
                      name="Email"
                      fullWidth
                      placeholder="Enter new email"
                      PostalCode={values.Email}
                      onChange={e => {
                        handleChange(e);
                      }}
                      onBlur={e => {
                        handleBlur(e);
                      }}
                      error={touched.Email && Boolean(errors.Email)}
                      helperText={touched.Email && errors.Email}
                    />
                    <TextField
                      className="input-field email-error"
                      id="outlined-basic"
                      margin="normal"
                      variant="outlined"
                      name="Password"
                      type="password"
                      fullWidth
                      placeholder="Confirm Password"
                      PostalCode={values.Password}
                      onChange={e => {
                        handleChange(e);
                      }}
                      onBlur={e => {
                        handleBlur(e);
                      }}
                      error={touched.Password && Boolean(errors.Password)}
                      helperText={touched.Password && errors.Password}
                    />
                  </span>
                  {apiError && <CommonErrorMessage align="left" message={apiError} />}
                </Box>
                <DialogActions style={{ padding: '0px', marginTop: '10px' }}>
                  <Button onClick={() => setIsOpen(false)}>Close</Button>
                  <Button
                    style={{ marginRight: '0px' }}
                    onClick={() => {
                      // setNewDomain(domain);
                    }}
                    disabled={loading}
                  >
                    Confirm
                  </Button>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
      <Dialog
        open={showConfirmation}
        onClose={() => setShowConfirmation(!showConfirmation)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="domain-name-dialog"
        classes={{
          paper: 'dialog-container',
        }}
      >
        <DialogContent>
          <p style={{ color: '#47627B' }}>Are you sure you want to change the domain? </p>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowConfirmation(!showConfirmation)}>No</Button>
          <Button onClick={() => {}} disabled={loading}>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

ChangeEmailModal.propTypes = {
  currentDomain: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  setNewDomain: PropTypes.func.isRequired,
};

export default ChangeEmailModal;
