import React from 'react'
import { Tooltip } from '@material-ui/core'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Wrapper = styled.div`
  display: inline-block;
`

export const TooltipWrapper = ({ children, tooltipText, isShow }) => {
  return (
    <>
      {isShow ? (
        <Tooltip className="cursor-pointer" title={tooltipText} arrow enterTouchDelay={0} leaveTouchDelay={4000}>
          <Wrapper>{children}</Wrapper>
        </Tooltip>
      ) : (
        children
      )}
    </>
  )
}

TooltipWrapper.propTypes = {
  tooltipText: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  isShow: PropTypes.bool,
}

TooltipWrapper.defaultProps = {
  isShow: true,
}
