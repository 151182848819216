import curry from 'lodash/curry';

const shape = curry((obj, data) => {
  return Object.entries(obj).reduce(
    (res, [key, to]) => ({
      ...res,
      [to]: data[key],
    }),
    {},
  );
});

export { shape };
