import React, { useCallback, useEffect, useRef, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import CoachImage from 'assets/chatlogo.png';
import Grid from '@material-ui/core/Grid';
import { useMediaQuery, useTheme } from '@material-ui/core';
import Modal from 'components/UI/Modal';
import { Form, Formik } from 'formik';
import moment from 'moment';
import * as contributionActions from 'actions/contributions';
import * as Yup from 'yup';
import { parseDate } from 'utils/datesAndMoney';
import * as userActions from 'actions/user';
import * as timeZoneActions from 'actions/timeZone';
import * as countryActions from 'actions/country';
import './profilepage.scss';
import 'react-color-palette/lib/css/styles.css';
import PersonalInformation from './components/sections/PersonalInformation';
import BusinessInformation from './components/sections/BusinessInformation';
import Contributions from './components/sections/Contributions';
import OtherLinks from './components/sections/OtherLinks';
import CommonErrorMessage from 'components/FormUI/CommonErrorMessage';
import Button from 'components/FormUI/Button';
import BrandingElement from './components/sections/BrandingElement';
import Settings from './components/sections/Settings';
import GeneralSettings from './components/sections/GeneralSettings';
import AutoSave from '../../components/FormikAutoSave/FormikAutoSave';
import Joyride, {
  CallBackProps,
  STATUS,
  Step,
  EVENTS,
  StoreHelpers,
  BeaconRenderProps,
  TooltipRenderProps,
} from 'react-joyride';
import { CONTRIBUTION_COLORS } from 'constants';
import TemplateOne from 'assets/congratulations.png';
import styled, { css } from 'styled-components';
import ChatAndGetStartedButtons from 'pages/Dashboard/components/CohealerDashboard/LiveChat/LiveChatContainer';
import { useLocation, useParams } from 'react-router-dom';
import useAccount from 'hooks/useAccount';
import { updateUser } from 'actions/update-user';
import { UpdateUserProgressBarData } from 'services/user.service';
import Loader from 'components/UI/Loader';
import useRouter from 'hooks/useRouter';
import { addhttp, isValidLink } from 'utils/utils';
import { getUserProfile } from 'services/user.service';
import { useHistory } from 'react-router-dom';
import { getLatestApproved, getUpcomingCreated } from 'services/contributions.service';
const StyledImg = styled.div`
  background-image: url(${({ previewImg }) => previewImg});
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  display: inline-block;
  border-radius: 40px;
  align-items: center;
  z-index: 1;
`;

const StyledImgCongrats = styled.div`
  background-image: url(${({ previewImg }) => previewImg});
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100px;
  height: 100px;
  display: inline-block;
  align-items: center;
  z-index: 1;
`;

const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const MIN_STRING_LENGTH = 3;
const MAX_STRING_LENGTH = 100;
export const BIO_MAX_LENGTH = 1000;
const DEFAULT_CONTRIBUTION_LABEL = 'Let’s work together';
const DEFAULT_LINKS_LABEL = 'Additional Links and Resources';
function ProfilePage({
  user,
  editProfile,
  loading,
  error,
  timeZoneLoading,
  timeZones,
  fetchTimeZones,
  countryLoading,
  countries,
  fetchCountries,
}) {
  const { state } = useLocation();
  console.log('user ==>', user);
  console.log('timeZones ==>', timeZones);

  const history = useHistory();
  const [contibId, setContibId] = useState(null);
  const showCompletedGetStart = useSelector(state => state.contributions.showCompletedGetStart);
  const initialStep =
    state?.from === 'Manage Your Website Information' || showCompletedGetStart === 'refreshWebsite'
      ? 3
      : state?.from === 'profile' || showCompletedGetStart === 'profileRefresh'
      ? 1
      : 1;
  const refreshProfile = showCompletedGetStart === 'profileRefresh' ? 1 : null;
  const refreshWebsite = showCompletedGetStart === 'refreshWebsite' ? 3 : null;
  const [stepIndex, setStepIndex] = useState(initialStep ? initialStep : 1);
  const [isValidLinks, setIsValidLinks] = useState(true);
  const formRef = useRef(null);
  const [run, setRun] = useState(
    (initialStep === 1 && !user?.userProgressbarData?.IsProfileUploaded) ||
      (initialStep === 3 && !user?.userProgressbarData?.IsWebsiteAdded) ||
      (user?.userProgressbarData?.IsProfileUploaded === true && refreshProfile) ||
      (user?.userProgressbarData?.IsWebsiteAdded === true && refreshWebsite),
  );
  const [showModal, setShowModal] = useState(false);
  const { query } = useRouter();

  const togglePopup = () => {
    setShowModal(!showModal);
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(993));
  let joyRideHelpers;

  useEffect(() => {
    if (query.from) {
      history.push('/account/profile', { from: query.from });
    }
  }, [query]);

  useEffect(() => {
    userActions.getProfile(user.id, dispatch);
    getLatestApproved().then(res => {
      setContibId(res);
    });
  }, []);
  const [steps, setSteps] = useState([
    {
      spotlightClicks: true,
      disableBeacon: true,
      hideFooter: true,
      content: (
        <div className="step0">
          <div
            className="image"
            style={{
              height: '70px',
              width: '70px',
              backgroundColor: 'white',
              position: 'absolute',
              borderRadius: '100px',
              top: -45,
              left: 150,
              borderColor: '#215C73',
              borderWidth: '5px',
            }}
          >
            <StyledImg previewImg={CoachImage} />
          </div>
          <div
            style={{
              fontSize: '18px',
              color: '#215C73',
              display: 'flex',
              alignSelf: 'flex-start',
              fontWeight: 850,
              fontFamily: 'Avenir',
            }}
          >
            Add Your Profile Photo
          </div>
          <div
            style={{
              color: 'black',
              fontSize: '16px',
              marginTop: '13px',
              fontWeight: 350,
              fontFamily: 'Avenir',
              textAlign: 'left',
            }}
          >
            This is your main profile photo that your clients will see on your landing pages, community, chat & more
            throughout the platform!
          </div>
          <div className="buttons" style={{ flexDirection: 'row', display: 'flex' }}>
            {refreshProfile === null && refreshWebsite === null && (
              <Button
                className="btn-left"
                autoWidth
                backgroundColor={'white'}
                invert
                style={{
                  marginTop: '40px',
                  paddingTop: '10px',
                  paddingBottom: '10px',
                  width: '70%',
                  borderColor: '#A58B62',
                  borderWidth: '1px',
                  borderRadius: '5px',
                  color: '#A58B62',
                }}
                onClick={() => {
                  updateUserF();
                  // setRun(false);
                }}
              >
                Mark as Complete
              </Button>
            )}

            <Button
              className="btn-right"
              autoWidth
              invert
              style={{
                marginTop: '40px',
                paddingTop: '10px',
                paddingBottom: '10px',
                width: '30%',
                borderColor: '#215C73',
                borderWidth: '1px',
                borderRadius: '5px',
                color: '#215C73',
                marginLeft: '10px',
              }}
              onClick={() => {
                joyRideHelpers.skip();
                // setRun(false);
              }}
            >
              Next
            </Button>
          </div>
        </div>
      ),
      placement: 'left',
      target: '#my-first-step',
    },
    {
      spotlightClicks: true,
      hideFooter: true,
      disableBeacon: true,
      content: (
        <div className="step1" style={{ display: 'flex', flexDirection: 'column' }}>
          <div
            className="image"
            style={{
              height: '70px',
              width: '70px',
              backgroundColor: 'blue',
              position: 'absolute',
              borderRadius: '100px',
              top: -50,
              left: 20,
              borderWidth: '5px',
            }}
          >
            {' '}
            <StyledImg previewImg={CoachImage} />{' '}
          </div>
          <div
            style={{
              fontSize: '18px',
              color: '#215C73',
              display: 'flex',
              alignSelf: 'flex-start',
              fontWeight: 850,
              fontFamily: 'Avenir',
            }}
          >
            Add Your Profile Photo
          </div>
          <div
            style={{
              color: 'black',
              fontSize: '16px',
              marginTop: '13px',
              fontWeight: 350,
              fontFamily: 'Avenir',
              textAlign: 'left',
            }}
          >
            This is your main profile photo that your clients will see on your landing pages, community, chat & more
            throughout the platform!
          </div>
          <div className="buttons" style={{ flexDirection: 'row', display: 'flex' }}>
            {refreshProfile === null && refreshWebsite === null && (
              <div>
                {/* <Button
                  className="btn-left"
                  autoWidth
                  backgroundColor={'white'}
                  invert
                  style={{
                    marginTop: '40px',
                    padding: '10px',
                    paddingBottom: '10px !important',
                    width: '70%',
                    borderColor: '#A58B62',
                    borderWidth: '1px',
                    borderRadius: '5px',
                    color: '#A58B62',
                  }}
                  onClick={() => {
                    updateUserF();
                  }}
                >
                  Mark as Complete
                </Button> */}
                <Button
                  className="btn-right"
                  autoWidth
                  invert
                  style={{
                    marginTop: '15px',
                    paddingTop: '10px',
                    paddingBottom: '10px',
                    width: '100%',
                    borderColor: '#215C73',
                    borderWidth: '1px',
                    borderRadius: '5px',
                    color: '#215C73',
                    // marginLeft: '10px',
                  }}
                  onClick={() => {
                    // updateUserF('', true);
                    joyRideHelpers.next();
                  }}
                >
                  Next
                </Button>
              </div>
            )}
          </div>
        </div>
      ),
      placement: 'right',
      target: '#profilePicture',
    },
    {
      spotlightClicks: true,
      hideFooter: true,
      disableBeacon: true,
      content: (
        <div className="step2">
          <div
            className="image"
            style={{
              height: '70px',
              width: '70px',
              backgroundColor: 'white',
              position: 'absolute',
              borderRadius: '100px',
              top: -45,
              left: 20,
              borderWidth: '5px',
            }}
          >
            {' '}
            <StyledImg previewImg={CoachImage} />
          </div>
          <div
            style={{
              fontSize: '18px',
              color: '#215C73',
              display: 'flex',
              alignSelf: 'flex-start',
              fontWeight: 850,
              fontFamily: 'Avenir',
            }}
          >
            Customize Your Branding
          </div>
          <div
            style={{
              color: 'black',
              fontSize: '16px',
              marginTop: '13px',
              fontWeight: 350,
              fontFamily: 'Avenir',
              textAlign: 'left',
            }}
          >
            Your logo and branding colors will automatically appear on your services website and can also be applied or
            customized on your landing pages.
          </div>
          <div className="buttons" style={{ flexDirection: 'row', display: 'flex' }}>
            {refreshProfile === null && refreshWebsite === null && (
              <ButtonsWrapper>
                <Button
                  className="btn-left"
                  autoWidth
                  backgroundColor={'white'}
                  invert
                  style={{
                    marginTop: '40px',
                    paddingTop: '10px',
                    paddingBottom: '10px',
                    width: '70%',
                    borderColor: '#A58B62',
                    borderWidth: '1px',
                    borderRadius: '5px',
                    color: '#A58B62',
                  }}
                  onClick={() => {
                    updateUserF();
                  }}
                >
                  Mark as Complete
                </Button>
                <Button
                  className="btn-left"
                  autoWidth
                  backgroundColor={'white'}
                  invert
                  style={{
                    marginTop: '10px',
                    paddingTop: '10px',
                    paddingBottom: '10px',
                    width: '70%',
                    borderColor: '  #215C73',
                    borderWidth: '1px',
                    borderRadius: '5px',
                    color: '#215C73',
                  }}
                  onClick={() => {
                    updateUserF('', true);
                  }}
                >
                  Complete & Next
                </Button>
              </ButtonsWrapper>
            )}
          </div>
        </div>
      ),
      placement: 'left',
      target: '#brandingColumn',
    },
    {
      spotlightClicks: true,
      hideFooter: true,
      disableBeacon: true,
      content: (
        <div className="step4">
          <div
            className="image"
            style={{
              height: '70px',
              width: '70px',
              backgroundColor: 'white',
              position: 'absolute',
              borderRadius: '100px',
              top: -45,
              left: 150,
              borderColor: '#215C73',
              borderWidth: '5px',
            }}
          >
            <StyledImg previewImg={CoachImage} />
          </div>
          <div
            style={{
              fontSize: '18px',
              color: '#215C73',
              display: 'flex',
              alignSelf: 'flex-start',
              fontWeight: 850,
              fontFamily: 'Avenir',
            }}
          >
            Services Website
          </div>
          <div
            style={{
              color: 'black',
              fontSize: '16px',
              marginTop: '13px',
              fontWeight: 350,
              fontFamily: 'Avenir',
              textAlign: 'left',
            }}
          >
            Your tagline, subtagline, banners and services, will be shown on your service website.
          </div>
          <div
            className="buttons"
            style={{ width: '100%', flexDirection: 'column', display: 'flex', alignItems: 'flex-start' }}
          >
            {user?.userProgressbarData?.IsWebsiteAdded === false && (
              <>
                <Button
                  className="btn-left"
                  autoWidth
                  backgroundColor={'white'}
                  invert
                  style={{
                    marginTop: '40px',
                    paddingTop: '10px',
                    paddingBottom: '10px',
                    width: '70%',
                    borderColor: '#A58B62',
                    borderWidth: '1px',
                    borderRadius: '5px',
                    color: '#A58B62',
                  }}
                  onClick={() => {
                    updateUserF('bussinessInfo');
                    // setRun(false);
                  }}
                >
                  Mark as Complete
                </Button>
                <Button
                  className="btn-left"
                  autoWidth
                  backgroundColor={'white'}
                  invert
                  style={{
                    marginTop: '10px',
                    paddingTop: '10px',
                    paddingBottom: '10px',
                    width: '70%',
                    borderColor: '#215C73',
                    borderWidth: '1px',
                    borderRadius: '5px',
                    color: '#215C73',
                  }}
                  onClick={() => {
                    updateUserF('bussinessInfo', true);
                    // setRun(false);
                  }}
                >
                  Complete & Next
                </Button>
              </>
            )}

            {/* <Button
              className="btn-right"
              autoWidth
              invert
              style={{
                marginTop: '40px',
                paddingTop: '10px',
                paddingBottom: '10px',
                width: '30%',
                borderColor: '#215C73',
                borderWidth: '1px',
                borderRadius: '5px',
                color: '#215C73',
                marginLeft: '10px',
              }}
              onClick={() => {
                joyRideHelpers.skip();
                setRun(false);
              }}
            >
              Next
            </Button> */}
          </div>
        </div>
      ),
      placement: 'right',
      target: '#bussinessInfo',
    },
  ]);
  const updateUserF = async (from, gotToNextStep = false) => {
    let obj = {};
    if (from == 'bussinessInfo') {
      obj = {
        id: user.id,
        userProgressbarData: {
          ...user.userProgressbarData,
          IsWebsiteAdded: true,
        },
        progressBarPercentage: user.progressBarPercentage,
      };
    } else {
      obj = {
        id: user.id,
        userProgressbarData: {
          ...user.userProgressbarData,
          IsProfileUploaded: true,
        },
        progressBarPercentage: user.progressBarPercentage,
      };
    }

    await UpdateUserProgressBarData(obj).then(res => {
      let u = {
        ...user,
        userProgressbarData: { ...res.userProgressbarData },
        progressBarPercentage: res.progressBarPercentage,
      };
      dispatch(updateUser(u));
      if (res.progressBarPercentage == 100) {
        setShowModal(true);
      }
      if (gotToNextStep) {
        if (res.userProgressbarData?.IsPlanPurchased === false) {
          history.push('/account/payment', { from: 'verify' });
        } else if (res?.userProgressbarData?.IsBankAccountConnected === false) {
          history.push('/account/payment', { from: 'bankAccount' });
        } else if (res?.userProgressbarData?.IntegrationsDone === false) {
          history.push('/account/integrations', { from: 'bankAccount' });
        } else if (res?.userProgressbarData?.IsProfileUploaded === false) {
          history.push('/account/profile');
        } else if (res?.userProgressbarData?.FirstContributionDone === false) {
          dispatch(contributionActions.setPopUp(true));
          dispatch(contributionActions.setShowCompletedGetStart('create'));
          history.push('/dashboard');
        } else if (res?.userProgressbarData?.IsWebsiteAdded === false) {
          history.push('/account/profile', { from: 'Manage Your Website Information' });
        } else if (res?.userProgressbarData?.InviteLink === false) {
          contibId === null ? console.log('Helloo') : history.push(`contribution-view/${contibId}/about`);
        }
      }
    });
    if (gotToNextStep) {
      joyRideHelpers.next();
    } else {
      joyRideHelpers.skip();
    }
  };

  useEffect(() => {
    if (
      (refreshProfile && user?.userProgressbarData?.IsProfileUploaded === true) ||
      (refreshWebsite && user?.userProgressbarData?.IsWebsiteAdded === true)
    ) {
    } else if (
      user?.userProgressbarData?.IsProfileUploaded === true &&
      !!user?.userProgressbarData?.IsWebsiteAdded === true
    ) {
      setSteps([]);
    } else if (
      user?.userProgressbarData?.IsWebsiteAdded === false &&
      user?.userProgressbarData?.IsProfileUploaded === false
    ) {
      return;
    } else if (user?.userProgressbarData?.IsProfileUploaded === false) {
      setSteps(steps);
    } else if (user?.userProgressbarData?.IsWebsiteAdded === false) {
      setSteps(steps);
    }
  }, []);

  const handleJoyrideCallback = data => {
    const { status, type, index } = data;
    const nextStepIndex = index + 1;

    const finishedStatuses = ['finished', 'skipped'];
    if (finishedStatuses.includes(status)) {
      setRun(false);
    }
    if ([EVENTS.STEP_AFTER].includes(type)) {
      setStepIndex(nextStepIndex);
    }
    if (data.action === 'close') {
      setRun(false);
    }

    console.groupCollapsed(type);
    console.groupEnd();
  };
  const getHelpers = helpers => {
    joyRideHelpers = helpers;
  };

  const [profileLinkName, setProfileLinkName] = useState((user && user.profileLinkName) || '');

  const dispatch = useDispatch();
  useEffect(() => {
    userActions.getProfile(user.id, dispatch);
    const profilePageViewModel = {
      ...user?.profilePageViewModel,
      contributions: user?.profilePageViewModel?.contributions,
      UpdationAllowed: true,
    };
    formRef.current.setFieldValue('profilePageViewModel', profilePageViewModel);
  }, [user?.profilePageViewModel?.contributions]);

  const { parentDomain } = useRouter();

  const initialValues = {
    FirstName: user.firstName,
    LastName: user.lastName,
    BirthDate: parseDate(user.birthDate, 'YYYY-MM-DD'),
    BusinessName: user.businessName,
    BusinessType: user.businessType,
    CustomBusinessType: user.customBusinessType || '',
    Email: user.email,
    Address: user.address || '',
    Certification: user.certification,
    Occupation: user.occupation,
    AvatarUrl: user.avatarUrl || '',
    profileLinkName: user.profileLinkName,
    CustomerLabelPreference: user.customerLabelPreference || '',
    Bio: user.bio || '',
    profession: user?.profession || 'coach',
    TimeZoneId: user.timeZoneId || 'America/Los_Angeles',
    CountryId: user.countryId || timeZones?.find(t => t.countryName === user.timeZoneId)?.countryId || '',
    HasAgreedToTerms: true,
    brandingColors: {
      PrimaryColorCode: user.brandingColors.PrimaryColorCode || '#CDBA8F',
      AccentColorCode: user.brandingColors.AccentColorCode || '#116582',
      TertiaryColorCode: user.brandingColors.TertiaryColorCode || '#F6E8BO',
      TextColorCode: user.brandingColors.TextColorCode || '#FFFFFF',
    },
    contributionLabel:
      user?.profilePageViewModel &&
      !!user?.profilePageViewModel?.contributionLabel?.length &&
      user?.profilePageViewModel?.contributionLabel !== null
        ? user?.profilePageViewModel?.contributionLabel
        : DEFAULT_CONTRIBUTION_LABEL,
    additionalLinksLabel:
      user?.profilePageViewModel &&
      !!user?.profilePageViewModel?.additionalLinksLabel?.length &&
      user?.profilePageViewModel?.additionalLinksLabel !== null
        ? user?.profilePageViewModel?.additionalLinksLabel
        : DEFAULT_LINKS_LABEL,
    customLogo: user.customLogo || null,
    profilePageViewModel: {
      ...(user?.profilePageViewModel || {}),
      UpdationAllowed: true,
    },
    isProfileActive: user?.isProfileActive || false,
  };

  const editHandler = useCallback(
    values => {
      const { profilePageViewModel, ...rest } = values;
      if (
        values.additionalLinksLabel?.length <= 2 ||
        values.additionalLinksLabel?.length > 50 ||
        values.contributionLabel?.length <= 2 ||
        values.contributionLabel?.length > 50
      ) {
        return;
      }
      const userData = {
        ...rest,
        profilePageViewModel: {
          ...profilePageViewModel,
          additionalLinksLabel: values.additionalLinksLabel,
          contributionLabel: values.contributionLabel,
          userId: user.id,
        },
        id: user.id,
        accountId: user.accountId,
        BirthDate: moment.utc(values.BirthDate).format(),
        serviceAgreementType: user.serviceAgreementType,
        isBetaUser: user.isBetaUser,
        isStandardAccount: user.isStandardAccount,
        defaultPaymentMethod: user.defaultPaymentMethod,
      };
      if (userData.additionalLinksLabel || userData.contributionLabel) {
        delete userData.additionalLinksLabel;
        delete userData.contributionLabel;
      }
      if (!userData.CustomerLabelPreference) {
        delete userData.CustomerLabelPreference;
      }

      editProfile(user.id, userData);
      userActions.getProfile(user.id, dispatch);
    },
    [editProfile, user.accountId, user.avatarUrl, user.id],
  );

  useEffect(() => {
    userActions.getProfile(user.id, dispatch);
    const profilePageViewModel = {
      ...user?.profilePageViewModel,
      contributions: user?.profilePageViewModel?.contributions,
      UpdationAllowed: true,
    };
    formRef.current.setFieldValue('profilePageViewModel', profilePageViewModel);
  }, [user?.profilePageViewModel?.contributions]);

  useEffect(() => {
    if ((!timeZones || timeZones?.length === 0) && !timeZoneLoading) {
      fetchTimeZones();
    }
    if ((!countries || countries?.length === 0) && !countryLoading) {
      fetchCountries();
    }
  }, [timeZones, fetchTimeZones, countries, fetchCountries]);
  return (
    <Grid container className="profile-form">
      {loading && <Loader />}
      <Joyride
        // tooltipComponent={Tooltip}
        callback={handleJoyrideCallback}
        continuous={true}
        getHelpers={getHelpers}
        run={run}
        stepIndex={stepIndex}
        showProgress={false}
        showSkipButton={false}
        steps={steps}
        styles={{
          options: {
            primaryColor: '#CDBA8F',
            zIndex: 10000000,
          },
        }}
      />
      <Formik
        innerRef={formRef}
        initialValues={initialValues}
        enableReinitialize
        validationSchema={Yup.object().shape({
          FirstName: Yup.string().strict(true).min(MIN_STRING_LENGTH).max(MAX_STRING_LENGTH).required(),
          LastName: Yup.string().strict(true).min(MIN_STRING_LENGTH).max(MAX_STRING_LENGTH).required(),
          TimeZoneId: Yup.mixed().required(),
          CountryId: Yup.mixed().required(),
          profession: Yup.mixed().required(),
          Address: Yup.mixed(),
          Email: Yup.mixed().required(),
          contributionLabel: Yup.string()
            .strict(true)
            .min(2, 'Must be at least 2 characters')
            .max(50, 'Cannot exceed 50 characters')
            .required('This is a required field'),
          additionalLinksLabel: Yup.string()
            .strict(true)
            .min(2, 'Must be at least 2 characters')
            .max(50, 'Cannot exceed 50 characters')
            .required('This is a required field'),
          Bio: Yup.string().strict(true).max(BIO_MAX_LENGTH),
          profilePageViewModel: Yup.object().shape({
            customLinks: Yup.array().of(
              Yup.object().shape({
                uniqueName: Yup.string().required('Unique Name is required'),
                link: Yup.string()
                  .required('Link is required')
                  .test('isValid', 'Invalid Link', value => {
                    return isValidLink(value);
                  }),
              }),
            ),
          }),
        })}
        onSubmit={editHandler}
      >
        {({ setFieldValue, handleChange, values, errors, touched, setTouched, setErrors }) => {
          return (
            <>
              <Form>
                <Grid style={{ padding: '30px 15px 30px 25px' }} container className="coach-profile">
                  <Grid item className="profile-info" xl={9} lg={8} md={7} sm={12} xs={12}>
                    <PersonalInformation
                      user={user}
                      timeZones={timeZones}
                      countries={countries}
                      setFieldValues={setFieldValue}
                      handleChange={handleChange}
                      values={values}
                      errors={errors}
                    />
                    {isMobile && (
                      <span id={'brandingColumn'} className="branding-column-mobile">
                        {/* <GeneralSettings values={values} setFieldValue={setFieldValue} /> */}
                        <div id={'brandingColumnMobile'}>
                          <BrandingElement
                            user={user}
                            values={values}
                            setFieldValue={setFieldValue}
                            profileLinkName={profileLinkName}
                          />
                        </div>
                        {/* <Settings errors={errors} values={values} setFieldValue={setFieldValue} /> */}
                      </span>
                    )}
                    <br />
                    {/* <span className="branding-column-mobile">
                      <Settings values={values} setFieldValue={setFieldValue} />
                    </span> */}
                    {/* {isValidLinks && <AutoSave debounceMs={300} loading={loading} />} */}
                    {error?.message && <CommonErrorMessage message={error.message} />}
                  </Grid>

                  <Grid item className="branding-column" xl={3} lg={4} md={5} sm={12} xs={12}>
                    {!isMobile && (
                      <div id={'brandingColumn'}>
                        <BrandingElement
                          user={user}
                          values={values}
                          setFieldValue={setFieldValue}
                          profileLinkName={profileLinkName}
                        />
                      </div>
                    )}
                  </Grid>

                  {errors && touched && errors.PrimaryColorCode && touched.PrimaryColorCode && (
                    <p style={{ color: 'red' }}>{errors.PrimaryColorCode}</p>
                  )}
                  {errors && touched && errors.AccentColorCode && touched.AccentColorCode && (
                    <p style={{ color: 'red' }}>{errors.AccentColorCode}</p>
                  )}
                  {errors && touched && errors.TertiaryColorCode && touched.TertiaryColorCode && (
                    <p style={{ color: 'red' }}>{errors.TertiaryColorCode}</p>
                  )}
                </Grid>
                <Grid container className="page-footer-buttons">
                  <Button className="submit-button" type="submit" disabled={loading}>
                    Save & Update
                  </Button>
                </Grid>
              </Form>
            </>
          );
        }}
      </Formik>
      <Modal
        title=""
        isOpen={showModal}
        onCloseText="OK"
        hiddenCancel
        onSubmit={togglePopup}
        onCancel={togglePopup}
        disableFooter
      >
        <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', marginBottom: '10px' }}>
          <StyledImgCongrats previewImg={TemplateOne}></StyledImgCongrats>
          <div style={{ fontSize: '24px', color: '#215C73', fontWeight: 800 }}>CONGRATS!</div>
          <div style={{ color: '#A58B62', fontSize: '16px', marginTop: '13px', fontWeight: 450, fontFamily: 'Avenir' }}>
            You are fully set up & ready to transform lives!
          </div>
          <Button
            autoWidth
            backgroundColor={'#C9B382'}
            style={{
              marginTop: '40px',
              paddingTop: '10px',
              paddingBottom: '10px',
              marginBottom: '10px',
            }}
            onClick={togglePopup}
          >
            Let’s do this!
          </Button>
        </div>
      </Modal>
      <ChatAndGetStartedButtons page={'ProfilePage'} />
    </Grid>
  );
}

ProfilePage.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string.isRequired,
    accountId: PropTypes.string.isRequired,
    lastName: PropTypes.string,
    firstName: PropTypes.string,
    birthDate: PropTypes.string,
    businessType: PropTypes.string,
    occupation: PropTypes.string,
    certification: PropTypes.string,
    customBusinessType: PropTypes.string,
    timeZoneId: PropTypes.string,
    profileLinkName: PropTypes.string,
    timeZone: PropTypes.shape({
      timeZones: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    timeZoneLoading: PropTypes.bool.isRequired,
    fetchTimeZones: PropTypes.func.isRequired,
    avatarUrl: PropTypes.string,
    customerLabelPreference: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    businessName: PropTypes.string,
    bio: PropTypes.string,
  }).isRequired,
  loading: PropTypes.bool.isRequired,
  editProfile: PropTypes.func.isRequired,
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      message: PropTypes.string,
    }),
  ]),
  country: PropTypes.shape({
    countries: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  countryLoading: PropTypes.bool.isRequired,
  fetchCountries: PropTypes.func.isRequired,
};

ProfilePage.defaultProps = {
  error: null,
  fetchTimeZones: () => null,
};

const mapStateToProps = ({ account, timeZone, country }) => ({
  user: account?.user || {},
  loading: account?.isLoading,
  error: account?.error,
  timeZones: timeZone?.timeZones,
  timeZoneLoading: timeZone?.loading,
  countries: country?.countries,
  countryLoading: country?.loading,
});

const actions = {
  getUser: userActions.getProfile,
  editProfile: userActions.editProfile,
  fetchTimeZones: timeZoneActions.fetchTimeZones,
  fetchCountries: countryActions.fetchCountries,
};

export default connect(mapStateToProps, actions)(ProfilePage);
