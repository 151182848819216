export const redirectInNewTab = url => {
  const win = window.open(url, '_blank');
  if (win != null) {
    win.focus();
  }
};

export const openInNewWindow = url => {
  window.open(url, '_blank');
};

export const redirectTo = url => {
  window.location.assign(url);
};
