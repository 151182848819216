import React from 'react'
import PropTypes from 'prop-types'

import { useRemoteParticipant, useRemoteAudioTrack, useRemoteVideoTrack } from '../../../../hooks'
import { useParticipantAudioVolume } from '../../../ParticipantsAudioVolumeProvider'
import Participant from './Participant'
import { useNetworkQualityLevel } from '../../../../hooks/participants'

const RemoteParticipant = ({ participant, selectedAudioOutputDevice, ...restProps }) => {
  const { audioTrack, videoTrack } = useRemoteParticipant(participant)
  const { isEnabled: isAudioEnabled } = useRemoteAudioTrack(audioTrack)
  const [audioVolume, setAudioVolume] = useParticipantAudioVolume(participant)
  const { isEnabled: isVideoEnabled } = useRemoteVideoTrack(videoTrack)

  const networkQualityLevel = useNetworkQualityLevel(participant)

  return (
    <Participant
      {...restProps}
      identity={participant.identity}
      networkQualityIndicator={networkQualityLevel}
      isAudioEnabled={isAudioEnabled}
      audioVolume={audioVolume}
      onAudioVolumeChange={setAudioVolume}
      isVideoEnabled={isVideoEnabled}
      selectedAudioOutputDevice={selectedAudioOutputDevice}
    />
  )
}

RemoteParticipant.propTypes = {
  participant: PropTypes.shape({
    identity: PropTypes.string,
  }).isRequired,
}

export default RemoteParticipant
