const CHANGE_HEADER = 'CHANGE_HEADER';
const TOGGLE_MOBILE_SIDEBAR = 'TOGGLE_MOBILE_SIDEBAR';
const TOGGLE_COACH_DASHBOARD = 'TOGGLE_COACH_DASHBOARD';

export const constants = {
  CHANGE_HEADER,
  TOGGLE_MOBILE_SIDEBAR,
  TOGGLE_COACH_DASHBOARD,
};

export const changeHeader = title => ({
  type: CHANGE_HEADER,
  payload: title,
});

export const changeDropdown = show => ({
  type: TOGGLE_COACH_DASHBOARD,
  payload: show,
});

export const toggleMobileSidebar = () => ({
  type: TOGGLE_MOBILE_SIDEBAR,
});
