import useAccount from 'hooks/useAccount';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { MEMBER_PODS_ACTIONS } from 'actions/MemberPods';
import { memberPodsSelector } from 'selectors/MemberPods';
import { memberPodsService } from 'services/memberPods/memberPods.service';
import useContribution from 'pages/ContributionView/hooks/useContribution';

export const useHandlers = () => {
  const dispatch = useDispatch();
  const { newPodName } = useSelector(memberPodsSelector);
  const { user } = useAccount();
  const contribution = useContribution();
  const { id: coachId } = user;

  const onChangeNewPodName = useCallback(
    event => {
      const value = event.target.value || '';

      dispatch(MEMBER_PODS_ACTIONS.setNewPodName(value));
    },
    [dispatch],
  );

  const onCreateMemberPod = useCallback(() => {
    const name = newPodName?.trim?.();

    if (!name) {
      return;
    }

    dispatch(MEMBER_PODS_ACTIONS.setIsFetching(true));

    memberPodsService
      .addMemberPod({ coachId, name, contributionId: contribution.id })
      .then(() => dispatch(MEMBER_PODS_ACTIONS.resetState()))
      .catch(error => dispatch(MEMBER_PODS_ACTIONS.setError(error)));
  }, [coachId, contribution.id, dispatch, newPodName]);

  const onDeletePod = useCallback(
    podId => {
      dispatch(MEMBER_PODS_ACTIONS.setIsFetching(true));

      memberPodsService
        .deleteMemberPod(podId)
        .then(() => dispatch(MEMBER_PODS_ACTIONS.resetState()))
        .catch(error => dispatch(MEMBER_PODS_ACTIONS.setError(error)));
    },
    [dispatch],
  );

  const onUpdatePodName = useCallback(
    ({ clientIds, podId, value }) => {
      const name = value?.trim?.();

      if (!name) {
        return;
      }

      dispatch(MEMBER_PODS_ACTIONS.setIsFetching(true));

      memberPodsService
        .updateMemberPod({ clientIds, coachId, name, podId, contributionId: contribution.id })
        .then(() => dispatch(MEMBER_PODS_ACTIONS.resetState()))
        .catch(error => dispatch(MEMBER_PODS_ACTIONS.setError(error)));
    },
    [coachId, contribution.id, dispatch],
  );

  return {
    onChangeNewPodName,
    onCreateMemberPod,
    onDeletePod,
    onUpdatePodName,
  };
};
