import { useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import orderBy from 'lodash/orderBy';

import { updateMessages, clearMessages, addMessage, updateMessage, removeMessage } from './actions';
import messagesReducer, { initialMessages } from './reducer';

const useChatClientChannelMessages = channel => {
  const [messages, dispatchMessageAction] = useReducer(messagesReducer, initialMessages);
  const messagesArray = useMemo(() => orderBy(Object.values(messages), [m => m.index], ['asc']), [messages]);
  const [messagesPaginator, setMessagesPaginator] = useState(null);
  const [isLoadingMoreMessages, setIsLoadingMoreMessages] = useState(false);

  const loadMoreMessages = useCallback(async () => {
    if (!messagesPaginator.hasPrevPage) {
      return;
    }

    try {
      setIsLoadingMoreMessages(true);
      const previousMessagesPaginator = await messagesPaginator.prevPage();
      dispatchMessageAction(updateMessages(previousMessagesPaginator.items));
      setMessagesPaginator(previousMessagesPaginator);
    } finally {
      setIsLoadingMoreMessages(false);
    }
  }, [messagesPaginator]);

  useEffect(() => {
    (async function getChannelMessagesAsync() {
      if (!channel) {
        dispatchMessageAction(clearMessages());
        setMessagesPaginator(null);

        return;
      }

      try {
        const channelMessagesPaginator = await channel.getMessages();
        dispatchMessageAction(updateMessages(channelMessagesPaginator.items));
        setMessagesPaginator(channelMessagesPaginator);
      } catch {
        dispatchMessageAction(updateMessages([]));
        setMessagesPaginator(null);
      }
    })();
  }, [channel]);
  useEffect(() => {
    if (channel) {
      const onMessageAdded = m => dispatchMessageAction(addMessage(m));
      const onMessageUpdated = ({ message }) => dispatchMessageAction(updateMessage(message));
      const onMessageRemoved = m => dispatchMessageAction(removeMessage(m.sid));

      channel.on('messageAdded', onMessageAdded);
      channel.on('messageUpdated', onMessageUpdated);
      channel.on('messageRemoved', onMessageRemoved);

      return () => {
        channel.off('messageAdded', onMessageAdded);
        channel.off('messageUpdated', onMessageUpdated);
        channel.off('messageRemoved', onMessageRemoved);
      };
    }
  }, [channel]);

  return {
    messages: messagesArray,
    hasMoreMessages: !!messagesPaginator && messagesPaginator.hasPrevPage,
    isLoadingMoreMessages,
    loadMoreMessages,
  };
};

export default useChatClientChannelMessages;
