import { ProceedPayment } from './payment';
import { ClientJourneyContribution } from './contributions';

export function createProceedPaymentFrom(proceedPayment) {
  // eslint-disable-line
  return new ProceedPayment(proceedPayment);
}

export function createClientJourneyContributionFrom(clientJourneyContribution) {
  return new ClientJourneyContribution(clientJourneyContribution);
}
