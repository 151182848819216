import React from 'react';
import styled, { css } from 'styled-components';
import { useTheme, useMediaQuery } from '@material-ui/core';
import colon from '../../../assets/testimonial-colon.png';
import stars from '../../../assets/stars.png';
import emma from '../../../assets/emma.png';
import { Avatar } from '@material-ui/core';
import { BorderDiv } from './AboutMainInfoBlock/AboutMainInfo.styles';
import useContribution from 'pages/ContributionView/hooks/useContribution';
import { getThemedColors } from 'services/contributions.service';
// import ColonIcon from "assets/colon.svg"
import ColonIcon from 'pages/ContributionView/Templates/ColonIcon';
const TestimonialMainContainer = styled.div``;
const TestimonialMainHeading = styled.h1`
  font-family: 'Brandon Text';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  color: #282b2b;
`;
const TestimonialInnerContent = styled.div``;
const CollenIcon = styled.img``;
const TestimonialP = styled.p`
  font-family: 'Brandon Text';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #282b2b;
  margin: 0px;
  padding: 1rem 0px;
`;
const TestimonialStars = styled.img``;
const TestimonialProfile = styled.div`
  display: flex;
  padding: 1.5rem 0px 0px 0px;
`;
const ProfileLeftImgDiv = styled.div`
  ${({ mobileView }) =>
    mobileView &&
    css`
      display: flex;
      justify-content: center;
    `};
`;

const ProfileLeftImg = styled.img`
  width: 70px;
`;
const ProfileRightTxtDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0px 0px 0px 0.6rem;
`;
const TestProfileRighth2 = styled.h2`
  font-family: 'Brandon Text';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #282b2b;
  margin-bottom: 0px;
`;
const TestProfileRightp = styled.p`
  font-family: 'Brandon Text';
  font-style: normal;
  font-weight: 420;
  font-size: 12px;
  color: #282b2b;
  opacity: 0.4;
  margin-bottom: 0px;
`;
const TestimonialMainDiv = styled.div`
  padding: 1rem 0px;
`;

function TestimonialTemOne(props) {
  const { testimonials } = props;
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'));

  const contribution = useContribution();

  const { themedColor } = getThemedColors(contribution);

  return (
    <>
      {/* {testimonials?.length > 0 && <BorderDiv margin="0.5rem 0px 4rem 0px"></BorderDiv>} */}
      <TestimonialMainContainer>
        {!!testimonials && <TestimonialMainHeading style={{ color: themedColor }}>Testimonials</TestimonialMainHeading>}
        {testimonials?.map(t => (
          <TestimonialMainDiv>
            <TestimonialInnerContent>
              <ColonIcon color={contribution?.isDarkModeEnabled ? 'white' : '#282B2B'} />
              <TestimonialP style={{ color: themedColor }}>{t.description}</TestimonialP>
              {/* <TestimonialStars src={stars}></TestimonialStars> */}
            </TestimonialInnerContent>
            <TestimonialProfile>
              <ProfileLeftImgDiv>
                {/* <ProfileLeftImg src={t.avatarUrl}></ProfileLeftImg> */}
                <Avatar src={t.avatarUrl} style={{ width: '50px', height: '50px' }} />
              </ProfileLeftImgDiv>
              <ProfileRightTxtDiv>
                <div>
                  <TestProfileRighth2 style={{ color: themedColor }}>{t.name}</TestProfileRighth2>
                </div>
                <div>
                  <TestProfileRightp style={{ color: themedColor }}>{t.role}</TestProfileRightp>
                </div>
              </ProfileRightTxtDiv>
            </TestimonialProfile>
          </TestimonialMainDiv>
        ))}
      </TestimonialMainContainer>
    </>
  );
}

export default TestimonialTemOne;
