import { useEffect, useRef } from 'react';

const usePrevious = current => {
  const previousRef = useRef();

  useEffect(() => {
    previousRef.current = current;
  }, [current]);

  return previousRef.current;
};

export default usePrevious;
