import axiosInstance from 'utils/axiosInstance';
import { get } from 'lodash/fp';

const baseURL = '/Contact';

const getAllContacts = data => axiosInstance.post(`${baseURL}/GetAll`, data).then(get('data'));
const importContacts = data => axiosInstance.post(`${baseURL}/ImportContacts`, data).then(get('data'));
const getAllTags = data => axiosInstance.post(`${baseURL}/GetAllTags`, data).then(get('data'));
const deleteContact = data => axiosInstance.post(`${baseURL}/DeleteContact`, data).then(get('data'));
const exportContacts = data => axiosInstance.post(`${baseURL}/Export?type=${data}`).then(get('data'));
const createTag = data => axiosInstance.post(`${baseURL}/Tag`, data).then(get('data'));
const addLead = data => axiosInstance.post(`${baseURL}/AddLead`, data).then(get('data'));
const editLead = (contactId, data) =>
  axiosInstance.put(`${baseURL}/EditLead?contactId=${contactId}`, data).then(get('data'));
const deleteTag = data => axiosInstance.post(`${baseURL}/DeleteTag`, data).then(get('data'));
const editTag = data => axiosInstance.put(`${baseURL}/EditTag`, data).then(get('data'));
const assignTagToUser = data => axiosInstance.post(`${baseURL}/AssignTagToUser`, data).then(get('data'));
const removeTagFromUser = params => {
  const { tagId, contactId } = params;
  return axiosInstance.delete(`${baseURL}/RemoveTagFromUser?tagId=${tagId}&contactId=${contactId}`).then(get('data'));
};

const getEmailActivity = contactId =>
  axiosInstance.get(`${baseURL}/getEmailActivity?contactId=${contactId}`).then(get('data'));

const getSegmentContactsList = () => axiosInstance.get(`/ContactSegment`).then(get('data'));
const getSegmentedContacts = id =>
  axiosInstance.get(`${baseURL}/GetContactsForSegment?segmentId=${id}`).then(get('data'));

const postSegments = data => axiosInstance.post(`/ContactSegment`, data).then(get('data'));
const putSegments = data => axiosInstance.put(`/ContactSegment`, data).then(get('data'));
const updateContact = data => {
  const { contactId, isLead } = data;
  return axiosInstance.put(`${baseURL}/UpdateContactRole?contactId=${contactId}&isLead=${isLead}`).then(get('data'));
};

export const ContactsServices = {
  importContacts,
  getAllContacts,
  getAllTags,
  deleteContact,
  exportContacts,
  updateContact,
  createTag,
  addLead,
  editLead,
  editTag,
  deleteTag,
  assignTagToUser,
  removeTagFromUser,
  getEmailActivity,
  getSegmentContactsList,
  postSegments,
  putSegments,
  getSegmentedContacts,
};
