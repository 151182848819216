import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { useMediaQuery, useTheme } from '@material-ui/core';
import styled, { css } from 'styled-components';

import { useAccount } from 'hooks';
import { Card, CardBody, CardHeader } from 'components/UI/Card';
import { PageTitleSecond } from 'components/UI/Text/TextStyles';
import { ContributionType, UserRoles, ContributionThemedColors } from 'helpers/constants';
import { cohereAcademyContributionsSelector } from 'selectors/paidTier';
import { roleChange } from 'actions/user';
import * as userService from 'services/user.service';
import * as accountPreferences from 'services/accountPreferences.service';
import { getThemedColors } from 'services/contributions.service';
import useRouter from 'hooks/useRouter';
import TextBlock from './TextBlock';
import { PaymentOptions, RevenueBlock, TotalCostBlock } from './PaymentComponents';
import TypeOfCourse from './TypeOfCourse';
import PurchaseModalsAndButtons from './PurchaseModalsAndButtons/PurchaseModalsAndButtons';
import * as purchaseService from '../../../services/purchase.service';
import { colors } from '../../../utils/styles/index';
import { ROUTES } from '../../../constants';
import useContribution from '../hooks/useContribution';

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  div {
    margin-right: 20px;
    margin-bottom: 20px;
  }
  button {
    width: 100%;
    min-width: 200px;
  }

  ${({ mobileView }) =>
    mobileView &&
    css`
      flex-direction: column;
      div {
        margin-right: 0px;
      }
    `}
`;

const ClickHereLink = styled.button`
  background: ${colors.white};
  border: none;
  text-decoration: underline;
  color: ${colors.fbBlue};
`;

const AboutPaymentBlock = ({ contribution, earnedRevenue, paymentInfo }) => {
  const { paymentOptions } = paymentInfo;

  const cohereAcademyContributions = useSelector(cohereAcademyContributionsSelector);

  const [error, setError] = useState(null);
  const [summary, setSummary] = useState({});
  const [showPaymentInfo, setShowPaymentInfo] = useState(true);
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'));
  const { currentRole } = useAccount();
  const isCoach = currentRole === UserRoles.cohealer;
  const [hideBtn, sethideBtn] = useState(false);
  const dispatch = useDispatch();
  const router = useRouter();

  const { id } = useContribution();

  const { themedColor, themedCardBackgroundColor } = getThemedColors(contribution);

  useEffect(() => {
    const filteredAcademyContributions = cohereAcademyContributions.filter(item => item.id === id);

    if (filteredAcademyContributions.length === 0) {
      setShowPaymentInfo(true);
    } else if (filteredAcademyContributions.length > 0) {
      setShowPaymentInfo(false);
    }
  }, [cohereAcademyContributions]);

  useEffect(() => {
    setError(null);
    const REQUEST_MAPPING = {
      [ContributionType.contributionOneToOne]: purchaseService.getOnToOnePaymentInfo,
      [ContributionType.contributionCourse]: purchaseService.getCoursePaymentInfo,
      [ContributionType.contributionMembership]: purchaseService.getMembershipPaymentInfo,
      [ContributionType.contributionCommunity]: purchaseService.getCommunityPaymentInfo,
    };
    const getPaymentData = REQUEST_MAPPING[contribution.type];

    paymentOptions
      .filter(paymentOption => paymentOption !== 'Free')
      .forEach(paymentOption => {
        getPaymentData(contribution.id, paymentOption)
          .then(paymentData => {
            setSummary(prevSummary => ({ ...prevSummary, [paymentOption]: paymentData }));
          })
          .catch(console.dir);
      });
  }, [contribution.id, paymentOptions]);

  // temporary for fix
  if (!paymentInfo) return <div>Not payment info.</div>;

  const handleRedirectToCoach = () => {
    Promise.all([
      userService.switchFromClientToCoach({}),
      accountPreferences.setAccountPreferences({ userView: UserRoles.cohealer }),
    ]).then(([auth, preferences]) => {
      router.push(ROUTES.DASHBOARD);
      dispatch(roleChange(preferences.userView));
      router.push(ROUTES.ACCOUNT_COACH_BILLING);
    });
  };

  return (
    <Card contribution={contribution} style={{ color: themedColor, backgroundColor: themedCardBackgroundColor }}>
      <CardHeader mobileView={mobileView}>
        <PageTitleSecond mobileView={mobileView} style={{ color: themedColor }}>
          Payment
        </PageTitleSecond>
      </CardHeader>
      <CardBody mobileView={mobileView}>
        <Grid container spacing={4}>
          {showPaymentInfo && (
            <>
              <Grid item md={3} sm={4} xs={12}>
                <TextBlock
                  image={null}
                  title="Price"
                  text={
                    <TotalCostBlock
                      defaultSymbol={contribution.defaultSymbol}
                      defaultCurrency={contribution.defaultCurrency}
                      type={contribution.type}
                      summary={summary}
                      paymentInfo={paymentInfo}
                    />
                  }
                  mobileView={mobileView}
                />
              </Grid>
              <Grid item md={3} sm={3} xs={12}>
                <TextBlock
                  image={null}
                  title="Payment Options"
                  text={
                    <PaymentOptions
                      defaultSymbol={contribution.defaultSymbol}
                      defaultCurrency={contribution.defaultCurrency}
                      paymentOptions={paymentOptions}
                    />
                  }
                  mobileView={mobileView}
                />
              </Grid>
              <Grid item md={2} sm={2} xs={12}>
                <ButtonsWrapper mobileView={mobileView}>
                  <TypeOfCourse contribution={contribution} mobileView={mobileView} PaymentBlock={true} />
                  <PurchaseModalsAndButtons hideBtn={true} contribution={contribution} PaymentBlock={true} />
                </ButtonsWrapper>
              </Grid>
            </>
          )}
          {!showPaymentInfo && (
            <Grid item md={9} sm={9} xs={12}>
              <TextBlock
                image={null}
                text={
                  <div>
                    Included with the Annual Impact plan and Monthly/Annual Scale plans. Click
                    <ClickHereLink onClick={handleRedirectToCoach}>here</ClickHereLink>
                    to learn more.
                  </div>
                }
                mobileView={mobileView}
              />
            </Grid>
          )}

          {isCoach && (
            <Grid item md={3} sm={6} xs={12}>
              <TextBlock
                image={null}
                title="Earned Revenue"
                text={
                  <RevenueBlock
                    defaultCurrency={contribution.defaultCurrency}
                    defaultSymbol={contribution.defaultSymbol}
                    earnedRevenue={earnedRevenue}
                    paymentOptions={paymentOptions}
                  />
                }
                mobileView={mobileView}
              />
            </Grid>
          )}
        </Grid>
      </CardBody>
    </Card>
  );
};

AboutPaymentBlock.defaultProps = {
  contribution: {},
  earnedRevenue: null,
  paymentInfo: {},
};

AboutPaymentBlock.propTypes = {
  contribution: PropTypes.shape({
    id: PropTypes.string,
  }),
  earnedRevenue: PropTypes.number,
  paymentInfo: PropTypes.shape({
    cost: PropTypes.number.isRequired,
    paymentOptions: PropTypes.arrayOf(PropTypes.string),
  }),
};

export default AboutPaymentBlock;
