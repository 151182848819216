import { useCallback } from 'react';

const useChatClientChannelUtilities = channel => {
  const sendTextMessage = useCallback(
    async text => {
      try {
        await channel.sendMessage(text);

        return true;
      } catch {
        return false;
      }
    },
    [channel],
  );
  const sendMediaMessage = useCallback(
    async file => {
      try {
        const formData = new FormData();

        formData.append('file', file);

        await channel.sendMessage(formData);

        return true;
      } catch {
        return false;
      }
    },
    [channel],
  );
  const readMessage = useCallback(messageIndex => channel.advanceLastConsumedMessageIndex(messageIndex), [channel]);
  const readAllMessages = useCallback(() => channel.setAllMessagesConsumed(), [channel]);
  const unreadAllMessages = useCallback(() => channel.setNoMessagesConsumed(), [channel]);
  const onTyping = useCallback(() => channel.typing(), [channel]);

  return {
    sendTextMessage,
    sendMediaMessage,
    readMessage,
    readAllMessages,
    unreadAllMessages,
    onTyping,
  };
};

export default useChatClientChannelUtilities;
