import React, { useState } from 'react';
import PropTypes from 'prop-types';
import UpcomingContributions from '../UpcomingContributions';
import PastContributions from '../PastContributions';
import { Box, Container, Grid } from '@material-ui/core';
import { ClientJourneyContribution } from 'services/dataContracts/contributions';
import ContributionsList from '../UpcomingContributions/ContributionsList';
import SessionCard from '../../SessionCard/SessionCard';
import styled from 'styled-components';
import { toRem } from 'utils/styles';
import { CommentRounded } from '@mui/icons-material';
import { JourneySection } from 'helpers/constants/sections';
import { isEmpty } from 'ramda';

const Heading = styled.p`
  font-size: ${toRem(22)};
  font-weight: 900;
  line-height: 1.5;
  letter-spacing: 0.12px;
  color: #116582;

  ${({ mobileView }) => mobileView && `font-size: ${toRem(16)};`}
`;

const StyledSessionsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
`;

const checkIfHasContributions = contributions => contributions?.length > 0;

const LiveConstributions = ({ bookable, inCompletedlive, upcominglive }) => {
  const [bookableSessions, setbookableSessions] = useState(bookable);
  const [inCompletedSessions, setInCompletedSessions] = useState(inCompletedlive);
  const [upcomingSessions, setUpcomingSessions] = useState(upcominglive);
  const deleteIndexBookable = indexData => {
    const data1 = bookableSessions.filter((item, index) => index != indexData);
    setbookableSessions(data1);
  };
  const deleteIndexIncompleted = indexData => {
    const data1 = inCompletedSessions.filter((item, index) => index != indexData);
    setInCompletedSessions(data1);
  };
  const deleteIndexUpcoming = indexData => {
    const data1 = upcomingSessions.filter((item, index) => index != indexData);
    setUpcomingSessions(data1);
  };

  if (isEmpty(inCompletedlive) && isEmpty(upcominglive) && isEmpty(bookable)) {
    return <Grid container>You don't have any active sessions yet.</Grid>;
  }
  return (
    <Grid container direction="column">
      {bookableSessions?.length > 0 && (
        <Grid item style={{ marginBottom: '70px' }}>
          <Heading>Book Your Sessions</Heading>
          <StyledSessionsContainer>
            {bookableSessions?.length > 0 ? (
              bookableSessions?.map((s, i) => {
                return (
                  <SessionCard
                    key={i}
                    index={i}
                    section={JourneySection.bookable}
                    sessioninfo={s}
                    removeIndexFunction={() => {
                      deleteIndexBookable(i);
                    }}
                  />
                );
              })
            ) : (
              <Grid container>No bookable sessions available.</Grid>
            )}
          </StyledSessionsContainer>
        </Grid>
      )}

      {inCompletedSessions?.length > 0 && (
        <Grid item style={{ marginBottom: '70px' }}>
          <Heading>Incomplete Sessions</Heading>
          <StyledSessionsContainer>
            {inCompletedSessions?.length > 0 ? (
              inCompletedSessions?.map((s, i) => {
                return (
                  <SessionCard
                    key={i}
                    index={i}
                    section={JourneySection.incompleted}
                    sessioninfo={s}
                    removeIndexFunction={() => {
                      deleteIndexIncompleted(i);
                    }}
                  />
                );
              })
            ) : (
              <Grid container>No incompleted sessions available.</Grid>
            )}
          </StyledSessionsContainer>
        </Grid>
      )}

      <Grid item style={{ marginBottom: '70px' }}>
        <Heading>Upcoming Live Sessions</Heading>
        <StyledSessionsContainer>
          {upcomingSessions?.length > 0 ? (
            upcomingSessions?.map((s, i) => {
              return (
                <SessionCard
                  key={i}
                  index={i}
                  section={JourneySection.upcoming}
                  sessioninfo={s}
                  removeIndexFunction={() => {
                    deleteIndexUpcoming(i);
                  }}
                />
              );
            })
          ) : (
            <Grid container>No upcoming sessions available.</Grid>
          )}
        </StyledSessionsContainer>
      </Grid>
    </Grid>
  );
};

const contributionsPropType = PropTypes.arrayOf(PropTypes.instanceOf(ClientJourneyContribution)).isRequired;

LiveConstributions.propTypes = {
  upcomingcontributions: PropTypes.shape({
    otherIncompleted: contributionsPropType,
    today: contributionsPropType,
    thisWeek: contributionsPropType,
    thisMonth: contributionsPropType,
    nextMonth: contributionsPropType,
    thisYear: contributionsPropType,
    afterThisYear: contributionsPropType,
    notBooked: contributionsPropType,
  }).isRequired,
  // pastcontributions: contributionsPropType,
};

export default LiveConstributions;
