import { useDeepEqualSelector, useShallowEqualSelector } from 'hooks';

const selector = state => state?.leadMagnets?.activeLeadMagnet;

function useLeadMagnet() {
  // // TODO: remove this temporary hardcoded object
  // return {
  //   userId: '65faafb48342e80f7614a520',
  //   title: 'Lead Magnet Sample Test',
  //   status: 'Approved',
  //   type: 'LeadMagnetPDF',
  //   previewContentUrls: [
  //     'https://coherepublic.s3.amazonaws.com/65faaf988342e80f7614a51b/f3494775-e66c-471d-b2b7-a34df462bc86',
  //   ],
  //   contributionTagline: 'Art',
  //   purpose:
  //     '<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry&#39;s standard dummy text ever since the 1500s.</p>',
  //   whoIAm:
  //     '<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry&#39;s standard dummy text ever since the 1500s.</p>',
  //   whatWeDo:
  //     '<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry&#39;s standard dummy text ever since the 1500s.</p>',
  //   preparation:
  //     '<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry&#39;s standard dummy text ever since the 1500s.</p>',
  //   activeTemplate: 'LMTemplateOne',
  //   whoIAmLabel: 'Who this is for',
  //   whatWeDoLabel: 'What to expect',
  //   purposeLabel: 'Why this was created',
  //   preparationLabel: 'Additional details',
  //   viewPurpose: false,
  //   viewWhoIAm: false,
  //   isDownloadEnabled: false,
  //   viewWhatWeDo: false,
  //   viewPreparation: false,
  //   whoIAmIcon:
  //     'M12,4A4,4 0 0,1 16,8A4,4 0 0,1 12,12A4,4 0 0,1 8,8A4,4 0 0,1 12,4M12,6A2,2 0 0,0 10,8A2,2 0 0,0 12,10A2,2 0 0,0 14,8A2,2 0 0,0 12,6M12,13C14.67,13 20,14.33 20,17V20H4V17C4,14.33 9.33,13 12,13M12,14.9C9.03,14.9 5.9,16.36 5.9,17V18.1H18.1V17C18.1,16.36 14.97,14.9 12,14.9Z',
  //   whatWeDoIcon:
  //     'M12,2A7,7 0 0,0 5,9C5,11.38 6.19,13.47 8,14.74V17A1,1 0 0,0 9,18H15A1,1 0 0,0 16,17V14.74C17.81,13.47 19,11.38 19,9A7,7 0 0,0 12,2M9,21A1,1 0 0,0 10,22H14A1,1 0 0,0 15,21V20H9V21Z',
  //   purposeIcon:
  //     'M12,21.35L10.55,20.03C5.4,15.36 2,12.27 2,8.5C2,5.41 4.42,3 7.5,3C9.24,3 10.91,3.81 12,5.08C13.09,3.81 14.76,3 16.5,3C19.58,3 22,5.41 22,8.5C22,12.27 18.6,15.36 13.45,20.03L12,21.35Z',
  //   preparationIcon:
  //     'M19,19H4V3H19M11.5,23A1.5,1.5 0 0,1 10,21.5A1.5,1.5 0 0,1 11.5,20A1.5,1.5 0 0,1 13,21.5A1.5,1.5 0 0,1 11.5,23M18.5,0H4.5A2.5,2.5 0 0,0 2,2.5V21.5A2.5,2.5 0 0,0 4.5,24H18.5A2.5,2.5 0 0,0 21,21.5V2.5A2.5,2.5 0 0,0 18.5,0Z',
  //   customToS: null,
  //   instagramUrl: 'https://instagram.com',
  //   linkedInUrl: 'https://linkedin.com',
  //   youtubeUrl: 'https://youtube.com',
  //   isElectronicSignatureActive: false,
  //   facebookUrl: 'https://facebook.com',
  //   tiktokUrl: 'https://tiktok.com',
  //   websiteUrl: 'https://tiktok.com',
  //   thankYouPageRedirectUrl: null,
  //   isSpeakingLanguageRequired: false,
  //   isCustomBrandingColorsActive: false,
  //   isLegacyColorsActive: false,
  //   isDarkModeEnabled: false,
  //   isCoachSelectedDarkModeEnabled: false,
  //   brandingColors: {
  //     PrimaryColorCode: '#CDBA8F',
  //     AccentColorCode: '#116582',
  //     TertiaryColorCode: '#F6E8BO',
  //     TextColorCode: '#FFFFFF',
  //   },
  //   coachSelectedBrandingColors: {
  //     PrimaryColorCode: '#CDBA8F',
  //     AccentColorCode: '#116582',
  //     TertiaryColorCode: '#F6E8BO',
  //     TextColorCode: '#FFFFFF',
  //   },
  //   invitationOnly: true,
  //   hasAgreedContributionTerms: true,
  //   rating: 0,
  //   likesNumber: 0,
  //   customTitleForMeetYourCoach: 'Coach',
  //   deletingAllowed: false,
  //   thankyouPage: {
  //     leadMagnetThankyouPage: {
  //       bannerImage:
  //         'https://coherepublic.s3.amazonaws.com/65faaf988342e80f7614a51b/08d97056-926f-49ef-8bce-b4d8db2c53c4',
  //       header: 'Access Your Content Now!',
  //       description:
  //         'Anette Oran is a Certified Transformational Coach who specializes in helping individuals step into their purpose by creating an online brand and business that is in alignment with their calling. Through the Mastermind.',
  //       ctaButtonText: 'Access Content',
  //       isVideoBanner: false,
  //     },
  //     additionalLeadMagnetThankyouPage: {
  //       bannerImage:
  //         'https://coherepublic.s3.amazonaws.com/65faaf988342e80f7614a51b/6292ad9a-0267-45da-9fdc-547a72fec0b6',
  //       header: 'Want to book a call to learn more?',
  //       description:
  //         'Anette Oran is a Certified Transformational Coach who specializes in helping individuals step into their purpose by creating an online brand and business that is in alignment with their calling. Through the Mastermind.',
  //       ctaButtonText: 'Button Text',
  //       url: 'Enter URL',
  //       isVideoBanner: false,
  //     },
  //   },
  //   content: {
  //     id: '79af3369-eb66-4f71-b704-26a3361322fe',
  //     contentHeader: 'Download Now',
  //     prerecordedData: [
  //       {
  //         id: null,
  //         documentKeyWithExtension: '65faaf988342e80f7614a51b/attachments/98d4-4e78-59fc-c7de.webm',
  //         documentOriginalNameWithExtension: 'file_example_WEBM_480_900KB (1).webm',
  //         contentType: null,
  //         duration: '30543',
  //         extension: '.webm',
  //         attachementUrl: null,
  //         thumbnailUrl:
  //           'https://coherepublic.s3.amazonaws.com/65faaf988342e80f7614a51b/7543f390-5d95-4b31-af4c-1631c29365ae',
  //         embeddedVideoUrl: null,
  //         uploadDateTime: null,
  //       },
  //       {
  //         id: null,
  //         documentKeyWithExtension: '65faaf988342e80f7614a51b/attachments/e178-e32f-e812-74be.webm',
  //         documentOriginalNameWithExtension: 'file_example_WEBM_480_900KB (1).webm',
  //         contentType: null,
  //         duration: '30543',
  //         extension: '.webm',
  //         attachementUrl: null,
  //         thumbnailUrl:
  //           'https://coherepublic.s3.amazonaws.com/65faaf988342e80f7614a51b/68a34a77-1077-4e4a-93b3-52d64886bfec',
  //         embeddedVideoUrl: null,
  //         uploadDateTime: null,
  //       },
  //       {
  //         id: null,
  //         documentKeyWithExtension: '65faaf988342e80f7614a51b/attachments/07be-bd65-ab6a-96b2.webm',
  //         documentOriginalNameWithExtension: 'file_example_WEBM_480_900KB (1).webm',
  //         contentType: null,
  //         duration: '30543',
  //         extension: '.webm',
  //         attachementUrl: null,
  //         thumbnailUrl:
  //           'https://coherepublic.s3.amazonaws.com/65faaf988342e80f7614a51b/4e550be8-7071-4670-bfd3-4d360b45e5c0',
  //         embeddedVideoUrl: null,
  //         uploadDateTime: null,
  //       },
  //       {
  //         id: null,
  //         documentKeyWithExtension: '65faaf988342e80f7614a51b/attachments/dd4c-1000-4c91-ef47.pdf',
  //         documentOriginalNameWithExtension: 'a .pdf',
  //         contentType: null,
  //         duration: null,
  //         extension: '.pdf',
  //         attachementUrl: null,
  //         thumbnailUrl: null,
  //         embeddedVideoUrl: null,
  //         uploadDateTime: null,
  //       },
  //       {
  //         id: null,
  //         documentKeyWithExtension: '65faaf988342e80f7614a51b/attachments/9f1b-4fac-a4e1-142f.pdf',
  //         documentOriginalNameWithExtension: 'a .pdf',
  //         contentType: null,
  //         duration: null,
  //         extension: '.pdf',
  //         attachementUrl: null,
  //         thumbnailUrl: null,
  //         embeddedVideoUrl: null,
  //         uploadDateTime: null,
  //       },
  //       {
  //         id: null,
  //         documentKeyWithExtension: '65faaf988342e80f7614a51b/attachments/a190-817b-af9a-e16e.wav',
  //         documentOriginalNameWithExtension: 'file_example_WAV_1MG.wav',
  //         contentType: null,
  //         duration: '5943.175',
  //         extension: '.wav',
  //         attachementUrl: null,
  //         thumbnailUrl: null,
  //         embeddedVideoUrl: null,
  //         uploadDateTime: null,
  //       },
  //       {
  //         id: null,
  //         documentKeyWithExtension: '65faaf988342e80f7614a51b/attachments/e6af-c4f5-27d9-da19.m4a',
  //         documentOriginalNameWithExtension: 'sample4.m4a',
  //         contentType: null,
  //         duration: '244462.99300000002',
  //         extension: '.m4a',
  //         attachementUrl: null,
  //         thumbnailUrl: null,
  //         embeddedVideoUrl: null,
  //         uploadDateTime: null,
  //       },
  //       {
  //         id: null,
  //         documentKeyWithExtension: '',
  //         documentOriginalNameWithExtension: '',
  //         contentType: null,
  //         duration: '',
  //         extension: '',
  //         attachementUrl: '',
  //         thumbnailUrl: null,
  //         embeddedVideoUrl:
  //           'https://soundcloud.com/chauudhary/atif-aslam-tajdar-e-haram-coke-studio-season-8-episode-1-tajdare-haram?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing',
  //         uploadDateTime: null,
  //       },
  //     ],
  //   },
  //   isThankyouPageAdded: false,
  //   isDuplicate: true,
  //   isDuplicated: false,
  //   customLogo: 'https://coherepublic.s3.amazonaws.com/6555eacad6cfbc30e6225966/e4794372-f564-4cb2-949d-e67303a658b4',
  //   parentLeadMagnetId: null,
  //   id: '660269f8a57a425d67ae2e41',
  //   createTime: '2024-03-26T06:23:52.236Z',
  //   updateTime: '2024-03-26T06:29:49.542Z',
  // };

  // return useDeepEqualSelector(selector) || {};
  return useShallowEqualSelector(selector) || {};
}

export default useLeadMagnet;
