import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import withStyles from '@material-ui/core/styles/withStyles';
import { Icon } from '@mdi/react';
import { mdiDelete } from '@mdi/js';
import { useHttp, useAccount, useVideoChatActions, useRouter } from 'hooks';
import { useTheme, useMediaQuery } from '@material-ui/core';
import Popup from 'components/Popup/Popup';
import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import classes from './ClientsTable.module.scss';
import Client from '../Client/Client';
import NotesModal from 'components/Notes/NotesModal';
import useClientNote from 'hooks/useClientNote';
import { updateClientNotes } from 'services/clients.service';

const BoldTableCell = withStyles({
  root: {
    fontWeight: 800,
  },
})(TableCell);

const BoldTableCellNext = withStyles({
  root: {
    fontWeight: 800,
    paddingLeft: '30px',
  },
})(TableCell);

const BoldTableCellStatus = withStyles({
  root: {
    fontWeight: 800,
    paddingLeft: '10px',
  },
})(TableCell);

const BoldTableCellEdit = withStyles({
  root: {
    fontWeight: 800,
    paddingLeft: '25px',
  },
})(TableCell);
const StyledTableContainer = withStyles({
  root: {
    overflowX: 'auto',
    maxWidth: props => (props.mobileView ? '96vw' : '100%'),
    margin: '0 auto',
  },
})(TableContainer);

const ClientsTable = ({ clients = [], isChecked, onCheckClicked, isReadOnlyView, searchedKeyword }) => {
  const [popupText, setPopupText] = useState(null);
  const { request } = useHttp();
  const [clientWithOpenDropDown, setClientWithOpenDropDown] = useState(null);
  const [openNotesOf, setOpenNotesOf] = useState(null);

  const theme = useTheme();
  const { user } = useAccount();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const {
    location: { pathname },
  } = useRouter();
  const isCreateOrEditCampaignPage = pathname.includes('create-campaign') || pathname.includes('edit-campaign');
  const setId = id => {
    setClientWithOpenDropDown(id);
  };

  const {
    data: editorDataUpdated,
    setData: setEditorDataUpdated,
    showNotes,
    setShowNotes,
    loading: loadingNotes,
    setLoading: setLoadingNotes,
    isNoteExist,
  } = useClientNote(openNotesOf);

  return clients?.length === 0 && searchedKeyword.length > 0 ? (
    <div style={{ display: 'flex', justifyContent: 'center', fontWeight: '600' }}>No search results</div>
  ) : clients?.length === 0 && searchedKeyword.length === 0 ? (
    <div style={{ display: 'flex', justifyContent: 'center', fontWeight: '600' }}>No clients yet.</div>
  ) : (
    <>
      <StyledTableContainer component={Paper} mobileView={mobileView}>
        <Table className="" aria-label="contributions table">
          <TableHead>
            <TableRow>
              <BoldTableCell>Full Name</BoldTableCell>
              <BoldTableCell>Email</BoldTableCell>
              <BoldTableCell>Recent Contribution</BoldTableCell>
              <BoldTableCell>Revenue</BoldTableCell>
              <BoldTableCell>Subscribed</BoldTableCell>
              {!isCreateOrEditCampaignPage && <BoldTableCell>Tags</BoldTableCell>}
              {!isCreateOrEditCampaignPage && <BoldTableCell align="center">Chat</BoldTableCell>}
              {!isCreateOrEditCampaignPage && <BoldTableCell align="center">Notes</BoldTableCell>}
              {!isCreateOrEditCampaignPage && !isReadOnlyView && <BoldTableCell>Action</BoldTableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {clients?.map((row, index) => (
              <Client
                key={index}
                index={index}
                row={row}
                isChecked={isChecked}
                onCheckClicked={onCheckClicked}
                clientWithOpenDropDown={clientWithOpenDropDown}
                setId={setId}
                isReadOnlyView={isReadOnlyView}
                totalCount={clients?.length}
                setOpenNotesOf={setOpenNotesOf}
              />
            ))}
          </TableBody>
        </Table>
      </StyledTableContainer>
      <NotesModal
        editorData={editorDataUpdated}
        showNotes={showNotes}
        loading={loadingNotes}
        setLoading={setLoadingNotes}
        setShowNotes={setShowNotes}
        setEditorDataUpdated={setEditorDataUpdated}
        setNotesId={setOpenNotesOf}
        onSave={() => {
          setLoadingNotes(true);
          updateClientNotes({ clientId: openNotesOf, userId: user.id, note: editorDataUpdated || '' }).finally(() => {
            setLoadingNotes(false);
            setOpenNotesOf(null);
            setShowNotes(false);
          });
        }}
        isNoteExist={isNoteExist}
      />
    </>
  );
};

export default ClientsTable;
