import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { MEMBER_PODS_ACTIONS } from 'actions/MemberPods';
import useContribution from 'pages/ContributionView/hooks/useContribution';
import { memberPodsSelector } from 'selectors/MemberPods';
import { memberPodsService } from 'services/memberPods/memberPods.service';

export const useHandlers = ({ memberId, onBlurElementRef, setState, state }) => {
  const { id: contributionId } = useContribution();
  const { contributionMemberPods } = useSelector(memberPodsSelector);
  const dispatch = useDispatch();

  const { initialSelectedValues, selectedValues } = state;

  const onChange = useCallback(
    (_, node) => {
      const { value } = node.props;
      const currentValues = new Set(selectedValues);
      const method = currentValues.has(value) ? 'delete' : 'add';

      currentValues[method](value);

      setState({ selectedValues: Array.from(currentValues) });
    },
    [selectedValues, setState],
  );

  const onClose = useCallback(() => {
    const podsToUpdate = contributionMemberPods?.[contributionId]?.reduce((acc, next) => {
      const isInitial = initialSelectedValues.includes(next.id);
      const isSelected = selectedValues.includes(next.id);

      if (isInitial === isSelected) {
        return acc;
      }

      return [...acc, next];
    }, []);

    if (podsToUpdate.length) {
      const promises = podsToUpdate.map(item => {
        const nextClientIds = new Set(item.clientIds);
        const method = nextClientIds.has(memberId) ? 'delete' : 'add';

        nextClientIds[method](memberId);

        return memberPodsService.updateMemberPod({
          clientIds: Array.from(nextClientIds),
          coachId: item.coachId,
          contributionId: item.contributionId,
          name: item.name,
          podId: item.id,
          requestedClientId: memberId,
        });
      });

      Promise.all(promises)
        .then(() => dispatch(MEMBER_PODS_ACTIONS.resetState()))
        .catch(error => dispatch(MEMBER_PODS_ACTIONS.setError(error)));
    }
  }, [contributionMemberPods, contributionId, initialSelectedValues, selectedValues, memberId, dispatch]);

  const onExited = useCallback(() => {
    onBlurElementRef.current.focus();
  }, [onBlurElementRef]);

  return {
    onChange,
    onClose,
    onExited,
  };
};
