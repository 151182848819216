import React from 'react'

import { ChatType } from 'services/chat.service'
import { useConversations } from 'hooks'
import UnreadMessagesCount from './UnreadMessagesCount'

const ClientsConversationsUnreadMessagesCount = () => {
  const { loading, conversationIds } = useConversations(ChatType.directWithClients)

  if (loading || !conversationIds) {
    return null
  }

  return <UnreadMessagesCount conversationIds={conversationIds} />
}

export default ClientsConversationsUnreadMessagesCount
