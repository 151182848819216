import { useState, useEffect, useCallback } from 'react';
import useRouter from './useRouter';
// import { sortBy } from 'lodash';
import useHttp from './http.hook';

function usePreferences() {
  const { request } = useHttp();
  const [preferences, setPreferences] = useState([]);
  const { pathname } = useRouter();
  function sortBy(array, key) {
    return array.sort((a, b) => {
      const nameA = a[key].toUpperCase(); // ignore upper and lowercase
      const nameB = b[key].toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
  }
  useEffect(() => {
    request('/referencedata/preferences')
      .then(res => {
        if (pathname.includes('profile')) {
          setPreferences(res);
        } else {
          let array = sortBy(res, ['name']);
          let categoryArray = [
            ...array,
            {
              name: 'Create Custom Category',
              id: '5ece7dbffd8eaa5db36318eb12',
              updateTime: '2020-05-27T14:48:31.93Z',
              isCustom: false,
              deletionNotAllowed: false,
            },
          ];
          setPreferences(categoryArray);
        }
      })
      /* eslint-disable-next-line */
      .catch(console.dir);
  }, [request]);
  const refetch = async () => {
    request('/referencedata/preferences')
      .then(res => {
        let array = sortBy(res, ['name']);
        let categoryArray = [
          ...array,
          {
            name: 'Create Custom Category',
            id: '5ece7dbffd8eaa5db36318eb12',
            updateTime: '2020-05-27T14:48:31.93Z',
            isCustom: false,
            deletionNotAllowed: false,
          },
        ];
        setPreferences(categoryArray);
      })
      /* eslint-disable-next-line */
      .catch(console.dir);
  };

  return { preferences, setPreferences, refetch };
}

export default usePreferences;
