import React, { useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'

import ParticipantsAudioVolumeContext from './ParticipantsAudioVolumeContext'
import { DEFAULT_AUDIO_VOLUME_LEVEL } from './constants'

const getParticipantsAudioVolume = (participants, currentAudioVolume = {}) =>
  participants.reduce(
    (audioVolume, participant) =>
      participant.sid in audioVolume ? audioVolume : { ...audioVolume, [participant.sid]: DEFAULT_AUDIO_VOLUME_LEVEL },
    currentAudioVolume
  )

const ParticipantsAudioVolumeProvider = ({ participants, children }) => {
  const [participantsAudioVolume, setParticipantsAudioVolume] = useState(() => getParticipantsAudioVolume(participants))
  useEffect(() => setParticipantsAudioVolume((audioVolume) => getParticipantsAudioVolume(participants, audioVolume)), [
    participants,
  ])

  const updateParticipantAudioVolume = useCallback(
    (participant, volume) =>
      setParticipantsAudioVolume((audioVolume) => ({
        ...audioVolume,
        [participant.sid]: volume,
      })),
    []
  )

  const participantsAudioVolumeContextValue = useMemo(
    () => ({
      participantsAudioVolume,
      updateParticipantAudioVolume,
    }),
    [participantsAudioVolume, updateParticipantAudioVolume]
  )

  return (
    <ParticipantsAudioVolumeContext.Provider value={participantsAudioVolumeContextValue}>
      {children}
    </ParticipantsAudioVolumeContext.Provider>
  )
}

ParticipantsAudioVolumeProvider.propTypes = {
  participants: PropTypes.arrayOf(
    PropTypes.shape({
      sid: PropTypes.string,
    })
  ).isRequired,
  children: PropTypes.node.isRequired,
}

export default ParticipantsAudioVolumeProvider
