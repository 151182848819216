import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { Video } from 'components/Video/Video';
import VideoButton from 'components/FormUI/Button';
import useVideoPlayer from 'hooks/useVideoPlayer';
import useContribution from 'pages/ContributionView/hooks/useContribution';
import { handleSelfpacedMediaDownload, handleSelfpacedMediaDelete } from '../../helpers';
import { fetchContributionActions } from 'actions/contributions';
import { useDispatch } from 'react-redux';
import ButtonWraper from 'components/FormUI/Button';
import { useMediaQuery, useTheme } from '@material-ui/core';

const StyledBody = styled.div`
  display: flex;
  /* width: 24rem; */
  flex-direction: column;
  align-items: flex-start;
  gap: 0.875rem;
  padding: 1.25rem ${({ mobileView }) => (mobileView ? 0 : '1.25rem')};
`;

const StyledContainer = styled.div`
  margin: 0;
  width: 100%;
`;

const StyledButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${({ mobileView }) => (mobileView ? 'flex-start' : 'center')};
  gap: 10px;
`;

const SelfPacedAudio = ({ session, sessionTime, sessionTimeIndex, isCoach, colorToUse, textColor }) => {
  const contribution = useContribution();
  const dispatch = useDispatch();
  const { generatePreRecordedVideoLink } = useVideoPlayer({
    sessionTime,
  });
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const videoData = {
    DocumentKeyWithExtension: sessionTime?.prerecordedSession?.documentKeyWithExtension,
    DocumentId: sessionTime?.prerecordedSession?.id,
    ContributionId: contribution.id,
    SessionId: '0',
  };
  const handleDelete = () => {
    handleSelfpacedMediaDelete(videoData, contribution.id).then(result =>
      dispatch(fetchContributionActions.success(result)),
    );
  };

  const handleDownload = () =>
    generatePreRecordedVideoLink({
      contributionId: contribution.id,
      sessionId: session.id,
    }).then(({ videoUrl, fileName }) => handleSelfpacedMediaDownload(videoUrl, fileName));

  return (
    <StyledBody {...{ mobileView }}>
      <Video
        style={{ width: '100%' }}
        isPreviewMode
        isSessionView
        session={session}
        sessionTime={sessionTime}
        sessionTimeIndex={sessionTimeIndex}
      />
      {isCoach && (
        <StyledContainer>
          <StyledButtons {...{ mobileView }}>
            <ButtonWraper
              textColor={colorToUse.PrimaryColorCode}
              borderColor={colorToUse.PrimaryColorCode}
              variant="outline"
              autoWidth
              onClick={handleDownload}
              backgroundColor={contribution?.isDarkModeEnabled ? 'transparent' : null}
            >
              Download
            </ButtonWraper>

            {/* <VideoButton
              textColor={textColor}
              backgroundColor={colorToUse?.PrimaryColorCode}
              autoWidth
              //disabled={buttonState}
              onClick={handleDelete}
            >
              Delete
            </VideoButton> */}
          </StyledButtons>
        </StyledContainer>
      )}
    </StyledBody>
  );
};

export default SelfPacedAudio;
