import styled from 'styled-components';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import 'styles/varibles.scss';
import 'utils/styles/styles.scss';
import { useMediaQuery } from '@material-ui/core';
import { theme } from 'styles/theme';
import ShareRecordingPopup from 'pages/ContributionView/components/ShareModal/Content/ShareRecordingPopup';
import { getThemedColors } from 'services/contributions.service';
import useContribution from 'pages/ContributionView/hooks/useContribution';

const StyledShareRecordingOuterContainer = styled.div`
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
`;

const StyledShareRecordingInnerContainer = styled.div`
  position: fixed;
  z-index: 100;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  width: auto;
  max-height: 90%;
  overflow: auto;
  border-radius: 5px;
  padding: 45px 20px 15px 20px;

  ${({ mobileView }) => mobileView && ` padding: 35px 10px 10px 10px;`}
`;

const StyledShareRecordingTextContainer = styled.div`
  height: 40px;
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 850;
  font-size: 26px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #213649;
  position: absolute;
  top: 0;
  left: 12;
`;

const ShareSessionRecordingContainer = ({
  applyTheming = false,
  contributionId,
  sessionTimeId,
  isPasscodeEnabled,
  setIsPasscodeEnabled,
  onClose,
  passCode,
}) => {
  const contribution = useContribution();

  const mobileView = useMediaQuery(theme.breakpoints.down('xs'));
  const { themedColor, themedCardBackgroundColor } = getThemedColors(contribution);
  const color = applyTheming ? themedColor : '';
  const backgroundColor = applyTheming ? themedCardBackgroundColor : '';

  return (
    <>
      <StyledShareRecordingOuterContainer>
        <StyledShareRecordingInnerContainer style={{ marginTop: '20px', backgroundColor }} mobileView={mobileView}>
          <StyledShareRecordingTextContainer style={{ marginTop: '20px', color, backgroundColor }}>
            Share Recording
          </StyledShareRecordingTextContainer>
          <CloseIcon onClick={onClose} style={{ position: 'absolute', top: 10, right: 10, cursor: 'pointer' }} />
          <ShareRecordingPopup
            applyTheming
            contributionId={contributionId}
            sessionTimeId={sessionTimeId}
            isPasscodeEnabled={isPasscodeEnabled}
            setIsPasscodeEnabled={setIsPasscodeEnabled}
            passCode={passCode}
          />
        </StyledShareRecordingInnerContainer>
      </StyledShareRecordingOuterContainer>
    </>
  );
};
export default ShareSessionRecordingContainer;
