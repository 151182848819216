import React from 'react'
import PropTypes from 'prop-types'
import IconButton from '@material-ui/core/IconButton'
import VideocamIcon from '@material-ui/icons/Videocam'
import VideocamOffIcon from '@material-ui/icons/VideocamOff'

import ToolbarAction from '../ToolbarAction'
import DevicesList, { DeviceType } from './DevicesList'

const Camera = ({ selectedDevice, onSelectedDeviceChange, enabled, onEnabledChange }) => {
  const CameraIcon = enabled ? VideocamIcon : VideocamOffIcon

  return (
    <ToolbarAction
      label={`Video: ${enabled ? 'On' : 'Off'}`}
      endAdornment={
        enabled && (
          <DevicesList
            type={DeviceType.videoInput}
            selectedDevice={selectedDevice}
            onSelectedDeviceChange={onSelectedDeviceChange}
          />
        )
      }
    >
      <IconButton color="inherit" onClick={() => onEnabledChange(!enabled)}>
        <CameraIcon />
      </IconButton>
    </ToolbarAction>
  )
}

Camera.propTypes = {
  selectedDevice: PropTypes.string,
  onSelectedDeviceChange: PropTypes.func.isRequired,
  enabled: PropTypes.bool.isRequired,
  onEnabledChange: PropTypes.func.isRequired,
}

Camera.defaultProps = {
  selectedDevice: null,
}

export default Camera
