import useShallowEqualSelector from './useShallowEqualSelector';

import * as videoChat from 'selectors/videoChat';

const useVideoChat = () => {
  const contributionId = useShallowEqualSelector(videoChat.contributionIdSelector);
  const classId = useShallowEqualSelector(videoChat.classIdSelector);
  const sessionId = useShallowEqualSelector(videoChat.sessionIdSelector);
  const roomId = useShallowEqualSelector(videoChat.roomIdSelector);
  const chatId = useShallowEqualSelector(videoChat.chatIdSelector);
  const type = useShallowEqualSelector(videoChat.typeSelector);
  const token = useShallowEqualSelector(videoChat.tokenSelector);
  const deleteRoomOnVideoEnd = useShallowEqualSelector(videoChat.deleteRoomSelector);
  const title = useShallowEqualSelector(videoChat.titleSelector);
  const creatorId = useShallowEqualSelector(videoChat.creatorIdSelector);
  const recordingStatus = useShallowEqualSelector(videoChat.recordStatusSelector);

  return {
    contributionId,
    classId,
    sessionId,
    roomId,
    chatId,
    type,
    token,
    deleteRoomOnVideoEnd,
    title,
    creatorId,
    recordingStatus,
  };
};

export default useVideoChat;
