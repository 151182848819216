import React, { useState, useEffect, useRef } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import styled from 'styled-components';
import CustomToolBar from './CustomToolBar';
//import file from 'assets/files/file-sample_150kB.pdf';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const StyledLoadingText = styled.div`
  color: ${({ textColor }) => textColor || '#000000'};
  display: flex;
  justify-content: center;
  /* align-items: center; */
  /* height: 100vh; */
  background: ${({ backgroundColor }) => backgroundColor || '#F5F5F5'};
  align-items: flex-start;
  padding-top: 2rem;
`;

const PdfViewer = ({
  pdfSource,
  setIsViewDocument,
  fileName,
  handleDownload,
  loadingScreenBackgroundColor,
  loadingScreenTextColor,
}) => {
  const [viewLoading, setViewLoading] = useState(true);
  const [errorLoad, setErrorLoad] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [scalePercent, setScalePercent] = useState(100);
  const [zoomInDisable, setZoomInDisable] = useState(false);
  const [zoomOutDisable, setZoomOutDisable] = useState(false);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setViewLoading(false);
    setCurrentPage(1);
    setTotalPages(numPages);
  };

  const onDocumentLoadError = error => {
    setErrorLoad(true);
  };
  const handleZoomIn = () => {
    scalePercent <= 25 ? setZoomInDisable(true) : setScalePercent(scalePercent - 25);
  };

  const handleZoomOut = () => {
    scalePercent >= 100 ? setZoomOutDisable(true) : setScalePercent(scalePercent + 25);
  };

  useEffect(() => {
    const onDocumentLoadSuccess = ({ totalPages }) => {
      setTotalPages(totalPages);
    };

    const handleScroll = () => {
      const container = containerRef.current;
      if (!container) return;
      const scrollTop = container.scrollTop;
      const pageHeight = container.scrollHeight / totalPages;
      const currentPageNumber = Math.ceil(scrollTop / pageHeight);
      currentPageNumber === 0 ? setCurrentPage(1) : setCurrentPage(currentPageNumber);
    };

    const container = containerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
    };
  }, [totalPages]);

  const containerRef = useRef(null);
  return (
    <>
      {' '}
      {errorLoad ? (
        <StyledLoadingText
          {...{
            textColor: loadingScreenTextColor || '#000000',
            backgroundColor: loadingScreenBackgroundColor || '#F5F5F5',
          }}
        >
          Failed to Load...
        </StyledLoadingText>
      ) : (
        <>
          {viewLoading ? (
            <>
              <StyledLoadingText
                {...{
                  textColor: loadingScreenTextColor || '#000000',
                  backgroundColor: loadingScreenBackgroundColor || '#F5F5F5',
                }}
              >
                Loading PDF...
              </StyledLoadingText>
            </>
          ) : (
            <>
              <CustomToolBar
                {...{
                  setIsViewDocument,
                  fileName,
                  currentPage,
                  setCurrentPage,
                  totalPages,
                  scalePercent,
                  handleZoomIn,
                  handleZoomOut,
                  zoomInDisable,
                  zoomOutDisable,
                  handleDownload,
                  containerRef,
                }}
              />
            </>
          )}
          <div
            ref={containerRef}
            style={{
              color: loadingScreenTextColor || '#000000',
              overflowY: 'auto',
              display: errorLoad ? 'none' : 'flex',
              justifyContent: 'center',
              height: viewLoading ? '0px' : '765px',
              background: loadingScreenBackgroundColor || '#F5F5F5',
              paddingRight: '17px',
              visibility: viewLoading ? 'hidden' : 'visible',
            }}
          >
            <Document file={pdfSource} onLoadSuccess={onDocumentLoadSuccess} onLoadError={onDocumentLoadError}>
              {[...Array(totalPages)].map((_, index) => (
                <Page key={`page_${index + 1}`} pageNumber={index + 1} scale={scalePercent / 100} />
              ))}
            </Document>
          </div>
        </>
      )}
    </>
  );
};

export default PdfViewer;
