import React from 'react';

const UploadSvg = ({ color = 'black', style = {} }) => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.293 15.4355L12.293 3.39453"
        stroke={color}
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15.2088 4.43555L12.2928 1.50755L9.3768 4.43555"
        stroke={color}
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16.9258 8.12695H17.8588C19.8938 8.12695 21.5428 9.77595 21.5428 11.812V16.696C21.5428 18.726 19.8978 20.371 17.8678 20.371L6.72779 20.371C4.69279 20.371 3.04279 18.721 3.04279 16.686V11.801C3.04279 9.77195 4.68879 8.12695 6.71779 8.12695L7.65979 8.12695"
        stroke={color}
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default UploadSvg;
