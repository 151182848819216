import { handleActions } from 'redux-actions';

import * as actions from 'actions/timeZone';

const initialState = {
  loading: false,
  error: null,
  timeZones: [],
};

const timeZone = handleActions(
  new Map([
    [
      actions.fetchTimeZonesActions.request,
      state => ({
        ...state,
        error: null,
        loading: true,
        timeZones: [],
      }),
    ],
    [
      actions.fetchTimeZonesActions.success,
      (state, action) => ({
        ...state,
        loading: false,
        error: null,
        timeZones: action.payload,
      }),
    ],
    [
      actions.fetchTimeZonesActions.error,
      (state, action) => ({
        ...state,
        loading: false,
        error: action.payload,
        timeZones: [],
      }),
    ],
  ]),
  initialState,
);

export default timeZone;
