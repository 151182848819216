import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';

import { TIMEZONES } from 'constants.js';
import { useMediaQuery } from '@material-ui/core';
import { redirectToAppIfIsCoachDomain } from 'utils/subdomains';
import useRouter from 'hooks/useRouter';

const StyledMainBlock = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 35px 70px 70px;
  ${({ mobileView }) => mobileView && `padding: 35px 20px 20px;`}
`;

const StyledInfoBlock = styled.p`
  padding: 0;
  font-size: 16px;
`;

const sessionDateFormat = 'MMMM Do YYYY hh:mm A';
const sessionTimeFormat = 'hh:mm A';

const BookModalView = ({ session: { title, start, end, serviceProviderName }, timeZoneId }) => {
  const mobileView = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const { domain } = useRouter();

  return (
    <StyledMainBlock mobileView={mobileView}>
      <StyledInfoBlock>
        Please confirm your session time for {title} with {serviceProviderName}
      </StyledInfoBlock>
      <StyledInfoBlock>
        {moment(start).format(sessionDateFormat)} - {moment(end).format(sessionTimeFormat)} {TIMEZONES[timeZoneId]}{' '}
        <br />
        <u
          style={{ cursor: 'pointer' }}
          onClick={() => {
            redirectToAppIfIsCoachDomain(domain, '/account/profile');
          }}
        >
          Update My Timezone
        </u>
      </StyledInfoBlock>
    </StyledMainBlock>
  );
};

BookModalView.propTypes = {
  session: PropTypes.shape({
    title: PropTypes.string,
    start: PropTypes.instanceOf(Date),
    end: PropTypes.instanceOf(Date),
    serviceProviderName: PropTypes.string,
  }),
  timeZoneId: PropTypes.string.isRequired,
};

BookModalView.defaultProps = {
  session: {},
};

export default BookModalView;
