import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { mdiHeart, mdiTabletIpad, mdiRun, mdiAccountOutline } from '@mdi/js';
import { useTheme, useMediaQuery } from '@material-ui/core';
import styled from 'styled-components';
import { replaceBreakToBr, replaceHtmlEscapedSpaceToNormal, wrapText, wrapTextWithColoredLinks } from 'utils/utils';
import { Card, CardBody, CardHeader } from 'components/UI/Card';
import { css } from 'styled-components';
import Icon from 'components/UI/Icon';
import { PageTitleSecond } from 'components/UI/Text/TextStyles';
import TextBlock from './TextBlock';
import { connect } from 'react-redux';
import { determineColorToUse, getThemedColors } from 'services/contributions.service';
import { BsFillHeartFill, BsFillPersonFill, BsFillQuestionCircleFill, BsFillLightbulbFill } from 'react-icons/bs';
import { BiSolidMobile } from 'react-icons/bi';
import { FaMobileButton } from 'react-icons/fa6';

const DetailMainContainer = styled.div`
  margin-top: 60px;
  ${({ mobileView }) =>
    mobileView &&
    css`
      padding: 2rem 0px 0px 0px;
      margin-top: 0px;
    `}
`;
const DetailH1 = styled.h1`
  font-family: 'Poppins';
  color: ${props => props.themedColor};
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  padding: ${props => (props.mobilePadding ? '0px 0px 3rem 0px' : '0px 0px 4rem 0px')};
  // padding: 0px 0px 4rem 0px;
  margin: 0px;
  line-height: 38.4px;
  width: 116px;
  height: 38px;
`;

const DetailSideIcon = styled.img`
  width: 30px;
  height: 30px;
  border-radius: 30px;
  // background-color: ${props => props.themedColor};
  color: ${props => props.themedColor};
`;
const DetailRightTxtMain = styled.div`
  padding: 1rem 0px 1rem 1rem;
  ${({ mobileView }) =>
    mobileView &&
    css`
      justify-content: center;
    `}
  ${({ PaddingMainStyled }) =>
    PaddingMainStyled &&
    css`
      padding: 1rem 0px 1rem 0rem;
    `}
`;
const DetailRighth1 = styled.h4`
  font-style: normal;
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: ${props => props.themedColor};
  ${({ mobileView }) =>
    mobileView &&
    `
  font-size: 16px;
  font-weight: 800;
  padding-left: 1rem;
`}
`;
const DetailP = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${props => props.themedColor};
  ${({ PaddingStyled }) =>
    PaddingStyled &&
    css`
      // padding: 1rem 0px 1rem 0rem;
    `}
  max-width: 100%;
  ${false &&
  css`
    overflow-wrap: break-word; /* Fallback for older browsers */
    word-wrap: break-word; /* Fallback for older browsers */
    overflow-wrap: anywhere; /* Latest syntax, supported in modern browsers */
    word-break: break-all; /* Fallback for older browsers */

    > p {
      max-width: inherit; /* or specify a specific value */
      overflow-wrap: anywhere; /* Latest syntax, supported in modern browsers */
      word-break: break-all; /* Fallback for older browsers */
    }
  `}
`;
const DetailIconDiv = styled.div`
  ${({ ForMobileStyled }) =>
    ForMobileStyled &&
    css`
      display: flex;
      justify-content: center;
      // align-items: flex-start;
      align-items: center;
    `}
`;
const DetailSideIconMain = styled.div`
  ${({ mobileView }) =>
    mobileView &&
    css`
      display: flex;
      align-items: center;
    `}
  display: flex;
  align-items: baseline;
`;

const StyledBsFillLightbulbFill = styled(BsFillLightbulbFill)`
  color: ${({ color }) => color};
  height: 30px;
  width: 30px;

  ${({ mobileView }) =>
    mobileView &&
    `
height: 15px;
width: 15px;
`}
`;

const StyledBsFillHeartFill = styled(BsFillHeartFill)`
  color: ${({ color }) => color};
  height: 30px;
  width: 30px;

  ${({ mobileView }) =>
    mobileView &&
    `
height: 15px;
width: 15px;
`}
`;

const StyledBsFillPersonFill = styled(BsFillPersonFill)`
  color: ${({ color }) => color};
  height: 30px;
  width: 30px;

  ${({ mobileView }) =>
    mobileView &&
    `
height: 15px;
width: 15px;
`}
`;

const StyledBsFillQuestionCircleFill = styled(BsFillQuestionCircleFill)`
  color: ${({ color }) => color};
  height: 30px;
  width: 30px;

  ${({ mobileView }) =>
    mobileView &&
    `
height: 15px;
width: 15px;
`}
`;

const StyledFaMobileButton = styled(FaMobileButton)`
  color: ${({ color }) => color};
  height: 30px;
  width: 30px;

  ${({ mobileView }) =>
    mobileView &&
    `
height: 15px;
width: 15px;
`}
`;

const AboutDetailsBlock = ({
  whoIAm,
  whatWeDo,
  purpose,
  preparation,
  whoIAmLabel,
  whatWeDoLabel,
  purposeLabel,
  preparationLabel,
  viewPreparation,
  viewPurpose,
  viewWhatWeDo,
  viewWhoIAm,
  whoIAmIcon,
  whatWeDoIcon,
  purposeIcon,
  preparationIcon,
  activeContribution,
  brandingColors,
}) => {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'));
  if (!whoIAm && !whatWeDo && !purpose && !preparation) {
    return;
  }

  const testStr = 'coherepublic.s3.amazonaws.com';
  const isCustomWhoIAmIcon = whoIAmIcon?.includes('coherepublic') && whoIAmIcon?.includes('s3.amazonaws.com');
  const isCustomwhatWeDoIcon = whatWeDoIcon?.includes('coherepublic') && whatWeDoIcon?.includes('s3.amazonaws.com');
  const isCustomPurposeIcon = purposeIcon?.includes('coherepublic') && purposeIcon?.includes('s3.amazonaws.com');
  const isCustomPreparationIcon =
    preparationIcon?.includes('coherepublic') && preparationIcon?.includes('s3.amazonaws.com');
  let colorToUse = determineColorToUse(activeContribution);
  const { themedColor } = getThemedColors(activeContribution);

  return (
    <>
      {!mobileView && (
        <DetailMainContainer>
          <DetailH1 style={{ color: themedColor }}>Details</DetailH1>

          {!viewPurpose && !!purpose && (
            <DetailSideIconMain mobileView={mobileView}>
              <DetailIconDiv>
                {isCustomPurposeIcon ? (
                  <DetailSideIcon themedColor={colorToUse?.PrimaryColorCode} src={purposeIcon}></DetailSideIcon>
                ) : (
                  // <Icon path={mdiHeart} right="5" valign="top" />
                  <StyledBsFillHeartFill color={colorToUse?.PrimaryColorCode} />
                )}
              </DetailIconDiv>
              <DetailRightTxtMain>
                <DetailRighth1 themedColor={themedColor}> {purposeLabel}</DetailRighth1>
                <DetailP className="rich-text-contribution" themedColor={themedColor}>
                  {wrapTextWithColoredLinks(
                    replaceBreakToBr(replaceHtmlEscapedSpaceToNormal(purpose)),
                    colorToUse?.PrimaryColorCode,
                  )}
                </DetailP>
              </DetailRightTxtMain>
            </DetailSideIconMain>
          )}

          {!viewWhoIAm && !!whoIAm && (
            <DetailSideIconMain mobileView={mobileView}>
              <DetailIconDiv>
                {isCustomWhoIAmIcon ? (
                  <DetailSideIcon themedColor={colorToUse?.PrimaryColorCode} src={whoIAmIcon}></DetailSideIcon>
                ) : (
                  // <Icon path={mdiAccountOutline} right="5" valign="top" />
                  <StyledBsFillPersonFill color={colorToUse?.PrimaryColorCode} />
                )}
              </DetailIconDiv>
              <DetailRightTxtMain>
                <DetailRighth1 themedColor={themedColor}>{whoIAmLabel}</DetailRighth1>
                <DetailP className="rich-text-contribution" themedColor={themedColor}>
                  {wrapTextWithColoredLinks(
                    replaceBreakToBr(replaceHtmlEscapedSpaceToNormal(whoIAm)),
                    colorToUse?.PrimaryColorCode,
                  )}
                </DetailP>
              </DetailRightTxtMain>
            </DetailSideIconMain>
          )}
          {!viewWhatWeDo && !!whatWeDo && (
            <DetailSideIconMain>
              <DetailIconDiv>
                {isCustomwhatWeDoIcon ? (
                  <DetailSideIcon themedColor={colorToUse?.PrimaryColorCode} src={whatWeDoIcon}></DetailSideIcon>
                ) : (
                  // <Icon path={mdiRun} right="5" valign="top" />
                  <StyledBsFillLightbulbFill color={colorToUse?.PrimaryColorCode} />
                )}
              </DetailIconDiv>
              <DetailRightTxtMain>
                <DetailRighth1 themedColor={themedColor}>{whatWeDoLabel}</DetailRighth1>
                <DetailP className="rich-text-contribution" themedColor={themedColor}>
                  {wrapTextWithColoredLinks(
                    replaceBreakToBr(replaceHtmlEscapedSpaceToNormal(whatWeDo)),
                    colorToUse?.PrimaryColorCode,
                  )}
                </DetailP>
              </DetailRightTxtMain>
            </DetailSideIconMain>
          )}
          {!viewPreparation && !!preparation && (
            <DetailSideIconMain>
              <DetailIconDiv>
                {isCustomPreparationIcon ? (
                  <DetailSideIcon themedColor={colorToUse?.PrimaryColorCode} src={preparationIcon}></DetailSideIcon>
                ) : (
                  // <Icon path={mdiTabletIpad} right="5" valign="top" />
                  <StyledFaMobileButton color={colorToUse?.PrimaryColorCode} />
                )}
              </DetailIconDiv>
              <DetailRightTxtMain>
                <DetailRighth1 themedColor={themedColor}>{preparationLabel}</DetailRighth1>
                <DetailP className="rich-text-contribution" themedColor={themedColor}>
                  {wrapTextWithColoredLinks(
                    replaceBreakToBr(replaceHtmlEscapedSpaceToNormal(preparation)),
                    colorToUse?.PrimaryColorCode,
                  )}
                </DetailP>
              </DetailRightTxtMain>
            </DetailSideIconMain>
          )}
        </DetailMainContainer>
      )}

      {mobileView && (
        <DetailMainContainer mobileView={mobileView}>
          <DetailH1 mobilePadding={'padding: 0px 0px 3rem 0px;'}>Details</DetailH1>
          {!viewPurpose && !!purpose && (
            <DetailSideIconMain>
              <DetailIconDiv ForMobileStyled={true} mobileView={mobileView}>
                {isCustomPurposeIcon ? (
                  <DetailSideIcon themedColor={colorToUse?.PrimaryColorCode} src={purposeIcon}></DetailSideIcon>
                ) : (
                  // <Icon path={mdiHeart} right="5" valign="top" />
                  <StyledBsFillHeartFill color={colorToUse?.PrimaryColorCode} />
                )}{' '}
                <DetailRighth1 themedColor={themedColor} mobileView={mobileView}>
                  {' '}
                  {purposeLabel}
                </DetailRighth1>
              </DetailIconDiv>
            </DetailSideIconMain>
          )}
          <DetailRightTxtMain PaddingMainStyled={true}>
            {!viewPurpose && !!purpose && (
              <>
                <DetailP themedColor={themedColor} PaddingStyled={true} className="rich-text-contribution">
                  {wrapTextWithColoredLinks(
                    replaceBreakToBr(replaceHtmlEscapedSpaceToNormal(purpose)),
                    colorToUse?.PrimaryColorCode,
                  )}
                </DetailP>
              </>
            )}
          </DetailRightTxtMain>
          {!viewWhoIAm && !!whoIAm && (
            <DetailSideIconMain>
              <DetailIconDiv ForMobileStyled={true}>
                {isCustomWhoIAmIcon ? (
                  <DetailSideIcon themedColor={colorToUse?.PrimaryColorCode} src={whoIAmIcon}></DetailSideIcon>
                ) : (
                  // <Icon path={mdiAccountOutline} right="5" valign="top" />
                  <StyledBsFillPersonFill color={colorToUse?.PrimaryColorCode} />
                )}{' '}
                <DetailRighth1 themedColor={themedColor} mobileView={mobileView}>
                  {whoIAmLabel}
                </DetailRighth1>
              </DetailIconDiv>
            </DetailSideIconMain>
          )}
          <DetailRightTxtMain PaddingMainStyled={true}>
            {!viewWhoIAm && !!whoIAm && (
              <>
                <DetailP PaddingStyled={true} className="rich-text-contribution">
                  {wrapTextWithColoredLinks(
                    replaceBreakToBr(replaceHtmlEscapedSpaceToNormal(whoIAm)),
                    colorToUse?.PrimaryColorCode,
                  )}
                </DetailP>
              </>
            )}
          </DetailRightTxtMain>
          {!viewWhatWeDo && !!whatWeDo && (
            <DetailSideIconMain>
              <DetailIconDiv ForMobileStyled={true}>
                {isCustomwhatWeDoIcon ? (
                  <DetailSideIcon themedColor={colorToUse?.PrimaryColorCode} src={whatWeDoIcon}></DetailSideIcon>
                ) : (
                  // <Icon path={mdiRun} right="5" valign="top" />
                  <StyledBsFillLightbulbFill color={colorToUse?.PrimaryColorCode} />
                )}{' '}
                <>
                  <DetailRighth1 themedColor={themedColor} mobileView={mobileView}>
                    {whatWeDoLabel}
                  </DetailRighth1>
                </>
              </DetailIconDiv>
            </DetailSideIconMain>
          )}
          <DetailRightTxtMain PaddingMainStyled={true}>
            {!viewWhatWeDo && !!whatWeDo && (
              <>
                <DetailP themedColor={themedColor} PaddingStyled={true} className="rich-text-contribution">
                  {wrapTextWithColoredLinks(
                    replaceBreakToBr(replaceHtmlEscapedSpaceToNormal(whatWeDo)),
                    colorToUse?.PrimaryColorCode,
                  )}
                </DetailP>
              </>
            )}
          </DetailRightTxtMain>
          {!viewPreparation && !!preparation && (
            <DetailSideIconMain>
              <DetailIconDiv ForMobileStyled={true}>
                {isCustomPreparationIcon ? (
                  <DetailSideIcon themedColor={colorToUse?.PrimaryColorCode} src={preparationIcon}></DetailSideIcon>
                ) : (
                  // <Icon path={mdiTabletIpad} right="5" valign="top" />
                  <StyledFaMobileButton color={colorToUse?.PrimaryColorCode} />
                )}{' '}
                <>
                  <DetailRighth1 themedColor={themedColor} mobileView={mobileView}>
                    {preparationLabel}
                  </DetailRighth1>{' '}
                </>
              </DetailIconDiv>
            </DetailSideIconMain>
          )}
          <DetailRightTxtMain PaddingMainStyled={true}>
            {!viewPreparation && !!preparation && (
              <>
                <DetailP themedColor={themedColor} PaddingStyled={true} className="rich-text-contribution">
                  {wrapTextWithColoredLinks(
                    replaceBreakToBr(replaceHtmlEscapedSpaceToNormal(preparation)),
                    colorToUse?.PrimaryColorCode,
                  )}
                </DetailP>
              </>
            )}
          </DetailRightTxtMain>
        </DetailMainContainer>
      )}
    </>
  );
};

AboutDetailsBlock.propTypes = {
  whoIAm: PropTypes.string,
  whatWeDo: PropTypes.string,
  purpose: PropTypes.string,
  preparation: PropTypes.string,
  whoIAmLabel: PropTypes.string,
  whatWeDoLabel: PropTypes.string,
  purposeLabel: PropTypes.string,
  preparationLabel: PropTypes.string,
};

AboutDetailsBlock.defaultProps = {
  whoIAm: 'None',
  whatWeDo: 'None',
  purpose: 'None',
  preparation: 'None',
  whoIAmLabel: 'Who this is for',
  whatWeDoLabel: 'What to expect',
  purposeLabel: 'Why this was created',
  preparationLabel: 'Additional details',
};

const mapStateToProps = ({ contributions: { activeContribution }, account }) => ({
  activeContribution: activeContribution,
});

export default connect(mapStateToProps)(AboutDetailsBlock);
