import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Grid, makeStyles } from '@material-ui/core';

import useDiscountCodes from 'hooks/useDiscountCodes';

import useRouter from 'hooks/useRouter';

import { deleteDiscountCodes } from 'services/discountCodes.service';
import { setDiscountCode, setDiscountCodes } from 'actions/discountCodes';

const useStyles = makeStyles(() => ({
  button: {
    background: 'inherit',
    border: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));

const CouponItem = ({ coupon, title, isLaunchPlan }) => {
  const { push } = useRouter();
  const dispatch = useDispatch();

  const classes = useStyles();

  let { codes } = useDiscountCodes();

  const onDeleteCouponClick = useCallback(() => {
    deleteDiscountCodes(coupon.id).then(data => {
      if (data?.id?.length > 0) {
        const index = codes?.findIndex(c => c.id === data.id);
        if (index > -1) {
          codes.splice(index, 1);
          dispatch(setDiscountCodes(codes));
        }
      }
    });
  }, []);

  const onEditCouponClick = () => {
    dispatch(setDiscountCode(coupon));
    push('/account/payment/editCoupon');
  };

  return (
    <Grid container item md={12} xs={12} classes={{ root: 'rows' }}>
      <Grid container item md={4} xs={4}>
        {title}
      </Grid>
      <Grid container item md={4} xs={4}>
        <button disabled={isLaunchPlan} type="button" onClick={onEditCouponClick} className={classes.button}>
          Edit
        </button>
      </Grid>
      <Grid container item md={4} xs={4}>
        <button disabled={isLaunchPlan} type="button" onClick={onDeleteCouponClick} className={classes.button}>
          Delete
        </button>
      </Grid>
    </Grid>
  );
};

export default CouponItem;
