import React, { useCallback, useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useStripe, useElements, CardNumberElement } from '@stripe/react-stripe-js';
import { Formik } from 'formik';
import isEmpty from 'lodash/isEmpty';
import {
  calculateSlotsheckIfPurchaseExistsForClient,
  getOneToOneSelfBookClient,
  getSingleContract,
  saveSignoffData,
  setSignedContract,
  updateTimeforSelfBook,
} from 'services/contributions.service';
import { dataURLtoFile, getIpGlobal, getPaymentTypeForUi } from 'utils/utils';
import * as contributionActions from 'actions/contributions';
import { useIsEmailExist } from 'utils/useIsEmailExist';
import { getPurchaseValidationSchema } from 'utils/validation';
import { useAccount, useApplicationStatus, useHttp, useRouter } from 'hooks';
import { LabelText } from 'components/UI/Text/TextStyles';
import { If, mapTo } from 'utils/fp';
import { login, completeRegister, LOG_IN_FAILURE, REGISTER_FAILURE, CONTINUE_REGISTER_FAILURE } from 'actions/user';
import * as purchaseService from 'services/purchase.service';
import { redirectTo } from 'services/links';
import Modal from 'components/UI/Modal';
import { ContributionPurchaseType, ContributionType, PaymentStatus, UserRoles } from 'helpers/constants';
import { fetchClientContribution, FETCH_CONTRIBUTION_SUCCESS, fetchContributionActions } from 'actions/contributions';
import { ModalTermsAndConditions } from 'components/Modals/TermsAndConditions';
import { joinContribution } from 'services/contributions.service';
import isNil from 'lodash/isNil';
import useContribution from '../../hooks/useContribution';
import {
  PURCHASE_MODAL_STEPS,
  PURCHASE_MODAL_TITLES,
  ACCOUNT_FORM_FIELDS,
  DISCOUNT_CODE_FIELDS,
} from './PurchaseModal.constants';
import { PurchaseModalForm } from './PurchaseModalForm';
import { PAYMENT_OPTIONS, ROUTES } from '../../../../constants';
import { SuccessfulPurchaseModal } from 'components/Modals/SuccessfulPurchaseModal';
import { FailedPurchaseModal } from 'components/Modals/FailedPurchaseModal';
import { PAYMENTS_STATUS } from '../PurchaseMembershipModal/PurchaseModal.constants';
import { CustomPurchaseModalFormSingleSession } from './CustomPurchaseModalFormSingleSession';
import { userSelector } from 'selectors/user';
import CreateCustomWaiver from 'pages/CreateContribution/components/CreateCustomWaiver';
import easyBooking from 'reducers/easyBooking.reducer';
import { editUserProfile } from 'services/user.service';
import { updateUser } from 'actions/update-user';
import { setItem } from 'services/localStorage.service';

const PurchaseModalForPaymentCard = ({
  isOpen,
  onClose,
  onSubmit,
  oneToOne,
  paymentDataOneToOne,
  isPackage,
  isMonthlySessionSubscription,
  proseedHandlePurchase,
  updatePackageIntent,
  submitNow,
  showOnlyProseedModal,
  handleUrlModal = true,
  selectedTimeZone,
  isElectronicSignatureActive,
  sessionInfo,
  timeZoneId,
  selectedDate,
  isCouponAvailable,
  isCodeToJoin,
  setfreeAccessCode,
  easyBookingState = false,
  showClientInvitedView,
}) => {
  const [showTerms, setShowTerms] = useState(false);
  const [allowpassword, setallowpassword] = useState(false);
  const [loginErrorMessage, setLoginErrorMessage] = useState('');
  const [creditJoinAllowed, setCreditJoinAllowed] = useState(false);
  const [showFiedlsState, showFileds] = useState(false);
  const priceId = useSelector(state => state.contributions.priceCode);
  const [newUserSignedIn, setNewUserSignedIn] = useState(false);
  const [newUser, setNewUser] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [ip, setIP] = useState('119.152.140.119');
  const [singleSessionInvite, setSingleSessionInvite] = useState(null);
  const [timeZoneArray, setTimeZoneArray] = useState([]);
  const [statesArray, setStatesArray] = useState([]);
  const { timeZones, loading: timeZoneLoading } = useSelector(state => state.timeZone);
  const { states } = useSelector(state => state?.states);
  const { countries, loading: countriesLoading } = useSelector(state => state?.country);
  const { clientData, loadingClientData } = useSelector(state => state?.invitedClient);

  const [paramsforEditUser, setParamsforEditUser] = useState({
    countryId: null,
    TimeZoneId: null,
    stateCode: null,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [showUnableJoin, setshowUnableJoin] = useState(false);
  const { query, history, location, domain } = useRouter();
  const queryCode = query?.code === undefined ? null : query?.code?.includes('p_') ? null : query?.code;
  const { payment } = query;
  const { currentRole } = useAccount();
  const [isWaiverVisible, setIsWaiverVisible] = useState(false);
  const [smsReminderCheckBox, setSmsReminderCheckBox] = useState(true);
  const [showPhoneNumberFields, setshowPhoneNumberFields] = useState(true);
  const [showErrorPhoneNumber, setShowErrorPhoneNumber] = useState(false);
  const [loginStep, setloginStep] = useState(false);
  const [phoneNumberField, setPhoneNumberField] = useState('');
  const [waiverData, setWaiverData] = useState(null);
  const isCoach = currentRole === UserRoles.cohealer;
  const [isAlreadyPuchased, setIsAlreadyPuchased] = useState(false);
  const isInviteToJoin = useSelector(state => state.contributions.inviteCode);
  const pickAnotherTime = useSelector(state => state.contributions.pickAnotherTime);
  const isLoadingAccount = useSelector(({ account }) => account.isLoading);
  const errorAccount = useSelector(({ account }) => account.error?.message);
  const errorContribution = useSelector(({ contributions }) => contributions.error?.message);
  const couponCode = useSelector(state => state?.contributions?.couponCode);
  const [isSigned, setIsSigned] = useState(null);
  const [enrollmentFormSubmitted, setEnrollmentFormSubmitted] = useState(false);
  const firstRender = useRef(true);
  const contribution = useContribution();
  const [isPurchasedBeforeLogin, setIsPurchasedBeforeLogin] = useState(false);
  const dispatch = useDispatch();
  const clearUrlSearch = () => {
    history.replace({
      search: '',
    });
  };

  const [isSuccessPurchaseModalOpen, setIsSuccessPurchaseModalOpen] = useState(
    handleUrlModal && payment === PAYMENTS_STATUS.SUCCESS,
  );
  const [isFailedPurchaseModalOpen, setIsFailedPurchaseModalOpen] = useState(
    handleUrlModal && payment === PAYMENTS_STATUS.FAILED,
  );
  useEffect(() => {
    if (isCodeToJoin) {
      getSelfBookData();
    }
    if (!isEmpty(user)) {
      if (contribution?.smsPermissionsPerContribution?.SessionReminder === true) {
        checkPhoneNumber();
      } else {
        setshowPhoneNumberFields(false);
      }
    } else {
      if (contribution?.smsPermissionsPerContribution?.SessionReminder === true) {
        setshowPhoneNumberFields(true);
      } else {
        setshowPhoneNumberFields(false);
      }
    }
  }, []);
  useEffect(() => {
    if (isEmpty(user) === false) {
      if (contribution.paymentType === 'Simple' && contribution.taxType != 'No' && isInviteToJoin === null) {
        checkProceedStatus();
      }
    }
  }, []);
  const getSelfBookData = () => {
    getOneToOneSelfBookClient(isCodeToJoin.slice(2)).then(response => {
      setSingleSessionInvite(response);
    });
  };

  const saveUserSignature = async values => {
    setIsLoading(true);
    const formData = new FormData();
    const sign = dataURLtoFile(values.clientSignature);
    setWaiverData({
      ...waiverData,
      clientName: values.clientName,
    });
    formData.append('file', sign);
    setIsSigned(formData);
    // setDisableValue(false);
    setIsWaiverVisible(false);
    setIsLoading(false);
  };
  const handleSuccessModalClose = useCallback(() => {
    setIsSuccessPurchaseModalOpen(false);
    clearUrlSearch();
  }, []);
  const handleFailedModalClose = useCallback(() => {
    setIsFailedPurchaseModalOpen(false);
    clearUrlSearch();
  }, []);

  const { user } = useAccount();
  const {
    type,
    id,
    title,
    paymentInfo,
    paymentInfo: { paymentOptions },
    isAccountNeeded,
  } = contribution;

  const paymentType = getPaymentTypeForUi(paymentInfo, query, queryCode);
  const isGuestMode = !history.location.pathname.includes('s_') && !isAccountNeeded && paymentType !== 'PAID';

  const isOneToOneType = type === ContributionType.contributionOneToOne;

  // const modalTitle = isInviteToJoin
  //   ? PURCHASE_MODAL_TITLES[type]?.join(title, !isEmpty(user))
  //   : PURCHASE_MODAL_TITLES[type]?.modal;
  const [step, setStep] = useState(() => {
    if (!isNil(isInviteToJoin)) {
      setNewUser(false);
      return PURCHASE_MODAL_STEPS.join;
    }
    if (!isEmpty(user)) {
      setNewUser(false);
      return PURCHASE_MODAL_STEPS.loggedIn;
    }
    return PURCHASE_MODAL_STEPS.init;
  });

  const setStepValue = useCallback(
    val => {
      setStep(val);
    },
    [step],
  );

  const [lastStep, setLastStep] = useState(step);

  const { checkEmail, isLoadingEmail } = useIsEmailExist();

  const formRef = useRef(null);

  const handleCloseAlreadyPurchased = () => {
    setIsAlreadyPuchased(false);
    onClose();
  };
  const handleCloseCreditJoin = () => {
    setCreditJoinAllowed(false);
    onClose();
  };

  useEffect(() => {
    if (submitNow) {
      // eslint-disable-next-line no-unused-expressions
      formRef.current?.handleSubmit();
    }
  }, [submitNow, formRef]);

  useEffect(() => {
    (async () => {
      const res = await fetch('https://api.ipify.org/?format=json');
      const data = await res.json();
      setIP(data.ip);
    })();
  }, []);

  const isSignSaved = async signature => {
    let isSignedSubmitted = null;
    if (!isCoach) {
      if (contribution.customWaiverId != null) {
        isSignedSubmitted = await submitUserSignature(signature);
      } else {
        isSignedSubmitted = await saveSignature(signature);
      }
    }
    return isSignedSubmitted;
  };

  const submitUserSignature = async val => {
    let isSignedSubmitted = false;
    setLoadingPayment(true);
    const formData = new FormData();
    const sign = dataURLtoFile(val);
    formData.append('file', sign);
    const userIP = await getIpGlobal();
    const details = {
      contributionId: contribution.id,
      contractId: contribution.customWaiverId,
      ipAddress: userIP,
    };
    try {
      setSignedContract(formData, details).then(data => {
        isSignedSubmitted = true;
      });
    } catch (e) {
      isSignedSubmitted = false;
    }
    setLoadingPayment(false);
    return isSignedSubmitted;
  };

  const saveSignature = async val => {
    let returnval = false;
    const formData = new FormData();
    const sign = dataURLtoFile(val);
    formData.append('file', sign);
    const userIP = await getIpGlobal();
    await saveSignoffData(formData, id, userIP)
      .then(res => {
        if (res === 'Signoff data succesfully saved.') {
          returnval = true;
        }
      })
      .catch(err => {});
    return returnval;
  };

  const handleProceedPurchaseStatus = useCallback(async () => {
    const userIp = await getIpGlobal();
    const action = await fetchClientContribution(id, userIp)(dispatch);
    if (action.type === FETCH_CONTRIBUTION_SUCCESS) {
      const isProceedStatus = [PaymentStatus.requiresAction, PaymentStatus.requiresConfirmation].includes(
        action.payload.purchaseStatus,
      );
      if (isProceedStatus) {
        return showOnlyProseedModal();
      }
    } else {
      return null;
    }
  }, [dispatch, id, showOnlyProseedModal]);

  // const stepVerifier = useCallback(
  //   async values => {
  //     if (!isEmpty(user)) {
  //       return true;
  //     }
  //     if (step === PURCHASE_MODAL_STEPS.create) {
  //       const returnedRegisterAction = await completeRegister({ ...values, userView: UserRoles.client })(dispatch);
  //       if (
  //         returnedRegisterAction?.type !== REGISTER_FAILURE &&
  //         returnedRegisterAction?.type !== CONTINUE_REGISTER_FAILURE
  //       ) {
  //         return formRef.current?.handleSubmit();
  //       }
  //       return false;
  //     }
  //     if (step === PURCHASE_MODAL_STEPS.joinCreate) {
  //       const returnedRegisterAction = await completeRegister({ ...values, userView: UserRoles.client })(dispatch);
  //       if (
  //         returnedRegisterAction?.type !== REGISTER_FAILURE &&
  //         returnedRegisterAction?.type !== CONTINUE_REGISTER_FAILURE
  //       ) {
  //         joinContribution({ id, accessCode: isInviteToJoin }).then(() => {
  //           dispatch(
  //             fetchContributionActions.success({
  //               ...contribution,
  //               isPurchased: true,
  //               purchaseStatus: 'succeeded',
  //               subscriptionStatus: { status: 'active' },
  //             }),
  //           );
  //         });
  //       }
  //       return false;
  //     }

  //     if (step === PURCHASE_MODAL_STEPS.login) {
  //       const { Email, Password } = values;
  //       const returnedLoginAction = await login(Email, Password)(dispatch);
  //       if (returnedLoginAction?.type !== LOG_IN_FAILURE) {
  //         if (!oneToOne) {
  //           handleProceedPurchaseStatus();
  //         }
  //         return formRef.current?.handleSubmit();
  //       }
  //       return false;
  //     }
  //     if (step === PURCHASE_MODAL_STEPS.joinLogin) {
  //       const { Email, Password } = values;
  //       const returnedLoginAction = await login(Email, Password)(dispatch);
  //       if (returnedLoginAction?.type !== LOG_IN_FAILURE) {
  //         joinContribution({ id, accessCode: isInviteToJoin })
  //           .then(() => {
  //             dispatch(
  //               fetchContributionActions.success({
  //                 ...contribution,
  //                 isPurchased: true,
  //                 purchaseStatus: 'succeeded',
  //                 subscriptionStatus: { status: 'active' },
  //               }),
  //             );
  //           })
  //           .then(() => {
  //             if (!oneToOne) {
  //               handleProceedPurchaseStatus();
  //             }
  //             return formRef.current?.handleSubmit();
  //           });
  //       }
  //       return false;
  //     }

  //     const isExistEmail = await checkEmail(values[ACCOUNT_FORM_FIELDS.email]);
  //     if (isExistEmail) {
  //       if (isInviteToJoin) {
  //         setStep(PURCHASE_MODAL_STEPS.joinLogin);
  //       } else {
  //         setStep(PURCHASE_MODAL_STEPS.login);
  //       }
  //     } else if (isInviteToJoin) {
  //       setStep(PURCHASE_MODAL_STEPS.joinCreate);
  //     } else {
  //       setStep(PURCHASE_MODAL_STEPS.create);
  //     }
  //     return false;
  //   },
  //   [checkEmail, dispatch, handleProceedPurchaseStatus, oneToOne, step, user, id],
  // );
  const checkPhoneNumber = () => {
    if (!isEmpty(user)) {
      if (!isEmpty(user.globalSmsPermissions) && user.globalSmsPermissions.SessionReminder === true) {
        if (
          !isEmpty(user.subCategorySmsPermissions) &&
          !isEmpty(contribution.subCategorySmsPermissionsPerContribution) &&
          ((user?.subCategorySmsPermissions?.OneHourSession === true &&
            contribution?.subCategorySmsPermissionsPerContribution?.OneHourSession === true) ||
            (user?.subCategorySmsPermissions?.TwentyFourHourSession === true &&
              contribution?.subCategorySmsPermissionsPerContribution?.TwentyFourHourSession === true))
        ) {
          if ((step === 'login' || step === 'joinLogin') && loginStep === false) {
            setshowPhoneNumberFields(true);
            setloginStep(true);
            setStepValue('loggedIn');
            return false;
          }
          if (smsReminderCheckBox === true) {
            if (user?.phoneNo === null || user.phoneNo?.length === 0) {
              if (phoneNumberField.length === 0) {
                setshowPhoneNumberFields(true);
                setShowErrorPhoneNumber(true);
                return false;
              } else {
                if (user?.phoneNo != phoneNumberField) {
                  updatePhoneNumber();
                }
                setshowPhoneNumberFields(false);
                return true;
              }
            } else {
              if (user?.phoneNo != phoneNumberField && phoneNumberField.length > 0) {
                updatePhoneNumber();
              } else if (phoneNumberField.length > 0) {
                setshowPhoneNumberFields(true);
                return true;
              } else {
                setshowPhoneNumberFields(true);
                setShowErrorPhoneNumber(true);
                return false;
              }
              setshowPhoneNumberFields(false);
              return true;
            }
          } else {
            return true;
          }
        } else {
          setshowPhoneNumberFields(false);
          return true;
        }
      } else {
        setshowPhoneNumberFields(false);
        return true;
      }
    } else {
      if (contribution?.smsPermissionsPerContribution?.SessionReminder === true) {
        setshowPhoneNumberFields(true);
      } else {
        setshowPhoneNumberFields(false);
      }

      return true;
    }
  };
  function isCountryNameAvailable(countryName, arrayOfObjects) {
    return arrayOfObjects.some(obj => obj.countryName === countryName);
  }
  const updatePhoneNumber = async () => {
    await request('/Account/UpdatePhoneNo', 'POST', {
      phoneNumber: phoneNumberField,
    }).then(res => {
      let u = {
        ...user,
        phoneNo: phoneNumberField,
      };
      dispatch(updateUser(u));
    });
  };

  const handleAccountNotNeeded = async (values, isExistEmail) => {
    if (isExistEmail) {
      const { Email, details, FirstName, LastName } = values;
      const returnedLoginAction = await login(
        Email.trim(),
        '',
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        true,
      )(dispatch);
      if (returnedLoginAction?.type !== LOG_IN_FAILURE) {
        setItem('helpingMaterial', details);
        setItem('guestFirstName', FirstName);
        setItem('guestLastName', LastName);
        return formRef.current?.handleSubmit();
      }

      const { payload } = returnedLoginAction || {};
      setLoginErrorMessage(payload?.message);
      return false;
    }

    const returnedRegisterAction = await completeRegister({
      ...values,
      PhoneNo: values.phoneNo,
      IsSecondaryAccount: true,
      userView: UserRoles.client,
    })(dispatch);
    if (
      returnedRegisterAction?.type !== REGISTER_FAILURE &&
      returnedRegisterAction?.type !== CONTINUE_REGISTER_FAILURE
    ) {
      const { details, FirstName, LastName } = values;
      setItem('helpingMaterial', details);
      setItem('guestFirstName', FirstName);
      setItem('guestLastName', LastName);
      return formRef.current.handleSubmit();
    }

    return false;
  };

  const stepVerifier = useCallback(
    async values => {
      let { Signature } = values;
      if (isSigned !== null) {
        Signature = isSigned;
      }

      if (!isEmpty(user)) {
        if (user?.id === contribution?.userId) {
          history.push(ROUTES.ROLE_SWITCH, { path: location.pathname, domain: domain });
          return false;
        }
        if (
          (contribution?.isCustomEnrollmentFormAdded ||
            (contribution?.isPhoneNumberAdded && contribution?.enrollmentFormViewModel?.questions?.length > 0)) &&
          !enrollmentFormSubmitted &&
          step !== PURCHASE_MODAL_STEPS.enrollmentForm
        ) {
          setStep(PURCHASE_MODAL_STEPS.enrollmentForm);
          return false;
        }
        if (isElectronicSignatureActive && Signature && step === PURCHASE_MODAL_STEPS.loggedIn) {
          const signSaved = await isSignSaved(Signature);
          if (signSaved || isSigned) {
            return true;
          } else {
            return false;
          }
        }
        return true;
      }

      if (step === PURCHASE_MODAL_STEPS.create) {
        setNewUser(true);
        setNewUserSignedIn(true);
        if (values.TimeZoneId === 'Select Timezone') {
          return;
        }
        const returnedRegisterAction = await completeRegister({
          ...values,
          PhoneNo: values.phoneNo,
          userView: UserRoles.client,
        })(dispatch);
        if (
          returnedRegisterAction?.type !== REGISTER_FAILURE &&
          returnedRegisterAction?.type !== CONTINUE_REGISTER_FAILURE
        ) {
          if (
            (contribution?.isCustomEnrollmentFormAdded ||
              (contribution?.isPhoneNumberAdded && contribution?.enrollmentFormViewModel?.questions?.length > 0)) &&
            !enrollmentFormSubmitted &&
            step !== PURCHASE_MODAL_STEPS.enrollmentForm
          ) {
            setStep(PURCHASE_MODAL_STEPS.enrollmentForm);
            return false;
          }
          if (isElectronicSignatureActive && Signature) {
            const signSaved = await isSignSaved(Signature);
            if (signSaved) {
              return formRef.current?.handleSubmit();
            } else {
              return false;
            }
          }

          return formRef.current?.handleSubmit();
        }
        return false;
      }
      if (step === PURCHASE_MODAL_STEPS.joinCreate) {
        setNewUser(true);
        setNewUserSignedIn(true);
        dispatch({
          type: 'PURCHASE_DUMMY',
          payload: false,
        });
        if (values.TimeZoneId === 'Select Timezone') {
          return;
        }
        const returnedRegisterAction = await completeRegister({
          ...values,
          PhoneNo: values.phoneNo,
          userView: UserRoles.client,
        })(dispatch);
        if (
          returnedRegisterAction?.type !== REGISTER_FAILURE &&
          returnedRegisterAction?.type !== CONTINUE_REGISTER_FAILURE
        ) {
          if (
            (contribution?.isCustomEnrollmentFormAdded ||
              (contribution?.isPhoneNumberAdded && contribution?.enrollmentFormViewModel?.questions?.length > 0)) &&
            !enrollmentFormSubmitted &&
            step !== PURCHASE_MODAL_STEPS.joinEnrollmentForm
          ) {
            setStep(PURCHASE_MODAL_STEPS.joinEnrollmentForm);
            return false;
          }
          if (isElectronicSignatureActive && Signature) {
            const signSaved = await isSignSaved(Signature);
            if (signSaved) {
              if (isOneToOneType) {
                return formRef.current?.handleSubmit();
              }
              joinContribution({ id, accessCode: isInviteToJoin })
                .then(() => {
                  dispatch(
                    fetchContributionActions.success({
                      ...contribution,
                      isPurchased: true,
                      purchaseStatus: 'succeeded',
                      subscriptionStatus: { status: 'active' },
                    }),
                  );
                  dispatch({
                    type: 'PURCHASE_DUMMY',
                    payload: true,
                  });
                })
                .then(() => {
                  // onJoin(true);
                });
            } else {
              return false;
            }
          }
          if (isOneToOneType) {
            if (isCodeToJoin) {
              // if (selectedDate) {
              //   updateTimeforSelfBook({
              //     id: isCodeToJoin.slice(2),
              //     contributionId: id,
              //     clientId: returnedRegisterAction?.payload?.user.id,
              //     availabilityTimeId: singleSessionInvite?.availabilityTimeId,
              //     selectedSlotStartDateTime: selectedDate.start,
              //     selectedSlotEndDateTime: selectedDate.end,
              //     updatedAvailabilityTimeId: selectedDate.id,
              //     accessCode: singleSessionInvite.accessCode,
              //     otherTimePicked: true,
              //     isNewUser: newUser,
              //   });
              // } else {
              //   updateTimeforSelfBook({
              //     id: isCodeToJoin.slice(2),
              //     contributionId: id,
              //     clientId: returnedRegisterAction?.payload?.user.id,
              //     availabilityTimeId: singleSessionInvite?.availabilityTimeId,
              //     otherTimePicked: false,
              //     isNewUser: newUser,
              //   });
              // }
              setfreeAccessCode(singleSessionInvite.accessCode);
            }
            return formRef.current?.handleSubmit();
          }
          joinContribution({ id, accessCode: isInviteToJoin })
            .then(() => {
              dispatch(
                fetchContributionActions.success({
                  ...contribution,
                  isPurchased: true,
                  purchaseStatus: 'succeeded',
                  subscriptionStatus: { status: 'active' },
                }),
              );
              dispatch({
                type: 'PURCHASE_DUMMY',
                payload: true,
              });
            })
            .then(() => {
              // onJoin(true);
            });
        }
        return false;
      }
      if (step === PURCHASE_MODAL_STEPS.login) {
        const { Email, Password, otp } = values;
        const returnedLoginAction = await login(
          Email.trim(),
          Password,
          null,
          null,
          null,
          null,
          null,
          null,
          otp,
        )(dispatch);
        if (returnedLoginAction?.type !== LOG_IN_FAILURE) {
          // we shouldn't redirect in the login process, redirect only after purchasing
          // the return url for successful and unsuccessful payment is set in the api when creating stripe checkout
          // await handleProceedPurchaseStatus();
          if (
            (contribution?.isCustomEnrollmentFormAdded ||
              (contribution?.isPhoneNumberAdded && contribution?.enrollmentFormViewModel?.questions?.length > 0)) &&
            !enrollmentFormSubmitted &&
            step !== PURCHASE_MODAL_STEPS.enrollmentForm
          ) {
            setStep(PURCHASE_MODAL_STEPS.enrollmentForm);
            return false;
          }
          if (isElectronicSignatureActive && Signature) {
            const signSaved = await isSignSaved(Signature);
            if (signSaved) {
              return formRef.current?.handleSubmit();
            } else {
              return false;
            }
          }

          return formRef.current?.handleSubmit();
        }

        const { payload } = returnedLoginAction || {};

        setLoginErrorMessage(payload?.message);

        return false;
      }
      if (step === PURCHASE_MODAL_STEPS.joinLogin) {
        const { Email, Password, otp } = values;
        const returnedLoginAction = await login(
          Email.trim(),
          Password,
          null,
          null,
          null,
          true,
          null,
          contribution?.userId,
          otp,
        )(dispatch);
        if (returnedLoginAction?.type !== LOG_IN_FAILURE) {
          if (
            (contribution?.isCustomEnrollmentFormAdded ||
              (contribution?.isPhoneNumberAdded && contribution?.enrollmentFormViewModel?.questions?.length > 0)) &&
            !enrollmentFormSubmitted &&
            step !== PURCHASE_MODAL_STEPS.joinEnrollmentForm
          ) {
            setStep(PURCHASE_MODAL_STEPS.joinEnrollmentForm);
            return false;
          }
          if (isElectronicSignatureActive && Signature) {
            const signSaved = await isSignSaved(Signature);
            if (signSaved) {
              if (isOneToOneType) {
                return formRef.current?.handleSubmit();
              }
              joinContribution({ id, accessCode: isInviteToJoin })
                .then(() => {
                  dispatch(
                    fetchContributionActions.success({
                      ...contribution,
                      isPurchased: true,
                      purchaseStatus: 'succeeded',
                      subscriptionStatus: { status: 'active' },
                    }),
                  );
                })
                .then(() => {
                  handleProceedPurchaseStatus();
                  return formRef.current?.handleSubmit();
                });
            } else {
              return false;
            }
          }
          if (isOneToOneType) {
            return formRef.current?.handleSubmit();
          }
          joinContribution({ id, accessCode: isInviteToJoin })
            .then(() => {
              dispatch(
                fetchContributionActions.success({
                  ...contribution,
                  isPurchased: true,
                  purchaseStatus: 'succeeded',
                  subscriptionStatus: { status: 'active' },
                }),
              );
            })
            .then(() => {
              handleProceedPurchaseStatus();
              return formRef.current?.handleSubmit();
            });
        }
        return false;
      }
      const isExistEmail = await checkEmail(values[ACCOUNT_FORM_FIELDS.email].trim());
      setallowpassword(isExistEmail && !isExistEmail?.isSecondaryAccount);

      setItem('existingUser', isExistEmail.emailExists || isExistEmail.isSecondaryAccount);

      if (contribution?.isCustomEnrollmentFormAdded) {
        const isPurchased = await calculateSlotsheckIfPurchaseExistsForClient(values?.Email.trim(), contribution.id);
        setIsPurchasedBeforeLogin(isPurchased);
      }
      if (isExistEmail && !isGuestMode) {
        if (
          isExistEmail.phNumberRequired === true &&
          contribution?.smsPermissionsPerContribution?.SessionReminder === true
        ) {
          setshowPhoneNumberFields(true);
        }
        if (
          isExistEmail.phNumberRequired === false &&
          contribution?.smsPermissionsPerContribution?.SessionReminder === true
        ) {
          setshowPhoneNumberFields(false);
        }
        if (isInviteToJoin || queryCode) {
          setLastStep(step);
          setStep(PURCHASE_MODAL_STEPS.joinLogin);
        } else {
          setLastStep(step);
          setStep(PURCHASE_MODAL_STEPS.login);
        }
      } else if (isInviteToJoin || queryCode) {
        if (isGuestMode) {
          return handleAccountNotNeeded(values, isExistEmail);
        }
        setLastStep(step);
        setStep(PURCHASE_MODAL_STEPS.joinCreate);
      } else {
        if (isGuestMode) {
          return handleAccountNotNeeded(values, isExistEmail);
        }
        setLastStep(step);
        setStep(PURCHASE_MODAL_STEPS.create);
      }
      return false;
    },
    [
      user,
      step,
      checkEmail,
      isInviteToJoin,
      dispatch,
      id,
      query.code,
      contribution,
      // onJoin,
      handleProceedPurchaseStatus,
      isSigned,
      clientData,
    ],
  );

  const stripe = useStripe();
  const elements = useElements();
  const { request, loading } = useHttp();
  const [typeOfPayment, setTypeOfPayment] = useState(
    oneToOne && paymentOptions.includes(PAYMENT_OPTIONS.PER_SESSION)
      ? PAYMENT_OPTIONS.PER_SESSION
      : paymentOptions.sort()[0],
  );
  const [summary, setSummary] = useState(null);
  const [coupan_id, setcoupan_id] = useState(null);
  const summaryRef = useRef();
  summaryRef.current = summary;
  const [name, setName] = useState(null);
  const [loadingPayment, setLoadingPayment] = useState(false);
  const [error, setError] = useState(null);

  const handlePayResponse = useCallback(
    response => {
      setLoadingPayment(false);

      if (response.error) {
        setError(response.error.message);
      } else {
        onSubmit();
      }
    },
    [onSubmit],
  );

  // Payment method
  const payWith3DSecure = useCallback(
    (...args) => {
      setLoadingPayment(true);

      stripe
        .confirmCardPayment(...args)
        .then(handlePayResponse)
        .catch(console.dir);
    },
    [stripe, handlePayResponse],
  );

  // Entire payment flow: prepareEntire => payEntireCourse => confirm 3d secure
  const payEntireCourse = useCallback(
    data => {
      const { clientSecret } = data;
      const card = elements.getElement(CardNumberElement);

      payWith3DSecure(clientSecret, {
        payment_method: {
          card,
          billing_details: { name },
        },
      });
    },
    [elements, name, payWith3DSecure],
  );

  // TODO: change namings
  const prepareEntire = useCallback(
    data => {
      let apiUrl = '/api/purchase/course/checkout';
      if (oneToOne) {
        apiUrl = '/api/purchase/one-to-one';
        data = {
          ...data,
          isPhoneNumberEnabledSession: contribution.isPhoneNumberAdded,
          isPhoneNumberAdded: contribution.isPhoneNumberAdded,
          isCoachNumberAdded: contribution.isCoachNumberAdded,
          priceId: priceId,
        };
        if (isMonthlySessionSubscription) {
          apiUrl += '/monthly-session-subscription';
        } else if (isPackage) {
          apiUrl += '/package';
        }
      }
      setLoadingPayment(true);
      request(apiUrl, 'POST', data)
        // .then(payEntireCourse)
        .then(res => {
          handleProceedPurchaseStatus();
          // handle 100% discount without checkout
          if (
            res === '100discount' ||
            res === 'Free session joined successfully.' ||
            isInviteToJoin ||
            (isCodeToJoin &&
              (singleSessionInvite?.priceOption === 'Free' || singleSessionInvite?.priceOption === 'Credit'))
          ) {
            // quick solution:redirect; todo: update state with purchased contribution and hide purchase modal
            setTimeout(() => {
              // window.location.reload();
              handleProceedPurchaseStatus();
              dispatch(contributionActions.setSingleCode(null));
              window.location.replace(`/contribution-view/${id}/sessions`);
            }, 1000);
            // handleProceedPurchaseStatus();
            // setLoadingPayment(false);
          } else if (res?.bookedSessionNumbers || res?.sessionNumbers || res?.freeSessionNumbers) {
            window.location.replace(`/contribution-view/${id}/sessions`);
            // dispatch(contributionActions.setSingleCode(null));
          } else {
            if (contribution?.paymentType === 'Advance') {
              return redirectTo(res);
            } else {
              return stripe.redirectToCheckout({ sessionId: res });
            }
          }
          // dispatch(contributionActions.setSingleCode(null));
        })
        .catch(e => {
          if (e?.response?.data.includes('You are not booked for this')) {
            setErrorModal(true);
          }
          if (e?.response?.status === 400) {
            if (isInviteToJoin) {
              window.location.reload();
            } else {
              // return setIsAlreadyPuchased(true);
            }
          }
          console.dir(e);
        });
      setLoadingPayment(false);
      // dispatch(contributionActions.setSingleCode(null));
    },
    [request /*, payEntireCourse*/],
  );

  // Split payments flow: createTokenForSplitPayments => attachPaymentMethodByToken => subscribe => confirm 3d secure
  const subscribe = useCallback(
    ({ paymentMethodId }) => {
      const data = {
        paymentMethodId,
        paymentOptions: isMonthlySessionSubscription ? 'MonthlySessionSubscription' : 'SplitPayments',
        paymentOption: isMonthlySessionSubscription ? 'MonthlySessionSubscription' : 'SplitPayments',
        contributionId: contribution.id,
        couponId: coupan_id ? coupan_id : summaryRef?.current?.coupon?.id,
      };

      const purchaseType = oneToOne ? ContributionPurchaseType.oneToOne : ContributionPurchaseType.course;
      const paymentOption = isMonthlySessionSubscription ? '/monthly-session-subscription' : '';
      const paymentUrl = `/api/purchase/${purchaseType}${paymentOption}`;

      request(paymentUrl, 'POST', data)
        .then(res => {
          if (isMonthlySessionSubscription) {
            payWith3DSecure(res.clientSecret);
          }
        })
        .catch(console.dir);
    },
    [request, contribution.id, payWith3DSecure, oneToOne, isMonthlySessionSubscription],
  );

  const attachPaymentMethodByToken = useCallback(
    cardToken => {
      request('/api/payment/attach-customer-payment-method-token', 'POST', {
        cardToken,
        contributionId: contribution.id,
      })
        .then(subscribe)
        .catch(console.dir);
    },
    [request, subscribe],
  );

  // TODO: change this to use stripe checkout
  const createTokenForSplitPayments = useCallback(() => {
    const card = elements.getElement(CardNumberElement);
    stripe.createToken(card).then(res => {
      if (!res.error) {
        attachPaymentMethodByToken(res.token.id);
      }
    });
  }, [stripe, elements, attachPaymentMethodByToken]);

  // Submitting form when user clicks on purchase button
  const handleSubmit = useCallback(
    async values => {
      const isAutoAddEnabled = clientData?.autoAddEnabled ?? false;
      formRef.current.setTouched({});
      const isCanProceed = await stepVerifier(values);
      if (!isCanProceed) {
        return;
      }
      if (contribution?.smsPermissionsPerContribution?.SessionReminder === true) {
        const phoneNumberAdded = checkPhoneNumber();
        if (!phoneNumberAdded) {
          setshowPhoneNumberFields(true);
          setLoadingPayment(false);
          if (phoneNumberField.length === 0) {
            setShowErrorPhoneNumber(true);
          }
          return;
        }
      }
      if (
        contribution.paymentType === 'Simple' &&
        contribution.taxType != 'No' &&
        isInviteToJoin === null &&
        newUserSignedIn === false &&
        ((contribution.paymentInfo.paymentOptions.length === 1 &&
          contribution.paymentInfo.paymentOptions.includes('Free')) ||
          (contribution.paymentInfo.paymentOptions.length === 1 &&
            contribution.paymentInfo.paymentOptions.includes('FreeSessionsPackage')) ||
          (contribution.paymentInfo.paymentOptions.length === 2 &&
            contribution.paymentInfo.paymentOptions.includes('FreeSessionsPackage') &&
            contribution.paymentInfo.paymentOptions.includes('Free'))) === false
      ) {
        const isCheckCountry = await checkCountry(values);
        if (!isCheckCountry) {
          setLoadingPayment(false);
          return;
        } else {
          await updateUserCall();
        }
      }
      if (
        contribution.customWaiverId != null &&
        isElectronicSignatureActive === true &&
        !isAutoAddEnabled &&
        (singleSessionInvite?.priceOption != 'Credit' || singleSessionInvite?.priceOption === null)
      ) {
        getWaiverForm();
      } else {
        if (isCodeToJoin != null && pickAnotherTime != null) {
          updateTimeforSelfBook({
            id: isCodeToJoin.slice(2),
            contributionId: id,
            clientId: singleSessionInvite.clientId,
            availabilityTimeId: singleSessionInvite?.availabilityTimeId,
            selectedSlotStartDateTime: pickAnotherTime.start,
            selectedSlotEndDateTime: pickAnotherTime.end,
            updatedAvailabilityTimeId: pickAnotherTime.updatedTimeId,
            accessCode: singleSessionInvite.accessCode,
            clientName: singleSessionInvite.clientName,
            priceId: priceId,
            otherTimePicked: true,
            isNewUser: newUser,
          }).then(res => {
            if (isCodeToJoin && singleSessionInvite?.priceOption === 'Free') {
              prepareEntire({
                AvailabilityTimeId: pickAnotherTime.updatedTimeId,
                contributionId: singleSessionInvite?.contributionId,
                offset: singleSessionInvite?.offset,
                accessCode: singleSessionInvite?.accessCode,
                paymentOption: singleSessionInvite?.priceOption,
                priceId: priceId,
                createSingleSession: true,
                couponId: summaryRef?.current?.coupon?.id || couponCode,
              });
            } else if (isCodeToJoin && singleSessionInvite?.priceOption === 'Credit') {
              if (singleSessionInvite.clientId === user.id) {
                prepareEntire({
                  AvailabilityTimeId: pickAnotherTime.updatedTimeId,
                  contributionId: singleSessionInvite?.contributionId,
                  offset: singleSessionInvite?.offset,
                  priceId: priceId,
                });
              } else {
                setCreditJoinAllowed(true);
              }
            } else if (
              isCodeToJoin &&
              singleSessionInvite?.priceOption != 'Free' &&
              singleSessionInvite?.priceOption != 'Credit'
            ) {
              prepareEntire({
                AvailabilityTimeId: pickAnotherTime.updatedTimeId,
                contributionId: singleSessionInvite?.contributionId,
                offset: singleSessionInvite?.offset,
                paymentOption: singleSessionInvite?.priceOption,
                createSingleSession: true,
                couponId: couponCode,
              });
            }
          });
          dispatch(contributionActions.setPickAnotherTime(null));
          if (newUser === false) {
            dispatch(contributionActions.setSingleCode(null));
          }
          return;
        }
        if (proseedHandlePurchase) {
          return proseedHandlePurchase(summary?.coupon?.id ? summary?.coupon?.id : null);
        }

        setName(values.Name);
        setError(null);
        if (oneToOne) {
          if (isMonthlySessionSubscription) {
            createTokenForSplitPayments();
            return;
          } else if (isPackage) {
            payEntireCourse(paymentDataOneToOne);
            return;
          }
          if (isCodeToJoin) {
            if (selectedDate) {
              updateTimeforSelfBook({
                id: isCodeToJoin.slice(2),
                contributionId: id,
                clientId: singleSessionInvite.clientId,
                availabilityTimeId: singleSessionInvite?.availabilityTimeId,
                selectedSlotStartDateTime: selectedDate.start,
                selectedSlotEndDateTime: selectedDate.end,
                updatedAvailabilityTimeId: selectedDate.id,
                accessCode: singleSessionInvite.accessCode,
                clientName: singleSessionInvite.clientName,
                priceId: priceId,
                otherTimePicked: true,
                isNewUser: newUser,
              }).then(res => {
                if (isCodeToJoin && singleSessionInvite?.priceOption === 'Free') {
                  prepareEntire({
                    AvailabilityTimeId: selectedDate.id,
                    contributionId: singleSessionInvite?.contributionId,
                    offset: singleSessionInvite?.offset,
                    accessCode: singleSessionInvite?.accessCode,
                    paymentOption: singleSessionInvite?.priceOption,
                    createSingleSession: true,
                    priceId: priceId,
                    couponId: summaryRef?.current?.coupon?.id || couponCode,
                  });
                } else if (isCodeToJoin && singleSessionInvite?.priceOption === 'Credit') {
                  if (singleSessionInvite.clientId === user.id) {
                    prepareEntire({
                      AvailabilityTimeId: selectedDate.id,
                      contributionId: singleSessionInvite?.contributionId,
                      offset: singleSessionInvite?.offset,
                      priceId: priceId,
                    });
                  } else {
                    setCreditJoinAllowed(true);
                  }
                } else if (
                  isCodeToJoin &&
                  singleSessionInvite?.priceOption != 'Free' &&
                  singleSessionInvite?.priceOption != 'Credit'
                ) {
                  prepareEntire({
                    AvailabilityTimeId: selectedDate.id,
                    contributionId: singleSessionInvite?.contributionId,
                    offset: singleSessionInvite?.offset,
                    paymentOption: singleSessionInvite?.priceOption,
                    createSingleSession: true,
                    priceId: priceId,
                    couponId: couponCode,
                  });
                }
              });
              return;
            } else {
              updateTimeforSelfBook({
                id: isCodeToJoin.slice(2),
                contributionId: id,
                clientId: user.id,
                availabilityTimeId: singleSessionInvite?.availabilityTimeId,
                clientName: singleSessionInvite.clientName,
                otherTimePicked: false,
                isNewUser: newUser,
              }).then(res => {
                if (isCodeToJoin && singleSessionInvite?.priceOption === 'Free') {
                  prepareEntire({
                    AvailabilityTimeId: singleSessionInvite?.availabilityTimeId,
                    contributionId: singleSessionInvite?.contributionId,
                    offset: singleSessionInvite?.offset,
                    accessCode: singleSessionInvite?.accessCode,
                    paymentOption: singleSessionInvite?.priceOption,
                    createSingleSession: true,
                    couponId: summaryRef?.current?.coupon?.id || couponCode,
                    priceId: priceId,
                  });
                } else if (isCodeToJoin && singleSessionInvite?.priceOption === 'Credit') {
                  if (singleSessionInvite.clientId === user.id) {
                    prepareEntire({
                      AvailabilityTimeId: singleSessionInvite?.availabilityTimeId,
                      contributionId: singleSessionInvite?.contributionId,
                      offset: singleSessionInvite?.offset,
                      priceId: priceId,
                    });
                  } else {
                    setCreditJoinAllowed(true);
                  }
                } else if (
                  isCodeToJoin &&
                  singleSessionInvite?.priceOption != 'Free' &&
                  singleSessionInvite?.priceOption != 'Credit'
                ) {
                  prepareEntire({
                    AvailabilityTimeId: singleSessionInvite?.availabilityTimeId,
                    contributionId: singleSessionInvite?.contributionId,
                    offset: singleSessionInvite?.offset,
                    paymentOption: singleSessionInvite?.priceOption,
                    couponId: summaryRef?.current?.coupon?.id || couponCode,
                    createSingleSession: true,
                    priceId: priceId,
                  });
                }
              });
            }
            setfreeAccessCode(singleSessionInvite.accessCode);
          } else {
            prepareEntire({
              ...paymentDataOneToOne,
              couponId: summaryRef?.current?.coupon?.id || couponCode,
              createSingleSession: true,
              priceId: priceId,
            });
          }
        }
        If(
          typeOfPayment === 'EntireCourse' ||
            typeOfPayment === 'SplitPayments' ||
            (type === ContributionType.contributionOneToOne && typeOfPayment === 'Free'),
        )
          .then(
            mapTo(
              isInviteToJoin
                ? {
                    contributionId: contribution.id,
                    paymentOption: typeOfPayment,
                    couponId: summaryRef?.current?.coupon?.id || couponCode,
                    accessCode: isInviteToJoin,
                    priceId: priceId,
                  }
                : {
                    contributionId: contribution.id,
                    paymentOption: typeOfPayment,
                    couponId: summaryRef?.current?.coupon?.id || couponCode,
                    priceId: priceId,
                  },
            ),
          )
          .then(prepareEntire)
          .else(createTokenForSplitPayments);
      }
    },
    [
      stepVerifier,
      proseedHandlePurchase,
      oneToOne,
      typeOfPayment,
      contribution.id,
      prepareEntire,
      createTokenForSplitPayments,
      // payEntireCourse,
      paymentDataOneToOne,
      singleSessionInvite,
      setName,
      isSigned,
      showPhoneNumberFields,
      phoneNumberField,
      clientData,
    ],
  );

  const getWaiverForm = async () => {
    setIsLoading(true);
    let email = ' ';
    if (history.location.pathname.includes('s_')) {
      email = formRef.current.values.Email || user?.email;
    }
    try {
      getSingleContract(contribution?.id, email).then(data => {
        setWaiverData({
          ...waiverData,
          formName: data?.formName,
          formDescription: data?.formDescription,
          formText: data?.formText,
          description: data?.formDescription,
          templateId: data.id,
          id: data.id,
          completeDescription: data?.formText,
        });
        setIsWaiverVisible(true);
        setIsLoading(false);
      });
    } catch (e) {
      setIsLoading(false);
    }
    setIsLoading(false);
  };
  // TODO : check if needed and fix
  useEffect(() => {
    if (payment === PAYMENTS_STATUS.SUCCESS) {
      setLoadingPayment(false);
      onSubmit();
    }
  }, [payment]);

  // Get summary for payment
  useEffect(() => {
    setError(null);
    getSummary();
  }, [typeOfPayment, contribution.id, oneToOne, isPackage, isMonthlySessionSubscription]);
  const { applicationRequiredButNotApproved, isApplicationNeedsToBeSubmitted } = useApplicationStatus({
    contribution,
    user,
  });
  const getSubmitButtonTitle = () => {
    if ((isEmpty(user) && step === PURCHASE_MODAL_STEPS.init) || step === PURCHASE_MODAL_STEPS.join) {
      return !isEmpty(user) && isInviteToJoin ? 'Ok' : 'Next';
    }
    if (isPurchasedBeforeLogin) {
      return 'Next';
    }
    if (location?.pathname.includes('apply-contribution-view') && isApplicationNeedsToBeSubmitted) {
      return 'Apply Now';
    }
    return PURCHASE_MODAL_TITLES[type].submit;
  };
  const checkCountry = async values => {
    const res = await fetch('https://geolocation-db.com/json/');
    const data = await res.json();
    const currentCountry = countries.find(obj => obj.name === data.country_name);
    // const currentCountry = { id: '60b8ddb57205057e7ce2b861' };

    if (currentCountry.id != formRef.current.values.countryId) {
      if (currentCountry.id === '60b8ddb57205057e7ce2b861') {
        const filteredStates = states.filter(obj => obj.countryId === formRef.current.values.countryId);
        formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.country, currentCountry.id);
        setStatesArray(filteredStates);
        setTimeZoneArray(timeZones);
        if (
          formRef?.current?.values?.TimeZoneId?.length > 0 &&
          formRef?.current?.values?.TimeZoneId != null &&
          formRef?.current?.values?.stateCode?.length > 0 &&
          formRef?.current?.values?.stateCode != null
        ) {
          formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.timeZoneId, values.TimeZoneId);
          formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.state, values.stateCode);
        } else {
          return false;
        }
        return true;
      } else {
        const filteredTimezone = timeZones.filter(obj => obj?.countryId === currentCountry.id);
        if (filteredTimezone.length === 1) {
          formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.country, currentCountry.id);
          formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.timeZoneId, filteredTimezone[0].countryName);
          formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.state, null);
          setTimeZoneArray(filteredTimezone);
          setLoadingPayment(false);
          setParamsforEditUser({
            countryId: formRef.current.values.countryId,
            TimeZoneId: filteredTimezone[0].countryName,
            stateCode: null,
          });
          return true;
        } else if (filteredTimezone.length > 1) {
          formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.country, currentCountry.id);
          formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.state, null);
          setTimeZoneArray(filteredTimezone);
          setParamsforEditUser({ countryId: currentCountry.id, TimeZoneId: null, stateCode: null });
          setLoadingPayment(false);
          if (formRef?.current?.values?.TimeZoneId?.length > 0 && formRef?.current?.values?.TimeZoneId != null) {
            let checkTimeZone = isCountryNameAvailable(formRef.current.values.TimeZoneId, filteredTimezone);
            if (checkTimeZone) {
              formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.timeZoneId, formRef.current.values?.TimeZoneId);
              return true;
            } else if (values.TimeZoneId.length > 0 && values.TimeZoneId != null) {
              let checkTimeZoneValue = isCountryNameAvailable(values.TimeZoneId, filteredTimezone);
              if (checkTimeZoneValue) {
                formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.timeZoneId, values.TimeZoneId);
                return true;
              } else {
                formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.timeZoneId, null);
                return false;
              }
            } else {
              formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.timeZoneId, null);
              return false;
            }
          } else {
            return false;
          }
          return true;
        } else if (filteredTimezone.length === 0) {
          formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.country, currentCountry.id);
          formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.state, null);
          setTimeZoneArray(timeZones);
          setParamsforEditUser({ countryId: currentCountry.id, TimeZoneId: null, stateCode: null });
          setLoadingPayment(false);
          if (formRef?.current?.values?.TimeZoneId?.length > 0 && formRef?.current?.values?.TimeZoneId != null) {
            formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.timeZoneId, formRef.current.values.TimeZoneId);
          } else {
            return false;
          }
          return true;
        }
      }
    } else {
      if (formRef.current.values.countryId === '60b8ddb57205057e7ce2b861') {
        formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.country, formRef.current.values.countryId);
        const filteredStates = states.filter(obj => obj.countryId === formRef.current.values.countryId);
        setStatesArray(filteredStates);
        if (formRef?.current?.values?.stateCode?.length > 0 && formRef?.current?.values?.stateCode != null) {
          formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.state, formRef.current.values.stateCode);
        } else {
          return false;
        }
        if (formRef?.current?.values?.TimeZoneId?.length > 0 && formRef?.current?.values?.TimeZoneId != null) {
          formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.timeZoneId, formRef.current.values.TimeZoneId);
        } else {
          return false;
        }
        return true;
      } else {
        const filteredTimezone = timeZones.filter(obj => obj?.countryId === formRef.current.values.countryId);
        if (filteredTimezone.length === 1) {
          formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.country, formRef.current.values?.countryId);
          formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.timeZoneId, filteredTimezone[0]?.countryName);
          formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.state, null);
          setTimeZoneArray(filteredTimezone);
          setLoadingPayment(false);
          setParamsforEditUser({
            countryId: formRef.current.values.countryId,
            TimeZoneId: filteredTimezone[0].countryName,
            stateCode: null,
          });
          return true;
        } else if (filteredTimezone.length > 1) {
          formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.country, formRef.current.values?.countryId);
          formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.state, null);
          setTimeZoneArray(filteredTimezone);
          setParamsforEditUser({ countryId: values?.countryId, TimeZoneId: null, stateCode: null });
          setLoadingPayment(false);

          if (formRef.current.values.TimeZoneId.length > 0 && formRef.current.values.TimeZoneId != null) {
            let checkTimeZone = isCountryNameAvailable(formRef.current.values.TimeZoneId, filteredTimezone);
            if (checkTimeZone) {
              formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.timeZoneId, formRef.current.values?.TimeZoneId);
              return true;
            } else if (values.TimeZoneId.length > 0 && values.TimeZoneId != null) {
              let checkTimeZoneValue = isCountryNameAvailable(values.TimeZoneId, filteredTimezone);
              if (checkTimeZoneValue) {
                formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.timeZoneId, values.TimeZoneId);
                return true;
              } else {
                formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.timeZoneId, null);
                return false;
              }
            } else {
              formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.timeZoneId, null);
              return false;
            }
          } else {
            return false;
          }
          return true;
        } else if (filteredTimezone.length === 0) {
          formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.country, currentCountry.id);
          formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.state, null);
          setTimeZoneArray(timeZones);
          setParamsforEditUser({ countryId: currentCountry.id, TimeZoneId: null, stateCode: null });
          setLoadingPayment(false);
          if (formRef?.current?.values?.TimeZoneId?.length > 0 && formRef?.current?.values?.TimeZoneId != null) {
            formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.timeZoneId, formRef.current.values.TimeZoneId);
          } else {
            return false;
          }
          return true;
        }
      }
    }
  };
  const checkProceedStatus = async () => {
    if (!isEmpty(user)) {
      formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.country, user.countryId);
      formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.timeZoneId, user.timeZoneId);
      formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.state, user.stateCode);
    }
  };

  const updateUserCall = async () => {
    const updatedUser = {
      ...user,
      id: user.id,
      stateCode: formRef.current.values.stateCode,
      timeZoneId: formRef.current.values.TimeZoneId,
      countryId: formRef.current.values.countryId,
    };
    await editUserProfile(user.id, updatedUser).then(async res => {
      let u = {
        ...user,
        countryId: res.user.countryId,
        timeZoneId: res.user.timeZoneId,
        stateCode: res.user.stateCode,
      };
      dispatch(updateUser(u));
    });
  };
  const getSummary = coupon => {
    let paymentType = `${isPackage ? 'SessionsPackage' : typeOfPayment}`;
    paymentType = `${isMonthlySessionSubscription ? 'MonthlySessionSubscription' : paymentType}`;

    const REQUEST_MAPPING = {
      [ContributionType.contributionOneToOne]: purchaseService.getOnToOnePaymentInfo,
      [ContributionType.contributionCourse]: purchaseService.getCoursePaymentInfo,
      [ContributionType.contributionMembership]: purchaseService.getMembershipPaymentInfo,
      [ContributionType.contributionCommunity]: purchaseService.getCommunityPaymentInfo,
    };

    const getPaymentData = REQUEST_MAPPING[contribution.type];

    getPaymentData(contribution.id, priceId, paymentType, coupon?.id)
      .then(data => {
        setSummary({ ...data, coupon: coupon });
        if (user && coupon) {
          updatePackageIntent({
            contributionId: contribution.id,
            couponId: coupon?.id || couponCode,
          });
        }
      })
      .catch(console.dir);
  };

  const handleRedeem = () => {
    const {
      current: { values },
    } = formRef;
    if (values?.Coupon?.length > 0) {
      request(`/Coupons/ValidateByName/${values.Coupon}/${contribution.id}/${typeOfPayment}`, 'GET').then(res => {
        dispatch(contributionActions.setCouponCode(res.id));
        if (res?.percentAmount > 0 || res?.discountAmount > 0) {
          setcoupan_id(res.id);
          getSummary(res);
        }
      });
    }
  };

  useEffect(() => {
    if (contribution?.isPurchased) {
      setEnrollmentFormSubmitted(true);
      setTimeout(() => {
        firstRender.current = false;
      }, 200);
    }
  }, [contribution]);

  useEffect(() => {
    if (enrollmentFormSubmitted) {
      if (isCodeToJoin && !clientData) {
        return;
      }
      const isAutoAddEnabled = clientData?.autoAddEnabled ?? false;
      // eslint-disable-next-line no-unused-expressions
      if (contribution.customWaiverId != null && isElectronicSignatureActive && !waiverData && !isAutoAddEnabled) {
        if (isEmpty(user)) {
          getWaiverForm();
        }
      } else {
        if (isCodeToJoin === null) {
          formRef.current.handleSubmit();
        }
        setStep(PURCHASE_MODAL_STEPS.loggedIn);
      }
    }
  }, [enrollmentFormSubmitted, loadingClientData]);

  return (
    <>
      <Formik
        initialValues={{
          Name: '',
          cardNumber: false,
          cardExpired: false,
          cardCVC: false,
          [ACCOUNT_FORM_FIELDS.confirmEmail]: '',
          [ACCOUNT_FORM_FIELDS.email]: '',
          [ACCOUNT_FORM_FIELDS.password]: '',
          [ACCOUNT_FORM_FIELDS.firstName]: '',
          [ACCOUNT_FORM_FIELDS.lastName]: '',
          [DISCOUNT_CODE_FIELDS.coupon]: '',
          [ACCOUNT_FORM_FIELDS.timeZoneId]: selectedTimeZone || '',
          [ACCOUNT_FORM_FIELDS.country]: '',
          [ACCOUNT_FORM_FIELDS.state]: '',
          [ACCOUNT_FORM_FIELDS.signature]: null,
          [ACCOUNT_FORM_FIELDS.details]: '',
        }}
        enableReinitialize
        validationSchema={getPurchaseValidationSchema(step, isPackage || isMonthlySessionSubscription, isGuestMode)}
        onSubmit={handleSubmit}
        innerRef={formRef}
      >
        {({ handleChange, errors, touched, values, setTouched, setFieldValue }) => {
          return (
            <>
              <CustomPurchaseModalFormSingleSession
                isInviteToJoin={isInviteToJoin}
                setTouched={setTouched}
                typeOfPayment={typeOfPayment}
                setTypeOfPayment={setTypeOfPayment}
                loading={loading}
                summary={summary}
                values={values}
                isPackage={isPackage}
                isMonthlySessionSubscription={isMonthlySessionSubscription}
                handleChange={handleChange}
                onClose={onClose}
                setFieldValue={setFieldValue}
                handleRedeem={handleRedeem}
                showFiedlsState={showFiedlsState}
                setSmsReminderCheckBox={setSmsReminderCheckBox}
                smsReminderCheckBox={smsReminderCheckBox}
                setshowPhoneNumberFields={setshowPhoneNumberFields}
                showPhoneNumberFields={showPhoneNumberFields}
                setloginStep={setloginStep}
                loginStep={loginStep}
                setPhoneNumberField={setPhoneNumberField}
                phoneNumberField={phoneNumberField}
                errors={errors}
                touched={touched}
                error={error}
                setShowTerms={setShowTerms}
                showClientInvitedView={showClientInvitedView}
                singleSessionInvite={singleSessionInvite}
                step={step}
                setStepValue={setStepValue}
                customWaiverId={contribution?.customWaiverId}
                setIsSigned={setIsSigned}
                isSigned={isSigned}
                paymentIntentCreated={paymentDataOneToOne?.created}
                sessionLifeTimeSeconds={paymentDataOneToOne?.sessionLifeTimeSeconds}
                onCheckoutSessionExpired={() => onClose(false)}
                submitTitle={getSubmitButtonTitle()}
                showErrorPhoneNumber={showErrorPhoneNumber}
                disableSubmitBtn={
                  loading ||
                  loadingPayment ||
                  isLoadingEmail ||
                  isLoadingAccount ||
                  (isCodeToJoin && singleSessionInvite === null)
                }
                addButtons
                selectedTimeZone={selectedTimeZone}
                isElectronicSignatureActive={isElectronicSignatureActive}
                formRef={formRef}
                sessionInfo={sessionInfo}
                timeZoneId={timeZoneId}
                isCouponAvailable={isCouponAvailable}
                stepVerifier={stepVerifier}
                setEnrollmentFormSubmitted={setEnrollmentFormSubmitted}
                easyBooking={easyBookingState}
              />
            </>
          );
        }}
      </Formik>

      <ModalTermsAndConditions applyTheming showTerms={showTerms} onCancel={() => setShowTerms(false)} />
      <SuccessfulPurchaseModal isOpen={isSuccessPurchaseModalOpen} handleClose={handleSuccessModalClose} />
      <FailedPurchaseModal isOpen={isFailedPurchaseModalOpen} handleClose={handleFailedModalClose} />

      {isAlreadyPuchased && (
        <Modal
          isOpen
          onCancel={handleCloseAlreadyPurchased}
          onSubmit={handleCloseAlreadyPurchased}
          title="Purchased contribution"
          hiddenCancel
        >
          <LabelText>You have already purchased this contribution</LabelText>
        </Modal>
      )}
      {isWaiverVisible && (
        <CreateCustomWaiver
          isOpen={isWaiverVisible}
          onSubmit={values =>
            // saveUserSignature(values).then(async () => {
            isSignSaved(values.clientSignature).then(() => {
              if (isCodeToJoin != null && pickAnotherTime != null) {
                updateTimeforSelfBook({
                  id: isCodeToJoin.slice(2),
                  contributionId: id,
                  clientId: singleSessionInvite.clientId,
                  availabilityTimeId: singleSessionInvite?.availabilityTimeId,
                  selectedSlotStartDateTime: pickAnotherTime.start,
                  selectedSlotEndDateTime: pickAnotherTime.end,
                  updatedAvailabilityTimeId: pickAnotherTime.updatedTimeId,
                  accessCode: singleSessionInvite.accessCode,
                  clientName: singleSessionInvite.clientName,
                  otherTimePicked: true,
                  isNewUser: newUser,
                }).then(res => {
                  if (isCodeToJoin && singleSessionInvite?.priceOption === 'Free') {
                    prepareEntire({
                      AvailabilityTimeId: pickAnotherTime.updatedTimeId,
                      contributionId: singleSessionInvite?.contributionId,
                      offset: singleSessionInvite?.offset,
                      accessCode: singleSessionInvite?.accessCode,
                      paymentOption: singleSessionInvite?.priceOption,
                      createSingleSession: true,
                      couponId: summaryRef?.current?.coupon?.id || couponCode,
                      priceId: priceId,
                    });
                  } else if (isCodeToJoin && singleSessionInvite?.priceOption === 'Credit') {
                    if (singleSessionInvite.clientId === user.id) {
                      prepareEntire({
                        AvailabilityTimeId: pickAnotherTime.updatedTimeId,
                        contributionId: singleSessionInvite?.contributionId,
                        offset: singleSessionInvite?.offset,
                        priceId: priceId,
                      });
                    } else {
                      setCreditJoinAllowed(true);
                    }
                  } else if (
                    isCodeToJoin &&
                    singleSessionInvite?.priceOption != 'Free' &&
                    singleSessionInvite?.priceOption != 'Credit'
                  ) {
                    prepareEntire({
                      AvailabilityTimeId: pickAnotherTime.updatedTimeId,
                      contributionId: singleSessionInvite?.contributionId,
                      offset: singleSessionInvite?.offset,
                      paymentOption: singleSessionInvite?.priceOption,
                      createSingleSession: true,
                      couponId: couponCode,
                      priceId: priceId,
                    });
                  }
                });
                dispatch(contributionActions.setPickAnotherTime(null));
                if (newUser === false) {
                  dispatch(contributionActions.setSingleCode(null));
                }
                return;
              }
              if (proseedHandlePurchase) {
                return proseedHandlePurchase(summary?.coupon?.id ? summary?.coupon?.id : null);
              }

              setName(values.Name);
              setError(null);
              if (oneToOne) {
                if (isMonthlySessionSubscription) {
                  createTokenForSplitPayments();
                  return;
                } else if (isPackage) {
                  payEntireCourse(paymentDataOneToOne);
                  return;
                }
                if (isCodeToJoin) {
                  if (selectedDate) {
                    updateTimeforSelfBook({
                      id: isCodeToJoin.slice(2),
                      contributionId: id,
                      clientId: singleSessionInvite.clientId,
                      availabilityTimeId: singleSessionInvite?.availabilityTimeId,
                      selectedSlotStartDateTime: selectedDate.start,
                      selectedSlotEndDateTime: selectedDate.end,
                      updatedAvailabilityTimeId: selectedDate.id,
                      accessCode: singleSessionInvite.accessCode,
                      clientName: singleSessionInvite.clientName,
                      otherTimePicked: true,
                      isNewUser: newUser,
                    }).then(res => {
                      if (isCodeToJoin && singleSessionInvite?.priceOption === 'Free') {
                        prepareEntire({
                          AvailabilityTimeId: selectedDate.id,
                          contributionId: singleSessionInvite?.contributionId,
                          offset: singleSessionInvite?.offset,
                          accessCode: singleSessionInvite?.accessCode,
                          paymentOption: singleSessionInvite?.priceOption,
                          createSingleSession: true,
                          couponId: summaryRef?.current?.coupon?.id || couponCode,
                          priceId: priceId,
                        });
                      } else if (isCodeToJoin && singleSessionInvite?.priceOption === 'Credit') {
                        if (singleSessionInvite.clientId === user.id) {
                          prepareEntire({
                            AvailabilityTimeId: selectedDate.id,
                            contributionId: singleSessionInvite?.contributionId,
                            offset: singleSessionInvite?.offset,
                            priceId: priceId,
                          });
                        } else {
                          setCreditJoinAllowed(true);
                        }
                      } else if (
                        isCodeToJoin &&
                        singleSessionInvite?.priceOption != 'Free' &&
                        singleSessionInvite?.priceOption != 'Credit'
                      ) {
                        prepareEntire({
                          AvailabilityTimeId: selectedDate.id,
                          contributionId: singleSessionInvite?.contributionId,
                          offset: singleSessionInvite?.offset,
                          paymentOption: singleSessionInvite?.priceOption,
                          createSingleSession: true,
                          couponId: couponCode,
                          priceId: priceId,
                        });
                      }
                    });
                    return;
                  } else {
                    updateTimeforSelfBook({
                      id: isCodeToJoin.slice(2),
                      contributionId: id,
                      clientId: user.id,
                      availabilityTimeId: singleSessionInvite?.availabilityTimeId,
                      clientName: singleSessionInvite.clientName,
                      otherTimePicked: false,
                      isNewUser: newUser,
                    }).then(res => {
                      if (isCodeToJoin && singleSessionInvite?.priceOption === 'Free') {
                        prepareEntire({
                          AvailabilityTimeId: singleSessionInvite?.availabilityTimeId,
                          contributionId: singleSessionInvite?.contributionId,
                          offset: singleSessionInvite?.offset,
                          accessCode: singleSessionInvite?.accessCode,
                          paymentOption: singleSessionInvite?.priceOption,
                          createSingleSession: true,
                          couponId: summaryRef?.current?.coupon?.id || couponCode,
                          priceId: priceId,
                        });
                      } else if (isCodeToJoin && singleSessionInvite?.priceOption === 'Credit') {
                        if (singleSessionInvite.clientId === user.id) {
                          prepareEntire({
                            AvailabilityTimeId: singleSessionInvite?.availabilityTimeId,
                            contributionId: singleSessionInvite?.contributionId,
                            offset: singleSessionInvite?.offset,
                            priceId: priceId,
                          });
                        } else {
                          setCreditJoinAllowed(true);
                        }
                      } else if (
                        isCodeToJoin &&
                        singleSessionInvite?.priceOption != 'Free' &&
                        singleSessionInvite?.priceOption != 'Credit'
                      ) {
                        prepareEntire({
                          AvailabilityTimeId: singleSessionInvite?.availabilityTimeId,
                          contributionId: singleSessionInvite?.contributionId,
                          offset: singleSessionInvite?.offset,
                          paymentOption: singleSessionInvite?.priceOption,
                          couponId: summaryRef?.current?.coupon?.id || couponCode,
                          createSingleSession: true,
                          priceId: priceId,
                        });
                      }
                    });
                  }
                  setfreeAccessCode(singleSessionInvite.accessCode);
                } else {
                  prepareEntire({
                    ...paymentDataOneToOne,
                    couponId: summaryRef?.current?.coupon?.id || couponCode,
                    createSingleSession: true,
                    priceId: priceId,
                  });
                }
              }
            })
          }
          // )

          contribution={contribution}
          waiverData={waiverData}
          isDarkModeAllowed={true}
          onCancel={() => setIsWaiverVisible(false)}
          isCreateContribution={false}
        />
      )}
      {errorModal && (
        <Modal
          isOpen={errorModal}
          onCancel={() => {
            setErrorModal(false);
          }}
          onSubmit={() => {
            setErrorModal(false);
          }}
          title=""
          hiddenCancel
          submitTitle="Ok"
        >
          <LabelText style={{ color: contribution?.isDarkModeEnabled ? 'white' : 'black' }}>
            You are not booked for this session or this session is already booked.
          </LabelText>
        </Modal>
      )}
      {creditJoinAllowed && (
        <Modal isOpen onCancel={handleCloseCreditJoin} onSubmit={handleCloseCreditJoin} title="" hiddenCancel>
          <LabelText style={{ color: contribution?.isDarkModeEnabled ? 'white' : 'black' }}>
            You are not booked for this session or this session is already booked.
          </LabelText>
        </Modal>
      )}
      {showUnableJoin && (
        <Modal
          title="Unable to join"
          isOpen={showUnableJoin}
          submitTitle="Close"
          hiddenCancel
          onCancel={() => {
            setshowUnableJoin(false);
          }}
          dontCancelOnSideClick
          onSubmit={() => {
            setshowUnableJoin(false);
          }}
          style={{ zIndex: '2000' }}
        >
          <p>Please use another email - you entered the account holder email of this service.</p>
        </Modal>
      )}
    </>
  );
};

PurchaseModalForPaymentCard.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  oneToOne: PropTypes.bool,
  paymentDataOneToOne: PropTypes.shape({
    clientSecret: PropTypes.string,
  }),
  isPackage: PropTypes.bool,
  isMonthlySessionSubscription: PropTypes.bool,
  proseedHandlePurchase: PropTypes.func,
  updatePackageIntent: PropTypes.func,
  submitNow: PropTypes.bool.isRequired,
  showOnlyProseedModal: PropTypes.func,
};

PurchaseModalForPaymentCard.defaultProps = {
  oneToOne: false,
  paymentDataOneToOne: null,
  isPackage: false,
  isMonthlySessionSubscription: false,
  proseedHandlePurchase: null,
  updatePackageIntent: null,
  showOnlyProseedModal: null,
  isInviteToJoin: null,
};

export default PurchaseModalForPaymentCard;
