import { createAction } from 'redux-actions';
import { get, pipe } from 'lodash/fp';

import * as discountCodesService from 'services/discountCodes.service';

export const GET_DISCOUNT_CODES = 'GET_DISCOUNT_CODES';
export const GET_DISCOUNT_CODES_SUCCESS = 'GET_DISCOUNT_CODES_SUCCESS';
export const GET_DISCOUNT_CODES_ERROR = 'GET_DISCOUNT_CODES_ERROR';

export const POST_DISCOUNT_CODE = 'POST_DISCOUNT_CODE';
export const POST_DISCOUNT_CODE_SUCCESS = 'POST_DISCOUNT_CODE_SUCCESS';
export const POST_DISCOUNT_CODE_ERROR = 'POST_DISCOUNT_CODE_ERROR';

export const PUT_DISCOUNT_CODE = 'PUT_DISCOUNT_CODE';
export const PUT_DISCOUNT_CODE_SUCCESS = 'PUT_DISCOUNT_CODE_SUCCESS';
export const PUT_DISCOUNT_CODE_ERROR = 'PUT_DISCOUNT_CODE_ERROR';

export const SET_DISCOUNT_CODE = 'SET_DISCOUNT_CODE';

export const SET_DISCOUNT_CODES = 'SET_DISCOUNT_CODES';

export const fetchDiscountCodesActions = {
  request: createAction(GET_DISCOUNT_CODES),
  success: createAction(GET_DISCOUNT_CODES_SUCCESS),
  error: createAction(GET_DISCOUNT_CODES_ERROR),
};

export const addDiscountCodeActions = {
  request: createAction(POST_DISCOUNT_CODE),
  success: createAction(POST_DISCOUNT_CODE_SUCCESS),
  error: createAction(POST_DISCOUNT_CODE_ERROR),
};

export const editDiscountCodeActions = {
  request: createAction(PUT_DISCOUNT_CODE),
  success: createAction(PUT_DISCOUNT_CODE_SUCCESS),
  error: createAction(PUT_DISCOUNT_CODE_ERROR),
};

export const fetchDiscountCodes = async dispatch => {
  dispatch(fetchDiscountCodesActions.request());

  try {
    const response = await discountCodesService.getDiscountCodes();
    dispatch(fetchDiscountCodesActions.success(response));
  } catch (error) {
    pipe(get('response'), get('data'), fetchDiscountCodesActions.error, dispatch)(error);
  }
};

export const addDiscountCode = async dispatch => {
  dispatch(addDiscountCodeActions.request());

  try {
    const response = await discountCodesService.postDiscountCodes();
    dispatch(addDiscountCodeActions.success(response));
  } catch (error) {
    pipe(get('response'), get('data'), addDiscountCodeActions.error, dispatch)(error);
  }
};

export const editDiscountCode = async dispatch => {
  dispatch(editDiscountCodeActions.request());

  try {
    const response = await discountCodesService.putDiscountCodes();
    dispatch(editDiscountCodeActions.success(response));
  } catch (error) {
    pipe(get('response'), get('data'), editDiscountCodeActions.error, dispatch)(error);
  }
};

export const setDiscountCode = payload => ({
  type: SET_DISCOUNT_CODE,
  payload,
});

export const setDiscountCodes = payload => ({
  type: SET_DISCOUNT_CODES,
  payload,
});
