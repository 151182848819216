import React from 'react';

const LinkedInSvg = ({ color, style = {} }) => {
  return (
    <svg style={style} width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0)">
        <path
          d="M22 19.4219V2.57812C22 1.15646 20.8435 0 19.4219 0H2.57812C1.15646 0 0 1.15646 0 2.57812V19.4219C0 20.8435 1.15646 22 2.57812 22H19.4219C20.8435 22 22 20.8435 22 19.4219ZM19.4219 1.71875C19.8957 1.71875 20.2812 2.10429 20.2812 2.57812V19.4219C20.2812 19.8957 19.8957 20.2812 19.4219 20.2812H2.57812C2.10429 20.2812 1.71875 19.8957 1.71875 19.4219V2.57812C1.71875 2.10429 2.10429 1.71875 2.57812 1.71875H19.4219Z"
          fill={color}
        />
        <path
          d="M11.5972 10.0151H11.6575L11.6856 9.96171C12.0213 9.32474 12.8532 8.69678 14.1109 8.69678C15.4323 8.69678 16.1888 9.11434 16.623 9.79218C17.0639 10.4806 17.1865 11.4571 17.1865 12.6063V16.8243C17.1865 17.0452 17.0074 17.2243 16.7865 17.2243H15.1682C14.9473 17.2243 14.7682 17.0452 14.7682 16.8243V13.1372C14.7682 12.645 14.7584 12.0584 14.5683 11.5945C14.4721 11.3599 14.3281 11.152 14.1126 11.0034C13.8968 10.8547 13.6175 10.771 13.2607 10.771C12.5381 10.771 12.087 11.0565 11.8234 11.4994C11.5654 11.9327 11.4946 12.5042 11.4946 13.0664V16.8243C11.4946 17.0452 11.3155 17.2243 11.0946 17.2243H9.51432C9.29341 17.2243 9.11432 17.0452 9.11432 16.8243V9.24856C9.11432 9.02765 9.29341 8.84856 9.51432 8.84856H11.0946C11.3155 8.84856 11.4946 9.02765 11.4946 9.24856V9.9151V10.0151H11.5946H11.5972ZM7.24501 16.8243C7.24501 17.0452 7.06592 17.2243 6.84501 17.2243H5.1888C4.96789 17.2243 4.7888 17.0452 4.7888 16.8243V9.24856C4.7888 9.02765 4.96789 8.84856 5.1888 8.84856H6.84501C7.06592 8.84856 7.24501 9.02765 7.24501 9.24856V16.8243ZM6.05515 4.56069C6.8581 4.56069 7.51003 5.21284 7.51003 6.01632C7.51003 6.82012 6.85809 7.47225 6.05515 7.47225C5.24896 7.47225 4.59878 6.82013 4.59878 6.01632C4.59878 5.21282 5.24895 4.56069 6.05515 4.56069Z"
          fill={color}
          stroke="white"
          stroke-width="0.2"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="22" height="22" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LinkedInSvg;
