import React from 'react';
import Intro from './Intro';
import Video from './Video';
import LetsWorkTogether from './LetsWorkTogether';
import Links from './Links';

function About({ data, theme }) {
  const dataObject = data?.profilePageViewModel || {};
  const brandingColors = data?.brandingColors || {};
  const bioBannerUrl = data?.profilePageViewModel?.bioBannerUrl || null;
  const bioHeader = data?.profilePageViewModel?.bioHeader || null;
  const accountId = data?.accountId || null;
  const firstName = data.firstName || '';
  const bio = data.bio || '';

  return (
    <span className="about-section">
      <div className="about-sub-section">
        <Video data={dataObject} theme={theme} brandingColors={brandingColors} />
        {bio && (
          <Intro
            profileImage={data?.avatarUrl}
            bioBannerUrl={bioBannerUrl}
            firstName={firstName}
            bio={bio}
            theme={theme}
            brandingColors={brandingColors}
            accountId={accountId}
            bioHeader={bioHeader}
          />
        )}
      </div>
      <div className="about-sub-section">
        <LetsWorkTogether
          contributions={data?.profilePageViewModel?.contributions?.filter(contrib => contrib?.isEnabled) || []}
          data={data?.profilePageViewModel}
          theme={theme}
          label={dataObject.contributionLabel}
          accountId={accountId}
          brandingColors={brandingColors}
        />
      </div>
      <div className="about-sub-section">
        <Links
          profileImage={data?.avatarUrl}
          data={data?.profilePageViewModel}
          firstName={firstName}
          theme={theme}
          label={dataObject.additionalLinksLabel}
          brandingColors={brandingColors}
        />
      </div>
    </span>
  );
}

export default About;
