import React from 'react';
import useContribution from 'pages/ContributionView/hooks/useContribution';
import { determineColorToUse } from 'services/contributions.service';
import PropTypes from 'prop-types';
import { lightOrDark, getStyledButton } from 'utils/utils';

export const getContributionTextColorCode = (contribution, colorToUse) => {
  return contribution.brandingColors?.TextColorCode === 'Auto'
    ? lightOrDark(colorToUse?.PrimaryColorCode)
    : contribution.brandingColors?.TextColorCode || 'white';
};
const AddQuestion = ({ handler, style }) => {
  const contribution = useContribution();
  const colorToUse = determineColorToUse(contribution?.activeContribution);
  const contributionTextColorCode = getContributionTextColorCode(contribution, colorToUse);
  const StyledButton = getStyledButton(contribution, contributionTextColorCode);
  return (
    <StyledButton onClick={handler} style={style} type="button" className="float-right btn">
      <svg
        className="bottom-2 mr-1"
        height="15px"
        width="15px"
        viewBox="0 0 256 256"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect fill="none" height="256" width="256" />
        <line
          fill="none"
          stroke={contributionTextColorCode}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="25"
          x1="40"
          x2="216"
          y1="128"
          y2="128"
        />
        <line
          fill="none"
          stroke={contributionTextColorCode}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="25"
          x1="128"
          x2="128"
          y1="40"
          y2="216"
        />
      </svg>
      Add another question
    </StyledButton>
  );
};
AddQuestion.propTypes = {
  handler: PropTypes.func.isRequired,
  style: PropTypes.shape({}),
};

AddQuestion.defaultProps = {
  style: undefined,
};
export default AddQuestion;
