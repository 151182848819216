import React, { useEffect, useMemo, useRef, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useTheme, useMediaQuery, Typography, TextField, makeStyles } from '@material-ui/core';
import { Form } from 'formik';
import { useDispatch, connect, useSelector } from 'react-redux';
import Select from '@material-ui/core/Select';
import { ACCOUNT_FORM_FIELDS } from './PurchaseModal.constants';
import { RESTORE_PASS } from './PurchaseModal.constants';
import isEmpty from 'lodash/isEmpty';
import SignatureCanvas from 'react-signature-canvas';
import PropTypes from 'prop-types';
import Popup from 'components/Popup/Popup';
import styled, { css } from 'styled-components';
import { colors } from 'utils/styles/styles';
import { TemplateType } from 'helpers/constants/templateType';
import { LabelText, PageSubtitle, PageTitleSecond } from 'components/UI/Text/TextStyles';
import { PhoneInput } from 'react-international-phone';
import Checkbox from '@material-ui/core/Checkbox';
import CommonErrorMessage from 'components/FormUI/CommonErrorMessage';
import useContribution from 'pages/ContributionView/hooks/useContribution';
import { FormikScrollToError } from 'components/FormikScrollToError';
import { useAccount, useHttp, useRouter, useVideoChat, useDefaultTimezone } from 'hooks';
import { formatMoney } from 'utils/datesAndMoney';
import CreditCard from './CreditCard';
import CustomCreditCard from './CustomCreditCard';
import AccountForm from './AccountForm/AccountForm';
import CustomAccountForm from './CustomAccountForm/CustomAccountForm';
import Countdown, { zeroPad } from 'react-countdown';
import moment from 'moment';
import { PAYMENT_OPTIONS } from '../../../../constants.js';
import { ContributionType } from 'helpers/constants';
import ApplyCoupon from './ApplyCoupon';
import CustomApplyCoupon from './CustomApplyCoupon';
import { PURCHASE_MODAL_STEPS } from './PurchaseModal.constants';
import BookSingleSession from './CalendarBookSession';
import { darken } from 'polished';
import {
  calculateSlotsheckIfPurchaseExistsForClient,
  determineColorToUse,
  getSingleContract,
  getThemedColors,
} from 'services/contributions.service';
import { useStyles } from '../PurchaseBlock/hooks/useStyles';
import SessionDateAndTime from './SessionDateAndTime';
import { dataURLtoFile, getPaymentTypeForUi, lightOrDark } from 'utils/utils';
import Loader from 'components/UI/Loader';
import CreateCustomWaiver from 'pages/CreateContribution/components/CreateCustomWaiver';
import { Email, HistoryToggleOff } from '@mui/icons-material';
import EnrollmentForm from '../EnrollmentForm';
import { fetchTimeZones } from 'actions/timeZone';
const StyledLink = styled.a`
  color: ${props => (props.color ? props.color : colors.darkOceanBlue)};
  font-weight: 600;
`;
const StyledDiv = styled.span`
  color: ${props => (props.color ? props.color : colors.darkOceanBlue)};
  font-weight: 600;
`;

const PriceContainer = styled.div`
  background-color: #fafafa;
  padding: 2px 0px;
`;

const StyledButton = styled.button`
  position: relative;
  padding: 1rem 1.25rem;
  font-size: 0.875rem;
  border-radius: 2px;
  border: none;
  color: #fff;
  fill: #fff; /* for svg */
  font-weight: 900;
  cursor: pointer;
  text-decoration: none;
  line-height: 1rem;
  letter-spacing: 1.25px;
  // min-width: 18rem;
  width: 100%;
  transition: background 0.2s;
  font-family: 'Avenir';

  ${({ marginTop }) =>
    marginTop &&
    css`
      margin-top: 10px;
    `}

  ${({ variant, backgroundColor, activeTemplate }) => {
    if (backgroundColor) {
      return css`
        background-color: ${backgroundColor};
      `;
    }
    return activeTemplate === TemplateType.TemplateOne
      ? css`
          background-color: #6999ca;
        `
      : css`
          background-color: #b78aea;
        `;
  }}

  ${({ invert }) =>
    invert &&
    css`
      color: rgba(0, 0, 0, 0.87);
      background-color: white;
      border: 1px solid rgba(0, 0, 0, 0.87);
    `}

  &:hover {
    ${({ variant, backgroundColor, activeTemplate }) => {
      if (backgroundColor) {
        return css`
          background-color: ${darken(0.05, backgroundColor)};
        `;
      }
      return activeTemplate === TemplateType.TemplateOne
        ? css`
            background-color: ${darken(0.05, '#6999CA')};
          `
        : css`
            background-color: ${darken(0.05, '#B78AEA')};
          `;
    }}

    ${({ invert }) =>
      invert &&
      css`
        background-color: ${darken(0.05, 'white')};
      `}
  }

  :disabled {
    background-color: #ededed;
    color: #9b9b9b;
    fill: #9b9b9b; /* for svg */
    cursor: not-allowed;
    border: none;
  }

  ${({ mobileView }) =>
    mobileView &&
    `
    font-size: 12px;
    padding: 8px 16px;
    min-width: 8rem;
  `}
`;

const ApplyCouponContainer = styled.div`
  background-color: #fafafa;
  padding: 15px;
  // margin-top: 20px;
`;

const StyledPhoneInput = styled(PhoneInput)`
  width: ${({ mobileView }) => (mobileView ? '100%' : '60%')};
  gap: 10px;
  &.phone-error {
    .react-international-phone-input {
      border-color: red;
    }
  }
  .react-international-phone-country-selector {
    .react-international-phone-country-selector-button {
      padding: 10px 6px;
      height: auto;
      border-radius: 10px;
    }
  }
  .react-international-phone-input {
    width: 100%;
    padding: 10px 10px;
    height: auto;
    border-radius: 10px;
    font-size: 14px;
  }
`;
const StyledError = styled.div`
  color: ${({ template, PrimaryColor }) =>
    template === TemplateType.TemplateOne
      ? PrimaryColor
        ? PrimaryColor
        : '#6999CA'
      : PrimaryColor
      ? PrimaryColor
      : '#D08ACD'};
  font-size: 14px;
  margin-top: 2px;
  padding-left: 10px;
`;
export const CustomPurchaseModalFormSingleSession = ({
  submitTitle,
  disableSubmitBtn,
  loading,
  summary,
  values,
  isPackage,
  isMonthlySessionSubscription,
  handleChange,
  onClose,
  setFieldValue,
  handleBlur,
  handleRedeem,
  errors,
  showFiedlsState,
  touched,
  error,
  setShowTerms,
  step,
  setStepValue,
  setSmsReminderCheckBox,
  smsReminderCheckBox,
  setshowPhoneNumberFields,
  showPhoneNumberFields,
  setPhoneNumberField,
  phoneNumberField,
  setloginStep,
  loginStep,
  paymentIntentCreated,
  sessionLifeTimeSeconds,
  onCheckoutSessionExpired,
  selectedTimeZone,
  isElectronicSignatureActive,
  formRef,
  sessionInfo,
  timeZoneId,
  isCouponAvailable,
  customWaiverId,
  setIsSigned,
  isSigned,
  stepVerifier,
  setEnrollmentFormSubmitted,
  showClientInvitedView,
  singleSessionInvite,
  showErrorPhoneNumber = false,
}) => {
  const [disableBtn, setDisableBtn] = useState(true);
  const [coachPhoneNumber, setCoachPhoneNumber] = useState('');
  const [coachPhoneNumberFocused, setCoachPhoneNumberFocused] = useState(false);
  const [sessionTimeExpired, setSessionTimeExpired] = useState(false);
  const [colorForSelect, setColorForSelect] = useState('darkgrey');
  const [showThreeFields, setshowThreeFields] = useState(false);
  const [statesArray, setStatesArray] = useState([]);
  const [timeZoneArray, setTimeZoneArray] = useState([]);
  const [waiverData, setWaiverData] = useState(null);
  const [isWaiverVisible, setIsWaiverVisible] = useState(false);
  const [signed, setSigned] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [defaultCountry, setDefaultCountry] = useState();
  const theme = useTheme();
  const canvasRef = useRef();
  const phoneInputRef = useRef(null);
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'));
  const { user } = useAccount();
  const classNames = useStyles();
  const { serviceProviderName, customToS, isAccountNeeded } = useContribution();
  const contribution = useContribution();
  let colorToUse = determineColorToUse(contribution);
  const { history, query } = useRouter();
  const queryCode = query?.code === undefined ? null : query?.code?.includes('p_') ? null : query?.code;
  const dispatch = useDispatch();
  const { pathname } = useRouter();
  const { newThemedCardColor, newThemedTextColor, newThemedBackgroundColor } = getThemedColors(contribution);
  const isInviteToJoin = useSelector(state => state.contributions.inviteCode);
  const { timeZones, loading: timeZoneLoading } = useSelector(state => state.timeZone);
  const { countries } = useSelector(state => state?.country);
  const { states } = useSelector(state => state?.states);
  const isCodeToJoin = useSelector(state => state?.contributions?.singleCode);
  const { fetchUserTimezone, fetchUserCountryCode } = useDefaultTimezone();
  const [phoneInputKey, setPhoneInputKey] = useState(0);

  const shouldHidePriceSection = isInviteToJoin || queryCode;
  const checkCountry = async values => {
    const res = await fetch('https://geolocation-db.com/json/');
    const data = await res.json();
    const currentCountry = countries.find(obj => obj.name === data.country_name);
    // const currentCountry = { id: '60b8ddb57205057e7ce2b861' };
    const filteredTimezone = timeZones.filter(obj => obj?.countryId === currentCountry.id);
    const filteredObjects = states.filter(obj => obj.countryId === currentCountry.id);
    setStatesArray(filteredObjects);
    formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.country, currentCountry.id);
    setTimeZoneArray(filteredTimezone);
    if (filteredTimezone.length === 1) {
      setshowThreeFields(false);
    } else {
      if (currentCountry.id === user.countryId && filteredTimezone.length === 1) {
        setshowThreeFields(false);
      } else {
        setshowThreeFields(true);
      }
    }
    if (!isEmpty(user)) {
      formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.country, user.countryId);
      formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.timeZoneId, user.timeZoneId);
      formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.state, user.stateCode);
    } else if (!formRef?.current?.values?.TimeZoneId && timeZones?.length > 0) {
      const finalTimezone = await fetchUserTimezone(user?.timeZone, contribution?.timeZoneId, timeZones);
      formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.timeZoneId, finalTimezone);
    }
  };
  const useSelectStyle = makeStyles(() => ({
    select: {
      width: '100%',
      color: colorForSelect,
      backgroundColor: 'white',
      borderRadius: '20px',
      border: '1px solid #f5f0f0',
      height: '53px',
    },
    handleNewFormFieldsLastName: {
      margin: '-10px 0px 0px 0px',
      marginTop: '5px',
    },
    handleNewFormFieldsTimeZone: {
      margin: ' -10px 0px 0px 0px',
      marginTop: '5px',
    },
  }));

  const setDefaultCountryCode = async () => {
    const countryCode = await fetchUserCountryCode(formRef?.current?.values?.TimeZoneId, timeZones, countries);
    setDefaultCountry(countryCode);
  };

  useEffect(() => {
    if (timeZones?.length > 0 && countries?.length > 0) setDefaultCountryCode();
  }, [formRef?.current?.values?.TimeZoneId, timeZones, countries]);

  const applyFocusEventOnPhonInput = () => {
    if (phoneInputRef.current) {
      const phoneInput = phoneInputRef.current.querySelector('.react-international-phone-input');
      if (phoneInput) {
        phoneInput.addEventListener('focus', () => {
          setCoachPhoneNumberFocused(true);
        });
      }
      return phoneInput;
    }
    return null;
  };

  useEffect(() => {
    if (phoneInputRef.current && !phoneNumberField) {
      const phoneInput = applyFocusEventOnPhonInput();
      return () => {
        if (phoneInput) {
          phoneInput.removeEventListener('focus', () => {
            setCoachPhoneNumberFocused(false);
            setCoachPhoneNumber('');
          });
        }
      };
    }
    if (phoneNumberField) {
      setCoachPhoneNumberFocused(true);
      setCoachPhoneNumber(phoneNumberField);
    }
  }, [phoneInputRef.current, showPhoneNumberFields, smsReminderCheckBox, step]);

  useEffect(() => {
    setPhoneInputKey(phoneInputKey + 1);
  }, [defaultCountry]);

  useEffect(() => {
    applyFocusEventOnPhonInput();
  }, [phoneInputKey]);

  useEffect(() => {
    if (coachPhoneNumberFocused && phoneInputRef.current) {
      const phoneInput = phoneInputRef.current.querySelector('.react-international-phone-input');
      if (phoneInput) {
        phoneInput.focus();
      }
    }
  }, [coachPhoneNumberFocused]);

  useEffect(() => {
    if (user?.phoneNo?.length > 0 && !isEmpty(user)) {
      setPhoneNumberField(user?.phoneNo);
    }
  }, [user]);

  useEffect(() => {
    if (phoneInputRef.current && !phoneNumberField) {
      const phoneInput = phoneInputRef.current.querySelector('.react-international-phone-input');
      if (phoneInput) {
        phoneInput.addEventListener('focus', () => {
          setCoachPhoneNumberFocused(true);
        });
      }
      return () => {
        if (phoneInput) {
          phoneInput.removeEventListener('focus', () => {
            setCoachPhoneNumberFocused(false);
            setCoachPhoneNumber('');
          });
        }
      };
    }
    if (phoneNumberField) {
      setCoachPhoneNumberFocused(true);
      setCoachPhoneNumber(phoneNumberField);
    }
  }, [phoneInputRef.current, , showPhoneNumberFields, smsReminderCheckBox, step]);

  useEffect(() => {
    if (coachPhoneNumberFocused && phoneInputRef.current) {
      const phoneInput = phoneInputRef.current.querySelector('.react-international-phone-input');
      if (phoneInput) {
        phoneInput.focus();
      }
    }
  }, [coachPhoneNumberFocused]);

  useEffect(() => {
    if (timeZones?.length > 0) {
      return;
    }
    if ((!timeZones || !timeZones.length) && !timeZoneLoading) {
      dispatch(fetchTimeZones());
    }
    if (contribution.paymentType === 'Simple' && contribution.taxType != 'No' && isInviteToJoin === null) {
      checkCountry();
    }
  }, [user, states]);
  const selectStyle = useSelectStyle();
  const TextColor =
    colorToUse?.TextColorCode === 'Auto'
      ? lightOrDark(colorToUse?.PrimaryColorCode)
      : colorToUse?.TextColorCode === '#000000'
      ? '#000000'
      : '#FFFFFF';

  const {
    activeTemplate,
    paymentInfo: {
      paymentOptions,
      splitNumbers,
      splitPeriod,
      packageSessionNumbers,
      monthlySessionSubscriptionInfo,
      membershipInfo,
    },
    type,
    title,
    defaultCurrency,
    defaultSymbol,
    isPurchased,
    isPriceHidden,
    customWaiverTemplateName,
  } = contribution;

  const borderRad = activeTemplate === TemplateType.TemplateOne ? '12px' : '5px';
  const PrimaryColor = colorToUse?.PrimaryColorCode;
  const showDueLater = type !== ContributionType.contributionMembership;
  const perSession = !isMonthlySessionSubscription && !isPackage;

  const singleSessionData = {
    summary,
    defaultCurrency,
    defaultSymbol,
  };

  const showPadForLoggedInClient = !isEmpty(user) && step === PURCHASE_MODAL_STEPS.loggedIn && !isPurchased;

  // const agrementText = useMemo(
  //   () =>
  //     (isElectronicSignatureActive && showPadForLoggedInClient) ||
  //     (isElectronicSignatureActive && isShowInput(step, ACCOUNT_FORM_FIELDS.signature))
  //       ? 'signing and clicking'
  //       : 'clicking',
  //   [step, isElectronicSignatureActive, showPadForLoggedInClient],
  // );
  const isShowInput = (step, input) => {
    const visible = {
      init: [ACCOUNT_FORM_FIELDS.email],
      join: [ACCOUNT_FORM_FIELDS.email],
      // loggedIn: [ACCOUNT_FORM_FIELDS.signature],
      joinLogin: [ACCOUNT_FORM_FIELDS.email, ACCOUNT_FORM_FIELDS.password, ACCOUNT_FORM_FIELDS.signature, RESTORE_PASS],
      login: [ACCOUNT_FORM_FIELDS.email, ACCOUNT_FORM_FIELDS.password, ACCOUNT_FORM_FIELDS.signature, RESTORE_PASS],
      joinCreate: [
        ACCOUNT_FORM_FIELDS.email,
        ACCOUNT_FORM_FIELDS.confirmEmail,
        // ACCOUNT_FORM_FIELDS.password,
        ACCOUNT_FORM_FIELDS.firstName,
        ACCOUNT_FORM_FIELDS.lastName,
        ACCOUNT_FORM_FIELDS.timeZoneId,
        ACCOUNT_FORM_FIELDS.signature,
        ACCOUNT_FORM_FIELDS.country,
        ACCOUNT_FORM_FIELDS.state,
      ],
      create: [
        ACCOUNT_FORM_FIELDS.email,
        ACCOUNT_FORM_FIELDS.confirmEmail,
        // ACCOUNT_FORM_FIELDS.password,
        ACCOUNT_FORM_FIELDS.firstName,
        ACCOUNT_FORM_FIELDS.lastName,
        ACCOUNT_FORM_FIELDS.timeZoneId,
        ACCOUNT_FORM_FIELDS.signature,
        ACCOUNT_FORM_FIELDS.country,
        ACCOUNT_FORM_FIELDS.state,
      ],
    };
    return visible[step]?.includes(input);
  };

  const disableJoinBtn =
    (disableBtn &&
      isElectronicSignatureActive &&
      customWaiverId === null &&
      isShowInput(step, ACCOUNT_FORM_FIELDS.signature)) ||
    (disableBtn && isElectronicSignatureActive && showPadForLoggedInClient && customWaiverId === null);

  const setDisableValue = val => {
    setDisableBtn(val);
  };

  const clearSign = () => {
    if (canvasRef?.current && formRef?.current) {
      canvasRef.current.clear();
      // setDisableBtn(true);
      setDisableValue(true);
      formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.signature, null);
    }
  };

  const paymentType = getPaymentTypeForUi(contribution?.paymentInfo, query, queryCode);

  const getIsGuestUserFlow = () => {
    return !history.location.pathname.includes('s_') && !isAccountNeeded && paymentType === 'FREE';
  };

  return (
    <>
      <FormikScrollToError>
        <Form id="credit-card-form">
          <>
            <Grid
              // className="mt-3"
              container
              // spacing={2}
            >
              {!shouldHidePriceSection && (
                <>
                  {/* Payment Summary */}
                  {step !== PURCHASE_MODAL_STEPS.enrollmentForm && (
                    <div
                      className="single-session-back-button"
                      style={{ display: 'flex', justifyContent: 'flex-start' }}
                    >
                      <div style={{ marginTop: '5px', cursor: 'pointer', padding: '5px 25px' }} onClick={onClose}>
                        <b>{`< Back`}</b>
                      </div>
                    </div>
                  )}
                  <Grid md={12} xs={12}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      {!isPriceHidden && (
                        <div
                          style={{
                            backgroundColor: colorToUse?.PrimaryColorCode,
                            paddingLeft: '15px',
                            paddingRight: '15px',
                            borderRadius: '5px',
                            fontSize: '18px',
                            color: TextColor,
                            // marginTop: '10px',
                            marginBottom: '15px',
                          }}
                        >
                          Session Price
                        </div>
                      )}
                      {!isPriceHidden && (
                        <div
                          style={{
                            fontSize: '36px',
                            fontWeight: '700',
                            // marginBottom: '15px',
                          }}
                        >
                          {defaultSymbol}
                          {formatMoney(summary?.price)}{' '}
                          <span style={{ fontWeight: '300' }}> {defaultCurrency.toUpperCase()}</span>
                        </div>
                      )}
                    </div>
                    {!isPriceHidden && (
                      <div style={{ padding: '5px 50px' }}>
                        {summary && perSession && paymentOptions.includes(PAYMENT_OPTIONS.PER_SESSION) && (
                          <>
                            {summary?.platformFee > 0 && (
                              <PriceContainer
                                className="price-container"
                                style={{ backgroundColor: newThemedCardColor }}
                              >
                                <Grid container justify="space-between">
                                  <Grid item>
                                    <Typography style={{ color: newThemedTextColor, lineHeight: 2.1 }}>
                                      Processing Fee:
                                    </Typography>
                                  </Grid>
                                  <Grid item>
                                    <Typography variant="h6" style={{ color: newThemedTextColor, fontWeight: 'bold' }}>
                                      {defaultSymbol}
                                      {formatMoney(summary?.platformFee)}{' '}
                                      <span style={{ fontWeight: '300' }}> {defaultCurrency.toUpperCase()}</span>
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </PriceContainer>
                            )}

                            {summary?.platformFee > 0 && (
                              <PriceContainer
                                className="price-container"
                                style={{ backgroundColor: newThemedCardColor }}
                              >
                                <Grid container justify="space-between">
                                  <Grid item>
                                    <Typography style={{ color: newThemedTextColor, lineHeight: 2.1 }}>
                                      Due Now:
                                    </Typography>
                                  </Grid>
                                  <Grid item>
                                    <Typography variant="h6" style={{ color: newThemedTextColor, fontWeight: 'bold' }}>
                                      {defaultSymbol}
                                      {formatMoney(summary?.dueNowWithCouponDiscountAmount)}{' '}
                                      <span style={{ fontWeight: '300' }}> {defaultCurrency.toUpperCase()}</span>
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </PriceContainer>
                            )}
                            {paymentIntentCreated && sessionLifeTimeSeconds && (
                              <>
                                <PriceContainer
                                  className="price-container"
                                  style={{ backgroundColor: newThemedCardColor }}
                                >
                                  <Grid container justify="space-between">
                                    <Grid item>
                                      <Typography style={{ color: newThemedTextColor, lineHeight: 2.1 }}>
                                        Checkout time left:
                                      </Typography>
                                    </Grid>
                                    <Grid item>
                                      <Countdown
                                        date={moment(paymentIntentCreated).add(sessionLifeTimeSeconds, 'seconds')}
                                        onComplete={() => {
                                          setSessionTimeExpired(true);
                                        }}
                                        renderer={props => (
                                          <>
                                            {zeroPad(props.minutes)}:{zeroPad(props.seconds)}
                                          </>
                                        )}
                                      />
                                    </Grid>
                                  </Grid>
                                </PriceContainer>
                              </>
                            )}
                          </>
                        )}
                      </div>
                    )}
                    <hr style={{ borderColor: contribution?.isDarkModeEnabled ? 'white' : '#F5F5F5' }} />
                  </Grid>

                  {isCouponAvailable && (
                    <Grid item md={12} xs={12} style={{ padding: '5px 50px' }}>
                      <CustomApplyCoupon
                        handleChange={handleChange}
                        activeTemplate={activeTemplate}
                        colorToUse={colorToUse}
                        values={values}
                        errors={errors}
                        touched={touched}
                        handleRedeem={handleRedeem}
                        padding="0px"
                      />
                    </Grid>
                  )}

                  <Grid className="session-date-time" item md={12} xs={12} style={{ padding: '5px 50px' }}>
                    {/* <SessionDateAndTime session={sessionInfo} timeZoneId={timeZoneId} /> */}
                    {!isEmpty(user) && (
                      <>
                        {contribution.paymentType === 'Simple' &&
                          contribution.taxType != 'No' &&
                          !isMonthlySessionSubscription &&
                          isInviteToJoin === null &&
                          showThreeFields && (
                            <>
                              <Grid
                                container
                                item
                                direction="column"
                                alignItems="flex-start"
                                md={12}
                                xs={12}
                                style={{
                                  padding: '5px 5px',
                                  position: 'relative',
                                }}
                              >
                                <Typography variant="h6" style={{ fontSize: '20px', color: newThemedTextColor }}>
                                  Where are you located?
                                </Typography>
                              </Grid>
                              <Grid item xs={12} className={selectStyle.handleNewFormFieldsTimeZone}>
                                <Select
                                  label="Country of Residence"
                                  name={ACCOUNT_FORM_FIELDS.country}
                                  fullWidth
                                  required
                                  native
                                  labelId="country"
                                  value={formRef?.current?.values?.countryId}
                                  className={[classNames.select, classNames.themedTextField].join(' ')}
                                  onChange={e => {
                                    const filteredObjects = states.filter(obj => obj.countryId === e.target.value);
                                    const filteredCountry = countries.find(obj => obj.id === e.target.value);
                                    const filteredTimezone = timeZones.filter(obj => obj.countryId === e.target.value);
                                    if (filteredObjects.length >= 1) {
                                      setStatesArray(filteredObjects);
                                    } else if (filteredObjects.length === 0) {
                                      setStatesArray(filteredObjects);
                                      if (filteredTimezone.length === 0) {
                                        setTimeZoneArray(timeZones);
                                      } else if (
                                        filteredTimezone.length === 1 &&
                                        filteredCountry.id != '60b8ddb57205057e7ce2b861'
                                      ) {
                                        setTimeZoneArray(filteredTimezone);
                                        setFieldValue(ACCOUNT_FORM_FIELDS.timeZoneId, filteredTimezone[0].countryName);
                                      } else {
                                        setTimeZoneArray(filteredTimezone);
                                      }
                                    }
                                    setFieldValue(ACCOUNT_FORM_FIELDS.state, '');
                                    setFieldValue(ACCOUNT_FORM_FIELDS.country, filteredCountry.id);
                                  }}
                                  disableUnderline
                                  style={{
                                    padding: '15px',
                                    position: 'relative',
                                    backgroundColor: newThemedBackgroundColor,
                                    color: newThemedTextColor,
                                    borderRadius: '10px',
                                  }}
                                >
                                  <option aria-label="Time Zone" value={null}>
                                    Select Country
                                  </option>
                                  {countries?.length > 0 &&
                                    countries.map(timeZone => <option value={timeZone.id}>{timeZone.name}</option>)}
                                </Select>
                              </Grid>
                            </>
                          )}
                        {contribution.paymentType === 'Simple' &&
                          contribution.taxType != 'No' &&
                          formRef?.current?.values?.countryId === '60b8ddb57205057e7ce2b861' &&
                          isInviteToJoin === null &&
                          statesArray.length > 1 &&
                          !isMonthlySessionSubscription && (
                            <Grid item xs={12} className={selectStyle.handleNewFormFieldsTimeZone}>
                              <Select
                                label="State"
                                name={ACCOUNT_FORM_FIELDS.state}
                                fullWidth
                                required
                                native
                                labelId="stateCode"
                                style={{
                                  padding: '15px',
                                  position: 'relative',
                                  backgroundColor: newThemedBackgroundColor,
                                  color: newThemedTextColor,
                                  borderRadius: '10px',
                                }}
                                value={formRef?.current?.values?.stateCode}
                                className={[classNames.select, classNames.themedTextField].join(' ')}
                                onChange={e => {
                                  const filteredState = states.find(obj => obj.alpha2Code === e.target.value);
                                  const filteredObjects = timeZones.filter(obj =>
                                    obj.statesIds.includes(filteredState.id),
                                  );
                                  if (filteredObjects.length >= 1) {
                                    if (filteredObjects.length === 1) {
                                      setTimeZoneArray(filteredObjects);
                                      setFieldValue(ACCOUNT_FORM_FIELDS.timeZoneId, filteredObjects[0].countryName);
                                    } else {
                                      setFieldValue(ACCOUNT_FORM_FIELDS.timeZoneId, filteredObjects[0].countryName);
                                      setTimeZoneArray(filteredObjects);
                                    }
                                  } else {
                                    setTimeZoneArray(timeZones);
                                  }
                                  setFieldValue(ACCOUNT_FORM_FIELDS.state, filteredState.alpha2Code);
                                }}
                                disableUnderline
                              >
                                <option aria-label="Time Zone" value={null}>
                                  Select State
                                </option>
                                {statesArray?.length > 0 &&
                                  statesArray.map(timeZone => (
                                    <option value={timeZone.alpha2Code}>{timeZone.name}</option>
                                  ))}
                              </Select>
                            </Grid>
                          )}
                        {contribution.paymentType === 'Simple' &&
                          contribution.taxType != 'No' &&
                          isInviteToJoin === null &&
                          timeZoneArray.length > 1 &&
                          formRef?.current?.values?.countryId != null && (
                            <>
                              <Grid item xs={12} className={selectStyle.handleNewFormFieldsTimeZone}>
                                <Select
                                  required
                                  native
                                  labelId="timezones"
                                  name={ACCOUNT_FORM_FIELDS.timeZoneId}
                                  onChange={e => {
                                    setFieldValue(ACCOUNT_FORM_FIELDS.timeZoneId, e.target.value);
                                    const color = e.target.value === 'Select Timezone' ? 'darkgrey' : 'black';
                                    setColorForSelect(color);
                                  }}
                                  value={formRef?.current?.values?.TimeZoneId}
                                  onBlur={handleBlur}
                                  disableUnderline
                                  className={[classNames.select, classNames.themedTextField].join(' ')}
                                  style={{
                                    padding: '15px',
                                    position: 'relative',
                                    backgroundColor: newThemedBackgroundColor,
                                    color: newThemedTextColor,
                                    borderRadius: '10px',
                                  }}
                                >
                                  <option aria-label="Time Zone" value={null}>
                                    Select Timezone
                                  </option>
                                  {timeZoneArray?.length > 0 &&
                                    timeZoneArray.map(timeZone => (
                                      <option
                                        value={timeZone.countryName}
                                        style={{ backgroundColor: newThemedBackgroundColor, color: newThemedTextColor }}
                                      >
                                        {timeZone.name}
                                      </option>
                                    ))}
                                </Select>
                                {/* {errors && errors[ACCOUNT_FORM_FIELDS.timeZoneId] && touched[ACCOUNT_FORM_FIELDS.timeZoneId] && (
                      <StyledError PrimaryColor={PrimaryColor} template={activeTemplate}>
                        {errors[ACCOUNT_FORM_FIELDS.timeZoneId]}
                      </StyledError>
                    )} */}
                              </Grid>
                            </>
                          )}
                      </>
                    )}
                  </Grid>

                  {(isMonthlySessionSubscription || isPackage) && (
                    <Grid item md={12} xs={12} style={{ padding: '5px 50px' }}>
                      <CustomCreditCard
                        handleChange={handleChange}
                        activeTemplate={activeTemplate}
                        colorToUse={colorToUse}
                        values={values}
                        errors={errors}
                        touched={touched}
                        setShowTerms={setShowTerms}
                      />
                      {error && <CommonErrorMessage message={error} />}
                    </Grid>
                  )}
                </>
              )}
              {/* {!isEmpty(user) && isInviteToJoin && (
                <Grid item xs={12}>
                  <PageTitleSecond>You joined {title} successfully</PageTitleSecond>
                </Grid>
              )} */}
              {isEmpty(user) && (
                <Grid
                  item
                  md={12}
                  xs={12}
                  // style={{ padding: '5px 30px' }}
                >
                  <CustomAccountForm
                    handleChange={handleChange}
                    setFieldValue={setFieldValue}
                    handleBlur={handleBlur}
                    activeTemplate={activeTemplate}
                    isElectronicSignatureActive={isElectronicSignatureActive}
                    isMonthlySessionSubscription={isMonthlySessionSubscription}
                    formRef={formRef}
                    setDisableValue={setDisableValue}
                    setSmsReminderCheckBox={setSmsReminderCheckBox}
                    smsReminderCheckBox={smsReminderCheckBox}
                    setshowPhoneNumberFields={setshowPhoneNumberFields}
                    showPhoneNumberFields={showPhoneNumberFields}
                    setloginStep={setloginStep}
                    loginStep={loginStep}
                    setPhoneNumberField={setPhoneNumberField}
                    phoneNumberField={phoneNumberField}
                    colorToUse={colorToUse}
                    values={values}
                    errors={errors}
                    touched={touched}
                    step={step}
                    onClose={onClose}
                    setStepValue={setStepValue}
                    selectedTimeZone={selectedTimeZone}
                    showErrorPhoneNumber={showErrorPhoneNumber}
                  />
                </Grid>
              )}

              {(step === PURCHASE_MODAL_STEPS.enrollmentForm || step === PURCHASE_MODAL_STEPS.joinEnrollmentForm) && (
                <Grid item md={12} xs={12} style={{ padding: '5px 30px' }}>
                  <>
                    {(isShowInput(step, ACCOUNT_FORM_FIELDS.sessionReminder) ||
                      step == PURCHASE_MODAL_STEPS.loggedIn ||
                      step === PURCHASE_MODAL_STEPS.enrollmentForm ||
                      step === PURCHASE_MODAL_STEPS.joinEnrollmentForm) &&
                      showPhoneNumberFields === true && (
                        <Grid
                          item
                          alignItems="flex-start"
                          md={12}
                          xs={12}
                          style={{
                            flexDirection: 'row',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <Checkbox
                            id="create-contribution-agreement"
                            style={{ color: newThemedTextColor }}
                            color="primary"
                            checked={smsReminderCheckBox}
                            onChange={() => {
                              if (smsReminderCheckBox === true) {
                                setSmsReminderCheckBox(false);
                              } else {
                                setSmsReminderCheckBox(true);
                              }
                            }}
                          />
                          <div style={{ fontFamily: 'Avenir', fontWeight: '300', fontSize: '14px' }}>
                            Receive SMS session reminders
                          </div>
                        </Grid>
                      )}
                    {(isShowInput(step, ACCOUNT_FORM_FIELDS.phone) ||
                      step == PURCHASE_MODAL_STEPS.loggedIn ||
                      step == PURCHASE_MODAL_STEPS.login ||
                      step === PURCHASE_MODAL_STEPS.enrollmentForm ||
                      step === PURCHASE_MODAL_STEPS.joinEnrollmentForm) &&
                      ((smsReminderCheckBox === true && showPhoneNumberFields === true) ||
                        contribution?.isPhoneNumberAdded) && (
                        <Grid
                          container
                          direction="column"
                          alignItems="flex-start"
                          md={12}
                          xs={12}
                          style={{ marginBottom: '10px' }}
                        >
                          <div ref={phoneInputRef}>
                            {defaultCountry && (
                              <PhoneInput
                                key={coachPhoneNumberFocused + phoneInputKey}
                                value={coachPhoneNumber}
                                defaultCountry={defaultCountry}
                                {...(!coachPhoneNumberFocused && { disableDialCodePrefill: true })}
                                {...(coachPhoneNumberFocused && { forceDialCode: true })}
                                disableCountryGuess
                                placeholder="Phone Number"
                                onChange={phone => {
                                  setFieldValue(ACCOUNT_FORM_FIELDS?.phone, phone);
                                  setPhoneNumberField(phone);
                                  setCoachPhoneNumber(phone);
                                }}
                              />
                            )}
                            {errors && errors[ACCOUNT_FORM_FIELDS?.phone] && touched[ACCOUNT_FORM_FIELDS?.phone] && (
                              <StyledError PrimaryColor={PrimaryColor} template={activeTemplate}>
                                {errors[ACCOUNT_FORM_FIELDS?.phone]}
                              </StyledError>
                            )}
                          </div>
                        </Grid>
                      )}
                  </>
                  <EnrollmentForm
                    enrollmentForm={contribution.enrollmentFormViewModel}
                    colorToUse={colorToUse}
                    contribution={contribution}
                    activeTemplate={activeTemplate}
                    style={{
                      padding: '0px 30px',
                      ...(pathname.includes('sessions') ? { backgroundColor: newThemedBackgroundColor } : {}),
                    }}
                    onSubmitSuccess={() => setEnrollmentFormSubmitted(true)}
                    onClose={onClose}
                    selectedTimezone={selectedTimeZone}
                    timeZones={timeZones}
                    countries={countries}
                  />
                </Grid>
              )}

              {isElectronicSignatureActive && customWaiverId === null && showPadForLoggedInClient && (
                <Grid item md={12} xs={12} style={{ padding: '5px 30px' }}>
                  <div style={{ position: 'relative' }}>
                    <SignatureCanvas
                      ref={canvasRef}
                      clearOnResize={false}
                      penColor="black"
                      canvasProps={{ height: '200px', className: classNames.inputField }}
                      onEnd={() => {
                        // setDisableBtn(false);
                        setDisableValue(false);
                        if (canvasRef?.current) {
                          const val = canvasRef?.current?.getTrimmedCanvas().toDataURL('image/png');
                          setFieldValue(ACCOUNT_FORM_FIELDS.signature, val);
                        }
                      }}
                    />
                    <div
                      type="button"
                      onClick={clearSign}
                      style={{
                        cursor: 'pointer',
                        position: 'absolute',
                        top: '5px',
                        right: '10px',
                        // color: colors.darkOceanBlue,
                        fontWeight: 'bold',
                      }}
                    >
                      Clear
                    </div>
                  </div>
                  <p
                    style={{
                      marginBottom: '0px',
                      fontSize: '12px',
                      marginLeft: '5px',
                      color: PrimaryColor,
                    }}
                  >
                    Sign Here To Join
                  </p>
                </Grid>
              )}
            </Grid>
            {step !== PURCHASE_MODAL_STEPS.enrollmentForm &&
              step !== PURCHASE_MODAL_STEPS.joinEnrollmentForm &&
              step !== PURCHASE_MODAL_STEPS.join &&
              step !== PURCHASE_MODAL_STEPS.init &&
              !isMonthlySessionSubscription &&
              !isPackage && (
                <>
                  {(isShowInput(step, ACCOUNT_FORM_FIELDS.sessionReminder) || step == PURCHASE_MODAL_STEPS.loggedIn) &&
                    showPhoneNumberFields === true && (
                      <Grid
                        item
                        alignItems="flex-start"
                        md={12}
                        xs={12}
                        style={{
                          padding: '5px 50px',
                          flexDirection: 'row',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <Checkbox
                          style={{ color: newThemedTextColor }}
                          id="create-contribution-agreement"
                          color="primary"
                          checked={smsReminderCheckBox}
                          onChange={() => {
                            if (smsReminderCheckBox === true) {
                              setSmsReminderCheckBox(false);
                            } else {
                              setSmsReminderCheckBox(true);
                            }
                          }}
                        />
                        <div style={{ fontFamily: 'Avenir', fontWeight: '300', fontSize: '14px' }}>
                          Receive SMS session reminders
                        </div>
                      </Grid>
                    )}
                  {(isShowInput(step, ACCOUNT_FORM_FIELDS.sessionReminder) || step == PURCHASE_MODAL_STEPS.loggedIn) &&
                    showPhoneNumberFields === true && (
                      <Grid
                        container
                        direction="column"
                        alignItems="flex-start"
                        md={12}
                        xs={12}
                        style={{ padding: '5px 50px' }}
                      >
                        <div style={{ width: '100%' }} ref={phoneInputRef}>
                          {defaultCountry && (
                            <PhoneInput
                              key={coachPhoneNumberFocused + phoneInputKey}
                              value={coachPhoneNumber}
                              defaultCountry={defaultCountry}
                              {...(!coachPhoneNumberFocused && { disableDialCodePrefill: true })}
                              {...(coachPhoneNumberFocused && { forceDialCode: true })}
                              disableCountryGuess
                              placeholder="Phone Number"
                              onChange={phone => {
                                setFieldValue(ACCOUNT_FORM_FIELDS?.phone, phone);
                                setPhoneNumberField(phone);
                                setCoachPhoneNumber(phone);
                              }}
                            />
                          )}
                          {errors && errors[ACCOUNT_FORM_FIELDS?.phone] && touched[ACCOUNT_FORM_FIELDS?.phone] && (
                            <StyledError PrimaryColor={PrimaryColor} template={activeTemplate}>
                              {errors[ACCOUNT_FORM_FIELDS?.phone]}
                            </StyledError>
                          )}
                          {showErrorPhoneNumber && (
                            <StyledError PrimaryColor={PrimaryColor} template={activeTemplate}>
                              Please enter valid phone number
                            </StyledError>
                          )}
                        </div>
                      </Grid>
                    )}
                  <Grid className="session-date-time" item md={12} xs={12} style={{ padding: '5px 50px' }}>
                    {/* <SessionDateAndTime session={sessionInfo} timeZoneId={timeZoneId} /> */}
                    {!isEmpty(user) && showClientInvitedView && showThreeFields && (
                      <>
                        {contribution.paymentType === 'Simple' &&
                          contribution.taxType != 'No' &&
                          !isMonthlySessionSubscription &&
                          isInviteToJoin === null &&
                          singleSessionInvite?.priceOption != 'Credit' &&
                          singleSessionInvite?.priceOption != 'Free' && (
                            <>
                              <Grid
                                container
                                item
                                direction="column"
                                alignItems="flex-start"
                                md={12}
                                xs={12}
                                style={{
                                  padding: '5px 5px',
                                  position: 'relative',
                                }}
                              >
                                <Typography variant="h6" style={{ fontSize: '20px', color: newThemedTextColor }}>
                                  Where are you located?
                                </Typography>
                              </Grid>
                              <Grid item xs={12} className={selectStyle.handleNewFormFieldsTimeZone}>
                                <Select
                                  label="Country of Residence"
                                  name={ACCOUNT_FORM_FIELDS.country}
                                  fullWidth
                                  required
                                  native
                                  labelId="country"
                                  value={formRef?.current?.values?.countryId}
                                  className={[classNames.select, classNames.themedTextField].join(' ')}
                                  onChange={e => {
                                    const filteredObjects = states.filter(obj => obj.countryId === e.target.value);
                                    const filteredCountry = countries.find(obj => obj.id === e.target.value);
                                    const filteredTimezone = timeZones.filter(obj => obj.countryId === e.target.value);
                                    if (filteredObjects.length >= 1) {
                                      setStatesArray(filteredObjects);
                                    } else if (filteredObjects.length === 0) {
                                      setStatesArray(filteredObjects);
                                      if (filteredTimezone.length === 0) {
                                        setTimeZoneArray(timeZones);
                                      } else if (
                                        filteredTimezone.length === 1 &&
                                        filteredCountry.id != '60b8ddb57205057e7ce2b861'
                                      ) {
                                        setTimeZoneArray(filteredTimezone);
                                        setFieldValue(ACCOUNT_FORM_FIELDS.timeZoneId, filteredTimezone[0].countryName);
                                      } else {
                                        setTimeZoneArray(filteredTimezone);
                                      }
                                    }
                                    setFieldValue(ACCOUNT_FORM_FIELDS.state, '');
                                    setFieldValue(ACCOUNT_FORM_FIELDS.country, filteredCountry.id);
                                  }}
                                  disableUnderline
                                  style={{
                                    padding: '15px',
                                    position: 'relative',
                                    backgroundColor: newThemedBackgroundColor,
                                    color: newThemedTextColor,
                                    borderRadius: '10px',
                                  }}
                                >
                                  <option aria-label="Time Zone" value={null}>
                                    Select Country
                                  </option>
                                  {countries?.length > 0 &&
                                    countries.map(timeZone => <option value={timeZone.id}>{timeZone.name}</option>)}
                                </Select>
                              </Grid>
                            </>
                          )}
                        {contribution.paymentType === 'Simple' &&
                          contribution.taxType != 'No' &&
                          formRef?.current?.values?.countryId === '60b8ddb57205057e7ce2b861' &&
                          isInviteToJoin === null &&
                          statesArray.length > 1 &&
                          !isMonthlySessionSubscription && (
                            <Grid item xs={12} className={selectStyle.handleNewFormFieldsTimeZone}>
                              <Select
                                label="State"
                                name={ACCOUNT_FORM_FIELDS.state}
                                fullWidth
                                required
                                native
                                labelId="stateCode"
                                style={{
                                  padding: '15px',
                                  position: 'relative',
                                  backgroundColor: newThemedBackgroundColor,
                                  color: newThemedTextColor,
                                  borderRadius: '10px',
                                }}
                                value={formRef?.current?.values?.stateCode}
                                className={[classNames.select, classNames.themedTextField].join(' ')}
                                onChange={e => {
                                  const filteredState = states.find(obj => obj.alpha2Code === e.target.value);
                                  const filteredObjects = timeZones.filter(obj =>
                                    obj.statesIds.includes(filteredState.id),
                                  );
                                  if (filteredObjects.length >= 1) {
                                    if (filteredObjects.length === 1) {
                                      setTimeZoneArray(filteredObjects);
                                      setFieldValue(ACCOUNT_FORM_FIELDS.timeZoneId, filteredObjects[0].countryName);
                                    } else {
                                      setFieldValue(ACCOUNT_FORM_FIELDS.timeZoneId, filteredObjects[0].countryName);
                                      setTimeZoneArray(filteredObjects);
                                    }
                                  } else {
                                    setTimeZoneArray(timeZones);
                                  }
                                  setFieldValue(ACCOUNT_FORM_FIELDS.state, filteredState.alpha2Code);
                                }}
                                disableUnderline
                              >
                                <option aria-label="Time Zone" value={null}>
                                  Select State
                                </option>
                                {statesArray?.length > 0 &&
                                  statesArray.map(timeZone => (
                                    <option value={timeZone.alpha2Code}>{timeZone.name}</option>
                                  ))}
                              </Select>
                            </Grid>
                          )}
                        {contribution.paymentType === 'Simple' &&
                          contribution.taxType != 'No' &&
                          isInviteToJoin === null &&
                          timeZoneArray.length > 1 && (
                            <>
                              <Grid item xs={12} className={selectStyle.handleNewFormFieldsTimeZone}>
                                <Select
                                  required
                                  native
                                  labelId="timezones"
                                  name={ACCOUNT_FORM_FIELDS.timeZoneId}
                                  onChange={e => {
                                    setFieldValue(ACCOUNT_FORM_FIELDS.timeZoneId, e.target.value);
                                    const color = e.target.value === 'Select Timezone' ? 'darkgrey' : 'black';
                                    setColorForSelect(color);
                                  }}
                                  value={formRef?.current?.values?.TimeZoneId}
                                  onBlur={handleBlur}
                                  disableUnderline
                                  className={[classNames.select, classNames.themedTextField].join(' ')}
                                  style={{
                                    padding: '15px',
                                    position: 'relative',
                                    backgroundColor: newThemedBackgroundColor,
                                    color: newThemedTextColor,
                                    borderRadius: '10px',
                                  }}
                                >
                                  <option aria-label="Time Zone" value={null}>
                                    Select Timezone
                                  </option>
                                  {timeZoneArray?.length > 0 &&
                                    timeZoneArray.map(timeZone => (
                                      <option
                                        value={timeZone.countryName}
                                        style={{ backgroundColor: newThemedBackgroundColor, color: newThemedTextColor }}
                                      >
                                        {timeZone.name}
                                      </option>
                                    ))}
                                </Select>
                                {/* {errors && errors[ACCOUNT_FORM_FIELDS.timeZoneId] && touched[ACCOUNT_FORM_FIELDS.timeZoneId] && (
                <StyledError PrimaryColor={PrimaryColor} template={activeTemplate}>
                  {errors[ACCOUNT_FORM_FIELDS.timeZoneId]}
                </StyledError>
              )} */}
                              </Grid>
                            </>
                          )}
                      </>
                    )}
                  </Grid>
                  <Grid item md={12} xs={12} style={{ padding: '0px 35px' }}>
                    {customToS ? (
                      isElectronicSignatureActive ? (
                        <Typography style={{ color: newThemedTextColor }} variant="caption">
                          22By signing and clicking reserve,I agree to the{' '}
                          <StyledLink
                            href="/"
                            color={PrimaryColor}
                            onClick={e => {
                              e.preventDefault();
                              setShowTerms(true);
                            }}
                          >
                            Terms and Conditions
                          </StyledLink>
                          , I also agree to {serviceProviderName}
                          {"'"}s{' '}
                          <StyledLink color={PrimaryColor} href={customToS}>
                            Terms and Conditions
                          </StyledLink>
                          , and I'm at least 18 years old.
                        </Typography>
                      ) : (
                        <Typography style={{ color: newThemedTextColor }} variant="caption">
                          By clicking reserve, I agree to the{' '}
                          <StyledLink
                            href="/"
                            color={PrimaryColor}
                            onClick={e => {
                              e.preventDefault();
                              setShowTerms(true);
                            }}
                          >
                            Terms and Conditions{' '}
                          </StyledLink>
                          and {serviceProviderName}'s{' '}
                          {customToS.length > 0 ? (
                            <StyledLink color={PrimaryColor} href={customToS}>
                              Terms and Conditions
                            </StyledLink>
                          ) : (
                            customWaiverId && (
                              <StyledDiv
                                href="/"
                                color={PrimaryColor}
                                onClick={e => {
                                  e.preventDefault();
                                  // getWaiverForm();
                                }}
                              >
                                {customWaiverTemplateName.trim()}
                              </StyledDiv>
                            )
                          )}
                          , and I'm at least 18 years old.
                        </Typography>
                      )
                    ) : (
                      <>
                        <Typography style={{ color: newThemedTextColor, marginTop: 10 }} variant="caption">
                          {isElectronicSignatureActive ? 'By signing and clicking reserve' : 'By clicking reserve'}, I
                          agree to the{' '}
                          <StyledLink
                            href="/"
                            color={PrimaryColor}
                            onClick={e => {
                              e.preventDefault();
                              setShowTerms(true);
                            }}
                          >
                            Terms and Conditions
                          </StyledLink>
                          {customWaiverId && (
                            <>
                              {' '}
                              and {serviceProviderName}{' '}
                              <StyledDiv color={PrimaryColor}>{customWaiverTemplateName}</StyledDiv>
                            </>
                          )}
                          , and I'm at least 18 years old.
                        </Typography>
                        <br />
                      </>
                    )}
                  </Grid>
                </>
              )}
            {!(step === PURCHASE_MODAL_STEPS.enrollmentForm || step === PURCHASE_MODAL_STEPS.joinEnrollmentForm) && (
              <>
                {getIsGuestUserFlow() ? (
                  <div
                    className="easy-booking-slot-buttons"
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      width: '100%',
                      gap: '1rem',
                      padding: '0 50px',
                    }}
                  >
                    <StyledButton
                      style={{
                        borderRadius: borderRad,
                        ...(pathname.includes('sessions') && {
                          backgroundColor: !contribution.isDarkModeEnabled ? 'white' : newThemedBackgroundColor,
                          color: contribution.isDarkModeEnabled ? 'white' : 'black',
                          border: `1px solid ${contribution.isDarkModeEnabled ? 'white' : 'black'}`,
                        }),
                        maxWidth: '90%',
                        borderRadius: '0.3125rem',
                        padding: '0.625rem 1.5rem',
                      }}
                      backgroundColor="darkgrey"
                      activeTemplate={activeTemplate}
                      onClick={onClose}
                    >
                      Cancel
                    </StyledButton>
                    <>
                      <StyledButton
                        style={{
                          borderRadius: borderRad,
                          // margin: isCodeToJoin ? '0px 42px 10px' : '0px 0px 10px',
                          color: TextColor,
                          maxWidth: '90%',
                          borderRadius: '0.3125rem',
                          padding: '0.625rem 1.5rem',
                        }}
                        backgroundColor={PrimaryColor}
                        type="submit"
                        activeTemplate={activeTemplate}
                        disabled={disableSubmitBtn || disableJoinBtn}
                      >
                        Book
                      </StyledButton>
                    </>
                  </div>
                ) : (
                  <Grid item md={12} xs={12} style={{ padding: '5px 50px' }}>
                    {/* <button type="submit">{submitTitle}</button> */}
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <StyledButton
                        style={{
                          borderRadius: borderRad,
                          // margin: isCodeToJoin ? '0px 42px 10px' : '0px 0px 10px',
                          color: TextColor,
                        }}
                        backgroundColor={PrimaryColor}
                        marginTop
                        type="submit"
                        activeTemplate={activeTemplate}
                        disabled={disableSubmitBtn || disableJoinBtn}
                      >
                        {submitTitle}
                      </StyledButton>
                    </div>
                  </Grid>
                )}
              </>
            )}
          </>
        </Form>
      </FormikScrollToError>
      <Popup
        open={sessionTimeExpired}
        title="The checkout time has expired"
        text="The checkout time has expired. Please select your session time again to complete registration."
        onCloseText="Select Session"
        onClose={onCheckoutSessionExpired}
      />
      {isLoading && <Loader />}
    </>
  );
};

CustomPurchaseModalFormSingleSession.propTypes = {
  loading: PropTypes.bool.isRequired,
  summary: PropTypes.shape({
    price: PropTypes.number,
    platformFee: PropTypes.number,
    dueNow: PropTypes.number,
    dueLater: PropTypes.number,
  }),
  isPackage: PropTypes.bool.isRequired,
  isMonthlySessionSubscription: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleRedeem: PropTypes.func.isRequired,
  errors: PropTypes.arrayOf(PropTypes.shape({})),
  touched: PropTypes.bool.isRequired,
  error: PropTypes.shape({}),
  setShowTerms: PropTypes.func.isRequired,
  step: PropTypes.string.isRequired,
  paymentIntentCreated: PropTypes.string,
  sessionLifeTimeSeconds: PropTypes.number,
  monthlySessionSubscriptionInfo: PropTypes.shape({
    sessionCount: PropTypes.number,
    duration: PropTypes.number,
    monthlyPrice: PropTypes.number,
  }),
};

CustomPurchaseModalFormSingleSession.defaultProps = {
  summary: undefined,
  isInviteToJoin: null,
  errors: undefined,
  error: undefined,
  paymentIntentCreated: undefined,
  sessionLifeTimeSeconds: undefined,
};
