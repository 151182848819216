import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { Video } from 'components/Video/Video';
import VideoButton from 'components/FormUI/Button';
import PdfViewer from 'components/PdfViewer/PdfViewer';
import useVideoPlayer from 'hooks/useVideoPlayer';
import useContribution from 'pages/ContributionView/hooks/useContribution';
import { handleSelfpacedMediaDownload } from '../../helpers';
import ButtonWraper from 'components/FormUI/Button';
import { getThemedColors } from 'services/contributions.service';
import { useMediaQuery, useTheme } from '@material-ui/core';
import useAccount from 'hooks/useAccount';

const StyledPdfContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  border-radius: 0.25rem;
  /* border: 1px solid ${({ borderColor }) => borderColor || 'var(--Cohere-Greys-Grey-Tint, #DFE3E4)'};
  padding: 0.5rem 1.25rem; */
  gap: 1rem;
  min-height: 90px;
  border: none;
  padding: 0;

  .MuiGrid-container {
    width: auto;
  }
`;

const StyledPdfContent = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const StyledPdfButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
`;

const StyledBody = styled.div`
  padding: 20px 0px;
`;
const StyledPdfViewerWrapper = styled.div`
  padding-bottom: 1.125rem;
  ${({ mobileView }) =>
    mobileView &&
    css`
      /* Note: To remove padding from main parent div of column body */
      margin-right: -1.5rem;
      margin-left: -1.5rem;
    `}
`;

const SelfPacedPdf = ({ session, sessionTime, sessionTimeIndex, colorToUse, textColor }) => {
  const [isViewDocument, setIsViewDocument] = useState(false);
  const [pdfSource, setPdfSource] = useState(null);
  const contribution = useContribution();
  const { generatePreRecordedVideoLink } = useVideoPlayer({
    sessionTime,
  });
  const { isDarkModeEnabled } = contribution;
  const { themedCardOutlineColor, themedColor, newThemedTextColor, newThemedBackgroundColor } = getThemedColors(
    contribution,
  );
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const { isCoach } = useAccount();

  const getLink = () => {
    generatePreRecordedVideoLink({
      contributionId: contribution.id,
      sessionId: session.id,
    }).then(({ videoUrl }) => setPdfSource(videoUrl));
  };
  const handleDownload = () =>
    generatePreRecordedVideoLink({
      contributionId: contribution.id,
      sessionId: session.id,
    }).then(({ videoUrl, fileName }) => handleSelfpacedMediaDownload(videoUrl, fileName));

  useEffect(() => {
    setIsViewDocument(false);
    getLink();
  }, [session, sessionTime]);
  return (
    <>
      {!isViewDocument ? (
        <StyledBody>
          <StyledPdfContainer borderColor={isDarkModeEnabled && themedCardOutlineColor}>
            <StyledPdfContent>
              <Video
                style={{ width: '100%' }}
                isPreviewMode
                isSessionView
                session={session}
                sessionTime={sessionTime}
                sessionTimeIndex={sessionTimeIndex}
              />
              <div>{sessionTime?.prerecordedSession?.documentOriginalNameWithExtension}</div>
            </StyledPdfContent>
            <StyledPdfButtons>
              <ButtonWraper
                textColor={colorToUse.PrimaryColorCode}
                borderColor={colorToUse.PrimaryColorCode}
                variant="outline"
                autoWidth
                onClick={handleDownload}
                backgroundColor={isDarkModeEnabled ? 'transparent' : null}
              >
                Download
              </ButtonWraper>

              <VideoButton
                textColor={textColor}
                backgroundColor={colorToUse?.PrimaryColorCode}
                autoWidth
                onClick={() => {
                  setIsViewDocument(true);
                }}
              >
                View Document
              </VideoButton>
            </StyledPdfButtons>
          </StyledPdfContainer>
          {/* {isViewDocument && <PdfViewer {...{ videoSource, setIsViewDocument }} />} */}
        </StyledBody>
      ) : (
        <StyledPdfViewerWrapper mobileView={mobileView}>
          {pdfSource && (
            <PdfViewer
              handleDownload={handleDownload}
              pdfSource={pdfSource}
              setIsViewDocument={setIsViewDocument}
              fileName={sessionTime?.prerecordedSession?.documentOriginalNameWithExtension}
              {...{
                loadingScreenTextColor: isCoach ? themedColor : newThemedTextColor,
                loadingScreenBackgroundColor: newThemedBackgroundColor,
              }}
            />
          )}
        </StyledPdfViewerWrapper>
      )}
    </>
  );
};

export default SelfPacedPdf;
