import { makeStyles } from '@material-ui/core';
const styles = theme => ({
  textArea: {
    backgroundColor: ({ backgroundColor }) => backgroundColor,
    color: ({ color }) => color,
    '&::placeholder': {
      color: ({ color }) => color,
    },
  },
  Input: {
    backgroundColor: ({ backgroundColor }) => backgroundColor,
    color: ({ color }) => color,
    '&::placeholder': {
      color: ({ color }) => color,
    },
    '& input': {
      color: ({ color }) => color,
      '&::placeholder': {
        color: ({ color }) => color,
      },
    },
    '& input:disabled': {
      color: ({ color }) => color,
      '-webkit-text-fill-color': ({ color }) => color,
      '&::placeholder': {
        color: ({ color }) => color,
      },
    },
  },
  Select: {
    backgroundColor: ({ backgroundColor }) => backgroundColor,
    color: ({ color }) => color,
    '&::placeholder': {
      color: ({ color }) => `${color} !important`,
    },
    '& .ui': {
      backgroundColor: ({ backgroundColor }) => backgroundColor,
      color: ({ color }) => `${color} !important`,
    },
  },
  underline: {
    borderBottom: '1px solid #f2f2f2',
  },
});
export const useStyles = makeStyles(styles);
