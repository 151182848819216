import { useCallback } from 'react';

export const useHandlers = ({ onAssignPod, onBlurElementRef, setSelectedValue }) => {
  const onChange = useCallback(
    (_, node) => {
      const { value } = node.props;

      setSelectedValue(value);
      onAssignPod(value);
    },
    [onAssignPod, setSelectedValue],
  );

  const onClick = useCallback(
    event => {
      // reset value if same option is selected
      if (event.target.value === 0) {
        setSelectedValue('');
        onAssignPod('');
      }
    },
    [onAssignPod, setSelectedValue],
  );

  const onExited = useCallback(() => {
    onBlurElementRef.current.focus();
  }, [onBlurElementRef]);

  return {
    onChange,
    onClick,
    onExited,
  };
};
