import { useCallback } from 'react';

export const useHandlers = ({ clientIds, setState, state, onDelete, onUpdate, podId }) => {
  const { initialValue, isEditPodName, value } = state;

  const onCancelDelete = useCallback(() => setState({ isOpenModalDelete: false }), [setState]);

  const onCancelUpdate = useCallback(() => setState({ isOpenModalUpdate: false, value: initialValue }), [
    initialValue,
    setState,
  ]);

  const onChangePodName = useCallback(event => setState({ value: event.target.value }), [setState]);

  const onConfirmDelete = useCallback(() => onDelete(podId), [onDelete, podId]);

  const onConfirmUpdate = useCallback(() => onUpdate({ clientIds, podId, value }), [clientIds, onUpdate, podId, value]);

  const onEditPodName = useCallback(() => {
    const currentValue = value.trim();
    const isUpdate = isEditPodName && currentValue && currentValue !== initialValue;
    const isReset = isEditPodName && !currentValue;

    if (isUpdate) {
      setState({ isOpenModalUpdate: true });
    }

    if (isReset) {
      setState({ value: initialValue });
    }

    setState({ isEditPodName: !isEditPodName });
  }, [initialValue, isEditPodName, setState, value]);

  const onOpenDeleteModal = useCallback(() => setState({ isOpenModalDelete: true }), [setState]);

  return {
    onCancelDelete,
    onCancelUpdate,
    onChangePodName,
    onConfirmDelete,
    onConfirmUpdate,
    onEditPodName,
    onOpenDeleteModal,
  };
};
