import DeviceType from '../components/UI/VideoChat/VideoChat/Toolbar/devices/DevicesList/DeviceType.js';
import Device from '../components/UI/VideoChat/VideoChat/Toolbar/devices/DevicesList/Device.js';

export const getAudioInputDevices = () =>
  navigator.mediaDevices
    .enumerateDevices()
    .then(ds => ds.filter(d => d.kind === DeviceType.audioInput))
    .then(ds =>
      ds.map(
        d =>
          new Device({
            id: d.deviceId,
            label: d.label,
            type: d.kind,
          }),
      ),
    );

export const getAudioOutputDevices = () =>
  navigator.mediaDevices
    .enumerateDevices()
    .then(ds => ds.filter(d => d.kind === DeviceType.audioOutput))
    .then(ds =>
      ds.map(
        d =>
          new Device({
            id: d.deviceId,
            label: d.label,
            type: d.kind,
          }),
      ),
    );

export const getVideoDevices = () =>
  navigator.mediaDevices
    .enumerateDevices()
    .then(ds => ds.filter(d => d.kind === DeviceType.videoInput))
    .then(ds =>
      ds.map(
        d =>
          new Device({
            id: d.deviceId,
            label: d.label,
            type: d.kind,
          }),
      ),
    );
