import React from 'react';
import PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe';

const MASKS = {
  date: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/],
};

const autoCorrectedDatePipe = createAutoCorrectedDatePipe('mm/dd/yyyy');

function CustomMaskedInput(props) {
  const { inputRef, mask, ...other } = props;
  return (
    <MaskedInput
      {...other}
      mask={MASKS[mask]}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      keepCharPositions
      pipe={autoCorrectedDatePipe}
    />
  );
}

CustomMaskedInput.propTypes = {
  mask: PropTypes.string.isRequired,
  inputRef: PropTypes.func.isRequired,
};

export default CustomMaskedInput;
