import axiosInstance from 'utils/axiosInstance';

const ENDPOINT = '/api/Pod';

const addMemberPod = async data => {
  const response = await axiosInstance.post(ENDPOINT, data);

  return response.data;
};

const deleteMemberPod = podId => {
  return axiosInstance.delete(`${ENDPOINT}/${podId}`);
};

const getMemberPods = async userId => {
  const response = await axiosInstance.get(ENDPOINT, { params: { userId } });

  return response.data;
};

const updateMemberPod = data => {
  const { podId } = data;

  return axiosInstance.put(`${ENDPOINT}/${podId}`, {
    ...data,
    id: podId,
  });
};

const getContributionPods = async contributionId => {
  const response = await axiosInstance.get(`${ENDPOINT}/GetByContributionId`, { params: { contributionId } });

  return response.data;
};

export const memberPodsService = {
  addMemberPod,
  deleteMemberPod,
  getMemberPods,
  updateMemberPod,
  getContributionPods,
};
