import React, { useState } from 'react';
import classes from './Filter.module.scss';
import Button from 'components/FormUI/Button';
import CrossIcon from './SVG/CrossIcon';
import Select from 'components/UI/Select';
import DateTimePicker from 'components/FormUI/DateTimePicker';
import moment from 'moment';
import { Formik, Form } from 'formik';
import { FilterTypes, INITIALS } from './FilterConstants';
import useCampaign from 'pages/CreateCampaign/Hooks/useCampaign';
import { TypeNames } from '../../Utils/constants';

const STATUSES = [
  { title: 'Draft', value: 'Draft' },
  { title: 'Sent', value: 'Sent' },
  { title: 'Scheduled', value: 'Scheduled' },
];

const TYPES = [
  { title: 'Lead', value: TypeNames.Lead },
  { title: 'Client', value: TypeNames.Client },
];

const Filter = ({ onCancelClick, onSubmitClick, type, value, onClear = () => {}, contacts = [] }) => {
  const isLeadFilter = type === FilterTypes.Lead;
  const isCampaignFilter = type === FilterTypes.Campaign;
  const isAllContactsFilter = type === FilterTypes.AllContacts;
  const isAllClientsFilter = type === FilterTypes.Client;

  let filteredContacts = contacts.filter(res => res.isContribution === true && res.isImported === false);
  const uniqueContributions = Object.values(
    filteredContacts.reduce((acc, curr) => {
      if (!acc[curr.recenetContributionId]) {
        acc[curr.recenetContributionId] = {
          title: curr.contributionTitle,
          value: curr.recenetContributionId,
        };
      }
      return acc;
    }, {}),
  );
  const CONTRIBUTIONS = uniqueContributions;

  const flattenedTags = contacts.flatMap(item => item.tags || []);

  const uniqueTags = Object.values(
    flattenedTags.reduce((acc, tag) => {
      if (tag.id !== null && !acc[tag.id]) {
        acc[tag.id] = {
          title: tag.name,
          value: tag.id,
        };
      }
      return acc;
    }, {}),
  );

  const TAGS = uniqueTags;

  const { campaignsCollection: campaigns, isLoading } = useCampaign();
  const getInitial = () => {
    if (isLeadFilter || isAllContactsFilter || isAllClientsFilter) {
      return {
        fromDate: value?.fromDate || null,
        toDate: value?.toDate || null,
        contributions: value?.contributions || [],
        tags: value?.tags || [],
        type: value?.type || [],
      };
    } else if (isCampaignFilter) {
      return {
        fromDate: value?.fromDate || null,
        toDate: value?.toDate || null,
        status: value?.status || [null],
      };
    }
  };

  const sortedCampaigns = campaigns.sort((a, b) => {
    const aCreateTime = new Date(a.createTime);
    const bCreateTime = new Date(b.createTime);
    if (a.scheduleDateTime && b.scheduleDateTime) {
      const aScheduleTime = new Date(a.scheduleDateTime);
      const bScheduleTime = new Date(b.scheduleDateTime);
      return bScheduleTime - aScheduleTime;
    } else if (a.scheduleDateTime) {
      return -1;
    } else if (b.scheduleDateTime) {
      return 1;
    } else {
      return bCreateTime - aCreateTime;
    }
  });
  const sortedArray = arr => {
    let array = arr.sort((a, b) => {
      const aCreateTime = new Date(a.joinDate);
      const bCreateTime = new Date(b.joinDate);
      if (a.scheduleDateTime && b.scheduleDateTime) {
        const aScheduleTime = new Date(a.scheduleDateTime);
        const bScheduleTime = new Date(b.scheduleDateTime);
        return bScheduleTime - aScheduleTime;
      } else if (a.scheduleDateTime) {
        return -1;
      } else if (b.scheduleDateTime) {
        return 1;
      } else {
        return bCreateTime - aCreateTime;
      }
    });
    return array;
  };
  const getMaxDate = () => {
    if (isCampaignFilter) {
      return moment(sortedCampaigns[0].scheduleDateTime || sortedCampaigns[0].createTime);
    } else {
      return moment();
    }
  };

  return (
    <Formik initialValues={getInitial()} onSubmit={onSubmitClick}>
      {({ values, setFieldValue, handleSubmit, resetForm }) => {
        return (
          <Form>
            <div className={classes.container}>
              <div className={classes.top_container}>
                <div className={classes.heading}>Filter By:</div>
                <div className={classes.cross_btn} onClick={onCancelClick}>
                  <CrossIcon />
                </div>
              </div>
              <div className={classes.body_container}>
                {(isLeadFilter || isCampaignFilter || isAllContactsFilter || isAllClientsFilter) && (
                  <div className={classes.main_container}>
                    <div className={classes.heading}>{isCampaignFilter ? 'Send Date' : 'Date Subscribed'}</div>
                    <div className={classes.date_filter_container}>
                      <div className={classes.date_heading}>From:</div>
                      <div className={classes.date_picker_container}>
                        <DateTimePicker
                          className={classes.date_picker}
                          type="date"
                          fullWidth
                          name="fromDate"
                          value={values.fromDate}
                          maxDate={getMaxDate()}
                          onChange={date => {
                            setFieldValue('fromDate', date);
                            if (isCampaignFilter == false) {
                              setFieldValue('toDate', null);
                            }
                          }}
                          InputProps={{
                            classes: {
                              root: classes.date_root,
                              underline: classes.date_underline,
                              input: classes.date_input,
                            },
                          }}
                          format={'MMM DD, YYYY'}
                          underline={false}
                          disablePast={false}
                        />
                      </div>
                    </div>
                    <div className={classes.date_filter_container}>
                      <div className={classes.date_heading}>To:</div>
                      <div className={classes.date_picker_container}>
                        {isCampaignFilter ? (
                          <DateTimePicker
                            className={classes.date_picker}
                            type="date"
                            fullWidth
                            name="toDate"
                            value={values.toDate}
                            onChange={date => {
                              setFieldValue('toDate', date);
                            }}
                            InputProps={{
                              classes: {
                                root: classes.date_root,
                                underline: classes.date_underline,
                                input: classes.date_input,
                              },
                            }}
                            format={'MMM DD, YYYY'}
                            maxDate={values.fromDate}
                            underline={false}
                            disablePast={false}
                          />
                        ) : values.fromDate === null ? (
                          <DateTimePicker
                            className={classes.date_picker}
                            type="date"
                            fullWidth
                            name="toDate"
                            value={values.toDate}
                            onChange={date => {
                              setFieldValue('toDate', date);
                            }}
                            InputProps={{
                              classes: {
                                root: classes.date_root,
                                underline: classes.date_underline,
                                input: classes.date_input,
                              },
                            }}
                            format={'MMM DD, YYYY'}
                            maxDate={moment()}
                            underline={false}
                            disablePast={false}
                          />
                        ) : (
                          <DateTimePicker
                            className={classes.date_picker}
                            type="date"
                            fullWidth
                            name="toDate"
                            value={values.toDate}
                            onChange={date => {
                              setFieldValue('toDate', date);
                            }}
                            InputProps={{
                              classes: {
                                root: classes.date_root,
                                underline: classes.date_underline,
                                input: classes.date_input,
                              },
                            }}
                            format={'MMM DD, YYYY'}
                            maxDate={moment()}
                            minDate={values.fromDate === null ? moment() : moment(values.fromDate).add(1, 'days')}
                            underline={false}
                            disablePast={false}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                )}
                {(isLeadFilter || isAllContactsFilter || isAllClientsFilter) && (
                  <div className={classes.main_container}>
                    <div className={classes.heading}>By Contributions</div>
                    <div className={classes.filter_container}>
                      <Select
                        className={classes.filter}
                        disableUnderline={true}
                        showHelperText={false}
                        fullWidth
                        multiple
                        items={CONTRIBUTIONS}
                        value={values.contributions}
                        emailMarketing={true}
                        classes={{
                          select: classes.filter_select,
                        }}
                        onChange={e => {
                          setFieldValue('contributions', e.target.value);
                        }}
                        MenuProps={{
                          disablePortal: true,
                        }}
                      />
                    </div>
                  </div>
                )}
                {(isLeadFilter || isAllContactsFilter || isAllClientsFilter) && (
                  <div className={classes.main_container}>
                    <div className={classes.heading}>By Tags</div>
                    <div className={classes.filter_container}>
                      <Select
                        className={classes.filter}
                        disableUnderline={true}
                        showHelperText={false}
                        fullWidth
                        multiple
                        items={TAGS}
                        value={values.tags}
                        classes={{
                          select: classes.filter_select,
                        }}
                        onChange={e => {
                          setFieldValue('tags', e.target.value);
                        }}
                        MenuProps={{
                          disablePortal: true,
                        }}
                      />
                    </div>
                  </div>
                )}
                {isAllContactsFilter && (
                  <div className={classes.main_container}>
                    <div className={classes.heading}>Type</div>
                    <div className={classes.filter_container}>
                      <Select
                        className={classes.filter}
                        disableUnderline={true}
                        showHelperText={false}
                        fullWidth
                        multiple
                        items={TYPES}
                        value={values.type}
                        classes={{
                          select: classes.filter_select,
                        }}
                        onChange={e => {
                          setFieldValue('type', e.target.value);
                        }}
                        MenuProps={{
                          disablePortal: true,
                        }}
                      />
                    </div>
                  </div>
                )}
                {isCampaignFilter && (
                  <div className={classes.main_container}>
                    <div className={classes.heading}>Status</div>
                    <div className={classes.filter_container}>
                      <Select
                        className={classes.filter_status}
                        disableUnderline={true}
                        showHelperText={false}
                        fullWidth
                        multiple
                        items={STATUSES}
                        value={values.status}
                        classes={{
                          select: classes.filter_select_status,
                        }}
                        onChange={e => {
                          setFieldValue('status', e.target.value);
                        }}
                        MenuProps={{
                          disablePortal: true,
                        }}
                      />
                    </div>
                  </div>
                )}
                <div className={classes.footerbtn_container}>
                  <Button
                    type="button"
                    className={classes.clear_btn}
                    autoWidth
                    invert
                    onClick={() => {
                      resetForm(getInitial(type));
                      onClear();
                    }}
                  >
                    Clear
                  </Button>
                  <Button className={classes.submit_btn} autoWidth type="submit" onClick={handleSubmit}>
                    Apply
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default Filter;
