import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import { useAccount } from 'hooks';

import Banner from 'components/Banner/Banner';

import './BannerContainer.scss';

const BannerContainer = ({ closestClassForBanner, clientDeclinedSubscriptions, hasContributions }) => {
  const [bannerTypes, setBannerTypes] = useState([]);
  const { user } = useAccount();

  const getType = useCallback(() => {
    const types = [];
    if (!user.isEmailConfirmed) {
      types.push('email');
    }
    if (!isEmpty(closestClassForBanner)) {
      types.push('closestSession');
    }
    if (hasContributions && user?.isVideoTestFirstTime) {
      types.push('testVideo');
    }
    if (clientDeclinedSubscriptions && clientDeclinedSubscriptions.length > 0) {
      types.push('clientDeclinedSubscriptions');
    }
    setBannerTypes(types);
  }, [user.isEmailConfirmed, closestClassForBanner, setBannerTypes, clientDeclinedSubscriptions, hasContributions]);

  useEffect(() => {
    getType();
  }, [getType]);

  return (
    <div className="banners-client">
      {bannerTypes.map(type => (
        <Banner
          type={type}
          closestClassForBanner={closestClassForBanner}
          clientDeclinedSubscriptions={clientDeclinedSubscriptions}
        />
      ))}
    </div>
  );
};

BannerContainer.defaultProps = {
  closestClassForBanner: {},
  hasContributions: false,
};

BannerContainer.propTypes = {
  closestClassForBanner: PropTypes.shape({
    contributionId: PropTypes.string.isRequired,
    classId: PropTypes.string.isRequired,
    contributionTitle: PropTypes.string.isRequired,
    minutesLeft: PropTypes.number.isRequired,
  }),
  hasContributions: PropTypes.bool,
};

export default BannerContainer;
