import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import ChatButton from 'components/UI/ListButtons/ChatButton';
import Loader from 'components/UI/Loader';
import { getStyledButton, getPressEffectButton, formatTimeWithComma, addhttp } from 'utils/utils';
import Card from '@material-ui/core/Card';
import useRouter from 'hooks/useRouter';
import formPreviewIcon from '../../../assets/form-preview-icon.svg';
import { approveOrRejectForm, ApplicationFormModal, applicationStatus } from '../useSharedApplicationForm';
import Modal from 'components/UI/Modal';

export const ApplicationResponsive = ({ index, row, totalRows, openedIndex, setOpenedIndex }) => {
  const { contributionId, status, clientName, id, contributionName, createTime, clientId } = row;
  const url = `/contribution-view/${contributionId}/about`;
  const [confirmationModalData, setConfirmationModalData] = useState(null);
  const { domain } = useRouter();
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();

  const StyledButton = getStyledButton();
  const PressedEffectButton = getPressEffectButton();

  return (
    <>
      <Grid container className="m-2 applications-table">
        <Grid item xs={12}>
          <Card className="p-3 m-2 card-radius">
            <Grid container spacing={2}>
              <Grid item xs={8}>
                <p className="modal-text client-name mt-2">{clientName}</p>
              </Grid>
              {(status === applicationStatus.rejected || status === applicationStatus.approved) && (
                <Grid item xs={4}>
                  <span>
                    <StyledButton
                      type="button"
                      className={`btn ${status?.toLowerCase()}-button float-right`}
                      value={status}
                    >
                      {status}
                    </StyledButton>
                  </span>
                </Grid>
              )}
              <Grid item xs={12}>
                <Link to={url}>
                  <p className="modal-text link p-0 mb-0">{contributionName}</p>
                </Link>
              </Grid>
              <Grid item xl={8} lg={8} md={7} sm={7} xs={6}>
                <p className="modal-text create-time display-inline-block pt-3 ">{formatTimeWithComma(createTime)}</p>
              </Grid>
              <Grid item xl={4} lg={4} md={5} sm={5} xs={6}>
                <span className="float-right">
                  <PressedEffectButton type="button">
                    <img src={formPreviewIcon} alt="preview" onClick={() => setOpenedIndex(index)} />
                  </PressedEffectButton>
                  <ChatButton
                    handleClick={() => {
                      window.location.href = addhttp(domain.concat(`/conversations/all?chatId=${clientId}`));
                    }}
                  />
                </span>
              </Grid>
            </Grid>
            {status === applicationStatus.pending && (
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <>
                    <StyledButton
                      type="button"
                      className="btn approve-button float-right"
                      value="Approve"
                      onClick={() => {
                        setConfirmationModalData({
                          onSubmit: () => {
                            approveOrRejectForm(id, true, setLoader, dispatch);
                            setConfirmationModalData(null);
                          },
                          onCancel: () => setConfirmationModalData(null),
                          text: 'Are you sure you want to approve this application?',
                        });
                      }}
                    >
                      Approve
                    </StyledButton>
                    <StyledButton
                      type="button"
                      className="btn reject-button float-right ml-1"
                      value="Reject"
                      onClick={() => {
                        setConfirmationModalData({
                          onSubmit: () => {
                            approveOrRejectForm(id, false, setLoader, dispatch);
                            setConfirmationModalData(null);
                          },
                          onCancel: () => setConfirmationModalData(null),
                          text: 'Are you sure you want to reject this application?',
                        });
                      }}
                    >
                      Reject
                    </StyledButton>
                  </>
                </Grid>
              </Grid>
            )}
          </Card>
        </Grid>
      </Grid>
      <ApplicationFormModal
        totalRows={totalRows}
        openedIndex={openedIndex}
        setOpenedIndex={setOpenedIndex}
        key={index}
        showApplicationFormDetailPopUp={index === openedIndex}
        setShowApplicationFormDetailPopUp={() => setOpenedIndex(null)}
        row={row}
      />
      <Modal
        title="Confirm"
        isOpen={Boolean(confirmationModalData)}
        onCancel={confirmationModalData?.onCancel}
        onSubmit={confirmationModalData?.onSubmit}
        cancelTitle="Cancel"
        submitTitle="Yes"
      >
        <p>{confirmationModalData?.text}</p>
      </Modal>
      {loader && <Loader />}
    </>
  );
};
ApplicationResponsive.propTypes = {
  index: PropTypes.string.isRequired,
  totalRows: PropTypes.number.isRequired,
  openedIndex: PropTypes.string.isRequired,
  setOpenedIndex: PropTypes.func.isRequired,
  row: PropTypes.shape({
    clientId: PropTypes.string.isRequired,
    clientName: PropTypes.string.isRequired,
    contributionName: PropTypes.string.isRequired,
    createTime: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    contributionId: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  }),
};

ApplicationResponsive.defaultProps = {
  row: {
    clientName: '',
    clientId: '',
    contributionName: '',
    createTime: '',
    status: '',
    contributionId: '',
  },
};
