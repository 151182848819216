import React, { useState } from 'react';
import classes from './Table.module.scss';
import styled from 'styled-components';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import { TOOLTIP } from '../../../../../constants';
import copyToClipboard from 'utils/copyToClipboard';

const StyledCopyButton = styled.div`
  height: 30px;
  padding: 0px 10px;
  border: 1px solid #dfe3e4;
  border-radius: 4px;
  font-family: Avenir;
  font-size: 14px;
  font-weight: 800;
  line-height: 16px;
  color: #215c73;
  cursor: pointer;
`;

const StyledHostName = styled.div`
  font-family: Avenir;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  color: rgba(0, 0, 0, 0.87);
  width: 80%;
  overflow: hidden;
`;

const ColumnType = styled.td`
  font-family: Avenir;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  min-width: 50px;
  max-width: 50px;
  ${({ isFirstRow }) =>
    isFirstRow &&
    `
  border-radius: 4px 0px 0px 0px;

`}

  ${({ isLastRow }) =>
    isLastRow &&
    `
  border-radius: 0px 0px 0px 4px;

`}
`;
const ColumnHost = styled.td`
  min-width: 350px;
  max-width: 350px;
`;
const ColumnValue = styled.td`
  min-width: 500px;
  max-width: 500px;
  ${({ isFirstRow }) =>
    isFirstRow &&
    `
  border-radius: 0px 4px 0px 0px;
`}

  ${({ isLastRow }) =>
    isLastRow &&
    `
  border-radius: 0px 0px 4px 0px;
`}
`;

const StyledError = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px;
  background-color: #f1f8ff;
`;

const ROW = styled.tr``;

const CustomTable = ({ data = [], showError = false, popup = false }) => {
  const [tooltipActiveFor, setTooltipActiveFor] = useState(null);
  const [isToolTipOpen, setIsToolTipOpen] = useState(false);
  return (
    <div className={classes.table}>
      <table className={classes.table_main}>
        <thead>
          <tr className={classes.table_head_row}>
            <th>Type</th>
            <th style={popup ? { padding: '0px 40px' } : {}}>Host</th>
            <th style={popup ? { padding: '0px 40px' } : {}}>Value</th>
          </tr>
        </thead>
        <tbody>
          {data &&
            data.map((item, index) => {
              const error = item?.error;
              const firstRow = index === 0;
              const lastRow = index === data.length - 1;
              return (
                <>
                  <ROW className={classes.table_data_row}>
                    <ColumnType isFirstRow={firstRow} isLastRow={lastRow} isError={false}>
                      {item.type}
                    </ColumnType>
                    <ColumnHost>
                      <div style={{ width: '100%' }}>
                        <StyledHostName>{item.host}</StyledHostName>
                        <Tooltip
                          className="cursor-pointer"
                          title="Copied!"
                          open={isToolTipOpen && tooltipActiveFor === `${index}host`}
                          onClose={() => {
                            setIsToolTipOpen(false);
                            setTooltipActiveFor(null);
                          }}
                          arrow
                        >
                          <StyledCopyButton
                            onClick={() => {
                              copyToClipboard(item.host);
                              setIsToolTipOpen(true);
                              setTooltipActiveFor(`${index}host`);
                            }}
                          >
                            Copy
                          </StyledCopyButton>
                        </Tooltip>
                      </div>
                    </ColumnHost>
                    <ColumnValue isFirstRow={firstRow} isLastRow={lastRow}>
                      <div style={{ width: '100%' }}>
                        <Tooltip
                          className="cursor-pointer"
                          title={item.value}
                          enterTouchDelay={TOOLTIP.ENTER_DELAY}
                          leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                          arrow
                        >
                          <StyledHostName>
                            {item.value.length > 50 ? item.value.slice(0, 50) + '...' : item.value}
                          </StyledHostName>
                        </Tooltip>
                        <Tooltip
                          className="cursor-pointer"
                          title="Copied!"
                          open={isToolTipOpen && tooltipActiveFor === `${index}value`}
                          onClose={() => {
                            setIsToolTipOpen(false);
                            setTooltipActiveFor(null);
                          }}
                          arrow
                        >
                          <StyledCopyButton
                            onClick={() => {
                              copyToClipboard(item.value);
                              setIsToolTipOpen(true);
                              setTooltipActiveFor(`${index}value`);
                            }}
                          >
                            Copy
                          </StyledCopyButton>
                        </Tooltip>
                      </div>
                    </ColumnValue>
                  </ROW>
                  {!item.valid && showError && (
                    <ROW className={classes.table_data_row}>
                      <td colSpan={3} className={classes.error}>
                        <div className={classes.error_heading}>
                          <span className={classes.tooltip}>
                            <Tooltip
                              title="Following error occured"
                              arrow
                              enterTouchDelay={TOOLTIP.ENTER_DELAY}
                              leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                            >
                              <InfoIcon htmlColor="#215C73" />
                            </Tooltip>
                            We received the following error when validating the record.
                          </span>
                        </div>
                        <div className={classes.error_message}>{`Expected ${item.type} FOR "${item.host}" to match ${
                          item.value.length > 30 ? item.value.slice(0, 30) + '...' : item.value
                        }`}</div>
                      </td>
                    </ROW>
                  )}
                </>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

export default CustomTable;
