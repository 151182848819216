import React, { useState, useCallback, useEffect, useMemo } from 'react';

import Table from '@material-ui/core/Table';
import { IconGoogle } from 'components/Icons/services/IconGoogle';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import { Button as MUIBtn } from '@mui/material';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import withStyles from '@material-ui/core/styles/withStyles';
import CloseIcon from '@material-ui/icons/Close';
import Tooltip from '@material-ui/core/Tooltip';
import { TOOLTIP } from '../../../constants';
import InfoIcon from '@material-ui/icons/Info';
import { colors } from 'utils/styles';
import { Spinner } from 'react-activity';
import 'react-activity/dist/library.css';
import { connect, useSelector } from 'react-redux';
import * as calendarsActions from 'actions/calendars';
import { useHttp } from 'hooks';
import { useLocation, useHistory } from 'react-router-dom';
import { getDomainIcon } from 'utils/calendar';
import { useTheme, useMediaQuery } from '@material-ui/core';
import Loader from 'components/UI/Loader';
import SettingsIcon from '@mui/icons-material/Settings';
import Button from 'components/FormUI/Button';
import styled, { css } from 'styled-components';
import AddCalendarModal from 'components/Modals/AddCalendarModal/AddCalendarModal';
import { RemoveCalendarModal, DefaultCalendarModal } from 'components/Modals/AddCalendarModal/RemoveCalendar';
import EmailComponent from './Email';
import ExternalCalendarSettingsPopup from '../../MasterCalendarPage/components/ExternalCalendarSettingsPopup';
import { isArray } from 'lodash';

const GoogleBtn = styled(MUIBtn)`
  background-color: white;
  margin-right: 5px;
  color: gray;
  text-tranfer: none;
`;
const BoldTableCell = withStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: ({ mobileView }) => (mobileView ? '16px' : '17.8px'),
    fontWeight: 500,
    letterSpacing: '0.15px',
  },
})(TableCell);

const NarrowTableCell = withStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingTop: 15,
    paddingBottom: 15,
  },
})(TableCell);

const StyledButton = styled(Button)`
  margin-right: ${({ googleBtn }) => (googleBtn ? '5px' : '0px')};
  ${({ googleBtn }) =>
    googleBtn
      ? `
  background-color: white;
  color: gray;
  box-shadow: 0 2px 5px 1px rgb(64 60 67 / 16%);
  '&:hover': {
    background-color: white;
  } 
  `
      : ''};
`;

const StyledCloseIcon = styled(CloseIcon)`
  cursor: pointer;
`;

const CalendarInfoWrapper = styled.div`
  padding: '20px';
  display: flex;
  align-items: center;
  cursor: ${props => (!props.isDefault ? 'pointer' : 'default')};
`;

const EmptyCalendarWrapper = styled.div`
  padding: 16px;
`;
const CalendarRecordActionsColumn = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  svg {
    cursor: pointer;
  }
`;

const MUIBtnStyled = styled(MUIBtn)`
  ${({ isAccountSettingsOpen }) =>
    isAccountSettingsOpen
      ? css`
          display: inline-flex !important;
          align-items: center !important;
          justify-content: center !important;
          position: relative !important;
          box-sizing: border-box !important;
          outline: 0 !important;
          border: 0 !important;
          margin: 0 !important;
          user-select: none !important;
          vertical-align: center !important;
          text-decoration: none !important;
          font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif !important;
          line-height: 1.75 !important;
          letter-spacing: 0.02857em !important;
          min-width: 64px !important;
          padding: 6px 8px !important;
          border-radius: 4px !important;
          transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
            box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
            color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
          background: white !important;
          color: gray !important;
          text-transform: none !important;
          box-shadow: 0 2px 5px 1px rgb(64 60 67 / 16%) !important;
          margin-right: 5px !important;
          font-size: 14px !important;
          font-weight: 550 !important;
        `
      : css``}
`;

const Calendars = ({
  calendars,
  addCalendar,
  calendarSignInCallback,
  fetchCalendars,
  defaultCalendar,
  removeCalendar,
  getAcсountsForConflictsCheck,
  getAcсountForSendReminders,
  integrationPage,
  isloading,
  closeJoyRide = () => {},
}) => {
  const query = new URLSearchParams(useLocation().search);
  const code = useMemo(() => query.get('code'), [query]);
  const contributionId = useMemo(() => query.get('state'), [query]);
  const [isAddCalendarModalOpen, setIsAddCalendarModalOpen] = useState(false);
  const [removedCalendarEmail, setRemovedCalendarEmail] = useState(false);
  const [defaultCalendarEmail, setDefaultCalendarEmail] = useState(false);
  const defaultCalendars = calendars && calendars?.filter(c => c.isDefault);
  const notdefaultCalendars = calendars && calendars?.filter(c => !c.isDefault);
  const sortedCalendars = [...defaultCalendars, ...notdefaultCalendars];
  const GoogleIcon = getDomainIcon(null, 'gmail');
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const [showExternalCalendarSettingsModal, setShowExternalCalendarSettingsModal] = useState(false);
  const [
    showExternalCalendarConnectionCompleteStepsModal,
    setShowExternalCalendarConnectionCompleteStepsModal,
  ] = useState({
    showStep1: false,
    showStep2: false,
  });
  const [calendarForSettingsPopup, setCalendarForSettingsPopup] = useState(false);
  const { lastAddedEmail, loadingLastEmail } = useSelector(s => s.calendars);
  const { loading } = useHttp();
  const history = useHistory();

  useEffect(() => {
    // Note: comment below IF for mocking step-wise popups
    if (code) {
      calendarSignInCallback(code);
    }
    if (contributionId === 'create') {
      history.push({
        pathname: '/create-contribution/sessions',
        state: { calendarModalOpen: true },
      });
    } else if (contributionId) {
      history.push({
        pathname: `/edit-contribution/${contributionId}/sessions`,
        state: { calendarModalOpen: true },
      });
    }
  }, []);
  useEffect(() => {
    // Note: uncomment below code for mocking step-wise popups
    // if (code) {
    //   const recentAddedAccount = (calendars || [])?.at(0) || {};
    //   if (recentAddedAccount) {
    //     setCalendarForSettingsPopup(recentAddedAccount);
    //     setShowExternalCalendarConnectionCompleteStepsModal({
    //       ...showExternalCalendarConnectionCompleteStepsModal,
    //       showStep1: true,
    //       showStep2: false,
    //     });
    //   }
    //   return;
    // }
    if (!loadingLastEmail && lastAddedEmail) {
      // TODO: here open the popup after connecting 1st calendar to complete setup
      if (isArray(calendars) && calendars.length) {
        const recentAddedAccount = calendars.find(k => k.emailAddress === lastAddedEmail);
        if (recentAddedAccount) {
          setCalendarForSettingsPopup(recentAddedAccount);
          setShowExternalCalendarConnectionCompleteStepsModal({
            ...showExternalCalendarConnectionCompleteStepsModal,
            showStep1: true,
            showStep2: false,
          });
        }
      }
    }
  }, [
    // code,
    calendars,
    lastAddedEmail,
    loadingLastEmail,
  ]);
  useEffect(() => {
    fetchCalendars();
    getAcсountsForConflictsCheck();
    getAcсountForSendReminders();
  }, [fetchCalendars, getAcсountsForConflictsCheck, getAcсountForSendReminders]);

  useEffect(() => {
    if (
      showExternalCalendarSettingsModal ||
      showExternalCalendarConnectionCompleteStepsModal.showStep1 ||
      showExternalCalendarConnectionCompleteStepsModal.showStep2
    ) {
      closeJoyRide();
    }
  }, [showExternalCalendarSettingsModal, showExternalCalendarConnectionCompleteStepsModal]);

  const handleModalSubmit = useCallback(async () => {
    setIsAddCalendarModalOpen(false);
  }, []);

  if (loading) {
    return <Loader />;
  }

  const handleOpenAddCalendarModal = () => {
    return isArray(calendars) && calendars.length
      ? setIsAddCalendarModalOpen(true)
      : addCalendar({ contributionId: null, isCreate: true, isMasterCalendar: true });
  };

  const onLinkClick = e => {
    e.preventDefault();
    addCalendar({ contributionId, isCreate: true });
  };

  const handleCloseAddCalendarModal = () => {
    setIsAddCalendarModalOpen(false);
  };

  const onRemoveCalendarModalOpen = email => {
    setRemovedCalendarEmail(email);
  };

  const handleclick = email => {
    setDefaultCalendarEmail(email);
  };

  const onRemovecalendarSubmit = () => {
    removeCalendar(removedCalendarEmail);
    setRemovedCalendarEmail(false);
  };

  const onDefaultcalendarSubmit = () => {
    defaultCalendar(defaultCalendarEmail);
    setDefaultCalendarEmail(null);

    // setTimeout(() => {
    //   fetchCalendars();
    // }, 1000);
  };

  const handleCloseRemoveCalendarModal = () => {
    setRemovedCalendarEmail(false);
  };

  const handleCloseDefaultCalendarModal = () => {
    setDefaultCalendarEmail(null);
  };

  return (
    <>
      <TableContainer id="integration1" component={Paper} style={{ boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px' }}>
        <Table className="" aria-label="contributions table">
          <TableHead>
            <TableRow>
              <BoldTableCell mobileView={mobileView}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <div>Calendar Connections</div>
                  <div style={{ marginBottom: '8px' }}>
                    <Tooltip
                      title="The default calendar you choose is the email that your cal invites will come from. Further, you can prevent double booking by connecting your calendars if you provide 1:1 services."
                      arrow
                      className="ml-2 mt-2"
                      enterTouchDelay={TOOLTIP.ENTER_DELAY}
                      leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                    >
                      <InfoIcon htmlColor={colors.lightBrown} />
                    </Tooltip>
                  </div>
                </div>
                <div style={{ display: 'flex' }}>
                  <MUIBtnStyled
                    startIcon={<GoogleIcon />}
                    sx={{
                      backgroundColor: 'white',
                      color: 'gray',
                      textTransform: 'none',
                      boxShadow: '0 2px 5px 1px rgb(64 60 67 / 16%)',
                      marginRight: '5px',
                      fontSize: '14px',
                      fontWeight: '550',
                    }}
                    isAccountSettingsOpen={
                      showExternalCalendarSettingsModal ||
                      showExternalCalendarConnectionCompleteStepsModal.showStep1 ||
                      showExternalCalendarConnectionCompleteStepsModal.showStep2
                    }
                    onClick={onLinkClick}
                  >
                    {' '}
                    Connect with Google
                  </MUIBtnStyled>
                  <StyledButton mobileView={mobileView} autoWidth onClick={handleOpenAddCalendarModal}>
                    Add My Calendar
                  </StyledButton>
                </div>
              </BoldTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedCalendars &&
              sortedCalendars.map((calendar, i) => {
                const {
                  provider,
                  emailAddress,
                  isCheckConflictsEnabled,
                  isEventRemindersEnabled,
                  isDefault,
                  isDefaultForGroupCourseInvites,
                } = calendar;
                const key = `${i}${emailAddress}`;
                const Icon = getDomainIcon(null, provider);
                return (
                  // <EmailComponent
                  //   isloading={isloading}
                  //   Icon={Icon}
                  //   key={`${i}${emailAddress}`}
                  //   provider={provider}
                  //   emailAddress={emailAddress}
                  //   isCheckConflictsEnabled={isCheckConflictsEnabled}
                  //   isEventRemindersEnabled={isEventRemindersEnabled}
                  //   isDefault={isDefault}
                  //   handleclick={() => {
                  //     if (!isDefault) {
                  //       handleclick(emailAddress);
                  //     }
                  //   }}
                  //   onRemove={() => onRemoveCalendarModalOpen(emailAddress)}
                  // />
                  <TableRow key={key} className="row">
                    <NarrowTableCell component="th" scope="row" style={{ minHeight: 78.17 }}>
                      <CalendarInfoWrapper
                        onClick={() => {
                          if (!isDefault) {
                            handleclick(emailAddress);
                          }
                        }}
                        isDefault={isDefault}
                      >
                        <Icon />
                        <span
                          className="ml-2"
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '10px',
                          }}
                        >
                          {emailAddress}
                          {(isDefault || isDefaultForGroupCourseInvites) && (
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                backgroundColor: '#116582',
                                width: 'fit-content',
                                padding: '2px 6px',
                                borderRadius: '5px',
                              }}
                            >
                              <p style={{ marginBottom: '0px', fontSize: '12px', color: 'white' }}>
                                Default for&nbsp;
                                {[isDefault && '1:1', isDefaultForGroupCourseInvites && 'Group']
                                  .filter(k => !!k)
                                  .join(', ')}
                                &nbsp;services
                              </p>
                            </div>
                          )}
                        </span>
                      </CalendarInfoWrapper>
                      <CalendarRecordActionsColumn>
                        {isloading === false ? (
                          <>
                            <SettingsIcon
                              onClick={() => {
                                closeJoyRide();
                                setCalendarForSettingsPopup(calendar);
                                setShowExternalCalendarSettingsModal(true);
                              }}
                            />
                            <StyledCloseIcon onClick={() => onRemoveCalendarModalOpen(emailAddress)} />
                          </>
                        ) : (
                          <Spinner color="#116582" size={20} />
                        )}
                      </CalendarRecordActionsColumn>
                    </NarrowTableCell>
                  </TableRow>
                );
              })}
            {(!calendars || !calendars.length) && (
              <EmptyCalendarWrapper>There are no calendars yet.</EmptyCalendarWrapper>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {isAddCalendarModalOpen && (
        <AddCalendarModal
          isOpen
          onModalClose={handleCloseAddCalendarModal}
          onSubmit={handleModalSubmit}
          addCalendar={addCalendar}
          calendars={calendars}
          showsetInviteCalendarRow="false"
          integrationPage={integrationPage}
          setCalendarForSettingsPopup={setCalendarForSettingsPopup}
          setShowExternalCalendarSettingsModal={setShowExternalCalendarSettingsModal}
          closeJoyRide={closeJoyRide}
        />
      )}
      {(showExternalCalendarSettingsModal ||
        showExternalCalendarConnectionCompleteStepsModal.showStep1 ||
        showExternalCalendarConnectionCompleteStepsModal.showStep2) && (
        <ExternalCalendarSettingsPopup
          isModalOpen={
            showExternalCalendarSettingsModal ||
            showExternalCalendarConnectionCompleteStepsModal.showStep1 ||
            showExternalCalendarConnectionCompleteStepsModal.showStep2
          }
          // connectedCalendars={calendars}
          connectedCalendar={calendarForSettingsPopup}
          onCloseModal={() => {
            setCalendarForSettingsPopup(null);
            setShowExternalCalendarSettingsModal(false);
            setShowExternalCalendarConnectionCompleteStepsModal({
              ...showExternalCalendarConnectionCompleteStepsModal,
              showStep1: false,
              showStep2: false,
            });
          }}
          closeJoyRide={closeJoyRide}
          setShowExternalCalendarConnectionCompleteStepsModal={setShowExternalCalendarConnectionCompleteStepsModal}
          showExternalCalendarConnectionCompleteStepsModal={showExternalCalendarConnectionCompleteStepsModal}
          // onConfirm={onRescheduleSlotSelection}
        />
      )}
      {defaultCalendarEmail && (
        <DefaultCalendarModal
          isOpen={defaultCalendarEmail}
          onCancel={handleCloseDefaultCalendarModal}
          onSubmit={onDefaultcalendarSubmit}
        />
      )}

      {removedCalendarEmail && (
        <RemoveCalendarModal
          isOpen={removedCalendarEmail}
          onCancel={handleCloseRemoveCalendarModal}
          onSubmit={onRemovecalendarSubmit}
          closeJoyRide={closeJoyRide}
          removedCalendarEmail={removedCalendarEmail}
        />
      )}
    </>
  );
};

Calendars.propTypes = {};

const mapStateToProps = ({ calendars }) => ({
  calendars: calendars.calendarsAccounts,
  isloading: calendars.loading,
});

const actions = {
  fetchCalendars: calendarsActions.fetchCalendars,
  defaultCalendar: calendarsActions.defaultCalendar,
  addCalendar: calendarsActions.addCalendar,
  removeCalendar: calendarsActions.removeCalendar,
  calendarSignInCallback: calendarsActions.calendarSignInCallback,
  getAcсountsForConflictsCheck: calendarsActions.getAcсountsForConflictsCheck,
  getAcсountForSendReminders: calendarsActions.getAcсountForSendReminders,
};

export default connect(mapStateToProps, actions)(Calendars);
