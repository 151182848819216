import React, { useRef, useState } from 'react';
import classes from './AddContactTagModal.module.scss';
import * as Yup from 'yup';
import { Formik } from 'formik';
import TextField from 'components/UI/TextField';
import Select from 'components/UI/Select';
import { CrossOutlineIcon } from 'pages/CreateLeadMagnet/components/Icon';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'components/UI/Modal';
import { ContactsServices } from 'pages/Contacts/Services/Contacts.service';
import { fetchAllContactsAsync, setContacts } from 'actions/contacts';
import styled from 'styled-components';
import Loader from 'components/UI/Loader';
import CrossableTag from 'pages/Contacts/Components/CrossableTag/CrossableTag';

const StyledError = styled.div`
  color: red;
`;

const AddContactTagModal = ({ contacts, contactsList, isOpen, onClose }) => {
  const formikRef = useRef(null);
  const dispatch = useDispatch();
  const [isRemovingtag, setIsRemovingtag] = useState(false);
  const alreadySelectedTags =
    contacts?.length > 1 ? [] : contacts[0]?.tags?.length > 0 ? contacts[0]?.tags?.map(t => t.id) : [];

  const [isSubmitting, setIsSubmitting] = useState(false);
  const {
    tags: { tags },
  } = useSelector(state => state?.contacts);

  const TAGS_LIST = [
    ...tags?.map(tag => ({
      title: tag.name,
      value: tag.id,
      disabled: alreadySelectedTags?.includes(tag.id) ? true : false,
    })),
  ];

  return (
    <>
      <Formik
        innerRef={formikRef}
        enableReinitialize
        initialValues={{
          tags: contacts?.length > 1 ? [] : contacts[0]?.tags?.length > 0 ? contacts[0]?.tags?.map(t => t.id) : [],
          contacts: contacts?.map(c => c.id),
        }}
        validationSchema={Yup.object().shape({
          tags: Yup.array().required('This is required'),
          contacts: Yup.array().required('This is required'),
        })}
        onSubmit={values => {
          setIsSubmitting(true);
          const data = { tagIds: values?.tags, contactIds: values?.contacts };
          ContactsServices.assignTagToUser(data)
            .then(res => {
              setIsSubmitting(false);
              dispatch(fetchAllContactsAsync({}));
              onClose();
            })
            .catch(err => {
              setIsSubmitting(false);
            });
        }}
      >
        {({ values, setFieldValue, handleBlur, handleChange, handleSubmit, errors, touched }) => {
          return (
            <Modal
              title="Add Tag to Selected Contact(s)"
              isOpen={isOpen}
              onCancel={() => {
                onClose();
                dispatch(fetchAllContactsAsync({}));
              }}
              onSubmit={() => {
                handleSubmit();
              }}
              submitTitle="Add"
              reduceCancelButton
              widthRequired
              disableSubmitClick={isSubmitting}
            >
              <div className={classes.container}>
                {(isSubmitting || isRemovingtag) && <Loader />}
                <div className={classes.main_container}>
                  <div className={classes.heading}>Tag Name</div>
                  <div className={classes.tag_list}>
                    {values?.tags?.length > 0 &&
                      values.tags?.map((tagId, index) => {
                        const tagDetail = tags?.find(t => t.id === tagId);

                        return (
                          <CrossableTag
                            key={tagId}
                            tagName={tagDetail?.name}
                            onCross={() => {
                              if (alreadySelectedTags?.includes(tagId)) {
                                setIsRemovingtag(true);
                                const data = {
                                  tagId: tagId,
                                  contactId: contacts[0]?.id,
                                };
                                ContactsServices.removeTagFromUser(data)
                                  .then(res => {
                                    setIsRemovingtag(false);

                                    const payload = {
                                      ...contacts[0],
                                      tags: contacts[0]?.tags?.filter(t => t.id != tagId),
                                    };
                                    dispatch(setContacts(payload));
                                  })
                                  .catch(() => {
                                    setIsRemovingtag(false);
                                  });
                              } else {
                                const newTags = values?.tags?.filter(tId => tId != tagId);
                                setFieldValue('tags', newTags);
                              }
                            }}
                          />
                        );
                      })}
                    {values?.tagName && (
                      <div className={classes.tag_container}>
                        <div className={classes.tag}>{tags?.find(t => t.id === values?.tagName).name}</div>
                      </div>
                    )}
                  </div>
                  <Select
                    fullWidth
                    value={values.tags}
                    onChange={e => {
                      setFieldValue('tags', e.target.value);
                    }}
                    items={TAGS_LIST}
                    multiple
                  />
                  {errors?.tags && <StyledError>{errors?.tags}</StyledError>}
                </div>

                <div className={classes.main_container}>
                  <div className={classes.heading}>Add Contacts</div>
                  <Select
                    fullWidth
                    value={values.contacts}
                    onChange={e => {
                      setFieldValue('contacts', e.target.value);
                    }}
                    multiple
                    items={contactsList}
                  />
                  {errors?.contacts && <StyledError>{errors?.contacts}</StyledError>}
                </div>
              </div>
            </Modal>
          );
        }}
      </Formik>
    </>
  );
};

export default AddContactTagModal;
