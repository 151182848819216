import React from 'react';
import Input from 'components/FormUI/Input';
import styled from 'styled-components';
import { Formik } from 'formik';
import { makeStyles } from '@material-ui/core';
import { TextField } from '@material-ui/core';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import HelpSection from './HelpSection';
import SECTIONS from './sections';

const Messsage = styled.div`
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
  margin-top: 20px;
`;

const Content = styled.div`
  height: 300px;
  overflow: scroll;
`;

const useStyles = makeStyles(theme => ({
  inputField: {
    backgroundColor: 'rgba(178, 202, 224, 0.21)',
    borderRadius: '5px',
    width: '100%',
    // border: '1px solid #f5f0f0',
    marginTop: '5px',
    marginBottom: '10px',
    '& .MuiOutlinedInput-input': {
      padding: '10px 14px',
    },
  },

  noBorder: {
    border: 'none',
  },
}));
const HelpView = () => {
  const ClassNames = useStyles();
  const filteredSections = (search, data) => {
    if (search === '') {
      return data;
    } else {
      const filteredData = data?.filter(item => item?.name?.toLowerCase()?.includes(search?.toLowerCase()));
      return filteredData;
    }
  };

  return (
    <>
      <Formik
        initialValues={{
          search: '',
        }}
      >
        {formProps => (
          <>
            <Messsage>How to video library</Messsage>
            <TextField
              fullWidth
              className={ClassNames?.inputField}
              name="search"
              placeholder="Search"
              variant="outlined"
              type="text"
              onChange={formProps?.handleChange}
              value={formProps?.values?.search}
              InputProps={{
                classes: { notchedOutline: ClassNames?.noBorder },
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
            <Content>
              {filteredSections(formProps?.values?.search, SECTIONS)?.map(s => {
                const { name, desc, link } = s;
                return <HelpSection secName={name} secDesc={desc} link={link} />;
              })}
            </Content>
          </>
        )}
      </Formik>
    </>
  );
};

export default HelpView;
