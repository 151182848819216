import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Input, IconButton, Typography } from '@material-ui/core';
import { Clear } from '@material-ui/icons';
import EditIcon from '@material-ui/icons/Edit';

import Modal from 'components/UI/Modal';

import { useLocalState } from './hooks/useLocalState';
import { useHandlers } from './hooks/useHandlers';
import { determineColorToUse, determineDarkThemedColorToUse } from 'services/contributions.service';
import useContribution from 'pages/ContributionView/hooks/useContribution';

export const MemberPodItem = props => {
  const { clientIds, initialValue, onDelete, onUpdate, podId } = props;
  const [state, setState] = useLocalState({ initialValue });
  const { isEditPodName, isOpenModalDelete, isOpenModalUpdate, value } = state;
  const contribution = useContribution();
  const handlers = useHandlers({ clientIds, setState, state, onDelete, onUpdate, podId });
  let colorToUse = determineColorToUse(contribution);
  const isDarkThemeEnabled = determineDarkThemedColorToUse(contribution);

  return (
    <>
      <Grid container>
        <Input disabled={!isEditPodName} onChange={handlers.onChangePodName} type="text" value={value} />
        <IconButton color={isEditPodName ? 'primary' : 'default'} onClick={handlers.onEditPodName}>
          <EditIcon style={{ color: isDarkThemeEnabled ? 'white' : 'black' }} />
        </IconButton>
        <IconButton style={{ color: isDarkThemeEnabled ? 'white' : 'black' }} onClick={handlers.onOpenDeleteModal}>
          <Clear />
        </IconButton>
        <Modal
          isOpen={isOpenModalDelete}
          onCancel={handlers.onCancelDelete}
          onSubmit={handlers.onConfirmDelete}
          submitTitle="Delete"
          title="Delete Pod?"
        >
          <Typography style={{ color: isDarkThemeEnabled ? 'white' : 'black' }} align="center" variant="h6">
            {value}
          </Typography>
        </Modal>
        <Modal
          isOpen={isOpenModalUpdate}
          onCancel={handlers.onCancelUpdate}
          onSubmit={handlers.onConfirmUpdate}
          submitTitle="Update"
          title="Update Group Name?"
        >
          <Typography style={{ color: isDarkThemeEnabled ? 'white' : 'black' }} paragraph>
            Current name: {initialValue}
          </Typography>
          <Typography style={{ color: isDarkThemeEnabled ? 'white' : 'black' }}>New name: {value.trim()}</Typography>
        </Modal>
      </Grid>
    </>
  );
};

MemberPodItem.defaultProps = {
  initialValue: '',
};

MemberPodItem.propTypes = {
  clientIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  initialValue: PropTypes.string,
  onDelete: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  podId: PropTypes.string.isRequired,
};
