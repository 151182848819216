import React from 'react';

const EnrollmentSvg = ({ width, height, color }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_9072_79038)">
        <path
          d="M10.4952 9.88796e-06C14.4406 0.00648152 17.6402 3.18405 17.6337 7.09292C17.6271 10.9953 14.4079 14.1794 10.4755 14.1729C6.53661 14.1599 3.3305 10.9694 3.33705 7.0735C3.34359 3.16464 6.55624 -0.00646174 10.4952 9.88796e-06Z"
          fill={color}
        />
        <path
          d="M16.5148 13.817C12.8114 16.0238 12.8573 21.1688 16.5933 23.2785C16.5017 23.285 16.4494 23.2979 16.3905 23.2979C11.8562 23.2979 7.31527 23.3044 2.78091 23.2979C1.17132 23.2979 -0.0129788 22.1007 0.00010739 20.5087C0.0262797 17.1175 2.47339 14.1341 5.82344 13.4222C5.92813 13.4028 6.06554 13.4287 6.15714 13.4805C9.03609 15.2278 11.9085 15.2343 14.7875 13.4934C14.8921 13.4287 15.0492 13.3963 15.167 13.4287C15.6053 13.5387 16.0241 13.6746 16.5148 13.817Z"
          fill={color}
        />
        <path
          d="M19.0534 13.7588C21.566 13.6228 23.8495 15.7002 23.9935 18.2501C24.144 20.8905 22.1025 23.1556 19.4526 23.2979C16.7634 23.4468 14.4864 21.4017 14.3817 18.7031C14.2705 16.0562 16.3577 13.8429 19.0534 13.7588ZM20.3228 19.6609C20.879 19.6609 21.3893 19.6674 21.9062 19.6609C22.5802 19.6544 23.0709 19.182 23.084 18.5478C23.0905 17.9006 22.6063 17.4152 21.9193 17.4023C21.4809 17.3958 21.036 17.4023 20.5976 17.4023C20.5125 17.4023 20.4275 17.3958 20.3228 17.3893C20.3228 16.8457 20.3293 16.328 20.3228 15.8167C20.3163 15.1696 19.8321 14.6777 19.2105 14.6648C18.5823 14.6518 18.0851 15.1178 18.0524 15.765C18.0393 15.9979 18.0458 16.2309 18.0458 16.4704C18.0458 16.7745 18.0458 17.0787 18.0458 17.4088C17.8953 17.4088 17.7841 17.4088 17.6729 17.4088C17.2018 17.4152 16.7241 17.3893 16.253 17.4346C15.5791 17.4994 15.1472 18.1724 15.3239 18.8196C15.4613 19.3244 15.8931 19.6609 16.4428 19.6674C16.9727 19.6738 17.4962 19.6674 18.0458 19.6674C18.0458 19.7838 18.0458 19.8744 18.0458 19.9586C18.0458 20.3857 18.0393 20.8128 18.0458 21.2464C18.0524 21.7577 18.2879 22.133 18.7655 22.3272C19.4984 22.6249 20.2966 22.0942 20.3163 21.2917C20.3359 20.7481 20.3228 20.2174 20.3228 19.6609Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_9072_79038">
          <rect width="24" height="23.3043" fill={color} />
        </clipPath>
      </defs>
    </svg>
  );
};

export default EnrollmentSvg;
