import React from 'react';
import PropTypes from 'prop-types';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import { Chat } from 'components/UI/chats';

import './Conversation.scss';

const Conversation = ({ conversationId, onShowConversations }) => (
  <div className="conversation">
    <div
      className="conversation__back-to-conversations conversation-back-to-conversations"
      onClick={onShowConversations}
    >
      <ArrowBackIosIcon fontSize="small" />
      <p className="conversation-back-to-conversations__title">Conversations</p>
    </div>
    <Chat channelId={conversationId} />
  </div>
);

Conversation.propTypes = {
  conversationId: PropTypes.string.isRequired,
  onShowConversations: PropTypes.func.isRequired,
};

export default Conversation;
