import React, { useEffect } from 'react';
import classes from './Notification.module.scss';
import CrossIconSvg from './CrossIconSvg';
import CheckIconSvg from './CheckIconSvg';
import { useDispatch } from 'react-redux';
import { setCampaignNotification } from 'actions/campaign';
import { isEmpty } from 'lodash';
import ExclamationIconSvg from './ExclamationIconSvg';
import InfoIcon from '@material-ui/icons/Info';

const Notification = ({ notification, sidebar = false, component = false }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    setTimeout(() => {
      dispatch(setCampaignNotification({}));
    }, 5000);
  }, []);

  return (
    <div className={component ? classes.maincomponent : sidebar ? classes.mainSidebar : classes.main}>
      <div className={classes.header}>
        <div className={classes.heading_container}>
          {notification?.heading.includes('Unable to Reschedule') ? (
            <InfoIcon style={{ transform: 'rotate(180deg)' }} htmlColor={'#215c73'} />
          ) : (
            <div className={classes.check_box}>
              <CheckIconSvg />
            </div>
          )}
          <div className={classes.heading}>{notification?.heading}</div>
        </div>
        <div
          className={classes.cross_btn}
          onClick={() => {
            dispatch(setCampaignNotification(''));
          }}
        >
          {!component && <CrossIconSvg />}
        </div>
      </div>
      <div className={classes.message}>{notification?.message}</div>
    </div>
  );
};

export default Notification;
