import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import useRouter from 'hooks/useRouter';
import { addhttp } from 'utils/utils';
import { getProfileLinkNameByConributionId } from 'services/profileLink.service';
import { getClientContribution, determineDarkThemedColorToUse } from 'services/contributions.service';
import { fetchContributionActions } from 'actions/contributions';
import { useDispatch } from 'react-redux';
import Loader from 'components/UI/Loader';
import ApplicationFormHeader from './components/ApplicationFormHeader';
import './ApplicationFormPage.scss';
import ApplicationFormBody from './components/ApplicationFormBody';

const ApplicationFormPage = ({
  match: {
    params: { id: contributionId },
  },
}) => {
  const [loadingDomainName, setLoadingDomainName] = useState(true);
  const [loadingContribution, setLoadingContribution] = useState(true);
  const { subdomain, parentDomain, pathname, query } = useRouter();
  const [contribution, setContribution] = useState(null);
  const dispatch = useDispatch();
  const isDarkThemeEnabled = determineDarkThemedColorToUse(contribution);

  useEffect(() => {
    getClientContribution(contributionId).then(data => {
      setContribution(data);
      dispatch(fetchContributionActions.success(data));
      setLoadingContribution(false);
    });
    if (subdomain) {
      setLoadingDomainName(false);
      return;
    }
    getProfileLinkNameByConributionId(contributionId)
      .then(res => {
        if (res.data) {
          window.location.replace(addhttp(`${res.data}.${parentDomain}${pathname}`));
        } else {
          setLoadingDomainName(false);
        }
      })
      .catch(() => {
        setLoadingDomainName(false);
      });
  }, [contributionId]);

  return (
    <>
      {loadingDomainName || loadingContribution ? (
        <Loader />
      ) : (
        <div className={`application-form-container ${isDarkThemeEnabled && 'application-form-container-dark'}`}>
          <ApplicationFormHeader title={contribution?.title || ''} />
          <ApplicationFormBody isDarkThemeEnabled={isDarkThemeEnabled} contribution={contribution} />
        </div>
      )}
    </>
  );
};

ApplicationFormPage.propTypes = {
  match: PropTypes.shape({
    params: { id: PropTypes.string },
  }).isRequired,
};

export default ApplicationFormPage;
