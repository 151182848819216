import React from 'react';
import Carousel from 'react-material-ui-carousel';
import classes from './Slider.module.scss';
import styled from 'styled-components';

const StyledImage = styled.img`
  width: 100%;
  height: auto;
`;

const Silder = ({ items = [], ...rest }) => {
  return (
    <div className={classes.container}>
      <Carousel {...rest}>
        {items?.map(item => (
          <StyledImage src={item?.src} />
        ))}
      </Carousel>
    </div>
  );
};

export default Silder;
