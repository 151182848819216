import React from 'react';
import SvgHandler from 'pages/ContributionView/components/ContributionHeader/ApplicationForm/SvgHandler';

export const ExpansionIcon = ({ fillColor = '#213649' }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path d="M13.333 5.3335L7.99967 10.6668L2.66634 5.3335" stroke={fillColor} strokeWidth="2" strokeLinecap="round" />
  </svg>
);
export const MediaIconLink = ({ fillColor = '#B2CAE0' }) => (
  <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.5999 12H4.3999C3.29324 12 2.35004 11.6099 1.5703 10.8296C0.790569 10.0493 0.400436 9.10613 0.399902 8C0.399902 6.89333 0.790036 5.95013 1.5703 5.1704C2.35057 4.39067 3.29377 4.00053 4.3999 4H7.5999V5.6H4.3999C3.73324 5.6 3.16657 5.83333 2.6999 6.3C2.23324 6.76667 1.9999 7.33333 1.9999 8C1.9999 8.66667 2.23324 9.23333 2.6999 9.7C3.16657 10.1667 3.73324 10.4 4.3999 10.4H7.5999V12ZM5.1999 8.8V7.2H11.5999V8.8H5.1999ZM9.1999 12V10.4H12.3999C13.0666 10.4 13.6332 10.1667 14.0999 9.7C14.5666 9.23333 14.7999 8.66667 14.7999 8C14.7999 7.33333 14.5666 6.76667 14.0999 6.3C13.6332 5.83333 13.0666 5.6 12.3999 5.6H9.1999V4H12.3999C13.5066 4 14.45 4.39013 15.2303 5.1704C16.0106 5.95067 16.4004 6.89387 16.3999 8C16.3999 9.10667 16.0098 10.0501 15.2295 10.8304C14.4492 11.6107 13.506 12.0005 12.3999 12H9.1999Z"
      fill={fillColor}
    />
  </svg>
);
export const MediaIconVideo = ({ fillColor = '#B2CAE0' }) => (
  <svg
    className="svg-icon"
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="currentColor"
  >
    <g clipPath="url(#clip0_825_479)">
      <path
        d="M0 1.18296C0 0.695849 0.395556 0.300293 0.882667 0.300293H15.1173C15.6044 0.300293 16 0.695849 16 1.18296V15.4176C15.9998 15.6517 15.9067 15.876 15.7412 16.0415C15.5757 16.207 15.3514 16.3001 15.1173 16.3003H0.882667C0.648569 16.3003 0.424059 16.2073 0.258527 16.0418C0.092995 15.8762 0 15.6517 0 15.4176V1.18296ZM1.77778 2.07807V14.5225H14.2222V2.07807H1.77778ZM6.77511 5.11363L11.112 8.00429C11.1608 8.03675 11.2008 8.08075 11.2284 8.13239C11.2561 8.18403 11.2706 8.24171 11.2706 8.30029C11.2706 8.35888 11.2561 8.41655 11.2284 8.46819C11.2008 8.51983 11.1608 8.56384 11.112 8.59629L6.77422 11.487C6.72074 11.5224 6.65865 11.5427 6.59455 11.5458C6.53045 11.5488 6.46673 11.5344 6.41014 11.5042C6.35355 11.4739 6.3062 11.4289 6.27312 11.3739C6.24003 11.3189 6.22245 11.256 6.22222 11.1918V5.40874C6.22234 5.34443 6.2399 5.28135 6.27303 5.22623C6.30616 5.17111 6.35362 5.12601 6.41035 5.09573C6.46709 5.06544 6.53097 5.05111 6.59521 5.05427C6.65944 5.05742 6.72161 5.07793 6.77511 5.11363Z"
        fill={fillColor}
      />
    </g>
    <defs>
      <clipPath id="clip0_825_479">
        <rect width="16" height="16" fill="white" transform="translate(0 0.300293)" />
      </clipPath>
    </defs>
  </svg>
);
export const MediaIconImage = ({ fillColor = '#B2CAE0' }) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.2222 1.77778V14.2222H1.77778V1.77778H14.2222ZM14.2222 0H1.77778C0.8 0 0 0.8 0 1.77778V14.2222C0 15.2 0.8 16 1.77778 16H14.2222C15.2 16 16 15.2 16 14.2222V1.77778C16 0.8 15.2 0 14.2222 0ZM9.90222 7.87556L7.23556 11.3156L5.33333 9.01333L2.66667 12.4444H13.3333L9.90222 7.87556Z"
      fill={fillColor}
    />
  </svg>
);
export const MediaIconDocument = ({ fillColor = '#B2CAE0' }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="19" viewBox="0 0 14 19" fill="currentColor">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.8125 7.05029V16.6128H1.6875V1.98779H6.75V5.08154C6.75 6.16829 7.632 7.05029 8.71875 7.05029H11.8125ZM11.6764 5.36279L8.4375 2.12392V5.08154C8.4375 5.23679 8.5635 5.36279 8.71875 5.36279H11.6764ZM0 1.42529C0 1.12692 0.118526 0.840776 0.329505 0.629798C0.540483 0.418819 0.826631 0.300293 1.125 0.300293L8.53425 0.300293C8.83259 0.300357 9.1187 0.418925 9.32963 0.629918L13.1704 4.47067C13.3814 4.6816 13.4999 4.9677 13.5 5.26604V17.1753C13.5 17.4737 13.3815 17.7598 13.1705 17.9708C12.9595 18.1818 12.6734 18.3003 12.375 18.3003H1.125C0.826631 18.3003 0.540483 18.1818 0.329505 17.9708C0.118526 17.7598 0 17.4737 0 17.1753V1.42529ZM4.21875 9.30029C3.99497 9.30029 3.78036 9.38919 3.62213 9.54742C3.46389 9.70566 3.375 9.92027 3.375 10.144C3.375 10.3678 3.46389 10.5824 3.62213 10.7407C3.78036 10.8989 3.99497 10.9878 4.21875 10.9878H9.28125C9.50503 10.9878 9.71964 10.8989 9.87787 10.7407C10.0361 10.5824 10.125 10.3678 10.125 10.144C10.125 9.92027 10.0361 9.70566 9.87787 9.54742C9.71964 9.38919 9.50503 9.30029 9.28125 9.30029H4.21875ZM3.375 12.9565C3.375 12.7328 3.46389 12.5182 3.62213 12.3599C3.78036 12.2017 3.99497 12.1128 4.21875 12.1128H7.03125C7.25503 12.1128 7.46964 12.2017 7.62787 12.3599C7.7861 12.5182 7.875 12.7328 7.875 12.9565C7.875 13.1803 7.7861 13.3949 7.62787 13.5532C7.46964 13.7114 7.25503 13.8003 7.03125 13.8003H4.21875C3.99497 13.8003 3.78036 13.7114 3.62213 13.5532C3.46389 13.3949 3.375 13.1803 3.375 12.9565Z"
      fill={fillColor}
    />
  </svg>
);
export const MediaIconMusic = ({ fillColor = '#B2CAE0' }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="19" viewBox="0 0 15 19" fill="currentColor">
    <path
      d="M9 0.300293H1.8C0.81 0.300293 0 1.11029 0 2.10029V16.5003C0 17.4903 0.81 18.3003 1.8 18.3003H12.6C13.59 18.3003 14.4 17.4903 14.4 16.5003V5.70029L9 0.300293ZM1.8 16.5003V2.10029H8.1V6.60029H12.6V16.5003H1.8ZM10.8 8.40029H7.2V11.8923C6.89535 11.6876 6.54075 11.5697 6.17421 11.5512C5.80767 11.5328 5.44301 11.6144 5.1193 11.7873C4.7956 11.9602 4.52506 12.218 4.33669 12.533C4.14832 12.848 4.04922 13.2083 4.05 13.5753C4.05 14.6913 4.959 15.6003 6.075 15.6003C7.191 15.6003 8.1 14.6913 8.1 13.5753V10.2003H10.8V8.40029Z"
      fill={fillColor}
    />
  </svg>
);
export const MediaIconQuiz = ({ fillColor = '#B2CAE0' }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="15" viewBox="0 0 18 15" fill="currentColor">
    <path
      d="M14.3835 5.02031C13.6028 5.80371 12.3346 5.80483 11.5526 5.02282L10.4405 3.9108C10.0932 3.5635 10.0932 3.00042 10.4405 2.65312C10.7878 2.30582 11.3509 2.30582 11.6982 2.65312L12.9604 3.91526L16.1129 0.762698C16.4605 0.415092 17.0241 0.415091 17.3717 0.762697C17.7189 1.10987 17.7194 1.67259 17.3728 2.02038L14.3835 5.02031ZM8.02775 3.76363C8.02775 3.27101 7.6284 2.87166 7.13578 2.87166H0.891973C0.39935 2.87166 0 3.27101 0 3.76363C0 4.25625 0.39935 4.6556 0.891973 4.6556H7.13578C7.6284 4.6556 8.02775 4.25625 8.02775 3.76363ZM16.3186 9.21801C16.6659 8.87071 16.6659 8.30763 16.3186 7.96033C15.9713 7.61303 15.4082 7.61303 15.061 7.96033L13.3796 9.6417L11.6982 7.96033C11.3509 7.61303 10.7878 7.61303 10.4405 7.96033C10.0932 8.30763 10.0932 8.87071 10.4405 9.21801L12.1219 10.8994L10.4405 12.5807C10.0932 12.928 10.0932 13.4911 10.4405 13.8384C10.7878 14.1857 11.3509 14.1857 11.6982 13.8384L13.3796 12.1571L15.061 13.8384C15.4082 14.1857 15.9713 14.1857 16.3186 13.8384C16.6659 13.4911 16.6659 12.928 16.3186 12.5807L14.6373 10.8994L16.3186 9.21801ZM8.02775 10.8994C8.02775 10.4068 7.6284 10.0074 7.13578 10.0074H0.891973C0.39935 10.0074 0 10.4068 0 10.8994C0 11.392 0.39935 11.7914 0.891973 11.7914H7.13578C7.6284 11.7914 8.02775 11.392 8.02775 10.8994Z"
      fill={fillColor}
    />
  </svg>
);
export const MediaIconMovie = ({ fillColor = '#B2CAE0' }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="13" viewBox="0 0 18 13" fill="currentColor">
    <path
      d="M12 2.5V10.5H2V2.5H12ZM13 0.5H1C0.734784 0.5 0.48043 0.605357 0.292893 0.792893C0.105357 0.98043 0 1.23478 0 1.5V11.5C0 11.7652 0.105357 12.0196 0.292893 12.2071C0.48043 12.3946 0.734784 12.5 1 12.5H13C13.2652 12.5 13.5196 12.3946 13.7071 12.2071C13.8946 12.0196 14 11.7652 14 11.5V8L18 12V1L14 5V1.5C14 1.23478 13.8946 0.98043 13.7071 0.792893C13.5196 0.605357 13.2652 0.5 13 0.5Z"
      fill={fillColor}
    />
  </svg>
);

export const CancelPresentationIcon = ({ fillColor }) => {
  return (
    <SvgHandler
      fill={fillColor}
      height="24px"
      width="24px"
      viewBox="0 0 24 24"
      style={{ margin: '' }}
      className="top-15 cursor-pointer MuiSvgIcon-root"
      pathD="M21 19.1H3V5H21V19.1ZM21 3H3C1.9 3 1 3.9 1 5V19C1 20.1 1.9 21 3 21H21C22.1 21 23 20.1 23 19V5C23 3.9 22.1 3 21 3Z M8.10547 9.98877L15.8946 9.98877L12 5.09275L8.10547 9.98877Z M15.8945 14.0928L8.10541 14.0928L12 18.9888L15.8945 14.0928Z"
    />
  );
};

export const AttachmentIcon = ({ fillColor = 'black' }) => {
  return (
    <svg
      style={{ flexShrink: '0' }}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M11.4 23.6998C10.0474 23.6998 8.75018 23.1625 7.79374 22.206C6.83731 21.2496 6.29999 19.9524 6.29999 18.5998V10.7998C6.29999 9.92459 6.64767 9.08522 7.26654 8.46635C7.88541 7.84748 8.72477 7.4998 9.59999 7.4998C10.4752 7.4998 11.3146 7.84748 11.9334 8.46635C12.5523 9.08522 12.9 9.92459 12.9 10.7998V17.9998H11.1V10.7998C11.1 10.402 10.942 10.0204 10.6606 9.73914C10.3793 9.45784 9.99781 9.2998 9.59999 9.2998C9.20216 9.2998 8.82063 9.45784 8.53933 9.73914C8.25802 10.0204 8.09999 10.402 8.09999 10.7998V18.5998C8.09999 19.475 8.44766 20.3144 9.06653 20.9333C9.68541 21.5521 10.5248 21.8998 11.4 21.8998C12.2752 21.8998 13.1146 21.5521 13.7334 20.9333C14.3523 20.3144 14.7 19.475 14.7 18.5998V4.7998C14.7 4.08372 14.4155 3.39696 13.9092 2.89062C13.4028 2.38427 12.7161 2.0998 12 2.0998C11.2839 2.0998 10.5971 2.38427 10.0908 2.89062C9.58445 3.39696 9.29999 4.08372 9.29999 4.7998V5.9998H7.49999V4.7998C7.49999 3.60633 7.97409 2.46174 8.81801 1.61782C9.66192 0.77391 10.8065 0.299805 12 0.299805C13.1935 0.299805 14.3381 0.77391 15.182 1.61782C16.0259 2.46174 16.5 3.60633 16.5 4.7998V18.5998C16.5 19.2695 16.3681 19.9327 16.1118 20.5515C15.8555 21.1702 15.4798 21.7325 15.0062 22.206C14.5327 22.6796 13.9704 23.0553 13.3517 23.3116C12.7329 23.5679 12.0697 23.6998 11.4 23.6998Z"
        fill={fillColor}
      />
    </svg>
  );
};

export const NoteIcon = ({ fillColor = 'black' }) => {
  return (
    <svg
      style={{ flexShrink: '0' }}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M9 7H15M9 11H15M9 15H13M5 5C5 4.46957 5.21071 3.96086 5.58579 3.58579C5.96086 3.21071 6.46957 3 7 3H17C17.5304 3 18.0391 3.21071 18.4142 3.58579C18.7893 3.96086 19 4.46957 19 5V19C19 19.5304 18.7893 20.0391 18.4142 20.4142C18.0391 20.7893 17.5304 21 17 21H7C6.46957 21 5.96086 20.7893 5.58579 20.4142C5.21071 20.0391 5 19.5304 5 19V5Z"
        stroke={fillColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const AttachmentFileIcon = ({ fillColor = '#116582' }) => {
  return (
    <svg
      style={{ flexShrink: '0' }}
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="41"
      viewBox="0 0 40 41"
      fill="none"
    >
      <path
        d="M13.3334 27.1668H26.6667V30.5002H13.3334V27.1668ZM13.3334 20.5002H26.6667V23.8335H13.3334V20.5002ZM23.3334 3.8335H10C8.16669 3.8335 6.66669 5.3335 6.66669 7.16683V33.8335C6.66669 35.6668 8.15002 37.1668 9.98335 37.1668H30C31.8334 37.1668 33.3334 35.6668 33.3334 33.8335V13.8335L23.3334 3.8335ZM30 33.8335H10V7.16683H21.6667V15.5002H30V33.8335Z"
        fill={fillColor}
      />
    </svg>
  );
};

export const PlayIcon = ({ fillColor = '#116582' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="28" viewBox="0 0 26 28" fill="none">
      <path
        d="M22.4671 16.9823L22.4671 16.9823L6.81185 26.3434C4.64607 27.6384 1.8681 26.0677 1.8681 23.4485V4.72631C1.8681 2.10701 4.64606 0.536312 6.81185 1.83134L22.4671 11.1924L22.9617 10.3652L22.4671 11.1924C24.634 12.4881 24.634 15.6867 22.4671 16.9823Z"
        fill="white"
        stroke="white"
        stroke-width="1.92761"
      />
    </svg>
  );
};

export const VerticalTimelineLockIcon = ({ fillColor = '#7D9CB8' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="16" viewBox="0 0 13 16" fill="none">
      <path
        d="M1.53516 15.75C1.12266 15.75 0.769656 15.6032 0.476156 15.3097C0.182656 15.0162 0.0356562 14.663 0.0351562 14.25V6.75C0.0351562 6.3375 0.182156 5.9845 0.476156 5.691C0.770156 5.3975 1.12316 5.2505 1.53516 5.25H2.28516V3.75C2.28516 2.7125 2.65091 1.82825 3.38241 1.09725C4.11391 0.36625 4.99816 0.0005 6.03516 0C7.07266 0 7.95716 0.36575 8.68866 1.09725C9.42016 1.82875 9.78566 2.713 9.78516 3.75V5.25H10.5352C10.9477 5.25 11.3009 5.397 11.5949 5.691C11.8889 5.985 12.0357 6.338 12.0352 6.75V14.25C12.0352 14.6625 11.8884 15.0157 11.5949 15.3097C11.3014 15.6037 10.9482 15.7505 10.5352 15.75H1.53516ZM6.03516 12C6.44766 12 6.80091 11.8532 7.09491 11.5597C7.38891 11.2662 7.53566 10.913 7.53516 10.5C7.53516 10.0875 7.38841 9.7345 7.09491 9.441C6.80141 9.1475 6.44816 9.0005 6.03516 9C5.62266 9 5.26966 9.147 4.97616 9.441C4.68266 9.735 4.53566 10.088 4.53516 10.5C4.53516 10.9125 4.68216 11.2657 4.97616 11.5597C5.27016 11.8537 5.62316 12.0005 6.03516 12ZM3.78516 5.25H8.28516V3.75C8.28516 3.125 8.06641 2.59375 7.62891 2.15625C7.19141 1.71875 6.66016 1.5 6.03516 1.5C5.41016 1.5 4.87891 1.71875 4.44141 2.15625C4.00391 2.59375 3.78516 3.125 3.78516 3.75V5.25Z"
        fill={fillColor}
      />
    </svg>
  );
};

export const VerticalTimelineHollowIcon = ({ fillColor = '#B2CAE0' }) => {
  return (
    <svg className="hollow" xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
      <circle cx="6.03516" cy="6" r="5" fill="white" stroke={fillColor} />
    </svg>
  );
};

export const VerticalTimelineFilledIcon = ({ fillColor = '#B2CAE0' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
      <circle cx="6.03467" cy="6.25" r="4.5" fill={fillColor} stroke={fillColor} stroke-width="2" />
    </svg>
  );
};
export const FullScreenExpansionIcon = ({ fillColor = '#36394D' }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      d="M1.74411 13.0993L5.72811 9.09128C5.90411 8.93128 6.10811 8.85128 6.34011 8.85128C6.57211 8.85128 6.77211 8.93528 6.94011 9.10328C7.10811 9.27128 7.19211 9.47128 7.19211 9.70328C7.19211 9.93528 7.11211 10.1313 6.95211 10.2913L2.94411 14.2993H5.32011C5.56011 14.2993 5.76411 14.3833 5.93211 14.5513C6.10011 14.7193 6.18411 14.9233 6.18411 15.1633C6.18411 15.4033 6.10011 15.6073 5.93211 15.7753C5.76411 15.9433 5.56011 16.0193 5.32011 16.0033H1.02411C0.752113 16.0033 0.516113 15.9073 0.316113 15.7153C0.116113 15.5233 0.0161133 15.2913 0.0161133 15.0193V10.7233C0.0161133 10.5633 0.0561133 10.4153 0.136113 10.2793C0.216113 10.1433 0.320113 10.0393 0.448113 9.96728C0.576113 9.89528 0.716113 9.85928 0.868113 9.85928C1.02011 9.85928 1.16411 9.89528 1.30011 9.96728C1.43611 10.0393 1.54411 10.1433 1.62411 10.2793C1.70411 10.4153 1.74411 10.5633 1.74411 10.7233V13.0993ZM10.2881 6.93128C10.1121 7.09128 9.90811 7.17128 9.67611 7.17128C9.44411 7.17128 9.24411 7.08728 9.07611 6.91928C8.90811 6.75128 8.82411 6.55128 8.82411 6.31928C8.82411 6.08728 8.90411 5.89128 9.06411 5.73128L13.0721 1.72328H10.6961C10.4561 1.72328 10.2561 1.63928 10.0961 1.47128C9.93611 1.30328 9.85211 1.09928 9.84411 0.859283C9.83611 0.619284 9.91611 0.415283 10.0841 0.247284C10.2521 0.0792837 10.4561 -0.00471687 10.6961 -0.00471687L14.9921 0.0192833C15.2641 0.0192833 15.5001 0.115284 15.7001 0.307283C15.9001 0.499284 16.0001 0.731283 16.0001 1.00328V5.29928C16.0001 5.45928 15.9601 5.60728 15.8801 5.74328C15.8001 5.87928 15.6961 5.98328 15.5681 6.05528C15.4401 6.12728 15.3001 6.16328 15.1481 6.16328C14.9961 6.16328 14.8521 6.12728 14.7161 6.05528C14.5801 5.98328 14.4721 5.87928 14.3921 5.74328C14.3121 5.60728 14.2721 5.45928 14.2721 5.29928V2.92328L10.2881 6.93128Z"
      fill={fillColor}
    />
  </svg>
);
export const FullScreenRestorationIcon = ({ fillColor = '#36394D' }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      d="M14.9999 4.99994H12.4199L15.7099 1.70994C15.8982 1.52164 16.004 1.26624 16.004 0.999941C16.004 0.73364 15.8982 0.478245 15.7099 0.289941C15.5216 0.101638 15.2662 -0.00415039 14.9999 -0.00415039C14.7336 -0.00415039 14.4782 0.101638 14.2899 0.289941L10.9999 3.56994V0.999941C10.9999 0.734725 10.8946 0.480371 10.707 0.292835C10.5195 0.105298 10.2652 -5.8651e-05 9.99994 -5.8651e-05C9.73472 -5.8651e-05 9.48037 0.105298 9.29283 0.292835C9.1053 0.480371 8.99994 0.734725 8.99994 0.999941V5.99994C8.99994 6.26516 9.1053 6.51951 9.29283 6.70705C9.48037 6.89458 9.73472 6.99994 9.99994 6.99994H14.9999C15.2652 6.99994 15.5195 6.89458 15.707 6.70705C15.8946 6.51951 15.9999 6.26516 15.9999 5.99994C15.9999 5.73472 15.8946 5.48037 15.707 5.29283C15.5195 5.1053 15.2652 4.99994 14.9999 4.99994ZM5.99994 8.99994H0.999939C0.734722 8.99994 0.480369 9.1053 0.292832 9.29284C0.105296 9.48037 -6.10352e-05 9.73472 -6.10352e-05 9.99994C-6.10352e-05 10.2652 0.105296 10.5195 0.292832 10.707C0.480369 10.8946 0.734722 10.9999 0.999939 10.9999H3.56994L0.289939 14.2899C0.196211 14.3829 0.121816 14.4935 0.0710478 14.6154C0.0202791 14.7372 -0.00585938 14.8679 -0.00585938 14.9999C-0.00585938 15.132 0.0202791 15.2627 0.0710478 15.3845C0.121816 15.5064 0.196211 15.617 0.289939 15.7099C0.382902 15.8037 0.493503 15.8781 0.615362 15.9288C0.737221 15.9796 0.867927 16.0057 0.999939 16.0057C1.13195 16.0057 1.26266 15.9796 1.38452 15.9288C1.50638 15.8781 1.61698 15.8037 1.70994 15.7099L4.99994 12.4199V14.9999C4.99994 15.2652 5.1053 15.5195 5.29283 15.707C5.48037 15.8946 5.73472 15.9999 5.99994 15.9999C6.26516 15.9999 6.51951 15.8946 6.70705 15.707C6.89458 15.5195 6.99994 15.2652 6.99994 14.9999V9.99994C6.99994 9.73472 6.89458 9.48037 6.70705 9.29284C6.51951 9.1053 6.26516 8.99994 5.99994 8.99994Z"
      fill={fillColor}
    />
  </svg>
);
export const AddRecordingPlusIcon = ({ fillColor = '#C9B382' }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M12.75 7V17M7.75 12H17.75M21.75 12C21.75 16.9706 17.7206 21 12.75 21C7.77944 21 3.75 16.9706 3.75 12C3.75 7.02944 7.77944 3 12.75 3C17.7206 3 21.75 7.02944 21.75 12Z"
      stroke={fillColor}
      stroke-width="2.1"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
