import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { fetchContributionAdmin } from 'actions/contributions';
import { Switch, Route } from 'react-router-dom';
import { useHeader } from 'hooks';
import ContributionsHeader from './components/ContributionsHeader';
import ContributionsAdminContainer from './ContributionsAdminContainer';

const StyledAdminContributionWrapper = styled.div`
  padding: 30px 150px 30px 60px;
`;

const AdminContributionsPage = ({ match: { path }, getContributions }) => {
  useHeader('Contributions');

  useEffect(() => {
    getContributions();
  }, [getContributions]);

  return (
    <>
      <ContributionsHeader />
      <StyledAdminContributionWrapper>
        <Switch>
          <Route to={path} exact component={ContributionsAdminContainer} />
        </Switch>
      </StyledAdminContributionWrapper>
    </>
  );
};

AdminContributionsPage.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string,
  }).isRequired,
  getContributions: PropTypes.func.isRequired,
};

const actions = {
  getContributions: fetchContributionAdmin,
};

export default connect(null, actions)(AdminContributionsPage);
