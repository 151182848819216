import React from 'react';
import Grid from '@material-ui/core/Grid';
import SubcscribeCard from './components/SubcscribeCard/SubcscribeCard';
import styled from 'styled-components';

import './Notifications.scss';
import ChatAndGetStartedButtons from 'pages/Dashboard/components/CohealerDashboard/LiveChat/LiveChatContainer';
import useAccount from 'hooks/useAccount';
import { UserRoles } from 'helpers/constants';

const StyledGrid = styled(Grid)`
  padding: 30px 15px 30px 25px;
`;

function Notifications() {
  const { coachLoginInfo, currentRole, user } = useAccount();
  const isCohealer = currentRole === UserRoles.cohealer;
  return (
    <Grid container direction="column" alignItems="center">
      <SubcscribeCard />
      {isCohealer && <ChatAndGetStartedButtons page={'ProfilePage'} />}
    </Grid>
  );
}

export default Notifications;
