import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import * as userActions from 'actions/user';
import { useAccount, useRouter } from 'hooks';
import * as userService from 'services/user.service';

import { ZoomIntegrationService } from '../service/zoomIntegration.service';

const href = [
  process.env.REACT_APP_ZOOM_URI,
  '?response_type=',
  process.env.REACT_APP_Z_R_T,
  '&client_id=',
  process.env.REACT_APP_ZOOM_CLIENT_ID,
  '&redirect_uri=',
  process.env.REACT_APP_ZOOM_REDIRECT_URI,
].join('');

export const useZoom = () => {
  const dispatch = useDispatch();
  const { history, location } = useRouter();
  const [isLoading, setIsLoading] = useState(false);
  const searchParams = new URLSearchParams(location.search);
  const authorizationCode = searchParams.get(process.env.REACT_APP_Z_R_T);
  const { user } = useAccount();
  const { accountId, isZoomEnabled, email } = user;
  const authorizeAccountHref = href;
  const isAccountConnected = isZoomEnabled;

  useEffect(() => {
    if (authorizationCode && !isZoomEnabled && !isLoading) {
      setIsLoading(true);

      ZoomIntegrationService.connectAccount({ authorizationCode, redirectUri: process.env.REACT_APP_ZOOM_REDIRECT_URI })
        .then(() => userService.getAccountById(accountId))
        .then(accountData => dispatch(userActions.getAccount(accountData)))
        .catch()
        .finally(() => setIsLoading(false));

      history.push(location.pathname);
    }
  }, [accountId, authorizationCode, dispatch, history, isLoading, location.pathname, isZoomEnabled]);

  const onDisconnect = useCallback(() => {
    setIsLoading(true);

    ZoomIntegrationService.disconnectAccount()
      .then(() => userService.getAccountById(accountId))
      .then(accountData => dispatch(userActions.getAccount(accountData)))
      .catch()
      .finally(() => setIsLoading(false));
  }, [accountId, dispatch]);

  return {
    authorizeAccountHref,
    authorizationCode,
    isAccountConnected,
    onDisconnect,
    isLoading,
    email,
  };
};
