import React from 'react';

const BackSvg = ({ width = 7, height = 13, color = '#215C73' }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 7 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6 1.5L1.35355 6.14645C1.15829 6.34171 1.15829 6.65829 1.35355 6.85355L6 11.5"
        stroke={color}
        stroke-width="2"
        stroke-linecap="round"
      />
    </svg>
  );
};

export default BackSvg;
