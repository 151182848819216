import { useCallback } from 'react';
import TwilioVideo, { createLocalAudioTrack } from 'twilio-video';
import pollAudioLevel from './pollAudioLevel';

import useAudioTrack from './useAudioTrack';

const useLocalAudioTrack = (localParticipant, localAudioTrack) => {
  const { audioTrack, isEnabled } = useAudioTrack(localAudioTrack);

  const startLocalAudioTrack = useCallback(
    async (deviceId = null) => {
      try {
        const audioTrack = await createLocalAudioTrack();

        // Display the audio level.
        pollAudioLevel(audioTrack, level => {});

        const createdAudioTrack = await TwilioVideo.createLocalAudioTrack({
          //logLevel: "debug",
          deviceId: deviceId ? { exact: deviceId } : undefined,
        });
        await localParticipant.publishTrack(createdAudioTrack);
      } catch (e) {
        console.log('Failed to turn on microphone.', e);
      }
    },
    [localParticipant],
  );

  const stopLocalAudioTrack = useCallback(() => {
    if (audioTrack) {
      audioTrack.stop();
      localParticipant.unpublishTrack(audioTrack);
    }
  }, [localParticipant, audioTrack]);

  const setIsLocalAudioTrackEnabled = useCallback(
    enabled => {
      if (audioTrack) {
        audioTrack.enable(enabled);
      }
    },
    [audioTrack],
  );

  const switchAudioDevice = useCallback(
    deviceId => {
      stopLocalAudioTrack();
      startLocalAudioTrack(deviceId);
    },
    [startLocalAudioTrack, stopLocalAudioTrack],
  );

  return {
    audioTrack,
    isEnabled,
    setIsEnabled: setIsLocalAudioTrackEnabled,
    deviceLabel: isEnabled && audioTrack ? audioTrack.mediaStreamTrack.label : null,
    switchDevice: switchAudioDevice,
  };
};

export default useLocalAudioTrack;
