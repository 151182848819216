import React, { useEffect, useMemo, useState } from 'react';
import classes from './Tags.module.scss';
import { StyledHeading } from '../Components/StyledComponents/StyledComponents';
import Buttons from '../Components/ContactsButtons/ContactsButtons';
import { PAGE_TYPES } from '../Components/ContactsButtons/ContactsButtons';
import { DummyTags } from './DummyTags';
import { FormControlLabel, Checkbox, Tooltip, useMediaQuery, useTheme } from '@material-ui/core';
import styled from 'styled-components';
import SearchBox from 'pages/EmailCampaigns/Components/SearchBox/SearchBox';
import { ClickAwayListener } from '@material-ui/core';
import FilterIcon from 'pages/EmailCampaigns/Styles/SVG/FilterSvg';
import TagsTable from './Tags/TagsTable';
import PaginationBar from 'pages/EmailCampaigns/Components/PaginationBar/PaginationBar';
import Modal from 'components/UI/Modal';
import AddTagModalContent from '../Components/AddTagModalContent/AddTagModalContent';
import DeleteTagModalContent from '../Components/DeleteTagModalContent/DeleteTagModalContent';
import { PER_PAGE_TAGS_COUNT } from 'pages/EmailCampaigns/Styles/Constants/constants';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllTagsAsync } from 'actions/contacts';
import SelectedContacts from 'pages/Contacts/Clients/Clients';
import Loader from 'components/UI/Loader';
import { ContactsServices } from '../Services/Contacts.service';
import InfoIcon from '@material-ui/icons/Info';
import { colors } from 'utils/styles';
import MainContainer from 'components/Containers/MainContainer';
import { StyledMainSection } from 'components/Containers/CreateContributionContainer';
import { useHeader } from 'hooks/usePageActions';

const StyledLabel = styled.div`
  font-family: 'Avenir';
  font-weight: 350;
  font-size: 14px;
  line-height: 16px;
  color: #4a4a4a;
`;

const AllTags = () => {
  const dispatch = useDispatch();
  const {
    tags: { isLoading, tags },
    contacts,
    clients: LSClients,
  } = useSelector(state => state.contacts);

  const [selectedTags, setSelectedTags] = useState([]);
  const [deletionAction, setDeletionAction] = useState('Remove');
  const [allSelected, setAllSelected] = useState(false);
  const [isUnderstandChecked, setIsUnderstandChecked] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isAddTagModalOpen, setIsAddTagModalOpen] = useState(false);
  const [isDeleteAllModalOpen, setIsDeleteAllModalOpen] = useState(false);
  const [isCreatingTag, setIsCreatingTag] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchedKeyword, setSearchedKeyword] = useState('');
  const [showContactsList, setShowContactsList] = useState(false);
  const [showContactsListItem, setShowContactsListItem] = useState(false);
  const [selectedTag, setSelectedTag] = useState(null);
  const [isReadOnlyView, setIsReadOnlyView] = useState(false);
  const [isAddContact, setIsAddContact] = useState(false);
  const [newTag, setNewTag] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [contactSavedModal, setContactSavedModal] = useState(false);
  const [contactNotSavedModal, setContactNotSavedModal] = useState(false);
  const [allContactsArray, setallContactsArray] = useState([]);
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [showModalToAddContacts, setshowModalToAddContacts] = useState(false);
  const theme = useTheme();
  useHeader('Tags');
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  useEffect(() => {
    dispatch(fetchAllTagsAsync());
  }, [showContactsList, showContactsListItem, showModalToAddContacts]);

  useEffect(() => {
    if (selectedTag) {
      setNewTag(selectedTag?.name);
      setSelectedContacts(selectedTag?.contactIds);
    }
  }, [selectedTag]);

  const handleDeleteAll = () => {
    setIsDeleteAllModalOpen(true);
  };

  const handleSearch = e => {
    setSearchedKeyword(e.target.value.trim());
  };

  const isChecked = id => {
    return selectedTags.includes(id);
  };

  const onCheckClicked = id => {
    if (isChecked(id)) {
      setSelectedTags(selectedTags.filter(tagId => tagId !== id));
    } else {
      setSelectedTags([...selectedTags, ...[id]]);
    }
  };

  const createNewTagClickHandler = () => {
    setNewTag('');
    setIsAddTagModalOpen(true);
  };

  const getSearchedData = (data = [], keyword = '') => {
    return data?.filter(item => item?.name?.toLowerCase()?.includes(keyword));
  };

  const pageCount = useMemo(() => {
    const count = Math.ceil(getSearchedData(tags, searchedKeyword?.toLocaleLowerCase())?.length / PER_PAGE_TAGS_COUNT);
    return count;
  }, [tags, searchedKeyword]);

  useEffect(() => {
    setCurrentPage(1);
  }, [pageCount]);

  useEffect(() => {
    if (selectedTags?.length > 0 && selectedTags?.length === tags?.length) {
      setAllSelected(true);
    } else {
      setAllSelected(false);
    }
  }, [selectedTags]);

  const handleDeletionActionChange = ({ target: { value } }) => {
    setDeletionAction(value);
  };

  const resetDeleteAction = () => {
    setDeletionAction('Remove');
  };

  const handleAddContactsClick = () => {
    // setShowContactsList(true);
    setShowContactsListItem(true);
    setshowModalToAddContacts(true);
  };

  const handleDoneClick = () => {
    //This case is for new tag creation. While creating new tag, selectedTag value is null...
    if (!selectedTag) {
      setShowContactsList(false);
      setShowContactsListItem(false);
      setshowModalToAddContacts(false);
      setIsAddTagModalOpen(true);
    } else if (isReadOnlyView && selectedTag) {
      //when viewing the contacts of a tag
      setSelectedContacts([]);
      setSelectedTag(null);
      setShowContactsList(false);
      setShowContactsListItem(false);
      setshowModalToAddContacts(false);
      setIsReadOnlyView(false);
    } else if (!isAddContact && selectedTag) {
      //when editing a tag
      setShowContactsList(false);
      setShowContactsListItem(false);
      setshowModalToAddContacts(false);
    } else if (isAddContact && selectedTag) {
      setIsSubmitting(true);
      if (selectedContacts.length != selectedTag.contactIds.length) {
        //when adding a new tag

        const data = {
          ...selectedTag,
          contactsCount: selectedContacts?.length,
          contactIds: selectedContacts,
          contacts: selectedContacts?.map(contactId => {
            const contactDetails = contacts?.find(c => c.id === contactId);
            return {
              id: contactId,
              notes: contactDetails?.notes,
              chatSid: contactDetails?.chatSid,
              tagIds: contactDetails?.tags?.map(t => t.id),
              firstName: contactDetails?.firstName,
              lastName: contactDetails?.lastName,
            };
          }),
        };

        ContactsServices.editTag(data)
          .then(res => {
            setSelectedTag(null);
            setSelectedContacts([]);
            setShowContactsList(false);
            setShowContactsListItem(false);
            setshowModalToAddContacts(false);
            setIsSubmitting(false);
            setContactSavedModal(true);
            setIsAddContact(false);
            dispatch(fetchAllTagsAsync());
          })
          .catch(err => {
            setSelectedTag(null);
            setSelectedContacts([]);
            setShowContactsList(false);
            setIsSubmitting(false);
            setshowModalToAddContacts(false);
            setIsAddContact(false);
            setContactNotSavedModal(true);
          });
      } else {
        setSelectedTag(null);
        setSelectedContacts([]);
        setShowContactsList(false);
        setIsSubmitting(false);
        setIsAddContact(false);
        setshowModalToAddContacts(false);
      }
    }
  };

  useEffect(() => {
    if (selectedContacts.length > 0) {
      const filteredArr = contacts.filter(item => selectedContacts.includes(item.id));
      setallContactsArray(filteredArr);
    } else {
    }
    if (showContactsList === false) {
      setallContactsArray([]);
    }
  }, [selectedContacts, showContactsList]);

  return (
    <>
      {isSubmitting && <Loader />}
      {showContactsListItem || showModalToAddContacts ? (
        <SelectedContacts
          showTopBar={false}
          showFilter={true}
          clients={isReadOnlyView === false ? contacts : allContactsArray}
          setClients={setSelectedContacts}
          markedClients={selectedContacts}
          onBackClick={handleDoneClick}
          isReadOnlyView={isReadOnlyView}
        />
      ) : showContactsList && allContactsArray.length > 0 ? (
        <SelectedContacts
          showTopBar={false}
          showFilter={true}
          clients={isReadOnlyView === false ? contacts : allContactsArray}
          setClients={setSelectedContacts}
          markedClients={selectedContacts}
          onBackClick={handleDoneClick}
          isReadOnlyView={isReadOnlyView}
        />
      ) : (
        <MainContainer>
          <StyledMainSection mobileView={mobileView}>
            <div className={classes.container}>
              <div className={classes.topBar}>
                <StyledHeading>
                  <div>Tags</div>
                  <div style={{ display: 'flex' }}>
                    <Tooltip title="In email marketing, a tag categorizes contacts for personalized campaigns, and Cohere auto-tags based on contributions and lead magnets.">
                      <InfoIcon htmlColor={colors.lightBrown} />
                    </Tooltip>
                  </div>
                </StyledHeading>
                <Buttons
                  page={PAGE_TYPES.Tags}
                  createNewTagClickHandler={createNewTagClickHandler}
                  createNewTagBtnClassName={isCreatingTag && classes.createtag_btn_disabled}
                />
              </div>
              <div className={classes.searchBar}>
                <div style={{ width: '50%', display: 'flex', justifyContent: 'flex-start', gap: '10px' }}>
                  <FormControlLabel
                    style={{ fontFamily: 'Avenir', marginRight: '5px' }}
                    checked={allSelected}
                    onChange={() => {
                      setAllSelected(!allSelected);
                      if (allSelected === false) {
                        setSelectedTags([...tags?.map(c => c.id)]);
                      } else {
                        setSelectedTags([]);
                      }
                    }}
                    control={<Checkbox color="primary" />}
                    label={<StyledLabel>Select all</StyledLabel>}
                    name="allSelect"
                    disabled={false}
                  />
                  {selectedTags?.length > 0 && (
                    <button className={classes.deleteBtn} type="button" onClick={handleDeleteAll}>
                      Delete
                    </button>
                  )}
                </div>
                <div style={{ width: '50%', display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
                  <div>
                    <SearchBox placeholder="Search" background="white" onChange={handleSearch} />
                  </div>
                </div>
              </div>
              <div className={classes.tagsTable}>
                {isLoading ? (
                  <Loader />
                ) : (
                  <>
                    <TagsTable
                      tags={getSearchedData(tags, searchedKeyword?.toLocaleLowerCase())?.slice(
                        (currentPage - 1) * PER_PAGE_TAGS_COUNT,
                        currentPage * PER_PAGE_TAGS_COUNT,
                      )}
                      isChecked={isChecked}
                      onCheckClicked={onCheckClicked}
                      setShowContactsList={setShowContactsList}
                      setSelectedTag={setSelectedTag}
                      setIsAddTagModalOpen={setIsAddTagModalOpen}
                      setIsReadOnlyView={setIsReadOnlyView}
                      setIsAddContact={setIsAddContact}
                      searchedKeyword={searchedKeyword}
                      createNewTagClickHandler={createNewTagClickHandler}
                      setshowModalToAddContacts={setshowModalToAddContacts}
                    />
                    <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'flex-end' }}>
                      <div style={{ fontSize: '13px', fontWeight: '400', position: 'relative', bottom: '-28px' }}>
                        {currentPage * PER_PAGE_TAGS_COUNT - PER_PAGE_TAGS_COUNT === 0
                          ? 1
                          : currentPage * PER_PAGE_TAGS_COUNT - PER_PAGE_TAGS_COUNT + 1}{' '}
                        - {pageCount != currentPage ? currentPage * PER_PAGE_TAGS_COUNT : tags.length} of {tags.length}
                      </div>
                      <PaginationBar
                        count={pageCount}
                        handleChange={(e, page) => {
                          setCurrentPage(page);
                        }}
                        page={currentPage}
                      />
                    </div>
                  </>
                )}
              </div>
              {isAddTagModalOpen && (
                <AddTagModalContent
                  isOpen={isAddTagModalOpen}
                  onClose={() => {
                    setNewTag('');
                    setSelectedTag(null);
                    setSelectedContacts([]);
                    setIsReadOnlyView(false);
                    setIsAddTagModalOpen(false);
                  }}
                  onAddContactsClick={handleAddContactsClick}
                  setNewTag={setNewTag}
                  tag={{
                    id: selectedTag ? selectedTag?.id : null,
                    name: newTag,
                    contactIds: selectedContacts,
                    coachId: selectedTag ? selectedTag?.coachId : null,
                    emailSent: selectedTag ? selectedTag?.emailSent : 0,
                  }}
                  isEditing={!!selectedTag}
                />
              )}
              {/* <Modal
            title="Delete Selected Tag(s)"
            isOpen={isDeleteAllModalOpen}
            onCancel={() => {
              setIsDeleteAllModalOpen(false);
            }}
            onSubmit={() => {
              setIsDeleting(true);
              ContactsServices.deleteTag(selectedTags)
                .then(() => {
                  resetDeleteAction();
                  setSelectedTags([]);
                  setIsDeleting(false);
                  dispatch(fetchAllTagsAsync());
                  setIsDeleteAllModalOpen(false);
                })
                .catch(err => {
                  setIsDeleting(false);
                });
            }}
            submitTitle="Delete"
            disableSubmitClick={isDeleting}
            reduceCancelButton
            widthRequired
          >
            <DeleteTagModalContent
              tags={tags?.filter(tag => selectedTags.includes(tag.id))}
              action={deletionAction}
              onChangeAction={handleDeletionActionChange}
            />
          </Modal> */}

              <Modal
                title="Changes Saved"
                isOpen={contactSavedModal}
                submitTitle="Close"
                onSubmit={() => {
                  setContactSavedModal(false);
                }}
                onCancel={() => {
                  setContactSavedModal(false);
                }}
                hiddenCancel
                reduceCancelButton
                widthRequiredIs
              >
                <div>Contact(s) added successfully!</div>
              </Modal>

              <Modal
                title="Changes Not Saved"
                isOpen={contactNotSavedModal}
                submitTitle="Close"
                onSubmit={() => {
                  setContactNotSavedModal(false);
                }}
                onCancel={() => {
                  setContactNotSavedModal(false);
                }}
                hiddenCancel
                reduceCancelButton
                widthRequiredIs
              >
                <div>Contact(s) not added</div>
              </Modal>

              <Modal
                title="Delete Tag(s)"
                isOpen={isDeleteAllModalOpen}
                onCancel={() => {
                  setIsDeleteAllModalOpen(false);
                }}
                submitTitle="Delete"
                onSubmit={() => {
                  setIsDeleting(true);
                  ContactsServices.deleteTag(selectedTags)
                    .then(res => {
                      setSelectedTags([]);
                      dispatch(fetchAllTagsAsync());
                    })
                    .catch(err => {})
                    .finally(() => {
                      setIsDeleting(false);
                      setIsDeleteAllModalOpen(false);
                    });
                }}
                reduceCancelButton
                widthRequired
                disableSubmitClick={!isUnderstandChecked || isDeleting}
              >
                <div className={classes.delete_modal_main}>
                  <div className={classes.top_heading}>
                    Are you sure you want to delete this/these tag(s)? Your action cannot be undone.
                  </div>
                  <div className={classes.form_control_label_container}>
                    <FormControlLabel
                      checked={isUnderstandChecked}
                      onChange={() => {
                        setIsUnderstandChecked(!isUnderstandChecked);
                      }}
                      control={<Checkbox className={classes.checkbox_root} disableRipple={true} color="primary" />}
                      className={classes.form_control}
                      label={
                        <div className={classes.form_label}>
                          I understand that this action will remove the below tags and cannot be undone.
                        </div>
                      }
                      name="understand"
                      disabled={false}
                    />
                  </div>
                  <div>
                    <div className={classes.contact_list_heading}>Following tag(s) will be deleted:</div>
                    <div className={classes.contact_list}>
                      {tags
                        ?.filter(t => selectedTags.includes(t.id))
                        .map(t => {
                          return <div>{`${t?.name}, `}</div>;
                        })}
                    </div>
                  </div>
                </div>
              </Modal>
            </div>
          </StyledMainSection>
        </MainContainer>
      )}
    </>
  );
};

export default AllTags;
