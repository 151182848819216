import { EventEmitter } from 'events';
import { If, mapTo, shape, filterCall } from '../utils/fp';

const STORAGE_KEYS = {
  AUDIO_INPUT: 'audio_input',
  AUDIO_OUTPUT: 'audio_output',
  VIDEO_INPUT: 'video_input',
};

const STORAGE_EVENTS = new EventEmitter();

function emit(...data) {
  STORAGE_EVENTS.emit('storage', ...data);
}

function emitRemoveEvent([key, value]) {
  emit({ key, prev: value, value: null });
}

function initStorage() {
  const mapEvent = shape({ key: 'key', oldValue: 'prev', newValue: 'value' });

  window.addEventListener('storage', event => {
    If(event.storageArea === localStorage)
      .then(mapTo(event))
      .then(mapEvent)
      .then(emit);
  });
}

function getItem(key) {
  return localStorage.getItem(key);
}

function getItemAsync(key, valueToReplace) {
  if (typeof valueToReplace !== 'string') {
    throw new Error('Value to replacement must be a string!');
  }

  return new Promise(resolve => {
    setTimeout(() => {
      resolve(getItem(key) || valueToReplace);
    }, 0);
  });
}

function setItem(key, _value) {
  const value = typeof _value !== 'string' ? JSON.stringify(_value) : _value;

  emit({ key, value, prev: getItem(key) });
  localStorage.setItem(key, value);
  return _value;
}

function setItemAsync(key, value) {
  return Promise.resolve(setItem(key, value));
}

function removeItem(key) {
  const value = getItem(key);
  emitRemoveEvent([key, value]);
  localStorage.removeItem(key);
  return value;
}

function clearStorage() {
  Object.entries(localStorage).map(emitRemoveEvent);
  localStorage.clear();
  sessionStorage.clear();
}

function subscribe(key, fn) {
  const cb = filterCall({ key }, fn);
  STORAGE_EVENTS.on('storage', cb);
  return () => STORAGE_EVENTS.removeListener('storage', cb);
}

export { STORAGE_KEYS, getItem, getItemAsync, setItem, setItemAsync, removeItem, clearStorage, initStorage, subscribe };
