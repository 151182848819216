import React, { useEffect, useState, useRef } from 'react';
import styled, { css } from 'styled-components';
import { getAllCampaignAnalytics } from 'pages/CreateCampaign/Services/CreateCampaign.service';
import { formatNumberTo2Decimal } from 'utils/utils';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 0.625rem;
  margin-top: 1.5rem;
`;
const StyledTitleContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 0.5rem;
  .title {
    color: #000;
    font-family: Avenir;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    cursor: text;
  }
  .subtitle {
    color: #000;
    font-family: Avenir;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;
const StyledCardsContainer = styled.div`
  display: flex;
  align-items: flex-start;
  column-gap: 8px;
  row-gap: 1rem;
  flex-wrap: wrap;

  @media (max-width: 1344px) {
    overflow-x: auto;
    scroll-behavior: smooth;
    flex-wrap: nowrap;
  }
`;
const StyledCard = styled.div`
  border-radius: 0.25rem;
  border: 1px solid var(--Cohere-Greys-Grey-Tint, #dfe3e4);
  background: #fff;

  display: flex;
  padding: 1rem;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 1rem;
  flex: 1 0 0;

  overflow: hidden;

  /* in figma design */
  /* min-width: 10.66071rem;
min-height: 6rem; */

  /* 20% lesser than in design */
  min-width: 8.528568rem;
  min-height: 4.8rem;

  /* 25% greater than figma design */
  max-height: 7.5rem;

  width: 170px;
  height: 120px;
  //max-width: 10rem;

  ${({ loading }) =>
    loading &&
    css`
      position: relative;
      > *:not(.loader) {
        opacity: 0.4;
      }
    `}

  .stats-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  .stats-values-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    flex-direction: column;
  }
  .stats-type {
    color: var(--Cohere-Greys-Text, #4a4a4a);
    font-family: Avenir;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem; /* 171.429% */
  }
  .stats-primary-value {
    color: var(--Cohere-Primary-Blue, #215c73);
    font-family: Avenir;
    font-size: 18.532px;
    font-style: normal;
    font-weight: 900;
    line-height: 22.238px;
  }
  .stats-secondary-value {
    color: rgba(0, 0, 0, 0.87);
    font-family: Avenir;
    font-size: 14.825px;
    font-style: normal;
    font-weight: 400;
    line-height: 22.238px;
  }
`;

const StyledNavButton = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(255, 255, 255, 0.8);
  border: none;
  cursor: pointer;
  z-index: 1;
  padding: 0.5rem;
  &:focus {
    outline: none;
  }
  &:hover {
    background-color: rgba(255, 255, 255, 1);
  }
`;

const LeftButton = styled(StyledNavButton)`
  left: 0;
  border-radius: 50%;
  padding: 0rem;
  border: 1.25px solid grey !important;
`;

const RightButton = styled(StyledNavButton)`
  right: 0;
  border-radius: 50%;
  padding: 0rem;
  border: 1.25px solid grey !important;
`;

const formatNumberWithCommas = number => {
  return number.toLocaleString();
};

const parseApiResponse = data => {
  const transformedList = [
    {
      type: 'Sent',
      primaryValue: formatNumberWithCommas(Number(data.emailsRequested || 0)),
    },
    {
      type: 'Delivered',
      primaryValue: `${formatNumberTo2Decimal(Number(data.deliveredPercentage || 0))}%`,
      secondaryValue: formatNumberWithCommas(Number(data.deliveredCount || 0)),
    },
    {
      type: 'Opened',
      primaryValue: `${formatNumberTo2Decimal(Number(data.openedPercentage || 0))}%`,
      secondaryValue: formatNumberWithCommas(Number(data.openedCount || 0)),
    },
    {
      type: 'Clicked',
      primaryValue: `${formatNumberTo2Decimal(Number(data.clickedPercentage || 0))}%`,
      secondaryValue: formatNumberWithCommas(Number(data.clickedCount || 0)),
    },
    {
      type: 'Bounced',
      primaryValue: `${formatNumberTo2Decimal(Number(data.bouncedPercentage || 0))}%`,
      secondaryValue: formatNumberWithCommas(Number(data.bouncedCount || 0)),
    },
    {
      type: 'Spam Reported',
      primaryValue: `${formatNumberTo2Decimal(Number(data.spamReportedPercentage || 0))}%`,
      secondaryValue: formatNumberWithCommas(Number(data.spamReportedCount || 0)),
    },
    {
      type: 'Unsubscribed',
      primaryValue: `${formatNumberTo2Decimal(Number(data.unsubscribedPercentage || 0))}%`,
      secondaryValue: formatNumberWithCommas(Number(data.unsubscribedCount || 0)),
    },
  ];
  return transformedList;
};

const Analytics = () => {
  const [loading, setLoading] = useState(true);
  const [cards, setCards] = useState(parseApiResponse(Array(7).fill({})));
  const [scrollPosition, setScrollPosition] = useState(0);
  const containerRef = useRef(null);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 1344);
  const [showLeftButton, setShowLeftButton] = useState(false);
  const [showRightButton, setShowRightButton] = useState(true);

  useEffect(() => {
    setLoading(true);
    getAllCampaignAnalytics()
      .then(parseApiResponse)
      .then(setCards)
      .finally(() => setLoading(false));

    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 1344);
    };

    const handleScroll = () => {
      const container = containerRef.current;
      const maxScrollLeft = container.scrollWidth - container.clientWidth;

      setScrollPosition(container.scrollLeft);
      setShowLeftButton(container.scrollLeft > 0);
      setShowRightButton(container.scrollLeft < maxScrollLeft - 1);
    };

    window.addEventListener('resize', handleResize);
    containerRef.current.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('resize', handleResize);
      containerRef.current.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleScrollClick = direction => {
    const container = containerRef.current;
    const scrollAmount = 200; // Adjust as needed
    const newScrollPosition =
      direction === 'left'
        ? Math.max(0, scrollPosition - scrollAmount)
        : Math.min(container.scrollWidth - container.clientWidth, scrollPosition + scrollAmount);

    container.scrollTo({ left: newScrollPosition, behavior: 'smooth' });
  };

  return (
    <>
      <StyledContainer>
        <StyledTitleContainer>
          <div className="title">Email Campaign Analytics</div>
          <div className="subtitle">(Month-to-date)</div>
        </StyledTitleContainer>
        <div style={{ position: 'relative' }}>
          {isSmallScreen && showLeftButton && (
            <LeftButton onClick={() => handleScrollClick('left')}>
              <KeyboardDoubleArrowLeftIcon />
            </LeftButton>
          )}
          <StyledCardsContainer ref={containerRef}>
            {cards.map((k, inx) => (
              <StyledCard key={inx} {...{ loading }}>
                <div className="stats-row">
                  <div className="stats-type">{k.type}</div>
                </div>
                <div className="stats-values-row">
                  <div className="stats-primary-value">{k.primaryValue}</div>
                  {inx !== 0 && <div className="stats-secondary-value">{k.secondaryValue}</div>}
                </div>
              </StyledCard>
            ))}
          </StyledCardsContainer>
          {isSmallScreen && showRightButton && (
            <RightButton onClick={() => handleScrollClick('right')}>
              <KeyboardDoubleArrowRightIcon />
            </RightButton>
          )}
        </div>
      </StyledContainer>
    </>
  );
};

Analytics.propTypes = {};

Analytics.defaultProps = {};

export default Analytics;
