import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { useMediaQuery, useTheme } from '@material-ui/core';
const GeneralSettings = ({ values, setFieldValue }) => {
  const { isProfileActive = false } = values || {};
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(993));
  return (
    <>
      <Grid container item className="branding-container profile-section">
        <Grid item xs={12} style={{ height: isMobile ? '220px' : '200px' }}>
          <div className="heading">
            <p style={{ fontFamily: 'Avenir', fontSize: '24px', fontWeight: 500, lineHeight: '24px' }}>
              General Settings
            </p>
          </div>
          <div>
            <p style={{ fontFamily: 'Avenir', fontSize: '16px', fontWeight: 350 }}>
              When deactivated, your clients will see a simple bio page. Activate your profile to showcase your services
              on a dedicated services website. Additionally, your mobile app profile will display your services and your
              added links. Scroll below to customize.
            </p>
          </div>
        </Grid>
        <Grid className="message-settings" item xs={12}>
          <p style={{ fontFamily: 'Avenir', fontSize: '16px', fontWeight: 350 }}>Services Website and Mobile Profile</p>
          <ToggleButtonGroup
            className="toggle-button"
            color="primary"
            value={isProfileActive ? 'enable' : 'disable'}
            exclusive
            onChange={() => setFieldValue('isProfileActive', !isProfileActive)}
          >
            <ToggleButton value="enable">Enable</ToggleButton>
            <ToggleButton value="disable">Disable</ToggleButton>
          </ToggleButtonGroup>
        </Grid>
      </Grid>
    </>
  );
};

GeneralSettings.propTypes = {
  values: PropTypes.shape({
    profilePageViewModel: {
      isProfileActive: PropTypes.bool,
    },
  }).isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

export default GeneralSettings;
