import React, { useCallback, useState, useEffect } from 'react';
import { Link, NavLink, useHistory, useRouteMatch } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Select from 'components/FormUI/Select';
import { Icon } from '@mdi/react';
import { mdiHelpCircleOutline } from '@mdi/js';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import Modal from 'components/UI/Modal';
import * as paidTier from 'selectors/paidTier';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Avatar from '@material-ui/core/Avatar';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import Tooltip from '@material-ui/core/Tooltip';
import * as R from 'ramda';
import * as countryActions from 'actions/country';
import isEmpty from 'lodash/isEmpty';
import { useMediaQuery, useTheme } from '@material-ui/core';
import * as pageActions from 'actions/page';

import { makeStyles, StylesProvider } from '@material-ui/core/styles';
// import { connect, useDispatch, useSelector } from 'react-redux';
import usePaidTier from 'hooks/usePaidTier';
import CohereIcon from 'assets/icon.png';
import BackButton from 'components/UI/BackButton';
import { useShowGettingStartedGuide } from 'components/App/GettingStarted/hooks/useShowGettingStartedGuide';
import SelectingPlanModal from 'components/Modals/SelectingPlanModal';
import TypeOfCourse from 'pages/ContributionView/components/TypeOfCourse';
import PurchaseModalsAndButtons from 'pages/ContributionView/components/PurchaseModalsAndButtons/PurchaseModalsAndButtons';
import BurgerMenu from 'assets/BurgerMenu.svg';
import { useAccount, useRouter, useShallowEqualSelector } from 'hooks';
import { SIGNUP_TYPES, UserRoles } from 'helpers/constants';
import * as userActions from 'actions/user';
import FreeTrialModal from 'pages/Dashboard/components/CohealerDashboard/FreeTrialModal';
import CountryModal from 'pages/Dashboard/components/CohealerDashboard/CountrySelectionModal';
import moment from 'moment';
import ClickDropDown, { StyledDropDownItem } from './ClickDropDown';
import { PAID_TIER_BUTTON_LABEL, PAID_TIER_TITLES, ROUTES, TOOLTIP } from '../../constants';
import { PageTitle, PageTitleText } from './Text/TextStyles';
import GettingStartedGuide from '../App/GettingStarted';
import { colors } from '../../utils/styles';
import UpgradeButton from './UpgradeButton';
import { getProfile } from 'actions/user';
import Loader from './Loader';
import ContributionHeader from 'pages/ContributionView/components/ContributionHeader';
import useContribution from 'pages/ContributionView/hooks/useContribution';
import fbImg from '../../assets/Facebook.png';
import linkedImg from '../../assets/LinkedIn.png';
import instaImg from '../../assets/Instagram.png';
import { redirectToAppIfIsCoachDomain } from 'utils/subdomains';
const StyledHaderContainer = styled.div`
  background-color: white;
  box-shadow: 0 2px 4px 0 rgba(157, 157, 157, 0.3);
  padding: 30px 55px 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 320px;

  ${({ noShadow, mobileView }) => {
    return noShadow && `box-shadow: none;`, mobileView && `padding: 15px 20px; `;
  }};
`;

const StyledUserSectionContainer = styled.div`
  display: flex;
  align-items: center;
  ${({ tabletView }) => tabletView && `margin-right: -20px;`};
`;

const HeaderIcon = styled.img`
  margin-right: 20px;
  vertical-align: middle;
  object-fit: contain;
  width: 45px;
  height: auto;
`;

const TitleAndMobileMenuWrapper = styled.div`
  display: flex;
  ${({ mobileView }) => mobileView && `align-items: baseline;`};
`;

const MobileMenuWrapper = styled.div`
  margin-right: 12px;
  &:hover {
    cursor: pointer;
  }
`;

const AvatarComponent = styled(Avatar)`
  ${({ mobileView }) => {
    return (
      mobileView &&
      `
        width: 24px;
        height: 24px;
        font-size: 0.7rem;
      `
    );
  }}
`;

const StyledIcon = styled(Icon)`
  margin-left: 20px;
  ${({ mobileView }) => mobileView && `width: 6px; height: 2px;`}
`;

const MockBlock = styled.div`
  width: 200px;
`;

const useStyles = makeStyles(theme => ({
  upgradeButton: {
    border: 'none',
    borderRadius: 6,
    width: 125,
    height: 33,
    marginRight: 15,
    color: colors.white,
    fontWeight: 500,
    background: colors.darkOceanBlue,
    transition: 'all 0.25s',
    '&:hover': {
      background: colors.darkBlue,
      textDecoration: 'none',
      color: colors.white,
    },
    [theme.breakpoints.down('sm')]: {
      width: 73,
      marginRight: 0,
      fontSize: 10,
      padding: '0px',
      marginRight: -16,
      '&>svg': {
        width: '0.9rem !important',
        height: '0.9rem !important',
      },
    },
  },
  disableMe: {
    pointerEvents: 'none !important',
    cursor: 'not-allowed !important',
    color: colors.white,
    background: colors.backgroundGrey,
    border: 'none',
    borderRadius: 6,
    width: 125,
    height: 33,
    marginRight: 15,
    fontWeight: 500,
    transition: 'all 0.25s',
  },
}));

const StyledLink = styled(NavLink)`
  &.disableMe {
    opacity: 0.5;
    pointer-events: none;
    cursor: not-allowed;
  }
`;

const StyledSupportLink = styled.a`
  color: ${colors.darkOceanBlue};
  font-weight: 600;
  font-size: 16px;
  &:hover {
    color: ${colors.darkOceanBlue};
  }
`;
const StyledAccountBillingLink = styled(NavLink)`
  position: absolute;
  overflow: hidden;
  color: ${colors.gray};
  font-weight: initial;
  font-size: 15px;
  @media (max-width: 1183px) {
    margin-left: 25px;
  }
  @media (max-width: 995px) {
    margin-left: 5px;
  }
  @media (max-width: 560px) {
    margin-left: 7px;
  }
  margin-left: 20px;
  ${({ tabletView }) => tabletView && `font-size: 13px;`};
  ${({ mobileView }) => mobileView && `font-size: 12px;`};

  &.disableMe {
    opacity: 0.5;
    pointer-events: none;
    cursor: not-allowed;
  }
`;
const isPathForContribution = path => path === '/contribution-view/:id';
const isPathForCohealerAbout = path => path === '/cohealer-about/:cohealerId';

const getInitialSymbol = R.compose(R.toUpper, R.head);
const TRIAL_PERIOD_START_DATE = moment('2021-11-01');
const TRIAL_PERIOD_DAYS = 13;

function Header({
  user,
  title,
  noShadow,
  contribution,
  isCustomPrevButton,
  backButtonText,
  children,
  view,
  countryLoading,
  countries,
  // fetchCountries,
}) {
  const classes = useStyles();

  const { path } = useRouteMatch();
  const history = useHistory();
  const { pathname } = useRouter();

  const { isLaunchPlan } = usePaidTier();
  const { showCoachDashboard } = useSelector(state => state.page);
  const { domain } = useRouter();
  const dispatch = useDispatch();
  const theme = useTheme();
  const { currentRole, user: signupType, loggedInUser } = useAccount();
  const isMobileSidebarNeeded = useMediaQuery(theme.breakpoints.down('sm'));
  const xsView = useMediaQuery(theme.breakpoints.down('xs'));
  const tabletView = useMediaQuery(theme.breakpoints.down('md'));
  const mobileView = view || xsView;
  const [supportPopup, setSupportPopup] = useState(false);
  const activePaidTierCurrentPlan = useShallowEqualSelector(paidTier.activePaidTierCurrentPlanSelector);

  const toggleMobilesidebar = () => {
    dispatch(pageActions.toggleMobileSidebar());
  };

  // useEffect(() => {
  //   if ((!countries || countries.length === 0) && !countryLoading) {
  //     fetchCountries();
  //   }
  // }, [countries, fetchCountries]);

  const onBackClick = () => {
    if (window.location.href.includes('contribution-view')) {
      history.replace('/dashboard');
    } else {
      history.goBack();
    }
  };
  const { isOpen, onClose, setIsOpen } = useShowGettingStartedGuide();
  const [isOpenStartedGuide, setIsOpenStartedGuide] = useState(false);
  const countrypopup = !user?.countryId;
  // const countrypopup = false;

  const handleOpenGetStartedGuide = useCallback(() => {
    setSupportPopup(false);
    // setIsOpenStartedGuide(true);
    window.open('https://calendly.com/d/4f2-5gx-w4n/1-1-cohere-kickstart-launch');
  }, [setIsOpenStartedGuide]);

  const handlehowToVideos = ({}) => {
    window.open('https://www.cohere.live/how-to-library/', '_blank');
  };

  const handleSubmit = useCallback(() => {
    setIsOpenStartedGuide(true);
    setIsOpen(false);
  }, [setIsOpenStartedGuide, setIsOpen]);

  const closeHandler = useCallback(() => {
    setIsOpenStartedGuide(false);
    onClose();
  }, [setIsOpenStartedGuide, onClose]);

  const modalButtons =
    currentRole === UserRoles.cohealer
      ? [
          { buttonTitle: 'Book Onboarding', onClick: handleOpenGetStartedGuide },
          { buttonTitle: 'How-To Videos', onClick: handlehowToVideos },
        ]
      : [];

  const isDashboardOrAccountPage = useCallback(() => {
    return pathname.includes(ROUTES.ACCOUNT) || ROUTES.DASHBOARD === pathname;
  }, [pathname]);

  const downXs = useMediaQuery(theme.breakpoints.down('xs'));
  const downSm = useMediaQuery(theme.breakpoints.down('sm'));
  const downMd = useMediaQuery(theme.breakpoints.down('md'));
  const downLg = useMediaQuery(theme.breakpoints.down('lg'));

  const shouldHideTitle = !downXs && downSm && downMd && downLg;
  const trialPeriodStart = moment(user.createTime);
  const trialPeriodEnd = moment(user.createTime).add(TRIAL_PERIOD_DAYS, 'days');
  const todayDate = moment(moment());
  const isContributionPurchased = contribution.isPurchased;

  const shouldBeDisabled =
    signupType === SIGNUP_TYPES.TYPE_A ||
    signupType === SIGNUP_TYPES.TYPE_B ||
    loggedInUser?.signupType === SIGNUP_TYPES.TYPE_B ||
    loggedInUser?.signupType === SIGNUP_TYPES.TYPE_A;
  const canAccess = currentRole === UserRoles.cohealer || (currentRole === UserRoles.client && isContributionPurchased);
  const { status } = useContribution();
  const CenterLogo = styled.div``;
  const RightLinks = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 110px;
  `;
  const FbAncher = styled.a``;
  const FbImg = styled.img``;
  const LinkImg = styled.img``;
  const InstaImg = styled.img``;
  return (
    <StyledHaderContainer noShadow={noShadow} mobileView={isMobileSidebarNeeded}>
      <Helmet>
        <title>{title} - Cohere</title>
      </Helmet>
      <TitleAndMobileMenuWrapper mobileView={mobileView}>
        {isMobileSidebarNeeded &&
          !(isPathForContribution(path) && contribution?.type) &&
          !isPathForCohealerAbout(path) && (
            <MobileMenuWrapper onClick={toggleMobilesidebar}>
              <img alt="burger-menu" src={BurgerMenu} />
            </MobileMenuWrapper>
          )}
        {/* {!isEmpty(user) && !tabletView && !mobileView && <MockBlock />} */}

        {isCustomPrevButton ? (
          <div className="d-flex align-items-center">
            {!isEmpty(user) && (
              <>
                {currentRole === UserRoles.cohealer && (
                  <>
                    <ArrowBackIosIcon fontSize="small" />
                    <div
                      className="h6 mr-3 cursor-pointer"
                      onClick={onBackClick}
                      onKeyPress={null}
                      role="button"
                      tabIndex={0}
                    >
                      {backButtonText}
                    </div>
                  </>
                )}
              </>
            )}
            {!mobileView && !shouldHideTitle && (
              <div className="d-flex align-items-center">
                {/* <HeaderIcon src={CohereIcon} /> */}
                <div>
                  {currentRole === UserRoles.cohealer && <p className="h3 mb-0 font-weight-light">{title}</p>}
                  {currentRole !== UserRoles.cohealer && (
                    <ContributionHeader
                      isCoach={currentRole === UserRoles.cohealer}
                      canAccess={canAccess}
                      status={status}
                    />
                  )}
                  {isPathForContribution(path) &&
                    currentRole === UserRoles.cohealer &&
                    isLaunchPlan &&
                    trialPeriodStart.isSameOrAfter(TRIAL_PERIOD_START_DATE, 'day') &&
                    todayDate.isAfter(trialPeriodEnd) && (
                      <p className="h5 text-muted font-weight-light">Please upgrade your account to invite clients</p>
                    )}
                </div>
              </div>
            )}
          </div>
        ) : (
          <PageTitle mobileView={mobileView}>
            {isPathForContribution(path) && contribution?.type && (
              <>
                <BackButton type={contribution.type} />
                {!isMobileSidebarNeeded && <HeaderIcon src={CohereIcon} />}
              </>
            )}
            <PageTitleText mobileView={mobileView}>
              {(!(isPathForContribution(path) && contribution?.type) || !isMobileSidebarNeeded) && title}
            </PageTitleText>
          </PageTitle>
        )}
      </TitleAndMobileMenuWrapper>
      <CenterLogo>{<HeaderIcon src={CohereIcon} />}</CenterLogo>
      <StyledUserSectionContainer tabletView={tabletView}>
        <RightLinks>
          <FbAncher href={'#'}>
            <FbImg src={fbImg}></FbImg>
          </FbAncher>
          <FbAncher href={'#'}>
            <LinkImg src={linkedImg}></LinkImg>
          </FbAncher>
          <FbAncher href={'#'}>
            <InstaImg src={instaImg}></InstaImg>
          </FbAncher>
        </RightLinks>
        {!isMobileSidebarNeeded && isPathForContribution(path) && (
          <TypeOfCourse contribution={contribution} handleUrlModal mobileView={mobileView} />
        )}
        {!isMobileSidebarNeeded && isPathForContribution(path) && (
          <PurchaseModalsAndButtons contribution={contribution} />
        )}
        {isPathForContribution(path) && isEmpty(user) && (
          <>
            {currentRole === UserRoles.cohealer && (
              <>
                <AccountCircleIcon style={{ color: '#C7C7C7', fontSize: '50px' }} />
                <ClickDropDown id="already-purchased-dropdown" title={user.firstName} mobileView={mobileView}>
                  <StyledDropDownItem
                    onClick={() => {
                      redirectToAppIfIsCoachDomain(domain, pathname, 'isPurchased=true');
                    }}
                  >
                    Already a member?
                  </StyledDropDownItem>
                </ClickDropDown>
              </>
            )}
          </>
        )}
        {currentRole === UserRoles.cohealer && isLaunchPlan && isDashboardOrAccountPage() && (
          <div className={shouldBeDisabled && 'disableMe'}>
            <StyledLink className={shouldBeDisabled && 'disableMe'} to="/account/billing/coach">
              <UpgradeButton
                disable={shouldBeDisabled}
                buttonLabel={PAID_TIER_BUTTON_LABEL.upgrade}
                style={shouldBeDisabled ? classes.disableMe : classes.upgradeButton}
                starIcon
              />
            </StyledLink>
            <div>
              <StyledAccountBillingLink
                className={shouldBeDisabled && 'disableMe'}
                mobileView={mobileView}
                tabletView={tabletView}
                to="/account/billing/coach"
              >
                Trial Account
              </StyledAccountBillingLink>
            </div>
          </div>
        )}
        {!isEmpty(user) ? (
          <>
            {children && <div style={{ marginRight: '20px' }}>{children}</div>}
            <Tooltip
              className="cursor-pointer text-grey"
              disableHoverListener={shouldBeDisabled}
              disableClickListener={shouldBeDisabled}
              title="Help"
              arrow
              enterTouchDelay={TOOLTIP.ENTER_DELAY}
              leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
            >
              <StyledIcon
                path={mdiHelpCircleOutline}
                size={mobileView ? 0.7 : 1.25}
                onClick={() => {
                  !shouldBeDisabled && setSupportPopup(true);
                }}
              />
            </Tooltip>
            <StylesProvider injectFirst>
              <AvatarComponent
                className="mx-2"
                alt={`${user.firstName} ${user.lastName}`}
                src={user.avatarUrl}
                mobileView={mobileView}
              >
                {`${user.firstName && getInitialSymbol(user.firstName)}${
                  user.lastName && getInitialSymbol(user.lastName)
                }`}
              </AvatarComponent>
            </StylesProvider>
            <ClickDropDown id="header-user-dropdown" title={user.firstName} mobileView={mobileView}>
              {shouldBeDisabled && currentRole === UserRoles.cohealer ? (
                <StyledDropDownItem className={'disableMe'} to={'#'}>
                  Account
                </StyledDropDownItem>
              ) : null}
              {!shouldBeDisabled || (shouldBeDisabled && currentRole != UserRoles.cohealer) ? (
                <StyledDropDownItem
                  className={shouldBeDisabled && currentRole === UserRoles.cohealer && 'disableMe'}
                  onClick={() => {
                    redirectToAppIfIsCoachDomain(domain, '/account/profile');
                  }}
                >
                  Account
                </StyledDropDownItem>
              ) : null}

              {(user?.connectedStripeAccountId || showCoachDashboard) && (
                <StyledDropDownItem
                  onClick={() => {
                    redirectToAppIfIsCoachDomain(domain, ROUTES.ROLE_SWITCH);
                  }}
                >
                  {currentRole === UserRoles.cohealer ? 'Client' : 'Coach'} Dashboard
                </StyledDropDownItem>
              )}
              <StyledDropDownItem
                onClick={() => {
                  redirectToAppIfIsCoachDomain(domain, '/logout');
                }}
              >
                Logout
              </StyledDropDownItem>
            </ClickDropDown>
            <Modal
              title="We are here for you!"
              applyTheming
              isOpen={supportPopup}
              submitTitle="Contact Support"
              onCancel={() => {
                setSupportPopup(false);
              }}
              onSubmit={() => {
                setSupportPopup(false);
                window.location.href = 'mailto:support@cohere.live';
              }}
              buttons={modalButtons}
            >
              <p>
                Have a service question? Tech problem? If so, we always have your back! Our service representatives
                respond within 2 business days. When you click the button below it will provide an email address to
                contact us.
              </p>

              <p>
                In the subject of your email, please write the type of issue or question. For example: “Tech Issue,”
                “Service Question,” or “Product Feedback.” If you have a tech issue, please also attach screen shots in
                the body of your email if possible.
              </p>

              <p>We are committed to ensuring that you have a great experience! Thank you for your partnership.</p>

              <p>The Cohere Team.</p>
              <StyledSupportLink href="mailto:support@cohere.live">support@cohere.live</StyledSupportLink>
            </Modal>
          </>
        ) : (
          !isEmpty(user) && !tabletView && <MockBlock />
        )}
      </StyledUserSectionContainer>
      {isLaunchPlan && trialPeriodStart.isSameOrAfter(TRIAL_PERIOD_START_DATE, 'day') ? (
        <>
          <FreeTrialModal
            isOpen={isOpen && !isOpenStartedGuide && !countrypopup}
            handleClose={handleSubmit}
            handleSubmit={handleSubmit}
          />
          {/* <GettingStartedGuide isOpen={isOpenStartedGuide && !isOpen && !countrypopup} closeHandler={closeHandler} /> */}
        </>
      ) : (
        <></>
        // <GettingStartedGuide isOpen={(isOpenStartedGuide || isOpen) && !countrypopup} closeHandler={closeHandler} />
      )}
      {countrypopup &&
        currentRole === UserRoles.cohealer &&
        pathname != '/account/billing/coach' &&
        pathname != '/account/billing/coach/extended' && <CountryModal isOpen={countrypopup} />}

      {!countrypopup && <SelectingPlanModal />}
    </StyledHaderContainer>
  );
}

Header.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
  noShadow: PropTypes.bool,
  // user: PropTypes.shape({
  //   firstName: PropTypes.string,
  //   lastName: PropTypes.string,
  //   avatarUrl: PropTypes.string,
  //   id: PropTypes.number,
  //   createTime: PropTypes.string,
  // }).isRequired,
  contribution: PropTypes.shape(),
  isCustomPrevButton: PropTypes.bool,
  backButtonText: PropTypes.string,
  children: PropTypes.node,
};

Header.defaultProps = {
  noShadow: false,
  contribution: null,
  isCustomPrevButton: false,
  backButtonText: '',
  children: null,
};

const actions = {
  getStartedToggle: userActions.getStartedToggle,
  fetchCountries: countryActions.fetchCountries,
};

const mapStateToProps = ({ account, page, contributions, country }) => ({
  user: account?.user || {},
  loading: account?.isLoading,
  title: page?.header,
  contribution: contributions.activeContribution,
  countries: country?.countries,
  countryLoading: country?.loading,
});

export default connect(mapStateToProps, actions)(Header);
