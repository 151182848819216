import styled, { css } from 'styled-components';
import { isObject } from 'lodash';
import Button from '@material-ui/core/Button';
import CustomButton from 'components/FormUI/Button';
import { ColorForDarkModeDisabled } from '../constants';

export const styleOverrideStyledFn = ({ styleOverrides }) =>
  isObject(styleOverrides) &&
  css`
    ${styleOverrides}
  `;

export const StyledMainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* min-height: calc(100vh - ${({ heightOffset }) => `${heightOffset}px`}); */
  > div {
    width: 100%;
  }
`;
export const TabsRow = styled.div`
  display: flex;
  /* width: 94.96356rem; */
  height: 4.5625rem;
  padding: 0rem 1.5rem;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${({ borderColor }) => borderColor || '#dfe3e4'};
  background: ${props => props.themedCardBackgroundColor || '#fff'};
  ${({ mobileView }) =>
    mobileView &&
    css`
      overflow-x: scroll;
    `};
  ${({ isSticky, top, themedCardBackgroundColor }) =>
    isSticky &&
    css`
      position: sticky;
      top: ${top};
      z-index: 19;
      /* background-color: ${themedCardBackgroundColor}; */
    `}
`;
export const ContentRow = styled.div`
  display: flex;
  flex-direction: ${({ mobileView }) => (mobileView ? 'column' : 'row')};
  align-items: flex-start;
  align-content: flex-start;
  flex-wrap: ${({ mobileView }) => (mobileView ? 'wrap' : 'nowrap')};
  ${({ mobileView }) =>
    mobileView
      ? css`
          /* gap: 1.25rem; */
          > div {
            width: 100%;
          }
        `
      : css`
          min-height: calc(100vh - ${({ heightOffset }) => `${heightOffset || 0}px`});
        `}
`;
export const ProgressBarContainer = styled.div`
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  gap: 0.4375rem;
  padding: 0 0.875rem;
  width: 100%;
`;
export const ProgressBarRow = styled.div`
  /* flex-direction: row; */

  display: flex;
  /* padding-bottom: 0.4375rem; */
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
`;
export const ProgressBarTitleText = styled.div`
  /* color: #116582; */
  color: ${({ textColor }) => textColor};
  font-family: Avenir;
  font-size: 1rem;
  font-style: normal;
  font-weight: 800;
  line-height: 1.5rem; /* 150% */
`;
export const FilterTabsContainer = styled.div`
  display: flex;
  flex-direction: row;
  /* align-items: flex-start; */
  justify-content: flex-start;
  align-items: center;
  gap: 0.625rem;
`;
export const FilterTabButton = styled(Button)`
  &.MuiButton-root {
    display: flex;
    width: 8.3125rem;
    height: 3rem;
    /* padding: 0.625rem 3.175rem 0.67813rem 3.175rem; */
    justify-content: center;
    align-items: center;
    gap: 0.27206rem;
    border-radius: 0.3125rem;
    border: 1px solid ${props => props.btnColor || '#cdba8f'};
    background: ${props => props.backgroundColor || 'var(--Cohere-Primary-White, #fff)'};
    .MuiButton-label {
      color: ${props => props.textColor || 'var(--Cohere-Secondary-Brown, #c9b382)'};
      text-align: center;
      /* font-family: Roboto; */
      font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
      font-size: 0.9375rem;
      font-style: normal;
      font-weight: 700;
      line-height: 1.64063rem; /* 175% */
      letter-spacing: 0.02681rem;
      text-transform: capitalize;
    }
  }
  &.MuiButton-root:hover {
    background-color: ${props => props.backgroundColor || 'var(--Cohere-Primary-White, #fff)'};
  }
`;
export const SelfPacedEngagementDataButton = styled(Button)`
  &.MuiButton-root {
    text-transform: capitalize;
    .MuiButton-label {
      /* color: #116582; */
      color: ${props => props.themedColor};
      text-align: center;
      font-family: Avenir;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 500;
      line-height: 1.53125rem; /* 175% */
      text-decoration-line: underline;
    }
  }
`;

export const DarkEnabledPrimaryFilledButton = styled(CustomButton)`
  /* color: red; */
  ${({ isDarkModeEnabled, disableBorder = true }) =>
    isDarkModeEnabled &&
    css`
      &:disabled {
        color: #9b9b9b;
        ${!disableBorder &&
        css`
          border: 1px solid #9b9b9b;
        `};
        background-color: ${ColorForDarkModeDisabled};
      }
    `}
  font-family: Avenir !important;
  font-size: 14px !important;
  font-weight: 900 !important;
  line-height: 16px !important;
  text-align: center !important;
  ${({ mobileView }) =>
    mobileView &&
    css`
      padding: 10px 16px !important;
      height: 48px !important;
    `}

  ${({ isOneOnOneExpansion }) =>
    isOneOnOneExpansion &&
    css`
      padding: 10px 16px !important;
    `}


  ${({ mobileViewCoachLaunch }) =>
    mobileViewCoachLaunch &&
    css`
      height: 48px !important;
      width: 100% !important;
      margin-top: 10px;
    `}
`;

export const DarkEnabledPrimaryOutlineButton = styled(CustomButton)`
  /* color: purple; */
  ${({ isDarkModeEnabled }) =>
    isDarkModeEnabled &&
    css`
      &:disabled {
        color: ${ColorForDarkModeDisabled};
        border: 1px solid ${ColorForDarkModeDisabled};
        background-color: ${'transparent' || '#252728'};
      }
    `}
`;
