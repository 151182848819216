import { makeStyles } from '@material-ui/core';
import styled from 'styled-components';

import { colors } from '../../../utils/styles';

export const useStyles = makeStyles(theme => ({
  root: {
    paddingBottom: 25,
  },

  descriptionContainer: {
    paddingBottom: 40,
    display: 'grid',
    gridAutoFlow: 'column',
    justifyContent: 'space-between',
    width: '100%',
    [theme.breakpoints.down(1050)]: {
      gridAutoFlow: 'row',
      justifyContent: 'center',
    },
    [theme.breakpoints.up(1050)]: {
      gridAutoFlow: 'row',
      // justifyContent: 'center',
    },
    [theme.breakpoints.down(500)]: {
      paddingBottom: 5,
    },
  },
  cardOne: {
    width: '92%',
    padding: ' 25px 30px 31px 25px',
    position: 'relative',
    background: '#ffffff',
    border: '0px',
    boxShadow: 'rgba(149,157,165,0.2) 0px 8px 24px',
    borderRadius: ' 0px',
  },

  advertismentCard: {
    padding: '20px !important',
    display: 'grid',
    gridAutoFlow: 'column',
    justifyItems: 'center',
    gap: 10,
    [theme.breakpoints.down(650)]: {
      padding: '0px !important',
      gridAutoFlow: 'row',
      maxWidth: 500,
      width: '100%',
    },
  },
  descriptionBlock: {
    display: 'grid',
    [theme.breakpoints.down('sm')]: {
      paddingBottom: 15,
    },
  },
  descriptionTitleMedia: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '22px',
      textAlign: 'center',
    },
  },
  descriptionAboutMedia: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
      textAlign: 'center',
    },
  },
  buttonMedia: {
    [theme.breakpoints.down('lg')]: {
      fontSize: '13px !important',
    },
  },
  updatePaymentMethod: {
    display: 'grid',
    justifyContent: 'center',
  },
  cohereAcademyContainer: {
    display: 'grid',
    width: '100%',
    gridTemplateColumns: '1fr 1fr 1fr',
    gridTemplateRows: '1fr',
    gap: 10,
    gridAutoFlow: 'column',
    justifyContent: 'space-between',
    justifyItems: 'center',
    [theme.breakpoints.down(1150)]: {
      gridAutoFlow: 'row',
      gridTemplateColumns: '1fr',
    },
  },
  cohereAcademy: {
    width: '100%',
    padding: 10,
    [theme.breakpoints.down(1150)]: {
      justifyContent: 'center',
    },
  },
  cohereAcademyLink: {
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  academyImage: {
    width: 480,
    height: 300,
    objectFit: 'cover',
    objectPosition: 'center',
    [theme.breakpoints.down(1600)]: {
      height: '175px',
      width: '300px',
    },
    [theme.breakpoints.down(1350)]: {
      height: '150px',
      width: '250px',
    },
    [theme.breakpoints.down(1100)]: {
      width: '225px',
      height: '115px',
    },
  },
  previewAcademyContainerPlan: {
    margin: '25px 15px 15px 0px',
    [theme.breakpoints.down(500)]: {
      display: 'grid',
      justifyContent: 'center',
    },
  },
  academyContainerPlan: {
    margin: '105px 15px 15px 0px',
    width: '100%',
    padding: '25px 30px 31px 25px',
    position: 'relative',
    background: '#ffffff',
    border: '0px',
    boxShadow: 'rgba(149,157,165,0.2) 0px 8px 24px',
    borderRadius: '0px',
    [theme.breakpoints.down(500)]: {
      display: 'grid',
      justifyContent: 'center',
    },
  },
  selectPlanButton: {
    border: 'none',
    borderRadius: 6,
    padding: '8px 38px',
  },
  enable: {
    color: colors.textMain,
    background: colors.backgroundLightGrey,
    transition: 'all 0.25s',
    '&:hover': {
      background: colors.backgroundGrey,
    },
  },
  disable: {
    color: colors.white,
    background: colors.darkOceanBlue,
    transition: 'all 0.25s',
    '&:hover': {
      background: colors.darkBlue,
    },
  },
  moreOptionsLink: {
    padding: '25px 0px 25px 0px',
    color: colors.darkOceanBlue,
  },
  disableMe: {
    opacity: 0.5,
    'pointer-events': 'none',
    cursor: 'not-allowed',
  },
  academyColumn: {
    // display: 'grid',
    // gridTemplateColumns: '1fr';
    // gridTemplateRows: '5fr 5fr';
  },
  bottom_Heading: {
    color: ' rgb(116, 166, 252)',
    padding: '20px 26px',
    borderRadius: '4px',
    justifyContent: 'left',
    backgroundColor: 'rgb(242, 245, 247)',
    marginTop: '20px',
  },
  expiresLabelMedia: {
    [theme.breakpoints.down(1500)]: {
      margin: '0px',
      fontSize: '12px !important',
    },
  },

  onePlanViewContainerMedia: {
    [theme.breakpoints.down(1200)]: {
      gridTemplateColumns: 'none !important',
    },
    [theme.breakpoints.down(500)]: {
      gridTemplateColumns: '1fr !important',
      gridGap: 15,
    },
  },
  paidTierExtendedMedia: {
    [theme.breakpoints.down(500)]: {
      gridTemplateColumns: 'none !important',
      gridTemplateRows: 'none !important',
    },
  },
  purchase_div: {
    [theme.breakpoints.down(500)]: {
      flexWrap: 'wrap',
    },
  },
  bottombilling: {
    display: 'flex',
    justifyContent: 'space-between',
    borderRadius: '4px',
    margin: '12px 0px 0px 0px',
    padding: '10px',
    backgroundColor: '#F2F5F7',
    color: '#74a6fc',
    [theme.breakpoints.down(500)]: {
      flexWrap: 'wrap',
    },
  },
  bottombuttonstyle: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down(500)]: {
      justifyContent: 'center',
      marginTop: '10px',
    },
  },
  bottomtextstyle: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down(500)]: {
      justifyContent: 'center',
      marginTop: '10px',
    },
  },
}));

export const PrimaryP = styled.p`
  color: ${colors.textMain};
  font-size: ${props => (props.textfont ? props.textfont : '26px')};
  //font-size: ${props => (props.textfont ? props.textfont : '18px')};
  padding: 0px 0px 0px 0px;
  margin: ${props => (props.margin ? props.margin : '0px')};
  text-align: ${props => (props.textAlign ? props.textAlign : '')};
  font-weight: ${props => (props.fontWeight ? props.fontWeight : 'lighter')};
  font-family: ${props => (props.fontFamily ? props.fontFamily : '')};
`;

export const SecondaryP = styled.p`
  color: ${colors.textSecondary};
  // font-size: ${props => (props.fontSize ? props.fontSize : '16px')};

  font-size: ${props => (props.fontSize ? props.fontSize : '16px')};
  padding: 0px 0px 0px 0px;
  margin: 0px;
  font-weight: ${props => (props.fontWeight ? props.fontWeight : 'lighter')};
`;

export const UpdatePaymentMethodButton = styled.button`
  border: none;
  font-size: 18px;
  font-weight: bolder;
  color: ${colors.white};
  background-color: ${colors.lightBrown};
  padding: 10px 15px;
  outline: none;
  transition: all 0.25s;

  &:hover {
    background-color: ${colors.darkGold};
  }
`;

export const PlansTitle = styled.h5`
  color: ${colors.textMain};

  & > span {
    color: ${colors.textSecondary};
  }
`;

export const ToggleSwitch = styled.label`
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;

  & > input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  & > span {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${colors.switch};
    transition: 0.4s;
  }
`;

export const CustomList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

export const CohereAcademyTitle = styled.h5`
  color: ${colors.textMain};
  font-size: 18px;
  font-weight: bolder;
`;

export const CohereAcademyP = styled.p`
  color: ${colors.textSecondary};
  font-weight: lighter;
  font-size: 12px;
`;

// One Plan Billing Page View

export const OnePlanViewContainer = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr;
`;

export const PlanColumnGrid = styled.div`
  // display: grid;
  grid-template-columns: 1fr;
  /* grid-template-rows: 1fr 1fr; */
  justify-items: center;
  grid-gap: 20px;
  padding: 0px 5px 0px 5px;
`;

export const PaidTierExtended = styled.div`
  display: grid;
  grid-template-columns: ${props => (props.columns ? props.columns : '1fr 1fr')};
  grid-template-rows: 1fr;
  margin-bottom: 15px;
`;

export const PlanColumn = styled.div`
  /* display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 2fr 8fr;
  grid-gap: 15px; */
  align-items: center;
  // padding: 0px 15px 0px 0px;
  padding: 0px 0px 0px 0px;

  ${({ mobileView }) =>
    !mobileView &&
    `
    & > div > h4 {
      width: 200%;
    }
  `}
`;

export const PlanCardContainer = styled.div`
  width: 100%;
  // padding: 18px 30px 31px 25px;
  padding: 24px 14px 24px 14px;
  position: relative;
  background: ${colors.white};

  // border: 1px solid ${colors.border};
  // border-radius: 8px;
  border-radius: 4px;

  margin-bottom: 25px;
`;

export const PriceField = styled.p`
  margin: 0px;
  padding-bottom: 4px;

  font-size: 24px;
  font-weight: bolder;

  & > span {
    font-size: 16px;
    font-weight: normal;
    color: ${colors.textSecondary};
  }
`;

export const FeeField = styled.p`
  margin: 0px;
  padding-bottom: 4px;
`;

export const ExpiresLabel = styled.p`
  color: ${colors.textSecondary};
  font-size: 14px;
  text-align: start;
`;

export const AdvantagesList = styled.ul`
  list-style: none;
  margin: 0px;
  padding: 5px 0px 5px 0px;
  align-self: self-start;

  & > li {
    padding-bottom: 5px !important;
  }
`;
