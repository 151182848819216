import { useState } from 'react';
import axiosInstance from 'utils/axiosInstance';

export const useVideoRoomStatus = () => {
  const [loading, setLoading] = useState(false);

  const getRoomStatus = async (classId, contributionId) => {
    const localHeaders = new Headers();
    setLoading(true);
    localHeaders.append('Content-Type', 'application/json');

    try {
      const response = await axiosInstance.request({
        url: `/Video/GetRoomStatus?contributionId=${contributionId}&classId=${classId}`,
        method: 'GET',
        headers: localHeaders,
      });
      const { data } = response;

      return data;
    } catch (e) {
      console.dir(e);
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    getRoomStatus,
  };
};
