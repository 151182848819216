/* eslint-disable no-unused-expressions */
import React, { useState } from 'react';
import { Mention, MentionsInput } from 'react-mentions';
import PropTypes from 'prop-types';
import { Grid, useMediaQuery, useTheme } from '@material-ui/core';
import UserMentionSuggestion from 'components/UI/mentions/UserMentionSuggestion';
import useContribution from 'pages/ContributionView/hooks/useContribution';
import { determineDarkThemedColorToUse, getThemedColors } from 'services/contributions.service';
import './UserMentionInput.scss';
import HashTagSuggestion from './HashTagSuggestion';

const UserMentionInput = ({
  users,
  onInputChange,
  defaultValue = '',
  placeholder = 'Type a message',
  name,
  resetInputValue,
  onResetInputValueCallback,
  hashTags,
}) => {
  const [messageText, setMessageText] = useState(defaultValue);

  if (resetInputValue === true) {
    onResetInputValueCallback();
    setTimeout(() => setMessageText(''), 100);
  }

  const contribution = useContribution();

  const { themedColor } = getThemedColors(contribution);
  const isDarkThemeEnabled = determineDarkThemedColorToUse(contribution);

  const handleOnChange = (e, newValue, newPlainTextValue, mentions) => {
    setMessageText(newValue);

    onInputChange && onInputChange(newPlainTextValue, mentions);
  };

  const theme = useTheme();
  const downXs = useMediaQuery(theme.breakpoints.down('xs'));

  const renderHashtags = () => {
    const updatedHashTags = hashTags?.map(x => ({
      display: x.toString(),
      id: x.toString(),
    }));

    return (
      <Mention
        renderSuggestion={entry => <HashTagSuggestion user={entry} />}
        trigger="#"
        className={`mentions__mention ${isDarkThemeEnabled ? 'mentions__mention_dark' : ''}`}
        data={updatedHashTags}
        displayTransform={(id, display) => `${display}`}
      />
    );
  };

  return (
    <Grid style={{ color: themedColor }} className={downXs ? 'width-limiter' : ''}>
      <MentionsInput
        name={name}
        className="mentions"
        value={messageText?.trimStart()}
        placeholder={placeholder}
        allowSuggestionsAboveCursor
        forceSuggestionsAboveCursor
        onChange={handleOnChange}
        allowSpaceInQuery
      >
        <Mention
          renderSuggestion={entry => <UserMentionSuggestion user={entry} />}
          trigger="@"
          className={`mentions__mention ${isDarkThemeEnabled ? 'mentions__mention_dark' : ''}`}
          data={search => users?.filter(user => user?.display?.toLowerCase().includes(search?.toLowerCase()))}
          displayTransform={(id, display) => `${display}`}
        />
        {renderHashtags()}
      </MentionsInput>
    </Grid>
  );
};

UserMentionInput.propTypes = {
  users: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      display: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onInputChange: PropTypes.func,
  onResetInputValueCallback: PropTypes.func,
  resetInputValue: PropTypes.bool,
  defaultValue: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string,
};

export default UserMentionInput;
