import React, { useState, useEffect } from 'react';
import useAccount from 'hooks/useAccount';
import LoginQuizPage from 'pages/QuizViewPage/Pages/LoginPage/LoginPage';
import ClientQuizPage from 'pages/QuizViewPage/Pages/QuizPage/QuizPage';
import ResultPage from 'pages/QuizViewPage/Pages/ResultPage/ResultPage';

import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from 'react-router-dom/cjs/react-router-dom.min';
import { getClientQuizFormById } from 'pages/CreateQuizes/Services/CreateQuizes.service';
import Loader from 'components/UI/Loader';
import { setActiveQuiz } from 'actions/quizes';
import ThankYouPage from 'pages/QuizViewPage/Pages/ThankYouPage/ThankYouPage';
import { isEmpty } from 'lodash';
import useRouter from 'hooks/useRouter';
function QuizViewContainer({ match: { path } }) {
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const { pathname, query } = useRouter();
  const { id } = useParams();

  const dispatch = useDispatch();
  const { isGuest } = useAccount();
  useEffect(() => {
    if (id && id != 'result') {
      setLoading(true);
      getClientQuizFormById(id)
        .then(res => {
          dispatch(setActiveQuiz(res));
          setLoading(false);
        })
        .catch(error => {
          setLoading(false);
        });
    }
  }, [id]);
  const { activeQuiz, userDetails } = useSelector(state => state?.quizes);
  // const { id } = useParams();
  useEffect(() => {
    if (isEmpty(userDetails) && id) {
      history.replace(
        activeQuiz.type === 'Form' || pathname.includes('/form-view/') ? `/form-view/${id}` : `/quiz-view/${id}`,
      );
    }
  }, [userDetails]);

  if (id && loading) {
    return <Loader />;
  }
  return (
    <>
      {isGuest ? (
        <Switch>
          <Route path={`${path}/login`} exact component={LoginQuizPage} />
          <Route path={`${path}/:id?/quiz`} exact component={ClientQuizPage} />
          <Route path={`${path}/:id?/result/:attemptId?/:leadId?`} exact component={ResultPage} />
          <Route path={`${path}/thankyou`} exact component={ThankYouPage} />
          <Redirect to={`${path}/login`} />
        </Switch>
      ) : (
        <Switch>
          <Route path={`${path}/quiz`} exact component={ClientQuizPage} />
          <Route path={`${path}/:id?/result/:attemptId?/:leadId?`} exact component={ResultPage} />
          <Route path={`${path}/thankyou`} exact component={ThankYouPage} />
          <Redirect to={`${path}/quiz`} />
        </Switch>
      )}
    </>
  );
}
export default QuizViewContainer;
