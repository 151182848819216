import { useMemo, useRef } from 'react';
import useContribution from './useContribution';
import isEqual from 'react-fast-compare';
import { useSelector } from 'react-redux';
import { activeContribSessTabViewSettingsSelector } from 'selectors/contribution';

const useActiveContributionSessionById = () => {
  const activeContribution = useContribution();
  const { selectedSessionId } = useSelector(activeContribSessTabViewSettingsSelector);
  if (!activeContribution || !selectedSessionId) {
    return null;
  }
  const inx = activeContribution?.sessions?.findIndex(k => k?.id === selectedSessionId);
  return {
    selectedSessionIndex: inx,
    selectedSession: activeContribution?.sessions?.[inx],
  };
  // const prev = useRef(null);
  // const res = {
  //   selectedSession: activeContribution?.sessions?.find(k => k.id === sessionId),
  //   selectedSessionIndex: activeContribution?.sessions?.findIndex(k => k.id === sessionId),
  // };
  // const resMemo = useMemo(() => {
  //   if (isEqual(res, prev.current)) {
  //     return prev.current;
  //   }
  //   prev.current = res;
  //   return res;
  // }, [activeContribution]);

  // if (!sessionId || !activeContribution) {
  //   return null;
  // }
  // return resMemo;
};

export default useActiveContributionSessionById;
