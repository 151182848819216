import React from 'react';
import styled, { css } from 'styled-components';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { CancelPresentationIcon } from 'pages/ContributionView/ContributionSessions/component/icons';
import DownloadIcon from '@mui/icons-material/Download';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useContribution } from 'pages/ContributionView/hooks';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { determineColorToUse, getThemedColors } from 'services/contributions.service';
import useAccount from 'hooks/useAccount';
import { lightOrDark } from 'utils/utils';

const StyledToolBar = styled.div`
  display: flex;
  width: 100%;
  min-height: 3.125rem;
  padding: 0.625rem 1.25rem;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  background: ${({ backgroundColor }) => backgroundColor || '#f7f7f7'};
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  ${({ mobileView }) =>
    mobileView &&
    css`
      flex-wrap: wrap;
      gap: 1.125rem;
    `}
`;

const StyledNameContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  max-width: 40%;
  ${({ mobileView }) =>
    mobileView &&
    css`
      order: 1;
    `}
`;
const StyledName = styled.div`
  color: ${({ textColor }) => textColor || 'var(--Cohere-Greys-Text, #4a4a4a)'};
  font-family: Avenir;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;
const StyledZoomPageNavigator = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 76px;
  ${({ mobileView }) =>
    mobileView &&
    css`
      gap: 100px;
      order: 3;
      width: 100%;
      justify-content: space-between;
    `}
`;

const StyledPageNavigator = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
`;

const StyledZoomablePager = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
`;
const StyledDownloadIcon = styled(DownloadIcon)`
  cursor: pointer;
  font-size: 24px;
  ${({ mobileView }) =>
    mobileView &&
    css`
      order: 2;
    `}
`;
const StyledPercentText = styled.div`
  color: ${({ textColor }) => textColor || 'var(--Cohere-Greys-Text, #4a4a4a)'};
  font-family: Poppins;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;
const StyledPercentTextWithBackground = styled(StyledPercentText)`
  padding: 0.3125rem 0.9375rem;
  background: #c8c8c8;
`;
const getPlusMinusButtonStyles = params => {
  const { textColor } = params;
  // Note: it's not accessible here, need to verify and find cause
  console.info('color:', textColor);
  return css`
    &.MuiSvgIcon-root {
      /* color: ${textColor || 'var(--Cohere-Greys-Text, #4a4a4a)'}; */
      font-family: Poppins;
      font-size: 1rem;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      cursor: pointer;
    }
  `;
};
const StyledAddIcon = styled(AddIcon)`
  ${getPlusMinusButtonStyles(props => props)}
  &.MuiSvgIcon-root {
    color: ${({ textColor }) => textColor || 'var(--Cohere-Greys-Text, #4a4a4a)'};
  }
`;
const StyledRemoveIcon = styled(RemoveIcon)`
  ${getPlusMinusButtonStyles(props => props)}
  &.MuiSvgIcon-root {
    color: ${({ textColor }) => textColor || 'var(--Cohere-Greys-Text, #4a4a4a)'};
  }
`;

const CustomToolBar = ({
  setIsViewDocument,
  fileName,
  currentPage,
  setCurrentPage,
  totalPages,
  scalePercent,
  handleZoomIn,
  handleZoomOut,
  zoomInDisable,
  zoomOutDisable,
  handleDownload,
  containerRef,
}) => {
  const { isCoach } = useAccount();
  const contribution = useContribution();
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const { themedColor, newThemedTextColor } = getThemedColors(contribution);
  let colorToUse = determineColorToUse(contribution);
  let textColor2 =
    colorToUse?.TextColorCode === 'Auto'
      ? lightOrDark(colorToUse?.PrimaryColorCode)
      : colorToUse?.TextColorCode === '#000000'
      ? '#000000'
      : '#FFFFFF';

  const textColor = isCoach ? themedColor : newThemedTextColor;
  const goToPrevPage = () => {
    if (currentPage > 1) {
      const previousPageHeight = containerRef.current.scrollHeight / totalPages;
      containerRef.current.scrollTop = previousPageHeight * (currentPage - 1);
      setCurrentPage(currentPage - 1);
    }
  };

  const goToNextPage = () => {
    if (currentPage < totalPages) {
      const nextPageHeight = containerRef.current.scrollHeight / totalPages;
      containerRef.current.scrollTop = nextPageHeight * currentPage;
      setCurrentPage(currentPage + 1);
    }
  };
  return (
    <StyledToolBar
      backgroundColor={contribution?.isDarkModeEnabled ? 'transparent' : null}
      {...{
        mobileView,
      }}
    >
      <StyledNameContainer {...{ mobileView }}>
        <ArrowBackIosNewIcon
          onClick={() => {
            setIsViewDocument(false);
          }}
          style={{ cursor: 'pointer', fontSize: '20px' }}
        />
        <StyledName {...{ textColor }}>{fileName}</StyledName>
      </StyledNameContainer>
      <StyledZoomPageNavigator {...{ mobileView }}>
        <StyledPageNavigator>
          <StyledPercentTextWithBackground>{currentPage}</StyledPercentTextWithBackground>
          <StyledPercentText {...{ textColor }}>/ {totalPages}</StyledPercentText>
        </StyledPageNavigator>
        <StyledZoomablePager>
          <StyledRemoveIcon {...{ textColor }} onClick={handleZoomIn} disable={zoomInDisable} />
          <StyledPercentTextWithBackground>{scalePercent}%</StyledPercentTextWithBackground>
          <StyledAddIcon {...{ textColor }} onClick={handleZoomOut} disable={zoomOutDisable} />
        </StyledZoomablePager>
        {/* <div style={{display:'flex', flexDirection:'column', gap:'2px', alignItems:'center', justifyItems:'center', border:'2px solid #4A4A4A'}}>
            <ArrowDropUpIcon onClick={goToPrevPage} style={{ cursor: 'pointer', fontSize: '16px' }} disabled={currentPage <= 1}/>
            <ArrowDropDownIcon onClick={goToNextPage} style={{ cursor: 'pointer', fontSize: '16px' }} disabled={currentPage >= totalPages}/>
        </div> */}
        {/* <CancelPresentationIcon fillColor="#4A4A4A" /> */}
      </StyledZoomPageNavigator>
      <StyledDownloadIcon onClick={handleDownload} {...{ mobileView }} />
    </StyledToolBar>
  );
};

export default CustomToolBar;
