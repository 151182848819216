import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

const MoreOptions = ({ className, onRemove, onSetAsDefault, onClosed }) => {
  const anchorRef = useRef();
  const [isOpen, setIsOpen] = useState(false);

  const onClose = () => setIsOpen(false);

  return (
    <>
      <IconButton
        ref={anchorRef}
        className={className}
        onClick={e => {
          e.stopPropagation();
          setIsOpen(true);
        }}
        color="inherit"
      >
        <MoreHorizIcon />
      </IconButton>
      <Menu
        anchorEl={anchorRef.current}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={isOpen}
        onClose={onClose}
        onClick={e => e.stopPropagation()}
        onExited={onClosed}
      >
        <MenuItem
          onClick={e => {
            e.stopPropagation();
            onSetAsDefault();
            onClose();
          }}
        >
          Make default
        </MenuItem>
        <MenuItem
          style={{ color: '#cd3d64' }}
          onClick={e => {
            e.stopPropagation();
            onRemove();
            onClose();
          }}
        >
          Remove
        </MenuItem>
      </Menu>
    </>
  );
};

MoreOptions.propTypes = {
  className: PropTypes.string.isRequired,
  onSetAsDefault: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onClosed: PropTypes.func.isRequired,
};

export default MoreOptions;
