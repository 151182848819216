import React, { useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import { useHeader, useRouter } from 'hooks';
import Loader from 'components/UI/Loader';
import ApplicationsTable from 'components/Tables/ApplicationResponses/Applications';
import { fetchApplications, fetchApplicationForms } from 'actions/applications';
import Button from 'components/FormUI/Button';
import CreateApplicationFormModal from 'pages/ContributionView/components/ContributionHeader/ApplicationForm/CreateApplicationFormModal';
import './ApplicationPage.scss';
import { APPLICATION_LIMIT } from 'services/applications.service';
import { fetchContributionActions } from 'actions/contributions';
import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import moment from 'moment';
import * as paidTier from 'selectors/paidTier';
import useShallowEqualSelector from '../../hooks/useShallowEqualSelector';
import { PAID_TIER_TITLES } from '../../constants';
import useAccount from 'hooks/useAccount';
import { TRIAL_PERIOD_DAYS, TRIAL_PERIOD_START_DATE } from 'components/Tables/Contribution';
import { getCohealerContribution, shareViaEmail } from 'services/contributions.service';
import ShareModal from 'pages/ContributionView/components/ShareModal/ShareModalContainer';
import Modal from 'components/UI/Modal';

import ApplicationSelectionModal from 'pages/AllFormsPage/components/ApplicationSelectionModal';
import ApplicationTemplateModal from 'pages/AllFormsPage/components/ApplicationTemplateModal';

const AllApplicationsPage = ({ applications, getApplications, forms, getForms, loading }) => {
  useHeader('Applications');
  const { pathname, history } = useRouter();
  const [showApplicationFormPopup, setShowApplicationFormPopup] = useState(false);
  const [showSelectionModal, setShowSelectionModal] = useState(false);
  const [showTemplateModal, setShowTemplateModal] = useState(false);
  const [modalType, setModalType] = useState(null);
  const [template, setTemplate] = useState(null);
  const [quizFilterType, setQuizFilterType] = useState('All');
  const [fetchChanges, setFetchChanges] = useState(true);
  const [offset, setOffset] = useState(0);
  const [formQuizesData, setformQuizesData] = useState(null);
  const [value, setValue] = React.useState('Applications');
  const [showApplicationCreatedPopup, setShowApplicationCreatedPopup] = useState(false);
  const { user } = useAccount();
  const trialPeriodStart = moment(user.createTime);
  const trialPeriodEnd = moment(user.createTime).add(TRIAL_PERIOD_DAYS, 'days');
  const activePaidTierCurrentPlan = useShallowEqualSelector(paidTier.activePaidTierCurrentPlanSelector);
  const isLaunchPlan = activePaidTierCurrentPlan?.displayName === PAID_TIER_TITLES.launch;
  const todayDate = moment(moment());
  const [openingModal, setOpeningModal] = useState(false);
  const [contributionId, setContributionId] = useState(null);
  const [contribution, setContribution] = useState(null);
  const [modalOpened, setModalOpened] = useState(false);
  const [emailsError, setEmailsError] = useState({
    isError: false,
    errorMessage: null,
  });
  useEffect(() => {
    if (pathname.includes('pending')) {
      setQuizFilterType('Pending');
    } else if (pathname.includes('approved')) {
      setQuizFilterType('Approved');
    } else if (pathname.includes('rejected')) {
      setQuizFilterType('Rejected');
    } else {
      setQuizFilterType('All');
    }
  }, []);

  const [isLoading, setLoading] = useState(false);
  const handleModalOpen = () => {
    setLoading(true);
    if (
      isLaunchPlan &&
      trialPeriodStart.isSameOrAfter(TRIAL_PERIOD_START_DATE, 'day') &&
      todayDate.isAfter(trialPeriodEnd)
    ) {
      return;
    }
    setOpeningModal(true);
    getCohealerContribution(contributionId)
      .then(contrib => {
        setContribution(contrib);
        setShowApplicationCreatedPopup(false);
        setModalOpened(true);
      })
      .finally(() => {
        setOpeningModal(false);
        setLoading(false);
      });
  };

  const handleModalClose = useCallback(() => {
    setModalOpened(false);
  }, [setModalOpened]);

  const handleModalSubmit = useCallback(
    emails => {
      shareViaEmail({ contributionId, emailAddresses: emails })
        .then(() => {
          handleModalClose();
        })
        .catch(err => {
          setEmailsError({ isError: true, errorMessage: err?.response?.data?.message });
        });
    },
    [handleModalClose],
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchContributionActions.success(null));
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;
      const scrollPosition = window.scrollY;
      if (scrollPosition + windowHeight + 100 > documentHeight && offset + APPLICATION_LIMIT === applications.length) {
        setOffset(offset + APPLICATION_LIMIT);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [offset, applications]);

  const getPath = useCallback(() => {
    if (pathname.includes('pending')) return 'pending';
    if (pathname.includes('rejected')) return 'rejected';
    if (pathname.includes('approved')) return 'approved';
    return 'all';
  }, [pathname]);

  const renderList = useCallback(async () => {
    getApplications({ status: getPath(), offset });
  }, [getApplications, getPath, offset]);

  useEffect(() => {
    setOffset(0);
  }, [pathname]);

  useEffect(() => {
    renderList();
  }, [renderList]);

  const renderForm = useCallback(async () => {
    getForms();
  }, [getForms]);

  useEffect(() => {
    renderForm();
  }, [renderForm]);
  if (isLoading && !applications?.length) {
    return <Loader />;
  }
  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', display: 'flex', width: '100%' }}>
        {['Applications'].map((item, index) => {
          return (
            <div
              style={{
                borderBottom: item === value ? `3px solid #215C73` : 'none',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                marginRight: '20px',
              }}
            >
              <Button
                variant="contained"
                value={item}
                style={{
                  backgroundColor: 'transparent',
                  display: 'flex',
                  justifyContent: 'center',
                  fontSize: '18px',
                  fontWeight: '400',
                  color: '#000000',
                  minWidth: 'auto',
                  fontFamily: 'Avenir',
                  alignItems: 'center',
                  gap: '5px',
                  padding: '1rem 0px',
                }}
                onClick={e => {
                  setformQuizesData(null);
                  setValue(item);
                }}
              >
                {item}
              </Button>
            </div>
          );
        })}
      </Box>
      <div className="quiz_btns_container">
        <div style={{ display: 'flex', flexDirection: 'row', gap: 10 }}>
          {['All', 'Pending', 'Approved', 'Rejected'].map((item, index) => {
            return (
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '15px' }}>
                <Button
                  variant="contained"
                  value={item}
                  style={
                    quizFilterType === item
                      ? {
                          backgroundColor: '#CDBA8F',
                          display: 'flex',
                          justifyContent: 'center',
                          fontSize: '15px',
                          fontWeight: '800',
                          color: 'white',
                          minWidth: 'auto',
                          fontFamily: 'Avenir',
                          alignItems: 'center',
                          gap: '5px',
                          letterSpacing: '0px',
                        }
                      : {
                          backgroundColor: 'transparent',
                          display: 'flex',
                          justifyContent: 'center',
                          fontSize: '15px',
                          fontWeight: '800',
                          color: '#CDBA8F',
                          minWidth: 'auto',
                          fontFamily: 'Avenir',
                          alignItems: 'center',
                          gap: '5px',
                          border: '1px solid  #CDBA8F',
                          letterSpacing: '0px',
                        }
                  }
                  onClick={e => {
                    setQuizFilterType(item);
                    history.push(`/applications/${item.toLowerCase()}`);
                  }}
                >
                  {item}
                </Button>
              </div>
            );
          })}
        </div>
        <Button
          style={{
            marginBottom: '10px',
            minWidth: 'unset',
            float: 'right',
            borderRadius: '5px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '10px',
          }}
          onClick={() => setShowSelectionModal(true)}
        >
          {/*  <span
            style={{
              display: 'flex',
              border: '2px solid white',
              borderRadius: 10000,
              marginRight: '5px',
              justifyContent: 'center',
              alignItems: 'center',
              height: 20,
              width: 20,
            }}
          >
            <AddIcon style={{ height: 15, width: 15 }} />
          </span> */}
          Create Application
        </Button>
      </div>

      {applications.length ? <ApplicationsTable rows={applications} /> : <div>There are no applications yet.</div>}
      {(isLoading || loading) && <Loader />}

      {showSelectionModal && (
        <ApplicationSelectionModal
          isOpen
          onClose={() => {
            setShowSelectionModal(false);
            setTemplate(null);
            setModalType(null);
          }}
          onSubmit={type => {
            if (type === 'new') {
              setShowApplicationFormPopup(true);
            } else {
              setShowTemplateModal(true);
            }
            setModalType(type);
            setShowSelectionModal(false);
          }}
        />
      )}
      {showTemplateModal && (
        <ApplicationTemplateModal
          isOpen={showTemplateModal}
          forms={forms}
          onClose={() => {
            setTemplate(null);
            setShowTemplateModal(false);
          }}
          onBack={() => {
            setTemplate(null);
            setShowTemplateModal(false);
            setShowSelectionModal(true);
          }}
          onSubmit={formTemplate => {
            setShowTemplateModal(false);
            setShowApplicationFormPopup(true);
            setTemplate(formTemplate);
          }}
        />
      )}
      {showApplicationFormPopup && (
        <CreateApplicationFormModal
          showApplicationFormPopup={showApplicationFormPopup}
          setShowApplicationFormPopup={setShowApplicationFormPopup}
          template={template}
          setTemplate={setTemplate}
          forms={forms}
          onCancel={() => {
            setTemplate(null);
          }}
          onSuccess={() => {
            setFetchChanges(true);
            getForms();
            setShowApplicationCreatedPopup(true);
          }}
          onBack={() => {
            if (modalType === 'new') {
              setShowSelectionModal(true);
            }
            if (modalType === 'template') {
              setShowTemplateModal(true);
            }
            setTemplate(null);
            setShowApplicationFormPopup(false);
          }}
          modalType={modalType}
          setContributionId={setContributionId}
        />
      )}
      {showApplicationCreatedPopup && (
        <Modal
          isOpen={showApplicationCreatedPopup}
          applyTheming
          title="Confirmation"
          className="app-form-created-success"
          onCancel={() => {
            setLoading(true);
            setShowApplicationCreatedPopup(false);
            setLoading(false);
          }}
          onSubmit={handleModalOpen}
          submitTitle="Invite"
        >
          <div>Your application is successfully created</div>
        </Modal>
      )}
      {modalOpened && (
        <ShareModal
          isOpen={modalOpened}
          onSubmit={handleModalSubmit}
          onModalClose={() => setModalOpened(false)}
          emailsError={emailsError}
          setEmailsError={setEmailsError}
          contributionId={contributionId}
          paymentInfo={contribution?.paymentInfo || {}}
          type={contribution?.type || ''}
          isInvoiced={contribution?.isInvoiced}
          symbol={contribution?.defaultSymbol || '$'}
        />
      )}
    </>
  );
};
const mapStateToProps = ({
  applications: { forms, applications, isLoading },
  upcomingCreated: { error },
  account,
}) => ({
  userId: account?.user?.id,
  loading: isLoading,
  error,
  applications: applications || [],
  forms: forms || [],
});
const actions = {
  getApplications: fetchApplications,
  getForms: fetchApplicationForms,
};
AllApplicationsPage.propTypes = {
  loading: PropTypes.bool.isRequired,
  applications: PropTypes.arrayOf(PropTypes.object).isRequired,
  getApplications: PropTypes.func.isRequired,
};
export default connect(mapStateToProps, actions)(AllApplicationsPage);
