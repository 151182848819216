import React, { useState } from 'react';
import classes from './Row.module.scss';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import withStyles from '@material-ui/core/styles/withStyles';
import { Link } from 'react-router-dom';
import SettingsIcon from '@mui/icons-material/Settings';
import CloseIcon from '@material-ui/icons/Close';
import styled from 'styled-components';
import { Spinner } from 'react-activity';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useDispatch } from 'react-redux';
import { setDomainInAuthProcess } from 'actions/emailMarketing.actions';
import DomainAuthenticationPopUp from 'pages/Account/DomainAuthenticationPage/DomainPopUp';

const NarrowTableCell = withStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingTop: 15,
    paddingBottom: 15,
  },
})(TableCell);

const CalendarInfoWrapper = styled.div`
  padding: '20px';
  display: flex;
  align-items: center;
  cursor: ${props => (props.isDefault ? 'pointer' : 'default')};
`;

const CalendarRecordActionsColumn = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  svg {
    cursor: pointer;
  }
`;

const StyledCloseIcon = styled(CloseIcon)`
  cursor: pointer;
`;

const StyledLink = styled(Link)`
  cursor: pointer;
  font-family: Avenir;
  font-size: 14px;
  font-weight: 800;
  line-height: 32px;
  color: #215c73;
  &:hover {
    color: #215c73;
  }
`;

const StyledEmail = styled.div`
  font-family: Avenir;
  font-size: 16px;
  font-weight: 500;
  line-height: 32px;
  color: background: rgba(0, 0, 0, 0.87);
`;

const StyledDomain = styled.div`
  font-family: Avenir;
  font-size: 18px;
  font-weight: 800;
  line-height: 32px;
  color: background: rgba(0, 0, 0, 0.87);
`;

const VerifiedEmail = styled.div`
  font-family: Avenir;
  font-size: 11px;
  font-weight: 800;
  line-height: 16px;
  color: #ffffff;
  background-color: #215c73;
  border-radius: 4px;
  padding: 2px 6px;
`;

const VerifiedDomain = styled.div`
  font-family: Avenir;
  font-size: 11px;
  font-weight: 800;
  line-height: 16px;
  color: #ffffff;
  background-color: #215c73;
  border-radius: 4px;
  padding: 2px 6px;
`;

const IncompleteDomain = styled.div`
  font-family: Avenir;
  font-size: 11px;
  font-weight: 800;
  line-height: 16px;
  color: #ffffff;
  background-color: #868686;
  border-radius: 4px;
  padding: 2px 6px;
`;

const DefaultEmail = styled.div`
  font-family: Avenir;
  font-size: 11px;
  font-weight: 800;
  line-height: 16px;
  color: #ffffff;
  background-color: #c9b382;
  border-radius: 4px;
  padding: 2px 6px;
`;

const NotVerifiedEmail = styled.div`
  font-family: Avenir;
  font-size: 11px;
  font-weight: 800;
  line-height: 16px;
  color: #215c73;
  background-color: white;
  border-radius: 4px;
  border: 2px solid #215c73;
  padding: 2px 6px;
`;

const Row = ({
  key,
  item = {},
  isLoading = false,
  handleCrossClick = () => {},
  handleSettingClick = () => {},
  resendClickHandle = () => {},
  tableRow = false,
  type = 'domain',
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { email, domain, subDomain, isDefault, isVerified } = item;
  const isDomain = type === 'domain';
  const isRowClickable = type === 'domain' && !isVerified;
  const [showModal, setShowModal] = useState(false);
  const handleRowClick = () => {
    dispatch(setDomainInAuthProcess(domain));
    history.push('/account/integrations/authenticate-domain');
  };
  const handleRowClickVerified = () => {
    dispatch(setDomainInAuthProcess(domain));
    setShowModal(true);
    // history.push('/account/integrations/authenticate-domain');
  };

  const handleCloseModal = () => {
    dispatch(setDomainInAuthProcess(null));
    setShowModal(false);
  };

  return (
    <>
      {tableRow ? (
        <TableRow
          key={key}
          className="row"
          style={{ cursor: 'pointer' }}
          onClick={isRowClickable ? handleRowClick : type === 'domain' ? handleRowClickVerified : () => {}}
        >
          <NarrowTableCell
            component="th"
            scope="row"
            style={
              isDomain
                ? { minHeight: 78.17, borderTop: '1px solid rgba(224, 224, 224, 1)' }
                : { minHeight: 78.17, borderBottom: 'transparent' }
            }
          >
            <CalendarInfoWrapper
              onClick={() => {
                if (!isDefault) {
                  // handleclick(emailAddress);
                }
              }}
              isDefault={!isVerified ? true : false}
            >
              <span
                className="ml-2"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '10px',
                }}
              >
                {type === 'domain' ? <StyledDomain>{domain}</StyledDomain> : <StyledEmail>{email}</StyledEmail>}
                {isVerified && type === 'domain' && <VerifiedDomain>Verified</VerifiedDomain>}
                {!isVerified && type === 'domain' && <IncompleteDomain>Incomplete</IncompleteDomain>}
                {isDefault && !isDomain && <DefaultEmail>Default</DefaultEmail>}
              </span>
            </CalendarInfoWrapper>
            <CalendarRecordActionsColumn>
              <>
                {/* {!isVerified && !isDomain && (
                    <StyledLink
                      to="#"
                      onClick={() => {
                        resendClickHandle(item);
                      }}
                    >
                      Resend Email
                    </StyledLink>
                  )} */}
                {!isDomain && (
                  <SettingsIcon
                    onClick={() => {
                      handleSettingClick(item);
                    }}
                  />
                )}
                {
                  <StyledCloseIcon
                    onClick={e => {
                      e.stopPropagation();
                      const data = isDomain ? domain : email;
                      handleCrossClick(data);
                    }}
                  />
                }
              </>
            </CalendarRecordActionsColumn>
          </NarrowTableCell>
        </TableRow>
      ) : (
        <div className={classes.row}>
          <div className={classes.row_item}>
            <CalendarInfoWrapper
              onClick={() => {
                if (!isDefault) {
                  // handleclick(emailAddress);
                }
              }}
              isDefault={isDefault}
            >
              <span
                className="ml-2"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '10px',
                }}
              >
                <StyledEmail>{email}</StyledEmail>
                {isVerified && <VerifiedEmail>Verified</VerifiedEmail>}
                {!isVerified && <NotVerifiedEmail>Non-Verified</NotVerifiedEmail>}
                {isDefault && <DefaultEmail>Default</DefaultEmail>}
              </span>
            </CalendarInfoWrapper>
          </div>
          <div className={classes.row_item}>
            <CalendarRecordActionsColumn>
              {isLoading === false ? (
                <>
                  {!isVerified && (
                    <StyledLink
                      to="#"
                      onClick={() => {
                        resendClickHandle(item);
                      }}
                    >
                      Resend Email
                    </StyledLink>
                  )}
                  <SettingsIcon
                    onClick={() => {
                      handleSettingClick(item);
                    }}
                  />
                  <StyledCloseIcon
                    onClick={() => {
                      handleCrossClick(email);
                    }}
                  />
                </>
              ) : (
                <Spinner color="#116582" size={20} />
              )}
            </CalendarRecordActionsColumn>
          </div>
        </div>
      )}
      <DomainAuthenticationPopUp showModal={showModal} handleCloseModal={handleCloseModal} />
    </>
  );
};

export default Row;
