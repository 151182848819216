import React from 'react'
import { useSelector } from 'react-redux'

import { TestVideoChartContainer } from 'components/UI/VideoChat/VideoChat/TestVideoChat/TestVideoChartContainer'
import { showTestVideoChatSelector } from 'reducers/testVideoChart'

export const TestVideoChat = () => {
  const show = useSelector(showTestVideoChatSelector)
  return show ? <TestVideoChartContainer /> : null
}
