import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import Loader from 'components/UI/Loader';

import {
  BasicInformationLeadMagnetPage as BasicInformationPage,
  ContributionDetailsLeadMagnetPage as ContributionDetailsPage,
  ReviewAndSubmitLeadMagnetPage as ReviewAndSubmitPage,
  SessionsLeadMagnetPage as ContentPage,
  PaymentLeadMagnetPage as PaymentPage,
} from 'pages/CreateLeadMagnet';
import { useHeader } from 'hooks';
import * as contributionActions from 'actions/contributions';
import * as leadMagnetActions from 'actions/leadMagnets';
import * as oneToOneDataActions from 'actions/oneToOneData';
import ThankYouPreviewPage from './ThankYouPreviewPage';
import { startIncrementDurationsOneToOne } from 'pages/CreateContribution/components/OneToOneForm';

function EditLeadMagnetContainer({
  match: { path, params },
  fetchCohealerLeadMagnet,
  leadMagnet,
  updateOneToOneData,
  updating,
  loading,
  // getEditContributionTimes,
  resetLeadMagnet,
}) {
  useHeader('Edit Lead Magnet');
  const { id } = params;

  useEffect(() => {
    fetchCohealerLeadMagnet(id);
  }, [
    id,
    fetchCohealerLeadMagnet,
    //, getEditContributionTimes
  ]);

  // useEffect(() => {
  //   if (contribution?.oneToOneSessionDataUi) {
  //     const { oneToOneSessionDataUi } = contribution;
  //     getEditContributionTimes(id, contribution?.oneToOneSessionDataUi, true);
  //   }
  // }, [getEditContributionTimes, contribution?.oneToOneSessionDataUi, id]);

  // useEffect(() => {
  //   if (contribution?.oneToOneSessionDataUi) {
  //     const preparedData = {
  //       ...contribution?.oneToOneSessionDataUi,
  //       ...{
  //         startTimeIncrementDuration: contribution?.sessionIncrements?.[0] || 0,
  //       },
  //     };
  //     const updatedWeeks = preparedData.selectedWeeks.map(week => {
  //       return {
  //         ...week,
  //         startTime: moment.utc(week.startTime),
  //         endTime: moment.utc(week.endTime),
  //       };
  //     });
  //     preparedData.selectedWeeks = updatedWeeks;
  //     updateOneToOneData(preparedData);
  //   }
  // }, [contribution?.oneToOneSessionDataUi, updateOneToOneData]);

  useEffect(() => {
    console.info('active:', leadMagnet);
    return () => {
      resetLeadMagnet();
    };
  }, []);

  return (
    <>
      {(updating || loading) && <Loader />}
      <Switch>
        <Route path={`${path}/basic`} exact component={BasicInformationPage} />
        <Route path={`${path}/details`} exact component={ContributionDetailsPage} />
        <Route path={`${path}/content`} exact component={ContentPage} />
        <Route path={`${path}/payment`} exact component={PaymentPage} />
        <Route path={`${path}/review`} exact component={ReviewAndSubmitPage} />
        <Route path={`${path}/thankyoupreviewpage`} exact component={ThankYouPreviewPage} />
        <Redirect to={`${path}/basic`} />
      </Switch>
    </>
  );
}

EditLeadMagnetContainer.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string,
    url: PropTypes.string,
    isExact: PropTypes.bool,
    params: PropTypes.object,
  }).isRequired,
  fetchCohealerLeadMagnet: PropTypes.func.isRequired,
  // getEditContributionTimes: PropTypes.func.isRequired,
  updating: PropTypes.bool,
};

EditLeadMagnetContainer.defaultProps = {
  updating: false,
};

const mapStateToProps = ({
  leadMagnets: {
    activeLeadMagnet,
    updating,
    loading,
    // editContributionTimes
  },
}) => ({
  leadMagnet: activeLeadMagnet,
  updating,
  loading,
});

const actions = {
  fetchCohealerLeadMagnet: leadMagnetActions.fetchCohealerLeadMagnet,
  updateOneToOneData: oneToOneDataActions.updateOneToOneData,
  resetLeadMagnet: leadMagnetActions.resetLeadMagnet,
  // getEditContributionTimes: contributionActions.fetchEditContributionTimes
};

export default connect(mapStateToProps, actions)(EditLeadMagnetContainer);
