import React from 'react';
import { Switch, useRouteMatch } from 'react-router-dom';
import { useRouter } from 'hooks';
import { CommonRoute, ProtectedRoute } from '../Routing';
import ContributionViewContainer from '../Containers/ContributionViewContainer';
import ChatProvider from './ChatProvider';
import VideoChat from './VideoChat';
import { TestVideoChat } from './TestVideoChat';
import { ModalsAndLoadersContainer } from './ModalsAndLoaders';
import LeadMagnetViewContainer from 'components/Containers/LeadMagnetViewContainer';

const CoachContent = () => {
  const { pathname, query } = useRouter();

  const pathName = pathname.split('/');
  const isRedirectedToModule = pathName.length === 5 && pathName[3] === 'sessions';
  const isRedirectedToNotes = pathName.length === 4 && pathName[3] === 'notes';
  const regExCommunity = /\/contribution-view\/.*\/community\/social/;
  const isRedirectToCommunitySocial = regExCommunity.test(pathname);
  const isRedirectToSessions = query.isPurchased;

  return (
    <ChatProvider>
      <Switch>
        {isRedirectToCommunitySocial || isRedirectToSessions || isRedirectedToModule || isRedirectedToNotes ? (
          <ProtectedRoute path="/contribution-view/:id" component={ContributionViewContainer} />
        ) : (
          <CommonRoute path="/contribution-view/:id" component={ContributionViewContainer} />
        )}
        {isRedirectToCommunitySocial || isRedirectToSessions || isRedirectedToNotes ? (
          <ProtectedRoute path="/apply-contribution-view/:id" component={ContributionViewContainer} />
        ) : (
          <CommonRoute path="/apply-contribution-view/:id" component={ContributionViewContainer} />
        )}
        <CommonRoute path="/lead-magnet-view/:id" component={LeadMagnetViewContainer} />
      </Switch>
      <VideoChat />
      <TestVideoChat />
      <ModalsAndLoadersContainer />
    </ChatProvider>
  );
};

export default CoachContent;
