import styled from 'styled-components';
import { makeStyles } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import { colors } from '../../utils/styles';

export const StyledContainer = styled.div`
  position: relative;

  background-color: ${colors.white};
  border-radius: 8px;

  width: 100%;
  height: 350px;

  box-shadow: 0px 0px 10px ${colors.shadowDark};

  transition: all 0.2s;

  &:hover {
    box-shadow: 0px 0px 18px ${colors.shadowDark};
  }
`;

export const AvatarComponent = styled(Avatar)`
  position: absolute;
  top: 15px;
  left: 8px;
  width: 26px;
  height: 26px;
  font-size: 0.85rem;
  ${({ mobileView }) => {
    return (
      mobileView &&
      `
        width: 22px;
        height: 22px;
        font-size: 0.7rem;
      `
    );
  }};
`;

export const PreviewImage = styled.img`
  width: 100%;
  height: 100%;

  border-radius: 8px;
  object-fit: cover;
  object-position: center;
`;

export const StyledTitle = styled.p`
  padding: 8px 0px 4px 0px;
  margin: 0px;

  font-weight: bolder;
  font-size: 12px;
  color: ${colors.textMain};
`;
export const StyledAuthor = styled.p`
  padding: 0px 0px 4px 0px;
  margin: 0px;

  font-weight: normal;
  font-size: ${props => (props.fontSize ? props.fontSize : '14px')};
  color: ${colors.textSecondary};
`;
export const StyledMembersCount = styled.span`
  padding-left: 4px;
  font-weight: normal;
  font-size: 14px;
  color: ${colors.textSecondary};
`;
export const StyledUnreadPosts = styled.span`
  padding-left: 4px;
  font-weight: normal;
  font-size: 14px;
  color: ${colors.textSecondary};
`;
export const StyledTheme = styled.p`
  padding: 0px 0px 4px 0px;
  margin: 0px;

  font-weight: normal;
  font-size: ${props => (props.fontSize ? props.fontSize : '14px')};
  color: ${colors.textSecondary};
`;
export const StyledRating = styled.div`
  & span {
    font-size: 14px;
    font-weight: normal;
    color: ${colors.textSecondary};
  }

  & button {
    border: none;
    border-radius: ${props => (props.borderRadius ? props.borderRadius : '16px')};

    color: ${colors.white};
    font-size: 12px;
    font-weight: bolder;

    padding: 4px 14px;
    background: ${colors.lightBrown};
  }
`;

export const EnrollButton = styled.button`
  border: none;
  border-radius: ${props => (props.borderRadius ? props.borderRadius : '4px')};

  color: ${colors.white};
  font-size: ${props => (props.fontSize ? props.fontSize : '16px')};
  font-weight: ${props => (props.fontWeight ? props.fontWeight : 'normal')};

  background: ${props => (props.backgroundColor ? props.backgroundColor : colors.lightBrown)};
  padding: ${props => (props.padding ? props.padding : '6px 50px')};

  transition: all 0.25s;

  &:hover {
    background-color: ${props => (props.backgroundColorhover ? props.backgroundColorhover : colors.darkGold)};
  }
`;

export const DisabledEnrollButton = styled.button`
  border: none;
  border-radius: 4px;

  color: ${colors.backgroundLightGrey};
  font-size: 16px;
  font-weight: normal;

  background: ${colors.backgroundGrey};
  padding: 6px 50px;

  transition: all 0.25s;
`;

export const DisableFeatureButton = styled.div`
  border: none;
  border-radius: 16px;
  pointer-events: none;
  color: ${colors.white};
  font-size: 12px;
  font-weight: bolder;

  padding: 4px 14px;
  background: ${colors.backgroundGrey};
`;

export const useStyles = makeStyles(theme => ({
  cardBox: {
    height: '100%',
    width: '100%',
    padding: 10,
  },
  topLevel: {
    height: '40%',
  },
  topLevelDashboard: {
    height: '65%',
  },
  midLevel: {
    height: '40%',
  },
  midLevelDashboard: {
    height: '30%',
    display: 'grid',
    alignItems: 'center',
  },
  bottomLevel: {
    height: '20%',
    [theme.breakpoints.down(1100)]: {
      height: '65%',
    },
    [theme.breakpoints.down(1200)]: {
      height: '52% !important',
    },
    [theme.breakpoints.down(500)]: {
      height: '71%',
    },
  },
  contributionView: {
    height: '200px !important',
    width: 250,
    [theme.breakpoints.down(500)]: {
      width: 300,
      height: 250,
    },
  },
  academyMembershipVerticalMedia: {
    height: '345px !important',
    [theme.breakpoints.down(1500)]: {
      height: '304px !important',
    },
    [theme.breakpoints.down(1100)]: {
      height: '290px !important',
      maxWidth: '400px',
    },
  },
  basicNotificationInfo: {
    height: 'auto',
  },
  basicNotificationImage: {
    height: 135,
    width: 250,
  },
  basicNotification: {
    height: '265px !important',
    width: 270,
    [theme.breakpoints.down(500)]: {
      width: 270,
      height: 265,
    },
  },
  unreadPostsIcon: {
    width: 20,
    height: 20,
    borderRadius: '50%',
    backgroundColor: '#d1b989',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    color: 'white',
    fontSize: 12,
    justifyContent: 'center',
  },
  noUnreadPostsIcon: {
    width: 20,
    height: 20,
    borderRadius: '50%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    color: 'white',
    fontSize: 12,
    justifyContent: 'center',
  },
}));

export const ExpiresLabel = styled.p`
  color: ${colors.textSecondary};
  font-size: 12px;
  text-align: center;
`;
