import React from 'react';

const DownSvg = ({ width = '13', height = '9', color = 'black', onClick, style }) => {
  return (
    <div style={{ ...style, cursor: 'pointer' }} onClick={onClick}>
      <svg width={width} height={height} viewBox="0 0 13 9" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M1.50003 0.770004L6.09003 5.36L10.68 0.770004L12.09 2.19L6.09003 8.19L0.0900269 2.19L1.50003 0.770004Z"
          fill={color}
          fill-opacity="0.87"
        />
      </svg>
    </div>
  );
};

export default DownSvg;
