import React from 'react';

import { ChatType } from 'services/chat.service';
import { useConversations } from 'hooks';
import UnreadMessagesCount from './UnreadMessagesCount';

const OpportunitiesConversationsUnreadMessageCount = () => {
  const { loading, conversationIds } = useConversations(ChatType.opportuntityDiscussion);

  if (loading || !conversationIds) {
    return null;
  }

  return <UnreadMessagesCount conversationIds={conversationIds} />;
};

export default OpportunitiesConversationsUnreadMessageCount;
