import Modal from 'components/UI/Modal';
import React, { useState, useCallback, useEffect, useRef } from 'react';
import useContribution from 'pages/ContributionView/hooks/useContribution';
import { determineColorToUse, determineDarkThemedColorToUse } from 'services/contributions.service';
import { getThemedColors } from 'services/contributions.service';
import Table from '@material-ui/core/Table';
import { Formik, Form } from 'formik';
import { makeStyles, MenuItem, OutlinedInput, Select } from '@material-ui/core';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import InfoIcon from '@material-ui/icons/Info';
import { useSelector } from 'react-redux';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import useAccount from 'hooks/useAccount';
import { UserRoles } from 'helpers/constants';
import Loader from 'components/UI/Loader';
import styled from 'styled-components';
import CrossIcon from '@material-ui/icons/Clear';
import * as Yup from 'yup';
import { EN_MESSAGES } from 'constants';
import { borderWidth } from 'polished';
import Button from 'components/FormUI/Button';
import { lightOrDark } from 'utils/utils';
import withStyles from '@material-ui/core/styles/withStyles';
import Paper from '@material-ui/core/Paper';
import { useTheme, useMediaQuery } from '@material-ui/core';
import TextArea from 'components/FormUI/TextArea';
import Grid from '@material-ui/core/Grid';
import { AffiliateRow } from './AffiliateTableRow';
import copyToClipboard from 'utils/copyToClipboard';
import { downloadFile } from 'utils/utils';
import {
  saveContributionAffiliate,
  getAllContributionEnrollments,
  getContributionAffiliateDataApi,
} from '../../../../services/affiliate.service';
import CommonErrorMessage from 'components/FormUI/CommonErrorMessage';
import { colors } from 'utils/styles';
import { formatMoney } from 'utils/datesAndMoney';
import { set } from 'lodash';
import Tooltip from '@material-ui/core/Tooltip';
import { TOOLTIP } from '../../../../constants';

const StyledTableContainer = withStyles({
  root: {
    overflowX: 'auto',
    maxWidth: props => (props.mobileView ? '96vw' : '100%'),
    margin: '0 auto',
  },
})(TableContainer);

const StyledCloseIcon = styled(CrossIcon)`
  margin: 5px;
  height: 15px;
  width: 15px;
  &:hover {
    cursor: pointer;
  }
`;

const useStyles = makeStyles(theme => ({
  notchedOutline: {
    border: 'none',
  },
  input: {
    border: '1px solid #dbe7f2',
    borderRadius: '4px',
    padding: '14px',
    '&:focus': {
      borderRadius: '4px',
      backgroundColor: 'white',
    },
  },
}));
function AffiliateShareModal({ isVisible, closeModal, setIsScheduleMeetingVisible }) {
  const [addNewAffiliate, setAddNewAffiliate] = useState(false);
  const [affiliateList, setaffiliateList] = useState([]);
  const [selectedPricing, setSelectedPricing] = useState('');
  const activeContribution = useSelector(state => state.contributions?.activeContribution);
  const [offset, setOffset] = useState(0);
  const contribution = useContribution();
  const { currentRole } = useAccount();
  const isCoach = currentRole === UserRoles.cohealer;
  const { themedColor } = getThemedColors(contribution);
  const theme = useTheme();
  const classes = useStyles();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const [isLoading, setIsLoading] = useState(false);
  const isDarkThemeEnabled = determineDarkThemedColorToUse(activeContribution);
  const [addApiFailError, setAddApiFailError] = useState(false);
  const [errorPopUpState, seterrorPopUpState] = useState(false);
  const formRef = useRef(null);
  let colorToUse = determineColorToUse(activeContribution);
  useEffect(() => {
    getContributionAffiliateData();
  }, []);

  const btnColor = colorToUse.PrimaryColorCode;
  const textColor =
    colorToUse?.TextColorCode === 'Auto'
      ? lightOrDark(colorToUse?.PrimaryColorCode)
      : colorToUse?.TextColorCode === '#000000'
      ? '#000000'
      : '#FFFFFF';
  const clickAnywhere = useCallback(() => {}, []);
  const getContributionAffiliateData = async () => {
    await getAllContributionEnrollments(activeContribution.id)
      .then(res => {
        setaffiliateList(res);
      })
      .catch(() => {
        setaffiliateList([]);
      });
  };
  const onCloseModal = () => {
    setIsScheduleMeetingVisible(false);
    closeModal();
  };
  const BoldTableCell = withStyles({
    root: {
      fontWeight: 800,
      fontSize: '14px',
      padding: '16px 0px 16px 0px',
      borderBottom: 0,
      color: isDarkThemeEnabled ? 'white' : 'black',
    },
  })(TableCell);
  return isLoading ? (
    <Loader />
  ) : (
    <Modal
      isOpen={isVisible}
      title={'Invite Affiliates'}
      onSubmit={values => {}}
      onCancel={() => onCloseModal()}
      onClickAnyWhere={clickAnywhere}
      disableFooter={true}
      widthRequired="100%"
      headerBorder={true}
    >
      <div
        style={{
          fontFamily: 'Avenir',
          fontSize: '16px',
          fontWeight: '400',
          margin: '20px 0px',
          lineHeight: '24px',
          color: isDarkThemeEnabled ? 'white' : 'black',
        }}
      >
        Ready to expand your reach and leverage the power of affiliates to grow your client base?
      </div>
      <div
        style={{
          fontFamily: 'Avenir',
          fontSize: '18px',
          fontWeight: '700',
          margin: '20px 0px',
          lineHeight: '17px',
          color: isDarkThemeEnabled ? 'white' : 'black',
        }}
      >
        Add New Affiliate Link
      </div>
      {addNewAffiliate === false && (
        <Button
          backgroundColor={btnColor}
          textColor={textColor}
          style={{ padding: '11px 30.41px 11px 30.76px' }}
          onClick={() => {
            setAddNewAffiliate(true);
          }}
        >
          Add New
        </Button>
      )}
      {addNewAffiliate === true && (
        <Formik
          initialValues={{
            FirstName: '',
            LastName: '',
            Email: '',
            priceId: '',
          }}
          innerRef={formRef}
          validationSchema={Yup.object().shape({
            Email: Yup.string().required().email('Please enter a valid email address'),
          })}
          onSubmit={() => {}}
        >
          {({ values, errors, setFieldValue }) => {
            return (
              <Form id="signUpFormTwo">
                <div>
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
                    <Grid item md={6} sm={6} xs={12}>
                      <TextArea
                        affiliateRow={true}
                        profile={true}
                        placeholder={'First Name'}
                        name="FirstName"
                        rows="1"
                        fullWidth
                      />
                    </Grid>
                    <div style={{ width: '20px' }} />
                    <Grid item md={6} sm={6} xs={12}>
                      <TextArea
                        affiliateRow={true}
                        profile={true}
                        placeholder={'Last Name'}
                        name="LastName"
                        rows="1"
                        fullWidth
                      />
                    </Grid>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'row', marginTop: '20px' }}>
                    <Grid item md={6} sm={6} xs={12} style={{ height: '73px' }}>
                      <TextArea
                        affiliateRow={true}
                        profile={true}
                        placeholder={'Email'}
                        name="Email"
                        rows="1"
                        fullWidth
                      />
                      {errors?.Email && values?.Email?.length > 0 && (
                        <CommonErrorMessage align="left" message="Please enter valid email address" />
                      )}
                    </Grid>
                    <div style={{ width: '20px' }} />
                    <Grid item md={6} sm={6} xs={12} style={{}}>
                      {activeContribution.paymentInfo.multiplePriceList.length > 1 ? (
                        <>
                          <Select
                            label="contributionPricing"
                            name="contributionPricing"
                            fullWidth
                            defaultValue={values.priceId}
                            input={
                              <OutlinedInput
                                margin="none"
                                classes={{
                                  notchedOutline: classes.notchedOutline,
                                  input: classes.input,
                                }}
                              />
                            }
                            onChange={e => {
                              setFieldValue('priceId', e.target.value);
                            }}
                          >
                            {activeContribution.paymentInfo.multiplePriceList?.map(data => {
                              const contribution = activeContribution;
                              const {
                                defaultSymbol: symbol,
                                paymentInfo: { paymentOptions },
                                type,
                              } = contribution;
                              const costText = `${symbol}${formatMoney(data?.cost)}`;
                              const packageCostText = `${symbol}${formatMoney(data?.packageCost)}`;
                              const splittedCostText = `${symbol}${formatMoney(data?.splittedCost)}`;

                              let priceText = '';

                              if (type === 'ContributionOneToOne') {
                                if (
                                  paymentOptions.includes('PerSession') &&
                                  paymentOptions.includes('SessionsPackage')
                                ) {
                                  priceText = `${costText} session price, ${packageCostText} package price`;
                                } else if (!paymentOptions.includes('PerSession')) {
                                  priceText = `${packageCostText} package price`;
                                } else {
                                  priceText = `${costText} session price`;
                                }
                              } else if (type === 'ContributionMembership' || type === 'ContributionCommunity') {
                                priceText = `${costText} ${
                                  data.paymentOption === 'MonthlyMembership'
                                    ? 'monthly membership price'
                                    : 'yearly membership price'
                                }`;
                              } else {
                                if (
                                  paymentOptions.includes('EntireCourse') &&
                                  paymentOptions.includes('SplitPayments')
                                ) {
                                  priceText = `${costText} full price, ${splittedCostText} split price`;
                                } else if (!paymentOptions.includes('EntireCourse')) {
                                  priceText = `${splittedCostText} split price`;
                                } else {
                                  priceText = `${costText} full price`;
                                }
                              }

                              return (
                                <MenuItem value={data?.id} key={data?.id}>
                                  {priceText}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </>
                      ) : (
                        <Button
                          isDarkMode={isDarkThemeEnabled}
                          backgroundColor={btnColor}
                          textColor={textColor}
                          onClick={() => {
                            setAddApiFailError(false);
                            saveContributionAffiliate({ ...values, contributionId: activeContribution.id })
                              .then(res => {
                                copyToClipboard(res?.freeAffiliateLink);
                                getContributionAffiliateData();
                                setAddNewAffiliate(false);
                                seterrorPopUpState(true);
                              })
                              .catch(() => {
                                setAddApiFailError(true);
                                // getContributionAffiliateData();
                                // setAddNewAffiliate(false);
                              });
                            setTimeout(() => {
                              seterrorPopUpState(false);
                            }, 3000);
                          }}
                          disabled={
                            values.FirstName.length === 0 ||
                            values.LastName.length === 0 ||
                            values.Email.length === 0 ||
                            errors?.Email
                          }
                        >
                          Add Affiliate Generate Link
                        </Button>
                      )}
                    </Grid>
                  </div>
                  {activeContribution.paymentInfo.multiplePriceList.length > 1 && (
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end' }}>
                      <Grid item md={6} sm={6} xs={12} style={{ height: '73px' }}>
                        <Button
                          isDarkMode={isDarkThemeEnabled}
                          backgroundColor={btnColor}
                          textColor={textColor}
                          onClick={() => {
                            setAddApiFailError(false);
                            saveContributionAffiliate({ ...values, contributionId: activeContribution.id })
                              .then(res => {
                                copyToClipboard(res?.freeAffiliateLink);
                                getContributionAffiliateData();
                                setAddNewAffiliate(false);
                                seterrorPopUpState(true);
                              })
                              .catch(() => {
                                setAddApiFailError(true);
                                // getContributionAffiliateData();
                                // setAddNewAffiliate(false);
                              });
                            setTimeout(() => {
                              seterrorPopUpState(false);
                            }, 3000);
                          }}
                          disabled={
                            values.FirstName.length === 0 ||
                            values.LastName.length === 0 ||
                            values.Email.length === 0 ||
                            values.priceId.length === 0 ||
                            errors?.Email
                          }
                        >
                          Add Affiliate Generate Link
                        </Button>
                      </Grid>
                    </div>
                  )}
                  {addApiFailError === true && (
                    <div style={{ marginTop: '20px' }}>
                      <CommonErrorMessage message="Email already exists" />
                    </div>
                  )}
                </div>
              </Form>
            );
          }}
        </Formik>
      )}
      <div
        style={{
          margin: '20px 0px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            fontFamily: 'Avenir',
            fontSize: '18px',
            fontWeight: '700',
            lineHeight: '17px',
            color: isDarkThemeEnabled ? 'white' : 'black',
          }}
        >
          Affiliate Links
        </div>
        <Button
          invert
          style={{ padding: '11px 30.41px 11px 30.76px', borderColor: btnColor, color: btnColor }}
          onClick={async () => {
            await getContributionAffiliateDataApi(activeContribution.id).then(res => {
              downloadFile(res, 'ContributionAffiliateTracking.csv');
            });
          }}
        >
          Download Affiliate Tracking CSV
        </Button>
      </div>
      {affiliateList?.length > 0 && (
        <StyledTableContainer mobileView={mobileView}>
          <Table className="applications-table" aria-label="applications-table">
            <TableHead>
              <TableRow>
                <BoldTableCell>First Name</BoldTableCell>
                <BoldTableCell>Last Name</BoldTableCell>
                <BoldTableCell>Email</BoldTableCell>
                <BoldTableCell>
                  Free Affiliate Link
                  <Tooltip
                    title="If you share this link your affiliate partners will able to join the free version of this service"
                    arrow
                    enterTouchDelay={TOOLTIP.ENTER_DELAY}
                    leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                  >
                    <InfoIcon
                      htmlColor={colorToUse ? colorToUse?.AccentColorCode : colors.lightBrown}
                      style={{ cursor: 'pointer', marginLeft: '3px' }}
                    />
                  </Tooltip>
                </BoldTableCell>
                {contribution?.paymentInfo?.paymentOptions?.length === 1 &&
                contribution?.paymentInfo?.paymentOptions?.includes('Free') ? (
                  <BoldTableCell align="center"></BoldTableCell>
                ) : (
                  <BoldTableCell>
                    Paid Affiliate Link
                    <Tooltip
                      title="If you share this link your affiliate partners will able to join the paid version of this service"
                      arrow
                      enterTouchDelay={TOOLTIP.ENTER_DELAY}
                      leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                    >
                      <InfoIcon
                        htmlColor={colorToUse ? colorToUse?.AccentColorCode : colors.lightBrown}
                        style={{ cursor: 'pointer', marginLeft: '3px' }}
                      />
                    </Tooltip>
                  </BoldTableCell>
                )}
                <BoldTableCell />
              </TableRow>
            </TableHead>
            {affiliateList?.length > 0 && (
              <TableBody>
                {affiliateList?.map((row, index) => (
                  <AffiliateRow row={row} index={index} getContributionAffiliateData={getContributionAffiliateData} />
                ))}
              </TableBody>
            )}
          </Table>
        </StyledTableContainer>
      )}
      {errorPopUpState === true && (
        <div
          className="sticky"
          style={{
            top: offset >= 280 ? 0 : 280,
            left: 120,
            width: '45%',
            backgroundColor: 'white',
            marginInline: '20%',
            border: '1px solid #DBE7F2',
            borderRadius: '4px',
            zIndex: 1,
            transitionDuration: '1s',
          }}
        >
          <div style={{ padding: '15px 10px 10px 15px' }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <CheckCircleIcon htmlColor={colors.darkOceanBlue} />
                <div
                  style={{
                    fontFamily: 'Avenir',
                    fontWeight: '700',
                    fontSize: '16px',
                    lineHeight: '24px',
                    color: '#1E1E1E',
                    marginInline: '10px',
                  }}
                >
                  Affiliate added successfully and link copied to clipboard
                </div>
              </div>
              <StyledCloseIcon
                onClick={() => {
                  seterrorPopUpState(false);
                }}
                fontSize="10px"
                style={{ color: 'black' }}
              />
            </div>
          </div>
        </div>
      )}
    </Modal>
  );
}

export default AffiliateShareModal;
