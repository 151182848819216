import axiosInstance from 'utils/axiosInstance';
import { get } from 'lodash/fp';

const getTestimonials = contributionId =>
  axiosInstance.get(`/api/Testimonial/GetByContributionId`, { params: { contributionId } }).then(get('data'));
const getTestimonialTemplates = () =>
  axiosInstance.get(`/api/Testimonial/GetAllTestinmonialsBYCoachId`).then(get('data'));
const addTestimonialAvatar = (contributionId, file, previousUrl) =>
  axiosInstance
    .post('/Content/AddTestimonialAvatar', file, {
      params: { contributionId, previousUrl },
      'Content-Type': 'multipart/form-data',
    })
    .then(get('data'));
const addTestimonial = payload => axiosInstance.post('/api/Testimonial', payload);
const editTestimonial = (id, payload) => axiosInstance.put(`/api/Testimonial/${id}`, payload);
const deleteTestimonial = id => axiosInstance.delete(`/api/Testimonial/${id}`);
const toggleShowcase = id => axiosInstance.patch(`/api/Testimonial/ToggleShowcase`, null, { params: { id } });

export {
  getTestimonials,
  getTestimonialTemplates,
  addTestimonialAvatar,
  addTestimonial,
  deleteTestimonial,
  toggleShowcase,
  editTestimonial,
};
