import { createAction } from 'redux-actions';
import * as applicationService from 'services/applications.service';
import * as applicationFormService from 'services/applicationForm.services';
import { get, pipe } from 'lodash/fp';
import { isFunction } from 'lodash';

export const FETCH_APPLICATIONS_REQUEST = 'FETCH_APPLICATIONS_REQUEST';
export const FETCH_APPLICATIONS_SUCCESS = 'FETCH_APPLICATIONS_SUCCESS';
export const FETCH_APPLICATIONS_ERROR = 'FETCH_APPLICATIONS_ERROR';
export const ACCEPTED_REJECTED_APPLICATION_FORM = 'ACCEPTED_REJECTED_APPLICATION_FORM';
export const RESET_APPLICATIONS = 'RESET_APPLICATIONS';

export const fetchApplicationsActions = {
  reset: createAction(RESET_APPLICATIONS),
  request: createAction(FETCH_APPLICATIONS_REQUEST),
  success: createAction(FETCH_APPLICATIONS_SUCCESS),
  error: createAction(FETCH_APPLICATIONS_ERROR),
  acceptedOrRejected: createAction(ACCEPTED_REJECTED_APPLICATION_FORM),
};
export const fetchApplications = ({ status, offset, limit }) => async dispatch => {
  dispatch(fetchApplicationsActions.request());
  try {
    if (!offset) {
      dispatch(fetchApplicationsActions.reset());
    }
    const response = await applicationService.getApplications(status, offset, limit);
    dispatch(fetchApplicationsActions.success({ applications: response }));
  } catch (error) {
    pipe(get('response'), get('data'), fetchApplicationsActions.error, dispatch)(error);
  }
};

export const FETCH_APPLICATION_FORMS_REQUEST = 'FETCH_APPLICATION_FORMS_REQUEST';
export const FETCH_APPLICATION_FORMS_SUCCESS = 'FETCH_APPLICATION_FORMS_SUCCESS';
export const FETCH_APPLICATION_FORMS_ERROR = 'FETCH_APPLICATION_FORMS_ERROR';

export const fetchApplicationFormsActions = {
  request: createAction(FETCH_APPLICATION_FORMS_REQUEST),
  success: createAction(FETCH_APPLICATION_FORMS_SUCCESS),
  error: createAction(FETCH_APPLICATION_FORMS_ERROR),
};
export const fetchApplicationForms = () => async dispatch => {
  dispatch(fetchApplicationFormsActions.request());
  try {
    const response = await applicationService.getApplicationForms();
    dispatch(fetchApplicationFormsActions.success({ forms: response }));
  } catch (error) {
    pipe(get('response'), get('data'), fetchApplicationsActions.error, dispatch)(error);
  }
};

export const DELETE_APPLICATION_FORM_REQUEST = 'DELETE_APPLICATION_FORM_REQUEST';
export const DELETE_APPLICATION_FORM_SUCCESS = 'DELETE_APPLICATION_FORM_SUCCESS';
export const DELETE_APPLICATION_FORM_ERROR = 'DELETE_APPLICATION_FORM_ERROR';

export const deleteApplicationFormActions = {
  request: createAction(DELETE_APPLICATION_FORM_REQUEST),
  success: createAction(DELETE_APPLICATION_FORM_SUCCESS),
  error: createAction(DELETE_APPLICATION_FORM_ERROR),
};

export const deleteApplicationForm = (
  contributionId,
  formId,
  onStart = null,
  onSuccess = null,
  onError = null,
  onFinally = null,
) => dispatch => {
  return new Promise(() => {
    if (onStart && isFunction(onStart)) {
      onStart();
    }
    // dispatch(deleteApplicationFormActions.request());
    return applicationFormService
      .deleteApplicationForm(contributionId, formId)
      .then(() => {
        dispatch(deleteApplicationFormActions.success(formId));
        if (onSuccess && isFunction(onSuccess)) {
          onSuccess();
        }
      })
      .catch(error => {
        if (onError && isFunction(onError)) {
          onError();
        }
        pipe(get('response'), get('data'), deleteApplicationFormActions.error, dispatch)(error);
      })
      .finally(() => {
        if (onFinally && isFunction(onFinally)) {
          onFinally();
        }
      });
  });
};
