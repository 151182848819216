import React, { useEffect, useRef, useCallback } from 'react';
import { useStripe } from '@stripe/react-stripe-js';
import { Box, Grid, useMediaQuery } from '@material-ui/core';
import usePaidTier from 'hooks/usePaidTier';
import useAccount from 'hooks/useAccount';
import { createPaidTierOptionsSession, upgradePaidTierOptions } from 'services/paidTierOptions.service';

import { setActivePaidTierPlan } from 'actions/paidTierOptions';
import useShallowEqualSelector from 'hooks/useShallowEqualSelector';
import { PAIDTIER_STATUSES, PAID_TIER_TITLES } from 'constants.js';
import OnePreviewLogo from 'assets/one_plan_view_academy.png';
import { ContributionType } from 'helpers/constants';
import AdvantagesItem from 'components/PlanCard/AdvantagesItem';
import UpgradeButton from 'components/UI/UpgradeButton';
import PlanCard from '../../../../components/PlanCard';
import Button from 'components/FormUI/Button';
import {
  AdvantagesList,
  CohereAcademyP,
  CohereAcademyTitle,
  ExpiresLabel,
  FeeField,
  PaidTierExtended,
  PlanCardContainer,
  PlanColumn,
  PriceField,
  PrimaryP,
  SecondaryP,
  useStyles,
} from '../Billing.styles';

import Dictionary from '../dictionary.json';
import { getUserProfile } from 'services/user.service';
import { getProfile } from 'actions/user';
import { padding } from 'polished';
import { EnrollButton } from 'components/AdvertismentCard/style';
import { StyledTitle } from '../../../../components/AdvertismentCard/style';
import { TitleField } from 'components/PlanCard/styles';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { allinoneplatformadvantages, thecohereacademyAdvantages, VIPAdvantages } from './CardContentLists';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import * as paidTierActions from 'actions/paidTierOptions';
import { setCohereAcademyStatus } from 'actions/update-user';
import * as paidTierService from 'services/paidTierOptions.service';
import { getBoughtByType } from 'services/contributions.service';
import * as paidTier from 'selectors/paidTier';

const monthCount = 12;
const PaidTierBigView = ({ modalsApi, setcohereacdemyactive }) => {
  const [platformactive, setplatformactive] = useState(true);
  const [acdemyactive, setacdemymactive] = useState(false);
  const [vipactive, setvipactive] = useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isLoader, setIsLoader] = useState({
    paidTierPlans: false,
    activePaidTierPlan: false,
    academyMemberships: false,
    activeAcademyMemberships: false,
  });

  const activePaidTierCurrentPlan = useShallowEqualSelector(paidTier.activePaidTierCurrentPlanSelector);
  const academyContributions = useSelector(paidTier.cohereAcademyContributionsSelector);
  const isImpactPlan = activePaidTierCurrentPlan?.displayName === PAID_TIER_TITLES.impact;

  const getAllPaidTiers = useCallback(() => paidTierService.getPaidTierOptions(), []);

  const isLaunchPlan = activePaidTierCurrentPlan?.displayName === PAID_TIER_TITLES.launch;
  // Correct behavior - 100%
  const filterMembership = useCallback(
    res => {
      const filteredMemberships = [];

      academyContributions.forEach(item => {
        const matchedMembership = res.find(el => el.id === item.id);

        if (matchedMembership && matchedMembership.isPurchased) {
          filteredMemberships.push(item);
        }
        return true;
      });

      return filteredMemberships;
    },
    [academyContributions],
  );

  const updateActiveContributionsState = useCallback(() => {
    return getBoughtByType(ContributionType.contributionMembership)
      .then(filterMembership)
      .then(matchedMemberships => {
        dispatch(paidTierActions.setActivePaidTierContributions(matchedMemberships));
      });
  }, [filterMembership]);

  useEffect(() => {
    // this is a temp solution, we need to find a way to not repopulate it again if not needed
    // TODO: don't get paid tiers plans if the right version for existing user is already loaded
    // if (paidTierPlans.length) {
    //   return;
    // }
    setIsLoader(s => ({ ...s, paidTierPlans: true }));

    getAllPaidTiers().then(data => {
      dispatch(paidTierActions.setPaidTierPlans(data));
      setIsLoader(s => ({ ...s, paidTierPlans: false }));
    });
  }, []);

  useEffect(() => {
    setIsLoader(s => ({ ...s, activePaidTierPlan: true }));

    getActivePaidTier().then(data => {
      dispatch(paidTierActions.setActivePaidTierPlan(data));
      setIsLoader(s => ({ ...s, activePaidTierPlan: false }));
    });
  }, []);

  useEffect(() => {
    if (activePaidTierCurrentPlan?.default) {
      dispatch(setCohereAcademyStatus(true));
    } else {
      dispatch(setCohereAcademyStatus(false));
    }
  }, [activePaidTierCurrentPlan, dispatch]);

  useEffect(() => {
    setIsLoader(s => ({ ...s, academyMemberships: true }));

    paidTierService.getAcademy().then(data => {
      dispatch(paidTierActions.setPaidTierContribution(data));
      setIsLoader(s => ({ ...s, academyMemberships: false }));
    });
  }, []);

  useEffect(() => {
    setIsLoader(s => ({ ...s, activeAcademyMemberships: true }));

    updateActiveContributionsState().then(() => {
      setIsLoader(s => ({ ...s, activeAcademyMemberships: false }));
    });
  }, []);

  const containerRef = useRef(null);

  const stripe = useStripe();
  const { user } = useAccount();

  const {
    paidTierPlans,
    activePaidTierPlan,
    // isLaunchPlan,
    // isImpactPlan,
    activePaidTierPlanEndDateTime,
    activePaidTierPlanStatus,
    activePaidTierCurrentPaymentPeriod,
    // getActivePaidTier,
  } = usePaidTier();

  const impactPlan = paidTierPlans.find(el => el.displayName === PAID_TIER_TITLES.impact);
  const getActivePaidTier = useCallback(() => paidTierService.getCurrentPlan(), []);
  const activePaidTierTotalPurchasePrice = activePaidTierPlan?.totalPurchasePrice;
  const activePaidTierPlanName = activePaidTierPlan?.planName;
  const activePaidTierPlanInterval =
    activePaidTierPlan?.planInterval === 'year'
      ? 'Annually'
      : activePaidTierPlan?.planIntervalCount === 6
      ? 'Six monthly'
      : 'Monthly';

  const planName = isLaunchPlan
    ? `Annual ${impactPlan?.displayName} Plan & Bundle`
    : `${activePaidTierPlanInterval} ${activePaidTierPlanName ?? ''} Plan & Bundle`;

  const mobileView = useMediaQuery('(max-width: 700px)');
  const ResponsivemobileView = useMediaQuery('(max-width: 450px)');

  const { title, description } = Dictionary.cohereAcademy;

  const paymentPeriodLabel = activePaidTierCurrentPaymentPeriod === 'Annually' ? 'annual' : 'monthly';

  const currentButtonClass = isImpactPlan
    ? `${classes.selectPlanButton} ${classes.enable}`
    : `${classes.selectPlanButton} ${classes.disable}`;

  const buttonLabel = isImpactPlan ? 'Cancel' : 'Upgrade & Join';
  const formatDate = new Date(activePaidTierPlanEndDateTime).toLocaleDateString('en-US');
  const isCanceled = activePaidTierPlanStatus === PAIDTIER_STATUSES.CANCEL;
  const nextOption = isCanceled ? 'cancels' : 'renews';

  const fetchActivePaidTierPlanAndUpdateStore = () => {
    return getActivePaidTier().then(data => {
      dispatch(setActivePaidTierPlan(data));
      return data;
    });
  };

  const handleUpgrade = () => {
    const period = 'Annually';

    if (isLaunchPlan) {
      createPaidTierOptionsSession(impactPlan?.id, period, user.accountId).then(({ data }) => {
        stripe.redirectToCheckout({
          sessionId: data,
        });
      });
    } else {
      upgradePaidTierOptions(user.accountId, impactPlan?.id, period)
        .then(() => {
          fetchActivePaidTierPlanAndUpdateStore().then(data => {
            modalsApi.upgrade_success.setIsShowSuccessUpgradeModal({
              isShow: true,
              label: `Plan will upgrade to ${data.nextPaidTierOption?.displayName} when your current plan expires.`,
            });
          });
        })
        .catch(err => {
          console.dir(err);
          if (activePaidTierPlanStatus !== PAIDTIER_STATUSES.ACTIVE) {
            modalsApi.upgrade_impossible.setImpossibleToUpgradeModal({
              isShow: true,
              label: `You are currently subscribed to an annual plan. Your plan will renew on (${new Date(
                activePaidTierPlanEndDateTime,
              ).toLocaleDateString(
                'en-US',
              )}). If you wish to upgrade and cancel your annual plan, please email us at support@cohere.live.`,
            });
          }
        });
    }
  };
  const handleCancel = () => {
    modalsApi.warning_before_cancellation.setIsShowBeforeCancellationWarning({
      isShow: true,
      label: `Are you sure you wish to cancel your subscription?`,
      userId: user.accountId,
      itemId: impactPlan?.id,
      activePlanTitle: impactPlan?.displayName,
    });
  };

  const fixedPrice = priceToParse => {
    const parsedValue = parseFloat(priceToParse);

    let price = 0;

    if (parsedValue !== NaN && typeof parsedValue === 'number') {
      price = Number(parsedValue).toFixed();
    }

    return price;
  };

  const handleOnClick = isImpactPlan ? handleCancel : handleUpgrade;

  useEffect(() => {
    if (mobileView) {
      setTimeout(() => {
        const offsetTopContainer = containerRef.current.offsetTop;
        window.scrollTo({
          top: offsetTopContainer,
          behavior: 'smooth',
        });
      }, 350);
    }
  }, [mobileView]);

  return (
    <PlanCardContainer ref={containerRef}>
      <PaidTierExtended columns="1fr" className={classes.paidTierExtendedMedia}>
        <PlanColumn mobileView={mobileView}>
          <Grid container>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              {mobileView ? (
                <>
                  <div>
                    {!isEmpty(activePaidTierPlan) && (
                      <div style={{ marginTop: '10px', textAlign: 'center' }}>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                          <Box
                            style={{
                              // marginLeft: '6px',
                              borderRadius: '3px',
                              // width: '220px',
                              backgroundColor: '#215C73',
                              paddingInline: '5px',
                              color: 'white',
                              display: 'flex',
                              justifyContent: 'center',
                              paddingTop: '7px',
                            }}
                          >
                            <h4
                              style={{
                                fontWeight: 600,
                                fontSize: '20px',
                                paddingTop: '2px',
                                paddingBottom: '2px',
                                color: 'white',
                              }}
                            >
                              {/* TODO: revert the label when the event week concludes */}
                              {/* Annual Impact Plan & Scale Your Coaching Bundle */}
                              {planName}
                              {/* {impactPlan?.displayNameExtraText} */}
                              {/* The Money Activation Bundle by Cohere and Allyson Byrd */}
                            </h4>
                          </Box>
                        </div>
                        {isLaunchPlan ? (
                          <>
                            <div style={{ height: '50px', display: 'flex', justifyContent: 'center' }}>
                              <PriceField style={{ fontSize: '50px' }}>
                                <span
                                  style={{
                                    color: '#282B2B',
                                    fontFamily: 'Inter',
                                    fontStyle: 'normal',
                                    fontWeight: 800,
                                    fontSize: '20px',
                                  }}
                                >
                                  $
                                </span>
                                {fixedPrice(impactPlan?.pricePerYear / monthCount)}
                              </PriceField>
                              <div
                                style={{
                                  width: '5px',
                                  height: '5px',
                                  fontSize: '10px',
                                  fontWeight: '600',
                                  color: '#282B2B',
                                  fontFamily: 'Inter',
                                  fontStyle: 'normal',
                                  paddingTop: '30px',
                                  paddingLeft: '3px',
                                }}
                              >
                                per month
                              </div>
                            </div>
                            <PriceField style={{ paddingBottom: '10px' }}>
                              <span
                                style={{
                                  color: '#282B2B',
                                  // fontFamily: 'Inter',
                                  // fontStyle: 'normal',
                                  fontSize: '14px',
                                  fontWeight: 400,
                                  paddingLeft: '9px',
                                }}
                              >
                                <span style={{ fontWeight: 800, fontSize: '12px' }}>
                                  ${fixedPrice(impactPlan?.pricePerYear)}
                                </span>{' '}
                                billed annually{' '}
                              </span>{' '}
                            </PriceField>
                          </>
                        ) : (
                          <>
                            <div style={{ height: '50px', display: 'flex', justifyContent: 'center' }}>
                              <PriceField style={{ fontSize: '50px' }}>
                                <span
                                  style={{
                                    color: '#282B2B',
                                    fontFamily: 'Inter',
                                    fontStyle: 'normal',
                                    fontWeight: 800,
                                    fontSize: '20px',
                                  }}
                                >
                                  $
                                </span>
                                {activePaidTierTotalPurchasePrice}
                              </PriceField>
                              {/* <div
                              style={{
                                width: '5px',
                                height: '5px',
                                fontSize: '10px',
                                fontWeight: '600',
                                color: '#282B2B',
                                fontFamily: 'Inter',
                                fontStyle: 'normal',
                                paddingTop: '30px',
                                paddingLeft: '3px',
                              }}
                            >
                              per month
                            </div> */}
                            </div>
                            <PriceField style={{ paddingBottom: '10px' }}>
                              <span
                                style={{
                                  color: '#282B2B',
                                  // fontFamily: 'Inter',
                                  // fontStyle: 'normal',
                                  fontSize: '14px',
                                  fontWeight: 400,
                                  paddingLeft: '9px',
                                }}
                              >
                                {/* <span style={{ fontWeight: 800, fontSize: '12px' }}>
                                ${fixedPrice(impactPlan?.pricePerYear)}
                              </span>{' '} */}
                                billed {activePaidTierPlanInterval.toLowerCase()}{' '}
                              </span>{' '}
                            </PriceField>
                          </>
                        )}

                        {/* <FeeField>{impactPlan?.fee}% Fee</FeeField> */}
                      </div>
                    )}
                  </div>
                  <Button
                    autoWidth
                    disabled={isImpactPlan}
                    onClick={handleOnClick}
                    style={{
                      margin: '35px auto 0px auto',
                      fontSize: '14px',
                      padding: '16px 40px',
                      fontWeight: '700',
                      display: 'flex',
                      justifyContent: 'center',
                      color: '#FFFFFF',
                      fontFamily: 'Inter',
                      fontStyle: 'normal',
                    }}
                  >
                    {buttonLabel}
                  </Button>
                  <div style={{ cursor: 'pointer' }}>
                    <SecondaryP
                      onClick={() => window.open('https://calendly.com/anettecohere/20min', '_blank')}
                      style={{
                        cursor: 'pointer',
                        marginBottom: '40px',
                        marginTop: '12px',
                        color: '#AFAFAF',
                        justifyContent: 'center',
                        display: 'flex',
                      }}
                    >
                      Book Demo Call
                    </SecondaryP>
                  </div>
                </>
              ) : (
                <>
                  <Box
                    style={{ display: 'flex', flexGrow: 1, justifyContent: 'space-between' }}
                    className={classes.purchase_div}
                  >
                    <div
                      className={classes.purchase_div}
                      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                    >
                      {/* <div>
                        <Button
                          style={{
                            padding: '9px 8px',
                            maxWidth: '86px',
                            margin: 'auto',
                            fontSize: '12px',
                            fontWeight: '600',
                            borderRadius: '4px',
                          }}
                          autoWidth
                        >
                          Purchase Plan
                        </Button>
                      </div> */}
                      {!isEmpty(activePaidTierPlan) && (
                        <div>
                          <Box
                            style={{
                              // marginLeft: '6px',
                              borderRadius: '3px',
                              // width: '220px',
                              backgroundColor: '#215C73',
                              color: 'white',
                              paddingInline: '5px',
                            }}
                          >
                            <h4
                              style={{
                                fontWeight: 600,
                                fontSize: '14px',
                                // paddingLeft: '8px',
                                paddingTop: '2px',
                                paddingBottom: '2px',
                                color: 'white',
                              }}
                            >
                              {/* TODO: revert the label when the event week concludes */}
                              {/* Annual Impact Plan & Scale Your Coaching Bundle */}
                              {planName}
                              {/* {impactPlan?.displayNameExtraText} */}
                              {/* The Money Activation Bundle by Cohere and Allyson Byrd */}
                            </h4>
                          </Box>
                          {isLaunchPlan ? (
                            <PriceField style={{ fontWeight: 800, fontSize: '20px', marginLeft: '6px' }}>
                              ${fixedPrice(impactPlan?.pricePerYear / monthCount)}
                              <span
                                style={{
                                  fontSize: '19px',
                                  fontWeight: '300',
                                  color: '#000',
                                }}
                              >
                                {' '}
                                / month (
                                <span style={{ fontWeight: 800, fontSize: '18px' }}>
                                  ${fixedPrice(impactPlan?.pricePerYear)}
                                </span>{' '}
                                billed annually){' '}
                              </span>
                            </PriceField>
                          ) : (
                            <PriceField style={{ fontWeight: 800, fontSize: '20px', marginLeft: '6px' }}>
                              ${activePaidTierTotalPurchasePrice}
                              <span
                                style={{
                                  fontSize: '19px',
                                  fontWeight: '300',
                                  color: '#000',
                                }}
                              >
                                {' '}
                                / billed {activePaidTierPlanInterval.toLowerCase()}{' '}
                              </span>
                            </PriceField>
                          )}

                          {/* <FeeField>{impactPlan?.fee}% Fee</FeeField> */}
                        </div>
                      )}
                    </div>
                    <div>
                      <div
                        className={classes.purchase_div}
                        style={{ marginTop: '3px', display: 'flex', justifyContent: 'end' }}
                      >
                        {!(activePaidTierPlanEndDateTime != null && !isLaunchPlan) && (
                          <SecondaryP
                            onClick={() => window.open('https://calendly.com/anettecohere/20min', '_blank')}
                            style={{
                              cursor: 'pointer',
                              color: '#AFAFAF',
                              alignItems: 'center',
                              display: 'flex',
                              marginRight: '16px',
                              fontWeight: 700,
                            }}
                          >
                            Book Demo Call
                          </SecondaryP>
                        )}

                        {activePaidTierPlanEndDateTime != null && !isLaunchPlan ? (
                          <div style={{ display: 'flex', alignItems: 'end' }}>
                            <ExpiresLabel
                              className={classes.expiresLabelMedia}
                              style={{ marginBottom: 0, lineHeight: 1.9 }}
                            >
                              {/* Your {paymentPeriodLabel} {impactPlan?.displayName} {nextOption} on {formatDate}.  */}
                              Your plan renews on {formatDate}
                            </ExpiresLabel>
                          </div>
                        ) : (
                          buttonLabel != 'Cancel' && (
                            <Button
                              autoWidth
                              disabled={isImpactPlan}
                              onClick={handleOnClick}
                              style={{
                                padding: '20px 20px',
                                fontSize: '17px',
                              }}
                            >
                              {buttonLabel}
                            </Button>
                          )
                        )}

                        {/* <Button
                          autoWidth
                          disabled={isImpactPlan}
                          onClick={handleOnClick}
                          style={{
                            fontSize: '12px',
                            padding: '11px 24px',
                            // fontWeight: 600,
                            // fontFamily: 'Inter',
                            // fontStyle: 'normal',
                          }}
                        >
                          {buttonLabel}
                        </Button> */}
                      </div>
                    </div>
                  </Box>
                </>
              )}
            </Grid>
          </Grid>
          {/* <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
            <Button autoWidth>Purchase Plan</Button>
            <div style={{ marginLeft: '10px' }}>
              <h4>
                {/* TODO: revert the label when the event week concludes */}
          {/* Annual Impact Plan & Scale Your Coaching Bundle */}
          {/* {impactPlan?.displayName}
              {impactPlan?.displayNameExtraText} */}
          {/* </h4>
              <PriceField>
                ${fixedPrice(impactPlan?.pricePerYear / monthCount)}
                <span>/ month (<span style={{ fontWeight: 900 }}>${fixedPrice(impactPlan?.pricePerYear)}</span> billed annually)</span>
              </PriceField>
              <FeeField>{impactPlan?.fee}% Fee</FeeField>
            </div>
            <div style={{ display: 'flex' }}>
              <SecondaryP>Book Demo Call</SecondaryP>
              <Button
                autoWidth
                onClick={() => { }}
                variant="primary"
              >
                Upgrade & Join
              </Button>
            </div>
          </div> */}
          {/* <CohereAcademyTitle>Coher Platform</CohereAcademyTitle>
          <AdvantagesList>
            {advantages.map(el => {
              return <AdvantagesItem label={el} key={el} />;
            })}
          </AdvantagesList> */}
        </PlanColumn>
        {/* <div className={classes.academyColumn}>
          <div className={classes.academyContainerPlan}>
            <CohereAcademyTitle>Coher Platform</CohereAcademyTitle>
            <AdvantagesList>
              {advantages.map(el => {
                return <AdvantagesItem label={el} key={el} />;
              })}
            </AdvantagesList>
            <div>
              <CohereAcademyTitle>{title}</CohereAcademyTitle>
              <CohereAcademyP>{description}</CohereAcademyP>
            </div>
            <AdvantagesList>
              {academyAdvantages.map(el => {
                return <AdvantagesItem label={el} key={el} />;
              })}
            </AdvantagesList>
          </div>
          <div className={classes.previewAcademyContainerPlan}>
            <img src={OnePreviewLogo} alt="" className={classes.academyImage} />
          </div>
        </div> */}
      </PaidTierExtended>
      {ResponsivemobileView ? (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div
            style={{
              cursor: 'pointer',
              borderRadius: '4px',
              padding: '5px 14px',
              width: '100px',
              backgroundColor: platformactive ? '#215C73' : '#F2F5F7',
            }}
            onClick={() => {
              setplatformactive(true);
              setacdemymactive(false);
              setvipactive(false);
              setcohereacdemyactive(false);
            }}
          >
            <TitleField
              style={{
                height: '27px',
                paddingLeft: '10px',
                paddingTop: '5px',
                width: '136px',
                fontWeight: 500,
                // fontStyle: 'normal',
                // fontFamily: 'Inter',
                color: platformactive ? 'white' : '#215C73',
                fontSize: '11px',
              }}
            >
              Platform
            </TitleField>
          </div>
          <div
            style={{
              cursor: 'pointer',
              borderRadius: '4px',
              padding: '0px 0px 0px 9px',
              width: '100px',
              backgroundColor: acdemyactive ? '#215C73' : '#F2F5F7',
              display: 'flex',
              justifyContent: 'end',
              alignItems: 'center',
            }}
            onClick={() => {
              setplatformactive(false);
              setacdemymactive(true);
              setvipactive(false);
              setcohereacdemyactive(true);
            }}
          >
            <TitleField
              style={{
                height: '27px',
                paddingLeft: '0px',
                paddingTop: '5px',
                width: '95px',
                fontWeight: 500,
                fontSize: '11px',
                color: acdemyactive ? 'white' : '#215C73',
                // fontStyle: 'normal',
                // fontFamily: 'Inter',
              }}
            >
              Cohere Academy
            </TitleField>
          </div>
          <div
            style={{
              cursor: 'pointer',
              borderRadius: '4px',
              padding: '5px 14px',
              backgroundColor: vipactive ? '#215C73' : '#F2F5F7',
              width: '122px',
            }}
            onClick={() => {
              setplatformactive(false);
              setacdemymactive(false);
              setvipactive(true);
              setcohereacdemyactive(false);
            }}
          >
            <TitleField
              style={{
                height: '27px',
                paddingLeft: '10px',
                paddingTop: '5px',
                width: '100px',
                fontWeight: 500,
                fontSize: '11px',
                color: vipactive ? 'white' : '#215C73',
                // fontStyle: 'normal',
                // fontFamily: 'Inter',
              }}
            >
              VIP Coaching
            </TitleField>
          </div>
        </div>
      ) : (
        ''
      )}

      {ResponsivemobileView ? (
        <div style={{ display: 'flex' }} className={classes.purchase_div}>
          {platformactive ? (
            <div style={{ display: 'flex' }}>
              <PlanCard
                PrimaryAmount="1,297"
                Primarytext="One Place to Sell, Schedule and Deliver your Online Services"
                fontSize="12px"
                title="All-In-One-Platform"
                advantages={allinoneplatformadvantages}
              />
            </div>
          ) : (
            ''
          )}
          {acdemyactive ? (
            <div style={{ display: 'flex' }}>
              <PlanCard
                PrimaryAmount="2,997"
                Primarytext="Monthly LIVE Group Coaching & Community"
                fontSize="12px"
                title="The Cohere Academy"
                advantages={thecohereacademyAdvantages}
              />
            </div>
          ) : (
            ''
          )}
          {vipactive ? (
            <div style={{ display: 'flex' }}>
              <PlanCard
                PrimaryAmount="597"
                Primarytext="Service Setup & Business Acceleration Support"
                fontSize="12px"
                title="VIP 1:1 Coaching"
                advantages={VIPAdvantages}
              />
            </div>
          ) : (
            ''
          )}
        </div>
      ) : (
        <div style={{ display: 'flex', justifyContent: 'space-between' }} className={classes.purchase_div}>
          <div style={{ display: 'flex', flex: 1, paddingLeft: '10px' }}>
            <PlanCard
              PrimaryAmount="1,297"
              Primarytext="One Place to Sell, Schedule and Deliver your Online Services"
              fontSize="12px"
              title="All-In-One-Platform"
              advantages={allinoneplatformadvantages}
            />
          </div>
          <div style={{ display: 'flex', flex: 1, paddingLeft: '10px' }}>
            <PlanCard
              PrimaryAmount="2,997"
              Primarytext="Monthly LIVE Group Coaching & Community"
              fontSize="12px"
              title="The Cohere Academy"
              advantages={thecohereacademyAdvantages}
            />
          </div>
          <div style={{ display: 'flex', flex: 1, paddingLeft: '10px' }}>
            <PlanCard
              PrimaryAmount="597"
              Primarytext="Service Setup & Business Acceleration Support"
              fontSize="12px"
              title="VIP 1:1 Coaching"
              advantages={VIPAdvantages}
            />
          </div>
        </div>
      )}

      {/* {!isLaunchPlan && (
        <Grid container justify="center">
          <div>
            <ExpiresLabel className={classes.expiresLabelMedia}>
              Your {paymentPeriodLabel} {impactPlan?.displayName} {nextOption} on {formatDate}.
            </ExpiresLabel>
          </div>
        </Grid>
      )}
      {!isCanceled && (
        <Grid container justify="center">
          <Button variant="secondary" disabled={isImpactPlan} onClick={handleOnClick}>
            {buttonLabel}
          </Button>
        </Grid>
      )} */}
      {(activePaidTierPlanEndDateTime != null && !isLaunchPlan) === false && (
        <div style={{ marginLeft: ResponsivemobileView ? '0px' : '9px' }} className={classes.bottombilling}>
          <div style={{ paddingLeft: ResponsivemobileView ? '0px' : '25px' }} className={classes.bottomtextstyle}>
            <PrimaryP fontFamily="Arial" textfont="14px">
              Ready to get started for just{' '}
              <span style={{ fontFamily: 'Inter', fontWeight: 600, color: '#215C73' }}>$1,297</span>?
            </PrimaryP>
            {/* <SecondaryP style={{ marginTop: '10px', color: '#939393' }} fontWeight={400} fontSize="12px">
            This offer has over 5,997 in value bundled for only $1,297.
          </SecondaryP> */}
          </div>
          <div style={{ lineHeight: '7px', marginTop: '4px', height: '35px' }} className={classes.bottombuttonstyle}>
            {!isLaunchPlan ? (
              <div style={{ display: 'flex', alignItems: 'end' }}>
                {/* <ExpiresLabel className={classes.expiresLabelMedia}>
                Your {paymentPeriodLabel} {impactPlan?.displayName} {nextOption} on {formatDate}.
              </ExpiresLabel> */}
              </div>
            ) : (
              <Button
                autoWidth
                disabled={isImpactPlan}
                onClick={handleOnClick}
                style={{
                  padding: '8px 24px',
                  fontSize: '12px',
                }}
              >
                {buttonLabel}
              </Button>
            )}
          </div>
        </div>
      )}
    </PlanCardContainer>
  );
};

export default PaidTierBigView;
