import { createAction } from 'redux-actions';
import { get, pipe } from 'lodash/fp';
import { createCampaignServices } from 'pages/CreateCampaign/Services/CreateCampaign.service';

export const CREATE_CAMPAIGN_REQUEST = 'CREATE_CAMPAIGN_REQUEST';
export const CREATE_CAMPAIGN_SUCCESS = 'CREATE_CAMPAIGN_SUCCESS';
export const CREATE_CAMPAIGN_ERROR = 'CREATE_CAMPAIGN_ERROR';
export const GET_CAMPAIGNS_REQUEST = 'GET_CAMPAIGNS_REQUEST';
export const GET_CAMPAIGNS_SUCCESS = 'GET_CAMPAIGNS_SUCCESS';
export const GET_CAMPAIGNS_ERROR = 'GET_CAMPAIGNS_ERROR';
export const SAVE_CAMPAIGN_TO_LS = 'SAVE_CAMPAIGN_TO_LS';
export const SAVE_CAMPAIGN = 'SAVE_CAMPAIGN';
export const RESET_CAMPAIGN = 'RESET_CAMPAIGN';
export const SET_CAMPAIGN = 'SET_CAMPAIGN';
export const SET_USER_CONTACTS = 'SET_USER_CONTACTS';
export const SET_CAMPAIGN_NOTIFICATION = 'SET_CAMPAIGN_NOTIFICATION';
export const SET_SEND_EMAIL_TO = 'SET_SEND_EMAIL_TO';

export const saveCampaign = createAction(SAVE_CAMPAIGN);
export const saveCampaignToLS = createAction(SAVE_CAMPAIGN_TO_LS);
export const resetCampaign = createAction(RESET_CAMPAIGN);
export const setCampaign = createAction(SET_CAMPAIGN);
export const setCampaignNotification = createAction(SET_CAMPAIGN_NOTIFICATION);
export const setSendEmailTo = createAction(SET_SEND_EMAIL_TO);

export const setUserContacts = createAction(SET_USER_CONTACTS);

export const createCampaignActions = {
  request: createAction(CREATE_CAMPAIGN_REQUEST),
  success: createAction(CREATE_CAMPAIGN_SUCCESS),
  error: createAction(CREATE_CAMPAIGN_ERROR),
};
export const getAllEmailCampaignsActions = {
  request: createAction(GET_CAMPAIGNS_REQUEST),
  success: createAction(GET_CAMPAIGNS_SUCCESS),
  error: createAction(GET_CAMPAIGNS_ERROR),
};

export const createDraftCampaignAsync = data => {
  return async dispatch => {
    dispatch(createCampaignActions.request());
    try {
      const response = await createCampaignServices.createDraftCampaign(data);
      dispatch(createCampaignActions.success(response));
    } catch (e) {
      dispatch(createCampaignActions.error(e.message));
    }
  };
};

export const fetchAllEmailCampaignsAsync = () => {
  return async dispatch => {
    dispatch(getAllEmailCampaignsActions.request());
    try {
      const response = await createCampaignServices.getAllEmailCampaigns();
      dispatch(getAllEmailCampaignsActions.success(response));
    } catch (e) {
      dispatch(getAllEmailCampaignsActions.error(e.message));
    }
  };
};
