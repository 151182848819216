import React, { useState, useEffect, useCallback } from 'react';
import { useFormikContext } from 'formik';
import _ from 'lodash';
import PropTypes from 'prop-types';

const AutoSave = ({ debounceMs = 1000, loading }) => {
  const formik = useFormikContext();
  const [isSaved, setIsSaved] = useState(null);

  const debouncedSubmit = useCallback(
    _.debounce(() => {
      return formik.submitForm().then(() => setIsSaved(true));
    }, debounceMs),
    [formik.submitForm, debounceMs],
  );

  useEffect(() => debouncedSubmit, [debouncedSubmit, formik.values]);

  return (
    <p className="text-center text-success">
      {loading && <p style={{ color: '#d1b989' }}>Saving... </p>}
      {isSaved ? <p style={{ color: '#d1b989' }}>Your changes saved. </p> : null}
    </p>
  );
};

AutoSave.propTypes = {
  debounceMs: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default AutoSave;
