import { NAVIGATION_ACTION_TYPES } from 'actions/Navigation';

const initialState = {
  isTransitionedToCoachView: false,
};

export const navigationReducer = (state = initialState, action) => {
  const { payload, type } = action;

  switch (type) {
    case NAVIGATION_ACTION_TYPES.RESET_STATE:
      return initialState;
    case NAVIGATION_ACTION_TYPES.SET_IS_TRANSITIONED_TO_COACH_VIEW:
      return {
        ...state,
        isTransitionedToCoachView: payload.isTransitionedToCoachView,
      };
    default:
      return state;
  }
};
