import React, { useState, useEffect, useCallback } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import withStyles from '@material-ui/core/styles/withStyles';
import { useTheme, useMediaQuery } from '@material-ui/core';
import { Pagination } from '@mui/material';
import PayOutsRow from '../PayoutsRow';

const BoldTableCell = withStyles({
  root: {
    fontWeight: 800,
    padding: 10,
    width: '33%',
  },
})(TableCell);

const StyledTableContainer = withStyles({
  root: {
    overflowX: 'auto',
    maxWidth: props => (props.mobileView ? '96vw' : '100%'),
    margin: '0 auto',
    border: props => (props.isModal ? '1px solid #DFE3E4' : ''),
  },
})(TableContainer);

const PayoutsTable = ({ contacts = [], isChecked, onCheckClicked, isModal = false }) => {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const [pageNumber, setpageNumber] = useState(1);
  const [totalRecords, settotalrecords] = useState(0);
  const round = Math.ceil(100 / 20);
  const handleChange = useCallback((event, value) => {
    if (value === 1) {
      setpageNumber(1);
      //   GetAllEnrollmentFormsByCoachFunction(1);
    } else if (value > 1) {
      setpageNumber(value);
      //   GetAllEnrollmentFormsByCoachFunction(value);
    } else {
      setpageNumber(value);
      //   GetAllEnrollmentFormsByCoachFunction(value);
    }
  }, []);
  return (
    <>
      <StyledTableContainer isModal={isModal} component={Paper} mobileView={mobileView}>
        <Table className="" aria-label="contributions table">
          <TableHead>
            <TableRow>
              <BoldTableCell>Amount</BoldTableCell>
              <BoldTableCell>Bank</BoldTableCell>
              <BoldTableCell> Date</BoldTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {[1, 2, 3].map((row, index) => (
              <PayOutsRow key={index} index={index} />
            ))}
          </TableBody>
        </Table>
        <div style={{ width: '100%', display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
          <div style={{ fontSize: '13px', fontWeight: '400', position: 'relative', bottom: '-12px' }}>
            {pageNumber === 0 ? 1 : pageNumber * 10 - 10 + 1} - {round != pageNumber ? pageNumber * 10 : totalRecords}{' '}
            of {totalRecords}
          </div>
          <Pagination
            count={round}
            page={pageNumber}
            onChange={handleChange}
            className={`client-info-table-pagination`}
            size="small"
            shape="rounded"
          />
        </div>
      </StyledTableContainer>
    </>
  );
};

export default PayoutsTable;
