import { useCallback, useState } from 'react';
import { LocalVideoTrack } from 'twilio-video';

import { tryGetFirefoxVersion } from 'utils/userAgent';
import VideoChatVideoTrackType from 'components/UI/VideoChat/VideoChatVideoTrackType';

const oldFirefoxVersion = 65;

export const useScreenTrack = () => {
  const [screenTrack, setScreenTrack] = useState();
  const isEnabled = Boolean(screenTrack);

  const stopLocalScreenTrack = useCallback(() => {
    console.info('COHERE: stopLocalScreenTrack ended handler');
    setScreenTrack(undefined);
  }, []);

  const setIsLocalScreenTrackEnabled = useCallback(
    enabled => {
      const startLocalScreenTrack = async () => {
        try {
          if (!screenTrack) {
            const firefoxVersion = tryGetFirefoxVersion();
            const screenStream =
              firefoxVersion && firefoxVersion <= oldFirefoxVersion
                ? await navigator.mediaDevices.getUserMedia({
                    mediaSource: 'window',
                  })
                : await navigator.mediaDevices.getDisplayMedia({ video: true });
            const screenStreamTrack = screenStream.getTracks()[0];

            const createdScreenTrack = new LocalVideoTrack(screenStreamTrack, {
              name: VideoChatVideoTrackType.screenShare,
            });
            screenStreamTrack.onended = stopLocalScreenTrack;

            setScreenTrack(createdScreenTrack);
          }
        } catch (e) {
          console.log('Failed to start screen sharing.', e);
        }
      };
      if (enabled) {
        startLocalScreenTrack();
      } else {
        stopLocalScreenTrack();
      }
    },
    [screenTrack, stopLocalScreenTrack],
  );

  return {
    screenTrack,
    isEnabled,
    setIsEnabled: setIsLocalScreenTrackEnabled,
  };
};
