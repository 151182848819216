import React from 'react';
import OtherLinks from './OtherLinks';
import Questions from './Questions';

function Links({ firstName, data, theme, profileImage, brandingColors, label }) {
  const isMessagingEnabled = data?.isMessagingEnabled || false;
  const customLinks = data?.customLinks || [];
  const defaultBgColor = theme === 'dark' ? '#2A2C2E' : 'white';
  const primaryColor = brandingColors?.PrimaryColorCode || defaultBgColor;

  return (
    <>
      <div className="coach-website-links">
        <OtherLinks
          firstName={firstName}
          profileImage={profileImage}
          customLinks={customLinks}
          theme={theme}
          label={label}
          primaryColor={primaryColor}
          data={data}
        />
        {isMessagingEnabled && <Questions data={data} theme={theme} brandingColors={brandingColors} />}
      </div>
    </>
  );
}

export default Links;
