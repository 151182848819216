import React, { useState, useCallback, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Grid, Popover } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core/styles';
import { Formik, Field, Form } from 'formik';
import { connect, useDispatch } from 'react-redux';
import { setItem, getItem } from 'services/localStorage.service';
import Logo from 'components/FormUI/Logo';
import Input from 'components/FormUI/Input';
import { ModalTermsAndConditions } from 'components/Modals/TermsAndConditions';
import Button from 'components/FormUI/Button';
import CommonErrorMessage from 'components/FormUI/CommonErrorMessage';
import { Card, CardBody } from 'components/UI/Card';
import { getSignUpClientValidationSchema } from 'utils/validation';
import * as userActions from 'actions/user';
import { toRem } from 'utils/styles';
import Select from 'components/FormUI/Select';
import { colors } from '../../utils/styles/styles.js';
import * as timeZoneActions from 'actions/timeZone';
import { UserRoles } from 'helpers/constants';
import { useRouter } from 'hooks/index';
import { ROUTES } from '../../constants';

const WelcomeMessage = styled.h1`
  font-size: ${toRem(22.3)};
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.25px;
  text-align: center;
  color: rgba(0, 0, 0, 0.87);
  margin-bottom: 1.5rem;
`;

const AgreementContainer = styled.div`
  text-align: center;
  margin: 1.5rem 0;
  line-height: 1.28;
  letter-spacing: 0.25px;
  font-weight: 500;
  font-size: 0.9375rem;
  width: 100%;

  a {
    color: inherit;
  }
`;

const ButtonsContainer = styled.div`
  margin-top: 2.5rem;
  text-align: center;
  width: 100%;

  & > * + * {
    margin-top: 1.5rem;
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  line-height: 1.17;
  letter-spacing: 0.4px;
  font-size: 0.875rem;
  font-weight: 300;

  &:hover {
    text-decoration: underline;
  }
`;

const StyledTerms = styled.a`
  color: ${colors.darkOceanBlue} !important;
`;
const StyledMainCard = styled(Card)`
  max-width: 580px;
  margin: auto;
`;
const useStyles = makeStyles(theme => ({
  popover: {
    pointerEvents: 'none',
    fontSize: 14,
  },
  paper: {
    padding: theme.spacing(1),
    transform: 'translateY(-10px) !important',
    maxWidth: 500,
  },
  container: {
    maxWidth: 520,
  },
  link: {
    marginTop: '1rem',
    display: 'flex',
    justifyContent: 'center',
  },
}));

function SignUpClientSecondScreen({
  loading,
  user,
  continueRegister,
  error,
  timeZoneLoading,
  timeZones,
  fetchTimeZones,
}) {
  const classes = useStyles();
  const [showTerms, setShowTerms] = useState(false);
  const [anchorEl] = useState(null);
  const accId = getItem('accountId');
  const registerHandler = useCallback(
    values => {
      const data = {
        ...values,
        accountId: user.accountId || accId,
        BusinessType: 'Coaching',
      };
      continueRegister({ fields: data, userView: UserRoles.client });
    },
    [continueRegister, user.accountId],
  );

  useEffect(() => {
    if ((!timeZones || timeZones.length === 0) && !timeZoneLoading) {
      fetchTimeZones();
    }
  }, [timeZones, fetchTimeZones]);

  return (
    <>
      <StyledMainCard>
        <CardBody>
          <Grid
            container
            direction="column"
            alignItems="stretch"
            classes={{
              container: classes.container,
            }}
          >
            <Grid container item>
              <Logo />
            </Grid>
            <Grid item>
              <WelcomeMessage>Register on Cohere</WelcomeMessage>
            </Grid>

            <Formik
              initialValues={{
                FirstName: '',
                LastName: '',
                HasAgreedToTerms: false,
              }}
              validationSchema={getSignUpClientValidationSchema()}
              onSubmit={registerHandler}
            >
              {({ values }) => (
                <Form id="signUpFormTwo">
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Input label="First Name" type="text" name="FirstName" fullWidth />
                    </Grid>
                    <Grid item xs={12}>
                      <Input label="Last Name" type="text" name="LastName" fullWidth />
                    </Grid>
                    <Grid item xs={12}>
                      <Select
                        label="Timezone"
                        name="TimeZoneId"
                        fullWidth
                        items={
                          timeZones?.length > 0
                            ? timeZones.map(timeZone => {
                                return { value: timeZone.countryName, title: timeZone.name };
                              })
                            : []
                        }
                      />
                    </Grid>
                  </Grid>

                  <Grid container>
                    <AgreementContainer>
                      <Field as={Checkbox} name="HasAgreedToTerms" type="checkbox" color="primary" />I agree to the{' '}
                      <StyledTerms
                        href="/"
                        onClick={e => {
                          e.preventDefault();
                          setShowTerms(true);
                        }}
                      >
                        Terms and Conditions
                      </StyledTerms>
                      , and I'm at least 18 years old.
                    </AgreementContainer>
                  </Grid>

                  {error?.message && <CommonErrorMessage message={error.message} />}

                  <Grid container>
                    <ButtonsContainer>
                      <Button disabled={!values.HasAgreedToTerms || loading} type="submit">
                        Join Cohere
                      </Button>
                    </ButtonsContainer>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Grid>
        </CardBody>
      </StyledMainCard>

      <Popover
        elevation={2}
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        Are you an inspiring coach, teacher, or mentor in the field of personal, professional, or spiritual development?
        Then this platform is for you! Cohere helps you scale your businesses using a single platform. Your customers
        can purchase and participate in your group courses, work with you 1:1, and join your community of existing
        clients and customers.
      </Popover>

      <div className={classes.link}>
        <StyledLink to="/auth/signup/continue">Are you a service provider? Click here</StyledLink>
      </div>

      <ModalTermsAndConditions showTerms={showTerms} onCancel={() => setShowTerms(false)} />
    </>
  );
}

SignUpClientSecondScreen.propTypes = {
  loading: PropTypes.bool.isRequired,
  user: PropTypes.shape({
    accountId: PropTypes.string,
  }).isRequired,
  continueRegister: PropTypes.func.isRequired,
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      message: PropTypes.string,
    }),
  ]),
  timeZoneId: PropTypes.string,
  timeZone: PropTypes.shape({
    timeZones: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  timeZoneLoading: PropTypes.bool.isRequired,
  fetchTimeZones: PropTypes.func.isRequired,
};

SignUpClientSecondScreen.defaultProps = {
  error: null,
  fetchTimeZones: () => null,
};

const mapStateToProps = ({ account, timeZone }) => ({
  user: account?.user || {},
  loading: account?.isLoading,
  error: account?.error || {},
  timeZones: timeZone?.timeZones,
  timeZoneLoading: timeZone?.loading,
});

const actions = {
  continueRegister: userActions.continueRegister,
  fetchTimeZones: timeZoneActions.fetchTimeZones,
};

export default connect(mapStateToProps, actions)(SignUpClientSecondScreen);
