import React from 'react';
import styled from 'styled-components';

export const StyledHeading = styled.div`
  font-family: Avenir;
  font-size: 22px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
  color: black;
  display: flex;
  align-items: center;
  gap: 8px;
`;
