import React, { useState, useEffect } from 'react';
import ReferalsTable from './Components/ReferalsTableContainer';
import classes from './cohereaffiliatereferals.module.scss';
import Button from 'components/FormUI/Button';
import ShareIcon from 'assets/ic-share-white.svg';
import styled from 'styled-components';
import InviteCoachesModal from 'pages/AffiliatesPage/components/Modals/InviteCoachesModal/InviteCoaches.Modal';

const Icon = styled.img`
  vertical-align: middle;
  object-fit: contain;
  margin-right: 7px;
`;
export default function CohereAffilliateReferals({ affiliateData = [] }) {
  const [showInviteModal, setShowInviteModal] = useState(false);

  return (
    <div className={classes.container}>
      {affiliateData.length === 0 ? (
        <div className={classes.container_norefer}>
          <div className={classes.no_refrealls}>No referrals yet.</div>
          <Button
            className={classes.welcome_button}
            autoWidth
            onClick={() => {
              setShowInviteModal(true);
            }}
          >
            <Icon src={ShareIcon} />
            Invite Coaches
          </Button>
        </div>
      ) : (
        <ReferalsTable affiliateData={affiliateData} />
      )}

      <InviteCoachesModal setShowInviteModal={setShowInviteModal} showInviteModal={showInviteModal} />
    </div>
  );
}
