import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import humanizeDuration from 'humanize-duration';
import Button from 'components/FormUI/Button';
import { downloadFileByLink, isEmptyOrNil, lightOrDark } from 'utils/utils';
import { useContributionTheme, useHttp, useRouter } from 'hooks';
import './SessionTime2.scss';
import { TemplateType } from 'helpers/constants/templateType';
import { useAccount, useShallowEqualSelector } from 'hooks';
import CloseIcon from '@material-ui/icons/Close';
import './SessionTime.scss';
import { UserRoles } from 'helpers/constants';
import { FormControl, Grid, InputLabel, MenuItem, Paper, Select, useMediaQuery, Tooltip } from '@material-ui/core';
import { BiCalendar } from 'react-icons/bi';
import { RiArrowDropDownLine, RiArrowDropUpLine } from 'react-icons/ri';
import styled from 'styled-components';
import { addToGoogleCalender, determineColorToUse, getThemedColors } from 'services/contributions.service';
import { useSelector } from 'react-redux';
import { redirectTo } from 'services/links';
import { useContribution } from 'pages/ContributionView/hooks';
import NoteModal from 'pages/ContributionView/components/NoteModal/NoteModalContainer';
import { useSessionAttachments } from 'hooks/useSessionAttachments';
import * as R from 'ramda';
import DocIcon from 'assets/docicon';
import Modal from 'components/UI/Modal';
import { borderColor } from 'polished';
import { BottomPanel } from '../BottomPanel/BottomPanel';

const CalendarContainer = styled.div`
  // width: 15%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
`;

const CalendarMenu = styled.div`
  width: 220px;
  background-color: white;
  color: black;
  box-shadow: 0 0px 10px 0px rgba(64, 60, 67, 0.16);
  position: absolute;
  ${({ mobileView, isCoach }) =>
    mobileView && isCoach
      ? `
       width: 190px;
      margin-top: 115px;
     right: -20px;
    `
      : mobileView === false && isCoach
      ? `margin-top: 85px;
     right: -53px;
    `
      : mobileView && isCoach === false
      ? `margin-top: 115px;
     right: 150px;
    `
      : `margin-top: 90px;
     right: -53px;
    `}
  z-index: 1000;
`;
const StyledHeading = styled.div`
  font-family: Avenir;
  font-size: 15px;
  font-weight: 900;
  line-height: 24px;
  color: #000000d1;
  margin-bottom: 8px;
`;
const StyledContent = styled.div`
  font-family: Avenir;
  font-size: 15px;
  font-weight: 350;
  line-height: 24px;
  color: #000000d1;
  margin-bottom: 8px;
`;
const AttachmentsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  padding: 10px 0;
`;
const StyledDiv = styled.div`
  display: flex;
  width: 100%;
  gap: 8px;
  align-items: baseline;
  margin-bottom: 8px;
`;
const StyledRowDiv = styled.div`
  display: flex;
  width: 100%;
  gap: 8px;
`;

const SessionTime = ({
  className,
  // startTime,
  // endTime,
  // participantId,
  session,
  timeDetails,
  session: { id, isCompleted, startTime, endTime, attachments, participantId },
  booked,
  renderActions,
  activeTemplate,
  handleReschedule,
  btnColor = { btnColor },
  textColor = { textColor },
  mobileView,
  timeZoneLabel,
  handleRescheduleCoach,
  accentColor,
  contributionId,
  sessionId,
  bottomPanelAttachments,
}) => {
  const { request } = useHttp();
  const { currentRole } = useAccount();
  const isCoach = currentRole === UserRoles.cohealer;
  const { protocol, domain, history } = useRouter();
  const userProfileUrl = new URL('/account/profile', `${protocol}//${domain}`).toString();
  const isIpad = useMediaQuery('(min-width: 768px) and (max-width: 1024px)');
  const isRescheduleDisabled = isCoach
    ? false
    : !moment(session.startTime).isAfter(moment().add(24, 'h')) || session.isCompleted;
  const dropdownRef = useRef(null);
  const [isVisibleCalendarDropdown, setIsVisibleCalendarDropdown] = useState(false);
  const activeContribution = useSelector(state => state.contributions?.activeContribution);
  const [modalOpened, setModalOpened] = useState(false);
  const [showAll, setShowAll] = useState(false);
  const contribution = useContribution();
  let colorToUse = determineColorToUse(contribution);
  const { themedColor } = getThemedColors(contribution);
  const {
    themedColor: themedTextColor,
    themedCardBackgroundColor: oldThemedCardBackgroundColor,
    themedBackgroundColor: oldThemedBackgroundColor,
    newThemedTextColor,
    newThemedCardColor,
    newThemedBackgroundColor,
    themedCardOutlineColor,
    themedColorBtn,
  } = getThemedColors(activeContribution);
  const { handleDownloadFile, handleRemoveFile } = useSessionAttachments(sessionId, contributionId, true);
  const visibleAttachments =
    bottomPanelAttachments.length > 4 ? bottomPanelAttachments.slice(0, 4) : bottomPanelAttachments;

  const { firstName, lastName } = useShallowEqualSelector(
    state =>
      (state?.contributions?.activeContribution?.participants || []).find(client => client.id === participantId) || {},
  );
  function formatString(text) {
    if (text.length > 20) {
      return text.slice(0, 10) + '...';
    }
    return text;
  }
  const startTimeMoment = moment(startTime);
  const endTimeMoment = moment(endTime);
  const duration = humanizeDuration(endTimeMoment.diff(startTimeMoment));

  const handleDownloadChatFile = async fileName => {
    const result = await request(
      `/Zoom/GetPresignedUrlForRecordedSession/${session.zoomMeetingData.meetingId}/${fileName}?asAttachment=true`,
      'GET',
    );
    downloadFileByLink(result, fileName);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  });

  const onClickAddToGoogleCalender = async (type = false) => {
    const data = {
      contributionId: activeContribution.id,
      AvailabilityTimeId: timeDetails?.timeId,
      isCoach,
      isDownloadable: type === 'Download',
    };
    const addToCalenderURL = await addToGoogleCalender(data);
    window.open(addToCalenderURL, '_blank');
    setIsVisibleCalendarDropdown(!isVisibleCalendarDropdown);
  };

  const handleOutsideClick = e => {
    if (!isVisibleCalendarDropdown) {
      return;
    }
    if (
      dropdownRef &&
      dropdownRef?.current &&
      !e?.target?.className?.includes('clickedOutSide') &&
      !dropdownRef?.current?.contains(e.target)
    ) {
      setIsVisibleCalendarDropdown(false);
    }
  };
  const handleOpenModal = () => {
    setModalOpened(true);
  };

  const handleCloseModal = () => {
    setModalOpened(false);
  };
  const chatFiles = session?.zoomMeetingData?.chatFiles?.map(chatFile => (
    <div key={chatFile} className="course-session-time__actions" style={{ width: '100%' }}>
      <Button
        classes={{
          root: 'text-lowercase regular-tex download-attach',
        }}
        onClick={() => handleDownloadChatFile(chatFile)}
        disableRipple
      >
        <span className="ml-2">{chatFile}</span>
      </Button>
    </div>
  ));
  return (
    <>
      {activeTemplate != TemplateType.TemplateThree && !isCoach && mobileView ? (
        <>
          <StyledRowDiv>
            <div style={{ flex: 1 }}>
              {startTime && (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <StyledHeading style={{ color: themedColor }}>Date</StyledHeading>
                  <StyledContent style={{ color: themedColor }}>{startTimeMoment.format('MMMM Do YYYY')}</StyledContent>
                </div>
              )}
            </div>
            <div style={{ flex: 1 }}>
              {startTime && (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <StyledHeading style={{ color: themedColor }}>Start Time</StyledHeading>
                  <StyledContent style={{ color: themedColor }}>{startTimeMoment.format('h:mmA')}</StyledContent>
                </div>
              )}
            </div>
          </StyledRowDiv>

          <StyledRowDiv>
            <div style={{ flex: 1 }}>
              {startTime && (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <StyledHeading style={{ color: themedColor }}>End Time</StyledHeading>
                  <StyledContent style={{ color: themedColor }}>{endTimeMoment.format('h:mmA')}</StyledContent>
                </div>
              )}
            </div>
            <div style={{ flex: 1 }}>
              {startTime && timeZoneLabel && (
                <div
                  style={
                    !isCoach && activeTemplate != TemplateType.TemplateThree
                      ? { display: 'flex', flexDirection: 'column' }
                      : { display: 'flex', flexDirection: 'column' }
                  }
                >
                  <StyledHeading
                    style={{ color: themedColor }}
                    className="one-to-one-session-time__label one-to-one-session-time__label--type--end-time"
                  >
                    Time Zone
                  </StyledHeading>
                  <StyledContent
                    onClick={() => {
                      redirectTo(userProfileUrl);
                    }}
                    style={{ textDecoration: 'underline', cursor: 'pointer', color: themedColor }}
                    className="one-to-one-session-time__value one-to-one-session-time__value--type--end-time"
                  >
                    {timeZoneLabel}
                  </StyledContent>
                </div>
              )}
            </div>
          </StyledRowDiv>

          {!activeContribution.externalCalendarEmail && session?.id && (
            <StyledDiv>
              <p
                ref={dropdownRef}
                onClick={() => setIsVisibleCalendarDropdown(!isVisibleCalendarDropdown)}
                style={{ cursor: 'pointer', fontSize: '14px', fontWeight: '800', flex: 1, color: themedColor }}
                className="one-to-one-session-time__label one-to-one-session-time__label--type--duration clickedOutSide"
              >
                <BiCalendar style={{ fontSize: 20 }} /> Add to Calendar
                {!isVisibleCalendarDropdown ? (
                  <RiArrowDropDownLine style={{ fontSize: 20 }} />
                ) : (
                  <RiArrowDropUpLine style={{ fontSize: 20 }} />
                )}
              </p>
              <span
                tabIndex="0"
                role="button"
                className="h4 notes"
                onClick={handleOpenModal}
                onKeyPress={handleOpenModal}
                style={{ flex: 1 }}
              >
                {/* <span style={{ color: themedColor }}>Personal Notes</span> */}
                <div
                  style={{
                    padding: '10px',
                    borderRadius: '4px',
                    border: `1px solid ${colorToUse.AccentColorCode}`,
                    width: '106px',
                  }}
                >
                  <div
                    style={{
                      fontFamily: 'Avenir',
                      fontSize: '14px',
                      fontWeight: 900,
                      color: colorToUse.AccentColorCode,
                    }}
                  >
                    Open Notes
                  </div>
                </div>
              </span>
              {isVisibleCalendarDropdown && (
                <CalendarContainer className="clickedOutSide" ref={dropdownRef}>
                  <CalendarMenu className="clickedOutSide" mobileView={mobileView} isCoach={isCoach}>
                    <MenuItem
                      onClick={() => onClickAddToGoogleCalender('Download')}
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        borderBottom: '0.5px solid gray',
                        ...(activeContribution?.isDarkModeEnabled && {
                          backgroundColor: newThemedBackgroundColor,
                          color: themedTextColor,
                        }),
                      }}
                    >
                      Download iCal
                    </MenuItem>
                    <MenuItem
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        ...(activeContribution?.isDarkModeEnabled && {
                          backgroundColor: newThemedBackgroundColor,
                          color: themedTextColor,
                        }),
                      }}
                      onClick={() => onClickAddToGoogleCalender()}
                    >
                      Add to Google Calendar
                    </MenuItem>
                  </CalendarMenu>
                </CalendarContainer>
              )}
            </StyledDiv>
          )}
          <>
            <StyledRowDiv>
              <span
                style={{
                  color: themedColor,
                  fontFamily: 'Avenir',
                  fontSize: '15px',
                  fontWeight: 900,
                }}
              >
                Attachments
              </span>
              {isEmptyOrNil(bottomPanelAttachments) && (
                <span style={{ color: 'gray', marginLeft: '5px', fontSize: '13px' }}>None</span>
              )}
            </StyledRowDiv>
            {!isEmptyOrNil(bottomPanelAttachments) && (
              <AttachmentsContainer>
                {visibleAttachments.map(
                  (
                    { id: documentId, contentType, documentKeyWithExtension, documentOriginalNameWithExtension },
                    idx,
                  ) => (
                    <div style={isCoach ? { marginTop: '9px' } : {}} key={documentId}>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          border: `1px solid #DFE3E4`,
                          padding: '9px',
                          borderRadius: '4px',
                          marginLeft: mobileView ? '' : '5px',
                          backgroundColor: '#FAFAFA',
                        }}
                        onClick={handleDownloadFile}
                        disableRipple
                        data-document-id={documentId}
                        data-document-name={documentOriginalNameWithExtension}
                        data-content-type={contentType}
                      >
                        <div style={{ display: 'flex', gap: '5px' }}>
                          <div
                            style={{
                              display: 'flex',
                              gap: '5px',
                              cursor: 'pointer',
                              color: colorToUse.AccentColorCode,
                            }}
                          >
                            <DocIcon color={colorToUse.AccentColorCode} />
                            <Tooltip title={documentOriginalNameWithExtension}>
                              <div> {formatString(documentOriginalNameWithExtension)}</div>
                            </Tooltip>
                          </div>
                          {isCoach && (
                            <CloseIcon
                              style={{ marginTop: '2px' }}
                              fontSize="small"
                              className="cursor-pointer"
                              onClick={handleRemoveFile}
                              data-document-id={documentId}
                              data-document-extension={documentKeyWithExtension}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  ),
                )}

                {bottomPanelAttachments.length > 4 && (
                  <div
                    onClick={() => setShowAll(!showAll)}
                    style={{
                      marginTop: isCoach ? '20px' : '10px',
                      marginLeft: mobileView ? '' : '5px',
                      color: colorToUse.AccentColorCode,
                      cursor: 'pointer',
                      textDecoration: 'underline',
                    }}
                  >
                    {'Show More'}
                  </div>
                )}
              </AttachmentsContainer>
            )}
          </>

          <div style={{ display: 'flex', width: '100%', flexDirection: 'row' }}>
            {activeTemplate === TemplateType.TemplateThree || isCoach || !session?.id ? (
              <>
                <div className="one-to-one-session-time__actions">{renderActions(session)}</div>
              </>
            ) : (
              <>
                {session?.id && (
                  <div
                    style={{
                      display: 'flex',
                      width: '100%',
                      flexDirection: 'row',
                      alignItems: 'center',
                      gap: mobileView ? '5px' : '16px',
                    }}
                  >
                    {session.isPurchaseConfirmed === true && (
                      <Button
                        type="button"
                        onClick={handleReschedule}
                        disabled={isRescheduleDisabled}
                        invert
                        color={colorToUse?.AccentColorCode}
                        borderColor={isRescheduleDisabled ? '' : colorToUse?.AccentColorCode}
                        textColor={colorToUse?.AccentColorCode}
                        autoWidth
                      >
                        Reschedule
                      </Button>
                    )}
                    {renderActions(session)}
                  </div>
                )}
              </>
            )}
          </div>
          <div>{session?.zoomMeetingData?.chatFiles?.length > 0 && <>{chatFiles}</>}</div>
        </>
      ) : (
        <>
          <div
            className={classNames(className, 'one-to-one-session-time')}
            style={activeTemplate === TemplateType.TemplateThree || isCoach ? {} : { flexWrap: isIpad ? '' : 'nowrap' }}
          >
            {startTime && (
              <div
                style={
                  activeTemplate === TemplateType.TemplateThree || isCoach
                    ? { width: mobileView ? '55%' : '12%' }
                    : { width: '20%' }
                }
              >
                <p className="one-to-one-session-time__label one-to-one-session-time__label--type--date">Date</p>
                <p className="one-to-one-session-time__value one-to-one-session-time__value--type--date">
                  {startTimeMoment.format('MMMM Do YYYY')}
                </p>
              </div>
            )}
            {startTime && (
              <div
                style={
                  !isCoach && activeTemplate != TemplateType.TemplateThree
                    ? { width: '20%' }
                    : { width: mobileView ? '45%' : '10%' }
                }
              >
                <p className="one-to-one-session-time__label one-to-one-session-time__label--type--start-time">
                  Start Time
                </p>
                <p className="one-to-one-session-time__value one-to-one-session-time__value--type--start-time">
                  {startTimeMoment.format('h:mmA')}
                </p>
              </div>
            )}
            {endTime && (
              <div
                style={
                  !isCoach && activeTemplate != TemplateType.TemplateThree
                    ? { width: '20%' }
                    : { width: mobileView ? '55%' : '10%', marginTop: '8px' }
                }
              >
                <p className="one-to-one-session-time__label one-to-one-session-time__label--type--end-time">
                  End Time
                </p>
                <p className="one-to-one-session-time__value one-to-one-session-time__value--type--end-time">
                  {endTimeMoment.format('h:mmA')}
                </p>
              </div>
            )}
            {startTime && timeZoneLabel && (
              <div
                style={
                  !isCoach && activeTemplate != TemplateType.TemplateThree
                    ? {}
                    : { width: mobileView ? '45%' : '10%', marginTop: '8px' }
                }
              >
                <p className="one-to-one-session-time__label one-to-one-session-time__label--type--end-time">
                  Time Zone
                </p>
                <p
                  onClick={() => {
                    redirectTo(userProfileUrl);
                  }}
                  style={{ textDecoration: 'underline', cursor: 'pointer' }}
                  className="one-to-one-session-time__value one-to-one-session-time__value--type--end-time"
                >
                  {timeZoneLabel}
                </p>
              </div>
            )}
            {startTime && (activeTemplate === TemplateType.TemplateThree || isCoach) && (
              <div
                style={{
                  width: mobileView && !activeContribution.externalCalendarEmail ? '55%' : mobileView ? '100%' : '7%',
                  marginTop: '8px',
                }}
              >
                <p className="one-to-one-session-time__label one-to-one-session-time__label--type--duration">
                  Duration
                </p>
                <p className="one-to-one-session-time__value one-to-one-session-time__value--type--duration">
                  {duration}
                </p>
              </div>
            )}
            {!activeContribution.externalCalendarEmail && session?.id && (
              <div
                style={
                  isCoach
                    ? {
                        width: mobileView ? '45%' : '180px',
                        display: 'flex',
                        justifyContent: mobileView ? '' : 'flex-end',
                        marginTop: '5px',
                        // marginBlock: 'auto',
                      }
                    : { width: '25%', display: 'flex', justifyContent: 'flex-end' }
                }
              >
                <p
                  ref={dropdownRef}
                  onClick={() => setIsVisibleCalendarDropdown(!isVisibleCalendarDropdown)}
                  style={{ cursor: 'pointer' }}
                  className="one-to-one-session-time__label one-to-one-session-time__label--type--duration clickedOutSide"
                >
                  <BiCalendar style={{ fontSize: 20, marginBottom: '8px' }} /> Add to Calendar
                  {!isVisibleCalendarDropdown ? (
                    <RiArrowDropDownLine style={{ fontSize: 20 }} />
                  ) : (
                    <RiArrowDropUpLine style={{ fontSize: 20 }} />
                  )}
                </p>
                {isVisibleCalendarDropdown && (
                  <CalendarContainer className="clickedOutSide" ref={dropdownRef}>
                    <CalendarMenu className="clickedOutSide" mobileView={mobileView} isCoach={isCoach}>
                      <MenuItem
                        onClick={() => onClickAddToGoogleCalender('Download')}
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-start',
                          borderBottom: '0.5px solid gray',
                          fontSize: mobileView ? '15px' : '',
                          ...(activeContribution?.isDarkModeEnabled && {
                            backgroundColor: newThemedBackgroundColor,
                            color: themedTextColor,
                          }),
                        }}
                      >
                        Download iCal
                      </MenuItem>
                      <MenuItem
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-start',
                          fontSize: mobileView ? '15px' : '',
                          ...(activeContribution?.isDarkModeEnabled && {
                            backgroundColor: newThemedBackgroundColor,
                            color: themedTextColor,
                          }),
                        }}
                        onClick={() => onClickAddToGoogleCalender()}
                      >
                        Add to Google Calendar
                      </MenuItem>
                    </CalendarMenu>
                  </CalendarContainer>
                )}
              </div>
            )}
            {isCoach ? (
              <></>
            ) : (
              // <div>
              //   <p className="one-to-one-session-time__label one-to-one-session-time__label--type--booked">Client</p>
              //   <p className="one-to-one-session-time__value one-to-one-session-time__value--type--booked">
              //     {`${firstName} ${lastName}`}
              //   </p>
              // </div>
              booked && (
                <div>
                  <p className="one-to-one-session-time__label one-to-one-session-time__label--type--booked">Booked</p>
                  <p className="one-to-one-session-time__value one-to-one-session-time__value--type--booked">
                    {booked ? 'Yes' : 'No'}
                  </p>
                </div>
              )
            )}
            {activeTemplate === TemplateType.TemplateThree || isCoach || !session?.id ? (
              <>
                {mobileView && (
                  <BottomPanel
                    contributionId={contributionId}
                    sessionId={sessionId}
                    attachments={attachments}
                    session={session}
                  />
                )}
                <div className="one-to-one-session-time__actions">{renderActions(session)}</div>
              </>
            ) : (
              <>
                {/* {session?.id && (
                  <button
                    type="button"
                    onClick={handleReschedule}
                    disabled={isRescheduleDisabled}
                    className="course-session-summary__reschedule2"
                    style={isRescheduleDisabled ? {} : { backgroundColor: btnColor, color: textColor, border: '0px' }}
                  >
                    Reschedule
                  </button>
                )} */}
              </>
            )}
          </div>
          <div>{session?.zoomMeetingData?.chatFiles?.length > 0 && <>{chatFiles}</>}</div>
          {/* {activeTemplate != TemplateType.TemplateThree && !isCoach && <hr />} */}
        </>
      )}
      {modalOpened && (
        <NoteModal
          noteProps={{
            classId: sessionId,
            contributionId,
          }}
          isOpen={modalOpened}
          onModalClose={handleCloseModal}
          onConfirm={handleCloseModal}
        />
      )}
      <Modal
        title="Session Attachments"
        applyTheming
        isOpen={showAll}
        submitTitle="Done"
        hiddenCancel
        onCancel={() => {
          setShowAll(false);
        }}
        onSubmit={() => {
          setShowAll(false);
        }}
      >
        <div style={{ marginBottom: '20px' }}>
          {attachments.map(
            ({ id: documentId, contentType, documentKeyWithExtension, documentOriginalNameWithExtension }, idx) => (
              <div style={isCoach ? { marginTop: '9px' } : {}} key={documentId}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    border: `1px solid #DFE3E4`,
                    padding: '9px',
                    borderRadius: '4px',
                    marginLeft: '5px',
                    backgroundColor: '#FAFAFA',
                    marginBottom: '5px',
                    cursor: 'pointer',
                  }}
                  onClick={handleDownloadFile}
                  disableRipple
                  data-document-id={documentId}
                  data-document-name={documentOriginalNameWithExtension}
                  data-content-type={contentType}
                >
                  <div style={{ display: 'flex', gap: '5px', color: colorToUse.AccentColorCode }}>
                    <DocIcon color={colorToUse.AccentColorCode} />
                    <Tooltip title={documentOriginalNameWithExtension}>
                      <div> {formatString(documentOriginalNameWithExtension)}</div>
                    </Tooltip>
                    {isCoach && (
                      <CloseIcon
                        style={{ marginTop: '2px', color: colorToUse.AccentColorCode }}
                        fontSize="small"
                        className="cursor-pointer"
                        onClick={event => {
                          event.stopPropagation();
                          handleRemoveFile(event);
                        }}
                        data-document-id={documentId}
                        data-document-extension={documentKeyWithExtension}
                      />
                    )}
                  </div>
                </div>
              </div>
            ),
          )}
        </div>
      </Modal>
    </>
  );
};

SessionTime.defaultProps = {
  startTime: '',
  endTime: '',
  participantId: '',
  booked: false,
  session: {},
};

SessionTime.propTypes = {
  startTime: PropTypes.string,
  endTime: PropTypes.string,
  className: PropTypes.string.isRequired,
  participantId: PropTypes.string,
  booked: PropTypes.bool,
  renderActions: PropTypes.func.isRequired,
  session: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    startTime: PropTypes.string,
    endTime: PropTypes.string,
    isCompleted: PropTypes.bool,
    participantId: PropTypes.string,
    attachments: PropTypes.arrayOf(PropTypes.shape({})),
  }),
};

export default SessionTime;
