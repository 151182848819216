import React, { useState } from 'react';
import styled from 'styled-components';
import TelegramIcon from '@mui/icons-material/Telegram';
import CommentIcon from '@mui/icons-material/Comment';
import { colors } from 'utils/styles';
import { addhttp, lightOrDark } from 'utils/utils';
import useRouter from 'hooks/useRouter';
const GreetingSection = styled.div`
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  width: 100%;
  height: 32px;
  color: ${colors?.lightBrown};
  margin-top: 5px;
  // font-size: 16px;
`;

const StyledHeading = styled.div`
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 850;
  font-size: 18px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #215c73;
`;

const WelcomeMesssage = styled.div`
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
`;

const SendUsAnEmail = styled.div`
  width: 100%;
  position: relative;
  border: 1px solid #dfe3e4;
  border-radius: 5px;
  margin-top: 10px;
  padding: 15px;
`;

const ROW = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
`;

const ChatWithCoach = styled.div`
  width: 100%;
  position: relative;
  border: 1px solid #dfe3e4;
  border-radius: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 15px;
`;

const StyledText = styled.div`
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
`;

const HomeView = ({ loadScript }) => {
  const { domain } = useRouter();

  return (
    <>
      <GreetingSection>Hi there 👋</GreetingSection>
      <WelcomeMesssage>How we can help?</WelcomeMesssage>
      <SendUsAnEmail onClick={() => (window.location = 'mailto:support@cohere.live')}>
        <ROW>
          <StyledHeading>Send us an email</StyledHeading>
          <TelegramIcon
            htmlColor={colors?.lightBrown}
            onClick={() => (window.location = 'mailto:support@cohere.live')}
          />
        </ROW>
        <StyledText>
          In the subject of your email, please share what you’re looking for support with. For example: “Platform
          Question, Tech Issue, Or Product Feedback.” (If you have a tech issue, please also attach links and screen
          shots in the body of your email if possible.)
        </StyledText>
        <br />
        <StyledText>
          We are committed to ensuring that you have a great experience on Cohere & we aim at responding as soon as
          possible, within one business day max.
        </StyledText>

        <br />
        <StyledText>The Cohere Team</StyledText>
        <StyledText>
          <a
            onClick={() => {
              window.location = 'mailto:support@cohere.live';
            }}
            href="support.cohere.live"
            target="_blank"
            style={{ color: '#215C73' }}
          >
            <u>support@cohere.live</u>
          </a>
        </StyledText>
      </SendUsAnEmail>
      <ChatWithCoach
        onClick={() => {
          // window.location.href = addhttp(domain.concat(`/conversations/all?chatId=62a83074a49637643384b962`));
        }}
      >
        <ROW>
          <div>
            <StyledHeading>Chat with a success coach</StyledHeading>
            <StyledText>We typically respond within one business day.</StyledText>
          </div>
          <div id="zendexchat" style={{ position: 'relative' }}></div>
          <CommentIcon
            htmlColor={colors?.lightBrown}
            style={{ cursor: 'pointer' }}
            // onClick={() => {
            //   // window.location.href = addhttp(domain.concat(`/conversations/all?chatId=62a83074a49637643384b962`));
            // }}
            onClick={loadScript}
          />
        </ROW>
      </ChatWithCoach>
    </>
  );
};

export default HomeView;
