import React from 'react';
import { useMediaQuery, useTheme } from '@material-ui/core';
import ClientPage from 'pages/ClientPage/ClientPage';
import MainContainer from './MainContainer';
import { StyledMainSection } from './CreateContributionContainer';

const ClientDetailViewContainer = () => {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <>
      <MainContainer>
        <StyledMainSection mobileView={mobileView}>
          <ClientPage />
        </StyledMainSection>
      </MainContainer>
    </>
  );
};

export default ClientDetailViewContainer;
