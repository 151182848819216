import { Grid, MenuItem, TextField, Typography } from '@material-ui/core';
import { completeRegister, loginUser, loginUserSimple } from 'actions/user';
import Button from 'components/FormUI/Button';
import { Form, Formik } from 'formik';
import {
  ACCOUNT_FORM_FIELDS,
  PURCHASE_MODAL_STEPS,
} from 'pages/ContributionView/components/PurchaseModal/PurchaseModal.constants';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIsEmailExist } from 'utils/useIsEmailExist';
import { getPurchaseValidationSchema } from 'utils/validation';
import { fetchTimeZones } from 'actions/timeZone';
import { determineColorToUse } from 'services/contributions.service';
import styled from 'styled-components';
import { UserRoles } from 'helpers/constants';
import { lightOrDark } from 'utils/utils';
import PropTypes from 'prop-types';
import { ForgotPasswordLink } from 'pages/ContributionView/components/PurchaseBlock/CustomPurchaseBlock';
import { ModalTermsAndConditions } from 'components/Modals/TermsAndConditions';
import { colors } from 'utils/styles';
import { redirectToAppIfIsCoachDomain } from 'utils/subdomains';
import useRouter from 'hooks/useRouter';
import useDefaultTimezone from 'hooks/useDefaultTimezone';

const StyledDiv = styled.span`
  color: ${props => (props.color ? props.color : colors.darkOceanBlue)};
  font-weight: 600;
`;

const StyledLink = styled.a`
  color: ${props => (props.color ? props.color : colors.darkOceanBlue)};
  font-weight: 600;
`;
const LoginForm = ({ isDarkThemeEnabled }) => {
  const { domain } = useRouter();
  const [emailExist, setEmailExist] = useState(false);
  const [isEmailChecked, setIsEmailChecked] = useState(false);
  const [step, setStep] = useState(0);
  const [touchedFields, setTouchedFields] = useState({});
  const { error, isLoading } = useSelector(({ account }) => account);
  const [showTerms, setShowTerms] = useState(false);
  const { timeZones, loading: timeZoneLoading } = useSelector(state => state.timeZone);
  const dispatch = useDispatch();
  const activeContribution = useSelector(state => state.contributions?.activeContribution);
  const colorToUse = determineColorToUse(activeContribution);
  const [selectedTimezone, setSelectedTimezone] = useState();
  const { fetchUserTimezone } = useDefaultTimezone();

  const textColor =
    colorToUse?.TextColorCode === 'Auto'
      ? lightOrDark(colorToUse?.PrimaryColorCode)
      : colorToUse?.TextColorCode === '#000000'
      ? '#000000'
      : '#FFFFFF';

  const StyledButton = styled(Button)`
    color: ${textColor};
    background-color: ${colorToUse?.PrimaryColorCode};
    &:hover {
      background-color: ${colorToUse?.PrimaryColorCode};
    }
  `;

  const fetchTimezone = async () => {
    const finalTimezone = await fetchUserTimezone(null, null, timeZones);
    setSelectedTimezone(finalTimezone);
  };

  useEffect(() => {
    if (timeZones?.length > 0) {
      if (!selectedTimezone) {
        fetchTimezone();
      }
      return;
    }
    if ((!timeZones || !timeZones.length) && !timeZoneLoading) {
      dispatch(fetchTimeZones());
    }
  }, [timeZones]);
  const { checkEmail, isLoadingEmail } = useIsEmailExist();

  const chooseSchemas = () => {
    if (step === 0) {
      return getPurchaseValidationSchema(PURCHASE_MODAL_STEPS.memberInit);
    }
    if (!emailExist) {
      return getPurchaseValidationSchema(PURCHASE_MODAL_STEPS.joinCreate);
    }
    return getPurchaseValidationSchema(PURCHASE_MODAL_STEPS.joinLogin);
  };

  const onChange = (fieldName, value, setFieldValue) => {
    if (fieldName === ACCOUNT_FORM_FIELDS.email || fieldName === ACCOUNT_FORM_FIELDS.confirmEmail) {
      setFieldValue(fieldName, value.replace(/\s/g, '').trim());
      setTouchedFields({ ...touchedFields, [fieldName]: true });
    } else {
      setFieldValue(fieldName, value);
      setTouchedFields({ ...touchedFields, [fieldName]: true });
    }
  };

  const onSubmit = values => {
    const { Email, Password, FirstName, LastName, TimeZoneId } = values || {};
    switch (step) {
      case 0:
        setTouchedFields({
          [ACCOUNT_FORM_FIELDS.email]: true,
        });
        checkEmail(Email).then(val => {
          setStep(1);
          setIsEmailChecked(true);
          setEmailExist(val);
        });
        break;
      case 1:
        if (emailExist) {
          dispatch(loginUserSimple(Email, Password, false, ''));
        } else {
          setTouchedFields({
            [ACCOUNT_FORM_FIELDS.email]: true,
            [ACCOUNT_FORM_FIELDS.password]: true,
            [ACCOUNT_FORM_FIELDS.confirmEmail]: true,
            [ACCOUNT_FORM_FIELDS.firstName]: true,
            [ACCOUNT_FORM_FIELDS.lastName]: true,
            [ACCOUNT_FORM_FIELDS.timeZoneId]: true,
          });
          dispatch(
            completeRegister({
              Email,
              FirstName,
              LastName,
              TimeZoneId,
              userView: UserRoles.client,
            }),
          );
        }
        break;
      default:
        break;
    }
  };

  return (
    <div className="login-form">
      <Formik
        initialValues={{
          [ACCOUNT_FORM_FIELDS.email]: '',
          [ACCOUNT_FORM_FIELDS.password]: '',
          [ACCOUNT_FORM_FIELDS.confirmEmail]: '',
          [ACCOUNT_FORM_FIELDS.firstName]: '',
          [ACCOUNT_FORM_FIELDS.lastName]: '',
          [ACCOUNT_FORM_FIELDS.timeZoneId]: selectedTimezone || '',
        }}
        enableReinitialize
        validationSchema={chooseSchemas()}
        onSubmit={onSubmit}
      >
        {({ setFieldValue, values, errors }) => {
          return (
            <Form id="signUpForm">
              <TextField
                className="input-field application-form-input"
                id="outlined-basic"
                margin="normal"
                variant="outlined"
                fullWidth
                disabled={step > 0}
                name={ACCOUNT_FORM_FIELDS.email}
                error={touchedFields[ACCOUNT_FORM_FIELDS.email] && Boolean(errors[ACCOUNT_FORM_FIELDS.email])}
                helperText={touchedFields[ACCOUNT_FORM_FIELDS.email] && errors[ACCOUNT_FORM_FIELDS.email]}
                type="text"
                placeholder="Email"
                value={values.Email}
                onChange={e => {
                  let trimmedEmail = e.target.value.trim();
                  setIsEmailChecked(false);
                  onChange(ACCOUNT_FORM_FIELDS.email, trimmedEmail, setFieldValue);
                }}
              />
              {isEmailChecked && emailExist && step > 0 && (
                <>
                  <TextField
                    className="input-field application-form-input"
                    id="outlined-basic"
                    type="password"
                    margin="normal"
                    variant="outlined"
                    fullWidth
                    name={ACCOUNT_FORM_FIELDS.password}
                    error={touchedFields[ACCOUNT_FORM_FIELDS.password] && Boolean(errors[ACCOUNT_FORM_FIELDS.password])}
                    helperText={touchedFields[ACCOUNT_FORM_FIELDS.password] && errors[ACCOUNT_FORM_FIELDS.password]}
                    placeholder="Password"
                    value={values[ACCOUNT_FORM_FIELDS.password]}
                    onChange={e => onChange(ACCOUNT_FORM_FIELDS.password, e.target.value, setFieldValue)}
                  />
                  <div className="forgot-password-container">
                    <ForgotPasswordLink onClick={() => redirectToAppIfIsCoachDomain(domain, '/auth/restore-pass')}>
                      Forgot Password?
                    </ForgotPasswordLink>
                  </div>
                </>
              )}
              {isEmailChecked && !emailExist && step > 0 && (
                <>
                  <TextField
                    className="input-field application-form-input"
                    id="outlined-basic"
                    margin="normal"
                    variant="outlined"
                    fullWidth
                    type="text"
                    error={
                      touchedFields[ACCOUNT_FORM_FIELDS.confirmEmail] &&
                      Boolean(errors[ACCOUNT_FORM_FIELDS.confirmEmail])
                    }
                    helperText={
                      touchedFields[ACCOUNT_FORM_FIELDS.confirmEmail] && errors[ACCOUNT_FORM_FIELDS.confirmEmail]
                        ? errors[ACCOUNT_FORM_FIELDS.confirmEmail]?.includes('required')
                          ? 'Confirm email is a required field'
                          : errors[ACCOUNT_FORM_FIELDS.confirmEmail]
                        : ''
                    }
                    name={ACCOUNT_FORM_FIELDS.confirmEmail}
                    placeholder="Confirm Email"
                    value={values[ACCOUNT_FORM_FIELDS.confirmEmail]}
                    onChange={e => onChange(ACCOUNT_FORM_FIELDS.confirmEmail, e.target.value, setFieldValue)}
                  />
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <TextField
                        className="input-field application-form-input"
                        id="outlined-basic"
                        margin="normal"
                        variant="outlined"
                        fullWidth
                        name={ACCOUNT_FORM_FIELDS.firstName}
                        error={
                          touchedFields[ACCOUNT_FORM_FIELDS.firstName] && Boolean(errors[ACCOUNT_FORM_FIELDS.firstName])
                        }
                        helperText={
                          touchedFields[ACCOUNT_FORM_FIELDS.firstName] && errors[ACCOUNT_FORM_FIELDS.firstName]
                            ? errors[ACCOUNT_FORM_FIELDS.firstName]?.includes('required')
                              ? 'First name is a required field'
                              : errors[ACCOUNT_FORM_FIELDS.firstName]
                            : ''
                        }
                        placeholder="First Name"
                        value={values[ACCOUNT_FORM_FIELDS.firstName]}
                        onChange={e => onChange(ACCOUNT_FORM_FIELDS.firstName, e.target.value, setFieldValue)}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        className="input-field application-form-input"
                        id="outlined-basic"
                        margin="normal"
                        variant="outlined"
                        fullWidth
                        name={ACCOUNT_FORM_FIELDS.lastName}
                        error={
                          touchedFields[ACCOUNT_FORM_FIELDS.lastName] && Boolean(errors[ACCOUNT_FORM_FIELDS.lastName])
                        }
                        helperText={
                          touchedFields[ACCOUNT_FORM_FIELDS.lastName] && errors[ACCOUNT_FORM_FIELDS.lastName]
                            ? errors[ACCOUNT_FORM_FIELDS.lastName]?.includes('required')
                              ? 'Last name is a required field'
                              : errors[ACCOUNT_FORM_FIELDS.lastName]
                            : ''
                        }
                        placeholder="Last Name"
                        value={values[ACCOUNT_FORM_FIELDS.lastName]}
                        onChange={e => onChange(ACCOUNT_FORM_FIELDS.lastName, e.target.value, setFieldValue)}
                      />
                    </Grid>
                  </Grid>
                  <TextField
                    className="input-field application-form-input"
                    id="outlined-basic"
                    margin="normal"
                    variant="outlined"
                    fullWidth
                    select
                    name={ACCOUNT_FORM_FIELDS.timeZoneId}
                    error={
                      touchedFields[ACCOUNT_FORM_FIELDS.timeZoneId] && Boolean(errors[ACCOUNT_FORM_FIELDS.timeZoneId])
                    }
                    helperText={touchedFields[ACCOUNT_FORM_FIELDS.timeZoneId] && errors[ACCOUNT_FORM_FIELDS.timeZoneId]}
                    label={values[ACCOUNT_FORM_FIELDS.timeZoneId] ? '' : 'Select Timezone'}
                    value={values[ACCOUNT_FORM_FIELDS.timeZoneId]}
                    onChange={e => onChange(ACCOUNT_FORM_FIELDS.timeZoneId, e.target.value, setFieldValue)}
                  >
                    {timeZones?.length > 0 &&
                      timeZones.map(timeZone => (
                        <MenuItem
                          className={isDarkThemeEnabled && `application-form-select-field`}
                          key={timeZone.name}
                          value={timeZone.countryName}
                        >
                          {timeZone.name}
                        </MenuItem>
                      ))}
                  </TextField>
                  {activeContribution.customToS ? (
                    activeContribution?.isElectronicSignatureActive && activeContribution?.customWaiverId === null ? (
                      <p className={isDarkThemeEnabled && `terms-and-conditions`}>
                        By signing and clicking join,I agree to the{' '}
                        <a
                          className="terms-and-conditions-link"
                          href="/"
                          color={colorToUse?.PrimaryColorCode}
                          onClick={e => {
                            e.preventDefault();
                            setShowTerms(true);
                          }}
                        >
                          Terms and Conditions
                        </a>
                        , I also agree to {activeContribution.serviceProviderName}
                        {"'"}s{' '}
                        <a
                          className="terms-and-conditions-link"
                          color={colorToUse?.PrimaryColorCode}
                          href={activeContribution.customToS}
                        >
                          Terms and Conditions
                        </a>
                        , and I'm at least 18 years old.
                      </p>
                    ) : (
                      <p className={isDarkThemeEnabled && `terms-and-conditions`}>
                        By clicking join,I agree to the{' '}
                        <a
                          className="terms-and-conditions-link"
                          href="/"
                          color={colorToUse?.PrimaryColorCode}
                          onClick={e => {
                            e.preventDefault();
                            setShowTerms(true);
                          }}
                        >
                          Terms and Conditions
                        </a>
                        , I also agree to {activeContribution.serviceProviderName}
                        {"'"}s{' '}
                        <a
                          className="terms-and-conditions-link"
                          color={colorToUse?.PrimaryColorCode}
                          href={activeContribution.customToS}
                        >
                          Terms and Conditions
                        </a>
                        , and I'm at least 18 years old.
                      </p>
                    )
                  ) : (
                    activeContribution?.customWaiverId != null && (
                      <>
                        <p className={isDarkThemeEnabled && `terms-and-conditions`}>
                          By {activeContribution?.customWaiverId != null ? 'clicking' : 'signing and clicking'} join, I
                          agree to the{' '}
                          <a
                            className="terms-and-conditions-link"
                            color={colorToUse?.PrimaryColorCode}
                            href="/"
                            onClick={e => {
                              e.preventDefault();
                              setShowTerms(true);
                            }}
                          >
                            Terms and Conditions
                          </a>
                          , I also agree to {activeContribution.serviceProviderName}
                          {"'"}s{' '}
                          <a className="terms-and-conditions-link">
                            {activeContribution?.customWaiverTemplateName.trim()}
                          </a>
                          , and I'm at least 18 years old.
                        </p>
                        <br />
                        {activeContribution.customToS && (
                          <p className={isDarkThemeEnabled && `terms-and-conditions`}>
                            By {activeContribution?.customWaiverId != null ? 'clicking' : 'signing and clicking'}{' '}
                            Reserve, I also agree to {activeContribution.serviceProviderName}
                            {"'"}s{' '}
                            <a
                              className="terms-and-conditions-link"
                              color={colorToUse?.PrimaryColorCode}
                              href={activeContribution.customToS}
                            >
                              Terms and Conditions
                            </a>
                            , and I'm at least 18 years old.
                          </p>
                        )}
                      </>
                    )
                  )}
                  <ModalTermsAndConditions
                    applyTheming={isDarkThemeEnabled}
                    showTerms={showTerms}
                    onCancel={() => setShowTerms(false)}
                  />
                </>
              )}
              {error?.message && <p className="login-error">{error.message}</p>}
              <div>
                <StyledButton
                  variant="primary"
                  className="form-next-button"
                  disabled={isLoadingEmail || isLoading}
                  type="submit"
                >
                  Next
                </StyledButton>
                {step > 0 && (
                  <StyledButton
                    className="form-back-button"
                    disabled={isLoadingEmail || isLoading}
                    onClick={() => setStep(step - 1)}
                  >
                    Back
                  </StyledButton>
                )}
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

LoginForm.propTypes = {
  isDarkThemeEnabled: PropTypes.bool,
};

LoginForm.defaultProps = {
  isDarkThemeEnabled: false,
};

export default LoginForm;
