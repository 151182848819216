import moment from 'moment';

const prepareTime = time => moment(time).set('seconds', 0).valueOf();

export const getSlotsWithoutOverlaps = (otherEvents, availabilityTimes = []) => {
  const allOtherSessionTimes = otherEvents
    .reduce((res, otherEvent) => [...res, ...otherEvent.sessionTimes], [])
    .sort((a, b) => a.startTime - b.startTime);
  const allAvailabilityTimes = [...availabilityTimes].sort((a, b) => a.startTime - b.startTime);

  return allAvailabilityTimes.filter(availabilityTime => {
    const startTime = prepareTime(availabilityTime.startTime) + 1;
    const endTime = prepareTime(availabilityTime.endTime) - 1;

    return !allOtherSessionTimes.some(otherSessionTime => {
      const startOtherTime = prepareTime(otherSessionTime.startTime);
      const endOtherTime = prepareTime(otherSessionTime.endTime);

      return (
        (startTime >= startOtherTime && startTime <= endOtherTime) ||
        (endTime >= startOtherTime && endTime <= endOtherTime)
      );
    });
  });
};
