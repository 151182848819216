import { makeStyles } from '@material-ui/core';

const stylesMemberPodsAssignment = () => ({
  selectWrapper: {
    width: '100%',
    textAlign: 'left !important',
  },
});

export const useStyles = makeStyles(stylesMemberPodsAssignment);
