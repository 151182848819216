import React, { useEffect, useState } from 'react';

import { useRouter } from 'hooks';
import chatService from 'services/chat.service';
import ConversationsList from './ConversationsList';

const AllConversationsList = () => {
  const [loadingChat, setLoadingChat] = useState(true);
  const [preferredConversationId, setPreferredConversationId] = useState(null);
  const {
    location: { state },
    query,
    history,
  } = useRouter();

  useEffect(() => {
    if (query.chatId) {
      history.replace('/conversations/all', { openChatWithUser: query.chatId });
    }
  }, [query]);

  useEffect(() => {
    const { preferredConversationId: preferredId = null, openChatWithUser = null } = state || {};

    if (!preferredId && !openChatWithUser) setLoadingChat(false);
    if (preferredId) {
      setPreferredConversationId(preferredId);
    }
    if (openChatWithUser) {
      chatService.getPersonalChatWithAsync(openChatWithUser, true).then(res => {
        setPreferredConversationId(res.sid);
      });
    }
  }, [state]);
  return (
    <>
      {(!loadingChat || preferredConversationId) && (
        <ConversationsList preferredConversationId={preferredConversationId} />
      )}
    </>
  );
};

export default AllConversationsList;
