import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Calendar as BigCalendar, momentLocalizer, Views } from 'react-big-calendar';
import moment from 'moment';
import * as R from 'ramda';
import * as calendarAction from 'actions/calendars';
import { connect, useDispatch, useSelector } from 'react-redux';
import { colors } from 'utils/styles';
import { PageSubtitle } from 'components/UI/Text/TextStyles';
import { checkIsPast, getTimePeriodsForSessions } from 'utils/datesAndMoney';
import Loader from 'components/UI/Loader';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './Calendar.scss';
import styled from 'styled-components';
import { AvailabilityModal } from 'pages/MasterCalendarPage/components/availabilityModal';
import useContribution from 'pages/ContributionView/hooks/useContribution';
import { ClickAwayListener } from '@material-ui/core';
import { uniqBy } from 'lodash';

const localizer = momentLocalizer(moment);
const allViews = R.compose(R.values, R.omit(['AGENDA', 'WORK_WEEK']))(Views);

const StyledOption = styled.div`
  width: 100%;
  font-family: 'Avenir';
  font-size: 16px;
  font-weight: 800;
  line-height: 21px;
  text-align: left;
  border-radius: 0px;
  padding: 16px 20px;
  cursor: pointer;
  border-bottom: ${({ borderBottom }) => (borderBottom ? '2px solid #e7e7e7' : '')};
  ${({ disabled }) =>
    disabled &&
    `
  cursor: default;
  color: darkgray;
  `}
`;

const DropDown = styled.div`
  width: ${({ w }) => w};
  position: absolute;
  top: ${({ top }) => `${top}px`};
  left: ${({ left }) => `${left}px`};
  border: 1px solid #dfe3e4;
  border-radius: 4px;
  background-color: #ffffff;
  z-index: 100;
  display: flex;
  flex-direction: column;
`;

const DROPDOWN_WITH = 200;

const Calendar = ({
  values,
  loadingTimes,
  hideSubTitle,
  CustomToolbar,
  setBlockedDates,
  newBlockedDates,
  setNewBlockedDates,
  openAndScrollToBlockCalendar,
  oneToOneStartDate,
  oneToOneEndDate,
  mobileView,
  calendarActiveView,
  incrementNewSlots,
  sessionDuration,
}) => {
  const eventsList = getTimePeriodsForSessions(values.sessions);
  const eventsListUniq = uniqBy(
    eventsList,
    k => moment(k.start).startOf('minute').toISOString() && moment(k.end).startOf('minute').toISOString(),
  );

  const [showAvailabilityPopUp, setshowAvailabilityPopUp] = useState(false);
  const [availabilityDisabled, setAvailabilityDisabled] = useState(false);

  const [dropdownPosition, setDropdownPosition] = useState({ x: 0, y: 0 });
  const [showDropDown, setShowDropDown] = useState(false);
  const [eventClicked, setEventClicked] = useState(null);
  const [eventClickedDate, setEventClickedDate] = useState(null);
  const [isAlreadyBlocked, setIsAlreadyBlocked] = useState(false);
  const { currentView } = useSelector(state => state.calendars);
  const contribution = useContribution();
  const dispatch = useDispatch();

  const handleOutsideClick = e => {
    if (!e.defaultPrevented) {
      setShowDropDown(false);
      setEventClicked(null);
    }
  };

  const handleBlockDate = () => {
    const eventClickedDateWithTime = moment(eventClicked)
      .set('hour', 9)
      .set('minute', 0)
      .set('second', 0)
      .set('milliseconds', 0);
    const newBlockDates = [...newBlockedDates, eventClickedDateWithTime];
    const reversedDates = [...newBlockDates]?.reverse();
    setNewBlockedDates(reversedDates);
    setBlockedDates(reversedDates?.map(date => date.toISOString()));
    openAndScrollToBlockCalendar();
    setShowDropDown(false);
  };

  useEffect(() => {
    setShowDropDown(false);
  }, [calendarActiveView]);

  const selectEventHandler = (event, e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    setEventClickedDate(event.start);
    setEventClicked(event?.start);
    const isPastDate = checkIsPast(event?.start);
    const alreadyBlocked = newBlockedDates?.some(d => moment(d).format('L') === moment(event?.start).format('L'));
    const dateToCheck = moment(event?.start).toISOString();
    const isDateInRange = moment(dateToCheck).isBetween(oneToOneStartDate, oneToOneEndDate);
    const blockDisabled = isPastDate || alreadyBlocked || !isDateInRange;
    setIsAlreadyBlocked(blockDisabled);
    const ele = document.getElementById('calendarmain');
    ele.addEventListener('contextmenu', function (e) {
      e.preventDefault();
    });
    let clientX;
    let clientY;
    const rect = ele.getBoundingClientRect();
    clientX = e?.clientX || event?.box?.clientX || event?.bounds?.x;
    clientY = e?.clientY || event?.box?.clientY || event?.bounds?.y;
    const offsetForMobile = event?.bounds && mobileView ? window.scrollY : 0;
    clientX = clientX - rect.left;
    clientY = clientY - rect.top - offsetForMobile;

    const spaceLeft = clientX;
    const spaceRight = window.innerWidth - clientX - 270;

    // Open on the right side by default
    if (spaceRight >= DROPDOWN_WITH) {
      clientX = clientX;
      clientY = clientY;
    } else if (spaceLeft >= DROPDOWN_WITH) {
      // If there's not enough space on the right, switch to the left
      clientX = clientX - DROPDOWN_WITH;
      clientY = clientY;
    } else {
      // If there's not enough space on either side, prioritize right
      clientX = clientX;
      clientY = clientY;
    }

    // const { clientX, clientY } = e || event?.box;
    setDropdownPosition({ x: clientX, y: clientY });
    if (e) {
      setAvailabilityDisabled(true);
    } else {
      setAvailabilityDisabled(false);
    }

    setShowDropDown(true);
  };

  return (
    <>
      {!hideSubTitle && <PageSubtitle>Client Session Availability</PageSubtitle>}
      {loadingTimes && (
        <div className="custom-calendar" style={{ position: 'relative' }}>
          <div style={{ justifyContent: 'center' }} className="rbc-month-view">
            <div className="">
              <Loader relative />
            </div>
          </div>
        </div>
      )}

      {!loadingTimes && (
        <div id="calendarmain" style={{ position: 'relative', height: '100%' }}>
          <ClickAwayListener onClickAway={handleOutsideClick}>
            <BigCalendar
              className="custom-calendar"
              style={{ color: 'black' }}
              localizer={localizer}
              events={eventsListUniq}
              // events={uniqBy(eventsList, e => e.id)}
              views={allViews}
              defaultView={Views.MONTH}
              step={30}
              selectable={true}
              showMultiDayTimes
              components={
                CustomToolbar
                  ? {
                      toolbar: CustomToolbar,
                    }
                  : {}
              }
              defaultDate={new Date()}
              onSelectEvent={selectEventHandler}
              onSelectSlot={selectEventHandler}
              longPressThreshold={10}
              onShowMore={() => {
                dispatch(calendarAction.setCurrentCalendarView.request('Day'));
              }}
              startAccessor="start"
              endAccessor="end"
              formats={{ eventTimeRangeFormat: () => '' }}
              eventPropGetter={({ color }) => {
                const newStyle = {
                  backgroundColor: color || colors.darkOceanBlue,
                  // minHeight: 24,
                  fontSize: Views.MONTH !== calendarActiveView?.toLowerCase() && 30 > sessionDuration ? 11 : 14,
                  // position: 'relative',
                  ...(Views.MONTH !== calendarActiveView?.toLowerCase() &&
                    30 > sessionDuration && { paddingTop: 0, paddingBottom: 0 }),
                };

                return {
                  className: '',
                  style: newStyle,
                };
              }}
            />
          </ClickAwayListener>
          {showDropDown && (
            <DropDown top={dropdownPosition.y} left={dropdownPosition.x} w={`${DROPDOWN_WITH}px`}>
              <StyledOption
                disabled={checkIsPast(eventClickedDate) || availabilityDisabled}
                borderBottom={true}
                onClick={() => {
                  if (checkIsPast(eventClickedDate) || availabilityDisabled) {
                    console.log('first');
                  } else {
                    setShowDropDown(false);
                    setshowAvailabilityPopUp(true);
                  }
                }}
              >
                Add 1:1 Availability
              </StyledOption>
              <StyledOption onClick={isAlreadyBlocked ? () => {} : handleBlockDate} disabled={isAlreadyBlocked}>
                Block Single Day
              </StyledOption>
            </DropDown>
          )}
          <AvailabilityModal
            showTerms={showAvailabilityPopUp}
            onCancel={() => {
              setshowAvailabilityPopUp(false);
              setShowDropDown(false);
            }}
            setshowAvailabilityPopUp={setshowAvailabilityPopUp}
            selectedDate={eventClickedDate}
            selectedContribution={{
              label: contribution?.title,
              id: contribution?.id,
            }}
            startTime={eventClickedDate}
            incrementNewSlots={incrementNewSlots}
          />
        </div>
      )}
    </>
  );
};

Calendar.propTypes = {
  values: PropTypes.shape({
    sessions: PropTypes.arrayOf(PropTypes.object),
  }),
  loadingTimes: PropTypes.bool,
};

Calendar.defaultProps = {
  values: {},
  loadingTimes: false,
};

const mapStateToProps = ({ oneToOneData }) => ({
  sessionDuration: oneToOneData.sessionDuration,
});

export default connect(mapStateToProps)(Calendar);
