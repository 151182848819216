import React from 'react';

export const UploadImageIcon = () => (
  <svg width="21" height="17.5" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.27276 2.16667H3.54307L3.69111 1.9405L4.634 0.5H7.36614L8.30902 1.9405L8.45706 2.16667H8.72737H10.9092C11.2267 2.16667 11.5001 2.4319 11.5001 2.77778V8.88889C11.5001 9.23476 11.2267 9.5 10.9092 9.5H1.09092C0.773479 9.5 0.5 9.23476 0.5 8.88889V2.77778C0.5 2.4319 0.773479 2.16667 1.09092 2.16667H3.27276Z"
      stroke="white"
    />
  </svg>
);
