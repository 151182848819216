import React from 'react';

export const EditIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 3C5.25049 3 3 5.25049 3 12C3 18.7495 5.25049 21 12 21C18.7495 21 21 18.7495 21 12"
      stroke="#B2CAE0"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M19.3223 4.51145V4.51145C18.3561 3.65621 16.8801 3.74572 16.0248 4.71188C16.0248 4.71188 11.7739 9.5135 10.2999 11.1802C8.82388 12.8459 9.90582 15.147 9.90582 15.147C9.90582 15.147 12.3421 15.9186 13.7958 14.2762C15.2504 12.6338 19.5227 7.80886 19.5227 7.80886C20.3779 6.84269 20.2874 5.36669 19.3223 4.51145Z"
      stroke="#B2CAE0"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M14.9297 5.96826L18.4275 9.06523"
      stroke="#B2CAE0"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
