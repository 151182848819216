import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import StopIcon from '@material-ui/icons/Stop';

import TollbarAction from '../ToolbarAction';

const Stop = ({ stopRecordOnClick }) => {
  return (
    <TollbarAction label="Stop record">
      <IconButton color="inherit" onClick={stopRecordOnClick}>
        <StopIcon />
      </IconButton>
    </TollbarAction>
  );
};

export default Stop;
