import {
  SET_PAIDTIER_CONTRIBUTIONS,
  SET_ACTIVE_PAIDTIER_PLAN,
  SET_ACTIVE_PAIDTIER_CONTRIBUTIONS,
  SET_PAIDTIER_PLANS,
} from 'actions/paidTierOptions';
import isEqual from 'lodash/isEqual';

const initialState = {
  paidTierOptions: [],
  activePaidTierOption: {},
  cohereAcademyContributions: [],
  activeCohereAcademyContributions: [],
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_PAIDTIER_CONTRIBUTIONS:
      if (isEqual(state.cohereAcademyContributions, payload)) {
        return state;
      }
      return {
        ...state,
        cohereAcademyContributions: payload,
      };

    case SET_PAIDTIER_PLANS:
      if (isEqual(state.paidTierOptions, payload)) {
        return state;
      }

      return {
        ...state,
        paidTierOptions: payload,
      };

    case SET_ACTIVE_PAIDTIER_PLAN:
      if (isEqual(state.activePaidTierOption, payload)) {
        return state;
      }

      return {
        ...state,
        activePaidTierOption: payload,
      };

    case SET_ACTIVE_PAIDTIER_CONTRIBUTIONS:
      if (isEqual(state.activeCohereAcademyContributions, payload)) {
        return state;
      }

      return {
        ...state,
        activeCohereAcademyContributions: payload,
      };

    default:
      return state;
  }
};
