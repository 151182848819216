import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, Redirect } from 'react-router-dom';
import { isNil } from 'ramda';
import orderBy from 'lodash/orderBy';
import find from 'lodash/find';
import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { determineColorToUse, determineDarkThemedColorToUse, getThemedColors } from 'services/contributions.service';
import { fetchTimeZones } from 'actions/timeZone';
import { SessionMoreInfo } from 'components/Session/SessionMoreInfo/SessionMoreInfo';
import { TIMEZONES } from 'constants.js';
import { UserRoles, ContributionThemedColors } from 'helpers/constants';
import { useAccount, useClientPreview } from 'hooks';
import { memberPodsSelector } from 'selectors/MemberPods';
import { toRem } from 'utils/styles';
import SessionTime from './SessionTime';
import { BottomPanel } from './BottomPanel/BottomPanel';
import { useRouter } from 'hooks';
import { redirectToAppIfIsCoachDomain } from 'utils/subdomains';
import './Session.scss';
import { isEqual } from 'lodash';
import { TemplateType } from 'helpers/constants/templateType';
import { ContributionType } from 'helpers/constants';
import { ColorSchemeService } from 'services/color.service';
import { lightOrDark } from 'utils/utils';
import Modal from 'components/UI/Modal';
import { BodyText } from 'components/UI/Text/TextStyles';
import { setSessionId } from 'actions/contributions';

const useStyles = makeStyles(theme => ({
  sessiontime_summary: {
    marginLeft: '35px',
    [theme.breakpoints.down('769')]: {
      marginLeft: '0px',
    },
  },
}));

const StyledTimeZoneLabel = styled.a`
  display: inline-block;
  font-size: ${toRem(13)};
  background: #116582;
  padding: 4px 8px;
  border-radius: 4px;
  margin: 0;
  margin-left: 10px;
  cursor: pointer;
  color: white;
  vertical-align: middle;
  margin-bottom: 3px;
  font-weight: bold;
`;

const SessionWrapper = styled.div`
  margin-top: 10px;
  border: 1px solid #dbe7f2;
  ${({ isDarkThemeEnabled }) =>
    !isDarkThemeEnabled &&
    `
    border-radius: 5px;
`}
`;

const getTimeZoneLabel = ({ contributionTimeZoneId, timeZones = [] }) => {
  const timeZone = timeZones.find(item => item?.countryName === contributionTimeZoneId);

  if (timeZone) {
    return timeZone.name;
  }

  return TIMEZONES[contributionTimeZoneId]; // fallback value
};

const Session = ({
  focusedSession,
  number,
  session,
  session: {
    id: sessionId,
    title,
    isCompleted,
    sessionTimes,
    maxParticipantsNumber,
    attachments,
    isPrerecorded,
    dateAvailable,
    name,
    startTime: sessionStartTime,
  },
  convertedToSelfPaced,
  contribution,
  contributionId,
  contributionTimeZoneId,
  isPurchased,
  changeShowPopUp,
  renderSessionTimeActions,
  filter,
  filterClicked,
}) => {
  const theme = useTheme();
  const { clientPreviewMode } = useClientPreview();
  const changeStyle = useMediaQuery(theme.breakpoints.down('600'));
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const [isRescheduleModalOpen, setRescheduleModalState] = useState(false);
  const [selfPacedSessionToBeExpanded, setSelfPacedSessionToBeExpanded] = useState(null);
  const sessionToBeExpanded = useSelector(state => state.contributions.sessionId);
  const { protocol, domain, history } = useRouter();
  const userProfileUrl = new URL('/account/profile', `${protocol}//${domain}`).toString();
  const classes = useStyles();
  const handleReschedule = e => {
    e.stopPropagation();
    setRescheduleModalState(true);
  };
  const clientDisable = !moment(sessionStartTime).isAfter(moment().add(24, 'h')) || isCompleted;
  const isRescheduleDisabled = contribution?.serviceProviderName ? clientDisable : isCompleted;
  const {
    themedColor: themedTextColor,
    themedCardBackgroundColor: oldThemedCardBackgroundColor,
    themedBackgroundColor: oldThemedBackgroundColor,
    newThemedTextColor,
    newThemedCardColor,
    newThemedBackgroundColor,
  } = getThemedColors(contribution);
  const isDarkThemeEnabled = determineDarkThemedColorToUse(contribution);
  const isTodaySession = session.sessionTimes.some(
    st => moment(st.startTime).startOf('day').diff(moment().startOf('day'), 'days') == 0,
  );
  const isPastSession = session.sessionTimes.some(
    st => moment(st.startTime).startOf('day').diff(moment().startOf('day'), 'days') < 0,
  );
  const isTodayselfpacedSession = session.sessionTimes.some(
    st => st.ignoreDateAvailable && moment(st.startTime).startOf('day').diff(moment().startOf('day'), 'days') <= 0,
  );

  const classNames = useStyles();
  const expandAllSessions = filter != 'All' && filterClicked;
  const [expanded, setExpanded] = useState(isTodaySession);

  const { currentRole, user } = useAccount();
  const isCoach = currentRole === UserRoles.cohealer;
  const themedColor = isCoach && !clientPreviewMode ? themedTextColor : newThemedTextColor;
  const themedCardBackgroundColor = isCoach && !clientPreviewMode ? oldThemedCardBackgroundColor : newThemedCardColor;
  const themedBackgroundColor = isCoach && !clientPreviewMode ? oldThemedBackgroundColor : newThemedBackgroundColor;
  const text = isPrerecorded ? 'View Content' : 'Open Session';
  const isUpcomingSessionTime = find(sessionTimes, { isCompleted: false });
  const dispatch = useDispatch();
  const { error, timeZones, loading } = useSelector(state => state.timeZone);

  useEffect(() => {
    setExpanded(isNil(isUpcomingSessionTime) && isCompleted ? false : !isPrerecorded);
  }, [expandAllSessions, isCompleted]);
  useEffect(() => {
    if (focusedSession === sessionId) {
      setExpanded(true);
    }
    if (sessionToBeExpanded === sessionId) {
      setExpanded(true);
    }
  }, [focusedSession, sessionToBeExpanded]);

  useEffect(() => {
    if (timeZones?.length > 0) {
      return;
    }
    if (!timeZones?.length && !loading && !error) {
      dispatch(fetchTimeZones());
    }
  }, [dispatch, error, loading, timeZones]);

  const { contributionMemberPods } = useSelector(memberPodsSelector);
  const [filteredSessionTimes, setFilteredSessionTimes] = useState([]);

  useEffect(() => {
    let result = [...sessionTimes];

    if (isCoach || !isPurchased) {
      setFilteredSessionTimes(result);
    } else {
      const contributionPods = contributionMemberPods?.[contributionId];

      result = sessionTimes?.filter(item => {
        if (!item.podId) {
          return true;
        }

        const sessionTimePods = contributionPods?.filter(pod => pod.id === item.podId);

        return sessionTimePods?.some(sessionTimePod => sessionTimePod.clientIds?.includes(user.id));
      });

      setFilteredSessionTimes(result);
    }
  }, [contributionId, contributionMemberPods, isCoach, isPurchased, sessionTimes, user.id]);

  if (!filteredSessionTimes?.length) {
    return null;
  }

  const timeZoneLabel = getTimeZoneLabel({ contributionTimeZoneId, timeZones });
  const incompleteSelfPacedSessionTimes = sessionTimes.filter(
    sessionTime => !sessionTime.completedSelfPacedParticipantIds.includes(user.id),
  );
  const anySessionTimeHasDateAvailable = session.sessionTimes.some(s => s.ignoreDateAvailable);
  const sortedSessionTimes = orderBy(session.sessionTimes, ['startTime'], 'asc');
  const closetSessionTime = sortedSessionTimes && sortedSessionTimes[0];
  const startTime = closetSessionTime?.startTime;
  const startTimeMoment = moment(startTime);

  const sortedSessionTimesWithIgnoreDateAvailable = sortedSessionTimes.filter(s => s.ignoreDateAvailable);
  const closetSessionTimesWithIgnoreDateAvailable =
    sortedSessionTimesWithIgnoreDateAvailable && sortedSessionTimesWithIgnoreDateAvailable[0];

  const activeTemplate = contribution?.activeTemplate || TemplateType.TemplateThree;
  const type = contribution?.type;
  const brandingColors = contribution?.brandingColors;
  const isCustomBrandingColorsActive = contribution?.isCustomBrandingColorsActive;
  // const shouldChangeColor = type != ContributionType.contributionOneToOne;
  // const customBtnColor = isCustomBrandingColorsActive
  //   ? brandingColors?.PrimaryColorCode
  //   : activeTemplate === TemplateType.TemplateOne
  //   ? '#6999CA'
  //   : '#D08ACD';
  let colorToUse = determineColorToUse(contribution);
  let textColor =
    colorToUse?.TextColorCode === 'Auto'
      ? lightOrDark(colorToUse?.PrimaryColorCode)
      : colorToUse?.TextColorCode === '#000000'
      ? '#000000'
      : '#FFFFFF';
  let textBadgeColor =
    colorToUse?.TextColorCode === 'Auto'
      ? lightOrDark(colorToUse?.AccentColorCode)
      : colorToUse?.TextColorCode === '#000000'
      ? '#000000'
      : '#FFFFFF';
  const btnColor = colorToUse.PrimaryColorCode;
  // Secondary color
  // const customProgressBarColor = isCustomBrandingColorsActive
  //   ? brandingColors?.AccentColorCode
  //   : activeTemplate === TemplateType.TemplateOne
  //   ? '#CDBA8F'
  //   : '#B78AEA';
  const progressbarColor = colorToUse.AccentColorCode;
  return (
    <SessionWrapper isDarkThemeEnabled={isDarkThemeEnabled} id={sessionId}>
      <ExpansionPanel
        style={{
          boxShadow: 'rgba(6, 24, 44, 0.1) 0px 0px 0px 1px',
          // backgroundColor: themedCardBackgroundColor,
          color: themedColor,
          backgroundColor: isDarkThemeEnabled ? 'black' : 'white',
          borderRadius: '5px',
        }}
        className="course-session"
        expanded={expanded}
        onChange={(_, newExpanded) => {
          setExpanded(newExpanded);
        }}
        TransitionProps={{ unmountOnExit: true }}
      >
        <ExpansionPanelSummary
          style={{
            display: expanded && mobileView ? '' : !expanded && 'flex',
            backgroundColor: isDarkThemeEnabled ? 'black' : 'white',
            borderRadius: '5px',
          }}
          // style={{ height: '100px' }}
          className={expanded ? classNames.sessiontime_summary : ''}
          expandIcon={
            mobileView ? (
              <ExpandMoreIcon style={{ color: themedColor }} />
            ) : expanded ? (
              <ExpandMoreIcon style={{ color: themedColor }} />
            ) : name.length <= 7 ? (
              <div style={{ fontSize: '16px' }} className="course-session-summary__type">
                <u>{text}</u>
              </div>
            ) : (
              <Typography className="course-session-summary__type course-session-summary__type_session ">
                <u>{text}</u>
              </Typography>
            )
          }
        >
          <div
            style={
              name.length <= 7
                ? { padding: '0rem 0rem' }
                : {
                    padding: mobileView && !expanded ? '0rem 0rem' : mobileView && expanded ? '0rem 0rem' : '0rem 0rem',
                  }
            }
            className="course-session-summary"
          >
            <>
              {name.length > 7 && (
                <h3
                  style={{ maxWidth: '100%', justifyContent: 'flex-start', display: 'flex' }}
                  className="course-session-summary__header"
                >
                  <span
                    className="course-session-summary__number-badge course-session-summary__number-badge_num"
                    // style={{ backgroundColor: brandingColors?.AccentColorCode }}
                    style={{
                      backgroundColor: progressbarColor,
                      marginBottom: changeStyle ? '5px' : '',
                      display: changeStyle ? 'table' : '',
                      // color: textBadgeColor,
                      marginInline: '8px',
                    }}
                  >
                    {number}
                  </span>
                  <div style={{ color: themedTextColor }}>{name}&nbsp;</div>
                </h3>
              )}
              {mobileView && (
                <div style={{ alignItems: 'center', display: 'flex' }} className="mbView_container_live_session">
                  {name.length <= 7 && (
                    <h3
                      style={{
                        maxWidth: '100%',
                        justifyContent: 'flex-start',
                        color: themedColor,
                      }}
                      className="course-session-summary__header"
                    >
                      <span
                        className="course-session-summary__number-badge course-session-summary__number-badge_num"
                        // style={{ backgroundColor: brandingColors?.AccentColorCode }}
                        style={{
                          backgroundColor: progressbarColor,
                          marginBottom: changeStyle ? '5px' : '',
                          display: changeStyle ? 'table' : '',
                          color: textColor,
                          marginInline: '8px',
                        }}
                      >
                        {number}
                      </span>
                      <div style={{ color: themedTextColor }}>{name}&nbsp;</div>
                    </h3>
                  )}

                  <p className="course-session-summary__title">{!expanded && ` (${filteredSessionTimes?.length})`}</p>
                  <Typography className="course-session-summary__type">
                    {isPrerecorded ? `Self-Paced`.toString() : 'Live Session'}
                  </Typography>

                  {isPrerecorded && !anySessionTimeHasDateAvailable === true ? (
                    <></>
                  ) : (
                    <p className="course-session-summary__status">
                      {isNil(isUpcomingSessionTime) && isCompleted
                        ? 'Completed'
                        : isPrerecorded && !anySessionTimeHasDateAvailable
                        ? ''
                        : (!isPrerecorded && isTodaySession) || (isPrerecorded && isTodayselfpacedSession)
                        ? 'Today '
                        : !isPrerecorded
                        ? `Upcoming on ${startTimeMoment.format('MMM Do YYYY')}  at ${startTimeMoment.format(
                            'h:mm a',
                          )} `
                        : `Upcoming on ${moment(closetSessionTimesWithIgnoreDateAvailable.startTime).format(
                            'MMM Do YYYY',
                          )}`}
                    </p>
                  )}
                </div>
              )}
              {!mobileView && (
                <>
                  {name.length <= 7 && (
                    <h3
                      style={{
                        maxWidth: '100%',
                        justifyContent: 'flex-start',
                        color: isDarkThemeEnabled ? 'white' : themedColor,
                        display: 'flex',
                      }}
                      className="course-session-summary__header"
                    >
                      <span
                        className="course-session-summary__number-badge course-session-summary__number-badge_num"
                        // style={{ backgroundColor: brandingColors?.AccentColorCode }}
                        style={{
                          backgroundColor: progressbarColor,
                          marginBottom: changeStyle ? '5px' : '',
                          display: changeStyle ? 'table' : '',
                          color: textColor,
                          marginInline: '8px',
                        }}
                      >
                        {number}
                      </span>
                      <div style={{ color: themedTextColor }}>{name}&nbsp;</div>
                    </h3>
                  )}
                  <p className="course-session-summary__title">{!expanded && ` (${filteredSessionTimes?.length})`}</p>
                  <Typography className="course-session-summary__type">
                    {isPrerecorded ? 'Self-Paced' : 'Live Session'}
                  </Typography>

                  <p className="course-session-summary__status">
                    {isNil(isUpcomingSessionTime) && isCompleted
                      ? 'Completed'
                      : isPrerecorded && !anySessionTimeHasDateAvailable
                      ? ' '
                      : (!isPrerecorded && isTodaySession) || (isPrerecorded && isTodayselfpacedSession)
                      ? 'Today'
                      : !isPrerecorded && isPastSession
                      ? 'Incompleted'
                      : !isPrerecorded
                      ? `Upcoming on ${startTimeMoment.format('MMM Do YYYY')} at ${startTimeMoment.format('h:mm a')}`
                      : isPrerecorded && closetSessionTimesWithIgnoreDateAvailable.ignoreDateAvailable === false
                      ? `Upcoming on ${moment(closetSessionTimesWithIgnoreDateAvailable.startTime).format(
                          'MMM Do YYYY',
                        )}`
                      : `Upcoming on ${moment(
                          closetSessionTimesWithIgnoreDateAvailable.selfPacedContentAvailableDate,
                        ).format('MMM Do YYYY')}`}
                  </p>
                  {isCoach && !clientPreviewMode && (
                    <>
                      {session?.id && !isPrerecorded && (
                        <button
                          type="button"
                          onClick={handleReschedule}
                          disabled={isCompleted}
                          className="course-session-summary__reschedule"
                          style={
                            isCompleted
                              ? { right: expanded ? '' : '130px' }
                              : {
                                  backgroundColor: btnColor,
                                  color: textColor,
                                  border: '0px',
                                  right: expanded ? '' : '130px',
                                }
                          }
                        >
                          Reschedule
                        </button>
                      )}
                    </>
                  )}
                </>
              )}
            </>
          </div>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails style={{ backgroundColor: isDarkThemeEnabled ? 'black' : 'white', borderRadius: '5px' }}>
          <div style={{ backgroundColor: isDarkThemeEnabled ? 'black' : 'white' }} className="course-session-details">
            {!isPrerecorded && (
              <h4 style={{ padding: '1rem 0px', color: themedColor }} className="course-session-details__header">
                Session Times
                {!isPrerecorded && timeZoneLabel && (
                  <StyledTimeZoneLabel href={userProfileUrl} style={{ backgroundColor: btnColor, color: textColor }}>
                    {timeZoneLabel}{' '}
                  </StyledTimeZoneLabel>
                )}
              </h4>
            )}
            <div>
              <div className="course-session-details-session-times">
                {orderBy(filteredSessionTimes, ['startTime'], ['asc']).map((st, i) => (
                  <SessionTime
                    themedColor={themedColor}
                    themedBackgroundColor={themedBackgroundColor}
                    key={st.id}
                    isPrerecorded={isPrerecorded}
                    dateAvailable={dateAvailable}
                    className="course-session-details-session-times__item"
                    number={i + 1}
                    sessionNumber={number - 1}
                    maxParticipantsCount={maxParticipantsNumber}
                    sessionTime={st}
                    convertedToSelfPaced={convertedToSelfPaced}
                    session={session}
                    isPurchased={isPurchased}
                    isCoach={isCoach && !clientPreviewMode}
                    changeShowPopUp={changeShowPopUp}
                    renderActions={sessionTime => renderSessionTimeActions(session, sessionTime, i)}
                    sessionTimeIndex={i}
                    isRescheduleDisabled={isRescheduleDisabled}
                    btnColor={btnColor}
                    contribution={contribution}
                    contributionId={contributionId}
                    setSelfPacedSessionToBeExpanded={setSelfPacedSessionToBeExpanded}
                  />
                ))}
              </div>
              {!session.isPrerecorded && (isPurchased || (isCoach && !clientPreviewMode)) && (
                <BottomPanel
                  themedColor={themedColor}
                  themedCardBackgroundColor={themedCardBackgroundColor}
                  title={title}
                  contributionId={contributionId}
                  sessionId={sessionId}
                  attachments={attachments}
                  idForAttachment={sessionId}
                  isPrerecorded={isPrerecorded}
                />
              )}
              {/* {session.isPrerecorded && session?.moreInfo && <SessionMoreInfo isDisabled value={session.moreInfo} />} */}
            </div>
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <Modal
        isOpen={isRescheduleModalOpen}
        onCancel={() => {
          setRescheduleModalState(false);
        }}
        PrimaryColor={btnColor}
        textColor={textColor}
        onSubmit={() => {
          // setRedirectToEditContributionPage(true);
          redirectToAppIfIsCoachDomain(domain, `/edit-contribution/${contribution?.id}/sessions/${sessionId}`);
          setRescheduleModalState(false);
        }}
        title="Reschedule your session"
        hiddenCancel
        submitTitle="Reschedule Session"
        disableSubmitClick={isCompleted}
      >
        <BodyText color={themedColor}>
          To reschedule, please select a new time from your calendar. Also, please confirm with your client that the new
          time works for them prior to making the change. It will automatically update the time for all parties once you
          confirm.
          <br />
          {/* <br/>
          If you need to cancel with a client, your Client will need to cancel on their end.
          At this time, Cohere does not automate refunds and thus requires approval from your Client.
          If you think your client needs a refund, please email us at <a href="mailto:support@cohere.live">support@cohere.live</a>. */}
        </BodyText>
      </Modal>
    </SessionWrapper>
  );
};

Session.propTypes = {
  number: PropTypes.number.isRequired,
  contributionTimeZoneId: PropTypes.string.isRequired,
  session: PropTypes.shape({
    dateAvailable: PropTypes.string,
    id: PropTypes.string,
    title: PropTypes.string,
    maxParticipantsNumber: PropTypes.number,
    moreInfo: PropTypes.string,
    isCompleted: PropTypes.bool,
    isPrerecorded: PropTypes.bool,
    sessionTimes: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
      }),
    ),
    attachments: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  }).isRequired,
  contributionId: PropTypes.string.isRequired,
  isPurchased: PropTypes.bool.isRequired,
  renderSessionTimeActions: PropTypes.func.isRequired,
};

export default Session;
