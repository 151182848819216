import { useContext } from 'react';

import ChatClientContext from 'components/App/ChatProvider/ChatClientContext';

const useChatClient = () => {
  const { connectionStatus, chatClient, error } = useContext(ChatClientContext);

  return {
    connectionStatus,
    chatClient,
    error,
  };
};

export default useChatClient;
