import React from 'react';
import PropTypes from 'prop-types';

import RoomLocalParticipant from './RoomLocalParticipant';
import TestLocalParticipant from './TestLocalParticipant';

const LocalParticipant = ({ isTest, ...rest }) =>
  isTest ? <TestLocalParticipant {...rest} /> : <RoomLocalParticipant {...rest} />;

LocalParticipant.propTypes = {
  isTest: PropTypes.bool.isRequired,
};

export default LocalParticipant;
