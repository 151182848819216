import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
// import { Translate } from "react-localize-redux";
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import Logo from 'components/FormUI/Logo';
import { Card } from 'components/UI/Card';
import { devices } from 'utils/styles';
import { useAccount, useHttp, useVideoChatActions } from 'hooks';
import axiosInstance from 'utils/axiosInstance';
import { fetchClientContribution, fetchCohealerContribution } from 'actions/contributions';
import { useSelector } from 'react-redux';
import { setItem } from 'services/localStorage.service';
import Loader from 'components/UI/Loader';
import { redirectTo } from 'services/links';
import { getIpGlobal } from 'utils/utils';

const CONTAINER_WIDTH = 520;

const StyledCard = styled(Card)`
  max-width: ${CONTAINER_WIDTH}px;
  margin: auto;
`;

const Container = styled.div`
  max-width: ${CONTAINER_WIDTH}px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px 24px;
  box-sizing: border-box;

  @media screen and (${devices.mobile}) {
    padding: 40px 100px;
  }
`;

const WelcomeMessage = styled.h1`
  font-size: 1.675rem;
  text-align: center;
  font-weight: 500;
  line-height: normal;
  color: rgba(0, 0, 0, 0.87);
  margin-bottom: 0;
  margin-top: 0.75;
`;

const WelcomeSubMessage = styled.h4`
  font-size: 1.125rem;
  text-align: center;
  font-weight: 500;
  line-height: 1.35;
  margin-top: 1.5rem;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 3.75rem;
  width: 100%;
  text-align: center;

  & > * + * {
    margin-top: 2.5rem;
  }
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
  color: rgba(0, 0, 0, 0.87);
  line-height: 1.17;
  letter-spacing: 0.4px;
  font-size: 0.875rem;
  font-weight: 300;

  &:hover {
    text-decoration: underline;
  }
`;

export const StyledText = styled.p`
  text-decoration: none;
  color: rgba(0, 0, 0, 0.87);
  line-height: 1.17;
  letter-spacing: 0.4px;
  font-size: 0.875rem;
  font-weight: 300;

  &:hover {
    text-decoration: underline;
  }
`;

const useStyles = makeStyles(() => ({
  input: {
    '& + &': {
      marginTop: '2rem',
    },
  },
}));

function DirectVideoCall() {
  const dispatch = useDispatch();
  const { request, loading } = useHttp();
  const { startVideoChat } = useVideoChatActions();
  const { token: tokenFromParam, sessId, sessTimeId, contributionId } = useParams();
  const { activeContribution } = useSelector(state => state.contributions);
  const [wait, setWait] = useState(true);
  const { user, token: tokenFromRedux } = useSelector(state => state.account);
  const token = tokenFromParam || tokenFromRedux || '';

  useEffect(() => {
    if (activeContribution && contributionId && !activeContribution?.isPurchased && !tokenFromParam) {
      redirectTo(`/contribution-view/${contributionId}`);
    }
  }, [activeContribution, contributionId, tokenFromParam]);

  useEffect(() => {
    if (token) {
      axiosInstance
        .get('Auth/GetAccountInfo', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(async sucs => {
          const userIP = await getIpGlobal();
          let payload = {
            ...sucs.data,
            currentRole: sucs.data?.account?.accountPreferences?.userView,
            account: {
              ...sucs.data?.account,
              oAuthToken: token,
            },
          };
          setItem('token', token);
          dispatch({ type: 'USER_SETUP', payload: payload });
          dispatch(fetchClientContribution(contributionId, userIP));
        })
        .catch(err => {});
    }
  }, []);

  useEffect(() => {
    if (activeContribution && activeContribution.id === contributionId) {
      let isCoach = false;
      if (activeContribution.userId === user.id) {
        isCoach = true;
      } else {
        if (activeContribution?.contributionPartners && activeContribution?.contributionPartners.length > 0) {
          for (let partner of activeContribution?.contributionPartners) {
            if (partner?.userId === user?.id) {
              isCoach = true;
              break;
            }
          }
        }
      }
      if (isCoach) {
        onLaunchSession({});
      } else {
        joinSession();
      }
      setWait(false);
    }
  }, [activeContribution]);

  const onLaunchSession = ({}) => {
    request('/Video/CreateRoomAndGetToken', 'POST', {
      contributionId: contributionId,
      classId: sessTimeId,
      identityName: `${user.firstName} ${user.lastName}`,
    }).then(({ room: { sid: roomId }, token }) => {
      setWait(false);
      startVideoChat({
        contributionId: contributionId,
        type: activeContribution.type,
        sessionId: sessId,
        classId: sessTimeId,
        title: activeContribution.title,
        roomId,
        chatId: activeContribution?.chat?.sid,
        token,
        deleteRoomOnVideoEnd: true,
      });
    });
  };

  const joinSession = () => {
    request('/Video/GetClientToken', 'POST', {
      contributionId: contributionId,
      classId: sessTimeId,
      identityName: `${user.firstName} ${user.lastName}`,
    }).then(({ token }) => {
      setWait(false);
      startVideoChat({
        contributionId: contributionId,
        type: activeContribution.type,
        sessionId: sessId,
        classId: sessTimeId,
        title: activeContribution.title,
        chatId: activeContribution?.chat?.sid,
        token,
      });
    });
  };

  return (
    <>
      <StyledCard>
        <Container>{wait ? <Loader /> : null}</Container>
      </StyledCard>
    </>
  );
}

export default DirectVideoCall;
