import React from 'react';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import classes from './Searchbox.module.scss';

const SearchBox = ({
  placeholder = 'Search here',
  background = '',
  containerClassName = '',
  onChange = () => {},
  searchIconColor,
}) => {
  let containerClass = classes.container;
  if (containerClassName) {
    containerClass = `${containerClass} ${containerClassName}`;
  }
  return (
    <div className={containerClass} style={{ backgroundColor: background }}>
      <IconButton sx={{ p: '5px 0 5px 10px' }} aria-label="search">
        <SearchIcon htmlColor={searchIconColor} />
      </IconButton>
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        placeholder={placeholder}
        inputProps={{
          'aria-label': 'search campaigns',
          onChange: onChange,
        }}
      />
    </div>
  );
};

export default SearchBox;
