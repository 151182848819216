import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';

import * as userActions from 'actions/user';
import { setCookie } from 'services/cookies.service';
import useRouter from 'hooks/useRouter';

function LogOut() {
  const dispatch = useDispatch();
  const [redirect, setRedirect] = useState(false);
  const { parentDomain } = useRouter();
  useEffect(() => {
    const pDomain = parentDomain.split(':3000')[0];
    setCookie('token', '', pDomain, 30);
    dispatch(userActions.logOut());
    setRedirect(true);
  }, [dispatch]);

  return redirect ? <Redirect to="/auth/signin" /> : null;
}

export default LogOut;
