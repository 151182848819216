import { makeStyles } from '@material-ui/core';

const styles = () => ({
  overlay: {
    height: '100%',
    left: 0,
    position: 'absolute',
    top: 0,
    width: '100%',
    zIndex: '12',
  },
});

export const useStyles = makeStyles(styles);
