import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

import { useHttp, useAccount, useVideoChatActions } from 'hooks';
import Card from 'components/Card/Card';
import { useRecordModal } from 'hooks/useSessionRecordModal';

import './CardSession.scss';

const CardSession = ({ card: { id, title, closestSession } }) => {
  const { request } = useHttp();
  const { user } = useAccount();
  const { startVideoChat } = useVideoChatActions();
  const nextSession = closestSession || {};
  const { enrolledTotal = 0, enrolledMax = 0, type = null, classId, contributionId } = nextSession;

  const handleLaunchSession = ({ RecordParticipantsOnConnect }) => {
    const { classGroupId, chatSid } = closestSession;

    request('/Video/CreateRoomAndGetToken', 'POST', {
      contributionId,
      classId,
      RecordParticipantsOnConnect,
      identityName: `${user.firstName} ${user.lastName}`,
    }).then(({ room: { sid: roomId }, token }) =>
      startVideoChat({
        contributionId,
        type,
        sessionId: classGroupId,
        classId,
        title: closestSession.title,
        roomId,
        chatId: chatSid,
        token,
        deleteRoomOnVideoEnd: true,
      }),
    );
  };

  const onSubmit = () => {
    handleLaunchSession({ RecordParticipantsOnConnect: true });
  };
  const onCancel = () => {
    handleLaunchSession({ RecordParticipantsOnConnect: false });
  };

  const modalOptions = {
    onSubmit,
    onCancel,
    classId,
    contributionType: type,
    contributionId,
    zoomStartUrl: nextSession.zoomStartUrl,
    liveVideoServiceProvider: closestSession?.liveVideoServiceProvider,
  };
  const { RecordModal, launchRecodModal } = useRecordModal(modalOptions);

  return (
    <Card
      id={id}
      title={title}
      subTitle={closestSession?.title}
      type={closestSession?.type}
      sessionId={closestSession?.classGroupId}
      onLaunchSession={launchRecodModal}
      isSession
      hasClosestSession={!!closestSession}
      noShadow
    >
      <div className="side side_left">
        <span className="text_regular">{moment(closestSession?.startTime).format('LLL')}</span>
      </div>
      <div className="side side_right">
        <span className="text_regular">Clients</span>
        <span className="text_regular">{`${enrolledTotal}/${enrolledMax}`}</span>
      </div>
      <RecordModal />
    </Card>
  );
};

CardSession.propTypes = {
  card: PropTypes.shape().isRequired,
};

export default CardSession;
