import React, { useState, useCallback, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Formik, Field, Form } from 'formik';
// import { Translate } from "react-localize-redux";
import { connect, useDispatch } from 'react-redux';
import moment from 'moment';
import InfoIcon from '@material-ui/icons/Info';
import Tooltip from '@material-ui/core/Tooltip';
import { setItem, getItem } from 'services/localStorage.service';
import Logo from 'components/FormUI/Logo';
import Input from 'components/FormUI/Input';
import Select from 'components/FormUI/Select';
import MaskedInput from 'components/FormUI/MaskedInput';
import Checkbox from '@material-ui/core/Checkbox';
import Button from 'components/FormUI/Button';
import CommonErrorMessage from 'components/FormUI/CommonErrorMessage';
import { ModalTermsAndConditions } from 'components/Modals/TermsAndConditions';
import { Card, CardBody } from 'components/UI/Card';
import { getSignUpValidationSchema } from 'utils/validation';
import * as userActions from 'actions/user';
import * as timeZoneActions from 'actions/timeZone';
import * as countryActions from 'actions/country';
import { colors } from 'utils/styles';
import { DATE_FORMATS } from 'utils/datesAndMoney';
import { redirectTo } from 'services/links';
import { setTemporaryDataForSwtichingRole } from 'actions/update-user';
import useRouter from 'hooks/useRouter';
import { SIGNUP_TYPES, UserRoles } from 'helpers/constants';
import { TOOLTIP, ROUTES } from '../../constants';

const useStyles = makeStyles(theme => ({
  popover: {
    pointerEvents: 'none',
    fontSize: 14,
  },
  paper: {
    padding: theme.spacing(1),
    transform: 'translateY(-10px) !important',
    maxWidth: 500,
  },
  container: {
    maxWidth: 530,
  },
}));

const WelcomeMessage = styled.h1`
  font-size: 1.675rem;
  text-align: center;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 1.5rem;
`;

const AgreementContainer = styled.div`
  text-align: center;
  margin: 2rem 0;
  line-height: 1.28;
  letter-spacing: 0.25px;
  font-weight: 500;
  font-size: 0.9375rem;
  width: 100%;

  a {
    color: inherit;
  }
`;

const ButtonsContainer = styled.div`
  margin-top: 2.5rem;
  text-align: center;
  width: 100%;

  & > * + * {
    margin-top: 1.5rem;
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  line-height: 1.17;
  letter-spacing: 0.4px;
  font-size: 0.875rem;
  font-weight: 300;

  &:hover {
    text-decoration: underline;
  }
`;

const StyledMainCard = styled(Card)`
  max-width: 580px;
  margin: auto;
`;

const StyledTerms = styled.a`
  color: ${colors.darkOceanBlue} !important;
`;

const StyledStripeSupportedCountries = styled.a`
  color: ${colors.darkOceanBlue} !important;
`;

function CountryTooltip() {
  return (
    <p>
      Country of Residence{' '}
      <Tooltip
        title="Please choose your country of residence. 
            This is where you are eligible to receive payments and the location of your bank account."
        arrow
        enterTouchDelay={TOOLTIP.ENTER_DELAY}
        leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
      >
        <InfoIcon htmlColor={colors.lightBrown} />
      </Tooltip>
    </p>
  );
}

function SignUpSecondScreen({
  loading,
  user,
  continueRegister,
  timeZoneLoading,
  timeZones,
  fetchTimeZones,
  countryLoading,
  countries,
  fetchCountries,
  error,
}) {
  const dispatch = useDispatch();
  const router = useRouter();
  const classes = useStyles();
  const { pathname } = useLocation();
  const [showTerms, setShowTerms] = useState(false);
  const isAddingCoachRole = false;
  // pathname === ROUTES.ADD_ROLE;

  useEffect(() => {
    if ((!timeZones || timeZones.length === 0) && !timeZoneLoading) {
      fetchTimeZones();
    }
    if ((!countries || countries.length === 0) && !countryLoading) {
      fetchCountries();
    }
  }, [timeZones, fetchTimeZones, countries, fetchCountries]);
  const accId = getItem('accountId');
  const registerHandler = useCallback(
    values => {
      if (isAddingCoachRole) {
        const data = {
          timeZoneId: values.TimeZoneId,
          countryId: values.CountryId,
        };

        try {
          dispatch(setTemporaryDataForSwtichingRole(data));
          router.push(ROUTES.SUBMIT_ADD_ROLE);
        } catch {
          redirectTo(ROUTES.DASHBOARD);
        }
      } else {
        let type = '';
        if (router.pathname.includes('coheresignupa')) {
          type = SIGNUP_TYPES.TYPE_A;
        } else if (router.pathname.includes('coheresignupb')) {
          type = SIGNUP_TYPES.TYPE_B;
        }
        const data = {
          ...values,
          accountId: user.accountId || accId,
          signupType: type,
          IsCohealer: true,
        };

        continueRegister({ fields: data, userView: UserRoles.cohealer });
      }
    },
    [continueRegister, user.accountId, isAddingCoachRole],
  );

  const isStripeSupportedCuntrySelected = countryId => {
    return countries?.find(t => t.id === countryId)?.stripeSupportedCountry === true;
  };

  return (
    <>
      <StyledMainCard>
        <CardBody>
          <Grid
            container
            direction="column"
            alignItems="stretch"
            classes={{
              container: classes.container,
            }}
          >
            <Grid container item>
              <Logo />
            </Grid>
            <Grid item>
              <WelcomeMessage>
                Register as a coach{' '}
                <Tooltip
                  title="Are you an inspiring coach, teacher, or mentor in the field of personal,
                  professional, or spiritual development? Then this platform is for you!
                  Cohere helps you scale your businesses using a single platform. Your
                  customers can purchase and participate in your group courses, work with
                  you One-on-One, and join your community of existing clients and
                  customers."
                  arrow
                  enterTouchDelay={TOOLTIP.ENTER_DELAY}
                  leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                >
                  <InfoIcon htmlColor={colors.lightBrown} />
                </Tooltip>
              </WelcomeMessage>
            </Grid>

            <Formik
              enableReinitialize
              initialValues={{
                FirstName: user.firstName || '',
                LastName: user.lastName || '',
                TimeZoneId: user.timeZoneId || '',
                CountryId: user.countryId || '',
                HasAgreedToTerms: false,
              }}
              validationSchema={getSignUpValidationSchema()}
              onSubmit={registerHandler}
            >
              {({ values, handleChange }) => (
                <Form>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Input label="First Name" type="text" name="FirstName" disabled={isAddingCoachRole} fullWidth />
                    </Grid>
                    <Grid item xs={12}>
                      <Input label="Last Name" type="text" name="LastName" disabled={isAddingCoachRole} fullWidth />
                    </Grid>
                  </Grid>

                  <Grid container spacing={4}>
                    <Grid item xs={12}>
                      <Select
                        label="Timezone"
                        name="TimeZoneId"
                        fullWidth
                        // disabled={isAddingCoachRole}
                        items={
                          timeZones?.length > 0
                            ? timeZones.map(timeZone => {
                                return { value: timeZone.countryName, title: timeZone.name };
                              })
                            : []
                        }
                        onChange={e => {
                          values.CountryId = timeZones?.find(t => t.countryName === e?.target?.value)?.countryId ?? '';
                          handleChange(e);
                        }}
                      />
                      {/* if the country is not supported stripe, show message and disable submit button */}
                      <Select
                        // label="Country of Residence"
                        label={CountryTooltip()}
                        name="CountryId"
                        fullWidth
                        // disabled={isAddingCoachRole}
                        items={
                          countries?.length > 0
                            ? countries.map(country => {
                                return { value: country.id, title: country.name };
                              })
                            : []
                        }
                      />

                      {values.CountryId?.length > 0 && !isStripeSupportedCuntrySelected(values.CountryId) && (
                        <span>
                          <center>
                            {countries?.find(c => c.id === values.CountryId)?.name} is not supported at the moment.
                            see&nbsp;
                            <StyledStripeSupportedCountries href="https://stripe.com/global" target="_blank">
                              stripe supported countries
                            </StyledStripeSupportedCountries>
                            &nbsp;for more info
                          </center>
                        </span>
                      )}
                    </Grid>
                  </Grid>

                  <Grid container>
                    <AgreementContainer>
                      <Field as={Checkbox} name="HasAgreedToTerms" type="checkbox" color="primary" />
                      &nbsp;&nbsp;&nbsp;I agree to the{' '}
                      <StyledTerms
                        href="#"
                        onClick={e => {
                          e.preventDefault();
                          setShowTerms(true);
                        }}
                      >
                        Terms and Conditions
                      </StyledTerms>
                      , and I'm at least 18 years old.
                    </AgreementContainer>
                  </Grid>

                  {error?.message && <CommonErrorMessage message={error.message} />}

                  <Grid container>
                    <ButtonsContainer>
                      <Button
                        // disabled={
                        //   !values.HasAgreedToTerms || loading || !isStripeSupportedCuntrySelected(values?.CountryId)
                        // }
                        // onClick={()=>{console.log("values=======>",values, loading)}}
                        type="submit"
                      >
                        Join Cohere
                      </Button>
                      <div>
                        <StyledLink to="/auth/signup/client/continue">Not a service provider? Click here.</StyledLink>
                      </div>
                    </ButtonsContainer>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Grid>
        </CardBody>
      </StyledMainCard>

      <ModalTermsAndConditions showTerms={showTerms} onCancel={() => setShowTerms(false)} />
    </>
  );
}

SignUpSecondScreen.propTypes = {
  loading: PropTypes.bool.isRequired,
  user: PropTypes.shape({
    accountId: PropTypes.string,
  }).isRequired,
  continueRegister: PropTypes.func.isRequired,
  timeZone: PropTypes.shape({
    timeZones: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  country: PropTypes.shape({
    countries: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      message: PropTypes.string,
    }),
  ]),
  timeZoneLoading: PropTypes.bool.isRequired,
  countryLoading: PropTypes.bool.isRequired,
  fetchTimeZones: PropTypes.func.isRequired,
  fetchCountries: PropTypes.func.isRequired,
};

SignUpSecondScreen.defaultProps = {
  error: null,
  fetchTimeZones: () => null,
};

const mapStateToProps = ({ account, timeZone, country }) => ({
  user: account?.user || {},
  loading: account?.isLoading,
  error: account?.error,
  timeZones: timeZone?.timeZones,
  timeZoneLoading: timeZone?.loading,
  countries: country?.countries,
  countryLoading: country?.loading,
});

const actions = {
  continueRegister: userActions.continueRegister,
  fetchTimeZones: timeZoneActions.fetchTimeZones,
  fetchCountries: countryActions.fetchCountries,
};

export default connect(mapStateToProps, actions)(SignUpSecondScreen);
