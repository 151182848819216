import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import useRouter from 'hooks/useRouter';
import useAccount from 'hooks/useAccount';

import { LabelText } from 'components/UI/Text/TextStyles';
import { ClientJourneyContribution } from 'services/dataContracts/contributions';
import { ContributionType, JOURNEY_ROUTES } from 'helpers/constants';
import { getClientPurchases } from 'services/contributions.service';
import ContributionsList from '../../ContributionsList';
import AcademyContributionList from '../../ContributionsList/AcademyContributionsList';
import { useDispatch } from 'react-redux';
import { setContributionsLoader } from 'actions/contributions';

const AllPurchasesContributions = ({ contributions }) => {
  const sortKeySelector = useCallback(c => c.purchaseDateTime.unix(), []);
  const [purchasedContributions, setPurchasedContributions] = useState([]);
  const dispatch = useDispatch();
  const { pathname } = useRouter();
  const { user } = useAccount();

  const isMembersip = pathname.includes(JOURNEY_ROUTES.MEMBERSHIP);
  const isOneToOne = pathname.includes(JOURNEY_ROUTES.ONE_TO_ONE);
  const isLive = pathname.includes(JOURNEY_ROUTES.LIVE);
  const isWorkshop = pathname.includes(JOURNEY_ROUTES.WORKSHOP);

  const filterByType = contribs => {
    if (isMembersip) {
      return contribs.filter(el => el.type === ContributionType.contributionMembership);
    } else if (isOneToOne) {
      return contribs.filter(el => el.type === ContributionType.contributionOneToOne);
    } else if (isLive) {
      return contribs.filter(el => el.type === ContributionType.contributionCourse && el.isWorkshop === false);
    } else if (isWorkshop) {
      return contribs.filter(el => el.type === ContributionType.contributionCourse && el.isWorkshop === true);
    } else {
      return contribs;
    }
  };

  useEffect(() => {
    dispatch(setContributionsLoader(true));
    getClientPurchases(user.id, false)
      .then(filterByType)
      .then(res => {
        setPurchasedContributions(res);
        setTimeout(() => {
          dispatch(setContributionsLoader(false));
        }, 1000);
      })
      .catch(() => {
        setTimeout(() => {
          dispatch(setContributionsLoader(false));
        }, 1000);
      });
  }, []);

  const contributionConditions = [isMembersip, isOneToOne, isLive, isWorkshop];

  if (contributionConditions.includes(true)) {
    return <AcademyContributionList contributions={purchasedContributions} />;
  }

  if (contributions.length === 0) {
    return <LabelText>You have no purchases yet.</LabelText>;
  }

  return <ContributionsList contributions={contributions} sortKeySelector={sortKeySelector} />;
};

AllPurchasesContributions.propTypes = {
  contributions: PropTypes.arrayOf(PropTypes.instanceOf(ClientJourneyContribution)).isRequired,
};

export default AllPurchasesContributions;
