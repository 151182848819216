import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import * as R from 'ramda';
import { colors } from 'utils/styles';
import { useSelector } from 'react-redux';

import NoteModal from 'pages/ContributionView/components/NoteModal/NoteModalContainer';
import { useSessionAttachments } from 'hooks/useSessionAttachments';
import { isEmptyOrNil } from 'utils/utils';
import { TOOLTIP } from '../../../../../../../src/constants';
import { Tooltip } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';

import './BottomPanel.scss';
import { CircularProgressWithLabel } from 'components/UI/CircularProgressWithLabel/CircularProgressWithLabel';
import styled from 'styled-components';
import { Box } from '@material-ui/core';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import { connect } from 'react-redux';
import useAccount from 'hooks/useAccount';
import { TemplateType } from 'helpers/constants/templateType';
import { determineColorToUse, determineDarkThemedColorToUse, getThemedColors } from 'services/contributions.service';

const StyledProgressWrap = styled(Box)`
  width: 40px;
  height: 40px;
  margin-right: 10px;
  margin-top: -8px;
  margin-bottom: -8px;
`;
const StyledAttachText = styled.span`
  text-transform: initial;
`;

const StyledFileLink = styled.a`
  color: ${colors.darkOceanBlue};
  font-weight: 600;
  font-size: 14px;
  display: flex;
  align-items: center;
  margin-left: -6px;
  padding: 6px 6px 6px 0;

  &:hover {
    color: ${colors.darkOceanBlue};
  }
`;

export const BottomPanel = ({
  title,
  sessionId,
  contributionId,
  attachments,
  idForAttachment,
  isPrerecorded,

  // contribution,
}) => {
  const [modalOpened, setModalOpened] = useState(false);
  const {
    progress,
    isFileUpload,
    // isCoach,
    handleUploadFile,
    handleDownloadFile,
    handleRemoveFile,
  } = useSessionAttachments(sessionId, contributionId, isPrerecorded, idForAttachment);

  // const { user, currentRole } = useAccount();
  const contribution = useSelector(state => state.contributions?.activeContribution);
  const {
    themedColor: themedTextColor,
    themedCardBackgroundColor: oldThemedCardBackgroundColor,
    newThemedTextColor,
    newThemedCardColor,
  } = getThemedColors(contribution);

  const { currentRole } = useAccount();
  const isCoach = currentRole == 'Cohealer';
  const themedColor = isCoach ? themedTextColor : newThemedTextColor;
  const themedCardBackgroundColor = isCoach ? oldThemedCardBackgroundColor : newThemedCardColor;
  const activeTemplate = contribution?.activeTemplate || TemplateType.TemplateThree;

  const isCustomBrandingColorsActive = contribution?.isCustomBrandingColorsActive;
  // const shouldChangeColor = contribution?.type != ContributionType.contributionOneToOne;
  // const customBtnColor = isCustomBrandingColorsActive
  //   ? contribution?.brandingColors?.AccentColorCode
  //   : activeTemplate === TemplateType.TemplateOne
  //   ? '#6999CA'
  //   : '#D08ACD';
  const colorToUse = determineColorToUse(contribution);
  const btnColor = colorToUse.AccentColorCode;

  const handleOpenModal = () => {
    setModalOpened(true);
  };

  const handleCloseModal = () => {
    setModalOpened(false);
  };
  const isDarkThemeEnabled = determineDarkThemedColorToUse(contribution);

  return (
    <div className="session-attachments position-relative mt-4">
      <span className="h4" style={{ color: isDarkThemeEnabled ? themedColor : btnColor }}>
        Attachments
      </span>
      <span tabIndex="0" role="button" className="h4 notes" onClick={handleOpenModal} onKeyPress={handleOpenModal}>
        <span style={{ color: isDarkThemeEnabled ? themedColor : btnColor }}>Personal Notes</span>
        <span
          className="ml-3 session-empty-panel-content"
          style={{ color: isDarkThemeEnabled ? themedColor : btnColor }}
        >
          Open Notes
        </span>
      </span>
      <div
        className="mt-2 attached-file-list"
        style={{ display: 'block', flexWrap: 'wrap', color: isDarkThemeEnabled ? themedColor : btnColor }}
      >
        {isEmptyOrNil(attachments) ? (
          <span className="session-empty-panel-content" style={{ color: isDarkThemeEnabled ? themedColor : btnColor }}>
            None
          </span>
        ) : (
          attachments.map(
            (
              {
                id: documentId,
                contentType,
                attachementUrl,
                documentKeyWithExtension,
                documentOriginalNameWithExtension,
              },
              idx,
            ) => (
              <div
                key={documentId}
                className="d-flex align-items-center"
                style={{ marginRight: '50px', color: themedColor }}
              >
                {/* <Button
                  classes={{
                    root: 'text-lowercase regular-tex download-attach',
                  }}
                  onClick={handleDownloadFile}
                  disableRipple
                  data-document-id={documentId}
                  data-document-name={documentOriginalNameWithExtension}
                  data-content-type={contentType}
                >
                  <span>{`${R.inc(idx)}.`}</span>
                  <span className="ml-2">{documentOriginalNameWithExtension}</span>
                </Button> */}
                <Button
                  onClick={handleDownloadFile}
                  disableRipple
                  data-document-id={documentId}
                  data-document-name={documentOriginalNameWithExtension}
                  data-content-type={contentType}
                >
                  <Tooltip
                    title={documentOriginalNameWithExtension}
                    arrow
                    enterTouchDelay={TOOLTIP.ENTER_DELAY}
                    leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                  >
                    <StyledFileLink
                      style={{ color: isDarkThemeEnabled ? themedColor : btnColor }}
                      download
                      target="_blank"
                    >
                      <DescriptionOutlinedIcon
                        style={{ fontSize: 40, color: isDarkThemeEnabled ? themedColor : btnColor }}
                      />
                      {documentOriginalNameWithExtension}
                      {/* {documentOriginalNameWithExtension?.length > 15
                        ? documentOriginalNameWithExtension.substr(0, 7) +
                          '...' +
                          documentOriginalNameWithExtension.substr(-10)
                        : documentOriginalNameWithExtension} */}
                    </StyledFileLink>
                  </Tooltip>
                </Button>
                {isCoach && (
                  <CloseIcon
                    fontSize="small"
                    className="cursor-pointer"
                    onClick={handleRemoveFile}
                    data-document-id={documentId}
                    data-document-extension={documentKeyWithExtension}
                  />
                )}
              </div>
            ),
          )
        )}
      </div>
      {isCoach && (
        <>
          <input
            accept="*"
            className="d-none"
            id={`attach-file-${idForAttachment}`}
            type="file"
            onChange={handleUploadFile}
            multiple
          />
          <label htmlFor={`attach-file-${idForAttachment}`}>
            <Button
              disabled={isFileUpload || (isPrerecorded && attachments.length == 5)}
              classes={{
                root: 'position-absolute text-capitalize attach-button',
              }}
              disableRipple
              component="span"
            >
              {isFileUpload ? (
                <StyledProgressWrap style={{ color: isDarkThemeEnabled ? themedColor : btnColor }} position="relative">
                  <CircularProgressWithLabel value={progress} />
                </StyledProgressWrap>
              ) : (
                <AttachFileIcon style={{ color: isDarkThemeEnabled ? themedColor : btnColor }} className="mr-3" />
              )}
              <StyledAttachText style={{ color: isDarkThemeEnabled ? themedColor : btnColor }} className="regular-text">
                Attach a File
              </StyledAttachText>
              {isPrerecorded && (
                <Tooltip
                  title="You can attach up to 5 files per content per module"
                  arrow
                  enterTouchDelay={TOOLTIP.ENTER_DELAY}
                  leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                  style={{ marginLeft: '5px' }}
                >
                  <InfoIcon htmlColor={colorToUse.PrimaryColorCode} />
                </Tooltip>
              )}
            </Button>
          </label>
        </>
      )}
      {modalOpened && (
        <NoteModal
          noteProps={{
            title,
            classId: idForAttachment,
            subClassId: sessionId,
            contributionId,
            isPrerecorded,
          }}
          themedColor={themedColor}
          themedCardBackgroundColor={themedCardBackgroundColor}
          isOpen={modalOpened}
          onModalClose={handleCloseModal}
          onConfirm={handleCloseModal}
        />
      )}
    </div>
  );
};

BottomPanel.propTypes = {
  title: PropTypes.string.isRequired,
  sessionId: PropTypes.string.isRequired,
  contributionId: PropTypes.string.isRequired,
  attachments: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

const mapStateToProps = ({ contributions }) => ({
  brandingColors: contributions?.activeContribution?.brandingColors,
  contributions: contributions,
});

export default connect(mapStateToProps)(BottomPanel);
