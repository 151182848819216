import { handleActions } from 'redux-actions';
import { getTestimonialTemplatesAction, getTestimonialsAction } from 'actions/testimonials';

const initialState = {
  testimonials: [],
  testimonialTemplates: [],
};

const testimonialsReducer = handleActions(
  {
    [getTestimonialsAction]: (state, { payload }) => ({
      ...state,
      testimonials: payload,
    }),
    [getTestimonialTemplatesAction]: (state, { payload }) => ({
      ...state,
      testimonialTemplates: payload,
    }),
  },
  initialState,
);

export default testimonialsReducer;
