import { updateChannelUnreadMessagesCount } from './actions';

const initialChannelsUnreadMessages = {};

const channelsUnreadMessagesReducer = (state, { type, payload }) => {
  switch (type) {
    case updateChannelUnreadMessagesCount.toString(): {
      const { channelsUnreadMessagesCount } = payload;

      const newState = Object.entries(channelsUnreadMessagesCount).reduce(
        (channelsUnreadMessages, [channelSid, channelUnreadMessages]) => {
          const {
            channel: { lastConsumedMessageIndex, lastMessage },
            unreadMessagesCount,
          } = channelUnreadMessages;

          return {
            ...channelsUnreadMessages,
            [channelSid]: {
              lastConsumedMessageIndex,
              lastMessageIndex: lastMessage ? lastMessage.index : null,
              unreadMessagesCount,
            },
          };
        },
        {},
      );

      return {
        ...state,
        ...newState,
      };
    }
    default:
      return state;
  }
};

export default channelsUnreadMessagesReducer;
export { initialChannelsUnreadMessages };
