import React from 'react';
import PropTypes from 'prop-types';

import { formatMoney } from 'utils/datesAndMoney';
import { getPaymentOptionsView } from 'utils/getPaymentOptionsView';
import { ContributionType } from 'helpers/constants';
import { PAYMENT_OPTIONS } from '../../../constants';

const renderPricePerMonthlySessionSubscription = (
  cost,
  defaultSymbol,
  defaultCurrency,
  summary,
  monthlySessionSubscriptionInfo,
) => {
  return (
    <>
      <div className="mt-2">
        Per Month - Includes {monthlySessionSubscriptionInfo.sessionCount} Sessions Monthly,{' '}
        {monthlySessionSubscriptionInfo.duration} Months Total
      </div>
      <div id="pricePerMonth">
        {defaultSymbol +
          formatMoney(monthlySessionSubscriptionInfo.monthlyPrice * monthlySessionSubscriptionInfo.duration) +
          ' ' +
          defaultCurrency.toUpperCase()}
      </div>
    </>
  );
};

const renderFullPaymentDiscount = (cost, summary, defaultCurrency, defaultSymbol) => {
  const costWithDiscount = summary?.price; /* - summary?.platformFee */

  if (cost === costWithDiscount) {
    return null;
  }

  return (
    <>
      <div className="mt-2">Full Payment Discount</div>
      <div id="priceFullPayment">
        {defaultSymbol + formatMoney(summary?.price /* - summary?.platformFee */) + ' ' + defaultCurrency.toUpperCase()}
      </div>
    </>
  );
};

const renderMembershipPayments = ({ paymentOptions, membershipInfo, defaultCurrency, defaultSymbol }) => (
  <>
    {paymentOptions.includes(PAYMENT_OPTIONS.PER_MONTH) && membershipInfo && (
      <>
        <div>Monthly subscription</div>
        <div id="priceMonthlySubscription">
          {defaultSymbol + formatMoney(membershipInfo?.costs?.MonthlyMembership) + ' ' + defaultCurrency.toUpperCase()}{' '}
        </div>
      </>
    )}
    {paymentOptions.includes(PAYMENT_OPTIONS.PER_YEAR) && membershipInfo && (
      <>
        <div>Yearly subscription</div>
        <div id="priceYearlySubscription">
          {defaultSymbol + formatMoney(membershipInfo?.costs?.YearlyMembership) + ' ' + defaultCurrency.toUpperCase()}{' '}
        </div>
      </>
    )}
    {paymentOptions.includes(PAYMENT_OPTIONS.PACKAGE) && membershipInfo && (
      <>
        <div>Packages</div>
        <div id="pricePackages">
          {defaultSymbol + formatMoney(membershipInfo?.membershipPackage?.cost) + ' ' + defaultCurrency.toUpperCase()}{' '}
          for
          {membershipInfo?.membershipPackage?.duration} months
        </div>
      </>
    )}
  </>
);

const renderCommunityPayments = ({ paymentOptions, membershipInfo, defaultCurrency, defaultSymbol }) => (
  <>
    {paymentOptions.includes(PAYMENT_OPTIONS.PER_MONTH) && membershipInfo && (
      <>
        <div>Monthly subscription</div>
        <div id="priceMonthlySubscription">
          {defaultSymbol + formatMoney(membershipInfo?.costs?.MonthlyMembership) + ' ' + defaultCurrency.toUpperCase()}{' '}
        </div>
      </>
    )}
    {paymentOptions.includes(PAYMENT_OPTIONS.PER_YEAR) && membershipInfo && (
      <>
        <div>Yearly subscription</div>
        <div id="priceYearlySubscription">
          {defaultSymbol + formatMoney(membershipInfo?.costs?.YearlyMembership) + ' ' + defaultCurrency.toUpperCase()}{' '}
        </div>
      </>
    )}
    {paymentOptions.includes(PAYMENT_OPTIONS.PACKAGE) && membershipInfo && (
      <>
        <div>Packages</div>
        <div id="pricePackages">
          {defaultSymbol + formatMoney(membershipInfo?.membershipPackage?.cost) + ' ' + defaultCurrency.toUpperCase()}{' '}
          for
          {membershipInfo?.membershipPackage?.duration} months
        </div>
      </>
    )}
  </>
);

const renderOneToOnePayments = ({
  paymentOptions,
  cost,
  packageSessionNumbers,
  summary,
  defaultCurrency,
  defaultSymbol,
  monthlySessionSubscriptionInfo,
  multiplePriceList,
}) =>
  multiplePriceList.length === 0 ? (
    <>
      {paymentOptions.includes(PAYMENT_OPTIONS.PER_SESSION) && (
        <>
          <div>Per Session</div>
          <div id="pricePerSession">{defaultSymbol + formatMoney(cost) + ' ' + defaultCurrency.toUpperCase()}</div>
        </>
      )}
      {paymentOptions.includes(PAYMENT_OPTIONS.SESSIONS_PACKAGE) && (
        <>
          <div className="mt-2">Per {packageSessionNumbers} Session Package</div>
          <div id="priceSessionPackage">
            {defaultSymbol +
              formatMoney(
                summary[PAYMENT_OPTIONS.SESSIONS_PACKAGE]?.price -
                  summary[PAYMENT_OPTIONS.SESSIONS_PACKAGE]?.platformFee,
              ) +
              ' ' +
              defaultCurrency.toUpperCase()}{' '}
          </div>
        </>
      )}
      {paymentOptions.includes(PAYMENT_OPTIONS.MONTHLY_SESSION_SUBSCRIPTION) &&
        renderPricePerMonthlySessionSubscription(
          cost,
          defaultSymbol,
          defaultCurrency,
          summary[PAYMENT_OPTIONS.MONTHLY_SESSION_SUBSCRIPTION],
          monthlySessionSubscriptionInfo,
        )}
    </>
  ) : (
    <>
      {paymentOptions.includes(PAYMENT_OPTIONS.PER_SESSION) && (
        <>
          <div>Per Session</div>
          {multiplePriceList.map(info => (
            <div id="pricePerSession">
              {defaultSymbol + formatMoney(info.cost) + ' ' + defaultCurrency.toUpperCase()}
            </div>
          ))}
        </>
      )}
      {paymentOptions.includes(PAYMENT_OPTIONS.SESSIONS_PACKAGE) && (
        <>
          <div className="mt-2">Per {packageSessionNumbers} Session Package</div>
          {multiplePriceList.map(info => (
            <div id="priceSessionPackage">
              {defaultSymbol + formatMoney(info.packageCost) + ' ' + defaultCurrency.toUpperCase()}
            </div>
          ))}
        </>
      )}
      {paymentOptions.includes(PAYMENT_OPTIONS.MONTHLY_SESSION_SUBSCRIPTION) &&
        renderPricePerMonthlySessionSubscription(
          cost,
          defaultSymbol,
          defaultCurrency,
          summary[PAYMENT_OPTIONS.MONTHLY_SESSION_SUBSCRIPTION],
          monthlySessionSubscriptionInfo,
        )}
    </>
  );

const renderLiveCoursePayments = ({
  paymentOptions,
  cost,
  summary,
  splitNumbers,
  splitPeriod,
  defaultCurrency,
  defaultSymbol,
}) => (
  <>
    {paymentOptions.includes(PAYMENT_OPTIONS.ENTIRE_COURSE) && (
      <>
        <div>Price Per Course</div>
        <div id="pricePerCourse">{defaultSymbol + formatMoney(cost) + ' ' + defaultCurrency.toUpperCase()}</div>
        {renderFullPaymentDiscount(cost, defaultCurrency, defaultSymbol, summary[PAYMENT_OPTIONS.ENTIRE_COURSE])}
      </>
    )}
    {paymentOptions.includes(PAYMENT_OPTIONS.SPLIT_PAYMENTS) && (
      <>
        <div className="mt-2">
          Split: {splitNumbers} Payments, Billed {splitPeriod}
        </div>
        <div id="priceSplit">
          {defaultSymbol + formatMoney(cost / splitNumbers) + ' ' + defaultCurrency.toUpperCase()}{' '}
        </div>
      </>
    )}
  </>
);

export const TotalCostBlock = ({ summary, paymentInfo, type, defaultCurrency, defaultSymbol }) => {
  const PAYMENTS_RENDER_MAPPING = {
    [ContributionType.contributionMembership]: renderMembershipPayments,
    [ContributionType.contributionOneToOne]: renderOneToOnePayments,
    [ContributionType.contributionCourse]: renderLiveCoursePayments,
    [ContributionType.contributionCommunity]: renderCommunityPayments,
  };

  return (
    PAYMENTS_RENDER_MAPPING[type] &&
    PAYMENTS_RENDER_MAPPING[type]({ ...paymentInfo, summary, defaultCurrency, defaultSymbol })
  );
};

export const PaymentOptions = ({ paymentOptions }) => {
  if (!paymentOptions || !paymentOptions.length) {
    return 'Non-Refundable';
  }

  return (
    <>
      {paymentOptions.map(opt => (
        <div key={opt}>{getPaymentOptionsView(opt)}</div>
      ))}
      Non-Refundable
    </>
  );
};

export const BreakdownBlock = () => {
  return <div>None</div>;
};

export const RevenueBlock = ({ earnedRevenue, defaultCurrency, defaultSymbol }) => (
  <div>{defaultSymbol + formatMoney(earnedRevenue) + ' ' + defaultCurrency.toUpperCase()}</div>
);

TotalCostBlock.propTypes = {
  cost: PropTypes.number.isRequired,
};

PaymentOptions.propTypes = {
  paymentOptions: PropTypes.arrayOf(PropTypes.string),
};

RevenueBlock.defaultProps = {
  earnedRevenue: null,
};

RevenueBlock.propTypes = {
  earnedRevenue: PropTypes.number,
};

PaymentOptions.defaultProps = {
  paymentOptions: [],
};
