import axiosInstance from 'utils/axiosInstance';
import { get } from 'lodash/fp';

const BaseURL = '/EmailMarketing';

const verifyDomainStatus = () => axiosInstance.get(`${BaseURL}/VerifyDomainStatus`).then(get('data'));
const getDomainsStatus = () => axiosInstance.get(`${BaseURL}/GetDomainsStatus`).then(get('data'));

const createEmailMarketingAccount = params => {
  const { email, domain, subDomain } = params;
  return axiosInstance
    .post(
      `${BaseURL}/CreateEmailMarketingAccount?${email ? `email=${email}` : ''}${domain ? `&domain=${domain}` : ''}${
        subDomain ? `&subDomain=${subDomain}` : ''
      }`,
    )
    .then(get('data'));
};

const addSenderEmail = email => axiosInstance.post(`${BaseURL}/AddSenderEmail?email=${email}`).then(get('data'));
const addDomainAuthentication = params => {
  const { email, domain, subDomain } = params;
  return axiosInstance
    .get(`${BaseURL}/AddDomainAuthentication?${email ? `email=${email}&` : ''}domain=${domain}&subDomain=${subDomain}`)
    .then(get('data'));
};

const removeDomain = params => {
  return axiosInstance.get(`${BaseURL}/RemoveDomain?domain=${params}`).then(get('data'));
};

const sendTestEmail = data => {
  const { fromEmail, fromName, toEmail } = data || {};
  return axiosInstance
    .post(`${BaseURL}/SendTestEmail?fromEmail=${fromEmail}&fromName=${fromName}&toEmaail=${toEmail}`)
    .then(get('data'));
};

const setAsDefaultSender = email => axiosInstance.put(`${BaseURL}/SetAsDefaultSender?email=${email}`).then(get('data'));

const getAllSenders = () => axiosInstance.get(`${BaseURL}/GetAllSenders`).then(get('data'));

const removeSender = email => axiosInstance.delete(`${BaseURL}/RemoveSender?email=${email}`).then(get('data'));

const resendSenderVerificationEmail = email =>
  axiosInstance.delete(`${BaseURL}/ResendSenderVerificationEmail?email=${email}`).then(get('data'));

export const EmailMarketingIntegrationService = {
  createEmailMarketingAccount,
  addSenderEmail,
  sendTestEmail,
  setAsDefaultSender,
  getAllSenders,
  removeSender,
  resendSenderVerificationEmail,
  verifyDomainStatus,
  addDomainAuthentication,
  getDomainsStatus,
  removeDomain,
};
