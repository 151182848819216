import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';

import TollbarAction from '../ToolbarAction';

const Start = ({ startRecordOnClick }) => {
  return (
    <TollbarAction label="Start record">
      <IconButton color="inherit" onClick={startRecordOnClick}>
        <PlayArrowIcon />
      </IconButton>
    </TollbarAction>
  );
};

export default Start;
