import React from 'react';

const UpSvg = ({ width = '12', height = '8', color = 'black', onClick, style }) => {
  return (
    <div onClick={onClick} style={{ ...style, cursor: 'pointer' }}>
      <svg width={width} height={height} viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M1.41 7.60999L6 3.01999L10.59 7.60999L12 6.18999L6 0.189985L0 6.18999L1.41 7.60999Z"
          fill={color}
          fill-opacity="0.87"
        />
      </svg>
    </div>
  );
};

export default UpSvg;
