import { useEffect, useState } from 'react';
import { getContributionSessionNotes } from 'services/contributions.service';

const useSessionNotes = ({ contributionId, classId, subClassId, isPrerecorded }) => {
  const [loading, setLoading] = useState(false);
  const [showNotes, setShowNotes] = useState(false);
  const [data, setData] = useState('');
  const [notesMeta, setNotesMeta] = useState();
  const [isNoteExist, setIsNoteExist] = useState(false);

  useEffect(() => {
    if (classId && contributionId) {
      setLoading(true);
      getContributionSessionNotes(contributionId, classId, subClassId, isPrerecorded)
        .then(response => {
          setData(response?.textContent || '');
          setNotesMeta({ id: response?.id, title: response?.title });
          setShowNotes(true);
          setIsNoteExist(response?.textContent !== null && response?.textContent !== undefined);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [contributionId, classId, subClassId, isPrerecorded, setShowNotes]);

  return { loading, showNotes, data, notesMeta, isNoteExist, setShowNotes, setLoading, setData, setIsNoteExist };
};

export default useSessionNotes;
