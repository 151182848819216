import React from 'react'
import PropTypes from 'prop-types'

import PlaceholderImage from 'assets/placeholder.jpg'
import MediaLink from '../MediaLink'

import './ImageMedia.scss'

const ImageMedia = ({ fileName, url }) => (
  <MediaLink url={url}>
    <img className="chat-message-content-media-image" alt={fileName} src={url || PlaceholderImage} />
  </MediaLink>
)

ImageMedia.propTypes = {
  fileName: PropTypes.string.isRequired,
  url: PropTypes.string,
}

ImageMedia.defaultProps = {
  url: null,
}

export default ImageMedia
