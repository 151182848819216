import MessageMediaType from './MessageMediaType';

const imageMimeType = 'image/';

const getMessageMediaType = media => {
  const { contentType } = media;

  switch (true) {
    case contentType.startsWith(imageMimeType):
      return MessageMediaType.image;
    default:
      return MessageMediaType.document;
  }
};

export default getMessageMediaType;
