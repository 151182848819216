import React from 'react'
import PropTypes from 'prop-types'

import { LocalParticipant } from '../Participant'
import GalleryView from './GalleryView'

import './GalleryView.scss'

const GalleryViewContainer = ({
  localParticipant,
  participants,
  dominantSpeakerParticipant,
  selectedAudioOutputDevice,
  isTest,
  videoTrack,
  audioTrack,
  screenTrack,
}) => {
  const dominantSpeakerParticipantIdentity = dominantSpeakerParticipant ? dominantSpeakerParticipant.identity : null

  return (
    <div className="video-chat-gallery-view-container">
      {participants.length === 0 ? (
        <LocalParticipant
          isTest={isTest}
          participant={localParticipant}
          dominant={localParticipant.identity === dominantSpeakerParticipantIdentity}
          videoTrack={videoTrack}
          audioTrack={audioTrack}
          screenTrack={screenTrack}
          selectedAudioOutputDevice={selectedAudioOutputDevice}
        />
      ) : (
        <GalleryView
          localParticipant={localParticipant}
          participants={participants}
          dominantSpeakerParticipant={dominantSpeakerParticipant}
        />
      )}
    </div>
  )
}

const participantPropType = PropTypes.shape({
  identity: PropTypes.string,
})

GalleryViewContainer.propTypes = {
  localParticipant: participantPropType.isRequired,
  participants: PropTypes.arrayOf(participantPropType).isRequired,
  dominantSpeakerParticipant: participantPropType,
  isTest: PropTypes.bool,
  videoTrack: PropTypes.shape({
    isLocalVideoEnabled: PropTypes.bool,
    setIsLocalVideoEnabled: PropTypes.func,
    videoDeviceLabel: PropTypes.string,
    switchVideoDevice: PropTypes.func,
  }).isRequired,
  audioTrack: PropTypes.shape({
    isLocalAudioEnabled: PropTypes.bool,
    setIsLocalAudioEnabled: PropTypes.func,
    audioDeviceLabel: PropTypes.string,
    switchAudioDevice: PropTypes.func,
  }).isRequired,
  screenTrack: PropTypes.shape({
    isLocalScreenEnabled: PropTypes.bool,
    setIsLocalScreenEnabled: PropTypes.func,
  }).isRequired,
}

GalleryViewContainer.defaultProps = {
  dominantSpeakerParticipant: null,
  isTest: false,
}

export default GalleryViewContainer
