import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import CloseIcon from '@material-ui/icons/Close';
import { CircularProgress } from '@material-ui/core';
import { colors } from '../../../../../utils/styles';
import { useMediaQuery, useTheme } from '@material-ui/core';
import './Attachment.scss';
import Popup from './PopupAttachment';
import OneAttachment from './OneAttachment';
import TwoAttachments from './TwoAttachments';
import ThreeAttachments from './ThreeAttachments';
import FourAttachments from './FourAttachments';
import MultipleAttachments from './MultipleAttachments';
import { useState } from 'react';
import { determineColorToUse } from 'services/contributions.service';
import useUnifiedCommunity from 'hooks/useUnifiedCommunity';

const StyledImage = styled.img`
  width: 90%;
  height: auto;
  max-height: 500px;
  border-radius: 8px;
`;
const StyledVideo = styled.video`
  width: 100%;
  height: 370px;
  border-radius: 5px;
  background-color: black;
  background-size: cover;
  ${({ mobileView }) => {
    return (
      mobileView &&
      `
        height: 250px;
        width:100%
      `
    );
  }};
  ${({ modal }) => {
    return (
      modal &&
      `
        height: 250px;
        width:100%
      `
    );
  }};
`;
const StyledWrap = styled.div`
  ${({ typeFile }) => {
    if (typeFile) {
      return `
    width: 100%;
    padding-right: 20px;`;
    }
  }}
  min-height: 40px;
  min-width: 40px;
  margin-bottom: 15px;
  position: relative;
  @media screen and (max-width: 1600px) {
    ${({ isLandscape }) => {
      if (isLandscape) {
        return `padding-top: 56.25% !important;`;
      }
    }}
    ${({ isCreatePost }) => {
      if (isCreatePost) {
        return `padding-top: 0 !important;`;
      }
    }}
    ${({ isVideo }) => {
      if (isVideo) {
        return `padding-top: 0 !important;`;
      }
    }}
    ${({ typeFile }) => {
      if (typeFile) {
        return `padding-top: 0 !important;`;
      }
    }}
    padding-top: 100%;
  }
`;
const StyledCloseIconWrap = styled.div`
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
  background-color: #fff;
  border-radius: 100px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  @media (min-width: 1441px) {
    top: 2%;
    right: 2%;
  }
`;

const StyledFileLink = styled.a`
  color: ${({ colorCode }) => (colorCode ? colorCode : colors.darkOceanBlue)};
  font-weight: 600;
  font-size: 14px;
  display: flex;
  align-items: center;
  margin-left: -6px;
  padding: 6px 40px 6px 0;

  &:hover {
    color: ${({ colorCode }) => (colorCode ? colorCode : colors.darkOceanBlue)};
  }
`;
const StyledCircularProgress = styled(CircularProgress)`
  display: block;
`;
const StyledAttachmentsContainer = styled.div`
  padding: 0 15px;

  ${({ unifiedCommunity }) =>
    unifiedCommunity &&
    `
  padding: 0px;
  `}
`;

export const Wrapper = ({
  children,
  index,
  type,
  removeAttachment,
  handleRemoveAttachment,
  isLandscape = false,
  isCreatePost = false,
  isVideo = false,
}) => {
  return (
    <StyledWrap typeFile={type === 'file'} isLandscape={isLandscape} isCreatePost={isCreatePost} isVideo={isVideo}>
      {removeAttachment && (
        <StyledCloseIconWrap
          onClick={() => {
            handleRemoveAttachment(index);
          }}
        >
          <CloseIcon style={{ marginBottom: '0', color: 'black' }} fontSize="small" />
        </StyledCloseIconWrap>
      )}
      {children}
    </StyledWrap>
  );
};

export const Attachments = ({
  modal,
  attachments,
  removeAttachment,
  pending,
  createPost,
  unifiedCommunity,
  colorToUse,
}) => {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'));
  const { isUnifiedCommunity } = useUnifiedCommunity();
  const fileNameColor = isUnifiedCommunity ? colors.darkOceanBlue : colorToUse?.AccentColorCode;
  const handleRemoveAttachment = index => {
    removeAttachment(index);
  };
  const [images, setImages] = useState([]);
  const [videos, setVideos] = useState([]);
  const [otherAttachments, setOtherAttachments] = useState([]);
  useEffect(() => {
    const allImages = [];
    const allVideos = [];
    const allOthers = [];
    attachments.forEach((item, index) => {
      switch (item.type) {
        case 'image/png':
        case 'image/jpeg':
        case 'image/x-png':
        case 'image/gif':
          allImages.push({ item, index });
          break;
        case 'video/mpeg':
        case 'video/mp4':
        case 'video/quicktime':
          allVideos.push({ item, index });
          break;
        default:
          allOthers.push({ item, index });
      }
    });
    setImages(allImages);
    setVideos(allVideos);
    setOtherAttachments(allOthers);
  }, [attachments]);

  const [popupImage, setPopupImage] = useState(null);
  const handleImageClick = image => {
    setPopupImage(image);
  };
  const handleClosePopup = () => {
    setPopupImage(null);
  };

  return (
    <>
      {pending && (
        <StyledAttachmentsContainer>
          <StyledCircularProgress />
        </StyledAttachmentsContainer>
      )}
      {!isEmpty(attachments) && (
        <div
          className={!!unifiedCommunity ? 'unifiedcommuntiy-attachments-container' : 'communtiy-attachments-container'}
        >
          <StyledAttachmentsContainer unifiedCommunity={unifiedCommunity}>
            {createPost ? (
              map(attachments, (item, index) => {
                switch (item.type) {
                  case 'image/png':
                  case 'image/jpeg':
                  case 'image/x-png':
                  case 'image/gif':
                    return (
                      <Wrapper
                        index={index}
                        removeAttachment={removeAttachment}
                        handleRemoveAttachment={handleRemoveAttachment}
                        isCreatePost
                      >
                        <div
                          className="image-wrap-original"
                          style={{
                            backgroundImage: `url(${item.s3Link || ''})`,
                            backgroundSize: 'cover',
                          }}
                        />
                      </Wrapper>
                    );
                  case 'video/mpeg':
                  case 'video/mp4':
                  case 'video/quicktime':
                    return (
                      <Wrapper
                        index={index}
                        removeAttachment={removeAttachment}
                        handleRemoveAttachment={handleRemoveAttachment}
                        isVideo
                      >
                        <StyledVideo modal={modal} controls="controls" preload="metadata" mobileView={mobileView}>
                          <source src={`${item.s3Link || ''}#t=0.2`} />
                        </StyledVideo>
                      </Wrapper>
                    );
                  default:
                    return (
                      <Wrapper
                        index={index}
                        type="file"
                        removeAttachment={removeAttachment}
                        handleRemoveAttachment={handleRemoveAttachment}
                      >
                        <StyledFileLink
                          href={item.s3Link}
                          download
                          target="_blank"
                          colorCode={isUnifiedCommunity ? colors.darkOceanBlue : colorToUse?.AccentColorCode}
                        >
                          <DescriptionOutlinedIcon style={{ fontSize: 40 }} />
                          {item.fileName}
                        </StyledFileLink>
                      </Wrapper>
                    );
                }
              })
            ) : (
              <>
                {videos.map((video, index) => {
                  const { index: newIndex = index, item } = video;
                  const { s3Link = '' } = item;
                  return (
                    <Wrapper
                      index={newIndex}
                      removeAttachment={removeAttachment}
                      handleRemoveAttachment={handleRemoveAttachment}
                      isVideo
                    >
                      <StyledVideo modal={modal} controls="controls" preload="metadata" mobileView={mobileView}>
                        <source src={`${s3Link}#t=0.2`} />
                      </StyledVideo>
                    </Wrapper>
                  );
                })}
                {images.length === 1 && (
                  <OneAttachment
                    item={images[0].item}
                    index={images[0].index}
                    removeAttachment={removeAttachment}
                    handleRemoveAttachment={handleRemoveAttachment}
                    handleImageClick={handleImageClick}
                  />
                )}
                {images.length === 2 && (
                  <TwoAttachments
                    attachments={images}
                    removeAttachment={removeAttachment}
                    handleRemoveAttachment={handleRemoveAttachment}
                    handleImageClick={handleImageClick}
                  />
                )}
                {images.length === 3 && (
                  <ThreeAttachments
                    attachments={images}
                    removeAttachment={removeAttachment}
                    handleRemoveAttachment={handleRemoveAttachment}
                    handleImageClick={handleImageClick}
                  />
                )}
                {images.length === 4 && (
                  <FourAttachments
                    attachments={images}
                    removeAttachment={removeAttachment}
                    handleRemoveAttachment={handleRemoveAttachment}
                    handleImageClick={handleImageClick}
                  />
                )}
                {images.length >= 5 && (
                  <MultipleAttachments
                    attachments={images}
                    removeAttachment={removeAttachment}
                    handleRemoveAttachment={handleRemoveAttachment}
                    handleImageClick={handleImageClick}
                  />
                )}
                {otherAttachments.map((attachment, index) => {
                  const { newIndex = index, item } = attachment;
                  const { fileName = '', s3Link = '' } = item || {};
                  return (
                    <Wrapper
                      index={newIndex}
                      type="file"
                      removeAttachment={removeAttachment}
                      handleRemoveAttachment={handleRemoveAttachment}
                      isVideo
                    >
                      <StyledFileLink
                        href={s3Link}
                        download
                        target="_blank"
                        colorCode={isUnifiedCommunity ? colors.darkOceanBlue : colorToUse?.AccentColorCode}
                      >
                        <DescriptionOutlinedIcon style={{ fontSize: 40 }} />
                        {fileName}
                      </StyledFileLink>
                    </Wrapper>
                  );
                })}
              </>
            )}
          </StyledAttachmentsContainer>
        </div>
      )}
      {popupImage && (
        <Popup image={popupImage} onClose={handleClosePopup} attachments={images.map(image => image.item)} />
      )}
    </>
  );
};
