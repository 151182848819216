import React from 'react';
import PropTypes, { node } from 'prop-types';
import TOOLTIP from 'constants';
import { Tooltip } from '@material-ui/core';

function CellToolTip({ title, children }) {
  return (
    <Tooltip title={title} arrow enterTouchDelay={TOOLTIP.ENTER_DELAY} leaveTouchDelay={TOOLTIP.LEAVE_DELAY}>
      {children}
    </Tooltip>
  );
}

CellToolTip.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.arrayOf(node).isRequired,
};

export default CellToolTip;
