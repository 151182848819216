import { useHttp } from 'hooks';
import { useState } from 'react';

const useVideoPlayer = ({ sessionTime }) => {
  const [link, setLink] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isFailed, setIsFailed] = useState(false);
  const { request, error, loading } = useHttp();

  const getVideoLink = async ({ contributionId, roomId }) => {
    setLink(null);

    const videoLink = await request('/Video/GetPresignedUrl', 'POST', {
      contributionId,
      roomId,
    });

    setLink(videoLink);
  };
  const getPreRecordedVideoLink = async ({ contributionId, sessionId }) => {
    if (isLoading || isFailed) {
      return;
    }

    setIsLoading(true);
    setLink(null);

    const contributionIdQuery = `contributionId=${contributionId}`;
    const sessionIdQuery = `&sessionId=${sessionId}`;
    const sessionTimeQuery = `${sessionTime?.prerecordedSession ? `&sessionTimeId=${sessionTime.id}` : ''}`;
    const query = `${contributionIdQuery}${sessionIdQuery}${sessionTimeQuery}`;
    const endpoint = `Video/GetPresignedUrlForRecordedSession`;

    const videoLink = await request(`${endpoint}?${query}`, 'GET');

    setIsFailed(videoLink?.failed);
    setLink(videoLink?.payload?.presignedUrl);
    setIsLoading(false);
  };

  const getPreRecordedZoomVideoLink = async ({ sessionTime }) => {
    if (isLoading || isFailed) {
      return;
    }

    setIsLoading(true);
    setLink(null);

    const videoLink = await request(
      `Zoom/GetPresignedUrlForRecordedSession/${sessionTime.zoomMeetingData.meetingId}/${sessionTime.zoomMeetingData.recordingFileName}`,
      'GET',
    );

    setLink(videoLink);
    setIsLoading(false);
  };
  const generateLiveVideoLink = ({ contributionId, roomId }) => {
    return request('/Video/GetPresignedUrl', 'POST', {
      contributionId,
      roomId,
    }).then(videoLink => ({
      link: videoLink,
      roomId,
    }));
  };
  const generateLiveVideoLinkWithPoster = ({ contributionId, roomId, poster }) => {
    return request('/Video/GetPresignedUrl', 'POST', {
      contributionId,
      roomId,
    }).then(videoLink => ({
      link: videoLink,
      roomId,
      poster,
    }));
  };
  const generatePreRecordedVideoLink = async ({ contributionId, sessionId }) => {
    const contributionIdQuery = `contributionId=${contributionId}`;
    const sessionIdQuery = `&sessionId=${sessionId}`;
    const sessionTimeQuery = `${sessionTime?.prerecordedSession ? `&sessionTimeId=${sessionTime.id}` : ''}`;
    const query = `${contributionIdQuery}${sessionIdQuery}${sessionTimeQuery}`;
    const endpoint = `Video/GetPresignedUrlForRecordedSession`;
    const fileName = sessionTime?.prerecordedSession?.documentOriginalNameWithExtension;
    return request(`${endpoint}?${query}`, 'GET').then(videoLink => ({
      videoUrl: videoLink?.payload?.presignedUrl,
      fileName,
    }));
  };
  const generatePreRecordedZoomVideoLink = ({ sessionTime }) => {
    return request(
      `Zoom/GetPresignedUrlForRecordedSession/${sessionTime.zoomMeetingData.meetingId}/${sessionTime.zoomMeetingData.recordingFileName}`,
      'GET',
    );
  };
  const generatePresignedUrlForRecordedLeadMagnet = async ({ leadMagnetId, preRecordedDataId, userEmail }) => {
    return request(
      `Video/GetPresignedUrlForRecordedLeadMagnet/?leadMagnetId=${leadMagnetId}&preRecordedDataId=${preRecordedDataId}&userEmail=${userEmail}`,
      'GET',
    ).then(data => ({
      contentUrl: data?.payload?.presignedUrl,
    }));
  };
  return {
    loading,
    link,
    error,
    getVideoLink,
    getPreRecordedVideoLink,
    getPreRecordedZoomVideoLink,
    generateLiveVideoLink,
    generateLiveVideoLinkWithPoster,
    generatePreRecordedVideoLink,
    generatePreRecordedZoomVideoLink,
    generatePresignedUrlForRecordedLeadMagnet,
  };
};

export default useVideoPlayer;
