import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Icon } from '@mdi/react';
import { mdiChevronDown } from '@mdi/js';
import { Link } from 'react-router-dom';
import { toRem } from 'utils/styles';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { useRouteMatch } from 'react-router-dom';
import { useAccount } from 'hooks';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { useSelector } from 'react-redux';
import * as S from '../../pages/ContributionView/components/ContributionHeader/styled';
import Tooltip from '@material-ui/core/Tooltip';
import { TOOLTIP } from '../../constants';
import { determineColorToUse, getThemedColors } from 'services/contributions.service';

const StyledButton = styled(Link)`
  display: flex;
  align-items: center;
  outline: none;
  border: none;
  background-color: transparent;
  font-weight: 900;
  line-height: 1.5;
  letter-spacing: 0.1px;
  font-family: 'Avenir';
  cursor: pointer;
  padding-left: 0;
  padding-right: 0;

  ${({ mobileView }) => mobileView && `font-size: 0.9rem`}
`;

const StyledDropDowmContainer = styled.div`
  position: relative;

  ${({ clientPreviewMode }) =>
    clientPreviewMode &&
    `
    pointer-events: none;
  `}
`;

const StyledItemsContainer = styled.div`
  z-index: 1000;
  position: absolute;
  right: 0;
  display: flex;
  flex-direction: column;
  box-shadow: 0 2px 4px 0 rgba(157, 157, 157, 0.3);
`;
const StyledItemsContainerLastIndex = styled.div`
  z-index: 1000;
  position: absolute;
  right: 0;
  top: -81px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 2px 4px 0 rgba(157, 157, 157, 0.3);
`;

function ClickDropDown({
  materialIcon,
  title,
  children,
  mobileView,
  id,
  icon = mdiChevronDown,
  lastIndex,
  materialIconVertical,
  isCoach,
  isDisable,
  className = '',
  clientPreviewMode,
  secondLast,
  isOpenNow = false,
  isPathForContribution,
  showDownArrow = true,
  buttonClassName,
  isShareContributionLink = false,
  isOneToOne = false,
}) {
  const { path } = useRouteMatch();
  const { user } = useAccount();
  const activeContribution = useSelector(state => state.contributions?.activeContribution);
  const { themedColor: themedTextColor, newThemedTextColor } = getThemedColors(activeContribution);
  const themedColor = isCoach ? themedTextColor : newThemedTextColor;
  const [show, setShow] = useState(
    path?.includes('dashboard') &&
      isCoach &&
      user?.userProgressbarData?.IsProfileUploaded === false &&
      isDisable === false
      ? true
      : false,
  );
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const setShowToFalse = () => {
      setShow(false);
    };
    window.addEventListener('click', setShowToFalse);

    return () => {
      window.removeEventListener('click', setShowToFalse);
    };
  }, []);

  const clickHandler = useCallback(e => {
    e.stopPropagation();
    e.preventDefault();
    setShow(prev => !prev);
  }, []);

  return (
    <StyledDropDowmContainer className={className} clientPreviewMode={clientPreviewMode}>
      <StyledButton className={buttonClassName} mobileView={mobileView} onClick={clickHandler} id={id} type="button">
        {!mobileView && title}
        {isShareContributionLink && (
          <S.StyledInviteOneToOneContainer
            // id={'invite'}
            color={themedColor}
            isDarkModeEnabled={activeContribution?.isDarkModeEnabled}
            style={{ marginRight: mobileView ? '8px' : '0px', background: 'none' }}
            mobileView={mobileView}
          >
            {windowWidth >= 1320 ? (
              <>
                <S.StyledShareIcon />
                <p style={{ margin: '0px', cursor: 'pointer' }}>{isOneToOne ? 'Invite & Schedule' : 'Invite'}</p>
              </>
            ) : (
              <Tooltip
                className="cursor-pointer text-grey"
                title={isOneToOne ? 'Invite & Schedule' : 'Invite'}
                arrow
                enterTouchDelay={TOOLTIP.ENTER_DELAY}
                leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
              >
                <S.StyledShareIcon mobileView={mobileView} />
              </Tooltip>
            )}
          </S.StyledInviteOneToOneContainer>
        )}
        {materialIcon
          ? showDownArrow === true && <MoreHorizIcon />
          : showDownArrow === true &&
            icon && (
              <Icon
                size={1}
                path={icon}
                style={{
                  verticalAlign: 'bottom',
                  color: activeContribution?.isDarkModeEnabled && isPathForContribution && 'white',
                  marginTop: isShareContributionLink && '5px',
                }}
              />
            )}
        {/* {materialIconVertical ? <MoreVertIcon /> : <Icon size={1} path={icon} style={{ verticalAlign: 'bottom' }} />} */}
      </StyledButton>
      {(show || isOpenNow) && (lastIndex || secondLast) ? (
        <StyledItemsContainerLastIndex>{children}</StyledItemsContainerLastIndex>
      ) : (
        (show || isOpenNow) && <StyledItemsContainer id={'my-first-step'}>{children}</StyledItemsContainer>
      )}
    </StyledDropDowmContainer>
  );
}

ClickDropDown.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  mobileView: PropTypes.bool,
  icon: PropTypes.string,
};

export const StyledDropDownItem = styled(Link)`
  font-size: ${toRem(16)};
  font-weight: 500;
  text-decoration: none;
  white-space: nowrap;
  background-color: white;
  padding: 8px;
  color: rgba(0, 0, 0, 0.87);
  border-bottom: 1px solid #f0f0f0;
  min-width: 100px;
  display: ${({ loader }) => (loader ? 'flex' : 'block')};
  width: 100%;

  &.disableMe {
    opacity: 1;
    color: gray;
    pointer-events: none;
    cursor: not-allowed;
  }

  &.disabled {
    opacity: 1;
    color: gray;
    cursor: not-allowed;
  }

  &:hover {
    text-decoration: underline;
  }

  ${({ inviteClient, isDarkModeEnabled }) =>
    inviteClient &&
    `
    background-color: ${isDarkModeEnabled ? '#242728' : 'white'};
    color: ${isDarkModeEnabled ? 'white !important' : 'rgba(0, 0, 0, 0.87)'};
    padding: 15px 20px !important;
    font-family: Avenir !important;
    font-size: 18px !important;
    font-weight: 400 !important;
    line-height: 20px !important;
  
  `}
  ${({ inviteClient, firstItem }) =>
    inviteClient &&
    firstItem &&
    `
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  `}

  ${({ inviteClient, lastItem }) =>
    inviteClient &&
    lastItem &&
    `
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  `}
`;

export default ClickDropDown;
