import { useEffect } from 'react';

import useParticipant from './useParticipant';
import VideoChatTrackKind from '../../VideoChatTrackKind';
import VideoChatVideoTrackType from '../../VideoChatVideoTrackType';

const useRemoteParticipant = remoteParticipant => {
  const { audioTracks, setAudioTracks, videoTracks, setVideoTracks } = useParticipant(remoteParticipant);

  useEffect(() => {
    const onTrackSubscribed = track => {
      switch (track.kind) {
        case VideoChatTrackKind.audio:
          setAudioTracks(at => [...at, track]);
          break;
        case VideoChatTrackKind.video:
          setVideoTracks(vt => [...vt, track]);
          break;
        default:
          break;
      }
    };
    const onTrackUnsubscribed = track => {
      switch (track.kind) {
        case VideoChatTrackKind.audio:
          setAudioTracks(at => at.filter(a => a !== track));
          break;
        case VideoChatTrackKind.video:
          setVideoTracks(vt => vt.filter(v => v !== track));
          break;
        default:
          break;
      }
    };

    remoteParticipant.on('trackSubscribed', onTrackSubscribed);
    remoteParticipant.on('trackUnsubscribed', onTrackUnsubscribed);

    return () => {
      remoteParticipant.off('trackSubscribed', onTrackSubscribed);
      remoteParticipant.off('trackUnsubscribed', onTrackUnsubscribed);
    };
  }, [remoteParticipant, setAudioTracks, setVideoTracks]);

  return {
    audioTrack: audioTracks[0],
    videoTrack: videoTracks.filter(vt => vt.name === VideoChatVideoTrackType.camera)[0],
    screenTrack: videoTracks.filter(vt => vt.name === VideoChatVideoTrackType.screenShare)[0],
  };
};

export default useRemoteParticipant;
