import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';

import { useHeader, useEndVideoChat } from 'hooks';
import { fetchArchivedLeadMagnets, fetchArchivedLeadMagnetsActions } from 'actions/leadMagnets';

import Loader from 'components/UI/Loader';
import Banner from 'components/Banner/Banner';
import LeadMagnetsTable from 'components/Tables/LeadMagnets';
import { useState } from 'react';

const ArchivedLeadMagnetsPage = ({
  // contributionscount,
  leadMagnets,
  // closestClassForBanner,
  getLeadMagnets,
  loading,
  userId,
}) => {
  const [prevLists, setPrevLists] = useState(leadMagnets);
  useEffect(() => {
    if (!loading) {
      setPrevLists(leadMagnets);
    }
  }, [leadMagnets, loading]);
  const [pagination, setpagination] = useState(0);
  useHeader('Archived Lead Magnets');

  const handlePageNumber = useCallback(page => {
    setpagination(page);
  }, []);

  const renderList = useCallback(async () => {
    getLeadMagnets();
  }, [getLeadMagnets, userId]);

  // const { Popup } = useEndVideoChat(renderList);

  useEffect(() => {
    renderList();
  }, [renderList]);

  if ((!prevLists || !prevLists?.length) && !loading) {
    return <div>There is no lead magnet archived yet.</div>;
  }

  return (
    <>
      {loading && <Loader />}
      {/* <Banner type="closestSession" closestClassForBanner={closestClassForBanner} /> */}
      {prevLists?.length > 0 && (
        <LeadMagnetsTable
          // totalCount={contributionscount}
          rows={loading ? prevLists : leadMagnets}
          childpageNumber={handlePageNumber}
          isArchivedPage
        />
      )}
      {/* <Popup /> */}
    </>
  );
};

const mapStateToProps = ({ leadMagnets: { loading, error, leadMagnetsCollection }, account }) => ({
  userId: account?.user?.id,
  loading,
  error,
  // contributionscount: upcomingCreated?.totalCount || null,
  leadMagnets: leadMagnetsCollection || [],
  // closestClassForBanner: upcomingCreated?.closestClassForBanner || {},
});

const actions = {
  getLeadMagnets: fetchArchivedLeadMagnets,
};

ArchivedLeadMagnetsPage.propTypes = {
  userId: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  leadMagnets: PropTypes.arrayOf(PropTypes.object).isRequired,
  getLeadMagnets: PropTypes.func.isRequired,
  // closestClassForBanner: PropTypes.shape({}).isRequired,
};

export default connect(mapStateToProps, actions)(ArchivedLeadMagnetsPage);
