import { get } from 'lodash/fp';
import axiosInstance from 'utils/axiosInstance';

export const deleteVideochatRoom = data => axiosInstance.post('/Video/DeleteRoom', data).then(get('data'));

export const getCurrentRoomStatus = (roomCid, contributionId) =>
  axiosInstance
    .get(`/Recording/GetCurrentRoomStatusById?RoomCid=${roomCid}&ContributionId=${contributionId}`)
    .then(get('data'));

export const startRecording = (roomCid, contributionId) =>
  axiosInstance.post(`/Recording/Start`, { roomCid, contributionId }).then(get('data'));

export const stopRecording = (roomCid, contributionId) =>
  axiosInstance.post(`/Recording/Stop`, { roomCid, contributionId }).then(get('data'));
