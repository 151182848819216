import { useShallowEqualSelector } from 'hooks';
import rules from './rules';

const check = (userRole, rulesForChecking, role, action, data) => {
  if (userRole === role) {
    return true;
  }

  const permissions = rulesForChecking[role];
  if (!permissions) {
    return false;
  }

  const staticPermissions = permissions.static;
  if (staticPermissions && staticPermissions.includes(action)) {
    return true;
  }

  const dynamicPermissions = permissions.dynamic;
  if (dynamicPermissions) {
    const permission = dynamicPermissions[action];
    if (!permission) {
      return false;
    }

    return permission(data);
  }

  return false;
};

const Can = ({ roleName, perform, data, yes, no }) => {
  const userRole = useShallowEqualSelector(s => s?.account?.currentRole);
  return check(userRole, rules, roleName, perform, data) ? yes() : no();
};

Can.defaultProps = {
  yes: () => null,
  no: () => null,
};

export default Can;
