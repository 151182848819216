import Modal from 'components/UI/Modal';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ShareIcon from '@material-ui/icons/Share';
import { Tooltip, Typography } from '@material-ui/core';
import CopyToClipboardIcon from 'assets/notes-content-copy.svg';
import './NotePanel.scss';
import InputEmailsComponent from 'components/UI/InputEmailsComponent';
import Loader from 'components/UI/Loader';
import useContribution from 'pages/ContributionView/hooks/useContribution';
import { sendClientNotesToEmail } from 'services/clients.service';
import NotesEditor from 'components/Notes/NotesEditor';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { determineColorToUse, getThemedColors } from 'services/contributions.service';
import Button from 'components/FormUI/Button';
import { lightOrDark } from 'utils/utils';
import useAccount from 'hooks/useAccount';

const CHARACTER_LIMIT = 100000;

const StyledPanel = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const PanelTitle = styled(Typography)`
  color: ${({ textColor }) => textColor || '#215c73'};
  font-family: Avenir;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;
const PanelEditorContainer = styled.div``;

const PanelButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 249px;
  height: 48px;
  justify-content: center;
  align-items: flex-start;
  gap: 14px;
  flex-shrink: 0;
`;

const StyledButton = styled.button`
  height: 48px;
  padding: 7px 24px;
  align-items: center;
  border-radius: 5px;
  text-align: center;
  font-family: Avenir;
  font-size: 14px;
  font-style: normal;
  font-weight: 800;
  line-height: 16px;
  display: flex;
  justify-content: center;
  flex: 1 0 0;
`;
const PanelBackButton = styled(StyledButton)`
  color: ${({ textColor }) => textColor || 'black'};
  background: ${({ backgroundColor }) => backgroundColor || '#fff'};
  border: 1.25px solid ${({ borderColor }) => borderColor || '#000'};
`;

const PanelSaveButton = styled(StyledButton)`
  background: #d1b989;
  color: white;
  border: unset;
`;

const NotesPanel = ({
  editorData,
  showNotes,
  loading,
  setShowNotes,
  setNotesId,
  setEditorDataUpdated,
  onSave,
  applyTheming,
  setLoading,
  isNoteExist,
  setOpenNotes,
}) => {
  const [editor, setEditor] = useState();
  const [showEmailPopup, setShowEmailPopup] = useState(false);
  const [emails, setEmails] = useState([]);
  const [emailError, setEmailError] = useState({});
  const [showEmailSentPopup, setShowEmailSentPopup] = useState(false);
  const [showCopied, setShowCopied] = useState(false);
  const contribution = useContribution();
  const [characterCount, setCharacterCount] = useState(0);
  const [loadingEditor, setLoadingEditor] = useState(true);
  const { isCoach } = useAccount();
  const activeContribution = useSelector(state => state.contributions?.activeContribution);
  const onLoaded = () => {
    setLoadingEditor(false);
  };
  const colorToUse = determineColorToUse(contribution);
  const { themedColor, newThemedTextColor, themedCardOutlineColor } = getThemedColors(contribution);

  const handleCopyClick = () => {
    editor.execCommand('selectAll');
    setTimeout(() => editor.execCommand('copy'), 100);
    setTimeout(() => {
      const selection = editor.getSelection();
      if (selection) {
        selection.removeAllRanges();
      }
      setShowCopied(true);
    }, 200);
  };

  useEffect(() => {
    setEmails([]);
    setEmailError('');
    setShowEmailPopup(false);
    setShowEmailSentPopup(false);
  }, [showNotes]);

  const handleEmailClick = () => {
    if (emails.length === 0) {
      return null;
    }
    setLoading(true);
    sendClientNotesToEmail({ note: editorData, emails, contributionId: activeContribution?.id })
      .then(() => {
        setLoading(false);
        setShowEmailPopup(false);
        setShowEmailSentPopup(true);
      })
      .finally(() => {
        setLoading(false);
        setShowEmailPopup(false);
      });
    return null;
  };
  const textColor =
    colorToUse?.TextColorCode === 'Auto'
      ? lightOrDark(colorToUse?.PrimaryColorCode)
      : colorToUse?.TextColorCode === '#000000'
      ? '#000000'
      : '#FFFFFF';
  const isBtnDisabled = characterCount > CHARACTER_LIMIT;
  return (
    <>
      <StyledPanel>
        <PanelTitle textColor={colorToUse?.AccentColorCode}>Add a Note</PanelTitle>
        <PanelEditorContainer className={`rich-text-notes-editor ${loadingEditor ? 'loading-editor' : ''}`}>
          <NotesEditor
            editorData={editorData}
            setEditorDataUpdated={setEditorDataUpdated}
            setEditor={setEditor}
            characterLimit={CHARACTER_LIMIT}
            setCharacterCount={setCharacterCount}
            characterCount={characterCount}
            onLoaded={onLoaded}
            mainBorder={contribution?.isDarkModeEnabled && `1px solid ${themedCardOutlineColor}`}
          />
          <div className="video-chat-notes-panel__statusBar">
            <p style={{ color: characterCount > CHARACTER_LIMIT ? 'red' : '', margin: 'unset' }}>
              {characterCount}/{CHARACTER_LIMIT}
            </p>
          </div>
          <div className="copy-and-share-container">
            <div
              className="share-via-email"
              tabIndex="0"
              role="button"
              onClick={() => setShowEmailPopup(true)}
              onKeyPress={() => setShowEmailPopup(true)}
            >
              <ShareIcon />
              <span>Share via Email</span>
            </div>
            <Tooltip
              className="cursor-pointer"
              title="Copied to clipboard"
              open={showCopied}
              onClose={() => setShowCopied(false)}
              arrow
            >
              <div
                className="copy-to-clipboard"
                tabIndex="0"
                role="button"
                onClick={handleCopyClick}
                onKeyPress={handleCopyClick}
              >
                <img alt="" src={CopyToClipboardIcon} />
                <span>Copy to Clipboard</span>
              </div>
            </Tooltip>
          </div>
        </PanelEditorContainer>
        <PanelButtonContainer>
          <PanelBackButton
            onClick={() => {
              if (setNotesId) setNotesId(null);
              if (setOpenNotes) setOpenNotes(false);
              if (setShowNotes) setShowNotes(false);
            }}
            {...(contribution?.isDarkModeEnabled && {
              textColor: isCoach ? themedColor : newThemedTextColor,
              backgroundColor: 'transparent',
              borderColor: themedCardOutlineColor,
            })}
          >
            Back
          </PanelBackButton>
          {/* <PanelSaveButton onClick={onSave} disabled={characterCount > CHARACTER_LIMIT}>
            {isNoteExist ? 'Update' : 'Save'}
          </PanelSaveButton> */}
          <Button
            autoWidth
            backgroundColor={
              isBtnDisabled && contribution?.isDarkModeEnabled ? 'transparent' : colorToUse?.PrimaryColorCode
            }
            textColor={textColor}
            borderColor={isBtnDisabled && contribution?.isDarkModeEnabled && textColor}
            onClick={onSave}
            disabled={isBtnDisabled}
          >
            {isNoteExist ? 'Update' : 'Save'}
          </Button>
        </PanelButtonContainer>
      </StyledPanel>
      {(loading || (showNotes && loadingEditor)) && <Loader />}
      <Modal
        className="notes-email-popup"
        isOpen={showEmailPopup}
        title="Share via Email"
        submitTitle="Send"
        hiddenCancel
        onCancel={() => setShowEmailPopup(false)}
        onSubmit={handleEmailClick}
        contribution={contribution}
        applyTheming
      >
        <InputEmailsComponent
          emails={emails}
          handleSetEmails={setEmails}
          emailsError={emailError}
          setEmailsError={setEmailError}
        />
        <p style={{ fontSize: '12px' }}>Separate emails with a comma.</p>
      </Modal>
      <Modal
        className="notes-email-popup"
        isOpen={showEmailSentPopup}
        title="Note Sent"
        submitTitle="Ok"
        hiddenCancel
        onCancel={() => setShowEmailSentPopup(false)}
        onSubmit={() => setShowEmailSentPopup(false)}
        contribution={contribution}
        applyTheming
      >
        <span>Notes sent to selected emails.</span>
      </Modal>
    </>
  );
};
NotesPanel.propTypes = {
  editorData: PropTypes.string.isRequired,
  setEditorDataUpdated: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  setNotesId: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  applyTheming: PropTypes.bool.isRequired,
  setLoading: PropTypes.func.isRequired,
  isNoteExist: PropTypes.bool.isRequired,
  setOpenNotes: PropTypes.func.isRequired,
  setShowNotes: PropTypes.func.isRequired,
  showNotes: PropTypes.bool.isRequired,
};

export default NotesPanel;
