import React, { useCallback, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import {
  useActiveContributionSessionById,
  useActiveContributionSessionTimeOrContentById,
  useContribution,
} from 'pages/ContributionView/hooks';
import useAccount from 'hooks/useAccount';
import { isEmpty } from 'lodash';
import { Tooltip, useMediaQuery, useTheme } from '@material-ui/core';
import { TOOLTIP } from '../../../../../constants';
import { useScreenView } from 'hooks';
import VideoButton from 'components/FormUI/Button';
import { determineColorToUse } from 'services/contributions.service';
import { useCompleteSelfPacedSessionTime } from '../../CourseSessions/ClientCourseSessions/hooks/useCompleteSelfPacedSession';
import { getIpGlobal, lightOrDark } from 'utils/utils';
import { fetchClientContribution } from 'actions/contributions';
import { useDispatch, useSelector } from 'react-redux';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { checkIsFuture } from 'utils/datesAndMoney';
import { DarkEnabledPrimaryFilledButton } from '../styled';
import { activeContribSessTabViewSettingsSelector } from 'selectors/contribution';

const StyledHeader = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: ${({ mobileView }) => (mobileView ? 'flex-start' : 'center')};
  align-items: center;
  flex-shrink: 0;
  ${({ mobileView }) =>
    mobileView &&
    css`
      gap: 0.625rem;
    `}
`;

const StyledTitle = styled.div`
  font-size: ${({ mobileView }) => (mobileView ? '1rem' : '1.875rem')};
  font-style: normal;
  font-weight: 800;
  line-height: 27px;
  color: ${({ textColor }) => textColor || '#282B2B'};

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  ${({ mobileView, isClient, isCenterColumnFullScreenMode }) =>
    !mobileView && isClient
      ? css`
          max-width: calc(100% - ${isCenterColumnFullScreenMode ? 273 : 24}px - 1rem);
          /* margin-left: ${isCenterColumnFullScreenMode ? '-273px' : '-24px'}; */
          padding-right: ${isCenterColumnFullScreenMode ? '136.5px' : '0px'};
        `
      : css`
          max-width: 100%;
        `}
  line-height: 2.5rem;
`;
const StyledDarkEnabledPrimaryFilledButton = styled(DarkEnabledPrimaryFilledButton)`
  position: relative;
  /* padding: 0.5rem 1rem; */
`;
const StyledRightSideActionsContainer = styled.div`
  position: absolute;
  right: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1.25rem;
  max-width: max-content;
`;

const SelfPacedHeader = ({
  ExpansionRestorationIcon = null,
  textColor,
  CenterColumnBackButton = null,
  selectNextSession,
}) => {
  const { isCenterColumnFullScreenMode } = useSelector(activeContribSessTabViewSettingsSelector);
  const { isCoach, isClient, user } = useAccount();
  const [isFuture, setIsFuture] = useState(false);
  const [shouldVideoBeDisabled, setShouldVideoBeDisabled] = useState(false);
  const { selectedSession, selectedSessionIndex } = useActiveContributionSessionById();
  const {
    selectedSessionTimeOrContent,
    selectedSessionTimeOrContentIndex,
  } = useActiveContributionSessionTimeOrContentById();
  const { isPrerecorded } = selectedSession;
  const { subCategoryName } = selectedSessionTimeOrContent || {};
  const { name: title } = selectedSession;
  const { mobileView } = useScreenView();
  const contribution = useContribution();
  const dispatch = useDispatch();
  const refreshContribution = useCallback(async () => {
    const userIp = await getIpGlobal();
    dispatch(fetchClientContribution(contribution?.id, userIp));
  }, [dispatch, contribution?.id]);
  const { Popup, isOpen, onShowPopup } = useCompleteSelfPacedSessionTime(refreshContribution, selectNextSession);
  const [buttonState, setButtonState] = useState(false);
  const { isPurchased, isDarkModeEnabled } = contribution;
  let colorToUse = determineColorToUse(contribution);
  const textColor2 =
    colorToUse?.TextColorCode === 'Auto'
      ? lightOrDark(colorToUse?.PrimaryColorCode)
      : colorToUse?.TextColorCode === '#000000'
      ? '#000000'
      : '#FFFFFF';
  // const name = subCategoryName || `Lesson ${selectedSessionTimeOrContentIndex + 1}`;
  const name = subCategoryName || title || ``;

  const StyledExpansionRestorationIcon = ExpansionRestorationIcon ? (
    styled(ExpansionRestorationIcon)`
      position: relative;
      right: unset;
    `
  ) : (
    <></>
  );

  const checkCompleteBtnStatus = () => {
    const session = selectedSession;
    const sessionIndex = selectedSessionIndex;
    const sessionTime = selectedSessionTimeOrContent;
    const sessionTimeIndex = selectedSessionTimeOrContentIndex;
    if (checkIsFuture(sessionTime?.startTime) && sessionTime.ignoreDateAvailable) {
      setButtonState(true);
    } else if (sessionTime?.completedSelfPacedParticipantIds?.includes(user.id)) {
      setButtonState(true);
    } else if (
      sessionTime?.prerecordedSession?.extension?.toLowerCase()?.includes('jpg') ||
      sessionTime?.prerecordedSession?.extension?.toLowerCase()?.includes('png') ||
      sessionTime?.prerecordedSession?.extension?.toLowerCase()?.includes('jpeg') ||
      sessionTime?.prerecordedSession?.extension?.toLowerCase()?.includes('pdf') ||
      sessionTime?.prerecordedSession?.extension?.toLowerCase()?.includes('doc') ||
      sessionTime?.prerecordedSession?.extension?.toLowerCase()?.includes('txt') ||
      sessionTime?.prerecordedSession?.extension?.toLowerCase()?.includes('xls') ||
      sessionTime?.prerecordedSession?.extension?.toLowerCase()?.includes('xlsx')
    ) {
      setButtonState(false);
    } else {
      if (sessionTime?.usersWhoViewedRecording?.includes(user?.id)) {
        setButtonState(false);
      } else {
        setButtonState(true);
      }
    }
  };

  useEffect(() => {
    if (isClient && isCenterColumnFullScreenMode) {
      checkCompleteBtnStatus();
      isVideoViewable();
    }
  }, [selectedSession, selectedSessionTimeOrContent, isCenterColumnFullScreenMode]);

  const isVideoViewable = () => {
    const session = selectedSession;
    const sessionIndex = selectedSessionIndex;
    const sessionTime = selectedSessionTimeOrContent;
    const sessionTimeIndex = selectedSessionTimeOrContentIndex;
    if (
      sessionTime?.prerecordedSession?.extension?.toLowerCase()?.includes('jpg') ||
      sessionTime?.prerecordedSession?.extension?.toLowerCase()?.includes('png') ||
      sessionTime?.prerecordedSession?.extension?.toLowerCase()?.includes('jpeg') ||
      sessionTime?.prerecordedSession?.extension?.toLowerCase()?.includes('pdf') ||
      sessionTime?.prerecordedSession?.extension?.toLowerCase()?.includes('doc') ||
      sessionTime?.prerecordedSession?.extension?.toLowerCase()?.includes('txt') ||
      sessionTime?.prerecordedSession?.extension?.toLowerCase()?.includes('xls') ||
      sessionTime?.prerecordedSession?.extension?.toLowerCase()?.includes('xlsx')
    ) {
      if (sessionTime?.ignoreDateAvailable) {
        if (checkIsFuture(sessionTime?.startTime)) {
          setIsFuture(true);
          setShouldVideoBeDisabled(true);
        } else {
          setIsFuture(false);
          setShouldVideoBeDisabled(false);
        }
      } else {
        setShouldVideoBeDisabled(false);
      }
    } else {
      if (sessionTime?.mustWatchPriorSelfPacedRecords) {
        if (sessionTime?.ignoreDateAvailable) {
          if (checkIsFuture(sessionTime?.startTime)) {
            setIsFuture(true);
            setShouldVideoBeDisabled(true);
          } else {
            setIsFuture(false);
            setShouldVideoBeDisabled(false);
          }
        }
        if (session.sessionTimes.length > 1 && sessionTimeIndex > 0) {
          if (!session.sessionTimes[sessionTimeIndex - 1]?.usersWhoViewedRecording?.includes(user.id)) {
            setShouldVideoBeDisabled(true);
          } else {
            if (!(sessionTime?.ignoreDateAvailable && checkIsFuture(sessionTime?.startTime)))
              setShouldVideoBeDisabled(false);
          }
        } else {
          if (sessionIndex > 0) {
            for (let i = sessionIndex - 1; i >= 0; i--) {
              if (contribution?.sessions[i].isPrerecorded) {
                if (
                  !contribution?.sessions[i]?.sessionTimes[
                    contribution?.sessions[i]?.sessionTimes.length - 1
                  ]?.usersWhoViewedRecording?.includes(user.id)
                ) {
                  setShouldVideoBeDisabled(true);
                } else {
                  if (shouldVideoBeDisabled && !checkIsFuture(sessionTime?.startTime)) {
                    setShouldVideoBeDisabled(false);
                  } else if (
                    contribution?.sessions[i]?.sessionTimes[
                      contribution?.sessions[i]?.sessionTimes.length - 1
                    ]?.usersWhoViewedRecording?.includes(user.id)
                  ) {
                    setShouldVideoBeDisabled(false);
                  }
                }
                break;
              }
            }
          }
        }
      } else if (sessionTime?.ignoreDateAvailable === true && checkIsFuture(sessionTime?.startTime)) {
        setIsFuture(true);
        setShouldVideoBeDisabled(true);
      } else {
        setShouldVideoBeDisabled(false);
        setIsFuture(false);
      }
    }
  };
  return (
    <>
      <StyledHeader mobileView={mobileView}>
        {CenterColumnBackButton && <CenterColumnBackButton />}
        <Tooltip
          title={name}
          arrow
          enterTouchDelay={TOOLTIP.ENTER_DELAY}
          leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
          style={
            mobileView
              ? {}
              : {
                  /* marginLeft: '5px' */
                }
          }
        >
          <StyledTitle {...{ textColor, mobileView, isCenterColumnFullScreenMode, isClient }}>{name}</StyledTitle>
        </Tooltip>
        {!mobileView && isClient && (
          <StyledRightSideActionsContainer>
            {isCenterColumnFullScreenMode && isPurchased && (
              <>
                <StyledDarkEnabledPrimaryFilledButton
                  textColor={textColor2}
                  backgroundColor={colorToUse?.PrimaryColorCode}
                  autoWidth
                  onClick={() => {
                    onShowPopup({
                      contributionId: contribution?.id,
                      sessionTimeId: selectedSessionTimeOrContent?.id,
                      sessionId: selectedSession?.id,
                    });
                  }}
                  disabled={buttonState || shouldVideoBeDisabled}
                  // endIcon={
                  //   <ArrowForwardIcon
                  //   // style={contribution?.isDarkModeEnabled ? { color: themedColor } : {}}
                  //   />
                  // }
                  {...{ isDarkModeEnabled }}
                >
                  Complete & Continue&nbsp;
                  <ArrowForwardIcon style={{ height: '1rem', width: '1rem' }} />
                </StyledDarkEnabledPrimaryFilledButton>
              </>
            )}
            {ExpansionRestorationIcon && <StyledExpansionRestorationIcon />}
          </StyledRightSideActionsContainer>
        )}
      </StyledHeader>
      {!mobileView && isClient && isPurchased && isPrerecorded && <Popup applyTheming />}
    </>
  );
};

export default SelfPacedHeader;
