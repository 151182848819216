import { createActions } from 'redux-actions';

export const {
  setCohealerInfoForClient,
  clearCohealerInfoForClient,
  setCohealerIcon,
  clearCohealerIcon,
} = createActions(
  'SET_COHEALER_INFO_FOR_CLIENT',
  'CLEAR_COHEALER_INFO_FOR_CLIENT',
  'SET_COHEALER_ICON',
  'CLEAR_COHEALER_ICON',
);
