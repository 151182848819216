import React from 'react';
import PropTypes from 'prop-types';

import { UserRoles } from 'helpers/constants';
import CohealerOneToOneDiscussion from './CohealerOneToOneDiscussion';
import ClientOneToOneDiscussion from './ClientOneToOneDiscussion';

const OneToOneDiscussionComponents = {
  [UserRoles.cohealer]: CohealerOneToOneDiscussion,
  [UserRoles.client]: ClientOneToOneDiscussion,
};

const OneToOneDiscussionContainer = props => {
  const { currentRole, ...restProps } = props;
  const OneToOneDiscussionComponent = OneToOneDiscussionComponents[currentRole];

  return <OneToOneDiscussionComponent {...restProps} />;
};

OneToOneDiscussionContainer.propTypes = {
  currentRole: PropTypes.string.isRequired,
};

export default OneToOneDiscussionContainer;
