import React, { useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import ClientJourneyHeader from './ClientJourneyHeader';
import BannerContainer from '../BannerContainer/BannerContainer';

import './ClientJourney.scss';
import ClosestContributionBannerContainer from '../ClosestContributionBannerContainer/ClosestContributionBannerContainer';
import SessionsHeader from './Sessions/SessionsHeader';
import ClientJourneyRouter from 'routes/ClientJourneyRouter';

import { ClientJourneyContribution } from 'services/dataContracts/contributions';
import { ContributionType, JOURNEY_ROUTES } from 'helpers/constants';

const ClientJourney = ({
  // upcomingNotBooked,
  // upcomingContributions,
  // liveupcomingContributions,
  // selfpacedupcomingContributions,
  // pastContributions,
  // livepastContributions,
  // selfpacedpastContributions,
  // clientDeclinedSubscriptions,
  // hasContributions,
  // coaches,
  hasContributions,
  upcoming,
  past,
  modules,
  inCompleted,
  inCompletedlive,
  inCompletedselfpaced,
  upcominglive,
  upcomingselfpaced,
  closestClassForBanner,
  clientDeclinedSubscriptions,
  bookable,
  coaches,
}) => {
  const [subUrl, setSubUrl] = useState(JOURNEY_ROUTES.SESSIONS || JOURNEY_ROUTES.LIVE);
  const [notBookedLiveSessions, setNotBookedLiveSessions] = useState([]);
  const [selfpacedSessions, setSelfPacedSessions] = useState([]);
  const [selfpacedPastSessions, setSelfPacedPastSessions] = useState([]);
  const [pastSessions, setPastSessions] = useState([]);
  const [liveSessions, setLiveSessions] = useState([]);
  const isSessionsHeaderVisible = subUrl !== JOURNEY_ROUTES.COACHES;

  const filter = (contributions, type) =>
    Object.keys(contributions).reduce((acc, key) => {
      acc[key] = contributions[key].filter(contrib => contrib.type === type);
      return acc;
    }, {});

  // const membershipsUpcoming = useMemo(() => filter(upcomingContributions, ContributionType.contributionMembership), [
  //   upcomingContributions,
  // ]);

  // const membershipsPast = useMemo(() => filter(pastContributions, ContributionType.contributionMembership), [
  //   pastContributions,
  // ]);

  // const oneToOneUpcoming = useMemo(() => filter(upcomingContributions, ContributionType.contributionOneToOne), [
  //   upcomingContributions,
  // ]);

  // const oneToOnePast = useMemo(() => filter(pastContributions, ContributionType.contributionOneToOne), [
  //   pastContributions,
  // ]);

  // const liveUpcoming = useMemo(() => filter(upcomingContributions, ContributionType.contributionCourse), [
  //   upcomingContributions,
  // ]);
  // const livePast = useMemo(() => filter(pastContributions, ContributionType.contributionMembership), [
  //   pastContributions,
  // ]);
  // useEffect(() => {
  //   upcomingNotBookedFN();
  //   pastContributionsFN();
  //   selfPacedContributionFN();
  //   selfPacedPastContributionFN();
  //   livepastFN();
  // }, []);

  // const upcomingNotBookedFN = () => {
  //   let upcomingArray = [];
  //   Array.prototype.push.apply(upcomingArray, upcomingNotBooked.notBooked);
  //   Array.prototype.push.apply(upcomingArray, upcomingNotBooked.otherIncompleted);
  //   setNotBookedLiveSessions(upcomingArray);
  // };
  // const livepastFN = () => {
  //   let livePastArray = [];
  //   Array.prototype.push.apply(livePastArray, livepastContributions.lastMonth);
  //   Array.prototype.push.apply(livePastArray, livepastContributions.priorYears);
  //   Array.prototype.push.apply(livePastArray, livepastContributions.thisMonth);
  //   Array.prototype.push.apply(livePastArray, livepastContributions.thisWeek);
  //   Array.prototype.push.apply(livePastArray, livepastContributions.thisYear);
  //   setLiveSessions(livePastArray);
  // };
  // const pastContributionsFN = () => {
  //   let pastArray = [];
  //   Array.prototype.push.apply(pastArray, pastContributions.lastMonth);
  //   Array.prototype.push.apply(pastArray, pastContributions.priorYears);
  //   Array.prototype.push.apply(pastArray, pastContributions.thisMonth);
  //   Array.prototype.push.apply(pastArray, pastContributions.thisWeek);
  //   Array.prototype.push.apply(pastArray, pastContributions.thisYear);

  //   setPastSessions(pastArray);
  // };

  // const selfPacedContributionFN = () => {
  //   let selfpacedContribution = [];
  //   Array.prototype.push.apply(selfpacedContribution, selfpacedupcomingContributions.afterThisYear);
  //   Array.prototype.push.apply(selfpacedContribution, selfpacedupcomingContributions.nextMonth);
  //   Array.prototype.push.apply(selfpacedContribution, selfpacedupcomingContributions.notBooked);
  //   Array.prototype.push.apply(selfpacedContribution, selfpacedupcomingContributions.otherIncompleted);
  //   Array.prototype.push.apply(selfpacedContribution, selfpacedupcomingContributions.thisMonth);
  //   Array.prototype.push.apply(selfpacedContribution, selfpacedupcomingContributions.thisWeek);
  //   Array.prototype.push.apply(selfpacedContribution, selfpacedupcomingContributions.thisYear);
  //   Array.prototype.push.apply(selfpacedContribution, selfpacedupcomingContributions.today);
  //   setSelfPacedSessions(selfpacedContribution);
  // };
  // const selfPacedPastContributionFN = () => {
  //   let sppc = [];
  //   Array.prototype.push.apply(sppc, selfpacedpastContributions.priorYears);
  //   Array.prototype.push.apply(sppc, selfpacedpastContributions.thisYear);
  //   Array.prototype.push.apply(sppc, selfpacedpastContributions.lastMonth);
  //   Array.prototype.push.apply(sppc, selfpacedpastContributions.thisMonth);
  //   Array.prototype.push.apply(sppc, selfpacedpastContributions.thisWeek);
  //   setSelfPacedPastSessions(sppc);
  // };

  return (
    <div className="client-journey">
      <div className="client-journey__headers">
        <ClientJourneyHeader tabClick={setSubUrl} />
        {isSessionsHeaderVisible && (
          <SessionsHeader
            subUrl={subUrl}
            // selfpacedSessions={selfpacedSessions}
            // upcomingNotBooked={notBookedLiveSessions}
            // upcomingContributions={upcomingContributions}
            // livepastSessions={liveSessions}
            // pastContributions={pastSessions}
            // liveupcomingContributions={liveupcomingContributions}
            // selfpacedupcomingContributions={selfpacedupcomingContributions}
            // livepastContributions={livepastContributions}
            // selfpacedpastContributions={selfpacedPastSessions}
            // membershipsUpcoming={membershipsUpcoming}
            // membershipsPast={membershipsPast}
            // oneToOneUpcoming={oneToOneUpcoming}
            // oneToOnePast={oneToOnePast}
            // liveUpcoming={liveUpcoming}
            // livePast={livePast}
          />
        )}
      </div>
      <div className="client-journey__content">
        <ClosestContributionBannerContainer />
        <BannerContainer
          clientDeclinedSubscriptions={clientDeclinedSubscriptions}
          hasContributions={hasContributions}
        />
        <ClientJourneyRouter
          // selfpacedSessions={selfpacedSessions}
          // upcomingNotBooked={notBookedLiveSessions}
          // upcomingContributions={upcomingContributions}
          // livepastSessions={liveSessions}
          // pastContributions={pastSessions}
          // liveupcomingContributions={liveupcomingContributions}
          // selfpacedupcomingContributions={selfpacedupcomingContributions}
          // livepastContributions={livepastContributions}
          // selfpacedpastContributions={selfpacedPastSessions}
          // membershipsUpcoming={membershipsUpcoming}
          // membershipsPast={membershipsPast}
          // oneToOneUpcoming={oneToOneUpcoming}
          // oneToOnePast={oneToOnePast}
          // liveUpcoming={liveUpcoming}
          // livePast={livePast}
          // coaches={coaches}
          bookable={bookable}
          hasContributions={hasContributions}
          upcoming={upcoming}
          past={past}
          modules={modules}
          inCompleted={inCompleted}
          inCompletedlive={inCompletedlive}
          inCompletedselfpaced={inCompletedselfpaced}
          upcominglive={upcominglive}
          upcomingselfpaced={upcomingselfpaced}
          closestClassForBanner={closestClassForBanner}
          clientDeclinedSubscriptions={clientDeclinedSubscriptions}
          coaches={coaches}
        />
      </div>
    </div>
  );
};

const contributionsPropType = PropTypes.arrayOf(PropTypes.instanceOf(ClientJourneyContribution)).isRequired;

ClientJourney.propTypes = {
  coaches: PropTypes.array.isRequired,
  upcomingContributions: PropTypes.shape({
    otherIncompleted: contributionsPropType,
    today: contributionsPropType,
    thisWeek: contributionsPropType,
    thisMonth: contributionsPropType,
    nextMonth: contributionsPropType,
    thisYear: contributionsPropType,
    afterThisYear: contributionsPropType,
  }).isRequired,
  pastContributions: PropTypes.shape({
    priorYears: contributionsPropType,
    thisYear: contributionsPropType,
    lastMonth: contributionsPropType,
    thisMonth: contributionsPropType,
    thisWeek: contributionsPropType,
  }).isRequired,
  clientDeclinedSubscriptions: PropTypes.shape({}),
};

export default ClientJourney;
