import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';

import UISelect from 'components/UI/Select';
import useContribution from 'pages/ContributionView/hooks/useContribution';
import { memberPodsSelector } from 'selectors/MemberPods';
import usePartnerCoach from 'hooks/usePartnerCoach';
import useAccount from 'hooks/useAccount';
import { useHandlers } from './hooks/useHandlers';
import { useStyles } from './hooks/useStyles';

const mapData = data => {
  return data.map(item => ({
    ...item,
    title: item.name,
    value: item.id,
  }));
};

export const MemberPodsToSessionTimeAssignment = props => {
  const { onAssignPod, value } = props;
  const { error, isFetching, contributionMemberPods } = useSelector(memberPodsSelector);
  const { id } = useContribution();
  const [selectedValue, setSelectedValue] = useState(value);
  const onBlurElementRef = useRef(null);
  const classNames = useStyles();
  const { partnerCoaches } = usePartnerCoach(id);
  const { user } = useAccount();
  const isPartnerCoach = partnerCoaches.some(obj => obj.userId === user.id);
  const handlers = useHandlers({ onAssignPod, onBlurElementRef, setSelectedValue });

  if (isFetching) {
    return 'Loading...';
  }

  if (!Array.isArray(contributionMemberPods?.[id]) || !contributionMemberPods?.[id]?.length || isPartnerCoach) {
    return null;
  }

  return (
    <Grid container direction="column">
      <Grid>
        {error && 'Error loading Member Pods.'}
        <UISelect
          className={classNames.selectWrapper}
          disabled={!contributionMemberPods[id].length}
          items={mapData(contributionMemberPods[id])}
          label={selectedValue ? 'Assigned Cohort' : 'Assign a cohort'}
          onClick={handlers.onClick}
          onChange={handlers.onChange}
          onExited={handlers.onExited}
          value={selectedValue}
        />
        <span ref={onBlurElementRef} tabIndex={-1} />
      </Grid>
    </Grid>
  );
};

MemberPodsToSessionTimeAssignment.defaultProps = {
  onAssignPod: () => {},
  value: '',
};

MemberPodsToSessionTimeAssignment.propTypes = {
  onAssignPod: PropTypes.func,
  value: PropTypes.string,
};
