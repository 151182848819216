import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Route, Redirect, Switch } from 'react-router-dom';
import { useTheme, useMediaQuery } from '@material-ui/core';
import MasterCalendarPage from 'pages/MasterCalendarPage/MasterCalendarPage';
import MainContainer from './MainContainer';
import AffiliatesPage from 'pages/AffiliatesPage';
import { useParams, useLocation, useHistory, useRouteMatch } from 'react-router-dom';
import Modal from 'components/UI/Modal';

const StyledMainSection = styled.div`
  padding: ${({ mobileView }) => (mobileView ? '30px 5px' : '15px')};
`;
function AffiliatesContainer({ match: { path } }) {
  const theme = useTheme();
  const history = useHistory();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <MainContainer>
      <StyledMainSection mobileView={mobileView}>
        <Switch>
          <Route path={`${path}`} exact component={AffiliatesPage} />
          <Redirect to={`${path}`} />
        </Switch>
      </StyledMainSection>
      <Modal
        isOpen={mobileView}
        title="Can't Access"
        reduceCancelButton
        hiddenCancel
        onCloseClick={() => {}}
        onSubmit={() => {
          history.push('/dashboard');
        }}
      >
        For optimal performance and full functionality, we recommend using a browser on a desktop or laptop computer.
      </Modal>
    </MainContainer>
  );
}
AffiliatesContainer.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string,
    url: PropTypes.string,
    isExact: PropTypes.bool,
    params: PropTypes.shape({}),
  }).isRequired,
};
export default AffiliatesContainer;
