export const getContributionTypeView = rawTypes => {
  const typesCollection = {
    ContributionCourse: 'Group Course',
    ContributionOneToOne: '1:1 Sessions',
    ContributionMembership: 'Membership',
    ContributionCommunity: 'Community',
  };

  return typesCollection[rawTypes] || 'Unknown';
};
