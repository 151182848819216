export const Steps = {
  IsPlanPurchased: 'IsPlanPurchased',
  IsProfileUploaded: 'IsProfileUploaded',
  IntegrationsDone: 'IntegrationsDone',
  FirstContributionDone: 'FirstContributionDone',
  ContributionPricingDone: 'ContributionPricingDone',
  IsBankAccountConnected: 'IsBankAccountConnected',
  IsWebsiteAdded: 'IsWebsiteAdded',
  InviteLink: 'InviteLink',
  IsBankAccountConnected: 'IsBankAccountConnected',
};
