import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { StyledButton, StyledTitle } from '../GettingStarted.styles';
import { ROUTES } from 'constants.js';

const StyledImage = styled.img`
  margin-top: 20px;
  height: 60%;

  ${({ mobileView }) =>
    mobileView &&
    `
    width: 80%;
    height: auto;
  `}
`;

const Affiliate = ({ mobileView }) => {
  return (
    <>
      <StyledImage src="/get-started-affiliate.png" mobileView={mobileView} />

      <StyledTitle mobileView={mobileView}>
        Invite your friends, family & community to become coaches & service providers on Cohere. Earn affiliate revenue.
      </StyledTitle>

      <Link to={ROUTES.ACCOUNT_AFFILIATE} target="_blank">
        <StyledButton variant="primary">Invite Friends</StyledButton>
      </Link>
    </>
  );
};

export default Affiliate;
