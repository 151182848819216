import React from 'react'
import PropTypes from 'prop-types'

import { useLocalParticipant, useLocalAudioTrack, useLocalVideoTrack, useLocalScreenTrack } from '../hooks'
import { VideoChartContent } from './VideoChartContent'

const VideoChat = ({
  className,
  chatId,
  room: { localParticipant },
  participants,
  dominantSpeakerParticipant,
  screenShareParticipants,
  onLeaveChat,
}) => {
  const { audioTrack, videoTrack, screenTrack } = useLocalParticipant(localParticipant)
  const {
    isEnabled: isLocalAudioEnabled,
    setIsEnabled: setIsLocalAudioEnabled,
    deviceLabel: audioDeviceLabel,
    switchDevice: switchAudioDevice,
  } = useLocalAudioTrack(localParticipant, audioTrack)
  const {
    isEnabled: isLocalVideoEnabled,
    setIsEnabled: setIsLocalVideoEnabled,
    deviceLabel: videoDeviceLabel,
    switchDevice: switchVideoDevice,
  } = useLocalVideoTrack(localParticipant, videoTrack)
  const { isEnabled: isLocalScreenEnabled, setIsEnabled: setIsLocalScreenEnabled } = useLocalScreenTrack(
    localParticipant,
    screenTrack
  )

  return (
    <VideoChartContent
      className={className}
      chatId={chatId}
      room={{ localParticipant }}
      participants={participants}
      dominantSpeakerParticipant={dominantSpeakerParticipant}
      screenShareParticipants={screenShareParticipants}
      onLeaveChat={onLeaveChat}
      audioTrack={{
        isLocalAudioEnabled,
        setIsLocalAudioEnabled,
        audioDeviceLabel,
        switchAudioDevice,
      }}
      videoTrack={{
        isLocalVideoEnabled,
        setIsLocalVideoEnabled,
        videoDeviceLabel,
        switchVideoDevice,
      }}
      screenTrack={{
        isLocalScreenEnabled,
        setIsLocalScreenEnabled,
      }}
    />
  )
}

const participantPropType = PropTypes.shape({
  identity: PropTypes.string,
})

VideoChat.propTypes = {
  className: PropTypes.string.isRequired,
  chatId: PropTypes.string.isRequired,
  room: PropTypes.shape({
    name: PropTypes.string,
    localParticipant: participantPropType,
  }).isRequired,
  participants: PropTypes.arrayOf(participantPropType).isRequired,
  dominantSpeakerParticipant: participantPropType,
  screenShareParticipants: PropTypes.arrayOf(participantPropType).isRequired,
  onLeaveChat: PropTypes.func.isRequired,
}

VideoChat.defaultProps = {
  dominantSpeakerParticipant: null,
}

export default VideoChat
