import React, { useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHeader, useHttp } from 'hooks';
import Loader from 'components/UI/Loader';
import Button from 'components/FormUI/Button';
import Clients from 'components/Tables/Clients/Clients';
import { fetchClients } from 'actions/clients';
import SearchIcon from '@mui/icons-material/Search';
import _ from 'lodash';
import { TextField } from '@material-ui/core';
import './AllClientsPage.scss';
import { CLIENTS_LIMIT, exportClients } from 'services/clients.service';
import ExportSvg from './components/ExportIcon';
import { downloadFile } from 'utils/utils';

const AllClientsPage = ({ clients, totalCount, getClients, loading }) => {
  useHeader('Clients');
  const [pageNumber, setPageNumber] = useState(1);
  const [clientName, setClientName] = useState();
  const { request } = useHttp();
  useEffect(() => {
    const handleScroll = () => {
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;
      const scrollPosition = window.scrollY;

      if (scrollPosition + windowHeight + 100 > documentHeight && pageNumber * CLIENTS_LIMIT < totalCount) {
        setPageNumber(pageNumber + 1);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [pageNumber, totalCount]);

  const handleSearchDebounceFunction = async searchedclientName => {
    setClientName(searchedclientName);
    setPageNumber(1);
    getClients({ pageNumber: 1, clientName: searchedclientName });
  };

  const searchdebounceFunc = useCallback(_.debounce(handleSearchDebounceFunction, 2000), []);

  function handleChange(event) {
    const newVal = event.target.value;
    if (newVal !== null) {
      searchdebounceFunc(newVal);
    }
  }

  const renderList = useCallback(async () => {
    getClients({ pageNumber, clientName });
  }, [getClients, pageNumber]);

  useEffect(() => {
    renderList();
  }, [renderList]);

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center">
        {!loading && clients.length === 0 ? <div>There are no clients to show.</div> : <div> </div>}
        <div className="clients-list-view-header">
          <TextField
            name="search"
            className="input-field"
            id="outlined-basic"
            margin="normal"
            variant="outlined"
            placeholder="Search by client name"
            fullWidth
            onChange={handleChange}
            InputProps={{
              startAdornment: <SearchIcon />,
            }}
          />
          <Button
            className="export-button"
            style={{ minWidth: '120px', float: 'right', borderRadius: '5px' }}
            onClick={() => {
              request('/api/cohealer-income/sales-history', 'GET', null, {}, 'blob')
                .then(res => downloadFile(res, 'Cohere financial activity.csv'))
                .catch(console.dir);
            }}
          >
            <ExportSvg />
            <span>Export</span>
          </Button>
        </div>
      </div>
      {loading && <Loader />}
      {clients.length ? <Clients rows={clients} /> : !loading}
    </div>
  );
};

const mapStateToProps = ({ clients: { clients, totalCount, isLoading, error }, account }) => ({
  userId: account?.user?.id,
  loading: isLoading,
  error,
  clients: clients || [],
  totalCount,
});

const actions = {
  getClients: fetchClients,
};

AllClientsPage.propTypes = {
  loading: PropTypes.bool.isRequired,
  clients: PropTypes.arrayOf(PropTypes.object).isRequired,
  getClients: PropTypes.func.isRequired,
  totalCount: PropTypes.number.isRequired,
};
export default connect(mapStateToProps, actions)(AllClientsPage);
