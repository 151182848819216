import React, { useState } from 'react';
import styled from 'styled-components';
import Modal from 'components/UI/Modal';
import { Box, Typography, TextField, Button } from '@mui/material';
import ThankYouModal from 'components/Modals/ThankYouModal';
import CustomButton from 'components/FormUI/Button';
import { sendUserNPS_Score } from 'services/user.service';
import { useAccount } from 'hooks';
import CloseIcon from '@material-ui/icons/Close';

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 100%;
`;

const StyledCloseIcon = styled(CloseIcon)`
  cursor: pointer;
  margin-bottom: auto;
  margin-top: 12px;
  ${({ isDarkThemeEnabled }) => (isDarkThemeEnabled ? 'color: #fff' : 'color: black')}
`;

const StyledModal = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ModalContent = styled(Box)`
  color: ${({ isDarkModeEnabled }) => (isDarkModeEnabled ? '#fff' : '#000')};
  border-radius: 8px;
`;

const FeedbackTitle = styled.p`
  color: ${({ isDarkModeEnabled }) => (isDarkModeEnabled ? 'white' : 'rgba(0, 0, 0, 0.87)')};
  font-family: Avenir;
  font-size: 22px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  margin-top: 15px;
  margin-bottom: 24px;
`;

const FeedbackSubtitle = styled.p`
  color: #000;
  color: ${({ isDarkModeEnabled }) => (isDarkModeEnabled ? 'white' : '#000')};
  font-family: Avenir;
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: 130%;
  margin-bottom: 32px;
`;

const FeedbackTextArea = styled(TextField)`
  width: 100%;
  margin-top: 10px;

  & .MuiInputBase-input::placeholder {
    color: ${({ isDarkModeEnabled }) => (isDarkModeEnabled ? '#FFFFFF' : '#000000')};
    font-family: Avenir;
  }

  & .MuiOutlinedInput-notchedOutline {
    border-color: ${({ isDarkModeEnabled }) => (isDarkModeEnabled ? '#FFFFFF' : '')};
  }

  .MuiInputBase-root {
    padding: 10px;
  }

  & .MuiInputBase-root:hover {
    border-color: ${({ isDarkModeEnabled }) => (isDarkModeEnabled ? '#FFFFFF' : '')};
  }

  & .MuiInputBase-input {
    color: ${({ isDarkModeEnabled }) => (isDarkModeEnabled ? '#FFFFFF' : '')};
    caret-color: ${({ isDarkModeEnabled }) => (isDarkModeEnabled ? '#FFFFFF' : '')};
    font-family: Avenir;
  }
`;

const FeedbackButton = styled(CustomButton)`
  display: flex;
  padding: 12px 32px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border-radius: 4px;
  background: var(--Cohere-Secondary-Brown, #c9b382);
`;

const DontRemindMeAgainButton = styled(CustomButton)`
  display: flex;
  padding: 12px 32px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border-radius: 4px;
  border: ${({ isDarkModeEnabled }) =>
    isDarkModeEnabled ? '1px solid white' : '1px solid var(--cohere-greys-000000, #000)'};
  color: ${({ isDarkModeEnabled }) => (isDarkModeEnabled ? 'white' : '#000')};
  background-color: ${({ isDarkModeEnabled }) => (isDarkModeEnabled ? '#333' : 'white')};
  &:hover {
    background-color: ${({ isDarkModeEnabled }) => (isDarkModeEnabled ? '#333' : 'white')};
  }
`;

const RatingContainer = styled(Box)`
  display: flex;
  gap: ${({ mobileView }) => (mobileView ? '' : '30px')};
  margin: 10px 0;
  margin-top: ${({ mobileView }) => (mobileView ? '5px' : '-16px')};
  flex-wrap: ${({ mobileView }) => (mobileView ? 'wrap' : 'none')};
`;

const RatingButton = styled.button`
  height: 48px;
  width: 48px;
  padding: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background-color: ${({ isSelected, isDarkModeEnabled }) =>
    isSelected ? '#C9B382' : isDarkModeEnabled ? '#333' : '#FBFBFB'};
  border: 1px solid #e7e7e7;
  border: ${({ isSelected }) => (isSelected ? '1px solid #FFF' : '1px solid #E7E7E7')};
  box-shadow: ${({ isSelected }) => (isSelected ? '0px 0px 3.3px 0px rgba(225, 187, 98, 1)' : 'none')};
  border-radius: 50%;
  cursor: pointer;
  box-shadow: ${({ isSelected }) => (isSelected ? '0px 0px 3.3px 0px rgba(225, 187, 98, 1)' : 'none')};
`;

const RatingValues = styled.p`
  color: ${({ isSelected, isDarkModeEnabled }) =>
    isSelected || isDarkModeEnabled ? '#fff' : isDarkModeEnabled ? 'white' : '#000'};
  leading-trim: both;
  text-edge: cap;
  font-family: Avenir;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.19px;
  margin-bottom: 1px;
`;

const FooterOuterContainer = styled(Box)`
  margin: 16px 0px;
  display: flex;
  justify-content: end;
  align-items: center;
  border-top: 1.8px solid #e7e7e7;
`;

const FooterInnerContainer = styled(Box)`
  display: flex;
  gap: 10px;
  margin-top: 16px;
`;

const RatingModal = ({ isOpen, handleClose, isDarkModeEnabled = false, mobileView }) => {
  const [rating, setRating] = useState(0);
  const [feedback, setFeedback] = useState('');
  const [isThankYouPopupOpen, setIsThankYouOpen] = useState(false);
  const { user } = useAccount();

  const handleFeedbackSubmit = () => {
    let data = {
      userId: user?.id,
      rating: rating,
      feedback: feedback ? feedback : '',
      dontRemindMe: false,
      type: 'Platform',
    };

    sendUserNPS_Score(data)
      .then(res => {
        handleOpenThankYou();
        handleClose();
      })
      .catch(console.dir);

    handleClose();
    handleOpenThankYou();
  };

  const handleOpenThankYou = () => setIsThankYouOpen(true);
  const handleCloseThankYou = () => {
    setIsThankYouOpen(false);
  };

  const handleFeedbackPreference = () => {
    let data = {
      userId: user?.id,
      rating: 0,
      feedback: feedback ? feedback : '',
      dontRemindMe: true,
      type: 'Platform',
    };

    sendUserNPS_Score(data)
      .then(res => {
        handleClose();
      })
      .catch(console.dir);

    handleClose();
  };

  return (
    <>
      <StyledModal
        isOpen={isOpen}
        onCancel={handleClose}
        disableFooter
        CrossIconHide
        brandingColor={false}
        disableOverlayClick
        isDarkModeSelected={false}
        isCreatingContribution
      >
        <ModalContent isDarkModeSelected={isDarkModeEnabled} mobileView={mobileView}>
          <HeaderContainer>
            <FeedbackTitle isDarkModeEnabled={isDarkModeEnabled} mobileView={mobileView}>
              Rate your overall experience with Cohere
            </FeedbackTitle>
            <StyledCloseIcon isDarkThemeEnabled={isDarkModeEnabled} onClick={handleClose} />
          </HeaderContainer>
          <FeedbackSubtitle isDarkModeEnabled={isDarkModeEnabled}>
            On a scale of 1 to 10, how likely are you to recommend Cohere to a friend or colleague?
          </FeedbackSubtitle>
          {!mobileView && (
            <>
              <RatingContainer mobileView={mobileView}>
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(value => (
                  <RatingButton
                    key={value}
                    onClick={() => setRating(value)}
                    isSelected={rating === value}
                    isDarkModeEnabled={isDarkModeEnabled}
                  >
                    <RatingValues isDarkModeEnabled={isDarkModeEnabled} isSelected={rating === value}>
                      {value}
                    </RatingValues>
                  </RatingButton>
                ))}
              </RatingContainer>
              <Box display="flex" justifyContent="space-between" width="100%">
                <Typography
                  variant="body2"
                  align="left"
                  style={{ color: isDarkModeEnabled ? 'white' : 'rgba(0, 0, 0, 0.87)' }}
                >
                  Not likely
                </Typography>
                <Typography
                  variant="body2"
                  align="right"
                  style={{ color: isDarkModeEnabled ? 'white' : 'rgba(0, 0, 0, 0.87)' }}
                >
                  Extremely likely
                </Typography>
              </Box>
            </>
          )}
          {mobileView && (
            <>
              <Typography
                variant="body2"
                align="left"
                style={{ color: isDarkModeEnabled ? 'white' : 'rgba(0, 0, 0, 0.87)' }}
              >
                Not likely
              </Typography>
              <RatingContainer
                mobileView={mobileView}
                style={{ justifyContent: 'space-between', padding: '10px 14px' }}
              >
                {[1, 2, 3, 4, 5].map(value => (
                  <RatingButton
                    key={value}
                    onClick={() => setRating(value)}
                    isSelected={rating === value}
                    isDarkModeEnabled={isDarkModeEnabled}
                  >
                    <RatingValues isDarkModeEnabled={isDarkModeEnabled} isSelected={rating === value}>
                      {value}
                    </RatingValues>
                  </RatingButton>
                ))}
              </RatingContainer>
              <RatingContainer
                mobileView={mobileView}
                style={{ justifyContent: 'space-between', padding: '10px 14px' }}
              >
                {[6, 7, 8, 9, 10].map(value => (
                  <RatingButton
                    key={value}
                    onClick={() => setRating(value)}
                    isSelected={rating === value}
                    isDarkModeEnabled={isDarkModeEnabled}
                  >
                    <RatingValues isDarkModeEnabled={isDarkModeEnabled} isSelected={rating === value}>
                      {value}
                    </RatingValues>
                  </RatingButton>
                ))}
              </RatingContainer>
              <Typography
                variant="body2"
                align="right"
                style={{ color: isDarkModeEnabled ? 'white' : 'rgba(0, 0, 0, 0.87)' }}
              >
                Extremely likely
              </Typography>
            </>
          )}
          <FeedbackSubtitle style={{ marginTop: '24px', marginBottom: '16px' }} isDarkModeEnabled={isDarkModeEnabled}>
            Your feedback <span style={{ fontWeight: 400 }}>(optional)</span>
          </FeedbackSubtitle>
          <FeedbackTextArea
            multiline
            rows={4}
            variant="outlined"
            placeholder="Anything you would like to add? Your input is valuable to us"
            value={feedback}
            onChange={e => setFeedback(e.target.value)}
            isDarkModeEnabled={isDarkModeEnabled}
            inputProps={{ maxLength: 500 }}
            helperText={`${feedback.length}/500`}
            sx={{
              '& .MuiFormHelperText-root': {
                color: 'rgba(0, 0, 0, 0.87)',
                fontSize: '0.75rem !important',
                fontWeight: 'normal !important',
                fontFamily: 'Avenir',
                lineHeight: '1.5rem',
                letterSpacing: '0.08px',
                marginLeft: '0px',
                marginRight: '0px',
                textAlign: 'end',
              },
            }}
          />
          <FooterOuterContainer mt={2}>
            {!mobileView && (
              <>
                <FooterInnerContainer>
                  <DontRemindMeAgainButton onClick={handleFeedbackPreference} isDarkModeEnabled={isDarkModeEnabled}>
                    Don't Remind Me Again
                  </DontRemindMeAgainButton>
                  <FeedbackButton
                    onClick={handleFeedbackSubmit}
                    disabled={rating === 0}
                    isDarkModeEnabled={isDarkModeEnabled}
                  >
                    Send Feedback
                  </FeedbackButton>
                </FooterInnerContainer>
              </>
            )}
            {mobileView && (
              <>
                <div style={{ width: '100%' }}>
                  <FooterInnerContainer style={{ display: 'inline-grid', width: '100%' }}>
                    <DontRemindMeAgainButton onClick={handleFeedbackPreference} isDarkModeEnabled={isDarkModeEnabled}>
                      Don't Remind Me Again
                    </DontRemindMeAgainButton>
                    <FeedbackButton
                      onClick={handleFeedbackSubmit}
                      disabled={rating === 0}
                      isDarkModeEnabled={isDarkModeEnabled}
                    >
                      Send Feedback
                    </FeedbackButton>
                  </FooterInnerContainer>
                </div>
              </>
            )}
          </FooterOuterContainer>
        </ModalContent>
      </StyledModal>

      <ThankYouModal
        isOpen={isThankYouPopupOpen}
        handleClose={handleCloseThankYou}
        isDarkModeEnabled={isDarkModeEnabled}
        mobileView={mobileView}
        title="Thank you for your feedback!"
        subtitle="We are grateful for your partnership. Your feedback is invaluable in helping us continually reflect and evolve to be the best possible platform for your business."
      />
    </>
  );
};

export default RatingModal;
