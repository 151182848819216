import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

export const IconUsersClass = ({ className, style }) => (
  <svg
    style={style}
    className={classnames('svg-inline--fa fa-users-class fa-w-20 fa-7x', className)}
    ariaHidden="true"
    focusable="false"
    dataPrefix="fas"
    dataIcon="users-class"
    viewBox="0 0 640 512"
  >
    <path
      fill="currentColor"
      d="M256 288c0 35.35 28.66 64 64 64 35.35 0 64-28.65 64-64s-28.65-64-64-64c-35.34 0-64 28.65-64 64zm224 0c0 35.35 28.66 64 64 64 35.35 0 64-28.65 64-64s-28.65-64-64-64c-35.34 0-64 28.65-64 64zM96 352c35.35 0 64-28.65 64-64s-28.65-64-64-64c-35.34 0-64 28.65-64 64s28.66 64 64 64zm480 32h-64c-35.34 0-64 28.65-64 64v32c0 17.67 14.33 32 32 32h128c17.67 0 32-14.33 32-32v-32c0-35.35-28.66-64-64-64zm-224 0h-64c-35.34 0-64 28.65-64 64v32c0 17.67 14.33 32 32 32h128c17.67 0 32-14.33 32-32v-32c0-35.35-28.66-64-64-64zm-224 0H64c-35.34 0-64 28.65-64 64v32c0 17.67 14.33 32 32 32h128c17.67 0 32-14.33 32-32v-32c0-35.35-28.66-64-64-64zM96 64h448v128c24.68 0 46.98 9.62 64 24.97V49.59C608 22.25 586.47 0 560 0H80C53.53 0 32 22.25 32 49.59v167.38C49.02 201.62 71.33 192 96 192V64z"
    />
  </svg>
)

IconUsersClass.propTypes = {
  className: PropTypes.string,
  style: PropTypes.string,
}

IconUsersClass.defaultProps = {
  className: null,
  style: {},
}
