import React from 'react';
import styled, { css } from 'styled-components';
import { Timeline } from 'antd';
import { useContribution } from 'pages/ContributionView/hooks';
import { determineColorToUse } from 'services/contributions.service';
import { VerticalTimelineLockIcon, VerticalTimelineHollowIcon, VerticalTimelineFilledIcon } from './icons';
import { hexToRgba } from 'utils/utils';
// import 'antd/dist/antd.min.css';

const StyledOuterTimeline = styled(Timeline)`
  &.ant-timeline {
    > .ant-timeline-item {
      width: 100%;
    }
  }
  ${({ $mainStyleOverrides }) => $mainStyleOverrides}
`;
const StyledOuterTimelineItem = styled(Timeline.Item)`
  &.ant-timeline-item {
    padding-bottom: 0;
    ${({ $timelineItemStyleOverrides }) => $timelineItemStyleOverrides}
    .ant-timeline-item-tail {
      transform: translateY(25%);
      ${({ hideTail, tailColor }) =>
        hideTail
          ? css`
              color: transparent;
              border-left-color: transparent;
              border-left-width: none;
              border-left: none;
            `
          : tailColor &&
            css`
              color: rgba(${hexToRgba(tailColor, 0.4)});
              border-left-color: rgba(${hexToRgba(tailColor, 0.4)});
            `};
      ${({ $timelineItemTailStyleOverrides }) => $timelineItemTailStyleOverrides}
    }
    .ant-timeline-item-head.ant-timeline-item-head-custom {
      transform: translate(-50%, 100%);
      /* svg {
        ${({ statusIconColor }) =>
        statusIconColor &&
        css`
          color: rgba(${hexToRgba(statusIconColor, 1)});
          border-left-color: rgba(${hexToRgba(statusIconColor, 1)});
        `};
        circle {
          ${({ hollowIconColor }) =>
        hollowIconColor &&
        css`
          stroke: rgba(${hexToRgba(hollowIconColor, 1)});
        `};
          ${({ filledIconColor }) =>
        filledIconColor &&
        css`
          fill: rgba(${hexToRgba(filledIconColor, 1)});
          stroke: rgba(${hexToRgba(filledIconColor, 1)});
        `};
        }
      } */
      ${({ $timelineItemHeadStyleOverrides }) => $timelineItemHeadStyleOverrides}
    }
    .ant-timeline-item-content {
      margin-left: 22px;
      ${({ $timelineItemContentStyleOverrides }) => $timelineItemContentStyleOverrides}
    }
  }
`;

export const VerticalTimeline = props => {
  const { children, ...rest } = props;
  const timelineProps = {
    // config props here: https://4x.ant.design/components/timeline/#Timeline
    mode: 'left',
  };

  return (
    <>
      <StyledOuterTimeline {...timelineProps} {...rest}>
        {children}
      </StyledOuterTimeline>
    </>
  );
};

export const VerticalTimelineItem = props => {
  const { children, isLocked = false, isCompleted = false, isLastItem = false, disableTail = false, ...rest } = props;
  const contribution = useContribution();
  const colorsToUse = determineColorToUse(contribution);
  const accentColor = colorsToUse?.AccentColorCode;
  let Icon = VerticalTimelineHollowIcon;
  if (isLocked) {
    Icon = VerticalTimelineLockIcon;
  } else if (isCompleted) {
    Icon = VerticalTimelineFilledIcon;
  }
  const timelineItemProps = {
    // config props here: https://4x.ant.design/components/timeline/#Timeline.Item
    color: accentColor,
    dot: <Icon fillColor={accentColor} />,
  };
  const hideTail = disableTail || isLastItem;
  const styleOverrides = {
    ...(hideTail ? { hideTail } : { tailColor: accentColor }),
  };
  // const iconColors = {
  //   ...(isLocked
  //     ? { lockIconColor: accentColor }
  //     : isCompleted
  //     ? { filledIconColor: accentColor }
  //     : { hollowIconColor: accentColor }),
  // };

  return (
    <StyledOuterTimelineItem {...timelineItemProps} {...styleOverrides} {...rest}>
      {children}
    </StyledOuterTimelineItem>
  );
};
