import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import Tabs from '@material-ui/core/Tabs';
import * as paidTier from 'selectors/paidTier';
import Can from 'components/Auth/Can';
import { SIGNUP_TYPES, UserRoles } from 'helpers/constants';
import { toRem, colors } from 'utils/styles';
import { useTheme, useMediaQuery } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useAccount, useShallowEqualSelector } from 'hooks/index';
import { PAID_TIER_TITLES } from '../../constants';
import { setCohereAcademyStatus } from 'actions/update-user';
const StyledAccountHeaderContainer = styled(Tabs)`
  border-top: solid 1px #e7e7e7;
  border-bottom: solid 1px #e7e7e7;
  min-width: 310px;

  ${({ mobileViewLaptop }) => mobileViewLaptop && 'width: calc(100vw - 300px);'}
`;

const StyledNavLink = styled(NavLink)`
  text-decoration: none;
  font-size: ${toRem(16)};
  line-height: 1;
  color: #4a4a4a;
  padding: 16px 32px;
  display: inline-block;
  position: relative;

  &.disableMe {
    opacity: 0.5;
    pointer-events: none;
    cursor: not-allowed;
  }

  &.active {
    color: ${colors.darkOceanBlue};
  }

  &.active:after {
    content: '';
    width: 100%;
    height: 4px;
    background-color: ${colors.darkOceanBlue};
    position: absolute;
    bottom: 0;
    left: 0;
  }
  ${({ mobileView }) => mobileView && 'display: block'}
`;

function CohealerMenu({ mobileViewLaptop, mobileView }) {
  const user = useSelector(state => state.account);
  const { user: loggedInUser } = useAccount();
  const dispatch = useDispatch();
  const activePaidTierCurrentPlan = useShallowEqualSelector(paidTier.activePaidTierCurrentPlanSelector);
  // const { isLaunchPlan } = usePaidTier();
  const isLaunchPlan = activePaidTierCurrentPlan?.displayName === PAID_TIER_TITLES.launch;

  // Correct behavior - 100%

  useEffect(() => {
    if (activePaidTierCurrentPlan?.default) {
      dispatch(setCohereAcademyStatus(true));
    } else {
      dispatch(setCohereAcademyStatus(false));
    }
  }, [activePaidTierCurrentPlan, dispatch]);

  const isBetaUser = user?.user?.isBetaUser && user?.user?.serviceAgreementType ? true : false;
  const UScoach = user?.user?.countryId === '60b8ddb57205057e7ce2b861' ? true : false;
  const showAff = !isBetaUser ? true : isBetaUser && UScoach ? true : false;
  const shouldBeDisabled =
    isLaunchPlan ||
    user.signupType === SIGNUP_TYPES.TYPE_A ||
    user.signupType === SIGNUP_TYPES.TYPE_B ||
    loggedInUser.signupType === SIGNUP_TYPES.TYPE_B ||
    loggedInUser.signupType === SIGNUP_TYPES.TYPE_A;

  return (
    <StyledAccountHeaderContainer
      mobileView={mobileView}
      mobileViewLaptop={mobileViewLaptop}
      variant="scrollable"
      scrollButtons="auto"
    >
      <StyledNavLink
        className={
          user?.user?.isPartnerCoach === false && shouldBeDisabled === false
            ? null
            : shouldBeDisabled == true && user?.user?.isPartnerCoach === false
            ? 'disableMe'
            : shouldBeDisabled == true && user?.user?.isPartnerCoach === true
            ? null
            : shouldBeDisabled == true && user?.user?.isPartnerCoach === false
            ? 'disableMe'
            : null
        }
        mobileView={mobileView}
        to="/account/profile"
      >
        Profile & Branding
      </StyledNavLink>
      <StyledNavLink
        className={
          user?.user?.isPartnerCoach === false && shouldBeDisabled === false
            ? null
            : shouldBeDisabled == true && user?.user?.isPartnerCoach === false
            ? 'disableMe'
            : shouldBeDisabled == true && user?.user?.isPartnerCoach === true
            ? null
            : shouldBeDisabled == true && user?.user?.isPartnerCoach === false
            ? 'disableMe'
            : null
        }
        mobileView={mobileView}
        to="/account/website"
      >
        Website
      </StyledNavLink>

      <StyledNavLink
        className={
          user?.user?.isPartnerCoach === false && shouldBeDisabled === false
            ? null
            : shouldBeDisabled == true && user?.user?.isPartnerCoach === false
            ? 'disableMe'
            : shouldBeDisabled == true && user?.user?.isPartnerCoach === true
            ? null
            : shouldBeDisabled == true && user?.user?.isPartnerCoach === false
            ? 'disableMe'
            : null
        }
        mobileView={mobileView}
        to="/account/payment"
      >
        My Sales
      </StyledNavLink>

      <StyledNavLink mobileView={mobileView} to="/account/billing/coach">
        Cohere Billing
      </StyledNavLink>

      {/* <StyledNavLink
        className={
          user?.user?.isPartnerCoach === false && shouldBeDisabled === false
            ? null
            : shouldBeDisabled == true && user?.user?.isPartnerCoach === false
            ? 'disableMe'
            : shouldBeDisabled == true && user?.user?.isPartnerCoach === true
            ? null
            : shouldBeDisabled == true && user?.user?.isPartnerCoach === false
            ? 'disableMe'
            : null
        }
        mobileView={mobileView}
        to="/account/tax-info"
      >
        Tax info
      </StyledNavLink> */}

      <StyledNavLink
        className={
          user?.user?.isPartnerCoach === false && shouldBeDisabled === false
            ? null
            : shouldBeDisabled == true && user?.user?.isPartnerCoach === false
            ? 'disableMe'
            : shouldBeDisabled == true && user?.user?.isPartnerCoach === true
            ? null
            : shouldBeDisabled == true && user?.user?.isPartnerCoach === false
            ? 'disableMe'
            : null
        }
        mobileView={mobileView}
        to="/account/notifications"
      >
        Notifications
      </StyledNavLink>

      <StyledNavLink
        className={
          user?.user?.isPartnerCoach === false && shouldBeDisabled === false
            ? null
            : shouldBeDisabled == true && user?.user?.isPartnerCoach === false
            ? 'disableMe'
            : shouldBeDisabled == true && user?.user?.isPartnerCoach === true
            ? null
            : shouldBeDisabled == true && user?.user?.isPartnerCoach === false
            ? 'disableMe'
            : null
        }
        mobileView={mobileView}
        to="/account/video"
      >
        Video
      </StyledNavLink>

      <StyledNavLink
        className={
          user?.user?.isPartnerCoach === false && shouldBeDisabled === false
            ? null
            : shouldBeDisabled == true && user?.user?.isPartnerCoach === false
            ? 'disableMe'
            : shouldBeDisabled == true && user?.user?.isPartnerCoach === true
            ? null
            : shouldBeDisabled == true && user?.user?.isPartnerCoach === false
            ? 'disableMe'
            : null
        }
        mobileView={mobileView}
        to="/account/integrations"
      >
        Integrations
      </StyledNavLink>

      {/* <StyledNavLink
        className={
          user?.user?.isPartnerCoach === false && shouldBeDisabled === false
            ? null
            : shouldBeDisabled == true && user?.user?.isPartnerCoach === false
            ? 'disableMe'
            : shouldBeDisabled == true && user?.user?.isPartnerCoach === true
            ? null
            : shouldBeDisabled == true && user?.user?.isPartnerCoach === false
            ? 'disableMe'
            : null
        }
        mobileView={mobileView}
        to="/account/affiliate"
      >
        Affiliate
      </StyledNavLink> */}
    </StyledAccountHeaderContainer>
  );
}

function ClientMenu({ mobileViewLaptop, mobileView }) {
  return (
    <StyledAccountHeaderContainer
      mobileView={mobileView}
      mobileViewLaptop={mobileViewLaptop}
      variant="scrollable"
      scrollButtons="auto"
    >
      <StyledNavLink mobileView={mobileView} to="/account/profile">
        My Profile
      </StyledNavLink>
      <StyledNavLink mobileView={mobileView} to="/account/billing">
        Billing
      </StyledNavLink>
      <StyledNavLink mobileView={mobileView} to="/account/notifications">
        Notifications
      </StyledNavLink>
      <StyledNavLink mobileView={mobileView} to="/account/video">
        Video
      </StyledNavLink>
      {/* <StyledNavLink mobileView={mobileView} to="/account/memberships">
        Memberships
      </StyledNavLink> */}
    </StyledAccountHeaderContainer>
  );
}

function AccountHeader() {
  const theme = useTheme();
  const mobileViewLaptop = useMediaQuery(theme.breakpoints.up('md'));

  const mobileView = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <>
      <Can
        roleName={UserRoles.client}
        yes={() => <ClientMenu mobileViewLaptop={mobileViewLaptop} mobileView={mobileView} />}
      />
      <Can
        roleName={UserRoles.cohealer}
        yes={() => <CohealerMenu mobileViewLaptop={mobileViewLaptop} mobileView={mobileView} />}
      />
    </>
  );
}

export default AccountHeader;
