import React from 'react';
import Helmet from 'react-helmet';

function SEO({ title, description, thumbnails }) {
  return (
    <Helmet>
      <title>{title}</title>
      <meta property="og:title" content={title} />
      {thumbnails && thumbnails[0] && <meta property="og:image" content={thumbnails[0]} />}
      <meta property="og:description" content={description} />
    </Helmet>
  );
}

export default SEO;
