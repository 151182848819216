import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import * as actions from 'actions/videoChat';

const useVideoChatActions = () => {
  const dispatch = useDispatch();

  const startVideoChat = useCallback(
    ({
      contributionId,
      type,
      sessionId = null,
      classId = null,
      title,
      roomId = null,
      chatId,
      token,
      deleteRoomOnVideoEnd = false,
    }) =>
      dispatch(
        actions.startVideoChat({
          contributionId,
          type,
          sessionId,
          classId,
          title,
          roomId,
          chatId,
          token,
          deleteRoomOnVideoEnd,
        }),
      ),
    [dispatch],
  );
  const endVideoChat = useCallback(() => dispatch(actions.endVideoChat()), [dispatch]);
  const addVideoChatCreator = useCallback(({ creatorId }) => dispatch(actions.addVideoChatCreator({ creatorId })), [
    dispatch,
  ]);

  return { startVideoChat, endVideoChat, addVideoChatCreator };
};

export default useVideoChatActions;
