import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as contributionActions from 'actions/contributions';
import { setPostsAsRead } from 'services/user.service';
import useRouter from './useRouter';

export const useSaveContribution = (
  canSave,
  hasAgreedContributionTerms,
  isElectronicSignatureActive,
  isCustomWaiverChecked,
  isThankyouPageActive,
  thankyouPage,
  customToS,
  customWaiverId,
  isAttachmentOrWaiver,
) => {
  const dispatch = useDispatch();
  const activeContribution = useSelector(state => state?.contributions?.activeContribution);
  const user = useSelector(state => state?.account?.user);
  const { query } = useRouter();
  const [saving, setSaving] = useState(false);
  const CONTRIBUTION_STATUSES = {
    DRAFT: 'Draft',
  };
  const onSave = useCallback(
    (contribution, redirect) => {
      if (!canSave) {
        return;
      }
      let tempCustomWaiverId = contribution?.customWaiverId
        ? contribution?.customWaiverId === 'None'
          ? null
          : contribution?.customWaiverId
        : customWaiverId
        ? customWaiverId === 'None'
          ? null
          : customWaiverId
        : null;
      setSaving(true);
      let data;
      if (query.id) {
        if (
          (contribution?.paymentInfo?.paymentOptions?.length === 1 &&
            activeContribution?.paymentInfo?.paymentOptions?.length === 1 &&
            activeContribution?.paymentInfo?.paymentOptions?.includes('Free')) ||
          (contribution?.paymentInfo?.paymentOptions?.length === 1 &&
            activeContribution?.paymentInfo?.paymentOptions?.length === 1 &&
            activeContribution?.paymentInfo?.paymentOptions?.includes('FreeSessionsPackage')) ||
          (contribution?.paymentInfo?.paymentOptions?.length === 2 &&
            activeContribution?.paymentInfo?.paymentOptions?.length === 2 &&
            activeContribution?.paymentInfo?.paymentOptions?.includes('Free') &&
            activeContribution?.paymentInfo?.paymentOptions?.includes('FreeSessionsPackage'))
        ) {
          data = {
            ...(contribution || activeContribution),
            userId: user.id,
            customToS: customToS ? customToS.fileUrl : (contribution || activeContribution).customToS,
            customWaiverId: isAttachmentOrWaiver != 'attachments' ? tempCustomWaiverId : null,
            customWaiverTemplateName:
              isAttachmentOrWaiver != 'attachments'
                ? (contribution || activeContribution).customWaiverTemplateName
                : null,
            paymentInfo: {
              ...activeContribution.paymentInfo,
              cost: null,
            },
          };
        } else {
          data = {
            ...(contribution || activeContribution),
            userId: user.id,
            customToS: customToS ? customToS.fileUrl : (contribution || activeContribution).customToS,
            customWaiverId: isAttachmentOrWaiver != 'attachments' ? tempCustomWaiverId : null,
            customWaiverTemplateName:
              isAttachmentOrWaiver != 'attachments'
                ? (contribution || activeContribution).customWaiverTemplateName
                : null,
            isThankyouPageAdded: isThankyouPageActive || contribution?.isThankyouPageAdded,
            thankyouPage: thankyouPage || contribution?.thankyouPage,
          };
        }
      } else {
        data = {
          ...(contribution || activeContribution),
          userId: user.id,
          customToS: customToS ? customToS.fileUrl : (contribution || activeContribution).customToS,
          customWaiverId: isAttachmentOrWaiver != 'attachments' ? tempCustomWaiverId : null,
          customWaiverTemplateName:
            isAttachmentOrWaiver != 'attachments'
              ? (contribution || activeContribution).customWaiverTemplateName
              : null,
          isThankyouPageAdded: isThankyouPageActive || contribution?.isThankyouPageAdded,
          thankyouPage: thankyouPage || contribution?.thankyouPage,
        };
      }
      const { id: contributionId } = contribution || activeContribution;

      data.hasAgreedContributionTerms =
        hasAgreedContributionTerms === undefined && data.status !== CONTRIBUTION_STATUSES.DRAFT
          ? true
          : hasAgreedContributionTerms;

      data.isElectronicSignatureActive =
        isElectronicSignatureActive === undefined && data.status !== CONTRIBUTION_STATUSES.DRAFT
          ? activeContribution?.isElectronicSignatureActive
          : isElectronicSignatureActive;
      data.isThankyouPageAdded =
        isThankyouPageActive === undefined && data.status !== CONTRIBUTION_STATUSES.DRAFT
          ? activeContribution?.isThankyouPageAdded
          : isThankyouPageActive || contribution?.isThankyouPageAdded;
      data.thankyouPage =
        thankyouPage === undefined && data.status !== CONTRIBUTION_STATUSES.DRAFT
          ? activeContribution?.thankyouPage
          : thankyouPage || contribution?.thankyouPage;

      const redirectByAction = action => {
        if (redirect) {
          redirect(action, data);
        }
        setSaving(false);
      };

      if (query.id) {
        delete data.serviceProviderName;
        delete data.timeZoneId;
        delete data.participants;

        dispatch(contributionActions.updateContribution(query.id, data)).then(redirectByAction);
      } else {
        if (data.liveVideoServiceProvider.providerName === 'InPersonSession') {
          data.liveVideoServiceProvider.providerName = 'Custom';
        }
        dispatch(contributionActions.createContribution(data))
          .then(redirectByAction)
          .then(() => setPostsAsRead({ contributionId, userId: user.id }));
      }
    },
    [
      dispatch,
      activeContribution,
      query,
      setSaving,
      user.id,
      customWaiverId,
      isCustomWaiverChecked,
      hasAgreedContributionTerms,
      isElectronicSignatureActive,
      canSave,
    ],
  );

  return { onSave, saving };
};
