export const ContributionStatus = {
  unfinished: 'Draft',
  inSandbox: 'InSandbox',
  inReview: 'InReview',
  updated: 'Updated',
  approved: 'Approved',
  rejected: 'Rejected',
  changeRequired: 'ChangeRequired',
  completed: 'Completed',
  revised: 'Revised',
};
