import React, { useCallback, useEffect, useState } from 'react';

import { TOOLTIP } from 'pages/ContributionView/ContributionSessions/constants';
import { colors } from 'utils/styles';
import useScreenView from 'hooks/useScreenView';
import { CrossOutlineIcon } from 'pages/LeadMagnetView/components/Icon';
import { set } from 'lodash';
import DetailsContainerWithHeader from './DetailsContainerWithHeader';
import InputWithTitle from './InputWithTitle';
import { StyledTitle, StyledInfoIcon, StyledTooltip } from './SharedStyledComponents';
import { FlexContainer, FlexColumn, StyledBannerTitleRow } from './StyleComponents';
import BannerUploader from './BannerUploader';
import { css } from 'styled-components';

const LeadMagnetThankYou = ({ values, errors, touched, setFieldValue, addFilesToDelete }) => {
  const { mobileView } = useScreenView();
  const { isVideoBanner, bannerImage } = values?.thankYou;
  const getFormProps = fieldName => {
    const fieldPath = `thankYou.${fieldName}`;
    return {
      // value: values?.[fieldPath],
      value: values?.thankYou?.[fieldName],
      name: fieldPath,
      onChange: v => {
        setFieldValue(fieldPath, v);
      },
    };
  };
  const onBannerUploadHander = (filePath, isVideo) => {
    const currentBanner = values?.thankYou?.bannerImage;
    addFilesToDelete(currentBanner);
    setFieldValue('thankYou.bannerImage', filePath);
    setFieldValue('thankYou.isVideoBanner', isVideo);
  };
  const removeBanner = useCallback(() => {
    // deletePublicImage(values?.thankYou?.bannerImage).then(() => {
    //   updateBannerInState('', false);
    // });
    onBannerUploadHander('', false);
  }, [values?.thankYou?.bannerImage]);
  return (
    <DetailsContainerWithHeader
      firstHeading="Customize Thank You Page"
      headingStyles={
        mobileView
          ? css`
              color: rgba(0, 0, 0, 0.87);
              font-family: Avenir;
              font-size: 1.0625rem;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
            `
          : css`
              color: rgba(0, 0, 0, 0.87);
              font-family: Avenir;
              font-size: 1.125rem;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
            `
      }
    >
      <FlexContainer mobileView={mobileView}>
        <FlexColumn>
          <StyledBannerTitleRow>
            <StyledTitle>Banner</StyledTitle>
            {bannerImage && (
              <div
                role="button"
                tabIndex={0}
                style={{ cursor: 'pointer' }}
                onClick={removeBanner}
                onKeyUp={removeBanner}
              >
                <CrossOutlineIcon />
              </div>
            )}
          </StyledBannerTitleRow>
          <div style={{ marginTop: '8px' }}>
            <BannerUploader {...{ onBannerUpload: onBannerUploadHander, isVideoBanner, bannerImage }} />
          </div>
        </FlexColumn>
        <FlexColumn>
          <InputWithTitle
            title="Header"
            wordLimit={80}
            toolTip="On the ‘thank you’ page, this is the header text for the section that contains your lead magnet. Click the ‘Example’ button above."
            formprops={getFormProps('header')}
            textField
            placeholder="Access Your Content Now!"
          />
          <InputWithTitle
            wordLimit={500}
            title="Description"
            formprops={getFormProps('description')}
            textArea
            placeholder="After your clients provide their email on the opt-in page, they’ll be redirected to a thank you page. Please write a short description here that entices your leads to finish accessing your lead magnet!"
          />
          <InputWithTitle
            wordLimit={20}
            title="CTA Button"
            toolTip="Enter the button text and link for CTA section, this display on the  main section of the Thank You page."
            formprops={getFormProps('ctaButtonText')}
            textField
            placeholder="Access Content"
          />
        </FlexColumn>
      </FlexContainer>
    </DetailsContainerWithHeader>
  );
};

export default LeadMagnetThankYou;
