import { useCallback, useState, useEffect } from 'react';
import TwilioVideo from 'twilio-video';

import VideoChatVideoTrackType from 'components/UI/VideoChat/VideoChatVideoTrackType';

export const useVideoTrack = () => {
  const [videoTrack, setVideoTrack] = useState();
  const isEnabled = Boolean(videoTrack);

  const startLocalVideoTrack = useCallback(async (deviceId = null) => {
    try {
      const createdVideoTrack = await TwilioVideo.createLocalVideoTrack({
        logLevel: 'debug',
        name: VideoChatVideoTrackType.camera,
        deviceId: deviceId ? { exact: deviceId } : undefined,
      });
      setVideoTrack(createdVideoTrack);
    } catch (e) {
      console.log('Failed to turn on camera.', e);
    }
  }, []);

  const stopLocalVideoTrack = useCallback(() => {
    if (videoTrack) {
      videoTrack.stop();
      setVideoTrack(undefined);
    }
  }, [videoTrack, setVideoTrack]);

  const switchVideoDevice = useCallback(
    deviceId => {
      stopLocalVideoTrack();
      startLocalVideoTrack(deviceId);
    },
    [startLocalVideoTrack, stopLocalVideoTrack],
  );

  const setIsLocalVideoTrackEnabled = useCallback(
    enabled => {
      if (enabled) {
        startLocalVideoTrack();
      } else {
        stopLocalVideoTrack();
      }
    },
    [startLocalVideoTrack, stopLocalVideoTrack],
  );

  useEffect(
    () => () => {
      stopLocalVideoTrack();
    },
    [stopLocalVideoTrack],
  );

  return {
    videoTrack,
    isEnabled,
    setIsEnabled: setIsLocalVideoTrackEnabled,
    deviceLabel: isEnabled && videoTrack ? videoTrack.mediaStreamTrack.label : null,
    switchDevice: switchVideoDevice,
  };
};
