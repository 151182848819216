import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useRouter } from 'hooks';
import { ClientJourneyContribution } from 'services/dataContracts/contributions';
import useAccount from 'hooks/useAccount';
import CoachesList from 'pages/Dashboard/components/ClientDashboard/ClientJourney/Sessions/CoachesList';
import SessionsRouter from './SessionsRouter';
import ContributionRouter from './ContributionRouter';
import { ContributionType, JOURNEY_ROUTES } from 'helpers/constants';
import { getClientPurchases } from 'services/contributions.service';

const ClientJourneyRouter = ({
  // upcomingNotBooked,
  // upcomingContributions,
  // pastContributions,
  // liveupcomingContributions,
  // selfpacedupcomingContributions,
  // livepastContributions,
  // selfpacedpastContributions,
  // livepastSessions,
  // coaches,
  // selfpacedSessions,

  hasContributions,
  upcoming,
  past,
  modules,
  inCompleted,
  inCompletedlive,
  inCompletedselfpaced,
  upcominglive,
  upcomingselfpaced,
  bookable,
  closestClassForBanner,
  clientDeclinedSubscriptions,
  coaches,
}) => {
  const {
    match: { path, url },
  } = useRouter();

  const [membershipUpcoming, setMemberShipUpcoming] = useState([]);
  const [membershipPast, setMemberShipPast] = useState([]);
  const [OneonOnePast, setOneonOnePast] = useState([]);
  const [OneonOneUpcoming, setOneonOneUpcoming] = useState([]);
  const [contributionCourseUpcoming, setcontributionCourseUpcoming] = useState([]);
  const [contributionCoursePast, setcontributionCoursePast] = useState([]);
  const [contributionWorkshopPast, setcontributionWorkshopPast] = useState([]);
  const [contributionWorkshopUpcoming, setcontributionWorkshopUpcoming] = useState([]);
  const { user } = useAccount();

  useEffect(() => {
    getClientPurchases(user.id, false).then(filterByType);
  }, []);

  const filterByType = contribs => {
    const pastMembership = contribs.filter(
      el => el.type === ContributionType.contributionMembership && el.isPast === true && el.isUpcoming === false,
    );
    setMemberShipPast(pastMembership);

    const upcomingMembership = contribs.filter(
      el => el.type === ContributionType.contributionMembership && el.isUpcoming === true && el.isPast === false,
    );
    setMemberShipUpcoming(upcomingMembership);

    const pastOneOnOne = contribs.filter(
      el => el.type === ContributionType.contributionOneToOne && el.isPast === true && el.isUpcoming === false,
    );
    setOneonOnePast(pastOneOnOne);

    const upcomingOneOnOne = contribs.filter(
      el => el.type === ContributionType.contributionOneToOne && el.isUpcoming === true && el.isPast === false,
    );
    setOneonOneUpcoming(upcomingOneOnOne);

    const pastGroupCourse = contribs.filter(
      el =>
        el.type === ContributionType.contributionCourse &&
        el.isPast === true &&
        el.isUpcoming === false &&
        el.isWorkshop === false,
    );
    setcontributionCoursePast(pastGroupCourse);

    const upcomingGroupCourse = contribs.filter(
      el =>
        el.type === ContributionType.contributionCourse &&
        el.isUpcoming === true &&
        el.isPast === false &&
        el.isWorkshop === false,
    );
    setcontributionCourseUpcoming(upcomingGroupCourse);
    const upcomingWorkshop = contribs.filter(
      el =>
        el.type === ContributionType.contributionCourse &&
        el.isUpcoming === true &&
        el.isPast === false &&
        el.isWorkshop === true,
    );
    setcontributionWorkshopUpcoming(upcomingWorkshop);
    const pastWorkshop = contribs.filter(
      el =>
        el.type === ContributionType.contributionCourse &&
        el.isPast === true &&
        el.isUpcoming === false &&
        el.isWorkshop === true,
    );
    setcontributionWorkshopPast(pastWorkshop);
  };

  return (
    <Switch>
      <Route
        path={`${path}/${JOURNEY_ROUTES.SESSIONS}`}
        component={() => (
          <SessionsRouter
            // selfpacedSessions={selfpacedSessions}
            // upcomingNotBooked={upcomingNotBooked}
            // upcomingContributions={upcomingContributions}
            // pastContributions={pastContributions}
            // liveupcomingContributions={liveupcomingContributions}
            // selfpacedupcomingContributions={selfpacedupcomingContributions}
            // livepastContributions={livepastContributions}
            // selfpacedpastContributions={selfpacedpastContributions}
            // livepastSessions={livepastSessions}
            bookable={bookable}
            upcoming={upcoming}
            past={past}
            modules={modules}
            inCompleted={inCompleted}
            inCompletedlive={inCompletedlive}
            inCompletedselfpaced={inCompletedselfpaced}
            upcominglive={upcominglive}
            upcomingselfpaced={upcomingselfpaced}
          />
        )}
      />
      <Route path={`${path}/${JOURNEY_ROUTES.COACHES}`} component={() => <CoachesList coaches={coaches} />} />
      <Route
        path={`${path}/${JOURNEY_ROUTES.MEMBERSHIP}`}
        component={() => (
          <ContributionRouter upcomingContributions={membershipUpcoming} pastContributions={membershipPast} />
        )}
      />
      <Route
        path={`${path}/${JOURNEY_ROUTES.ONE_TO_ONE}`}
        component={() => (
          <ContributionRouter upcomingContributions={OneonOneUpcoming} pastContributions={OneonOnePast} />
        )}
      />
      <Route
        path={`${path}/${JOURNEY_ROUTES.LIVE}`}
        component={() => (
          <ContributionRouter
            upcomingContributions={contributionCourseUpcoming}
            pastContributions={contributionCoursePast}
          />
        )}
      />
      <Route
        path={`${path}/${JOURNEY_ROUTES.WORKSHOP}`}
        component={() => (
          <ContributionRouter
            upcomingContributions={contributionWorkshopUpcoming}
            pastContributions={contributionWorkshopPast}
          />
        )}
      />
      <Redirect to={`${url}/${JOURNEY_ROUTES.SESSIONS}`} />
    </Switch>
  );
};

const contributionsPropType = PropTypes.arrayOf(PropTypes.instanceOf(ClientJourneyContribution)).isRequired;

const contributionsListPropType = PropTypes.shape({
  otherIncompleted: contributionsPropType,
  today: contributionsPropType,
  thisWeek: contributionsPropType,
  thisMonth: contributionsPropType,
  nextMonth: contributionsPropType,
  thisYear: contributionsPropType,
  afterThisYear: contributionsPropType,
});

ClientJourneyRouter.propTypes = {
  coaches: PropTypes.array.isRequired,
  upcomingContributions: contributionsListPropType.isRequired,
  pastContributions: contributionsListPropType.isRequired,
  membershipsUpcoming: contributionsListPropType.isRequired,
  membershipsPast: contributionsListPropType.isRequired,
  oneToOneUpcoming: contributionsListPropType.isRequired,
  oneToOnePast: contributionsListPropType.isRequired,
  liveUpcoming: contributionsListPropType.isRequired,
  livePast: contributionsListPropType.isRequired,
};

export default ClientJourneyRouter;
