import React from 'react'
import PropTypes from 'prop-types'
import IconButton from '@material-ui/core/IconButton'
import MicIcon from '@material-ui/icons/Mic'
import MicOffIcon from '@material-ui/icons/MicOff'

import ToolbarAction from '../ToolbarAction'
import AudioDevicesList from './DevicesList/AudioDevicesList'

const Microphone = ({
  selectedAudioInputDevice,
  onSelectedAudioInputDeviceChange,
  selectedAudioOutputDevice,
  onSelectedAudioOutputDeviceChange,
  enabled,
  onEnabledChange,
}) => {
  const MicrophoneIcon = enabled ? MicIcon : MicOffIcon

  return (
    <ToolbarAction
      label={`Mic: ${enabled ? 'On' : 'Off'}`}
      endAdornment={
        enabled && (
          <AudioDevicesList
            selectedAudioInputDevice={selectedAudioInputDevice}
            onSelectedAudioInputDeviceChange={onSelectedAudioInputDeviceChange}
            selectedAudioOutputDevice={selectedAudioOutputDevice}
            onSelectedAudioOutputDeviceChange={onSelectedAudioOutputDeviceChange}
          />
        )
      }
    >
      <IconButton color="inherit" onClick={() => onEnabledChange(!enabled)}>
        <MicrophoneIcon />
      </IconButton>
    </ToolbarAction>
  )
}

Microphone.propTypes = {
  selectedAudioInputDevice: PropTypes.string,
  onSelectedAudioInputDeviceChange: PropTypes.func.isRequired,
  selectedAudioOutputDevice: PropTypes.string,
  onSelectedAudioOutputDeviceChange: PropTypes.func.isRequired,
  enabled: PropTypes.bool.isRequired,
  onEnabledChange: PropTypes.func.isRequired,
  audioMediaStreamTrack: PropTypes.shape({}),
  localAudioTrack: PropTypes.shape({}),
}

Microphone.defaultProps = {
  selectedAudioInputDevice: null,
  audioMediaStreamTrack: undefined,
  localAudioTrack: undefined,
}

export default Microphone
