import { useEffect, useState } from 'react';
import { getApplicationResponseCount } from 'services/applications.service';

export const usePendingApplications = () => {
  const [pendingCount, setPendingCount] = useState(0);

  useEffect(() => {
    getApplicationResponseCount().then(res => {
      setPendingCount(res);
    });
  }, []);

  return {
    pendingCount,
  };
};
