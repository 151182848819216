import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import './CourseSession.scss';
import ProgressBar from '@ramonak/react-progress-bar';
import styled, { css } from 'styled-components';
import StyledButton from 'components/FormUI/Button';
import MiniDrawer from '../component/RightMiniDrawer';
import LiveSessionBody from '../component/LiveSessionComponents/LiveSessionBody';
// import SelfPacedSessionBody from '../component/SelfPacedComponents/SelfPacedSessionBody';

const ContentDetailTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: baseline;
  border-bottom: 1px solid black;
`;

const ContentDetailBody = styled.div`
  display: flex;
  align-items: baseline;
`;

const CourseSessionRedesign = ({ progressbarColor, percentageCompleted, sessions }) => {
  const sessionTime = sessions ? sessions[2].sessionTimes[0] : [];
  const [openDrawer, setOpenDrawer] = useState(false);
  return (
    <>
      <Grid item container style={{ height: '1300', border: '1px solid black', background: 'white' }}>
        <Grid lg={3} style={{ borderRight: '1px solid black' }}>
          <div style={{ width: '100%', padding: '10px 24px', borderBottom: '1px solid black' }}>
            <div
              style={{ display: 'flex', justifyContent: 'space-between', color: progressbarColor, fontWeight: '900' }}
            >
              <p style={{ marginBottom: '7px' }}>Completion Progress </p>
              <p style={{ color: progressbarColor }}>{percentageCompleted}%</p>
            </div>
            <ProgressBar completed={percentageCompleted} bgColor={progressbarColor} />
          </div>
          <div style={{ width: '100%', padding: '10px 24px' }}>sessions details</div>
        </Grid>
        <Grid lg={openDrawer ? 6 : 8}>
          {sessions ? (
            <>
              {' '}
              <LiveSessionBody
                session={sessions[0]}
                sessionTime={sessions[0].sessionTimes[0]}
                index={0}
              ></LiveSessionBody>
              <div> self paced</div>
              {/* <SelfPacedSessionBody session={sessions[2]} sessionTime={sessionTime} sessionTimeIndex={0} />{' '} */}
            </>
          ) : (
            <>
              <ContentDetailTitle>session title</ContentDetailTitle>
              <ContentDetailBody>session info</ContentDetailBody>
            </>
          )}
        </Grid>
        <Grid lg={openDrawer ? 3 : 1} style={{ borderLeft: '1px solid black' }}>
          <MiniDrawer open={openDrawer} setOpen={setOpenDrawer} />
        </Grid>
      </Grid>
    </>
  );
};

export default CourseSessionRedesign;
