import React from 'react';

const ExportIcon = ({ width = 16, height = 16, disabled = false, ...rest }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 15 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.9709 5.49935L12.3042 2.83268V4.83268H6.3042V6.16601H12.3042V8.16601M0.304199 9.49934V1.49935C0.304199 1.14573 0.444675 0.806588 0.694724 0.55654C0.944772 0.306491 1.28391 0.166016 1.63753 0.166016H9.63753C9.99116 0.166016 10.3303 0.306491 10.5803 0.55654C10.8304 0.806588 10.9709 1.14573 10.9709 1.49935V3.49935H9.63753V1.49935H1.63753V9.49934H9.63753V7.49935H10.9709V9.49934C10.9709 9.85297 10.8304 10.1921 10.5803 10.4422C10.3303 10.6922 9.99116 10.8327 9.63753 10.8327H1.63753C1.28391 10.8327 0.944772 10.6922 0.694724 10.4422C0.444675 10.1921 0.304199 9.85297 0.304199 9.49934Z"
        fill={disabled ? 'rgba(0, 0, 0, 0.26)' : '#215C73'}
      />
    </svg>
  );
};

export default ExportIcon;
