import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { Route, Redirect, Switch } from 'react-router-dom';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useTheme, useMediaQuery, makeStyles } from '@material-ui/core';
import WebsitePage from 'pages/Account/Website/WebsitePage';
import Payment from 'pages/Account/Payment/Payment';
import Affiliate from 'pages/Account/Affiliate/Affiliate';
import { IntegrationsTab } from 'pages/Account/IntegrationsTab/IntegrationsTab';
import Notifications from 'pages/Account/Notifications/Notifications';
import { Video } from 'pages/Account/Video/Video';
import BillingPage from 'pages/Account/Billing/BillingPage';
import CreateCoupon from 'pages/Account/Payment/CouponPage';
import ConditionalRenderBillingPage from 'pages/Account/CohealerBilling/ConditionalRenderBillingPage';
import BillingButtons from 'pages/Account/CohealerBilling/DefaultBillingView/BillingButtons';

import { ProfilePage, ClientProfilePage } from 'pages';

import AccountHeader from 'components/UI/AccountHeader';
import Can from 'components/Auth/Can';

import { UserRoles } from 'helpers/constants';

import * as userActions from 'actions/user';
import { useHeader, useRouter } from 'hooks';
import { colors } from 'utils/styles';
import MainContainer from './MainContainer';
import { TaxInfo } from '../TaxInfo/TaxInfo';
import { ROUTES } from '../../constants';
import AccountMemberships from '../../pages/Account/Memberships';
import BillingRoleSwitch from 'pages/Account/CohealerBilling/BillingRoleSwitch';
import ConditionalRenderBilingSixMonthPage from 'pages/Account/CohealerBilling/ConditionalRenderBilingSixMonthPage';

const StyledMainContainer = styled.div`
  background-color: white;
  height: 100%;
  min-width: 320px;
`;

const StyledMainSection = styled.div`
  ${({ showPadding }) => !showPadding && `padding: 30px 15px 30px 25px;`}

  ${({ mobileView }) => mobileView && `padding: 16px;`}
`;

const useStyles = makeStyles(theme => ({
  billingTabBackground: {
    background: colors.lightGray,
    [theme.breakpoints.down('xs')]: {
      background: 'white',
    },
  },
}));

function AccountContainer({ userId, getUserProfile, match: { path } }) {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'));
  useHeader('My Account');

  const { pathname } = useRouter();
  const classes = useStyles();

  useEffect(() => {
    if (userId) {
      getUserProfile(userId);
    }
  }, [userId, getUserProfile]);

  const renderCohealerRoutes = useCallback(
    () => (
      <Switch>
        <Route path={`${path}/profile`} exact component={ProfilePage} />
        <Route path={`${path}/`} exact component={ProfilePage} />
        <Route path={`${path}/website`} exact component={WebsitePage} />
        <Route path={`${path}/payment`} exact component={Payment} />
        <Route path={`${path}/payment/createCoupon`} exact component={CreateCoupon} />
        <Route path={`${path}/payment/editCoupon`} exact component={CreateCoupon} />
        <Route path={`${path}/billing/coach`} exact component={BillingButtons} />
        <Route path={`${path}/billing/six-month`} exact component={ConditionalRenderBilingSixMonthPage} />
        <Route path={`${path}/billing/coach/extended`} exact component={ConditionalRenderBillingPage} />
        <Route path={`${path}/tax-info`} exact component={TaxInfo} />
        <Route path={`${path}/notifications`} exact component={Notifications} />
        <Route path={`${path}/video`} exact component={Video} />
        <Route path={`${path}/integrations`} exact component={IntegrationsTab} />
        {/* <Route path={`${path}/affiliate`} exact component={Affiliate} /> */}
        <Redirect to={`${path}/profile`} />
      </Switch>
    ),
    [path],
  );
  const renderClientRoutes = useCallback(
    () => (
      <Switch>
        <Route path={`${path}/profile`} exact component={ClientProfilePage} />
        <Route path={`${path}/billing`} exact component={BillingPage} />
        <Route path={`${path}/billing/coach`} exact component={BillingRoleSwitch} />
        <Route path={`${path}/billing/six-month`} exact render={() => <Redirect to={`${path}/billing`} />} />
        <Route path={`${path}/billing/coach/extended`} exact component={BillingRoleSwitch} />
        <Route path={`${path}/notifications`} exact component={Notifications} />
        <Route path={`${path}/video`} exact component={Video} />
        <Route path={`${path}/memberships`} exact component={AccountMemberships} />
        <Redirect to={`${path}/profile`} />
      </Switch>
    ),
    [path],
  );
  return (
    <MainContainer>
      <StyledMainContainer>
        <AccountHeader />
        <StyledMainSection
          showPadding={pathname.includes('account/website') || pathname.includes('account/profile')}
          mobileView={mobileView}
          className={pathname.includes(ROUTES.ACCOUNT_BILLING) && classes.billingTabBackground}
        >
          <Can roleName={UserRoles.cohealer} yes={renderCohealerRoutes} />
          <Can roleName={UserRoles.client} yes={renderClientRoutes} />
        </StyledMainSection>
      </StyledMainContainer>
    </MainContainer>
  );
}

AccountContainer.propTypes = {
  userId: PropTypes.string,
  getUserProfile: PropTypes.func.isRequired,
  match: PropTypes.shape({
    path: PropTypes.string,
    url: PropTypes.string,
    isExact: PropTypes.bool,
    params: PropTypes.object,
  }).isRequired,
};

AccountContainer.defaultProps = {
  userId: null,
};

const mapStateToProps = ({ account }) => ({
  userId: account?.user?.id,
});

const actions = {
  getUserProfile: userActions.getProfile,
};

export default connect(mapStateToProps, actions)(AccountContainer);
