import React from 'react';
import styled from 'styled-components';
import { toRem, colors, devices } from 'utils/styles';
import { NavLink } from 'react-router-dom';
import { useTheme, useMediaQuery } from '@material-ui/core';

import { useRouter } from 'hooks';
import { JOURNEY_ROUTES } from 'helpers/constants';
import { StyledClientJourneyHeaderWrapper } from '../ClientJourneyHeader';

const StyledClientJourneyHeaderContainer = styled.div`
  // padding: 0 16px;
  margin: 0 auto;

  ${({ mobileView }) => mobileView && `padding-left: 0px;`}

  ${({ JourneySubHeader }) =>
    JourneySubHeader &&
    `
    height: 60px;
    display: flex;
    justify-content: left;
    align-items: center; 
  `}
`;

const StyledClientJourneySubHeaderWrapper = styled(StyledClientJourneyHeaderWrapper)`
  border: none;
`;
export const StyledNavLink = styled(NavLink)`
  text-decoration: none;
  font-size: ${toRem(16)};
  line-height: 1;
  font-weight: 900;
  width: 122px;
  color: ${colors.darkGray};
  padding: 16px;
  display: inline-block;
  position: relative;

  ${({ mobileView }) => {
    return (
      mobileView &&
      `
        padding: 16px 15px;
      `
    );
  }}

  ${({ firstLink }) => firstLink && `padding-left: 16px;`}

  ${({ JourneySubHeader }) =>
    JourneySubHeader &&
    `
    border-radius: 4px;
    margin-right: 10px;
    padding: 8px 12px 8px 12px;
    width: 122px;
    height: 34px;
    text-align: center;
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 900;
    font-size: 14px;
    line-height: 130%;
    color: ${colors.lightBrown};
    background-color: white;
    border: 1px solid ${colors.lightBrown};
    &:hover {
      color: ${colors.lightBrown};
      }

    &.active {
      color: white;
      background-color: ${colors.lightBrown};
      border: none;

      &:hover {
        color: white;
        }
    }

  `}
`;

const ClientJourneyHeader = ({ subUrl }) => {
  const { match, pathname } = useRouter();
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <StyledClientJourneySubHeaderWrapper mobileView={mobileView}>
      <StyledClientJourneyHeaderContainer mobileView={mobileView} JourneySubHeader>
        {pathname.includes(JOURNEY_ROUTES.SESSIONS) && (
          <StyledNavLink to={`${match.url}/${subUrl}/allsessions`} mobileView={mobileView} JourneySubHeader>
            All
          </StyledNavLink>
        )}
        {pathname.includes(JOURNEY_ROUTES.SESSIONS) && (
          <StyledNavLink to={`${match.url}/${subUrl}/livesessions`} mobileView={mobileView} JourneySubHeader>
            Live
          </StyledNavLink>
        )}
        {pathname.includes(JOURNEY_ROUTES.SESSIONS) && (
          <StyledNavLink to={`${match.url}/${subUrl}/selfpacedsessions`} mobileView={mobileView} JourneySubHeader>
            {mobileView ? 'SelfPaced' : 'Self-Paced'}
          </StyledNavLink>
        )}
        {pathname.includes(JOURNEY_ROUTES.SESSIONS) && (
          <StyledNavLink to={`${match.url}/${subUrl}/pastsessions`} mobileView={mobileView} JourneySubHeader>
            Past
          </StyledNavLink>
        )}

        {/* <StyledNavLink to={`${match.url}/${subUrl}/upcomingsessions`} mobileView={mobileView} firstLink>
        Upcoming
      </StyledNavLink> */}
        {!pathname.includes(JOURNEY_ROUTES.SESSIONS) && (
          <StyledNavLink to={`${match.url}/${subUrl}/all`} mobileView={mobileView} JourneySubHeader>
            All Purchases
          </StyledNavLink>
        )}

        {!pathname.includes(JOURNEY_ROUTES.SESSIONS) && (
          <StyledNavLink to={`${match.url}/${subUrl}/upcoming`} mobileView={mobileView} JourneySubHeader>
            Upcoming
          </StyledNavLink>
        )}
        {!pathname.includes(JOURNEY_ROUTES.SESSIONS) && (
          <StyledNavLink to={`${match.url}/${subUrl}/past`} mobileView={mobileView} JourneySubHeader>
            Past
          </StyledNavLink>
        )}
      </StyledClientJourneyHeaderContainer>
    </StyledClientJourneySubHeaderWrapper>
  );
};

export default ClientJourneyHeader;
