import React from 'react'
import PropTypes from 'prop-types'
import Modal from 'components/UI/Modal'
import moment from 'moment'

import { useHttp, useVideoChatActions } from 'hooks'
import { useRecordModal } from 'hooks/useSessionRecordModal'

const sessionDateFormat = 'MMMM Do YYYY hh:mm A'
const sessionTimeFormat = 'hh:mm A'

const LaunchSessionTimeModal = ({
  user,
  open,
  onOpenChange,
  contribution,
  sessionTime: { id, title, start, end, participantId },
}) => {
  const { request, loading } = useHttp()
  const { startVideoChat } = useVideoChatActions()

  const onLaunchSession = ({ RecordParticipantsOnConnect }) => {
    request('/Video/CreateRoomAndGetToken', 'POST', {
      contributionId: contribution.id,
      classId: id,
      RecordParticipantsOnConnect,
      identityName: `${user.firstName} ${user.lastName}`,
    }).then(({ room: { sid: roomId }, token }) => {
      onOpenChange(false)
      startVideoChat({
        contributionId: contribution.id,
        type: contribution.type,
        classId: id,
        roomId,
        chatId: contribution.chat.cohealerPeerChatSids[participantId],
        token,
        deleteRoomOnVideoEnd: true,
      })
    })
  }

  const onSubmit = () => {
    onLaunchSession({ RecordParticipantsOnConnect: true })
  }
  const onCancel = () => {
    onLaunchSession({ RecordParticipantsOnConnect: false })
  }
  const modalOptions = {
    onSubmit,
    onCancel,
    classId: id,
    contributionType: contribution.type,
    contributionId: contribution.id,
    contributionLiveVideoServiceProvider: contribution.liveVideoServiceProvider,
  }
  const { RecordModal, launchRecodModal } = useRecordModal(modalOptions)

  return (
    <>
      <Modal
        isOpen={open}
        title="Launch 1:1 session"
        submitTitle="Launch session"
        onCancel={() => onOpenChange(false)}
        onSubmit={launchRecodModal}
        loading={loading}
      >
        <p>
          Please, launch session for {title} that is scheduled on {moment(start).format(sessionDateFormat)} -{' '}
          {moment(end).format(sessionTimeFormat)}
        </p>
      </Modal>
      <RecordModal />
    </>
  )
}

LaunchSessionTimeModal.propTypes = {
  user: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }).isRequired,
  open: PropTypes.bool.isRequired,
  onOpenChange: PropTypes.func.isRequired,
  contribution: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
    chat: PropTypes.shape({
      cohealerPeerChatSids: PropTypes.shape({}),
    }),
  }).isRequired,
  sessionTime: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    start: PropTypes.instanceOf(Date),
    end: PropTypes.instanceOf(Date),
    participantId: PropTypes.string,
  }).isRequired,
}

export default LaunchSessionTimeModal
