import React from 'react';
import { useSelector } from 'react-redux';
import { Grid, Input, Typography } from '@material-ui/core';

import Button from 'components/FormUI/Button';
import Loader from 'components/UI/Loader';
import useContribution from 'pages/ContributionView/hooks/useContribution';
import { memberPodsSelector } from 'selectors/MemberPods';
import { determineColorToUse } from 'services/contributions.service';
import { MemberPodItem } from '../MemberPodItem/MemberPodItem';

import { useStyles } from './hooks/useStyles';
import { useHandlers } from './hooks/useHandlers';

export const MemberPodsCreation = () => {
  const classNames = useStyles();
  const { contributionMemberPods, error, isFetching, newPodName } = useSelector(memberPodsSelector);
  const { id: contributionId } = useContribution();
  const contribution = useContribution();
  const handlers = useHandlers();
  const data = contributionMemberPods?.[contributionId];
  let colorToUse = determineColorToUse(contribution);

  if (isFetching) {
    return <Loader />;
  }

  if (error) {
    return <Typography>Error loading data.</Typography>;
  }

  if (!Array.isArray(data)) {
    return <Typography>No data.</Typography>;
  }

  return (
    <>
      <Grid className={classNames.podsList} container direction="column" justify="space-between">
        {data.map(({ clientIds, id, name }) => (
          <MemberPodItem
            clientIds={clientIds}
            initialValue={name}
            key={id}
            onDelete={handlers.onDeletePod}
            onUpdate={handlers.onUpdatePodName}
            podId={id}
          />
        ))}
      </Grid>
      <Grid container direction="column">
        <Input onChange={handlers.onChangeNewPodName} placeholder="New Group Name" type="text" value={newPodName} />
        <Button
          className={classNames.createButton}
          disabled={!newPodName?.trim()}
          invert
          onClick={handlers.onCreateMemberPod}
          style={{ backgroundColor: !newPodName?.trim() ? '#ededed' : colorToUse?.PrimaryColorCode }}
        >
          Create Cohorts
        </Button>
      </Grid>
    </>
  );
};
