import React, { useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import classnames from 'classnames';
import editImage from 'assets/editLinkImage.png';
import Loader from 'components/UI/Loader';
import { useHttp } from 'hooks';
import * as userActions from 'actions/user';

import './ThumbnailAvatar.scss';

export const ThumbnailAvatar = ({ avatarUrl, className, updateUserImg, avatarPlaceholder }) => {
  const dispatch = useDispatch();
  const { request, loading } = useHttp();
  const backgroundElementRef = useRef(null);

  const handleUploadFile = useCallback(
    ({ target: { files } }) => {
      const formData = new FormData();

      formData.append('file', R.head(files));

      request('/Content/addpublicimage', 'POST', formData, {
        'Content-Type': 'multipart/form-data',
      })
        .then(res => {
          updateUserImg(res);
        })
        .catch(console.dir);
      // .finally(updateUserImg);
    },
    [dispatch, request, updateUserImg],
  );

  if (loading) {
    return <Loader />;
  }

  const handleClick = () => {
    // dispatch a click event to the background element
    backgroundElementRef.current.click();
  };

  return (
    <>
      <div className="thumbnail-avatar-container">
        <div
          style={{
            backgroundImage: `url(${avatarUrl || avatarPlaceholder})`,
          }}
          className="thumbnail-avatar"
        >
          <input
            type="file"
            accept="image/*"
            className="w-100 h-100 cursor-pointer thumbnail-input-avatar"
            onChange={handleUploadFile}
            ref={backgroundElementRef}
          />
        </div>
      </div>
      {avatarUrl && (
        <span style={{ backgroundImage: `url(${editImage})` }} className="thumbnail-change" onClick={handleClick} />
      )}
    </>
  );
};

ThumbnailAvatar.propTypes = {
  avatarUrl: PropTypes.string,
  className: PropTypes.string,
  avatarPlaceholder: PropTypes.string,
};

ThumbnailAvatar.defaultProps = {
  avatarUrl: '',
  avatarPlaceholder: '',
  className: null,
};
