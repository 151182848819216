import { useEffect, useState } from 'react';

const useAudioTrack = audioTrack => {
  const [isEnabled, setIsEnabled] = useState(!!audioTrack && audioTrack.isEnabled);

  useEffect(() => {
    if (audioTrack) {
      const onTrackEnabled = () => {
        console.log(
          `${audioTrack.id ? 'Local' : 'Remote'} Audio track (name: ${audioTrack.name}) is enabled (unmuted).`,
        );
        setIsEnabled(true);
      };
      const onTrackDisabled = () => {
        console.log(
          `${audioTrack.id ? 'Local' : 'Remote'} Audio track (name: ${audioTrack.name}) is disabled (muted).`,
        );
        setIsEnabled(false);
      };

      setIsEnabled(audioTrack.isEnabled);

      audioTrack.on('enabled', onTrackEnabled);
      audioTrack.on('disabled', onTrackDisabled);

      return () => {
        audioTrack.off('enabled', onTrackEnabled);
        audioTrack.off('disabled', onTrackDisabled);
      };
    } else {
      setIsEnabled(false);
    }
  }, [audioTrack]);

  return {
    audioTrack,
    isEnabled,
  };
};

export default useAudioTrack;
