import React from 'react';
import { useAccount, useHttp } from 'hooks';
import { useMediaQuery } from '@material-ui/core';
import styled from 'styled-components';

import Button from 'components/FormUI/Button';
import { redirectTo } from 'services/links';

import '../Banner.scss';

const StyledButton = styled(Button)`
  background-color: #0e536b;
  padding: 6px 14px;
  min-width: 95px;
  width: 95px;
  font-size: 12px;
  font-weight: 700;
`;

const SocialSecurityInfo = () => {
  const { user } = useAccount();
  const { request } = useHttp();

  const mobileView = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const BannerButton = mobileView ? StyledButton : Button;

  if (user.isSocialSecurityCheckPassed) {
    return null;
  }

  const onClick = async () => {
    request('api/Payment/generate-account-verification-link', 'POST').then(({ link }) => {
      redirectTo(link);
    });
  };

  return (
    <div className={`banner-container ${mobileView && 'banner-container__mobile'}`}>
      <p className="banner-container__text">
        Additional information is required to accept Client payments of $1,500 or greater.
      </p>
      <BannerButton variant="primary" className="banner-container__button" autoWidth onClick={onClick}>
        Add Info
      </BannerButton>
    </div>
  );
};

export default SocialSecurityInfo;
