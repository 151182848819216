import React, { useCallback, useState, useRef, useEffect } from 'react';
import { useHttp } from 'hooks';
import Modal from 'components/UI/Modal';
import * as Yup from 'yup';
import Select from 'components/FormUI/Select';
import Input from 'components/FormUI/Input';
import { Formik, Form } from 'formik';
import CommonErrorMessage from 'components/FormUI/CommonErrorMessage';
import Grid from '@material-ui/core/Grid';
import { useStripe } from '@stripe/react-stripe-js';
import * as countryActions from 'actions/country';
import { connect } from 'react-redux';

const AddBankAccountPopup = ({
  addStripeBankAccountDataPopup,
  onCancel,
  countryLoading,
  countries,
  fetchCountries,
  type,
}) => {
  const stripe = useStripe();
  const [errors, setErrors] = useState(null);
  const { request } = useHttp();

  useEffect(() => {
    if ((!countries || countries.length === 0) && !countryLoading) {
      fetchCountries();
    }
  }, [countries, fetchCountries]);
  const [countryValue, setCountryValue] = React.useState('US');
  const [accountNumberLabel, setAccountNumberLabel] = React.useState('Account Number');
  const [accountNumberValue, setAccountNumberValue] = React.useState('');
  const [routingNumberLabel, setRoutingNumberLabel] = React.useState('Routing Number');
  const [routingNumberValue, setRoutingNumberValue] = React.useState('');
  const [branchCodeLabel, setBranchCodeLabel] = React.useState('Branch code');
  const [branchCodeValue, setBranchCodeValue] = React.useState('');
  const [showBranchCode, setShowBranchCode] = React.useState(false);
  const [showRoutingNumber, setShowRoutingNumber] = React.useState(true);
  const accountNumberCountries = [
    'AU',
    'BO',
    'CL',
    'CO',
    'PY',
    'UY',
    'BR',
    'DO',
    'SG',
    'TT',
    'CA',
    'HK',
    'IN',
    'ID',
    'TH',
    'JP',
    'MY',
    'GB',
    'US',
  ];

  const handleCountryChange = e => {
    handleAccountNumber(e.target.value);
    handleRoutingNumber(e.target.value);
    handleBranchCode(e.target.value);
    setCountryValue(e.target.value);
  };

  const handleAccountNumber = newCountry => {
    setAccountNumberValue('');
    if (accountNumberCountries.includes(newCountry)) {
      setAccountNumberLabel('Account Number');
    } else if (newCountry == 'PE') {
      setAccountNumberLabel('CCI');
    } else if (newCountry == 'MX') {
      setAccountNumberLabel('CLABE');
    } else if (newCountry == 'AR') {
      setAccountNumberLabel('CBU');
    } else {
      setAccountNumberLabel('IBAN');
    }
  };

  const handleRoutingNumber = newCountry => {
    setRoutingNumberValue('');
    let showRoutingNumber = true;
    switch (newCountry) {
      case 'AU':
        setRoutingNumberLabel('BSB');
        break;
      case 'AR':
      case 'BO':
      case 'CL':
      case 'CO':
      case 'PY':
      case 'UY':
      case 'BR':
      case 'DO':
      case 'SG':
      case 'TT':
      case 'ID':
      case 'JP':
      case 'TH':
        setRoutingNumberLabel('Bank code');
        break;
      case 'CA':
        setRoutingNumberLabel('Transit number');
        break;
      case 'EG':
        setRoutingNumberLabel('BIC');
        break;
      case 'HK':
        setRoutingNumberLabel('Clearing Code');
        break;
      case 'IN':
        setRoutingNumberLabel('IFSC Code');
        break;
      case 'GB':
        setRoutingNumberLabel('Sort Code');
        break;
      case 'US':
        setRoutingNumberLabel('Routing Number');
        break;
      default:
        showRoutingNumber = false;
        break;
    }
    setShowRoutingNumber(showRoutingNumber);
  };

  const handleBranchCode = newCountry => {
    setBranchCodeValue('');
    let showBranchCode = true;
    if (['BR', 'DO', 'SG', 'TT', 'HK', 'JP'].includes(newCountry)) {
      setBranchCodeLabel('Branch code');
    } else if (newCountry == 'CA') {
      setBranchCodeLabel('Institution number');
    } else {
      showBranchCode = false;
    }
    setShowBranchCode(showBranchCode);
  };

  const attachExternalAccount = useCallback(
    stripeToken =>
      request(
        '/api/Payment/attach-external-account',
        'POST',
        type === 'affiliate' || type === 'affiliatetab' ? { stripeToken, forAffiliatePurpose: true } : { stripeToken },
        {
          'Content-Type': 'application/json',
        },
      ),
    [request],
  );

  //use this form https://gist.github.com/adamjstevenson/bb1698728ba3b36c8038ebe2796a0253/stargazers
  return (
    <Modal
      isOpen={addStripeBankAccountDataPopup}
      onCancel={async () => await onCancel()}
      form="bank-account-form"
      title="Bank Account Details"
      footer={
        <Grid item style={{ fontWeight: 600 }}>
          We take your security and privacy seriously.
          <br />
          Your information is protected by data encryption and is securely stored.
        </Grid>
      }
    >
      <>
        {errors && <CommonErrorMessage message={errors}></CommonErrorMessage>}
        <Formik
          initialValues={{
            accountNumber: '',
            country: 'US',
            accountHolderType: 'individual',
            routingNumber: '',
            currency: 'USD',
          }}
          validationSchema={Yup.object().shape({
            accountNumber: Yup.string().required('This is a required field'),
            // routingNumber: Yup.string()
            //   .required('This is a required field')
            //   .length(9, 'Routing number must have 9 digits'),
          })}
          onSubmit={async (values, actions) => {
            setErrors(null);
            var requestData = {
              ...values,
              account_number: values.accountNumber,
              account_holder_type: values.accountHolderType,
              account_holder_name: values.accountHolderName,
            };
            if (routingNumberValue) {
              if (['BR', 'CA', 'SG', 'HK'].includes(countryValue)) {
                requestData.routing_number = routingNumberValue + (branchCodeValue ? '-' + branchCodeValue : '');
              } else {
                requestData.routing_number = routingNumberValue + (branchCodeValue ? branchCodeValue : '');
              }
            } else {
              requestData.routing_number = undefined;
            }

            var createTokenResult = await stripe.createToken('bank_account', requestData);
            if (createTokenResult?.error) {
              setErrors(createTokenResult?.error.message);
            } else {
              try {
                await attachExternalAccount(createTokenResult.token.id);
              } catch (error) {
                setErrors(error.response.data.message || error.message);
                return;
              }
              await onCancel();
            }
          }}
        >
          {({ handleChange }) => (
            <Form id="bank-account-form">
              <Grid container>
                <Grid item xs={12}>
                  <Input
                    label={accountNumberLabel}
                    type="text"
                    name="accountNumber"
                    fullWidth
                    value={accountNumberValue}
                    onChange={e => {
                      setAccountNumberValue(e.target.value);
                      handleChange(e);
                    }}
                  />
                </Grid>
                {showRoutingNumber && (
                  <Grid item xs={12}>
                    <Input
                      label={routingNumberLabel}
                      type="text"
                      name="routingNumber"
                      fullWidth
                      value={routingNumberValue}
                      onChange={e => {
                        setRoutingNumberValue(e.target.value);
                        handleChange(e);
                      }}
                    />
                  </Grid>
                )}
                {showBranchCode && (
                  <Grid item xs={12}>
                    <Input
                      label={branchCodeLabel}
                      type="text"
                      name="branchCodeLabel"
                      fullWidth
                      value={branchCodeValue}
                      onChange={e => {
                        setBranchCodeValue(e.target.value);
                        handleChange(e);
                      }}
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Input label="Account Holder Name" type="text" name="accountHolderName" fullWidth />
                </Grid>
                <Grid item xs={12} style={{ marginTop: '26px' }}>
                  <Select
                    label="Account Holder Type"
                    name="accountHolderType"
                    fullWidth
                    items={[
                      { title: 'Individual', value: 'individual' },
                      { title: 'Company', value: 'company' },
                    ]}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Select
                    name="currency"
                    label="Currency"
                    fullWidth
                    items={[
                      { title: 'USD', value: 'USD' },
                      { title: 'AED', value: 'AED' },
                      { title: 'AUD', value: 'AUD' },
                      { title: 'BGN', value: 'BGN' },
                      { title: 'BRL', value: 'BRL' },
                      { title: 'CAD', value: 'CAD' },
                      { title: 'CHF', value: 'CHF' },
                      { title: 'DKK', value: 'DKK' },
                      { title: 'EUR', value: 'EUR' },
                      { title: 'GBP', value: 'GBP' },
                      { title: 'HKD', value: 'HKD' },
                      { title: 'HUF', value: 'HUF' },
                      { title: 'INR', value: 'INR' },
                      { title: 'JPY', value: 'JPY' },
                      { title: 'MXN', value: 'MXN' },
                      { title: 'MYR', value: 'MYR' },
                      { title: 'NOK', value: 'NOK' },
                      { title: 'NZD', value: 'NZD' },
                      { title: 'PLN', value: 'PLN' },
                      { title: 'RON', value: 'RON' },
                      { title: 'SEK', value: 'SEK' },
                      { title: 'SGD', value: 'SGD' },
                      { title: 'CZK', value: 'CZK' },
                      { title: 'ILS', value: 'ILS' },
                      { title: 'TWD', value: 'TWD' },
                      { title: 'PHP', value: 'PHP' },
                      { title: 'THB', value: 'THB' },
                    ]}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Select
                    label="Country"
                    name="country"
                    fullWidth
                    value={countryValue}
                    onChange={e => {
                      handleCountryChange(e);
                      handleChange(e);
                    }}
                    items={
                      countries?.length > 0
                        ? countries
                            .filter(c => c.stripeSupportedCountry)
                            .map(country => {
                              return { value: country.alpha2Code, title: country.name };
                            })
                        : []
                    }
                  />
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </>
    </Modal>
  );
};

const mapStateToProps = ({ country }) => ({
  countries: country?.countries,
  countryLoading: country?.loading,
});

const actions = {
  fetchCountries: countryActions.fetchCountries,
};

export default connect(mapStateToProps, actions)(AddBankAccountPopup);
