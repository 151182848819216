import React from 'react'
import PropTypes from 'prop-types'
import Modal from 'components/UI/Modal'
import moment from 'moment'
import { LiveVideoProvider } from 'constants.js'
import { openInNewWindow } from 'services/links'

import { useHttp, useVideoChatActions } from 'hooks'

const sessionDateFormat = 'MMMM Do YYYY hh:mm A'
const sessionTimeFormat = 'hh:mm A'

const JoinSessionTimeModal = ({
  user,
  open,
  onOpenChange,
  contribution,
  sessionTime: { id, title, start, end, serviceProviderName },
}) => {
  const { request, loading } = useHttp()
  const { startVideoChat } = useVideoChatActions()

  const onJoinSession = () => {
    if (
      contribution.liveVideoServiceProvider &&
      contribution.liveVideoServiceProvider.providerName != LiveVideoProvider.Cohere.value
    ) {
      openInNewWindow(contribution.liveVideoServiceProvider.customLink)
    } else {
      request('/Video/GetClientToken', 'POST', {
        contributionId: contribution.id,
        classId: id,
        identityName: `${user.firstName} ${user.lastName}`,
      }).then(({ token }) => {
        onOpenChange(false)
        startVideoChat({
          contributionId: contribution.id,
          type: contribution.type,
          classId: id,
          chatId: contribution.chat?.sid,
          token,
        })
      })
    }
  }

  return (
    <Modal
      isOpen={open}
      title="Join 1:1 session"
      submitTitle="Join session"
      onCancel={() => onOpenChange(false)}
      onSubmit={onJoinSession}
      loading={loading}
    >
      <p>
        Please, join session for {title} with {serviceProviderName} that is scheduled on{' '}
        {moment(start).format(sessionDateFormat)} - {moment(end).format(sessionTimeFormat)}
      </p>
    </Modal>
  )
}

JoinSessionTimeModal.propTypes = {
  user: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }).isRequired,
  open: PropTypes.bool.isRequired,
  onOpenChange: PropTypes.func.isRequired,
  contribution: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
    chat: PropTypes.shape({
      sid: PropTypes.string,
    }),
  }).isRequired,
  sessionTime: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    start: PropTypes.instanceOf(Date),
    end: PropTypes.instanceOf(Date),
    serviceProviderName: PropTypes.string,
  }).isRequired,
}

export default JoinSessionTimeModal
