import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledUl = styled.ul`
  list-style: none;
  padding-left: ${({ menubar }) => (menubar ? '0px' : '2rem')};
`;

function List({ menubar, children }) {
  return <StyledUl menubar={menubar}>{children}</StyledUl>;
}

List.propTypes = {
  children: PropTypes.node.isRequired,
};

export default List;
export { StyledUl };
