import { handleActions } from 'redux-actions';

import * as actions from 'actions/contributions';

const initialState = {
  loading: false,
  error: null,
  archived: [],
};

const archived = handleActions(
  new Map([
    [
      actions.fetchArchivedActions.request,
      state => ({
        ...state,
        error: null,
        loading: true,
        archived: null,
      }),
    ],
    [
      actions.fetchArchivedActions.success,
      (state, action) => ({
        ...state,
        loading: false,
        error: null,
        archived: action.payload,
      }),
    ],
    [
      actions.fetchArchivedActions.error,
      (state, action) => ({
        ...state,
        loading: false,
        error: action.payload,
        archived: [],
      }),
    ],
  ]),
  initialState,
);

export default archived;
