/* eslint-disable no-bitwise */
import { useCallback, useEffect, useState } from 'react';
import * as R from 'ramda';
import * as contentService from 'services/content.service';
import axios from 'axios';

export const FILE_UPLOAD_STATUSES = {
  inProgress: 'inProgress',
  completed: 'completed',
  error: 'error',
};

const FILE_UPLOAD_STATUS_DEFAULT_VALUE = {
  status: FILE_UPLOAD_STATUSES.inProgress,
  progress: 0,
  fileURL: '',
};

const MAX_FILES_SIZE_IN_BYTES = 5368706371;
const isFilesSizeLimitOrLess = R.compose(sum => sum <= MAX_FILES_SIZE_IN_BYTES, R.sum, R.map(R.prop('size')));
const guid = () => {
  let d = new Date().getTime();
  const id = 'xxxx-xxxx-xxxx-xxxx'.replace(/[xy]/g, c => {
    const r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    return (c === 'x' ? r : (r & 0x7) | 0x8).toString(16);
  });
  return id;
};

const useUploadResources = (contributionId, groupId, onFileUploadSuccess = () => {}) => {
  const [filesInfo, setFilesInfo] = useState({});
  const [filesUploadCompleted, setFilesUploadCompleted] = useState(true);

  const cancelAllRequestsToEndpoint = () => {
    const values = Object.values(filesInfo) || [];
    values.forEach(file => {
      file.cancelToken.cancel();
    });
  };

  useEffect(() => {
    const completedFiles = Object.values(filesInfo ?? [])?.filter(file => file.status === 'completed');
    if (completedFiles.length === Object.values(filesInfo).length) {
      setFilesUploadCompleted(true);
    }
  }, [filesInfo]);

  const cancelRequestToFileAtIndex = index => {
    const keys = Object.keys(filesInfo);
    const values = Object.values(filesInfo);
    values[index].cancelToken.cancel('');
    const newFilesInfo = { ...filesInfo };
    delete newFilesInfo[keys[index]];
    setFilesInfo({ ...newFilesInfo });
  };

  const updateFileInfo = useCallback(({ documentId, property, value }) => {
    setFilesInfo(prevData => {
      return {
        ...prevData,
        [documentId]: {
          ...prevData[documentId],
          [property]: value,
        },
      };
    });
  }, []);

  const handleUpdateProgress = useCallback(
    (documentId, partNumber, totalParts) => progressData => {
      const percentPerChunk = 100 / totalParts;

      updateFileInfo({
        documentId,
        property: 'progress',
        value: (progressData / 100) * percentPerChunk + percentPerChunk * (partNumber - 1),
      });
    },
    [updateFileInfo],
  );

  const handleFileStatus = useCallback(
    (documentId, status) => {
      updateFileInfo({
        documentId,
        property: 'status',
        value: status,
      });
    },
    [updateFileInfo],
  );

  const uploadPartFile = useCallback(
    (chunk, partNumber, totalParts, isLastPart, documentId, fileName, uploadId, prevETags, cancelToken) =>
      new Promise((resolve, reject) => {
        const formData = new FormData();
        formData.append('file', chunk);

        contentService
          .addResourceToContribution(
            formData,
            contributionId,
            partNumber,
            isLastPart,
            documentId,
            fileName,
            uploadId,
            prevETags,
            handleUpdateProgress(documentId, partNumber, totalParts),
            groupId,
            cancelToken,
          )
          .then(result => {
            resolve(result);
          })
          .then(result => {
            if (isLastPart) {
              handleFileStatus(documentId, FILE_UPLOAD_STATUSES.completed);
              onFileUploadSuccess(documentId);
            }
          })
          .catch(result => {
            if (!axios.isCancel(result)) {
              handleFileStatus(documentId, FILE_UPLOAD_STATUSES.error);
            }
            reject(result);
          });
      }),
    [contributionId, groupId, handleFileStatus, handleUpdateProgress],
  );

  const innerLoop = useCallback(
    async (file, documentId, cancelToken) => {
      const chunkSize = 26214400;
      const fileName = file.name;
      let uploadId = '';
      let prevETags = '';
      let partNumber = 1;
      for (let start = 0; start < file.size; start += chunkSize) {
        const isLastPart = start + chunkSize >= file.size;
        const chunk = file.slice(start, start + chunkSize);
        const totalParts = Math.ceil(file.size / chunkSize);
        const result = await uploadPartFile(
          chunk,
          partNumber,
          totalParts,
          isLastPart,
          documentId,
          fileName,
          uploadId,
          prevETags,
          cancelToken,
        );

        if (result) {
          uploadId = result.uploadId;
          prevETags = result.prevETags;
        }
        partNumber += 1;
      }
    },
    [uploadPartFile],
  );

  const handleUploadFile = useCallback(
    async files => {
      setFilesUploadCompleted(false);
      if (isFilesSizeLimitOrLess(files)) {
        const newFilesInfo = files.reduce(accumlatedValue => {
          const documentId = guid();
          const cancelToken = axios.CancelToken.source();
          return {
            ...accumlatedValue,
            [documentId]: { ...FILE_UPLOAD_STATUS_DEFAULT_VALUE, cancelToken },
          };
        }, {});
        setFilesInfo({
          ...filesInfo,
          ...newFilesInfo,
        });
        for (let i = 0; i < files.length; i += 1) {
          const file = files[i];
          const documentId = Object.keys(newFilesInfo)[i];
          const { cancelToken } = Object.values(newFilesInfo)[i];
          innerLoop(file, documentId, cancelToken);
        }
      }
    },
    [filesInfo, innerLoop],
  );

  return {
    handleUploadFile,
    filesInfo,
    cancelAllRequestsToEndpoint,
    cancelRequestToFileAtIndex,
    filesUploadCompleted,
  };
};

export default useUploadResources;
