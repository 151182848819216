import styled from 'styled-components';
import CloseIcon from '@material-ui/icons/Close';
import React, { useEffect, useState } from 'react';
import 'styles/varibles.scss';
import 'utils/styles/styles.scss';
import { devices } from 'utils/styles';
import { useTheme, useMediaQuery } from '@material-ui/core';
import useContribution from 'pages/ContributionView/hooks/useContribution';
import { getThemedColors } from 'services/contributions.service';

const PrevNextButton = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: transparent;
  border: none;
  font-size: 1.5rem;
  color: white;
  cursor: pointer;
  outline: none;

  background: #4a4a4a;
  opacity: 0.8;
  border-radius: 4px;
  padding: 0 10px;
  ${({ direction }) => (direction === 'left' ? 'left: 40px' : 'right: 40px')};
`;

const StyledImageNumber = styled.div`
  position: absolute;
  top: 95%;
  left: 48%;
  transform: translateY(-50%);
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;

  color: #ffffff;
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  line-height: 20px;

  background: #4a4a4a;
  opacity: 0.8;
  border-radius: 4px;

  padding: 10px 10px 10px 10px;
`;

const StyledPopupContainer = styled.div`
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #000000;

  @media screen and (${devices.mobile}) {
    background-color: rgba(0, 0, 0, 0.5);
  }
`;

const StyledPopupInnerContainer = styled.div` 
  position: fixed;
  z-index: 100;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #000000;
  width: 100%;
  max-height: 80%;
  overflow: auto;
  
  @media screen and (${devices.mobile}) { 
    border-radius: 7px;
    background-color:  white;
    padding: 45px 15px 15px 15px;
    width: auto;
    max-height: 80%;
    max-width: 90%;
`;

const StyledPopupImage = styled.img`
  margin: auto;
  display: block;
  width: 100%;
  max-height: 80%;

  @media screen and (${devices.mobile}) {
    border-radius: 7px;
    width: auto;
    max-width: 100%;
    max-height: 80%;
  }
`;

const Popup = ({ image, onClose, attachments = [] }) => {
  const contribution = useContribution();
  const [index, setIndex] = useState(attachments.findIndex(att => att.s3Link === image.s3Link));
  const [startX, setStartX] = useState(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const handlePrev = () => {
    setIndex(index === 0 ? attachments.length - 1 : index - 1);
  };

  const handleNext = () => {
    setIndex(index === attachments.length - 1 ? 0 : index + 1);
  };

  useEffect(() => {
    const handleKeyDown = event => {
      if (event.key === 'ArrowLeft' && index !== 0) {
        handlePrev();
      } else if (event.key === 'ArrowRight' && index !== attachments.length - 1) {
        handleNext();
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [index, attachments, handlePrev, handleNext]);

  const handleTouchStart = event => {
    setStartX(event.touches[0].clientX);
  };

  const handleTouchMove = event => {
    if (startX === null) {
      return;
    }

    const { clientX } = event.touches[0];
    const distance = clientX - startX;

    if (distance > 50) {
      handlePrev();
      setStartX(null);
    } else if (distance < -50) {
      handleNext();
      setStartX(null);
    }
  };

  const handleTouchEnd = () => {
    setStartX(null);
  };

  if (isMobile) {
    // for mobile view
    return (
      <StyledPopupContainer onTouchStart={handleTouchStart} onTouchMove={handleTouchMove} onTouchEnd={handleTouchEnd}>
        <StyledPopupInnerContainer>
          <StyledPopupImage src={attachments[index]?.s3Link || ''} />
        </StyledPopupInnerContainer>
        <CloseIcon onClick={onClose} style={{ position: 'absolute', top: 5, right: 5, color: 'white' }} />
      </StyledPopupContainer>
    );
  } else {
    // for web view
    return (
      <StyledPopupContainer>
        {attachments.length > 1 && index !== 0 ? (
          <PrevNextButton direction="left" onClick={handlePrev}>
            {'<'}
          </PrevNextButton>
        ) : null}
        {attachments.length > 1 && index !== attachments.length - 1 ? (
          <PrevNextButton direction="right" onClick={handleNext}>
            {'>'}
          </PrevNextButton>
        ) : null}
        <StyledPopupInnerContainer>
          <StyledPopupImage src={attachments[index]?.s3Link || ''} />
          <CloseIcon
            onClick={onClose}
            style={{
              color: '#000',
              position: 'absolute',
              top: 10,
              right: 10,
              cursor: 'pointer',
            }}
          />
        </StyledPopupInnerContainer>
        <StyledImageNumber>
          {index + 1} / {attachments.length}
        </StyledImageNumber>
      </StyledPopupContainer>
    );
  }
};

export default Popup;
