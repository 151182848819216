import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import useContribution from 'pages/ContributionView/hooks/useContribution';
import { getThemedColors } from 'services/contributions.service';

import getInitials from 'utils/getInitials';

import './ParticipantsModal.scss';

const ParticipantsModal = ({ members, open, onOpenChange }) => {
  const onClose = () => onOpenChange(false);

  const contribution = useContribution();

  const { themedColor, themedCardBackgroundColor } = getThemedColors(contribution);

  return (
    <Dialog
      classes={{ paper: 'chat-participants-modal' }}
      PaperProps={{
        style: {
          backgroundColor: themedCardBackgroundColor,
          color: themedColor,
        },
      }}
      open={open}
      onClose={onClose}
    >
      <DialogTitle className="chat-participants-modal-header" disableTypography>
        <p className="chat-participants-modal-header__title">Participants</p>
        <IconButton className="chat-participants-modal-header__close" onClick={onClose}>
          <CloseIcon style={{ color: themedColor }} />
        </IconButton>
      </DialogTitle>
      <List>
        {members.map(m => {
          const { PreviewImage: avatarUrl, Name: displayName } = m.attributes;

          return (
            <ListItem key={m?.sid}>
              <ListItemAvatar>
                <Avatar src={avatarUrl}>{getInitials(displayName)}</Avatar>
              </ListItemAvatar>
              <ListItemText
                disableTypography
                primary={<p className="chat-participants-modal-participant-name">{displayName}</p>}
              />
            </ListItem>
          );
        })}
      </List>
    </Dialog>
  );
};

ParticipantsModal.propTypes = {
  members: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  open: PropTypes.bool.isRequired,
  onOpenChange: PropTypes.func.isRequired,
};

export default ParticipantsModal;
