import React, { useEffect, useState } from 'react'

import { useAccount, useHttp, useRouter } from 'hooks'
import { useTheme, useMediaQuery } from '@material-ui/core'

import Button from 'components/FormUI/Button'

import styled from 'styled-components'

import '../Banner.scss'
import { useDispatch } from 'react-redux'
import { useStripe } from '@stripe/react-stripe-js'

const StyledButton = styled(Button)`
  background-color: #0e536b;
  padding: 6px 14px;
  min-width: 95px;
  width: 95px;
  font-size: 12px;
  font-weight: 700;
`

const ClientDeclinedSubscriptions = () => {
  const stripe = useStripe()
  const theme = useTheme()
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'))
  const BannerButton = mobileView ? StyledButton : Button
  const { request } = useHttp()

  const onClick = () => {
    request('/subscription/CreateCheckoutSession', 'POST')
      .then((checkoutSessionId) =>
        stripe.redirectToCheckout({
          sessionId: checkoutSessionId,
        })
      )
      .catch(console.dir)
  }

  return (
    <div className={`banner-container ${mobileView && 'banner-container__mobile'}`}>
      <p className="banner-container__text">
        There was a problem processing your payment. Please update your payment information.
      </p>
      <BannerButton variant="primary" className="banner-container__button" autoWidth onClick={onClick}>
        Update
      </BannerButton>
    </div>
  )
}

export default ClientDeclinedSubscriptions
