import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { MEMBER_PODS_ACTIONS } from 'actions/MemberPods';
import useAccount from 'hooks/useAccount';
import { memberPodsSelector } from 'selectors/MemberPods';
import { memberPodsService } from 'services/memberPods/memberPods.service';
import useContribution from 'pages/ContributionView/hooks/useContribution';

export const useLoadMemberPods = () => {
  const dispatch = useDispatch();
  const state = useSelector(memberPodsSelector);
  const { user } = useAccount();
  const contribution = useContribution();

  useEffect(() => {
    if (state.data || state.error || state.isFetching) {
      return;
    }

    dispatch(MEMBER_PODS_ACTIONS.setIsFetching(true));

    memberPodsService
      .getMemberPods(user.id)
      .then(data => {
        if (!data?.length) {
          const defaultMemberPods = ['Group One', 'Group Two', 'Group Three', 'Group Four'];
          const promises = defaultMemberPods.map(name =>
            memberPodsService.addMemberPod({ coachId: user.id, name, contributionId: contribution.id }),
          );

          return Promise.all(promises);
        }

        return data;
      })
      .then(data => dispatch(MEMBER_PODS_ACTIONS.setData(data)))
      .catch(error => dispatch(MEMBER_PODS_ACTIONS.setError(error)))
      .finally(() => dispatch(MEMBER_PODS_ACTIONS.setIsFetching(false)));
  }, [dispatch, state.data, state.error, state.isFetching, user.id]);
};
