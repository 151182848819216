import React from 'react';
import classes from './ConvertToClientModal.module.scss';

const ConvertToClientModal = () => {
  return (
    <>
      <div>
        Are you sure you wish to convert your lead to a client. By doing this it'll now appear in your clients list. You
        can always revert this again.
      </div>
    </>
  );
};

export default ConvertToClientModal;
