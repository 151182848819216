import React from 'react';
import classes from './DeleteTagModalContent.module.scss';
import styled from 'styled-components';
import { FormControlLabel, Radio, RadioGroup, Checkbox } from '@material-ui/core';
import { truncate } from 'lodash/fp';

const StyledTag = styled.div`
  padding: 6px 8px;
  border-radius: 4px;
  background-color: #dfe3e4;
  font-family: Avenir;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  color: rgba(0, 0, 0, 0.87);
`;

const StyledLabel = styled.div`
  font-family: Avenir;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.87);
`;

const DeleteTagModalContent = ({ tags = [], action, onChangeAction }) => {
  return (
    <div className={classes.main}>
      <div className={classes.main_heading}>Are you sure you want to delete this tag?</div>
      <div className={classes.selected_tags_container}>
        <div className={classes.heading}>Tag selected to delete</div>
        <div className={classes.tags}>
          {tags?.length > 0 &&
            tags?.map(tag => {
              return <StyledTag>{tag?.name}</StyledTag>;
            })}
        </div>
      </div>
      <div className={classes.actions_main}>
        <div className={classes.heading}>What would you like to do with all the contacts with this tag?</div>
        <div className={classes.options}>
          <RadioGroup value={action} onChange={onChangeAction}>
            <div className={classes.options_list}>
              <FormControlLabel
                className={classes.radio_btn}
                label={<StyledLabel>Remove this tag from all contacts who have it</StyledLabel>}
                control={<Radio color="primary" value="Remove" />}
              />
              <FormControlLabel
                className={classes.radio_btn}
                label={<StyledLabel>Move all contacts to a different tag</StyledLabel>}
                control={<Radio color="primary" value="Move" />}
              />
            </div>
          </RadioGroup>
        </div>
      </div>
      <div className={classes.actions_main}>
        <div className={classes.heading}>What would you like to do with all the contacts with this tag?</div>
        <div className={classes.options}>
          <div className={classes.options_list}>
            <FormControlLabel
              className={classes.checkbox_btn}
              label={
                <StyledLabel>
                  Deleting selected tags will also remove the tag from all automations and segments that reference them,
                  which may cause them to stop working
                </StyledLabel>
              }
              control={<Checkbox color="primary" value="" className={classes.checkbox_control} />}
              checked={true}
            />
            <FormControlLabel
              className={classes.checkbox_btn}
              label={<StyledLabel>Deleting tags can take 24 hours to complete</StyledLabel>}
              control={<Checkbox color="primary" value="" className={classes.checkbox_control} />}
              checked={true}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteTagModalContent;
