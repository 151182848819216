import React from 'react'
import PropTypes from 'prop-types'
import IconButton from '@material-ui/core/IconButton'
import CreateIcon from '@material-ui/icons/Create'
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined'

import ToolbarAction from './ToolbarAction'

const Notes = ({ enabled, onEnabledChange }) => {
  const NotesIcon = enabled ? CreateOutlinedIcon : CreateIcon
  const label = enabled ? 'Hide Notes' : 'My Notes'

  return (
    <ToolbarAction label={label}>
      <IconButton color="inherit" onClick={() => onEnabledChange(!enabled)}>
        <NotesIcon />
      </IconButton>
    </ToolbarAction>
  )
}

Notes.propTypes = {
  enabled: PropTypes.bool.isRequired,
  onEnabledChange: PropTypes.func.isRequired,
}

export default Notes
