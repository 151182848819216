import { makeStyles } from '@material-ui/core';
import styled from 'styled-components';

import { colors } from 'utils/styles';

export const useStyles = makeStyles(theme => ({
  root: {
    container: {
      maxWidth: 550,
    },
    row: {
      '& + &': {
        marginTop: 20,
      },
    },
    display: 'grid',
    gridAutoFlow: 'row',
    gap: 25,
    '& input': {
      outline: 'none',
    },
    '& select': {
      outline: 'none',
    },
  },
  backButton: {
    border: 'none',
    background: 'rgba(0,0,0,0)',
  },
  couponCodeContainer: {
    display: 'grid',
    gridAutoFlow: 'row',
    gap: 5,
    '& > p': {
      fontSize: 12,
      fontWeight: 700,
      margin: 0,
      padding: 0,
      color: colors.gray,
    },
    '& input': {
      border: `1px solid ${colors.gray}`,
      padding: '5px 8px',
    },
    '& button': {
      border: 'none',
      background: 'inherit',
      textDecoration: 'underline',
      fontSize: 14,
      margin: 0,
      padding: 0,
    },
  },
  discountContainer: {
    display: 'grid',
    gap: 5,
    '& > p': {
      fontSize: 12,
      fontWeight: 700,
      margin: 0,
      padding: 0,
      color: colors.gray,
    },
    '& input': {
      border: `1px solid ${colors.gray}`,
      padding: '5px 8px',
      marginRight: 5,
      width: 100,
    },
    '& select': {
      border: `1px solid ${colors.gray}`,
      padding: '5px 8px',
      background: 'inherit',
    },
  },
  thisCanBeUsedContainer: {
    display: 'grid',
    gap: 5,
    '& > p': {
      fontSize: 12,
      fontWeight: 700,
      margin: 0,
      padding: 0,
      color: colors.gray,
    },
    '& input': {
      border: `1px solid ${colors.gray}`,
      padding: '5px 8px',
      marginRight: 5,
      width: 100,
    },
    '& select': {
      border: `1px solid ${colors.gray}`,
      padding: '5px 8px',
      background: 'inherit',
    },
    '& > span': {
      color: colors.gray,
      fontSize: 14,
    },
  },
  codeAppliesTo: {
    display: 'grid',
    gap: 5,
    '& > p': {
      fontSize: 12,
      fontWeight: 700,
      margin: 0,
      padding: 0,
      color: colors.gray,
    },
    '& input': {
      border: `1px solid ${colors.gray}`,
      padding: '5px 8px',
      marginRight: 5,
      width: 100,
    },
  },
  expiration: {
    display: 'grid',
    gap: 5,
    '& > p': {
      fontSize: 12,
      fontWeight: 700,
      margin: 0,
      padding: 0,
      color: colors.gray,
    },
    '& input': {
      border: `1px solid ${colors.gray}`,
      padding: '5px 8px',
      marginRight: 5,
    },
    '& > span': {
      color: colors.gray,
      fontSize: 14,
    },
  },
  buttonStyles: {
    border: 'none',
    background: colors.darkOceanBlue,
    color: colors.white,
  },
}));
