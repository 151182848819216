import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
// import { Translate } from "react-localize-redux";
import { connect } from 'react-redux';

import { useRouter } from 'hooks';
import { NEW_PASS_SUCCESS } from 'actions/user';
import Popup from 'components/Popup/Popup';
import Input from 'components/FormUI/Input';
import Button from 'components/FormUI/Button';
import Logo from 'components/FormUI/Logo';
import CommonErrorMessage from 'components/FormUI/CommonErrorMessage';
import { Card } from 'components/UI/Card';
import { restorePassExtendedValidationSchema, restorePassValidationSchema } from 'utils/validation';
import * as userActions from 'actions/user';
import { devices } from 'utils/styles';

const CONTAINER_WIDTH = 520;

const Container = styled.div`
  max-width: ${CONTAINER_WIDTH}px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px 24px;
  box-sizing: border-box;

  @media screen and (${devices.mobile}) {
    padding: 40px 100px;
  }
`;

const WelcomeMessage = styled.h1`
  font-size: 1.675rem;
  text-align: center;
  font-weight: 500;
  line-height: normal;
  color: rgba(0, 0, 0, 0.87);
  margin-bottom: 0;
  margin-top: 0.75;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 3.75rem;
  width: 100%;
  text-align: center;

  & > * + * {
    margin-top: 2.5rem;
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: rgba(0, 0, 0, 0.87);
  line-height: 1.17;
  letter-spacing: 0.4px;
  font-size: 0.875rem;
  font-weight: 300;

  &:hover {
    text-decoration: underline;
  }
`;

const useStyles = makeStyles(() => ({
  input: {
    '& + &': {
      marginTop: '2rem',
    },
  },
}));

function RestorePassword({ restorePass, newPass, loading, error }) {
  const [popupText, setPopupText] = useState(null);
  const {
    location: { state = {} },
    history,
  } = useRouter();
  const classes = useStyles();

  const submitHandler = useCallback(
    ({ email, newPassword }) => {
      if (newPassword) {
        newPass({
          newPassword,
          email,
          token: state.token,
        }).then(({ type }) => {
          if (type === NEW_PASS_SUCCESS) {
            history.push('/auth/signin');
          }
        });
        return;
      }
      restorePass(email).then(({ message }) => {
        setPopupText(message);
      });
    },
    [restorePass, state.token, history, newPass],
  );

  const handleClosePopup = () => {
    setPopupText(null);
  };

  if (popupText) {
    return (
      <Popup
        title="Email confirmation"
        text={popupText}
        open={!!popupText}
        onCloseText="OK"
        onClose={handleClosePopup}
      />
    );
  }

  return (
    <>
      <Card>
        <Container>
          <Logo />
          <WelcomeMessage>Password Restoring</WelcomeMessage>
          <Formik
            initialValues={{ email: '' }}
            // validationSchema={
            //   state.token
            //     ? restorePassExtendedValidationSchema(Translate)
            //     : restorePassValidationSchema(Translate)
            // }
            validationSchema={state.token ? restorePassExtendedValidationSchema() : restorePassValidationSchema()}
            onSubmit={submitHandler}
          >
            <Form>
              <Input className={classes.input} label="Email" type="email" name="email" fullWidth />
              {state.token && (
                <Input className={classes.input} label="New password" type="password" name="newPassword" fullWidth />
              )}
              <ButtonsContainer>
                <Button type="submit" disabled={loading}>
                  Restore password
                </Button>
                {error?.message && <CommonErrorMessage message={error?.message} />}
                <StyledLink to="/auth/signin">Already have an account? Login here.</StyledLink>
              </ButtonsContainer>
            </Form>
          </Formik>
        </Container>
      </Card>
    </>
  );
}

RestorePassword.propTypes = {
  restorePass: PropTypes.func.isRequired,
  newPass: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      message: PropTypes.string,
    }),
  ]),
};

RestorePassword.defaultProps = {
  error: null,
};

const mapStateToProps = ({ account }) => ({
  loading: account.isLoading,
  error: account.error,
});

const actions = {
  restorePass: userActions.restorePass,
  newPass: userActions.newPass,
};

export default connect(mapStateToProps, actions)(RestorePassword);
