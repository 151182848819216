import React from 'react';
import PropTypes from 'prop-types';
import MuiTextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';

import { colors } from 'utils/styles';

const useStyles = makeStyles(borderBottoms => {
  return {
    labelRoot: {
      fontFamily: 'Avenir',
      lineHeight: '1rem',
      letterSpacing: '0.4px',
      fontSize: `${14 / 16}rem`,
      color: 'rgba(0, 0, 0, 0.87)',
      fontWeight: 300,
      transform: `translate(0, ${33 / 16}rem) scale(1)`,
    },
    labelShrink: {
      transform: 'translate(0, 4px)',
    },
    labelFocused: {
      color: 'inherit !important',
    },
    labelFormControl: {
      transform: `translate(0, ${32 / 16}rem) scale(1)`,
    },
    labelError: {
      color: 'inherit !important',
    },
    inputRoot: {
      fontFamily: 'Avenir',
      lineHeight: `${24 / 16}rem`,
      letterSpacing: '0.15px',
      fontSize: `${18 / 16}rem`,
      color: 'rgba(0, 0, 0, 0.87)',
      fontWeight: 500,
    },
    inputRootEmail: {
      fontFamily: 'Avenir',
      lineHeight: `${24 / 16}rem`,
      letterSpacing: '0.15px',
      fontSize: `16px`,
      color: 'rgba(0, 0, 0, 0.87)',
      fontWeight: 400,
    },
    inputFormControl: {
      'label + &': {
        marginTop: 26,
      },
    },

    inputUnderline: {
      '&:hover:not(.Mui-disabled):before': {
        borderBottom: props =>
          borderBottoms ? (props?.underLineColor ? `2px solid ${props?.underLineColor}` : '') : `2px solid #6486a2`,
      },
      '&:after': {
        borderBottom: props =>
          borderBottoms ? (props?.underLineColor ? `2px solid ${props?.underLineColor}` : '0px') : `2px solid #6486a2`,
      },
      '&:before': {
        borderColor: borderBottoms ? '' : '#e7e7e7',
        borderBottom: props => (props?.underLineColor ? `1px solid ${props?.underLineColor}` : ''),
      },
    },

    inputError: {
      '&:after': {
        borderBottomColor: `${colors.fadedPurple} !important`,
      },
    },
    helperTextRoot: {
      textAlign: 'right',
      fontFamily: 'Avenir',
      lineHeight: `${24 / 16}rem`,
      letterSpacing: '0.08px',
      fontSize: `${12 / 16}rem`,
      color: 'rgba(0, 0, 0, 0.87)',
      fontWeight: 'normal',
    },
    helperTextError: {
      color: `${colors.fadedPurple} !important`,
      textAlign: 'left',
      fontSize: '1rem',
      letterSpacing: '0.1px',
    },
    helperTextErrorWhite: {
      color: 'white !important',
      textAlign: 'left',
      fontSize: '1rem',
      letterSpacing: '0.1px',
    },
    helperTextErrorRed: {
      color: `${colors.red} !important`,
      textAlign: 'left',
      fontSize: '1rem',
      letterSpacing: '0.1px',
    },
    textField: {
      [`& fieldset`]: {
        borderRadius: borderBottoms ? '10px' : '',
      },
    },
  };
});

function TextField(props) {
  const {
    redError = false,
    whiteError = false,
    endAdornment,
    startAdornment,
    borderBottoms,
    helperText,
    inputComponent,
    underLineColor = '',
    emailMarketingField = false,
    mask,
    ...rest
  } = props;
  const classes = useStyles({ borderBottoms, underLineColor });
  return (
    <MuiTextField
      InputLabelProps={{
        classes: {
          root: classes.labelRoot,
          focused: classes.labelFocused,
          shrink: classes.labelShrink,
          error: classes.labelError,
        },
      }}
      InputProps={{
        classes: {
          root: emailMarketingField ? classes.inputRootEmail : classes.inputRoot,
          formControl: classes.inputFormControl,
          underline: classes.inputUnderline,
          error: classes.inputError,
          ...rest.inputClasses,
        },
        endAdornment,
        startAdornment,
        inputComponent,
        inputProps: {
          mask,
        },
      }}
      FormHelperTextProps={{
        classes: {
          root: classes.helperTextRoot,
          error: whiteError
            ? classes.helperTextErrorWhite
            : redError
            ? classes.helperTextErrorRed
            : classes.helperTextError,
        },
      }}
      helperText={helperText}
      {...rest}
    />
  );
}

TextField.propTypes = {
  fullWidth: PropTypes.bool,
  endAdornment: PropTypes.node,
  startAdornment: PropTypes.node,
  label: PropTypes.string,
  helperText: PropTypes.string,
  inputComponent: PropTypes.elementType,
  mask: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

TextField.defaultProps = {
  fullWidth: false,
  endAdornment: null,
  startAdornment: null,
  label: null,
  helperText: null,
  inputComponent: 'input',
  mask: null,
};

export default TextField;
