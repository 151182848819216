import axiosInstance from 'utils/axiosInstance';
import { get } from 'lodash/fp';

const getMagicWriteText = data =>
  axiosInstance
    .post(`/MagicWrite/GetMagicWriteText`, data, {
      headers: { 'Content-Type': 'application/json' },
    })
    .then(get('data'));

const getImprovedBioText = data =>
  axiosInstance
    .post(`/MagicWrite/ImproveBio`, data, {
      headers: { 'Content-Type': 'application/json' },
    })
    .then(get('data'));

export { getMagicWriteText, getImprovedBioText };
