import React from 'react'

import List, { Item } from './List'

function AdminMenu() {
  return (
    <List>
      <Item to="/admin/contributions/review" title="Contributions" />
    </List>
  )
}

export default AdminMenu
