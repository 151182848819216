import { handleActions } from 'redux-actions';

import * as actions from 'actions/country';

const initialState = {
  loading: false,
  error: null,
  states: [],
};

const states = handleActions(
  new Map([
    [
      actions.fetchStatesActions.request,
      state => ({
        ...state,
        error: null,
        loading: true,
        states: [],
      }),
    ],
    [
      actions.fetchStatesActions.success,
      (state, action) => ({
        ...state,
        loading: false,
        error: null,
        states: action.payload,
      }),
    ],
    [
      actions.fetchStatesActions.error,
      (state, action) => ({
        ...state,
        loading: false,
        error: action.payload,
        states: [],
      }),
    ],
  ]),
  initialState,
);

export default states;
