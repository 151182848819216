import moment from 'moment';

export const SHOW_ALERT = 'SHOW_ALERT';
export const HIDE_ALERT = 'HIDE_ALERT';

function showAlert(message, time) {
  if (!time) {
    time = moment().format('HH:mm');
  }

  return { type: SHOW_ALERT, payload: { message, time } };
}

function hideAlert() {
  return { type: HIDE_ALERT };
}

export const alertActions = {
  showAlert,
  hideAlert,
};
