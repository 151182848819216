const size = {
  mobile: '576px',
  tablet: '768px',
  laptop: '992px',
  desktop: '1200px',
};

const devices = {
  mobile: `min-width: ${size.mobile}`,
  tablet: `min-width: ${size.tablet}`,
  laptop: `min-width: ${size.laptop}`,
  desktop: `min-width: ${size.desktop}`,
};

const colors = {
  jamiColor: '#D08ACD',
  goldenColor: '#CDBA8F',
  lightBlue: '#6999CA',
  darkOceanBlue: '#116582',
  darkOceanBlue2: '#235b72',
  darkBlue: '#203e51',
  darkestBlue: '#203E5A',
  lightBrown: '#d1b989',
  gold: '#e9c062',
  newGold: '#C9B382',
  lightGold: '#BA9500',
  darkGold: '#b09167',
  fbBlue: '#1877f2',
  fadedPurple: '#9164a2',
  textMain: 'rgba(0, 0, 0, 0.87)',
  textSecondary: 'rgba(0, 0, 0, 0.5)',
  stroke: '#dfdfdf',
  black: '#000000',
  white: '#ffffff',
  shadow: 'rgba(214, 214, 214, 0.5)',
  shadowDark: 'rgba(0, 0, 0, 0.1)',
  backgroundLightGrey: 'rgba(0,0,0, 0.15)',
  backgroundGrey: 'rgba(0,0,0, 0.25)',
  border: 'rgba(0,0,0, 0.2)',
  switch: '#ccc',
  darkWhite: '#e7e7e7',
  darkGray: '#4a4a4a',
  gray: '#808080',
  lightGray: '#fafafa',
  borderLightGray: '#eaf0f2',
  smokedGray: '#a9a9a9',
  red: '#FF0000',
  advantagesColor: '#4B4B4B',
  whisper: '#e7e7e7',
  newGold: '#C9B382',
};

const toRem = (px, base = 16) => `${px / base}rem`;

export { devices, toRem, colors };
