import styled from 'styled-components';
import { makeStyles } from '@material-ui/core';
import { colors } from '../../utils/styles';

export const useStyles = makeStyles(theme => ({
  cardBoxCard: {
    height: '50%',
  },
  cardBox: {
    height: '100%',
  },
  favIconContainer: {
    position: 'absolute',
    top: -5,
    right: 7,
  },
  icon: {
    marginLeft: '43%',
  },
  topLevelcoach: {
    height: '17%',

    [theme.breakpoints.up(1150)]: {
      height: '20%',
    },
  },
  topLevel: {
    height: '20%',

    [theme.breakpoints.up(1150)]: {
      height: '15%',
    },
  },
  midLevel: {
    height: '70%',

    [theme.breakpoints.up(1150)]: {
      height: '75%',
    },
  },
  midLevelcoach: {
    height: '70%',
    [theme.breakpoints.down(800)]: {
      height: 'auto',
    },
    [theme.breakpoints.up(1150)]: {
      height: '40%',
    },
    [theme.breakpoints.down(1150)]: {
      height: '40%',
    },
    // [theme.breakpoints.down(500)]: {
    //   height: '0px',
    // },
  },
  bottomLevel: {
    height: 'auto',
  },
  selectPlanButton: {
    border: 'none',
    borderRadius: 6,
    padding: '8px 38px',
  },
  enable: {
    color: colors.textMain,
    background: colors.backgroundLightGrey,
    transition: 'all 0.25s',
    '&:hover': {
      background: colors.backgroundGrey,
    },
  },
  disable: {
    color: colors.white,
    background: colors.darkOceanBlue,
    transition: 'all 0.25s',
    '&:hover': {
      background: colors.darkBlue,
    },
  },
  enabeltext: {
    color: '#215C73',
    fontWeight: 'bold',
    transition: 'all 0.25s',
    '&:hover': {
      color: colors.darkOceanBlue,
    },
  },
  StyledContainerheightstyle: {
    [theme.breakpoints.down(500)]: {
      // height: '420px',
    },
  },

  // titleMedia: {
  //   [theme.breakpoints.up(2000)]: {
  //     fontSize: 30,
  //   },
  // },
  // priceMedia: {
  //   [theme.breakpoints.up(2000)]: {
  //     fontSize: 30,
  //   },
  //   [theme.breakpoints.down(600)]: {
  //     '&>span': {
  //       fontSize: 13,
  //     },
  //   },
  // },
  // feeMedia: {
  //   [theme.breakpoints.up(2000)]: {
  //     fontSize: 24,
  //   },
  // },
  upgradeButtonWrapper: {
    display: 'grid',
    justifyContent: 'center',
  },
}));

export const StyledContainer = styled.div`
  height: ${props => (props.height ? props.height : '980px')};

  @media (min-width: 768px) {
    // height: 750px;
  }
  @media (min-width: 1150px) {
    // height: 1100px;
  }
  @media (min-width: 1350px) {
    // height: 800px;
  }
  width: ${props => (props.width ? props.width : '100%')};

  padding: ${props => (props.padding ? props.padding : '18px 30px 31px 25px')};
  position: relative;
  background: ${colors.white};

  border: ${props => (props.border ? props.border : `1px solid ${colors.border}`)};
  box-shadow: ${props => (props.boxShadow ? props.boxShadow : '')};
  border-radius: ${props => (props.borderRadius ? props.borderRadius : '8px')};
`;

export const TitleField = styled.p`
  margin: 0px;
  padding-bottom: 4px;

  font-size: ${props => (props.fontSize ? props.fontSize : '22px')};
  font-weight: bolder;
  // by usa
  color: rgba(0, 0, 0, 0.87);
`;

export const PriceField = styled.p`
  margin: 0px;
  padding-bottom: 4px;

  font-size: 24px;
  font-weight: bolder;

  & > span {
    font-size: 16px;
    font-weight: normal;
    color: ${colors.textSecondary};
  }
`;

export const FeeField = styled.p`
  margin: 0px;
  padding-bottom: 4px;
`;

export const AdvantagesList = styled.ul`
list - style: none;
margin: 0px;
font-size: 13px;
padding: 5px 0px 5px 0px;
`;

export const ExpiresLabel = styled.p`
color: ${colors.textSecondary};
font - size: 14px;
text - align: center;
`;
