import React, { useEffect, useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { toRem } from 'utils/styles';
import { determineColorToUse } from 'services/contributions.service';
import PropTypes, { bool, number, string } from 'prop-types';
import useRouter from 'hooks/useRouter';
import { ContributionType, UserRoles } from 'helpers/constants';
import ModeCommentIcon from '@mui/icons-material/ModeComment';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IconButton, Tooltip } from '@material-ui/core';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { connect, useDispatch } from 'react-redux';
import { IoAddCircleOutline } from 'react-icons/io5';
import SettingsIcon from '@mui/icons-material/Settings';
import { Switch, StyledInput, StyledSlider } from 'components/switch/style';
import {
  moveGroupFromOneSectionToOther,
  updateCommunityGroupOrder,
  updateCommunitySectionOrder,
} from 'services/communitySections.service';
import {
  moveGroupFromOneSectionToOtherAction,
  updateCommunityGroupsOrderActions,
  updateCommunitySectionOrderActions,
  updateSectionGroupsLimit,
} from 'actions/communitySections';
import { findValueByKey, isValidUrl, pluckKeys } from 'utils/utils';
import HomeIcon from './HomeIcon';
import SectionMenu from './SectionMenu';
import { SECTION_MENU_OPTIONS, TOOLTIP } from '../../../../constants';
import GroupMenu from './GroupMenu';
import MuteIcon from './MuteIcon';
import GroupIcon from './icons/GroupIcon';
import Modal from 'components/UI/Modal';
import { postApprovalRequired } from 'services/communicationTab.service ';
import { fetchCohealerContribution } from 'actions/contributions';
import useContributionTheme from 'hooks/useContributionTheme';
import usePartnerCoach from 'hooks/usePartnerCoach';
import useAccount from 'hooks/useAccount';

export const StyledNavLink = styled(NavLink)`
  text-decoration: none;
  font-size: ${toRem(16)};
  line-height: 1;
  font-weight: 900;
  ${props => props.themedBackgroundColor};
  padding: 16px;
  display: inline-block;
  position: relative;

  &.active {
    ${({ btnColor }) => {
      return (
        btnColor &&
        `
         color: ${btnColor};
      `
      );
    }}
  }

  ${({ mobileView }) => {
    return (
      mobileView &&
      `
        padding: 16px 15px;
      `
    );
  }}

  ${({ firstLink }) => firstLink && `padding-left: 16px;`}
`;

const LeftSectionMenu = ({
  contribution,
  currentRole,
  themedColors,
  mobileView,
  pathname,
  createSection,
  sections,
  editSection,
  deleteSection,
  archivedGroups,
  createGroup,
  handleGroupActions,
  currentGroup,
  mainGroup,
  isDarkThemeEnabled,
}) => {
  const {
    match: { url },
    history,
    location: Location,
  } = useRouter();
  const sectionsRef = useRef(null);
  const dispatch = useDispatch();
  const [modalVisible, setModalVisible] = useState(false);
  const [isPostApprovalRequired, setIsPostApprovalRequired] = useState(contribution?.isPostApprovalRequired);
  const [arePublicPostsAllowed, setArePublicPostsAllowed] = useState(contribution?.arePublicPostsAllowed);
  const [openedSections, setOpenedSections] = useState([]);
  const [sectionElement, setSectionElement] = useState(null);
  const [groupElement, setGroupElement] = useState(null);
  const [groupToEdit, setGroupToEdit] = useState(null);
  const { user } = useAccount();
  useEffect(() => {
    const currentActiveSections = openedSections;
    const activeSections = sections
      ?.filter(section => !pluckKeys(sectionsRef.current, 'sectionId')?.includes(section.sectionId))
      ?.map(section => {
        return !openedSections.includes(section.sectionId) ? section.sectionId : null;
      })
      .filter(Boolean);

    if (activeSections && activeSections.length > 0) {
      setOpenedSections([...currentActiveSections, ...activeSections]);
    }

    sectionsRef.current = sections;
  }, [sections]);

  useEffect(() => {
    if (currentGroup) {
      const section = findValueByKey(sections, 'sectionId', currentGroup?.sectionId);
      const groupIndex = section?.groups.findIndex(sectionGroup => sectionGroup.id === currentGroup?.id);
      if (groupIndex > 4) {
        dispatch(
          updateSectionGroupsLimit.update({
            sectionId: section.sectionId,
            limit: section?.groups?.length,
          }),
        );
      }
    }
  }, [currentGroup?.id]);

  const toggleSection = sectionId => {
    const exist = openedSections.includes(sectionId);
    if (exist) {
      setOpenedSections(openedSections.filter(id => id !== sectionId));
    } else {
      setOpenedSections([...openedSections, sectionId]);
    }
  };
  const handleCloseModal = () => {
    postApprovalRequired(contribution.id, isPostApprovalRequired, arePublicPostsAllowed).then(() => {
      setModalVisible(false);
      dispatch(fetchCohealerContribution(contribution.id));
    });
  };

  const getElement = element => {
    let requiredElement = null;
    let traversal = 1;

    if (!element) {
      return null;
    }

    while (requiredElement === null && traversal < 10) {
      if (element?.tagName === 'BUTTON' && element.id) {
        requiredElement = element;
      }
      element = element.parentNode;
      traversal += 1;
    }

    return requiredElement;
  };

  const getElementId = type => {
    return type === 'section' ? getElement(sectionElement)?.id : getElement(groupElement)?.id;
  };

  const handleSectionMenuSelect = option => {
    const selectedSection = sections?.find(section => section.sectionId === getElementId('section').split('-')[2]);
    if (option === SECTION_MENU_OPTIONS.createGroup) {
      createGroup(selectedSection);
    }
    if (option === SECTION_MENU_OPTIONS.editSection) {
      editSection(selectedSection);
    }
    if (option === SECTION_MENU_OPTIONS.deleteSection) {
      deleteSection(selectedSection);
    }
    setSectionElement(null);
  };

  const handleGroupSelect = option => {
    handleGroupActions(option, groupToEdit);
    setGroupElement(null);
    setGroupToEdit(null);
  };

  const colorToUse = determineColorToUse(contribution);
  // const shouldChangeColor = type != ContributionType.contributionOneToOne;
  const isContributionPurchased = contribution.isPurchased;

  const canAccessClients =
    (contribution.type === ContributionType.contributionCourse ||
      contribution.type === ContributionType.contributionMembership ||
      contribution.type === ContributionType.contributionCommunity) &&
    (currentRole === UserRoles.cohealer ||
      (!contribution.isMembersHiddenInCommunity && currentRole === UserRoles.client && isContributionPurchased));
  // CMP1-3129 hide chat for a contribution with id === '617c3037fb3c265bed7891f3'
  const shouldShowChatTab = contribution?.id !== '617c3037fb3c265bed7891f3';
  const isCoach = currentRole === UserRoles.cohealer || currentRole === UserRoles.cohealerAssistant;
  const { partnerCoaches } = usePartnerCoach(contribution.id);
  const isPartnerCoach = partnerCoaches.some(obj => obj.userId === user.id);
  const updateSectionOrder = newSections => {
    dispatch(
      updateCommunitySectionOrderActions.update({
        sections: newSections,
      }),
    );

    const updatedOrders = {};
    newSections.forEach(section => {
      if (!section.isAnonymous) {
        updatedOrders[section.sectionId] = section.sectionOrder;
      }
    });

    updateCommunitySectionOrder({
      contributionId: contribution.id,
      updatedOrders,
    });
  };

  const updateGroupOrder = (groups, sectionId) => {
    dispatch(
      updateCommunityGroupsOrderActions.update({
        sectionId,
        groups,
      }),
    );

    const updatedOrders = {};
    groups.forEach(group => {
      updatedOrders[group.id] = group.orderNo;
    });

    updateCommunityGroupOrder({
      contributionId: contribution.id,
      sectionId,
      updatedOrders,
    });
  };

  const moveGroupFromSectionToSection = (
    groupMovedFromSectionId,
    groupMovedToSectionId,
    sourceGroups,
    destinationGroups,
    movedGroupId,
  ) => {
    dispatch(
      moveGroupFromOneSectionToOtherAction.update({
        fromSection: groupMovedFromSectionId,
        toSection: groupMovedToSectionId,
        fromSectionGroups: sourceGroups,
        toSectionGroups: destinationGroups,
      }),
    );

    const groupMovedToSectionOrders = {};
    const groupMovedFromSectionOrders = {};
    sourceGroups.forEach(group => {
      groupMovedFromSectionOrders[group.id] = group.orderNo;
    });
    destinationGroups.forEach(group => {
      groupMovedToSectionOrders[group.id] = group.orderNo;
    });

    moveGroupFromOneSectionToOther({
      contributionId: contribution.id,
      groupMovedFromSectionId,
      groupMovedToSectionId,
      groupMovedFromSectionOrders,
      groupMovedToSectionOrders,
      movedGroupId,
    });
  };

  const handleDragEnd = results => {
    const { source, destination, type } = results;
    if (!destination) return;

    if (source.droppableId === destination.droppableId && source.index === destination.index) {
      return;
    }

    if (type === 'section') {
      let reOrderedSections = [...sections];
      const destinationSection = reOrderedSections[destination.index];
      const [removedSection] = reOrderedSections.splice(source.index, 1);
      reOrderedSections.splice(destination.index, 0, removedSection);

      if (destinationSection?.isAnonymous || removedSection?.isAnonymous) {
        return;
      }

      reOrderedSections = reOrderedSections.map((section, index) => {
        return { ...section, sectionOrder: index + 1 };
      });

      updateSectionOrder(reOrderedSections);
      return;
    }

    const sectionSourceIndex = source.index;
    const sectionDestinationIndex = destination.index;

    const storeSourceIndex = sections.findIndex(section => section.sectionId === source.droppableId);
    const storeDestinationIndex = sections.findIndex(section => section.sectionId === destination.droppableId);

    const destinationSection = sections[storeDestinationIndex];
    if (destinationSection.isAnonymous) {
      return;
    }

    let newSourceGroups = [...sections[storeSourceIndex].groups];
    let newDestinationGroups =
      source.droppableId !== destination.droppableId ? [...sections[storeDestinationIndex]?.groups] : newSourceGroups;

    let [deletedItem] = newSourceGroups.splice(sectionSourceIndex, 1);
    deletedItem = { ...deletedItem, sectionId: destinationSection.sectionId };
    const exisingSuggestedGroups =
      destinationSection?.groups?.filter(group => group.isSuggested && deletedItem.title === group.title) ?? [];

    if (deletedItem.isSuggested && source.droppableId !== destination.droppableId && exisingSuggestedGroups.length) {
      return;
    }
    newDestinationGroups.splice(sectionDestinationIndex, 0, { ...deletedItem });

    const newSections = [...sections];
    let order = 1;
    newSourceGroups = newSourceGroups.map(group => {
      return { ...group, orderNo: order++ };
    });

    order = 1;
    newDestinationGroups = newDestinationGroups.map(group => {
      return { ...group, orderNo: order++ };
    });

    newSections[storeSourceIndex] = {
      ...sections[storeSourceIndex],
      groups: newSourceGroups,
    };

    newSections[storeDestinationIndex] = {
      ...sections[storeDestinationIndex],
      groups: newDestinationGroups,
    };

    if (source.droppableId === destination.droppableId) {
      updateGroupOrder(newDestinationGroups, destination.droppableId);
    } else {
      moveGroupFromSectionToSection(
        source.droppableId,
        destination.droppableId,
        newSourceGroups,
        newDestinationGroups,
        deletedItem.id,
      );
    }
  };

  const handleLoadMore = sectionId => {
    const sectionGroups = sections.find(section => section.sectionId === sectionId)?.groups;
    dispatch(
      updateSectionGroupsLimit.update({
        sectionId,
        limit: sectionGroups.length,
      }),
    );
  };

  const handleLoadLess = sectionId => {
    dispatch(
      updateSectionGroupsLimit.update({
        sectionId,
        limit: 5,
      }),
    );
  };

  const openGroup = group => {
    history.push(`${url}/${group.id}`);
  };

  const getGroupImage = imageUrl => {
    if (imageUrl === '') {
      return <div className="group-image"> </div>;
    }
    if (isValidUrl(imageUrl)) {
      return <img src={imageUrl} alt="group icon" className="group-image" />;
    }
    return <div className="group-image">{imageUrl ?? ''}</div>;
  };

  const sectionList = (section, index, parentProvided) => {
    return (
      <Droppable droppableId={section.sectionId}>
        {(provided, snapshot) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            <div className="section">
              <div
                className={`section-item ${openedSections.includes(section.sectionId) ? 'open' : ''}`}
                tabIndex="0"
                role="button"
                onKeyDown={() => {}}
                onClick={() => toggleSection(section.sectionId)}
              >
                {isCoach && !section?.isAnonymous && (
                  <span {...parentProvided.dragHandleProps}>
                    <DragIndicatorIcon className="drag-icon" />
                  </span>
                )}
                <ArrowForwardIosIcon className="accordian-arrow" />
                <span className="title">
                  <Tooltip
                    placement="bottom-start"
                    title={section.sectionTitle}
                    enterTouchDelay={TOOLTIP.ENTER_DELAY}
                    leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                  >
                    <span>{section.sectionTitle}</span>
                  </Tooltip>
                </span>
              </div>
              {isCoach && !section?.isAnonymous && (
                <IconButton
                  className="options"
                  id={`section-option-${section.sectionId}`}
                  aria-label="more"
                  aria-controls={
                    getElementId('section') === `section-option-${section?.sectionId}` ? 'section-menu' : undefined
                  }
                  aria-expanded={
                    getElementId('section') === `section-option-${section?.sectionId}` ? 'true' : undefined
                  }
                  aria-haspopup="true"
                  onClick={e => setSectionElement(e.target)}
                  style={{ color: isDarkThemeEnabled ? 'white' : '#979797' }}
                >
                  <MoreVertIcon />
                </IconButton>
              )}
            </div>
            <div className="groups-list">
              {openedSections.includes(section.sectionId) && (
                <>
                  {section?.groups?.length > 0 ? (
                    <div>
                      {section.groups.slice(0, section.groupLimit).map((group, groupIndex) => (
                        <Draggable draggableId={group.id} index={groupIndex} key={group.id}>
                          {provided => (
                            <div {...provided.draggableProps} ref={provided.innerRef}>
                              <div className={`section-group ${currentGroup?.id === group.id ? 'active-group' : ''}`}>
                                <div
                                  className={`item ${
                                    group.isNotificationsMuted && group.unreadCount ? 'muted-item' : ''
                                  }`}
                                  role="button"
                                  tabIndex="0"
                                  onClick={() => openGroup(group)}
                                  onKeyDown={() => {}}
                                >
                                  <ArrowForwardIosIcon className="accordian-arrow invisible" />
                                  {isCoach && (
                                    <span {...provided.dragHandleProps}>
                                      <DragIndicatorIcon className="drag-icon" />
                                    </span>
                                  )}
                                  <div className="item-data">
                                    {getGroupImage(group.imageUrl)}
                                    <span className="title">
                                      <Tooltip
                                        placement="bottom-start"
                                        title={group.title}
                                        enterTouchDelay={TOOLTIP.ENTER_DELAY}
                                        leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                                      >
                                        <span>{group.title}</span>
                                      </Tooltip>
                                    </span>
                                  </div>
                                </div>
                                <div
                                  className={`options ${group.unreadCount && group.isNotificationsMuted ? 'gap' : ''}`}
                                >
                                  {group?.unreadCount ? (
                                    <span
                                      className={`unread-count ${!group.isNotificationsMuted ? 'mr-1' : ''}`}
                                      role="button"
                                      tabIndex="0"
                                      onClick={() => openGroup(group)}
                                      onKeyDown={() => {}}
                                    >
                                      {group?.unreadCount}
                                    </span>
                                  ) : null}
                                  <div className="d-flex align-items-center">
                                    {group.isNotificationsMuted && (
                                      <MuteIcon
                                        className="mute-icon"
                                        fillColor={isDarkThemeEnabled ? 'white' : '#979797'}
                                        role="button"
                                        tabIndex="0"
                                        onClick={() => openGroup(group)}
                                        onKeyDown={() => {}}
                                      />
                                    )}
                                    <IconButton
                                      className="options"
                                      id={`group-option-${group.id}`}
                                      aria-label="more"
                                      aria-controls={
                                        getElementId('group') === `group-option-${group?.id}` ? 'group-menu' : undefined
                                      }
                                      aria-expanded={
                                        getElementId('group') === `group-option-${group?.id}` ? 'true' : undefined
                                      }
                                      aria-haspopup="true"
                                      onClick={e => {
                                        setGroupElement(e.target);
                                        setGroupToEdit(group);
                                      }}
                                      style={{ color: isDarkThemeEnabled ? 'white' : '#979797' }}
                                    >
                                      <MoreVertIcon />
                                    </IconButton>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </Draggable>
                      ))}
                    </div>
                  ) : (
                    <>
                      {isCoach && (
                        <div
                          className="section-group"
                          role="button"
                          tabIndex="0"
                          onClick={() => {
                            createGroup(section);
                          }}
                          onKeyDown={() => {}}
                        >
                          <div className="item">
                            <ArrowForwardIosIcon className="accordian-arrow invisible" />
                            <span>
                              <IoAddCircleOutline />
                            </span>
                            <div className="item-data create-group">
                              <span className="title">Create Group</span>
                            </div>
                          </div>
                          <div className="options"> </div>
                        </div>
                      )}
                    </>
                  )}
                </>
              )}
            </div>

            {!snapshot.isUsingPlaceholder && openedSections.includes(section.sectionId) && section?.groups?.length > 5 && (
              <>
                {section?.groups?.length > 5 &&
                section?.groups?.slice(0, section.groupLimit).length !== section?.groups?.length ? (
                  <div
                    className="load-more"
                    tabIndex="0"
                    role="button"
                    onClick={() => handleLoadMore(section.sectionId)}
                    onKeyDown={() => {}}
                  >
                    Show more
                  </div>
                ) : (
                  <div
                    className="load-more"
                    tabIndex="0"
                    role="button"
                    onClick={() => handleLoadLess(section.sectionId)}
                    onKeyDown={() => {}}
                  >
                    Show Less
                  </div>
                )}
              </>
            )}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    );
  };

  return isCoach ? (
    <div className={`community-right-section ${isDarkThemeEnabled ? 'dark-mode-right-section' : ''}`}>
      <div className="right-section-links">
        {contribution?.isCommunityFeatureHiddden === false && (
          <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
            <StyledNavLink
              themedColor={themedColors.themedColor}
              to={`${url}${pathname.includes('social') ? '/social' : ''}`}
              exact
              isActive={location => {
                return !Location?.search && location?.isExact;
              }}
              mobileView={mobileView}
              className="right-section-link"
              style={{ width: '90%' }}
            >
              <div className="right-section-item">
                <HomeIcon fillColor={colorToUse?.PrimaryColorCode} /> Main Community
              </div>
              {mainGroup?.unreadCount ? <span className="unread-count">{mainGroup?.unreadCount}</span> : null}
            </StyledNavLink>
            <div
              style={{ width: '10%', justifyContent: 'center', alignItems: 'center', display: 'flex' }}
              onClick={e => {
                setModalVisible(true);
              }}
              className="right-section-item"
            >
              <SettingsIcon fillColor={'#4A4A4A'} />
            </div>
          </div>
        )}
        {contribution?.isCommunityFeatureHiddden === true && (
          <>
            {canAccessClients && (
              <StyledNavLink
                themedColor={themedColors.themedColor}
                to={`${url}/clients`}
                mobileView={mobileView}
                firstLink
                className="right-section-link"
                style={{ width: '90%' }}
              >
                <div className="right-section-item">
                  <GroupIcon fillColor={colorToUse?.PrimaryColorCode} />
                  {contribution.type === ContributionType.contributionMembership ||
                  contribution.type === ContributionType.contributionCourse ||
                  contribution.type === ContributionType.contributionCommunity
                    ? 'Members'
                    : 'Clients'}
                </div>
              </StyledNavLink>
            )}
            {contribution?.isGroupChatHidden === false && shouldShowChatTab && (
              <StyledNavLink
                themedColor={themedColors.themedColor}
                to={`${url}/conversations`}
                mobileView={mobileView}
                firstLink
                className="right-section-link"
                style={{ width: '90%' }}
              >
                <div className="right-section-item">
                  <ModeCommentIcon style={{ color: colorToUse?.PrimaryColorCode }} /> Chat
                </div>
              </StyledNavLink>
            )}
          </>
        )}
        {contribution?.isCommunityFeatureHiddden === false && (
          <>
            {contribution?.isGroupChatHidden === false && shouldShowChatTab && (
              <StyledNavLink
                themedColor={themedColors.themedColor}
                to={`${url}/conversations`}
                mobileView={mobileView}
                firstLink
                className="right-section-link"
                style={{ width: '90%' }}
              >
                <div className="right-section-item">
                  <ModeCommentIcon style={{ color: colorToUse?.PrimaryColorCode }} /> Chat
                </div>
              </StyledNavLink>
            )}
            {canAccessClients && (
              <StyledNavLink
                themedColor={themedColors.themedColor}
                to={`${url}/clients`}
                mobileView={mobileView}
                firstLink
                className="right-section-link"
                style={{ width: '90%' }}
              >
                <div className="right-section-item">
                  <GroupIcon fillColor={colorToUse?.PrimaryColorCode} />
                  {contribution.type === ContributionType.contributionMembership ||
                  contribution.type === ContributionType.contributionCourse ||
                  contribution.type === ContributionType.contributionCommunity
                    ? 'Members'
                    : 'Clients'}
                </div>
              </StyledNavLink>
            )}
          </>
        )}
      </div>

      {isCoach && contribution?.isCommunityFeatureHiddden === false && (
        <div className="add-item" tabIndex="0" role="button" onKeyDown={() => {}} onClick={() => createSection()}>
          <IoAddCircleOutline /> Create Section
        </div>
      )}

      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="sections" type="section">
          {provided => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {sections && (
                <div className={`sections-list ${!isCoach ? 'margin-top' : ''}`}>
                  {sections.map((section, index) => (
                    <>
                      {section.isAnonymous && section?.groups.length === 0 ? null : (
                        <Draggable
                          draggableId={section.sectionId}
                          key={section.sectionId}
                          index={index}
                          isDragDisabled={!isCoach}
                        >
                          {provided => (
                            <div {...provided.draggableProps} ref={provided.innerRef}>
                              {sectionList(section, index, provided)}
                            </div>
                          )}
                        </Draggable>
                      )}
                    </>
                  ))}
                </div>
              )}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      {archivedGroups && archivedGroups.length > 0 && (isCoach || isPartnerCoach) && (
        <div className="sections-list archived">
          <div>
            <div className="section">
              <div
                className={`section-item justify-content-start ${openedSections.includes('archived') ? 'open' : ''}`}
                tabIndex="0"
                role="button"
                onKeyDown={() => {}}
                onClick={() => toggleSection('archived')}
              >
                <ArrowForwardIosIcon className="accordian-arrow" />
                <span className="title">Archived Group(s)</span>
              </div>
            </div>
            <div className="groups-list">
              {openedSections.includes('archived') &&
                archivedGroups?.map((group, groupIndex) => (
                  <div
                    className={`section-group ${groupIndex === archivedGroups.length - 1 ? 'mb-5' : ''} ${
                      currentGroup?.id === group.id ? 'active-group' : ''
                    }`}
                  >
                    <div
                      className={`item ${group.isNotificationsMuted && group.unreadCount ? 'muted-item' : ''}`}
                      tabIndex="0"
                      role="button"
                      onKeyDown={() => {}}
                      onClick={() => openGroup(group)}
                    >
                      <ArrowForwardIosIcon className="accordian-arrow invisible" />
                      {isCoach && (
                        <span className="invisible">
                          <DragIndicatorIcon className="drag-icon" />
                        </span>
                      )}
                      <div className="item-data">
                        {getGroupImage(group.imageUrl)}
                        <span className="title">
                          <Tooltip
                            placement="bottom-start"
                            title={group.title}
                            enterTouchDelay={TOOLTIP.ENTER_DELAY}
                            leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                          >
                            <span>{group.title}</span>
                          </Tooltip>
                        </span>
                      </div>
                    </div>
                    <div className={`options ${group.unreadCount && group.isNotificationsMuted ? 'gap' : ''}`}>
                      {group?.unreadCount ? (
                        <span
                          className={`unread-count ${!group.isNotificationsMuted ? 'mr-1' : ''}`}
                          role="button"
                          tabIndex="0"
                          onClick={() => openGroup(group)}
                          onKeyDown={() => {}}
                        >
                          {group?.unreadCount}
                        </span>
                      ) : null}
                      <div className="d-flex">
                        {group.isNotificationsMuted && (
                          <MuteIcon
                            className="mute-icon"
                            fillColor={isDarkThemeEnabled ? 'white' : '#979797'}
                            role="button"
                            tabIndex="0"
                            onClick={() => openGroup(group)}
                            onKeyDown={() => {}}
                          />
                        )}
                        {isCoach && (
                          <IconButton
                            className="options"
                            id={`group-option-${group.id}`}
                            aria-label="more"
                            aria-controls={
                              getElementId('group') === `group-option-${group?.id}` ? 'group-menu' : undefined
                            }
                            aria-expanded={getElementId('group') === `group-option-${group?.id}` ? 'true' : undefined}
                            aria-haspopup="true"
                            onClick={e => {
                              setGroupElement(e.target);
                              setGroupToEdit(group);
                            }}
                            style={{ color: isDarkThemeEnabled ? 'white' : '#979797' }}
                          >
                            <MoreVertIcon />
                          </IconButton>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      )}

      <SectionMenu
        anchorElement={sectionElement}
        handleClose={() => setSectionElement(null)}
        handleSelect={handleSectionMenuSelect}
        labelledBy={getElementId('section')}
        isDarkThemeEnabled={isDarkThemeEnabled}
      />

      <GroupMenu
        anchorElement={groupElement}
        handleClose={() => {
          setGroupElement(null);
          setTimeout(() => {
            setGroupToEdit(null);
          }, 600);
        }}
        handleSelect={handleGroupSelect}
        labelledBy={getElementId('group')}
        isCoach={isCoach}
        groupToEdit={groupToEdit}
        sections={sections}
        isDarkThemeEnabled={isDarkThemeEnabled}
      />
      <Modal
        isOpen={modalVisible}
        onSubmit={handleCloseModal}
        title={'Community Settings'}
        hiddenCancel
        submitTitle={'Save'}
        onCancel={() => {
          setModalVisible(false);
        }}
        headerBorder
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '495px',
            borderBottom: '1px solid #DFE3E4',
            height: '60px',
            marginTop: '10px',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <div
            style={{
              fontSize: '14px',
              fontWeight: '800',
              fontFamily: 'Avenir',
              color: isDarkThemeEnabled ? 'white' : '#213649',
            }}
          >
            Enable Client Posting
          </div>
          <span style={{ display: 'flex', marginTop: '10px' }}>
            <Switch className="switch" style={{ marginLeft: 8, marginRight: 8 }}>
              <StyledInput
                type="checkbox"
                onClick={() => {
                  setArePublicPostsAllowed(!arePublicPostsAllowed);
                }}
                checked={arePublicPostsAllowed}
              />
              <StyledSlider
                className="slider round"
                style={{ backgroundColor: isDarkThemeEnabled && arePublicPostsAllowed && colorToUse?.PrimaryColorCode }}
              />
            </Switch>
          </span>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '495px',
            height: '60px',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <div
            style={{
              fontSize: '14px',
              fontWeight: '800',
              fontFamily: 'Avenir',
              color: isDarkThemeEnabled ? 'white' : '#213649',
            }}
          >
            Admin Approval For Client Posts (Coach and Partner Coaches)
          </div>
          <span style={{ display: 'flex', marginTop: '20px' }}>
            <Switch className="switch" style={{ marginLeft: 8, marginRight: 8 }}>
              <StyledInput
                type="checkbox"
                onClick={() => {
                  setIsPostApprovalRequired(!isPostApprovalRequired);
                }}
                checked={isPostApprovalRequired}
              />
              <StyledSlider
                className="slider round"
                style={{
                  backgroundColor: isDarkThemeEnabled && isPostApprovalRequired && colorToUse?.PrimaryColorCode,
                }}
              />
            </Switch>
          </span>
        </div>
      </Modal>
    </div>
  ) : (
    <div className={`community-right-section ${isDarkThemeEnabled ? 'dark-mode-right-section' : ''}`}>
      <div className="right-section-links">
        <StyledNavLink
          themedColor={themedColors.themedColor}
          to={`${url}${pathname.includes('social') ? '/social' : ''}`}
          exact
          isActive={location => {
            return !Location?.search && location?.isExact;
          }}
          mobileView={mobileView}
          className="right-section-link"
        >
          <div className="right-section-item">
            <HomeIcon fillColor={colorToUse?.PrimaryColorCode} /> Main Community
          </div>
          {mainGroup?.unreadCount ? <span className="unread-count">{mainGroup?.unreadCount}</span> : null}
        </StyledNavLink>
        {contribution?.isGroupChatHidden === false && shouldShowChatTab && (
          <StyledNavLink
            themedColor={themedColors.themedColor}
            to={`${url}/conversations`}
            mobileView={mobileView}
            firstLink
            className="right-section-link"
          >
            <div className="right-section-item">
              <ModeCommentIcon style={{ color: colorToUse?.PrimaryColorCode }} /> Chat
            </div>
          </StyledNavLink>
        )}
        {canAccessClients && (
          <StyledNavLink
            themedColor={themedColors.themedColor}
            to={`${url}/clients`}
            mobileView={mobileView}
            firstLink
            className="right-section-link"
          >
            <div className="right-section-item">
              <GroupIcon fillColor={colorToUse?.PrimaryColorCode} />
              {contribution.type === ContributionType.contributionMembership ||
              contribution.type === ContributionType.contributionCourse ||
              contribution.type === ContributionType.contributionCommunity
                ? 'Members'
                : 'Clients'}
            </div>
          </StyledNavLink>
        )}
      </div>

      {isCoach && (
        <div className="add-item" tabIndex="0" role="button" onKeyDown={() => {}} onClick={() => createSection()}>
          <IoAddCircleOutline /> Create Section
        </div>
      )}

      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="sections" type="section">
          {provided => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {sections && (
                <div className={`sections-list ${!isCoach ? 'margin-top' : ''}`}>
                  {sections.map((section, index) => (
                    <>
                      {section.isAnonymous && section?.groups.length === 0 ? null : (
                        <Draggable
                          draggableId={section.sectionId}
                          key={section.sectionId}
                          index={index}
                          isDragDisabled={!isCoach}
                        >
                          {provided => (
                            <div {...provided.draggableProps} ref={provided.innerRef}>
                              {sectionList(section, index, provided)}
                            </div>
                          )}
                        </Draggable>
                      )}
                    </>
                  ))}
                </div>
              )}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      {archivedGroups && archivedGroups.length > 0 && (isCoach || isPartnerCoach) && (
        <div className="sections-list archived">
          <div>
            <div className="section">
              <div
                className={`section-item justify-content-start ${openedSections.includes('archived') ? 'open' : ''}`}
                tabIndex="0"
                role="button"
                onKeyDown={() => {}}
                onClick={() => toggleSection('archived')}
              >
                <ArrowForwardIosIcon className="accordian-arrow" />
                <span className="title">Archived Group(s)</span>
              </div>
            </div>
            <div className="groups-list">
              {openedSections.includes('archived') &&
                archivedGroups?.map((group, groupIndex) => (
                  <div
                    className={`section-group ${groupIndex === archivedGroups.length - 1 ? 'mb-5' : ''} ${
                      currentGroup?.id === group.id ? 'active-group' : ''
                    }`}
                  >
                    <div
                      className={`item ${group.isNotificationsMuted && group.unreadCount ? 'muted-item' : ''}`}
                      tabIndex="0"
                      role="button"
                      onKeyDown={() => {}}
                      onClick={() => openGroup(group)}
                    >
                      <ArrowForwardIosIcon className="accordian-arrow invisible" />
                      {isCoach && (
                        <span className="invisible">
                          <DragIndicatorIcon className="drag-icon" />
                        </span>
                      )}
                      <div className="item-data">
                        {getGroupImage(group.imageUrl)}
                        <span className="title">
                          <Tooltip
                            placement="bottom-start"
                            title={group.title}
                            enterTouchDelay={TOOLTIP.ENTER_DELAY}
                            leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                          >
                            <span>{group.title}</span>
                          </Tooltip>
                        </span>
                      </div>
                    </div>
                    <div className={`options ${group.unreadCount && group.isNotificationsMuted ? 'gap' : ''}`}>
                      {group?.unreadCount ? (
                        <span
                          className={`unread-count ${!group.isNotificationsMuted ? 'mr-1' : ''}`}
                          role="button"
                          tabIndex="0"
                          onClick={() => openGroup(group)}
                          onKeyDown={() => {}}
                        >
                          {group?.unreadCount}
                        </span>
                      ) : null}
                      <div className="d-flex">
                        {group.isNotificationsMuted && (
                          <MuteIcon
                            className="mute-icon"
                            fillColor={isDarkThemeEnabled ? 'white' : '#979797'}
                            role="button"
                            tabIndex="0"
                            onClick={() => openGroup(group)}
                            onKeyDown={() => {}}
                          />
                        )}
                        {isCoach && (
                          <IconButton
                            className="options"
                            id={`group-option-${group.id}`}
                            aria-label="more"
                            aria-controls={
                              getElementId('group') === `group-option-${group?.id}` ? 'group-menu' : undefined
                            }
                            aria-expanded={getElementId('group') === `group-option-${group?.id}` ? 'true' : undefined}
                            aria-haspopup="true"
                            onClick={e => {
                              setGroupElement(e.target);
                              setGroupToEdit(group);
                            }}
                            style={{ color: isDarkThemeEnabled ? 'white' : '#979797' }}
                          >
                            <MoreVertIcon />
                          </IconButton>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      )}

      <SectionMenu
        anchorElement={sectionElement}
        handleClose={() => setSectionElement(null)}
        handleSelect={handleSectionMenuSelect}
        labelledBy={getElementId('section')}
        isDarkThemeEnabled={isDarkThemeEnabled}
      />

      <GroupMenu
        anchorElement={groupElement}
        handleClose={() => {
          setGroupElement(null);
          setTimeout(() => {
            setGroupToEdit(null);
          }, 600);
        }}
        handleSelect={handleGroupSelect}
        labelledBy={getElementId('group')}
        isCoach={isCoach}
        groupToEdit={groupToEdit}
        sections={sections}
        isDarkThemeEnabled={isDarkThemeEnabled}
      />
    </div>
  );
  // isCoach ?
  // (

  //   <div className={`community-right-section ${isDarkThemeEnabled ? 'dark-mode-right-section' : ''}`}>
  //     <div className="right-section-links">
  //       <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
  //         <StyledNavLink
  //           themedColor={themedColors.themedColor}
  //           to={`${url}${pathname.includes('social') ? '/social' : ''}`}
  //           exact
  //           isActive={location => {
  //             return !Location?.search && location?.isExact;
  //           }}
  //           mobileView={mobileView}
  //           className="right-section-link"
  //           style={{ width: '90%' }}
  //         >
  //           <div className="right-section-item">
  //             <HomeIcon fillColor={colorToUse?.PrimaryColorCode} /> Main Community
  //           </div>
  //           {mainGroup?.unreadCount ? <span className="unread-count">{mainGroup?.unreadCount}</span> : null}
  //         </StyledNavLink>
  //         <div
  //           style={{ width: '10%', justifyContent: 'center', alignItems: 'center', display: 'flex' }}
  //           onClick={e => {
  //             setModalVisible(true);
  //           }}
  //           className="right-section-item"
  //         >
  //           <SettingsIcon fillColor={'#4A4A4A'} />
  //         </div>
  //       </div>
  //       {contribution?.isGroupChatHidden === false && shouldShowChatTab && (
  //         <StyledNavLink
  //           themedColor={themedColors.themedColor}
  //           to={`${url}/conversations`}
  //           mobileView={mobileView}
  //           firstLink
  //           className="right-section-link"
  //           style={{ width: '90%' }}
  //         >
  //           <div className="right-section-item">
  //             <ModeCommentIcon style={{ color: colorToUse?.PrimaryColorCode }} /> Chat
  //           </div>
  //         </StyledNavLink>
  //       )}
  //       {canAccessClients && (
  //         <StyledNavLink
  //           themedColor={themedColors.themedColor}
  //           to={`${url}/clients`}
  //           mobileView={mobileView}
  //           firstLink
  //           className="right-section-link"
  //           style={{ width: '90%' }}
  //         >
  //           <div className="right-section-item">
  //             <GroupIcon fillColor={colorToUse?.PrimaryColorCode} />
  //             {contribution.type === ContributionType.contributionMembership ||
  //             contribution.type === ContributionType.contributionCourse ||
  //             contribution.type === ContributionType.contributionCommunity
  //               ? 'Members'
  //               : 'Clients'}
  //           </div>
  //         </StyledNavLink>
  //       )}
  //     </div>

  //     {isCoach && (
  //       <div className="add-item" tabIndex="0" role="button" onKeyDown={() => {}} onClick={() => createSection()}>
  //         <IoAddCircleOutline /> Create Section
  //       </div>
  //     )}

  //     <DragDropContext onDragEnd={handleDragEnd}>
  //       <Droppable droppableId="sections" type="section">
  //         {provided => (
  //           <div {...provided.droppableProps} ref={provided.innerRef}>
  //             {sections && (
  //               <div className={`sections-list ${!isCoach ? 'margin-top' : ''}`}>
  //                 {sections.map((section, index) => (
  //                   <>
  //                     {section.isAnonymous && section?.groups.length === 0 ? null : (
  //                       <Draggable
  //                         draggableId={section.sectionId}
  //                         key={section.sectionId}
  //                         index={index}
  //                         isDragDisabled={!isCoach}
  //                       >
  //                         {provided => (
  //                           <div {...provided.draggableProps} ref={provided.innerRef}>
  //                             {sectionList(section, index, provided)}
  //                           </div>
  //                         )}
  //                       </Draggable>
  //                     )}
  //                   </>
  //                 ))}
  //               </div>
  //             )}
  //             {provided.placeholder}
  //           </div>
  //         )}
  //       </Droppable>
  //     </DragDropContext>

  //     {archivedGroups && archivedGroups.length > 0 && (
  //       <div className="sections-list archived">
  //         <div>
  //           <div className="section">
  //             <div
  //               className={`section-item justify-content-start ${openedSections.includes('archived') ? 'open' : ''}`}
  //               tabIndex="0"
  //               role="button"
  //               onKeyDown={() => {}}
  //               onClick={() => toggleSection('archived')}
  //             >
  //               <ArrowForwardIosIcon className="accordian-arrow" />
  //               <span className="title">Archived Group(s)</span>
  //             </div>
  //           </div>
  //           <div className="groups-list">
  //             {openedSections.includes('archived') &&
  //               archivedGroups?.map((group, groupIndex) => (
  //                 <div
  //                   className={`section-group ${groupIndex === archivedGroups.length - 1 ? 'mb-5' : ''} ${
  //                     currentGroup?.id === group.id ? 'active-group' : ''
  //                   }`}
  //                 >
  //                   <div
  //                     className={`item ${group.isNotificationsMuted && group.unreadCount ? 'muted-item' : ''}`}
  //                     tabIndex="0"
  //                     role="button"
  //                     onKeyDown={() => {}}
  //                     onClick={() => openGroup(group)}
  //                   >
  //                     <ArrowForwardIosIcon className="accordian-arrow invisible" />
  //                     {isCoach && (
  //                       <span className="invisible">
  //                         <DragIndicatorIcon className="drag-icon" />
  //                       </span>
  //                     )}
  //                     <div className="item-data">
  //                       {getGroupImage(group.imageUrl)}
  //                       <span className="title">
  //                         <Tooltip
  //                           placement="bottom-start"
  //                           title={group.title}
  //                           enterTouchDelay={TOOLTIP.ENTER_DELAY}
  //                           leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
  //                         >
  //                           <span>{group.title}</span>
  //                         </Tooltip>
  //                       </span>
  //                     </div>
  //                   </div>
  //                   <div className={`options ${group.unreadCount && group.isNotificationsMuted ? 'gap' : ''}`}>
  //                     {group?.unreadCount ? (
  //                       <span
  //                         className={`unread-count ${!group.isNotificationsMuted ? 'mr-1' : ''}`}
  //                         role="button"
  //                         tabIndex="0"
  //                         onClick={() => openGroup(group)}
  //                         onKeyDown={() => {}}
  //                       >
  //                         {group?.unreadCount}
  //                       </span>
  //                     ) : null}
  //                     <div className="d-flex">
  //                       {group.isNotificationsMuted && (
  //                         <MuteIcon
  //                           className="mute-icon"
  //                           fillColor={isDarkThemeEnabled ? 'white' : '#979797'}
  //                           role="button"
  //                           tabIndex="0"
  //                           onClick={() => openGroup(group)}
  //                           onKeyDown={() => {}}
  //                         />
  //                       )}
  //                       {isCoach && (
  //                         <IconButton
  //                           className="options"
  //                           id={`group-option-${group.id}`}
  //                           aria-label="more"
  //                           aria-controls={
  //                             getElementId('group') === `group-option-${group?.id}` ? 'group-menu' : undefined
  //                           }
  //                           aria-expanded={getElementId('group') === `group-option-${group?.id}` ? 'true' : undefined}
  //                           aria-haspopup="true"
  //                           onClick={e => {
  //                             setGroupElement(e.target);
  //                             setGroupToEdit(group);
  //                           }}
  //                           style={{ color: isDarkThemeEnabled ? 'white' : '#979797' }}
  //                         >
  //                           <MoreVertIcon />
  //                         </IconButton>
  //                       )}
  //                     </div>
  //                   </div>
  //                 </div>
  //               ))}
  //           </div>
  //         </div>
  //       </div>
  //     )}

  //     <SectionMenu
  //       anchorElement={sectionElement}
  //       handleClose={() => setSectionElement(null)}
  //       handleSelect={handleSectionMenuSelect}
  //       labelledBy={getElementId('section')}
  //       isDarkThemeEnabled={isDarkThemeEnabled}
  //     />

  //     <GroupMenu
  //       anchorElement={groupElement}
  //       handleClose={() => {
  //         setGroupElement(null);
  //         setTimeout(() => {
  //           setGroupToEdit(null);
  //         }, 600);
  //       }}
  //       handleSelect={handleGroupSelect}
  //       labelledBy={getElementId('group')}
  //       isCoach={isCoach}
  //       groupToEdit={groupToEdit}
  //       sections={sections}
  //       isDarkThemeEnabled={isDarkThemeEnabled}
  //     />
  //     <Modal
  //       isOpen={modalVisible}
  //       onSubmit={handleCloseModal}
  //       title={'Community Posts Settings'}
  //       hiddenCancel
  //       submitTitle={'Save'}
  //       onCancel={() => {
  //         setModalVisible(false);
  //       }}
  //       headerBorder
  //     >
  //       <div
  //         style={{
  //           display: 'flex',
  //           flexDirection: 'row',
  //           width: '495px',
  //           borderBottom: '1px solid #DFE3E4',
  //           height: '60px',
  //           marginTop: '10px',
  //           alignItems: 'center',
  //           justifyContent: 'space-between',
  //         }}
  //       >
  //         <div style={{ fontSize: '14px', fontWeight: '800', fontFamily: 'Avenir', color: '#213649' }}>
  //           Enable Client Posting
  //         </div>
  //         <span style={{ display: 'flex', marginTop: '20px' }}>
  //           <Switch className="switch" style={{ marginLeft: 8, marginRight: 8 }}>
  //             <StyledInput
  //               type="checkbox"
  //               onClick={() => {
  //                 setArePublicPostsAllowed(!arePublicPostsAllowed);
  //               }}
  //               checked={arePublicPostsAllowed}
  //             />
  //             <StyledSlider className="slider round" />
  //           </Switch>
  //         </span>
  //       </div>
  //       <div
  //         style={{
  //           display: 'flex',
  //           flexDirection: 'row',
  //           width: '495px',
  //           height: '60px',
  //           alignItems: 'center',
  //           justifyContent: 'space-between',
  //         }}
  //       >
  //         <div style={{ fontSize: '14px', fontWeight: '800', fontFamily: 'Avenir', color: '#213649' }}>
  //           Admin Approval required for Client posting
  //         </div>
  //         <span style={{ display: 'flex', marginTop: '20px' }}>
  //           <Switch className="switch" style={{ marginLeft: 8, marginRight: 8 }}>
  //             <StyledInput
  //               type="checkbox"
  //               onClick={() => {
  //                 setIsPostApprovalRequired(!isPostApprovalRequired);
  //               }}
  //               checked={isPostApprovalRequired}
  //             />
  //             <StyledSlider className="slider round" />
  //           </Switch>
  //         </span>
  //       </div>
  //     </Modal>
  //   </div>
  // ) : (

  // );
};

const mapStateToProps = ({ communitySections: { mainGroup } }) => ({
  mainGroup,
});

LeftSectionMenu.propTypes = {
  contribution: PropTypes.shape({
    type: string,
    isMembersHiddenInCommunity: bool,
    id: string,
    isGroupChatHidden: bool,
    isPurchased: bool,
  }).isRequired,
  currentRole: PropTypes.string.isRequired,
  themedColors: PropTypes.shape({ themedBackgroundColor: string, themedColor: string }).isRequired,
  mobileView: PropTypes.string.isRequired,
  pathname: PropTypes.string.isRequired,
  createSection: PropTypes.func.isRequired,
  sections: PropTypes.shape([]).isRequired,
  editSection: PropTypes.func.isRequired,
  deleteSection: PropTypes.func.isRequired,
  archivedGroups: PropTypes.shape([]).isRequired,
  createGroup: PropTypes.func.isRequired,
  handleGroupActions: PropTypes.func.isRequired,
  currentGroup: PropTypes.shape({ id: string, sectionId: string, isArchived: bool }).isRequired,
  mainGroup: PropTypes.shape({ unreadCount: number }).isRequired,
  isDarkThemeEnabled: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps, [])(LeftSectionMenu);
