import { useEffect, useState } from 'react';
import { useHttp } from 'hooks';

import { prepareSessions } from '../utils';

export const useOtherEvents = isOneToOne => {
  const [otherEvents, setOtherEvents] = useState([]);
  const { request } = useHttp();

  useEffect(() => {
    if (isOneToOne) {
      const getOtherEvents = async () => {
        const data = await request('/Contribution/GetCohealerContributionsTimeRanges', 'GET');
        setOtherEvents(
          prepareSessions({
            availabilityTimes: data.map(({ sessionName, sessionStartTime, sessionEndTime, ...other }) => ({
              title: sessionName,
              startTime: sessionStartTime,
              endTime: sessionEndTime,
              ...other,
            })),
          }),
        );
      };
      getOtherEvents();
    }
  }, [isOneToOne, request]);

  return { otherEvents };
};
