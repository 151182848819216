import { createAction } from 'redux-actions';
import { get, pipe } from 'lodash/fp';

import { getBankAccounts } from 'services/bankAccounts.service';

export const GET_BANK_ACCOUNTS_REQUEST = 'GET_BANK_ACCOUNTS_REQUEST';
export const GET_BANK_ACCOUNTS_SUCCESS = 'GET_BANK_ACCOUNTS_SUCCESS';
export const GET_BANK_ACCOUNTS_ERROR = 'GET_BANK_ACCOUNTS_ERROR';

export const fetchBankAccountsActions = {
  request: createAction(GET_BANK_ACCOUNTS_REQUEST),
  success: createAction(GET_BANK_ACCOUNTS_SUCCESS),
  error: createAction(GET_BANK_ACCOUNTS_ERROR),
};

export const fetchBalance = () => async dispatch => {
  dispatch(fetchBankAccountsActions.request());

  try {
    const response = await getBankAccounts();
    dispatch(fetchBankAccountsActions.success(response));
  } catch (error) {
    pipe(get('response'), get('data'), fetchBankAccountsActions.error, dispatch)(error);
  }
};
