import { SvgIcon } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

function EmoticonIcon({ fillColor = '#213649' }) {
  return (
    <SvgIcon>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 32 32" fill="none">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.6068 12.543C27.8548 16.417 26.9908 20.833 23.9208 23.905C21.8068 26.019 18.9928 27.183 16.0008 27.183C13.0088 27.183 10.1968 26.019 8.08076 23.905C3.71476 19.535 3.71476 12.431 8.08076 8.06498C10.1968 5.94698 13.0088 4.78298 16.0008 4.78298C17.0948 4.78298 18.1488 4.99098 19.1688 5.29098V2.36098C18.1268 2.11898 17.0648 1.98498 16.0008 1.98498C12.4188 1.98498 8.83476 3.35098 6.10076 6.08498C0.632756 11.551 0.632756 20.417 6.10076 25.883C8.83476 28.615 12.4188 29.985 16.0008 29.985C19.5848 29.985 23.1668 28.615 25.9008 25.883C29.5188 22.267 30.7288 17.167 29.5588 12.543H26.6068ZM28.117 3.87845V0.000976562H25.8703V3.87845H21.9928V6.13084H25.8703V10.001H28.117V6.13084H31.9928V3.87845H28.117ZM7.91276 17.937C8.26076 19.383 8.97076 20.757 10.0988 21.887C11.7288 23.515 13.8648 24.331 16.0008 24.331C18.1388 24.331 20.2728 23.515 21.9028 21.887C23.0308 20.757 23.7408 19.383 24.0888 17.937H21.5488C21.2648 18.747 20.8188 19.511 20.1748 20.159C19.0608 21.273 17.5768 21.887 16.0008 21.887C14.4248 21.887 12.9428 21.273 11.8268 20.159C11.1808 19.511 10.7368 18.747 10.4548 17.937H7.91276ZM17.4108 11.937C17.4108 13.043 18.3068 13.937 19.4108 13.937C20.5168 13.937 21.4108 13.043 21.4108 11.937C21.4108 10.833 20.5168 9.93698 19.4108 9.93698C18.3068 9.93698 17.4108 10.833 17.4108 11.937ZM14.5768 11.937C14.5768 10.833 13.6828 9.93698 12.5768 9.93698C11.4728 9.93698 10.5768 10.833 10.5768 11.937C10.5768 13.043 11.4728 13.937 12.5768 13.937C13.6828 13.937 14.5768 13.043 14.5768 11.937Z"
          fill={fillColor}
        />
      </svg>
    </SvgIcon>
  );
}

EmoticonIcon.propTypes = {
  fillColor: PropTypes.string.isRequired,
};

export default EmoticonIcon;
