import React, { useCallback, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import { FaFacebookF, FaLinkedinIn, FaTiktok } from 'react-icons/fa';
import { AiFillInstagram, AiFillYoutube } from 'react-icons/ai';
import { HiGlobeAlt } from 'react-icons/hi';
import { Avatar, Grid, makeStyles } from '@material-ui/core';
import { LabelText, LabelText2 } from 'components/UI/Text/TextStyles';
import { useAccount, useRouter } from 'hooks';
import chatService from 'services/chat.service';
import getInitials from 'utils/getInitials';
import styled, { css } from 'styled-components';
import { useTheme, useMediaQuery, TextField } from '@material-ui/core';
import useContribution from 'pages/ContributionView/hooks/useContribution';
import { determineColorToUse, getThemedColors } from 'services/contributions.service';
import {
  BorderDiv,
  MeetYourCoachContainer,
  MeetCoachTxt,
  CoachProfileMainContainer,
  ProfileTxtLeft,
  ProfileLeftImgCont,
  ProfileLeftImg,
  MeetCoachTxt2,
  CoachProfileMainContainer2,
} from './AboutMainInfo.styles';
import sarahImg from '../../../../assets/sarah-img.png';
import { useHttp, useShallowEqualSelector } from 'hooks';
import { CoachItem } from './CoachItem';
import { useState } from 'react';
import { ColorSchemeService } from 'services/color.service';
import { addhttp } from 'utils/utils';
import Loader from 'components/UI/Loader';
import { getUserProfileStatus } from 'services/profileLink.service';
import { lightOrDark } from 'utils/utils';
import useLeadMagnetTheme from 'hooks/useLeadMagnetTheme';
import { useLeadMagnet } from 'pages/LeadMagnetView/hooks';

// const ProfileLeftImg = styled.img`
//   width: 3.625rem;
// `;

export const StyledLine = styled.div`
  width: 100%;
  height: 1px;
  margin-top: 50px;
  margin-bottom: 40px;
  background-color: ${({ color }) => color};
`;
export const StyledFaFacebookF = styled(FaFacebookF)`
  color: ${({ color }) => color};
  height: 25px;
  width: 25px;
  margin: 0px 5px;

  ${({ mobileView }) =>
    mobileView &&
    `
  height: 15px;
  width: 15px;
  `}
`;
export const StyledFaLinkedinIn = styled(FaLinkedinIn)`
  color: ${({ color }) => color};
  height: 25px;
  width: 25px;
  margin: 0px 5px;

  ${({ mobileView }) =>
    mobileView &&
    `
  height: 15px;
  width: 15px;
  `}
`;
export const StyledAiFillInstagram = styled(AiFillInstagram)`
  color: ${({ color }) => color};
  height: 25px;
  width: 25px;
  margin: 0px 5px;

  ${({ mobileView }) =>
    mobileView &&
    `
  height: 15px;
  width: 15px;
  `}
`;
export const StyledAiFillYoutube = styled(AiFillYoutube)`
  color: ${({ color }) => color};
  height: 25px;
  width: 25px;
  margin: 0px 5px;

  ${({ mobileView }) =>
    mobileView &&
    `
  height: 15px;
  width: 15px;
  `}
`;

export const StyledHiGlobeAlt = styled(HiGlobeAlt)`
  color: ${({ color }) => color};
  height: 25px;
  width: 25px;
  margin: 0px 5px;

  ${({ mobileView }) =>
    mobileView &&
    `
  height: 15px;
  width: 15px;
  `}
`;

export const StyledFaTiktok = styled(FaTiktok)`
  color: ${({ color }) => color};
  height: 25px;
  width: 25px;
  margin: 0px 5px;

  ${({ mobileView }) =>
    mobileView &&
    `
  height: 15px;
  width: 15px;
  `}
`;

const ProfileRightTxt = styled.div`
  padding-left: ${({ mobileView }) => (mobileView ? '0px' : '1.375rem')};
  margin-top: ${({ mobileView }) => (mobileView ? '10px' : '0px')};
  text-align: ${({ mobileView }) => (mobileView ? 'left' : '')};
`;
const ProfileUserName = styled.h4`
  margin: 0px;
  font-style: normal;
  font-weight: 420;
  font-size: 14px;
  line-height: 19px;

  color: #282b2b;
`;
const ProfileUserLocation = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #474747;
`;
const ProfileParagraph = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #282c2c;
  max-width: ${({ isLeadMagnet }) => (isLeadMagnet ? '100%' : '31.25rem')};
  /* max-width: 31.25rem; */
`;
const ProfileParagraphMobile = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #282c2c;
  max-width: 31.25rem;
`;
const ProfileRightButton = styled.div``;
const StyledButton = styled.button`
  position: relative;
  padding: 0.7rem 1rem;
  font-size: 16px;
  border-radius: 5px;
  border: none;
  color: ${props => props.color};
  fill: #fff; /* for svg */
  font-weight: 500;
  cursor: pointer;
  text-decoration: none;
  line-height: 1rem;
  letter-spacing: 1.25px;
  min-width: 6rem;
  transition: background 0.2s;
  font-family: 'Poppins';
  background: ${props => props.background};
  font-style: normal;
  font-weight: 400;
  margin: ${props => props.margin};
  max-width: ${props => props.maxWidth};
`;

const MeetCoachTxt22 = styled.h4`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 50px;
  color: #282c2c;
`;

const HyperLink = styled.a.attrs({ target: '_blank' })``;

const SocialLinks = styled.div`
  width: ${({ mobileView }) => (mobileView ? '100%' : '100px')};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const useStyles = makeStyles(theme => ({
  Avatar: {
    width: '65px',
    height: '65px',
  },
}));

const StyledCoachName = styled.div`
  ${props =>
    props.isClickable &&
    css`
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    `}
`;

function MeetYourCoach({
  // user,
  id,
  userId,
  bio,
  coachCountry,
  customTitleForMeetYourCoach,
  previewContentUrls,
  serviceProviderName,
  contributionPartners,
  socialUrls,
  firstName,
  lastName,
  isCustomBrandingColorsActive,
  brandingColors,
  mobileView,
  isLeadMagnet = false,
  coachAvatarUrl,
}) {
  const { instagramUrl, linkedInUrl, youtubeUrl, facebookUrl, websiteUrl, tiktokUrl } = socialUrls;
  const AvatarUrl = styled.a``;
  const avatarUrl = useShallowEqualSelector(state => state?.cohealerInfo?.avatarUrl);
  const theme = useTheme();
  const classNames = useStyles();
  // const mobileView = useMediaQuery(theme.breakpoints.down('xs'));
  const history = useRouter();
  const { user } = useAccount();
  const [Style1, setStyle1] = useState(false);
  const [Style2, setStyle2] = useState(true);
  const [loadingCoachProfile, setLoadingCoachProfile] = useState(false);
  const { domain, parentDomain } = useRouter();
  const leadMagnet = useLeadMagnet();
  const contribution = useContribution();
  const colorToUseContribution = determineColorToUse(contribution);
  const { themedColor } = getThemedColors(contribution);
  const { themedColors, colorToUse: colorToUseLeadMagnet } = useLeadMagnetTheme();
  const newThemedColor = isLeadMagnet ? themedColors.themedColor : themedColor;
  const colorToUse = isLeadMagnet ? colorToUseLeadMagnet : colorToUseContribution;
  const [leadMagnetCoachProfileUrl, setLeadMagnetCoachProfileUrl] = useState('');
  const textColorToUse =
    colorToUse?.TextColorCode === 'Auto'
      ? lightOrDark(colorToUse?.PrimaryColorCode)
      : colorToUse?.TextColorCode === '#000000'
      ? '#000000'
      : '#FFFFFF';
  const newIconCOlor = isLeadMagnet ? colorToUse.AccentColorCode : colorToUse.PrimaryColorCode;
  // const { PrimaryColorCode } = brandingColors;
  // const PrimaryColor = isCustomBrandingColorsActive ? PrimaryColorCode : '#D08ACD';
  const PrimaryColor = ColorSchemeService().PrimaryColorCode;
  // const handleSendMessageToCoach = useCallback(() => {
  //   if (isEmpty(user)) {
  //     history.push(`/cohealer-about/${userId}/about`);
  //   } else if (userId !== user.id) {
  //     window.location.href = addhttp(history?.domain?.concat(`/conversations/all?chatId=${userId}`));
  //   }
  // }, [history, user, userId]);

  useLayoutEffect(() => {
    getUserProfileStatus(userId).then(resp => {
      if (resp.data) {
        const { isProfileActive, profileName } = resp.data;

        if (isProfileActive && profileName) {
          const coachProfileURL = addhttp(profileName.concat('.', parentDomain));

          setLeadMagnetCoachProfileUrl(coachProfileURL);
        }
      }
    });
  }, [userId]);

  const handleCoachNameClick = _userId => {
    setLoadingCoachProfile(true);

    return getUserProfileStatus(_userId)
      .then(resp => {
        if (resp.data) {
          const { isProfileActive, profileName } = resp.data;

          if (isProfileActive && profileName) {
            const coachProfileURL = addhttp(profileName.concat('.', parentDomain));

            window.open(coachProfileURL, '_blank').focus();
            return;
          }
          // TODO: open 1:1 chat
          // User not logged-in
          if (isEmpty(user)) {
            window.location.href = addhttp(domain.concat(`/conversations/all?chatId=${userId}`));
            return;
          }
          // Logged-in user
          // window.open(addhttp(domain.concat(`/conversations/all?chatId=${userId}`)), '_blank').focus();
          window.location.href = addhttp(domain.concat(`/conversations/all?chatId=${userId}`));
        } else {
          // API call resolved but returned invalid data i.e. null
        }
      })
      .catch(() => {
        // API failed
      })
      .finally(() => {
        setLoadingCoachProfile(false);
      });
  };

  return (
    <>
      {!isLeadMagnet && (
        <div
          style={{
            margin: '5rem 0px',
            marginBottom: '40px',
            border: `1px solid ${contribution?.isDarkModeEnabled ? '#696969' : '#0000000D'}`,
          }}
        ></div>
      )}
      {/* <BorderDiv margin="5rem 0px"></BorderDiv> */}
      {/* <CoachItem
        serviceProviderName={serviceProviderName}
        avatarUrl={avatarUrl}
        userId={userId}
        mobileView={mobileView}
      /> */}
      {/* <Grid item>
        <Grid item container alignItems="center">
          <Avatar src={avatarUrl} className="mx-2">
            {getInitials(serviceProviderName || `${firstName} ${lastName}`)}
          </Avatar>
          <LabelText id="coach-name" mobileView={mobileView} onClick={handleSendMessageToCoach}>
            <Link to="#coach-name">{serviceProviderName || `${firstName} ${lastName}`}</Link>
          </LabelText>
        </Grid>
      </Grid> */}
      {!mobileView && (
        <Grid md={8} sm={8} xs={12}>
          {isLeadMagnet && (
            <Grid>
              <div
                style={{
                  margin: '4.5rem 0px',
                  border: `1px solid ${leadMagnet?.isDarkModeEnabled ? '#4A4A4A' : '#DBE7F2'}`,
                }}
              ></div>
            </Grid>
          )}
          <MeetYourCoachContainer>
            {/* <StyledLine style={{ marginBottom: '66px' }} /> */}
            {/* <StyledLine style={{ marginBottom: '0px' }} /> */}
            {!isLeadMagnet && (
              <MeetCoachTxt2 style={{ color: newThemedColor }} fontWeight="600" fontSize="32px" Color="#282C2C">
                Meet Your {customTitleForMeetYourCoach}
              </MeetCoachTxt2>
            )}
            <CoachProfileMainContainer>
              {loadingCoachProfile && <Loader />}
              <ProfileTxtLeft style={{ color: newThemedColor }}>
                <ProfileLeftImgCont>
                  <Avatar src={isLeadMagnet ? coachAvatarUrl : avatarUrl} className={classNames.Avatar}>
                    {getInitials(serviceProviderName || `${firstName} ${lastName}`)}
                  </Avatar>
                </ProfileLeftImgCont>
              </ProfileTxtLeft>
              <ProfileRightTxt mobileView={mobileView}>
                {/* <ProfileUserName>{serviceProviderName}</ProfileUserName> */}
                <LabelText
                  id="coach-name"
                  style={{ fontFamily: 'Poppins', fontWeight: 500 }}
                  mobileView={mobileView}
                  onClick={() => handleCoachNameClick(userId)}
                >
                  <StyledCoachName isClickable style={{ color: newThemedColor }}>
                    {serviceProviderName || `${firstName} ${lastName}`}
                  </StyledCoachName>
                </LabelText>
                <ProfileUserLocation style={{ color: newThemedColor }}>{coachCountry}</ProfileUserLocation>
              </ProfileRightTxt>
            </CoachProfileMainContainer>
            <Grid xs={isLeadMagnet ? 12 : 8}>
              <ProfileParagraph {...{ isLeadMagnet }} style={{ color: newThemedColor, whiteSpace: 'pre-wrap' }}>
                {bio}
              </ProfileParagraph>
            </Grid>
            {isLeadMagnet && leadMagnetCoachProfileUrl && (
              <>
                <ProfileRightButton>
                  <StyledButton
                    background={colorToUse?.PrimaryColorCode}
                    color="#fff"
                    margin="1rem 0 0 0"
                    onClick={() => window.open(leadMagnetCoachProfileUrl, '_blank').focus()}
                  >
                    Learn More
                  </StyledButton>
                </ProfileRightButton>
                <Grid md={12} sm={12} xs={12}>
                  <div
                    style={{
                      margin: '1rem 0px',
                      border: `1px solid ${leadMagnet?.isDarkModeEnabled ? '#4A4A4A' : '#DBE7F2'}`,
                    }}
                  />
                </Grid>
              </>
            )}

            {contributionPartners &&
              !isLeadMagnet &&
              contributionPartners.map(({ firstName, lastName, avatarUrl, userId: _userId, bio }) => (
                <div>
                  <CoachProfileMainContainer>
                    <ProfileTxtLeft style={{ color: newThemedColor }}>
                      <ProfileLeftImgCont>
                        {/* <ProfileLeftImg src={sarahImg}></ProfileLeftImg> */}
                        <Avatar src={avatarUrl} className={classNames.Avatar}>
                          {getInitials(`${firstName} ${lastName}`)}
                        </Avatar>
                      </ProfileLeftImgCont>
                    </ProfileTxtLeft>
                    <ProfileRightTxt Pl="1.375rem">
                      {/* <ProfileUserName>{serviceProviderName}</ProfileUserName> */}
                      <LabelText id="coach-name" mobileView={mobileView} onClick={() => handleCoachNameClick(_userId)}>
                        <StyledCoachName
                          isClickable
                          style={{ color: newThemedColor }}
                        >{`${firstName} ${lastName}`}</StyledCoachName>
                      </LabelText>
                      <ProfileUserLocation style={{ color: newThemedColor }}>{coachCountry}</ProfileUserLocation>
                    </ProfileRightTxt>
                  </CoachProfileMainContainer>
                  <ProfileParagraph style={{ color: newThemedColor, whiteSpace: 'pre-wrap' }}>{bio}</ProfileParagraph>
                </div>
                // <CoachItem
                //   serviceProviderName={null}
                //   firstName={firstName}
                //   lastName={lastName}
                //   avatarUrl={avatarUrl}
                //   userId={userId}
                //   mobileView={mobileView}
                // />
              ))}
            {(facebookUrl || linkedInUrl || instagramUrl || youtubeUrl || websiteUrl || tiktokUrl) && (
              <Grid item xs={12}>
                <SocialLinks>
                  {facebookUrl && (
                    <HyperLink href={facebookUrl.includes('https://') ? facebookUrl : `https://${facebookUrl}`}>
                      {/* <img style={{ padding: '0px 5px' }} src={fbImg}></img> */}
                      <StyledFaFacebookF color={newIconCOlor} />
                    </HyperLink>
                  )}
                  {linkedInUrl && (
                    <HyperLink href={linkedInUrl.includes('https://') ? linkedInUrl : `https://${linkedInUrl}`}>
                      {/* <img style={{ padding: '0px 5px' }} src={linkedImg}></img> */}
                      <StyledFaLinkedinIn color={newIconCOlor} />
                    </HyperLink>
                  )}
                  {instagramUrl && (
                    <HyperLink href={instagramUrl.includes('https://') ? instagramUrl : `https://${instagramUrl}`}>
                      {/* <img style={{ padding: '0px 5px', width: '40px', height: '30px' }} src={instaImg}></img> */}
                      <StyledAiFillInstagram color={newIconCOlor} />
                    </HyperLink>
                  )}
                  {youtubeUrl && (
                    <HyperLink href={youtubeUrl.includes('https://') ? youtubeUrl : `https://${youtubeUrl}`}>
                      {/* <img style={{ padding: '0px 5px' }} src={youtube}></img> */}
                      <StyledAiFillYoutube color={newIconCOlor} />
                    </HyperLink>
                  )}
                  {websiteUrl && (
                    <HyperLink href={websiteUrl.includes('https://') ? websiteUrl : `https://${websiteUrl}`}>
                      {/* <img style={{ padding: '0px 5px' }} src={website}></img> */}
                      <StyledHiGlobeAlt color={newIconCOlor} />
                    </HyperLink>
                  )}
                  {tiktokUrl && (
                    <HyperLink href={tiktokUrl.includes('https://') ? tiktokUrl : `https://${tiktokUrl}`}>
                      {/* <img style={{ padding: '0px 5px' }} src={tiktok}></img> */}
                      <StyledFaTiktok color={newIconCOlor} />
                    </HyperLink>
                  )}
                </SocialLinks>
              </Grid>
            )}
            <StyledLine />
          </MeetYourCoachContainer>
        </Grid>
      )}

      {mobileView && (
        <Grid md={8} sm={8} xs={12}>
          <MeetYourCoachContainer>
            {isLeadMagnet && (
              <Grid md={8} sm={8} xs={12}>
                <div
                  style={{
                    // margin: '2.5rem 0px',
                    // marginBottom: '40px',
                    border: `1px solid ${contribution?.isDarkModeEnabled ? '#696969' : '#DBE7F2'}`,
                    margin: 0,
                  }}
                ></div>
              </Grid>
            )}
            {!isLeadMagnet && <StyledLine />}
            {!isLeadMagnet && (
              <MeetCoachTxt2 style={{ color: newThemedColor }} fontWeight="600" fontSize="32px" Color="#282C2C">
                Meet Your {customTitleForMeetYourCoach}
              </MeetCoachTxt2>
            )}

            <CoachProfileMainContainer2 mobileView={mobileView}>
              <ProfileTxtLeft style={{ color: newThemedColor }}>
                <ProfileLeftImgCont>
                  <Avatar src={isLeadMagnet ? coachAvatarUrl : avatarUrl} className={classNames.Avatar}>
                    {getInitials(serviceProviderName || `${firstName} ${lastName}`)}
                  </Avatar>
                </ProfileLeftImgCont>
              </ProfileTxtLeft>
              <ProfileRightTxt mobileView={mobileView}>
                {/* <ProfileUserName>{serviceProviderName}</ProfileUserName> */}
                <LabelText
                  id="coach-name"
                  style={{ fontFamily: 'Poppins', fontWeight: 500 }}
                  onClick={() => handleCoachNameClick(userId)}
                >
                  <StyledCoachName isClickable style={{ color: newThemedColor }}>
                    {serviceProviderName || `${firstName} ${lastName}`}
                  </StyledCoachName>
                </LabelText>
                <ProfileUserLocation style={{ color: newThemedColor }}>{coachCountry}</ProfileUserLocation>
                <ProfileParagraphMobile style={{ color: newThemedColor, whiteSpace: 'pre-wrap' }}>
                  {bio}
                </ProfileParagraphMobile>
                {isLeadMagnet && leadMagnetCoachProfileUrl && (
                  <>
                    <ProfileRightButton>
                      <StyledButton
                        background={colorToUse?.PrimaryColorCode}
                        color="#fff"
                        margin="1rem 0rem"
                        onClick={() => window.open(leadMagnetCoachProfileUrl, '_blank').focus()}
                      >
                        Learn More
                      </StyledButton>
                    </ProfileRightButton>
                    {!mobileView && (
                      <Grid md={8} sm={8} xs={12}>
                        <div
                          style={{
                            margin: '1rem 0px',
                          }}
                        ></div>
                      </Grid>
                    )}
                  </>
                )}
              </ProfileRightTxt>
              {/* <AvatarUrl href={avatarUrl}> </AvatarUrl> */}
            </CoachProfileMainContainer2>
            {contributionPartners &&
              !isLeadMagnet &&
              contributionPartners.map(({ firstName, lastName, avatarUrl, userId: _userId, bio }) => (
                <div>
                  <CoachProfileMainContainer2 mobileView={mobileView}>
                    <ProfileTxtLeft>
                      <ProfileLeftImgCont>
                        {/* <ProfileLeftImg src={sarahImg}></ProfileLeftImg> */}
                        <Avatar src={avatarUrl} className={classNames.Avatar}>
                          {getInitials(`${firstName} ${lastName}`)}
                        </Avatar>
                      </ProfileLeftImgCont>
                    </ProfileTxtLeft>
                    <ProfileRightTxt mobileView={mobileView}>
                      {/* <ProfileUserName>{serviceProviderName}</ProfileUserName> */}
                      <LabelText
                        id="coach-name"
                        style={{ fontFamily: 'Poppins', fontWeight: 500 }}
                        mobileView={mobileView}
                        onClick={() => handleCoachNameClick(_userId)}
                      >
                        <StyledCoachName
                          isClickable
                          style={{ color: newThemedColor }}
                        >{`${firstName} ${lastName}`}</StyledCoachName>
                      </LabelText>
                      <ProfileUserLocation style={{ color: newThemedColor }}>{coachCountry}</ProfileUserLocation>
                    </ProfileRightTxt>
                  </CoachProfileMainContainer2>
                  <ProfileParagraphMobile style={{ color: newThemedColor, whiteSpace: 'pre-wrap' }}>
                    {bio}
                  </ProfileParagraphMobile>
                </div>
                // <CoachItem
                //   serviceProviderName={null}
                //   firstName={firstName}
                //   lastName={lastName}
                //   avatarUrl={avatarUrl}
                //   userId={userId}
                //   mobileView={mobileView}
                // />
              ))}
            {(facebookUrl || linkedInUrl || instagramUrl || youtubeUrl || websiteUrl || tiktokUrl) && (
              <Grid item xs={12} sm={12} md={12}>
                <SocialLinks>
                  {facebookUrl && (
                    <HyperLink href={facebookUrl.includes('https://') ? facebookUrl : `https://${facebookUrl}`}>
                      {/* <img style={{ padding: '0px 5px' }} src={fbImg}></img> */}
                      <StyledFaFacebookF color={newIconCOlor} />
                    </HyperLink>
                  )}
                  {linkedInUrl && (
                    <HyperLink href={linkedInUrl.includes('https://') ? linkedInUrl : `https://${linkedInUrl}`}>
                      {/* <img style={{ padding: '0px 5px' }} src={linkedImg}></img> */}
                      <StyledFaLinkedinIn color={newIconCOlor} />
                    </HyperLink>
                  )}
                  {instagramUrl && (
                    <HyperLink href={instagramUrl.includes('https://') ? instagramUrl : `https://${instagramUrl}`}>
                      {/* <img style={{ padding: '0px 5px', width: '40px', height: '30px' }} src={instaImg}></img> */}
                      <StyledAiFillInstagram color={newIconCOlor} />
                    </HyperLink>
                  )}
                  {youtubeUrl && (
                    <HyperLink href={youtubeUrl.includes('https://') ? youtubeUrl : `https://${youtubeUrl}`}>
                      {/* <img style={{ padding: '0px 5px' }} src={youtube}></img> */}
                      <StyledAiFillYoutube color={newIconCOlor} />
                    </HyperLink>
                  )}
                  {websiteUrl && (
                    <HyperLink href={websiteUrl.includes('https://') ? websiteUrl : `https://${websiteUrl}`}>
                      {/* <img style={{ padding: '0px 5px' }} src={website}></img> */}
                      <StyledHiGlobeAlt color={newIconCOlor} />
                    </HyperLink>
                  )}
                  {tiktokUrl && (
                    <HyperLink href={tiktokUrl.includes('https://') ? tiktokUrl : `https://${tiktokUrl}`}>
                      {/* <img style={{ padding: '0px 5px' }} src={tiktok}></img> */}
                      <StyledFaTiktok color={newIconCOlor} />
                    </HyperLink>
                  )}
                </SocialLinks>
              </Grid>
            )}
            {isLeadMagnet && !mobileView && <StyledLine />}
          </MeetYourCoachContainer>
        </Grid>
      )}

      {/* <BorderDiv></BorderDiv> */}
    </>
  );
}

export default MeetYourCoach;

CoachItem.propTypes = {
  userId: PropTypes.string.isRequired,
  serviceProviderName: PropTypes.string,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  avatarUrl: PropTypes.string.isRequired,
  mobileView: PropTypes.bool.isRequired,
};

CoachItem.defaultProps = {
  serviceProviderName: 'No name',
};
