import React from 'react';

const TimeSvg = ({ width, height, color = '#C9B382' }) => {
  return (
    <svg width="12" height="17" viewBox="0 0 12 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.64706 2.48233C7.20376 2.48233 8.47059 3.74915 8.47059 5.30586C8.47059 6.86256 7.20376 8.12939 5.64706 8.12939C4.09035 8.12939 2.82353 6.86256 2.82353 5.30586C2.82353 3.74915 4.09035 2.48233 5.64706 2.48233ZM5.64706 0.599976C3.04565 0.599976 0.941176 2.70633 0.941176 5.30586C0.941176 7.90539 3.04565 10.0117 5.64706 10.0117C8.24847 10.0117 10.3529 7.90539 10.3529 5.30586C10.3529 2.70633 8.24847 0.599976 5.64706 0.599976ZM5.64706 12.8353C7.54918 12.8353 8.75388 13.5609 9.20753 14.1953C8.56471 14.44 7.35435 14.7176 5.64706 14.7176C3.77976 14.7176 2.62965 14.4306 2.05365 14.2075C2.48565 13.5346 3.72612 12.8353 5.64706 12.8353ZM5.64706 10.9529C2.11765 10.9529 0 12.8353 0 14.7176C0 15.6588 2.11765 16.6 5.64706 16.6C8.95812 16.6 11.2941 15.6588 11.2941 14.7176C11.2941 12.8353 9.07859 10.9529 5.64706 10.9529Z"
        fill={color}
      />
    </svg>
  );
};

export default TimeSvg;
