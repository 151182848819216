import { useEffect } from 'react';
import { useFormikContext } from 'formik';
import isObject from 'lodash/isObject';

const getFirstErrorKey = (object, keys = []) => {
  const firstErrorKey = Object.keys(object)[0];
  if (isObject(object[firstErrorKey])) {
    return getFirstErrorKey(object[firstErrorKey], [...keys, firstErrorKey]);
  }
  return [...keys, firstErrorKey].join('.');
};

export const FormikScrollToError = ({ children }) => {
  const formik = useFormikContext();

  useEffect(() => {
    if (formik.isSubmitting && !formik.isValid && formik.submitCount > 0) {
      const firstErrorKey = getFirstErrorKey(formik.errors);
      const elements = window.document.getElementsByName(firstErrorKey);

      if (elements.length) {
        elements[0].scrollIntoView({
          behavior: 'smooth',
        });
      }
    }
  }, [formik.submitCount, formik.isValid, formik.errors, formik.isSubmitting]);
  return children;
};
