import { useCallback, useEffect, useMemo, useReducer, useState } from 'react';

import ChatClientConnectionStatus from 'components/App/ChatProvider/ChatClientConnectionStatus';
import useChatClient from '../useChatClient';
import ChatClientChannelConnectionStatus from '../ChatClientChannelConnectionStatus';
import ChatClientChannelConnectionError from '../ChatClientChannelConnectionError';
import { updateChannels, clearChannels, addChannel, removeChannel, updateChannel } from './actions';
import channelsReducer, { initialChannels } from './reducer';

const useChatClientChannels = (channelIds = null, loadAllChannels = false, channelsReload = false) => {
  const { connectionStatus: chatClientConnectionStatus, chatClient } = useChatClient();

  const [connectionStatus, setConnectionStatus] = useState(ChatClientChannelConnectionStatus.pending);
  const [error, setError] = useState(null);
  const [channels, dispatchChannelAction] = useReducer(channelsReducer, initialChannels);
  const channelsArray = useMemo(() => {
    const channelsList = Object.values(channels);
    return channelIds ? channelsList.filter(c => channelIds.includes(c.sid)) : channelsList;
  }, [channels, channelIds]);

  const channelsArrayForContribution = useMemo(() => {
    const channelsList = Object.values(channels);

    return channelIds ? channelsList.filter(c => channelIds.includes(c.sid)) : [];
  }, [channels, channelIds]);
  const [channelsPaginator, setChannelsPaginator] = useState(null);
  const [isLoadingMoreChannels, setIsLoadingMoreChannels] = useState(false);

  const loadMoreChannels = useCallback(async () => {
    if (!channelsPaginator.hasPrevPage) {
      return;
    }

    try {
      setIsLoadingMoreChannels(true);
      const previousChannelsPaginator = await channelsPaginator.prevPage();
      dispatchChannelAction(updateChannels(previousChannelsPaginator.items));
      setChannelsPaginator(previousChannelsPaginator);
    } finally {
      setIsLoadingMoreChannels(false);
    }
  }, [channelsPaginator]);

  useEffect(() => {
    (async function getChannelsAsync() {
      if (chatClientConnectionStatus === ChatClientConnectionStatus.connectionError) {
        setError(ChatClientChannelConnectionError.chatClientDisabled);
        setConnectionStatus(ChatClientChannelConnectionStatus.connectionError);
        dispatchChannelAction(clearChannels());
        setChannelsPaginator(null);

        return;
      }
      if (chatClientConnectionStatus !== ChatClientConnectionStatus.connected) {
        return;
      }

      try {
        setError(null);
        setConnectionStatus(ChatClientChannelConnectionStatus.connecting);

        const chatChannelsPaginator = await chatClient.getSubscribedChannels();

        dispatchChannelAction(updateChannels(chatChannelsPaginator.items));
        setChannelsPaginator(chatChannelsPaginator);
        setConnectionStatus(ChatClientChannelConnectionStatus.connected);

        if (loadAllChannels) {
          let hasNextPage = chatChannelsPaginator.hasNextPage;
          do {
            if (hasNextPage) {
              const nextPage = await chatChannelsPaginator.nextPage();
              hasNextPage = nextPage.hasNextPage;
              dispatchChannelAction(updateChannels(nextPage.items));
            }
          } while (hasNextPage);
        }
      } catch {
        setError(ChatClientChannelConnectionError.unknown);
        setConnectionStatus(ChatClientChannelConnectionStatus.connectionError);
        dispatchChannelAction(updateChannels([]));
        setChannelsPaginator(null);
      }
    })();
  }, [chatClient, chatClientConnectionStatus, channelsReload]);
  useEffect(() => {
    if (chatClient) {
      const onChannelAdded = c => {
        dispatchChannelAction(addChannel(c));
      };
      const onChannelUpdated = ({ channel }) => {
        dispatchChannelAction(updateChannel(channel));
      };
      const onChannelRemoved = c => {
        dispatchChannelAction(removeChannel(c.sid));
      };

      chatClient.on('channelJoined', onChannelAdded);
      chatClient.on('channelUpdated', onChannelUpdated);
      chatClient.on('channelLeft', onChannelRemoved);
      chatClient.on('channelRemoved', onChannelRemoved);

      return () => {
        chatClient.off('channelJoined', onChannelAdded);
        chatClient.off('channelUpdated', onChannelUpdated);
        chatClient.off('channelLeft', onChannelRemoved);
        chatClient.off('channelRemoved', onChannelRemoved);
      };
    }
  }, [chatClient]);

  return {
    connectionStatus,
    channels: channelsArray,
    channelsForContribution: channelsArrayForContribution,
    error,
    hasMoreChannels: !!channelsPaginator && channelsPaginator.hasPrevPage,
    isLoadingMoreChannels,
    loadMoreChannels,
  };
};

export default useChatClientChannels;
