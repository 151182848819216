import { handleActions } from 'redux-actions';
import * as discountCodesActions from 'actions/discountCodes';

const initialState = {
  loading: false,
  error: null,
  codes: [],
  code: null,
};

const discountCodes = handleActions(
  new Map([
    [
      discountCodesActions.fetchDiscountCodesActions.request,
      state => ({
        ...state,
        loading: true,
        error: null,
        codes: state.codes,
      }),
    ],
    [
      discountCodesActions.fetchDiscountCodesActions.success,
      (state, action) => ({
        ...state,
        loading: false,
        error: null,
        codes: action.payload,
      }),
    ],
    [
      discountCodesActions.fetchDiscountCodesActions.error,
      (state, action) => ({
        ...state,
        loading: false,
        error: action.payload,
        codes: state.codes,
      }),
    ],
    [
      discountCodesActions.addDiscountCodeActions.request,
      state => ({
        ...state,
        loading: true,
        error: null,
        codes: state.codes,
      }),
    ],
    [
      discountCodesActions.addDiscountCodeActions.success,
      (state, action) => ({
        ...state,
        loading: false,
        error: null,
        codes: [...state.codes, action.payload],
      }),
    ],
    [
      discountCodesActions.addDiscountCodeActions.error,
      (state, action) => ({
        ...state,
        loading: false,
        error: action.payload,
        codes: state.codes,
      }),
    ],
    [
      discountCodesActions.editDiscountCodeActions.request,
      state => ({
        ...state,
        loading: true,
        error: null,
        codes: state.codes,
      }),
    ],
    [
      discountCodesActions.editDiscountCodeActions.success,
      (state, action) => ({
        ...state,
        loading: false,
        error: null,
        codes: action.payload,
      }),
    ],
    [
      discountCodesActions.editDiscountCodeActions.error,
      (state, action) => ({
        ...state,
        loading: false,
        error: action.payload,
        codes: state.codes,
      }),
    ],
    [
      discountCodesActions.SET_DISCOUNT_CODE,
      (state, action) => ({
        ...state,
        code: action.payload,
      }),
    ],
    [
      discountCodesActions.SET_DISCOUNT_CODES,
      (state, action) => ({
        ...state,
        codes: action.payload,
      }),
    ],
  ]),
  initialState,
);

export default discountCodes;
