import React from 'react';

const ReminderSvg = ({ width, height, color }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.7797 0C10.9203 0 11.0609 0 11.2015 0C11.7969 0.201563 12.0406 0.623438 12.0031 1.24219C11.9844 1.51406 11.9984 1.78594 11.9984 2.0625C12.0547 2.08125 12.0969 2.09531 12.1437 2.10469C15.5515 2.69531 17.9844 5.56406 17.9984 9.01875C18.0031 9.99375 17.9937 10.9734 18.0031 11.9484C18.0125 12.7828 18.1906 13.5891 18.514 14.3625C18.9265 15.3516 19.55 16.1906 20.3562 16.8984C20.975 17.4422 21.1578 18.1969 20.8672 18.9094C20.5812 19.6078 19.9531 20.0016 19.1187 20.0016C13.7047 20.0016 8.2906 20.0016 2.87654 20.0016C2.77341 20.0016 2.67498 19.9969 2.57185 19.9828C1.53591 19.8563 0.865602 18.9656 1.02966 17.9391C1.10466 17.4563 1.3906 17.1094 1.74685 16.7953C2.90935 15.7547 3.66404 14.4656 3.88435 12.9188C3.99685 12.1266 3.96873 11.3156 3.99216 10.5141C4.0156 9.75 3.96404 8.97656 4.04841 8.22188C4.36716 5.31563 6.42498 2.97656 9.26091 2.23125C9.49998 2.17031 9.84216 2.18438 9.94998 2.02969C10.0719 1.85625 10.0062 1.53281 9.98748 1.27969C9.95467 0.65625 10.1797 0.215625 10.7797 0Z"
        fill={color}
      />
      <path
        d="M10.5921 23.9999C10.1374 23.8499 9.65461 23.7515 9.22805 23.5358C8.26711 23.0483 7.66711 22.2374 7.3718 21.2062C7.35305 21.1452 7.35305 21.0843 7.33899 21.0093C9.78118 21.0093 12.2187 21.0093 14.6702 21.0093C14.4077 22.1249 13.8031 22.9921 12.7952 23.5171C12.3593 23.7421 11.8577 23.8405 11.389 23.9952C11.1265 23.9999 10.8593 23.9999 10.5921 23.9999Z"
        fill={color}
      />
      <path
        d="M0.00306122 9.55307C0.134311 6.27182 1.32025 3.44057 3.62181 1.08745C4.04837 0.651509 4.65775 0.637447 5.06556 1.03588C5.47337 1.43432 5.47337 2.0437 5.0515 2.47963C3.72494 3.83901 2.80619 5.42338 2.33275 7.26557C2.12181 8.09526 2.00931 8.93432 2.00931 9.78745C2.00931 10.1578 1.89212 10.4812 1.57337 10.6968C1.24994 10.9171 0.903061 10.9453 0.551499 10.7671C0.213999 10.5937 0.0311862 10.3031 0.00774872 9.92338C-0.00631378 9.80151 0.00306122 9.67495 0.00306122 9.55307Z"
        fill={color}
      />
      <path
        d="M21.9921 9.47341C21.9921 9.63748 21.9968 9.76404 21.9921 9.8906C21.9781 10.4484 21.5562 10.875 21.0125 10.8797C20.464 10.8844 20.0187 10.4765 20.0046 9.92341C19.9765 8.65779 19.789 7.42029 19.3437 6.23435C18.8187 4.83279 18.0312 3.59998 16.9859 2.53123C16.7984 2.34373 16.6578 2.13279 16.6343 1.86091C16.5968 1.42966 16.8125 1.0406 17.1828 0.857788C17.5671 0.665601 18.0265 0.717163 18.3218 1.04998C18.8609 1.66404 19.4187 2.27341 19.8781 2.94841C21.2421 4.91716 21.9125 7.12498 21.9921 9.47341Z"
        fill={color}
      />
    </svg>
  );
};

export default ReminderSvg;
