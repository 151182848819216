import { updateChannels, clearChannels, addChannel, updateChannel, removeChannel } from './actions';

const initialChannels = {};

const channelsReducer = (state, { type, payload }) => {
  switch (type) {
    case updateChannels.toString(): {
      const { channels } = payload;

      return channels.reduce((newState, c) => ({ ...newState, [c.sid]: c }), state);
    }
    case clearChannels.toString():
      return { ...initialChannels };
    case addChannel.toString():
    case updateChannel.toString(): {
      const { channel } = payload;

      return { ...state, [channel.sid]: channel };
    }
    case removeChannel.toString(): {
      const { channelSid } = payload;

      const newState = { ...state };
      delete newState[channelSid];

      return newState;
    }
    default:
      return state;
  }
};

export default channelsReducer;
export { initialChannels };
