import React from 'react';

const EditIcon = ({ width = '17', height = '17' }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.5 13.1671V16.5H3.83287L13.6626 6.67025L10.3298 3.33737L0.5 13.1671ZM16.24 4.09282C16.5867 3.74621 16.5867 3.18628 16.24 2.83966L14.1603 0.759951C14.0781 0.677549 13.9805 0.612173 13.8729 0.567567C13.7654 0.52296 13.6502 0.5 13.5338 0.5C13.4174 0.5 13.3021 0.52296 13.1946 0.567567C13.0871 0.612173 12.9894 0.677549 12.9072 0.759951L11.2807 2.38639L14.6136 5.71927L16.24 4.09282Z"
        fill="#215C73"
      />
    </svg>
  );
};

export default EditIcon;
