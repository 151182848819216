import { useState } from 'react';
import { fetchUserTimezoneInfoIP } from 'services/user.service';
import { defaultCountries, parseCountry } from 'react-international-phone';
import { useDispatch } from 'react-redux';
import { setGuestTimeZone } from 'actions/contributions';

const useDefaultTimezone = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const fetchUserTimezone = async (userTimeZone, contributionTimeZone, timeZones) => {
    setLoading(true);
    try {
      const response = await fetchUserTimezoneInfoIP();
      let finalTimezone = 'Select Timezone';
      const isTimezoneAvailable = timeZones.filter(tz => tz.countryName === response.timezone);
      if (isTimezoneAvailable.length > 0) {
        finalTimezone = response.timezone;
      } else if (userTimeZone) {
        return userTimeZone;
      } else if (contributionTimeZone) {
        finalTimezone = contributionTimeZone;
      }
      dispatch(setGuestTimeZone(finalTimezone));
      return finalTimezone;
    } catch (error) {
      console.error('Error fetching timezone:', error);
      return userTimeZone || contributionTimeZone || null;
    } finally {
      setLoading(false);
    }
  };

  const fetchUserCountryCode = async (currentTimeZone, timeZones, countries) => {
    try {
      const currentTimezone = timeZones?.find(t => t.countryName === currentTimeZone);
      const currentCountry = countries?.find(t => t.id === currentTimezone?.countryId);
      const countryObj = defaultCountries?.find(t => t[0] === currentCountry?.name);
      if (countryObj) {
        const { iso2 } = parseCountry(countryObj);
        return iso2;
      }
      return 'us';
    } catch (error) {
      console.error('Error fetching country code:', error);
      return 'us';
    }
  };

  const fetchUserCountryCodeByIp = async (currentTimeZone, timeZones, countries) => {
    const timezone = await fetchUserTimezone(null, currentTimeZone, timeZones, countries);
    const countryCode = await fetchUserCountryCode(timezone, timeZones, countries);
    return countryCode;
  };

  return { loading, fetchUserTimezone, fetchUserCountryCode, fetchUserCountryCodeByIp };
};

export default useDefaultTimezone;
