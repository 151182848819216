import React, { useEffect, useState } from 'react';
import classes from './DomainAuthentication.module.scss';
import Header from 'components/UI/Header';
import styled from 'styled-components';
import Button from 'components/FormUI/Button';
import Step from './Components/Step/Step';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import * as paidTierActions from 'actions/paidTierOptions';
import * as paidTierService from 'services/paidTierOptions.service';
import CustomTable from './Components/Table/Table';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setAccount, setLastSender } from 'actions/emailMarketing.actions';
import { Tooltip } from '@material-ui/core';
import { EmailMarketingIntegrationService } from 'integrations/EmailMarketing/service/marketingAccountIntegration.service';
import copyToClipboard from 'utils/copyToClipboard';
import Loader from 'components/UI/Loader';
import { PAID_TIER_TITLES } from '../../../constants';
import * as paidTier from 'selectors/paidTier';
import useShallowEqualSelector from 'hooks/useShallowEqualSelector';
import Modal from 'components/UI/Modal';
const StyledMainContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #fafafa;
`;

const StyledMainSection = styled.div`
  padding: ${({ mobileView }) => (mobileView ? '30px 16px' : '30px 35px')};
`;
const TestDataForRedux = {
  senderIdentities: [],
  sendGridUserId: '42800595',
  apiKey: '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',
  email: 'email@speed.com',
  userId: '65eab8126e5cc2311ce7c775',
  domainData: [
    {
      id: '20759010',
      email: 'email@speed.com',
      subDomain: 'em290',
      domain: 'speed.com',
      customSpf: true,
      default: true,
      automaticSecurity: false,
      ips: ['167.89.80.57'],
      dnsObjects: {
        mailServer: {
          host: 'em290.speed.com',
          value: 'mx.sendgrid.net.',
          valid: false,
          type: 'mx',
        },
        spf: {
          host: 'em290.speed.com',
          value: 'v=spf1 ip4:167.89.80.57 -all',
          valid: false,
          type: 'txt',
        },
        dkim: {
          host: 'm1._domainkey.speed.com',
          value:
            'k=rsa; t=s; p=MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDggtyRBAr2SO7wHJv5MPVJPD11dQjqFvFkcHq+6c72CZM2nzBZkba9Vq76PlkIzt/Mv+eI1Lh6tp1JjtHxXlZQA38Gsb4Butj50GeGr0zuZPO4MRHSo2lL/ie/EwtqvG0mlqlNTqz339282xtlGocd99Z6EvtLRqXHy0ksAL5yRQIDAQAB',
          valid: false,
          type: 'txt',
        },
      },
      isVerified: false,
    },
  ],
  isVerified: false,
  id: '65eab8636e5cc2311ce7c787',
  createTime: '2024-03-08T07:04:03.2872848Z',
  updateTime: '2024-03-08T07:04:03.2872848Z',
};

const DomainAuthenticationPopUp = ({ showModal, handleCloseModal }) => {
  const dispatch = useDispatch();

  const [showDomainErrors, setShowDomainErrors] = useState(false);
  const [isToolTipOpen, setIsToolTipOpen] = useState(false);
  const allDomains = useSelector(state => state?.emailMarketing?.domains);
  const domainInAuthProcess = useSelector(state => state?.emailMarketing?.domainInAuthProcess);
  const domainData = allDomains?.find(domain => domain.domain === domainInAuthProcess);
  const dnsObjects = domainData?.dnsObjects || {};
  const dnsArray = Object.values(dnsObjects);

  const clickCopyHandler = () => {
    let formattedText = '';
    for (var key in dnsObjects) {
      formattedText += `type=${dnsObjects[key]['type']},host=${dnsObjects[key]['host']},value=${dnsObjects[key]['value']}\n`;
    }
    // copyToClipboard(formattedText);
    navigator.clipboard
      .writeText(formattedText)
      .then(() => {
        console.log('copiedtext->>', formattedText);
      })
      .catch(function (error) {
        console.error('Error copying text to clipboard: ', error);
        alert('Error copying text to clipboard. Please try again.');
      });
    setIsToolTipOpen(true);
    setTimeout(() => {
      setIsToolTipOpen(false);
    }, 1000);
  };

  return (
    <Modal
      isOpen={showModal}
      onSubmit={handleCloseModal}
      title={'DNS Details'}
      hiddenCancel
      maxWidth="1000px"
      onCancel={handleCloseModal}
    >
      <div className={classes.main_container}>
        <div className={classes.table_container_popup}>
          <CustomTable popup={true} data={dnsArray} showError={showDomainErrors} />
          <Tooltip
            // className="cursor-pointer"
            title="Copied!"
            open={isToolTipOpen}
            onClose={() => {
              setIsToolTipOpen(false);
            }}
            arrow
          >
            <div onClick={clickCopyHandler} className={classes.copy_btn}>
              Copy All To Clipboard
            </div>
          </Tooltip>
        </div>
      </div>
    </Modal>
  );
};

export default DomainAuthenticationPopUp;
