import { useState, useCallback } from 'react';

import axiosInstance from 'utils/axiosInstance';

const useHttp = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [progress, setProgress] = useState(null);

  const request = useCallback(async (url, method = 'GET', body = null, headers = {}, responseType) => {
    const localHeaders = new Headers();
    setLoading(true);

    if (body) {
      localHeaders.append('Content-Type', 'application/json');
    }

    Object.entries(headers).forEach(([key, value]) => localHeaders.append(key, value));

    try {
      const response = await axiosInstance.request({
        url,
        method,
        headers: localHeaders,
        data: body,
        responseType,
        onUploadProgress: data => {
          setProgress(Math.round((100 * data.loaded) / data.total));
        },
      });
      const { data } = response;

      return data;
    } catch (e) {
      setError(e);
      throw e;
    } finally {
      setLoading(false);
    }
  }, []);

  const clearError = useCallback(() => {
    setError(null);
  }, []);

  return {
    loading,
    error,
    clearError,
    request,
    progress,
  };
};

export { useHttp as default };
