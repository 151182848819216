import { useMediaQuery, useTheme } from '@material-ui/core';
import { isEmpty } from 'lodash';
import { useLeadMagnet } from 'pages/LeadMagnetView/hooks';
import { determineColorToUse, determineDarkThemedColorToUse, getThemedColors } from 'services/leadMagnets.service';
import { lightOrDark } from 'utils/utils';

const useLeadMagnetTheme = () => {
  const theme = useTheme();
  const leadMagnet = useLeadMagnet();
  // const { id } = leadMagnet;
  if (!leadMagnet || isEmpty(leadMagnet)) {
    // return {};
    return {
      theme: {},
      colorToUse: {},
      textColor: null,
      darkThemedColorToUse: {},
      themedColors: {},
    };
  }

  const colorToUse = determineColorToUse(leadMagnet);
  const textColor =
    colorToUse?.TextColorCode === 'Auto'
      ? lightOrDark(colorToUse?.PrimaryColorCode)
      : colorToUse?.TextColorCode === '#000000'
      ? '#000000'
      : '#FFFFFF';

  const darkThemedColorToUse = determineDarkThemedColorToUse(leadMagnet);

  const themedColors = getThemedColors(leadMagnet);
  // const { themedColor, newThemedTextColor } = themedColors;

  return {
    theme,
    colorToUse,
    textColor,
    darkThemedColorToUse,
    themedColors,
  };
};

export default useLeadMagnetTheme;
