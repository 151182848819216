import { get } from 'lodash/fp';

import axiosInstance from 'utils/axiosInstance';

const ChatType = {
  directWithClients: 'DirectWithClients',
  directWithCohealers: 'DirectWithCohealers',
  groupDiscussion: 'GroupDiscussion',
  opportuntityDiscussion: 'Opportunities',
};

const getChatTokenAsync = () => axiosInstance.get('/Chat/GetToken').then(get('data'));

const markChatAsFavoriteAsync = (chatId, isFavorite) =>
  axiosInstance.post(isFavorite ? `/Chat/SetAsFavorite/${chatId}` : `/Chat/SetAsRegular/${chatId}`).then(get('data'));

const getChatsByTypeAsync = (type = ChatType.groupDiscussion) =>
  axiosInstance.get(`/Chat/GetChatsByType?type=${type}`).then(get('data'));

const getChatDetails = chatsid =>
  axiosInstance.get(`/Chat/GetContributionDataByChatId?chatSid=${chatsid}`).then(get('data'));

const deleteSingleMessage = (chatType, chatSid, messageSid, contributionId) =>
  axiosInstance
    .delete(
      `/Chat/DeleteSingleMessageInConversation?chatType=${chatType}&chatSid=${chatSid}&messageSid=${messageSid}${
        contributionId === null ? '' : `&contributionId=${contributionId}`
      }`,
    )
    .then(get('data'));

const getPersonalChatWithAsync = (userId, isOpportunity = false) =>
  axiosInstance.post(`/Chat/CreatePersonalChatWith/${userId}?IsOpportunity=${isOpportunity}`).then(get('data'));

export default {
  getChatTokenAsync,
  markChatAsFavoriteAsync,
  getChatsByTypeAsync,
  getPersonalChatWithAsync,
  getChatDetails,
  deleteSingleMessage,
};
export { ChatType };
