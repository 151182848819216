import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { toRem, colors, devices } from 'utils/styles';
import Button from 'components/FormUI/Button';
import Modal from 'components/UI/Modal';
import SelectButton from 'components/FormUI/SelectButton';
import { PROFILE_CATEGORY_DESCRIPTIONS } from 'constants.js';

const STATES = {
  NotInterested: 0,
  Interested: 1,
  VeryInterested: 2,
};

const INVERT_STATES = {
  0: 'NotInterested',
  1: 'Interested',
  2: 'VeryInterested',
};

const StyledInterestsContainer = styled.div`
  @media screen and (${devices.laptop}) {
    & + & {
      margin-top: 40px;
    }
  }
`;

const StyledTitle = styled.p`
  font-size: ${toRem(13.2)};
  font-weight: 900;
  line-height: 1.21;
  letter-spacing: 2px;
  color: rgba(0, 0, 0, 0.87);
  text-transform: uppercase;
  display: flex;
  align-items: center;
`;

const StyledModalDescription = styled.p`
  font-size: ${toRem(16)};
  font-weight: normal;
  line-height: 1.25;
  letter-spacing: 0.25px;
  color: rgba(0, 0, 0, 0.87);
`;

const StyledLabelsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: -8px;
  margin-right: -8px;
  margin-bottom: 8px;
`;

const StyledLabel = styled.span`
  display: inline-block;
  padding: 12px 16px;
  margin: 8px;
  border-radius: 4px;

  ${({ state }) =>
    state === 0 &&
    css`
      background-color: #e7e7e7;
      color: rgba(0, 0, 0, 0.6);
    `}

  ${({ state }) =>
    state === 1 &&
    css`
      background-color: ${colors.darkOceanBlue};
      color: white;
    `}

  ${({ state }) =>
    state === 2 &&
    css`
      background-color: ${colors.fadedPurple};
      color: white;
    `}
`;

const StyledButtonsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;

  & button {
    margin: 10px;
  }
`;

function preparePreferences(preferences = {}) {
  return Object.keys(preferences).reduce((prev, key) => {
    const state = preferences[key];

    if (state !== 'NotInterested') {
      prev[key] = state;
    }

    return prev;
  }, {});
}

function Interests({ disabled, title, buttonTitle, source: preferences, onEdit, list, icon, type }) {
  const [userPreferences, setUserPreferences] = useState(list);
  const [showModal, setShowModal] = useState(false);

  const editClickHandler = useCallback(() => {
    onEdit(preparePreferences(userPreferences));
    setShowModal(false);
  }, [userPreferences, onEdit]);

  const onClickHandler = useCallback(
    prefName => state => {
      setUserPreferences(up => ({
        ...up,
        [prefName]: INVERT_STATES[state],
      }));
    },
    [],
  );

  return (
    <StyledInterestsContainer>
      <StyledTitle>
        {icon}
        &nbsp;
        {title}
      </StyledTitle>

      {!!Object.keys(list).length && (
        <StyledLabelsContainer>
          {Object.keys(list).map(key => (
            <StyledLabel state={STATES[list[key]]} key={key}>
              {key}
            </StyledLabel>
          ))}
        </StyledLabelsContainer>
      )}

      {!disabled && (
        <Button invert onClick={() => setShowModal(true)} autoWidth>
          {buttonTitle}
        </Button>
      )}

      <Modal
        isOpen={showModal}
        title={`Edit ${title}`}
        onCancel={() => setShowModal(false)}
        onSubmit={editClickHandler}
        submitTitle="Save Changes"
      >
        <StyledModalDescription>{PROFILE_CATEGORY_DESCRIPTIONS[type]}</StyledModalDescription>
        <StyledButtonsContainer>
          {preferences.map(({ name, id }) => (
            <SelectButton onClick={onClickHandler(name)} autoWidth state={STATES[userPreferences[name]] || 0} key={id}>
              {name}
            </SelectButton>
          ))}
        </StyledButtonsContainer>
      </Modal>
    </StyledInterestsContainer>
  );
}

Interests.propTypes = {
  disabled: PropTypes.bool,
  title: PropTypes.string.isRequired,
  buttonTitle: PropTypes.string.isRequired,
  onEdit: PropTypes.func.isRequired,
  source: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  list: PropTypes.shape(),
  icon: PropTypes.node.isRequired,
  type: PropTypes.string.isRequired,
};

Interests.defaultProps = {
  disabled: true,
  list: {},
};

export default Interests;
