import React from 'react';

export const DeleteIcon = ({ className, color = '#213649' }) => (
  <svg
    className={`delete-icon ${className}`}
    width="32"
    height="32"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.5349 9.66113C18.5349 17.4703 19.6785 21.0002 11.987 21.0002C4.29457 21.0002 5.46169 17.4703 5.46169 9.66113"
      stroke={color}
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path d="M20 6.66685H4" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path
      d="M10.125 16.5L10.125 11.25"
      stroke={color}
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M13.875 16.5L13.875 11.25"
      stroke={color}
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M15.3881 6.66715C15.3881 6.66715 15.9117 3 11.9943 3C8.07779 3 8.60144 6.66715 8.60144 6.66715"
      stroke={color}
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
