import { handleActions } from 'redux-actions';

import { setUserNote, clearUserNote, setUserNotes, clearUserNotes } from 'actions/userNotes';

const initialState = {
  note: {},
  notes: [],
};

const userNotes = handleActions(
  {
    [setUserNote]: (state, { payload }) => ({
      ...state,
      note: payload,
    }),
    [clearUserNote]: state => ({ ...state, note: {} }),
    [setUserNotes]: (state, { payload }) => ({
      ...state,
      notes: payload,
    }),
    [clearUserNotes]: state => ({ ...state, notes: [] }),
  },
  initialState,
);

export default userNotes;
