import { Dialog, DialogActions, DialogContent, Grid, TextField, Typography } from '@material-ui/core';
import React from 'react';
import Button from 'components/FormUI/Button';

const PasscodePopup = ({ isShowPasscodePopup, passcode, setPasscode, error, isPasscodeWrong, onPasscodeSubmit }) => {
  return (
    <>
      <Dialog fullWidth open={isShowPasscodePopup}>
        <DialogContent>
          <Typography align="left" variant="h6">
            Enter the passcode to watch
          </Typography>
          <TextField
            className="input-field"
            id="outlined-basic"
            margin="normal"
            variant="outlined"
            fullWidth
            placeholder="Passcode"
            value={passcode}
            error={error}
            onChange={e => {
              setPasscode(e.target.value);
            }}
          />
          {isPasscodeWrong && <p className="passcode-error-message">incorrect passcode!</p>}
        </DialogContent>
        <Grid container item xs justifyContent="center" direction="column" alignItems="center">
          <DialogActions>
            <Button onClick={onPasscodeSubmit} autoWidth>
              Watch Recording
            </Button>
          </DialogActions>
        </Grid>
      </Dialog>
    </>
  );
};

export default PasscodePopup;
