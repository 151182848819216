import React, { Fragment, useState } from 'react';
import classes from './FormOptions.module.scss';
import { questionTypes } from 'pages/CreateQuizes/Constants/constants';
import DropdownTypeOptions from './FormOptionsType/DropdownType/DropdownType';
import MultipleChoiceTypeOptions from './FormOptionsType/MultipleChoiceType/MultipleChoiceType';
import RankingTypeOptions from './FormOptionsType/RankingType/RankingType';
import RatingTypeOptions from './FormOptionsType/RatingType/RatingType';
import ShortTextOptions from './FormOptionsType/ShortText/ShortText';

const FormOptions = ({ questionType, ...restProps }) => {
  const FORM_OPTIONS_TYPES_MAPPER = {
    [questionTypes.dropdown]: <DropdownTypeOptions {...restProps} />,
    [questionTypes.multiple_choice]: <MultipleChoiceTypeOptions {...restProps} />,
    [questionTypes.ranking]: <RankingTypeOptions {...restProps} />,
    [questionTypes.rating]: <RatingTypeOptions {...restProps} />,
    [questionTypes.short_text]: <ShortTextOptions {...restProps} />,
  };
  return FORM_OPTIONS_TYPES_MAPPER[questionType] || null;
};

export default FormOptions;
