import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';
import { CircularProgressWithLabel } from 'components/UI/CircularProgressWithLabel/CircularProgressWithLabel';
import { colors } from 'utils/styles';
import axiosInstance from 'utils/axiosInstance';
import { getVideoUrl } from 'services/contributions.service';
import AudioFileOutlinedIcon from '@mui/icons-material/AudioFileOutlined';
import UploadThumbnailIcon from 'pages/CreateContribution/components/UploadThumbnailIcon';
import { TOOLTIP } from '../../constants';
import { Tooltip } from '@material-ui/core';
import LockIcon from '@mui/icons-material/Lock';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import useContribution from 'pages/ContributionView/hooks/useContribution';
const StyledVideo = styled.video`
  border-radius: 4px;
  display: flex;
  height: ${({ isPreviewMode }) => (isPreviewMode ? '300px' : '230px')};
  justify-content: center;
  margin: 10px 0 10px;
  // max-width: 400px;
  width: ${({ isPreviewMode }) => (isPreviewMode ? '630px ' : '400px')};

  ${({ shouldDim }) => shouldDim && `filter: brightness(0.5);`};
  ${({ makeFullWidthAndHeight }) =>
    makeFullWidthAndHeight &&
    `height: 100%;
   width: 100%; 
  `};
`;
const getColor = props => {
  if (props.isDragAccept) {
    return colors.darkOceanBlue;
  }
  if (props.isDragReject) {
    return '#eeeeee';
  }
  if (props.isDragActive) {
    return colors.darkOceanBlue;
  }
  return '#bdbdbd';
};

const Container = styled.div`
  cursor: ${({ isDisabled }) => (isDisabled ? 'default' : 'pointer')};
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${({ showVideo }) => !showVideo && '20px'};
  background-color: #f5f5f5;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
  height: 200px;
  margin: 20px 0 20px;
`;

const StyledIconWrap = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-size: 60px;
  transition: color 0.24s ease-in-out;
  color: ${props => getColor(props)};
`;
const Image = styled.img`
  width: 100%;
  max-height: 200px;
  object-fit: contain;
`;
const MainDiv = styled.div`
  width: 'auto';
  position: absolute;
  padding: ${({ isSessionPage }) => (isSessionPage ? '40px 20px' : '20px')};
  top: 0px;
  bottom: 5px;
  left: 5px;
  right: 5px;
`;

const Dropzone = ({
  multiple,
  action,
  isDisabled,
  type,
  icon,
  progress,
  placeholder,
  loading,
  duration,
  sessionTime,
  isSessionPage,
  StyledUploadThumbnailContainer,
  uploadThumbnail,
  StyledVideoContainer,
  videoRef,
  onPlay,
  onPause,
  showVideo,
  setShowVideo,
}) => {
  const videoStyle = {
    padding: '0px',
    height: 'auto',
    margin: '0px',
    backgroundColor: 'inherit',
  };
  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    onDrop: files => action(files),
    accept: type,
    multiple,
    disabled: isDisabled || loading,
  });
  const [previewVideo, setPreviewVideo] = useState(null);
  const [showAudio, setShowAudio] = useState(false);
  const contribution = useContribution();
  // const [showVideo, setShowVideo] = useState(false)
  useEffect(() => {
    if (showVideo === true) {
      if (sessionTime?.prerecordedSession?.documentKeyWithExtension) {
        getPreviewVideoUrl();
      }
    }
  }, [showVideo]);

  const getPreviewVideoUrl = async () => {
    getVideoUrl(sessionTime?.prerecordedSession?.documentKeyWithExtension).then(res => {
      setPreviewVideo(res);
    });
  };

  return (
    <Container
      showVideo
      {...getRootProps({ isDragActive, isDragAccept, isDragReject })}
      onClick={event => event.stopPropagation()} // avoid of triggering onClick twice https://github.com/react-dropzone/react-dropzone/issues/252
      style={previewVideo && videoStyle}
      isDisabled={isDisabled}
    >
      {loading ? (
        <CircularProgressWithLabel value={progress} />
      ) : duration ? (
        sessionTime?.prerecordedSession?.extension?.toLowerCase()?.includes('mp3') ? (
          showAudio ? (
            previewVideo && (
              <StyledVideo controls>
                <source src={previewVideo} />
              </StyledVideo>
            )
          ) : (
            <AudioFileOutlinedIcon
              onClick={() => {
                setShowAudio(!showAudio);
              }}
              style={{ fontSize: 40 }}
            />
          )
        ) : sessionTime?.prerecordedSession?.extension?.toLowerCase()?.includes('png' || 'jpg' || 'jpeg') ? (
          previewVideo && <Image crossorigin="anonymous" src={previewVideo} />
        ) : showVideo ? (
          <div className="position-relative w-100">
            <Tooltip
              title="Thumbnail"
              arrow
              enterTouchDelay={TOOLTIP.ENTER_DELAY}
              leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
            >
              <StyledUploadThumbnailContainer onClick={() => uploadThumbnail(true)}>
                <UploadThumbnailIcon style={{ width: '36px', height: '37px' }} />
              </StyledUploadThumbnailContainer>
            </Tooltip>
            <StyledVideoContainer
              ref={videoRef}
              controls
              preload="none"
              poster={sessionTime?.selfPacedThumbnailUrl}
              onPlay={onPlay}
              onPause={onPause}
              showVideo={showVideo}
              setShowVideo={setShowVideo}
            >
              <source src={previewVideo} />
            </StyledVideoContainer>
          </div>
        ) : sessionTime?.selfPacedThumbnailUrl != null ? (
          <div
            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            onClick={() => {
              setShowVideo(true);
              setTimeout(() => {
                videoRef.current.play();
              }, [2000]);
            }}
          >
            <img
              src={sessionTime?.selfPacedThumbnailUrl}
              style={{
                height: '200px',
                width: '100%',
              }}
            />

            <PlayArrowIcon style={{ height: '50px', width: '50px', color: 'gray', position: 'absolute', zIndex: 10 }} />
          </div>
        ) : contribution?.previewContentUrls?.at(0) != null ? (
          <div
            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            onClick={() => {
              setShowVideo(true);
              setTimeout(() => {
                videoRef.current.play();
              }, [2000]);
            }}
          >
            <img
              src={contribution?.previewContentUrls?.at(0)}
              style={{
                height: '200px',
                width: '100%',
              }}
            />

            <PlayArrowIcon style={{ height: '50px', width: '50px', color: 'gray', position: 'absolute', zIndex: 10 }} />
          </div>
        ) : (
          <div
            onClick={() => {
              setShowVideo(true);
              setTimeout(() => {
                videoRef.current.play();
              }, [2000]);
            }}
            style={{
              backgroundColor: 'black',
              height: '200px',
              width: '100%',
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
              cursor: 'pointer',
            }}
          >
            <PlayArrowIcon style={{ height: '50px', width: '50px', color: 'gray', position: 'absolute', zIndex: 10 }} />
          </div>
        )
      ) : (
        <>
          <input {...getInputProps()} />
          <MainDiv isSessionPage={isSessionPage} {...getRootProps({ isDragActive, isDragAccept, isDragReject })}>
            <StyledIconWrap>{icon}</StyledIconWrap>
            &nbsp;&nbsp;&nbsp;
            <div>{placeholder}</div>
          </MainDiv>
        </>
      )}
    </Container>
  );
};

Dropzone.defaultProps = {
  multiple: false,
  progress: 0,
  isDisabled: false,
  isSessionPage: false,
};

Dropzone.propTypes = {
  isDisabled: PropTypes.bool,
  isSessionPage: PropTypes.bool,
};

export default Dropzone;
