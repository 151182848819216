import React, { useState } from 'react';
import Modal from 'components/UI/Modal';
import PropTypes from 'prop-types';
import './DeleteApplicationFormModal.scss';
import { BodyText } from 'components/UI/Text/TextStyles';
import { useDispatch } from 'react-redux';
import { deleteApplicationForm } from 'actions/applications';
import Loader from 'components/UI/Loader';
// import Loader from 'components/UI/SmallLoader';

function DeleteApplicationFormModal({ isOpen, contributionName, setOpen, contributionId, formId }) {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);

  const handleSubmitDelete = () => {
    dispatch(
      deleteApplicationForm(
        contributionId,
        formId,
        () => {
          setOpen(false);
          setLoading(true);
        },
        null,
        null,
        () => {
          setLoading(false);
        },
      ),
    );
  };

  return (
    <>
      {isLoading && <Loader />}
      <Modal
        isOpen={isOpen}
        onCancel={() => {
          setOpen(false);
        }}
        onSubmit={handleSubmitDelete}
        title="Confirm Action"
        brandingColor={false}
        submitTitle="Yes"
        cancelTitle="No"
        // className="delete-application-form-modal"
        // disableOverlayClick
      >
        <div
          style={{
            fontSize: '1rem',
            fontFamily: 'Avenir',
            color: 'rgba(0, 0, 0, 0.87)',
            fontWeight: 400,
            marginBottom: '20px',
          }}
        >
          <div className="mb-2">
            <strong>Are you sure you wish to delete application form for {contributionName}?</strong>
          </div>
          On deleting previously shared links for applications will no longer work and you will no longer have a
          shareable link for an application associated with {contributionName}. Are you sure you wish to continue?
        </div>
      </Modal>
    </>
  );
}

DeleteApplicationFormModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setOpen: PropTypes.func,
  contributionName: PropTypes.string,
  contributionId: PropTypes.string.isRequired,
  formId: PropTypes.string.isRequired,
};

DeleteApplicationFormModal.defaultProps = {
  setOpen: () => {},
  contributionName: 'n/a',
};

export default DeleteApplicationFormModal;
