import Modal from 'components/UI/Modal';
import React from 'react';
import styled from 'styled-components';
import { RiChatQuoteLine } from 'react-icons/ri';
import PropTypes from 'prop-types';

import './TestimonialModal.scss';
import useContribution from 'pages/ContributionView/hooks/useContribution';
import { determineColorToUse, determineDarkThemedColorToUse } from 'services/contributions.service';
import AddCircleOutlineIcon from 'components/Icons/AddCircleOutlineIcon';

const StyledContainer = styled.div`
  height: 206px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 18px;
  flex: 1 1 0;
  border-radius: 4px;
  border: 1px solid #dfe3e4;
  background: ${({ isDarkModeEnabled }) => (isDarkModeEnabled ? '#252728' : '#fff')};

  &:hover {
    border-color: #215c73;
    cursor: pointer;
  }

  p {
    font-size: 18px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    margin-bottom: 0;
    color: rgba(0, 0, 0, 0.87);
    color: ${({ isDarkModeEnabled }) => (isDarkModeEnabled ? 'white' : 'black')};
  }

  svg {
    width: 45px;
    height: 45px;
    flex-shrink: 0;
    color: #215c73;
  }
`;

const StyledMainContainer = styled.div`
  display: flex;
  gap: 20px;
`;

function TestimonialModal({ isOpen, handleClose, handleClick }) {
  const contribution = useContribution();
  const colorToUse = determineColorToUse(contribution);
  const isDarkModeEnabled = determineDarkThemedColorToUse(contribution);

  return (
    <Modal
      isOpen={isOpen}
      onCancel={handleClose}
      title="Create Testimonial"
      className="testimonial-modal"
      disableFooter
      applyTheming
      disableOverlayClick
    >
      <StyledMainContainer>
        <StyledContainer onClick={e => handleClick('new')} isDarkModeEnabled={isDarkModeEnabled}>
          <AddCircleOutlineIcon fillColor={colorToUse?.PrimaryColorCode} />
          <p>Create From Scratch</p>
        </StyledContainer>

        <StyledContainer onClick={e => handleClick('template')} isDarkModeEnabled={isDarkModeEnabled}>
          <RiChatQuoteLine color={colorToUse?.PrimaryColorCode} />
          <p>Choose a Prior Testimonial</p>
        </StyledContainer>
      </StyledMainContainer>
    </Modal>
  );
}

TestimonialModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleClick: PropTypes.func.isRequired,
};

export default TestimonialModal;
