import React, { useEffect } from 'react';
import { StylesProvider } from '@mui/styles';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { AvatarComponent } from 'components/AdvertismentCard/style';
import ClickDropDown, { StyledDropDownItem } from 'components/UI/ClickDropDown';
import PropTypes from 'prop-types';

import isEmpty from 'lodash/isEmpty';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';
import { useSelector, useDispatch } from 'react-redux';
import { redirectToAppIfIsCoachDomain } from 'utils/subdomains';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { SIGNUP_TYPES, UserRoles } from 'helpers/constants';
import useAccount from 'hooks/useAccount';
import usePaidTier from 'hooks/usePaidTier';
import useRouter from 'hooks/useRouter';
import { getThemedColors } from 'services/contributions.service';
import { ROUTES } from '../../../constants';
import { getInitialSymbol } from 'components/UI/Header';
import useShallowEqualSelector from 'hooks/useShallowEqualSelector';
import * as paidTier from 'selectors/paidTier';
import { setCohereAcademyStatus } from 'actions/update-user';
import { PAID_TIER_TITLES } from '../../../constants';
const ApplicationFormHeader = ({ isCustomPrevButton, title }) => {
  const theme = useTheme();
  const xsView = useMediaQuery(theme.breakpoints.down('xs'));
  const { currentRole, user: signupType, loggedInUser } = useAccount();
  // const { isLaunchPlan } = usePaidTier();
  const dispatch = useDispatch();
  const activePaidTierCurrentPlan = useShallowEqualSelector(paidTier.activePaidTierCurrentPlanSelector);
  const isLaunchPlan = activePaidTierCurrentPlan?.displayName === PAID_TIER_TITLES.launch;

  // Correct behavior - 100%

  useEffect(() => {
    if (activePaidTierCurrentPlan?.default) {
      dispatch(setCohereAcademyStatus(true));
    } else {
      dispatch(setCohereAcademyStatus(false));
    }
  }, [activePaidTierCurrentPlan, dispatch]);
  const { domain, pathname } = useRouter();
  const { user, contribution } = useSelector(({ account, contributions }) => {
    return { user: account?.user || {}, contribution: contributions.activeContribution };
  });
  const { path } = useRouteMatch();
  const { themedColor, themedBackgroundColor } = getThemedColors(contribution);
  const isPathForApplicationForm = checkPath => checkPath === '/application-form/:id/:code?';

  const mobileView = xsView;
  const shouldBeDisabled =
    signupType === SIGNUP_TYPES.TYPE_A ||
    signupType === SIGNUP_TYPES.TYPE_B ||
    loggedInUser?.signupType === SIGNUP_TYPES.TYPE_B ||
    loggedInUser?.signupType === SIGNUP_TYPES.TYPE_A;

  return (
    <div className="application-form-header">
      <p className="contribution-title">Apply to {title}</p>
      <span className="header-content">
        {isPathForApplicationForm(path) && isEmpty(user) ? (
          <>
            <AccountCircleIcon className="mx-2 profile-pic-container" style={{ color: '#C7C7C7', fontSize: '50px' }} />
            <ClickDropDown
              className="dropdown-container"
              id="already-purchased-dropdown"
              title={user.firstName}
              mobileView={mobileView}
            >
              <StyledDropDownItem
                style={isCustomPrevButton ? { backgroundColor: themedBackgroundColor, color: themedColor } : {}}
                onClick={() => {
                  redirectToAppIfIsCoachDomain(domain, pathname, 'isPurchased=true');
                }}
              >
                Already a member?
              </StyledDropDownItem>
            </ClickDropDown>
          </>
        ) : (
          <>
            <StylesProvider injectFirst>
              <AvatarComponent
                className="mx-2 profile-pic-container"
                alt={`${user.firstName} ${user.lastName}`}
                src={user.avatarUrl}
                mobileView={mobileView}
              >
                {`${user.firstName && getInitialSymbol(user.firstName)}${
                  user.lastName && getInitialSymbol(user.lastName)
                }`}
              </AvatarComponent>
            </StylesProvider>
            <ClickDropDown className="dropdown-container" id="header-user-dropdown" title="" mobileView={mobileView}>
              {shouldBeDisabled && currentRole === UserRoles.cohealer ? (
                <StyledDropDownItem
                  style={isCustomPrevButton ? { backgroundColor: themedBackgroundColor, color: themedColor } : {}}
                  className="disableMe"
                  to="#"
                >
                  Account
                </StyledDropDownItem>
              ) : null}
              {!shouldBeDisabled || (shouldBeDisabled && currentRole !== UserRoles.cohealer) ? (
                <StyledDropDownItem
                  style={isCustomPrevButton ? { backgroundColor: themedBackgroundColor, color: themedColor } : {}}
                  className={
                    ((shouldBeDisabled && currentRole === UserRoles.cohealer) ||
                      (isLaunchPlan && currentRole === UserRoles.cohealer && user.isPartnerCoach === false)) &&
                    'disableMe'
                  }
                  onClick={() => {
                    redirectToAppIfIsCoachDomain(domain, '/account/profile');
                  }}
                >
                  Account
                </StyledDropDownItem>
              ) : null}

              <StyledDropDownItem
                style={isCustomPrevButton ? { backgroundColor: themedBackgroundColor, color: themedColor } : {}}
                onClick={() => {
                  redirectToAppIfIsCoachDomain(domain, ROUTES.ROLE_SWITCH);
                }}
              >
                {currentRole === UserRoles.cohealer ? 'Client' : 'Coach'} Dashboard
              </StyledDropDownItem>
              <StyledDropDownItem
                style={isCustomPrevButton ? { backgroundColor: themedBackgroundColor, color: themedColor } : {}}
                onClick={() => {
                  redirectToAppIfIsCoachDomain(domain, '/logout');
                }}
              >
                Logout
              </StyledDropDownItem>
            </ClickDropDown>
          </>
        )}
      </span>
    </div>
  );
};

ApplicationFormHeader.propTypes = {
  user: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    avatarUrl: PropTypes.string,
    isPartnerCoach: PropTypes.bool,
  }).isRequired,
  contribution: PropTypes.shape({}).isRequired,
  isCustomPrevButton: PropTypes.bool,
  title: PropTypes.string.isRequired,
};

ApplicationFormHeader.defaultProps = {
  isCustomPrevButton: false,
};

export default ApplicationFormHeader;
