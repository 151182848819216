import React, { useEffect, useRef, useState } from 'react';
import classes from './DesignPage.module.scss';
import CreateCampaignMain from 'pages/CreateCampaign/Components/Containers/MainContainer/MainContainer';
import Stepper from 'pages/CreateCampaign/Components/Stepper/Stepper';
import { Formik, Form } from 'formik';
import useRouter from 'hooks/useRouter';
import { Card, CardBody, CardHeader } from 'components/UI/Card';
import { useTheme, useMediaQuery, Radio, FormControlLabel, Grid, ClickAwayListener } from '@material-ui/core';
import Buttons from 'pages/CreateContribution/components/Buttons';
import LabelInput from 'pages/CreateCampaign/Components/LabelInput/LabelInput';
import Input from 'components/FormUI/Input';
import * as Yup from 'yup';
import { mdiContentCopy } from '@mdi/js';
import { Icon } from '@mdi/react';
import InfoIcon from '@material-ui/icons/Info';
import Button from 'components/FormUI/Button';
import Checkbox from '@mui/material/Checkbox';
import { CheckboxHeading } from 'pages/ContributionView/ContributionCommunication/components/ContributionCommunicationStyled';
import CustomBrandingModal from './CustomBrandingModal/CustomBrandingModal';
import Modal from 'components/UI/Modal';
import { CKEditor, useCKEditor } from 'ckeditor4-react';
import { ArrowDropDownIcon, PhoneDesktop, PhonePreviewVector } from './assests/SvgImages';
import { createCampaignServices } from 'pages/CreateCampaign/Services/CreateCampaign.service';
import useCampaign from 'pages/CreateCampaign/Hooks/useCampaign';
import { Campaign } from '@mui/icons-material';
import useAccount from 'hooks/useAccount';
import Select from 'components/FormUI/Select';
import verfiedIcon from 'assets/verified.png';
import notverifiedIcon from 'assets/notverified.png';
import useEmailMarketing from 'pages/CreateCampaign/Hooks/useEmailMarketing';
import { saveCampaignToLS, setSendEmailTo } from 'actions/campaign';
import { useDispatch } from 'react-redux';
import Loader from 'components/UI/Loader';
import MUISelect from 'components/UI/Select';
import TextField from '@mui/material/TextField';
import { EmailMarketingIntegrationService } from 'integrations/EmailMarketing/service/marketingAccountIntegration.service';
import { setAccount, setDomains } from 'actions/emailMarketing.actions';
import copyToClipboard from 'utils/copyToClipboard';
import { Tooltip } from '@material-ui/core';
import { CampaignStatus } from 'pages/CreateCampaign/Constants/constants';
import styled from 'styled-components';
import { CAMPAIGN_TEMPLATE_TYPES } from '../../../../constants';
import { colors } from 'utils/styles';
import PersonalizedIcon from '../SummaryPage/Components/Icons/SVG/PersonalizedIcon';

const StyledError = styled.div`
  color: red;
  margin-top: 5px;
`;

const emailRegEx = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/;

const Graphical_Template =
  '<p>&nbsp;</p>\n\n<p>&nbsp;</p>\n\n<p style="text-align: center;"><span style="font-family: Roboto, sans-serif; font-size:31.16px; color: rgba(0, 0, 0, 1); line-height: 49.23px;"><strong>ADD LOGO</strong></span></p>\n\n<p style="text-align: center;">&nbsp;</p>\n\n<p style="text-align: center;"><span style="font-family: Roboto, sans-serif; font-size: 37.39px; line-height: 40.57px; color: #215C73; "><b>Design Your Email Here</b></span></p>\n\n<p style="font-family: Roboto, sans-serif; font-size: 12.46px; line-height: 17.7px; text-align: center; color: rgba(0, 0, 0, 0.87);">This designer gives you the ability to fully customize the layout and style of your emails. You can add, rearrange and remove structures and sections.</p>\n\n<p style="text-align: center;">&nbsp;</p>\n\n<p style="text-align: center;">&nbsp;</p>\n\n<p style="text-align: center;"><span style="font-family: Roboto, sans-serif; font-size: 37.39px; line-height: 40.57px; color: #215C73; "><strong>Build The Way You Want</strong></span></p>\n\n<p style="font-family: Roboto, sans-serif; font-size: 12.46px; line-height: 17.7px; text-align: center; color: rgba(0, 0, 0, 0.87);">Structures make it easy to create a multi-column look without the hassle. You can add as many structures to an email as you would like.</p>\n\n<p style="text-align: center;">&nbsp;</p>\n\n<p style="text-align: center;">&nbsp;</p>\n\n<p style="text-align: center;"><button style="color: white; background-color: #C9B382; border-radius: 1.56px; padding: 12px 18px; border: 1px solid #C9B382; font-family: Avenir; font-size: 12.46px; line-height: 17.16px;">Add Button</button></p>\n\n<p style="text-align: center;">&nbsp;</p>\n\n<p style="text-align: center;">&nbsp;</p>';

const DesignPage = () => {
  const underLineColorOfField = '#DFE3E4';
  const themedColor = '#215C73';
  const campaignFormRef = useRef();
  const { campaign, isCreateCampagnPage } = useCampaign();
  const { user } = useAccount();
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const { history, query } = useRouter();
  const isEditing = !!query?.id;
  const [redirectTo, setRedirectTo] = useState(null);
  const { verifiedDomains, verifiedSendingAddresses, isLoading: domainsLoading } = useEmailMarketing();
  const [openBrandingModal, setOpenBrandingModal] = useState(false);
  const [testEmailAddess, setTestEmailAddess] = useState('');
  const [sendingTestEmail, setSendingTestEmail] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [openBrandingModalSavingPopUp, setOpenBrandingModalSavingPopUp] = useState(false);
  const [openCampaignPreview, setOpenCampaignPreview] = useState(false);
  const [openPhonePreview, setPhonePreview] = useState(false);
  const [textOnly, setTextOnly] = useState(true);
  const [graphical, setGraphical] = useState(false);
  const [emailSentSuccessful, setEmailSuccessful] = useState(false);
  const [showTextEditor, setShowTextEditor] = useState(true);
  const [isToolTipOpen, setIsToolTipOpen] = useState(false);
  const [isToolTipOpenSubject, setIsToolTipOpenSubject] = useState(false);
  const [addEmailPopUpOpen, setAddEmailPopUpOpen] = useState(false);
  const [textData, setTextData] = useState(null);
  const [graphicalData, setGraphicalData] = useState(Graphical_Template);
  const [selectedDomain, setSelectedDomain] = useState(null);
  const [email, setEmail] = useState('');
  const [isPersonalizeDropDownOpen, setIsPersonalizeDropDownOpen] = useState(false);
  const [isSubjectPersonalizeDropDownOpen, setIsSubjectPersonalizeDropDownOpen] = useState(false);
  const [accountCreationError, setAccountCreationError] = useState('');
  const [isEditorLoaded, setIsEditorLoaded] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [emailAddressError, setEmailAddressError] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (verifiedDomains.length > 0) {
      if (selectedDomain === verifiedDomains[0]?.domain || selectedDomain === null) {
        setSelectedDomain(verifiedDomains[0]?.domain);
      }
    } else {
      setSelectedDomain('');
    }
  }, [verifiedDomains]);

  useEffect(() => {
    if (isCreateCampagnPage && campaign === null) {
      history.replace(`/create-campaign/title`);
    }
  }, [campaign]);

  useEffect(() => {
    const handleOutsideClick = e => {
      const cls = e.target.className;
      if (cls?.length > 0 && !cls.includes('personalize')) {
        setIsPersonalizeDropDownOpen(false);
      }
    };
    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  const submitHandler = values => {
    setIsLoading(true);
    const data = {
      ...campaign,
      ...values,
    };
    if (isEditing && campaign?.status === CampaignStatus.Approved) {
      createCampaignServices
        .updateApprovedCampaign(data)
        .then(res => {
          dispatch(saveCampaignToLS(res));
          history.replace(redirectTo);
        })
        .catch(error => {})
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      createCampaignServices
        .updateDraftCampaign(data)
        .then(res => {
          dispatch(saveCampaignToLS(res));
          history.replace(redirectTo);
        })
        .catch(error => {})
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const handleBackClick = () => {
    dispatch(setSendEmailTo(null));
    history.goBack();
    if (history.length === 1) {
      history.push('/dashboard');
    }
  };

  const handleEmailChange = e => {
    setEmail(e.target.value);
  };

  const handleTestEmailChange = e => {
    const value = e.target.value;
    setTestEmailAddess(value);

    if (value.length === 0) {
      setEmailAddressError(null);
      return;
    }
    const isEmailCorrect = emailRegEx.test(value);
    if (!isEmailCorrect) {
      setEmailAddressError('Invalid email address');
    } else {
      setEmailAddressError(null);
    }
  };

  return (
    <CreateCampaignMain
      headerProps={{
        handleBackClick,
      }}
    >
      <Formik
        innerRef={campaignFormRef}
        enableReinitialize
        initialValues={{
          emailSubject: campaign?.emailSubject || 'Email Subject Test',
          preHeaderText: campaign?.preHeaderText,
          fromName: campaign?.fromName || 'From Name Test',
          fromEmail: campaign?.fromEmail,
          emailContent: campaign?.emailContent === null ? '' : campaign?.emailContent || '',
          useCustomBrandingColor: campaign?.useCustomBrandingColor || false,
          isGraphicalEmail: campaign?.isGraphicalEmail || false,
        }}
        validationSchema={Yup.object().shape({
          emailSubject: Yup.string()
            .max(100, 'Email subject can be a max of 100 characters')
            .required('This is a required field'),
          preHeaderText: Yup.string().max(100, 'Preheader can be a max of 100 characters'),
          // .required('This is a required field'),
          fromName: Yup.string()
            .max(100, 'From name can be a max of 100 characters')
            .required('This is a required field'),
          fromEmail: Yup.string()
            .max(100, 'Sending Address can be a max of 100 characters')
            .required('This is required field'),
          emailContent: Yup.string()
            .min(50, 'Email content should be at least 50 character long')
            .required('This is required field')
            .nullable(),
          useCustomBrandingColor: Yup.boolean(),
          isGraphicalEmail: Yup.boolean(),
        })}
        onSubmit={submitHandler}
      >
        {({ values, setFieldValue, handleBlur, handleChange, handleSubmit, errors, touched }) => {
          const handleSendingAddressChange = ({ target: { name, value } }) => {
            if (value === 'Add Another Email') {
              setAddEmailPopUpOpen(true);
            } else {
              setFieldValue(name, value);
            }
          };
          return (
            <>
              {(isLoading || domainsLoading || !isEditorLoaded) && <Loader />}
              <Card id={'createCampaign'} mobileView={mobileView}>
                <CardHeader mobileView={mobileView}>
                  <div className={classes.stepper_container}>
                    <Stepper
                      activeStep={3}
                      handleSubmit={({ to, isGoingTo }) => {
                        setRedirectTo(to);
                        handleSubmit(values);
                        // if (isGoingTo < 3) {
                        //   history.replace(to);
                        // } else {
                        //   setRedirectTo(to);
                        //   handleSubmit(values);
                        // }
                      }}
                    />
                  </div>
                </CardHeader>
                <CardBody className={classes.form_body} mobileView={mobileView}>
                  <div className={classes.form_container}>
                    <div className={classes.title_class}>Design Email</div>
                    <div className={classes.card_container}>
                      <div className={classes.field_container}>
                        <LabelInput tooltipText="This is the subject line of your email." label={'Email Subject'} />
                        <Input
                          underLineColor={underLineColorOfField}
                          type="text"
                          name="emailSubject"
                          fullWidth
                          redError={true}
                          emailMarketingField={true}
                          inputClasses={{
                            error: classes.error,
                          }}
                          endAdornment={
                            <>
                              <Tooltip
                                className="cursor-pointer"
                                title="Copied!"
                                open={isToolTipOpenSubject}
                                onClose={() => {
                                  setTimeout(() => {
                                    setIsToolTipOpenSubject(false);
                                  }, 2000);
                                }}
                                arrow
                              >
                                <div
                                  className={classes.personalize_dropdown}
                                  onClick={() => {
                                    setIsSubjectPersonalizeDropDownOpen(!isSubjectPersonalizeDropDownOpen);
                                  }}
                                >
                                  <Tooltip
                                    title="You can insert personalized dynamic text in your emails based upon on your contacts in Cohere. When your Clients read the email it will show their personalized information."
                                    arrow
                                  >
                                    <div>
                                      <PersonalizedIcon />
                                    </div>
                                  </Tooltip>
                                </div>
                              </Tooltip>
                              {isSubjectPersonalizeDropDownOpen && (
                                <div
                                  style={{ position: 'absolute', top: '35px', right: 0, zIndex: 100 }}
                                  className={classes.personalize_dropdownpanel}
                                >
                                  <div
                                    className={classes.personalize_dropdownpanel_item}
                                    onClick={e => {
                                      copyToClipboard('{{first_name}}');
                                      setIsSubjectPersonalizeDropDownOpen(false);
                                      setIsToolTipOpenSubject(true);
                                    }}
                                  >
                                    {`First Name`}
                                    <Icon size={0.7} color={'#4a4a4a'} path={mdiContentCopy} />
                                  </div>
                                  <div
                                    className={classes.personalize_dropdownpanel_item_last}
                                    onClick={e => {
                                      copyToClipboard('{{last_name}}');
                                      setIsSubjectPersonalizeDropDownOpen(false);
                                      setIsToolTipOpenSubject(true);
                                    }}
                                  >
                                    {`Last Name`}
                                    <Icon size={0.7} color={'#4a4a4a'} path={mdiContentCopy} />
                                  </div>
                                </div>
                              )}
                            </>
                          }
                        />
                      </div>

                      <div className={classes.field_container}>
                        <LabelInput
                          tooltipText="Text that appears after your subject line. If this field is left blank the first line of your email will display."
                          label={'Preheader'}
                        />
                        <Input
                          underLineColor={underLineColorOfField}
                          type="text"
                          name="preHeaderText"
                          fullWidth
                          redError={true}
                          emailMarketingField={true}
                          inputClasses={{
                            error: classes.error,
                          }}
                        />
                      </div>
                      <div className={classes.field_container}>
                        <LabelInput tooltipText="This is the name your email will be sent from." label={'From Name'} />
                        <Input
                          underLineColor={underLineColorOfField}
                          type="text"
                          name="fromName"
                          fullWidth
                          redError={true}
                          emailMarketingField={true}
                          inputClasses={{
                            error: classes.error,
                          }}
                        />
                      </div>
                      <div className={classes.field_container}>
                        <LabelInput
                          tooltipText="This is the Email address you are sending from."
                          label={'Sending Address'}
                        />
                        {/* <Input
                          underLineColor={underLineColorOfField}
                          type="text"
                          name="fromEmail"
                          fullWidth
                          redError={true}
                        /> */}
                        <Select
                          helperTextErrorColor="red"
                          fullWidth
                          emailMarketing={true}
                          underLineColor={underLineColorOfField}
                          className={classes.select}
                          classes={{
                            select: classes.Mui_select,
                          }}
                          onChange={handleSendingAddressChange}
                          name="fromEmail"
                          items={verifiedSendingAddresses?.concat([{ email: 'Add Another Email' }]).map(item => ({
                            title: item.email,
                            value: item.email,
                            customIcon: item?.isVerified ? verfiedIcon : notverifiedIcon,
                          }))}
                        />
                      </div>
                    </div>
                    <div className={classes.email_label}>Choose Your Email Type</div>
                    <div className={classes.radio_btns_conatiner}>
                      <FormControlLabel
                        className={classes.radio_btn}
                        control={
                          <Radio
                            onChange={event => {
                              setFieldValue('isGraphicalEmail', false);
                              setFieldValue('emailContent', textData);
                              setShowTextEditor(false);
                              setTimeout(() => {
                                setShowTextEditor(true);
                              }, 200);
                            }}
                            color="primary"
                            checked={!values?.isGraphicalEmail}
                          />
                        }
                        label="Text Only"
                      />
                      <Tooltip
                        className=""
                        title="Coming soon..."
                        open={tooltipOpen}
                        arrow
                        onClose={() => {
                          setTooltipOpen(false);
                        }}
                      >
                        <FormControlLabel
                          className={classes.radio_btn}
                          control={
                            <Radio
                              disabled={true}
                              onChange={event => {
                                setFieldValue('isGraphicalEmail', true);
                                setFieldValue('emailContent', graphicalData);
                                setShowTextEditor(false);
                                setTimeout(() => {
                                  setShowTextEditor(true);
                                }, 200);
                              }}
                              color="primary"
                              checked={values?.isGraphicalEmail}
                            />
                          }
                          onMouseOver={() => {
                            setTooltipOpen(true);
                          }}
                          label="Graphical"
                        />
                      </Tooltip>
                      <div className={classes.section}>
                        <div className={classes.btn_container}>
                          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                            <input
                              className={classes.email_input}
                              type="email"
                              placeholder="Add Email Address"
                              value={testEmailAddess}
                              onChange={handleTestEmailChange}
                            />
                            {emailAddressError && <StyledError>{emailAddressError}</StyledError>}
                          </div>
                          <Button
                            className={classes.btn_test}
                            autoWidth
                            disabled={
                              sendingTestEmail ||
                              errors?.emailContent ||
                              !values?.emailContent ||
                              emailAddressError ||
                              testEmailAddess.length === 0
                            }
                            onClick={() => {
                              setSendingTestEmail(true);
                              const data = {
                                fromEmail: values?.fromEmail,
                                fromName: values?.fromName,
                                toEmail: testEmailAddess,
                                htmlBody: values?.emailContent,
                                subject: values?.emailSubject,
                              };
                              createCampaignServices
                                .sendTestEmail(data)
                                .then(res => {
                                  setSendingTestEmail(false);
                                  setEmailSuccessful(true);
                                })
                                .catch(error => {
                                  setSendingTestEmail(false);
                                });
                            }}
                          >
                            Send Test
                            <LabelInput
                              tooltipText={`You will receive an email at ${testEmailAddess || user?.email}.`}
                              infoColor="white"
                            />
                          </Button>
                          <Button
                            onClick={() => setOpenCampaignPreview(true)}
                            className={classes.btn_preview}
                            invert
                            textColor="#c9b382"
                            autoWidth
                          >
                            Preview
                          </Button>
                        </div>
                      </div>
                    </div>

                    {(textOnly || graphical) && showTextEditor && (
                      <div className={classes.template_editor_container}>
                        <CKEditor
                          config={{
                            editorplaceholder: 'Write your email here',
                            allowedContent: true,
                            height: '500px',
                            width: '100%',
                            dialog_noConfirmCancel: true,
                            toolbar: [
                              { name: 'undo', items: ['Undo', 'Redo', 'SelectAll'] },
                              {
                                name: 'alignment',
                                items: ['JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock'],
                              },
                              { name: 'indent', items: ['Indent', 'Outdent'] },
                              '/',
                              { name: 'colors', items: ['BGColor'] },
                              {
                                name: 'basicstyles',
                                items: ['Bold', 'Italic', 'Underline', 'Code', 'Strike'],
                              },
                              { name: 'list', items: ['NumberedList', 'BulletedList'] },
                              { name: 'link', items: ['Link'] },
                              { name: 'insert', items: ['EmojiPanel'] },
                              // { name: 'paragraph', items: ['Blockquote'] },
                              { name: 'format', items: ['Format'] },
                              { name: 'font', items: ['Font'] },
                              { name: 'fontSize', items: ['FontSize'] },
                            ],
                            extraPlugins: [
                              'emoji',
                              'font',
                              'editorplaceholder',
                              'colorbutton',
                              'justify',
                              'colordialog',
                            ],
                            removeButtons: [],
                          }}
                          initData={values?.emailContent}
                          onChange={event => {
                            setFieldValue('emailContent', event?.editor?.getData()?.trim());
                            if (textOnly) {
                              setTextData(event?.editor?.getData()?.trim());
                            }
                            if (graphical) {
                              setGraphicalData(event?.editor?.getData()?.trim());
                            }
                          }}
                          onLoaded={() => {
                            setIsEditorLoaded(true);
                          }}
                        />
                        {isEditorLoaded && (
                          <Tooltip
                            className="cursor-pointer"
                            title="Copied!"
                            open={isToolTipOpen}
                            onClose={() => {
                              setTimeout(() => {
                                setIsToolTipOpen(false);
                              }, 2000);
                            }}
                            arrow
                          >
                            <div
                              className={classes.personalize_dropdown}
                              onClick={() => {
                                setIsPersonalizeDropDownOpen(!isPersonalizeDropDownOpen);
                              }}
                            >
                              Personalized Text
                              <ArrowDropDownIcon onClick={() => {}} />
                              <Tooltip
                                title="You can insert personalized dynamic text in your emails based upon on your contacts in Cohere. When your Clients read the email it will show their personalized information."
                                arrow
                              >
                                <InfoIcon htmlColor={colors.lightBrown} />
                              </Tooltip>
                            </div>
                          </Tooltip>
                        )}

                        {isPersonalizeDropDownOpen && (
                          <div className={classes.personalize_dropdownpanel}>
                            {/* <div
                              className={classes.personalize_dropdownpanel_item}
                              onClick={e => {
                                copyToClipboard(e.target.innerText);
                                setIsPersonalizeDropDownOpen(false);
                                setIsToolTipOpen(true);
                              }}
                            >
                              {`{emailAddress}`}
                            </div> */}
                            <div
                              className={classes.personalize_dropdownpanel_item}
                              onClick={e => {
                                copyToClipboard('{{first_name}}');
                                setIsPersonalizeDropDownOpen(false);
                                setIsToolTipOpen(true);
                              }}
                            >
                              {`First Name`}
                              <Icon size={0.7} color={'#4a4a4a'} path={mdiContentCopy} />
                            </div>
                            <div
                              className={classes.personalize_dropdownpanel_item_last}
                              onClick={e => {
                                copyToClipboard('{{last_name}}');
                                setIsPersonalizeDropDownOpen(false);
                                setIsToolTipOpen(true);
                              }}
                            >
                              {`Last Name`}
                              <Icon size={0.7} color={'#4a4a4a'} path={mdiContentCopy} />
                            </div>
                            {/* <div
                              className={classes.personalize_dropdownpanel_item}
                              onClick={e => {
                                copyToClipboard(e.target.innerText);
                                setIsPersonalizeDropDownOpen(false);
                                setIsToolTipOpen(true);
                              }}
                            >
                              {`{phoneNumber}`}
                            </div>
                            <div
                              className={classes.personalize_dropdownpanel_item}
                              onClick={e => {
                                copyToClipboard(e.target.innerText);
                                setIsPersonalizeDropDownOpen(false);
                                setIsToolTipOpen(true);
                              }}
                            >
                              {`{title}`}
                            </div>
                            <div
                              className={classes.personalize_dropdownpanel_item_last}
                              onClick={e => {
                                copyToClipboard(e.target.innerText);
                                setIsPersonalizeDropDownOpen(false);
                                setIsToolTipOpen(true);
                              }}
                            >
                              {`{contributionName}`}
                            </div> */}
                          </div>
                        )}

                        {errors && errors?.emailContent && <div className={classes.error}>{errors?.emailContent}</div>}
                      </div>
                    )}
                  </div>
                </CardBody>
              </Card>
              <Buttons
                backButtonTitle="Back"
                nextButtonTitle="Save and Next"
                formId="create-campaign-form"
                disabled={isLoading}
                onNextClickHandler={e => {
                  const to = isEditing ? `/edit-campaign/${query?.id}/summary` : '/create-campaign/summary';
                  setRedirectTo(to);
                  handleSubmit(e);
                }}
                backClickHandler={() => {
                  history.replace(isEditing ? `/edit-campaign/${query?.id}/contacts` : '/create-campaign/contacts');
                }}
              />
            </>
          );
        }}
      </Formik>
      <Modal
        isOpen={addEmailPopUpOpen}
        title="Where do you want your marketing emails come from?"
        submitTitle="Add Email"
        cancelTitle="Cancel"
        onSubmit={() => {
          setIsLoading(true);
          const data = `${email}@${selectedDomain}`;
          EmailMarketingIntegrationService.addSenderEmail(data)
            .then(res => {
              dispatch(setAccount(res));
              dispatch(setDomains(res?.domainData));
              setEmail('');
              setAddEmailPopUpOpen(false);
              setIsLoading(false);
              setTimeout(() => {
                campaignFormRef.current.setFieldValue('fromEmail', data);
              }, 2000);
            })
            .catch(err => {
              const errorMessage = err?.response?.data?.message || err?.message;
              setAccountCreationError(errorMessage);
              setIsLoading(false);
            });
        }}
        onCancel={() => {
          setEmail('');
          setAddEmailPopUpOpen(false);
        }}
        widthRequired
        reduceCancelButton
      >
        <div className={classes.email_main_container}>
          <TextField
            className={classes.email_main}
            placeholder="Email Address"
            variant="outlined"
            value={email}
            onChange={handleEmailChange}
            InputProps={{
              classes: {
                root: classes.email_root,
                notchedOutline: classes.email_notchedOutline,
                adornedEnd: classes.email_adornedEnd,
              },
              endAdornment: (
                <div className={classes.endAdornment}>
                  <MUISelect
                    name="domain-select"
                    value={selectedDomain}
                    disableUnderline={true}
                    items={verifiedDomains?.map(d => {
                      return {
                        title: `@${d.domain}`,
                        value: d.domain,
                      };
                    })}
                    onChange={e => {
                      setSelectedDomain(e.target.value);
                    }}
                    classes={{
                      root: classes.select_root,
                    }}
                  />
                </div>
              ),
            }}
          />
        </div>
      </Modal>
      <Modal
        title="Custom Branding Colors "
        isOpen={openBrandingModal}
        applyTheming
        brandingColor={false}
        hiddenCancel
        isCreatingContribution={true}
        onCancel={() => {
          setOpenBrandingModal(false);
        }}
        onSubmit={() => {
          setOpenBrandingModal(false);
          setOpenBrandingModalSavingPopUp(true);
        }}
        submitTitle="Save"
      >
        <CustomBrandingModal />
      </Modal>

      <Modal
        title="Custom Branding Colors Set As Default"
        isOpen={openBrandingModalSavingPopUp}
        brandingColor={false}
        // hiddenCancel
        // loading={saving}
        // onCancel={() => {
        //   setOpenBrandingModalPopUp(false);
        //   setOpenBrandingModal(false);
        //   formProps.setFieldValue('isLegacyColorsActive', true);
        // }}
        // onSubmit={() => {
        //   if (
        //     !formProps.errors?.PrimaryColorCode &&
        //     !formProps.errors?.AccentColorCode &&
        //     !formProps.errors?.TertiaryColorCode &&
        //     !formProps.errors?.TextColorCode
        //   ) {
        //     const userData = {
        //       id: user.id,
        //       brandingColors: {
        //         PrimaryColorCode: formProps.values.PrimaryColorCode,
        //         AccentColorCode: formProps.values.AccentColorCode,
        //         TertiaryColorCode: formProps.values.TertiaryColorCode,
        //         TextColorCode: formProps.values.TextColorCode,
        //       },
        //       customLogo: formProps.values.customLogo,
        //       isDarkModeEnabled: formProps.values.isDarkModeEnabled,
        //     };
        //     // saveData(formProps, to, true);
        //     editUserColors(userData).then(res => {
        //       dispatch(userActions.getUserSuccess(res));
        //     });

        //     // editProfile(user?.id, userData);
        //     setOpenBrandingModal(false);
        //     setOpenBrandingModalPopUp(false);
        //     formProps.setFieldValue('isLegacyColorsActive', true);
        //   }
        // }}
        onCancel={() => {
          setOpenBrandingModalSavingPopUp(false);
        }}
        cancelTitle="Just For This Campaign"
        submitTitle="Set as default for all Email Campaigns"
      >
        <Grid container item style={{ marginBottom: '50px', width: mobileView ? 'auto' : '500px' }}>
          Do you want to make these colors your default branding for all the campaigns
        </Grid>
      </Modal>

      <Modal
        disableConfirm={true}
        title="Campaign Preview "
        isOpen={openCampaignPreview}
        hiddenCancel
        onCancel={() => {
          setOpenCampaignPreview(false);
        }}
        widthRequired
      >
        <div className={classes.preview_conatiner}>
          <div className={classes.preview_btns}>
            <div className={classes.image} onClick={() => setPhonePreview(true)}>
              <PhonePreviewVector
                fillBackgroundColor={openPhonePreview ? '#F1F8FF' : ''}
                fillColor={openPhonePreview ? '#215C73' : ''}
              />
            </div>
            <div className={classes.image} onClick={() => setPhonePreview(false)}>
              <PhoneDesktop
                fillBackgroundColor={!openPhonePreview ? '#F1F8FF' : ''}
                fillColor={!openPhonePreview ? '#215C73' : ''}
              />
            </div>
          </div>
          <div className={classes.preview_body}>
            <div
              className={openPhonePreview ? classes.responsive_preview : classes.responsive_desktop_preview}
              dangerouslySetInnerHTML={{ __html: campaignFormRef?.current?.values?.emailContent }}
            ></div>
          </div>
        </div>
      </Modal>
      <Modal
        disableConfirm={false}
        title="Email Sent"
        isOpen={emailSentSuccessful}
        hiddenCancel
        onSubmit={() => {
          setEmailSuccessful(false);
        }}
        submitTitle="Ok"
        onCancel={() => {
          setEmailSuccessful(false);
        }}
        minHeight={'160px'}
      >
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          Test email sent successfully.
        </div>
      </Modal>
    </CreateCampaignMain>
  );
};

export default DesignPage;
