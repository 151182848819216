import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import * as pageActions from 'actions/page';

function usePageActions() {
  const dispatch = useDispatch();

  const changeHeader = useCallback(
    title => {
      dispatch(pageActions.changeHeader(title));
    },
    [dispatch],
  );

  return { changeHeader };
}

function useHeader(title) {
  const { changeHeader } = usePageActions();

  useEffect(() => {
    changeHeader(title);
  }, [title, changeHeader]);
}

export { usePageActions, useHeader };
