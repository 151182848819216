import React, { useEffect, useCallback, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useHeader, useEndVideoChat } from 'hooks';
import { ContributionType } from 'helpers/constants';
import { fetchUpcomingCreatedWithType } from 'actions/contributions';

import Banner from 'components/Banner/Banner';
import Loader from 'components/UI/Loader';
import ContributionsTable from 'components/Tables/Contributions';
import SessionsTable from 'components/Tables/Sessions';
import { useAccount } from '../../hooks';

const MembershipsPageContainer = () => {
  useHeader('Memberships');
  const dispatch = useDispatch();
  const { user } = useAccount();
  const isLoading = useSelector(({ upcomingCreatedWithType: { loading } }) => loading);
  const contributions = useSelector(
    ({ upcomingCreatedWithType: { upcomingCreatedWithType } }) => upcomingCreatedWithType?.contributions || [],
  );
  const sessions = useSelector(
    ({ upcomingCreatedWithType: { upcomingCreatedWithType } }) => upcomingCreatedWithType?.upcomingSessions || [],
  );
  const closestClassForBanner = useSelector(
    ({ upcomingCreatedWithType: { upcomingCreatedWithType } }) => upcomingCreatedWithType?.closestClassForBanner || {},
  );

  const [prevData, setPrevData] = useState({
    contributions: contributions,
    sessions: sessions,
  });

  useEffect(() => {
    if (!isLoading) {
      setPrevData({
        contributions: contributions,
        sessions: sessions,
      });
    }
  }, [isLoading]);

  const renderList = useCallback(async () => {
    dispatch(fetchUpcomingCreatedWithType(user.id, ContributionType.contributionMembership));
  }, [user.id]);

  const { Popup } = useEndVideoChat(renderList);

  useEffect(() => {
    renderList();
  }, [renderList]);

  if (!prevData?.contributions?.length && !isLoading) {
    return <div>There are no sessions yet.</div>;
  }

  return (
    <div className="tables-container">
      {isLoading && <Loader />}
      <div>
        <Banner type="closestSession" closestClassForBanner={closestClassForBanner} />
        {prevData?.contributions?.length > 0 && (
          <>
            <h2 className="table__title">My Contributions</h2>
            <ContributionsTable rows={isLoading ? prevData?.contributions : contributions} refreshList={renderList} />
          </>
        )}
      </div>
      <div>
        {prevData?.sessions?.length > 0 && (
          <>
            <SessionsTable rows={isLoading ? prevData?.sessions : sessions} refreshList={renderList} />
          </>
        )}
      </div>
      <Popup />
    </div>
  );
};

export default MembershipsPageContainer;
