import React from 'react';
import styled from 'styled-components';
import Avatar from '@material-ui/core/Avatar';
import { Field } from 'formik';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import Button from '../../../../../components/FormUI/Button';
import { colors } from '../../../../../utils/styles';

export const AvatarComponent = styled(Avatar)`
  width: 46px;
  height: 46px;

  ${props => `margin: ${props.customMargin ? props.customMargin : '15px'}`};

  ${props => `padding: ${props.customPadding ? props.customPadding : '0'}`};

  ${({ mobileView }) => {
    return (
      mobileView &&
      ` 
        font-size: 0.7rem;
      `
    );
  }}
`;

export const StyledTextArea = styled(Field)`
  border: none;
  border-radius: 40px;
  font-size: 1rem;
  color: rgba(0, 0, 0, 0.87);
  line-height: 1.24;
  letter-spacing: 0.25px;
  resize: none;
  padding: 0.85rem;
  font-family: 'Avenir';
  font-weight: normal;
  outline: none;
  background: ${colors.lightGray};
  width: 100%;
  height: 100%;
  ${({ mobileView }) => {
    return mobileView && `padding: 0.85rem 1rem;`;
  }}
`;

export const StyledButton = styled(Button)`
  padding: 0.5rem !important;
  margin-top: 1.35rem;
  margin-bottom: 1.35rem;
  margin-right: 1.35rem;
  margin-left: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border-radius: 100px;
`;

export const StyledPhotoCameraIcon = styled(PhotoCameraIcon)`
  margin-right: 1rem;
  ${({ mobileView }) => {
    return mobileView && `margin-right: .5rem;`;
  }}
`;

export const StyledTextError = styled.span`
  color: ${colors.fadedPurple} !important;
  text-align: left;
  font-size: 1rem;
  letter-spacing: 0.1px;
  margin-top: -15px;
  padding-bottom: 10px;
  padding-left: 30px;
  ${({ mobileView }) => {
    return (
      mobileView &&
      ` 
         font-size: .8rem;
         padding-left: 20px;`
    );
  }}
`;
