import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { UserRoles } from 'helpers/constants';
import ResourcesCard from 'components/ResourcesCard/ResourcesCard';
import Loader from 'components/UI/Loader';
import { useAccount } from 'hooks';
import { useStyles } from 'components/NotificationCard/hooks/useStyles';

const ResourcesContainer = props => {
  const { currentRole } = useAccount();
  const { contributionResources } = props;
  const classNames = useStyles();
  if (!contributionResources) {
    return (
      <Grid className={classNames.wrapper}>
        <Loader relative />
      </Grid>
    );
  }
  return (
    <ResourcesCard
      resourceTitle={contributionResources?.documentOriginalNameWithExtension || ''}
      resourceImg={contributionResources?.attachementUrl || ''}
      resourceDateTime={contributionResources?.uploadDateTime || ''}
      resourceContentType={contributionResources?.contentType || ''}
      resourceId={contributionResources?.id || ''}
      resourceSessionId={contributionResources?.sessionId || ''}
      resourceKeyExntension={contributionResources?.documentKeyWithExtension || ''}
    />
  );
};
ResourcesContainer.propTypes = {
  contributionResources: PropTypes.shape({
    resourceTitle: PropTypes.string,
    resourceImg: PropTypes.string,
    resourceDateTime: PropTypes.string,
    resourceContentType: PropTypes.string,
    resourceId: PropTypes.string,
    resourceSessionId: PropTypes.string,
    resourceKeyExntension: PropTypes.string,
  }).isRequired,
};
export default ResourcesContainer;
