import { handleActions } from 'redux-actions';

import * as actions from 'actions/audioOutputDevices.actions';

const initialState = {
  activeAudioOutputDevice: null,
};

const audioOutputDevices = handleActions(
  new Map([
    [
      actions.audioOutputDevicesActions.setActiveAudioOutputDevice,
      (state, action) => ({
        ...state,
        activeAudioOutputDevice: action.payload,
      }),
    ],
  ]),
  initialState,
);

export default audioOutputDevices;
