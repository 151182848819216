import axiosInstance from 'utils/axiosInstance';

const getProfileLinkNameByConributionId = id =>
  axiosInstance.get(`/ProfilePage/GetProfileLinkNameByContributionId?ContributionId=${id}`);

const getUserProfileStatus = userId =>
  axiosInstance.get(`User/getUserProfileStatus`, {
    params: { userId },
  });

export { getProfileLinkNameByConributionId, getUserProfileStatus };
