import React from 'react';
import Modal from 'components/UI/Modal';
import classes from './GuideVideoModal.module.scss';
import ReactPlayer from 'react-player';

const DummyLink = 'https://www.youtube.com/watch?v=yqqphr4S-i0&ab_channel=SinghinUSA';
const GuideVideoModal = ({ isOpen, setOpen, videoSrc = '' }) => {
  return (
    <>
      <Modal
        isOpen={isOpen}
        onCancel={() => {
          setOpen(false);
        }}
        onSubmit={() => {
          setOpen(false);
        }}
        submitTitle="Done"
        title="How to create Segment?"
        widthRequired
      >
        <div className={classes.video_container}>
          <ReactPlayer className={classes.video} controls url={videoSrc || DummyLink} width="100%" />
        </div>
      </Modal>
    </>
  );
};

export default GuideVideoModal;
