import React, { Fragment } from 'react';
import classes from './AddChoices.module.scss';
import Modal from 'components/UI/Modal';
import { FieldArray, Formik } from 'formik';
import Button from 'components/FormUI/Button';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import Choice from './Choice/Choice';
import * as Yup from 'yup';
import styled from 'styled-components';
import { Checkbox, FormControlLabel, TextField } from '@material-ui/core';
import { DOCUMENT_TYPES } from '../../constants';
import CrossIcon from 'pages/Contacts/Components/Filter/SVG/CrossIcon';
import CommonErrorMessage from 'components/FormUI/CommonErrorMessage';

const StyledLabel = styled.div`
  font-family: Avenir;
  font-weight: 350;
  font-size: 16px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.87);
`;

const DummyChoice = {
  value: '',
  isCorrect: false,
};

const StyledError = styled.div`
  color: red;
  margin: 5px 0px 0px 40px;
`;

const AddChoicesModal = ({ isOpen, onClose, choices, onSubmit, mobileView, docType }) => {
  const handleDragEnd = (results, choices, setFieldValue) => {
    const { source, destination } = results;
    if (!destination) return;

    if (source.droppableId === destination.droppableId && source.index === destination.index) {
      return;
    }

    const reOrderedChoices = [...choices];
    const destinationChoices = reOrderedChoices[destination.index];
    const [removedSection] = reOrderedChoices.splice(source.index, 1);
    reOrderedChoices.splice(destination.index, 0, removedSection);
    setFieldValue('choices', reOrderedChoices);
  };

  return (
    <Formik
      initialValues={{
        choices: choices?.length > 0 ? choices : [DummyChoice],
      }}
      validationSchema={Yup.object().shape({
        choices: Yup.array().of(
          Yup.object().shape({
            value: Yup.string().required('Required'),
            isCorrect: Yup.boolean(),
          }),
        ),
      })}
      onSubmit={values => {
        const { choices } = values;
        onSubmit(choices);
        onClose();
      }}
    >
      {({ values, handleSubmit, setFieldValue, errors, touched, handleBlur }) => {
        return (
          <FieldArray name="choices">
            {({ push, remove, form }) => {
              const addChoice = () => {
                push(DummyChoice);
              };

              const removeChoice = idx => {
                remove(idx);
              };
              const disableSubmit = values?.choices?.every(item => item?.isCorrect === false);
              const disableSubmitForm = () => {
                return form.values?.choices?.every(item => item?.value?.length === 0 || item?.value?.length > 21);
              };
              return (
                <Modal
                  isOpen={isOpen}
                  onCancel={onClose}
                  onSubmit={handleSubmit}
                  title="Add Options"
                  disableSubmitClick={docType === DOCUMENT_TYPES.Quiz ? disableSubmit : disableSubmitForm()}
                  submitTitle="Create"
                  cancelTitle="Cancel"
                  cancelBtnStyle={{ color: 'black' }}
                  reduceCancelButton
                  widthRequired
                >
                  <div className={classes.container}>
                    {docType === DOCUMENT_TYPES.Form ? (
                      <div className={classes.choices_list}>
                        {values?.choices?.map((choice, index) => {
                          return (
                            <>
                              <div className={classes.choice} key={index}>
                                <TextField
                                  placeholder="Type here..."
                                  variant="outlined"
                                  fullWidth
                                  value={choice?.value}
                                  onChange={e => {
                                    setFieldValue(`choices[${index}].value`, e?.target?.value);
                                    setFieldValue(`isSaved`, false);
                                  }}
                                  InputProps={{
                                    maxLength: 20,
                                    classes: {
                                      root: classes.input_root,
                                      notchedOutline: classes.input_notchedOutline,
                                      focused: classes.input_focused,
                                    },
                                  }}
                                />
                                <div
                                  className={classes.cross_btn_container}
                                  onClick={() => {
                                    removeChoice(index);
                                  }}
                                >
                                  <CrossIcon />
                                </div>
                              </div>
                              {choice?.value.length > 20 && (
                                <CommonErrorMessage
                                  align="left"
                                  message="Choice should not be more than 20 characters."
                                />
                              )}
                            </>
                          );
                        })}
                      </div>
                    ) : (
                      <DragDropContext onDragEnd={results => handleDragEnd(results, values?.choices, setFieldValue)}>
                        <Droppable droppableId="choices" type="choice" className="test">
                          {provided => (
                            <div {...provided.droppableProps} ref={provided.innerRef} className={classes.choices_list}>
                              {values?.choices?.map((choice, index) => {
                                const hasCorrectAnswer = values?.choices?.some(item => item.isCorrect === true);
                                return (
                                  <Fragment key={choice?.localId}>
                                    <Draggable draggableId={`choice-${index}`} key={`choice-${index}`} index={index}>
                                      {provided => (
                                        <div {...provided.draggableProps} ref={provided.innerRef}>
                                          <Choice
                                            key={index}
                                            choice={choice}
                                            index={index}
                                            parentProvided={provided}
                                            setFieldValue={setFieldValue}
                                            removeChoice={removeChoice}
                                            errors={errors}
                                            handleBlur={handleBlur}
                                            allChoices={values?.choices}
                                            mobileView={mobileView}
                                          />
                                          {mobileView && (
                                            <div className={classes.correct_answer}>
                                              <FormControlLabel
                                                className={classes.MuiFormControlLabel_root}
                                                checked={choice?.isCorrect}
                                                onChange={() => {
                                                  if (choice?.value?.length > 0) {
                                                    if (!hasCorrectAnswer) {
                                                      let updatedArray = values?.choices?.map(item => ({
                                                        ...item,
                                                        isCorrect: false,
                                                      }));
                                                      setFieldValue(`choices`, updatedArray);
                                                      setFieldValue(`choices[${index}].isCorrect`, !choice?.isCorrect);
                                                    } else if (choice?.isCorrect) {
                                                      setFieldValue(`choices[${index}].isCorrect`, !choice?.isCorrect);
                                                    } else {
                                                      let updatedArray = values?.choices?.map(item => ({
                                                        ...item,
                                                        isCorrect: false,
                                                      }));
                                                      setFieldValue(`choices`, updatedArray);
                                                      setFieldValue(`choices[${index}].isCorrect`, !choice?.isCorrect);
                                                    }
                                                  }
                                                }}
                                                control={
                                                  <Checkbox
                                                    classes={{
                                                      root: classes.checkbox_root,
                                                    }}
                                                    color="primary"
                                                  />
                                                }
                                                label={<StyledLabel>Correct Answer</StyledLabel>}
                                                name="default"
                                              />
                                            </div>
                                          )}
                                          {touched?.choices && errors?.choices && errors?.choices[index]?.value && (
                                            <StyledError>{errors?.choices[index]?.value}</StyledError>
                                          )}
                                        </div>
                                      )}
                                    </Draggable>
                                  </Fragment>
                                );
                              })}
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      </DragDropContext>
                    )}

                    <div className={classes.btn_container}>
                      <Button className={classes.add_choice_btn} onClick={addChoice}>
                        + Add an option
                      </Button>
                    </div>
                  </div>
                </Modal>
              );
            }}
          </FieldArray>
        );
      }}
    </Formik>
  );
};

export default AddChoicesModal;
