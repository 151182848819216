import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import classNames from 'classnames'

import './MessageSeparator.scss'

const checkIfSameDay = (momentA, momentB) => momentA.isSame(momentB, 'day')

const getSeparatorTitle = (messageDate) => {
  const today = moment()
  if (checkIfSameDay(messageDate, today)) {
    return 'Today'
  }

  const yesterday = moment().subtract(1, 'days')
  if (checkIfSameDay(messageDate, yesterday)) {
    return 'Yesterday'
  }

  return messageDate.format('MMM Do YYYY')
}

const MessageSeparator = ({ className, date }) => {
  const messageDate = moment(date)

  return (
    <div className={classNames(className, 'chat-message-separator')}>
      <div className="chat-message-separator__separator" />
      <p className="chat-message-separator__title">{getSeparatorTitle(messageDate)}</p>
      <div className="chat-message-separator__separator" />
    </div>
  )
}

MessageSeparator.propTypes = {
  className: PropTypes.string.isRequired,
  date: PropTypes.instanceOf(Date).isRequired,
}

export default MessageSeparator
