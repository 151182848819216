import TwilioChatError from './TwilioChatError';

const accessTokenExpiredErrorKeywords = ['access token', 'expired'];
const accessTokenInvalidErrorKeywords = ['access token', 'invalid'];

const getTwilioChatErrorFrom = error => {
  const errorMessage = error.message.toLowerCase();

  if (accessTokenExpiredErrorKeywords.every(kw => errorMessage.includes(kw))) {
    return TwilioChatError.tokenExpired;
  }
  if (accessTokenInvalidErrorKeywords.every(kw => errorMessage.includes(kw))) {
    return TwilioChatError.invalidToken;
  }

  return TwilioChatError.unknown;
};

export default getTwilioChatErrorFrom;
