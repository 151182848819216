import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from '@material-ui/core';
import Button from 'components/FormUI/Button';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { useAccount } from 'hooks/index';
import { useSelector } from 'react-redux';
import { SIGNUP_TYPES } from 'helpers/constants';

const useStyles = makeStyles(theme => ({
  actions: {
    justifyContent: 'center',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

function FreeTrialModal({ isOpen, handleSubmit, handleClose }) {
  const classes = useStyles();
  const { user } = useAccount();
  const { signupType } = useSelector(state => state.account);
  const showDialogModal =
    user.signupType === SIGNUP_TYPES.TYPE_A ||
    user.signupType === SIGNUP_TYPES.TYPE_B ||
    signupType === SIGNUP_TYPES.TYPE_A ||
    signupType === SIGNUP_TYPES.TYPE_B;

  return (
    <Dialog fullWidth open={showDialogModal ? false : isOpen}>
      <DialogTitle>
        <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography align="center" variant="h6">
          Welcome to Cohere!
        </Typography>
        <DialogContentText align="center">
          One place to sell, deliver and scale your online services. Ready to activate your trial Coach Account?
        </DialogContentText>
      </DialogContent>
      <Grid container item xs justifyContent="center" direction="column" alignItems="center">
        <DialogActions className={classes.actions}>
          <Button autoWidth onClick={handleSubmit}>
            Yes, I'm in!
          </Button>
        </DialogActions>
      </Grid>
    </Dialog>
  );
}

FreeTrialModal.propTypes = {
  isOpen: PropTypes.bool,
  handleSubmit: PropTypes.func,
  handleClose: PropTypes.func,
};

FreeTrialModal.defaultProps = {
  isOpen: false,
  handleSubmit: () => {},
  handleClose: () => {},
};

export default FreeTrialModal;
