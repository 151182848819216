import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { darken } from 'polished';
import { colors } from 'utils/styles';
import { TemplateType } from 'helpers/constants/templateType';
import { connect } from 'react-redux';
import useAccount from 'hooks/useAccount';
import { ContributionType } from 'helpers/constants';
import { lightOrDark } from 'utils/utils';
const StyledMobileButton = styled.button`
  position: relative;
  padding: 1rem 1.25rem;
  font-size: 0.875rem;
  border: none;
  color: #fff;
  fill: #fff; /* for svg */
  font-weight: 900;
  cursor: pointer;
  text-decoration: none;
  line-height: 1rem;
  letter-spacing: ${({ removeLetterSpaccing }) => (!removeLetterSpaccing ? '1.25px' : '')};
  min-width: 18rem;
  transition: background 0.2s;
  font-family: 'Avenir';
  ${({ activeTemplate, TCModal }) =>
    activeTemplate &&
    !TCModal &&
    css`
      filter: ${activeTemplate == TemplateType.TemplateOne ? ' drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))' : ''};
    `};
  ${({ activeTemplate }) =>
    activeTemplate
      ? css`
          border-radius: ${activeTemplate == TemplateType.TemplateOne
            ? '12px'
            : activeTemplate == TemplateType.TemplateTwo
            ? '5px'
            : ''};
        `
      : css`
          border-radius: 2px;
        `}
  ${({ autoWidth }) =>
    autoWidth &&
    css`
      min-width: auto;
    `}

    

  ${({ variant, backgroundColor, btnColor }) => {
    if (backgroundColor) {
      return css`
        background-color: ${backgroundColor};
      `;
    }
    return variant === 'primary'
      ? css`
          background-color: ${colors.darkOceanBlue};
        `
      : variant === 'grayedOut'
      ? css`
          background-color: ${colors.backgroundGrey};
        `
      : css`
          background-color: ${colors.lightBrown};
        `;
  }}


    
  ${({ invert }) =>
    invert &&
    css`
      color: rgba(0, 0, 0, 0.87);
      background-color: white;
      border: 1px solid rgba(0, 0, 0, 0.87);
    `}

  &:hover {
    ${({ variant, backgroundColor }) => {
      if (backgroundColor) {
        return css`
          // background-color: ${darken(0.05, backgroundColor)};
          background-color: ${backgroundColor};
        `;
      }
      return variant === 'primary'
        ? css`
            // background-color: ${darken(0.05, colors.darkOceanBlue)};
            background-color: ${colors.darkOceanBlue};
          `
        : variant === 'grayedOut'
        ? css`
  background-color: ${colors.borderLightGray})};
`
        : css`
            // background-color: ${darken(0.05, colors.lightBrown)};
            background-color: ${colors.lightBrown};
          `;
    }}

    ${({ invert }) =>
      invert &&
      css`
        // background-color: ${darken(0.05, 'white')};
        background-color: ${'white'};
      `}
  }

  :disabled {
    background-color: #ededed;
    color: #9b9b9b;
    fill: #9b9b9b; /* for svg */
    cursor: not-allowed;
    border: none;
  }

  ${({ mobileView }) =>
    mobileView &&
    `
    font-size: 12px;
    min-width: 8rem;
  `}

  ${({ mobileView, joinNow }) =>
    mobileView &&
    joinNow &&
    `
    font-size: 14px;
    padding: 8px 16px;
    min-width: 8rem;
  `}
`;

const MobileButton = ({ children, ...attrs }) => {
  return <StyledMobileButton {...attrs}>{children}</StyledMobileButton>;
};

MobileButton.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  active: PropTypes.bool,
  variant: PropTypes.oneOf(['primary', 'secondary']),
  backgroundColor: PropTypes.string,
  autoWidth: PropTypes.bool,
  invert: PropTypes.bool,
  mobileView: PropTypes.bool,
};

MobileButton.defaultProps = {
  children: 'Default Button',
  onClick: () => {},
  disabled: false,
  active: false,
  variant: 'secondary',
  backgroundColor: null,
  autoWidth: false,
  invert: false,
  mobileView: false,
};

const mapStateToProps = ({ contributions }) => ({
  brandingColors: contributions?.brandingColors,
  contributions: contributions,
});

export default connect(mapStateToProps)(MobileButton);
