import * as actions from 'actions/invitedClientData.actions';
import { handleActions } from 'redux-actions';

const initialState = {
  clientData: null,
  loadingClientData: false,
};

const reducerInvitedClientData = handleActions(
  new Map([
    [
      actions.SET_INVITED_CLIENT_DATA,
      (state, action) => ({
        ...state,
        clientData: action.payload,
        loadingClientData: false,
      }),
    ],
    [
      actions.SET_INVITED_CLIENT_DATA_LOADING,
      state => ({
        ...state,
        loadingClientData: true,
      }),
    ],
  ]),
  initialState,
);

export default reducerInvitedClientData;
