import React from 'react'
import PropTypes from 'prop-types'

import { useLocalParticipant, useLocalAudioTrack, useLocalVideoTrack } from '../../../../hooks'
import Participant from './Participant'
import { useNetworkQualityLevel } from '../../../../hooks/participants'

const RoomLocalParticipant = ({ participant, selectedAudioOutputDevice, ...restProps }) => {
  const { audioTrack, videoTrack } = useLocalParticipant(participant)
  const { isEnabled: isAudioEnabled } = useLocalAudioTrack(participant, audioTrack)
  const { isEnabled: isVideoEnabled } = useLocalVideoTrack(participant, videoTrack)

  const networkQualityLevel = useNetworkQualityLevel(participant)

  return (
    <Participant
      {...restProps}
      identity={participant.identity}
      networkQualityIndicator={networkQualityLevel}
      additionalInfo="(me)"
      isAudioEnabled={isAudioEnabled}
      audioVolume={0}
      isVideoEnabled={isVideoEnabled}
      selectedAudioOutputDevice={selectedAudioOutputDevice}
    />
  )
}

RoomLocalParticipant.propTypes = {
  participant: PropTypes.shape({
    identity: PropTypes.string,
  }).isRequired,
}

export default RoomLocalParticipant
