import React, { useCallback, useState, useEffect } from 'react';
import classes from './QuizFailed.module.scss';
import { SlimStyledSlider, StyledSlider, SlimSwitch, Switch, StyledInput } from 'components/switch/style';
import QuizImage from '../../QuizContent/Components/QuizImage/QuizImage';
import { useAccount, useHttp } from 'hooks/index';
import axiosInstance from 'utils/axiosInstance';
import { EditableTextField } from '../../QuizContent/Components/EditableTextField/EditableTextField';
import Button from 'components/FormUI/Button';
import TextField from 'components/UI/TextField';
import classNames from 'classnames';
import CommonErrorMessage from 'components/FormUI/CommonErrorMessage';
import { colors } from 'utils/styles';
import { Tooltip } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';

const QuizFailed = ({ passResultData, failResultData, setFieldValue, mobileView }) => {
  const [imgUrl, setImgUrl] = useState(null);
  const [failedMsg, setFailedMsg] = useState('You failed this quiz with a score of');
  const [scorePercentage, setScorePercentage] = useState('You need [80%] to pass');
  const [percentageValue, setPercentageValue] = useState('[%]');
  const { request, loading } = useHttp();
  const { user } = useAccount();

  const handleUploadFile = useCallback(({ target: { files } }) => {
    const formData = new FormData();

    formData.append('file', files[0]);

    request('/Content/AddAvatar', 'POST', formData, {
      'Content-Type': 'multipart/form-data',
    })
      .then(res => {
        setFieldValue('isSaved', false);
        setFieldValue('failResultData', {
          ...failResultData,
          image: res,
          isImageEnabled: true,
        });
      })
      .catch(console.dir);
    // .finally(updateUserImg);
  }, []);

  const fieldAvatarUrl = 'AvatarUrl';
  const fieldValue = null;
  const fieldinUserCollection = true;

  const onDeleteClick = useCallback(async () => {
    try {
      const res = await axiosInstance.post(
        `Content/RemoveImagesFromS3BucketAndAccountPage?imageUrl=${imgUrl}&field=${fieldAvatarUrl}&fieldValue=${fieldValue} &userId=${user?.id}&inUserCollection=${fieldinUserCollection}`,
      );
      setFieldValue('isSaved', false);
      setFieldValue('failResultData', {
        ...failResultData,
        image: null,
      });
    } catch (error) {
      console.error(error);
    }
  });

  const handleThankNoteChange = e => {
    e.stopPropagation();
    setFieldValue('isSaved', false);
    setFailedMsg(e.target.value);
    setFieldValue('failResultData', {
      ...failResultData,
      headerText: e.target.value,
    });
  };

  const handlePercentageChange = e => {
    e.stopPropagation();
    setScorePercentage(e.target.value);
    setFieldValue('isSaved', false);
    setFieldValue('failResultData', {
      ...failResultData,
      percentageText: e.target.value,
    });
  };
  useEffect(() => {
    if (
      failResultData?.isCutomButtonEnabled &&
      (failResultData?.buttonLink?.length === 0 || failResultData?.buttonLink === null)
    ) {
      setFieldValue('isSaved', true);
    }
    if (failResultData?.isCutomButtonEnabled && failResultData?.buttonText?.length === 0) {
      setFieldValue('isSaved', true);
    }
    if (failResultData?.isImageEnabled && (failResultData?.image === null || failResultData?.image?.length === 0)) {
      setFieldValue('isSaved', true);
    }
    if (failResultData?.percentageText?.length === 0) {
      setFieldValue('isSaved', true);
    }
    if (failResultData?.headerText?.length === 0) {
      setFieldValue('isSaved', true);
    }
  }, [failResultData]);
  return (
    <div className={classes.container}>
      <div className={classes.image_toggle_container}>
        <div className={classes.image_toggle}>
          <div className={classes.heading}>
            Add Custom Image
            {failResultData?.isImageEnabled ? (
              <Tooltip title={'Photo should be atleast 500x500px and upto 2 mb.'} arrow>
                <InfoIcon style={{ marginLeft: '10px' }} htmlColor={colors.lightBrown} />
              </Tooltip>
            ) : (
              <></>
            )}
          </div>
          <Switch className={classes.toggle_button}>
            <StyledInput
              type="checkbox"
              onClick={() => {
                setFieldValue('isSaved', false);
                setFieldValue('failResultData', {
                  ...failResultData,
                  isImageEnabled: failResultData.isImageEnabled ? false : true,
                });
              }}
              checked={failResultData.isImageEnabled}
            />
            <StyledSlider className="slider round" />
          </Switch>
        </div>
      </div>

      {failResultData?.isImageEnabled && (
        <>
          <div className={classes.image_container}>
            <QuizImage
              url={failResultData?.image}
              onUpload={handleUploadFile}
              onDelete={onDeleteClick}
              isUploading={loading}
            />
          </div>
          {/* <div className={classes.photo_size}>Photo should be atleast 500x500px and upto 2 mb.</div> */}
        </>
      )}

      <EditableTextField
        className={classNames(classes.failed_msg, { [classes.failed_msg_responsive]: mobileView })}
        value={failResultData.headerText}
        onChange={handleThankNoteChange}
        mobileView={mobileView}
      />
      <EditableTextField
        className={classNames(classes.score_percentage, { [classes.score_percentage_responsive]: mobileView })}
        value={failResultData.percentageText}
        onChange={handlePercentageChange}
        mobileView={mobileView}
      />
      <div className={classNames(classes.correct_answers, { [classes.correct_answers_responsive]: mobileView })}>
        You answered [no. of correct answers] out of [total no. of questions] questions correctly
      </div>

      <div className={classNames(classes.action_btns, { [classes.action_btns_responsive]: mobileView })}>
        <Button className={classes.retake_btn} autoWidth invert borderColor="#D1B989" textColor="#D1B989">
          Retake Quiz
        </Button>
        {failResultData.isCutomButtonEnabled ? (
          <Button className={classes.button_btn} autoWidth>
            {failResultData.buttonText}
          </Button>
        ) : (
          <Button className={classes.button_btn} autoWidth>
            Go to Dashboard
          </Button>
        )}
      </div>
      <div className={classes.image_toggle_container}>
        <div className={classes.image_toggle}>
          <div className={classes.heading}>Customize Button & URL</div>
          <Switch className={classes.toggle_button}>
            <StyledInput
              type="checkbox"
              onClick={() => {
                setFieldValue('isSaved', false);
                setFieldValue('failResultData', {
                  ...failResultData,
                  isCutomButtonEnabled: failResultData?.isCutomButtonEnabled ? false : true,
                });
              }}
              checked={failResultData.isCutomButtonEnabled}
            />
            <StyledSlider className="slider round" />
          </Switch>
        </div>
      </div>
      {failResultData.isCutomButtonEnabled && (
        <div className={classes.btn_text_input}>
          <TextField
            fullWidth
            placeholder="Button Text"
            variant="outlined"
            value={failResultData.buttonText}
            onChange={e => {
              setFieldValue('isSaved', false);
              setFieldValue('failResultData', {
                ...failResultData,
                buttonText: e.target.value,
              });
            }}
            inputClasses={{
              root: classes.input_root,
              notchedOutline: classes.input_notchedOutline,
              focused: classes.input_focused,
            }}
          />
          {failResultData.buttonText.length === 0 && (
            <CommonErrorMessage message="Customize button text should not be empty" />
          )}
        </div>
      )}
      {failResultData.isCutomButtonEnabled && (
        <div className={classes.btn_link_input}>
          <TextField
            fullWidth
            placeholder="Enter Custom Redirect URL"
            variant="outlined"
            inputClasses={{
              root: classes.input_root,
              notchedOutline: classes.input_notchedOutline,
              focused: classes.input_focused,
            }}
            value={failResultData.buttonLink}
            onChange={e => {
              setFieldValue('isSaved', false);
              setFieldValue('failResultData', {
                ...failResultData,
                buttonLink: e.target.value,
              });
            }}
          />
        </div>
      )}
    </div>
  );
};

export default QuizFailed;
