import { useEffect, useState } from 'react';
import { joinStatus } from 'utils/utils';
import { JOIN_CONTRIBUTION_STATUS } from '../constants';

const useApplicationStatus = ({ contribution, user }) => {
  const [status, setStatus] = useState(JOIN_CONTRIBUTION_STATUS.apply);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!contribution) return;
    async function apiCall() {
      const apiResponse = await joinStatus(contribution, user);
      if (apiResponse === JOIN_CONTRIBUTION_STATUS.join) {
        setStatus(JOIN_CONTRIBUTION_STATUS.join);
      }
      if (apiResponse === JOIN_CONTRIBUTION_STATUS.pending) {
        setStatus(JOIN_CONTRIBUTION_STATUS.pending);
      }
      setIsLoading(false);
    }
    apiCall();
  }, [contribution, user]);
  return {
    applicationRequiredButNotApproved:
      status === JOIN_CONTRIBUTION_STATUS.apply || status === JOIN_CONTRIBUTION_STATUS.pending,
    isApplicationNeedsToBeSubmitted: status === JOIN_CONTRIBUTION_STATUS.apply,
    isApplicationResponsePending: status === JOIN_CONTRIBUTION_STATUS.pending,
    isLoadingApplicationStatus: isLoading,
  };
};

export default useApplicationStatus;
