import React from 'react';
import PropTypes from 'prop-types';
import { useAccount } from 'hooks';
import { UserRoles } from 'helpers/constants';
import CohealerCourseCalendar from './CourseCalendars';
import useContribution from '../hooks/useContribution';
import ClientCalendar from '../components/ClientCalendar/ClientCalendar';
import { useRouter } from 'hooks';

const CourseSessionsComponents = {
  [UserRoles.cohealer]: CohealerCourseCalendar,
  [UserRoles.client]: ClientCalendar,
  [UserRoles.admin]: () => <div>In development</div>,
};

const ContributionCalendarContainer = () => {
  const { user, currentRole } = useAccount();
  const contribution = useContribution();
  const { pathname } = useRouter();
  const clientPreviewMode = pathname?.includes('clientpreview');
  const CourseSessionsComponent = CourseSessionsComponents[currentRole] || ClientCalendar[contribution];
  if (clientPreviewMode) {
    return <ClientCalendar contribution={contribution} />;
  }
  return <CourseSessionsComponent user={user} currentRole={currentRole} contribution={contribution} />;
};

ContributionCalendarContainer.propTypes = {
  currentRole: PropTypes.string.isRequired,
};

export default ContributionCalendarContainer;
