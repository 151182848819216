import { useEffect, useState } from 'react';
import useHttp from './http.hook';

const usePartnerCoach = contributionId => {
  const [partnerCoaches, setPartnerCoaches] = useState([]);
  const { request, loading } = useHttp();

  useEffect(() => {
    if (contributionId) {
      request(`/PartnerCoach/ContributionPartners/${contributionId}`).then(setPartnerCoaches).catch(console.dir);
    }
  }, []);

  return {
    loading,
    partnerCoaches,
    updatePartnerCoaches: setPartnerCoaches,
  };
};

export default usePartnerCoach;
