import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { useRouter, useHttp } from 'hooks';
import { parseDataForVerify } from 'utils/parseData';

import Loader from 'components/UI/Loader';
import Popup from 'components/Popup/Popup';

const RestorePassword = () => {
  const dispatch = useDispatch();
  const [redirect, setRedirect] = useState(false);
  const [popupText, setPopupText] = useState(null);
  const { location, history } = useRouter();

  const { request, loading } = useHttp();

  useEffect(() => {
    if (!location.search) {
      setRedirect(true);
    }

    const data = parseDataForVerify(location.search);

    request('/Account/RestorePasswordLinkVerification', 'POST', data)
      .then(token => {
        history.push(`/auth/restore-pass`, { token });
      })
      .catch(() => {
        setPopupText('Failed to restore password. Please try again');
      });
  }, [dispatch, location.search, request, history]);

  if (loading) {
    return <Loader />;
  }

  const handleClosePopup = () => {
    setPopupText(null);
    setRedirect(true);
  };

  if (popupText) {
    return (
      <Popup
        title="Email confirmation"
        text={popupText}
        open={!!popupText}
        onCloseText="OK"
        onClose={handleClosePopup}
      />
    );
  }

  return redirect ? <Redirect to="/auth/signin" /> : null;
};

export default RestorePassword;
