import { CLIENTPREVIEWMODE_ON, CLIENTPREVIEWMODE_OFF } from 'actions/clientpreviewmode';
const initialState = {
  clientPreview: false,
  backLink: '',
};
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case CLIENTPREVIEWMODE_ON:
      return { ...state, clientPreview: true, backLink: payload };
    case CLIENTPREVIEWMODE_OFF:
      return { ...state, clientPreview: false, backLink: '' };
    default:
      return state;
  }
};
