import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import { Field } from 'formik';
import { useShallowEqualSelector } from 'hooks';
import { PageTitleSecond } from 'components/UI/Text/TextStyles';
import { colors } from 'utils/styles';
import { useTheme, useMediaQuery, Typography, TextField, Box, TextareaAutosize } from '@material-ui/core';
import { Card, CardBody, CardHeader } from 'components/UI/Card';
import Select from 'components/FormUI/Select';
import Input from 'components/FormUI/Input';
import InfoIcon from '@material-ui/icons/Info';
import { TOOLTIP, LiveVideoProvider } from 'constants.js';
import TextArea from 'components/FormUI/TextArea';
import { StyledTextArea } from 'pages/ContributionView/ContributionSocial/components/Post/Post.styled';
import { ZoomModal } from 'components/Modals/ZoomModal';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core';
import { ZoomDefaultProviderModal } from 'components/Modals/ZoomDefaultProviderModal';

export const useStyles = makeStyles(theme => ({
  enableDownloadMain: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: '74px',
  },
  enableHeading: {
    paddingTop: '14px',
  },
}));
const StyledInfoIcon = styled(InfoIcon)`
  cursor: pointer;
  margin: -5px 0 0 5px;
`;

const StyledCheckbox = styled(Checkbox)`
  padding-left: 0 !important;
`;

const StyledTitle = styled.div`
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 1.115;
  letter-spacing: 0.15px;
  color: rgba(0, 0, 0, 0.87);
  ${({ mobileView }) => mobileView && `font-size: 16px`}
`;
const initialLiveVideProviders = [
  LiveVideoProvider.Cohere,
  LiveVideoProvider.Custom,
  LiveVideoProvider.InPersonSession,
];

const LiveVideo = ({ liveVideoServiceProvider, setFieldValue }) => {
  const classes = useStyles();
  const [liveVideoProviders, setLiveVideoProviders] = useState(initialLiveVideProviders);
  const user = useShallowEqualSelector(state => state?.account?.user);
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const [showTerms, setShowTerms] = useState(false);
  useEffect(() => {
    if (user?.isZoomEnabled) {
      setLiveVideoProviders(prevState => [...prevState, LiveVideoProvider.Zoom]);
    }
  }, [user.isZoomEnabled]);
  return (
    <Grid item xs={12}>
      <Card mobileView={mobileView}>
        <CardHeader mobileView={mobileView}>
          <PageTitleSecond mobileView={mobileView}>Live Video or In Person</PageTitleSecond>
        </CardHeader>
        <CardBody mobileView={mobileView}>
          <Grid container spacing={4}>
            <Grid item md={6} xs={12}>
              <Select
                label="Live Video Service Provider"
                name="liveVideoServiceProvider.providerName"
                onChange={({ target }) => {
                  setFieldValue(target.name, target.value);
                  if (target.value === 'Zoom') {
                    setShowTerms(true);
                  }
                }}
                fullWidth
                items={liveVideoProviders}
              />
              <div className={classes.enableDownloadMain}>
                <Field as={StyledCheckbox} name="isDownloadEnabled" type="checkbox" color="primary" />{' '}
                <p className={classes.enableHeading}>Enable client ability to download self paced modules as well</p>
              </div>
            </Grid>

            <Grid item md={6} xs={12}>
              <Input
                hidden={
                  liveVideoServiceProvider && liveVideoServiceProvider.providerName !== LiveVideoProvider.Custom.value
                }
                placeholder="Enter Private Link"
                name="liveVideoServiceProvider.customLink"
                onChange={({ target }) => {
                  setFieldValue(target.name, target.value, false);
                }}
                fullWidth
                label={
                  <div>
                    Use Your Private Room link instead (Zoom or Others)
                    <Tooltip
                      title="Cohere provides a built-in rich & streamlined video experience for you and your clients.
                          The Cohere video experiences saves your in-video chat threads, private session notes, and automates video recordings.
                          If you instead wish to use another video provider without these features,
                          you can add your private room link below and we’ll make sure your clients show up there instead.
                          When you and your clients access this Contribution,
                          the session launch button will open your platform of choice and we’ll also update calendar invites for both you and your clients."
                      arrow
                      enterTouchDelay={TOOLTIP.ENTER_DELAY}
                      leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                    >
                      <StyledInfoIcon fontSize="small" htmlColor={colors.lightBrown} />
                    </Tooltip>
                  </div>
                }
              />
              <Input
                hidden={
                  liveVideoServiceProvider &&
                  liveVideoServiceProvider.providerName !== LiveVideoProvider.InPersonSession.value
                }
                placeholder="Enter Private Link"
                name="liveVideoServiceProvider.customLink"
                onChange={({ target }) => {
                  setFieldValue(target.name, target.value, false);
                }}
                fullWidth
                label={
                  <div>
                    Please add meeting location
                    <Tooltip
                      title="This is the meeting location of your sessions. Feel free to link an address, video conferencing link, etc."
                      arrow
                      enterTouchDelay={TOOLTIP.ENTER_DELAY}
                      leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                    >
                      <StyledInfoIcon fontSize="small" htmlColor={colors.lightBrown} />
                    </Tooltip>
                  </div>
                }
              />
              <div style={{ marginTop: '4px', padding: '5px', width: '100%', height: '100%' }}>
                <div>
                  Calendar invite body text
                  <Tooltip
                    title="This is the meeting location of your sessions. Feel free to link an address, video conferencing link, etc."
                    arrow
                    enterTouchDelay={TOOLTIP.ENTER_DELAY}
                    leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                  >
                    <StyledInfoIcon fontSize="small" htmlColor={colors.lightBrown} />
                  </Tooltip>
                </div>
                <TextArea
                  labelName="CustomInvitationBody"
                  placeholder="This section is used to share a short bio. This is an opportunity to include your professional title, background, experience and your mission."
                  name="customInvitationBody"
                  rows="5"
                  fullWidth
                  helperTextPosition="right"
                  id="contribution-details-bio-textarea"
                />
              </div>
            </Grid>
          </Grid>
        </CardBody>
      </Card>
      {/* <ZoomModal showTerms={showTerms} onCancel={() => setShowTerms(false)} /> */}
      <ZoomDefaultProviderModal
        onSubmit={() => {
          setFieldValue('isZoomDefaultServiceProvider', true);
          setShowTerms(false);
        }}
        showTerms={showTerms}
        onCancel={() => {
          setFieldValue('isZoomDefaultServiceProvider', true);
          setShowTerms(false);
        }}
      />
    </Grid>
  );
};

LiveVideo.propTypes = {
  liveVideoServiceProvider: PropTypes.shape().isRequired,
};

export default LiveVideo;
