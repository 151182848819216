import React, { useEffect, useState } from 'react';
import '../../ViewAsCoachPage.scss';
import ReactPlayer from 'react-player';
import { StyledSection, StyledButton, StyledIntroDiv } from './StyledElements';
import { PlayIcon } from '../icons/Play';
import { PauseIcon } from '../icons/Pause';
import { lightOrDark } from 'utils/utils';

function Video({ theme, data, brandingColors, videoComponent = false, colorToUse }) {
  const [videoPlaying, setVideoPlaying] = useState(videoComponent);
  const primaryColor = brandingColors?.PrimaryColorCode || '#D08ACD';
  const accentColor = brandingColors?.AccentColorCode || '#D08ACD';
  const tertiaryColor = brandingColors?.TertiaryColorCode || '#D08ACD';
  const tagline = data?.tagline || '';
  const subtagLine = data?.subtagLine || '';
  const primaryBannerUrl = data?.primaryBannerUrl || null;
  const isPrimaryBannerVideo = data?.isPrimaryBannerVideo || false;
  const tags = data?.tags || [];
  const color = theme === 'dark' ? 'white' : 'black';
  const outline = theme === 'dark' ? '#2A2C2E' : 'white';
  const textColor =
    brandingColors?.TextColorCode === 'Auto'
      ? lightOrDark(brandingColors?.PrimaryColorCode)
      : brandingColors?.TextColorCode === '#000000'
      ? '#000000'
      : '#FFFFFF';

  return (
    <>
      <StyledSection id="video-section-webview" className="section-margin" color={color}>
        <div className="primary-video-image-container">
          <div className="primary-video-image">
            {!videoComponent && (
              <h1 style={{ color }} className="header-text">
                {tagline}
              </h1>
            )}
            {!videoComponent && (
              <p style={{ color }} className="subheader-text">
                {subtagLine}
              </p>
            )}

            {primaryBannerUrl && (
              <>
                {isPrimaryBannerVideo ? (
                  <div onClick={() => setVideoPlaying(!videoPlaying)} className="primary-banner-video">
                    {videoComponent === false ? (
                      <span className="play-pause-button" onClick={() => setVideoPlaying(!videoPlaying)}>
                        {videoPlaying ? <PauseIcon color={primaryColor} /> : <PlayIcon color={primaryColor} />}
                      </span>
                    ) : !videoPlaying ? (
                      <span
                        style={{
                          position: 'absolute',
                          top: '45%',
                          left: 0,
                          right: 0,
                          margin: 'auto',
                        }}
                        className="play-pause-button"
                        onClick={() => setVideoPlaying(!videoPlaying)}
                      >
                        <PlayIcon color={colorToUse?.PrimaryColorCode || primaryColor} />
                      </span>
                    ) : null}
                    <ReactPlayer
                      className="react-player"
                      loop
                      playing={videoPlaying}
                      width="100%"
                      height="100%"
                      url={primaryBannerUrl}
                    />
                  </div>
                ) : (
                  <picture>
                    <img className="primary-banner-image" width="100%" src={primaryBannerUrl} alt="" />
                  </picture>
                )}
              </>
            )}
          </div>
          <div className="tag-container">
            {tags.length > 0 &&
              tags.map((tag, index) => {
                const position = index % 3;
                const buttonColor = position === 0 ? primaryColor : position === 1 ? accentColor : tertiaryColor;
                return (
                  <StyledButton
                    bgColor={buttonColor}
                    outline={outline}
                    color={textColor}
                    className="inline-btn btn-small responsive-small-font tags"
                  >
                    {tag}
                  </StyledButton>
                );
              })}
          </div>
        </div>
      </StyledSection>
    </>
  );
}

export default Video;
