import React from 'react';
import styled from 'styled-components';
import { Switch, Route, NavLink } from 'react-router-dom';
import { devices } from 'utils/styles';
import Logo from 'components/FormUI/Logo';
import NavMenu from './NavMenu';

const SidebarContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 270px;
  min-height: 100vh;
  overflow-y: auto;
  background-color: white;
  box-shadow: 0 2px 4px 0 rgba(157, 157, 157, 0.3), 2px 0 4px 0 rgba(157, 157, 157, 0.3);
  z-index: 10;

  /* @media screen and (${devices.laptop}) {
    display: block;
  } */
`;

const SidebarHeader = styled.div`
  margin: 45px 0 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledLink = styled(NavLink)`
  &.disableMe {
    opacity: 0.5;
    pointer-events: none;
    cursor: not-allowed;
  }
`;

function Sidebar(props) {
  return (
    <SidebarContainer style={{ backgroundColor: props?.isDarkModeEnabled ? 'rgb(42, 44, 46)' : '#ffffff' }}>
      <div>
        <SidebarHeader>
          <StyledLink to="">
            {props?.customLogo && (
              <img
                src={props?.customLogo}
                style={{
                  objectFit: 'cover',
                  height: '150px',
                  width: '150px',
                  display: 'block',
                  padding: '0px',
                  verticalAlign: 'middle',
                  borderRadius: '100%',
                  marginBottom: '10px',
                }}
                alt=""
              />
            )}
          </StyledLink>
        </SidebarHeader>
      </div>
      <Switch>
        <Route component={NavMenu}>
          <NavMenu {...props} />
        </Route>
      </Switch>
    </SidebarContainer>
  );
}

Sidebar.propTypes = {};

Sidebar.defaultProps = {};

export default Sidebar;
