import React from 'react';
import classes from './Content.module.scss';
import { Icon } from '@mdi/react';
import { mdiFileDocumentOutline } from '@mdi/js';
import { CrossOutlineIcon } from 'pages/CreateLeadMagnet/components/Icon';

const Content = ({ file = null, onCrossClick = () => {} }) => {
  return (
    <div className={classes.content_container}>
      <div className={classes.cross_icon} onClick={onCrossClick}>
        <CrossOutlineIcon />
      </div>
      <div>
        <Icon size={8} path={mdiFileDocumentOutline} />
      </div>
      <div className={classes.file_name}>{file?.name}</div>
    </div>
  );
};

export default Content;
