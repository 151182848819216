import axiosInstance from 'utils/axiosInstance';
import { get } from 'lodash/fp';

const inviteByEmail = data => axiosInstance.post(`/Affiliate/InviteByEmail`, data).then(get('data'));

const getAffiliateName = inviteCode =>
  axiosInstance.get(`/Affiliate/GetAffiliateName?inviteCode=${inviteCode}`).then(get('data'));

const getAffiliateSummary = () => axiosInstance.get(`/Affiliate/AffiliateRevenueSummary`).then(get('data'));

const getReferralsData = () => axiosInstance.get(`/Affiliate/GetReferralsData`).then(get('data'));

const getAffiliateBalanceForPayout = () =>
  axiosInstance.get(`/Affiliate/GetAffiliateBalanceForPayout`).then(get('data'));

const payout = () => axiosInstance.post(`/Affiliate/Payout`).then(get('data'));

const toggleAffiliateEnrollment = () => axiosInstance.post(`/Affiliate/ToggleEnrollmentStatus`).then(get('data'));

const getEnrollmentStatus = () => axiosInstance.get(`/Affiliate/IsEnrolled`).then(get('data'));

const getAffiliateCoach = () => axiosInstance.post(`/Affiliate/EnableAffiliateCoach`).then(get('data'));

const getPaidFull = () => axiosInstance.post(`/Affiliate/GetPaid/Full`).then(get('data'));
const downloadEnrollmentdetails = () =>
  axiosInstance.get(`/Affiliate/DownloadAffiliateReferralHistory`).then(get('data'));
const saveContributionAffiliate = data =>
  axiosInstance.post(`/Affiliate/SaveContributionReferrals`, data).then(get('data'));
const updateContributionAffiliate = data =>
  axiosInstance.post(`/Affiliate/UpdateContributionReferrals`, data).then(get('data'));
const getAllContributionEnrollments = contributionId =>
  axiosInstance
    .get(`/Affiliate/GetAllContributionReferralsWithContributionId?contributionId=${contributionId}`)
    .then(get('data'));
const getContributionAffiliateDataApi = contributionId =>
  axiosInstance.get(`/Affiliate/DownloadAffiliateTrackingData?contributionId=${contributionId}`).then(get('data'));

const getAffiliateReferralHistory = () => axiosInstance.get(`/Affiliate/AffiliateReferralHistory`).then(get('data'));

const getAffiliateReferralDetail = () => axiosInstance.get(`/Affiliate/AffiliatePartnerTierDetail`).then(get('data'));
export {
  inviteByEmail,
  getAffiliateName,
  getAffiliateSummary,
  toggleAffiliateEnrollment,
  getEnrollmentStatus,
  getPaidFull,
  getAffiliateCoach,
  getReferralsData,
  getAffiliateBalanceForPayout,
  downloadEnrollmentdetails,
  payout,
  saveContributionAffiliate,
  updateContributionAffiliate,
  getAllContributionEnrollments,
  getContributionAffiliateDataApi,
  getAffiliateReferralHistory,
  getAffiliateReferralDetail,
};
