import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import Button from 'components/FormUI/Button';
import Select from 'components/FormUI/Select';
import Loader from 'components/UI/Loader';
import { AddCalendarModalBox } from './AddCalendarModalBox';
import { ADD_CALENDAR_MODAL_FIELDS } from './AddCalendarModalConstants';
import { SEND_INVITE_MODAL_FIELDS } from './AddCalendarModalConstants';
import Modal from 'components/UI/Modal';

const StyledLabel = styled.span`
  font-weight: 800;
`;
const StyledLabelSecondary = styled.p`
  color: #a4a4a4;
  font-size: 12px;
  line-height: 14px;
`;
const StyledContent = styled.p`
  margin-top: 12px;
`;
const StyledRow = styled(Grid)`
  padding: 16px 50px;
`;
const StyledButton = styled(Button)`
  min-width: 95px;
  width: 100%;
`;
const StyledLink = styled(Link)`
  font-size: 12px;
`;

// const firstSelectValue = [{ title: "Add calendar", value: "Add calendar" }];

export const SendInvitesFromModalContent = ({
  addCalendar,
  calendars = [],
  contributionId,
  isCreate,
  lastAddedMail,
  loadingLastEmail,
  setFieldValue,
  preparedCheckedCalendars,
  values,
  isGroup = false,
}) => {
  const preparedValues =
    calendars.length > 0
      ? calendars?.map(el => ({
          title: isGroup
            ? `${el.emailAddress}${el.isDefaultForGroupCourseInvites ? ' (default)' : ' '}`
            : `${el.emailAddress}${el.isDefault ? ' (default)' : ' '}`,
          value: el.emailAddress,
        }))
      : [];
  const [showModal, setShowModal] = useState(false);

  const togglePopup = () => {
    setShowModal(!showModal);
    addCalendar({ contributionId, isCreate });
  };
  // const preparedValues = [];

  // const renderAddToCalendarValue = (selected) => {
  // const Icon = getDomainIcon(selected);
  //   return <StyledValue>
  //      <Icon /> <span className="ml-2">{selected}</span>
  //    </StyledValue>
  //  }

  const onLinkClick = e => {
    e.preventDefault();

    setShowModal(true);
  };

  let selectDefaultValue = null;

  if (lastAddedMail) {
    const foundCalendar = calendars && calendars.find(el => el.emailAddress === lastAddedMail);
    if (foundCalendar) {
      selectDefaultValue = {
        title: foundCalendar.emailAddress,
        value: foundCalendar.emailAddress,
      };
    }
  }

  // useEffect(() => {
  //   if (!preparedCheckedCalendars?.length && lastAddedMail) {
  //     setFieldValue('checkedCalendars', [lastAddedMail]);
  //   }
  // }, [lastAddedMail]);

  return (
    <Grid container xs={12}>
      {/* {loadingLastEmail && <Loader />} */}
      <StyledRow container item xs={12}>
        {/* <Grid item sm={6} xs={12}>
          <StyledLabel>Prevent Double Booking</StyledLabel>
          <StyledContent>
            Add your personal calendars to prevent schedule conflicts.
            <br />
            <StyledLabelSecondary>
              Nylas is a trusted partner of Cohere. They power our connected calendar features.
            </StyledLabelSecondary>
          </StyledContent>
        </Grid> */}
        <Grid item sm={12} xs={12} style={{ width: '70%' }}>
          {calendars.length > 0 ? (
            <Select
              label="Calendar"
              name="externalCalendarEmail"
              fullWidth
              // multiple
              value={values.externalCalendarEmail}
              onChange={e => {
                setFieldValue('externalCalendarEmail', e.target.value);
              }}
              items={preparedValues}
              defaultValue={[selectDefaultValue]}
            />
          ) : (
            <StyledButton onClick={onLinkClick}>Add first calendar</StyledButton>
          )}
        </Grid>
        <Modal
          title="Add First Calendar"
          isOpen={showModal}
          onCloseText="OK"
          brandingColor={false}
          isCreatingContribution
          hiddenCancel
          onSubmit={togglePopup}
          onCancel={() => {
            setShowModal(false);
          }}
        >
          Please note, when adding your personal calendar to send invites from, clients will be able to see each others
          emails. If you prefer to not have clients see each others emails we recommend not connecting your calendar.
          Your clients will still get calendar invites and so will you and they will come by a noreply@cohere.live email
          address
        </Modal>
      </StyledRow>

      {/* To do in future release */}

      {/* <StyledRow container item xs={12}>
        <Grid item sm={6} xs={12}>
          <StyledLabel>Receive Calendar Invites</StyledLabel>
          <StyledContent>Add your primary calendar to receive confirmed client bookings and course sessions.</StyledContent>
        </Grid>
        <Grid item sm={6} xs={12}>
          <AddCalendarModalBox>
          {calendars.length ?
            <Select
              name={ADD_CALENDAR_MODAL_FIELDS.CALENDAR}
              fullWidth
              renderValue={renderAddToCalendarValue}
              items={preparedValues}
            />
            :
            <Select
            name={ADD_CALENDAR_MODAL_FIELDS.CHECKED_CALENDARS}
            fullWidth
            items={firstSelectValue}
            onChange={onLinkClick}
          />
        }
          </AddCalendarModalBox>
        </Grid>
      </StyledRow> */}
    </Grid>
  );
};
