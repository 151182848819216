import { get, pipe } from 'lodash/fp';

import { subscribe, getItem } from 'services/localStorage.service';
import { configureStore } from 'store/configureStore';
import { logOut } from 'actions/user';
import { setAuthorizationHeader } from './auth';
import axiosInstance from './axiosInstance';

const { store } = configureStore();

axiosInstance.interceptors.response.use(
  response => response,
  error => {
    if (error?.response?.status === 401) {
      store.dispatch(logOut());
    }

    return Promise.reject(error);
  },
);

// set or remove token
// when application save token data to localStorage
subscribe('token', pipe(get('value'), setAuthorizationHeader));

// set token from localStorage when application starts
setAuthorizationHeader(getItem('token'));
