import React from 'react';
import '../../ViewAsCoachPage.scss';
import { StyledLinkDiv } from './StyledElements';
import Avatar from '@mui/material/Avatar';
import OpenInNewRoundedIcon from '@mui/icons-material/OpenInNewRounded';
import { addhttp } from 'utils/utils';

function OtherLinkBar({ themebg, link, avatar, text, primaryColor }) {
  const defaultText = text || '';
  const defaultAvatar = avatar || null;
  const defaultLink = link || null;
  const defaultThemeBg = themebg || '';

  return (
    <>
      <StyledLinkDiv
        bgColor={defaultThemeBg}
        primaryColor={primaryColor}
        fullWidth
        onClick={() => {
          if (defaultLink) {
            window.open(addhttp(defaultLink), '_blank');
          }
        }}
      >
        <div className="flex-item">
          <Avatar alt={defaultText} src={defaultAvatar} />
        </div>
        <span style={{ padding: '0 8px' }} className="flex-item justify-content-center">
          {defaultText}
        </span>
        <a id="openInNewSvg" className="flex-item justify-content-end" href="/user/logout">
          <OpenInNewRoundedIcon style={{ color: primaryColor }} />
        </a>
      </StyledLinkDiv>
    </>
  );
}

export default OtherLinkBar;
