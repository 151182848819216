import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import Grid from '@material-ui/core/Grid';
import { LabelText, PageSubtitle, PageTitleSecond } from 'components/UI/Text/TextStyles';
import { useTheme, useMediaQuery, Typography, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import { getThemedColors } from 'services/contributions.service';
import { darken } from 'polished';
import { TemplateType } from 'helpers/constants/templateType';
import { determineColorToUse } from 'services/contributions.service';
import { lightOrDark } from 'utils/utils';

const styles = theme => ({
  inputField: {
    backgroundColor: ({ backgroundColor }) => backgroundColor,
    borderRadius: '10px',
    width: '100%',
    border: '1px solid #f5f0f0',
    marginTop: '5px',
  },

  noBorder: {
    border: 'none',
  },
});

export const useStyles = makeStyles(styles);

const StyledHeading = styled.div`
  font-family: 'Brandon Text';
  font-style: normal;
  font-size: 25px;
  font-weight: 800;
  justify-content: center;
  display: flex;
`;

const PageSubtitleCustom = styled(PageSubtitle)`
  font-family: 'Brandon Text';
  font-style: normal;
  padding-top: 10px;
  // padding-bottom: 10px;
  margin-left: 10px;
`;

const StyledButton = styled.button`
  position: relative;
  padding: 1rem 1.25rem;
  font-size: 0.875rem;
  border-radius: 2px;
  border: none;
  color: #fff;
  fill: #fff; /* for svg */
  font-weight: 900;
  cursor: pointer;
  text-decoration: none;
  line-height: 1rem;
  letter-spacing: 1.25px;
  min-width: 18rem;
  width: 92%;
  transition: background 0.2s;
  font-family: 'Avenir';

  ${({ marginTop }) =>
    marginTop &&
    css`
      margin-top: 10px;
    `}

  ${({ variant, backgroundColor, activeTemplate }) => {
    if (backgroundColor) {
      return css`
        background-color: ${backgroundColor};
      `;
    }
    return activeTemplate === TemplateType.TemplateOne
      ? css`
          background-color: #6999ca;
        `
      : css`
          background-color: #b78aea;
        `;
  }}

  ${({ invert }) =>
    invert &&
    css`
      color: rgba(0, 0, 0, 0.87);
      background-color: white;
      border: 1px solid rgba(0, 0, 0, 0.87);
    `}

  &:hover {
    ${({ variant, backgroundColor, activeTemplate }) => {
      if (backgroundColor) {
        return css`
          background-color: ${darken(0.05, backgroundColor)};
        `;
      }
      return activeTemplate === TemplateType.TemplateOne
        ? css`
            background-color: ${darken(0.05, '#6999CA')};
          `
        : css`
            background-color: ${darken(0.05, '#B78AEA')};
          `;
    }}

    ${({ invert }) =>
      invert &&
      css`
        background-color: ${darken(0.05, 'white')};
      `}
  }

  :disabled {
    background-color: #ededed;
    color: #9b9b9b;
    fill: #9b9b9b; /* for svg */
    cursor: not-allowed;
    border: none;
  }

  ${({ mobileView }) =>
    mobileView &&
    `
    font-size: 12px;
    padding: 8px 16px;
    min-width: 8rem;
  `}
`;

const FreePackageView = ({ contribution }) => {
  const [email, setEmail] = useState('');
  const classNames = useStyles();
  const { newThemedTextColor, newThemedBackgroundColor } = getThemedColors(contribution);
  const borderRad = contribution?.activeTemplate === TemplateType.TemplateOne ? '12px' : '5px';
  let colorToUse = determineColorToUse(contribution);
  const PrimaryColor = colorToUse?.PrimaryColorCode;
  const TextColor =
    colorToUse?.TextColorCode === 'Auto'
      ? lightOrDark(colorToUse?.PrimaryColorCode)
      : colorToUse?.TextColorCode === '#000000'
      ? '#000000'
      : '#FFFFFF';
  return (
    <div>
      <Grid item md={12} xs={12} style={{ padding: '20px 30px' }}>
        <StyledHeading>Book your session package </StyledHeading>
        <PageSubtitleCustom style={{ color: newThemedTextColor }}>
          This is a FREE Session Package. Please login or create a new account below in order to complete your
          registration & to book your sessions!
        </PageSubtitleCustom>
      </Grid>
      <Grid item xs={12} style={{ padding: '0px 20px' }}>
        <TextField
          fullWidth
          className={classNames.inputField}
          name="Email"
          placeholder="Email"
          variant="outlined"
          type="text"
          onChange={e => setEmail(e.target.value)}
          value={email}
          InputProps={{
            style: {
              color: newThemedTextColor,
              backgroundColor: newThemedBackgroundColor,
              borderRadius: '10px',
            },
            classes: { notchedOthemedColorutline: classNames.noBorder },
          }}
        />
      </Grid>
      <Grid item md={12} xs={12}>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <StyledButton
            style={{ borderRadius: borderRad, color: TextColor }}
            backgroundColor={PrimaryColor}
            marginTop
            type="submit"
            activeTemplate={contribution?.activeTemplate}
            disabled={true}
          >
            Join
          </StyledButton>
        </div>
      </Grid>
    </div>
  );
};

export default FreePackageView;
