import React, { useCallback } from 'react'

import * as bankAccountsService from 'services/bankAccounts.service'
import { redirectInNewTab } from 'services/links'
import { StyledTitleForVideo, StyledVideo, StyledButton } from '../GettingStarted.styles'

const Verification = ({ mobileView }) => {
  const handleConnectStripeModalSubmit = useCallback(
    () =>
      bankAccountsService
        .verifyStripeAccount()
        .then(({ link }) => redirectInNewTab(link))
        .catch((err) => {
          console.dir(err)
        }),
    []
  )

  return (
    <>
      <StyledVideo controls="controls" preload="metadata">
        <source src={`https://coherepublic-prod.s3.amazonaws.com/DemoVideos/COHERE+VERIFICATION+(VIDEO+2_4).mp4`} />
      </StyledVideo>

      <StyledTitleForVideo mobileView={mobileView}>
        The first step to finish getting setup is to verify your account.
      </StyledTitleForVideo>

      <StyledTitleForVideo mobileView={mobileView}>
        This verification step will allow you to setup and create services as well as receive payments via our Stripe integration.
      </StyledTitleForVideo>

      <StyledButton onClick={handleConnectStripeModalSubmit} variant="primary">
        Verify
      </StyledButton>
    </>
  )
}

export default Verification
