import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'components/UI/Modal';
import { useTheme, useMediaQuery } from '@material-ui/core';
import AddNewPartnerModalView from './AddNewPartnerModalView';
import isEmpty from 'lodash/isEmpty';

const AddNewPartnerModalContainer = ({ isOpen, onModalClose, onSubmit, emailsError, setEmailsError, applyTheming }) => {
  const [emails, setEmails] = useState([]);

  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'));

  const handleSubmit = useCallback(
    e => {
      // e.preventDefault();

      if (isEmpty(emails)) {
        setEmailsError({
          isError: true,
          errorMessage: 'Email сannot be empty',
        });
        return;
      }

      onSubmit(emails);
    },
    [onSubmit, emails],
  );

  return (
    <Modal
      title="Add team member"
      isOpen={isOpen}
      applyTheming={applyTheming}
      onCancel={onModalClose}
      onSubmit={handleSubmit}
      submitTitle={mobileView ? 'Send' : 'Send Invitations'}
    >
      <AddNewPartnerModalView
        emails={emails}
        applyTheming={applyTheming}
        onSetEmails={setEmails}
        emailsError={emailsError}
        setEmailsError={setEmailsError}
        mobileView={mobileView}
      />
    </Modal>
  );
};

AddNewPartnerModalContainer.propTypes = {
  onModalClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  emailsError: PropTypes.shape({
    isError: PropTypes.bool,
    errorMessage: PropTypes.string,
  }).isRequired,
  setEmailsError: PropTypes.func.isRequired,
  applyTheming: PropTypes.bool,
};
AddNewPartnerModalContainer.defaultProps = {
  applyTheming: false,
};

export default AddNewPartnerModalContainer;
