import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import Loader from 'components/UI/Loader';
import * as R from 'ramda';

import NoteModal from 'pages/ContributionView/components/NoteModal/NoteModalContainer';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { useSessionAttachments } from 'hooks/useSessionAttachments';
import { isEmptyOrNil } from 'utils/utils';
import { TemplateType } from 'helpers/constants/templateType';
import './BottomPanel.scss';
import { downloadFileByLink } from 'utils/utils';
import { CircularProgressWithLabel } from 'components/UI/CircularProgressWithLabel/CircularProgressWithLabel';
import styled from 'styled-components';
import { Box, useTheme, useMediaQuery, Tooltip } from '@material-ui/core';
import useContribution from 'pages/ContributionView/hooks/useContribution';
import { getThemedColors } from 'services/contributions.service';
import { determineColorToUse } from 'services/contributions.service';
import DocIcon from 'assets/docicon';
import Modal from 'components/UI/Modal';
import useHttp from 'hooks/http.hook';
import LaunchRecordedSessionVideo from 'components/SessionButtons/LaunchRecordedSessionVideo';
import { isMobile } from 'react-device-detect';

const StyledProgressWrap = styled(Box)`
  width: 40px;
  height: 40px;
  margin-right: 10px;
  margin-top: -8px;
  margin-bottom: -8px;
`;
const StyledRowDiv = styled.div`
  display: flex;
  width: 100%;
  gap: 8px;
`;
const StyledAttachText = styled.span`
  text-transform: initial;
`;
const AttachmentsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  padding: 10px 0;
  width: 290px;
  ${({ isCoach, mobileView }) =>
    mobileView &&
    isCoach &&
    `
padding: 0px 0 10px 0px;
`};
`;
export const BottomPanel = ({ sessionId, contributionId, attachments, session }) => {
  const [showAll, setShowAll] = useState(false);
  const contribution = useContribution();
  const { activeTemplate } = contribution;
  const { themedColor } = getThemedColors(contribution);
  const { request } = useHttp();
  const [modalOpened, setModalOpened] = useState(false);
  const theme = useTheme();
  let colorToUse = determineColorToUse(contribution);
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'));
  const isHasRecordings = session.recordingInfos.length > 0;
  const {
    progress,
    isFileUpload,
    loading,
    isCoach,
    handleUploadFile,
    handleDownloadFile,
    handleRemoveFile,
  } = useSessionAttachments(sessionId, contributionId, true);
  function formatString(text) {
    if (text.length > 20) {
      return text.slice(0, 10) + '...';
    }
    return text;
  }
  const handleDownloadAttachedFile = ({
    currentTarget: {
      dataset: { documentId, documentName, contentType },
    },
  }) => {
    request(
      `/Content/DownloadAttachment?DocumentId=${documentId}&ContributionId=${contributionId}&SessionId=${sessionId}`,
      'GET',
    )
      .then(res => downloadFileByLink(res, documentName, contentType))
      .catch(console.dir);
  };
  const visibleAttachments = attachments.length > 3 ? attachments.slice(0, 3) : attachments;
  if (loading) {
    return <Loader />;
  }

  const handleOpenModal = () => {
    setModalOpened(true);
  };

  const handleCloseModal = () => {
    setModalOpened(false);
  };
  return (
    <>
      {activeTemplate != TemplateType.TemplateThree && !isCoach && mobileView ? (
        <>
          <div style={{ display: 'flex', justifyContent: 'space-between', color: themedColor }}>
            <div>
              <span
                style={{
                  color: themedColor,
                  fontFamily: 'Avenir',
                  fontSize: '15px',
                  fontWeight: 900,
                }}
              >
                Attachments
              </span>
              {isEmptyOrNil(attachments) && (
                <span style={{ color: 'gray', marginLeft: '5px', fontSize: '13px' }}>None</span>
              )}
            </div>
            <div>
              <span tabIndex="0" role="button" onClick={handleOpenModal} onKeyPress={handleOpenModal}>
                <span style={{ color: themedColor }}>Personal Notes</span>
                <span style={{ color: 'gray', marginLeft: '5px' }}>&#x2295;</span>
              </span>
            </div>
          </div>
          <div>
            {!isEmptyOrNil(attachments) &&
              attachments.map(
                ({ id: documentId, contentType, documentKeyWithExtension, documentOriginalNameWithExtension }, idx) => (
                  <div key={documentId} className="d-flex align-items-center">
                    <Button
                      classes={{
                        root: 'text-lowercase regular-tex download-attach',
                      }}
                      onClick={handleDownloadFile}
                      disableRipple
                      data-document-id={documentId}
                      data-document-name={documentOriginalNameWithExtension}
                      data-content-type={contentType}
                    >
                      <span>{`${R.inc(idx)}.`}</span>
                      <span className="ml-2">{documentOriginalNameWithExtension}</span>
                    </Button>
                    {isCoach && (
                      <CloseIcon
                        fontSize="small"
                        className="cursor-pointer"
                        // onClick={handleRemoveFile}
                        onClick={event => {
                          event.stopPropagation();
                          handleRemoveFile(event);
                        }}
                        data-document-id={documentId}
                        data-document-extension={documentKeyWithExtension}
                      />
                    )}
                  </div>
                ),
              )}
          </div>
        </>
      ) : (
        <>
          <div
            className={
              isCoach && mobileView
                ? 'session-attachments position-relative mt-2'
                : 'session-attachments position-relative mt-4'
            }
          >
            <div
              style={{
                display: 'flex',
                flexDirection: mobileView ? 'column' : 'row',
                justifyContent: mobileView ? '' : 'space-between',
                alignItems: mobileView ? 'flex-start' : 'center',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: mobileView ? 'column' : 'row',
                  width: mobileView ? '' : '60%',
                }}
              >
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <span
                    style={{
                      color: themedColor,
                      fontFamily: 'Avenir',
                      fontSize: '15px',
                      fontWeight: 900,
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    Attachments
                  </span>
                  {isCoach && (
                    <>
                      <input
                        accept="*"
                        className="d-none"
                        id={`attach-file-${sessionId}`}
                        type="file"
                        onChange={handleUploadFile}
                        multiple
                      />
                      <label htmlFor={`attach-file-${sessionId}`}>
                        <Button
                          style={
                            mobileView
                              ? {
                                  padding: '5px',
                                  borderRadius: '4px',
                                  border: isFileUpload
                                    ? `1px solid transparent`
                                    : `1px solid ${colorToUse.AccentColorCode}`,
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  marginTop: '10px',
                                  marginLeft: '10px',
                                  minWidth: '105px',
                                }
                              : {
                                  padding: '8px',
                                  borderRadius: '4px',
                                  border: isFileUpload
                                    ? `1px solid transparent`
                                    : `1px solid ${colorToUse.AccentColorCode}`,
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  marginTop: '10px',
                                  marginLeft: '10px',
                                }
                          }
                          disableRipple
                          component="span"
                        >
                          {isFileUpload ? (
                            <StyledProgressWrap position="relative">
                              <CircularProgressWithLabel
                                mobileView={mobileView}
                                colorToUse={colorToUse}
                                value={progress}
                                color={colorToUse.AccentColorCode}
                              />
                            </StyledProgressWrap>
                          ) : (
                            <></>
                            // <AttachFileIcon style={{ color: themedColor }} className="mr-3" />
                          )}

                          <StyledAttachText
                            style={{
                              fontFamily: 'Avenir',
                              fontSize: mobileView ? '12px' : '14px',
                              fontWeight: 900,
                              color: colorToUse.AccentColorCode,
                              letterSpacing: '1.25px',
                            }}
                            // className="regular-text"
                          >
                            Attach a File
                          </StyledAttachText>
                        </Button>
                      </label>
                    </>
                  )}
                  {mobileView && isCoach && (
                    <span
                      style={{ width: 'max-content', marginTop: '10px' }}
                      tabIndex="0"
                      role="button"
                      onClick={handleOpenModal}
                      onKeyPress={handleOpenModal}
                    >
                      <div
                        style={{
                          padding: '6px 10px',
                          borderRadius: '4px',
                          border: `1px solid ${colorToUse.AccentColorCode}`,
                          justifyContent: 'center',
                          alignItems: 'center',
                          marginLeft: '10px',
                          display: 'flex',
                        }}
                      >
                        <div
                          style={{
                            fontFamily: 'Avenir',
                            fontSize: '12px',
                            fontWeight: 900,
                            color: colorToUse.AccentColorCode,
                            letterSpacing: '1.25px',
                          }}
                        >
                          Open Notes{' '}
                        </div>
                      </div>
                    </span>
                  )}
                </div>
                <div style={{ display: 'flex', flexDirection: mobileView ? 'column' : 'row' }}>
                  {
                    isEmptyOrNil(attachments) ? (
                      <span
                        style={mobileView ? {} : { marginTop: isCoach ? '22px' : '4px', marginLeft: '10px' }}
                        className="session-empty-panel-content"
                      >
                        None
                      </span>
                    ) : !mobileView ? (
                      <div style={{ display: 'flex' }}>
                        {visibleAttachments.map(
                          (
                            {
                              id: documentId,
                              contentType,
                              documentKeyWithExtension,
                              documentOriginalNameWithExtension,
                            },
                            idx,
                          ) => (
                            <div style={isCoach ? { marginTop: '10px' } : {}} key={documentId}>
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  border: `1px solid #DFE3E4`,
                                  padding: '8px',
                                  borderRadius: '4px',
                                  marginLeft: '5px',
                                  backgroundColor: '#FAFAFA',
                                  height: '42.5px',
                                }}
                                onClick={handleDownloadAttachedFile}
                                disableRipple
                                data-document-id={documentId}
                                data-document-name={documentOriginalNameWithExtension}
                                data-content-type={contentType}
                              >
                                <div style={{ display: 'flex', gap: '5px' }}>
                                  <div
                                    style={{
                                      display: 'flex',
                                      gap: '5px',
                                      cursor: 'pointer',
                                      color: colorToUse.AccentColorCode,
                                    }}
                                  >
                                    <DocIcon color={colorToUse.AccentColorCode} />
                                    <Tooltip title={documentOriginalNameWithExtension}>
                                      <div> {formatString(documentOriginalNameWithExtension)}</div>
                                    </Tooltip>
                                  </div>
                                  {isCoach && (
                                    <CloseIcon
                                      style={{ marginTop: '2px', color: colorToUse.AccentColorCode }}
                                      fontSize="small"
                                      className="cursor-pointer"
                                      onClick={event => {
                                        event.stopPropagation();
                                        handleRemoveFile(event);
                                      }}
                                      data-document-id={documentId}
                                      data-document-extension={documentKeyWithExtension}
                                    />
                                  )}
                                </div>
                              </div>
                            </div>
                          ),
                        )}

                        {attachments.length > 3 && (
                          <div
                            onClick={() => setShowAll(!showAll)}
                            style={{
                              marginTop: isCoach ? '20px' : '10px',
                              marginLeft: '5px',
                              color: colorToUse.AccentColorCode,
                              cursor: 'pointer',
                              textDecoration: 'underline',
                            }}
                          >
                            {'Show More'}
                          </div>
                        )}
                      </div>
                    ) : (
                      <></>
                    )
                    // attachments.map(
                    //   (
                    //     { id: documentId, contentType, documentKeyWithExtension, documentOriginalNameWithExtension },
                    //     idx,
                    //   ) => (
                    //     <div style={isCoach ? { marginTop: '9px' } : {}} key={documentId}>
                    //       <div
                    //         style={{
                    //           display: 'flex',
                    //           flexDirection: 'row',
                    //           border: `1px solid #DFE3E4`,
                    //           padding: '9px',
                    //           borderRadius: '4px',
                    //           marginLeft: '5px',
                    //           backgroundColor: '#FAFAFA',
                    //         }}
                    //         // classes={{
                    //         //   root: 'text-lowercase regular-tex download-attach',
                    //         // }}
                    //         onClick={handleDownloadFile}
                    //         disableRipple
                    //         data-document-id={documentId}
                    //         data-document-name={documentOriginalNameWithExtension}
                    //         data-content-type={contentType}
                    //       >
                    //         <div style={{ display: 'flex', gap: '5px' }}>
                    //           {/* {`${R.inc(idx)}.`} */}
                    //           {/* <img alt="burger-menu" src={DocIcon} /> */}
                    //           <DocIcon color={colorToUse.AccentColorCode} />
                    //           {documentOriginalNameWithExtension}

                    //           {isCoach && (
                    //             <CloseIcon
                    //               style={{ marginTop: '2px' }}
                    //               fontSize="small"
                    //               className="cursor-pointer"
                    //               onClick={handleRemoveFile}
                    //               data-document-id={documentId}
                    //               data-document-extension={documentKeyWithExtension}
                    //             />
                    //           )}
                    //         </div>
                    //       </div>
                    //     </div>
                    //   ),
                    // )
                  }
                </div>
              </div>
              {isCoach && mobileView && !isEmptyOrNil(attachments) && (
                <div>
                  {!isEmptyOrNil(attachments) && (
                    <AttachmentsContainer isCoach={isCoach} mobileView={mobileView}>
                      {visibleAttachments.map(
                        (
                          { id: documentId, contentType, documentKeyWithExtension, documentOriginalNameWithExtension },
                          idx,
                        ) => (
                          <div style={isCoach ? { marginTop: mobileView ? '2px' : '9px' } : {}} key={documentId}>
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                border: `1px solid #DFE3E4`,
                                padding: '8px',
                                borderRadius: '4px',
                                marginLeft: mobileView ? '' : '5px',
                                backgroundColor: '#FAFAFA',
                                height: '42.5px',
                              }}
                              onClick={handleDownloadAttachedFile}
                              disableRipple
                              data-document-id={documentId}
                              data-document-name={documentOriginalNameWithExtension}
                              data-content-type={contentType}
                            >
                              <div style={{ display: 'flex', gap: '5px' }}>
                                <div
                                  style={{
                                    display: 'flex',
                                    gap: '5px',
                                    cursor: 'pointer',
                                    color: colorToUse.AccentColorCode,
                                  }}
                                >
                                  <DocIcon color={colorToUse.AccentColorCode} />
                                  <Tooltip title={documentOriginalNameWithExtension}>
                                    <div> {formatString(documentOriginalNameWithExtension)}</div>
                                  </Tooltip>
                                </div>
                                {isCoach && (
                                  <CloseIcon
                                    style={{ marginTop: '2px', color: colorToUse.AccentColorCode }}
                                    fontSize="small"
                                    className="cursor-pointer"
                                    onClick={event => {
                                      event.stopPropagation();
                                      handleRemoveFile(event);
                                    }}
                                    data-document-id={documentId}
                                    data-document-extension={documentKeyWithExtension}
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                        ),
                      )}

                      {attachments.length > 4 && (
                        <div
                          onClick={() => setShowAll(!showAll)}
                          style={{
                            marginTop: isCoach ? '20px' : '10px',
                            marginLeft: '5px',
                            color: colorToUse.AccentColorCode,
                            cursor: 'pointer',
                            textDecoration: 'underline',
                          }}
                        >
                          {'Show More'}
                        </div>
                      )}
                    </AttachmentsContainer>
                  )}
                </div>
              )}

              {!mobileView && (
                <div style={{ display: 'flex' }}>
                  {' '}
                  {isHasRecordings && (
                    <LaunchRecordedSessionVideo
                      contribution={contribution}
                      session={null}
                      sessionTime={session}
                      mobileView={mobileView}
                      isOntoOneBottomPanel={true}
                    />
                  )}{' '}
                  <span tabIndex="0" role="button" onClick={handleOpenModal} onKeyPress={handleOpenModal}>
                    <div
                      style={{
                        padding: '10px',
                        borderRadius: '4px',
                        border: `1px solid ${colorToUse.AccentColorCode}`,
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginLeft: '10px',
                      }}
                    >
                      <div
                        style={{
                          fontFamily: 'Avenir',
                          fontSize: '14px',
                          fontWeight: 900,
                          color: colorToUse.AccentColorCode,
                          letterSpacing: '1.25px',
                        }}
                      >
                        Open Notes{' '}
                      </div>
                    </div>
                  </span>
                </div>
              )}
            </div>
          </div>
        </>
      )}
      {modalOpened && (
        <NoteModal
          noteProps={{
            classId: sessionId,
            contributionId,
          }}
          isOpen={modalOpened}
          onModalClose={handleCloseModal}
          onConfirm={handleCloseModal}
        />
      )}

      <Modal
        title="Session Attachments"
        applyTheming
        isOpen={showAll}
        submitTitle="Done"
        hiddenCancel
        onCancel={() => {
          setShowAll(false);
        }}
        onSubmit={() => {
          setShowAll(false);
        }}
      >
        <div style={{ marginBottom: '20px' }}>
          {attachments.map(
            ({ id: documentId, contentType, documentKeyWithExtension, documentOriginalNameWithExtension }, idx) => (
              <div style={isCoach ? { marginTop: '10px' } : {}} key={documentId}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    border: `1px solid #DFE3E4`,
                    padding: '8px',
                    borderRadius: '4px',
                    marginLeft: '5px',
                    backgroundColor: '#FAFAFA',
                    marginBottom: '5px',
                    cursor: 'pointer',
                  }}
                  onClick={handleDownloadAttachedFile}
                  disableRipple
                  data-document-id={documentId}
                  data-document-name={documentOriginalNameWithExtension}
                  data-content-type={contentType}
                >
                  <div style={{ display: 'flex', gap: '5px', color: colorToUse.AccentColorCode }}>
                    <DocIcon color={colorToUse.AccentColorCode} />
                    <Tooltip title={documentOriginalNameWithExtension}>
                      <div> {formatString(documentOriginalNameWithExtension)}</div>
                    </Tooltip>
                    {isCoach && (
                      <CloseIcon
                        style={{ marginTop: '2px', color: colorToUse.AccentColorCode }}
                        fontSize="small"
                        className="cursor-pointer"
                        onClick={event => {
                          event.stopPropagation();
                          handleRemoveFile(event);
                        }}
                        data-document-id={documentId}
                        data-document-extension={documentKeyWithExtension}
                      />
                    )}
                  </div>
                </div>
              </div>
            ),
          )}
        </div>
      </Modal>
    </>
  );
};

BottomPanel.propTypes = {
  number: PropTypes.number.isRequired,
  sessionId: PropTypes.string.isRequired,
  contributionId: PropTypes.string.isRequired,
  attachments: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};
