import React, { useCallback, useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';

import {
  getAffiliateSummary,
  getPaidFull,
  getReferralsData,
  getAffiliateBalanceForPayout,
  payout,
} from 'services/affiliate.service';
import AffiliateSummaryCard from './components/AffiliateSummaryCard/AffiliateSummaryCard';

import './Affiliate.scss';
import ChatAndGetStartedButtons from 'pages/Dashboard/components/CohealerDashboard/LiveChat/LiveChatContainer';
import BankAccountCard from '../Payment/components/BankAccountCard/BankAccountCard';
import BalanceCard from '../Payment/components/BalanceCard/BalanceCard';
import { parseQuery } from 'utils/parseData';
import useRouter from 'hooks/useRouter';
import { getUserProfile } from 'services/user.service';
import * as userActions from 'actions/user';
import { useDispatch } from 'react-redux';
import useAccount from 'hooks/useAccount';

function Affiliate() {
  const [referralsData, setReferralsData] = useState(undefined);
  const [affiliateBalanceForPayout, setAffiliateBalanceForPayout] = useState(undefined);
  const dispatch = useDispatch();
  const { location } = useRouter();
  const { user } = useAccount();
  useEffect(() => {
    getReferralsData().then(data => setReferralsData(data));
  }, [setReferralsData]);

  useEffect(() => {
    getAffiliateBalanceForPayout().then(data => setAffiliateBalanceForPayout(data));
  }, [setAffiliateBalanceForPayout]);

  useEffect(() => {
    const parsedQuery = parseQuery(location.search);
    if (parsedQuery?.refreshUser) {
      getUserProfile(user?.id);
      userActions.getProfile(user?.id, dispatch);
    }
  }, []);

  const onGetPaidClick = useCallback(() => {
    getPaidFull()
      .then(() => getReferralsData())
      .then(data => setReferralsData(data));
  }, []);

  return (
    <Grid container direction="column" alignItems="center" spacing={3}>
      <AffiliateSummaryCard incomes={referralsData} onGetPaidClick={onGetPaidClick} />
      <BankAccountCard type={'affiliate'} />
      <BalanceCard
        setAffiliateBalanceForPayout={setAffiliateBalanceForPayout}
        income={affiliateBalanceForPayout}
        type={'affiliate'}
      />
      <ChatAndGetStartedButtons page={'ProfilePage'} />
    </Grid>
  );
}

export default Affiliate;
