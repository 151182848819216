import { useContext, useCallback } from 'react';

import ParticipantsAudioVolumeContext from './ParticipantsAudioVolumeContext';
import { DEFAULT_AUDIO_VOLUME_LEVEL } from './constants';

const useParticipantAudioVolume = participant => {
  const { participantsAudioVolume, updateParticipantAudioVolume } = useContext(ParticipantsAudioVolumeContext);

  const volume =
    participant.sid in participantsAudioVolume ? participantsAudioVolume[participant.sid] : DEFAULT_AUDIO_VOLUME_LEVEL;
  const setVolume = useCallback(newVolume => updateParticipantAudioVolume(participant, newVolume), [
    updateParticipantAudioVolume,
    participant,
  ]);

  return [volume, setVolume];
};

export default useParticipantAudioVolume;
