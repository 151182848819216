import moment from 'moment';
import {
  CHANGE_BOOKING_DAY,
  SET_START_TIME,
  ADD_BOOKING_TIME,
  SET_START_DATE,
  SET_END_DATE,
  SET_DURATION,
  REMOVE_BOOKING_TIME,
  UPDATE_ONE_TO_ONE_DATA,
  SET_SESSION_DURATION,
  SET_SLOTS,
  SET_SESSION_DURATION_ERROR,
  SET_BLOCKED_DAYS,
  SET_START_TIME_INCREMENT_DURATION,
  SET_START_TIME_INCREMENT_DURATION_ERROR,
  SET_DEFAULT_WEEKLY_ONE_ON_ONE_DATA,
} from 'actions/oneToOneData';

import { getWeekdays } from '../pages/CreateContribution/components/WeekdayPicker';

const defaultWeekData = {
  days: getWeekdays(true),
  startTime: moment.utc().set('hour', 9).set('minutes', 0).set('seconds', 0).set('milliseconds', 0),
  endTime: moment.utc().set('hour', 17).set('minutes', 0).set('seconds', 0).set('milliseconds', 0),
};

const initialState = {
  startDay: moment(new Date()),
  endDay: moment(new Date()).add(60, 'days'),
  duration: 60,
  sessionDuration: 60,
  sessionDurationError: undefined,
  startTimeIncrementDuration: 0,
  startTimeIncrementDurationError: undefined,
  startTimeIncrementSlots: [],
  selectedWeeks: [
    {
      ...defaultWeekData,
    },
  ],
  preparedSlots: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case CHANGE_BOOKING_DAY:
      const updatedSessions = [...state.selectedWeeks];
      const updatedSession = { ...state.selectedWeeks[payload.index] };
      const updatedDays = [...updatedSession.days];
      const selectedDayIndex = updatedSession.days.findIndex(item => item.value === payload.data.value);
      if (selectedDayIndex < 0) {
        updatedDays.push(payload.data);
      } else {
        updatedDays.splice(selectedDayIndex, 1);
      }
      updatedSession.days = updatedDays;
      updatedSessions.splice(payload.index, 1, updatedSession);

      return {
        ...state,
        selectedWeeks: updatedSessions,
      };
    case SET_START_TIME:
      let updatedSessionsForTimes = [...state.selectedWeeks];
      let updatedSessionForTimes = { ...state.selectedWeeks[payload.index] };
      updatedSessionForTimes[payload.timeType] = payload.data;
      updatedSessionsForTimes.splice(payload.index, 1, updatedSessionForTimes);

      return {
        ...state,
        selectedWeeks: updatedSessionsForTimes,
      };

    case SET_DEFAULT_WEEKLY_ONE_ON_ONE_DATA:
      return {
        ...initialState,
      };
    case ADD_BOOKING_TIME:
      const newStartTime = state.selectedWeeks[state.selectedWeeks.length - 1].endTime;
      const newEndTime = moment(newStartTime).add(payload, 'minutes').toISOString();

      return {
        ...state,
        selectedWeeks: [
          ...state.selectedWeeks,
          {
            ...defaultWeekData,
            startTime: newStartTime,
            endTime: newEndTime,
          },
        ],
      };

    case SET_START_DATE:
      return {
        ...state,
        startDay: payload,
      };

    case SET_BLOCKED_DAYS:
      return {
        ...state,
        blockedDates: payload,
      };

    case SET_END_DATE:
      return {
        ...state,
        endDay: payload,
      };

    case SET_DURATION:
      return {
        ...state,
        duration: payload,
      };
    case REMOVE_BOOKING_TIME:
      const updatedSelectedWeeksAfterRemove = [...state.selectedWeeks];
      updatedSelectedWeeksAfterRemove.splice(payload, 1);
      return {
        ...state,
        selectedWeeks: updatedSelectedWeeksAfterRemove,
      };
    case SET_SESSION_DURATION:
      return {
        ...state,
        sessionDuration: payload,
      };
    case SET_SESSION_DURATION_ERROR:
      return {
        ...state,
        sessionDurationError: payload,
      };
    case SET_START_TIME_INCREMENT_DURATION: {
      const increments = [];
      if (payload > 0) {
        for (let i = 0; i < Math.ceil(state.sessionDuration / payload); i++) {
          increments.push(i * payload);
        }
      }
      return {
        ...state,
        startTimeIncrementDuration: payload,
        startTimeIncrementSlots: increments,
      };
    }
    case SET_START_TIME_INCREMENT_DURATION_ERROR:
      return {
        ...state,
        startTimeIncrementDurationError: payload,
      };
    case UPDATE_ONE_TO_ONE_DATA: {
      const increments = [];
      if (payload?.startTimeIncrementDuration > 0) {
        for (let i = 0; i < Math.ceil(payload?.sessionDuration / payload?.startTimeIncrementDuration); i++) {
          increments.push(i * payload);
        }
      }
      return {
        ...payload,
        sessionTimeSlots: increments,
      };
    }
    case SET_SLOTS:
      return {
        ...state,
        preparedSlots: payload,
      };
    default:
      return state;
  }
};
