import React from 'react';
import PropTypes from 'prop-types';

import { UserRoles } from 'helpers/constants';
import CohealerOneToOneSessions from './CohealerOneToOneSessions';
import ClientOneToOneSessions from './ClientOneToOneSessions';
import { useClientPreview, useRouter } from 'hooks';
import EasyBooking from './EasyBooking/EasyBooking';
import useContribution from 'pages/ContributionView/hooks/useContribution';

const OneToOneSessionsComponents = {
  [UserRoles.cohealer]: CohealerOneToOneSessions,
  [UserRoles.client]: ClientOneToOneSessions,
};

const OneToOneSessionsContainer = ({ currentRole, ...restProps }) => {
  const { pathname } = useRouter();
  const clientPreviewMode = pathname?.includes('clientpreview');
  const { isPurchased } = useContribution();
  const OneToOneSessionsComponent = OneToOneSessionsComponents[currentRole] || ClientOneToOneSessions;
  if (clientPreviewMode) {
    return <EasyBooking clientPreviewMode={clientPreviewMode} />;
  }
  if (!isPurchased && currentRole !== UserRoles.cohealer) {
    return <EasyBooking />;
  }
  return <OneToOneSessionsComponent {...restProps} />;
};

OneToOneSessionsContainer.propTypes = {
  currentRole: PropTypes.string.isRequired,
};

export default OneToOneSessionsContainer;
