import React from 'react';
import List, { Item } from '../../../../components/UI/SidebarDropDown/List';

function NavMenu({ mappedLink, customLinks, linkColor }) {
  return (
    <List>
      {mappedLink &&
        mappedLink
          .filter(link => {
            if (link.path === 'links' && !customLinks.length > 0) {
              return false; // skip
            }
            return true;
          })
          .map(link => {
            return <Item to={link?.path} title={link?.name} linkColor={linkColor} />;
          })}
    </List>
  );
}

export default NavMenu;
