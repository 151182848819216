import { createAction } from 'redux-actions';

export const updateMessages = createAction('UPDATE_MESSAGES', messages => ({
  messages,
}));

export const clearMessages = createAction('CLEAR_MESSAGES');

export const addMessage = createAction('ADD_MESSAGE', message => ({ message }));

export const updateMessage = createAction('UPDATE_MESSAGE', message => ({
  message,
}));

export const removeMessage = createAction('REMOVE_MESSAGE', messageSid => ({
  messageSid,
}));
