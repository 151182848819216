import React, { useEffect, useState, useCallback } from 'react';

import Loader from 'components/UI/Loader';
import PopupComp from 'components/Popup/Popup';

import useHttp from './http.hook';
import usePrevious from './common/usePrevious';
import useVideoChat from './useVideoChat';
import useAccount from './useAccount';
import useScreenView from 'hooks/useScreenView';
import useContribution from 'pages/ContributionView/hooks/useContribution';
import FeatureFeedbackModal from 'components/Modals/FeatureFeedbackModal';
import { showSessionFeedbackPopup } from 'actions/contributions';
import { useDispatch, useSelector } from 'react-redux';
import { determineColorToUse } from 'services/contributions.service';

const useEndVideoChat = (refreshList, endpoint = '/Contribution/SetClassAsCompleted', applyTheming = false) => {
  const INITIAL_STATE_VALUE = {
    isShowPopup: false,
    contributionId: null,
    classId: null,
  };
  const [valuesForPopup, setValuesForPopup] = useState(INITIAL_STATE_VALUE);

  const { request, loading } = useHttp();
  const { user, currentRole } = useAccount();
  const { token, contributionId, classId, creatorId } = useVideoChat();
  const { mobileView } = useScreenView();
  const contribution = useContribution();
  const colorToUse = determineColorToUse(contribution);
  const prevSession = usePrevious({ token, contributionId, classId, creatorId }) || {};
  const dispatch = useDispatch();
  const isFeedbackPopupOpen = useSelector(state => state.contributions?.showSessionFeedbackPopup);
  const handleCloseFeedback = () => {
    dispatch(showSessionFeedbackPopup(false));
  };
  const getShowPopup = shouldShowPopup => {
    if (currentRole === 'Client') {
      return false;
    }
    return shouldShowPopup !== undefined ? shouldShowPopup : true;
  };
  const onShowPopup = useCallback(
    (selectedContributionId, selectedClassId, shouldShowPopup) => {
      setValuesForPopup({
        isShowPopup: getShowPopup(shouldShowPopup),
        contributionId: selectedContributionId,
        classId: selectedClassId,
      });
    },
    [setValuesForPopup],
  );

  const handleFinishFeedbackCallback = () => {
    dispatch(showSessionFeedbackPopup(false));
  };

  const continueLaunchPopup = useCallback(() => {
    if (!prevSession.creatorId) {
      onShowPopup(prevSession.contributionId, prevSession.classId);
      return;
    }

    if (prevSession.creatorId === user.id) {
      onShowPopup(prevSession.contributionId, prevSession.classId);
    }
  }, [onShowPopup, prevSession.classId, prevSession.contributionId, user.id, prevSession.creatorId]);

  useEffect(() => {
    if (prevSession.token && !token) {
      continueLaunchPopup();
    }
  }, [token, prevSession.token, prevSession.contributionId, prevSession.classId, continueLaunchPopup]);

  const handleSessionComplete = useCallback(
    async (selectedContributionId, selectedClassId) => {
      try {
        const apiResponse = await request(endpoint, 'POST', {
          contributionId: selectedContributionId !== undefined ? selectedContributionId : valuesForPopup.contributionId,
          classId: selectedClassId !== undefined ? selectedClassId : valuesForPopup.classId,
          markingAsComplete: valuesForPopup.isShowPopup,
        });
        const { showRatingFeedbackPopUp } = apiResponse;
        dispatch(showSessionFeedbackPopup(showRatingFeedbackPopUp));
        await refreshList();
      } catch (err) {
        console.dir(err);
      }
    },
    [valuesForPopup.contributionId, valuesForPopup.classId, request, refreshList],
  );

  const handleCompleteFromPopup = useCallback(async () => {
    handleSessionComplete();
    setValuesForPopup(INITIAL_STATE_VALUE);
  }, [handleSessionComplete, setValuesForPopup, INITIAL_STATE_VALUE]);

  const handleClosePopup = useCallback(() => {
    setValuesForPopup(INITIAL_STATE_VALUE);
  }, [setValuesForPopup, INITIAL_STATE_VALUE]);

  const Popup = () => {
    if (loading) {
      return <Loader />;
    }

    return (
      <>
        <PopupComp
          applyTheming
          title="Was this session completed?"
          text="PLEASE NOTE, once you mark as complete you will not be able to undo this. If you click no, you can start and finish this session later"
          open={valuesForPopup.isShowPopup}
          onCloseText="No"
          onClose={handleClosePopup}
          onSubmit={handleCompleteFromPopup}
        />

        <FeatureFeedbackModal
          isOpen={isFeedbackPopupOpen}
          handleClose={handleCloseFeedback}
          isDarkModeEnabled={contribution?.isDarkModeEnabled}
          feature="Session"
          mobileView={mobileView}
          primaryColorCode={colorToUse?.PrimaryColorCode}
          handleFinishFeedback={handleFinishFeedbackCallback}
        />
      </>
    );
  };

  return {
    Popup,
    onShowPopup,
    handleSessionComplete,
  };
};

export default useEndVideoChat;
