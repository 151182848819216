import React from 'react';
import { Typography } from '@material-ui/core';
import ChatAndGetStartedButtons from 'pages/Dashboard/components/CohealerDashboard/LiveChat/LiveChatContainer';

export const TaxInfo = () => (
  <>
    <Typography paragraph>
      For tax purposes and making sales on Cohere, we will email you a 1099 or the proper form for your country. Please
      email us your address at support@cohere.live before December 31st to avoid delays. Please use the subject title
      “Tax filing address.”
    </Typography>
    <Typography>With gratitude,</Typography>
    <Typography>The Cohere Team.</Typography>
    <ChatAndGetStartedButtons page={'ProfilePage'} />
  </>
);
