import React from 'react';

const RichTextPlaceholder = ({ content, width, height }) => {
  return (
    <div
      className="rich-text-placeholder"
      dangerouslySetInnerHTML={{ __html: content }}
      style={{ width, height, border: '1px solid #d1d1d1', padding: '20px' }}
    />
  );
};

export default RichTextPlaceholder;
