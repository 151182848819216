import { useEffect, useState } from 'react';

import useAccount from 'hooks/useAccount';
// import { getUnreadPosts } from 'services/community.service';

export const useLoadUnreadPosts = ({ contributionId }) => {
  const [error, setError] = useState(null);
  const [isFetching, setIsFetching] = useState(false);
  const [unreadPostsCount, setUnreadPostsCount] = useState(null);
  const { user } = useAccount();

  useEffect(() => {
    if (error || isFetching || typeof unreadPostsCount === 'number') {
      return;
    }

    setIsFetching(true);

    // getUnreadPosts({ userId: user.id, contributionIds: [contributionId] })
    //   .then(response => setUnreadPostsCount(response?.[contributionId] || 0))
    //   .catch(err => setError(err))
    //   .finally(() => setIsFetching(false));
  }, [contributionId, error, isFetching, unreadPostsCount, user.id]);

  return {
    unreadPostsCount,
    userId: user.id,
  };
};
