import React from 'react';

const ForwardSvg = ({ color, style = {} }) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.5 5L12.5 10L7.5 15" stroke={color} stroke-width="1.875" stroke-linecap="round" />
    </svg>
  );
};

export default ForwardSvg;
