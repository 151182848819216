import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import { Avatar, Grid } from '@material-ui/core';

import { LabelText } from 'components/UI/Text/TextStyles';
import { useAccount, useRouter } from 'hooks';
import chatService from 'services/chat.service';
import getInitials from 'utils/getInitials';

import useContribution from 'pages/ContributionView/hooks/useContribution';
import { getThemedColors } from 'services/contributions.service';
import { addhttp } from 'utils/utils';
import Loader from 'components/UI/Loader';
import styled, { css } from 'styled-components';
import { getUserProfileStatus } from 'services/profileLink.service';

const StyledCoachName = styled.div`
  ${props =>
    props.isClickable &&
    css`
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    `}
`;

export const CoachItem = ({ serviceProviderName, firstName, lastName, avatarUrl, userId, mobileView }) => {
  const history = useRouter();
  const { user } = useAccount();
  const contribution = useContribution();
  const [loadingCoachProfile, setLoadingCoachProfile] = useState(false);
  const { domain, parentDomain } = useRouter();

  const { themedColor } = getThemedColors(contribution);

  // const handleSendMessageToCoach = useCallback(() => {
  //   if (isEmpty(user)) {
  //     history.push(`/cohealer-about/${userId}/about`);
  //   } else if (userId !== user.id) {
  //     window.location.href = addhttp(history?.domain?.concat(`/conversations/all?chatId=${userId}`));
  //   }
  // }, [history, user, userId]);

  const handleCoachNameClick = _userId => {
    setLoadingCoachProfile(true);

    return getUserProfileStatus(_userId)
      .then(resp => {
        if (resp.data) {
          const { isProfileActive, profileName } = resp.data;

          if (isProfileActive && profileName) {
            const coachProfileURL = addhttp(profileName.concat('.', parentDomain));

            window.open(coachProfileURL, '_blank').focus();
            return;
          }
          // TODO: open 1:1 chat
          // User not logged-in
          if (isEmpty(user)) {
            window.location.href = addhttp(parentDomain.concat('/auth/signin'));
            return;
          }
          // Logged-in user
          // window.open(addhttp(domain.concat(`/conversations/all?chatId=${userId}`)), '_blank').focus();
          window.location.href = addhttp(domain.concat(`/conversations/all?chatId=${userId}`));
        } else {
          // API call resolved but returned invalid data i.e. null
        }
      })
      .catch(() => {
        // API failed
      })
      .finally(() => {
        setLoadingCoachProfile(false);
      });
  };

  const coachName = serviceProviderName || `${firstName} ${lastName}`;
  const isClickable = isEmpty(user) || userId !== user.id;

  return (
    <>
      {loadingCoachProfile && <Loader />}
      <Grid item>
        <Grid item container alignItems="center">
          <Avatar src={avatarUrl} className="mx-2">
            {getInitials(coachName)}
          </Avatar>
          <LabelText
            style={{ color: themedColor }}
            id="coach-name"
            mobileView={mobileView}
            onClick={isClickable ? () => handleCoachNameClick(userId) : null}
          >
            <StyledCoachName isClickable={isClickable}>{coachName}</StyledCoachName>
          </LabelText>
        </Grid>
      </Grid>
    </>
  );
};

CoachItem.propTypes = {
  userId: PropTypes.string.isRequired,
  serviceProviderName: PropTypes.string,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  avatarUrl: PropTypes.string.isRequired,
  mobileView: PropTypes.bool.isRequired,
};

CoachItem.defaultProps = {
  serviceProviderName: 'No name',
};
