import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as R from 'ramda';

import { useHttp, useAccount } from 'hooks';
import * as actions from 'actions/contributions';
import { downloadFileByLink } from 'utils/utils';
import { UserRoles } from 'helpers/constants';
import * as contentService from 'services/content.service';

/**
 * Max size limit on backend is 5GB
 */
const MAX_FILES_SIZE_IN_BYTES = 5368706371;

const isFilesSizeLimitOrLess = R.compose(sum => sum <= MAX_FILES_SIZE_IN_BYTES, R.sum, R.map(R.prop('size')));

export const useSessionAttachments = (sessionId, contributionId, isPrerecorded, idForAttachment, isOneToOne) => {
  const dispatch = useDispatch();
  const { request, loading } = useHttp();
  const { currentRole } = useAccount();
  const [isFileUpload, setIsFileUpload] = useState(false);
  const [progress, setProgress] = useState(null);
  const handleIsFileLoad = () => {
    setIsFileUpload(false);
    setProgress(null);
  };

  const handleUpdateProgress = (partNumber, totalParts) => progressData => {
    const percentPerChunk = 100 / totalParts;
    setProgress((progressData / 100) * percentPerChunk + percentPerChunk * (partNumber - 1));
  };

  const guid = () => {
    var d = new Date().getTime();
    var guid = 'xxxx-xxxx-xxxx-xxxx'.replace(/[xy]/g, function (c) {
      var r = (d + Math.random() * 16) % 16 | 0;
      d = Math.floor(d / 16);
      return (c == 'x' ? r : (r & 0x7) | 0x8).toString(16);
    });
    return guid;
  };

  const handleUploadFile = useCallback(
    async ({ target: { files } }) => {
      const chunkSize = 26214400;
      if (isFilesSizeLimitOrLess(files)) {
        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          const documentId = guid();
          const fileName = file.name;
          let uploadId = '';
          let prevETags = '';
          let partNumber = 1;
          for (let start = 0; start < file.size; start += chunkSize) {
            const isLastPart = start + chunkSize >= file.size;
            const chunk = file.slice(start, start + chunkSize);
            const totalParts = Math.ceil(file.size / chunkSize);
            const result = await uploadPartFile(
              chunk,
              contributionId,
              sessionId,
              partNumber,
              totalParts,
              isLastPart,
              documentId,
              fileName,
              uploadId,
              prevETags,
            );
            if (result) {
              if (isLastPart) {
                dispatch(actions.fetchCohealerContribution(contributionId));
                dispatch(
                  isOneToOne ? actions.updateOneToOneSessionAttachments(result) : actions.addSessionAttachments(result),
                );
              }
              uploadId = result.uploadId;
              prevETags = result.prevETags;
            }
            partNumber++;
          }
        }
      }
    },
    [contributionId, dispatch, request, sessionId],
  );

  const uploadPartFile = (
    chunk,
    contributionId,
    sessionId,
    partNumber,
    totalParts,
    isLastPart,
    documentId,
    fileName,
    uploadId,
    prevETags,
  ) =>
    new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append('file', chunk);
      // formData.append('sessionId', sessionId);
      // formData.append('contributionId', contributionId);
      setIsFileUpload(true);
      contentService
        .addFileToSession(
          formData,
          contributionId,
          sessionId,
          partNumber,
          isLastPart,
          documentId,
          fileName,
          uploadId,
          prevETags,
          handleUpdateProgress(partNumber, totalParts),
        )
        .then(data => {
          resolve(data);
        })
        .then(() => {
          if (isLastPart) {
            handleIsFileLoad();
          }
        })
        .catch(data => {
          handleIsFileLoad();
          console.dir(data);
          reject(data);
        });
    });

  const handleDownloadFile = ({
    currentTarget: {
      dataset: { documentId, documentName, contentType },
    },
  }) => {
    request(
      `/Content/DownloadAttachment?DocumentId=${documentId}&ContributionId=${contributionId}&SessionId=${sessionId}`,
      'GET',
    )
      .then(res => downloadFileByLink(res, documentName, contentType))
      .catch(console.dir);
  };

  const handleRemoveFile = ({
    currentTarget: {
      dataset: { documentId, documentExtension },
    },
  }) => {
    const sessionPayload = {
      sessionId,
      documentId,
    };

    const sessionTimePayload = {
      sessionId: idForAttachment,
      sessionTimeId: sessionId,
      documentId: documentId,
    };
    const payload = isPrerecorded ? sessionTimePayload : sessionPayload;
    const action = isPrerecorded ? actions.updateSessionTimeAttachments : actions.updateSessionAttachments;

    request('/Content/DeleteAttachment', 'POST', {
      documentKeyWithExtension: documentExtension,
      documentId,
      contributionId,
      sessionId,
    })
      .then(() => {
        dispatch(actions.fetchCohealerContribution(contributionId));
        R.compose(dispatch, isOneToOne ? actions.updateOneToOneSessionAttachments : action)(payload);
      })
      .catch(console.dir);
  };

  return {
    isFileUpload,
    progress,
    loading,
    isCoach: currentRole === UserRoles.cohealer,
    handleUploadFile,
    handleDownloadFile,
    handleRemoveFile,
  };
};
