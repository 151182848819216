import axiosInstance from 'utils/axiosInstance';
import { get } from 'lodash/fp';

const submitApplicationResponse = data =>
  axiosInstance.post('/ApplicationForm/SubmitApplicationForm', data).then(get('data'));

const isApplicationFormSubmitted = id =>
  axiosInstance.get(`/ApplicationForm/IsApplicationFormSubmitted/${id}`).then(get('data'));

const getApplicationFormByContribId = id =>
  axiosInstance.get(`/ApplicationForm/GetApplicationFormByContribId/${id}`).then(get('data'));

const AddOrUpdateApplicationForm = applicationFormData =>
  axiosInstance
    .post('/ApplicationForm/AddOrUpdateApplicationForm', { ...applicationFormData })
    .then(get('data'))
    .catch(err => {
      console.log('error occrred during submitting application form', err);
    });

const deleteApplicationForm = (contributionId, formId) =>
  axiosInstance.delete('/ApplicationForm/DeleteApplicationForm', {
    params: {
      contributionId,
      applicationFormId: formId,
    },
  });

export {
  submitApplicationResponse,
  isApplicationFormSubmitted,
  getApplicationFormByContribId,
  AddOrUpdateApplicationForm,
  deleteApplicationForm,
};
