import React from 'react';

import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';

const CustomEditIcon = ({ handleOnClick, disabled, className, color = false }) => (
  <IconButton
    className={className}
    disabled={disabled}
    onClick={handleOnClick}
    color={color ? color : 'primary'}
    aria-label="edit picture"
    component="span"
  >
    {color ? <EditIcon style={{ color: color }} /> : <EditIcon />}
  </IconButton>
);

export default CustomEditIcon;
