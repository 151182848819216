import React from 'react';
import { openInNewWindow } from 'services/links';

export const LinkIcon = props => {
  return (
    <svg
      width={props?.width ?? '22'}
      style={{ cursor: 'pointer' }}
      onClick={() => props.link && openInNewWindow(props.link)}
      height={props?.height ?? '21'}
      viewBox="0 0 22 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.19744 3.63623C3.06152 3.63623 1.01562 5.68213 1.01562 11.8181C1.01562 17.954 3.06152 19.9999 9.19744 19.9999C15.3334 19.9999 17.3793 17.954 17.3793 11.8181"
        stroke={props?.color || '#213649'}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.27256 12.2474L18.9219 2.44043"
        stroke={props?.color || '#213649'}
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.7845 2.69988L18.9276 2.44323L18.7104 7.62574"
        stroke={props?.color || '#213649'}
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
