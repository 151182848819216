// List of paid tier plans
export const paidTierPlansSelector = s => s.paidTierOptions.paidTierOptions;

// Current purchased of paid tier plan information
export const activePaidTierPlanSelector = s => s.paidTierOptions.activePaidTierOption;

export const activePaidTierCurrentPlanSelector = s => activePaidTierPlanSelector(s)?.paidTierOption;
export const activePaidTierNextPlanSelector = s => activePaidTierPlanSelector(s)?.nextPaidTierOption;

export const activePaidTierStatusSelector = s => activePaidTierPlanSelector(s)?.status;
export const activePaidTierEndDateTimeSelector = s => activePaidTierPlanSelector(s)?.endDateTime;

export const activePaidTierCurrentPaymentPeriodSelector = s => activePaidTierPlanSelector(s)?.currentPaymentPeriod;
export const activePaidTierNextPaymentPeriodSelector = s => activePaidTierPlanSelector(s)?.nextPaymentPeriod;

// Cohere Academy Contributions
export const cohereAcademyContributionsSelector = s => s.paidTierOptions.cohereAcademyContributions;
export const activeCohereAcademyContributionsSelector = s => s.paidTierOptions.activeCohereAcademyContributions;
