import ParticipantVideoTrackType from './ParticipantVideoTrackType';

const createActualVideoTrack = (
  actualVideoTrack,
  isActualVideoTrackEnabled,
  isActualVideoTrackMirrored,
  actualVideoTrackType,
) => ({
  actualVideoTrack,
  isActualVideoTrackEnabled,
  isActualVideoTrackMirrored,
  actualVideoTrackType,
});

const getActualVideoTrack = (
  { videoTrack, isVideoEnabled, isVideoMirrored },
  { screenTrack, isScreenEnabled },
  preferredVideoTrackType,
) => {
  switch (preferredVideoTrackType) {
    case ParticipantVideoTrackType.camera: {
      return videoTrack
        ? createActualVideoTrack(videoTrack, isVideoEnabled, isVideoMirrored, ParticipantVideoTrackType.camera)
        : createActualVideoTrack(screenTrack, isScreenEnabled, false, ParticipantVideoTrackType.screenShare);
    }
    case ParticipantVideoTrackType.screenShare: {
      return screenTrack
        ? createActualVideoTrack(screenTrack, isScreenEnabled, false, ParticipantVideoTrackType.screenShare)
        : createActualVideoTrack(videoTrack, isVideoEnabled, isVideoMirrored, ParticipantVideoTrackType.camera);
    }
    default:
      throw new Error(`Unknown preferred participant video track type: ${preferredVideoTrackType}.`);
  }
};

export default getActualVideoTrack;
