import React from 'react';
import classes from './ResubSuccessCard.module.scss';
import Card from '../Card/Card';
import styled from 'styled-components';
import Button from 'components/FormUI/Button';
const ResubSuccessCard = ({ clientEmail }) => {
  return (
    <Card header={clientEmail}>
      <div className={classes.body}>
        <div className={classes.status}>Resubscribe Successful</div>
        <div className={classes.msg}>Your resubscription to our email list was successful.</div>
      </div>
    </Card>
  );
};
export default ResubSuccessCard;
