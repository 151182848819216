import { colors } from 'utils/styles';

const EVENTS_COLORS = [
  colors.darkOceanBlue,
  colors.darkGold,
  colors.lightGold,
  colors.gold,
  colors.lightBrown,
  colors.darkestBlue,
];

export const prepareSessions = ({ availabilityTimes, durations }) => {
  const eventsColors = {};
  let remainingColors = [...EVENTS_COLORS];

  return [
    {
      durations,
      maxParticipantsNumber: 1,
      minParticipantsNumber: 1,
      sessionTimes: availabilityTimes.map(({ id, bookedTimes, startTime, endTime, contributionId, title, eventId }) => {
        let color = colors.darkOceanBlue;

        if (contributionId) {
          remainingColors = remainingColors.length > 0 ? remainingColors : [...EVENTS_COLORS];
          color = eventsColors[contributionId] || remainingColors.shift();
          eventsColors[contributionId] = color;
        }

        return {
          id,
          bookedTimes,
          startTime,
          endTime,
          startDate: startTime,
          endDate: endTime,
          color,
          title,
          eventId,
        };
      }),
    },
  ];
};
