import React from 'react';
import classes from './DatePickerWithArrow.module.scss';
import ArrowDownIcon from '../Icons/SVG/ArrowDownIcon';
import DateTimePicker from 'components/FormUI/DateTimePicker';
import { useState } from 'react';

const DatePickerWithArrow = ({ value, onChangeHandler, setOpenDatePicker, openDatePicker }) => {
  // const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

  const handleDatePickerClose = () => {
    setOpenDatePicker(false);
  };

  return (
    <div className={classes.date_container}>
      <DateTimePicker
        open={openDatePicker}
        onClose={handleDatePickerClose}
        className={classes.date_picker}
        type="date"
        fullWidth
        name="endDate"
        value={value}
        onChange={onChangeHandler}
        InputProps={{
          classes: {
            root: classes.inputRoot,
            underline: classes.inputUnderline,
            input: classes.input,
          },
        }}
        format={'MM/DD/YYYY'}
        underline={false}
      />
      <ArrowDownIcon
        onClickHandler={e => {
          e.stopPropagation();
          setOpenDatePicker(true);
        }}
      />
    </div>
  );
};

export default DatePickerWithArrow;
