import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
// import Button from 'components/FormUI/Button'
import { determineColorToUse } from 'services/contributions.service';
import { useHttp } from 'hooks';
import Button from 'components/FormUI/Button';
import { lightOrDark } from 'utils/utils';
import Modal from 'components/UI/Modal';
import EasyBooking from '../../../EasyBooking/EasyBooking';
import SessionBookingThankyou from '../../../EasyBooking/components/SessionBookingThankyou';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { padding } from 'polished';
const BookSessionTime = ({
  backgroundColor,
  onSubmit,
  showModal = false,
  rescheduleSession = false,
  setShowModal = () => {},
  setRescheduleSession = () => {},
  showCount,
  setShowCount = () => {},
  index,
}) => {
  const activeContribution = useSelector(state => state.contributions?.activeContribution);
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'));
  const { loading } = useHttp();
  let colorToUse = determineColorToUse(activeContribution);
  const btnColor = colorToUse.PrimaryColorCode;
  const textColor =
    colorToUse?.TextColorCode === 'Auto'
      ? lightOrDark(colorToUse?.PrimaryColorCode)
      : colorToUse?.TextColorCode === '#000000'
      ? '#000000'
      : '#FFFFFF';
  return (
    <>
      <Button
        style={
          mobileView
            ? { padding: '10px', borderRadius: '5px', textTransform: 'none', minWidth: '87px', marginLeft: '10px' }
            : { borderRadius: '5px', textTransform: 'none', minWidth: '5px' }
        }
        onClick={onSubmit}
        disabled={loading}
        backgroundColor={btnColor}
        textColor={textColor}
      >
        Book Session
      </Button>
      {showCount === index && (
        <Modal
          minWidth={mobileView ? '350px' : ''}
          height={'650px'}
          isOpen={showModal}
          onSubmit={() => {
            setShowModal(false);
            setShowCount(0);
          }}
          title={'Book your next session'}
          disableFooter={true}
          onCancel={() => {
            setShowModal(false);
            setShowCount(0);
          }}
          noPadding={true}
          isBookingModal={true}
        >
          <EasyBooking
            clientPreviewMode={false}
            isSelfSchedule={false}
            rescheduleSession={false}
            setRescheduleSession={() => {}}
            isModal={true}
            packageSessionBooking={true}
            setShowModal={setRescheduleSession}
          />
        </Modal>
      )}
      <Modal
        isOpen={rescheduleSession}
        onSubmit={() => {
          setRescheduleSession(false);
          setShowCount(0);
        }}
        title={'Book your next session'}
        disableFooter={true}
        onCancel={() => {
          setRescheduleSession(false);
          // setShowCount(0);
        }}
        noPadding={true}
      >
        <SessionBookingThankyou
          setRescheduleSession={setRescheduleSession}
          rescheduleSession={rescheduleSession}
          isModal={true}
          handleCancel={() => setRescheduleSession(false)}
        />
      </Modal>
    </>
  );
};

BookSessionTime.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default BookSessionTime;
