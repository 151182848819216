import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import MailOutlineIcon from '@material-ui/icons/MailOutline';

import InputEmailsComponent from 'components/UI/InputEmailsComponent';
import { useSelector } from 'react-redux';
import { determineDarkThemedColorToUse } from 'services/contributions.service';

const StyledShareModalMain = styled.div`
  width: 100%;
  max-width: 520px;
  // height: 405px;
  box-sizing: border-box;
  padding: ${mobileView => (mobileView ? '30px 30px' : '55px 70px')};
`;

const StyledSendEmailBlock = styled.div`
  display: flex;

  ${({ mobileView }) => mobileView && `display: block;`}
`;

const StyledEmailWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-width: 330px;
`;

const StyledIconBlack = styled.span`
  margin: 0 8px 0 4px !important;
  display: inline-block;
  margin-top: 18px;
  vertical-align: middle;
  width: 40px;
  height: 40px;
  box-sizing: border-box;
  padding-top: 7px;
  border-radius: 50%;
  text-align: center;
  background-color: #000000 !important;
`;

const StyledHeader = styled.h1`
  margin-top: 45px;
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: 300;
`;

const StyledParagraph = styled.h2`
  margin-bottom: 15px;
  font-size: 16px;
  font-weight: 300;

  b {
    font-weight: 600;
  }
`;

const AddNewPartnerModalView = ({ emails, onSetEmails, emailsError, setEmailsError, mobileView, applyTheming }) => {
  const activeContribution = useSelector(state => state.contributions?.activeContribution);
  const isDarkThemeEnabled = determineDarkThemedColorToUse(activeContribution);
  return (
    <StyledShareModalMain mobileView={mobileView}>
      <StyledSendEmailBlock mobileView={mobileView}>
        <StyledIconBlack>
          <MailOutlineIcon style={{ color: '#FFFFFF' }} />
        </StyledIconBlack>
        <StyledEmailWrapper>
          <InputEmailsComponent
            emails={emails}
            applyTheming={applyTheming}
            handleSetEmails={onSetEmails}
            emailsError={emailsError}
            setEmailsError={setEmailsError}
          />
        </StyledEmailWrapper>
      </StyledSendEmailBlock>
      <StyledHeader style={{ color: isDarkThemeEnabled ? 'white' : 'black' }}>Add partner coaches!</StyledHeader>
      <StyledParagraph style={{ color: isDarkThemeEnabled ? 'white' : 'black' }}>
        <b>What they can do:</b> Your partner coach will be able to edit the course, launch and join sessions,
        communicate with clients, see revenue numbers, and will show up on the sales page. You can remove them at
        anytime.
      </StyledParagraph>
      <StyledParagraph style={{ color: isDarkThemeEnabled ? 'white' : 'black' }}>
        <b>What they can’t do:</b> They will not be able to transfer revenue to their bank. They can not add other
        coaches.
      </StyledParagraph>
    </StyledShareModalMain>
  );
};

AddNewPartnerModalView.propTypes = {
  emails: PropTypes.arrayOf(PropTypes.string).isRequired,
  onSetEmails: PropTypes.func.isRequired,
  emailsError: PropTypes.shape({
    isError: PropTypes.bool,
    errorMessage: PropTypes.string,
  }).isRequired,
  setEmailsError: PropTypes.func.isRequired,
  mobileView: PropTypes.bool,
  applyTheming: PropTypes.bool,
};

AddNewPartnerModalView.defaultProps = {
  mobileView: false,
  applyTheming: false,
};

export default AddNewPartnerModalView;
