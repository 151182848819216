import { useEffect } from 'react';

const useScript = (scriptInner, condition) => {
  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.innerHTML = scriptInner;
    script.async = true;

    if (condition) {
      document.body.appendChild(script);
      return () => {
        document.body.removeChild(script);
      };
    }
  }, [scriptInner, condition]);
};

export default useScript;
