import React from 'react';
import { openInNewWindow } from 'services/links';

export const LinkChainIcon = props => {
  return (
    <svg
      width="24"
      height="24"
      onClick={() => props.link && openInNewWindow(props.link)}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.94996 21.9997C5.63826 21.9942 4.38127 21.4734 3.44996 20.5497C2.98432 20.0935 2.6144 19.5489 2.36186 18.9479C2.10933 18.347 1.97925 17.7016 1.97925 17.0497C1.97925 16.3978 2.10933 15.7525 2.36186 15.1515C2.6144 14.5505 2.98432 14.0059 3.44996 13.5497L7.04996 9.94972C7.50619 9.48408 8.05075 9.11416 8.65174 8.86162C9.25273 8.60908 9.89807 8.479 10.55 8.479C11.2019 8.479 11.8472 8.60908 12.4482 8.86162C13.0492 9.11416 13.5937 9.48408 14.05 9.94972C14.1944 10.1423 14.2645 10.3805 14.2474 10.6206C14.2304 10.8607 14.1273 11.0866 13.9571 11.2568C13.7869 11.427 13.561 11.5301 13.3209 11.5472C13.0807 11.5643 12.8425 11.4941 12.65 11.3497C12.0892 10.8 11.3352 10.4921 10.55 10.4921C9.7647 10.4921 9.01075 10.8 8.44996 11.3497L4.84996 14.9497C4.29301 15.5067 3.98011 16.2621 3.98011 17.0497C3.98011 17.8374 4.29301 18.5928 4.84996 19.1497C5.40692 19.7067 6.16231 20.0196 6.94996 20.0196C7.73761 20.0196 8.49301 19.7067 9.04996 19.1497L11.3 16.7997C11.4925 16.6553 11.7307 16.5852 11.9709 16.6022C12.211 16.6193 12.4369 16.7224 12.6071 16.8926C12.7773 17.0628 12.8804 17.2887 12.8974 17.5288C12.9145 17.7689 12.8444 18.0071 12.7 18.1997L10.45 20.5497C9.51866 21.4734 8.26166 21.9942 6.94996 21.9997Z"
        fill="#213649"
      />
      <path
        d="M13.45 15.4998C12.1383 15.4943 10.8813 14.9735 9.95 14.0498C9.80557 13.8572 9.73545 13.619 9.75252 13.3789C9.76958 13.1388 9.87268 12.9129 10.0429 12.7427C10.2131 12.5725 10.439 12.4694 10.6791 12.4523C10.9192 12.4353 11.1574 12.5054 11.35 12.6498C11.9108 13.1995 12.6647 13.5074 13.45 13.5074C14.2353 13.5074 14.9892 13.1995 15.55 12.6498L19.15 9.0498C19.707 8.49285 20.0198 7.73745 20.0198 6.9498C20.0198 6.16215 19.707 5.40676 19.15 4.8498C18.593 4.29285 17.8377 3.97995 17.05 3.97995C16.2623 3.97995 15.507 4.29285 14.95 4.8498L12.7 7.1998C12.5074 7.34423 12.2692 7.41435 12.0291 7.39729C11.789 7.38022 11.5631 7.27712 11.3929 7.10691C11.2227 6.9367 11.1196 6.7108 11.1025 6.47069C11.0855 6.23058 11.1556 5.99237 11.3 5.7998L13.55 3.4498C14.4815 2.53698 15.7355 2.02863 17.0396 2.03522C18.3438 2.04181 19.5926 2.5628 20.5148 3.48498C21.437 4.40716 21.958 5.65601 21.9646 6.96016C21.9712 8.2643 21.4628 9.51835 20.55 10.4498L16.95 14.0498C16.0187 14.9735 14.7617 15.4943 13.45 15.4998Z"
        fill="#213649"
      />
    </svg>
  );
};
