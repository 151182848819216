import { handleActions } from 'redux-actions';

import * as actions from 'actions/calendars';

const initialState = {
  loading: false,
  loadingBusyTimes: false,
  loadingUpdateAccount: false,
  error: null,
  calendarsAccounts: [],
  accountsForConflictsCheck: [],
  accountForSendReminders: '',
  busyTimes: [],
  lastAddedEmail: null,
  currentView: 'Month',
};

const calendars = handleActions(
  new Map([
    [
      actions.fetchCalendarsActions.request,
      state => ({
        ...state,
        error: null,
        loading: true,
      }),
    ],
    [
      actions.fetchCalendarsActions.success,
      (state, action) => ({
        ...state,
        loading: false,
        error: null,
        calendarsAccounts: action.payload,
      }),
    ],
    [
      actions.fetchCalendarsActions.error,
      (state, action) => ({
        ...state,
        loading: false,
        error: action.payload,
        calendarsAccounts: [],
      }),
    ],

    [
      actions.addCalendarAccountActions.request,
      state => ({
        ...state,
        error: null,
        loading: true,
      }),
    ],
    [
      actions.addCalendarAccountActions.success,
      (state, action) => ({
        ...state,
        loading: false,
        error: null,
      }),
    ],
    [
      actions.addCalendarAccountActions.error,
      (state, action) => ({
        ...state,
        loading: false,
        error: action.payload,
      }),
    ],

    [
      actions.calendarSignInCallbackActions.request,
      state => ({
        ...state,
        error: null,
        loadingLastEmail: true,
      }),
    ],
    [
      actions.calendarSignInCallbackActions.success,
      (state, action) => ({
        ...state,
        lastAddedEmail: action.payload,
        loadingLastEmail: false,
        error: null,
      }),
    ],
    [
      actions.calendarSignInCallbackActions.error,
      (state, action) => ({
        ...state,
        loadingLastEmail: false,
        error: action.payload,
      }),
    ],

    [
      actions.acсountsForConflictsCheckActions.request,
      state => ({
        ...state,
        error: null,
        loading: true,
      }),
    ],
    [
      actions.acсountsForConflictsCheckActions.success,
      (state, action) => ({
        ...state,
        loading: false,
        error: null,
        accountsForConflictsCheck: action.payload,
      }),
    ],
    [
      actions.acсountsForConflictsCheckActions.error,
      (state, action) => ({
        ...state,
        loading: false,
        error: action.payload,
      }),
    ],

    [
      actions.acсountForSendRemindersActions.request,
      state => ({
        ...state,
        error: null,
        loading: true,
      }),
    ],
    [
      actions.acсountForSendRemindersActions.success,
      (state, action) => ({
        ...state,
        loading: false,
        error: null,
        accountForSendReminders: action.payload?.emailAddress,
      }),
    ],
    [
      actions.acсountForSendRemindersActions.error,
      (state, action) => ({
        ...state,
        loading: false,
        error: action.payload,
      }),
    ],

    [
      actions.removeCalendarAccountActions.request,
      state => ({
        ...state,
        error: null,
        loading: true,
      }),
    ],
    [
      actions.removeCalendarAccountActions.success,
      (state, action) => ({
        ...state,
        loading: false,
        error: null,
        calendarsAccounts: state.calendarsAccounts.filter(account => account.emailAddress !== action.payload),
        accountsForConflictsCheck:
          state?.accountsForConflictsCheck !== ''
            ? state.accountsForConflictsCheck.filter(account => account.emailAddress !== action.payload)
            : state?.accountsForConflictsCheck,
        accountForSendReminders: action.payload === state.accountForSendReminders ? '' : state.accountForSendReminders,
        busyTimes: [],
      }),
    ],
    [
      actions.updateExternalCalendarActions.request,
      state => ({
        ...state,
        loadingUpdateAccount: true,
        error: null,
      }),
    ],
    [
      actions.updateExternalCalendarActions.success,
      (state, action) => ({
        ...state,
        loadingUpdateAccount: false,
        calendarsAccounts: state.calendarsAccounts.map(account => {
          // return account.emailAddress === action.payload?.emailAddress ? action.payload : account;
          const acc = account;
          if (action.payload?.isDefault) {
            acc.isDefault = false;
          }
          if (action.payload?.isDefaultForGroupCourseInvites) {
            acc.isDefaultForGroupCourseInvites = false;
          }

          return account.emailAddress === action.payload?.emailAddress
            ? action.payload
            : // : { ...account, isDefault: !action.payload?.isDefault, acc.isDefaultForGroupCourseInvites = !action.payload?.isDefaultForGroupCourseInvites }
              acc;
        }),
        accountsForConflictsCheck:
          state?.accountsForConflictsCheck !== ''
            ? state.accountsForConflictsCheck.map(account => {
                const acc = account;
                if (action.payload?.isDefault) {
                  acc.isDefault = false;
                }
                if (action.payload?.isDefaultForGroupCourseInvites) {
                  acc.isDefaultForGroupCourseInvites = false;
                }
                return account.emailAddress === action.payload?.emailAddress ? action.payload : acc;
              })
            : state?.accountsForConflictsCheck,
      }),
    ],
    [
      actions.updateExternalCalendarActions.error,
      (state, action) => ({
        ...state,
        loadingUpdateAccount: false,
        error: action.payload,
      }),
    ],
    [
      actions.removeCalendarAccountActions.error,
      (state, action) => ({
        ...state,
        loading: false,
        error: action.payload,
      }),
    ],

    [
      actions.removeCalendarFromConflictsCheckActions.request,
      (state, action) => ({
        ...state,
        error: null,
        loading: true,
      }),
    ],

    [
      actions.removeCalendarFromConflictsCheckActions.success,
      (state, action) => ({
        ...state,
        loading: false,
        error: null,
        accountsForConflictsCheck: state.accountsForConflictsCheck.filter(
          account => account.emailAddress !== action.payload,
        ),
        calendarsAccounts: state.calendarsAccounts.map(k =>
          k.emailAddress === action.payload ? { ...k, isCheckConflictsEnabled: false } : k,
        ),
      }),
    ],

    [
      actions.removeCalendarFromConflictsCheckActions.error,
      (state, action) => ({
        ...state,
        loading: false,
        error: action.payload,
      }),
    ],

    [
      actions.setDefaultCalendarActions.request,
      (state, action) => ({
        ...state,
        error: null,
        loading: true,
      }),
    ],

    [
      actions.setDefaultCalendarActions.success,
      (state, action) => ({
        ...state,
        loading: false,
        error: null,
        calendarsAccounts: state.calendarsAccounts.map(account => {
          if (account.emailAddress == action.payload) {
            return { ...account, isDefault: true };
          } else {
            return { ...account, isDefault: false };
          }
        }),
      }),
    ],

    [
      actions.setDefaultCalendarActions.error,
      (state, action) => ({
        ...state,
        loading: false,
        error: action.payload,
      }),
    ],

    [
      actions.getBusytimeActions.request,
      state => ({
        ...state,
        error: null,
        loadingBusyTimes: true,
      }),
    ],
    [
      actions.getBusytimeActions.success,
      (state, action) => ({
        ...state,
        loadingBusyTimes: false,
        error: null,
        busyTimes: action.payload,
      }),
    ],
    [
      actions.getBusytimeActions.error,
      (state, action) => ({
        ...state,
        loadingBusyTimes: false,
        error: action.payload,
      }),
    ],

    [
      actions.putMailsForConfictCheckActions.error,
      (state, action) => ({
        ...state,
        loadingBusyTimes: false,
      }),
    ],

    [
      actions.setCurrentCalendarView.request,
      (state, action) => ({
        ...state,
        currentView: action.payload,
      }),
    ],
  ]),
  initialState,
);

export default calendars;
