import React, { useState } from 'react';
import { css } from 'styled-components';
import CustomExpansionPanel from '../../../CreateContribution/components/ExpansionPanel';
import { ExpansionIcon } from './icons';
import { useContribution } from 'pages/ContributionView/hooks';
import { getThemedColors } from 'services/contributions.service';

export const ExpansionPanel = props => {
  const { children, style = {}, rootElProps = {}, expanded = false, ...rest } = props;
  const [isExpanded, setExpanded] = useState(false);
  const contribution = useContribution();
  const {
    themedColorBtn,
    themedCardBackgroundColor,
    themedCardOutlineColor,
    newThemedBackgroundColor,
    themedColor,
  } = getThemedColors(contribution);
  const { isDarkModeEnabled } = contribution;

  const rootPanelStyles = expanded => css`
    width: 100%;
    /* border-bottom: ${expanded
      ? 'unset'
      : `1px solid ${isDarkModeEnabled ? themedCardOutlineColor : 'var(--Cohere-Greys-Grey-Tint, #DFE3E4)'}`}; */
    ${style}
    &.MuiExpansionPanel-root {
      box-shadow: none;
      ${isDarkModeEnabled &&
      css`
        background-color: ${themedCardBackgroundColor};
        color: ${themedColor};
      `}
      &::before {
        display: none;
      }
    }
    .MuiExpansionPanelDetails-root {
      display: flex;
      flex-direction: column;
      /* align-items: flex-start; */
      gap: 0.625rem;
      align-self: stretch;

      // copied from above lines
      .content-group-container {
        display: flex;
        flex-direction: column;
        /* align-items: flex-start; */
        gap: 0.625rem;
        align-self: stretch;
      }
    }
    &.Mui-expanded {
      margin: 0 !important;
    }
    .MuiExpansionPanelSummary-root {
      /* border: 1px solid purple; */
      .MuiExpansionPanelSummary-content {
        margin: 0 !important;
        padding: 0 !important;
        /* border: 1px solid red; */
        .summary-title-wrapper {
          align-items: unset !important;
        }
      }
      .MuiExpansionPanelSummary-expandIcon {
        margin-top: 22px;
        /* align-self: ${expanded ? 'flex-end' : 'center'}; */
        align-self: baseline;
        .MuiIconButton-label {
          width: 1rem;
          height: 1rem;
          /* svg {
            color: crimson;
            color: rgba(33, 54, 73, 1);
          } */
        }
      }
    }
  `;
  const config = {
    noBorder: true,
    expandIcon: <ExpansionIcon {...(isDarkModeEnabled && { fillColor: themedColorBtn })} />,
    // rootPanelStyles: { width: '100%' },
    rootPanelStyles: rootPanelStyles(isExpanded),
  };
  // const StyledCustomExpansionPanel = styled(CustomExpansionPanel)`
  //   width: 100%;
  //   .MuiExpansionPanelSummary-content {
  //     margin: 0 !important;
  //     padding: 0 !important;
  //   }
  // `;
  return (
    <CustomExpansionPanel {...config} rootProps={rootElProps} expand={expanded} {...rest}>
      {expanded => {
        setExpanded(expanded);
        return typeof children === 'function' ? children(expanded) : children;
      }}
    </CustomExpansionPanel>
  );
};
