import { makeStyles } from '@material-ui/core';

const styles = () => ({
  wrapper: {
    width: 270,
    height: 260,
    position: 'relative',
    backgroundColor: 'white',
    paddingTop: '40%',
  },
});

export const useStyles = makeStyles(styles);
