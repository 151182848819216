import React from 'react';

const TimeSvg = ({ width, height, color = '#C9B382' }) => {
  return (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8 14.7115C11.536 14.7115 14.4 11.8475 14.4 8.31152C14.4 4.77552 11.536 1.91152 8 1.91152C4.464 1.91152 1.6 4.77552 1.6 8.31152C1.6 11.8475 4.464 14.7115 8 14.7115ZM8 0.311523C12.4 0.311523 16 3.91152 16 8.31152C16 12.7115 12.4 16.3115 8 16.3115C3.576 16.3115 0 12.7115 0 8.31152C0 3.91152 3.6 0.311523 8 0.311523ZM8.4 9.11152H7.2V4.31152H8.4V7.71952L11.36 6.01552L11.96 7.05552L8.4 9.11152Z"
        fill={color}
      />
    </svg>
  );
};

export default TimeSvg;
