import Modal from 'components/UI/Modal';
import React, { useEffect, useRef, useState } from 'react';
import ReactCrop, { centerCrop, makeAspectCrop, Crop, PixelCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
// import demoImage from "./demo-image.jpg";
import styled from 'styled-components';
import { canvasPreview } from './canvasPreview';
import { useDebounceEffect } from './useDebounceEffect';
import { ImageCompressor } from 'image-compressor';
import { dataURLtoFile } from 'utils/utils';
import { useHttp } from 'hooks';

const StyledCropperWrapper = styled.div`
  width: 400px;
  min-height: 400px;
`;

const StyledPreview = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // margin-top: 30px;
`;

const StyledImage = styled.img`
  background-color: #f5f5f5;
  width: 100%;
  max-height: 500px;
  object-fit: contain;
  border: solid 1px #dadada;
`;

function ImageCropper(props) {
  const { imageToCrop, isOpen, onClose, field, setFieldValue, shape, title, submitTitle, brandingColor = true } = props;
  const { request } = useHttp();
  const [uploading, setUploading] = useState(false);
  const [crop, setCrop] = useState(undefined);
  const rectAspect = 14 / 7;
  const sqrAspect = 10 / 9;
  const [aspect, setAspect] = useState(rectAspect);
  const imageRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const [completedCrop, setCompletedCrop] = useState(null);
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);

  const Submit = () => {
    setUploading(true);
    const imageCompressor = new ImageCompressor();
    const compressorSettings = {
      toWidth: 640,
      toHeight: 350,
      mimeType: 'image/png',
      mode: 'strict',
      quality: 0.6,
    };
    const formData = new FormData();
    const value = previewCanvasRef.current && previewCanvasRef.current.toDataURL();
    if (field && value) {
      formData.append('file', dataURLtoFile(value));
      request('/content/addpublicimage', 'POST', formData, {
        'Content-Type': 'multipart/form-data',
      })
        .then(image => {
          setFieldValue(field, image);
        })
        .catch(console.dir)
        .finally(() => {
          onClose();
          setUploading(false);
          if (previewCanvasRef.current) {
            previewCanvasRef.current = null;
          }
        });
    }
  };

  function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
    return centerCrop(
      makeAspectCrop(
        {
          unit: '%',
          width: 90,
        },
        aspect,
        mediaWidth,
        mediaHeight,
      ),
      mediaWidth,
      mediaHeight,
    );
  }

  function onImageLoad(e) {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
      setCompletedCrop({ width, height });
    }
  }

  useDebounceEffect(
    async () => {
      if (completedCrop?.width && completedCrop?.height && imageRef?.current && previewCanvasRef?.current) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(imageRef.current, previewCanvasRef.current, completedCrop, scale, rotate);
      }
    },
    100,
    [completedCrop, scale, rotate],
  );

  return (
    <Modal
      title={title}
      isOpen={isOpen}
      hiddenCancel
      loading={uploading}
      onCancel={() => {
        onClose();
      }}
      dontCancelOnSideClick
      onSubmit={() => {
        Submit();
      }}
      brandingColor={brandingColor}
      submitTitle={submitTitle}
    >
      <StyledCropperWrapper>
        {!!imageToCrop && (
          <ReactCrop
            crop={crop}
            onChange={(_, percentCrop) => setCrop(percentCrop)}
            onComplete={c => setCompletedCrop(c)}
            aspect={aspect}
          >
            <img
              ref={imageRef}
              alt="Crop me"
              src={imageToCrop}
              // style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
              onLoad={onImageLoad}
            />
          </ReactCrop>
        )}
        <StyledPreview>
          {!!completedCrop && (
            <>
              <div style={{ fontWeight: 'bold' }}>Preview</div>
              <canvas
                ref={previewCanvasRef}
                style={{
                  border: '1px solid black',
                  objectFit: 'contain',
                  width: completedCrop.width,
                  height: '250px',
                }}
              />
            </>
          )}
        </StyledPreview>
      </StyledCropperWrapper>
    </Modal>
  );
}

ImageCropper.defaultProps = {};

export default ImageCropper;
