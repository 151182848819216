import axios from 'axios';
import http from 'http';
import https from 'https';

const useDevApi = false;

const API_DOMAIN = (useDevApi && process.env.REACT_APP_API_DOMAIN_DEV) || process.env.REACT_APP_API_DOMAIN || '';

const axiosInstance = axios.create({
  baseURL: API_DOMAIN,
  httpAgent: new http.Agent({ keepAlive: true }),
  httpsAgent: new https.Agent({ keepAlive: true }),
});

export default axiosInstance;
