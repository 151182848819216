import moment from 'moment';

export const INITIALS = {
  leads: {
    fromDate: moment(new Date()),
    toDate: moment(new Date()),
    contributions: ['All'],
    tags: ['All'],
  },
};

export const FilterTypes = {
  Lead: 'Lead',
  Campaign: 'Campaign',
  AllContacts: 'AllContacts',
  Client: 'Client',
};
