import { useMemo, useRef } from 'react';
import useActiveContributionSessionById from './useActiveContributionSessionById';
import isEqual from 'react-fast-compare';
import { activeContribSessTabViewSettingsSelector } from 'selectors/contribution';
import { useSelector } from 'react-redux';

const useActiveContributionSessionTimeOrContentById = () => {
  const { selectedSessionTimeOrContentId } = useSelector(activeContribSessTabViewSettingsSelector);
  const { selectedSession } = useActiveContributionSessionById() || {};
  if (!selectedSessionTimeOrContentId || !selectedSession) {
    return null;
  }
  const inx = selectedSession?.sessionTimes?.findIndex(k => k?.id === selectedSessionTimeOrContentId);
  return {
    selectedSessionTimeOrContent: selectedSession?.sessionTimes?.[inx],
    selectedSessionTimeOrContentIndex: inx,
  };
  // const prev = useRef(null);
  // const res = {
  //   selectedSessionTimeOrContent: selectedSession?.sessionTimes?.find(k => k.id === timeOrContentId),
  //   selectedSessionTimeOrContentIndex: selectedSession?.sessionTimes?.findIndex(k => k.id === timeOrContentId),
  // };
  // const resMemo = useMemo(() => {
  //   if (isEqual(res, prev.current)) {
  //     return prev.current;
  //   }
  //   prev.current = res;
  //   return res;
  // }, [selectedSession]);

  // if (!selectedSession) {
  //   return null;
  // }
  // return resMemo;
};

export default useActiveContributionSessionTimeOrContentById;
