import SvgHandler from 'pages/ContributionView/components/ContributionHeader/ApplicationForm/SvgHandler';
import PropTypes from 'prop-types';
import React from 'react';

function HomeIcon({ fillColor, width = '28px', height = '28px', style }) {
  return (
    <SvgHandler
      fill={fillColor}
      height={height}
      width={width}
      style={style || {}}
      viewBox="0 0 24 24"
      className="top-15 cursor-pointer MuiSvgIcon-root"
      pathD="M2.16786 10.5552C2.09501 10.4458 2.04441 10.3232 2.01896 10.1944C1.9935 10.0655 1.99368 9.93286 2.0195 9.80405C2.04532 9.67525 2.09626 9.55279 2.16942 9.44368C2.24257 9.33457 2.33651 9.24094 2.44587 9.16815L11.4459 3.16815C11.6102 3.05851 11.8033 3 12.0009 3C12.1984 3 12.3915 3.05851 12.5559 3.16815L21.5559 9.16815C21.7345 9.28727 21.8701 9.46073 21.9425 9.66287C22.0149 9.86501 22.0203 10.0851 21.958 10.2906C21.8956 10.496 21.7688 10.676 21.5962 10.8038C21.4237 10.9316 21.2146 11.0004 20.9999 11.0001H18.9999V20.0001C18.9999 20.2654 18.8945 20.5197 18.707 20.7073C18.5194 20.8948 18.2651 21.0001 17.9999 21.0001H5.99987C5.73465 21.0001 5.48029 20.8948 5.29276 20.7073C5.10522 20.5197 4.99987 20.2654 4.99987 20.0001V11.0001H2.99986L3.01886 10.9811C2.85252 10.9882 2.68713 10.9528 2.53825 10.8783C2.38937 10.8037 2.26191 10.6925 2.16786 10.5552Z"
    />
  );
}

HomeIcon.propTypes = {
  fillColor: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
};

export default HomeIcon;
