import Modal from 'components/UI/Modal';
import React from 'react';
import PropTypes from 'prop-types';

const DeleteFileModal = ({ isOpen, setIsOpen, onSubmit, isDarkThemeEnabled }) => {
  return (
    <Modal
      isOpen={isOpen}
      title="Delete File"
      submitTitle="Yes, delete"
      onSubmit={onSubmit}
      onCancel={() => {
        setIsOpen(false);
      }}
    >
      {/* <span style={{ color: isDarkThemeEnabled ? 'white' : 'black' }}>
        If you delete this file, it will also get deleted from the ‘Sessions’ tab if it was uploaded there.
        <br />
      </span> */}
      <span style={{ color: isDarkThemeEnabled ? 'white' : 'black' }}> Are you sure you wish to continue?</span>
    </Modal>
  );
};

DeleteFileModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default DeleteFileModal;
