class Device {
  id;
  label;
  type;

  constructor({ id, label, type }) {
    this.id = id;
    this.label = label;
    this.type = type;
  }
}

export default Device;
