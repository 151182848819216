import React from 'react';

const CrossIconSvg = () => {
  return (
    <div>
      <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M8.50677 7.51856L12.6083 2.62949C12.6771 2.54824 12.6193 2.4248 12.513 2.4248H11.2661C11.1927 2.4248 11.1224 2.45762 11.074 2.51387L7.69114 6.54668L4.30833 2.51387C4.26145 2.45762 4.19114 2.4248 4.11614 2.4248H2.86927C2.76302 2.4248 2.7052 2.54824 2.77395 2.62949L6.87552 7.51856L2.77395 12.4076C2.75855 12.4257 2.74867 12.4479 2.74549 12.4714C2.7423 12.495 2.74594 12.519 2.75597 12.5405C2.76601 12.5621 2.78202 12.5803 2.8021 12.593C2.82218 12.6057 2.84549 12.6124 2.86927 12.6123H4.11614C4.18958 12.6123 4.25989 12.5795 4.30833 12.5232L7.69114 8.49043L11.074 12.5232C11.1208 12.5795 11.1911 12.6123 11.2661 12.6123H12.513C12.6193 12.6123 12.6771 12.4889 12.6083 12.4076L8.50677 7.51856Z"
          fill="#1E1E1E"
        />
      </svg>
    </div>
  );
};

export default CrossIconSvg;
