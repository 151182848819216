import React from 'react';
import PropTypes from 'prop-types';

import { Chat } from 'components/UI/chats';

const CourseDiscussion = ({ contribution: { chat } }) => <Chat channelId={chat?.sid} />;

CourseDiscussion.propTypes = {
  contribution: PropTypes.shape({
    chat: PropTypes.shape({
      sid: PropTypes.string,
    }),
  }).isRequired,
};

export default CourseDiscussion;
