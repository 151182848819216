export const styles = theme => ({
  button: {
    margin: '0 16px',
  },
  header: {
    fontSize: 22.3,
    fontWeight: 500,
    margin: 0,
    padding: 16,
    [theme.breakpoints.up(theme.breakpoints.values.sm)]: {
      fontSize: 16,
    },
  },
  headerWrapper: {
    borderBottom: `1px solid ${theme.palette.colors.whisper}`,
  },
  listItem: {
    padding: '15px 24px',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: ({ backgroundColor }) => backgroundColor,
      transition: 'background-color 0.2s',
    },
  },
  listItemName: {
    marginLeft: 15,
  },
  wrapper: {
    backgroundColor: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.palette.shadows.primary,
  },
});
