import { useEffect, useMemo, useReducer } from 'react';

import { useAccount } from 'hooks/index';
import find from 'lodash/find';
import useChatClientChannels from '../useChatClientChannels';
import { updateChannelUnreadMessagesCount } from './actions';
import channelsUnreadMessagesReducer, { initialChannelsUnreadMessages } from './reducer';

const useChatClientChannelsUnreadMessagesCount = (channelIds = null) => {
  const { channels } = useChatClientChannels(channelIds);
  const { user } = useAccount();

  const [channelsUnreadMessages, dispatchChannelUnreadMessageAction] = useReducer(
    channelsUnreadMessagesReducer,
    initialChannelsUnreadMessages,
  );
  const unreadMessagesCount = useMemo(() => {
    const channelsUnreadMessagesCounts = Object.entries(channelsUnreadMessages)
      .filter(([channelId]) => !channelIds || channelIds.includes(channelId))
      .map(([_, channelUnreadMessages]) => channelUnreadMessages.unreadMessagesCount);
    const totalUnreadMessagesCount = channelsUnreadMessagesCounts.reduce((total, current) => total + current, 0);

    return totalUnreadMessagesCount;
  }, [channelIds, channelsUnreadMessages]);

  useEffect(() => {
    const checkIfShouldUpdateChannelUnreadMessagesCount = channel => {
      const channelUnreadMessages = channelsUnreadMessages[channel.sid];
      if (!channelUnreadMessages) {
        return true;
      }
      const { lastConsumedMessageIndex, lastMessageIndex } = channelUnreadMessages;
      return (
        channel.lastConsumedMessageIndex !== lastConsumedMessageIndex ||
        (!!channel.lastMessage && channel.lastMessage.index !== lastMessageIndex)
      );
    };

    const getChannelUnconsumedMessagesCount = async channel => {
      try {
        const getMessagesLast = await channel.getMessages();
        const getMessageLast = find(getMessagesLast.items, { state: { index: channel.lastMessage.index } });
        const unconsumedMessagesCount = await channel.getUnconsumedMessagesCount();
        if (getMessageLast.author === user.email) {
          return 0;
        }
        if (unconsumedMessagesCount !== null) {
          return unconsumedMessagesCount;
        }
        const allMessagesCount = await channel.getMessagesCount();

        return allMessagesCount || 0;
      } catch {
        return 0;
      }
    };

    const channelsToUpdateUnreadMessagesCount = channels.filter(checkIfShouldUpdateChannelUnreadMessagesCount);
    if (channelsToUpdateUnreadMessagesCount.length === 0) {
      return;
    }

    Promise.all(channelsToUpdateUnreadMessagesCount.map(c => Promise.all([c, getChannelUnconsumedMessagesCount(c)])))
      .then(channelsUnconsumedMessages =>
        channelsUnconsumedMessages.reduce(
          (channelsUnreadMessagesObject, [channel, unconsumedMessagesCount]) => ({
            ...channelsUnreadMessagesObject,
            [channel.sid]: {
              channel,
              unreadMessagesCount: unconsumedMessagesCount,
            },
          }),
          {},
        ),
      )
      .then(updateChannelUnreadMessagesCount)
      .then(dispatchChannelUnreadMessageAction);
  }, [channels, channelsUnreadMessages]);

  return unreadMessagesCount;
};

export default useChatClientChannelsUnreadMessagesCount;
