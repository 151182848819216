import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import classnames from 'classnames';

import avatarPlaceholder from 'assets/placeholder.jpg';
import Loader from 'components/UI/Loader';
import { useHttp } from 'hooks';
import * as userActions from 'actions/user';
import { UploadImageIcon } from '../../../src/pages/Profile/components/icons/UploadImage';
import axiosInstance from 'utils/axiosInstance';
import { get } from 'lodash/fp';

import './AvatarUploader.scss';
import CloseIcon from '@material-ui/icons/Close';

export const AvatarUploader = ({ avatarUrl, className, updateImg }) => {
  const { request, loading } = useHttp();
  const inputRef = React.createRef();

  const handleUploadFile = ({ target: { files } }) => {
    const formData = new FormData();

    formData.append('file', R.head(files));

    request('/Content/addpublicimage', 'POST', formData, {
      'Content-Type': 'multipart/form-data',
    })
      .then(res => {
        updateImg(res);
      })
      .catch(console.dir);
    // .finally(updateUserImg);
  };

  let emptyString = '';

  const onDeleteClick = async () => {
    try {
      const res = await axiosInstance.post(`/Content/RemoveCustomLogo?imageUrl=${avatarUrl}`);
      updateImg(emptyString);
    } catch (error) {
      console.dir(error);
    }
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="profile-avatar-container">
      <p className="profile-avatar-text">Add Photo</p>
      {avatarUrl !== '' && (
        <span
          className="remove-image-button"
          onClick={onDeleteClick}
          onKeyUp={onDeleteClick}
          role="button"
          tabIndex="0"
        >
          <CloseIcon style={{ color: 'white', width: '16px', height: '16px' }} />
        </span>
      )}
      <div
        style={{
          backgroundImage: `url(${avatarUrl || avatarPlaceholder})`,
        }}
        className={classnames('mr-4 mb-4 br-50 profile-avatar', className)}
      >
        <input
          type="file"
          accept="image/*"
          className="w-100 h-100 cursor-pointer profile-input-avatar"
          onChange={handleUploadFile}
          ref={inputRef}
        />
        <span
          className="upload-image-button"
          role="button"
          tabIndex="0"
          onKeyUp={() => inputRef.current.click()}
          onClick={() => inputRef.current.click()}
        >
          <UploadImageIcon />
        </span>
      </div>
      <span className="profile-avatar-description">Photo should be atleast 500x500px and upto 2 mb.</span>
    </div>
  );
};

AvatarUploader.propTypes = {
  avatarUrl: PropTypes.string,
  className: PropTypes.string,
  updateImg: PropTypes.string,
};

AvatarUploader.defaultProps = {
  avatarUrl: avatarPlaceholder,
  className: null,
  updateImg: '',
};
