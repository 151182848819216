import * as R from 'ramda';
import queryString from 'query-string';

export const parseDataForVerify = R.compose(
  str => ({
    email: R.compose(email => R.replace('%40', '@', email), R.last, R.split('email='), R.head)(str),
    token: R.compose(R.last, R.split('token='), R.last)(str),
  }),
  R.split('&'),
  R.tail,
);

export const parseQuery = str => {
  return {
    ...queryString.parse(str),
  };
};
