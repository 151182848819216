import React from 'react';
import {
  useActiveContributionSessionById,
  useActiveContributionSessionTimeOrContentById,
  useContribution,
} from 'pages/ContributionView/hooks';
import styled from 'styled-components';
import { FullScreenExpansionIcon, FullScreenRestorationIcon } from './icons';
import useAccount from 'hooks/useAccount';
import SelfPacedHeader from './SelfPacedComponents/SelfPacedHeader';
import LiveSessionHeader from './LiveSessionComponents/LiveSessionHeader';
import { determineColorToUse, getThemedColors } from 'services/contributions.service';
import { lightOrDark } from 'utils/utils';
import { ErrorBoundary } from '@sentry/react';
import { IconButton, useMediaQuery, useTheme } from '@material-ui/core';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { useDispatch, useSelector } from 'react-redux';
import { activeContribSessTabViewSettingsSelector } from 'selectors/contribution';
import { setCenterColumnFullScreenMode } from 'actions/contributions';

const StyledFullScreenExpansionIconWrapper = styled.div`
  cursor: pointer;
  position: absolute;
  right: 1.5rem;
  svg {
    width: 0.999rem;
    height: 1.00063rem;
  }
`;
const StyledFullScreenRestorationIconWrapper = styled.div`
  cursor: pointer;
  position: absolute;
  right: 1.5rem;
  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
`;
const BackIconWrapper = styled.div`
  display: flex;
  padding: 5px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 27px;
  background: ${({ backgroundColor }) => backgroundColor || '#f5f5f5'};
`;

const SessionHeader = ({ resetSelectedSession = () => {}, selectNextSession = () => {} }) => {
  const dispatch = useDispatch();
  const { isCenterColumnFullScreenMode } = useSelector(activeContribSessTabViewSettingsSelector);
  const { isClient, isCoach } = useAccount();
  const { selectedSession } = useActiveContributionSessionById();
  const contribution = useContribution();
  const { themedColor, newThemedTextColor, newThemedBackgroundColor } = getThemedColors(contribution);
  const { isDarkModeEnabled } = contribution;
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));

  const SelectedHeader = selectedSession?.isPrerecorded ? SelfPacedHeader : LiveSessionHeader;
  let ExpandRestoreIconWrapper = null;
  let ExpandRestoreIcon = null;
  let ExpansionRestorationIcon = null;
  if (isClient) {
    ExpandRestoreIconWrapper = isCenterColumnFullScreenMode
      ? StyledFullScreenRestorationIconWrapper
      : StyledFullScreenExpansionIconWrapper;
    ExpandRestoreIcon = isCenterColumnFullScreenMode ? FullScreenRestorationIcon : FullScreenExpansionIcon;
    ExpansionRestorationIcon = props => (
      <ExpandRestoreIconWrapper
        onClick={() => dispatch(setCenterColumnFullScreenMode(!isCenterColumnFullScreenMode))}
        {...props}
      >
        <ExpandRestoreIcon {...(contribution?.isDarkModeEnabled && { fillColor: themedColor })} />
      </ExpandRestoreIconWrapper>
    );
  }
  const CenterColumnBackButton = () =>
    mobileView ? (
      <BackIconWrapper
        {...(isDarkModeEnabled
          ? {
              backgroundColor: 'transparent',
            }
          : {})}
      >
        <IconButton
          style={{
            padding: '0px',
            height: '1.625rem',
            width: '1.625rem',
            ...(isDarkModeEnabled
              ? {
                  color: newThemedTextColor,
                  backgroundColor: newThemedBackgroundColor,
                }
              : {}),
          }}
          className="icon"
          onClick={resetSelectedSession}
        >
          <ChevronLeftIcon />
        </IconButton>
      </BackIconWrapper>
    ) : null;
  let colorToUse = determineColorToUse(contribution);
  let textColor =
    colorToUse?.TextColorCode === 'Auto'
      ? lightOrDark(colorToUse?.PrimaryColorCode)
      : colorToUse?.TextColorCode === '#000000'
      ? '#000000'
      : '#FFFFFF';
  return (
    <>
      <SelectedHeader
        {...{
          ExpansionRestorationIcon,
          textColor: isCoach ? themedColor : newThemedTextColor,
          CenterColumnBackButton,
          selectNextSession,
        }}
      />
      {/* <ExpandRestoreIconWrapper>
        <ExpandRestoreIcon />
      </ExpandRestoreIconWrapper> */}
    </>
  );
};

export default SessionHeader;
