import React from 'react'
import PropTypes from 'prop-types'

import { Chat } from 'components/UI/chats'

const MembershipDiscussion = ({ contribution: { chat } }) => <Chat channelId={chat?.sid} />

MembershipDiscussion.propTypes = {
  contribution: PropTypes.shape({
    chat: PropTypes.shape({
      sid: PropTypes.string,
    }),
  }).isRequired,
}

export default MembershipDiscussion
