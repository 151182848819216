import React from 'react'
import PropTypes from 'prop-types'

import Participant from './Participant'
import { useNetworkQualityLevel } from '../../../../hooks/participants'

const TestLocalParticipant = ({ participant, videoTrack, audioTrack, selectedAudioOutputDevice, ...restProps }) => {
  const isAudioEnabled = audioTrack.isLocalAudioEnabled
  const isVideoEnabled = videoTrack.isLocalVideoEnabled

  const networkQualityLevel = useNetworkQualityLevel(participant)

  return (
    <Participant
      {...restProps}
      identity={participant.identity}
      networkQualityIndicator={networkQualityLevel}
      additionalInfo="(me)"
      isAudioEnabled={isAudioEnabled}
      audioVolume={0}
      isVideoEnabled={isVideoEnabled}
      selectedAudioOutputDevice={selectedAudioOutputDevice}
    />
  )
}

TestLocalParticipant.propTypes = {
  participant: PropTypes.shape({
    identity: PropTypes.string,
  }).isRequired,
  videoTrack: PropTypes.shape({
    isLocalVideoEnabled: PropTypes.bool,
  }).isRequired,
  audioTrack: PropTypes.shape({
    isLocalAudioEnabled: PropTypes.bool,
  }).isRequired,
}

export default TestLocalParticipant
