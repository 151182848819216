import { get } from 'lodash/fp';
import * as Qs from 'query-string';
import axiosInstance from '../utils/axiosInstance';

const getAllContributionPostsForClientAsync = (pageNumber, pageSize) =>
  axiosInstance
    .post(`/Post/GetAllContributionPostsForClient?pageNumber=${pageNumber}&pageSize=${pageSize}`)
    .then(get('data'));
const getAllCommunityPostsForCoachAsync = data =>
  axiosInstance.post(`/Post/GetAllCommunityPostsForCoach`, data).then(get('data'));
const getAllCommunityPostsForClientAsync = data =>
  axiosInstance.post(`/Post/GetAllContributionPostsForClient`, data).then(get('data'));
const getAllPostsForContributionAsync = id => axiosInstance.get(`/Post/GetAll/${id}`).then(get('data'));
const searchByKeyword = (id, keyword, groupId) =>
  axiosInstance
    .post(`/Post/GetSuggestedSearchOptions`, {
      contributionId: id,
      keywords: keyword,
      groupId: groupId,
    })
    .then(get('data'));
const getSuggestedSearchOptionsForAllContributionOfCoach = data =>
  axiosInstance.post(`/Post/GetSuggestedSearchOptionsForAllContributionOfCoach`, data).then(get('data'));

const getSuggestedSearchOptionsForAllContributionOfClient = data =>
  axiosInstance.post(`/Post/GetSuggestedSearchOptionsForAllContributionOfClient`, data).then(get('data'));

const getPostForKeywords = params => axiosInstance.post(`/Post/GetPostsUsingKeywordsSearch`, params).then(get('data'));
const getHashTagByContributionId = id =>
  axiosInstance.get(`/Post/GetHashTagsInCommunityByContributionId?contributionId=${id}`).then(get('data'));
const getHashTagsIncommunitiesFromContributionsForCoach = () =>
  axiosInstance.get(`/Post/GetHashTagsIncommunitiesFromContributionsForCoach`).then(get('data'));
const getHashTagsIncommunitiesFromContributionsForClient = () =>
  axiosInstance.get(`/Post/GetHashTagsIncommunitiesFromContributionsForClient`).then(get('data'));
const getAllContributionForCommunityPostCoach = () =>
  axiosInstance.get(`/Post/GetAllContributionForCommunityPostCoach`).then(get('data'));
const getAllContributionForCommunityPostClient = isCreatePost =>
  axiosInstance.get(`/Post/GetAllContributionForCommunityPostClient?isCreatePost=${isCreatePost}`).then(get('data'));
const post = data => axiosInstance.post(`/Post`, data).then(get('data'));
const createMutiplePosts = data => axiosInstance.post(`/Post/CreateMutiplePost`, data).then(get('data'));
const deletePost = id => axiosInstance.delete(`/Post/${id}`).then(get('data'));
const getPost = id => axiosInstance.get(`/Post/${id}`).then(get('data'));
const editPost = data => axiosInstance.put('/Post', data).then(get('data'));
const removeAttachment = data => axiosInstance.delete(`/Post/Attachment/${data}`).then(get('data'));
const removeAttachmentForUnifiedCommunity = data =>
  axiosInstance.delete(`/Post/DeleteAttachmentForUnifiedCommunity/${data}`).then(get('data'));
const addLike = data => axiosInstance.post('/Like', data).then(get('data'));
const removeLike = id => axiosInstance.delete(`/Like/${id}`).then(get('data'));
const addComment = data => axiosInstance.post('/Comment', data).then(get('data'));
const removeComment = id => axiosInstance.delete(`/Comment/${id}`).then(get('data'));
const editComment = data => axiosInstance.put('/Comment', data).then(get('data'));
const getHashtags = id =>
  axiosInstance.get(`/Post/GetHashTagsInCommunityByContributionId?contributionId=${id}`).then(get('data'));
const getTotalUnreadPosts = data =>
  axiosInstance
    .post('/User/GetNonReadedPostsTotalCount', data)
    .then(get('data'))
    .catch(e => {});
const notifyTaggedUsers = data => axiosInstance.post('api/Notification/NotifyTaggedUsers', data);
const notifyPlanUpgrade = data => axiosInstance.post('/api/Notification/NotifyAdminsAboutPlanUpgrade', data);

export {
  getAllPostsForContributionAsync,
  post,
  createMutiplePosts,
  deletePost,
  editPost,
  addLike,
  removeLike,
  removeAttachment,
  removeAttachmentForUnifiedCommunity,
  addComment,
  removeComment,
  editComment,
  notifyTaggedUsers,
  getTotalUnreadPosts,
  notifyPlanUpgrade,
  getPost,
  searchByKeyword,
  getPostForKeywords,
  getHashTagByContributionId,
  getAllCommunityPostsForCoachAsync,
  getAllCommunityPostsForClientAsync,
  getAllContributionPostsForClientAsync,
  getSuggestedSearchOptionsForAllContributionOfCoach,
  getSuggestedSearchOptionsForAllContributionOfClient,
  getAllContributionForCommunityPostCoach,
  getAllContributionForCommunityPostClient,
  getHashTagsIncommunitiesFromContributionsForCoach,
  getHashTagsIncommunitiesFromContributionsForClient,
  getHashtags,
};
