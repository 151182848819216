import moment from 'moment';
import lodash from 'lodash';
import { getSlotsWithoutOverlaps } from './getSlotsWithoutOverlaps';

export const generateOneToOneSlots = (oneToOneData, previousData, otherEvents = [], editContributionTimes) => {
  const allDays = [];
  const slots = [];
  const range = moment(oneToOneData.endDay).diff(moment(oneToOneData.startDay), 'days');

  let allSelectedDays = oneToOneData.selectedWeeks.map(week => {
    return week.days.map(day => {
      const preparedStartTime = moment()
        .set('hours', moment(week.startTime).get('hours'))
        .set('minutes', moment(week.startTime).get('minutes'));

      const preparedEndTime = moment()
        .set('hours', moment(week.endTime).get('hours'))
        .set('minutes', moment(week.endTime).get('minutes'));

      const amountOfFreeTime = moment.utc(preparedEndTime).diff(moment.utc(preparedStartTime), 'minutes');
      const { sessionDuration } = oneToOneData;

      const timeSlots = Math.floor(amountOfFreeTime / sessionDuration);
      const preparedDays = [];
      for (let i = 0; i < Math.abs(timeSlots); i++) {
        const startTimeValue = i * sessionDuration;
        const endTimeValue = (i + 1) * sessionDuration;
        const startTime = moment(week.startTime).add(startTimeValue, 'minutes');
        const endTime = moment(week.startTime).add(endTimeValue, 'minutes');
        preparedDays.push({
          day,
          startTime,
          endTime,
        });
      }
      return preparedDays;
    });
  });

  allSelectedDays = lodash.flattenDeep(allSelectedDays);

  for (let i = 0; i <= range; i++) {
    const nextDay = moment(oneToOneData.startDay).add(i, 'days');
    allDays.push({
      date: nextDay,
      name: nextDay.format('dddd').slice(0, 3),
    });
  }

  allSelectedDays.forEach(selectedDay => {
    allDays.forEach(day => {
      if (selectedDay.day.value === day.name) {
        const startTimeValue = moment(day.date)
          .set('hour', moment(selectedDay.startTime).hours())
          .set('minutes', moment(selectedDay.startTime).minutes())
          .format('YYYY-MM-DDTHH:mm:ss');
        const endTimeValue = moment(day.date)
          .set('hour', moment(selectedDay.endTime).hours())
          .set('minutes', moment(selectedDay.endTime).minutes())
          .format('YYYY-MM-DDTHH:mm:ss');
        const slot = {
          bookedTimes: [],
          startTime: startTimeValue,
          startDate: startTimeValue,
          endTime: endTimeValue,
          endDate: endTimeValue,
        };
        slots.push(slot);
      }
    });
  });

  const bookedSlots = previousData?.length
    ? previousData.filter(previousSlot => previousSlot.bookedTimes?.length > 0)
    : [];

  // const finalizedSlots = slots.map(slot => {
  //   const previousSlot = previousData.find(previousSlot => {
  //     if (
  //       moment(slot.startTime).format("YYYY-MM-DDTHH:mm") ===
  //         moment(previousSlot.startTime).format("YYYY-MM-DDTHH:mm") &&
  //       moment(slot.endTime).format("YYYY-MM-DDTHH:mm") ===
  //         moment(previousSlot.endTime).format("YYYY-MM-DDTHH:mm")
  //     ) {
  //       return previousSlot;
  //     }
  //   });

  //   if (previousSlot) {
  //     return {
  //       ...slot,
  //       id: previousSlot?.id,
  //       bookedTimes: previousSlot?.bookedTimes
  //     };
  //   }

  //   return slot;
  // });

  // const preparedBusyTimes = busyTimes
  //   ? busyTimes.map(time => ({
  //       color: "#e9c062",
  //       endDate: time.endTime,
  //       endTime: time.endTime,
  //       startDate: time.startTime,
  //       startTime: time.startTime,
  //       title: "External calendar"
  //     }))
  //   : [];
  const preparedOtherEvents = otherEvents.map(event => {
    return {
      ...event,
      sessionTimes: [...event.sessionTimes],
    };
  });

  if (preparedOtherEvents.length) {
    preparedOtherEvents[0].sessionTimes = preparedOtherEvents[0].sessionTimes.concat(bookedSlots);
  }

  const slotsWithoutOverlaps = getSlotsWithoutOverlaps(preparedOtherEvents, editContributionTimes);

  const preparedAndBookedSlots = [...slotsWithoutOverlaps, ...bookedSlots];

  return { slots: preparedAndBookedSlots, allDays, allSelectedDays };
};
