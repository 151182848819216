import { useEffect } from 'react';

import useParticipant from './useParticipant';
import VideoChatTrackKind from '../../VideoChatTrackKind';
import VideoChatVideoTrackType from '../../VideoChatVideoTrackType';
import DeviceType from '../../VideoChat/Toolbar/devices/DevicesList/DeviceType';
import Device from '../../VideoChat/Toolbar/devices/DevicesList/Device';

const useLocalParticipant = localParticipant => {
  const { setAudioTracks, videoTracks, setVideoTracks } = useParticipant(localParticipant);

  useEffect(() => {
    const onTrackPublished = publication => {
      switch (publication.kind) {
        case VideoChatTrackKind.audio:
          setAudioTracks(at => [...at, publication.track]);
          break;
        case VideoChatTrackKind.video:
          setVideoTracks(vt => [...vt, publication.track]);
          break;
        default:
          break;
      }
    };
    const onTrackStopped = track => {
      switch (track.kind) {
        case VideoChatTrackKind.audio:
          setAudioTracks(at => at.filter(a => a !== track));
          const localAudioTrack = Array.from(localParticipant.audioTracks.values())[0]?.track;

          if (localAudioTrack) {
            navigator.mediaDevices
              .enumerateDevices()
              .then(ds => ds.filter(d => d.kind === DeviceType.audioOutput))
              .then(ds =>
                ds
                  .map(
                    d =>
                      new Device({
                        id: d.deviceId,
                        label: d.label,
                        type: d.kind,
                      }),
                  )
                  .filter(d => d.id !== 'default'),
              )
              .then(devices => localAudioTrack.restart({ deviceId: devices[0]?.id }));
          }
          break;
        case VideoChatTrackKind.video:
          setVideoTracks(vt => vt.filter(v => v !== track));
          break;
        default:
          break;
      }
    };

    localParticipant.on('trackPublished', onTrackPublished);
    localParticipant.on('trackStopped', onTrackStopped);

    return () => {
      localParticipant.off('trackPublished', onTrackPublished);
      localParticipant.off('trackStopped', onTrackStopped);
    };
  }, [localParticipant, setAudioTracks, setVideoTracks]);

  return {
    audioTrack: Array.from(localParticipant.audioTracks.values())[0]?.track,
    videoTrack: videoTracks.filter(vt => vt.name === VideoChatVideoTrackType.camera)[0],
    screenTrack: videoTracks.filter(vt => vt.name === VideoChatVideoTrackType.screenShare)[0],
  };
};

export default useLocalParticipant;
