import { useCallback } from 'react';
import TwilioVideo from 'twilio-video';

import { LOCAL_TRACK_PRIORITIES } from 'constants.js';

import { useAccount } from 'hooks';
import { UserRoles } from 'helpers/constants';
import useVideoTrack from './useVideoTrack';
import VideoChatVideoTrackType from '../../VideoChatVideoTrackType';

const useLocalVideoTrack = (localParticipant, localVideoTrack) => {
  const { currentRole } = useAccount();
  const { videoTrack, isEnabled } = useVideoTrack(localVideoTrack);

  const startLocalVideoTrack = useCallback(
    async (deviceId = null) => {
      try {
        const createdVideoTrack = await TwilioVideo.createLocalVideoTrack({
          logLevel: 'debug',
          name: VideoChatVideoTrackType.camera,
          deviceId: deviceId ? { exact: deviceId } : undefined,
        });
        await localParticipant.publishTrack(createdVideoTrack, {
          priority: currentRole === UserRoles.cohealer ? LOCAL_TRACK_PRIORITIES.high : LOCAL_TRACK_PRIORITIES.low,
        });
      } catch (e) {
        console.log('Failed to turn on camera.', e);
      }
    },
    [currentRole, localParticipant],
  );

  const stopLocalVideoTrack = useCallback(() => {
    if (videoTrack) {
      videoTrack.stop();
      localParticipant.unpublishTrack(videoTrack);
    }
  }, [localParticipant, videoTrack]);

  const setIsLocalVideoTrackEnabled = useCallback(
    enabled => {
      if (enabled) {
        startLocalVideoTrack();
      } else {
        stopLocalVideoTrack();
      }
    },
    [startLocalVideoTrack, stopLocalVideoTrack],
  );

  const switchVideoDevice = useCallback(
    deviceId => {
      stopLocalVideoTrack();
      startLocalVideoTrack(deviceId);
    },
    [startLocalVideoTrack, stopLocalVideoTrack],
  );

  return {
    videoTrack,
    isEnabled,
    setIsEnabled: setIsLocalVideoTrackEnabled,
    deviceLabel: isEnabled && videoTrack ? videoTrack.mediaStreamTrack.label : null,
    switchDevice: switchVideoDevice,
  };
};

export default useLocalVideoTrack;
