import moment from 'moment';

const { getTimePeriodsForAvailability } = require('./datesAndMoney');

export const getOneToOneEvents = ({ contributionTimes, duration, title, serviceProviderName }) => {
  const events = getTimePeriodsForAvailability({
    availabilityPeriods: contributionTimes,
    duration,
    title,
    serviceProviderName,
  }).map(e => {
    const { isBooked } = e;
    if (!isBooked) {
      return e;
    }
    const bookedTime = contributionTimes.find(at => at.id === e.id).bookedTimes.find(bt => bt.id === e.bookedTimeId);

    return {
      ...e,
      participantId: bookedTime.participantId,
      videoRoomInfo: bookedTime.videoRoomInfo,
    };
  });
  return events;
};

export const isOneToOnePastDate = day => {
  const todayDate = moment(new Date()).startOf('day');
  const selectedDate = moment(day).startOf('day');
  const isPastDate = selectedDate.isBefore(todayDate);
  return isPastDate;
};
