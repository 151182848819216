import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import MenuItem from '@material-ui/core/MenuItem'
import Menu from '@material-ui/core/Menu'

import { getVideoDevices } from 'utils/devices'
import DeviceType from './DeviceType'

import './DevicesList.scss'

const DevicesList = ({ type, selectedDevice, onSelectedDeviceChange }) => {
  const anchorRef = useRef()
  const [isOpen, setIsOpen] = useState(false)
  const onClose = () => setIsOpen(false)

  const [devices, setDevices] = useState([])
  useEffect(() => {
    if (isOpen) {
      getVideoDevices().then((ds) => setDevices(ds))
    }
  }, [isOpen, type])

  const IconComponent = isOpen ? ArrowDropUpIcon : ArrowDropDownIcon

  return (
    <>
      <IconComponent ref={anchorRef} onClick={() => setIsOpen(true)} />
      <Menu
        anchorEl={anchorRef.current}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={isOpen}
        onClose={onClose}
      >
        {devices.map((d) => (
          <MenuItem
            key={d.id}
            onClick={() => {
              onSelectedDeviceChange(d.label, d.id)
              onClose()
            }}
          >
            <span
              className={classNames('video-chat-devices-list-device', {
                'video-chat-devices-list-device--selected': d.label === selectedDevice,
              })}
            >
              {d.label}
            </span>
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

DevicesList.propTypes = {
  type: PropTypes.oneOf([DeviceType.audioInput, DeviceType.videoInput, DeviceType.audioOutput]).isRequired,
  selectedDevice: PropTypes.string,
  onSelectedDeviceChange: PropTypes.func.isRequired,
}

DevicesList.defaultProps = {
  selectedDevice: null,
}

export default DevicesList
