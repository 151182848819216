import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import withStyles from '@material-ui/core/styles/withStyles';
import { Icon } from '@mdi/react';
import { mdiDelete } from '@mdi/js';
import { useHttp, useAccount, useVideoChatActions } from 'hooks';
import { useTheme, useMediaQuery } from '@material-ui/core';
import Popup from 'components/Popup/Popup';
import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import classes from './TagsTable.module.scss';
import Tag from '../Tag/Tag';

const BoldTableCell = withStyles({
  root: {
    fontWeight: 800,
  },
})(TableCell);

const BoldTableCellNext = withStyles({
  root: {
    fontWeight: 800,
    paddingLeft: '30px',
  },
})(TableCell);

const BoldTableCellStatus = withStyles({
  root: {
    fontWeight: 800,
    paddingLeft: '10px',
  },
})(TableCell);

const BoldTableCellEdit = withStyles({
  root: {
    fontWeight: 800,
    paddingLeft: '25px',
  },
})(TableCell);
const StyledTableContainer = withStyles({
  root: {
    overflowX: 'auto',
    maxWidth: props => (props.mobileView ? '96vw' : '100%'),
    margin: '0 auto',
  },
})(TableContainer);

const TagsTable = ({
  tags = [],
  isChecked,
  onCheckClicked,
  setShowContactsList,
  setSelectedTag,
  setIsAddTagModalOpen,
  setIsReadOnlyView,
  setIsAddContact,
  createNewTagClickHandler,
  searchedKeyword,
  setshowModalToAddContacts,
}) => {
  const [popupText, setPopupText] = useState(null);
  const { request } = useHttp();
  const [tagWithOpenDropDown, setTagtWithOpenDropDown] = useState(null);
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));

  const setId = id => {
    setTagtWithOpenDropDown(id);
  };

  return tags?.length === 0 && searchedKeyword.length > 0 ? (
    <div style={{ display: 'flex', justifyContent: 'center', fontWeight: '600' }}>No search results</div>
  ) : tags?.length === 0 && searchedKeyword.length === 0 ? (
    <div style={{ display: 'flex', justifyContent: 'center', fontWeight: '400' }}>
      No tags yet.{' '}
      <span
        style={{
          display: 'flex',
          justifyContent: 'center',
          fontWeight: '400',
          cursor: 'pointer',
          textDecoration: 'underline',
          marginLeft: '3px',
        }}
        onClick={createNewTagClickHandler}
      >
        click here to create a tag
      </span>
    </div>
  ) : (
    <>
      <StyledTableContainer component={Paper} mobileView={mobileView}>
        <Table className="" aria-label="contributions table">
          <TableHead>
            <TableRow>
              <BoldTableCell>Tag Name</BoldTableCell>
              <BoldTableCell align="center">Contacts</BoldTableCell>
              <BoldTableCell align="center">Emails Sent</BoldTableCell>
              <BoldTableCell>Date Created</BoldTableCell>
              <BoldTableCell align="center">Action</BoldTableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {tags?.map((row, index) => (
              <Tag
                key={row?.id}
                index={index}
                row={row}
                isChecked={isChecked}
                onCheckClicked={onCheckClicked}
                tagWithOpenDropDown={tagWithOpenDropDown}
                setId={setId}
                setShowContactsList={setShowContactsList}
                setSelectedTag={setSelectedTag}
                setIsAddTagModalOpen={setIsAddTagModalOpen}
                setIsReadOnlyView={setIsReadOnlyView}
                setIsAddContact={setIsAddContact}
                setshowModalToAddContacts={setshowModalToAddContacts}
              />
            ))}
          </TableBody>
        </Table>
      </StyledTableContainer>
    </>
  );
};

export default TagsTable;
