import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Switch, Redirect } from 'react-router-dom';

import { ProtectedRoute, PublicRoute } from 'components/Routing';
import {
  SignUpFirstPage,
  SignUpClientSecondPage,
  SignUpSecondPage,
  SignInPage,
  SignInExtendedPage,
  RestorePassword,
} from 'pages';
import { SignUpSelectType } from 'pages/Auth/SignUpSelectType';

const CenterContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: 20px 10px;
  box-sizing: border-box;
  flex-direction: column;
  background-color: #fafafa;
  ${({ viewportHeight }) => viewportHeight && 'min-height: 100vh'}
`;

function SignUpPage({ match: { path } }) {
  return (
    <CenterContainer viewportHeight>
      <Switch>
        <PublicRoute path={`${path}/signin`} exact component={SignInPage} />
        <PublicRoute path={`${path}/signin/extended`} exact component={SignInExtendedPage} />
        <PublicRoute path={`${path}/restore-pass`} exact component={RestorePassword} />
        {/** Simple Signup */}
        <PublicRoute path={`${path}/signup`} exact component={SignUpFirstPage} />
        <PublicRoute path={`${path}/signup/type`} exact component={SignUpSelectType} />
        <PublicRoute path={`${path}/signup/client`} exact component={SignUpFirstPage} />
        <ProtectedRoute path={`${path}/signup/continue`} exact component={SignUpSecondPage} />
        <ProtectedRoute path={`${path}/signup/client/continue`} exact component={SignUpClientSecondPage} />

        {/** Signup A routes */}
        <PublicRoute path={`${path}/coheresignupa`} exact component={SignUpFirstPage} />
        <PublicRoute path={`${path}/coheresignupa/type`} exact component={SignUpSelectType} />
        <ProtectedRoute path={`${path}/coheresignupa/continue`} exact component={SignUpSecondPage} />
        <ProtectedRoute path={`${path}/coheresignupa`} exact component={SignUpSecondPage} />

        {/** Signup B routes */}
        <PublicRoute path={`${path}/coheresignupb`} exact component={SignUpFirstPage} />
        <PublicRoute path={`${path}/coheresignupb/type`} exact component={SignUpSelectType} />
        <ProtectedRoute path={`${path}/coheresignupb/continue`} exact component={SignUpSecondPage} />

        <Redirect to={`${path}/signin`} />
      </Switch>
    </CenterContainer>
  );
}

SignUpPage.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string,
  }).isRequired,
};

export default SignUpPage;
