import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, Redirect } from 'react-router-dom';

import { useRouter } from 'hooks';
import { JOURNEY_ROUTES } from 'helpers/constants';

import { ClientJourneyContribution } from 'services/dataContracts/contributions';
import UpcomingContributions from '../pages/Dashboard/components/ClientDashboard/ClientJourney/Sessions/UpcomingContributions';
import PastContributions from '../pages/Dashboard/components/ClientDashboard/ClientJourney/Sessions/PastContributions';
import AllConstributions from 'pages/Dashboard/components/ClientDashboard/ClientJourney/Sessions/AllContributions';
import AllPurchasesContributions from '../pages/Dashboard/components/ClientDashboard/ClientJourney/Sessions/AllPurchasesContributions';
import LiveConstributions from 'pages/Dashboard/components/ClientDashboard/ClientJourney/Sessions/LiveContributions';
import SelfpacedConstributions from 'pages/Dashboard/components/ClientDashboard/ClientJourney/Sessions/SelfpacedContributions/SelfpacedContributions';

const Sessions = ({
  // selfpacedSessions,
  // upcomingNotBooked,
  // upcomingContributions,
  // pastContributions,
  // liveupcomingContributions,
  // livepastSessions,
  // selfpacedupcomingContributions,
  // livepastContributions,
  // selfpacedpastContributions,
  upcoming,
  past,
  modules,
  inCompleted,
  inCompletedlive,
  inCompletedselfpaced,
  upcominglive,
  bookable,
  upcomingselfpaced,
}) => {
  const {
    match: { path, url },
  } = useRouter();

  // const upcomingContributionsArray = useMemo(
  //   () => Object.keys(upcomingContributions).flatMap(key => upcomingContributions[key]),
  //   [upcomingContributions],
  // );
  // const pastContributionsArray = useMemo(() => Object.keys(pastContributions).flatMap(key => pastContributions[key]), [
  //   pastContributions,
  // ]);

  // const livepastContributionsArray = useMemo(
  //   () => Object.keys(livepastContributions).flatMap(key => livepastContributions[key]),
  //   [livepastContributions],
  // );

  // const selfpacedpastContributionsArray = useMemo(
  //   () => Object.keys(selfpacedpastContributions).flatMap(key => selfpacedpastContributions[key]),
  //   [selfpacedpastContributions],
  // );

  // const allPurchasesContributionsArray = useMemo(() => [...upcomingContributionsArray, ...pastContributionsArray], [
  //   upcomingContributionsArray,
  //   pastContributionsArray,
  // ]);

  return (
    <Switch>
      <Route
        exact
        path={`${path}/allsessions`}
        component={() => (
          <AllConstributions
            // selfpacedupcomingContributions={selfpacedSessions}
            // selfpacedpastContributions={selfpacedpastContributions}
            // upcomingNotBooked={upcomingNotBooked}
            // upcomingcontributions={liveupcomingContributions}
            // pastcontributions={pastContributions}
            // livepastSessions={livepastSessions}
            bookable={bookable}
            upcoming={upcoming}
            inCompleted={inCompleted}
            modules={modules}
          />
        )}
      />
      <Route
        exact
        path={`${path}/livesessions`}
        component={() => (
          <LiveConstributions
            // upcomingcontributions={liveupcomingContributions}
            // pastcontributions={livepastContributionsArray}
            // upcomingNotBooked={upcomingNotBooked}
            // // upcomingcontributions={upcomingContributions} // Not needed
            // livepastSessions={livepastSessions}
            bookable={bookable}
            inCompletedlive={inCompletedlive}
            upcominglive={upcominglive}
          />
        )}
      />
      <Route
        exact
        path={`${path}/selfpacedsessions`}
        component={() => (
          <SelfpacedConstributions
            // upcomingcontributions={selfpacedupcomingContributions}
            // pastcontributions={selfpacedpastContributionsArray}
            // selfpacedSessions={selfpacedSessions}
            // selfpacedpastContributions={selfpacedpastContributions}
            modules={modules}
          />
        )}
      />
      <Route
        exact
        path={`${path}/pastsessions`}
        component={() => (
          <PastContributions
            // contributions={pastContributionsArray}
            // livepastSessions={livepastSessions}
            // selfpacedpastContributions={selfpacedpastContributions}
            // type={'past'}
            past={past}
          />
        )}
      />

      {/* This was already commeented, so dont uncomment it */}
      {/* <Route
        exact
        path={`${path}/upcomingsessions`}
        component={() => <UpcomingContributions contributions={upcomingContributions} />}
      /> */}

      {/* {!path.includes(JOURNEY_ROUTES.SESSIONS) && (
        <Route
          exact
          path={`${path}/all`}
          component={() => <AllPurchasesContributions contributions={allPurchasesContributionsArray} />}
        />
      )} */}
      <Redirect to={path.includes(JOURNEY_ROUTES.SESSIONS) ? `${url}/allsessions` : `${url}/all`} />
    </Switch>
  );
};

const contributionsPropType = PropTypes.arrayOf(PropTypes.instanceOf(ClientJourneyContribution)).isRequired;

Sessions.propTypes = {
  upcomingContributions: PropTypes.shape({
    otherIncompleted: contributionsPropType,
    today: contributionsPropType,
    thisWeek: contributionsPropType,
    thisMonth: contributionsPropType,
    nextMonth: contributionsPropType,
    thisYear: contributionsPropType,
    afterThisYear: contributionsPropType,
  }).isRequired,
  pastContributions: PropTypes.shape({
    priorYears: contributionsPropType,
    thisYear: contributionsPropType,
    lastMonth: contributionsPropType,
    thisMonth: contributionsPropType,
    thisWeek: contributionsPropType,
  }).isRequired,
};

export default Sessions;
