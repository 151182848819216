import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { StyledTitle, StyledButton } from '../GettingStarted.styles'
import { ROUTES } from 'constants.js'

const StyledImage = styled.img`
  margin-top: 20px;
  height: 55%;

  ${({ mobileView }) =>
    mobileView &&
    `
    width: 80%;
    height: auto;
  `}
`

const TestVideo = ({ mobileView }) => {
  return (
    <>
      <StyledImage src="/get-started-test-video.png" mobileView={mobileView} />

      <StyledTitle mobileView={mobileView}>
        Deliver your services using Cohere’s video platform or connect to others like Zoom. Leverage community features
        to communicate with clients.
      </StyledTitle>

      <Link to={ROUTES.ACCOUNT_VIDEO} target="_blank">
        <StyledButton variant="primary">Test Video</StyledButton>
      </Link>
    </>
  )
}

export default TestVideo
