import React from 'react';
import PropTypes from 'prop-types';

import { ChatsList } from 'components/UI/chats';

const ConversationsList = ({ conversationIds, onSelectConversation }) => (
  <ChatsList channelIds={conversationIds} onChannelClick={onSelectConversation} />
);

ConversationsList.propTypes = {
  conversationIds: PropTypes.arrayOf(PropTypes.string),
  onSelectConversation: PropTypes.func.isRequired,
};

ConversationsList.defaultProps = {
  conversationIds: null,
};

export default ConversationsList;
