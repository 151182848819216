import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import styled from 'styled-components';
import { getAudioInputDevices, getAudioOutputDevices } from 'utils/devices';

import './DevicesList.scss';
import DeviceType from './DeviceType';

const AudioDevicesList = ({
  selectedAudioInputDevice,
  onSelectedAudioInputDeviceChange,
  selectedAudioOutputDevice,
  onSelectedAudioOutputDeviceChange,
}) => {
  const anchorRef = useRef();
  const [isOpen, setIsOpen] = useState(false);
  const onClose = () => setIsOpen(false);

  const [audioInputDevices, setAudioInputDevices] = useState([]);
  const [audioOutputDevices, setAudioOutputDevices] = useState([]);

  const StyledDeviceType = styled.span`
    margin-left: 8px;
    font-weight: bold;
  `;
  useEffect(() => {
    if (isOpen) {
      // navigator.mediaDevices
      // .enumerateDevices()
      // .then(ds => ds.filter(d => d.kind === DeviceType.audioInput))
      // .then(ds => ds.map(d => alert('id: ' + d.deviceId + ' label: ' + d.label + ' type: ' + d.kind)));
      getAudioInputDevices().then(ds => setAudioInputDevices(ds));
      getAudioOutputDevices().then(ds => setAudioOutputDevices(ds));
    }
  }, [isOpen]);

  const IconComponent = isOpen ? ArrowDropUpIcon : ArrowDropDownIcon;
  const setSinkIdSupported = 'setSinkId' in HTMLMediaElement.prototype;

  return (
    <>
      <IconComponent ref={anchorRef} onClick={() => setIsOpen(true)} />
      <Menu
        anchorEl={anchorRef.current}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={isOpen}
        onClose={onClose}
      >
        <StyledDeviceType>Select a Microphone:</StyledDeviceType>
        {audioInputDevices.map(d => (
          <MenuItem
            key={d.id}
            onClick={() => {
              onSelectedAudioInputDeviceChange(d.label, d.id);
              onClose();
            }}
          >
            <span
              className={classNames('video-chat-devices-list-device', {
                'video-chat-devices-list-device--selected': d.label === selectedAudioInputDevice,
              })}
            >
              {d.label}
            </span>
          </MenuItem>
        ))}
        {setSinkIdSupported && (
          <>
            <hr />
            <StyledDeviceType>Select a Speaker:</StyledDeviceType>
          </>
        )}
        {setSinkIdSupported &&
          audioOutputDevices.map(d => (
            <MenuItem
              key={d.id}
              onClick={() => {
                onSelectedAudioOutputDeviceChange(d);
                onClose();
              }}
            >
              <span
                className={classNames('video-chat-devices-list-device', {
                  'video-chat-devices-list-device--selected': d.label === selectedAudioOutputDevice,
                })}
              >
                {d.label}
              </span>
            </MenuItem>
          ))}
      </Menu>
    </>
  );
};

AudioDevicesList.propTypes = {
  selectedAudioInputDevice: PropTypes.string,
  onSelectedAudioInputDeviceChange: PropTypes.func.isRequired,
  selectedAudioOutputDevice: PropTypes.string.isRequired,
  onSelectedAudioOutputDeviceChange: PropTypes.func.isRequired,
};

AudioDevicesList.defaultProps = {
  selectedAudioInputDevice: null,
  selectedAudioOutputDevice: null,
};

export default AudioDevicesList;
