import styled from 'styled-components';
import { colors } from '../../../../../utils/styles';
import { Box } from '@material-ui/core';

export const StyledContainerLink = styled.a`
  display: flex;
  flex-direction: column;
  width: 300px;
  margin-bottom: 15px;
  border-radius: 8px;
  box-shadow: 0 0 10px ${colors.shadowDark};
  overflow: hidden;
  transition: all 0.2s;
  &:hover {
    box-shadow: 0 0 18px ${colors.shadowDark};
    text-decoration: none;
  }
`;
export const StyledImg = styled.div`
  ${({ bg }) =>
    `
    background-image: url(${bg});
    `};
  padding-top: 50%;
  background-position: center;
  background-size: cover;
  flex-direction: column;
  background-repeat: no-repeat;
`;
export const StyledTitle = styled(Box)`
  padding: 10px 10px 0;
  margin: 0;
  font-weight: bolder;
  font-size: 16px;
  color: ${colors.textMain};
`;
export const StyledDescription = styled(Box)`
  padding: 0 10px 10px;
  margin: 0;
  font-size: 12px;
  color: ${colors.textSecondary};
`;
