import React from 'react';
import './Attachment.scss';
import { Wrapper } from './Attachments';

const ThreeAttachments = ({ attachments = [], removeAttachment, handleRemoveAttachment, handleImageClick }) => {
  return (
    <Wrapper index={0} removeAttachment={removeAttachment} handleRemoveAttachment={handleRemoveAttachment}>
      <div
        className="image-wrap-row"
        style={{
          backgroundImage: `url(${attachments[0]?.item?.s3Link || ''})`,
          backgroundSize: 'cover',
        }}
        onClick={() => handleImageClick(attachments[0]?.item || {})}
      ></div>
      <div className="modified-image-container">
        <div
          className="modified-image-wrap-three-attachments"
          style={{
            backgroundImage: `url(${attachments[1]?.item?.s3Link || ''})`,
            backgroundSize: 'cover',
          }}
          onClick={() => handleImageClick(attachments[1]?.item || {})}
        ></div>
        <div
          className="modified-image-wrap-three-attachments"
          style={{
            backgroundImage: `url(${attachments[2]?.item?.s3Link || ''})`,
            backgroundSize: 'cover',
          }}
          onClick={() => handleImageClick(attachments[2]?.item || {})}
        ></div>
      </div>
    </Wrapper>
  );
};

export default ThreeAttachments;
