import React from 'react';
import classes from './ContentEditor.module.scss';
import EditIcon from '../Icons/SVG/EditIcon';
import Button from 'components/FormUI/Button';

const ContentEditor = props => {
  const {
    attributeName,
    attributeValue,
    isValueHoverable = false,
    onEditClick = () => {},
    onValueClick = () => {},
  } = props;

  let fieldValueClass = classes.field_value;
  if (isValueHoverable) {
    fieldValueClass = `${classes.field_value} ${classes.hoverable}`;
  }

  return (
    <div className={classes.editor_container}>
      <div className={classes.field_name_container}>
        <div className={classes.field_name}>{attributeName}</div>
      </div>
      <div className={classes.field_value_container}>
        <div className={fieldValueClass} onClick={onValueClick}>
          {attributeValue}
        </div>
        <div className={classes.editbutton_container}>
          <Button className={classes.editbutton} onClick={onEditClick} autoWidth>
            <EditIcon width="16" height="16" />
            Edit
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ContentEditor;
