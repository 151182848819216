import React from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';

import TextField from '../UI/TextField';

const Input = React.forwardRef((props, ref) => {
  const { redError, whiteError, helperText, counter, noHelperText, ...rest } = props;
  const [field, meta] = useField(props);

  const privateHelperText = counter ? `${field?.value?.length}/${counter}` : helperText;
  return (
    <TextField
      helperText={(!noHelperText && meta.touched && meta.error) || privateHelperText}
      redError={redError}
      whiteError={whiteError}
      {...field}
      {...rest}
      error={Boolean(meta.touched && meta.error)}
      inputRef={ref}
    />
  );
});

Input.propTypes = {
  helperText: PropTypes.string,
};

Input.defaultProps = {
  helperText: null,
};

export default Input;
