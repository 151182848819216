import React, { useEffect, useState } from 'react';
import { useMediaQuery } from '@material-ui/core';
import { setActiveQuiz, setShowCreateModal } from 'actions/quizes';
import { useHeader } from 'hooks/usePageActions';
import CreateQuizesModal from 'pages/CreateQuizes/Components/Modals/CreateModal/CreateModal';
import PreviewPage from 'pages/CreateQuizes/Pages/PreviewPage/PreviewPage';
import ContentPage from 'pages/CreateQuizes/Pages/QuizContent/ContentPage';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getQuizFormById } from '../../pages/CreateQuizes/Services/CreateQuizes.service';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from 'react-router-dom/cjs/react-router-dom.min';
import { isEmpty } from 'lodash';
import Loader from 'components/UI/Loader';
import ClientQuizPage from 'pages/QuizViewPage/Pages/QuizPage/QuizPage';
function EditQuizContainer({ match: { path } }) {
  const { activeQuiz, showCustomizeResults } = useSelector(state => state?.quizes);
  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { showModal } = useSelector(state => state.quizes);
  const [loading, setLoading] = useState(true);
  const mobileView = useMediaQuery('(max-width: 1200px)');
  useEffect(() => {
    if (id) {
      setLoading(true);
      getQuizFormById(id)
        .then(res => {
          dispatch(setActiveQuiz({ ...res, isSaved: true }));
          setLoading(false);
        })
        .catch(error => {
          setLoading(false);
        });
    }
  }, [id]);

  if (id && loading) {
    return <Loader />;
  }

  return (
    <>
      <Switch>
        <Route path={`${path}/content`} exact component={ContentPage} />
        <Route path={`${path}/preview/:id?`} exact component={ClientQuizPage} />
        <Route path={`${path}/result`} exact component={ContentPage} />
        <Redirect to={`${path}/content`} />
      </Switch>
    </>
  );
}
export default EditQuizContainer;
