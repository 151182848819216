import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import withStyles from '@material-ui/core/styles/withStyles';
import CloseIcon from '@material-ui/icons/Close';
import Tooltip from '@material-ui/core/Tooltip';
import { TOOLTIP } from '../../../constants';
import InfoIcon from '@material-ui/icons/Info';
import { colors } from 'utils/styles';
import { Spinner } from 'react-activity';
import 'react-activity/dist/library.css';
import { connect } from 'react-redux';
import * as calendarsActions from 'actions/calendars';
import { useHttp } from 'hooks';
import { useLocation, useHistory } from 'react-router-dom';
import { getDomainIcon } from 'utils/calendar';
import { useTheme, useMediaQuery } from '@material-ui/core';
import Loader from 'components/UI/Loader';

import Button from 'components/FormUI/Button';
import styled from 'styled-components';
import AddCalendarModal from 'components/Modals/AddCalendarModal/AddCalendarModal';
import { RemoveCalendarModal, DefaultCalendarModal } from 'components/Modals/AddCalendarModal/RemoveCalendar';

const BoldTableCell = withStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: ({ mobileView }) => (mobileView ? '16px' : '17.8px'),
    fontWeight: 500,
    letterSpacing: '0.15px',
  },
})(TableCell);

const NarrowTableCell = withStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingTop: 15,
    paddingBottom: 15,
  },
})(TableCell);

const StyledButton = styled(Button)`
  margin-right: 0px;
`;

const StyledCloseIcon = styled(CloseIcon)`
  cursor: pointer;
`;

const CalendarInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: ${props => (!props.isDefault ? 'pointer' : 'default')};
`;

const EmptyCalendarWrapper = styled.div`
  padding: 16px;
`;
const EmailComponent = (
  {
    provider,
    emailAddress,
    isCheckConflictsEnabled,
    isEventRemindersEnabled,
    isDefault,
    handleclick,
    onRemove,
    Icon,
    key,
    isloading,
  },
  i,
) => {
  return (
    <TableRow key={key} className="row">
      <NarrowTableCell component="th" scope="row">
        <CalendarInfoWrapper
          onClick={() => {
            if (!isDefault) {
              handleclick(emailAddress);
            }
          }}
          isDefault={isDefault}
        >
          <Icon />
          <span className="ml-2">
            {isDefault && (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: '#116582',
                  width: '36%',
                  borderRadius: '5px',
                }}
              >
                <p style={{ marginBottom: '0px', fontSize: '12px', color: 'white' }}>Default</p>
              </div>
            )}
            {emailAddress}{' '}
          </span>
        </CalendarInfoWrapper>

        {isloading === false ? (
          <StyledCloseIcon onClick={() => onRemove(emailAddress)} />
        ) : (
          <Spinner color="#116582" size={20} />
        )}
      </NarrowTableCell>
    </TableRow>
  );
};

export default EmailComponent;
