export const PaymentStatus = {
  requiresPaymentMethod: 'requires_payment_method',
  requiresConfirmation: 'requires_confirmation',
  requiresAction: 'requires_action',
  processing: 'processing',
  requiresCapture: 'requires_capture',
  canceled: 'canceled',
  succeeded: 'succeeded',
  unpurchased: 'unpurchased',
  proceedSubscription: 'proceed_subscription',
};
