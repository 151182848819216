import { useState, useEffect } from 'react';

const getTracksFromTrackPublications = trackPublicationsMap =>
  [...trackPublicationsMap.values()].map(p => p.track).filter(t => t !== null);

const useParticipant = participant => {
  const [audioTracks, setAudioTracks] = useState([]);
  const [videoTracks, setVideoTracks] = useState([]);

  useEffect(() => {
    setAudioTracks(getTracksFromTrackPublications(participant.audioTracks));
    setVideoTracks(getTracksFromTrackPublications(participant.videoTracks));

    return () => {
      setAudioTracks([]);
      setVideoTracks([]);
    };
  }, [participant]);

  return {
    audioTracks,
    setAudioTracks,
    videoTracks,
    setVideoTracks,
  };
};

export default useParticipant;
