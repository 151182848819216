import React from 'react'
import PropTypes from 'prop-types'

const MediaLink = ({ url, children }) => {
  if (!url) {
    return children
  }

  return (
    <a href={url} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
      {children}
    </a>
  )
}

MediaLink.propTypes = {
  children: PropTypes.node.isRequired,
  url: PropTypes.string,
}

MediaLink.defaultProps = {
  url: null,
}

export default MediaLink
