import React from 'react';
import PropTypes from 'prop-types';

import Button from 'components/FormUI/Button';
import { useAccount, useHttp } from 'hooks';
import { connect } from 'react-redux';
import { TemplateType } from 'helpers/constants/templateType';
import { ContributionType } from 'helpers/constants';
import { ColorSchemeService } from 'services/color.service';
import { determineColorToUse } from 'services/contributions.service';
import { lightOrDark } from 'utils/utils';
import { DarkEnabledPrimaryOutlineButton } from '../../component/styled';

const BookSessionTime = ({
  contribution,
  session,
  sessionTime,
  onSessionTimeBooked,
  mobileView,
  isSessionView = false,
}) => {
  const { request, loading } = useHttp();
  const { user, currentRole } = useAccount();
  const activeTemplate = contribution?.activeTemplate || TemplateType.TemplateThree;
  const type = contribution?.type;
  // const brandingColors = contribution?.brandingColors;
  // const isCustomBrandingColorsActive = contribution?.isCustomBrandingColorsActive;
  const isCoach = currentRole == 'Cohealer';
  // const shouldChangeColor = type != ContributionType.contributionOneToOne;
  // const customBtnColor = isCustomBrandingColorsActive
  //   ? brandingColors?.PrimaryColorCode
  //   : activeTemplate === TemplateType.TemplateOne
  //   ? '#6999CA'
  //   : '#D08ACD';
  let colorToUse = determineColorToUse(contribution);
  const btnColor = colorToUse.PrimaryColorCode;
  const textColor =
    colorToUse?.TextColorCode === 'Auto'
      ? lightOrDark(colorToUse?.PrimaryColorCode)
      : colorToUse?.TextColorCode === '#000000'
      ? '#000000'
      : '#FFFFFF';
  // const btnColor = ColorSchemeService().PrimaryColorCode;
  const onBookSession = () => {
    request('/Contribution/BookSessionTime', 'POST', {
      contributionId: contribution.id,
      sessionId: session.id,
      sessionTimeId: sessionTime.id,
    }).then(() => onSessionTimeBooked());
  };

  return isSessionView ? (
    <DarkEnabledPrimaryOutlineButton
      autoWidth
      textColor={colorToUse.PrimaryColorCode}
      borderColor={colorToUse.PrimaryColorCode}
      variant="outline"
      onClick={onBookSession}
      disabled={loading}
      mobileView={mobileView}
      backgroundColor={contribution?.isDarkModeEnabled ? 'transparent' : null}
      {...{ isDarkModeEnabled: contribution?.isDarkModeEnabled }}
    >
      Book Session Time
    </DarkEnabledPrimaryOutlineButton>
  ) : (
    <Button
      textColor={textColor}
      backgroundColor={btnColor}
      autoWidth
      onClick={onBookSession}
      disabled={loading}
      mobileView={mobileView}
    >
      Book Session Time
    </Button>
  );
};

BookSessionTime.propTypes = {
  contribution: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  session: PropTypes.shape({
    id: PropTypes.string,
  }),
  sessionTime: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  onSessionTimeBooked: PropTypes.func.isRequired,
  mobileView: PropTypes.bool,
};

const mapStateToProps = ({ contributions }) => ({
  brandingColors: contributions?.brandingColors,
  contribution: contributions.activeContribution,
});

export default connect(mapStateToProps)(BookSessionTime);
