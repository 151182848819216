export const STICK_HEADER = 'STICK_HEADER';
export const UNSTICK_HEADER = 'UNSTICK_HEADER';

export const stickHeader = () => ({
  type: STICK_HEADER,
});

export const unstickHeader = () => ({
  type: UNSTICK_HEADER,
});
