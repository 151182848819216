import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { useAccount } from 'hooks';
import { useVideoChatRecording } from 'hooks/useVideoChatRecording';
import { LocalParticipant, RemoteParticipant, ParticipantVideoTrackType, ParticipantDisplayMode } from '../Participant';
import { setVideoChatRecordingStatus } from 'actions/videoChat';

import './SpeakerView.scss';
import { UserRoles } from 'helpers/constants';
import StyledRound from 'components/UI/RecordingRoundStatus/Round';

const getDominantParticipant = (dominantSpeakerParticipant, screenShareParticipants) => {
  if (screenShareParticipants.length === 0) {
    return dominantSpeakerParticipant;
  }
  if (!dominantSpeakerParticipant) {
    return screenShareParticipants[0];
  }
  const isDominantSpeakerSharingScreen = screenShareParticipants.some(ssp => ssp === dominantSpeakerParticipant);

  return isDominantSpeakerSharingScreen ? dominantSpeakerParticipant : screenShareParticipants[0];
};

const SpeakerView = ({
  localParticipant,
  participants,
  dominantSpeakerParticipant,
  screenShareParticipants,
  isTest,
  videoTrack,
  audioTrack,
  screenTrack,
  selectedAudioOutputDevice,
}) => {
  const { currentRole } = useAccount();
  const dominantParticipant = getDominantParticipant(dominantSpeakerParticipant, screenShareParticipants);
  const dominantSpeakerParticipantIdentity = dominantSpeakerParticipant ? dominantSpeakerParticipant.identity : null;
  const localParticipantVideoPreferred =
    currentRole === UserRoles.cohealer ? ParticipantVideoTrackType.screenShare : undefined;

  const dispatch = useDispatch();

  const { getCurrentRoomStatus, roomId, contributionId, recordingStatus } = useVideoChatRecording();

  useEffect(() => {
    getCurrentRoomStatus(roomId, contributionId).then(res => {
      dispatch(setVideoChatRecordingStatus(res.payload));
    });
  });

  return (
    <div className="video-chat-speaker-view">
      <div className="video-chat-speaker-view-header">
        <div className="video-chat-speaker-view-header-participants-list">
          {
            <LocalParticipant
              isTest={isTest}
              className="video-chat-speaker-view-header-participants-list__item"
              participant={localParticipant}
              preferredVideoTrack={localParticipantVideoPreferred}
              dominant={localParticipant.identity === dominantSpeakerParticipantIdentity}
              videoTrack={videoTrack}
              audioTrack={audioTrack}
              screenTrack={screenTrack}
              selectedAudioOutputDevice={selectedAudioOutputDevice}
            />
          }
          {participants.map(p => (
            <RemoteParticipant
              key={p.identity}
              className="video-chat-speaker-view-header-participants-list__item"
              participant={p}
              dominant={p.identity === dominantSpeakerParticipantIdentity}
              selectedAudioOutputDevice={selectedAudioOutputDevice}
            />
          ))}
        </div>
      </div>
      <div className="video-chat-speaker-view__speaker">
        {recordingStatus && <StyledRound top={20} right={20} />}
        {dominantParticipant ? (
          <RemoteParticipant
            participant={dominantParticipant}
            displayMode={ParticipantDisplayMode.video}
            preferredVideoTrack={ParticipantVideoTrackType.screenShare}
            selectedAudioOutputDevice={selectedAudioOutputDevice}
          />
        ) : (
          <LocalParticipant
            isTest={isTest}
            participant={localParticipant}
            preferredVideoTrack={localParticipantVideoPreferred}
            videoTrack={videoTrack}
            audioTrack={audioTrack}
            screenTrack={screenTrack}
            selectedAudioOutputDevice={selectedAudioOutputDevice}
          />
        )}
      </div>
    </div>
  );
};

const participantPropType = PropTypes.shape({
  identity: PropTypes.string,
});

SpeakerView.propTypes = {
  localParticipant: participantPropType.isRequired,
  participants: PropTypes.arrayOf(participantPropType).isRequired,
  dominantSpeakerParticipant: participantPropType,
  screenShareParticipants: PropTypes.arrayOf(participantPropType).isRequired,
  videoTrack: PropTypes.shape({
    isLocalVideoEnabled: PropTypes.bool,
    setIsLocalVideoEnabled: PropTypes.func,
    videoDeviceLabel: PropTypes.string,
    switchVideoDevice: PropTypes.func,
  }).isRequired,
  audioTrack: PropTypes.shape({
    isLocalAudioEnabled: PropTypes.bool,
    setIsLocalAudioEnabled: PropTypes.func,
    audioDeviceLabel: PropTypes.string,
    switchAudioDevice: PropTypes.func,
  }).isRequired,
  screenTrack: PropTypes.shape({
    isLocalScreenEnabled: PropTypes.bool,
    setIsLocalScreenEnabled: PropTypes.func,
  }).isRequired,
  isTest: PropTypes.bool,
};

SpeakerView.defaultProps = {
  dominantSpeakerParticipant: null,
  isTest: false,
};

export default SpeakerView;
