import React from 'react';

import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import ExportIcon from 'pages/Contacts/Components/Icons/ExportIcon';

const CustomIcon = ({ handleOnClick, disabled, className, color = false, ...rest }) => (
  <IconButton
    className={className}
    disabled={disabled}
    onClick={handleOnClick}
    color={color || 'primary'}
    aria-label="export"
    component="span"
    {...rest}
  >
    {color ? (
      <ExportIcon style={{ color }} width={24} height={24} disabled={disabled} />
    ) : (
      <ExportIcon width={24} height={24} disabled={disabled} />
    )}
  </IconButton>
);

export default CustomIcon;
