import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import CircularProgress from '@material-ui/core/CircularProgress';
import orderBy from 'lodash/orderBy';

import SortOrder from '../SortOrder';
import DateFilter from '../DateFilter';
import Chat from './Chat';

import './ChatChannelsList.scss';
import { determineDarkThemedColorToUse } from 'services/contributions.service';
import useContribution from 'pages/ContributionView/hooks/useContribution';

const LOAD_MORE_CHANNELS_SCROLL_THRESHOLD = 500;

const ChatChannelsList = ({
  className,
  channels,
  showFavoriteOnly,
  searchFilter,
  sortOrder,
  dateFilter,
  onChannelClick,
  channelLinkProvider,
  isLoadingMoreChannels,
  onLoadMoreChannels,
  selectedChannels,
  onSelectedChannelsChange,
}) => {
  const contribution = useContribution();
  const isDarkThemeEnabled = determineDarkThemedColorToUse(contribution);

  const sortedChannels = useMemo(
    () =>
      orderBy(
        channels,
        [c => (c.lastMessage ? moment(c.lastMessage.timestamp).unix() : 0)],
        [sortOrder === SortOrder.newestFirst ? 'desc' : 'asc'],
      ),
    [channels, sortOrder],
  );

  const onChannelsListScroll = ({ target: { scrollTop, scrollHeight, clientHeight } }) => {
    const scrollBottom = scrollHeight - scrollTop - clientHeight;
    if (scrollBottom < LOAD_MORE_CHANNELS_SCROLL_THRESHOLD) {
      onLoadMoreChannels();
    }
  };

  const onChannelSelectedChange = (channel, selected) => {
    const newSelectedChannels = selected
      ? [...selectedChannels, channel]
      : selectedChannels.filter(sc => sc?.sid !== channel?.sid);

    onSelectedChannelsChange(newSelectedChannels);
  };

  return (
    <div className={classNames(className, 'chat-channels-list')} onScroll={onChannelsListScroll}>
      {sortedChannels.map(c => (
        <Chat
          key={c?.sid}
          className={`${isDarkThemeEnabled ? 'chat-channels-list__item_dark' : 'chat-channels-list__item'}`}
          channel={c}
          onClick={onChannelClick}
          linkProvider={channelLinkProvider}
          showFavoriteOnly={showFavoriteOnly}
          searchFilter={searchFilter}
          dateFilter={dateFilter}
          selected={selectedChannels.some(sc => sc?.sid === c?.sid)}
          onSelectedChange={selected => onChannelSelectedChange(c, selected)}
        />
      ))}
      {isLoadingMoreChannels && <CircularProgress className="chat-channels-list__load-more-channels-progress" />}
    </div>
  );
};

ChatChannelsList.propTypes = {
  className: PropTypes.string.isRequired,
  channels: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  showFavoriteOnly: PropTypes.bool.isRequired,
  searchFilter: PropTypes.string.isRequired,
  sortOrder: PropTypes.oneOf([SortOrder.newestFirst, SortOrder.oldestFirst]).isRequired,
  dateFilter: PropTypes.oneOf([DateFilter.thisWeek, DateFilter.all]).isRequired,
  onChannelClick: PropTypes.func,
  channelLinkProvider: PropTypes.func,
  isLoadingMoreChannels: PropTypes.bool.isRequired,
  onLoadMoreChannels: PropTypes.func.isRequired,
  selectedChannels: PropTypes.arrayOf(PropTypes.string).isRequired,
  onSelectedChannelsChange: PropTypes.func.isRequired,
};

ChatChannelsList.defaultProps = {
  onChannelClick: null,
  channelLinkProvider: null,
};

export default ChatChannelsList;
