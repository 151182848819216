import React from 'react';
import PropTypes from 'prop-types';

import IconButton from '@material-ui/core/IconButton';
import VisibilityIcon from '@material-ui/icons/Visibility';

const ViewButton = ({ handleClick, PLeft }) => (
  <IconButton
    style={{ padding: PLeft ? '0px' : '' }}
    color="primary"
    aria-label="video picture"
    component="span"
    onClick={handleClick}
  >
    <VisibilityIcon />
  </IconButton>
);

ViewButton.propTypes = {
  handleClick: PropTypes.func,
};

ViewButton.defaultProps = {
  handleClick: undefined,
};

export default ViewButton;
