import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import getBackPages from 'utils/getBackPages';
import useAccount from 'hooks/useAccount';
import { UserRoles } from 'helpers/constants';

const StyledBackButton = styled.span`
  margin-right: 20px;
  font-family: Avenir;
  font-size: 16px;
  font-weight: 900;
  text-decoration: none !important;
  line-height: 28px;
`;

const BackButton = ({ type }) => {
  const { currentRole } = useAccount();
  const backPages = getBackPages(type, currentRole === UserRoles.client);

  return (
    <StyledBackButton>
      <Link to={backPages?.link} style={{ textDecoration: 'none' }}>
        <ArrowBackIosIcon fontSize="small" style={{ verticalAlign: 'middle' }} />
        Back
      </Link>
    </StyledBackButton>
  );
};

BackButton.propTypes = {
  type: PropTypes.string.isRequired,
};

export default BackButton;
