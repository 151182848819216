import { createAction } from 'redux-actions';

export const SET_PAIDTIER_PLANS = 'SET_PAIDTIER_PLANS';
export const SET_ACTIVE_PAIDTIER_PLAN = 'SET_ACTIVE_PAIDTIER_PLAN';

export const SET_PAIDTIER_CONTRIBUTIONS = 'SET_PAIDTIER_CONTRIBUTIONS';
export const SET_ACTIVE_PAIDTIER_CONTRIBUTIONS = 'SET_ACTIVE_PAIDTIER_CONTRIBUTIONS';

export const setActivePaidTierPlan = createAction(SET_ACTIVE_PAIDTIER_PLAN);
export const setPaidTierPlans = createAction(SET_PAIDTIER_PLANS);

export const setPaidTierContribution = createAction(SET_PAIDTIER_CONTRIBUTIONS);
export const setActivePaidTierContributions = createAction(SET_ACTIVE_PAIDTIER_CONTRIBUTIONS);
