import React from 'react';
import PropTypes from 'prop-types';
import {
  StyledAlignmentContainer,
  StyledResourceActions,
  StyledResourceDateTime,
  StyledResourcePartition,
} from '../style';
import { getThemedColors } from 'services/contributions.service';
import { useSelector } from 'react-redux';

const ResourceDateTime = ({ startTimeMoment }) => {
  const activeContribution = useSelector(state => state.contributions?.activeContribution);
  const {
    themedColor: themedTextColor,
    themedBackgroundColor: oldThemedBackgroundColor,
    newThemedBackgroundColor,
    newThemedTextColor,
  } = getThemedColors(activeContribution);
  return (
    <StyledAlignmentContainer>
      <StyledResourceActions>
        <StyledResourceDateTime style={{ color: newThemedTextColor }}>
          {startTimeMoment.format('MMM Do YYYY')}
        </StyledResourceDateTime>
        <StyledResourcePartition style={{ color: newThemedTextColor }}>&nbsp;|&nbsp;</StyledResourcePartition>
        <StyledResourceDateTime style={{ color: newThemedTextColor }}>
          {startTimeMoment.format('h:mm a')}
        </StyledResourceDateTime>
      </StyledResourceActions>
    </StyledAlignmentContainer>
  );
};

ResourceDateTime.propTypes = {
  startTimeMoment: PropTypes.string.isRequired,
};

export default ResourceDateTime;
