import React, { useState, useCallback, useEffect, useRef } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { connect, useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import CoachImage from 'assets/chatlogo.png';
import InfoIcon from '@material-ui/icons/Info';
import { colors } from 'utils/styles';
import {
  useTheme,
  useMediaQuery,
  Radio,
  FormControlLabel,
  RadioGroup,
  FormControl,
  NativeSelect,
  MenuItem,
  OutlinedInput,
  Grid,
  Typography,
  Tooltip,
  TextField,
} from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import TemplateOne from 'assets/congratulations.png';
import * as bankAccountsService from 'services/bankAccounts.service';
import { redirectTo } from 'services/links';
import * as contributionActions from 'actions/contributions';
import * as leadMagnetActions from 'actions/leadMagnets';
import { ModalTermsAndConditions } from 'components/Modals/TermsAndConditions';
import { StyledMainSection } from 'components/Containers/CreateContributionContainer';
import MainContainer from 'components/Containers/MainContainer';
import Button from 'components/FormUI/Button';
import CommonErrorMessage from 'components/FormUI/CommonErrorMessage';
import { PageTitleSecond, BodyOrLeftText, BodyMainText } from 'components/UI/Text/TextStyles';
import { Card, CardBody, CardHeader } from 'components/UI/Card';
import { useRouter, useHttp, useAccount, usePartnerCoach, useSaveLeadMagnet } from 'hooks';
import Joyride, {
  CallBackProps,
  STATUS,
  Step,
  EVENTS,
  StoreHelpers,
  BeaconRenderProps,
  TooltipRenderProps,
} from 'react-joyride';
import { StyleLabel, StyledLink, StyledButtonContainer, StyleLabelSignature } from '../CreateContribution/style';
import Modal from '../../components/UI/Modal.js';
import styled from 'styled-components';
import { UpdateUserProgressBarData } from 'services/user.service';
import { updateUser } from 'actions/update-user';
import { ClientPreviewModeOn, ClientPreviewModeOff } from 'actions/clientpreviewmode';
import { makeStyles } from '@material-ui/core/styles';
import CrossIcon from '@material-ui/icons/Clear';
import CustomExpansionPanel from 'pages/CreateContribution/components/ExpansionPanel';
import { Switch, StyledInput, StyledSlider } from 'components/switch/style';
import LeadMagnet from 'components/Tables/LeadMagnetRow';

const StyledImg = styled.div`
  background-image: url(${({ previewImg }) => previewImg});
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  display: inline-block;
  border-radius: 40px;
  align-items: center;
  z-index: 1;
`;
const HyperLink = styled.a`
  visibility: hidden;
`;
const StyledImgCongrats = styled.div`
  background-image: url(${({ previewImg }) => previewImg});
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100px;
  height: 100px;
  display: inline-block;
  // border-radius: 40px;
  align-items: center;
  z-index: 1;
`;
const StyledCloseIcon = styled(CrossIcon)`
  margin: 5px;
  height: 15px;
  width: 15px;
  &:hover {
    cursor: pointer;
  }
`;

export const StyledSwitch = styled(Switch)`
  height: 1.25rem;
  width: 2.08331rem;
  margin: 0;
  display: flex;
  align-items: center;
  padding: 0;
`;
export const StyledToggleSwitch = styled(StyledSlider)`
  margin: 0;
  padding: 0;
  &::before {
    height: 14px;
    width: 14px;
  }
`;
export const StyledInputSwitch = styled(StyledInput)`
  &:checked + .slider::before {
    transform: translateX(13px);
  }
`;
const useStyles = makeStyles(({ mobileView }) => ({
  formControl: {
    width: '100%',
    marginTop: 0,
  },
  cardContainer: {
    width: '100%',
    border: '1px solid #E7E7E7',
    borderRadius: '4px',
  },
  cardHeader: {
    width: '100%',
    height: '50px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '10px 24px',
    borderBottom: '1px solid #E7E7E7',
  },
  description: {
    display: 'flex',
    flexDirection: 'row',
  },
  header: {
    fontFamily: 'Avenir',
    fontSize: '18px',
    fontWeight: '800',
    lineHeight: '30px',
    letterSpacing: '0.10000000149011612px',
    textAlign: 'left',
    marginBottom: '10px',
  },
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  textFieldHeader: {
    fontFamily: 'Avenir',
    fontSize: '14px',
    fontWeight: 300,
    lineHeight: '25px',
    textAlign: 'left',
    color: ' #000000DE',
  },
  cardHeaderHeading: {
    fontFamily: 'Avenir',
    fontSize: '18px',
    fontWeight: '500',
    lineHeight: '30px',
    letterSpacing: '0.1px',
  },
  cardHeaderHeadingMobile: {
    fontFamily: 'Avenir',
    fontSize: '16px',
    fontWeight: '500',
    lineHeight: '30px',
    letterSpacing: '0.1px',
  },
  cardBody: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '20px 24px',
  },
}));
function ReviewAndSubmit({ user, saveLeadMagnetToLS, error, loading, leadMagnet, saveLeadMagnet }) {
  const { history, query, domain } = useRouter();
  const anchorRef = useRef();
  const isEditing = !!query.id;
  const { request } = useHttp();
  const dispatch = useDispatch();
  const [metaAdsLandingPage, setMetaAdsLandingPage] = useState(leadMagnet?.metaPixel?.landingPageEnabled);
  const [metaAdsThankYouPage, setMetaAdsThankYouPage] = useState(leadMagnet?.metaPixel?.thankYouPageEnabled);
  const [googleAdsLandingPage, setGoogleAdsLandingPage] = useState(leadMagnet?.googleTags?.landingPageEnabled);
  const [googleAdsThankYouPage, setGoogleAdsThankYouPage] = useState(leadMagnet?.googleTags?.thankYouPageEnabled);
  const [metaAdsLandingPageContent, setMetaAdsLandingPageContent] = useState(leadMagnet?.metaPixel?.landingPagePixelId);
  const [metaAdsThankYouPageContent, setMetaAdsThankYouPageContent] = useState(
    leadMagnet?.metaPixel?.thankYouPagePixelId,
  );
  const [googleAdsLandingPageContent, setGoogleAdsLandingPageContent] = useState(
    leadMagnet?.googleTags?.landingPageTagId,
  );
  const [googleAdsThankYouPageContent, setGoogleAdsThankYouPageContent] = useState(
    leadMagnet?.googleTags?.thankYouPageTagId,
  );
  const [expanded, setExpanded] = useState(false);

  const clientPreviewMode = useSelector(state => state?.clientPreviewMode?.clientPreview);
  const errorState = useSelector(state => state.contributions.error);
  const errorOccured = useSelector(state => state?.contributions.showError);
  const [loader, setLoader] = useState(false);

  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));

  const {
    user: {
      transfersEnabled,
      isStandardAccount,
      defaultPaymentMethod,
      standardAccountTransfersEnabled,
      avatarUrl,
      email,
    },
  } = useAccount();

  const classes = useStyles();
  const [agree, setAgree] = useState(leadMagnet?.hasAgreedContributionTerms || false);

  const [errorPopUpState, seterrorPopUpState] = useState(true);

  const [showTerms, setShowTerms] = useState(false);

  const [createdRecordId, setCreatedRecordId] = useState(null);
  const [documents, setDocuments] = useState({
    termsOfUse: null,
    payment: null,
  });

  const [contibId, setContibId] = useState(null);

  const [showModal, setShowModal] = useState(false);

  const togglePopup = () => {
    setShowModal(!showModal);
  };
  const [run, setRun] = useState(!user?.userProgressbarData?.FirstContributionDone);
  const [stepIndex, setStepIndex] = useState(4);

  const [offset, setOffset] = useState(0);

  let joyRideHelpers;

  const [steps, setSteps] = useState([
    {
      spotlightClicks: true,
      disableBeacon: true,
      hideFooter: true,
      content: (
        <div className="step3">
          <div
            className="image"
            style={{
              height: '70px',
              width: '70px',
              backgroundColor: 'white',
              position: 'absolute',
              borderRadius: '100px',
              top: -45,
              left: 150,
              borderColor: '#215C73',
              borderWidth: '5px',
            }}
          >
            <StyledImg previewImg={CoachImage} />
          </div>
          <div
            style={{
              fontSize: '18px',
              color: '#215C73',
              display: 'flex',
              alignSelf: 'flex-start',
              fontWeight: 850,
              fontFamily: 'Avenir',
            }}
          >
            Add Photo
          </div>
          <div
            style={{
              color: 'black',
              fontSize: '16px',
              marginTop: '13px',
              fontWeight: 350,
              fontFamily: 'Avenir',
              textAlign: 'left',
            }}
          >
            Lorem Ipsum passages, and more recently with desktop publishing software like
          </div>
          <div className="buttons" style={{ flexDirection: 'row', display: 'flex' }}>
            <Button
              className="btn-left"
              autoWidth
              backgroundColor={'white'}
              invert
              style={{
                marginTop: '40px',
                paddingTop: '10px',
                paddingBottom: '10px',
                width: '70%',
                borderColor: '#A58B62',
                borderWidth: '1px',
                borderRadius: '5px',
                color: '#A58B62',
              }}
              onClick={() => {
                updateUserF();
                // setRun(false);
              }}
            >
              Mark as Complete
            </Button>

            <Button
              className="btn-right"
              autoWidth
              invert
              style={{
                marginTop: '40px',
                paddingTop: '10px',
                paddingBottom: '10px',
                width: '30%',
                borderColor: '#215C73',
                borderWidth: '1px',
                borderRadius: '5px',
                color: '#215C73',
                marginLeft: '10px',
              }}
              onClick={() => {
                joyRideHelpers.skip();
                // setRun(false);
              }}
            >
              Next
            </Button>
          </div>
        </div>
      ),
      placement: 'left',
      target: '#integrationTab',
    },
    {
      spotlightClicks: true,
      disableBeacon: true,
      hideFooter: true,
      content: (
        <div className="step3">
          <div
            className="image"
            style={{
              height: '70px',
              width: '70px',
              backgroundColor: 'white',
              position: 'absolute',
              borderRadius: '100px',
              top: -45,
              left: 150,
              borderColor: '#215C73',
              borderWidth: '5px',
            }}
          >
            <StyledImg previewImg={CoachImage} />
          </div>
          <div
            style={{
              fontSize: '18px',
              color: '#215C73',
              display: 'flex',
              alignSelf: 'flex-start',
              fontWeight: 850,
              fontFamily: 'Avenir',
            }}
          >
            Add Photo
          </div>
          <div
            style={{
              color: 'black',
              fontSize: '16px',
              marginTop: '13px',
              fontWeight: 350,
              fontFamily: 'Avenir',
              textAlign: 'left',
            }}
          >
            Lorem Ipsum passages, and more recently with desktop publishing software like
          </div>
          <div className="buttons" style={{ flexDirection: 'row', display: 'flex' }}>
            <Button
              className="btn-left"
              autoWidth
              backgroundColor={'white'}
              invert
              style={{
                marginTop: '40px',
                paddingTop: '10px',
                paddingBottom: '10px',
                width: '70%',
                borderColor: '#A58B62',
                borderWidth: '1px',
                borderRadius: '5px',
                color: '#A58B62',
              }}
              onClick={() => {
                updateUserF();
                // setRun(false);
              }}
            >
              Mark as Complete
            </Button>

            <Button
              className="btn-right"
              autoWidth
              invert
              style={{
                marginTop: '40px',
                paddingTop: '10px',
                paddingBottom: '10px',
                width: '30%',
                borderColor: '#215C73',
                borderWidth: '1px',
                borderRadius: '5px',
                color: '#215C73',
                marginLeft: '10px',
              }}
              onClick={() => {
                joyRideHelpers.skip();
                // setRun(false);
              }}
            >
              Next
            </Button>
          </div>
        </div>
      ),
      placement: 'left',
      target: '#integrationTab',
    },
    {
      spotlightClicks: true,
      disableBeacon: true,
      hideFooter: true,
      content: (
        <div className="step3">
          <div
            className="image"
            style={{
              height: '70px',
              width: '70px',
              backgroundColor: 'white',
              position: 'absolute',
              borderRadius: '100px',
              top: -45,
              left: 150,
              borderColor: '#215C73',
              borderWidth: '5px',
            }}
          >
            <StyledImg previewImg={CoachImage} />
          </div>
          <div
            style={{
              fontSize: '18px',
              color: '#215C73',
              display: 'flex',
              alignSelf: 'flex-start',
              fontWeight: 850,
              fontFamily: 'Avenir',
            }}
          >
            Add Photo
          </div>
          <div
            style={{
              color: 'black',
              fontSize: '16px',
              marginTop: '13px',
              fontWeight: 350,
              fontFamily: 'Avenir',
              textAlign: 'left',
            }}
          >
            Lorem Ipsum passages, and more recently with desktop publishing software like
          </div>
          <div className="buttons" style={{ flexDirection: 'row', display: 'flex' }}>
            <Button
              className="btn-left"
              autoWidth
              backgroundColor={'white'}
              invert
              style={{
                marginTop: '40px',
                paddingTop: '10px',
                paddingBottom: '10px',
                width: '70%',
                borderColor: '#A58B62',
                borderWidth: '1px',
                borderRadius: '5px',
                color: '#A58B62',
              }}
              onClick={() => {
                updateUserF();
                // setRun(false);
              }}
            >
              Mark as Complete
            </Button>

            <Button
              className="btn-right"
              autoWidth
              invert
              style={{
                marginTop: '40px',
                paddingTop: '10px',
                paddingBottom: '10px',
                width: '30%',
                borderColor: '#215C73',
                borderWidth: '1px',
                borderRadius: '5px',
                color: '#215C73',
                marginLeft: '10px',
              }}
              onClick={() => {
                joyRideHelpers.skip();
                // setRun(false);
              }}
            >
              Next
            </Button>
          </div>
        </div>
      ),
      placement: 'left',
      target: '#integrationTab',
    },
    {
      spotlightClicks: true,
      disableBeacon: true,
      hideFooter: true,
      content: (
        <div className="step3">
          <div
            className="image"
            style={{
              height: '70px',
              width: '70px',
              backgroundColor: 'white',
              position: 'absolute',
              borderRadius: '100px',
              top: -45,
              left: 150,
              borderColor: '#215C73',
              borderWidth: '5px',
            }}
          >
            <StyledImg previewImg={CoachImage} />
          </div>
          <div
            style={{
              fontSize: '18px',
              color: '#215C73',
              display: 'flex',
              alignSelf: 'flex-start',
              fontWeight: 850,
              fontFamily: 'Avenir',
            }}
          >
            Add Photo
          </div>
          <div
            style={{
              color: 'black',
              fontSize: '16px',
              marginTop: '13px',
              fontWeight: 350,
              fontFamily: 'Avenir',
              textAlign: 'left',
            }}
          >
            Lorem Ipsum passages, and more recently with desktop publishing software like
          </div>
          <div className="buttons" style={{ flexDirection: 'row', display: 'flex' }}>
            <Button
              className="btn-left"
              autoWidth
              backgroundColor={'white'}
              invert
              style={{
                marginTop: '40px',
                paddingTop: '10px',
                paddingBottom: '10px',
                width: '70%',
                borderColor: '#A58B62',
                borderWidth: '1px',
                borderRadius: '5px',
                color: '#A58B62',
              }}
              onClick={() => {
                updateUserF();
                // setRun(false);
              }}
            >
              Mark as Complete
            </Button>

            {/* <Button
              className="btn-right"
              autoWidth
              invert
              style={{
                marginTop: '40px',
                paddingTop: '10px',
                paddingBottom: '10px',
                width: '30%',
                borderColor: '#215C73',
                borderWidth: '1px',
                borderRadius: '5px',
                color: '#215C73',
                marginLeft: '10px',
              }}
              onClick={() => {
                joyRideHelpers.skip();
                setRun(false);
              }}
            >
              Next
            </Button> */}
          </div>
        </div>
      ),
      placement: 'left',
      target: '#integrationTab',
    },
    {
      spotlightClicks: true,
      disableBeacon: true,
      hideFooter: true,
      content: (
        <div className="step4">
          <div
            className="image"
            style={{
              height: '70px',
              width: '70px',
              backgroundColor: 'white',
              position: 'absolute',
              borderRadius: '100px',
              top: -45,
              left: 150,
              borderColor: '#215C73',
              borderWidth: '5px',
            }}
          >
            <StyledImg previewImg={CoachImage} />
          </div>
          <div
            style={{
              fontSize: '18px',
              color: '#215C73',
              display: 'flex',
              alignSelf: 'flex-start',
              fontWeight: 850,
              fontFamily: 'Avenir',
            }}
          >
            You’re almost done!
          </div>
          <div
            style={{
              color: 'black',
              fontSize: '16px',
              marginTop: '13px',
              fontWeight: 350,
              fontFamily: 'Avenir',
              textAlign: 'left',
            }}
          >
            In this step, you can add terms for your clients to agree to at checkout. As soon as you agree to the terms
            and submit, you’ll be ready to share this new service with your clients. Congrats!
          </div>
          <div className="buttons" style={{ flexDirection: 'row', display: 'flex' }}>
            <Button
              className="btn-left"
              autoWidth
              backgroundColor={'white'}
              invert
              style={{
                marginTop: '40px',
                paddingTop: '10px',
                paddingBottom: '10px',
                width: '100%',
                borderColor: '#A58B62',
                borderWidth: '1px',
                borderRadius: '5px',
                color: '#A58B62',
              }}
              onClick={() => {
                updateUserF();

                // setRun(false);
              }}
            >
              Mark as Complete
            </Button>

            {/* <Button
              className="btn-right"
              autoWidth
              invert
              style={{
                marginTop: '40px',
                paddingTop: '10px',
                paddingBottom: '10px',
                width: '30%',
                borderColor: '#215C73',
                borderWidth: '1px',
                borderRadius: '5px',
                color: '#215C73',
                marginLeft: '10px',
              }}
              onClick={() => {
                joyRideHelpers.skip();
                setRun(false);
              }}
            >
              Next
            </Button> */}
          </div>
        </div>
      ),
      placement: 'left',
      target: '#reviewAndSubmit',
    },
    // {
    //   hideFooter: true,
    //   disableBeacon: true,
    //   content: (
    //     <div>
    //       <StyledImgCongrats previewImg={TemplateOne}></StyledImgCongrats>
    //       <div style={{ fontSize: '24px', color: '#215C73', fontWeight: 800 }}>CONGRATS!</div>
    //       <div style={{ color: '#A58B62', fontSize: '16px', marginTop: '13px', fontWeight: 450, fontFamily: 'Avenir' }}>
    //         You are fully set up & ready to transform lives!
    //       </div>
    //       <Button
    //         autoWidth
    //         backgroundColor={'#C9B382'}
    //         style={{
    //           marginTop: '40px',
    //           paddingTop: '10px',
    //           paddingBottom: '10px',
    //         }}
    //         onClick={() => {
    //           joyRideHelpers.skip();
    //         }}
    //       >
    //         Let’s do this!
    //       </Button>
    //     </div>
    //   ),
    //   placement: 'center',
    //   target: 'body',
    // },
  ]);

  const updateUserF = async () => {
    let obj = {
      id: user.id,
      userProgressbarData: {
        ...user.userProgressbarData,
        FirstContributionDone: true,
      },
      progressBarPercentage: user.progressBarPercentage,
    };

    await UpdateUserProgressBarData(obj).then(res => {
      let u = {
        ...user,
        userProgressbarData: { ...res.userProgressbarData },
        progressBarPercentage: res.progressBarPercentage,
      };
      dispatch(updateUser(u));
      if (res.progressBarPercentage == 100) {
        setShowModal(true);
      }
      if (res.userProgressbarData?.IsPlanPurchased === false) {
        history.push('/account/payment', { from: 'verify' });
      } else if (res?.userProgressbarData?.IsBankAccountConnected === false) {
        history.push('/account/payment', { from: 'bankAccount' });
      } else if (res?.userProgressbarData?.IntegrationsDone === false) {
        history.push('/account/integrations', { from: 'bankAccount' });
      } else if (res?.userProgressbarData?.IsProfileUploaded === false) {
        history.push('/account/profile');
      } else if (res?.userProgressbarData?.FirstContributionDone === false) {
        dispatch(contributionActions.setPopUp(true));
        history.push('/dashboard');
      } else if (res?.userProgressbarData?.IsWebsiteAdded === false) {
        history.push('/account/profile', { from: 'Manage Your Website Information' });
      } else if (res?.userProgressbarData?.InviteLink === false) {
        contibId === null ? console.log('Helloo') : history.push(`contribution-view/${contibId}/about`);
      }
    });
    joyRideHelpers.skip();
  };

  useEffect(() => {
    if (!!user?.userProgressbarData?.FirstContributionDone) {
      setSteps([]);
    }

    if (isEditing === true) {
      setRun(false);
    }
  }, []);

  const getHelpers = helpers => {
    joyRideHelpers = helpers;
  };

  const handleJoyrideCallback = data => {
    const { status, type, index } = data;
    const nextStepIndex = index + 1;

    const finishedStatuses = ['finished', 'skipped'];
    if (finishedStatuses.includes(status)) {
      setRun(false);
    }
    if ([EVENTS.STEP_AFTER].includes(type)) {
      setStepIndex(nextStepIndex);
    }
    if (data.action === 'close') {
      setRun(false);
    }

    console.groupCollapsed(type);
    console.groupEnd();
  };

  const [connectStripeModal, setConnectStripeModal] = useState(false);
  const handleConnectStripeModalClose = useCallback(() => setConnectStripeModal(false), [connectStripeModal]);

  useEffect(() => {
    request('/ReferenceData/GetAgreements').then(response => {
      const termsOfUse = response.find(doc => doc.agreementType === 'TermsOfUse');
      const payment = response.find(doc => doc.agreementType === 'Payment');
      setDocuments({ termsOfUse, payment });
    });
  }, [request]);

  const redirect = useCallback(
    (action, savedRecord) => {
      if (action.type === leadMagnetActions.UPDATE_LEAD_MAGNET_SUCCESS) {
        setCreatedRecordId(savedRecord?.id);
      }

      if (action.type === leadMagnetActions.CREATE_LEAD_MAGNET_SUCCESS) {
        saveLeadMagnetToLS({});
        setCreatedRecordId(savedRecord?.id);
      }
    },
    [createdRecordId],
  );

  const { onSave } = useSaveLeadMagnet(
    true,
    agree,
    // ,isThankyouPageActive,
    // thankyouPage,
  );
  const onSubmit = () => {
    let metaPixel = null;
    let googleTags = null;
    if (metaAdsLandingPage && metaAdsThankYouPage) {
      metaPixel = {
        landingPageEnabled: metaAdsLandingPage,
        landingPagePixelId: metaAdsLandingPageContent,
        thankYouPageEnabled: metaAdsThankYouPage,
        thankYouPagePixelId: metaAdsLandingPageContent,
      };
    } else if (metaAdsLandingPage === true && (metaAdsThankYouPage === false || metaAdsThankYouPage === undefined)) {
      metaPixel = {
        landingPageEnabled: metaAdsLandingPage,
        landingPagePixelId: metaAdsLandingPageContent,
        thankYouPageEnabled: false,
        thankYouPagePixelId: '',
      };
    } else if ((metaAdsLandingPage === false || metaAdsLandingPage === undefined) && metaAdsThankYouPage === true) {
      metaPixel = {
        thankYouPageEnabled: metaAdsThankYouPage,
        thankYouPagePixelId: metaAdsLandingPageContent,
        landingPageEnabled: false,
        landingPagePixelId: '',
      };
    }
    if (googleAdsLandingPage && googleAdsThankYouPage) {
      googleTags = {
        landingPageEnabled: googleAdsLandingPage,
        landingPageTagId: googleAdsLandingPageContent,
        thankYouPageEnabled: googleAdsThankYouPage,
        thankYouPageTagId: googleAdsLandingPageContent,
      };
    } else if (
      googleAdsLandingPage === true &&
      (googleAdsThankYouPage === false || googleAdsThankYouPage === undefined)
    ) {
      googleTags = {
        landingPageEnabled: googleAdsLandingPage,
        landingPageTagId: googleAdsLandingPageContent,
        thankYouPageEnabled: false,
        thankYouPageTagId: '',
      };
    } else if (
      (googleAdsLandingPage === false || googleAdsLandingPage === undefined) &&
      googleAdsThankYouPage === true
    ) {
      googleTags = {
        thankYouPageEnabled: googleAdsThankYouPage,
        thankYouPageTagId: googleAdsLandingPageContent,
        landingPageEnabled: false,
        landingPageTagId: '',
      };
    }

    let leadMagnetData = {
      ...leadMagnet,
      metaPixel: metaPixel,
      googleTags: googleTags,
    };
    onSave(leadMagnetData, redirect);
  };

  const goTOClientPreviewMode = () => {
    dispatch(ClientPreviewModeOn(`/edit-contribution/${leadMagnet?.id}/review`));
    if (anchorRef?.current) {
      anchorRef.current.click();
    }
  };

  const backClickHandler = useCallback(() => {
    if (query.id) {
      // history.push(`/edit-lead-magnet/${query.id}/content`);
      history.push(`/edit-lead-magnet/${query.id}/content`);
    } else {
      // history.push('/create-lead-magnet/content');
      history.push('/create-lead-magnet/content');
    }
  }, [history, query.id]);

  const handleConnectStripeModalSubmit = useCallback(
    () =>
      bankAccountsService
        .verifyStripeAccount()
        .then(({ link }) => redirectTo(link))
        .catch(err => console.dir(err)),
    [],
  );
  const onVerificate = useCallback(() => {
    request('api/Payment/generate-account-verification-link?forStandardAccount=true', 'POST').then(({ link }) => {
      redirectTo(link);
    });
  }, [request]);
  const showMetaAds =
    email.includes('support@onlinemedicalentrepreneur.com') ||
    email.includes('syafiqkusni@gmail.com') ||
    email.includes('hello@jpeckenergycoach.com');

  if (createdRecordId) {
    return <Redirect to={`/lead-magnet-view/${createdRecordId}/landingpage`} />;
  }

  return (
    <MainContainer>
      <>
        {error?.message ||
          (error?.Error?.ExceptionName == 'ArgumentNullException' &&
            error.Error.Message ==
              "Class Name: Microsoft.AspNetCore.Routing.EndpointRoutingMiddleware - Method Name: System.Threading.Tasks.Task Invoke(Microsoft.AspNetCore.Http.HttpContext) - OuterException: Value cannot be null. (Parameter 'refreshToken') \n InnerException: " && (
              <div
                className="sticky"
                style={{
                  top: offset >= 110 ? 0 : 110,
                  width: '45%',
                  backgroundColor: 'white',
                  marginInline: '20%',
                  border: '1px solid #DBE7F2',
                  borderRadius: '4px',
                  zIndex: 1,
                  transitionDuration: '1s',
                }}
              >
                <div style={{ padding: '15px 10px 10px 15px' }}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      width: '100%',
                      justifyContent: 'space-between',
                      alignItems: 'flex-end',
                    }}
                  >
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <InfoIcon style={{ transform: 'rotateX(180deg)' }} htmlColor={colors.darkOceanBlue} />
                      <div
                        style={{
                          fontFamily: 'Avenir',
                          fontWeight: '700',
                          fontSize: '16px',
                          lineHeight: '24px',
                          color: '#1E1E1E',
                          marginInline: '10px',
                        }}
                      >
                        Before you proceed...
                      </div>
                    </div>
                    <StyledCloseIcon
                      onClick={() => {
                        seterrorPopUpState(false);
                        // dispatch(contributionActions.setErrorPopUp(false));
                      }}
                      fontSize="10px"
                      style={{ color: 'black' }}
                    />
                  </div>
                </div>
              </div>
            ))}
        <Joyride
          // tooltipComponent={Tooltip}
          callback={handleJoyrideCallback}
          continuous={true}
          getHelpers={getHelpers}
          run={run}
          stepIndex={stepIndex}
          showProgress={false}
          showSkipButton={false}
          steps={steps}
          styles={{
            options: {
              primaryColor: '#CDBA8F',
              zIndex: 10000000,
            },
          }}
        />
        <StyledMainSection mobileView={mobileView}>
          <Card id={'reviewAndSubmit'} mobileView={mobileView}>
            <CardHeader mobileView={mobileView}>
              <PageTitleSecond mobileView={mobileView}>4. Review and Submit</PageTitleSecond>
            </CardHeader>
            <CardBody>
              <BodyMainText>
                {user.firstName}, fantastic job on crafting this new Lead Magnet! Once you click submit, you'll be able
                to preview your landing page instantly and receive a shareable link, empowering you to start inviting
                new leads to join right away.
              </BodyMainText>
              <BodyMainText>
                Please note, you can come back to edit any portion of the Lead Magnet, except for the lead magnet type.
                If you’re not sure you want to submit yet, feel free to “Save & Exit” to save it as a draft to come back
                to.
              </BodyMainText>

              {showMetaAds && (
                <CustomExpansionPanel
                  summaryTitle={
                    <div className="d-flex align-items-center" style={{ columnGap: '1rem' }}>
                      <div className={mobileView ? classes.cardHeaderHeadingMobile : classes.cardHeaderHeading}>
                        Are you using ads? (Optional)
                      </div>
                    </div>
                  }
                  panelHeight={mobileView ? '' : '50px'}
                  panelPadding="10px 24px 10px 24px"
                  customStyle={{ padding: '30px 24px' }}
                  expand={expanded}
                >
                  <Grid lg={12} xs={12} sm={12}>
                    <div className={classes.container}>
                      <div className={classes.header}>
                        Meta Ads Pixel (Facebook)
                        <Tooltip
                          title="Optimize user interactions seamlessly with your Meta Pixel ID integration. Retrieve your Pixel ID and code from the Meta Ads Manager under 'Pixels' within 'Data Sources' in your Events Manager."
                          arrow
                        >
                          <InfoIcon htmlColor={colors.lightBrown} style={{ marginLeft: '8px', marginBottom: '5px' }} />
                        </Tooltip>
                      </div>
                      <div className={classes.description}>
                        <Grid lg={6} sm={12} xs={12}>
                          <StyleLabelSignature mobileView={mobileView}>
                            <div
                              style={{ fontFamily: 'Avenir', fontSize: '18px', fontWeight: 500, color: '#000000DE' }}
                            >
                              Landing Page
                            </div>
                            <div style={{ marginLeft: '8px' }}>
                              <StyledSwitch className="switch" style={{}}>
                                <StyledInputSwitch
                                  type="checkbox"
                                  onChange={() => {
                                    setMetaAdsLandingPage(!metaAdsLandingPage);
                                  }}
                                  checked={metaAdsLandingPage}
                                />
                                <StyledToggleSwitch className="slider round" />
                              </StyledSwitch>
                            </div>
                          </StyleLabelSignature>
                          {metaAdsLandingPage === true && (
                            <div>
                              <div className={classes.textFieldHeader}>Meta Pixel Code</div>
                              <TextField
                                fullWidth
                                variant="outlined"
                                multiline
                                onChange={e => {
                                  setMetaAdsLandingPageContent(e.target.value);
                                }}
                                style={{ color: 'black', marginBottom: '10px', width: '90%' }}
                                placeholder="Add Pixel Code"
                                minRows={7}
                                value={metaAdsLandingPageContent}
                              />
                            </div>
                          )}
                        </Grid>
                        <Grid lg={6} sm={12} xs={12}>
                          <StyleLabelSignature mobileView={mobileView}>
                            <div
                              style={{ fontFamily: 'Avenir', fontSize: '18px', fontWeight: 500, color: '#000000DE' }}
                            >
                              Thank You Page
                            </div>
                            <div style={{ marginLeft: '8px' }}>
                              <StyledSwitch className="switch" style={{}}>
                                <StyledInputSwitch
                                  type="checkbox"
                                  onChange={() => {
                                    setMetaAdsThankYouPage(!metaAdsThankYouPage);
                                  }}
                                  checked={metaAdsThankYouPage}
                                />
                                <StyledToggleSwitch className="slider round" />
                              </StyledSwitch>
                            </div>
                          </StyleLabelSignature>
                          {metaAdsThankYouPage === true && (
                            <div>
                              <div className={classes.textFieldHeader}>Meta Pixel Code</div>
                              <TextField
                                fullWidth
                                variant="outlined"
                                multiline
                                onChange={e => {
                                  setMetaAdsThankYouPageContent(e.target.value);
                                }}
                                style={{ color: 'black', marginBottom: '10px', width: '90%' }}
                                placeholder="Add Pixel Code"
                                minRows={7}
                                value={metaAdsThankYouPageContent}
                              />
                            </div>
                          )}
                        </Grid>
                      </div>
                    </div>
                    <div style={{ borderTop: '1px solid #DFE3E4', width: '100%', height: '1px', marginTop: '20px' }} />
                    <div style={{ marginTop: '20px' }} className={classes.container}>
                      <div className={classes.header}>
                        Google Tags
                        <Tooltip
                          title="Boost your marketing strategy with Google Tags Pixel ID for precise tracking and insights. Get your code from Google Tag Manager's 'Tags' section."
                          arrow
                        >
                          <InfoIcon htmlColor={colors.lightBrown} style={{ marginLeft: '8px', marginBottom: '5px' }} />
                        </Tooltip>
                      </div>
                      <div className={classes.description}>
                        <Grid lg={6} sm={12} xs={12}>
                          <StyleLabelSignature mobileView={mobileView}>
                            <div
                              style={{ fontFamily: 'Avenir', fontSize: '18px', fontWeight: 500, color: '#000000DE' }}
                            >
                              Landing Page
                            </div>
                            <div style={{ marginLeft: '8px' }}>
                              <StyledSwitch className="switch" style={{}}>
                                <StyledInputSwitch
                                  type="checkbox"
                                  onChange={() => {
                                    setGoogleAdsLandingPage(!googleAdsLandingPage);
                                  }}
                                  checked={googleAdsLandingPage}
                                />
                                <StyledToggleSwitch className="slider round" />
                              </StyledSwitch>
                            </div>
                          </StyleLabelSignature>
                          {googleAdsLandingPage === true && (
                            <div>
                              <div className={classes.textFieldHeader}>Google Tag</div>
                              <TextField
                                fullWidth
                                variant="outlined"
                                multiline
                                onChange={e => {
                                  setGoogleAdsLandingPageContent(e.target.value);
                                }}
                                style={{ color: 'black', marginBottom: '10px', width: '90%' }}
                                placeholder="Add Google Tag"
                                minRows={7}
                                value={googleAdsLandingPageContent}
                              />
                            </div>
                          )}
                        </Grid>
                        <Grid lg={6} sm={12} xs={12}>
                          <StyleLabelSignature mobileView={mobileView}>
                            <div
                              style={{ fontFamily: 'Avenir', fontSize: '18px', fontWeight: 500, color: '#000000DE' }}
                            >
                              Thank You Page
                            </div>
                            <div style={{ marginLeft: '8px' }}>
                              <StyledSwitch className="switch" style={{}}>
                                <StyledInputSwitch
                                  type="checkbox"
                                  onChange={() => {
                                    setGoogleAdsThankYouPage(!googleAdsThankYouPage);
                                  }}
                                  checked={googleAdsThankYouPage}
                                />
                                <StyledToggleSwitch className="slider round" />
                              </StyledSwitch>
                            </div>
                          </StyleLabelSignature>
                          {googleAdsThankYouPage === true && (
                            <div>
                              <div className={classes.textFieldHeader}>Google Tag</div>
                              <TextField
                                fullWidth
                                variant="outlined"
                                multiline
                                onChange={e => {
                                  setGoogleAdsThankYouPageContent(e.target.value);
                                }}
                                style={{ color: 'black', marginBottom: '10px', width: '90%' }}
                                placeholder="Add Google Tag"
                                minRows={7}
                                value={googleAdsThankYouPageContent}
                              />
                            </div>
                          )}
                        </Grid>
                      </div>
                    </div>
                  </Grid>
                </CustomExpansionPanel>
              )}

              <div className={classes.cardContainer}>
                <div className={classes.cardHeader}>
                  <div className={mobileView ? classes.cardHeaderHeadingMobile : classes.cardHeaderHeading}>
                    Terms & Conditions
                  </div>
                </div>
                <div className={classes.cardBody}>
                  <BodyMainText style={{ width: '100%' }}>
                    If you have any questions, feel free to email us at&nbsp;
                    <StyledLink href="mailto:support@cohere.live">support@cohere.live</StyledLink> or review the&nbsp;
                    <StyledLink
                      href="https://www.cohere.live/privacy-and-terms/user-agreement/"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Terms of Service.
                    </StyledLink>
                    <StyleLabel mobileView={mobileView}>
                      <Checkbox
                        id="create-lead-magnet-agreement"
                        color="primary"
                        checked={agree}
                        onChange={e => setAgree(e.target.checked)}
                      />
                      <a
                        href="/"
                        onClick={e => {
                          e.preventDefault();
                          setShowTerms(true);
                        }}
                      >
                        I agree to the Terms of Service
                      </a>
                    </StyleLabel>
                  </BodyMainText>
                </div>
              </div>
              {error?.Error?.ExceptionName == 'ZoomException' &&
                error.Error.Message !=
                  'Class Name: Microsoft.AspNetCore.Routing.EndpointRoutingMiddleware - Method Name: System.Threading.Tasks.Task Invoke(Microsoft.AspNetCore.Http.HttpContext) - OuterException: Invalid access token. \n InnerException: ' && (
                  <CommonErrorMessage
                    message={
                      'You have exceeded the daily rate limit (100) of Meeting Create/Update API requests permitted for this particular user.'
                    }
                  />
                )}
              {error?.message && (
                <CommonErrorMessage
                  message={
                    error?.message === 'Your availability time frame windows are overlapping. Please fix this on step 3'
                      ? 'Your availability time frame windows are overlapping. Please fix this on step 3'
                      : 'Please complete all required details to submit.'
                  }
                />
              )}
            </CardBody>
          </Card>

          <StyledButtonContainer>
            <Button invert autoWidth type="button" onClick={backClickHandler}>
              Back
            </Button>
            <div>
              <HyperLink
                style={{ display: 'none' }}
                ref={anchorRef}
                href={`https://${domain}/lead-magnet-view/${leadMagnet?.id}/clientpreview/landingpage`}
                target="_blank"
              />
              <Button autoWidth clientPreviewBtn onClick={goTOClientPreviewMode} disabled={loading || !agree}>
                Client Preview
              </Button>
              <Button autoWidth onClick={onSubmit} disabled={loading || !agree || loader}>
                Submit
              </Button>
            </div>
          </StyledButtonContainer>
        </StyledMainSection>
      </>
      <ModalTermsAndConditions showTerms={showTerms} onCancel={() => setShowTerms(false)} />

      <Modal
        isOpen={connectStripeModal}
        onCancel={handleConnectStripeModalClose}
        brandingColor={false}
        onSubmit={() => {
          leadMagnet?.paymentType === 'Advance' ? onVerificate() : handleConnectStripeModalSubmit();
        }}
        hiddenCancel
        title="Account Verification Required"
        submitTitle="Verify"
      >
        Please verify your information for your Lead Magnet to be approved.
      </Modal>
      <Modal
        title=""
        isOpen={showModal}
        onCloseText="OK"
        hiddenCancel
        onSubmit={togglePopup}
        onCancel={togglePopup}
        disableFooter
      >
        <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
          <StyledImgCongrats previewImg={TemplateOne}></StyledImgCongrats>
          <div style={{ fontSize: '24px', color: '#215C73', fontWeight: 800 }}>CONGRATS!</div>
          <div style={{ color: '#A58B62', fontSize: '16px', marginTop: '13px', fontWeight: 450, fontFamily: 'Avenir' }}>
            You are fully set up & ready to transform lives!
          </div>
          <Button
            autoWidth
            backgroundColor={'#C9B382'}
            style={{
              marginTop: '40px',
              paddingTop: '10px',
              paddingBottom: '10px',
              marginBottom: '10px',
            }}
            onClick={togglePopup}
          >
            Let’s do this!
          </Button>
        </div>
      </Modal>
    </MainContainer>
  );
}

ReviewAndSubmit.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string,
    firstName: PropTypes.string,
  }).isRequired,
  loading: PropTypes.bool.isRequired,
  saveLeadMagnetToLS: PropTypes.func.isRequired,
  saveLeadMagnet: PropTypes.func.isRequired,
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      message: PropTypes.string,
    }),
  ]),
};

ReviewAndSubmit.defaultProps = {
  error: null,
};

const mapStateToProps = ({ account, leadMagnets }) => ({
  user: account?.user,
  error: leadMagnets?.error,
  loading: leadMagnets?.loading,
  leadMagnet: leadMagnets.activeLeadMagnet,
});

const actions = {
  saveLeadMagnetToLS: leadMagnetActions.saveDraftLeadMagnet,
  saveLeadMagnet: leadMagnetActions.saveLeadMagnet,
};

export default connect(mapStateToProps, actions)(ReviewAndSubmit);
