import React from 'react';
import reactCSS from 'reactcss';
import { SketchPicker } from 'react-color';

class ButtonExample extends React.Component {
  state = {
    displayColorPicker: false,
    color: this.props.colorCode,
  };

  handleClick = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker });
  };

  handleClose = color => {
    this.setState({ displayColorPicker: false });
    this.props.onchangevalue(this.state.color);
  };

  handleChange = color => {
    this.setState({ color: color.hex });
    // this.props.onchangevalue(color.hex);
  };

  render() {
    const styles = reactCSS({
      default: {
        color: {
          width: '36px',
          height: '14px',
          borderRadius: '2px',
          background: this.state.color,
        },
        swatch: {
          padding: '5px',
          background: '#fff',
          borderRadius: '1px',
          boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
          display: 'inline-block',
          cursor: 'pointer',
        },
        popover: {
          position: 'absolute',
          zIndex: '2',
          // right: '30px',
          left: this.props.isLeadMagnet && this.props.mobileView ? 300 : 550,
          top: 150,
        },
        popoverprofile: {
          position: 'absolute',
          zIndex: '2',
          right: '30px',
        },
        cover: {
          position: 'fixed',
          top: '0px',
          right: '0px',
          bottom: '0px',
          left: '0px',
        },
      },
    });

    return (
      <div>
        <div style={styles.swatch} onClick={this.handleClick}>
          <div
            style={{
              width: '36px',
              height: '14px',
              borderRadius: '2px',
              background: `${this.props.colorCode}`,
            }}
          />
        </div>
        {this.state.displayColorPicker ? (
          <div style={window.location.href.includes('profile') ? styles.popoverprofile : styles.popover}>
            <div style={styles.cover} onClick={this.handleClose} />
            <SketchPicker color={this.state.color} onChange={this.handleChange} />
          </div>
        ) : null}
      </div>
    );
  }
}

export default ButtonExample;
