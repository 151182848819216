import axiosInstance from 'utils/axiosInstance';
import { get } from 'lodash/fp';

const getCommunitySection = contributionId =>
  axiosInstance.get(`/Post/GetCommunitySectionAndGroups/?contributionId=${contributionId}`).then(get('data'));

const createOrEditCommunitySection = data => axiosInstance.post('/Post/CreateCommunitySection', data).then(get('data'));

const updateCommunitySectionOrder = data =>
  axiosInstance.post('/Post/UpdateCommunitySectionsOrder', data).then(get('data'));

const deleteCommunitySection = data => axiosInstance.post('/Post/DeleteSection', data).then(get('data'));

const updateCommunityGroupOrder = data =>
  axiosInstance.post('/Post/UpdateCommunityGroupsOrder', data).then(get('data'));

const moveGroupFromOneSectionToOther = data =>
  axiosInstance.post('/Post/MoveGroupFromOneSectionToOther', data).then(get('data'));

const createCommunityGroup = data => axiosInstance.post('/Post/CreateCommunityGroup', data).then(get('data'));

const getSuggestedCommunityGroups = () => axiosInstance.get('/Post/GetSuggestedCommunityGroup').then(get('data'));

const addLinksInGroup = data => axiosInstance.post('/Post/AddLinksInGroup', data).then(get('data'));

const deleteLinksInGroup = ({ linkId, groupId }) =>
  axiosInstance.delete(`/Post/RemoveLinkFromGroup?linkId=${linkId}&groupId=${groupId}`).then(get('data'));

const deleteResourcesInGroup = ({ contributionId, groupId, documentId, deleteFromS3Only = false, fileExtension }) =>
  axiosInstance
    .delete(
      `/Post/DeleteResourcesForCommunityGroupAndResourceTab?contributionId=${contributionId}&groupId=${groupId}&docId=${documentId}${
        deleteFromS3Only
          ? '&fileExtension='.concat(fileExtension).concat('&deleteFromS3Only=').concat(deleteFromS3Only)
          : ''
      }`,
    )
    .then(get('data'));

const getCommunityGroupResources = ({ contributionId, groupId }) =>
  axiosInstance
    .get(`/Post/GetResourcesForCommunityGroup?contribId=${contributionId}${groupId ? '&groupId='.concat(groupId) : ''}`)
    .then(get('data'));

const getCommunityGroupLinks = ({ groupId }) =>
  axiosInstance.get(`/Post/GetLinksByGroupId?${groupId ? '&groupId='.concat(groupId) : ''}`).then(get('data'));

const muteOrUnmuteGroupNotifications = (groupId, isMuted) =>
  axiosInstance.post(`/Post/MuteGroupNotifications?groupId=${groupId}&isMuted=${isMuted}`).then(get('data'));

export {
  getCommunitySection,
  createOrEditCommunitySection,
  updateCommunitySectionOrder,
  deleteCommunitySection,
  updateCommunityGroupOrder,
  moveGroupFromOneSectionToOther,
  createCommunityGroup,
  getSuggestedCommunityGroups,
  addLinksInGroup,
  deleteLinksInGroup,
  deleteResourcesInGroup,
  getCommunityGroupResources,
  getCommunityGroupLinks,
  muteOrUnmuteGroupNotifications,
};
