import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { fetchDiscountCodes } from 'actions/discountCodes';

import {
  discountCodesSelector,
  discountCodeSelector,
  discountCodesLoadingSelector,
  discounCodesErrorSelector,
} from 'selectors/discountCodes';

const useDiscountCodes = () => {
  const dispatch = useDispatch();

  const codes = useSelector(discountCodesSelector);
  const code = useSelector(discountCodeSelector);
  const loading = useSelector(discountCodesLoadingSelector);
  const error = useSelector(discounCodesErrorSelector);

  const getCodeById = id => {
    return codes.find(code => code.id === id);
  };

  useEffect(() => {
    fetchDiscountCodes(dispatch);
  }, [fetchDiscountCodes]);

  return {
    codes,
    code,
    loading,
    error,
    getCodeById,
  };
};

export default useDiscountCodes;
