import * as React from 'react';
import MUIStepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel, { stepLabelClasses } from '@mui/material/StepLabel';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { styled } from '@mui/material/styles';
import classes from './Stepper.module.scss';
import { useHistory, useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';
import useRouter from 'hooks/useRouter';

const labelBackgroundColor = '#c9b382';
const notActiveLabelBackgroundColor = '#DFE3E4';
const notActiveColor = '#4A4A4A';
const pendingBackgroundColor = '#F5F5F5';

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
    left: 'calc(-50% + 18px) !important',
    right: 'calc(50% + 18px) !important',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: labelBackgroundColor,
    },
    [`& .${stepConnectorClasses.root}`]: {},
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: labelBackgroundColor,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 2,
    margin: '0px 6px',
    border: 0,
    backgroundColor: notActiveLabelBackgroundColor,
    borderRadius: 1,
  },
}));

const QontoStepIconRoot = styled('div')(({ ownerState }) => {
  return {
    color: notActiveColor,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '10px',
    height: '50px',
    width: '50px',
    border: `3px solid ${notActiveLabelBackgroundColor}`,
    backgroundColor: `${pendingBackgroundColor}`,
    borderRadius: '47px',
    fontSize: '24px',
    fontWeight: '500',
    fontFamily: 'Avenir',
    ...(ownerState.completed && {
      color: 'white',
      backgroundColor: labelBackgroundColor,
      border: '0px',
      fontWeight: '900',
    }),
    ...(ownerState.active && {
      color: labelBackgroundColor,
      backgroundColor: 'white',
      fontWeight: '900',
      border: `3px solid ${labelBackgroundColor}`,
    }),
  };
});

function QontoStepIcon(props) {
  const { active, completed, className } = props;

  const icons = {
    1: 1,
    2: 2,
    3: 3,
    4: 4,
    5: 5,
  };

  return (
    <QontoStepIconRoot ownerState={{ active, completed }} className={className}>
      {icons[String(props.icon)]}
    </QontoStepIconRoot>
  );
}

const ColorlibStepLabel = styled(StepLabel)(() => ({
  [`& .${stepLabelClasses.label}`]: {
    //   [`&.${stepLabelClasses.completed}`]: {
    //     color: 'rgb(255, 255, 255, 0.3)',
    //   },
    [`&.${stepLabelClasses.active}`]: {
      color: labelBackgroundColor,
      fontWeight: '500',
    },
    color: 'rgba(74,74,74,1)',
    fontSize: '18px',
    fontWeight: '500',
    fontFamily: 'Avenir',
  },
}));

const Stepper = ({ activeStep, handleSubmit, ...props }) => {
  const steps = ['Details', 'Template', 'Contacts', 'Design', 'Summary'];
  const { path } = useRouteMatch();
  const { query } = useRouter();
  const isEditing = !!query?.id;
  const splittedPath = path.split('/');
  const pathname = splittedPath[1];
  const history = useHistory();

  return (
    <>
      <div className={classes.container}>
        <MUIStepper activeStep={activeStep} alternativeLabel connector={<ColorlibConnector />} {...props}>
          {steps.map((label, index) => {
            return (
              <Step key={label}>
                <ColorlibStepLabel
                  onClick={() => {
                    if (path.includes(label.toLowerCase()) === false) {
                      handleSubmit({
                        to: isEditing
                          ? `/${pathname}/${query?.id}/${label.toLowerCase()}`
                          : `/${pathname}/${label.toLowerCase()}`,
                        isGoingTo: index,
                      });
                    }
                  }}
                  StepIconComponent={QontoStepIcon}
                  classes={{
                    alternativeLabel: classes.alternativeLabel,
                  }}
                >
                  {label}
                </ColorlibStepLabel>
              </Step>
            );
          })}
        </MUIStepper>
      </div>
    </>
  );
};
export default Stepper;
