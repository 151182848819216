import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Route, Redirect, Switch } from 'react-router-dom';
import { useTheme, useMediaQuery } from '@material-ui/core';
import MainContainer from './MainContainer';
import AllCampaigns from 'pages/EmailCampaigns/AllCampaigns/AllCampaigns';
import { fetchAllEmailCampaignsAsync } from 'actions/campaign';
import { useDispatch } from 'react-redux';
import useEmailMarketing from 'pages/CreateCampaign/Hooks/useEmailMarketing';
import useAccount from 'hooks/useAccount';
import { fetchDomains } from 'actions/emailMarketing.actions';
import { fetchAllContactsAsync } from 'actions/contacts';
import Clients from 'pages/Contacts/Clients/Clients';

const StyledMainSection = styled.div`
  padding: ${({ mobileView, showIntroPage }) => (mobileView ? '30px 5px' : showIntroPage ? '16px' : '30px 35px')};
`;

function EmailCampaignsContainer({ match: { path } }) {
  const theme = useTheme();
  const { user } = useAccount();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const { verifiedSendingAddresses } = useEmailMarketing();
  const isSendGridConnected = user?.isSendGridConnected;
  const dispatch = useDispatch();
  const showIntroPage = !isSendGridConnected || verifiedSendingAddresses?.length === 0;

  useEffect(() => {
    dispatch(fetchDomains());
  }, []);

  useEffect(() => {
    dispatch(fetchAllContactsAsync({}));
  }, []);

  useEffect(() => {
    dispatch(fetchAllEmailCampaignsAsync());
  }, []);

  return (
    <MainContainer>
      <StyledMainSection mobileView={mobileView} showIntroPage={showIntroPage}>
        <Switch>
          <Route path={`${path}/campaigns`} exact component={AllCampaigns} />
          <Route path={`${path}/clients`} exact component={Clients} />
          <Redirect to={`${path}/campaigns`} />
        </Switch>
      </StyledMainSection>
    </MainContainer>
  );
}

EmailCampaignsContainer.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string,
    url: PropTypes.string,
    isExact: PropTypes.bool,
    params: PropTypes.object,
  }).isRequired,
};

export default EmailCampaignsContainer;
