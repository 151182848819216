import useRouter from './useRouter';
import { useEffect, useState } from 'react';

const useApplyContributionView = () => {
  const { pathname } = useRouter();
  const [isApplyContributionView, setIsApplyContributionView] = useState(pathname?.includes('apply-contribution-view'));
  useEffect(() => {
    if (pathname) {
      setIsApplyContributionView(pathname?.includes('apply-contribution-view'));
    }
  }, [pathname]);
  return { isApplyContributionView };
};

export default useApplyContributionView;
