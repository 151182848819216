import { handleActions } from 'redux-actions';

import { LOG_OUT } from 'actions/user';
import { startTestVideoChat, endTestVideoChat } from 'actions/testVideoChart';

const initialState = {
  showTestVideoChat: false,
};

export const showTestVideoChatSelector = state => state.testVideoChart.showTestVideoChat;

export default handleActions(
  {
    [startTestVideoChat]: state => ({ ...state, showTestVideoChat: true }),
    [endTestVideoChat]: state => ({ ...state, showTestVideoChat: false }),
    [LOG_OUT]: () => ({ ...initialState }),
  },
  initialState,
);
