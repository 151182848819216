import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { toRem, colors, devices } from 'utils/styles';
import { NavLink } from 'react-router-dom';
import { useTheme, useMediaQuery } from '@material-ui/core';

import { useRouter } from 'hooks';
import { ContributionType, JOURNEY_ROUTES } from 'helpers/constants';
import useAccount from 'hooks/useAccount';
import { getClientPurchases } from 'services/contributions.service';
import find from 'lodash/find';

export const StyledClientJourneyHeaderWrapper = styled.div`
  background-color: ${colors.white};
  // border: solid 1px ${colors.darkWhite};
  box-shadow: 0 0px 4px 0 rgba(157, 157, 157, 0.3);
  padding: 0px 55px;

  ${({ mobileView }) => mobileView && `padding: 0px 16px;`}
  ${({ mobileView, TopHeader }) => mobileView && TopHeader && `padding: 0px 0px;`}
`;

const StyledClientJourneyHeaderContainer = styled.div`
  // padding: 0 16px;
  margin: 0 auto;

  @media screen and (${devices.desktop}) {
    // padding: 0 60px;
  }

  ${({ mobileView }) => mobileView && `padding-left: 0px;`}
`;

export const StyledNavLink = styled(NavLink)`
  text-decoration: none;
  font-size: ${toRem(16)};
  line-height: 1;
  font-weight: 900;
  color: ${colors.darkGray};
  padding: 16px 32px;
  display: inline-block;
  position: relative;

  &.active {
    color: ${colors.darkOceanBlue};
  }

  &.active:after {
    content: '';
    width: 100%;
    height: 4px;
    background-color: ${colors.darkOceanBlue};
    position: absolute;
    bottom: 0;
    left: 0;
  }

  ${({ mobileView }) => {
    return (
      mobileView &&
      `
        padding: 16px 15px;
      `
    );
  }}

  ${({ firstLink }) => firstLink && `padding-left: 16px;`}

  ${({ MyJourney, mobileView }) =>
    MyJourney &&
    `
    padding: 16px 0px;  
    margin-right: 20px;
    font-weight: bold;
    
    &.active:after {
      height: ${mobileView ? '4px' : '2px'};
    }
  `}
  ${({ MyJourney, mobileView }) => MyJourney && mobileView && `padding: 16px 16px;`}
`;

const ClientJourneyHeader = ({ tabClick }) => {
  const {
    match: { url },
  } = useRouter();
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'));
  const [isHasMembership, setIsHasMembership] = useState(false);
  const [isHasOneToOne, setIsHasOneToOne] = useState(false);
  const [isHasCourse, setIsCourse] = useState(false);
  const [isHasWorkshop, setIsWorkshop] = useState(false);

  const { user } = useAccount();

  const filterByType = contribs => {
    setIsHasMembership(find(contribs, { type: ContributionType.contributionMembership }));
    setIsHasOneToOne(find(contribs, { type: ContributionType.contributionOneToOne }));
    setIsCourse(find(contribs, { type: ContributionType.contributionCourse, isWorkshop: false }));
    setIsWorkshop(find(contribs, { type: ContributionType.contributionCourse, isWorkshop: true }));
  };

  useEffect(() => {
    getClientPurchases(user.id, false).then(filterByType);
  }, []);
  return (
    <StyledClientJourneyHeaderWrapper mobileView={mobileView} TopHeader>
      <StyledClientJourneyHeaderContainer mobileView={mobileView}>
        <StyledNavLink
          to={`${url}/${JOURNEY_ROUTES.SESSIONS}`}
          onClick={() => tabClick(JOURNEY_ROUTES.SESSIONS)}
          mobileView={mobileView}
          MyJourney
        >
          Sessions
        </StyledNavLink>
        <StyledNavLink
          to={`${url}/${JOURNEY_ROUTES.COACHES}`}
          onClick={() => tabClick(JOURNEY_ROUTES.COACHES)}
          mobileView={mobileView}
          MyJourney
        >
          Coaches
        </StyledNavLink>
        {isHasMembership && (
          <StyledNavLink
            to={`${url}/${JOURNEY_ROUTES.MEMBERSHIP}`}
            onClick={() => tabClick(JOURNEY_ROUTES.MEMBERSHIP)}
            mobileView={mobileView}
            MyJourney
          >
            Memberships
          </StyledNavLink>
        )}
        {isHasOneToOne && (
          <StyledNavLink
            to={`${url}/${JOURNEY_ROUTES.ONE_TO_ONE}`}
            onClick={() => tabClick(JOURNEY_ROUTES.ONE_TO_ONE)}
            mobileView={mobileView}
            MyJourney
          >
            1:1s
          </StyledNavLink>
        )}
        {isHasCourse && (
          <StyledNavLink
            to={`${url}/${JOURNEY_ROUTES.LIVE}`}
            onClick={() => tabClick(JOURNEY_ROUTES.LIVE)}
            mobileView={mobileView}
            MyJourney
          >
            Group Courses
          </StyledNavLink>
        )}
        {isHasWorkshop && (
          <StyledNavLink
            to={`${url}/${JOURNEY_ROUTES.WORKSHOP}`}
            onClick={() => tabClick(JOURNEY_ROUTES.WORKSHOP)}
            mobileView={mobileView}
            MyJourney
          >
            Workshops
          </StyledNavLink>
        )}
      </StyledClientJourneyHeaderContainer>
    </StyledClientJourneyHeaderWrapper>
  );
};

export default ClientJourneyHeader;
