import { store } from 'react-notifications-component';
import 'animate.css/animate.min.css';
import { useCallback } from 'react';

const useNotifications = () => {
  const notification = {
    type: 'default',
    insert: 'top',
    container: 'bottom-right',
    animationIn: ['animate__animated animate__fadeIn'], // `animate.css v4` classes
    animationOut: ['animate__animated animate__fadeOut'], // `animate.css v4` classes
    dismiss: {
      duration: 3000,
    },
  };

  const successMessage = useCallback((title = 'Success', message = 'Request completed successfully') => {
    store.addNotification({
      ...notification,
      title,
      message,
      type: 'success',
    });
  }, []);
  const errorMessage = useCallback((title = 'Error', message = 'Something went wrong') => {
    store.addNotification({
      ...notification,
      title,
      message,
      type: 'danger',
    });
  }, []);
  const infoMessage = useCallback((title = 'Info', message = '') => {
    store.addNotification({
      ...notification,
      title,
      message,
      type: 'info',
    });
  }, []);

  return { successMessage, errorMessage, infoMessage };
};

export default useNotifications;
