import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import Grid from '@material-ui/core/Grid';

import * as contributionService from '../../../services/contributions.service';
import { ContributionType } from 'helpers/constants';
import MembershipItem from './components/MembershipItem';
import Loader from '../../../components/UI/Loader';
import * as purchaseService from '../../../services/purchase.service.js';
import { updateSubscriptionInfo } from '../../../actions/contributions.js';
import { INFO_MODAL_TYPES } from '../../../components/Modals/InfoModal/constants.js';
import { MEMBERSHIP_STATUSES } from '../../../constants.js';
import { setActivePaidTierContributions } from 'actions/paidTierOptions';
import { getAcademy } from 'services/paidTierOptions.service';

const AccountMemberships = () => {
  const [memberships, setMemberships] = useState([]);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    contributionService
      .getBoughtByType(ContributionType.contributionMembership)
      .then(contributions => {
        setMemberships(contributions);
      })
      .then(() => {
        return getAcademy();
      })
      .then(res => {
        dispatch(setActivePaidTierContributions(res));
        setLoading(false);
      })
      .catch(console.error);
  }, []);

  const handleCancelMembershipClick = useCallback(
    id => {
      purchaseService
        .cancelMembership(id)
        .then(() =>
          setMemberships(prevState =>
            prevState.map(membership =>
              membership.id === id
                ? {
                    ...membership,
                    subscriptionStatus: { ...membership.subscriptionStatus, status: MEMBERSHIP_STATUSES.CANCEL },
                  }
                : membership,
            ),
          ),
        )
        .catch(console.error);
    },
    [memberships, setMemberships],
  );

  if (loading) {
    return <Loader />;
  }

  return (
    <Grid container xs={12}>
      {memberships.map(membership => (
        <MembershipItem
          key={membership.id}
          membership={membership}
          onCancelSubscription={handleCancelMembershipClick}
        />
      ))}
    </Grid>
  );
};

export default AccountMemberships;
