import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { ClientJourneyContribution } from 'services/dataContracts/contributions';
import CommonContributionsList from '../../../ContributionsList';

import './ContributionsList.scss';

const ContributionsList = ({ className, title, contributions, sortKeySelector, sortOrder, empty }) => {
  return (
    <div className={classNames(className, 'client-journey-upcoming-contributions-list')}>
      <p className="client-journey-upcoming-contributions-list__title">{title}</p>

      <CommonContributionsList contributions={contributions} sortKeySelector={sortKeySelector} sortOrder={sortOrder} />
      {contributions.length === 0 && empty ? empty : null}
    </div>
  );
};

ContributionsList.propTypes = {
  className: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  contributions: PropTypes.arrayOf(PropTypes.instanceOf(ClientJourneyContribution)).isRequired,
  sortKeySelector: PropTypes.func,
  sortOrder: PropTypes.string,
};

ContributionsList.defaultProps = {
  sortKeySelector: c => c.sessionTime.unix(),
  sortOrder: 'asc',
};

export default ContributionsList;
