const Monad = data => ({
  map: fn => Monad(fn(data)),
  ap: monad => monad.map(data),
  chain: cb => cb(data),
  join: () => data,
  return: value => value || data,
});

const mapTo = val => () => val;
const provide = val => val;

export { Monad, mapTo, provide };
