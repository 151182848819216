import React from 'react';
import PropTypes from 'prop-types';

function SvgHandler({
  height = null,
  width = null,
  pathD,
  viewBox,
  className = '',
  onClick = null,
  fill = 'black',
  style,
}) {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox={viewBox}
      onClick={onClick}
      style={style || { margin: 'auto' }}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path fill={fill} d={pathD} />
    </svg>
  );
}
SvgHandler.propTypes = {
  height: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  pathD: PropTypes.string.isRequired,
  viewBox: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  fill: PropTypes.string.isRequired,
  style: PropTypes.shape({}),
};

SvgHandler.defaultProps = {
  style: null,
};

export default SvgHandler;
