import { createAction, createActions } from 'redux-actions';
import { getTestimonialTemplates, getTestimonials } from 'services/testimonials.service';

export const { createTestimonialAction } = createActions('CREATE_TESTIMONIAL');

export const getTestimonialsAction = createAction('GET_TESTIMONIALS');

export const getTestimonialTemplatesAction = createAction('GET_TESTIMONIAL_TEMPLATES');

export const fetchTestimonials = contributionId => async dispatch => {
  try {
    const response = await getTestimonials(contributionId);
    dispatch(getTestimonialsAction(response));
  } catch (error) {
    console.dir(error);
  }
};

export const fetchTestimonialTemplates = () => async dispatch => {
  try {
    const response = await getTestimonialTemplates();
    dispatch(getTestimonialTemplatesAction(response));
  } catch (error) {
    console.dir(error);
  }
};
