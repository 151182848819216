import React from 'react';
import { CircularProgress, Grid } from '@material-ui/core';
import NotificationCard from 'components/NotificationCard/NotificationCard';
import { useLoadContributions } from './hooks/useLoadContributions';
import useAccount from 'hooks/useAccount';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Moment from 'moment';
import { fetchUpcomingCreated } from 'actions/contributions';
import { UserRoles } from 'helpers/constants';
import { getClientContribution, getClientPurchases, getCohealerContribution } from 'services/contributions.service';

const sortContributionsByRecentFirst = data => {
  return data.sort((a, b) => {
    return new Moment(b.createTime).valueOf() - new Moment(a.createTime).valueOf();
  });
};
export const CommunityPage = () => {
  const selector = state => ({
    authorAvatarUrl: state?.upcomingCreated?.upcomingCreated?.authorAvatarUrl || '',
    contributionsForTable: state?.upcomingCreated?.upcomingCreated?.contributionsForTable,
  });

  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const { authorAvatarUrl, contributionsForTable } = useSelector(selector);
  const { currentRole, user } = useAccount();
  const { id: userId } = user;
  const isCohealer = currentRole === UserRoles.cohealer;
  const dispatch = useDispatch();
  // Step 1: load contributions by role
  useEffect(() => {
    if (currentRole === UserRoles.cohealer) {
      dispatch(fetchUpcomingCreated(userId)).then(res => {
        if (res === undefined) {
          setData([]);
        }
      });
    }

    if (currentRole === UserRoles.client) {
      getClientPurchases(userId)
        // Step 2 (for client): map contributions for a client
        .then(mappedContributions => {
          const sortedArray = sortContributionsByRecentFirst(mappedContributions);
          setData(sortedArray);
        })
        .catch(responseError => {
          setError(responseError);
        });
    }
  }, [currentRole, dispatch, userId]);

  // Step 2 (for coach): map contributions for a coach
  useEffect(() => {
    if (currentRole === UserRoles.cohealer) {
      if (contributionsForTable?.length > 0) {
        setData(sortContributionsByRecentFirst(contributionsForTable.map(item => ({ ...item }))));
      } else if (contributionsForTable === undefined) {
        setData(null);
      } else {
        setData([]);
      }
    }
  }, [contributionsForTable, currentRole]);

  if (error) {
    return 'Error loading contributions.';
  }

  return data == null ? (
    <Grid style={{ justifyContent: 'center' }} container spacing={2}>
      <CircularProgress />
    </Grid>
  ) : data.length === 0 ? (
    <Grid container spacing={2}>
      {currentRole === UserRoles.cohealer ? (
        <div>You currently don’t have any communities set up on your coach account.</div>
      ) : (
        <div>You currently don’t have any communities set up on your client account.</div>
      )}
    </Grid>
  ) : isCohealer ? (
    <Grid container spacing={2}>
      {data.map(item =>
        item?.isCommunityFeatureHiddden === false ? (
          <Grid item key={item.id}>
            <NotificationCard contribution={item} />
          </Grid>
        ) : (
          <></>
        ),
      )}
    </Grid>
  ) : (
    <Grid container spacing={2}>
      {data.map(item =>
        item?.isAccessRevokedByCoach === false &&
        item.isSelfRemoved === false &&
        (item?.isCommunityFeatureHiddden === false || item?.isCommunityFeatureHiddden === undefined) ? (
          <Grid item key={item.id}>
            <NotificationCard contribution={item} />
          </Grid>
        ) : item?.isCommunityFeatureHiddden === true ? (
          <></>
        ) : item?.isAccessRevokedByCoach === false && item.isSelfRemoved === true ? (
          <></>
        ) : item?.isAccessRevokedByCoach === true && item.isSelfRemoved === false ? (
          <></>
        ) : (
          item?.isAccessRevokedByCoach === true && item.isSelfRemoved === true && <></>
        ),
      )}
    </Grid>
  );
};
