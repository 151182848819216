import React from 'react';
import styled, { css } from 'styled-components';
import { Video } from 'components/Video/Video';

const StyledBody = styled.div`
  padding: 20px 0px;
  display: flex;
  justify-content: center;
  div.MuiGrid-container {
    width: fit-content;
    max-width: 100%;
    img {
      /* min-width: fit-content; */
      background-color: transparent;
      border: none;
    }
  }
`;

const StyledImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2.232px;
  border: 1.018px solid #dadada;
`;
const SelfPacedImage = ({ session, sessionTime, sessionTimeIndex }) => {
  return (
    <StyledBody>
      <Video
        style={{ width: '100%' }}
        isPreviewMode
        isSessionView
        session={session}
        sessionTime={sessionTime}
        sessionTimeIndex={sessionTimeIndex}
      />
    </StyledBody>
  );
};

export default SelfPacedImage;
