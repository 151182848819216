import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Popover from '@material-ui/core/Popover'
import Slider from '@material-ui/core/Slider'

import './VolumeSlider.scss'

const VolumeSlider = ({ className, children, volume, onVolumeChange }) => {
  const anchorRef = useRef()
  const [isOpen, setIsOpen] = useState(false)
  const actualVolume = Math.round(volume * 100)

  return (
    <>
      <div
        ref={anchorRef}
        className={classNames(className, 'video-chat-side-panel-participant-volume-slider-target')}
        onClick={() => setIsOpen(true)}
      >
        {children}
      </div>
      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={isOpen}
        onClose={() => setIsOpen(false)}
      >
        <div className="video-chat-side-panel-participant-volume-slider">
          <Slider value={actualVolume} onChange={(_, newVolume) => onVolumeChange(newVolume / 100)} />
          <p className="video-chat-side-panel-participant-volume-slider__volume-level">{actualVolume}%</p>
        </div>
      </Popover>
    </>
  )
}

VolumeSlider.propTypes = {
  className: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  volume: PropTypes.number.isRequired,
  onVolumeChange: PropTypes.func.isRequired,
}

export default VolumeSlider
