import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import ShareModal from 'pages/ContributionView/components/ShareModal/ShareModalContainer';
import { shareViaEmail } from 'services/contributions.service';
import axiosInstance from 'utils/axiosInstance';
import useRouter from 'hooks/useRouter';
import { addhttp } from 'utils/utils';
import ContributionViewMainContainer from './ContributionViewMainContainer';
import Loader from 'components/UI/Loader';
import { isEmpty, isEqual } from 'lodash';
import { memo } from 'react';

function ContributionViewContainer(props) {
  const {
    match: {
      params: { id },
    },
  } = props;

  const [modalOpened, setModalOpened] = useState(false);
  const [emailsError, setEmailsError] = useState({
    isError: false,
    errorMessage: null,
  });
  const [loadingDomainName, setLoadingDomainName] = useState(true);
  const activeContribution = useSelector(state => state.contributions?.activeContribution);
  const { subdomain, parentDomain, pathname, history, query, location } = useRouter();

  const handleModalClose = useCallback(() => {
    setEmailsError({
      isError: false,
      errorMessage: null,
    });
    setModalOpened(false);
  }, [setModalOpened]);

  const handleShareButtonClick = useCallback(e => {
    e.preventDefault();
    setModalOpened(true);
  }, []);

  const getApiData = useCallback(
    () => axiosInstance.get(`/ProfilePage/GetProfileLinkNameByContributionId?ContributionId=${id}`),
    [id],
  );

  useEffect(() => {
    if (subdomain) {
      setLoadingDomainName(false);
      return;
    }
    getApiData()
      .then(res => {
        if (res.data) {
          if (query?.isPurchased) {
            window.location.replace(addhttp(`${res.data}.${parentDomain}${pathname}?isPurchased=true`));
          } else {
            window.location.replace(
              addhttp(`${res.data}.${parentDomain}${pathname.concat(location.search).concat(location.hash)}`),
            );
          }
        } else {
          setLoadingDomainName(false);
        }
      })
      .catch(() => {
        setLoadingDomainName(false);
      });
  }, [id]);

  const handleModalSubmit = useCallback(
    (emails, priceId) => {
      shareViaEmail(
        priceId === null
          ? { contributionId: id, emailAddresses: emails }
          : { contributionId: id, emailAddresses: emails, priceId: priceId },
      )
        .then(() => {
          handleModalClose();
        })
        .catch(err => {
          setEmailsError({
            isError: true,
            errorMessage: err?.response?.data?.message,
          });
        });
    },
    [handleModalClose, id],
  );

  useEffect(() => {
    if (
      !isEmpty(activeContribution) &&
      (!activeContribution?.applicationFormViewModel || activeContribution?.applicationFormViewModel?.isDeleted) &&
      pathname.includes('apply-contribution-view')
    ) {
      const newPath = pathname.replace('apply-contribution-view', 'contribution-view');
      history.replace(newPath);
    }
  }, [activeContribution]);

  if (loadingDomainName) {
    return <Loader />;
  }

  return (
    <>
      <ContributionViewMainContainer handleShareButtonClick={handleShareButtonClick}>
        {modalOpened && (
          <ShareModal
            isOpen={modalOpened}
            onSubmit={handleModalSubmit}
            onModalClose={handleModalClose}
            emailsError={emailsError}
            setEmailsError={setEmailsError}
            contributionId={id}
            paymentInfo={activeContribution?.paymentInfo}
            isInvoiced={activeContribution?.isInvoiced}
            symbol={activeContribution?.defaultSymbol}
            type={activeContribution?.type}
          />
        )}
      </ContributionViewMainContainer>
    </>
  );
}

ContributionViewContainer.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string,
    url: PropTypes.string,
    isExact: PropTypes.bool,
    params: PropTypes.object,
  }).isRequired,
};

export default ContributionViewContainer;
// export default memo(ContributionViewContainer, (prev, next) => isEqual(prev, next));
