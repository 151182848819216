import React from 'react'
import PropTypes from 'prop-types'

import Participant from './Participant'
import ParticipantDisplayMode from './ParticipantDisplayMode'
import { useNetworkQualityLevel } from '../../../hooks/participants'

const TestLocalParticipant = React.forwardRef(
  ({ participant, videoTrack, audioTrack, screenTrack, selectedAudioOutputDevice, ...restProps }, ref) => {
    const isAudioEnabled = audioTrack.isLocalAudioEnabled
    const isScreenEnabled = screenTrack.isLocalScreenEnabled
    const isVideoEnabled = videoTrack.isLocalVideoEnabled

    const networkQualityLevel = useNetworkQualityLevel(participant)

    return (
      <Participant
        {...restProps}
        ref={ref}
        identity={participant.identity}
        networkQualityIndicator={networkQualityLevel}
        audioTrack={audioTrack.localAudioTrack}
        isAudioEnabled={isAudioEnabled}
        audioVolume={0}
        videoTrack={isVideoEnabled ? videoTrack.localVideoTrack : undefined}
        isVideoEnabled={isVideoEnabled}
        isVideoMirrored
        screenTrack={screenTrack.localScreenTrack}
        isScreenEnabled={isScreenEnabled}
        displayMode={ParticipantDisplayMode.video}
        audioMediaStreamTrack={audioTrack.audioMediaStreamTrack}
        selectedAudioOutputDevice={selectedAudioOutputDevice}
      />
    )
  }
)

TestLocalParticipant.propTypes = {
  participant: PropTypes.shape({
    identity: PropTypes.string,
  }).isRequired,
  videoTrack: PropTypes.shape({
    localVideoTrack: PropTypes.shape({
      attach: PropTypes.func,
      detach: PropTypes.func,
    }),
    isLocalVideoEnabled: PropTypes.bool,
    setIsLocalVideoEnabled: PropTypes.func,
    videoDeviceLabel: PropTypes.string,
    switchVideoDevice: PropTypes.func,
  }).isRequired,
  audioTrack: PropTypes.shape({
    isLocalAudioEnabled: PropTypes.bool,
    setIsLocalAudioEnabled: PropTypes.func,
    audioDeviceLabel: PropTypes.string,
    switchAudioDevice: PropTypes.func,
    audioMediaStreamTrack: PropTypes.shape({}).isRequired,
    localAudioTrack: PropTypes.shape({}).isRequired,
  }).isRequired,
  screenTrack: PropTypes.shape({
    localScreenTrack: PropTypes.shape({
      attach: PropTypes.func,
      detach: PropTypes.func,
    }),
    isLocalScreenEnabled: PropTypes.bool,
    setIsLocalScreenEnabled: PropTypes.func,
  }).isRequired,
}

export default TestLocalParticipant
