import React, { useState, useCallback } from 'react';
import { makeStyles } from '@material-ui/core';

import {
  //   StyledCommentName,
  StyledMessage,
  StyledMetaData,
  StyledCommentWrap,
  StyledContainerComment,
  //   AvatarComponentComment,
  AvatarComponent,
  StyledInputWrapper,
  StyledTextAreaWrap,
  StyledTextArea,
  StyledTextError,
  StyledButton,
  StyledClickDropDown,
  StyledName,
  StyledDate,
  StyledProfileLink,
  StyleTextLink,
  StyledText,
} from '../Post/Post.styled';
import Linkify from 'react-linkify';
import { colors } from 'utils/styles';
import useContribution from '../../../hooks/useContribution';
import ClickDropDown, { StyledDropDownItem } from '../../../../../components/UI/ClickDropDown';
import Grid from '@material-ui/core/Grid';
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects';
import { Box, CircularProgress, useMediaQuery, useTheme } from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import { mdiHeart, mdiMessage, mdiDeleteOutline, mdiDotsVertical, mdiPencilOutline, mdiDotsHorizontal } from '@mdi/js';
import Icon from '../../../../../components/UI/Icon';
import some from 'lodash/some';
import { Formik } from 'formik';
import { determineColorToUse, getThemedColors } from 'services/contributions.service';
import * as Yup from 'yup';
import { addComment, notifyTaggedUsers } from 'services/community.service';
import EditCommentModal from '../EditModal/EditCommentModal';
import { removeComment } from 'services/community.service';
import useDiffDate from '../../hooks/useDiffDate';
import { useRouter, usePartnerCoach, useAccount, useUnifiedCommunity } from '../../../../../hooks';
import chatService from 'services/chat.service';
import UserMentionInput from 'components/UI/mentions/UserMentionInput';
import { useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import uniq from 'lodash/uniq';
import { TemplateType } from 'helpers/constants/templateType';
import { ContributionType } from 'helpers/constants';
import { ColorSchemeService } from 'services/color.service';
import { determineDarkThemedColorToUse } from 'services/contributions.service';
import { addhttp, lightOrDark } from 'utils/utils';
import LinkPreview from '../LinkPreview/LinkPreview';
import { map } from 'lodash';

const ROW_HEIGHT = 20;

const Comment = ({
  commentData,
  isCoach,
  handleToggleCommentLike,
  user,
  allComments,
  fetchPosts,
  generateHTMLText,
  pods = [],
  defaultTextareaValue = '',
  contributionData,
}) => {
  const { isUnifiedCommunity } = useUnifiedCommunity();
  const contrib = useContribution();
  const contribution = isUnifiedCommunity ? contributionData : contrib;
  const theme = useTheme();
  const [comment, setComment] = useState(commentData);
  const [showCommentInput, setShowCommentInput] = useState(false);
  const [commentPostPending, setCommentPostPending] = useState(false);
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'));
  const isMyComment = user?.id === comment?.userInfo?.id;
  const [modalOpened, setModalOpened] = useState(false);
  const { createDataDiff } = useDiffDate(comment?.createTime, user?.timeZoneId);
  const { history, domain } = useRouter();

  // const { partnerCoaches } = usePartnerCoach(contribution?.id);
  const podIdPrefix = 'podId-';
  const contributionAuthor = useSelector(state => state.cohealerInfo.info);
  const [userMentions, setUserMentions] = useState([]);
  const [resetUserMentionInputValue, setResetUserMentionInputValue] = useState(false);
  const [textareaValue, setTextareaValue] = useState(defaultTextareaValue);
  const { currentRole } = useAccount();
  const activeTemplate = contribution?.activeTemplate || TemplateType.TemplateThree;
  const type = contribution?.type;
  let colorToUse = determineColorToUse(isUnifiedCommunity ? {} : contribution);
  const isDarkThemeEnabled = determineDarkThemedColorToUse(isUnifiedCommunity ? {} : contribution);
  const { themedColor, themedCardBackgroundColor, themedBackgroundColor } = getThemedColors(
    isUnifiedCommunity ? {} : contribution,
  );

  const useStyles = makeStyles(theme => ({
    MainDivStyled: {
      display: 'flex',
      justifyContent: 'space-between',
      backgroundColor: '#fafafa',
      borderRadius: '5px',
      padding: '8px',
      /* margin: '10px 0px', */
    },
  }));
  const classes = useStyles();
  // const brandingColors = contribution?.brandingColors;
  // const isCustomBrandingColorsActive = contribution?.isCustomBrandingColorsActive;
  // const shouldChangeColor = type != ContributionType.contributionOneToOne;
  // const customBtnColor = isCustomBrandingColorsActive
  //   ? brandingColors?.PrimaryColorCode
  //   : activeTemplate === TemplateType.TemplateOne
  //   ? '#6999CA'
  //   : '#D08ACD';

  const btnColor = colorToUse.PrimaryColorCode;
  const PrimaryColor = colorToUse.PrimaryColorCode;
  // const btnColor = ColorSchemeService().PrimaryColorCode;
  const textColor =
    colorToUse?.TextColorCode === 'Auto'
      ? lightOrDark(colorToUse?.PrimaryColorCode)
      : colorToUse?.TextColorCode === '#000000'
      ? '#000000'
      : '#FFFFFF';
  const users = [
    ...(contribution?.participants?.map(x => ({
      imageUrl: x.avatarUrl,
      display: `@${x.firstName} ${x.lastName}`,
      id: x.id,
    })) || []),
    ...(contribution?.contributionPartners?.map(
      x =>
        ({
          imageUrl: x.avatarUrl,
          display: `@${x.firstName} ${x.lastName}`,
          id: x.userId,
        } || []),
    ) || []),
    ...(pods?.map(x => ({ id: `${podIdPrefix}${x.id}`, display: x.name })) || []),
  ];
  if (user.id === contribution?.userId || contribution?.contributionPartners?.some(x => x.userId == user.id)) {
    users.push({
      imageUrl: null,
      display: `channel`,
      id: 'channelId',
    });
  }
  if (contributionAuthor && !users.some(e => e.id === contributionAuthor.id)) {
    users.push({
      imageUrl: contributionAuthor.avatarUrl,
      display: `@${contributionAuthor.firstName} ${contributionAuthor.lastName}`,
      id: contributionAuthor.id,
    });
  }
  const handleKeyUp = useCallback((plainText, mentions) => {
    setTextareaValue(plainText);
    setUserMentions(mentions);
  });

  const handleToggleCommentInput = () => {
    setShowCommentInput(!showCommentInput);
  };

  const handleCommentCreate = (e, { resetForm }) => {
    if (comment) {
      setCommentPostPending(true);
      addComment({
        text: textareaValue,
        postId: comment?.postId,
        parentCommentId: comment?.id,
      })
        .then(data => {
          resetForm({});
          data.ident = comment?.ident + 1;
          comment.childComments.push(data);
          setCommentPostPending(false);
          setShowCommentInput(false);
        })
        .then(async () => {
          if (!isEmpty(userMentions)) {
            let mentionedUserIds;

            if (userMentions.some(e => e.id === 'channelId')) {
              mentionedUserIds = uniq([
                ...(contribution?.participants?.map(x => x.id) || []),
                ...(contribution?.contributionPartners?.map(x => x.userId) || []),
                ...pods?.flatMap(podItem => podItem.clientIds),
                contribution?.userId,
              ]);
            } else {
              mentionedUserIds = uniq([
                ...(userMentions?.filter(x => !x.id.includes(podIdPrefix))?.map(x => x.id) || []),
                ...(userMentions
                  .filter(x => x.id.includes(podIdPrefix))
                  .flatMap(x =>
                    pods.filter(pod => `${podIdPrefix}${pod.id}` === x.id).flatMap(podItem => podItem.clientIds),
                  ) || []),
              ]).filter(x => isEmpty(x));
            }

            await notifyTaggedUsers({
              mentionAuthorUserName: `${user.firstName} ${user.lastName}`,
              message: textareaValue,
              contributionName: contribution?.title,
              mentionedUserIds,
              replyLink: window.location.href,
            });
          }
          setTextareaValue('');
          setResetUserMentionInputValue(true);
        });
    }
  };

  const handleClickEdit = useCallback(() => {
    setModalOpened(true);
  }, [setModalOpened]);
  const handleModalClose = useCallback(() => {
    setModalOpened(false);
  }, [setModalOpened]);

  const handleRemoveComment = () => {
    removeComment(comment?.id).then(() => {
      fetchPosts();
    });
  };

  const onStartDirectChat = useCallback(
    participantId => {
      window.location.href = addhttp(domain?.concat(`/conversations/all?chatId=${participantId}`));
    },
    [domain],
  );
  return (
    <>
      {comment && (
        <StyledCommentWrap
          style={
            isUnifiedCommunity
              ? {
                  borderRadius: '14px',
                  background: 'white',
                  boxShadow: '0 9px 24px 14px rgba(0,0,0,0.02)',
                  marginBottom: '20px',
                  padding: '20px 20px 25px 20px ',
                }
              : {
                  borderRadius: '14px',
                  background: themedBackgroundColor,
                  boxShadow: '0 9px 24px 14px rgba(0,0,0,0.02)',
                  marginBottom: '20px',
                  padding: '20px 20px 25px 20px ',
                }
          }
          ident={comment?.ident}
        >
          {/* <StyledCommentName>
            {comment?.userInfo?.firstName} {comment?.userInfo?.lastName}{' '}
          </StyledCommentName> */}
          <Box component="div" display="flex">
            <Grid container>
              <Grid item sm={1} xs={2}>
                <StyledProfileLink
                  isMyPost={isMyComment}
                  onClick={() => onStartDirectChat(comment?.userInfo?.id)}
                  isDarkThemeEnabled={isDarkThemeEnabled}
                >
                  <AvatarComponent
                    alt={`${comment?.userInfo?.firstName}${comment?.userInfo?.lastName}`}
                    src={comment?.userInfo?.avatarUrl}
                    mobileView={mobileView}
                    small={true}
                  >
                    {comment?.userInfo?.firstName[0]}
                    {comment?.userInfo?.lastName[0]}
                  </AvatarComponent>
                </StyledProfileLink>
              </Grid>
              <Grid item sm={11} xs={10}>
                <Box
                  style={
                    isUnifiedCommunity
                      ? { background: 'white' }
                      : { background: themedCardBackgroundColor, color: themedColor }
                  }
                  component="div"
                  className={classes.MainDivStyled}
                >
                  {/* <StyledName> */}
                  <div>
                    {' '}
                    <StyledProfileLink
                      isMyPost={isMyComment}
                      onClick={() => onStartDirectChat(comment?.userInfo?.id)}
                      isDarkThemeEnabled={isDarkThemeEnabled}
                    >
                      <StyledName>
                        {comment?.userInfo?.firstName} {comment?.userInfo?.lastName}
                      </StyledName>
                    </StyledProfileLink>
                    <StyledText
                      style={isUnifiedCommunity ? { opacity: '90%' } : { color: themedColor, opacity: '90%' }}
                    >
                      <Linkify
                        componentDecorator={(decoratedHref, decoratedText, key) => (
                          <StyleTextLink target="blank" href={decoratedHref} key={key}>
                            {decoratedText}
                          </StyleTextLink>
                        )}
                      >
                        {!isEmpty(comment?.text) &&
                          comment?.text
                            ?.split('\n')
                            .map(str => <div dangerouslySetInnerHTML={{ __html: generateHTMLText(str) }} />)}
                      </Linkify>
                    </StyledText>
                  </div>

                  <div id="HHHHHHH">
                    <Box>
                      {(isMyComment || isCoach) && (
                        <StyledClickDropDown noMarginLeft={true}>
                          <ClickDropDown icon={mdiDotsHorizontal}>
                            {isMyComment && (
                              <StyledDropDownItem
                                style={
                                  isUnifiedCommunity
                                    ? { backgroundColor: 'white' }
                                    : { color: themedColor, backgroundColor: themedBackgroundColor }
                                }
                                onClick={handleClickEdit}
                              >
                                <Icon
                                  color={isUnifiedCommunity ? 'black' : themedColor}
                                  path={mdiPencilOutline}
                                  right="5"
                                  size={0.9}
                                />
                                Edit
                              </StyledDropDownItem>
                            )}
                            {(isMyComment || isCoach) && (
                              <StyledDropDownItem
                                style={
                                  isUnifiedCommunity
                                    ? { backgroundColor: 'white' }
                                    : { color: themedColor, backgroundColor: themedBackgroundColor }
                                }
                                onClick={handleRemoveComment}
                              >
                                <Icon
                                  color={isUnifiedCommunity ? 'black' : themedColor}
                                  path={mdiDeleteOutline}
                                  right="5"
                                  size={0.9}
                                />
                                Delete
                              </StyledDropDownItem>
                            )}
                          </ClickDropDown>
                        </StyledClickDropDown>
                      )}
                    </Box>
                  </div>
                  {/* </StyledName> */}
                  {/* <StyledDate>{createDataDiff}</StyledDate> */}
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Grid container>
            <Grid item md={1} xs={1}></Grid>
            <Grid item md={11} xs={11}>
              <StyledMetaData
                style={{
                  position: 'relative',
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'left',
                  marginBottom: '10px',
                }}
                isComment={true}
              >
                <StyledMessage onClick={handleToggleCommentInput} className="inline-block">
                  <StyledDate style={{ paddingRight: '5px', color: themedColor }}>{createDataDiff}</StyledDate>
                  <Icon size={0.7} color="#a9a9a9" path={mdiMessage} right="5" valign="center" />
                  <span>Reply </span>
                </StyledMessage>
                <div className="inline-block">
                  <Icon
                    size={0.7}
                    onClick={() => handleToggleCommentLike(comment)}
                    // color={some(comment?.likes, { userInfo: { id: user.id } }) ? PrimaryColor : '#a9a9a9'}
                    color={some(comment?.likes, { userInfo: { id: user?.id } }) ? PrimaryColor : '#a9a9a9'}
                    path={mdiHeart}
                    right="5"
                    valign="center"
                  />
                  <span>{comment?.likes?.length > 0 ? comment?.likes?.length : ''}</span>
                </div>
              </StyledMetaData>
            </Grid>
          </Grid>
          {showCommentInput && (
            <Formik
              enableReinitialize
              initialValues={{
                text: '',
              }}
              validateOnMount
              isInitialValid={false}
              onSubmit={handleCommentCreate}
              id={`post-comment-form-${comment?.id}`}
            >
              {formProps => {
                return (
                  <div className="create-post-root">
                    <Grid container className="flex-flow-column">
                      <Grid container className="flex-flow-row align-items-start">
                        <Grid container className="flex-flow-row align-items-start">
                          <Box style={{ marginLeft: '15px' }} component="div" display="flex">
                            <AvatarComponent
                              alt={`${user?.firstName}${user?.lastName}`}
                              src={user?.avatarUrl}
                              mobileView={mobileView}
                            >
                              {user?.firstName[0]}
                              {user?.lastName[0]}
                            </AvatarComponent>
                            <Box style={{ display: 'flex', alignItems: 'center', marginTop: '5px' }} component="div">
                              <StyledName>
                                {user?.firstName} {user?.lastName}
                                {comment?.isBubbled && (
                                  <EmojiObjectsIcon style={{ color: colors.gold }} className="ml-1" />
                                )}
                              </StyledName>
                              {/* <StyledDate>{createDataDiff}</StyledDate> */}
                            </Box>
                          </Box>
                          <div
                            style={{
                              position: 'relative',
                              width: '100%',
                              backgroundColor: !isUnifiedCommunity && isDarkThemeEnabled ? '#252728' : '#FFFFFF',
                              paddingInline: '10px',
                              marginTop: '5px',
                              borderRadius: '5px',
                            }}
                          >
                            <Grid
                              style={{ width: '90%' }}
                              className={` ${
                                !isUnifiedCommunity && isDarkThemeEnabled
                                  ? `create-post-container-dark-themed `
                                  : `create-post-container`
                              } flex-flow-row`}
                              container
                            >
                              <div
                                className={
                                  !isUnifiedCommunity && isDarkThemeEnabled
                                    ? 'create-comment-mention-input-container-dark-themed'
                                    : 'create-comment-mention-input-container'
                                }
                              >
                                <UserMentionInput
                                  placeholder="What’s on your mind..."
                                  defaultValue={textareaValue}
                                  users={isUnifiedCommunity ? [] : users}
                                  onInputChange={handleKeyUp}
                                  resetInputValue={resetUserMentionInputValue}
                                  onResetInputValueCallback={() => setResetUserMentionInputValue(false)}
                                />
                              </div>
                            </Grid>
                            <div
                              style={{
                                position: 'absolute',
                                right: '15px',
                                bottom: '28px',
                              }}
                            >
                              <StyledButton
                                style={{ backgroundColor: btnColor }}
                                disabled={commentPostPending || textareaValue?.length <= 0}
                                autoWidth
                                type="submit"
                                id={`post-create-form-${comment?.id}`}
                                onClick={formProps.handleSubmit}
                              >
                                {commentPostPending ? (
                                  <CircularProgress
                                    size={36}
                                    className="chat-messages-history-list__load-more-messages-progress"
                                  />
                                ) : (
                                  <SendIcon
                                    fontSize="small"
                                    style={{ color: textColor }}
                                    className="smoked-gary-color hoverable"
                                  />
                                )}
                              </StyledButton>
                            </div>
                          </div>
                        </Grid>
                        {formProps.errors.text && formProps.dirty && (
                          <StyledTextError mobileView={mobileView}>{formProps.errors.text}</StyledTextError>
                        )}
                      </Grid>
                    </Grid>
                  </div>
                );
              }}
            </Formik>
          )}
          {modalOpened && (
            <EditCommentModal
              isOpen={modalOpened}
              onModalClose={handleModalClose}
              comment={comment}
              setComment={setComment}
              fetchPosts={fetchPosts}
              sendIconColor={btnColor}
              textColor={textColor}
              user={user}
            />
          )}
        </StyledCommentWrap>
      )}
      {comment?.childComments?.length > 0 &&
        comment?.childComments?.map(reply => (
          <Comment
            themedColor={themedColor}
            themedCardBackgroundColor={themedCardBackgroundColor}
            themedBackgroundColor={themedBackgroundColor}
            commentData={reply}
            isCoach={isCoach}
            generateHTMLText={generateHTMLText}
            user={user}
            handleToggleCommentLike={handleToggleCommentLike}
            allComments={allComments}
            fetchPosts={fetchPosts}
            pods={pods}
          />
        ))}
    </>
  );
};

export default Comment;
