import { Tooltip } from '@material-ui/core';
import Input from 'components/FormUI/Input';
import InfoIcon from '@material-ui/icons/Info';
import React from 'react';
import { TOOLTIP } from '../../../../constants';
import classes from './LabelInput.module.scss';
import { colors } from 'utils/styles';

const LabelInput = ({
  infoColor = '',
  tooltipText = '',
  labelClassName = '',
  containerClassName = '',
  label = null,
  ...props
}) => {
  let labelClass = classes.label;
  let containerClass = classes.container;

  if (labelClassName) {
    labelClass = `${labelClass} ${labelClassName}`;
  }
  if (containerClassName) {
    containerClass = `${containerClass} ${containerClassName}`;
  }
  return (
    <>
      <div className={containerClass}>
        {label && <div className={labelClass}>{label}</div>}
        {tooltipText && (
          <Tooltip
            title={tooltipText}
            arrow
            enterTouchDelay={TOOLTIP.ENTER_DELAY}
            leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
          >
            <InfoIcon htmlColor={infoColor ? infoColor : colors.lightBrown} />
          </Tooltip>
        )}
      </div>
    </>
  );
};

export default LabelInput;
