import React from 'react'
import PropTypes from 'prop-types'

import SpeakerView from './SpeakerView'
import GalleryView from './GalleryView'
import { ViewModeType } from '../Toolbar'

const VideoChatView = ({ type, ...restProps }) => {
  switch (type) {
    case ViewModeType.speaker:
      return <SpeakerView {...restProps} />
    case ViewModeType.gallery:
      return <GalleryView {...restProps} />
    default:
      return null
  }
}

VideoChatView.propTypes = {
  type: PropTypes.oneOf([ViewModeType.speaker, ViewModeType.gallery]).isRequired,
}

export default VideoChatView
