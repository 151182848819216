import React from 'react';

import { ChatType } from 'services/chat.service';
import { useConversations } from 'hooks';
import ConversationsList from './ConversationsList';

const ClientsConversationsList = () => {
  const { loading, conversationIds } = useConversations(ChatType.directWithClients);

  if (loading || !conversationIds) {
    return null;
  }

  return <ConversationsList conversationIds={conversationIds} />;
};

export default ClientsConversationsList;
