import axiosInstance from 'utils/axiosInstance';
import { get } from 'lodash/fp';

export const APPLICATION_LIMIT = 20;

const AddOrUpdateApplicationForm = applicationFormData =>
  axiosInstance.post('/ApplicationForm/AddOrUpdateApplicationForm', { ...applicationFormData }).then(get('data'));

const getApplications = (status = 'all', offset = 0, limit = APPLICATION_LIMIT) =>
  axiosInstance
    .get(`/ApplicationForm/GetApplicationResponseList?status=${status}&offset=${offset}&limit=${limit}`)
    .then(get('data'));

const getApplicationForms = () => axiosInstance.get(`/ApplicationForm/GetAllApplicationFormByCoach`).then(get('data'));

const updateApplicationFormStatus = (formResponseId, IsAccepted) =>
  axiosInstance
    .put(`/ApplicationForm/AcceptOrRejectApplicationForm?FormResponseId=${formResponseId}&IsAccepted=${IsAccepted}`)
    .then(get('data'));

const getApplicationResponseCount = () =>
  axiosInstance
    .get('/ApplicationForm/GetApplicationResponseCount')
    .then(get('data'))
    .catch(e => {});

export {
  getApplications,
  AddOrUpdateApplicationForm,
  updateApplicationFormStatus,
  getApplicationForms,
  getApplicationResponseCount,
};
