import { Grid } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import classes from './revenuedetails.module.scss';
import styled, { css } from 'styled-components';
import PartnerTier from 'assets/partner_tier.png';
import LearnMore from 'assets/learn_mored.png';
import InfoIcon from '@material-ui/icons/Info';
import { useTheme, useMediaQuery, Tooltip, TextField } from '@material-ui/core';
import {
  getAffiliateBalanceForPayout,
  getAffiliateReferralDetail,
  getReferralsData,
  payout,
} from 'services/affiliate.service';
import Button from 'components/FormUI/Button';
import getSymbolFromCurrency from 'currency-symbol-map';
import { formatMoney } from 'utils/datesAndMoney';
import { openInNewWindow, redirectInNewTab, redirectTo } from 'services/links';
import moment from 'moment';
import Loader from 'components/UI/Loader';
import useAccount from 'hooks/useAccount';

const StyledCard = styled.div`
  border-radius: 0.25rem;
  border: 1px solid var(--Cohere-Greys-Grey-Tint, #dfe3e4);
  // background: #fff;

  display: flex;
  padding: 1rem;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  flex: 1 0 0;

  overflow: hidden;

  /* in figma design */
  /* min-width: 10.66071rem;
min-height: 6rem; */

  /* 20% lesser than in design */
  min-width: 8.528568rem;
  min-height: 4.8rem;

  /* 25% greater than figma design */
  max-height: 7.5rem;

  // width: 303px;
  height: 108px;
  //max-width: 10rem;

  ${({ loading }) =>
    loading &&
    css`
      position: relative;
      > *:not(.loader) {
        opacity: 0.4;
      }
    `}

  .stats-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .stats-values-row {
    display: flex;
    flex-direction: row;
    // justify-content: space-between;
    align-items: baseline;
    width: 100%;
    flex-direction: row;
  }
  .stats-type {
    color: var(--Cohere-Greys-Text, #4a4a4a);
    font-family: Avenir;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
  }
  .stats-primary-value {
    color: var(--Cohere-Primary-Blue, #215c73);
    font-family: Avenir;
    font-size: 22px;
    font-weight: 900;
    line-height: 22.238px;
  }
  .stats-secondary-value {
    color: var(--Cohere-Primary-Blue, #215c73);
    font-family: Avenir;
    font-size: 14px;
    font-style: normal;
    font-weight: 900;
    line-height: 22.238px;
  }
`;

const StyledCardRow = styled.div`
  border-radius: 0.25rem;
  border: 1px solid var(--Cohere-Greys-Grey-Tint, #dfe3e4);
  // background: #fff;

  display: flex;
  padding: 1rem;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 2.3rem;
  flex: 1 0 0;

  overflow: hidden;

  /* in figma design */
  /* min-width: 10.66071rem;
min-height: 6rem; */

  /* 20% lesser than in design */
  min-width: 8.528568rem;
  min-height: 4.8rem;

  /* 25% greater than figma design */
  max-height: 7.5rem;

  ${({ loading }) =>
    loading &&
    css`
      position: relative;
      > *:not(.loader) {
        opacity: 0.4;
      }
    `}

  .stats-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 72%;
  }
  .stats-values-row {
    display: flex;
    flex-direction: row;
    // justify-content: space-between;
    align-items: baseline;
    width: 100%;
    flex-direction: row;
  }
  .stats-type {
    color: var(--Cohere-Greys-Text, #4a4a4a);
    font-family: Avenir;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
  }
  .stats-primary-value {
    color: var(--Cohere-Primary-Blue, #215c73);
    font-family: Avenir;
    font-size: 22px;
    font-weight: 900;
    line-height: 22.238px;
  }
  .stats-secondary-value {
    color: var(--Cohere-Primary-Blue, #215c73);
    font-family: Avenir;
    font-size: 14px;
    font-style: normal;
    font-weight: 900;
    line-height: 22.238px;
  }
  .button {
    padding: 6px 16px 6px 16px;
    gap: 8px;
    border-radius: 4px;
    position: absolute;
    top: 31%;
    right: -4%;
  }
`;

const StyledImg = styled.div`
  background-image: url(${({ previewImg }) => previewImg});
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  width: 80px;
  height: 80px;
  z-index: 1;
`;
const StyledImgLM = styled.div`
  background-image: url(${({ previewImg }) => previewImg});
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
  z-index: 1;
`;
export default function RevenueDetails({
  referralsData = [],
  affiliateBalanceForPayout,
  onGetPaidStandardAccountForAffiliate,
  refferalDetail,
  referalManualPayout,
}) {
  const { user } = useAccount();
  return (
    <Grid className={classes.mainContainer} container xs={12} sm={12} lg={12}>
      <Grid container spacing={2} xs={12} sm={12} lg={referalManualPayout ? 7 : 6}>
        {referralsData.length > 0 && (
          <Grid style={{ position: 'relative' }} item xs={12} sm={12} lg={12}>
            <StyledCardRow>
              <div className="stats-row">
                <div className="stats-type">Cash Available For Payout</div>
                {user.affiliatePayoutsEnabled === true &&
                  user.isAffiliateBankAccountConnected === true &&
                  affiliateBalanceForPayout?.amount > 0 && (
                    <Button
                      onClick={onGetPaidStandardAccountForAffiliate}
                      autoWidth
                      // className="button"
                      style={{
                        padding: ' 12px 16px',
                        borderRadius: '4px',
                        position: 'absolute',
                        top: '15%',
                        right: '20px',
                        letterSpacing: '0px',
                      }}
                    >
                      Get Paid
                    </Button>
                  )}
              </div>

              <div className="stats-values-row">
                <div className="stats-values-row">
                  <div className="stats-primary-value">
                    {affiliateBalanceForPayout?.currency != undefined
                      ? ` ${getSymbolFromCurrency(affiliateBalanceForPayout?.currency)}${formatMoney(
                          affiliateBalanceForPayout?.amount,
                        )}`
                      : `$0`}
                  </div>
                  <div style={{ marginLeft: '5px' }} className="stats-secondary-value">
                    {affiliateBalanceForPayout?.currency != undefined
                      ? `${affiliateBalanceForPayout?.currency.toUpperCase()}`
                      : `USD`}
                  </div>
                </div>
              </div>
            </StyledCardRow>
          </Grid>
        )}
        {referralsData.map((k, index) => (
          <Grid item xs={12} sm={12} lg={referalManualPayout ? 4 : 6} key={index}>
            <StyledCard>
              <div className="stats-row">
                <div className="stats-type">
                  {k.title}
                  {k.title.includes('Total Affiliate Revenue For Manual Payout') ? (
                    <Tooltip
                      title="If you have not yet set up and verified your account for automatic payouts, your earnings will be processed manually by the Cohere team. For manual payouts, please contact us at support@cohere.live with your PayPal email address. We will process your payout within 10 business days."
                      arrow
                    >
                      <InfoIcon
                        style={{ marginLeft: '5px', marginBottom: '3px' }}
                        htmlColor="
                    #C9B382"
                      />
                    </Tooltip>
                  ) : k.title.includes('Total Earned Revenue') ? (
                    <Tooltip
                      title="After your referral has signed up for Cohere, your referral commission will be available within 60 days. Assuming no refunds or disputes occur with your referral."
                      arrow
                    >
                      <InfoIcon
                        style={{ marginLeft: '5px', marginBottom: '3px' }}
                        htmlColor="
                    #C9B382"
                      />
                    </Tooltip>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              <div className="stats-values-row">
                <div className="stats-primary-value">
                  {k.currency != null ? getSymbolFromCurrency(k?.currency) : ''}
                  {k.newValue === null ? k.value : k.newValue}{' '}
                </div>
                {k.currency != null && (
                  <div style={{ marginLeft: '5px' }} className="stats-secondary-value">
                    {k.currency.toUpperCase()}
                  </div>
                )}
              </div>
            </StyledCard>
          </Grid>
        ))}
      </Grid>
      {refferalDetail != undefined && (
        <Grid className={classes.partner_container} xs={12} sm={12} lg={referalManualPayout ? 5 : 6}>
          <div className={classes.partner_details}>
            <StyledImg previewImg={refferalDetail?.imageUrl} />
            <div className={classes.partner_details_description}>
              <div className={classes.partner_title}>
                {refferalDetail?.partnerTierTitle}
                <span className={classes.sub_detail}>
                  ({refferalDetail?.minReferralCount}-{refferalDetail?.maxReferralCount} Referrals)
                </span>
              </div>
              <div className={classes.referrals_detail}>
                Level up by {refferalDetail?.maxReferralCount - refferalDetail?.currentReferralsCount} more successful
                referrals
              </div>
              <div className={classes.referrals_detail_sub}>
                Cohere Partner since {`${moment(refferalDetail?.firstReferral).format('MMMM DD, yyyy')}`}
              </div>
            </div>
          </div>
          <div className={classes.partner_details_progress}>
            <div className={classes.partner_details_progress_bar}>
              <div
                className={classes.partner_details_progress_bar_filled}
                style={{
                  width: `${refferalDetail.scalePercentage}%`,
                }}
              ></div>
            </div>
            <div className={classes.partner_details_progress_values}>
              <div className={classes.progress_value}>{refferalDetail?.minReferralCount}</div>
              <div className={classes.progress_value}>{refferalDetail?.maxReferralCount}</div>
            </div>
          </div>
          <div
            onClick={() => {
              redirectInNewTab('https://www.cohere.live/become-a-partner/');
            }}
            className={classes.learn_more}
          >
            Learn more about Cohere Partner Tiers
            <StyledImgLM previewImg={LearnMore} />
          </div>
        </Grid>
      )}
    </Grid>
  );
}
