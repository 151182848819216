import React, { useEffect, useCallback, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { redirectTo } from 'services/links';

import { useHttp, useAccount } from 'hooks';
import * as payoutActions from 'actions/payout';
import { formatMoney } from 'utils/datesAndMoney';

import Loader from 'components/UI/Loader';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import { Card, CardBody, CardHeader } from 'components/UI/Card';
import { PageTitleSecond } from 'components/UI/Text/TextStyles';
import Button from 'components/FormUI/Button';
import { colors } from 'utils/styles';
import getSymbolFromCurrency from 'currency-symbol-map';
import { TOOLTIP } from '../../../../../constants.js';
import '../../Payment.scss';
import { payout } from 'services/affiliate.service.js';
import styled from 'styled-components';

const StyledError = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const BalanceCard = ({
  setAffiliateBalanceForPayout,
  income,
  balance: { affiliateAmount, amount, currency, standardAccountAmount, standardAccountCurrency },
  getBalance,
  type,
}) => {
  const { request, loading } = useHttp();
  const { user } = useAccount();
  const tooltipText = `Total balance includes your affiliate amount i.e. ${getSymbolFromCurrency(
    currency,
  )}${affiliateAmount}`;
  const [payoutError, setPayoutError] = useState(null);
  const onGetBalance = useCallback(() => {
    getBalance();
  }, [getBalance]);

  useEffect(() => {
    getBalance();
  }, [getBalance]);

  if (loading) {
    return <Loader />;
  }

  const onGetPaid = () => {
    request('/api/Payout/get-paid/full', 'POST')
      .then(onGetBalance)
      .catch(err => {
        if (err.errorCode == 'payouts_not_allowed') {
          request('api/Payment/generate-account-verification-link', 'POST').then(({ link }) => {
            redirectTo(link);
          });
        }
      });
  };

  const onGetPaidStandardAccount = () => {
    request('/api/Payout/get-paid/full?isStandardAccount=true', 'POST')
      .then(onGetBalance)
      .catch(err => {
        if (err.errorCode == 'payouts_not_allowed') {
          request('api/Payment/generate-account-verification-link', 'POST').then(({ link }) => {
            redirectTo(link);
          });
        }
      });
  };

  const onGetPaidStandardAccountForAffiliate = () => {
    payout()
      .then(res => {
        setAffiliateBalanceForPayout({ ...income, amount: 0 });
      })
      .catch(err => {
        if (err) {
          setPayoutError('Error');
        }
      });
  };

  return (
    <Grid item xs={10} classes={{ root: 'card-container' }}>
      <Card maxHeight>
        <CardHeader>
          <PageTitleSecond>{type != 'affiliate' ? `Balance` : `Affiliate Balance`}</PageTitleSecond>
        </CardHeader>
        <CardBody style={{ flexDirection: 'column', display: 'flex' }} className="card-body-container">
          {type != 'affiliate' && (!!amount || amount === 0) && (
            <div style={{ flexDirection: 'row', width: '100%', justifyContent: 'space-between', display: 'flex' }}>
              <p className="card-body-container__text">
                Your balance from Cohere Payment Processor:
                <span className="card-body-container__text-amount">
                  {` ${getSymbolFromCurrency(currency)}${formatMoney(amount)} ${currency}`}{' '}
                  {/* <Tooltip
                    title={tooltipText}
                    arrow
                    enterTouchDelay={TOOLTIP.ENTER_DELAY}
                    leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                  >
                    <InfoIcon htmlColor={colors.lightBrown} />
                  </Tooltip> */}
                </span>
              </p>
              {user.payoutsEnabled && (
                <Button
                  disabled={amount === 0 || user.isBankAccountConnected === false}
                  autoWidth
                  variant="primary"
                  onClick={onGetPaid}
                >
                  Get Paid
                </Button>
              )}
            </div>
          )}
          {type != 'affiliate' && (!!standardAccountAmount || standardAccountAmount === 0) && (
            <div
              style={{
                flexDirection: 'row',
                width: '100%',
                justifyContent: 'space-between',
                display: 'flex',
                marginTop: '15px',
              }}
            >
              <p className="card-body-container__text">
                Your balance from Direct Stripe Account:
                <span className="card-body-container__text-amount">
                  {` ${getSymbolFromCurrency(standardAccountCurrency)}${formatMoney(
                    standardAccountAmount,
                  )} ${standardAccountCurrency}`}{' '}
                  {/* <Tooltip
                    title={tooltipText}
                    arrow
                    enterTouchDelay={TOOLTIP.ENTER_DELAY}
                    leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                  >
                    <InfoIcon htmlColor={colors.lightBrown} />
                  </Tooltip> */}
                </span>
              </p>
              {user.payoutsEnabled && (
                <Button
                  disabled={standardAccountAmount === 0 || user.isStandardBankAccountConnected === false}
                  autoWidth
                  variant="primary"
                  onClick={onGetPaidStandardAccount}
                >
                  Get Paid
                </Button>
              )}
              {user.affiliatePayoutsEnabled === true &&
                user.isAffiliateBankAccountConnected === true &&
                type === 'affiliate' && (
                  <Button
                    disabled={standardAccountAmount === 0 || user.isStandardBankAccountConnected === false}
                    autoWidth
                    variant="primary"
                    onClick={onGetPaidStandardAccount}
                  >
                    Get Paid
                  </Button>
                )}
            </div>
          )}
          {type === 'affiliate' && (!!income || income?.amount === 0) && (
            <>
              <div
                style={{
                  flexDirection: 'row',
                  width: '100%',
                  justifyContent: 'space-between',
                  display: 'flex',
                  marginTop: '15px',
                }}
              >
                <p className="card-body-container__text">
                  Your balance from Affiliate Account:
                  <span className="card-body-container__text-amount">
                    {` ${getSymbolFromCurrency(income?.currency)}${formatMoney(
                      income?.amount,
                    )} ${income?.currency.toUpperCase()}`}{' '}
                    {/* <Tooltip
                      title={tooltipText}
                      arrow
                      enterTouchDelay={TOOLTIP.ENTER_DELAY}
                      leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                    >
                      <InfoIcon htmlColor={colors.lightBrown} />
                    </Tooltip> */}
                  </span>
                </p>
                {user.affiliatePayoutsEnabled === true &&
                  user.isAffiliateBankAccountConnected === true &&
                  income?.amount > 0 &&
                  type === 'affiliate' && (
                    <Button
                      autoWidth
                      variant={type === 'affiliate' ? 'secondary' : 'primary'}
                      onClick={onGetPaidStandardAccountForAffiliate}
                    >
                      Get Paid
                    </Button>
                  )}
              </div>
            </>
          )}
        </CardBody>
      </Card>
    </Grid>
  );
};

BalanceCard.propTypes = {
  balance: PropTypes.shape({
    amount: PropTypes.number,
    currency: PropTypes.string,
  }),
  getBalance: PropTypes.func.isRequired,
};

BalanceCard.defaultProps = {
  balance: {},
};

const mapStateToProps = ({ payout }) => ({
  balance: payout?.balance,
});

const actions = {
  getBalance: payoutActions.fetchBalance,
};

export default connect(mapStateToProps, actions)(BalanceCard);
