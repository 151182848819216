import { SHOW_ALERT, HIDE_ALERT } from 'actions/alerts';

export default (state = {}, { type, payload }) => {
  switch (type) {
    case SHOW_ALERT:
      return { ...payload };
    case HIDE_ALERT:
      return {};
    default:
      return state;
  }
};
