import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { position } from 'polished';
import styled from 'styled-components';
import Logo from 'components/FormUI/Logo';
import MinimizeIcon from '@mui/icons-material/Minimize';
import Avatar from '@material-ui/core/Avatar';
import useAccount from 'hooks/useAccount';
import * as R from 'ramda';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { colors } from 'utils/styles';
import ProgressBar from '@ramonak/react-progress-bar';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import DoneIcon from '@mui/icons-material/Done';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CoachImage from 'assets/chatlogo.png';
import ListItem from './ListItem';
import { ListItemTypes } from 'helpers/constants/listItemTypes';
import HomeIcon from '@mui/icons-material/Home';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import SendIcon from '@mui/icons-material/Send';
import HomeView from './HomeView';
import HelpView from './HelpView';

const StyledLogoAndBtn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const CardFooter = styled.div`
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.13);
  border-radius: 0px 0px 4px 4px;
`;

const IconWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 10px;
  color: gray;
  cursor: pointer;
`;

const IconWithDescription = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  ${({ isActive }) =>
    isActive &&
    `
    color: #215c73;
  `}
`;

const TABS = {
  Home: 'Home',
  Help: 'Help',
};

export default function ChatCard(props) {
  const { hideCardFunc, loadScript } = props;
  const [activeTab, setActiveTab] = useState(TABS.Home);
  const { user } = useAccount();
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'));

  const CardStyle = {
    minWidth: mobileView ? '350px' : '385px',
    maxWidth: mobileView ? '350px' : '385px',
    position: 'fixed',
    bottom: 105,
    right: !mobileView ? 60 : 45,
    cursor: 'default',
    boxShadow: '0 0px 5px 0 rgba(157, 157, 157, 0.3)',
  };

  return (
    <Card sx={CardStyle}>
      <CardContent>
        <StyledLogoAndBtn>
          <Logo startlogo />
          <IconWrapper onClick={hideCardFunc}>
            <MinimizeIcon style={{ color: 'gray' }} />
          </IconWrapper>
        </StyledLogoAndBtn>
        {activeTab === TABS.Home ? <HomeView loadScript={loadScript} /> : <HelpView />}
      </CardContent>
      <CardFooter>
        <IconWithDescription
          isActive={activeTab === TABS.Home}
          onClick={() => {
            setActiveTab(TABS.Home);
          }}
        >
          <div>
            <HomeIcon htmlColor={activeTab === TABS.Home ? '#215c73' : ''} />
          </div>
          <div>Home</div>
        </IconWithDescription>
        <IconWithDescription
          isActive={activeTab === TABS.Help}
          onClick={() => {
            // setActiveTab(TABS.Help);
            window.open('https://cohere2298.zendesk.com/hc/en-us', '_blank');
          }}
        >
          <div>
            <HelpOutlineIcon htmlColor={activeTab === TABS.Help ? '#215c73' : ''} />
          </div>
          <div>Help</div>
        </IconWithDescription>
      </CardFooter>
    </Card>
  );
}
