import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Checkbox from '@material-ui/core/Checkbox'
import Avatar from '@material-ui/core/Avatar'
import { useTheme, useMediaQuery } from '@material-ui/core'
import styled from 'styled-components'

import getInitials from 'utils/getInitials'

import './GeneralInfo.scss'

const AvatarComponent = styled(Avatar)`
  ${({ mobileView }) => {
    return (
      mobileView &&
      `
        width: 24px;
        height: 24px;
        font-size: 0.7rem;
      `
    )
  }}
`

const GeneralInfo = ({ className, title, iconUrl, isActive, selected, onSelectedChange }) => {
  const theme = useTheme()
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'))
  return (
    <div className={classNames(className, 'chats-list-chat-general-info')}>
      {isActive ? (
        <Checkbox
          className="chats-list-chat-general-info__select"
          color="primary"
          onClick={(e) => e.stopPropagation()}
          checked={selected}
          onChange={({ target: { checked } }) => onSelectedChange(checked)}
        />
      ) : (
        <AvatarComponent src={iconUrl} mobileView={mobileView}>
          {getInitials(title)}
        </AvatarComponent>
      )}
      <p className="chats-list-chat-general-info__title" title={title}>
        {title}
      </p>
    </div>
  )
}

GeneralInfo.propTypes = {
  className: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  iconUrl: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
  selected: PropTypes.bool.isRequired,
  onSelectedChange: PropTypes.func.isRequired,
}

export default GeneralInfo
