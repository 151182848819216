import { createAction } from 'redux-actions';
import { get, pipe } from 'lodash/fp';

import { getTimeZones } from 'services/timeZone.service';

export const GET_TIME_ZONES = 'GET_TIME_ZONES';
export const GET_TIME_ZONES_SUCCESS = 'GET_TIME_ZONES_SUCCESS';
export const GET_TIME_ZONES_ERROR = 'GET_TIME_ZONES_ERROR';

export const fetchTimeZonesActions = {
  request: createAction(GET_TIME_ZONES),
  success: createAction(GET_TIME_ZONES_SUCCESS),
  error: createAction(GET_TIME_ZONES_ERROR),
};

export const fetchTimeZones = () => async dispatch => {
  dispatch(fetchTimeZonesActions.request());

  try {
    const response = await getTimeZones();
    dispatch(fetchTimeZonesActions.success(response));
  } catch (error) {
    pipe(get('response'), get('data'), fetchTimeZonesActions.error, dispatch)(error);
  }
};
