import { createAction } from 'redux-actions';
import { EmailMarketingIntegrationService } from 'integrations/EmailMarketing/service/marketingAccountIntegration.service';
import { pipe, get } from 'lodash/fp';

export const SET_ACCOUNT = 'SET_ACCOUNT';
export const SET_DOMAINS = 'SET_DOMAINS';
export const SET_LAST_SENDER = 'SET_LAST_SENDER';
export const GET_DOMAINS_REQUEST = 'GET_DOMAINS_REQUEST';
export const GET_DOMAINS_SUCCESS = 'GET_DOMAINS_SUCCESS';
export const GET_DOMAINS_ERROR = 'GET_DOMAINS_ERROR';
export const SET_SENDER_IDENTITIES = 'SET_SENDER_IDENTITIES';
export const SET_DOMAIN_IN_AUTH_PROCESS = 'SET_DOMAIN_IN_AUTHENTICATION_PROCESS';

export const setDomainInAuthProcess = (domain = '') => ({
  type: SET_DOMAIN_IN_AUTH_PROCESS,
  payload: domain,
});
export const setAccount = (accounts = {}) => ({
  type: SET_ACCOUNT,
  payload: accounts,
});

export const setDomains = (domains = []) => ({
  type: SET_DOMAINS,
  payload: domains,
});

export const setSenderIdentities = (data = []) => ({
  type: SET_SENDER_IDENTITIES,
  payload: data,
});

export const setLastSender = (data = {}) => ({
  type: SET_LAST_SENDER,
  payload: data,
});

export const fetchDomainsActions = {
  request: createAction(GET_DOMAINS_REQUEST),
  success: createAction(GET_DOMAINS_SUCCESS),
  error: createAction(GET_DOMAINS_ERROR),
};

export const fetchDomains = () => {
  return async dispatch => {
    dispatch(fetchDomainsActions.request());

    try {
      const domains = await EmailMarketingIntegrationService.getDomainsStatus();
      dispatch(fetchDomainsActions.success(domains));
    } catch (error) {
      dispatch(fetchDomainsActions.error(error?.response?.data?.message || error?.response?.data?.Error?.Message));
    }
  };
};
