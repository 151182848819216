import { createAction } from 'redux-actions';
import { get, pipe } from 'lodash/fp';

import { getCountries, getStates } from 'services/country.service';

export const GET_COUNTRIES = 'GET_COUNTRIES';
export const GET_COUNTRIES_SUCCESS = 'GET_COUNTRIES_SUCCESS';
export const GET_COUNTRIES_ERROR = 'GET_COUNTRIES_ERROR';
export const GET_STATES = 'GET_STATES';
export const GET_STATES_SUCCESS = 'GET_STATES_SUCCESS';
export const GET_STATES_ERROR = 'GET_STATES_ERROR';

export const fetchCountriesActions = {
  request: createAction(GET_COUNTRIES),
  success: createAction(GET_COUNTRIES_SUCCESS),
  error: createAction(GET_COUNTRIES_ERROR),
};

export const fetchStatesActions = {
  request: createAction(GET_STATES),
  success: createAction(GET_STATES_SUCCESS),
  error: createAction(GET_STATES_ERROR),
};
export const fetchCountries = () => async dispatch => {
  dispatch(fetchCountriesActions.request());

  try {
    const response = await getCountries();
    dispatch(fetchCountriesActions.success(response));
  } catch (error) {
    pipe(get('response'), get('data'), fetchCountriesActions.error, dispatch)(error);
  }
};

export const fetchStates = () => async dispatch => {
  dispatch(fetchStatesActions.request());

  try {
    const response = await getStates();
    dispatch(fetchStatesActions.success(response));
  } catch (error) {
    pipe(get('response'), get('data'), fetchStatesActions.error, dispatch)(error);
  }
};
