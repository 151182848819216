import { MEMBER_PODS_ACTION_TYPES } from './memberPods.actionTypes';

const resetState = () => {
  return {
    type: MEMBER_PODS_ACTION_TYPES.RESET_STATE,
  };
};

const setContributionMemberPods = payload => {
  const { contributionId, data } = payload;

  return {
    payload: { contributionId, data },
    type: MEMBER_PODS_ACTION_TYPES.SET_CONTRIBUTION_MEMBER_PODS,
  };
};

const setData = data => {
  return {
    payload: { data },
    type: MEMBER_PODS_ACTION_TYPES.SET_DATA,
  };
};

const setError = error => {
  return {
    payload: { error },
    type: MEMBER_PODS_ACTION_TYPES.SET_ERROR,
  };
};

const setIsFetching = isFetching => {
  return {
    payload: { isFetching },
    type: MEMBER_PODS_ACTION_TYPES.SET_IS_FETCHING,
  };
};

const setNewPodName = newPodName => {
  return {
    payload: { newPodName },
    type: MEMBER_PODS_ACTION_TYPES.SET_NEW_POD_NAME,
  };
};

export const MEMBER_PODS_ACTIONS = {
  resetState,
  setContributionMemberPods,
  setData,
  setError,
  setIsFetching,
  setNewPodName,
};
