import React from 'react';
import styled from 'styled-components';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import { openInNewWindow } from 'services/links';

const ROW = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 7px;
  ${({ isLast }) =>
    isLast &&
    `
  margin-bottom: 0px;
  `}
`;

const StyledHeading = styled.div`
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-decoration-line: underline;
  color: #215c73;
  cursor: pointer;
`;

const StyledDescription = styled.div`
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 15px;
  display: flex;
  align-items: center;
`;

const StyledTime = styled.div`
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center;
  margin-left: 5px;
`;

const HorizentalLine = styled.div`
  width: 100%;
  height: 0px;
  border: 1px solid #dfe3e4;
`;

const HelpSection = ({ secName, secDesc, duration, link }) => {
  return (
    <div style={{ marginBottom: '7px' }}>
      <ROW>
        <StyledHeading onClick={() => openInNewWindow(link)}>{secName}</StyledHeading>
        <OpenInNewIcon onClick={() => openInNewWindow(link)} htmlColor="#A58B62" style={{ fontSize: '18px' }} />
      </ROW>
      <ROW>
        <StyledDescription>{secDesc}</StyledDescription>
      </ROW>
      {/* <ROW>
                <div style={{display: 'flex'}}>
                    <WatchLaterIcon htmlColor='#215C73' style={{fontSize: '14px',}}/> 
                    <StyledTime>{`${duration} min`}</StyledTime>
                </div>
            </ROW> */}
      <HorizentalLine />
    </div>
  );
};

export default HelpSection;
