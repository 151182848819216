import { ContactsServices } from 'pages/Contacts/Services/Contacts.service';
import { DummyTags } from 'pages/Contacts/Tags/DummyTags';
import { createAction } from 'redux-actions';
import { getAllContracts } from 'services/contributions.service';

export const GET_CONTACTS_REQUEST = 'GET_CONTACTS_REQUEST';
export const GET_CONTACTS_SUCCESS = 'GET_CONTACTS_SUCCESS';
export const GET_CONTACTS_ERROR = 'GET_CONTACTS_ERROR';

export const SET_CONTACTS = 'SET_CONTACTS';

export const GET_TAGS_REQUEST = 'GET_TAGS_REQUEST';
export const GET_TAGS_SUCCESS = 'GET_TAGS_SUCCESS';
export const GET_TAGS_ERROR = 'GET_TAGS_ERROR';
export const GET_USER_TEMPLATES = 'GET_USER_TEMPLATES';

export const SET_LEADS = 'SET_LEADS';
export const SET_CLIENTS = 'SET_CLIENTS';

export const setLeads = createAction(SET_LEADS);
export const setClients = createAction(SET_CLIENTS);
export const setUserTemplates = createAction(GET_USER_TEMPLATES);
export const setContacts = createAction(SET_CONTACTS);

export const getAllTagsActions = {
  request: createAction(GET_TAGS_REQUEST),
  success: createAction(GET_TAGS_SUCCESS),
  error: createAction(GET_TAGS_ERROR),
};

export const getContactsActions = {
  request: createAction(GET_CONTACTS_REQUEST),
  success: createAction(GET_CONTACTS_SUCCESS),
  error: createAction(GET_CONTACTS_ERROR),
};

export const fetchAllTagsAsync = () => {
  return async dispatch => {
    dispatch(getAllTagsActions.request());
    try {
      const response = await ContactsServices.getAllTags();
      dispatch(getAllTagsActions.success(response));
    } catch (e) {
      dispatch(getAllTagsActions.error(e));
    }
  };
};

export const fetchAllContactsAsync = data => {
  return async dispatch => {
    dispatch(getContactsActions.request());
    try {
      const contacts = await ContactsServices.getAllContacts(data);
      dispatch(getContactsActions.success(contacts));
    } catch (error) {
      dispatch(getContactsActions.error(error.response));
    }
  };
};
