/* eslint-disable */

class ParticipantDisplayMode {
  static audio = 1;
  static video = 2;
  static audioAndVideo = ParticipantDisplayMode.audio | ParticipantDisplayMode.video;

  static hasFlag(displayMode, displayModeToCheck) {
    return (displayMode & displayModeToCheck) > 0;
  }
}

export default ParticipantDisplayMode;
