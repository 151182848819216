import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Logo from 'components/FormUI/Logo';
import { useParams, useLocation } from 'react-router-dom';
import { redirectTo } from 'services/links';

const DownloadIcalFile = () => {
  const { downloadLink, contribId, docId } = useParams();
  const [apiError, setApiError] = useState(null);
  const location = useLocation();

  useEffect(() => {
    // Redirect to the download link
    redirectTo(`https://${downloadLink}/${contribId}/${docId}`);

    // Display a message to the user
    setApiError('Your download will start shortly. Please close this tab after the download completes.');

    // Close the window after a delay (adjust as needed)
    const closeWindowTimeout = setTimeout(() => {
      redirectTo('/dashboard');
    }, 5000); // 5000 milliseconds (5 seconds)

    // Cleanup: Clear the timeout when the component is unmounted
    return () => {
      clearTimeout(closeWindowTimeout);
    };
  }, [downloadLink, contribId, docId]);
  const isRedirected = location.state && location.state.fromRedirect;

  return (
    <Grid container style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
      <div style={{ justifyContent: 'center', alignItems: 'center', marginTop: '50px', display: 'grid' }}>
        <Logo />
        {isRedirected ? (
          <p>Redirect has occurred. You may need to close this tab manually.</p>
        ) : (
          <p>Your download will start shortly.</p>
        )}
      </div>
    </Grid>
  );
};

export default DownloadIcalFile;
