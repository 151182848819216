import React from 'react';
import { StyledTitleForVideo, StyledVideo, StyledButton } from '../GettingStarted.styles';

const DemoVideo = ({ mobileView }) => {
  return (
    <>
      <StyledVideo controls="controls" preload="metadata">
        <source src={`https://coherepublic-prod.s3.amazonaws.com/DemoVideos/COHERE+INTRO+(DEMO+VIDEO+1_4).mp4`} />
      </StyledVideo>

      <StyledTitleForVideo mobileView={mobileView}>
        Welcome to Cohere! One place to sell, deliver, and scale your online services.
      </StyledTitleForVideo>

      <StyledTitleForVideo mobileView={mobileView}>
        This getting started guide will support you finishing setting up your account.
      </StyledTitleForVideo>

      <StyledTitleForVideo mobileView={mobileView}>
        We also offer a complimentary 45 min onboarding call to answer any questions and support your next steps.
      </StyledTitleForVideo>

      <StyledTitleForVideo mobileView={mobileView}>Click the link below to book.</StyledTitleForVideo>

      <StyledButton variant="primary">
        <a target="_blank" href="https://calendly.com/hasiacohere/45min">
          Book Your Onboarding Call
        </a>
      </StyledButton>
    </>
  );
};

export default DemoVideo;
