import React from 'react';
import classes from './HoverableImage.module.scss';
import Button from 'components/FormUI/Button';

const HoverableImage = ({
  onSelect = () => {},
  onPreview = () => {},
  imageContainerClassName = '',
  template,
  content,
}) => {
  let imageContainerClass = classes.imageContainer;
  if (imageContainerClassName) {
    imageContainerClass = `${imageContainerClass} ${imageContainerClassName}`;
  }
  return (
    <div className={imageContainerClass}>
      {/* <img className={classes.templateImg} src={template} /> */}
      <div className={classes.templateImg} dangerouslySetInnerHTML={{ __html: content }}></div>
      <div className={classes.overlay}></div>
      <div className={classes.middle}>
        {/* <Button onClick={onSelect} className={classes.btn} variant="secondary">
          Select
        </Button> */}
        <Button onClick={onPreview} className={classes.btn} variant="primary">
          Preview
        </Button>
      </div>
    </div>
  );
};

export default HoverableImage;
