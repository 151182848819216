import React from 'react'
import PropTypes from 'prop-types'
import IconButton from '@material-ui/core/IconButton'
import ScreenShareIcon from '@material-ui/icons/ScreenShare'
import StopScreenShareIcon from '@material-ui/icons/StopScreenShare'

import ToolbarAction from './ToolbarAction'

const ShareScreen = ({ enabled, onEnabledChange }) => {
  const ShareScreenIcon = enabled ? StopScreenShareIcon : ScreenShareIcon
  const label = enabled ? 'Stop Screen Sharing' : 'Share Screen'

  return (
    <ToolbarAction label={label}>
      <IconButton color="inherit" onClick={() => onEnabledChange(!enabled)}>
        <ShareScreenIcon />
      </IconButton>
    </ToolbarAction>
  )
}

ShareScreen.propTypes = {
  enabled: PropTypes.bool.isRequired,
  onEnabledChange: PropTypes.func.isRequired,
}

export default ShareScreen
