import React from 'react';

const ArrowDownIcon = ({ width = '13', height = '9', onClickHandler = () => {} }) => {
  return (
    <div onClick={onClickHandler}>
      <svg width={width} height={height} viewBox="0 0 13 9" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M1.91 0.790039L6.5 5.38004L11.09 0.790039L12.5 2.21004L6.5 8.21004L0.5 2.21004L1.91 0.790039Z"
          fill="#8A8A8A"
        />
      </svg>
    </div>
  );
};

export default ArrowDownIcon;
