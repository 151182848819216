import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';

import { LabelText } from 'components/UI/Text/TextStyles';
import { ClientJourneyContribution } from 'services/dataContracts/contributions';
import ContributionsList from './ContributionsList';
import styled from 'styled-components';
import { toRem } from 'utils/styles';

import './UpcomingContributions.scss';

const checkIfHasContributions = contributions => contributions?.length > 0;
const Heading = styled.p`
  font-size: ${toRem(22)};
  font-weight: 900;
  line-height: 1.5;
  letter-spacing: 0.12px;
  color: #000000;

  ${({ mobileView }) => mobileView && `font-size: ${toRem(16)};`}
`;

const UpcomingContributions = ({
  contributions: { otherIncompleted, today, thisWeek, thisMonth, nextMonth, thisYear, afterThisYear, notBooked },
  upcomingNotBooked,
  selfpacedupcomingContributions,
  pastContributions,
  type,
}) => {
  // const hasOtherIncompletedContributions = checkIfHasContributions([upcomingNotBooked]);
  // const hasBookSession = upcomingNotBooked.length > 0;
  // const hasTodayContributions = checkIfHasContributions(today);
  // const hasThisWeekContributions = checkIfHasContributions(thisWeek);
  // const hasThisMonthContributions = checkIfHasContributions(thisMonth);
  // const hasNextMonthContributions = checkIfHasContributions(nextMonth);
  // const hasThisYearContributions = checkIfHasContributions(thisYear);
  // const hasAfterThisYearContributions = checkIfHasContributions(afterThisYear);
  // const hasAnyContributions =
  //   // hasOtherIncompletedContributions ||
  //   hasTodayContributions ||
  //   hasThisWeekContributions ||
  //   hasThisMonthContributions ||
  //   hasNextMonthContributions ||
  //   hasThisYearContributions ||
  //   hasAfterThisYearContributions;

  // if (!hasAnyContributions) {
  //   return <LabelText>You have no upcoming sessions.</LabelText>;
  // }
  return (
    <div className="upcoming-contributions-list-container">
      {upcomingNotBooked.length > 0 ? (
        <ContributionsList
          className="upcoming-contributions-list-container__contributions"
          title="Book Session"
          contributions={upcomingNotBooked}
          sortKeySelector={['sessionTimeWithoutMoment']}
          sortOrder={['asc']}
          // empty={<p>No upcoming bookable sessions.</p>}
        />
      ) : null}

      {[...today, ...thisWeek, ...thisMonth, ...nextMonth, ...thisYear, ...afterThisYear].length > 0 && (
        <ContributionsList
          className="upcoming-contributions-list-container__contributions"
          title="Sessions"
          contributions={[...today, ...thisWeek, ...thisMonth, ...nextMonth, ...thisYear, ...afterThisYear]}
          sortKeySelector={['sessionTimeWithoutMoment']}
          sortOrder={['asc']}
          // empty={<p>No upcoming sessions.</p>}
        />
      )}
      {/* {(type === 'selfPaced' || hasTodayContributions) && (
        <ContributionsList
          className="upcoming-contributions-list-container__contributions"
          title=""
          contributions={selfpacedupcomingContributions}
        />
      )} */}

      {type === 'all' && (
        <>
          {selfpacedupcomingContributions.length > 0 && <Heading>Upcoming Modules</Heading>}
          <ContributionsList
            className="upcoming-contributions-list-container__contributions"
            title=""
            contributions={selfpacedupcomingContributions}
            sortKeySelector={['sessionTimeWithoutMoment']}
            sortOrder={['asc']}
            // empty={<p>No upcoming modules.</p>}
          />
        </>
      )}
      {/* {type != "Live"&& type != "selfPaced" && hasThisWeekContributions && (
        <ContributionsList
          className="upcoming-contributions-list-container__contributions"
          title="Past"
          contributions={pastContributions}
        />
      )} */}
      {/* {hasThisMonthContributions && (
        <ContributionsList
          className="upcoming-contributions-list-container__contributions"
          title="This Month"
          contributions={thisMonth}
        />
      )}
      {hasNextMonthContributions && (
        <ContributionsList
          className="upcoming-contributions-list-container__contributions"
          title="Next Month"
          contributions={nextMonth}
        />
      )}
      {hasThisYearContributions && (
        <ContributionsList
          className="upcoming-contributions-list-container__contributions"
          title="This Year"
          contributions={thisYear}
        />
      )}
      {hasAfterThisYearContributions && (
        <ContributionsList
          className="upcoming-contributions-list-container__contributions"
          title="Next Year"
          contributions={afterThisYear}
        />
      )} */}
    </div>
  );
};

const contributionsPropType = PropTypes.arrayOf(PropTypes.instanceOf(ClientJourneyContribution)).isRequired;

UpcomingContributions.propTypes = {
  contributions: PropTypes.shape({
    otherIncompleted: contributionsPropType,
    today: contributionsPropType,
    thisWeek: contributionsPropType,
    thisMonth: contributionsPropType,
    nextMonth: contributionsPropType,
    thisYear: contributionsPropType,
    afterThisYear: contributionsPropType,
    notBooked: contributionsPropType,
  }).isRequired,
};

export default UpcomingContributions;
