import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import range from 'lodash/range';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { mdiMinusCircleOutline, mdiPlus } from '@mdi/js';
import * as yup from 'yup';
import { Icon } from '@mdi/react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Formik, Form, FieldArray, Field } from 'formik';
import { useSelector } from 'react-redux';
import { useAccount } from 'hooks';
import Input from 'components/FormUI/Input';
import Select from 'components/FormUI/Select';
import { formatMoney } from 'utils/datesAndMoney';
import { colors } from 'utils/styles';
import './OneOnOne.scss';
import InfoIcon from '@material-ui/icons/Info.js';
import Tooltip from '@material-ui/core/Tooltip';
import { ContributionStatus, ContributionType } from 'helpers/constants';
import useRouter from 'hooks/useRouter';
import { getCurrency } from 'utils/getPaymentOptionsView';
import Modal from 'components/UI/Modal';
import CustomExpansionPanel from 'pages/CreateContribution/components/ExpansionPanel';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { isEmpty, isNumber, isUndefined, toNumber, toUpper } from 'lodash';
import { FORM_FIELDS } from './Membership/constants';
import { INPUT_NUMBERS_VALIDATION_REGEX, TOOLTIP, PAYMENT_OPTIONS } from '../../../../constants';
import {
  BlueCheckbox,
  BlueCheckboxForAccordianSummaryStyled,
  FormControlLabelStyled,
  InputStyled,
  SelectStyled,
  SelectWrapStyled,
  StyledHeader,
  StyledSummaryBodyContainer,
  StyledText,
  StyledTooltip,
  StyledTooltipWrap,
  SummaryPricingTextContainer,
  FormControlFullWidthStyled,
} from './Membership/styledComponents';
import CommonErrorMessage from 'components/FormUI/CommonErrorMessage';

const DEFAULT_PACKAGE_SIZE = 5;
const StyledLink = styled.div`
  /* max-width: 260px; */
  display: flex;
  height: 30px;
  width: 165px;
  padding: 0px 10px;
  justify-content: center;
  align-items: center;
  /* gap: 0.625rem; */

  cursor: pointer;
  border: 1px solid rgba(223, 227, 228, 1);
  border-radius: 4px;

  svg {
    width: 15px !important;
    height: 15px !important;
  }

  span {
    font-family: Avenir;
    font-size: 14px;
    font-style: normal;
    font-weight: 800;
    line-height: 21px;
    letter-spacing: 0.1px;
    color: ${colors.darkOceanBlue};
  }

  &:hover {
    text-decoration: underline;
  }
`;

const StyledIcon = styled(Icon)`
  cursor: pointer;
  margin: 10px 1px;
`;
const StyledIconDisabled = styled(Icon)`
  cursor: pointer;
  margin: 10px 1px;
  color: gray;
`;
function renderMonthlySessionSubscriptionPrice({ monthlySessionSubscriptionInfo }) {
  if (monthlySessionSubscriptionInfo) {
    return formatMoney(monthlySessionSubscriptionInfo.monthlyPrice * monthlySessionSubscriptionInfo.duration);
  }
  return formatMoney(0);
}

function renderPrice(values) {
  return formatMoney(+values.cost);
}

function sessionNumbersItems() {
  return range(1, 11).map(i => ({ title: `${i} session(s) per month`, value: i }));
}

function durationItems() {
  return range(2, 13).map(i => ({ title: `${i} month(s)`, value: i }));
}

// const validatePositiveNumber = inputNumber => {
//   const num = isNumber(inputNumber) ? inputNumber : toNumber(inputNumber);
//   return num > 0 && /^\d{0,10}(\.\d{0,2})?$/.test(num);
// };
const NoOfSessionsComponent = props => {
  const { shouldBeDisabled, formProps } = props;
  return (
    <>
      <InputStyled
        labelId="lbl-input-number-of-sessions"
        variant="outlined"
        margin="dense"
        type="text"
        name="packageSessionNumbers"
        fullWidth
        label=""
        disabled={shouldBeDisabled}
        onChange={({ target: { value } }) => {
          if (!INPUT_NUMBERS_VALIDATION_REGEX.test(value)) {
            formProps.setFieldValue('packageSessionNumbers', value);
            formProps.setFieldValue('packageCost', Math.fround((formProps.values.cost || 0) * value).toFixed(2));
          }
        }}
        onBlur={({ target: { value } }) => {
          if (!value) {
            formProps.setFieldValue('packageSessionNumbers', 0);
            formProps.setFieldValue('packageCost', Math.fround((formProps.values.cost || 0) * 0).toFixed(2));
          }
        }}
        // value={formProps.values.packageSessionNumbers}
      />
    </>
  );
};

const OneOnOne = ({ contribution, saveToStorageHandler, shouldBeDisabled, children }) => {
  const initialdefaultcurrency = contribution?.availableCurrencies?.find(c => c.isUserDefaultCurrency == true);
  const { user } = useAccount();
  const {
    user: { transfersEnabled, isStandardAccount, defaultPaymentMethod, standardAccountTransfersEnabled, avatarUrl },
  } = useAccount();
  const initialdefaultcurrencycode = initialdefaultcurrency?.code;
  const [supportPopup, setSupportPopup] = useState(false);
  const userDetail = useSelector(state => state.account.user);
  const repeatingError = useSelector(state => state.contributions.repeatingError);
  const { query } = useRouter();
  const [showMonthly, setshowMonthly] = useState(false);
  const isFeeDisabled = !!query.id && contribution?.status !== ContributionStatus.unfinished;
  const isEditing = !!query.id;
  const [isDisabled, setIsDisabled] = useState(false);
  let formRef = useRef();
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const smView = useMediaQuery(theme.breakpoints.up('sm'));

  const formatPackagePrice = ({ packageCost, packageSessionDiscountPercentage }) => {
    return formatMoney((packageCost * (100 - packageSessionDiscountPercentage)) / 100, 2);
  };
  const serviceAgreementType = user?.serviceAgreementType;
  const isBetaUser = user?.isBetaUser;
  const showOnlyFreeOption = serviceAgreementType && isBetaUser ? true : false;

  useEffect(() => {
    formRef.current.setFieldValue('coachPaysStripeFee', contribution?.paymentInfo?.coachPaysStripeFee);
    if (user?.defaultPaymentMethod === 'Simple' && contribution.status === 'Draft') {
      setshowMonthly(true);
    } else if (contribution.status === 'Approved' && contribution?.paymentType === 'Simple' && isEditing) {
      setshowMonthly(true);
    } else if (contribution?.paymentType === 'Simple') {
      setshowMonthly(true);
    }
  }, []);

  const handleFormKeyDown = e => {
    if ((e.charCode || e.keyCode) === 13) {
      e.preventDefault();
    }
  };

  const handleChangeCurrency = ({ target: { value } }, formProps) => {
    const symbol = contribution.availableCurrencies?.find(c => c.code === value).symbol;
    formProps.setFieldValue('defaultCurrency', value);
    formProps.setFieldValue('defaultSymbol', symbol);
    const needchanges =
      initialdefaultcurrencycode != value && formProps.values.paymentOptions.includes('MonthlySessionSubscription');
    if (needchanges) {
      const updatedpaymentoptions = formProps.values.paymentOptions.filter(e => e != 'MonthlySessionSubscription');
      formProps.setFieldValue('paymentOptions', updatedpaymentoptions);
    }
  };

  const handlePaymentProcessor = ({ target: { value } }, formProps) => {
    if (value === 'Simple') {
      setIsDisabled(false);
      formProps.setFieldValue('taxType', 'Exclusive');
    }
    if (value != 'Advance') {
      if (!user.isStandardTaxEnabled) {
        setIsDisabled(true);
      } else {
        setIsDisabled(false);
      }
      setshowMonthly(true);
      if (
        [
          'numan@yopmail.com',
          'numan@cohere.live',
          'jade@cohere.live',
          'standardhina@yopmail.com',
          't@cohere.live',
        ].includes(user?.email)
      ) {
        formProps.setFieldValue('taxType', 'Exclusive');
      } else {
        formProps.setFieldValue('taxType', 'No');
      }
    } else {
      setshowMonthly(false);
      setSupportPopup(true);
      const updatedpaymentoptions = formProps.values.paymentOptions.filter(e => e != 'MonthlySessionSubscription');
      formProps.setFieldValue('paymentOptions', updatedpaymentoptions);
      formProps.setFieldValue('taxType', 'No');
      if (standardAccountTransfersEnabled === false) {
        formProps.setFieldValue('multiplePriceList', []);
        formProps.setFieldValue('cost', null);
        formProps.setFieldValue('splittedCost', null);
        formProps.setFieldValue('paymentOptions', ['Free']);
      }
    }
    formProps.setFieldValue('paymentType', value);
  };
  const handleTaxType = ({ target: { value } }, formProps) => {
    formProps.setFieldValue('taxType', value);
  };

  useEffect(() => console.info('ge:', formRef?.current?.values?.paymentOptions), [
    formRef?.current?.values?.paymentOptions,
  ]);

  useEffect(() => {
    if (formRef?.current?.values?.paymentType != undefined && contribution?.status === 'Draft') {
      if (
        (formRef?.current?.values?.paymentType === 'Simple' && transfersEnabled === false) ||
        (formRef?.current?.values?.paymentType === 'Advance' && standardAccountTransfersEnabled === false)
      ) {
        formRef.current.setFieldValue('paymentOptions', ['Free']);
      }
    }
  }, [formRef?.current?.values?.paymentType]);

  return (
    <Formik
      innerRef={formRef}
      enableReinitialize
      initialValues={{
        cost: shouldBeDisabled
          ? contribution?.paymentInfo?.cost
            ? Math.fround(contribution?.paymentInfo?.cost).toFixed(2)
            : ''
          : contribution?.paymentInfo?.cost || '',
        packageCost: shouldBeDisabled
          ? contribution?.paymentInfo?.multiplePriceList?.length === 1
            ? contribution?.paymentInfo?.multiplePriceList[0].packageCost
            : contribution?.paymentInfo?.packageCost
          : contribution?.paymentInfo?.multiplePriceList?.length === 1
          ? contribution?.paymentInfo?.multiplePriceList[0].packageCost
          : contribution?.paymentInfo?.packageCost || '',
        paymentOptions: contribution?.paymentInfo?.paymentOptions ||
          (((user?.defaultPaymentMethod === 'Simple' && transfersEnabled === false) ||
            (user?.defaultPaymentMethod === 'Advance' && standardAccountTransfersEnabled === false)) && ['Free']) || [
            'PerSession',
          ],
        packageDiscount: !!contribution?.paymentInfo?.packageSessionDiscountPercentage,
        defaultCurrency: contribution.defaultCurrency,
        defaultSymbol: contribution.defaultSymbol,
        packageSessionNumbers: contribution?.paymentInfo?.packageSessionNumbers || '',
        packageSessionDiscountPercentage: contribution?.paymentInfo?.packageSessionDiscountPercentage
          ? contribution?.paymentInfo?.packageSessionDiscountPercentage
          : '',
        monthlySessionSubscriptionInfo: contribution?.paymentInfo?.monthlySessionSubscriptionInfo,
        coachPaysStripeFee: contribution?.paymentInfo?.coachPaysStripeFee,
        paymentType: contribution?.paymentType || user?.defaultPaymentMethod,
        taxType: contribution?.taxType || 'No',
        isPriceHidden: contribution?.isPriceHidden,
        multiplePriceList:
          contribution?.paymentInfo?.multiplePriceList?.length === 0
            ? [
                {
                  cost: contribution?.paymentInfo?.cost || 0,
                  packageCost: contribution?.paymentInfo?.packageCost || 0,
                },
              ]
            : contribution?.paymentInfo?.multiplePriceList || [{ cost: 0, packageCost: 0 }],
      }}
      validationSchema={yup.object().shape({
        cost: yup.number().when('paymentOptions', {
          is: values =>
            values.includes('PerSession') ||
            values.includes('SessionsPackage') ||
            values.includes('Free') ||
            values.includes('FreeSessionsPackages'),
          then: yup.number().min(1, 'Value should be more than 1').required('This is a required field'),
          otherwise: yup.number().nullable(),
        }),
        packageCost: yup.number().when('paymentOptions', {
          is: values => values.includes('SessionsPackage'),
          then: yup.number().min(1, 'Value should be more than 1').required('This is a required field'),
          otherwise: yup.number().nullable(),
        }),
        packageSessionNumbers: yup.number(),
        packageSessionDiscountPercentage: yup
          .number()
          .moreThan(0, 'Value should be more than 0')
          .max(100, 'Value should be less than 100'),
        paymentOptions: yup
          .array()
          .of(
            yup
              .mixed()
              .oneOf(['PerSession', 'SessionsPackage', 'MonthlySessionSubscription', 'Free', 'FreeSessionsPackage']),
          )
          .min(1),
        monthlySessionSubscriptionInfo: yup.object().when('paymentOptions', {
          is: values => values.includes('MonthlySessionSubscription'),
          then: yup
            .object()
            .required()
            .shape({
              sessionCount: yup
                .number()
                .required()
                .min(1, 'Value should be more than 1')
                .max(10, 'Value should be less than 10'),
              duration: yup
                .number()
                .required()
                .min(2, 'Value should be more than 2')
                .max(12, 'Value should be less than 12'),
              monthlyPrice: yup.number().min(1, 'Value should be more than 1').required('This is a required field'),
            }),
          otherwise: yup.object().nullable(),
        }),
        multiplePriceList: yup
          .array()
          .of(
            yup.object().shape({
              cost: yup.number().when('paymentOptions', {
                is: values => values?.includes('PerSession'),
                then: yup
                  .number()
                  .typeError('Please enter a valid number')
                  .min(1, 'Cost must be greater than or equal to 1')
                  .required('This is a required field'),
                otherwise: yup
                  .number()
                  .typeError('Please enter a valid number')
                  .min(1, 'Session cost must be greater than or equal to 1')
                  .required('This is a required field'),
              }),
              packageCost: yup.number().when('paymentOptions', {
                is: values => values?.includes('SessionsPackage'),
                then: yup
                  .number()
                  .typeError('Please enter a valid number')
                  .min(1, 'Cost must be greater than or equal to 1')
                  .required('This is a required field'),
                otherwise: yup
                  .number()
                  .typeError('Please enter a valid number')
                  .min(1, 'Package cost must be greater than or equal to 1')
                  .required('This is a required field'),
              }),
            }),
          )
          .test('unique-cost', 'Cost values must not all be the same', function (values) {
            const uniqueCosts = new Set(values.map(item => item.cost));
            return uniqueCosts.size === values.length;
          }),
        coachPaysStripeFee: yup.bool(),
      })}
      onSubmit={saveToStorageHandler}
    >
      {formProps => {
        const renderCardBody = (
          <Form id="create-contribution-form" onKeyDown={handleFormKeyDown}>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={8} style={smView ? {} : { paddingBottom: '0.5rem' }}>
                <FieldArray name="paymentOptions">
                  {({ push, remove, form }) => {
                    const changeHandler = ({ target: { value, checked } }) => {
                      if (checked) {
                        push(value);
                        let sessionNumbers = form.values?.packageSessionNumbers;
                        if (
                          (value === 'SessionsPackage' &&
                            !form.values.paymentOptions.includes('FreeSessionsPackage')) ||
                          (value === 'FreeSessionsPackage' && !form.values.paymentOptions.includes('SessionsPackage'))
                        ) {
                          sessionNumbers = DEFAULT_PACKAGE_SIZE;
                        }
                        if (value === 'SessionsPackage') {
                          // if (formProps.values.paymentType === 'Simple') {
                          //   form.setFieldValue('taxType', 'Exclusive');
                          // } else {
                          form.setFieldValue('taxType', 'No');
                          // }

                          form.setFieldValue('packageSessionNumbers', sessionNumbers);

                          formProps.setFieldValue(
                            'packageCost',
                            Math.fround(form.values.cost * sessionNumbers).toFixed(2),
                          );
                          const updatedMultiplePricePerSession = formProps.values.multiplePriceList.map(session => {
                            return {
                              ...session,
                              packageCost: Math.fround(session.cost * sessionNumbers).toFixed(2),
                            };
                          });
                          formProps.setFieldValue('multiplePriceList', updatedMultiplePricePerSession);
                        }
                        if (value === 'PerSession' && formProps.values.paymentOptions.includes('SessionsPackage')) {
                          // if (formProps.values.paymentType === 'Simple') {
                          //   form.setFieldValue('taxType', 'Exclusive');
                          // } else {
                          form.setFieldValue('taxType', 'No');
                          // }
                          formProps.setFieldValue(
                            'cost',
                            Math.fround(form.values.packageCost * sessionNumbers).toFixed(2),
                          );
                          const updatedMultiplePricePerSession = formProps.values.multiplePriceList.map(session => {
                            return {
                              ...session,
                              cost: Math.fround(session.packageCost / sessionNumbers).toFixed(2),
                            };
                          });
                          formProps.setFieldValue('multiplePriceList', updatedMultiplePricePerSession);
                        }
                        if (value === 'FreeSessionsPackage') {
                          form.setFieldValue('packageSessionNumbers', sessionNumbers);
                        }
                        if (value === 'MonthlySessionSubscription') {
                          form.setFieldValue('taxType', 'No');
                          form.setFieldValue('monthlySessionSubscriptionInfo[sessionCount]', 4);
                          form.setFieldValue('monthlySessionSubscriptionInfo[duration]', 6);
                          form.setFieldValue('monthlySessionSubscriptionInfo[monthlyPrice]', '');
                        }
                      } else {
                        const idx = form.values.paymentOptions.indexOf(value);
                        if (value === 'SessionsPackage') {
                          form.setFieldValue('packageDiscount', false);
                          form.setFieldValue('packageSessionDiscountPercentage', '');
                          form.setFieldValue('packageCost', '');
                          const updatedMultiplePricePerSession = formProps.values.multiplePriceList.map(session => {
                            return {
                              ...session,
                              packageCost: null,
                            };
                          });
                          formProps.setFieldValue('multiplePriceList', updatedMultiplePricePerSession);
                        }
                        if (value === 'PerSession') {
                          form.setFieldValue('cost', '');
                          const updatedMultiplePricePerSession = formProps.values.multiplePriceList.map(session => {
                            return {
                              ...session,
                              cost: null,
                            };
                          });
                          formProps.setFieldValue('multiplePriceList', updatedMultiplePricePerSession);
                        }
                        if (value === 'MonthlySessionSubscription') {
                          // if (formProps.values.paymentType === 'Simple') {
                          //   form.setFieldValue('taxType', 'Exclusive');
                          // } else {
                          form.setFieldValue('taxType', 'No');
                          // }
                          form.setFieldValue('monthlySessionSubscriptionInfo[monthlyPrice]', '');
                        }
                        if (
                          (value === 'SessionsPackage' &&
                            !formProps.values.paymentOptions.includes('FreeSessionsPackage')) ||
                          (value === 'FreeSessionsPackage' &&
                            !formProps.values.paymentOptions.includes('SessionsPackage'))
                        ) {
                          form.setFieldValue('packageSessionNumbers', '');
                        }

                        remove(idx);
                      }
                    };
                    const hasNoSelectionError =
                      !isUndefined(formProps.errors.paymentOptions) &&
                      typeof formProps.errors.paymentOptions === 'string' &&
                      form.values.paymentOptions?.length <= 0;

                    return (
                      <Grid container>
                        <FormControlFullWidthStyled error={hasNoSelectionError} fullWidth>
                          <Grid item sm={12}>
                            {hasNoSelectionError && (
                              <FormLabel className="mb-3" component="legend">
                                Pick at least one option
                              </FormLabel>
                            )}
                          </Grid>
                          <Grid item sm={12}>
                            <CustomExpansionPanel
                              bodyPadding={mobileView ? '20px 10px !important' : '20px 24px !important'}
                              headerPadding={mobileView ? '0px 2px !important' : '0px 18px'}
                              minHeight="30px"
                              summaryTitle={
                                <StyledTooltipWrap>
                                  <FormControlLabelStyled
                                    name={FORM_FIELDS.PAYMENT_OPTIONS}
                                    control={<BlueCheckboxForAccordianSummaryStyled color="primary" />}
                                    label="Free Session"
                                    disabled={shouldBeDisabled}
                                    value={PAYMENT_OPTIONS.FREE}
                                    onChange={changeHandler}
                                    checked={form.values.paymentOptions.includes(PAYMENT_OPTIONS.FREE)}
                                    style={{
                                      fontSize: '18px',
                                      fontStyle: 'normal',
                                      fontWeight: 500,
                                      lineHeight: '30px',
                                      letterSpacing: '0.1px',
                                      margin: '0 1rem 0 0px',
                                    }}
                                  />
                                  <StyledTooltip
                                    title="If you select this option, you will have a free link where clients can join session for free."
                                    arrow
                                    enterTouchDelay={TOOLTIP.ENTER_DELAY}
                                    leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                                  >
                                    <InfoIcon fontSize="small" htmlColor={colors.lightBrown} />
                                  </StyledTooltip>
                                </StyledTooltipWrap>
                              }
                              // mobileView={mobileView}
                              // expand={false}
                              disabledExpansion
                            />
                          </Grid>
                          <FieldArray name="multiplePriceList">
                            {({ push, remove, form }) => {
                              return (
                                <CustomExpansionPanel
                                  // asyncContainerPadding
                                  bodyPadding={mobileView ? '20px 10px !important' : '20px 24px !important'}
                                  headerPadding={mobileView ? '0px 2px !important' : '0px 18px'}
                                  summaryTitle={
                                    <StyledTooltipWrap>
                                      <FormControlLabelStyled
                                        name={FORM_FIELDS.PAYMENT_OPTIONS}
                                        control={<BlueCheckboxForAccordianSummaryStyled color="primary" />}
                                        label="Pay Per Session"
                                        value="PerSession"
                                        onChange={changeHandler}
                                        disabled={
                                          shouldBeDisabled ||
                                          (formProps.values.paymentType === 'Simple' && transfersEnabled === false) ||
                                          (formProps.values.paymentType === 'Advance' &&
                                            standardAccountTransfersEnabled === false)
                                        }
                                        checked={form.values.paymentOptions.includes('PerSession')}
                                        style={{
                                          fontSize: '18px',
                                          fontStyle: 'normal',
                                          fontWeight: 500,
                                          lineHeight: '30px',
                                          letterSpacing: '0.1px',
                                          margin: '0 1rem 0 0px',
                                        }}
                                      />
                                      <StyledTooltip
                                        title="Client price per single session"
                                        arrow
                                        enterTouchDelay={TOOLTIP.ENTER_DELAY}
                                        leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                                      >
                                        <InfoIcon fontSize="small" htmlColor={colors.lightBrown} />
                                      </StyledTooltip>
                                    </StyledTooltipWrap>
                                  }
                                  summarySubTitle={
                                    <div style={{ paddingLeft: 9 }}>
                                      {form.values.paymentOptions.includes('PerSession') &&
                                        `${
                                          form?.values?.multiplePriceList?.length > 1 ? 'Multiple Prices:' : 'Price:'
                                        } ${form?.values?.multiplePriceList
                                          ?.map(k => `${form.values.defaultSymbol || '$'}${k.cost}`)
                                          ?.join(', ')}`}
                                      {/* {form.values.paymentOptions.includes('PerSession') &&
                                        `${form.values.defaultSymbol || '$'}${form.values?.cost || 0} per session`} */}
                                    </div>
                                  }
                                  // mobileView={mobileView}
                                  expand={form.values.paymentOptions.includes('PerSession')}
                                  disabledExpansion={!form.values.paymentOptions.includes('PerSession')}
                                  rootProps={{
                                    classes: {
                                      root: 'expansionpanel__rootclass',
                                    },
                                  }}
                                >
                                  <Grid container item>
                                    <Grid item xs={12}>
                                      {formProps.values.paymentOptions.includes('PerSession') &&
                                        form?.values?.multiplePriceList?.map((c, i) => {
                                          return (
                                            <div
                                              style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'flex-start',
                                                // ,borderBottom:`1px solid #949494`,
                                                marginTop: i === 1 && '8px',
                                              }}
                                            >
                                              <Grid item sm={4} xs={11}>
                                                <InputStyled
                                                  startAdornment={
                                                    form.values.defaultSymbol ? form.values.defaultSymbol : '$'
                                                  }
                                                  type="text"
                                                  variant="outlined"
                                                  name={`multiplePriceList[${i}].cost`}
                                                  fullWidth
                                                  onChange={({ target: { value } }) => {
                                                    if (!/\D/.test(value)) {
                                                      if (
                                                        form.values.paymentOptions.includes('SessionsPackage') &&
                                                        form.values.paymentOptions.includes('PerSession')
                                                      ) {
                                                        const parsedValue = value;
                                                        form.setFieldValue(`multiplePriceList[${i}]`, {
                                                          packageCost: isNaN(parsedValue)
                                                            ? 0
                                                            : parsedValue * form?.values?.packageSessionNumbers,
                                                          cost: isNaN(parsedValue) ? '' : parsedValue,
                                                        });
                                                      } else if (form.values.paymentOptions.includes('PerSession')) {
                                                        const parsedValue = value;
                                                        form.setFieldValue(`multiplePriceList[${i}]`, {
                                                          packageCost: null,
                                                          cost: isNaN(parsedValue) ? '' : parsedValue,
                                                        });
                                                      }
                                                    }
                                                    if (i === 0) {
                                                      form.setFieldValue(`cost`, isNaN(value) ? '' : value);
                                                      if (form.values.paymentOptions.includes('SessionsPackage')) {
                                                        form.setFieldValue(
                                                          `packageCost`,
                                                          isNaN(value)
                                                            ? ''
                                                            : value * form?.values?.packageSessionNumbers,
                                                        );
                                                      }
                                                    }
                                                    // Ensure the following line is adjusted accordingly based on your requirements
                                                    // Set to empty string if NaN
                                                  }}
                                                  margin="dense"
                                                  label=""
                                                  // label={
                                                  //   i > 0
                                                  //     ? ``
                                                  //     : `Total Price  ${
                                                  //         form.values.defaultSymbol
                                                  //           ? form.values.defaultSymbol
                                                  //           : '$'
                                                  //       }`
                                                  // }
                                                  disabled={form?.values?.multiplePriceList[i]?.id && shouldBeDisabled}
                                                />
                                              </Grid>
                                              <Grid item sm={1} xs={1} className="ml-3" style={{ paddingTop: '5px' }}>
                                                {(form?.values?.multiplePriceList || [])?.length > 1 &&
                                                  i >= 0 &&
                                                  (form?.values?.multiplePriceList[i]?.id && shouldBeDisabled ? (
                                                    <StyledIconDisabled
                                                      onClick={() => {
                                                        if (
                                                          form?.values?.multiplePriceList[i]?.id &&
                                                          shouldBeDisabled
                                                        ) {
                                                        } else {
                                                          remove(i);
                                                        }
                                                      }}
                                                      path={mdiMinusCircleOutline}
                                                      size={1}
                                                    />
                                                  ) : (
                                                    <StyledIcon
                                                      onClick={() => {
                                                        if (
                                                          form?.values?.multiplePriceList[i]?.id &&
                                                          shouldBeDisabled
                                                        ) {
                                                        } else {
                                                          remove(i);
                                                        }
                                                      }}
                                                      path={mdiMinusCircleOutline}
                                                      size={1}
                                                    />
                                                  ))}
                                              </Grid>
                                            </div>
                                          );
                                        })}
                                    </Grid>
                                    <Grid sm={4} xs={12} item style={{ marginTop: '1.25rem' }}>
                                      <StyledLink
                                        onClick={() => {
                                          if (formProps.values.paymentOptions.includes('SessionsPackage')) {
                                            push({ cost: 0, packageCost: 0 });
                                          } else {
                                            push({ cost: 0, packageCost: null });
                                          }
                                        }}
                                      >
                                        <Icon
                                          path={mdiPlus}
                                          size={1}
                                          color={colors.darkOceanBlue}
                                          width="15px"
                                          height="15px"
                                        />
                                        <span>Add Another Price</span>
                                      </StyledLink>
                                    </Grid>
                                    {repeatingError && (
                                      <Grid style={{ marginTop: '1.25rem' }} sm={12} xs={12}>
                                        <CommonErrorMessage
                                          align="left"
                                          color={'red'}
                                          message="Per Session Cost must not be same."
                                        />
                                      </Grid>
                                    )}
                                  </Grid>
                                </CustomExpansionPanel>
                              );
                            }}
                          </FieldArray>
                          <Grid item sm={12}>
                            <CustomExpansionPanel
                              bodyPadding={mobileView ? '20px 10px !important' : '20px 24px !important'}
                              headerPadding={mobileView ? '0px 2px !important' : '0px 18px'}
                              minHeight="30px"
                              // asyncContainerPadding={mobileView}
                              summaryTitle={
                                <StyledTooltipWrap>
                                  <FormControlLabelStyled
                                    name={FORM_FIELDS.PAYMENT_OPTIONS}
                                    control={<BlueCheckboxForAccordianSummaryStyled color="primary" />}
                                    label="Free Sessions Packages"
                                    value="FreeSessionsPackage"
                                    onChange={changeHandler}
                                    disabled={shouldBeDisabled}
                                    checked={form.values.paymentOptions.includes('FreeSessionsPackage')}
                                    style={{
                                      marginLeft: 0,
                                      marginBottom: 0,
                                    }}
                                  />
                                  <StyledTooltip
                                    title="If you select this option, you will have a free link where clients can join packages for free."
                                    arrow
                                    enterTouchDelay={TOOLTIP.ENTER_DELAY}
                                    leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                                  >
                                    <InfoIcon fontSize="small" htmlColor={colors.lightBrown} />
                                  </StyledTooltip>
                                </StyledTooltipWrap>
                              }
                              summarySubTitle={
                                <div style={{ paddingLeft: '6px' }}>
                                  {form.values.paymentOptions.includes('FreeSessionsPackage') &&
                                    `${form.values?.packageSessionNumbers || 0} Sessions`}
                                </div>
                              }
                              // mobileView={mobileView}
                              expand={form.values.paymentOptions.includes('FreeSessionsPackage')}
                              disabledExpansion={!form.values.paymentOptions.includes('FreeSessionsPackage')}
                            >
                              {formProps.values.paymentOptions.includes('FreeSessionsPackage') && (
                                <>
                                  <Grid container spacing={2}>
                                    <Grid item sm={6} xs={12}>
                                      <Grid item sm={8} xs={12}>
                                        <StyledHeader id="lbl-input-number-of-sessions">
                                          Number of Sessions
                                        </StyledHeader>
                                        <InputStyled
                                          labelId="lbl-input-number-of-sessions"
                                          variant="outlined"
                                          margin="dense"
                                          type="text"
                                          name="packageSessionNumbers"
                                          fullWidth
                                          label=""
                                          disabled={shouldBeDisabled}
                                          onChange={({ target: { value } }) => {
                                            // if (!value) {
                                            formProps.setFieldValue('packageSessionNumbers', value);
                                            // }
                                            if (!INPUT_NUMBERS_VALIDATION_REGEX.test(value)) {
                                              if (
                                                form.values.paymentOptions.includes('SessionsPackage') &&
                                                form.values.paymentOptions.includes('PerSession')
                                              ) {
                                                const updatedMultiplePricePerSession = formProps.values.multiplePriceList.map(
                                                  session => {
                                                    return {
                                                      ...session,
                                                      packageCost: Math.fround(session.cost * (value || 0)).toFixed(2),
                                                    };
                                                  },
                                                );
                                                formProps.setFieldValue(
                                                  'multiplePriceList',
                                                  updatedMultiplePricePerSession,
                                                );
                                              } else {
                                                // formProps.setFieldValue('packageSessionNumbers', value);
                                              }
                                            }
                                          }}
                                          onBlur={({ target: { value } }) => {
                                            if (!value) {
                                              const numbers = 0;
                                              formProps.setFieldValue('packageSessionNumbers', numbers);
                                              if (!INPUT_NUMBERS_VALIDATION_REGEX.test(numbers)) {
                                                if (
                                                  form.values.paymentOptions.includes('SessionsPackage') &&
                                                  form.values.paymentOptions.includes('PerSession')
                                                ) {
                                                  const updatedMultiplePricePerSession = formProps.values.multiplePriceList.map(
                                                    session => {
                                                      return {
                                                        ...session,
                                                        packageCost: Math.fround(session.cost * numbers).toFixed(2),
                                                      };
                                                    },
                                                  );
                                                  formProps.setFieldValue(
                                                    'multiplePriceList',
                                                    updatedMultiplePricePerSession,
                                                  );
                                                } else {
                                                  // formProps.setFieldValue('packageSessionNumbers', value);
                                                }
                                              }
                                            }
                                          }}
                                        />
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </>
                              )}
                            </CustomExpansionPanel>
                          </Grid>

                          <Grid item sm={12}>
                            <FieldArray name="multiplePriceList">
                              {({ push, remove, form }) => {
                                return (
                                  <CustomExpansionPanel
                                    // asyncContainerPadding
                                    bodyPadding={mobileView ? '20px 10px !important' : '20px 24px !important'}
                                    headerPadding={mobileView ? '0px 2px !important' : '0px 18px'}
                                    summaryTitle={
                                      <StyledTooltipWrap>
                                        <FormControlLabelStyled
                                          name={FORM_FIELDS.PAYMENT_OPTIONS}
                                          control={<BlueCheckboxForAccordianSummaryStyled color="primary" />}
                                          label="Session Packages"
                                          value="SessionsPackage"
                                          onChange={changeHandler}
                                          disabled={
                                            shouldBeDisabled ||
                                            (formProps.values.paymentType === 'Simple' && transfersEnabled === false) ||
                                            (formProps.values.paymentType === 'Advance' &&
                                              standardAccountTransfersEnabled === false)
                                          }
                                          checked={form.values.paymentOptions.includes('SessionsPackage')}
                                          style={{
                                            fontSize: '18px',
                                            fontStyle: 'normal',
                                            fontWeight: 500,
                                            lineHeight: '30px',
                                            letterSpacing: '0.1px',
                                            margin: '0 1rem 0 0px',
                                          }}
                                        />
                                        <StyledTooltip
                                          title="Price for a package of sessions"
                                          arrow
                                          enterTouchDelay={TOOLTIP.ENTER_DELAY}
                                          leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                                        >
                                          <InfoIcon fontSize="small" htmlColor={colors.lightBrown} />
                                        </StyledTooltip>
                                      </StyledTooltipWrap>
                                    }
                                    summarySubTitle={
                                      <div style={{ paddingLeft: 9 }}>
                                        {/* {form.values.paymentOptions.includes('SessionsPackage') &&
                                          `${form.values?.packageSessionNumbers || 0} Sessions, ${form.values.defaultSymbol || '$'
                                          }${form.values?.packageCost || 0} per session package`} */}
                                        {form.values.paymentOptions.includes('SessionsPackage') &&
                                          `${
                                            form?.values?.multiplePriceList?.length > 1 ? 'Multiple Prices:' : 'Price:'
                                          } ${form?.values?.multiplePriceList
                                            ?.map(k => `${form.values.defaultSymbol || '$'}${k.packageCost}`)
                                            ?.join(', ')}`}
                                      </div>
                                    }
                                    // mobileView={mobileView}
                                    expand={form.values.paymentOptions.includes('SessionsPackage')}
                                    disabledExpansion={!form.values.paymentOptions.includes('SessionsPackage')}
                                  >
                                    {formProps.values.paymentOptions.includes('SessionsPackage') && (
                                      <>
                                        <Grid container spacing={2}>
                                          <Grid item sm={6} xs={12}>
                                            <Grid item sm={8} xs={12}>
                                              <Grid container item direction="column">
                                                <StyledHeader id="lbl-input-number-of-sessions">
                                                  Number of Sessions
                                                </StyledHeader>
                                                <InputStyled
                                                  labelId="lbl-input-number-of-sessions"
                                                  variant="outlined"
                                                  margin="dense"
                                                  type="text"
                                                  name="packageSessionNumbers"
                                                  fullWidth
                                                  label=""
                                                  disabled={shouldBeDisabled}
                                                  onChange={({ target: { value } }) => {
                                                    formProps.setFieldValue('packageSessionNumbers', value);
                                                    if (!INPUT_NUMBERS_VALIDATION_REGEX.test(value)) {
                                                      if (
                                                        form.values.paymentOptions.includes('SessionsPackage') &&
                                                        form.values.paymentOptions.includes('PerSession')
                                                      ) {
                                                        // formProps.setFieldValue('packageSessionNumbers', value);
                                                        const updatedMultiplePricePerSession = formProps.values.multiplePriceList.map(
                                                          session => {
                                                            return {
                                                              ...session,
                                                              packageCost: Math.fround(
                                                                session.cost * (value || 0),
                                                              ).toFixed(2),
                                                            };
                                                          },
                                                        );
                                                        formProps.setFieldValue(
                                                          'multiplePriceList',
                                                          updatedMultiplePricePerSession,
                                                        );
                                                      } else {
                                                        // formProps.setFieldValue('packageSessionNumbers', value);
                                                      }
                                                      // formProps.setFieldValue(
                                                      //   'packageCost',
                                                      //   Math.fround(formProps.values.cost * value).toFixed(2),
                                                      // );
                                                    }
                                                  }}
                                                  onBlur={({ target: { value } }) => {
                                                    if (!value) {
                                                      const numbers = 0;
                                                      formProps.setFieldValue('packageSessionNumbers', numbers);
                                                      if (!INPUT_NUMBERS_VALIDATION_REGEX.test(numbers)) {
                                                        if (
                                                          form.values.paymentOptions.includes('SessionsPackage') &&
                                                          form.values.paymentOptions.includes('PerSession')
                                                        ) {
                                                          const updatedMultiplePricePerSession = formProps.values.multiplePriceList.map(
                                                            session => {
                                                              return {
                                                                ...session,
                                                                packageCost: Math.fround(
                                                                  session.cost * numbers,
                                                                ).toFixed(2),
                                                              };
                                                            },
                                                          );
                                                          formProps.setFieldValue(
                                                            'multiplePriceList',
                                                            updatedMultiplePricePerSession,
                                                          );
                                                        } else {
                                                          // formProps.setFieldValue('packageSessionNumbers', value);
                                                        }
                                                      }
                                                    }
                                                  }}
                                                />
                                              </Grid>
                                            </Grid>
                                          </Grid>
                                          {mobileView ? null : <Grid item sm={6} xs={12} />}
                                          <Grid item sm={6} xs={12}>
                                            <Grid item sm={9} xs={12}>
                                              <Grid container item direction="column">
                                                <StyledHeader id="lbl-input-price-per-package">
                                                  Price Per Package
                                                </StyledHeader>
                                                {form?.values?.multiplePriceList?.map((c, i) => {
                                                  return (
                                                    <div
                                                      style={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        // alignItems: 'center',
                                                        marginTop: i === 1 && '8px',
                                                      }}
                                                    >
                                                      <Grid item sm={11} xs={12}>
                                                        <InputStyled
                                                          startAdornment={
                                                            form.values.defaultSymbol ? form.values.defaultSymbol : '$'
                                                          }
                                                          type="text"
                                                          variant="outlined"
                                                          name={`multiplePriceList[${i}].packageCost`}
                                                          value={
                                                            form.values.multiplePriceList[i].packageCost.length === 0
                                                              ? 0
                                                              : parseFloat(form.values.multiplePriceList[i].packageCost)
                                                          }
                                                          fullWidth
                                                          onChange={({ target: { value } }) => {
                                                            if (!/\D/.test(value)) {
                                                              if (
                                                                form.values.paymentOptions.includes(
                                                                  'SessionsPackage',
                                                                ) &&
                                                                form.values.paymentOptions.includes('PerSession')
                                                              ) {
                                                                form.setFieldValue(`multiplePriceList[${i}]`, {
                                                                  packageCost: value,
                                                                  cost: value / form?.values?.packageSessionNumbers,
                                                                });
                                                              } else if (
                                                                form.values.paymentOptions.includes('SessionsPackage')
                                                              ) {
                                                                form.setFieldValue(`multiplePriceList[${i}]`, {
                                                                  packageCost: value,
                                                                  cost: null,
                                                                });
                                                              }
                                                            }
                                                            form.setFieldValue(
                                                              `packageCost`,
                                                              form.values.multiplePriceList[0].packageCost,
                                                            );
                                                          }}
                                                          margin="dense"
                                                          label=""
                                                          // label={
                                                          //   i > 0
                                                          //     ? ``
                                                          //     : `Total Price  ${
                                                          //         form.values.defaultSymbol
                                                          //           ? form.values.defaultSymbol
                                                          //           : '$'
                                                          //       }`
                                                          // }
                                                          disabled={
                                                            form?.values?.multiplePriceList[i]?.id && shouldBeDisabled
                                                          }
                                                        />
                                                      </Grid>
                                                      {(form?.values?.multiplePriceList || [])?.length > 1 && (
                                                        <Grid
                                                          item
                                                          sm={1}
                                                          xs={1}
                                                          className="ml-3"
                                                          style={{ paddingTop: '5px' }}
                                                        >
                                                          {(form?.values?.multiplePriceList || [])?.length > 1 &&
                                                            i >= 0 &&
                                                            (form?.values?.multiplePriceList[i]?.id &&
                                                            shouldBeDisabled ? (
                                                              <StyledIconDisabled
                                                                onClick={() => {
                                                                  if (
                                                                    form?.values?.multiplePriceList[i]?.id &&
                                                                    shouldBeDisabled
                                                                  ) {
                                                                  } else {
                                                                    remove(i);
                                                                  }
                                                                }}
                                                                path={mdiMinusCircleOutline}
                                                                size={1}
                                                              />
                                                            ) : (
                                                              <StyledIcon
                                                                onClick={() => {
                                                                  if (
                                                                    form?.values?.multiplePriceList[i]?.id &&
                                                                    shouldBeDisabled
                                                                  ) {
                                                                  } else {
                                                                    remove(i);
                                                                  }
                                                                }}
                                                                path={mdiMinusCircleOutline}
                                                                size={1}
                                                              />
                                                            ))}
                                                        </Grid>
                                                      )}
                                                    </div>
                                                  );
                                                })}
                                                {/* <InputStyled
                                            startAdornment={
                                              formProps.values.defaultSymbol ? formProps.values.defaultSymbol : '$'
                                            }
                                            labelId="lbl-input-price-per-package"
                                            variant="outlined"
                                            margin="dense"
                                            type="number"
                                            name="packageCost"
                                            fullWidth
                                            label=""
                                            disabled={shouldBeDisabled}
                                            onChange={({ target: { value } }) => {
                                              if (/[+-]?([0-9]*[.])?[0-9]+/.test(value)) {
                                                if (formProps.values.packageSessionNumbers === 0) {
                                                  formProps.setFieldValue('packageCost', value);
                                                  return;
                                                }
                                                formProps.setFieldValue('packageCost', value);
                                                formProps.setFieldValue(
                                                  'cost',
                                                  Math.fround(value / formProps.values.packageSessionNumbers).toFixed(
                                                    2,
                                                  ),
                                                );
                                              } else if (value.length < 2) {
                                                if (formProps.values.packageSessionNumbers === 0) {
                                                  formProps.setFieldValue('packageCost', value);
                                                  return;
                                                }
                                                formProps.setFieldValue('packageCost', value);
                                                formProps.setFieldValue(
                                                  'cost',
                                                  Math.fround(value / formProps.values.packageSessionNumbers).toFixed(
                                                    2,
                                                  ),
                                                );
                                              }
                                            }}
                                            onBlur={({ target: { value } }) => {
                                              if (!value) {
                                                formProps.setFieldValue('packageCost', 0);
                                              }
                                            }}
                                          /> */}
                                              </Grid>
                                              <Grid sm={11} xs={12} item style={{ marginTop: '1.25rem' }}>
                                                <StyledLink
                                                  onClick={() => {
                                                    push({ cost: 0, packageCost: 0 });
                                                  }}
                                                >
                                                  <Icon
                                                    path={mdiPlus}
                                                    size={1}
                                                    color={colors.darkOceanBlue}
                                                    width="15px"
                                                    height="15px"
                                                  />
                                                  <span>Add Another Price</span>
                                                </StyledLink>
                                              </Grid>
                                            </Grid>
                                            {repeatingError && (
                                              <Grid style={{ marginTop: '1.25rem' }} sm={12} xs={12}>
                                                <CommonErrorMessage
                                                  align="left"
                                                  color={'red'}
                                                  message="Per Package Cost must not be same."
                                                />
                                              </Grid>
                                            )}
                                          </Grid>
                                          {mobileView ? null : <Grid item sm={6} xs={12} />}
                                          <Grid item sm={6} xs={12} style={{ paddingBottom: 0 }}>
                                            <Grid item sm={12} xs={12}>
                                              <StyledTooltipWrap>
                                                <FormControlLabelStyled
                                                  name="packageDiscount"
                                                  control={<BlueCheckbox color="primary" />}
                                                  label="Package Discount"
                                                  checked={form.values.packageDiscount}
                                                  onChange={({ target: { checked } }) => {
                                                    if (checked) {
                                                      form.setFieldValue('packageSessionDiscountPercentage', 10);
                                                    } else {
                                                      form.setFieldValue('packageSessionDiscountPercentage', '');
                                                    }
                                                    form.setFieldValue('packageDiscount', !form.values.packageDiscount);
                                                  }}
                                                  disabled={
                                                    shouldBeDisabled ||
                                                    (formProps.values.paymentType === 'Simple' &&
                                                      transfersEnabled === false) ||
                                                    (formProps.values.paymentType === 'Advance' &&
                                                      standardAccountTransfersEnabled === false)
                                                  }
                                                />
                                                <StyledTooltip
                                                  title="Discount that is applied to the package price to incentivize clients to pay in full for the package"
                                                  arrow
                                                  enterTouchDelay={TOOLTIP.ENTER_DELAY}
                                                  leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                                                  className="mb-2"
                                                >
                                                  <InfoIcon fontSize="small" htmlColor={colors.lightBrown} />
                                                </StyledTooltip>
                                              </StyledTooltipWrap>
                                            </Grid>
                                          </Grid>
                                          {mobileView ? null : <Grid item sm={6} xs={12} />}
                                          {formProps.values.packageDiscount && (
                                            <Grid item sm={6} xs={12}>
                                              <Grid item sm={8} xs={7}>
                                                <InputStyled
                                                  fullWidth
                                                  style={{ margin: 0 }}
                                                  startAdornment="%"
                                                  type="text"
                                                  variant="outlined"
                                                  name="packageSessionDiscountPercentage"
                                                  label=""
                                                  margin="dense"
                                                  onChange={({ target: { value } }) => {
                                                    if (!/\D/.test(value))
                                                      if (value < 100) {
                                                        formProps.setFieldValue(
                                                          'packageSessionDiscountPercentage',
                                                          value,
                                                        );
                                                      }
                                                  }}
                                                  disabled={
                                                    shouldBeDisabled ||
                                                    (formProps.values.paymentType === 'Simple' &&
                                                      transfersEnabled === false) ||
                                                    (formProps.values.paymentType === 'Advance' &&
                                                      standardAccountTransfersEnabled === false)
                                                  }
                                                />
                                              </Grid>
                                            </Grid>
                                          )}
                                        </Grid>
                                      </>
                                    )}
                                  </CustomExpansionPanel>
                                );
                              }}
                            </FieldArray>
                          </Grid>

                          {(initialdefaultcurrencycode !== form.values.defaultCurrency) === false &&
                            showMonthly === true && (
                              <Grid item sm={12}>
                                <CustomExpansionPanel
                                  bodyPadding={mobileView ? '20px 10px !important' : '20px 24px !important'}
                                  headerPadding={mobileView ? '0px 2px !important' : '0px 18px'}
                                  minHeight="30px"
                                  // asyncContainerPadding={mobileView}
                                  summaryTitle={
                                    <StyledTooltipWrap>
                                      <FormControlLabelStyled
                                        name={FORM_FIELDS.PAYMENT_OPTIONS}
                                        control={<BlueCheckboxForAccordianSummaryStyled color="primary" />}
                                        label="Monthly Subscription"
                                        value="MonthlySessionSubscription"
                                        onChange={changeHandler}
                                        disabled={
                                          isFeeDisabled ||
                                          shouldBeDisabled ||
                                          initialdefaultcurrencycode !== form.values.defaultCurrency ||
                                          (formProps.values.paymentType === 'Simple' && transfersEnabled === false) ||
                                          (formProps.values.paymentType === 'Advance' &&
                                            standardAccountTransfersEnabled === false)
                                        }
                                        checked={form.values.paymentOptions.includes('MonthlySessionSubscription')}
                                        style={{
                                          marginLeft: 0,
                                          marginBottom: 0,
                                        }}
                                      />
                                      <StyledTooltip
                                        title="For automatic recurring monthly payments & sessions with your clients"
                                        arrow
                                        enterTouchDelay={TOOLTIP.ENTER_DELAY}
                                        leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                                      >
                                        <InfoIcon fontSize="small" htmlColor={colors.lightBrown} />
                                      </StyledTooltip>
                                    </StyledTooltipWrap>
                                  }
                                  summarySubTitle={
                                    <div style={{ paddingLeft: '6px' }}>
                                      {form.values.paymentOptions.includes('MonthlySessionSubscription') &&
                                        `${form.values?.defaultSymbol || '$'}${formatMoney(
                                          form.values?.monthlySessionSubscriptionInfo?.monthlyPrice || 0,
                                        )} per month, ${
                                          form.values?.monthlySessionSubscriptionInfo?.sessionCount || 0
                                        } sessions per month, ${
                                          form.values?.monthlySessionSubscriptionInfo?.duration || 0
                                        } months duration of subscription`}
                                    </div>
                                  }
                                  // mobileView={mobileView}
                                  expand={form.values.paymentOptions.includes('MonthlySessionSubscription')}
                                  disabledExpansion={!form.values.paymentOptions.includes('MonthlySessionSubscription')}
                                >
                                  {formProps.values.paymentOptions.includes('MonthlySessionSubscription') &&
                                    showMonthly && (
                                      <>
                                        <Grid container spacing={2}>
                                          <Grid item sm={6} xs={12}>
                                            <Grid item sm={8} xs={12}>
                                              <Grid container item direction="column">
                                                <StyledHeader id="lbl-input-price-per-month">
                                                  Price Per Month
                                                </StyledHeader>
                                                <InputStyled
                                                  startAdornment={
                                                    formProps.values.defaultSymbol
                                                      ? formProps.values.defaultSymbol
                                                      : '$'
                                                  }
                                                  labelId="lbl-input-price-per-month"
                                                  variant="outlined"
                                                  margin="dense"
                                                  type="number"
                                                  name="monthlySessionSubscriptionInfo[monthlyPrice]"
                                                  fullWidth
                                                  label=""
                                                  disabled={shouldBeDisabled}
                                                  onChange={({ target: { value } }) => {
                                                    if (!/\D/.test(value)) {
                                                      formProps.setFieldValue(
                                                        'monthlySessionSubscriptionInfo[monthlyPrice]',
                                                        value,
                                                      );
                                                    }
                                                  }}
                                                  onBlur={({ target: { value } }) => {
                                                    if (!value) {
                                                      formProps.setFieldValue(
                                                        'monthlySessionSubscriptionInfo[monthlyPrice]',
                                                        0,
                                                      );
                                                    }
                                                  }}
                                                />
                                              </Grid>
                                            </Grid>
                                          </Grid>
                                          {mobileView ? null : <Grid item sm={6} xs={12} />}
                                          <Grid item sm={6} xs={12}>
                                            <StyledHeader id="lbl-client-session-credits-per-month">
                                              Client Session Credits Per Month
                                            </StyledHeader>
                                            <Grid
                                              item
                                              sm={8}
                                              xs={12}
                                              style={{
                                                minWidth: 190,
                                              }}
                                            >
                                              <Grid container item direction="column">
                                                <SelectWrapStyled>
                                                  <SelectStyled
                                                    style={{
                                                      fontFamily: 'Avenir',
                                                      border: '1px solid #DFE3E4',
                                                      borderRadius: '4px',
                                                      fontSize: '1rem',
                                                      fontWeight: '400',
                                                      lineHeight: '24px',
                                                      letterSpacing: '0.15px',
                                                    }}
                                                    margin="dense"
                                                    labelId="lbl-client-session-credits-per-month"
                                                    variant="outlined"
                                                    label=""
                                                    name="monthlySessionSubscriptionInfo[sessionCount]"
                                                    fullWidth
                                                    // disabled={ }
                                                    items={sessionNumbersItems()}
                                                  />
                                                </SelectWrapStyled>
                                              </Grid>
                                            </Grid>
                                          </Grid>
                                          <Grid item sm={mobileView ? 12 : 6} xs={12}>
                                            <StyledHeader id="lbl-duration-of-subscription">
                                              Duration of Subscription
                                            </StyledHeader>
                                            <Grid item sm={8} xs={12}>
                                              <Grid container item direction="column">
                                                <SelectWrapStyled>
                                                  <SelectStyled
                                                    style={{
                                                      fontFamily: 'Avenir',
                                                      border: '1px solid #DFE3E4',
                                                      borderRadius: '4px',
                                                      fontSize: '1rem',
                                                      fontWeight: '400',
                                                      lineHeight: '24px',
                                                      letterSpacing: '0.15px',
                                                    }}
                                                    margin="dense"
                                                    labelId="lbl-duration-of-subscription"
                                                    variant="outlined"
                                                    label=""
                                                    name="monthlySessionSubscriptionInfo[duration]"
                                                    fullWidth
                                                    // disabled={ }
                                                    items={durationItems()}
                                                  />
                                                </SelectWrapStyled>
                                              </Grid>
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                      </>
                                    )}
                                </CustomExpansionPanel>
                              </Grid>
                            )}

                          <Grid item xs={12}>
                            <CustomExpansionPanel
                              bodyPadding="20px 24px !important"
                              headerPadding="0px 24px"
                              // asyncContainerPadding={mobileView}
                              summaryTitle={
                                <div
                                  style={{
                                    fontSize: '1.125rem',
                                    fontStyle: 'normal',
                                    fontWeight: 500,
                                    lineHeight: '30px',
                                    letterSpacing: '0.1px',
                                    color: 'black',
                                    marginLeft: '-1px',
                                  }}
                                >
                                  Payment Settings
                                </div>
                              }
                              summarySubTitle={
                                formProps.values.paymentOptions?.length &&
                                (formProps.values.paymentOptions.includes(PAYMENT_OPTIONS.PER_SESSION) ||
                                  formProps.values.paymentOptions.includes(PAYMENT_OPTIONS.SESSIONS_PACKAGE) ||
                                  formProps.values.paymentOptions.includes(
                                    PAYMENT_OPTIONS.MONTHLY_SESSION_SUBSCRIPTION,
                                  )) ? (
                                  <div>
                                    {[
                                      toUpper(formProps.values?.defaultCurrency),
                                      (formProps.values?.paymentType === 'Simple' && 'Cohere Payment Processor') ||
                                        (formProps.values?.paymentType === 'Advance' &&
                                          `${
                                            userDetail.firstName.charAt(0).toUpperCase() + userDetail.firstName.slice(1)
                                          }'s Stripe Account`),
                                      user?.isStandardAccount === true &&
                                        formProps?.values?.paymentType === 'Advance' &&
                                        (formProps.values?.taxType === 'No' ? 'No Tax' : formProps.values?.taxType),
                                      formProps.values?.coachPaysStripeFee ? 'Coach Pays Stripe Processing Fees' : '',
                                      formProps?.values?.isPriceHidden ? 'Prices Hidden on Landing Pages' : '',
                                    ]
                                      .filter(k => !!k)
                                      .join(', ')}
                                  </div>
                                ) : (
                                  <></>
                                )
                              }
                              // mobileView={mobileView}
                              // onToggle={setIsSettingsAccordianOpen}
                              // expand={isSettingsAccordianOpen}
                              // disabledExpansion={!isSettingsAccordianOpen}
                            >
                              <Grid container spacing={2}>
                                {(showOnlyFreeOption || user?.isStandardAccount) &&
                                  (formProps.values.paymentOptions.includes(PAYMENT_OPTIONS.PER_SESSION) ||
                                    formProps.values.paymentOptions.includes(PAYMENT_OPTIONS.SESSIONS_PACKAGE) ||
                                    formProps.values.paymentOptions.includes(
                                      PAYMENT_OPTIONS.MONTHLY_SESSION_SUBSCRIPTION,
                                    )) && (
                                    <>
                                      <Grid item sm={8} xs={8} direction="column">
                                        <StyledHeader id="lbl-currency">Currency</StyledHeader>
                                        <SelectWrapStyled>
                                          <SelectStyled
                                            style={{
                                              fontFamily: 'Avenir',
                                              border: '1px solid #DFE3E4',
                                              borderRadius: '4px',
                                              fontSize: '1rem',
                                              fontWeight: '400',
                                              lineHeight: '24px',
                                              letterSpacing: '0.15px',
                                            }}
                                            margin="dense"
                                            labelId="lbl-currency"
                                            // label=""
                                            variant="outlined"
                                            name="defaultCurrency"
                                            fullWidth
                                            disabled={isFeeDisabled}
                                            onChange={event => handleChangeCurrency(event, formProps)}
                                            value={formProps.values?.defaultCurrency}
                                            items={getCurrency(contribution)}
                                          />
                                        </SelectWrapStyled>

                                        {/* {!showOnlyFreeOption && (
                                            <Grid item container xs={12}>
                                              <StyledText>{contribution.defaultCurrency?.toUpperCase()}</StyledText>
                                            </Grid>
                                          )} */}
                                      </Grid>
                                    </>
                                  )}
                                {(formProps.values.paymentOptions.includes(PAYMENT_OPTIONS.PER_SESSION) ||
                                  formProps.values.paymentOptions.includes(PAYMENT_OPTIONS.SESSIONS_PACKAGE) ||
                                  formProps.values.paymentOptions.includes(
                                    PAYMENT_OPTIONS.MONTHLY_SESSION_SUBSCRIPTION,
                                  )) && (
                                  <>
                                    {user?.isStandardAccount && (
                                      <>
                                        <Grid item sm={8} xs={8} direction="column">
                                          <StyledHeader id="lbl-payment-processor">Payment Processor</StyledHeader>
                                          <SelectWrapStyled>
                                            <SelectStyled
                                              style={{
                                                fontFamily: 'Avenir',
                                                border: '1px solid #DFE3E4',
                                                borderRadius: '4px',
                                                fontSize: '1rem',
                                                fontWeight: '400',
                                                lineHeight: '24px',
                                                letterSpacing: '0.15px',
                                              }}
                                              margin="dense"
                                              labelId="lbl-payment-processor"
                                              label=""
                                              name="paymentType"
                                              fullWidth
                                              variant="outlined"
                                              disabled={isFeeDisabled}
                                              onChange={event => handlePaymentProcessor(event, formProps)}
                                              value={formProps.values?.paymentType}
                                              items={
                                                user.connectedStripeAccountId === null
                                                  ? [
                                                      {
                                                        value: 'Advance',
                                                        title: `${
                                                          userDetail.firstName.charAt(0).toUpperCase() +
                                                          userDetail.firstName.slice(1)
                                                        }'s Stripe Account`,
                                                      },
                                                    ]
                                                  : [
                                                      { value: 'Simple', title: 'Cohere Payment Processor' },
                                                      {
                                                        value: 'Advance',
                                                        title: `${
                                                          userDetail.firstName.charAt(0).toUpperCase() +
                                                          userDetail.firstName.slice(1)
                                                        }'s Stripe Account`,
                                                      },
                                                    ]
                                              }
                                            />
                                          </SelectWrapStyled>
                                        </Grid>
                                      </>
                                    )}

                                    {/* {user?.isStandardAccount && formProps.values?.paymentType === 'Advance' && ( */}
                                    {((formRef?.current?.values?.paymentOptions.includes(
                                      PAYMENT_OPTIONS.MONTHLY_SESSION_SUBSCRIPTION,
                                    ) === false &&
                                      formRef?.current?.values?.paymentType === 'Simple') ||
                                      formRef?.current?.values?.paymentType === 'Advance') &&
                                      (([
                                        'numan@yopmail.com',
                                        'numan@cohere.live',
                                        'jade@cohere.live',
                                        'standardhina@yopmail.com',
                                        't@cohere.live',
                                      ].includes(user?.email) &&
                                        formProps.values?.paymentType === 'Simple') ||
                                        (user?.isStandardAccount && formProps.values?.paymentType === 'Advance')) && (
                                        <>
                                          <Grid item sm={8} xs={8} direction="column">
                                            <StyledHeader id="lbl-tax-type">Tax Type</StyledHeader>
                                            <SelectWrapStyled>
                                              <SelectStyled
                                                style={{
                                                  fontFamily: 'Avenir',
                                                  border: '1px solid #DFE3E4',
                                                  borderRadius: '4px',
                                                  fontSize: '1rem',
                                                  fontWeight: '400',
                                                  lineHeight: '24px',
                                                  letterSpacing: '0.15px',
                                                }}
                                                margin="dense"
                                                labelId="lbl-tax-type"
                                                label=""
                                                name="taxType"
                                                fullWidth
                                                variant="outlined"
                                                disabled={isFeeDisabled}
                                                defaultValue={formProps.values?.taxType}
                                                isOptionDisabled={option => option.isdisabled}
                                                onChange={event => handleTaxType(event, formProps)}
                                                value={formProps.values?.taxType}
                                                items={
                                                  formProps.values?.paymentType === 'Simple'
                                                    ? [
                                                        {
                                                          value: 'Exclusive',
                                                          title: 'Exclusive',
                                                          // disabled: isDisabled,
                                                        },
                                                        {
                                                          value: 'Inclusive',
                                                          title: 'Inclusive',
                                                          // disabled: isDisabled,
                                                        },
                                                      ]
                                                    : [
                                                        {
                                                          value: 'Exclusive',
                                                          title: 'Exclusive',
                                                          disabled: isDisabled,
                                                        },
                                                        {
                                                          value: 'Inclusive',
                                                          title: 'Inclusive',
                                                          disabled: isDisabled,
                                                        },
                                                        { value: 'No', title: 'No Tax' },
                                                      ]
                                                }
                                              />
                                            </SelectWrapStyled>
                                          </Grid>
                                        </>
                                      )}
                                    {/* )} */}
                                  </>
                                )}

                                {false && (
                                  <Grid item sm={9} xs={8} direction="column">
                                    <StyledTooltipWrap style={{ marginTop: '1.25rem' }}>
                                      <FormControl>
                                        <StyledHeader id="lbl-how-client-pays">How Your Client Pays</StyledHeader>
                                      </FormControl>
                                      <StyledTooltip
                                        title="The recomended payment approach is for client to pay with the credit card. Most coaches prefer simple checkout process where your client immidately pays with the credit card. If you prefer your clients to pay via invoice, they will also have the option to pay with the bank transfer instead."
                                        arrow
                                        enterTouchDelay={TOOLTIP.ENTER_DELAY}
                                        leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                                      >
                                        <InfoIcon fontSize="small" htmlColor={colors.lightBrown} />
                                      </StyledTooltip>
                                    </StyledTooltipWrap>
                                    <SelectWrapStyled>
                                      <SelectStyled
                                        style={{
                                          fontFamily: 'Avenir',
                                          border: '1px solid #DFE3E4',
                                          borderRadius: '4px',
                                          fontSize: '1rem',
                                          fontWeight: '400',
                                          lineHeight: '24px',
                                          letterSpacing: '0.15px',
                                        }}
                                        margin="dense"
                                        labelId="lbl-how-client-pays"
                                        label=""
                                        name="taxType"
                                        fullWidth
                                        variant="outlined"
                                        disabled={isFeeDisabled}
                                        defaultValue={!user.isStandardTaxEnabled ? 'No' : contribution?.taxType}
                                        isOptionDisabled={option => option.isdisabled}
                                        onChange={event => handleTaxType(event, formProps)}
                                        value={formProps.values?.taxType}
                                        items={[
                                          {
                                            value: 'Exclusive',
                                            title: 'Exclusive',
                                            disabled: isDisabled,
                                          },
                                          {
                                            value: 'Inclusive',
                                            title: 'Inclusive',
                                            disabled: isDisabled,
                                          },
                                          { value: 'No', title: 'No Tax' },
                                        ]}
                                      />
                                    </SelectWrapStyled>
                                  </Grid>
                                )}

                                <Grid item sm={12} xs={12} direction="row">
                                  <StyledTooltipWrap>
                                    <FormControlLabelStyled
                                      name="coachPaysStripeFee"
                                      control={<BlueCheckbox color="primary" />}
                                      fontSize="16px"
                                      fontWeight="400"
                                      label="Coach Pays for Processing Fee"
                                      checked={
                                        formRef?.current?.values?.paymentOptions?.includes(PAYMENT_OPTIONS.FREE) &&
                                        formRef?.current?.values?.paymentOptions?.length === 1
                                          ? false
                                          : formProps.values.coachPaysStripeFee
                                      }
                                      onChange={formProps.handleChange('coachPaysStripeFee')}
                                      disabled={
                                        shouldBeDisabled ||
                                        isFeeDisabled ||
                                        (formRef?.current?.values?.paymentOptions?.includes(PAYMENT_OPTIONS.FREE) &&
                                          formRef?.current?.values?.paymentOptions?.length === 1)
                                      }
                                    />
                                    <StyledTooltip
                                      title="If the box is checked, you are choosing to pay the Stripe credit card processing fee. If left unchecked, your client will be charged the processing fee at checkout. The Stripe processing fee is a 2.9% plus 30 cents surcharge on top of your base pricing."
                                      arrow
                                      enterTouchDelay={TOOLTIP.ENTER_DELAY}
                                      leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                                      className="mb-2"
                                    >
                                      <InfoIcon fontSize="small" htmlColor={colors.lightBrown} />
                                    </StyledTooltip>
                                  </StyledTooltipWrap>
                                </Grid>

                                {['PerSession', 'SessionsPackage', 'MonthlySessionSubscription'].some(paymentOption =>
                                  formProps.values.paymentOptions.includes(paymentOption),
                                ) && (
                                  <>
                                    <Grid item sm={12} xs={12} direction="row">
                                      <FormControlLabelStyled
                                        name="isPriceHidden"
                                        control={<BlueCheckbox color="primary" />}
                                        fontSize="16px"
                                        fontWeight="400"
                                        label="Hide Pricing Details from Landing Page"
                                        checked={formProps?.values?.isPriceHidden}
                                        onChange={e => {
                                          formProps.setFieldValue('isPriceHidden', e.target.checked);
                                        }}
                                        disabled={shouldBeDisabled}
                                      />
                                    </Grid>
                                  </>
                                )}
                              </Grid>
                            </CustomExpansionPanel>
                          </Grid>
                        </FormControlFullWidthStyled>
                      </Grid>
                    );
                  }}
                </FieldArray>
              </Grid>
              <Grid item xs={12} sm={4} style={smView ? { paddingLeft: 0 } : { paddingTop: 0 }}>
                <div
                  style={{
                    borderRadius: '4px',
                    border: '1px solid #E7E7E7',
                    height: '100%',
                  }}
                >
                  <div
                    style={{
                      fontSize: '18px',
                      fontStyle: 'normal',
                      fontWeight: 500,
                      lineHeight: '30px',
                      letterSpacing: '0.1px',
                      minHeight: '50px',
                      padding: '10px 24px',
                      borderBottom: '1px solid #E7E7E7',
                    }}
                  >
                    Payment Summary
                  </div>

                  <StyledSummaryBodyContainer>
                    {contribution?.type === ContributionType.contributionOneToOne &&
                      (formProps.values.paymentOptions.includes('Free') ||
                        formProps.values.paymentOptions.includes('PerSession') ||
                        formProps.values.paymentOptions.includes('FreeSessionsPackage') ||
                        formProps.values.paymentOptions.includes('SessionsPackage') ||
                        formProps.values.paymentOptions.includes('MonthlySessionSubscription')) && (
                        <StyledText>The following pricing links will be created for this contribution:</StyledText>
                      )}
                    {formProps.values.paymentOptions.includes(PAYMENT_OPTIONS.FREE) &&
                      contribution?.type === ContributionType.contributionOneToOne && (
                        <SummaryPricingTextContainer>
                          <StyledHeader className="value-key">
                            Free: &nbsp;{' '}
                            <StyledText style={{ display: 'inline' }}>
                              A link will be created for clients to join for free
                            </StyledText>
                          </StyledHeader>
                        </SummaryPricingTextContainer>
                      )}
                    {formProps.values.paymentOptions.includes('FreeSessionsPackage') && (
                      <SummaryPricingTextContainer>
                        <StyledHeader className="value-key">Free package: </StyledHeader>
                        <StyledText className="body-value">
                          A free package link will be created where clients can avail free sessions
                        </StyledText>
                      </SummaryPricingTextContainer>
                    )}
                    {
                      formProps.values.paymentOptions.includes('PerSession') &&
                        formProps?.values?.multiplePriceList.map((sessionTime, i) => {
                          return (
                            <SummaryPricingTextContainer>
                              <StyledHeader className="value-key">Price per Session: </StyledHeader>
                              <StyledText className="body-value">
                                {formProps.values.defaultSymbol ? formProps.values.defaultSymbol : '$'}
                                {renderPrice(sessionTime)}
                              </StyledText>
                            </SummaryPricingTextContainer>
                          );
                        })
                      // <SummaryPricingTextContainer>
                      //   {/* <StyledHeader>Pricing Per Session Options:</StyledHeader> */}
                      //   <StyledHeader className="value-key">Price per Session:</StyledHeader>
                      // <StyledText className="body-value">
                      //   {formProps.values.defaultSymbol ? formProps.values.defaultSymbol : '$'}
                      //   {renderPrice(formProps.values)}
                      // </StyledText>
                      // </SummaryPricingTextContainer>
                    }
                    {
                      formProps.values.paymentOptions.includes('SessionsPackage') &&
                        formProps?.values?.multiplePriceList.map((sessionTime, i) => {
                          return (
                            <SummaryPricingTextContainer>
                              <StyledHeader className="value-key">Price per Package: </StyledHeader>
                              <StyledText className="body-value">
                                {formProps.values.defaultSymbol ? formProps.values.defaultSymbol : '$'}
                                {formProps.values.packageDiscount
                                  ? formatPackagePrice({
                                      packageCost: sessionTime.packageCost,
                                      packageSessionDiscountPercentage:
                                        formProps?.values?.packageSessionDiscountPercentage,
                                    })
                                  : formatMoney(sessionTime.packageCost)}
                              </StyledText>
                            </SummaryPricingTextContainer>
                          );
                        })
                      // <SummaryPricingTextContainer>
                      //   {/* <StyledHeader>Package Pricing Options:</StyledHeader> */}
                      //   <StyledHeader className="value-key">Price per Package:</StyledHeader>
                      //   <StyledText className="body-value">
                      //     {formProps.values.defaultSymbol ? formProps.values.defaultSymbol : '$'}
                      //     {formProps.values.packageDiscount
                      //       ? formatPackagePrice(formProps.values)
                      //       : formatMoney(formProps.values.packageCost)}
                      //     {/* {formatPackagePrice(formProps.values)} */}
                      //   </StyledText>
                      // </SummaryPricingTextContainer>
                    }
                    {formProps.values.paymentOptions.includes('MonthlySessionSubscription') && showMonthly && (
                      <SummaryPricingTextContainer>
                        <StyledHeader>Monthly Pricing Options: </StyledHeader>
                        {/* <StyledHeader className="value-key">Monthly Pricing Options:</StyledHeader> */}
                        <StyledText className="body-value">
                          Per Month - Includes {formProps.values.monthlySessionSubscriptionInfo.sessionCount} Sessions
                          Monthly, {formProps.values.monthlySessionSubscriptionInfo.duration} Months Total{' '}
                          {formProps.values.defaultSymbol ? formProps.values.defaultSymbol : '$'}
                          {renderMonthlySessionSubscriptionPrice(formProps.values)}
                        </StyledText>
                      </SummaryPricingTextContainer>
                    )}
                    {/* {formProps.values.paymentOptions.includes('SplitPayments') &&
                      contribution?.type === ContributionType.contributionCourse && (
                        <StyledHeader>Split Payment Pricing Options:</StyledHeader>
                      )} */}
                  </StyledSummaryBodyContainer>
                </div>
              </Grid>
            </Grid>

            {supportPopup && (
              <>
                <Modal
                  CrossIconHide
                  isOpen={supportPopup}
                  widthRequiredIs
                  handleOnCancel
                  disableConfirm
                  onCancel={() => {
                    setSupportPopup(false);
                  }}
                  isCreatingContribution={true}
                  cancelBtnStyle={{ backgroundColor: '#C9B382', border: '1px solid #C9B382', color: 'White' }}
                >
                  <p>
                    You are creating contribution on standard stripe account. Please make sure automatic tax calculation
                    is enabled from stripe settings otherwise client's won't be able to purchase the contribution.
                  </p>
                </Modal>
              </>
            )}
          </Form>
        );
        return children(formProps, renderCardBody);
      }}
    </Formik>
  );
};

OneOnOne.propTypes = {
  contribution: PropTypes.shape(),
  saveToStorageHandler: PropTypes.func.isRequired,
  children: PropTypes.func.isRequired,
};

OneOnOne.defaultProps = {
  contribution: null,
};

export default OneOnOne;
