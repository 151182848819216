import React from 'react';
import classes from './PaginationBar.module.scss';
import Pagination from '@mui/material/Pagination';
import PropTypes from 'prop-types';
import PaginationItem from '@mui/material/PaginationItem';

const PaginationBar = ({ count = 0, paginationClassName = '', handleChange = () => {}, page }) => {
  const paginationClass = `${classes.pagination} ${paginationClassName}`;
  return (
    <div className={classes.paginationWrapper}>
      <Pagination
        page={page}
        className={paginationClass}
        count={count}
        shape="rounded"
        renderItem={item => <PaginationItem {...item} classes={{ selected: classes.selected }} />}
        onChange={handleChange}
      />
    </div>
  );
};

PaginationBar.propTypes = {
  count: PropTypes.number,
};

export default PaginationBar;
