import React from 'react';
import { useAccount } from 'hooks';
import { useTheme, useMediaQuery } from '@material-ui/core';
import Button from 'components/FormUI/Button';
import styled from 'styled-components';
import '../Banner.scss';
import { UserRoles } from 'helpers/constants';
import { usePendingApplications } from 'pages/AllApplicationsPage/hooks/usePendingApplications';
import { redirectTo } from 'services/links';
import multipleAccounts from 'assets/applicationformicon.png';

const AccountIcon = styled.img`
  object-fit: inherit;
  width: 35px;
  height: auto;
  ${({ mobileView }) => {
    return mobileView ? `width: 50px;` : `width: 35px;`;
  }};
`;

const StyledButton = styled(Button)`
  background-color: #0e536b;
  padding: 6px 14px;
  min-width: 95px;
  width: 95px;
  font-size: 12px;
  font-weight: 700;
  text-decoration: underline;
`;

const StyledButtonWeb = styled(Button)`
  text-decoration: underline;
`;

const PendingApplications = () => {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const BannerButton = mobileView ? StyledButton : StyledButtonWeb;
  const { pendingCount } = usePendingApplications();

  const onClick = () => {
    redirectTo('/applications/all');
  };

  return (
    <>
      {pendingCount > 0 ? (
        <div className={`banner-container ${mobileView && 'banner-container__mobile'}`}>
          <div style={{ display: 'flex' }}>
            {/* <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="30" height="30" rx="15" fill="white" />
              <path
                d="M20.0943 17.9043C21.252 18.7804 22.001 20.1957 22.001 21.8132H12.0586C12.0586 20.1283 12.8077 18.713 13.9654 17.8369"
                stroke="#215C73"
                stroke-width="1.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M19.7524 13.1865C19.7524 14.13 19.2757 15.0061 18.5266 15.4779C18.118 15.7475 17.5732 15.9496 16.9603 15.9496C16.4155 15.9496 15.8707 15.8148 15.4621 15.4779C14.713 15.0061 14.2363 14.13 14.2363 13.1865C14.2363 11.6364 15.4621 10.4233 17.0284 10.4233C18.5266 10.4233 19.7524 11.6364 19.7524 13.1865Z"
                stroke="#215C73"
                stroke-width="1.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M8 20.3895C8 18.7047 8.74909 17.2894 9.90677 16.4133"
                stroke="#215C73"
                stroke-width="1.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M11.4736 13.9871C10.7245 13.5828 10.1797 12.7067 10.1797 11.7631C10.1797 10.2131 11.4736 9 12.9717 9"
                stroke="#215C73"
                stroke-width="1.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg> */}
            <AccountIcon mobileView={mobileView} src={multipleAccounts} />
            <p
              className="banner-container__text"
              style={{ margin: 'auto auto auto 10px' }}
            >{`You have ${pendingCount} application requests waiting to be approved.`}</p>
          </div>
          <BannerButton variant="primary" className="banner-container__button" autoWidth onClick={onClick}>
            View All
          </BannerButton>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default PendingApplications;
