import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { colors } from 'utils/styles';
import Button from './Button';

const STATES = {
  notInterested: 0,
  interested: 1,
  veryInterested: 2,
};

const StyledButton = styled(Button)`
  ${({ state }) =>
    state === 0 &&
    css`
      background-color: #e7e7e7;
      color: rgba(0, 0, 0, 0.6);
    `}

  ${({ state }) =>
    state === 1 &&
    css`
      color: white;
    `}

  ${({ state }) =>
    state === 2 &&
    css`
      background-color: ${colors.darkOceanBlue};
      color: white;
    `}

  &:hover {
    ${({ state }) =>
      state === 0 &&
      css`
        background-color: #e7e7e7;
        color: rgba(0, 0, 0, 0.6);
      `}

    ${({ state }) =>
      state === 1 &&
      css`
        color: white;
      `}

    ${({ state }) =>
      state === 2 &&
      css`
        background-color: ${colors.darkOceanBlue};
        color: white;
      `}
  }
`;

const SelectButton = ({ children, onClick, state, ...attrs }) => {
  const [buttonState, setState] = useState(state);

  const clickHandler = useCallback(() => {
    const newStateCandidate = buttonState + 1;
    const newState = newStateCandidate > STATES.veryInterested ? STATES.notInterested : newStateCandidate;

    setState(newState);
    onClick(newState);
  }, [buttonState, onClick]);

  return (
    <StyledButton onClick={clickHandler} state={buttonState} {...attrs}>
      {children}
    </StyledButton>
  );
};

SelectButton.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  active: PropTypes.bool,
  variant: PropTypes.oneOf(['primary', 'secondary']),
  backgroundColor: PropTypes.string,
  autoWidth: PropTypes.bool,
  invert: PropTypes.bool,
  state: PropTypes.number,
};

SelectButton.defaultProps = {
  children: 'Default button',
  onClick: () => {},
  disabled: false,
  active: false,
  variant: 'secondary',
  backgroundColor: null,
  autoWidth: false,
  invert: false,
  state: STATES.notInterested,
};

export default SelectButton;
