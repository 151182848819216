import axiosInstance from 'utils/axiosInstance';
import { get } from 'lodash/fp';

export const getPaidTierOptions = () => axiosInstance.get(`/PaidTier`).then(get('data'));

export const hidePaidTierOptionBanner = () => axiosInstance.post('/Account/HidePaidTierOptionBanner');

export const createPaidTierOptionsSession = (paidTierId, paidTierPeriod, clientAccountId) =>
  axiosInstance.post('/PaidTier/create-checkout-session', {
    paidTierId,
    paidTierPeriod,
    clientAccountId,
  });

export const cancelPaidTierOptions = (accountId, paidTierId) =>
  axiosInstance.post('/PaidTier/cancel/paidTier', { accountId, paidTierId }).then(get('data'));

export const upgradePaidTierOptions = (accountId, paidTierId, paymentOption) =>
  axiosInstance.post('/PaidTier/upgrade/paidTier', { accountId, paidTierId, paymentOption }).then(get('data'));

export const getCurrentPlan = () => axiosInstance.get(`/PaidTier/getCurrent`).then(get('data'));

export const createCustomerPortalLink = () =>
  axiosInstance.post('/api/Payment/CreateCustomerPortalLink').then(get('data'));

export const getAcademy = () => axiosInstance.get(`/Academy/get-academy-contribution-preview`).then(get('data'));

export const purchaseAcademyContribution = id =>
  axiosInstance.post(`/api/Purchase/membership/academy/${id}`).then(get('data'));
