import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { UserRoles } from 'helpers/constants';
import { useRouter } from 'hooks';
import { isEqual } from 'lodash';
import CohealerCourseSessions from './CohealerCourseSessions';
import ClientCourseSessions from './ClientCourseSessions';

const CourseSessionsComponents = {
  [UserRoles.cohealer]: CohealerCourseSessions,
  [UserRoles.client]: ClientCourseSessions,
  [UserRoles.admin]: () => <div>In development</div>,
};

const CourseSessionsContainer = props => {
  const { currentRole, ...restProps } = props;
  const { pathname } = useRouter();

  const clientPreviewMode = pathname?.includes('clientpreview');
  const CourseSessionsComponent = CourseSessionsComponents[currentRole] || ClientCourseSessions;

  if (clientPreviewMode) {
    return <ClientCourseSessions {...restProps} />;
  }
  return <CourseSessionsComponent {...restProps} />;
};

CourseSessionsContainer.propTypes = {
  currentRole: PropTypes.string.isRequired,
};

export default memo(CourseSessionsContainer, isEqual);
