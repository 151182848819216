import React from 'react';
import PropTypes from 'prop-types';
import ListItem from '@material-ui/core/ListItem';
import { Avatar } from '@material-ui/core';
import ListItemText from '@material-ui/core/ListItemText';
import useContribution from 'pages/ContributionView/hooks/useContribution';
import { getThemedColors } from 'services/contributions.service';

const HashTagSuggestion = ({ user }) => {
  const contribution = useContribution();
  const { themedCardBackgroundColor } = getThemedColors(contribution);
  return (
    <ListItem
      style={{ backgroundColor: themedCardBackgroundColor, borderColor: themedCardBackgroundColor }}
      key={user?.display.toString()}
    >
      <ListItemText primary={user?.display} />
    </ListItem>
  );
};

HashTagSuggestion.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string,
    display: PropTypes.string,
    imageUrl: PropTypes.string,
  }).isRequired,
};

export default HashTagSuggestion;
