import React from 'react';

const CopySvg = ({ width = 18, height = 18, fill = 'none', color = 'white' }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 18 18" fill={fill} xmlns="http://www.w3.org/2000/svg">
      <path d="M12 2.25H3V12" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path
        d="M6 5.25H15V14.25C15 14.6478 14.842 15.0294 14.5607 15.3107C14.2794 15.592 13.8978 15.75 13.5 15.75H7.5C7.10218 15.75 6.72064 15.592 6.43934 15.3107C6.15804 15.0294 6 14.6478 6 14.25V5.25Z"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default CopySvg;
