import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import classNames from 'classnames';
import CircularProgress from '@material-ui/core/CircularProgress';

import { usePrevious } from 'hooks';
import MessageSeparator from './MessageSeparator';
import Message from './Message';
import TypingMembers from './TypingMembers';

import './MessagesHistory.scss';

const checkIfMessagesSentTheSameDay = (messageA, messageB) => {
  const timestampA = moment(messageA.timestamp);
  const timestampB = moment(messageB.timestamp);

  return timestampA.isSame(timestampB, 'day');
};

const LOAD_MORE_MESSAGES_SCROLL_THRESHOLD = 500;
const NEW_MESSAGE_ADDED_SCROLL_THRESHOLD = 200;

const MessagesHistory = ({
  className,
  members,
  myMember,
  memberProvider,
  messages,
  isLoadingMoreMessages,
  onLoadMoreMessages,
  onReadMessage,
  typingMembers,
}) => {
  const previousLastMessage = usePrevious(messages.length > 0 ? messages[messages.length - 1] : null);
  const messagesHistoryRef = useRef();
  useEffect(() => {
    if (messages.length === 0) {
      return;
    }
    const lastMessage = messages[messages.length - 1];
    const isNewMessageAdded = !previousLastMessage || lastMessage.index > previousLastMessage.index;
    if (!isNewMessageAdded) {
      return;
    }

    const messagesHistoryNode = messagesHistoryRef.current;
    const isInitialLoad = !previousLastMessage;
    const isLastMessageSentByMe = lastMessage.memberSid === myMember?.sid;
    const messagesHistoryScrollBottom =
      messagesHistoryNode.scrollHeight - messagesHistoryNode.scrollTop - messagesHistoryNode.clientHeight;
    const isLastMessageVisible = messagesHistoryScrollBottom < NEW_MESSAGE_ADDED_SCROLL_THRESHOLD;
    const shouldScrollToLastMessage = isInitialLoad || isLastMessageSentByMe || isLastMessageVisible;
    if (shouldScrollToLastMessage) {
      messagesHistoryNode.scrollTo({ top: messagesHistoryNode.scrollHeight });
    }
  }, [messages, previousLastMessage, myMember?.sid]);

  const onMessagesHistoryScroll = ({ target: { scrollTop } }) => {
    if (scrollTop < LOAD_MORE_MESSAGES_SCROLL_THRESHOLD) {
      onLoadMoreMessages();
    }
  };

  return (
    <div className={classNames(className, 'chat-messages-history')}>
      <div
        ref={messagesHistoryRef}
        className="chat-messages-history__list chat-messages-history-list"
        onScroll={onMessagesHistoryScroll}
      >
        {isLoadingMoreMessages && (
          <CircularProgress className="chat-messages-history-list__load-more-messages-progress" />
        )}
        {messages.map((m, i) => {
          const previousMessage = i > 0 ? messages[i - 1] : null;
          const shouldShowSeparator = !previousMessage || !checkIfMessagesSentTheSameDay(previousMessage, m);
          const isReadByOthers = members?.some(
            member =>
              member?.sid !== myMember?.sid &&
              member?.lastConsumedMessageIndex !== null &&
              m?.index <= member?.lastConsumedMessageIndex,
          );

          return (
            <React.Fragment key={m?.sid}>
              {shouldShowSeparator && (
                <MessageSeparator className="chat-messages-history-list__message-separator" date={m?.timestamp} />
              )}
              <Message
                className={classNames('chat-messages-history-list__message', {
                  'chat-messages-history-list__message--unread': !isReadByOthers,
                })}
                message={m}
                memberProvider={memberProvider}
                isUnread={m?.index > myMember?.lastConsumedMessageIndex}
                onRead={onReadMessage}
              />
            </React.Fragment>
          );
        })}
        <TypingMembers className="chat-messages-history-list__typing-members" members={typingMembers} />
      </div>
    </div>
  );
};

const memberPropType = PropTypes.shape({
  sid: PropTypes.string,
  lastConsumedMessageIndex: PropTypes.number,
});

MessagesHistory.propTypes = {
  className: PropTypes.string.isRequired,
  members: PropTypes.arrayOf(memberPropType).isRequired,
  myMember: memberPropType.isRequired,
  memberProvider: PropTypes.func.isRequired,
  messages: PropTypes.arrayOf(
    PropTypes.shape({
      sid: PropTypes.string,
      memberSid: PropTypes.string,
      index: PropTypes.number,
      body: PropTypes.string,
      timestamp: PropTypes.instanceOf(Date),
    }),
  ).isRequired,
  isLoadingMoreMessages: PropTypes.bool.isRequired,
  onLoadMoreMessages: PropTypes.func.isRequired,
  onReadMessage: PropTypes.func.isRequired,
  typingMembers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default MessagesHistory;
