export const allinoneplatformadvantages = [
  'Create Landing Pages with Integrated Payment Links in Minutes',
  'Schedule and Deliver LIVE Group and 1:1 Sessions',
  'Leverage Integrated Zoom or Cohere Video For Streamlined Delivery',
  'Host Self-Paced Content',
  'Create Free or Paid Communities  ',
  'Chat with 1:1 Clients and Groups',
  'Automate Bookkeeping & Track Revenue',
  'Provide a Professional Client Experience',
  // 'Client Membership Portal ',
  // 'Scheduling for Groups And 1:1',
  // 'Cohere Live Video Sessions ',
  // 'Integrate with Other Video Platforms',
  // 'Automated Session Recordings',
  // 'Automated Session Cal Invites ',
  // 'Payments & Recurring Revenue',
  // 'Sales Dashboard and Bookkeeping',
  // 'Secure Chat',
  // 'Self-Paced Courses',
  // 'Add Team Members to Services ',
  // 'Discount Codes',
  // 'By-Pass Payment System (Annual Impact Plan Only)',
];

export const thecohereacademyAdvantages = [
  'Join Monthly Group Sessions, Masterclasses, Workshops + Q&A’s to Learn Key Strategies to Grow Your Coaching Business',
  'Connect with a Global Community of Like-Minded Coaches & Entrepreneurs',
  'Gain Access to a Growing Library of Content, Including;',
  'The Create Your Irresistible Offer Workshop',
  'The High-Conversion Workshop Model for Coaches',
  // 'Create Your Irresistible Offer.',
  'Sell Your Coaching with Certainty Workshop',
  'Scale Your Coaching Workshop & more!',
];
export const VIPAdvantages = [
  'Connect with Your Own Dedicated Cohere Success Coach',
  'Receive 1:1 Onboarding & Setup Support to Launch Your Services',
  'Create Quarterly Accountability to Reach Business Milestones',
  'Have Human Support to Help You Maximize Your Business Journey on Cohere!',
];
