import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import withStyles from '@material-ui/core/styles/withStyles';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import { determineColorToUse, getThemedColors } from 'services/contributions.service';
import { addhttp, lightOrDark } from 'utils/utils';
import { useAccount, useHttp, usePartnerCoach, useRouter, useShallowEqualSelector } from 'hooks';
import useContribution from 'pages/ContributionView/hooks/useContribution';
import { useTheme, useMediaQuery, Tooltip, Avatar } from '@material-ui/core';
import Loader from 'components/UI/Loader';
import { ContributionStatus } from 'helpers/constants';

import Button from 'components/FormUI/Button';
import Popup from 'components/Popup/Popup';
import { colors } from 'utils/styles';
import { getUserProfileStatus } from 'services/profileLink.service';

import AddNewPartnerModalContainer from '../components/AddNewPartnerModal/AddNewPartnerModalContainer';
import { TOOLTIP } from '../../../constants';

const BoldTableCell = withStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: ({ mobileView }) => (mobileView ? '16px' : '17.8px'),
    fontWeight: 500,
    letterSpacing: '0.15px',
  },
})(TableCell);

const NarrowTableCell = withStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingTop: 15,
    paddingBottom: 15,
  },
})(TableCell);

const AvatarComponent = styled(Avatar)`
  ${({ mobileView }) => {
    return (
      mobileView &&
      `
        width: 24px;
        height: 24px;
        font-size: 0.7rem;
      `
    );
  }}
`;

const StyledButton = styled(Button)`
  margin-right: 0px;
`;
const StyledInfoIcon = styled(InfoIcon)`
  margin-left: 20px;
`;

const StyledCloseIcon = styled(CloseIcon)`
  cursor: pointer;
`;

const PartnerInfoWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StyledCoachName = styled.div`
  ${props =>
    props.isClickable &&
    css`
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    `}
`;

const ContributionTeam = ({ match: { params } }) => {
  const [isAddPartnerModalOpen, setIsAddPartnerModalOpen] = useState(false);
  const [removePartnerId, setRemovePartnerId] = useState(false);
  const [isConfirmRemoveModalOpen, setIsConfirmRemoveModalOpen] = useState(false);
  const avatarUrl = useShallowEqualSelector(state => state?.cohealerInfo?.avatarUrl);
  const detail = useShallowEqualSelector(state => state?.cohealerInfo);
  const [emailsError, setEmailsError] = useState({
    isError: false,
    errorMessage: null,
  });
  const [loadingCoachProfile, setLoadingCoachProfile] = useState(false);
  const contribution = useContribution();
  const { themedColor, themedCardBackgroundColor } = getThemedColors(contribution);

  const { status, userId: contributionUserId, serviceProviderName, coachAvatarUrl } = useContribution();
  const colorToUse = determineColorToUse(contribution);
  const { user } = useAccount();
  const { domain, parentDomain } = useRouter();

  const { loading: loadingPartner, partnerCoaches, updatePartnerCoaches } = usePartnerCoach(params.id);
  const textColor =
    colorToUse?.TextColorCode === 'Auto'
      ? lightOrDark(colorToUse?.PrimaryColorCode)
      : colorToUse?.TextColorCode === '#000000'
      ? '#000000'
      : '#FFFFFF';
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));

  const { request, loading } = useHttp();

  const handleModalSubmit = useCallback(
    async emails => {
      if (emailsError.isError) {
        return;
      }

      try {
        const data = {
          contributionId: params.id,
          emails,
        };
        await request('/PartnerCoach/Invite', 'POST', data);
        setIsAddPartnerModalOpen(false);
      } catch (err) {
        setEmailsError({ isError: true, errorMessage: err?.response?.data });
      }
    },
    [params.id, emailsError],
  );

  const onRemovePartner = async () => {
    try {
      await request(`/PartnerCoach/${params.id}/${removePartnerId}`, 'DELETE');
      const UpdatedPartnerCoaches = updatedArray.filter(partner => partner.userId !== removePartnerId);
      updatePartnerCoaches(UpdatedPartnerCoaches);
      setIsAddPartnerModalOpen(false);
    } catch (err) {
      console.dir(err);
    }
  };

  if (loading || loadingPartner) {
    return <Loader />;
  }

  if (
    status === ContributionStatus.rejected ||
    status === ContributionStatus.inReview ||
    status === ContributionStatus.revised
  ) {
    return <div>You can not add partners to unapproved contributions</div>;
  }

  const handleOpenAddPartnerModal = () => {
    setIsAddPartnerModalOpen(true);
  };

  const handleCloseAddPartnerModal = () => {
    setEmailsError({
      isError: false,
      errorMessage: null,
    });
    setIsAddPartnerModalOpen(false);
  };

  const showConfirmRemove = userId => {
    setIsConfirmRemoveModalOpen(true);
    setRemovePartnerId(userId);
  };

  const closeConfirmRemove = () => {
    setIsConfirmRemoveModalOpen(false);
  };

  const submitConfirmRemove = () => {
    onRemovePartner();
    setIsConfirmRemoveModalOpen(false);
  };

  const handleCoachNameClick = _userId => {
    setLoadingCoachProfile(true);

    return getUserProfileStatus(_userId)
      .then(resp => {
        if (resp.data) {
          const { isProfileActive, profileName } = resp.data;

          if (isProfileActive && profileName) {
            const coachProfileURL = addhttp(profileName.concat('.', parentDomain));

            window.open(coachProfileURL, '_blank').focus();
            return;
          }
          // TODO: open 1:1 chat
          // window.open(addhttp(domain.concat(`/conversations/all?chatId=${userId}`)), '_blank').focus();
          window.location.href = addhttp(domain.concat(`/conversations/all?chatId=${_userId}`));
        } else {
          // API call resolved but returned invalid data i.e. null
        }
      })
      .catch(() => {
        // API failed
      })
      .finally(() => {
        setLoadingCoachProfile(false);
      });
  };

  const createdByCurrentUser = contributionUserId === user.id;
  const tooltipText =
    'Cohere lets you add partner coaches.What they can do: Your partner coach will be able to edit the course, launch and join sessions, communicate with clients, see revenue numbers, and will show up on the sales page. You can remove them at anytime. What they can’t do: They will not be able to transfer revenue to their bank. They can not add other coaches.';
  const updatedArray = [
    {
      userId: contributionUserId,
      avatarUrl: avatarUrl || coachAvatarUrl,
      firstName: serviceProviderName,
      lastName: '',
    },
  ]
    .concat(partnerCoaches)
    .filter(item => item.userId != user.id);

  return (
    <>
      {loadingCoachProfile && <Loader />}
      <TableContainer style={{ color: themedColor, backgroundColor: themedCardBackgroundColor }} component={Paper}>
        <Table className="" aria-label="contributions table">
          <TableHead>
            <TableRow>
              <BoldTableCell style={{ color: themedColor }} mobileView={mobileView}>
                Team
                <div>
                  {createdByCurrentUser && (
                    <StyledButton
                      style={
                        contribution?.status === 'Draft'
                          ? {
                              backgroundColor: '#e5e5e5',
                              color: 'gray',
                            }
                          : {
                              backgroundColor: colorToUse?.PrimaryColorCode,
                              color: textColor,
                            }
                      }
                      mobileView={mobileView}
                      autoWidth
                      onClick={handleOpenAddPartnerModal}
                      disabled={contribution?.status === 'Draft'}
                    >
                      Add team member
                    </StyledButton>
                  )}
                  <Tooltip title={tooltipText} arrow enterTouchDelay={TOOLTIP.ENTER_DELAY}>
                    <StyledInfoIcon htmlColor={colorToUse.PrimaryColorCode} />
                  </Tooltip>
                </div>
              </BoldTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {updatedArray.map(({ firstName, lastName, avatarUrl, userId }) => {
              const isClickable = user.id !== userId;
              return (
                <TableRow key={userId} className="row">
                  <NarrowTableCell style={{ color: themedColor }} component="th" scope="row">
                    <PartnerInfoWrapper>
                      <AvatarComponent
                        alt="Coach name"
                        src={avatarUrl || 'https://via.placeholder.com/40'}
                        mobileView={mobileView}
                      />
                      <StyledCoachName
                        isClickable={isClickable}
                        onClick={isClickable ? () => handleCoachNameClick(userId) : null}
                        className="ml-2"
                      >{`${firstName} ${lastName}`}</StyledCoachName>
                    </PartnerInfoWrapper>

                    {createdByCurrentUser && <StyledCloseIcon onClick={() => showConfirmRemove(userId)} />}
                  </NarrowTableCell>
                </TableRow>
              );
            })}
            {(!updatedArray || !updatedArray.length) && (
              <TableRow className="row">
                <NarrowTableCell style={{ color: themedColor }} component="th" scope="row">
                  Expand your collaboration
                </NarrowTableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {isAddPartnerModalOpen && (
        <AddNewPartnerModalContainer
          applyTheming
          isOpen={isAddPartnerModalOpen}
          onModalClose={handleCloseAddPartnerModal}
          onSubmit={handleModalSubmit}
          emailsError={emailsError}
          setEmailsError={setEmailsError}
        />
      )}
      {isConfirmRemoveModalOpen && (
        <Popup
          title="Remove confirmation"
          text="Do you really want do remove this partner?"
          open={isConfirmRemoveModalOpen}
          onCloseText="No"
          onClose={closeConfirmRemove}
          onSubmit={submitConfirmRemove}
        />
      )}
    </>
  );
};

ContributionTeam.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string,
    url: PropTypes.string,
    isExact: PropTypes.bool,
    params: PropTypes.object,
  }).isRequired,
};

export default ContributionTeam;
