import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import { Avatar, Grid, makeStyles } from '@material-ui/core';
import { LabelText } from 'components/UI/Text/TextStyles';
import { useAccount, useRouter } from 'hooks';
import chatService from 'services/chat.service';
import getInitials from 'utils/getInitials';
import styled, { css } from 'styled-components';
import { useTheme, useMediaQuery, TextField } from '@material-ui/core';
import useContribution from 'pages/ContributionView/hooks/useContribution';
import { determineColorToUse, getThemedColors } from 'services/contributions.service';
import {
  BorderDiv,
  MeetYourCoachContainer,
  MeetCoachTxt,
  CoachProfileMainContainer,
  ProfileTxtLeft,
  ProfileLeftImgCont,
  ProfileLeftImg,
} from './AboutMainInfo.styles';
import sarahImg from '../../../../assets/sarah-img.png';
import { useHttp, useShallowEqualSelector } from 'hooks';
import { CoachItem } from './CoachItem';
import { useState } from 'react';
import { ColorSchemeService } from 'services/color.service';
import { StyledLine } from './MeetYourCoachTemTwo';
import {
  StyledFaFacebookF,
  StyledFaLinkedinIn,
  StyledAiFillInstagram,
  StyledAiFillYoutube,
  StyledHiGlobeAlt,
  StyledFaTiktok,
} from './MeetYourCoachTemTwo';
import { addhttp } from 'utils/utils';
import Loader from 'components/UI/Loader';
import { getUserProfileStatus } from 'services/profileLink.service';

//  const ProfileLeftImg = styled.img`
//   width: 3.625rem;
// `;

const SocialLinks = styled.div`
  width: ${({ mobileView }) => (mobileView ? '100%' : '100px')};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const HyperLink = styled.a.attrs({ target: '_blank' })``;

const ProfileRightTxt = styled.div`
  padding-left: ${props => props.Pl};
  margin-top: ${props => props.Mt};
`;
const ProfileUserName = styled.h4`
  margin: 0px;
  font-style: normal;
  font-weight: 420;
  font-size: 14px;
  line-height: 19px;

  color: #282b2b;
`;
const ProfileUserLocation = styled.p`
  font-family: 'Brandon Text';
  font-style: normal;
  font-weight: 420;
  font-size: 14px;
  color: #656d6d;
`;
const ProfileParagraph = styled.p`
  max-width: 31.25rem;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #282b2b;
`;
const ProfileRightButton = styled.div`
  ${({ mobileView }) =>
    mobileView &&
    css`
      display: flex;
      margin: auto;
      width: 150px;
    `}
`;
const StyledButton = styled.button`
  position: relative;
  padding: 0.5rem 1rem;
  font-size: 14px;
  border-radius: 8px;
  border: none;
  color: ${props => props.color};
  fill: #fff; /* for svg */
  font-weight: 700;
  cursor: pointer;
  text-decoration: none;
  line-height: 1rem;
  letter-spacing: 1.25px;
  min-width: 6rem;
  transition: background 0.2s;
  font-family: 'Brandon Text';
  background: ${props => props.background};
  font-style: normal;
  // font-weight: 400;
  margin: ${props => props.margin};
  max-width: ${props => props.maxWidth};
  width: 134px;
  height: 44px;
`;

const AvatarUrl = styled.a``;

const useStyles = makeStyles(theme => ({
  Avatar: {
    width: '65px',
    height: '65px',
  },
  CoachName: {
    fontStyle: 'normal',
    // fontWeight: '420',
    // fontSize: '20px',
    // lineHeight: ' 28px',
    color: '#282B2B',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '16px',
  },
}));

const StyledCoachName = styled.div`
  ${props =>
    props.isClickable &&
    css`
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    `}
`;

function MeetYourCoach({
  // user,
  id,
  userId,
  customTitleForMeetYourCoach,
  previewContentUrls,
  serviceProviderName,
  bio,
  coachCountry,
  contributionPartners,
  socialUrls,
  firstName,
  lastName,
  brandingColors,
  isCustomBrandingColorsActive,
  isDarkModeEnabled,
}) {
  // const { PrimaryColorCode } = brandingColors;
  // const PrimaryColor = isCustomBrandingColorsActive ? PrimaryColorCode : '#6999CA';
  const { instagramUrl, linkedInUrl, youtubeUrl, facebookUrl, websiteUrl, tiktokUrl } = socialUrls;
  const PrimaryColor = ColorSchemeService().PrimaryColorCode;
  const avatarUrl = useShallowEqualSelector(state => state?.cohealerInfo?.avatarUrl);
  const theme = useTheme();
  const classNames = useStyles();
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'));
  const history = useRouter();
  const { user } = useAccount();
  const [Style1, setStyle1] = useState(true);
  const [Style2, setStyle2] = useState(false);
  const [loadingCoachProfile, setLoadingCoachProfile] = useState(false);
  const { domain, parentDomain } = useRouter();

  const contribution = useContribution();
  const colorToUse = determineColorToUse(contribution);
  const { themedColor, newThemedTextColor } = getThemedColors(contribution);

  // const handleSendMessageToCoach = useCallback(() => {
  //   if (isEmpty(user)) {
  //     history.push(`/cohealer-about/${userId}/about`);
  //   } else if (userId !== user.id) {
  //     window.location.href = addhttp(history?.domain?.concat(`/conversations/all?chatId=${userId}`));
  //   }
  // }, [history, user, userId]);

  const handleCoachNameClick = _userId => {
    setLoadingCoachProfile(true);

    return getUserProfileStatus(_userId)
      .then(resp => {
        if (resp.data) {
          const { isProfileActive, profileName } = resp.data;

          if (isProfileActive && profileName) {
            const coachProfileURL = addhttp(profileName.concat('.', parentDomain));

            window.open(coachProfileURL, '_blank').focus();
            return;
          }
          // TODO: open 1:1 chat
          // User not logged-in
          if (isEmpty(user)) {
            window.location.href = addhttp(parentDomain.concat('/auth/signin'));
            return;
          }
          // Logged-in user
          // window.open(addhttp(domain.concat(`/conversations/all?chatId=${userId}`)), '_blank').focus();
          window.location.href = addhttp(domain.concat(`/conversations/all?chatId=${userId}`));
        } else {
          // API call resolved but returned invalid data i.e. null
        }
      })
      .catch(() => {
        // API failed
      })
      .finally(() => {
        setLoadingCoachProfile(false);
      });
  };

  return (
    <>
      {/* <BorderDiv margin="0.5rem 0px 4rem 0px"></BorderDiv> */}
      {/* <CoachItem
        serviceProviderName={serviceProviderName}
        avatarUrl={avatarUrl}
        userId={userId}
        mobileView={mobileView}
      /> */}
      {/* <Grid item>
        <Grid item container alignItems="center">
          <Avatar src={avatarUrl} className="mx-2">
            {getInitials(serviceProviderName || `${firstName} ${lastName}`)}
          </Avatar>
          <LabelText id="coach-name" mobileView={mobileView} onClick={handleSendMessageToCoach}>
            <Link to="#coach-name">{serviceProviderName || `${firstName} ${lastName}`}</Link>
          </LabelText>
        </Grid>
      </Grid> */}
      <Grid md={12} sm={12} xs={12}>
        <StyledLine style={{ marginBottom: '66px' }} color={isDarkModeEnabled ? '#696969' : '#0000000D'} />
        <MeetYourCoachContainer>
          {loadingCoachProfile && <Loader />}
          <MeetCoachTxt style={{ color: themedColor }}>Meet Your {customTitleForMeetYourCoach}</MeetCoachTxt>
          <CoachProfileMainContainer mobileView={mobileView}>
            <ProfileTxtLeft style={{ color: themedColor }} mobileView={mobileView}>
              <ProfileLeftImgCont>
                {/* <ProfileLeftImg src={sarahImg}></ProfileLeftImg> */}
                <Avatar src={avatarUrl} className={classNames.Avatar}>
                  {getInitials(serviceProviderName || `${firstName} ${lastName}`)}
                </Avatar>
              </ProfileLeftImgCont>
              <ProfileRightTxt Pl={mobileView ? '' : '1.375rem'} Mt={mobileView ? '1.375rem' : ''}>
                {/* <ProfileUserName>{serviceProviderName}</ProfileUserName> */}
                <LabelText
                  style={{ fontFamily: 'Poppins', fontWeight: 500 }}
                  id="coach-name"
                  mobileView={mobileView}
                  onClick={() => handleCoachNameClick(userId)}
                >
                  <StyledCoachName isClickable style={{ color: themedColor }} className={classNames.CoachName}>
                    {serviceProviderName || `${firstName} ${lastName}`}
                  </StyledCoachName>
                </LabelText>
                <ProfileUserLocation style={{ color: themedColor }}>{coachCountry}</ProfileUserLocation>
              </ProfileRightTxt>
            </ProfileTxtLeft>
            {/* <AvatarUrl href={avatarUrl}> </AvatarUrl> */}
            {/* <ProfileRightButton mobileView={mobileView}>
              <StyledButton background={PrimaryColor} color="#fff">
                View Profile
              </StyledButton>
            </ProfileRightButton> */}
          </CoachProfileMainContainer>
          <Grid>
            <ProfileParagraph style={{ color: themedColor, padding: 10, whiteSpace: 'pre-wrap' }}>
              {bio}
            </ProfileParagraph>
          </Grid>
          {contributionPartners &&
            contributionPartners.map(({ firstName, lastName, avatarUrl, userId: _userId, bio }) => (
              <div>
                <CoachProfileMainContainer>
                  <ProfileTxtLeft style={{ color: themedColor }}>
                    <ProfileLeftImgCont>
                      {/* <ProfileLeftImg src={sarahImg}></ProfileLeftImg> */}
                      <Avatar src={avatarUrl} className={classNames.Avatar}>
                        {getInitials(`${firstName} ${lastName}`)}
                      </Avatar>
                    </ProfileLeftImgCont>
                  </ProfileTxtLeft>
                  <ProfileRightTxt Pl="1.375rem">
                    {/* <ProfileUserName>{serviceProviderName}</ProfileUserName> */}
                    <LabelText
                      style={{ fontFamily: 'Poppins', fontWeight: 500 }}
                      id="coach-name"
                      mobileView={mobileView}
                      onClick={() => handleCoachNameClick(_userId)}
                    >
                      <StyledCoachName
                        isClickable
                        style={{ color: themedColor }}
                      >{`${firstName} ${lastName}`}</StyledCoachName>
                    </LabelText>
                    <ProfileUserLocation>{coachCountry}</ProfileUserLocation>
                  </ProfileRightTxt>
                </CoachProfileMainContainer>
                <ProfileParagraph style={{ color: themedColor, padding: 10, whiteSpace: 'pre-wrap' }}>
                  {bio}
                </ProfileParagraph>
              </div>
              // <CoachItem
              //   serviceProviderName={null}
              //   firstName={firstName}
              //   lastName={lastName}
              //   avatarUrl={avatarUrl}
              //   userId={userId}
              //   mobileView={mobileView}
              // />
            ))}
        </MeetYourCoachContainer>
        {(facebookUrl || linkedInUrl || instagramUrl || youtubeUrl || websiteUrl || tiktokUrl) && (
          <Grid item xs={12}>
            <SocialLinks>
              {facebookUrl && (
                <HyperLink href={facebookUrl.includes('https://') ? facebookUrl : `https://${facebookUrl}`}>
                  {/* <img style={{ padding: '0px 5px' }} src={fbImg}></img> */}
                  <StyledFaFacebookF color={colorToUse?.PrimaryColorCode} />
                </HyperLink>
              )}
              {linkedInUrl && (
                <HyperLink href={linkedInUrl.includes('https://') ? linkedInUrl : `https://${linkedInUrl}`}>
                  {/* <img style={{ padding: '0px 5px' }} src={linkedImg}></img> */}
                  <StyledFaLinkedinIn color={colorToUse?.PrimaryColorCode} />
                </HyperLink>
              )}
              {instagramUrl && (
                <HyperLink href={instagramUrl.includes('https://') ? instagramUrl : `https://${instagramUrl}`}>
                  {/* <img style={{ padding: '0px 5px', width: '40px', height: '30px' }} src={instaImg}></img> */}
                  <StyledAiFillInstagram color={colorToUse?.PrimaryColorCode} />
                </HyperLink>
              )}
              {youtubeUrl && (
                <HyperLink href={youtubeUrl.includes('https://') ? youtubeUrl : `https://${youtubeUrl}`}>
                  {/* <img style={{ padding: '0px 5px' }} src={youtube}></img> */}
                  <StyledAiFillYoutube color={colorToUse?.PrimaryColorCode} />
                </HyperLink>
              )}
              {websiteUrl && (
                <HyperLink href={websiteUrl.includes('https://') ? websiteUrl : `https://${websiteUrl}`}>
                  {/* <img style={{ padding: '0px 5px' }} src={website}></img> */}
                  <StyledHiGlobeAlt color={colorToUse?.PrimaryColorCode} />
                </HyperLink>
              )}
              {tiktokUrl && (
                <HyperLink href={tiktokUrl.includes('https://') ? tiktokUrl : `https://${tiktokUrl}`}>
                  {/* <img style={{ padding: '0px 5px' }} src={tiktok}></img> */}
                  <StyledFaTiktok color={colorToUse?.PrimaryColorCode} />
                </HyperLink>
              )}
            </SocialLinks>
          </Grid>
        )}
        <StyledLine color={isDarkModeEnabled ? '#696969' : '#0000000D'} />
      </Grid>
    </>
  );
}

export default MeetYourCoach;

CoachItem.propTypes = {
  userId: PropTypes.string.isRequired,
  serviceProviderName: PropTypes.string,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  avatarUrl: PropTypes.string.isRequired,
  mobileView: PropTypes.bool.isRequired,
};

CoachItem.defaultProps = {
  serviceProviderName: 'No name',
};
