import React, { useState } from 'react';
import classes from './ResubCard.module.scss';
import Card from '../Card/Card';
import styled from 'styled-components';
import Button from 'components/FormUI/Button';
import { EMAIL_SUBSCRIPTION_STATUS } from 'pages/Unsubscribe/Unsubscribe';
import { resubscribeEmailCampaign } from 'pages/CreateCampaign/Services/CreateCampaign.service';
import Loader from 'components/UI/Loader';
const ResubCard = ({ clientEmail, coachId, onResubscribe }) => {
  const handleResubscribe = () => {
    const data = {
      coachId,
      clientEmail,
    };
    onResubscribe(data);
  };
  return (
    <Card header={clientEmail}>
      <div className={classes.body}>
        <div className={classes.status}>Unsubscription Successful</div>
        <div className={classes.msg}>
          You have been successfully removed from the email list and won't receive any further emails from us.
        </div>
        <Button className={classes.unsub_btn} autoWidth onClick={handleResubscribe}>
          Resubscribe
        </Button>
      </div>
    </Card>
  );
};
export default ResubCard;
