import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { NavLink, Route, Switch, useHistory, useParams } from 'react-router-dom';
import * as R from 'ramda';

import Button from 'components/FormUI/Button';
import Can from 'components/Auth/Can';
import { UserRoles } from 'helpers/constants';
import { setCohealerInfoForClient, clearCohealerInfoForClient } from 'actions/cohealerInfo';
import { useHttp, useShallowEqualSelector, useHeader } from 'hooks';
import { useTheme, useMediaQuery } from '@material-ui/core';
import Header from 'components/UI/Header';
import HeaderMobile from 'components/UI/HeaderMobile';
import Loader from 'components/UI/Loader';
import chatService from 'services/chat.service';
import About from './About/About';
import Services from './Services/Services';

import './CohealerAbout.scss';

const CohealerAbout = () => {
  const { firstName = '', lastName = '' } = useShallowEqualSelector(state => state?.cohealerInfo?.info);
  const dispatch = useDispatch();
  const { cohealerId } = useParams();
  const history = useHistory();
  const { request, loading } = useHttp();
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  useHeader(`${firstName} ${lastName}`);

  const onSendMessage = () => {
    chatService
      .getPersonalChatWithAsync(cohealerId)
      .then(({ sid }) => history.push(`/conversations/all`, { preferredConversationId: sid }));
  };

  useEffect(() => {
    document.body.classList.add('bg-snow');

    return () => document.body.classList.remove('bg-snow');
  }, []);

  useEffect(() => {
    request(`/Contribution/GetCohealerInfoForClient/${cohealerId}`, 'GET')
      .then(R.compose(dispatch, setCohealerInfoForClient))
      .catch(console.dir);

    return R.compose(dispatch, clearCohealerInfoForClient);
  }, [cohealerId, dispatch, request]);

  return loading ? (
    <Loader />
  ) : (
    <>
      <Header isCustomPrevButton backButtonText="Previous Page" view={mobileView}>
        {!mobileView && (
          <Can roleName={UserRoles.client} yes={() => <Button onClick={onSendMessage}>Send a Message</Button>} />
        )}
      </Header>
      {mobileView && <HeaderMobile onSendMessage={onSendMessage} roleName={UserRoles.client} />}
      <div className="cohealer-about">
        <div className="bg-white cohealer-about-links">
          <NavLink to={`/cohealer-about/${cohealerId}/about`} className="cohealer-about-link" activeClassName="active">
            About
          </NavLink>
          <NavLink
            to={`/cohealer-about/${cohealerId}/services`}
            className="cohealer-about-link"
            activeClassName="active"
          >
            Services
          </NavLink>
        </div>
      </div>

      <Switch>
        <Route exact path={`/cohealer-about/${cohealerId}/about`} component={About} />
        <Route to={`/cohealer-about/${cohealerId}/services`} exact component={Services} />
      </Switch>
    </>
  );
};

export default CohealerAbout;
