import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
// import { LocalizeProvider } from "react-localize-redux";
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { ThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import 'react-notifications-component/dist/theme.css';

import 'utils/api';
import { theme } from 'styles/theme';
import { configureStore } from 'store/configureStore';
// import Localize from "./Localize";
import ReactNotification from 'react-notifications-component';
import Routes from './Routes';
import useScript from './useScript';

const { store, persistor } = configureStore();

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_TOKEN);

const activeCampaignScript = `(function(e,t,o,n,p,r,i){e.visitorGlobalObjectAlias=n;e[e.visitorGlobalObjectAlias]=e[e.visitorGlobalObjectAlias]||function(){(e[e.visitorGlobalObjectAlias].q=e[e.visitorGlobalObjectAlias].q||[]).push(arguments)};e[e.visitorGlobalObjectAlias].l=(new Date).getTime();r=t.createElement("script");r.src=o;r.async=true;i=t.getElementsByTagName("script")[0];i.parentNode.insertBefore(r,i)})(window,document,"https://diffuser-cdn.app-us1.com/diffuser/diffuser.js","vgo");vgo('setAccount', '90822014');vgo('setTrackByDefault', true);vgo('process');`;

function App() {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        {/* <LocalizeProvider> */}
        {useScript(activeCampaignScript, process.env.REACT_APP_API_DOMAIN.includes('api-prod'))}
        <ReactNotification />
        <Elements stripe={stripePromise}>
          <ThemeProvider theme={theme}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              {/* <Localize /> */}
              <Router>
                <Routes />
              </Router>
            </MuiPickersUtilsProvider>
          </ThemeProvider>
        </Elements>
        {/* </LocalizeProvider> */}
      </PersistGate>
    </Provider>
  );
}

export default App;
