import React from 'react';
import StarIcon from '../../../components/Icons/IconStar';

const UpgradeButton = ({ onclick, buttonLabel, style, starIcon, disable }) => {
  return (
    <button disabled={disable} type="button" className={style} onClick={onclick}>
      {starIcon && <StarIcon />}
      {buttonLabel}
    </button>
  );
};

export default UpgradeButton;
