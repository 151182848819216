import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import AcademyCard from 'components/AdvertismentCard';
import PlaceholderImage from 'assets/placeholder.jpg';
import { makeStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useAccount } from 'hooks/index';
import { SIGNUP_TYPES } from 'helpers/constants';
import { LabelText } from 'components/UI/Text/TextStyles';
import { StyledSessionsContainer as StyledContributionContainer } from './ContributionsList';
import SessionCard from '../SessionCard/SessionCard';
import Loader from 'components/UI/Loader';

const useStyles = makeStyles(theme => ({
  rootAcademyList: {
    display: 'flex',
    gap: 15,
    flexWrap: 'wrap',
    [theme.breakpoints.down(500)]: {
      justifyContent: 'center',
    },
  },
  cohereAcademyLink: {
    textDecoration: 'none',
    padding: 15,
    '&:hover': {
      textDecoration: 'none',
    },
  },
  contributionLink: {
    textDecoration: 'none',
    width: '32%',
    marginRight: '10px',
    '&:hover': {
      textDecoration: 'none',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginRight: '0px',
    },
  },
}));

const AcademyContributionsList = ({ contributions }) => {
  const classes = useStyles();
  let contributionsList = contributions;
  const loader = useSelector(state => state.contributions.loader);
  const deleteIndexContributions = indexData => {
    const data1 = contributionsList.filter((item, index) => index != indexData);
    contributionsList = data1;
  };

  return loader ? (
    <Loader />
  ) : contributionsList?.length > 0 ? (
    // <div className={classes.rootAcademyList}>
    //   {contributions.map(item => {
    //     return (
    //       <Link
    //         to={`/contribution-view/${item.contributionId}/about`}
    //         key={item.id}
    //         className={classes.cohereAcademyLink}
    //       >
    //         <AcademyCard
    //           key={item.id}
    //           img={item.previewContentUrls[0] || PlaceholderImage}
    //           title={item.title}
    //           userId={item.authorUserId}
    //           author={`By: ${item.serviceProviderName}`}
    //           isShowEnrollButton
    //           AvatarUrl={item.authorAvatarUrl}
    //         />
    //       </Link>
    //     );
    //   })}
    // </div>

    <StyledContributionContainer>
      {contributionsList?.map((item, index) => {
        return (
          <SessionCard
            key={item?.id}
            index={index}
            sessioninfo={item}
            isContribution={true}
            removeIndexFunction={() => {
              deleteIndexContributions(index);
            }}
          />
        );
      })}
    </StyledContributionContainer>
  ) : (
    <LabelText>You have no contributions yet.</LabelText>
  );
};

export default AcademyContributionsList;
