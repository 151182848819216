import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import AddBankAccountPopup from '../../../pages/Account/Payment/components/BankAccountCard/AddBankAccountPopup';
import { useHttp, useAccount } from 'hooks';
import { useTheme, useMediaQuery } from '@material-ui/core';
import { redirectTo } from 'services/links';
import * as userActions from 'actions/user';
import Button from 'components/FormUI/Button';
import styled from 'styled-components';
import '../Banner.scss';
// import StandardStripeAccountPlaid from './StandardStripeAccountPlaid';
const StyledButton = styled(Button)`
  background-color: #0e536b;
  padding: 6px 14px;
  min-width: 95px;
  width: 95px;
  font-size: 12px;
  font-weight: 700;
`;
const AffiliateCoachBanner = ({ userId, getUserProfile }) => {
  const { request } = useHttp();
  const { user } = useAccount();
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const BannerButton = mobileView ? StyledButton : Button;
  const [isClick, setIsClick] = useState(false);
  const [ready, setReady] = useState(true);
  let opensFunc = () => null;
  const getUser = useCallback(() => {
    if (userId) {
      getUserProfile(userId);
    }
  }, [userId, getUserProfile]);
  const onVerificate = useCallback(() => {
    request('api/Payment/generate-account-verification-link?forAffiliateAccount=true', 'POST').then(({ link }) => {
      redirectTo(link);
    });
  }, [request]);
  const checkOnClick = () => {
    onVerificate();
  };
  const [addStripeBankAccountDataPopup, setAddStripeBankAccountDataPopup] = useState(false);
  return (
    <div className={`banner-container ${mobileView && 'banner-container__mobile'}`}>
      <p className="banner-container__text">Verify your information for affiliate account.</p>
      <div>
        <BannerButton
          disabled={!user.standardAccountTransfersEnabled && !ready}
          variant="primary"
          className="banner-container__button"
          autoWidth
          onClick={checkOnClick}
        >
          {'Verify'}
          {/* {isClick && (
                <StandardStripeAccountPlaid
                  userId={userId}
                  getUserProfile={getUserProfile}
                  getOpen={handleGetOpen}
                  getReady={handleGetReady}
                />
              )} */}
        </BannerButton>
      </div>
    </div>
  );
};
AffiliateCoachBanner.propTypes = {
  userId: PropTypes.string,
  getUserProfile: PropTypes.func.isRequired,
};
AffiliateCoachBanner.defaultProps = {
  userId: null,
};
const mapStateToProps = ({ account }) => ({
  userId: account?.user?.id,
});
const actions = {
  getUserProfile: userActions.getProfile,
};
export default connect(mapStateToProps, actions)(AffiliateCoachBanner);
