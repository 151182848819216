import React, { useState } from 'react';
import Modal from 'components/UI/Modal';
import { Grid } from '@material-ui/core';
import classes from './invitecoachmodal.module.scss';
import Button from 'components/FormUI/Button';
import CopySvg from 'pages/ContributionView/components/ShareModal/Content/CopyIcon';
import { useTheme, useMediaQuery, Tooltip, TextField } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import copyToClipboard from 'utils/copyToClipboard';
import { useAccount } from 'hooks';
import { redirectInNewTab } from 'services/links';

export default function InviteCoachesModal({ setShowInviteModal, showInviteModal }) {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const { user } = useAccount();
  const handleCopyClick = () => {
    copyToClipboard(
      origin.includes('local') || origin.includes('test') || origin.includes('dev')
        ? `https://www.cohere.live/affiliate-stg?inviteCode=${user.accountId}`
        : `https://www.cohere.live/affiliate?inviteCode=${user.accountId}`,
    );
  };
  return (
    <Modal
      maxWidth={'700px'}
      disableFooter={true}
      isOpen={showInviteModal}
      title="Invite Coaches"
      modalType={'Quizes'}
      closeClick={() => {
        setShowInviteModal(false);
      }}
      minHeight={'180px'}
      onCloseClick={() => {
        setShowInviteModal(false);
      }}
      onSubmit={() => {
        setShowInviteModal(false);
      }}
    >
      <Grid style={{ display: 'flex', justifyContent: 'space-between' }} lg={12}>
        <Grid xs={12} sm={12} lg={7}>
          <div className={classes.text}>
            Share this link with friends, family, and colleagues to help them learn about the Cohere platform, watch a
            demo, and book a call. All referrals will be tracked to you, and you'll be notified when they enroll.
          </div>
        </Grid>
        <Grid xs={12} sm={12} lg={4}>
          <div className={classes.buttons_container}>
            <div>
              <Tooltip
                className="cursor-pointer"
                title="Copied to clipboard"
                open={tooltipOpen}
                arrow
                onClose={() => {
                  setTooltipOpen(false);
                }}
              >
                <div
                  onClick={() => {
                    setTooltipOpen(true);
                    handleCopyClick();
                  }}
                  className={classes.invite_button}
                >
                  <CopySvg />
                  Copy Invite Coach Link
                </div>
              </Tooltip>
            </div>
            <div className={classes.resources_container}>
              <Button
                onClick={() => {
                  redirectInNewTab(
                    'https://drive.google.com/drive/folders/1Ba6YF2cY5cx2hRmfsIbRcNli0t2Cwtgi?usp=share_link',
                  );
                }}
                invert
                className={classes.resources_button}
              >
                Affiliate Resources
              </Button>
              <Tooltip
                title="This folder contains resources that will make it easier to share Cohere with your friends, family and colleagues."
                arrow
              >
                <InfoIcon htmlColor="#C9B382" />
              </Tooltip>
            </div>
          </div>
        </Grid>
      </Grid>
    </Modal>
  );
}
