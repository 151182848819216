import React, { useEffect, useState, useCallback } from 'react';
import Grid from '@material-ui/core/Grid';
import Modal from 'components/UI/Modal';
import Button from 'components/FormUI/Button';
import classes from './magicwrite.module.scss';
import Logo from 'components/FormUI/Logo';
import MagicIntro from '../../../assets/crystalize.svg';
import useHttp from 'hooks/http.hook';
import { useDispatch } from 'react-redux';
import { updateUser } from 'actions/update-user';
import useAccount from 'hooks/useAccount';
const MagicwriteIntro = ({ openMagicModal, setOpenMagicModal }) => {
  const dispatch = useDispatch();
  const { user } = useAccount();
  const { request, loading } = useHttp();
  const disablePopup = useCallback(
    () =>
      request(`account/DisableMagicWriteFirstTimePopup`, 'POST').then(() => {
        dispatch(
          updateUser({
            ...user,
            showFirstTimeMagicWritePopup: false,
          }),
        );
      }),
    [request],
  );
  return (
    <Modal
      isOpen={openMagicModal}
      disableFooter
      onCloseClick={() => {
        setOpenMagicModal(false);
        disablePopup();
      }}
      closeClick={true}
      brandingColors={false}
      isCreatingContribution={true}
      wholeModalColor="linear-gradient(to bottom,#E8E2D5 5%,#E8E2D5 0%,#ffffff 45%,#ffffff 100%)"
    >
      <div className={classes.MagicModalContainer}>
        <div className={classes.MagicButtonContainer}>
          <img alt="" src={MagicIntro} />
        </div>
        <div className={classes.MagicModalTitle}>Introducing CohereCopy</div>
        <div className={classes.MagicModalDescription}>
          We're excited to introduce a new generative AI feature designed to help you launch your services on Cohere
          even faster with well-written copy.
        </div>
        <div>
          <Grid container item xs justifyContent="center" direction="column" alignItems="center">
            <Button
              onClick={() => {
                setOpenMagicModal(false);
                disablePopup();
              }}
              type="submit"
              className={classes.btn}
            >
              Get Started!
            </Button>
          </Grid>
        </div>
      </div>
    </Modal>
  );
};

export default MagicwriteIntro;
