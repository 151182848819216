import React from 'react';

import Modal from 'components/UI/Modal';
import { LabelText } from 'components/UI/Text/TextStyles';

export const FailedPurchaseModal = ({ isOpen, handleClose }) => {
  return (
    <Modal isOpen={isOpen} onCancel={handleClose} onSubmit={handleClose} title="Purchase incomplete." hiddenCancel>
      <LabelText>Your purchase was not completed. Please try again.</LabelText>
    </Modal>
  );
};
