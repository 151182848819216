import React from 'react';

const CommunitySvg = ({ width, height, color }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.0577 16.0695C6.0577 14.8069 6.03166 13.5638 6.07071 12.3206C6.08373 11.813 6.17485 11.2988 6.32455 10.8171C6.71506 9.56751 7.50259 8.61075 8.62857 7.93386C8.76525 7.84925 8.84335 7.86878 8.96701 7.9599C10.7634 9.35923 13.2106 9.37225 15.0135 7.97291C15.1631 7.85576 15.2608 7.85576 15.417 7.95339C17.0116 8.97523 17.8772 10.4266 17.9358 12.3206C17.9748 13.5638 17.9423 14.8069 17.9423 16.0761C13.9851 16.0695 10.0344 16.0695 6.0577 16.0695Z"
        fill={color}
      />
      <path
        d="M11.9935 8.10316C9.78056 8.10966 7.95166 6.28727 7.95166 4.07437C7.95166 1.82892 9.75453 0.00652599 11.9739 1.74415e-05C14.2194 -0.0064911 16.0353 1.80939 16.0418 4.05484C16.0483 6.28727 14.2324 8.09665 11.9935 8.10316Z"
        fill={color}
      />
      <path
        d="M15.7034 7.34172C17.298 5.55838 17.3956 2.74018 15.7164 0.787613C16.8293 -0.162635 19.2831 -0.455519 20.9427 1.12606C22.4918 2.6035 22.6154 5.07674 21.2226 6.69737C19.6931 8.48071 17.1287 8.42865 15.7034 7.34172Z"
        fill={color}
      />
      <path
        d="M6.17485 9.00772C6.12929 9.09233 6.10977 9.13789 6.08373 9.18345C5.45891 10.1988 5.15301 11.3052 5.15952 12.4963C5.16603 13.5897 5.15952 14.6767 5.15952 15.7701C5.15952 15.8677 5.15952 15.9588 5.15952 16.0695C3.43475 16.0695 1.74253 16.0695 0.0307861 16.0695C0.0242775 16.0109 0.0112605 15.9458 0.0112605 15.8873C0.0112605 14.6767 -0.0212823 13.4596 0.0242775 12.249C0.0958715 10.381 0.961508 8.95565 2.53007 7.94683C2.68627 7.8492 2.79041 7.84269 2.95312 7.96635C3.81876 8.64324 4.81457 8.97518 5.908 9.00121C5.9861 9.00772 6.06421 9.00772 6.17485 9.00772Z"
        fill={color}
      />
      <path
        d="M17.8252 9.02088C18.5281 9.01438 19.1855 8.9558 19.7908 8.68244C20.2464 8.47417 20.6694 8.20732 21.099 7.94047C21.2227 7.86887 21.2943 7.84284 21.4179 7.92094C23.0255 8.92326 23.9107 10.3682 23.9758 12.2621C24.0213 13.4727 23.9888 14.6898 23.9888 15.9004C23.9888 15.9525 23.9823 16.0111 23.9758 16.0761C22.2705 16.0761 20.5783 16.0761 18.847 16.0761C18.847 15.9785 18.847 15.8809 18.847 15.7898C18.847 14.6963 18.8405 13.6094 18.847 12.516C18.8535 11.3119 18.5476 10.1989 17.9163 9.1836C17.8903 9.14455 17.8708 9.09899 17.8252 9.02088Z"
        fill={color}
      />
      <path
        d="M8.2901 7.33505C6.89076 8.41547 4.51514 8.46103 2.97913 6.79485C1.53423 5.23279 1.56026 2.77256 3.04421 1.24306C4.60626 -0.364554 6.96236 -0.266926 8.2901 0.78095C7.48955 1.72469 7.05998 2.81162 7.05998 4.05475C7.05998 5.30439 7.48955 6.39131 8.2901 7.33505Z"
        fill={color}
      />
    </svg>
  );
};

export default CommunitySvg;
