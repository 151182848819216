import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';
import { useMediaQuery, useTheme } from '@material-ui/core';
import CalendarSvg from '../../icons/CalendarIcon';
import TimeSvg from '../../icons/TimeIcon';
import TimeZoneIcon from '../../icons/TimeZoneIcon';
import UserIcon from '../../icons/UserIcon';
import ContributionForm from '../CalendarContributionView/ContributionForm';

const BookingSummary = ({
  duration,
  contributionTitle,
  client,
  name,
  scheduleData,
  setpricingOption,
  pricingOption,
  colorToUse,
  calculatePricings,
  contributionSelectionDisable,
  fromMasterCalendar,
  activeContribution,
}) => {
  const { timeZones } = useSelector(state => state?.timeZone);
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'));
  function getShortNameByCountryName(countryName) {
    const timezone = timeZones.find(tz => tz.countryName === countryName);
    return timezone?.name;
  }

  return (
    <div
      className={`user-details-side ${!activeContribution?.id ? 'no-active-contribution' : ''} ${
        mobileView ? 'mobile-view' : ''
      }`}
      style={{
        padding: '0px 15px 0px 0px',
        width: mobileView ? '345px' : activeContribution?.id ? '323px' : '760px',
      }}
    >
      {!fromMasterCalendar ? (
        <div className="contribution-title" style={{ textAlign: 'left', marginTop: 0 }}>
          {contributionTitle}
        </div>
      ) : (
        <ContributionForm
          setpricingOption={setpricingOption}
          pricingOption={pricingOption}
          calculatePricings={calculatePricings}
          contributionSelectionDisable={contributionSelectionDisable}
          fromMasterCalendar={fromMasterCalendar}
          hasActiveContribution={activeContribution?.id}
        />
      )}
      {activeContribution?.id && (
        <>
          <div className="duration-details" style={{ textAlign: 'left' }}>{`${duration} Minutes Session`}</div>
          {(client || name) && (
            <div className="selected-date-time" style={{ justifyContent: 'unset' }}>
              <UserIcon color={colorToUse?.PrimaryColorCode} />
              {client && <p>{client?.name}</p>}
              {name && <p>{name}</p>}
            </div>
          )}
          <div className="selected-date-time" style={{ justifyContent: 'unset' }}>
            <CalendarSvg color={colorToUse?.PrimaryColorCode} />
            <p>
              {`${moment(scheduleData?.selectedDate).format('dddd')},`}{' '}
              {moment(scheduleData?.selectedDate).format('MMMM DD, yyyy')}
            </p>
          </div>
          {scheduleData?.selectedSlot && (
            <div className="selected-date-time" style={{ justifyContent: 'unset' }}>
              <TimeSvg color={colorToUse?.PrimaryColorCode} />
              <p>
                {moment(scheduleData?.selectedSlot?.start).format('h:mmA')}
                {' - '}
                {moment(scheduleData?.selectedSlot?.end).format('h:mmA')}
              </p>
            </div>
          )}
          <div className="selected-date-time" style={{ justifyContent: 'unset' }}>
            <TimeZoneIcon color={colorToUse?.PrimaryColorCode} />
            {scheduleData?.timeZone && <p>{getShortNameByCountryName(scheduleData?.timeZone)}</p>}
          </div>
        </>
      )}
    </div>
  );
};

BookingSummary.propTypes = {
  duration: PropTypes.number.isRequired,
  contributionTitle: PropTypes.string.isRequired,
  client: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  name: PropTypes.string.isRequired,
  scheduleData: PropTypes.shape({
    timeZone: PropTypes.string.isRequired,
    selectedSlot: PropTypes.shape({
      start: PropTypes.shape({}).isRequired,
      end: PropTypes.shape({}).isRequired,
    }).isRequired,
    selectedDate: PropTypes.string.isRequired,
  }).isRequired,
  setpricingOption: PropTypes.func.isRequired,
  pricingOption: PropTypes.shape({
    type: PropTypes.string.isRequired,
    amount: PropTypes.number.isRequired,
  }).isRequired,
  calculatePricings: PropTypes.func.isRequired,
  contributionSelectionDisable: PropTypes.bool.isRequired,
  fromMasterCalendar: PropTypes.bool.isRequired,
  activeContribution: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  }).isRequired,
  colorToUse: PropTypes.shape({
    PrimaryColorCode: PropTypes.string.isRequired,
  }).isRequired,
  mobileView: PropTypes.bool.isRequired,
};

export default BookingSummary;
