import React, { useState, useCallback, useEffect } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { css } from 'styled-components';
import { TextField } from '@material-ui/core';

import { Formik, Form, FieldArray, Field } from 'formik';

import { TOOLTIP } from 'pages/ContributionView/ContributionSessions/constants';
import { colors } from 'utils/styles';
import DetailsContainerWithHeader from './DetailsContainerWithHeader';
import useScreenView from 'hooks/useScreenView';
import InputWithTitle from './InputWithTitle';
import { StyledLinkButton, StyledTitle, StyledInfoIcon, StyledTooltip } from './SharedStyledComponents';
import { FlexContainer, FlexColumn, Image, StyledBannerTitleRow } from './StyleComponents';
import MediaDropZone from './MediaDropZone';
import DisplayContent from './DisplayContent';
import { CrossOutlineIcon } from './Icon';
import { useVideoDurationParse } from '../hooks/useVideoDurationParse';
import { useLeadMagnet } from 'pages/LeadMagnetView/hooks';
import styled from 'styled-components';

const styleOverrides = css`
  justify-content: flex-start;
  gap: 5px;
`;

const StyledPlaceholderText = styled.div`
  color: #bdbdbd;
  text-align: center;
  font-family: Avenir;
  font-size: 1rem;
  font-style: normal;
  font-weight: 350;
  line-height: 1.25rem; /* 125% */

  padding-right: ${({ mobileView }) => (mobileView ? '1.25rem' : '4rem')};
  padding-left: ${({ mobileView }) => (mobileView ? '1.25rem' : '4rem')};

  .bold {
    color: #bdbdbd;
    font-family: Avenir;
    font-size: 1rem;
    font-style: normal;
    font-weight: 900;
    line-height: 1.25rem;
  }
`;

const StyledTextField = styled(TextField)`
  .MuiOutlinedInput-notchedOutline {
    border: none;
  }
`;

const LeadMagnetContent = ({ values, errors, touched, setFieldValue }) => {
  const { contentHeader, content } = values;
  const { mobileView } = useScreenView();
  const [addNewContent, setAddNewContent] = useState(content.length <= 0);
  const [contentIndex, setContentIndex] = useState(content.length);
  const [embeddUrl, setEmbeddUrl] = useState('');

  const { uploadVideo, videoSrc, loading, progress, videoError } = useVideoDurationParse();

  const leadMagnet = useLeadMagnet();

  const handleUpload = useCallback(
    files => {
      return uploadVideo(files, leadMagnet);
    },
    [uploadVideo, leadMagnet],
  );

  const getFormProps = fieldName => {
    return {
      // value: values?.[fieldPath],
      value: values?.[fieldName],
      name: fieldName,
      onChange: v => {
        setFieldValue(fieldName, v);
      },
    };
  };

  return (
    <DetailsContainerWithHeader
      firstHeading="Add Your Lead Magnet Content"
      toolTip="This is the section for adding your lead magnet content."
      headingStyles={
        mobileView
          ? css`
              color: rgba(0, 0, 0, 0.87);
              font-family: Avenir;
              font-size: 1.0625rem;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
            `
          : css`
              color: rgba(0, 0, 0, 0.87);
              font-family: Avenir;
              font-size: 1.125rem;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
            `
      }
    >
      <FlexContainer {...{ mobileView }}>
        <FieldArray name="content">
          {({ push, remove, form }) => {
            return (
              <>
                <FlexColumn>
                  <div style={{ display: 'flex', justifyContent: 'space-between', gap: '5px' }}>
                    <FlexContainer $styleOverrides={styleOverrides}>
                      <StyledTitle>Upload your Lead Magnet</StyledTitle>
                      <StyledTooltip
                        title="Add content that the user will be able to view and download for your lead magnet."
                        arrow
                        enterTouchDelay={TOOLTIP.ENTER_DELAY}
                        leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                        style={{ margin: '0px' }}
                      >
                        <StyledInfoIcon htmlColor={colors.lightBrown} />
                      </StyledTooltip>
                    </FlexContainer>
                  </div>
                  {values?.content?.length
                    ? values?.content.map((content, i) => {
                        return <DisplayContent {...{ content, setFieldValue, i, values, remove }} />;
                      })
                    : null}
                  {(addNewContent || !values?.content?.length) && (
                    <>
                      {values?.content?.length && addNewContent ? (
                        <div
                          style={{ display: 'flex', justifyContent: 'flex-end', gap: '5px', marginBottom: '0.5rem' }}
                        >
                          <div style={{ cursor: 'pointer' }} onClick={() => setAddNewContent(false)}>
                            <CrossOutlineIcon />
                          </div>
                        </div>
                      ) : null}
                      <MediaDropZone
                        action={files => {
                          handleUpload(files).then(data => {
                            setAddNewContent(false);
                            push({
                              embeddedVideoUrl: data?.embeddedVideoUrl,
                              documentKeyWithExtension: data?.documentKeyWithExtension,
                              documentOriginalNameWithExtension: data?.documentOriginalNameWithExtension,
                              contentType: data?.contentType,
                              duration: data?.duration,
                              extension: data?.extension,
                              uploadDateTime: data?.uploadDateTime,
                              attachementUrl: data?.attachementUrl,
                              thumbnailUrl: data?.thumbnailUrl,
                            });
                          });
                        }}
                        isDisabled={false}
                        placeholder={
                          <StyledPlaceholderText {...{ mobileView }}>
                            Drag and drop your <span className="bold">video, audio, pdf or course</span> content here or
                            click this box to select a file to upload. Max file size is 3GB
                          </StyledPlaceholderText>
                        }
                        type="video/mpeg,video/mp4,video/avi,video/webm,video/mkv,video/mov,video/quicktime,video/*,audio/mp3,audio/*,application/pdf"
                        loading={loading}
                        progress={progress}
                        stopPropagation
                      ></MediaDropZone>
                      <StyledTextField
                        fullWidth
                        name="embeddUrl"
                        placeholder={
                          // <StyledPlaceholderText>
                          //   or <span className="bold">Embed a video link</span> by pasting URL (e.g.
                          //   http://www.vimeo.com)
                          // </StyledPlaceholderText>
                          `or Embed a video link by pasting URL (e.g. http://www.vimeo.com)`
                        }
                        variant="outlined"
                        onChange={e => {
                          if (embeddUrl === '') {
                            setEmbeddUrl(e.target.value);
                            push({
                              embeddedVideoUrl: e.target.value,
                              documentKeyWithExtension: '',
                              documentOriginalNameWithExtension: '',
                              // contentType: '',
                              duration: '',
                              extension: '',
                              uploadDateTime: '',
                              attachementUrl: '',
                              thumnailUrl: '',
                            });
                            setAddNewContent(false);
                            setEmbeddUrl('');
                          } else {
                            setEmbeddUrl(e.target.value);
                            setFieldValue(`content[${content.length - 1}].embeddedVideoUrl`, e.target.value);
                            setAddNewContent(false);
                            setEmbeddUrl('');
                          }
                        }}
                        value={embeddUrl}
                        type="text"
                        InputProps={{
                          style: {
                            backgroundColor: '#f5f5f5',
                            borderRadius: '0.25rem',
                          },
                          // classes: { notchedOutline: classNames.noBorder },
                        }}
                        disabled={loading}
                      />
                    </>
                  )}
                </FlexColumn>
                <FlexColumn>
                  <InputWithTitle
                    wordLimit={80}
                    title="Content Section Header Text"
                    toolTip="This is the header text that displays in the header above your content."
                    textField
                    formprops={getFormProps('contentHeader')}
                    placeholder="Download Now"
                  />
                  <FlexContainer
                    style={{ justifyContent: 'flex-start', gap: '5px', cursor: 'pointer' }}
                    onClick={() => {
                      setAddNewContent(true);
                    }}
                  >
                    <AddIcon />
                    <StyledLinkButton type="button">Additional Lead Magnet Content (Optional)</StyledLinkButton>
                  </FlexContainer>
                </FlexColumn>
              </>
            );
          }}
        </FieldArray>
      </FlexContainer>
    </DetailsContainerWithHeader>
  );
};

export default LeadMagnetContent;
