import React from 'react';

const CheckIconSvg = () => {
  return (
    <div>
      <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M13.6029 0.170254C13.8569 0.392504 13.8827 0.778581 13.6604 1.03258L5.10487 10.8103C4.99349 10.9376 4.83434 11.0131 4.6653 11.0187C4.49625 11.0243 4.33244 10.9596 4.21284 10.8401L0.546178 7.17339C0.307524 6.93473 0.307524 6.5478 0.546178 6.30915C0.784831 6.07049 1.17177 6.07049 1.41042 6.30915L4.61519 9.51392L12.7406 0.227743C12.9629 -0.0262572 13.3489 -0.0519956 13.6029 0.170254Z"
          fill="white"
        />
      </svg>
    </div>
  );
};

export default CheckIconSvg;
