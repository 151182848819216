export const INFO_MODAL_TYPES = {
  CANCEL_SUCCESS: 'cancel success',
  CANCEL_FAILED: 'cancel failed',
};

export const INFO_MODAL_TEXTS = {
  [INFO_MODAL_TYPES.CANCEL_SUCCESS]: {
    title: 'Cancel success',
    text: 'You successfully cancel subscription',
  },
  [INFO_MODAL_TYPES.CANCEL_SUCCESS]: {
    title: 'Cancel failed',
    text: 'Error in cancellation subscription',
  },
};
