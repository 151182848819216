import useShallowEqualSelector from '../useShallowEqualSelector';

const chatSelector = state => state?.chat;

const tokenSelector = state => chatSelector(state)?.token;

const useChat = () => {
  const token = useShallowEqualSelector(tokenSelector);

  return { token };
};

export default useChat;
