import { STICK_HEADER, UNSTICK_HEADER } from 'actions/header';

const initialState = {
  stickyHeader: false,
};

const stickyHeaderReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case STICK_HEADER:
      return { stickyHeader: true };
    case UNSTICK_HEADER:
      return { stickyHeader: false };
    default:
      return state;
  }
};

export default stickyHeaderReducer;
