import { handleActions } from 'redux-actions';

import { setDashboardIncome, clearDashboardIncome } from 'actions/dashboardIncome';

const initialState = {
  incomes: [],
};

const dashboardIncome = handleActions(
  {
    [setDashboardIncome]: (state, { payload }) => ({
      ...state,
      incomes: payload,
    }),
    [clearDashboardIncome]: () => ({ ...initialState }),
  },
  initialState,
);

export default dashboardIncome;
