import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import withStyles from '@material-ui/core/styles/withStyles';
import { Icon } from '@mdi/react';
import { mdiDelete } from '@mdi/js';
import { useHttp, useAccount, useVideoChatActions } from 'hooks';
import { useTheme, useMediaQuery } from '@material-ui/core';
import Popup from 'components/Popup/Popup';
import LeadMagnetRow from './LeadMagnetRow';
import PaginationRounded from './PaginationAllContributions';
import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { cloneDeep } from 'lodash';
import { LeadMagnetStatus } from 'helpers/constants';

const BoldTableCell = withStyles({
  root: {
    fontWeight: 800,
  },
})(TableCell);

const BoldTableCellNext = withStyles({
  root: {
    fontWeight: 800,
    paddingLeft: '30px',
  },
})(TableCell);

const BoldTableCellStatus = withStyles({
  root: {
    fontWeight: 800,
    paddingLeft: '10px',
  },
})(TableCell);

const BoldTableCellEdit = withStyles({
  root: {
    fontWeight: 800,
    paddingLeft: '25px',
  },
})(TableCell);
const StyledTableContainer = withStyles({
  root: {
    overflowX: 'auto',
    maxWidth: props => (props.mobileView ? '96vw' : '100%'),
    margin: '0 auto',
  },
})(TableContainer);
const StyledIcon = styled(Icon)`
  // margin: 0 10px;
`;
const LeadMagnetsTable = ({ totalCount, childpageNumber, rows, isArchivedPage = false }) => {
  const location = useLocation();
  const [popupText, setPopupText] = useState(null);
  const { request } = useHttp();
  const { user } = useAccount();
  const [dataList, setDataList] = useState(rows);
  const { startVideoChat } = useVideoChatActions();
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));

  const handlePageNumber = useCallback(page => {
    childpageNumber(page);
  }, []);

  useEffect(() => {
    setDataList(rows);
    console.info('dataList:', cloneDeep(dataList));
  }, [rows]);

  const handleClosePopup = () => {
    setPopupText(null);
  };
  const deleteIndex = indexData => {
    const data1 = dataList.filter((item, index) => index !== indexData);
    setDataList(data1);
  };
  const archiveIndex = indexData => {
    const data2 = dataList.filter((item, index) => index !== indexData);
    setDataList(data2);
  };
  return (
    <>
      <StyledTableContainer component={Paper} mobileView={mobileView}>
        <Table className="" aria-label="lead magnets table">
          <TableHead>
            <TableRow>
              <BoldTableCell>Title</BoldTableCell>
              {/* <BoldTableCellNext>Next Session</BoldTableCellNext> */}
              <BoldTableCell align="left">Status</BoldTableCell>
              <BoldTableCell align="left">Type</BoldTableCell>
              <BoldTableCellEdit align="center">Share</BoldTableCellEdit>
              <BoldTableCellEdit align="center">Edit</BoldTableCellEdit>
              <BoldTableCellEdit align="center">Export Leads</BoldTableCellEdit>
              <BoldTableCellEdit align="center">Analytics</BoldTableCellEdit>
              {isArchivedPage ? null : <BoldTableCell>{/* <StyledIcon path={mdiDelete} size={1} /> */}</BoldTableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {dataList.map((row, index, recordsList) => (
              <LeadMagnetRow
                rows={recordsList}
                index={index}
                key={row.id}
                {...row}
                setPopupText={setPopupText}
                deleteIndex={deleteIndex}
                archiveIndex={archiveIndex}
                {...{ isArchivedPage, isArchived: /*isArchivedPage ||*/ row?.status === LeadMagnetStatus.completed }}
              />
            ))}
          </TableBody>
        </Table>
      </StyledTableContainer>
      {/* {location.pathname === '/contributions/all' && totalCount > 10 && (
        <div style={{ padding: '25px 0px 25px 0px', display: 'flex', justifyContent: 'center' }}>
          <PaginationRounded Totalcount={totalCount} pageNumber={handlePageNumber} />
        </div>
      )} */}
    </>
  );
};

LeadMagnetsTable.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.object),
  isArchived: PropTypes.bool,
};

LeadMagnetsTable.defaultProps = {
  rows: [],
  isArchived: false,
};

export default LeadMagnetsTable;
