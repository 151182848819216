import React, { useEffect, useMemo, useState } from 'react';
import classes from './Clients.module.scss';
import { StyledHeading } from '../Components/StyledComponents/StyledComponents';
import Buttons from '../Components/ContactsButtons/ContactsButtons';
import { PAGE_TYPES } from '../Components/ContactsButtons/ContactsButtons';
import { DummyClients } from './DummyClients';
import { FormControlLabel, Checkbox, useMediaQuery, useTheme } from '@material-ui/core';
import styled from 'styled-components';
import SearchBox from 'pages/EmailCampaigns/Components/SearchBox/SearchBox';
import { ClickAwayListener } from '@material-ui/core';
import FilterIcon from 'pages/EmailCampaigns/Styles/SVG/FilterSvg';
import ClientsTable from './Clients/ClientsTable';
import PaginationBar from 'pages/EmailCampaigns/Components/PaginationBar/PaginationBar';
import useRouter from 'hooks/useRouter';
import useSelection from 'antd/lib/table/hooks/useSelection';
import { useDispatch, useSelector } from 'react-redux';
import Loader from 'components/UI/Loader';
import { PER_PAGE_CLIENTS_COUNT } from 'pages/EmailCampaigns/Styles/Constants/constants';
import { useHeader } from 'hooks/usePageActions';
import { isEmpty } from 'lodash';
import { getSearchedAndFilteredData } from '../Utils/functions';
import { FilterTypes } from '../Components/Filter/FilterConstants';
import ClientsFilter from '../Components/Filter/Filter';
import Modal from 'components/UI/Modal';
import AddContactTagModal from '../Leads/LeadModals/AddContactTagModal';
import Select from 'components/UI/Select';
import ImportFileContent from '../Components/ImportFileContent/ImportFileContent';
import moment from 'moment';
import { ContactsServices } from '../Services/Contacts.service';
import { downloadFile } from 'utils/utils';
import MainContainer from 'components/Containers/MainContainer';
import { StyledMainSection } from 'components/Containers/CreateContributionContainer';
import Container from 'pages/CreateCampaign/Components/Containers/MainContainer/MainContainer';
import { fetchAllContactsAsync } from 'actions/contacts';
import { setSendEmailTo } from 'actions/campaign';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import useCampaign from 'pages/CreateCampaign/Hooks/useCampaign';
import Notification from 'pages/CreateCampaign/Components/Notification/Notification';

const StyledLabel = styled.div`
  font-family: 'Avenir';
  font-weight: 350;
  font-size: 14px;
  line-height: 16px;
  color: #4a4a4a;
`;

const FilterTag = styled.div`
  position: absolute;
  right: -5px;
  top: -5px;
  background-color: ${({ color }) => color ?? 'black'};
  width: 18px;
  height: 18px;
  border-radius: 9px;
`;

const PerPageCount = 8;

const AllClients = ({
  showTopBar = true,
  showFilter = true,
  clients = [],
  setClients,
  markedClients,
  isReadOnlyView,
  onBackClick,
}) => {
  const {
    location: { pathname },
  } = useRouter();
  const dispatch = useDispatch();
  const history = useHistory();
  useHeader('Clients');

  const { isLoading, clients: LSClients, contacts } = useSelector(state => state.contacts);
  const isCreateOrEditCampaignPage = pathname.includes('create-campaign') || pathname.includes('edit-campaign');
  const isTagsPage = pathname.includes('/tags');
  const isAllCampaignsPage = pathname.includes('/emails/campaigns');
  const isPathForAllContacts =
    pathname.includes('contacts') && !isCreateOrEditCampaignPage && !isTagsPage && !isReadOnlyView;
  const [data, setData] = useState(isCreateOrEditCampaignPage || isReadOnlyView || isTagsPage ? clients : LSClients);

  const [selectedClients, setSelectedClients] = useState(
    isCreateOrEditCampaignPage || isReadOnlyView || isTagsPage ? markedClients : [],
  );

  const [allSelected, setAllSelected] = useState(
    selectedClients?.length > 0 && selectedClients?.length === data?.length ? true : false,
  );
  const [filterPanelOpen, setFilterPanelOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchedKeyword, setSearchedKeyword] = useState('');
  const [appliedFilters, setAppliedFilters] = useState({});
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [isDeleteContactsModalOpen, setIsDeleteContactsModalOpen] = useState(false);
  const [isAddTagModalOpen, setIsAddTagModalOpen] = useState(false);
  const [isImportFileModalOpen, setIsImportFileModalOpen] = useState(false);
  const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);
  const [isExporting, setIsExporting] = useState(false);
  const [isImporting, setIsImporting] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isUnderstandChecked, setIsUnderstandChecked] = useState(false);
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));

  const handleSubmitFilter = values => {
    setAppliedFilters(values);
    setIsFilterApplied(true);
    setFilterPanelOpen(false);
  };

  const handleDeleteAll = () => {
    setIsDeleteContactsModalOpen(true);
  };
  const handleAddTag = () => {
    setIsAddTagModalOpen(true);
  };

  const handleSendEmail = () => {
    const data = LSClients?.filter(c => selectedClients?.includes(c.id));
    dispatch(setSendEmailTo(data));
    history.push('/create-campaign/title');
    // setIsEmailModalOpen(true);
  };

  const handleSearch = e => {
    setSearchedKeyword(e.target.value.trim());
  };

  const handleFilterClick = () => {
    setFilterPanelOpen(!filterPanelOpen);
  };

  const isChecked = id => {
    return selectedClients?.includes(id);
  };

  const onCheckClicked = id => {
    if (isChecked(id)) {
      setSelectedClients(selectedClients.filter(c => c !== id));
      if (isCreateOrEditCampaignPage || isTagsPage) {
        setClients(selectedClients.filter(c => c !== id));
      }
    } else {
      setSelectedClients([...selectedClients, ...[id]]);
      if (isCreateOrEditCampaignPage || isTagsPage) {
        setClients([...selectedClients, ...[id]]);
      }
    }
  };

  useEffect(() => {
    if (isCreateOrEditCampaignPage || isReadOnlyView || isTagsPage) {
      setSelectedClients(markedClients);
    }
  }, [markedClients]);

  useEffect(() => {
    if (isCreateOrEditCampaignPage || isReadOnlyView || isTagsPage) {
      setData(clients);
    } else {
      setData(LSClients);
    }
  }, [LSClients, clients]);

  useEffect(() => {
    if (selectedClients?.length > 0 && selectedClients?.length === data?.length) {
      setAllSelected(true);
    } else {
      setAllSelected(false);
    }
  }, [selectedClients]);

  const pageCount = useMemo(() => {
    const count = Math.ceil(
      getSearchedAndFilteredData(data, searchedKeyword?.toLocaleLowerCase(), appliedFilters)?.length /
        PER_PAGE_CLIENTS_COUNT,
    );
    return count;
  }, [data, searchedKeyword, appliedFilters]);

  useEffect(() => {
    setCurrentPage(1);
  }, [pageCount]);
  const { campaignNotification } = useCampaign();
  const handleExportClick = () => {
    setIsExporting(true);
    // const data = {
    //   text: searchedKeyword || undefined,
    //   fromDate: appliedFilters?.fromDate
    //     ? `${moment(appliedFilters?.fromDate).format('YYYY-MM-DD')}T${moment(appliedFilters?.fromDate).format(
    //         'hh:mm:ss',
    //       )}Z`
    //     : undefined,
    //   toDate: appliedFilters?.toDate
    //     ? `${moment(appliedFilters?.toDate).format('YYYY-MM-DD')}T${moment(appliedFilters?.toDate).format('hh:mm:ss')}Z`
    //     : undefined,
    //   tagFilter: appliedFilters?.tags,
    //   contributionFilter: appliedFilters?.contributions,
    // };
    const type = 'clients';
    ContactsServices.exportContacts(type)
      .then(res => {
        downloadFile(res, 'Clients List.csv');
      })
      .catch(err => {})
      .finally(() => {
        setIsExporting(false);
      });
  };

  const handleImportClick = () => {
    setIsImportFileModalOpen(true);
  };

  const handleBackClick = () => {
    onBackClick();
  };

  const renderClients = () => {
    return (
      <div className={classes.container}>
        {!isEmpty(campaignNotification) && <Notification notification={campaignNotification} />}
        {showTopBar && (
          <div className={classes.topBar}>
            <StyledHeading>Clients</StyledHeading>
            <Buttons
              page={PAGE_TYPES.Clients}
              exportClickHandler={handleExportClick}
              importClickHandler={handleImportClick}
              exportBtnClassName={isExporting && classes.export_btn_disabled}
              importBtnClassName={isImporting && classes.import_btn_disabled}
            />
          </div>
        )}
        <div className={classes.searchBar}>
          <div style={{ width: '50%', display: 'flex', justifyContent: 'flex-start', gap: '10px' }}>
            {!isAllCampaignsPage && (
              <FormControlLabel
                style={{ fontFamily: 'Avenir', marginRight: '5px' }}
                checked={allSelected}
                onChange={() => {
                  setAllSelected(!allSelected);
                  if (allSelected === false) {
                    setSelectedClients([...data?.map(c => c.id)]);
                    if (isCreateOrEditCampaignPage || isTagsPage) {
                      setClients([...data?.map(c => c.id)]);
                    }
                  } else {
                    setSelectedClients([]);
                    if (isCreateOrEditCampaignPage || isTagsPage) {
                      setClients([]);
                    }
                  }
                }}
                control={<Checkbox color="primary" />}
                label={<StyledLabel>Select all</StyledLabel>}
                name="allSelect"
                disabled={isReadOnlyView}
              />
            )}
            {isAllCampaignsPage && <div className={classes.backBtn} onClick={handleBackClick}>{`< Back`}</div>}
            {selectedClients?.length > 0 && isPathForAllContacts && (
              <button className={classes.deleteBtn} type="button" onClick={handleDeleteAll}>
                Delete
              </button>
            )}
            {selectedClients?.length > 0 && isPathForAllContacts && (
              <button className={classes.addTagBtn} type="button" onClick={handleAddTag}>
                Add Tag
              </button>
            )}
            {selectedClients?.length > 0 && isPathForAllContacts && (
              <button className={classes.sendEmailBtn} type="button" onClick={handleSendEmail}>
                Send an Email
              </button>
            )}
          </div>
          <div style={{ width: '50%', display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
            <div>
              <SearchBox
                placeholder="Search"
                background="white"
                onChange={handleSearch}
                searchIconColor="rgba(0, 0, 0, 0.87)"
              />
            </div>
            {showFilter && (
              <ClickAwayListener
                onClickAway={() => {
                  setFilterPanelOpen(false);
                }}
              >
                <div style={{ position: 'relative' }}>
                  <button className={classes.filterBtn} type="button" onClick={handleFilterClick}>
                    <FilterIcon /> Filter
                  </button>
                  {!isEmpty(appliedFilters) && <FilterTag color="#215C73" />}
                  {filterPanelOpen && (
                    <ClientsFilter
                      type={FilterTypes.Client}
                      value={appliedFilters}
                      onCancelClick={() => {
                        setFilterPanelOpen(false);
                      }}
                      onSubmitClick={handleSubmitFilter}
                      onClear={() => {
                        setAppliedFilters({});
                        setIsFilterApplied(false);
                        setFilterPanelOpen(false);
                      }}
                      contacts={contacts?.filter(item => item.isDeleted === false)}
                    />
                  )}
                </div>
              </ClickAwayListener>
            )}
          </div>
        </div>
        <div className={classes.clientsTable}>
          {isLoading ? (
            <Loader />
          ) : (
            <>
              <ClientsTable
                clients={getSearchedAndFilteredData(data, searchedKeyword?.toLocaleLowerCase(), appliedFilters)?.slice(
                  (currentPage - 1) * PER_PAGE_CLIENTS_COUNT,
                  currentPage * PER_PAGE_CLIENTS_COUNT,
                )}
                isChecked={isChecked}
                onCheckClicked={onCheckClicked}
                isReadOnlyView={isReadOnlyView}
                searchedKeyword={searchedKeyword}
              />
              {data?.length > 0 && (
                <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'flex-end' }}>
                  <div style={{ fontSize: '13px', fontWeight: '400', position: 'relative', bottom: '-28px' }}>
                    {currentPage * PER_PAGE_CLIENTS_COUNT - PER_PAGE_CLIENTS_COUNT === 0
                      ? 1
                      : currentPage * PER_PAGE_CLIENTS_COUNT - PER_PAGE_CLIENTS_COUNT + 1}{' '}
                    -{' '}
                    {pageCount != currentPage
                      ? currentPage * PER_PAGE_CLIENTS_COUNT
                      : getSearchedAndFilteredData(data, searchedKeyword?.toLocaleLowerCase(), appliedFilters)
                          ?.length}{' '}
                    of {getSearchedAndFilteredData(data, searchedKeyword?.toLocaleLowerCase(), appliedFilters)?.length}
                  </div>
                  <PaginationBar
                    paginationClassName={!showTopBar && classes.pagination}
                    count={pageCount}
                    handleChange={(e, page) => {
                      setCurrentPage(page);
                    }}
                    page={currentPage}
                  />
                </div>
              )}
            </>
          )}
        </div>
        <Modal
          isOpen={isDeleteContactsModalOpen}
          title="Delete Contact(s)"
          submitTitle="Delete"
          onSubmit={() => {
            setIsDeleting(true);
            ContactsServices.deleteContact([...selectedClients])
              .then(res => {
                setSelectedClients([]);
                setIsUnderstandChecked(false);
                setIsDeleteContactsModalOpen(false);
                dispatch(fetchAllContactsAsync({}));
              })
              .catch(err => {})
              .finally(() => {
                setIsDeleting(false);
              });
          }}
          onCancel={() => {
            setIsDeleteContactsModalOpen(false);
          }}
          reduceCancelButton
          disableSubmitClick={!isUnderstandChecked || isDeleting}
        >
          <div className={classes.delete_modal_main}>
            <div className={classes.top_heading}>
              Are you sure you want to delete this/these contact(s)? Your action cannot be undone.
            </div>
            <div className={classes.form_control_label_container}>
              <FormControlLabel
                checked={isUnderstandChecked}
                onChange={() => {
                  setIsUnderstandChecked(!isUnderstandChecked);
                }}
                control={<Checkbox className={classes.checkbox_root} disableRipple={true} color="primary" />}
                className={classes.form_control}
                label={
                  <div className={classes.form_label}>
                    Please note: This action will remove the contact from all lists and services. They will need to
                    re-enroll to be added back to your contacts.
                  </div>
                }
                name="understand"
                disabled={false}
              />
            </div>
            <div>
              <div className={classes.contact_list_heading}>Following contact(s) will be deleted:</div>
              <div className={classes.contact_list}>
                {LSClients?.filter(c => selectedClients.includes(c.id))?.map((item, index) => {
                  const isLastIndex = index === selectedClients?.length - 1;
                  return isLastIndex ? (
                    <div>{`${item?.firstName} ${item?.lastName}`}</div>
                  ) : (
                    <div>{`${item?.firstName} ${item?.lastName}, `}</div>
                  );
                })}
              </div>
            </div>
          </div>
        </Modal>

        {isAddTagModalOpen && (
          <AddContactTagModal
            contacts={LSClients?.filter(client => selectedClients.includes(client.id))}
            contactsList={LSClients.map(c => ({ title: `${c.firstName} ${c.lastName}`, value: c.id }))}
            isOpen={isAddTagModalOpen}
            onClose={() => {
              setSelectedClients([]);
              setIsAddTagModalOpen(false);
            }}
          />
        )}

        <Modal
          title="Send a One-Time Email"
          isOpen={isEmailModalOpen}
          onCancel={() => {
            setIsEmailModalOpen(false);
          }}
          onSubmit={() => {
            setIsEmailModalOpen(false);
          }}
          submitTitle="Send"
          reduceCancelButton
          widthRequired
        >
          <div className={classes.email_modal_main}>
            <div>
              <div className={classes.label}>Send a One-Time Email</div>
              <div className={classes.select_container}>
                <Select
                  className={classes.select}
                  value={['All']}
                  onChange={() => {}}
                  multiple
                  items={[{ title: 'All', value: 'All' }]}
                />
              </div>
            </div>
          </div>
        </Modal>

        {isImportFileModalOpen && (
          <ImportFileContent
            title="Import CSV file"
            isOpen={isImportFileModalOpen}
            onCancel={() => {
              setIsImportFileModalOpen(false);
            }}
          />
        )}
      </div>
    );
  };
  console.log('test---------->', LSClients, contacts);
  return (
    <>
      {isCreateOrEditCampaignPage || isAllCampaignsPage ? (
        renderClients()
      ) : (
        <>
          {isTagsPage ? (
            <Container
              headerProps={{
                backButtonText: 'Done',
                headerTitle: 'Contacts in Campaign',
                handleBackClick,
              }}
            >
              {renderClients()}
            </Container>
          ) : (
            <MainContainer>
              <StyledMainSection mobileView={mobileView}>{renderClients()}</StyledMainSection>
            </MainContainer>
          )}
        </>
      )}
    </>
  );
};

export default AllClients;
