import { cloneDeep } from 'lodash';
import React, { Component } from 'react';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      hasError: true,
      error: error,
      errorInfo: errorInfo,
    });

    // Log the error to the console (you can customize this log as needed)
    console.error('Error Boundary caught an error:', cloneDeep(error), cloneDeep(errorInfo));
  }

  render() {
    if (this.state.hasError) {
      // Do not render anything, just handle the error
      return null;
    }

    // If there's no error, render the children as usual
    return this.props.children;
  }
}

export default ErrorBoundary;
