import useRouter from './useRouter';
import { useEffect, useState } from 'react';

const useClientPreview = () => {
  const { pathname } = useRouter();
  const [clientPreviewMode, setClientPreviewMode] = useState(pathname?.includes('clientpreview'));

  useEffect(() => {
    if (pathname) {
      setClientPreviewMode(pathname?.includes('clientpreview'));
    }
  }, [pathname]);

  return { clientPreviewMode };
};

export default useClientPreview;
