import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { useHeader } from 'hooks';
import { fetchArchived } from 'actions/contributions';

import Loader from 'components/UI/Loader';
import ContributionsTable from 'components/Tables/Contributions';

const ArchivedPage = ({ archived, getContributions, loading, userId }) => {
  useHeader('Archived');

  const renderList = useCallback(async () => {
    getContributions(userId);
  }, [getContributions, userId]);

  useEffect(() => {
    renderList();
  }, [renderList]);

  if (loading) {
    return <Loader />;
  }

  if (!archived || !archived.length) {
    return <div>This is where your completed courses and archived 1:1 services will appear.</div>;
  }

  return (
    <>
      <p>This is record of your completed courses and archived 1:1 services.</p>
      <ContributionsTable rows={archived} isArchived />
    </>
  );
};

const mapStateToProps = ({ archived: { loading, error, archived }, account }) => ({
  userId: account?.user?.id,
  loading,
  error,
  archived: archived || [],
});

const actions = {
  getContributions: fetchArchived,
};

ArchivedPage.propTypes = {
  userId: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  archived: PropTypes.arrayOf(PropTypes.object).isRequired,
  getContributions: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, actions)(ArchivedPage);
