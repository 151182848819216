import React from 'react'
import PropTypes from 'prop-types'
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile'

import MediaLink from '../MediaLink'
import getDocumentDisplaySize, { DisplaySizeUnits } from './getDocumentDisplaySize'

import './DocumentMedia.scss'

const DisplaySizeUnitFormats = {
  [DisplaySizeUnits.byte]: 'B',
  [DisplaySizeUnits.kiloByte]: 'KB',
  [DisplaySizeUnits.megaByte]: 'MB',
  [DisplaySizeUnits.gigaByte]: 'GB',
}

const DocumentMedia = ({ size, fileName, url }) => {
  const { size: displaySize, units: displayUnits } = getDocumentDisplaySize(size)

  return (
    <MediaLink url={url}>
      <div className="chat-message-content-media-document">
        <div className="chat-message-content-media-document__icon chat-message-content-media-document-icon">
          <InsertDriveFileIcon fontSize="inherit" />
        </div>
        <p className="chat-message-content-media-document__name">{fileName}</p>
        <p className="chat-message-content-media-document__size">
          {Math.round(displaySize * 100) / 100} {DisplaySizeUnitFormats[displayUnits]}
        </p>
      </div>
    </MediaLink>
  )
}

DocumentMedia.propTypes = {
  size: PropTypes.number.isRequired,
  fileName: PropTypes.string.isRequired,
  url: PropTypes.string,
}

DocumentMedia.defaultProps = {
  url: null,
}

export default DocumentMedia
