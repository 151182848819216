import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import ApplicationFormDetail from 'pages/ApplicationForm/components/ApplicationFormDetail';
import Modal from 'components/UI/Modal';
import PropTypes from 'prop-types';
import { fetchApplicationsActions } from 'actions/applications';
import '../../pages/AllApplicationsPage/ApplicationPage.scss';
import { updateApplicationFormStatus } from 'services/applications.service';
import Loader from 'components/UI/Loader';
import Button from 'components/FormUI/Button';
import ForwardSvg from './icons/ForwardIcon';
import BackwardSvg from './icons/BackwardIcon';

export const applicationStatus = {
  pending: 'Pending',
  rejected: 'Rejected',
  approved: 'Approved',
};

export const approveOrRejectForm = (formResponseId, isAccepted, setLoader, dispatch) => {
  setLoader(true);
  updateApplicationFormStatus(formResponseId, isAccepted)
    .then(() => {
      dispatch(fetchApplicationsActions.acceptedOrRejected({ formResponseId, isAccepted }));
    })
    .finally(() => {
      setLoader(false);
    });
};

export const ApplicationFormModal = ({
  key,
  showApplicationFormDetailPopUp,
  setShowApplicationFormDetailPopUp,
  row,
  totalRows,
  openedIndex,
  setOpenedIndex,
}) => {
  const [confirmationModalData, setConfirmationModalData] = useState(null);
  const dispatch = useDispatch();
  const handleNext = () => {
    const newIndex = openedIndex + 1;
    if (newIndex < totalRows) {
      setOpenedIndex(newIndex);
    }
  };
  const handlePrevious = () => {
    const newIndex = openedIndex - 1;
    if (newIndex > -1) {
      setOpenedIndex(newIndex);
    }
  };
  const [loader, setLoader] = useState(false);
  const { status, id, isDeleted } = row;

  const footer = () => {
    return (
      <div className="detail-response-footer-container">
        <div className="detail-response-navigation-buttons">
          <span onClick={handlePrevious} style={{ cursor: 'pointer' }}>
            <BackwardSvg color={openedIndex <= 0 ? '#D3D3D3' : '#000'} />
          </span>
          <div className="detail-response-footer-buttons">
            {!isDeleted && status === applicationStatus.pending ? (
              <>
                <Button
                  buttonTitle="Approve"
                  onClick={() => {
                    setConfirmationModalData({
                      onSubmit: () => {
                        approveOrRejectForm(id, true, setLoader, dispatch);
                        setConfirmationModalData(null);
                      },
                      onCancel: () => setConfirmationModalData(null),
                      text: 'Are you sure you want to approve this application?',
                    });
                  }}
                  className="btn approve-button"
                  type="button"
                >
                  Approve
                </Button>
                <Button
                  buttonTitle="Reject"
                  onClick={() => {
                    setConfirmationModalData({
                      onSubmit: () => {
                        approveOrRejectForm(id, false, setLoader, dispatch);
                        setConfirmationModalData(null);
                      },
                      onCancel: () => setConfirmationModalData(null),
                      text: 'Are you sure you want to reject this application?',
                    });
                  }}
                  className="btn reject-button"
                  type="button"
                >
                  Reject
                </Button>
              </>
            ) : (
              <>
                {isDeleted ? (
                  <Button
                    buttonTitle="Not Responded"
                    style={{ width: 'initial' }}
                    className="btn rejected-button"
                    type="button"
                  >
                    Not Responded
                  </Button>
                ) : (
                  <Button buttonTitle={status} className={`btn ${status?.toLowerCase()}-button`} type="button">
                    {status}
                  </Button>
                )}
              </>
            )}
          </div>
          <span onClick={handleNext} style={{ cursor: 'pointer' }}>
            <ForwardSvg color={totalRows - 1 <= openedIndex ? '#D3D3D3' : '#000'} />
          </span>
        </div>
      </div>
    );
  };

  return (
    <>
      <Modal
        key={key}
        className="application-response-model"
        widthRequiredIs="500px"
        isOpen={showApplicationFormDetailPopUp}
        title="Application Details"
        hiddenCancel
        disableConfirm
        footer={footer()}
        hideFooterBorder
        onCancel={setShowApplicationFormDetailPopUp}
        headerBorder
        createPost={false}
      >
        <ApplicationFormDetail applicationFormResponse={row} />
      </Modal>
      <Modal
        title="Confirm"
        isOpen={Boolean(confirmationModalData)}
        onCancel={confirmationModalData?.onCancel}
        onSubmit={confirmationModalData?.onSubmit}
        cancelTitle="Cancel"
        submitTitle="Yes"
      >
        <p>{confirmationModalData?.text}</p>
      </Modal>
      {loader && <Loader />}
    </>
  );
};

ApplicationFormModal.propTypes = {
  showApplicationFormDetailPopUp: PropTypes.bool.isRequired,
  setShowApplicationFormDetailPopUp: PropTypes.func.isRequired,
  row: PropTypes.shape({
    status: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    isDeleted: PropTypes.bool,
  }),
  totalRows: PropTypes.number.isRequired,
  openedIndex: PropTypes.string.isRequired,
  setOpenedIndex: PropTypes.func.isRequired,
};

ApplicationFormModal.defaultProps = {
  row: {
    status: '',
    isDeleted: false,
  },
};
