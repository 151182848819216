import React, { useCallback, useMemo, useState, useEffect } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import './indexstyle.css';
import { getThemedColors } from 'services/contributions.service';
import { ContributionType } from 'helpers/constants';
import Select from 'components/FormUI/Select';
import copyContributionLinkToClipboard from 'utils/copyContributionLinkToClipboard';
import { Formik } from 'formik';
import * as S from './styled';
import { colors } from '../../../../../utils/styles';

const FREE_BUTTON_TITLE = 'Share Free Link';
const PAID_BUTTON_TITLE = 'Share Paid Link';
const TOOLTIP_TITLE = 'Copied to clipboard';

const StyledSelect = styled(Select)`
  max-width: 190px;
  margin-left: 20px !important;
  .MuiInputBase-root {
    margin-top: 0;
    .MuiSelect-root {
      color: ${({ color }) => color};
    }
    svg {
      color: ${({ color }) => color};
    }
  }
`;

const InviteFriendModalView = ({
  onCopyToClipboard,
  onPremiumCopyToClipboard,
  mobileView,
  tooltipOpen,
  setTooltipOpen,
  premiumTooltipOpen,
  setPremiumTooltipOpen,
  type,
  paymentInfo,
  isInvoiced,
  contributionId,
  setPriceId,
  applyTheming,
  symbol,
  inviteClientType,
}) => {
  const activeContribution = useSelector(state => state.contributions?.activeContribution);
  const contributionType = activeContribution?.type;
  const [defaultPrice, setdefaultPrice] = useState(null);
  const isLiveGroupOrMembership = useMemo(
    () =>
      type === ContributionType.contributionMembership ||
      type === ContributionType.contributionCourse ||
      type === ContributionType.contributionCommunity ||
      contributionType === ContributionType.contributionMembership ||
      contributionType === ContributionType.contributionCourse ||
      contributionType === ContributionType.contributionCommunity,
    [contributionType, type],
  );
  const [showtag, setShowtag] = useState(false);

  const { themedColor, themedBackgroundColor } = getThemedColors(activeContribution);

  const onCloseTooltip = useCallback(() => setTooltipOpen(false), [setTooltipOpen]);
  const onClosePremiumTooltip = useCallback(() => setPremiumTooltipOpen(false), [setPremiumTooltipOpen]);

  const paymentOptions = paymentInfo?.paymentOptions;
  const [age, setAge] = React.useState(null);

  const disablePaidButton = () => {
    if (inviteClientType === 'Free') {
      return true;
    }
    if (paymentOptions.length === 1) {
      if (paymentOptions.includes('Free')) {
        return true;
      } else if (paymentOptions.includes('FreeSessionsPackage')) {
        return true;
      } else {
        return false;
      }
    } else if (paymentOptions.length === 2) {
      if (paymentOptions.includes('Free') && paymentOptions.includes('FreeSessionsPackage')) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (
      (type === ContributionType.contributionCourse || contributionType == ContributionType.contributionCourse) &&
      paymentInfo?.multiplePriceList.length > 0
    ) {
      const defaultValue = paymentInfo?.multiplePriceList.filter(item => item?.isDefault);
      setdefaultPrice(false);
      setAge(defaultValue[0]?.id);
      setPriceId(defaultValue[0]?.id);
    }
  }, []);
  const dropDownData = paymentInfo.multiplePriceList.map(data => ({
    title:
      data.splittedCost != null && data?.cost != null
        ? `${symbol}${data?.cost} full price, ${symbol}${data?.splittedCost} split price`
        : data.cost === null
        ? `${symbol}${data?.splittedCost} split price`
        : `${symbol}${data?.cost} full price`,
    value: data.id,
  }));

  return (
    <S.ShareModalMain style={applyTheming ? { color: themedColor } : {}} mobileView={mobileView}>
      <S.ColumnFlexContainer style={applyTheming ? { color: themedColor } : {}}>
        <div className="top-note">
          <p style={applyTheming ? { color: themedColor } : {}}>
            You can invite your friends to join this contribution by copying your landing page link to clipboard through
            the button(s) below, and then pasting that link to invite your friends via email, text message, DM, social
            media & more!
          </p>
        </div>

        <div style={{ borderLeft: '4px solid #E9C64C', paddingLeft: '15px', marginBottom: '30px' }}>
          <S.Title
            style={{
              color: applyTheming ? themedColor : null,
            }}
          >
            <div className="Invite-Purchase">
              <p> Invite Friends to Join</p>
            </div>
          </S.Title>

          <div
            className="invite-links-container"
            style={{
              justifyContent: 'space-between',
              marginTop: '15px',
              maxWidth: '350px',
              display: 'flex',
              flexWrap: 'wrap',
            }}
          >
            {isLiveGroupOrMembership === false ? (
              inviteClientType !== 'Paid' &&
              (paymentInfo?.paymentOptions?.includes('Free') ||
                paymentInfo?.paymentOptions?.includes('FreeSessionsPackage')) && (
                <S.LinkWrapp hasPremiumPlan onClick={onPremiumCopyToClipboard}>
                  <Tooltip
                    className="cursor-pointer"
                    title={TOOLTIP_TITLE}
                    open={premiumTooltipOpen}
                    onClose={onClosePremiumTooltip}
                    arrow
                  >
                    <S.CopyToClipboardBlock className="share-btn" hasPremiumPlan>
                      <p style={applyTheming ? { color: themedColor, backgroundColor: themedBackgroundColor } : {}}>
                        <S.ButtonIcon aria-label="close modal" component="span">
                          <S.ShareIcon color={applyTheming ? themedColor : 'black'} hasPremiumPlan />
                        </S.ButtonIcon>
                        {FREE_BUTTON_TITLE}{' '}
                      </p>
                    </S.CopyToClipboardBlock>
                  </Tooltip>
                </S.LinkWrapp>
              )
            ) : (
              <>
                {!isInvoiced && inviteClientType !== 'Paid' && (
                  <>
                    <S.LinkWrapp onClick={onPremiumCopyToClipboard} hasPremiumPlan>
                      <Tooltip
                        className="cursor-pointer"
                        title={TOOLTIP_TITLE}
                        open={premiumTooltipOpen}
                        onClose={onClosePremiumTooltip}
                        arrow
                      >
                        <S.CopyToClipboardBlock className="share-btn" hasPremiumPlan>
                          <p style={applyTheming ? { color: themedColor, backgroundColor: themedBackgroundColor } : {}}>
                            <S.ButtonIcon aria-label="close modal" component="span">
                              <S.ShareIcon color={applyTheming ? themedColor : 'black'} hasPremiumPlan />
                            </S.ButtonIcon>
                            {FREE_BUTTON_TITLE}{' '}
                          </p>
                        </S.CopyToClipboardBlock>
                      </Tooltip>
                    </S.LinkWrapp>
                  </>
                )}
              </>
            )}

            {!disablePaidButton() && (
              <div style={{ display: 'flex' }}>
                <S.LinkWrapp onClick={onCopyToClipboard} hasPremiumPlan>
                  <Tooltip
                    className="cursor-pointer"
                    title={TOOLTIP_TITLE}
                    open={tooltipOpen}
                    onClose={onCloseTooltip}
                    arrow
                  >
                    <S.CopyToClipboardBlockDefault
                      className="share-btn"
                      hasPremiumPlan
                      style={{
                        color: disablePaidButton() ? colors.smokedGray : null,
                        minWidth: '120px',
                      }}
                      onClick={() => {
                        if (disablePaidButton() === false) {
                          if (
                            type === ContributionType.contributionCourse ||
                            contributionType === ContributionType.contributionCourse
                          ) {
                            copyContributionLinkToClipboard({ contributionId, code: age });
                          } else {
                            onCopyToClipboard();
                          }
                        }
                      }}
                    >
                      <p
                        style={
                          applyTheming
                            ? {
                                color: themedColor,
                                backgroundColor: themedBackgroundColor,
                                position: 'relative',
                              }
                            : { position: 'relative' }
                        }
                      >
                        <S.ButtonIcon aria-label="close modal" component="span">
                          <S.ShareIcon color={applyTheming ? themedColor : 'black'} />
                        </S.ButtonIcon>
                        {PAID_BUTTON_TITLE}
                      </p>
                    </S.CopyToClipboardBlockDefault>
                  </Tooltip>
                </S.LinkWrapp>

                {(type === ContributionType.contributionCourse ||
                  contributionType === ContributionType.contributionCourse) && (
                  <Formik
                    initialValues={{
                      copyMySettingsFromContribution: age?.name,
                    }}
                  >
                    {paymentInfo?.multiplePriceList?.length >= 2 && (
                      <div>
                        <StyledSelect
                          name="copyMySettingsFromContribution"
                          onChange={e => {
                            setdefaultPrice(true);
                            setAge(e.target.value);
                            setPriceId(e.target.value);
                          }}
                          onOpen={() => {
                            setShowtag(true);
                          }}
                          onClose={() => {
                            setShowtag(false);
                          }}
                          color={applyTheming ? themedColor : ''}
                          defaultValue={dropDownData[0]?.value}
                          items={dropDownData}
                        />
                      </div>
                    )}
                  </Formik>
                )}
              </div>
            )}
          </div>
        </div>
      </S.ColumnFlexContainer>
    </S.ShareModalMain>
  );
};

InviteFriendModalView.propTypes = {
  onCopyToClipboard: PropTypes.func.isRequired,
  onPremiumCopyToClipboard: PropTypes.func.isRequired,
  setTooltipOpen: PropTypes.func.isRequired,
  setPremiumTooltipOpen: PropTypes.func.isRequired,
  mobileView: PropTypes.bool,
  tooltipOpen: PropTypes.bool,
  premiumTooltipOpen: PropTypes.bool,
  applyTheming: PropTypes.bool,
  inviteClientType: PropTypes.string.isRequired,
};

InviteFriendModalView.defaultProps = {
  mobileView: false,
  tooltipOpen: false,
  premiumTooltipOpen: false,
  applyTheming: false,
};

export default InviteFriendModalView;
