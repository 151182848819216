import { createAction } from 'redux-actions';

export const updateMembers = createAction('UPDATE_MEMBERS', members => ({
  members,
}));

export const clearMembers = createAction('CLEAR_MEMBERS');

export const addMember = createAction('ADD_MEMBER', member => ({ member }));

export const updateMember = createAction('UPDATE_MEMBER', member => ({
  member,
}));

export const removeMember = createAction('REMOVE_MEMBER', memberSid => ({
  memberSid,
}));
