import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import IconButton from '@material-ui/core/IconButton'
import AttachFileIcon from '@material-ui/icons/AttachFile'
import CircularProgress from '@material-ui/core/CircularProgress'

import './UploadAttachment.scss'

const ATTACHMENT_INPUT_ID = 'chat-message-attachment-input'

const UploadAttachment = ({ className, isUploadingAttachment, onUploadAttachment }) => (
  <>
    <input
      id={ATTACHMENT_INPUT_ID}
      className="d-none"
      type="file"
      accept="*"
      onChange={(e) => {
        const attachment = e.target.files[0]
        if (!attachment) {
          return
        }

        onUploadAttachment(attachment)
      }}
    />
    <div className={classNames(className, 'chat-message-input-upload-attachment')}>
      <label
        htmlFor={ATTACHMENT_INPUT_ID}
        className="chat-message-input-upload-attachment__upload"
        style={{ margin: 0 }}
      >
        <IconButton color="inherit" component="span" disabled={isUploadingAttachment}>
          <AttachFileIcon />
        </IconButton>
      </label>
      {isUploadingAttachment && (
        <CircularProgress
          className="chat-message-input-upload-attachment__progress"
          style={{ width: 20, height: 20 }}
        />
      )}
    </div>
  </>
)

UploadAttachment.propTypes = {
  className: PropTypes.string.isRequired,
  isUploadingAttachment: PropTypes.bool.isRequired,
  onUploadAttachment: PropTypes.func.isRequired,
}

export default UploadAttachment
