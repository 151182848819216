import React, { useCallback, useEffect, useRef, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Logo from 'components/FormUI/Logo';
import { useLocation, useParams } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import { isEmpty } from 'lodash';

import Loader from 'components/UI/Loader';
import { useAccount, useHttp } from 'hooks/index';
import { useDispatch } from 'react-redux';
import { logOut } from 'actions/user';

const VerifyEmail = ({}) => {
  const { ticks, userId } = useParams();
  const [apiError, setApiError] = useState(null);
  const { request } = useHttp();
  const dispatch = useDispatch();
  const { user } = useAccount();

  useEffect(() => {
    request(`/User/ConfirmNewEmailAddress`, 'PUT', {
      ticks: ticks,
      accountId: userId,
    })
      .then(res => {
        setApiError('Email Updated Successfully');
        setTimeout(() => {
          dispatch(logOut());
        }, 5000);
      })
      .catch(err => {
        setApiError(err.response.data);
      });
  }, []);

  if (isEmpty(user) && apiError === 'Email Updated Successfully') {
    return <Redirect to="/dashboard" />;
  }
  return (
    <Grid container style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
      {apiError ? (
        <div style={{ justifyContent: 'center', alignItems: 'center', marginTop: '50px', display: 'grid' }}>
          <Logo />
          <div>{apiError}</div>
        </div>
      ) : (
        <Loader />
      )}
    </Grid>
  );
};

export default VerifyEmail;
