import { useEffect } from 'react';

export const useDebounce = (func, wait) => {
  let timeout;

  useEffect(() => {
    return () => {
      // Cleanup: clear the timeout on component unmount
      clearTimeout(timeout);
    };
  }, []);

  return function (param) {
    if (timeout) {
      clearTimeout(timeout);
    }

    timeout = setTimeout(() => {
      timeout = null;
      func(param);
    }, wait);
  };
};
