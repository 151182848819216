import React, { useState } from 'react';
import PropTypes from 'prop-types';
import UpcomingContributions from '../UpcomingContributions';
import PastContributions from '../PastContributions';
import { Box, Container, Grid } from '@material-ui/core';
import { ClientJourneyContribution } from 'services/dataContracts/contributions';
import SessionCard from '../../SessionCard/SessionCard';
import styled from 'styled-components';
import { toRem } from 'utils/styles';
import { JourneySection } from 'helpers/constants/sections';
import { isEmpty } from 'ramda';
const Heading = styled.p`
  font-size: ${toRem(22)};
  font-weight: 900;
  line-height: 1.5;
  letter-spacing: 0.12px;
  color: #116582;

  ${({ mobileView }) => mobileView && `font-size: ${toRem(16)};`}
`;

const StyledSessionsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
`;

const checkIfHasContributions = contributions => contributions?.length > 0;

const AllConstributions = ({ bookable, upcoming, inCompleted, modules }) => {
  const [bookableSessions, setbookableSessions] = useState(bookable);
  const [inCompletedSessions, setInCompletedSessions] = useState(inCompleted);
  const [modulesSessions, setModulesSessions] = useState(modules);
  const [upcomingSessions, setUpcomingSessions] = useState(upcoming);
  const deleteIndexBookable = indexData => {
    const data1 = bookableSessions.filter((item, index) => index != indexData);
    setbookableSessions(data1);
  };
  const deleteIndexIncompleted = indexData => {
    const data1 = inCompletedSessions.filter((item, index) => index != indexData);
    setInCompletedSessions(data1);
  };
  const deleteIndexUpcoming = indexData => {
    const data1 = upcomingSessions.filter((item, index) => index != indexData);
    setUpcomingSessions(data1);
  };
  const deleteIndexModules = indexData => {
    const data1 = modulesSessions.filter((item, index) => index != indexData);
    setModulesSessions(data1);
  };
  if (isEmpty(upcoming) && isEmpty(inCompleted) && isEmpty(modules) && isEmpty(bookable)) {
    return <Grid container>You don't have any active sessions yet.</Grid>;
  }
  return (
    // <Grid container direction="column">
    //   <Grid item style={{ marginBottom: '70px' }}>
    //     {upcomingcontributions.length > 0 && <Heading>Upcoming Live</Heading>}
    //     <UpcomingContributions
    //       contributions={upcomingcontributions}
    //       upcomingNotBooked={upcomingNotBooked}
    //       selfpacedupcomingContributions={selfpacedupcomingContributions}
    //       // livepastSessions={livepastSessions}
    //       // selfpacedpastContributions={selfpacedpastContributions}
    //       type={'all'}
    //     />
    //   </Grid>
    //   <Grid item style={{ marginBottom: '20px' }}>
    //     {pastcontributions.length > 0 && <Heading>Past</Heading>}

    //     <PastContributions
    //       contributions={pastcontributions}
    //       livepastSessions={livepastSessions}
    //       selfpacedpastContributions={selfpacedpastContributions}
    //     />
    //   </Grid>
    // </Grid>

    <Grid container direction="column" style={{ flexWrap: 'nowrap' }}>
      {bookableSessions?.length > 0 && (
        <Grid item style={{ marginBottom: '70px' }}>
          <Heading>Book Your Sessions</Heading>
          <StyledSessionsContainer>
            {bookableSessions?.length > 0 ? (
              bookableSessions?.map((s, i) => {
                return (
                  <SessionCard
                    key={i}
                    index={i}
                    section={JourneySection.bookable}
                    sessioninfo={s}
                    removeIndexFunction={() => {
                      deleteIndexBookable(i);
                    }}
                  />
                );
              })
            ) : (
              <Grid container>No bookable sessions available.</Grid>
            )}
          </StyledSessionsContainer>
        </Grid>
      )}
      {inCompletedSessions?.length > 0 && (
        <Grid item style={{ marginBottom: '70px' }}>
          <Heading>Incomplete Sessions</Heading>
          <StyledSessionsContainer>
            {inCompletedSessions?.length > 0 ? (
              inCompletedSessions?.map((s, i) => {
                return (
                  <SessionCard
                    key={i}
                    index={i}
                    section={JourneySection.incompleted}
                    sessioninfo={s}
                    removeIndexFunction={() => {
                      deleteIndexIncompleted(i);
                    }}
                  />
                );
              })
            ) : (
              <Grid container>No incompleted sessions available.</Grid>
            )}
          </StyledSessionsContainer>
        </Grid>
      )}
      <Grid item style={{ marginBottom: '70px' }}>
        <Heading>Upcoming Sessions</Heading>
        <StyledSessionsContainer>
          {upcomingSessions?.length > 0 ? (
            upcomingSessions?.map((s, i) => {
              return (
                <SessionCard
                  key={i}
                  index={i}
                  section={JourneySection.upcoming}
                  sessioninfo={s}
                  removeIndexFunction={() => {
                    deleteIndexUpcoming(i);
                  }}
                />
              );
            })
          ) : (
            <Grid container>No upcoming sessions available.</Grid>
          )}
        </StyledSessionsContainer>
      </Grid>

      <Grid item style={{ marginBottom: '70px' }}>
        <Heading>Upcoming Modules</Heading>
        <StyledSessionsContainer>
          {modulesSessions?.length > 0 ? (
            modulesSessions?.map((s, i) => {
              return (
                <SessionCard
                  key={i}
                  index={i}
                  section={JourneySection.modules}
                  sessioninfo={s}
                  removeIndexFunction={() => {
                    deleteIndexModules(i);
                  }}
                />
              );
            })
          ) : (
            <Grid container>No modules available.</Grid>
          )}
        </StyledSessionsContainer>
      </Grid>
    </Grid>
  );
};

const contributionsPropType = PropTypes.arrayOf(PropTypes.instanceOf(ClientJourneyContribution)).isRequired;

AllConstributions.propTypes = {
  upcomingcontributions: PropTypes.shape({
    otherIncompleted: contributionsPropType,
    today: contributionsPropType,
    thisWeek: contributionsPropType,
    thisMonth: contributionsPropType,
    nextMonth: contributionsPropType,
    thisYear: contributionsPropType,
    afterThisYear: contributionsPropType,
    notBooked: contributionsPropType,
  }).isRequired,
  pastcontributions: contributionsPropType,
};

export default AllConstributions;
