import styled, { css } from 'styled-components';
import { Icon } from '@mdi/react';

const StyledIcon = styled(Icon).attrs(props => ({
  size: props.size || 1,
}))`
  margin-right: ${({ right }) => (right && `${right}px`) || 0};
  margin-left: ${({ left }) => (left && `${left}px`) || 0};
  margin-top: ${({ top }) => (top && `${top}px`) || 0};

  ${({ margin }) =>
    margin &&
    css`
      margin: ${margin};
    `}

  ${({ valign }) =>
    valign &&
    css`
      vertical-align: ${valign};
    `}

  ${({ onClick }) =>
    onClick &&
    css`
      cursor: pointer;
    `}
`;

export default StyledIcon;
