import React, { useEffect, useState } from 'react';
import classes from './DomainAuthentication.module.scss';
import Header from 'components/UI/Header';
import styled from 'styled-components';
import Button from 'components/FormUI/Button';
import Step from './Components/Step/Step';
import IdeaIcon from 'assets/Idea.png';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import * as paidTierActions from 'actions/paidTierOptions';
import * as paidTierService from 'services/paidTierOptions.service';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CustomTable from './Components/Table/Table';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setAccount, setLastSender } from 'actions/emailMarketing.actions';
import { Tooltip } from '@material-ui/core';
import { EmailMarketingIntegrationService } from 'integrations/EmailMarketing/service/marketingAccountIntegration.service';
import copyToClipboard from 'utils/copyToClipboard';
import Loader from 'components/UI/Loader';
import { PAID_TIER_TITLES } from '../../../constants';
import * as paidTier from 'selectors/paidTier';
import useShallowEqualSelector from 'hooks/useShallowEqualSelector';
const StyledMainContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #fafafa;
`;

const StyledImg = styled.div`
  background-image: url(${({ previewImg }) => previewImg});
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  width: 40px;
  height: 40px;
  display: inline-block;
  align-items: center;
  margin-left: 20px;
`;
const StyledMainSection = styled.div`
  padding: ${({ mobileView }) => (mobileView ? '30px 16px' : '30px 35px')};
`;
const TestDataForRedux = {
  senderIdentities: [],
  sendGridUserId: '42800595',
  apiKey: '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',
  email: 'email@speed.com',
  userId: '65eab8126e5cc2311ce7c775',
  domainData: [
    {
      id: '20759010',
      email: 'email@speed.com',
      subDomain: 'em290',
      domain: 'speed.com',
      customSpf: true,
      default: true,
      automaticSecurity: false,
      ips: ['167.89.80.57'],
      dnsObjects: {
        mailServer: {
          host: 'em290.speed.com',
          value: 'mx.sendgrid.net.',
          valid: false,
          type: 'mx',
        },
        spf: {
          host: 'em290.speed.com',
          value: 'v=spf1 ip4:167.89.80.57 -all',
          valid: false,
          type: 'txt',
        },
        dkim: {
          host: 'm1._domainkey.speed.com',
          value:
            'k=rsa; t=s; p=MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDggtyRBAr2SO7wHJv5MPVJPD11dQjqFvFkcHq+6c72CZM2nzBZkba9Vq76PlkIzt/Mv+eI1Lh6tp1JjtHxXlZQA38Gsb4Butj50GeGr0zuZPO4MRHSo2lL/ie/EwtqvG0mlqlNTqz339282xtlGocd99Z6EvtLRqXHy0ksAL5yRQIDAQAB',
          valid: false,
          type: 'txt',
        },
      },
      isVerified: false,
    },
  ],
  isVerified: false,
  id: '65eab8636e5cc2311ce7c787',
  createTime: '2024-03-08T07:04:03.2872848Z',
  updateTime: '2024-03-08T07:04:03.2872848Z',
};

const DomainAuthentication = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [recordAdded, setRecordAdded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [triedToVerify, setTriedToVerify] = useState(false);
  const [showDomainErrors, setShowDomainErrors] = useState(false);
  const [isToolTipOpen, setIsToolTipOpen] = useState(false);
  const allDomains = useSelector(state => state?.emailMarketing?.domains);
  const domainInAuthProcess = useSelector(state => state?.emailMarketing?.domainInAuthProcess);
  const domainData = allDomains?.find(domain => domain.domain === domainInAuthProcess);
  const dnsObjects = domainData?.dnsObjects || {};
  const activePaidTierCurrentPlan = useShallowEqualSelector(paidTier.activePaidTierCurrentPlanSelector);
  const [showDetail, setShowDetail] = useState(false);
  const isScalePlan = activePaidTierCurrentPlan?.displayName === PAID_TIER_TITLES.scale;
  const dnsArray = Object.values(dnsObjects);
  useEffect(() => {
    paidTierService.getCurrentPlan().then(data => {
      dispatch(paidTierActions.setActivePaidTierPlan(data));
    });
  }, []);

  const clickSupportHandler = () => {
    window.location = 'mailto:support@cohere.live';
  };
  const clickSetupCallHandler = () => {
    window.open('https://calendly.com/hasiacohere/30min', '_blank');
  };
  const clickVerifyHandler = () => {
    setIsLoading(true);
    setShowDomainErrors(false);
    EmailMarketingIntegrationService.verifyDomainStatus()
      .then(res => {
        let status = res.domainData.filter(res => res.domain === domainInAuthProcess);
        dispatch(setAccount(res));
        // const error = dnsArray?.some(obj => obj.valid === false);
        if (status[0]?.isVerified) {
          dispatch(setLastSender(domainData));
          history.push('/account/integrations', {
            from: 'domainverification',
            data: { domain: domainData?.domain, email: domainData?.email },
          });
        } else {
          setRecordAdded(false);
          setShowDomainErrors(true);
        }
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const clickCopyHandler = () => {
    let formattedText = '';
    for (var key in dnsObjects) {
      formattedText += `type=${dnsObjects[key]['type']},host=${dnsObjects[key]['host']},value=${dnsObjects[key]['value']}\n`;
    }
    // copyToClipboard(formattedText);
    navigator.clipboard
      .writeText(formattedText)
      .then(() => {
        console.log('copiedtext->>', formattedText);
      })
      .catch(function (error) {
        console.error('Error copying text to clipboard: ', error);
        alert('Error copying text to clipboard. Please try again.');
      });
    setIsToolTipOpen(true);
    setTimeout(() => {
      setIsToolTipOpen(false);
    }, 1000);
  };

  return (
    <StyledMainContainer>
      {isLoading && <Loader />}
      <Header backButtonText="" isCustomPrevButton />
      <StyledMainSection>
        <div className={classes.main_container}>
          <div className={classes.main_heading}>Authenticate My Domain</div>
          <div className={classes.section_action}>
            <div className={classes.description}>
              You will need to install the following records to complete the authentication.
            </div>
            <div className={classes.actions}>
              <div className={classes.need_help_text}>Need help?</div>
              <Button
                onClick={clickSupportHandler}
                className={classes.support_btn}
                invert
                textColor="#c9b382"
                autoWidth
              >
                Contact Support
              </Button>
              {isScalePlan && (
                <Button onClick={clickSetupCallHandler} className={classes.call_btn} textColor="#fff" autoWidth>
                  Book Setup Call
                </Button>
              )}
            </div>
          </div>
          <div className={classes.instruction_heading}>Instructions</div>
          <Step stepNumber={1} description="Add all of these records to domain provider’s DNS section." />
          <div className={classes.table_container}>
            <CustomTable data={dnsArray} showError={showDomainErrors} />
            <Tooltip
              // className="cursor-pointer"
              title="Copied!"
              open={isToolTipOpen}
              onClose={() => {
                setIsToolTipOpen(false);
              }}
              arrow
            >
              <div onClick={clickCopyHandler} className={classes.copy_btn}>
                Copy All To Clipboard
              </div>
            </Tooltip>

            <div className={classes.troubleShootContainer}>
              <div
                onClick={() => {
                  setShowDetail(!showDetail);
                }}
                className={classes.title}
              >
                Troubleshooting Tip for Hostname Issues{' '}
                <StyledImg
                  onClick={() => {
                    setShowDetail(!showDetail);
                  }}
                  previewImg={IdeaIcon}
                />
                <KeyboardArrowDownIcon
                  style={{ right: '100px', position: 'absolute', transform: showDetail ? 'rotate(180deg)' : '' }}
                />
              </div>

              {showDetail && (
                <div className={classes.description}>
                  If you're having trouble with the hostname, "abcd.domainname.com" try the following:
                  <ol>
                    <li style={{ position: 'relative', left: '-20px' }}>
                      Remove "domainname.com" from the hostname:
                      <ul style={{ position: 'relative', left: '-20px' }}>
                        <li>
                          Sometimes, domain providers automatically add "domainname.com" to the hostname you enter.
                        </li>
                        <li>This can cause a duplicate hostname, like "abc.domainname.com.domainname.com"</li>
                      </ul>
                    </li>
                  </ol>
                  By removing "domainname.com" from the hostname you enter, you can avoid this issue and ensure your
                  hostname works correctly.
                  <br />
                  For further support, feel free to look at the help center on your current domain provider.
                </div>
              )}
            </div>
          </div>

          <div className={classes.divider} />
          <Step stepNumber={2} description={`After adding, click verify and continue.`} />
          <div className={classes.bottom}>
            <FormControlLabel
              control={
                <Checkbox
                  classes={{
                    root: classes.checkbox_root,
                    checked: classes.checked,
                  }}
                  checked={recordAdded}
                  onChange={() => {
                    setRecordAdded(!recordAdded);
                  }}
                />
              }
              classes={{
                label: classes.label,
              }}
              label="I added the records"
            />
            <Button
              onClick={clickVerifyHandler}
              className={classes.verify_btn}
              textColor="#fff"
              autoWidth
              disabled={!recordAdded}
            >
              Verify & Continue
            </Button>
          </div>
        </div>
      </StyledMainSection>
    </StyledMainContainer>
  );
};

export default DomainAuthentication;
