import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as leadMagnetActions from 'actions/leadMagnets';
import { setPostsAsRead } from 'services/user.service';
import useRouter from './useRouter';
import { LeadMagnetStatus } from 'helpers/constants';

// const LEAD_MAGNET_STATUSES = {
//   DRAFT: 'Draft',
// };

const useSaveLeadMagnet = (
  canSave,
  hasAgreedLeadMagnetTerms,
  // isThankyouPageActive,
  // thankyouPage,
) => {
  const dispatch = useDispatch();
  const activeLeadMagnet = useSelector(state => state?.leadMagnets?.activeLeadMagnet);
  const user = useSelector(state => state?.account?.user);
  const { query } = useRouter();
  const [saving, setSaving] = useState(false);
  const onSave = useCallback(
    (leadMagnet, redirect) => {
      if (!canSave) {
        return;
      }
      const currentLeadMagnet = leadMagnet || activeLeadMagnet;
      // let tempCustomWaiverId = leadMagnet?.customWaiverId
      //   ? leadMagnet?.customWaiverId === 'None'
      //     ? null
      //     : leadMagnet?.customWaiverId
      //   : customWaiverId
      //   ? customWaiverId === 'None'
      //     ? null
      //     : customWaiverId
      //   : null;
      setSaving(true);
      let data;
      // if (query.id) {
      //   if (
      //     (leadMagnet?.paymentInfo?.paymentOptions?.length === 1 &&
      //       activeLeadMagnet?.paymentInfo?.paymentOptions?.length === 1 &&
      //       activeLeadMagnet?.paymentInfo?.paymentOptions?.includes('Free')) ||
      //     (leadMagnet?.paymentInfo?.paymentOptions?.length === 1 &&
      //       activeLeadMagnet?.paymentInfo?.paymentOptions?.length === 1 &&
      //       activeLeadMagnet?.paymentInfo?.paymentOptions?.includes('FreeSessionsPackage')) ||
      //     (leadMagnet?.paymentInfo?.paymentOptions?.length === 2 &&
      //       activeLeadMagnet?.paymentInfo?.paymentOptions?.length === 2 &&
      //       activeLeadMagnet?.paymentInfo?.paymentOptions?.includes('Free') &&
      //       activeLeadMagnet?.paymentInfo?.paymentOptions?.includes('FreeSessionsPackage'))
      //   ) {
      //     data = {
      //       ...(leadMagnet || activeLeadMagnet),
      //       userId: user.id,
      //       customToS: customToS ? customToS.fileUrl : (leadMagnet || activeLeadMagnet).customToS,
      //       customWaiverId: isAttachmentOrWaiver != 'attachments' ? tempCustomWaiverId : null,
      //       customWaiverTemplateName:
      //         isAttachmentOrWaiver != 'attachments' ? (leadMagnet || activeLeadMagnet).customWaiverTemplateName : null,
      //       paymentInfo: {
      //         ...activeLeadMagnet.paymentInfo,
      //         cost: null,
      //       },
      //     };
      //   } else {
      //     data = {
      //       ...(leadMagnet || activeLeadMagnet),
      //       userId: user.id,
      //       customToS: customToS ? customToS.fileUrl : (leadMagnet || activeLeadMagnet).customToS,
      //       customWaiverId: isAttachmentOrWaiver != 'attachments' ? tempCustomWaiverId : null,
      //       customWaiverTemplateName:
      //         isAttachmentOrWaiver != 'attachments' ? (leadMagnet || activeLeadMagnet).customWaiverTemplateName : null,
      //       isThankyouPageAdded: isThankyouPageActive || leadMagnet?.isThankyouPageAdded,
      //       thankyouPage: thankyouPage || leadMagnet?.thankyouPage,
      //     };
      //   }
      // } else {
      //   data = {
      //     ...(leadMagnet || activeLeadMagnet),
      //     userId: user.id,
      //     customToS: customToS ? customToS.fileUrl : (leadMagnet || activeLeadMagnet).customToS,
      //     customWaiverId: isAttachmentOrWaiver != 'attachments' ? tempCustomWaiverId : null,
      //     customWaiverTemplateName:
      //       isAttachmentOrWaiver != 'attachments' ? (leadMagnet || activeLeadMagnet).customWaiverTemplateName : null,
      //     isThankyouPageAdded: isThankyouPageActive || leadMagnet?.isThankyouPageAdded,
      //     thankyouPage: thankyouPage || leadMagnet?.thankyouPage,
      //   };
      // }
      data = {
        ...currentLeadMagnet,
        userId: user.id,
      };
      const { id: leadMagnetId } = currentLeadMagnet;
      data.hasAgreedContributionTerms =
        hasAgreedLeadMagnetTerms === undefined && data.status !== LeadMagnetStatus.unfinished
          ? true
          : hasAgreedLeadMagnetTerms;

      // data.isElectronicSignatureActive =
      //   isElectronicSignatureActive === undefined && data.status !== LEAD_MAGNET_STATUSES.DRAFT
      //     ? activeLeadMagnet?.isElectronicSignatureActive
      //     : isElectronicSignatureActive;
      // data.isThankyouPageAdded =
      //   isThankyouPageActive === undefined && data.status !== LEAD_MAGNET_STATUSES.DRAFT
      //     ? activeLeadMagnet?.isThankyouPageAdded
      //     : isThankyouPageActive || leadMagnet?.isThankyouPageAdded;
      // data.thankyouPage =
      //   thankyouPage === undefined && data.status !== LEAD_MAGNET_STATUSES.DRAFT
      //     ? activeLeadMagnet?.thankyouPage
      //     : thankyouPage || leadMagnet?.thankyouPage;

      const redirectByAction = action => {
        if (redirect) {
          redirect(action, data);
        }
        setSaving(false);
      };

      if (query.id) {
        // delete data.timeZoneId;

        dispatch(leadMagnetActions.updateLeadMagnet(query.id, data)).then(redirectByAction);
      } else {
        dispatch(leadMagnetActions.createLeadMagnet(data))
          .then(redirectByAction)
          .then(() => setPostsAsRead({ leadMagnetId, userId: user.id }));
      }
    },
    [dispatch, activeLeadMagnet, query, setSaving, user.id, hasAgreedLeadMagnetTerms, canSave],
  );

  return { onSave, saving };
};

export default useSaveLeadMagnet;
