import React from 'react';
import classes from './DefaultRow.module.scss';
import styled from 'styled-components';
import { FormControlLabel, Checkbox } from '@material-ui/core';

const StyledHeader = styled.div`
  padding: 20px 20px;
  border: 1px solid #dfe3e4;
  border-radius: 4px 4px 0px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  font-family: Avenir;
  font-size: 18px;
  font-weight: 800;
  line-height: 16px;
  color: black;
`;

const NotVerifiedEmail = styled.div`
  font-family: Avenir;
  font-size: 11px;
  font-weight: 800;
  line-height: 16px;
  color: #215c73;
  background-color: white;
  border-radius: 4px;
  border: 2px solid #215c73;
  padding: 2px 6px;
`;

const VerifiedEmail = styled.div`
  font-family: Avenir;
  font-size: 11px;
  font-weight: 800;
  line-height: 16px;
  color: #ffffff;
  background-color: #215c73;
  border-radius: 4px;
  padding: 2px 6px;
`;

const StyledBody = styled.div`
  padding: 20px 20px;
  border: 1px solid #dfe3e4;
  border-radius: 0px 0px 4px 4px;
`;

const StyledLabel = styled.div`
  font-family: 'Avenir';
  font-weight: 350;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.87);
`;

const StyledOption = styled.div`
  font-family: 'Avenir';
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #215c73;
  cursor: pointer;
`;

const DefaultRow = ({ sender, onCheckedChange, showLessOption, showLessClick }) => {
  return (
    <div>
      <StyledHeader>
        <div className={classes.email_details}>
          {!sender?.isVerified && <NotVerifiedEmail>Non-Verified</NotVerifiedEmail>}
          <div>{sender?.email}</div>
          {sender?.isVerified && <VerifiedEmail>Verified</VerifiedEmail>}
        </div>
        <div>{showLessOption && <StyledOption onClick={showLessClick}>Show Less</StyledOption>}</div>
      </StyledHeader>
      <StyledBody>
        <FormControlLabel
          style={{ marginRight: '5px' }}
          checked={sender?.isDefault}
          onChange={onCheckedChange}
          classes={{
            root: classes.MuiFormControlLabel_root,
          }}
          control={
            <Checkbox
              classes={{
                root: classes.checkbox_root,
              }}
              color="primary"
            />
          }
          label={<StyledLabel>Make this my default sending address</StyledLabel>}
          name="default"
          // disabled={sender?.isDefault}
        />
      </StyledBody>
    </div>
  );
};

export default DefaultRow;
