import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { LocalParticipant, RemoteParticipant } from './Participant'

import './ParticipantsPanel.scss'

const ParticipantsPanel = ({
  className,
  localParticipant,
  participants,
  dominantSpeakerParticipant,
  isTest,
  audioTrack,
  videoTrack,
  screenTrack,
  selectedAudioOutputDevice,
}) => {
  const totalParticipantsCount = participants.length + 1
  const dominantSpeakerParticipantIdentity = dominantSpeakerParticipant ? dominantSpeakerParticipant.identity : null

  return (
    <div className={classNames(className, 'video-chat-participants-panel')}>
      <p className="video-chat-participants-panel__title">Participants ({totalParticipantsCount})</p>
      <div className="video-chat-participants-panel-participants">
        <LocalParticipant
          isTest={isTest}
          className="video-chat-participants-panel-participants__item"
          participant={localParticipant}
          dominant={localParticipant.identity === dominantSpeakerParticipantIdentity}
          videoTrack={videoTrack}
          audioTrack={audioTrack}
          screenTrack={screenTrack}
          selectedAudioOutputDevice={selectedAudioOutputDevice}
        />
        {participants.map((p) => (
          <RemoteParticipant
            key={p.identity}
            className="video-chat-participants-panel-participants__item"
            participant={p}
            dominant={p.identity === dominantSpeakerParticipantIdentity}
            selectedAudioOutputDevice={selectedAudioOutputDevice}
          />
        ))}
      </div>
    </div>
  )
}

const participantPropType = PropTypes.shape({
  identity: PropTypes.string,
})

ParticipantsPanel.propTypes = {
  className: PropTypes.string,
  localParticipant: participantPropType.isRequired,
  participants: PropTypes.arrayOf(participantPropType).isRequired,
  dominantSpeakerParticipant: participantPropType,
  screenTrack: PropTypes.shape({
    localScreenTrack: PropTypes.shape({
      attach: PropTypes.func,
      detach: PropTypes.func,
    }),
    isLocalScreenEnabled: PropTypes.bool,
    setIsLocalScreenEnabled: PropTypes.func,
  }).isRequired,
  videoTrack: PropTypes.shape({
    isLocalVideoEnabled: PropTypes.bool,
    setIsLocalVideoEnabled: PropTypes.func,
    videoDeviceLabel: PropTypes.string,
    switchVideoDevice: PropTypes.func,
  }).isRequired,
  audioTrack: PropTypes.shape({
    isLocalAudioEnabled: PropTypes.bool,
    setIsLocalAudioEnabled: PropTypes.func,
    audioDeviceLabel: PropTypes.string,
    switchAudioDevice: PropTypes.func,
  }).isRequired,
  isTest: PropTypes.bool,
}

ParticipantsPanel.defaultProps = {
  className: '',
  dominantSpeakerParticipant: null,
  isTest: false,
}

export default ParticipantsPanel
