import {
  ACCEPTED_REJECTED_APPLICATION_FORM,
  DELETE_APPLICATION_FORM_REQUEST,
  DELETE_APPLICATION_FORM_SUCCESS,
  FETCH_APPLICATIONS_ERROR,
  FETCH_APPLICATIONS_REQUEST,
  FETCH_APPLICATIONS_SUCCESS,
  FETCH_APPLICATION_FORMS_REQUEST,
  FETCH_APPLICATION_FORMS_SUCCESS,
  RESET_APPLICATIONS,
} from 'actions/applications';
import { applicationStatus } from 'components/Tables/useSharedApplicationForm';

const initialState = {
  error: null,
  isLoading: false,
  applications: [],
  forms: [],
};
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_APPLICATIONS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case RESET_APPLICATIONS:
      return {
        ...state,
        error: null,
        applications: [],
      };
    case FETCH_APPLICATIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        applications: [...state.applications, ...payload.applications],
      };
    case FETCH_APPLICATIONS_ERROR:
      return {
        ...state,
        isLoading: false,
        error: payload.error,
      };
    case ACCEPTED_REJECTED_APPLICATION_FORM:
      return {
        ...state,
        isLoading: false,
        error: null,
        applications: state.applications.map(application => {
          const { formResponseId, isAccepted } = payload;
          return {
            ...application,
            status:
              formResponseId === application?.id
                ? isAccepted
                  ? applicationStatus.approved
                  : applicationStatus.rejected
                : application.status,
          };
        }),
      };
    case FETCH_APPLICATION_FORMS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_APPLICATION_FORMS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        forms: payload.forms,
      };

    case DELETE_APPLICATION_FORM_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case DELETE_APPLICATION_FORM_SUCCESS: {
      const formId = payload;

      return {
        ...state,
        isLoading: false,
        error: null,
        forms: state.forms.filter(k => k.id !== formId),
      };
    }
    default:
      return state;
  }
};
