import { createAction } from 'redux-actions';
import { get, pipe } from 'lodash/fp';

import * as communitySectionService from 'services/communitySections.service';

export const FETCH_COMMUNITY_SECTIONS_REQUEST = 'FETCH_COMMUNITY_SECTIONS_REQUEST';
export const UPDATE_SECTIONS_META_REQUEST = 'UPDATE_SECTIONS_META_REQUEST';
export const FETCH_COMMUNITY_SECTIONS_SUCCESS = 'FETCH_COMMUNITY_SECTIONS_SUCCESS';
export const FETCH_COMMUNITY_SECTIONS_ERROR = 'FETCH_COMMUNITY_SECTIONS_ERROR';

export const CREATE_OR_EDIT_COMMUNITY_SECTION_REQUEST = 'CREATE_OR_EDIT_COMMUNITY_SECTION_REQUEST';
export const CREATE_OR_EDIT_COMMUNITY_SECTION_SUCCESS = 'CREATE_OR_EDIT_COMMUNITY_SECTION_SUCCESS';
export const CREATE_OR_EDIT_COMMUNITY_SECTION_ERROR = 'CREATE_OR_EDIT_COMMUNITY_SECTION_ERROR';

export const UPDATE_SECTION_ORDER = 'UPDATE_SECTION_ORDER';
export const UPDATE_GROUP_ORDER = 'UPDATE_GROUP_ORDER';
export const MOVE_GROUP_FROM_SECTION_TO_SECTION = 'MOVE_GROUP_FROM_SECTION_TO_SECTION';

export const DELETE_COMMUNITY_SECTION_REQUEST = 'DELETE_COMMUNITY_SECTION_REQUEST';
export const DELETE_COMMUNITY_SECTION_ERROR = 'DELETE_COMMUNITY_SECTION_ERROR';
export const DELETE_COMMUNITY_SECTION_SUCCESS = 'DELETE_COMMUNITY_SECTION_SUCCESS';

export const CREATE_OR_EDIT_COMMUNITY_GROUP_REQUEST = 'CREATE_OR_EDIT_COMMUNITY_GROUP_REQUEST';
export const CREATE_OR_EDIT_COMMUNITY_GROUP_SUCCESS = 'CREATE_OR_EDIT_COMMUNITY_GROUP_SUCCESS';
export const CREATE_OR_EDIT_COMMUNITY_GROUP_ERROR = 'CREATE_OR_EDIT_COMMUNITY_GROUP_ERROR';

export const FETCH_COMMUNITY_SUGGESTED_GROUPS_REQUEST = 'FETCH_COMMUNITY_SUGGESTED_GROUPS_REQUEST';
export const FETCH_COMMUNITY_SUGGESTED_GROUPS_ERROR = 'FETCH_COMMUNITY_SUGGESTED_GROUPS_ERROR';
export const FETCH_COMMUNITY_SUGGESTED_GROUPS_SUCCESS = 'FETCH_COMMUNITY_SUGGESTED_GROUPS_SUCCESS';

export const UPDATE_SECTION_GROUPS_LIMIT = 'UPDATE_SECTION_GROUPS_LIMIT';

export const UPDATE_CURRENT_GROUP = 'UPDATE_CURRENT_GROUP';
export const UPDATE_GROUP_UNREAD_COUNT = 'UPDATE_GROUP_UNREAD_COUNT';
export const MUTE_OR_UNMUTE_GROUP_NOTIFICATIONS = 'MUTE_OR_UNMUTE_GROUP_NOTIFICATIONS';

export const SET_CONTRIBUTION_ID = 'SET_CONTRIBUTION_ID';

export const fetchCommunitySectionActions = {
  request: createAction(FETCH_COMMUNITY_SECTIONS_REQUEST),
  updateMeta: createAction(UPDATE_SECTIONS_META_REQUEST),
  success: createAction(FETCH_COMMUNITY_SECTIONS_SUCCESS),
  error: createAction(FETCH_COMMUNITY_SECTIONS_ERROR),
};

export const createCommunitySectionActions = {
  request: createAction(CREATE_OR_EDIT_COMMUNITY_SECTION_REQUEST),
  success: createAction(CREATE_OR_EDIT_COMMUNITY_SECTION_SUCCESS),
  error: createAction(CREATE_OR_EDIT_COMMUNITY_SECTION_ERROR),
};

export const updateCommunitySectionOrderActions = {
  update: createAction(UPDATE_SECTION_ORDER),
};

export const updateCommunityGroupsOrderActions = {
  update: createAction(UPDATE_GROUP_ORDER),
};

export const moveGroupFromOneSectionToOtherAction = {
  update: createAction(MOVE_GROUP_FROM_SECTION_TO_SECTION),
};

export const deleteCommunitySectionActions = {
  request: createAction(DELETE_COMMUNITY_SECTION_REQUEST),
  success: createAction(DELETE_COMMUNITY_SECTION_SUCCESS),
  error: createAction(DELETE_COMMUNITY_SECTION_ERROR),
};

export const createCommunityGroupActions = {
  request: createAction(CREATE_OR_EDIT_COMMUNITY_GROUP_REQUEST),
  success: createAction(CREATE_OR_EDIT_COMMUNITY_GROUP_SUCCESS),
  error: createAction(CREATE_OR_EDIT_COMMUNITY_GROUP_ERROR),
};

export const fetchcommunitySuggestedGroups = {
  request: createAction(FETCH_COMMUNITY_SUGGESTED_GROUPS_REQUEST),
  success: createAction(FETCH_COMMUNITY_SUGGESTED_GROUPS_SUCCESS),
  error: createAction(FETCH_COMMUNITY_SUGGESTED_GROUPS_ERROR),
};

export const updateSectionGroupsLimit = {
  update: createAction(UPDATE_SECTION_GROUPS_LIMIT),
};

export const updateCurrentGroup = {
  update: createAction(UPDATE_CURRENT_GROUP),
};

export const updateCurrentGroupData = data => async dispatch => {
  dispatch(updateCurrentGroup.update(data));
};

export const updateGroupUnreadCount = {
  update: createAction(UPDATE_GROUP_UNREAD_COUNT),
};

export const muteOrUnmuteGroupNotificationsActions = {
  update: createAction(MUTE_OR_UNMUTE_GROUP_NOTIFICATIONS),
};

export const setContributionIdActions = {
  update: createAction(SET_CONTRIBUTION_ID),
};

export const fetchCommunitySections = contributionId => async dispatch => {
  dispatch(fetchCommunitySectionActions.request());
  try {
    const response = await communitySectionService.getCommunitySection(contributionId);

    dispatch(
      fetchCommunitySectionActions.success({
        subSections: response.subCommunitiesList,
        anonymousGroups: response.anonymousGroups,
        archivedGroups: response.archivedGroups,
        mainGroup: response.mainGroup,
        postCount: response.postCount,
      }),
    );
  } catch (error) {
    pipe(get('response'), get('data'), fetchCommunitySectionActions.error, dispatch)(error);
  }
};

export const updateSectionsMetaOnly = contributionId => async dispatch => {
  dispatch(fetchCommunitySectionActions.updateMeta());
  try {
    const response = await communitySectionService.getCommunitySection(contributionId);

    dispatch(
      fetchCommunitySectionActions.success({
        subSections: response.subCommunitiesList,
        anonymousGroups: response.anonymousGroups,
        archivedGroups: response.archivedGroups,
        mainGroup: response.mainGroup,
        postCount: response.postCount,
      }),
    );
  } catch (error) {
    pipe(get('response'), get('data'), fetchCommunitySectionActions.error, dispatch)(error);
  }
};

export const createOrEditCommunitySection = ({ contributionId, sectionId, title, orderNo = 0 }) => async dispatch => {
  dispatch(createCommunitySectionActions.request());
  try {
    const response = await communitySectionService.createOrEditCommunitySection({
      contributionId,
      id: sectionId,
      title,
      orderNo,
    });

    dispatch(
      createCommunitySectionActions.success({
        section: { ...response, sectionTitle: response.title, sectionId: response.id },
      }),
    );
  } catch (error) {
    pipe(get('response'), get('data'), createCommunitySectionActions.error, dispatch)(error);
  }
};

export const deleteCommunitySection = data => async dispatch => {
  dispatch(deleteCommunitySectionActions.request());
  try {
    const response = await communitySectionService.deleteCommunitySection(data);

    dispatch(
      deleteCommunitySectionActions.success({
        subSections: response.subCommunitiesList,
        anonymousGroups: response.anonymousGroups,
        archivedGroups: response.archivedGroups,
        mainGroup: response.mainGroup,
        postCount: response.postCount,
      }),
    );
  } catch (error) {
    pipe(get('response'), get('data'), deleteCommunitySectionActions.error, dispatch)(error);
  }
};

export const createOrEditCommunityGroup = data => async dispatch => {
  dispatch(createCommunityGroupActions.request());
  try {
    const response = await communitySectionService.createCommunityGroup(data);

    dispatch(
      createCommunityGroupActions.success({
        sectionId: response.sectionId,
        groups: response.groups,
      }),
    );
  } catch (error) {
    pipe(get('response'), get('data'), createCommunityGroupActions.error, dispatch)(error);
  }
};

export const fetchCommunitySuggestedGroups = () => async dispatch => {
  dispatch(fetchcommunitySuggestedGroups.request());
  try {
    const response = await communitySectionService.getSuggestedCommunityGroups();

    dispatch(
      fetchcommunitySuggestedGroups.success({
        suggestedGroups: response,
      }),
    );
  } catch (error) {
    pipe(get('response'), get('data'), fetchcommunitySuggestedGroups.error, dispatch)(error);
  }
};

export const muteOrUnmuteGroupNotifications = (groupId, isMuted) => async dispatch => {
  try {
    const response = await communitySectionService.muteOrUnmuteGroupNotifications(groupId, isMuted);
    dispatch(
      muteOrUnmuteGroupNotificationsActions.update({
        group: response,
      }),
    );
  } catch (error) {}
};
