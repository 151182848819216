import { updateMembers, clearMembers, addMember, updateMember, removeMember } from './actions';

const initialMembers = {};

const membersReducer = (state, { type, payload }) => {
  switch (type) {
    case updateMembers.toString(): {
      const { members } = payload;

      return members.reduce((newState, m) => ({ ...newState, [m.sid]: m }), state);
    }
    case clearMembers.toString():
      return { ...initialMembers };
    case addMember.toString():
    case updateMember.toString(): {
      const { member } = payload;

      return { ...state, [member.sid]: member };
    }
    case removeMember.toString(): {
      const { memberSid } = payload;

      const newState = { ...state };
      delete newState[memberSid];

      return newState;
    }
    default:
      return state;
  }
};

export default membersReducer;
export { initialMembers };
