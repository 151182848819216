import React, { useEffect, useMemo, useRef } from 'react'
import PropTypes from 'prop-types'

import { LocalParticipant, RemoteParticipant } from '../Participant'
import GalleryViewParticipant from './GalleryViewParticipant'
import { getGalleryParticipantItemWidth } from './utils'

const participantsGap = 10

const GalleryView = ({ localParticipant, participants, dominantSpeakerParticipant }) => {
  const galleryViewRef = useRef()
  const participantsContainerRef = useRef()

  const participantsCountRef = useRef(participants.length)
  useEffect(() => {
    participantsCountRef.current = participants.length
  }, [participants])

  const galleryViewResizeObserver = useMemo(
    () =>
      new ResizeObserver(([target]) => {
        const { width, height } = target.contentRect
        const totalParticipantsCount = 1 + participantsCountRef.current
        const participantWidth = getGalleryParticipantItemWidth(width, height, participantsGap, totalParticipantsCount)

        participantsContainerRef.current.style.gridTemplateColumns = `repeat(auto-fit, minmax(${participantWidth}px, 1fr))`
      }),
    []
  )
  useEffect(() => {
    const galleryNode = galleryViewRef.current

    galleryViewResizeObserver.observe(galleryNode)

    return () => galleryViewResizeObserver.unobserve(galleryNode)
  }, [participants, galleryViewResizeObserver])

  const dominantSpeakerParticipantIdentity = dominantSpeakerParticipant ? dominantSpeakerParticipant.identity : null

  return (
    <div ref={galleryViewRef} className="video-chat-gallery-view">
      <div
        ref={participantsContainerRef}
        className="video-chat-gallery-view-participants"
        style={{ gridGap: `${participantsGap}px` }}
      >
        <GalleryViewParticipant
          participantComponent={LocalParticipant}
          participant={localParticipant}
          dominant={localParticipant.identity === dominantSpeakerParticipantIdentity}
        />
        {participants.map((p) => (
          <GalleryViewParticipant
            key={p.identity}
            participantComponent={RemoteParticipant}
            participant={p}
            dominant={p.identity === dominantSpeakerParticipantIdentity}
          />
        ))}
      </div>
    </div>
  )
}

const participantPropType = PropTypes.shape({
  identity: PropTypes.string,
})

GalleryView.propTypes = {
  localParticipant: participantPropType.isRequired,
  participants: PropTypes.arrayOf(participantPropType).isRequired,
  dominantSpeakerParticipant: participantPropType,
}

GalleryView.defaultProps = {
  dominantSpeakerParticipant: null,
}

export default GalleryView
