import { createAction } from 'redux-actions';
import { get, pipe } from 'lodash/fp';

import { getCohealerIncome } from 'services/cohealerIncome.service';

export const COHEALER_INCOME_REQUEST = 'COHEALER_INCOME_REQUEST';
export const COHEALER_INCOME_SUCCESS = 'COHEALER_INCOME_SUCCESS';
export const COHEALER_INCOME_ERROR = 'COHEALER_INCOME_ERROR';

export const fetchCohealerIncomeActions = {
  request: createAction(COHEALER_INCOME_REQUEST),
  success: createAction(COHEALER_INCOME_SUCCESS),
  error: createAction(COHEALER_INCOME_ERROR),
};

export const fetchCohealerIncome = () => async dispatch => {
  dispatch(fetchCohealerIncomeActions.request());

  try {
    const response = await getCohealerIncome();
    dispatch(fetchCohealerIncomeActions.success(response));
  } catch (error) {
    pipe(get('response'), get('data'), fetchCohealerIncomeActions.error, dispatch)(error);
  }
};
