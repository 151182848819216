import React from 'react'
import PropTypes from 'prop-types'

import { useRouter } from 'hooks'
import { Chat } from 'components/UI/chats'

const ClientChat = ({
  contribution: {
    chat: { cohealerPeerChatSids },
  },
}) => {
  const {
    match: {
      params: { userId },
    },
  } = useRouter()

  return <Chat channelId={cohealerPeerChatSids[userId]} />
}

ClientChat.propTypes = {
  contribution: PropTypes.shape({
    chat: PropTypes.shape({
      cohealerPeerChatSids: PropTypes.shape({}),
    }),
  }).isRequired,
}

export default ClientChat
