import {
  LOG_IN_REQUEST,
  LOG_IN_SUCCESS,
  LOG_IN_FAILURE,
  RESTORE_PASS_REQUEST,
  RESTORE_PASS_SUCCESS,
  RESTORE_PASS_FAILURE,
  NEW_PASS_REQUEST,
  NEW_PASS_SUCCESS,
  NEW_PASS_FAILURE,
  REGISTER_REQUEST,
  REGISTER_SUCCESS,
  REGISTER_FAILURE,
  CONTINUE_REGISTER_REQUEST,
  CONTINUE_REGISTER_SUCCESS,
  CONTINUE_REGISTER_FAILURE,
  LOG_OUT,
  EDIT_PROFILE_REQUEST,
  EDIT_PROFILE_SUCCESS,
  EDIT_PROFILE_FAILURE,
  CLEAR_USER_ERRORS,
  DISABLE_SHOW_CLIENT_MODALS,
  GET_USER_REQUEST,
  GET_USER_SUCCESS,
  GET_USER_FAILURE,
  SET_PROFILE_AVATAR,
  GET_ACCOUNT,
  HIDE_TEST_VIDEO_BUTTON,
  GETTING_STARTED_TOGGLE,
  SAME_COACH_JOIN,
  SET_IS_CODE,
  PURCHASE_SUCCESSFUL,
  TEST_LOGIN,
  SET_GUEST_CONTRIBUTION_ID,
} from 'actions/user';
import {
  UPDATE_USER_ACTION,
  UPDATE_PAIDTIEROPTIONS_PERIOD,
  TEMPORARY_DATA_FOR_SWITCHING_ROLE,
  COHERE_ACADEMY_IS_ENABLE,
} from '../actions/update-user';
import { CHANGE_CURRENT_ROLE } from '../actions/user.js';
import { setItem } from 'services/localStorage.service';

const initialState = {
  user: null,
  token: null,
  error: null,
  roles: [],
  currentRole: '',
  signupType: '',
  showClientModals: true,
  isLoading: false,
  isPaidTierOptionsMonthly: false,
  temporaryDataForSwtichingRole: {},
  cohereAcademyIsEnable: false,
  purchasingDummy: true,
  showSameCoachJoinPopUp: false,
  UpdatePasswordPopupText: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case COHERE_ACADEMY_IS_ENABLE:
      return {
        ...state,
        cohereAcademyIsEnable: payload,
      };
    case 'PURCHASE_DUMMY':
      return {
        ...state,
        purchasingDummy: payload,
      };
    case TEMPORARY_DATA_FOR_SWITCHING_ROLE:
      return {
        ...state,
        temporaryDataForSwtichingRole: payload,
      };

    case UPDATE_PAIDTIEROPTIONS_PERIOD:
      return {
        ...state,
        isPaidTierOptionsMonthly: payload,
      };

    case UPDATE_USER_ACTION:
      return {
        ...state,
        user: payload,
      };

    case GETTING_STARTED_TOGGLE:
      return {
        ...state,
        user: { ...state.user, gettingStartedGuideFinished: payload },
      };
    case DISABLE_SHOW_CLIENT_MODALS:
      return { ...state, showClientModals: false };
    case REGISTER_REQUEST:
    case CONTINUE_REGISTER_REQUEST:
    case LOG_IN_REQUEST:
    case RESTORE_PASS_REQUEST:
    case NEW_PASS_REQUEST:
    case EDIT_PROFILE_REQUEST:
    case GET_USER_REQUEST:
      return { ...state, isLoading: true, error: null };
    case REGISTER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        signupType: payload.signupType,
        user: { ...state.user, ...payload },
        token: payload.oAuthToken,
      };
    case CONTINUE_REGISTER_SUCCESS:
    case 'USER_SETUP':
    case LOG_IN_SUCCESS:
    case EDIT_PROFILE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        user: { ...payload.account, ...payload.user },
        roles: payload.roles,
        currentRole: payload.currentRole,
        token: payload.account.oAuthToken,
      };
    case CHANGE_CURRENT_ROLE:
      return {
        ...state,
        currentRole: payload,
      };
    case GET_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        user: { ...state.user, ...payload },
      };
    case RESTORE_PASS_SUCCESS:
    case NEW_PASS_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case REGISTER_FAILURE:
    case CONTINUE_REGISTER_FAILURE:
    case LOG_IN_FAILURE:
    case RESTORE_PASS_FAILURE:
    case NEW_PASS_FAILURE:
    case EDIT_PROFILE_FAILURE:
    case GET_USER_FAILURE:
      return { ...state, isLoading: false, error: payload };
    case LOG_OUT:
      return { ...initialState };
    case CLEAR_USER_ERRORS: {
      return { ...state, error: null };
    }
    case SET_PROFILE_AVATAR:
      return {
        ...state,
        user: {
          ...state.user,
          avatarUrl: payload,
        },
      };
    case GET_ACCOUNT:
      // eslint-disable-next-line no-case-declarations
      const { id, createTime, updateTime, ...rest } = payload;

      return {
        ...state,
        user: { ...state.user, accountId: id, ...rest },
      };
    case HIDE_TEST_VIDEO_BUTTON:
      return {
        ...state,
        user: {
          ...state.user,
          isVideoTestFirstTime: false,
        },
      };
    case PURCHASE_SUCCESSFUL:
      return {
        ...state,
        signupType: payload.type,
        user: {
          ...state.user,
          signupType: payload.type,
        },
      };
    case SAME_COACH_JOIN:
      return {
        ...state,
        showSameCoachJoinPopUp: payload,
      };
    case SET_GUEST_CONTRIBUTION_ID:
      setItem('guestContributionId', payload);
      return {
        ...state,
        user: {
          ...state.user,
          guestContributionId: payload,
        },
      };
    default:
      return state;
  }
};
