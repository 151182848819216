import PropTypes, { arrayOf, string } from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import Modal from 'components/UI/Modal';
import { Box, Checkbox, ClickAwayListener, FormControlLabel, Grid, IconButton, TextField } from '@material-ui/core';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { determineColorToUse } from 'services/contributions.service';
import { hexToRgba, isValidUrl, pluckKeys } from 'utils/utils';
import EmojiPicker from 'emoji-picker-react';
import DeleteIcon from './DeleteIcon';
import EmoticonIcon from './EmoticonIcon';
import { GROUP_MENU_OPTIONS, SECTION_MENU_OPTIONS } from '../../../../constants';

const TITLE_LIMIT = 50;

const validationSchema = Yup.object().shape({
  groups: Yup.array().of(
    Yup.object().shape({
      title: Yup.string()
        .max(50, "Title can't have more than 50 characters")
        .when('imageUrl', {
          is: imageUrl => imageUrl?.length > 1,
          then: Yup.string().required('Title is a required field'),
        }),
      imageUrl: Yup.string(),
      enableClientPosting: Yup.bool(),
      isPostApprovalRequired: Yup.bool(),
    }),
  ),
});

function GroupModal({
  isOpen,
  section,
  contribution,
  mode = SECTION_MENU_OPTIONS.createGroup,
  title,
  suggestedGroups,
  submitTitle,
  themedColors,
  groupToEdit,
  onSubmit,
  onCancel,
  isDarkThemeEnabled,
}) {
  const colorToUse = determineColorToUse(contribution);

  const [groups, setGroups] = useState([]);
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [suggestedGroupsToAdd, setSuggestedGroupsToAdd] = useState([]);
  const formRef = useRef(null);

  useEffect(() => {
    let initGroups = [];
    if (groupToEdit) {
      initGroups = [groupToEdit];
    } else {
      initGroups = [
        {
          id: 1,
          title: '',
          imageUrl: '',
          enableClientPosting: true,
          pickEmoji: false,
          isPostApprovalRequired: false,
        },
      ];
    }

    setGroups(initGroups);

    if (mode === SECTION_MENU_OPTIONS.createGroup) {
      const sectionGroups = section?.groups;
      const exisingSuggestedGroups = sectionGroups?.filter(group => group.isSuggested);
      const suggestedGroupsAvailable = [
        ...suggestedGroups.filter(group => !pluckKeys(exisingSuggestedGroups, 'title')?.includes(group.title)),
      ];
      setSuggestedGroupsToAdd([
        ...suggestedGroupsAvailable.map(group => {
          return { ...group, added: false, isSuggested: true };
        }),
      ]);
    }
  }, [section]);

  const validateGroups = () => {
    const suggestedGroupsAddded = suggestedGroupsToAdd.filter(group => group.added) || [];
    const values = formRef?.current?.values?.groups || [];
    const validGroups = [...suggestedGroupsAddded, ...values].filter(group => group.title !== '');
    setDisableSubmit(!(validGroups?.length > 0));
  };

  useEffect(() => {
    validateGroups();
  }, [formRef?.current?.values?.groups, suggestedGroupsToAdd]);

  const addGroup = () => {
    const group = {
      id: groups.length + 1,
      title: '',
      imageUrl: '',
      enableClientPosting: true,
      isPostApprovalRequired: false,
      pickEmoji: false,
    };
    const { values, setFieldValue } = formRef.current;
    setFieldValue('groups', [...values?.groups, group], false);
    setGroups([...groups, group]);
  };

  const deleteGroup = (index, values, setFieldValue) => {
    setFieldValue('groups', [...values.groups.filter((group, groupIndex) => index !== groupIndex)], false);
    setGroups([...groups.filter((group, groupIndex) => index !== groupIndex)]);
  };

  const handleDragEnd = results => {
    const { source, destination } = results;
    if (!destination) return;

    if (source.droppableId === destination.droppableId && source.index === destination.index) {
      return;
    }

    const reOrderedGroups = [...groups];
    const [removedSection] = reOrderedGroups.splice(source.index, 1);
    reOrderedGroups.splice(destination.index, 0, removedSection);
    setGroups(reOrderedGroups);
    formRef.current.setFieldValue('groups', reOrderedGroups);
  };

  const handleToggleSuggestedGroupAdd = (index, value) => {
    setSuggestedGroupsToAdd([
      ...suggestedGroupsToAdd.map((group, groupIndex) => {
        if (index === groupIndex) {
          group.added = value;
        }
        return group;
      }),
    ]);
  };

  const onChange = (index, property, value) => {
    const toBeModified = groups[index];

    const modifiedObject = {
      ...toBeModified,
      [property]: value,
    };

    const modifiedGroups = [
      ...groups.map((group, groupIndex) => {
        return index === groupIndex ? modifiedObject : group;
      }),
    ];

    formRef.current.setFieldValue('groups', modifiedGroups);
    setGroups(modifiedGroups);
    setTimeout(() => {
      validateGroups();
    }, 100);
  };

  const handleSubmit = values => {
    const suggestedGroupsAddded = suggestedGroupsToAdd.filter(group => group.added);
    onSubmit({ ...values, groups: [...suggestedGroupsAddded, ...values.groups?.filter(group => group.title !== '')] });
  };

  const updateGroupValue = (index, setFieldValue, keys, values = []) => {
    let originalGroups = [...groups];
    originalGroups = originalGroups?.map((group, groupIndex) => {
      if (index === groupIndex) {
        const newData = {};
        keys.forEach((key, keyIndex) => {
          newData[key] = values[keyIndex] ?? !group[key];
        });
        return { ...group, ...newData };
      }
      return group;
    });

    setFieldValue('groups', originalGroups);
    setGroups(originalGroups);
  };

  const getGroupIcon = imageUrl => {
    if (isValidUrl(imageUrl)) {
      return <img src={imageUrl} alt="group icon" className="group-image" />;
    }
    return <div className="group-image">{imageUrl ?? ''}</div>;
  };

  return (
    <Modal
      className={`create-group-popup ${mode !== SECTION_MENU_OPTIONS.createGroup ? 'edit-group' : ''}`}
      isOpen={isOpen}
      title={title}
      applyTheming
      submitTitle={submitTitle}
      hiddenCancel
      disableSubmitClick={disableSubmit}
      onCancel={onCancel}
      onSubmit={() => formRef.current.handleSubmit()}
    >
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="groups">
          {provided => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              id="group-modal-content"
              className={`${mode === SECTION_MENU_OPTIONS.createGroup ? 'create-groups' : 'edit-group'}`}
            >
              <Formik
                innerRef={formRef}
                initialValues={{ sectionId: section?.sectionId, contributionId: contribution.id, groups }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({ values, errors, setFieldTouched, setFieldValue }) => (
                  <>
                    {groups.map((group, index) => (
                      <>
                        <Draggable key={group.id.toString()} draggableId={group.id.toString()} index={index}>
                          {provided => (
                            <div ref={provided.innerRef} {...provided.draggableProps}>
                              <div
                                className="group-item"
                                style={{
                                  backgroundColor: !isDarkThemeEnabled
                                    ? `rgba(${hexToRgba(colorToUse.AccentColorCode, 0.1)})`
                                    : '',
                                }}
                              >
                                <div className="group-container">
                                  <div className="group">
                                    {mode !== GROUP_MENU_OPTIONS.editGroup ? (
                                      <span {...provided.dragHandleProps}>
                                        <DragIndicatorIcon className="drag-icon" />
                                      </span>
                                    ) : (
                                      <span {...provided.dragHandleProps}> </span>
                                    )}
                                    <div className="input-container">
                                      <IconButton
                                        className="p-0"
                                        onClick={() => updateGroupValue(index, setFieldValue, ['pickEmoji'])}
                                      >
                                        {group.imageUrl !== '' ? (
                                          getGroupIcon(group.imageUrl)
                                        ) : (
                                          <EmoticonIcon fillColor={isDarkThemeEnabled ? 'white' : '#213649'} />
                                        )}
                                      </IconButton>
                                      <div className="w-100">
                                        <TextField
                                          name="title"
                                          className="input-field"
                                          variant="outlined"
                                          fullWidth
                                          autoFocus
                                          style={{
                                            color: themedColors.themedColor,
                                            backgroundColor: themedColors.themedBackgroundColor,
                                          }}
                                          value={values?.groups[index]?.title}
                                          error={Boolean(errors.groups && errors?.groups[index]?.title)}
                                          placeholder="Title"
                                          onBlur={() => setFieldTouched(`groups.${index}.title`)}
                                          onChange={e => onChange(index, 'title', e.target.value.trimStart())}
                                          inputProps={{ maxLength: TITLE_LIMIT }}
                                        />
                                      </div>
                                      <span className="modal-error-message">
                                        {errors.groups && errors?.groups[index]?.title}
                                      </span>
                                    </div>
                                    {mode === SECTION_MENU_OPTIONS.createGroup && (
                                      <IconButton
                                        className="p-0"
                                        onClick={() => deleteGroup(index, values, setFieldValue)}
                                      >
                                        <DeleteIcon fillColor={isDarkThemeEnabled ? 'white' : '#213649'} />
                                      </IconButton>
                                    )}
                                  </div>
                                  <div className="client-posting">
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          style={{ color: colorToUse.PrimaryColorCode }}
                                          checked={values?.groups[index]?.enableClientPosting}
                                          onChange={e => onChange(index, 'enableClientPosting', e.target.checked)}
                                        />
                                      }
                                      label="Enable Client posting"
                                    />
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          style={{ color: colorToUse.PrimaryColorCode }}
                                          checked={values?.groups[index]?.isPostApprovalRequired}
                                          onChange={e => onChange(index, 'isPostApprovalRequired', e.target.checked)}
                                        />
                                      }
                                      label="Admin Approval required for Client posting"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </Draggable>
                        {group.pickEmoji && (
                          <ClickAwayListener
                            onClickAway={() => {
                              updateGroupValue(index, setFieldValue, ['pickEmoji'], [false]);
                            }}
                          >
                            <Box className="emoji-picker-container">
                              <EmojiPicker
                                previewConfig={{
                                  showPreview: false,
                                }}
                                theme={isDarkThemeEnabled ? 'dark' : 'light'}
                                onEmojiClick={(emojiData, emojiEvent) => {
                                  updateGroupValue(
                                    index,
                                    setFieldValue,
                                    ['pickEmoji', 'imageUrl'],
                                    [false, emojiData.imageUrl],
                                  );
                                }}
                                height={350}
                                lazyLoadEmojis
                                skinTonesDisabled
                                size="14"
                              />
                            </Box>
                          </ClickAwayListener>
                        )}
                      </>
                    ))}
                  </>
                )}
              </Formik>
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      {mode === SECTION_MENU_OPTIONS.createGroup && (
        <div className={`justify-content-start ${groups.length === 0 ? 'mb-3' : 'my-3'}`}>
          <div className="add-item" role="button" tabIndex="0" onKeyDown={() => {}} onClick={() => addGroup()}>
            <AddCircleOutlineIcon /> Create Group
          </div>
        </div>
      )}
      {mode === SECTION_MENU_OPTIONS.createGroup && suggestedGroupsToAdd && suggestedGroupsToAdd.length > 0 && (
        <div>
          <div className="suggested-title">Suggested Groups</div>
          <Grid container spacing={1}>
            {suggestedGroupsToAdd?.map((group, index) => (
              <Grid item xs={12} md={6} lg>
                <div className="suggested-tile">
                  <FormControlLabel
                    control={
                      <Checkbox
                        style={{ color: colorToUse.PrimaryColorCode }}
                        checked={suggestedGroupsToAdd.added}
                        onChange={e => handleToggleSuggestedGroupAdd(index, e.target.checked)}
                      />
                    }
                    label={
                      <div className="suggested-body">
                        <img src={group.imageUrl} alt="suggested icon" className="mr-1" />
                        {group.title}
                      </div>
                    }
                  />
                </div>
              </Grid>
            ))}
          </Grid>
        </div>
      )}
    </Modal>
  );
}

GroupModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  section: PropTypes.shape({ sectionId: string, groups: arrayOf({}) }).isRequired,
  contribution: PropTypes.shape({ id: string }).isRequired,
  title: PropTypes.string.isRequired,
  submitTitle: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  mode: PropTypes.string.isRequired,
  groupToEdit: PropTypes.shape({}).isRequired,
  themedColors: PropTypes.shape([]).isRequired,
  suggestedGroups: PropTypes.shape([]).isRequired,
  isDarkThemeEnabled: PropTypes.bool.isRequired,
};

export default GroupModal;
