import React, { useCallback, Fragment, useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { getIn } from 'formik';
import Select from 'components/UI/Select';
import { Icon } from '@mdi/react';
import { mdiPlus, mdiMinusCircleOutline } from '@mdi/js';
import moment from 'moment';
import TimePickerField from 'components/FormUI/TimePickerField';
import { useTheme, useMediaQuery, Tooltip } from '@material-ui/core';
import * as calendarsActions from 'actions/calendars';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Button from 'components/FormUI/Button';
import Loader from 'components/UI/Loader';
import CommonErrorMessage from 'components/FormUI/CommonErrorMessage';
import { generateOneToOneSlots } from 'pages/CreateContribution/Sessions';
import { colors, toRem } from 'utils/styles';
import * as oneToOneDataActions from 'actions/oneToOneData';
import DatePanel from 'react-multi-date-picker/plugins/date_panel';
import DatePicker from 'react-multi-date-picker';
import { DATE_FORMATS } from 'utils/datesAndMoney';
import * as contributionActions from 'actions/contributions';
import InfoIcon from '@material-ui/icons/Info';
import { TOOLTIP } from '../../../constants';
import WeekdayPicker from './WeekdayPicker';

const StyledIcon = styled(Icon)`
  cursor: pointer;
  margin: 0 10px;
`;

const StyledFormControl = styled(FormControl)`
  width: 100%;
`;

export const StyledLink = styled.span`
  max-width: 260px;
  display: flex;
  font-family: Brandon Text;
  align-items: center;
  font-size: ${toRem(16)};
  font-weight: normal;
  line-height: 1.25;
  letter-spacing: 0.25px;
  color: rgba(0, 0, 0, 0.87);
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;
const StyledDivContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: ${({ smView }) => (smView ? 'column' : 'row')};
  margin-bottom: ${({ smView }) => (smView ? '16px' : '0')};
  justify-content: space-between;
`;

const StyledButton = styled(Button)`
  margin-top: 10px;
`;

const StyledLabel = styled.div`
  width: 100px;
  font-family: 'Brandon Text';
`;

const StyledHeading = styled.div`
  width: 281px;
  height: 21px;
  font-family: Brandon Text;
  font-style: normal;
  font-weight: 390;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  letter-spacing: 0.1px;
  color: #282b2b;
  margin-top: 15px;
  margin-bottom: 10px;

  ${({ GiveYourself, mobileView }) =>
    GiveYourself &&
    `
  width: ${mobileView ? '100%' : '253px'};
  height: 48px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  
  display: flex;
  align-items: center;
  
  color: rgba(0, 0, 0, 0.87);
  `}
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  .MuiTypography-body1 {
    font-family: Avenir;
    font-size: 16px;
    font-style: normal;
    font-weight: 350;
  }
  margin-bottom: 0;
  color: rgba(0, 0, 0, 0.87);
`;

const bufferTimeValues = [
  { title: '15 min', value: 15 },
  { title: '30 min', value: 30 },
  { title: '1 hour', value: 60 },
];

const SessionTimeOneToOneComponent = ({
  form,
  push,
  remove,
  sessionIdx,
  timesCount,
  isOneToOne,
  oneToOneStartDate,
  oneToOneEndDate,
  addBookingTime,
  setOneToOneTime,
  selectedWeeks,
  removeBookingTime,
  sessionDuration,
  oneToOneData,
  setSlots,
  otherEvents,
  contribution,
  getBusyTime,
  busyTimes,
  loadingBusyTimes,
  editContributionTimes,
  getEditContributionTimes,
  calculatedSlots,
  bufferTimeBefore,
  bufferTimeAfter,
  blockedDates,
  setBlockedDates,
}) => {
  const { duration, startDay, endDay } = oneToOneData;
  const { sessions } = form?.values;
  const { setFieldValue } = form;
  const error = getIn(form.errors, `sessions[${sessionIdx}]`);
  const showError = getIn(form.touched, `sessions[${sessionIdx}]`)?.sessionTimes && !!error;
  const [isBeforeBufferActive, setIsBeforeBufferActive] = useState(bufferTimeBefore === 0 ? false : true);
  const [isAfterBufferActive, setIsAfterBufferActive] = useState(bufferTimeAfter === 0 ? false : true);
  const [addCustomEnrollmentForm, setAddCustomEnrollmentForm] = useState(
    contribution?.isCustomEnrollmentFormAdded ?? false,
  );
  const [beforeBufferTime, setBeforeBufferTime] = useState(5);
  const [afterBufferTime, setAfterBufferTime] = useState(5);
  const [newBlockedDates, setNewBlockedDates] = useState([]);
  const calenderRef = useRef();

  const addTimesHandler = () => {
    addBookingTime(sessionDuration);
  };

  const removeTimesHandler = useCallback(i => () => removeBookingTime(i), [removeBookingTime]);

  const theme = useTheme();
  const smView = useMediaQuery(theme.breakpoints.down('sm'));
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'));

  const handleTimeChange = (value, sessionTimeIdx, timeType) => {
    if (value) {
      const currentDate = moment(oneToOneStartDate).format('YYYY-MM-DD');
      const currentTime = moment(`${currentDate} ${value.format('HH:mm:ss')}`).format('YYYY-MM-DDTHH:mm:ss[Z]');
      setOneToOneTime(sessionTimeIdx, currentTime, timeType);

      if (isOneToOne && timeType === 'startTime') {
        const endTimeValue = value.add(sessionDuration, 'minutes');
        const newEndTime = moment(`${currentDate} ${endTimeValue.format('HH:mm:ss')}`).format('YYYY-MM-DDTHH:mm:ss[Z]');
        setOneToOneTime(sessionTimeIdx, newEndTime, 'endTime');
      }
    }
  };

  useEffect(() => {
    setNewBlockedDates(
      blockedDates?.map(date => {
        return new Date(date);
      }),
    );
  }, [blockedDates]);

  return (
    <>
      {/* {loadingBusyTimes && <Loader />} */}
      {selectedWeeks.length && (
        <Grid container spacing={4}>
          {selectedWeeks.map((week, i) => {
            const momentStartTime = moment.utc(week.startTime);
            const momentEndTime = moment.utc(week.endTime);
            const key = i;
            return (
              <Fragment key={key}>
                <Grid container style={{ padding: '16px 0' }}>
                  <StyledDivContainer smView={smView}>
                    <Grid sm={smView ? 12 : 6} xs={smView ? 12 : 6}>
                      <div className="d-flex" style={{ width: '100%', padding: '0 16px' }}>
                        <StyledFormControl>
                          <InputLabel
                            style={{ color: 'black', fontFamily: 'Brandon Text' }}
                            shrink
                            htmlFor={`available-time-${i}`}
                          >
                            Add available times: What days and times can be booked?
                          </InputLabel>
                          <WeekdayPicker itemIndex={i} />
                        </StyledFormControl>
                      </div>
                    </Grid>
                    <Grid sm={smView ? 12 : 6} xs={smView ? 12 : 6} style={{ marginLeft: smView ? 0 : 8 }}>
                      <Grid container direction="row" justify="space-around" alignItems="baseline">
                        <Grid item sm={4} xs={4}>
                          <div className="d-flex">
                            <StyledFormControl>
                              <InputLabel
                                htmlFor={`start-time-${i}`}
                                style={{ top: '-20px', fontFamily: 'Brandon Text' }}
                              >
                                From
                              </InputLabel>
                              <TimePickerField
                                onChange={value => handleTimeChange(value, i, 'startTime')}
                                name={`start-time-${i}`}
                                id={`tstart-time-${i}`}
                                classes={{
                                  root: 'mt-4 mr-3',
                                }}
                                showSecond={false}
                                value={momentStartTime}
                                format="h:mm a"
                                use12Hours
                                minuteStep={15}
                              />
                            </StyledFormControl>
                          </div>
                        </Grid>
                        <Grid item sm={4} xs={4}>
                          <div className="d-flex">
                            <StyledFormControl>
                              <InputLabel
                                htmlFor={`end-time-${i}`}
                                style={{ top: '-20px', fontFamily: 'Brandon Text' }}
                              >
                                To
                              </InputLabel>
                              <TimePickerField
                                style={{ fontFamily: 'Brandon Text' }}
                                onChange={value => handleTimeChange(value, i, 'endTime')}
                                name={`end-time-${i}`}
                                id={`tend-time-${i}`}
                                classes={{
                                  root: 'mt-4 mr-3',
                                }}
                                showSecond={false}
                                value={momentEndTime}
                                format="h:mm a"
                                use12Hours
                                minuteStep={15}
                              />
                            </StyledFormControl>
                          </div>
                        </Grid>
                        <Grid
                          item
                          sm={2}
                          xs={2}
                          style={{
                            textAlign: 'right',
                          }}
                          classes={{
                            root: 'd-flex align-items-center',
                          }}
                        >
                          &nbsp;
                          {selectedWeeks.length > 1 && (
                            <StyledIcon onClick={removeTimesHandler(i)} path={mdiMinusCircleOutline} size={1} />
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </StyledDivContainer>
                </Grid>
              </Fragment>
            );
          })}
        </Grid>
      )}

      {!!sessions[sessionIdx].sessionTimes?.length && <br />}

      <StyledLink onClick={addTimesHandler}>
        <Icon path={mdiPlus} size={1} />
        Add Additional Booking Times
      </StyledLink>
      <div style={{ position: 'relative', marginBottom: '20px', marginTop: '20px' }}>
        <DatePicker
          ref={calenderRef}
          value={newBlockedDates}
          onChange={val => setNewBlockedDates(val.map(date => new Date(date.unix * 1000)))}
          multiple
          minDate={moment(oneToOneStartDate).toDate()}
          maxDate={moment(oneToOneEndDate).toDate()}
          sort
          highlightToday={false}
          onClose={() => {
            setBlockedDates(newBlockedDates?.map(date => date.toISOString()));
          }}
          plugins={[
            <DatePanel
              header="Blocked Days"
              formatFunction={({ format }) => {
                return moment(format).format(DATE_FORMATS.DAY);
              }}
            />,
          ]}
        />
        <StyledLink
          style={{ position: 'absolute', left: 0, top: '0', backgroundColor: 'white', width: '247px' }}
          onClick={e => {
            e.preventDefault();
            calenderRef.current.openCalendar();
          }}
        >
          <Icon path={mdiPlus} size={1} />
          Block Single Days ({blockedDates?.length || 0})
        </StyledLink>
      </div>
      {showError && timesCount === 0 && (
        <>
          <br />
          <CommonErrorMessage align="left" message={error.sessionTimes} />
        </>
      )}
      <Grid container>
        <Grid item xs={12}>
          <StyledHeading>Give yourself buffer time before or after your client bookings</StyledHeading>
        </Grid>
        <Grid item container xs={12} spacing={3}>
          <Grid item direction="column" md={2} sm={3} xs={6}>
            <FormControlLabel
              style={{ fontFamily: 'Brandon Text' }}
              checked={isBeforeBufferActive}
              onChange={() => {
                setIsBeforeBufferActive(!isBeforeBufferActive);
                if (isBeforeBufferActive === true) {
                  setFieldValue('bufferTimeBefore', 0);
                }
              }}
              control={<Checkbox color="primary" />}
              label={<StyledLabel>Before event</StyledLabel>}
              name="isBeforeBufferActive"
              disabled={false}
            />
            <Select
              labelTop="0px"
              // name='name'
              value={bufferTimeBefore}
              style={{ fontFamily: 'Brandon Text' }}
              onChange={e => {
                setFieldValue('bufferTimeBefore', e.target.value);
              }}
              fullWidth
              items={bufferTimeValues?.map(({ title, value }) => ({
                title,
                value,
              }))}
              disabled={!isBeforeBufferActive}
            />
          </Grid>
          <Grid item direction="column" md={2} sm={3} xs={6}>
            <FormControlLabel
              style={{ fontFamily: 'Brandon Text' }}
              checked={isAfterBufferActive}
              onChange={() => {
                setIsAfterBufferActive(!isAfterBufferActive);
                if (isAfterBufferActive === true) {
                  setFieldValue('bufferTimeAfter', 0);
                }
              }}
              control={<Checkbox color="primary" />}
              label={<StyledLabel>After event</StyledLabel>}
              name="isAfterBufferActive"
              disabled={false}
            />
            <Select
              labelTop="0px"
              // name={`sessions[${index}].daterange`}
              value={bufferTimeAfter}
              onChange={e => {
                setFieldValue('bufferTimeAfter', e.target.value);
              }}
              style={{ fontFamily: 'Brandon Text' }}
              fullWidth
              items={bufferTimeValues?.map(({ title, value }) => ({
                title,
                value,
              }))}
              disabled={!isAfterBufferActive}
            />
          </Grid>
          <Grid md={5} sm={6} xs={12} style={{ padding: '12px', display: 'flex', alignItems: 'flex-end' }}>
            {/* <StyledHeading GiveYourself mobileView={mobileView}>
              Give yourself buffer time before or after your client bookings{' '}
            </StyledHeading> */}
          </Grid>
        </Grid>
      </Grid>
      <Grid container style={{ marginTop: '24px' }}>
        <Grid item xs={12}>
          <div className="d-flex align-items-center">
            <StyledFormControlLabel
              checked={addCustomEnrollmentForm}
              onChange={e => {
                setAddCustomEnrollmentForm(e.target.checked);
                setFieldValue('enrollmentForm.isCustomEnrollmentFormAdded', e.target.checked);
              }}
              control={<Checkbox color="primary" />}
              label="Add Custom Client Enrollment Form"
              name="customEnrollmentForm"
            />
            <Tooltip
              title="This will add custom fields for your clients to answer additional questions on your enrollment page."
              arrow
              placement="right"
              enterTouchDelay={TOOLTIP.ENTER_DELAY}
              leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
            >
              <InfoIcon htmlColor={colors.lightBrown} style={{ cursor: 'pointer' }} />
            </Tooltip>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

SessionTimeOneToOneComponent.propTypes = {
  form: PropTypes.any.isRequired,
  push: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  isOneToOne: PropTypes.bool,
  sessionIdx: PropTypes.number.isRequired,
  timesCount: PropTypes.number.isRequired,
  oneToOneStartDate: PropTypes.shape(),
  oneToOneEndDate: PropTypes.shape(),
  oneToDateRange: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

SessionTimeOneToOneComponent.defaultProps = {
  isOneToOne: false,
  oneToOneStartDate: null,
  oneToOneEndDate: null,
  oneToDateRange: null,
};

const mapStateToProps = ({ oneToOneData, contributions, calendars }) => ({
  oneToOneStartDate: oneToOneData?.startDay,
  oneToOneEndDate: oneToOneData?.endDay,
  sessionDuration: oneToOneData?.sessionDuration,
  selectedWeeks: oneToOneData?.selectedWeeks,
  blockedDates: oneToOneData?.blockedDates,
  oneToOneData: oneToOneData,
  contribution: contributions.activeContribution,
  editContributionTimes: contributions.editContributionTimes,
  calculatedSlots: contributions.calculatedSlots,
});

const actions = {
  addBookingTime: oneToOneDataActions.addBookingTime,
  setOneToOneTime: oneToOneDataActions?.setOneToOneTime,
  removeBookingTime: oneToOneDataActions?.removeBookingTime,
  setSlots: oneToOneDataActions?.setSlots,
  setBlockedDates: oneToOneDataActions.setBlockedDates,
};

export const SessionTimeOneToOne = connect(mapStateToProps, actions)(SessionTimeOneToOneComponent);
