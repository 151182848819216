import React from 'react';
import styled, { css } from 'styled-components';
import { useTheme, useMediaQuery } from '@material-ui/core';
import colon from '../../../assets/testimonial-colon.png';
import jami_stars from '../../../assets/jami-stars.png';
import emma from '../../../assets/emma.png';
import { Avatar } from '@material-ui/core';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import useContribution from 'pages/ContributionView/hooks/useContribution';
import { getThemedColors } from 'services/contributions.service';
import { BorderDiv } from './AboutMainInfoBlock/AboutMainInfo.styles';

const TestimonialMainContainer = styled.div``;
const TestimonialMainHeading = styled.h1`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 50px;
  margin: 0;
  padding: 0px 0px 1rem 0px;
  color: #282c2c;
  ${({ mobileView }) =>
    mobileView &&
    css`
      text-align: center;
      font-size: 32px;
    `}
`;
const TestimonialInnerContent = styled.div`
  ${({ mobileView }) =>
    mobileView &&
    css`
      text-align: center;
    `}
`;
const CollenIcon = styled.img``;
const TestimonialP = styled.p`
  margin: 0px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;

  color: #282c2c;
`;
const TestimonialStars = styled.img`
  margin-bottom: 15px;
`;
const TestimonialProfile = styled.div`
  display: flex;
  padding: 1rem 0px 1rem 0px;
  ${({ mobileView }) =>
    mobileView &&
    css`
      display: block;
      text-align: center;
    `};
`;
const ProfileLeftImgDiv = styled.div`
  ${({ mobileView }) =>
    mobileView &&
    css`
      display: flex;
      justify-content: center;
    `};
`;

const ProfileLeftImg = styled.img`
  width: 70px;
`;
const ProfileRightTxtDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  // align-items: center;
  padding: 0px 0px 0px 0.6rem;
`;
const TestProfileRighth2 = styled.h2`
  font-family: 'Brandon Text';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #282b2b;
  margin-bottom: 0px;
`;
const TestProfileRightp = styled.p`
  font-family: 'Brandon Text';
  font-style: normal;
  font-weight: 420;
  font-size: 12px;
  color: #282b2b;
  opacity: 0.4;
  margin-bottom: 0px;
`;
const TestimonialMainDiv = styled.div`
  // padding: 2rem 0px;
  margin-bottom: 15px;
`;

const TestimonialH3 = styled.h3`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  color: #282c2c;
  margin: 0px;
  padding: 10px 0px;
`;

function TestimonialTemTwo(props) {
  const { testimonials } = props;
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'));

  const contribution = useContribution();

  const { themedColor } = getThemedColors(contribution);

  return (
    <>
      {!!testimonials && (
        <div
          style={{
            margin: '5rem 0px',
            border: `1px solid ${
              contribution?.isDarkModeEnabled ? '#696969' : contribution?.brandingColors?.PrimaryColorCode
            }`,
          }}
        ></div>
      )}
      <TestimonialMainContainer>
        {!!testimonials && (
          <TestimonialMainHeading style={{ color: themedColor }} mobileView={mobileView}>
            Testimonials
          </TestimonialMainHeading>
        )}
        {testimonials?.map(t => {
          const reviewStars = 4;
          return (
            <TestimonialMainDiv>
              <TestimonialProfile mobileView={mobileView}>
                <ProfileLeftImgDiv mobileView={mobileView}>
                  {/* <ProfileLeftImg src={t.avatarUrl}></ProfileLeftImg> */}
                  <Avatar src={t.avatarUrl} style={{ width: '50px', height: '50px' }} />
                </ProfileLeftImgDiv>
                <ProfileRightTxtDiv>
                  <div>
                    <TestProfileRighth2 style={{ color: themedColor }}>{t.name}</TestProfileRighth2>
                  </div>
                  <div>
                    <TestProfileRightp style={{ color: themedColor }}>{t.role}</TestProfileRightp>
                  </div>
                </ProfileRightTxtDiv>
              </TestimonialProfile>
              <TestimonialInnerContent mobileView={mobileView}>
                {/* <TestimonialStars src={jami_stars}></TestimonialStars> */}
                {/* <TestimonialH3>The entire process was very easy.</TestimonialH3> */}
                <TestimonialP style={{ color: themedColor }}>{t.description}</TestimonialP>
              </TestimonialInnerContent>
            </TestimonialMainDiv>
          );
        })}
      </TestimonialMainContainer>
    </>
  );
}

export default TestimonialTemTwo;
