import React, { useCallback, useState, useRef, useEffect, useMemo } from 'react';
import { useDispatch, connect } from 'react-redux';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import ProgressBar from '@ramonak/react-progress-bar';
import { useTheme, useMediaQuery, Tooltip } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { lighten } from 'polished';
import { Card as CardUI, CardHeader as CardHeaderUI } from 'components/UI/Card';
import { CardContent } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { SuccessfulPurchaseModal } from 'components/Modals/SuccessfulPurchaseModal';
import Button from 'components/FormUI/Button';
import {
  fetchClientContribution,
  fetchClientContributionAfterInterval,
  rescheduleSessionForClient,
  setPreviousFreeSession,
  setRescheduleFormerSessionDetail,
  setRescheduleModal,
  setSingleCode,
} from 'actions/contributions';
import { ContributionType, PaymentStatus, UserRoles } from 'helpers/constants';
import { formatMoney, getTimePeriodsForAvailability } from 'utils/datesAndMoney';
import { determineColorToUse, getOneToOneSelfBookClient } from 'services/contributions.service';
import {
  useAccount,
  useApplicationStatus,
  useClientPreview,
  useHttp,
  useRouter,
  useVideoChat,
  useDefaultTimezone,
} from 'hooks';
import JoinSessionTime from 'components/SessionButtons/JoinSessionTime';
import Modal from 'components/UI/Modal';
import { lightOrDark, CanClinetInviteFrieds, getIpGlobal } from 'utils/utils';
import { BodyText } from 'components/UI/Text/TextStyles';
import TextField from 'components/UI/TextField';
import styled from 'styled-components';
import { TIMEZONES } from 'constants.js';
import { TemplateType } from 'helpers/constants/templateType';
import LaunchRecordedSessionVideo from 'components/SessionButtons/LaunchRecordedSessionVideo';
import SessionsContainer from '../SessionsContainer/SessionsContainer';
import CalendarContributionView from '../../../components/CalendarContributionView/CalendarContributionView';
import BookModal from '../../../components/BookModal';
import PurchaseModal from '../../../components/PurchaseModal';
import ProceedModal from '../../../components/ProceedModal';
import RefuseModal from '../../../components/RefuseModal';
import JoinSessionTimeModal from './JoinSessionTimeModal';
import PaymentCard from 'pages/ContributionView/components/PaymentCard';
import './ClientOneToOneSessions.scss';
import SlotsModal from '../../../components/SlotsModal';
import { FILTER_TABS_OPTIONS, TOOLTIP } from '../../constants';
import { JOIN_CONTRIBUTION_STATUS } from '../../../../../constants';
import TabSelector from '../../../../../components/UI/TabSelector';
import { useStyles } from '../../../components/PurchaseBlock/hooks/useStyles';
import ClientOneToOneSessionsCard from './ClientOneToOneSessionsCard';
import CustomPurchaseBlock from 'pages/ContributionView/components/PurchaseBlock/CustomPurchaseBlock';
import { colors, toRem } from 'utils/styles';
import CustomPurchaseBlockOneToOne from 'pages/ContributionView/components/PurchaseBlock/CustomPurchaseBlockOneToOne';
import { joinContribution } from 'services/contributions.service';
import CalendarContributionViewForPaymentCard from 'pages/ContributionView/components/CalendarContributionViewForPaymentCard/CalendarContributionViewForPaymentCard';
import SlotsModalForPaymentCard from 'pages/ContributionView/components/PurchaseModal/SlotsModalForPaymentCard';
import BookModalContainerForPaymentCard from 'pages/ContributionView/components/PurchaseModal/BookModalForPaymentCard';
import PurchaseModalForPaymentCard from 'pages/ContributionView/components/PurchaseModal/PurchaseModalForPaymentCard';
import Loader from 'components/UI/Loader';

import { getThemedColors } from 'services/contributions.service';
import PurchaseClientInvitationFrom from 'pages/ContributionView/components/PurchaseModal/PurchaseClientInvitationForm';
import { redirectTo } from 'services/links';
import * as S from '../../../../ContributionView/components/ContributionHeader/styled';
import ShareModalContainer from 'pages/ContributionView/components/ShareModal/ShareModalContainer';
import { useStripe } from '@stripe/react-stripe-js';
import EasyBooking from '../EasyBooking/EasyBooking';
import SessionBookingThankyou from '../EasyBooking/components/SessionBookingThankyou';
import { setEasyBookingThankYouData } from 'actions/easyBookingThankYou';
import { setEasyBookingData } from 'actions/easyBooking';

const scrollToCalendar = ref => window.scrollTo(0, ref.current.offsetTop);

const StyledTextField = styled(TextField)`
  width: 100%;
`;

const StyledSessionRemaining = styled.div`
  width: ${({ width }) => (width ? width : '300px')};
  color: ${({ clr }) => clr};
  margin-left: ${({ mobileView }) => (mobileView ? '' : '15px')};
  margin-top: ${({ mobileView }) => (mobileView ? '10px' : '')};
`;

const CardHeaderTab = styled.div`
  color: ${({ color }) => color};
  background-color: ${({ clicked, colorToUse }) => (clicked ? colorToUse?.PrimaryColorCode : '')};
  border-radius: 10px 10px 0px 0px;
  font-family: 'Brandon Text';
  font-style: normal;
  font-size: 17px;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 12px;
  font-weight: 600;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

const ClientOneToOneSessions = ({
  user,
  contribution,
  contribution: {
    id,
    title,
    type,
    participantsIds,
    availabilityTimes = [],
    durations,
    serviceProviderName,
    paymentInfo,
    packagePurchases,
    progressBarPackagePurchases,
    isCouponAvailable,
  },
  contributionTimes,
  rescheduleChosenSession,
  isCustomBrandingColorsActive,
  isBrandingColor,
  easyBooking = false,
}) => {
  // const progressBarPackagePurchasesForThisClient = progressBarPackagePurchases?.filter(p => p.userId === user?.id);
  // const lastOne = progressBarPackagePurchasesForThisClient?.length - 1;
  // const clientPackage = progressBarPackagePurchasesForThisClient[lastOne];
  let totalSessionNumbers = 0;
  let totalBookedSessionNumbers = 0;
  const clientPackage = progressBarPackagePurchases?.filter(p => p.userId === user?.id);
  const [showModal, setShowModal] = useState(false);
  const [rescheduleSession, setRescheduleSession] = useState(false);
  clientPackage.forEach(item => {
    totalSessionNumbers += item.sessionNumbers;
    totalBookedSessionNumbers += item.bookedSessionNumbers;
  });
  const { sessionNumbers, freeSessionNumbers, bookedSessionNumbers } = clientPackage[clientPackage.length - 1] || {};
  const [rescheduleError, setRescheduleError] = useState(false);
  const [rescheduleBookingModal, setRescheduleBookingModal] = useState(false);
  const percentageCompleted = (totalBookedSessionNumbers / totalSessionNumbers) * 100;
  const { clientPreviewMode } = useClientPreview();
  const dispatch = useDispatch();
  let colorToUse = determineColorToUse(contribution);
  let textColor =
    colorToUse?.TextColorCode === 'Auto'
      ? lightOrDark(colorToUse?.PrimaryColorCode)
      : colorToUse?.TextColorCode === '#000000'
      ? '#000000'
      : '#FFFFFF';
  const btnColor = colorToUse.PrimaryColorCode;
  const { currentRole } = useAccount();
  const stripe = useStripe();
  const isCoach = currentRole === UserRoles.cohealer;
  const [sessionReschuling, setSessionReschuling] = useState(false);
  const activeTemplate = contribution?.activeTemplate || TemplateType.TemplateThree;
  const shouldChangeColor = (!isCoach || clientPreviewMode) && activeTemplate != TemplateType.TemplateThree;
  const tabColor = shouldChangeColor ? colorToUse.PrimaryColorCode : colors.darkOceanBlue2;
  const [isBookModalShowed, setBookModalShowed] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isPurchaseModalShowed, setPurchaseModalShowed] = useState(false);
  const [isProceedModalShowed, setProceedModalShowed] = useState(false);
  const priceId = useSelector(state => state?.contributions?.priceCode);
  const previosSessionNumber = useSelector(state => state?.contributions?.previosSessionNumber);
  const [isRefuseModalShowed, setRefuseModalShowed] = useState(false);
  const [isOnlyPackageBookingAllowedModalShowed, setIsOnlyPackageBookingAllowedModalShowed] = useState(false);
  const [paymentData, setPaymentData] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [sessionPeriodSelected, setSessionPeriodSelected] = useState({});
  const [isPackage, setIsPackage] = useState(false);
  const [isOpenSuccessPurchaseModal, setSuccessPurchaseModal] = useState(false);
  const [isNotLoggedUser, setIsNotLoggedUser] = useState(false);
  const [isSubmitPurchaseModalNow, setIsSubmitPurchaseModalNow] = useState(false);
  const [rescheduleMode, setRescheduleMode] = useState(false);
  const [isCalendarRescheduleModalOpen, setCalendarRescheduleModal] = useState(false);
  const [currentRescheduleSession, setCurrentRescheduleSession] = useState(null);
  const [messageText, setMessageText] = useState('');
  const [rescheduleTo, setRescheduleTo] = useState(null);
  const [calendarRescheduleModalStatus, setCalendarRescheduleModalStatus] = useState(null);
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const [selectedTimeZone, setSelectedTimeZone] = useState('Select Timezone');
  const oneToOneType = type === ContributionType.contributionOneToOne;
  const [sessionInviteData, setSessionInviteData] = useState(null);
  const isInviteToJoin = useSelector(state => state?.contributions?.inviteCode);
  const isCodeToJoin = useSelector(state => state?.contributions?.singleCode);
  const couponCode = useSelector(state => state?.contributions?.couponCode);
  const [showClientInvitedView, setShowClientInvitedView] = useState(isCodeToJoin != null);
  const [InviteModalOpened, setInviteModalOpened] = useState(false);
  const [slotExpired, setSlotExpired] = useState(false);
  const [errorState, setErrorState] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const { fetchUserTimezone } = useDefaultTimezone();
  const { timeZones, loading: timeZoneLoading } = useSelector(state => state.timeZone);

  const { location, history } = useRouter();
  useEffect(() => {
    if (isCodeToJoin) {
      getSelfBookData();
    }
  }, []);

  const getSelfBookData = () => {
    getOneToOneSelfBookClient(isCodeToJoin.slice(2))
      .then(response => {
        setSessionInviteData(response);
      })
      .catch(err => {
        setSlotExpired(true);
        setErrorState(err.response.data);
      });
  };

  const fetchTimezone = async () => {
    const finalTimezone = await fetchUserTimezone(user?.timeZoneId, contribution?.timeZoneId, timeZones);
    setSelectedTimeZone(finalTimezone);
  };

  useEffect(() => {
    if (selectedTimeZone === 'Select Timezone' && timeZones?.length > 0 && contribution) {
      fetchTimezone();
    }
  }, [timeZones, contribution]);

  const toggleSlotPopup = () => {
    setSlotExpired(!slotExpired);
    setLoading(false);
    dispatch(setSingleCode(null));
    window.location.replace(`/contribution-view/${id}/about`);
    // history.push( `/contribution-view/${id}/about`);
  };
  const toggleRescheduleSlotPopup = () => {
    setRescheduleError(!rescheduleError);
  };
  const { newThemedCardColor, newThemedBackgroundColor, themedColor } = getThemedColors(contribution);

  const calendarEl = useRef(null);

  const { token } = useVideoChat();
  const { request } = useHttp();
  const styledProps = {
    cardBackgroundColor: newThemedCardColor,
  };
  const classNames = useStyles(styledProps);
  const calendarRef = useRef(null);
  const dateToShow = currentRescheduleSession?.startTime;
  const executeScroll = () => {
    scrollToCalendar(calendarRef);
    dispatch(setRescheduleModal(true));
  };
  const { applicationRequiredButNotApproved } = useApplicationStatus({ contribution, user });

  const handleCalendarRescheduleSubmit = () => {
    const rescheduleData = {
      rescheduleFromId: currentRescheduleSession?.id,
      rescheduleToId: rescheduleTo?.id,
      contributionId: id,
      note: messageText,
      offset: rescheduleTo?.offset,
    };
    rescheduleChosenSession(rescheduleData).then(() => {
      setCalendarRescheduleModal(false);
      setCurrentRescheduleSession(null);
      dispatch(setRescheduleModal(false));
      setMessageText(null);
      setCalendarRescheduleModalStatus(rescheduleTo);
    });
  };

  const handleCalendarRescheduleCancel = () => {
    setCalendarRescheduleModal(false);
    setRescheduleMode(false);
    dispatch(setRescheduleModal(false));
    setRescheduleTo(null);
  };

  useEffect(() => {
    const sessionsRefresher = setInterval(() => {
      return !token && dispatch(fetchClientContributionAfterInterval(id));
    }, 10000);

    return () => {
      clearInterval(sessionsRefresher);
    };
  }, [token, dispatch, id]);

  const duration = durations && durations.length ? durations[0] : 60; // temporary becauce there is null
  const events = getTimePeriodsForAvailability({
    availabilityPeriods: contributionTimes,
    duration,
    title,
    serviceProviderName,
  }).map(e => {
    const { isBooked } = e;
    if (!isBooked) {
      return e;
    }
    const bookedTime = contributionTimes.find(at => at.id === e.id).bookedTimes.find(bt => bt.id === e.bookedTimeId);

    return {
      ...e,
      participantId: bookedTime.participantId,
      videoRoomInfo: bookedTime.videoRoomInfo,
    };
  });
  const hideBookSessionButton = useCallback(() => {
    const effectiveBookedSessions =
      totalBookedSessionNumbers > totalSessionNumbers ? totalSessionNumbers : totalBookedSessionNumbers;

    const isSessionsPackageOnly =
      paymentInfo.paymentOptions?.includes('SessionsPackage') && paymentInfo.paymentOptions.length === 1;

    const isFreeSessionsPackageOnly =
      paymentInfo.paymentOptions?.includes('FreeSessionsPackage') && paymentInfo.paymentOptions.length === 1;

    const isBothPackagesIncluded =
      paymentInfo.paymentOptions?.includes('FreeSessionsPackage') &&
      paymentInfo.paymentOptions?.includes('SessionsPackage') &&
      paymentInfo.paymentOptions.length === 2;

    return (
      effectiveBookedSessions === totalSessionNumbers &&
      (isSessionsPackageOnly || isFreeSessionsPackageOnly || isBothPackagesIncluded)
    );
  }, [totalBookedSessionNumbers, totalSessionNumbers, paymentInfo, contribution]);

  const toggleSuccessPurchaseModal = () => setSuccessPurchaseModal(prevState => !prevState);

  // const handlePayment = useCallback(
  //   // ({ status }) => {
  //   () => {
  //     // if ([PaymentStatus.requiresPaymentMethod, PaymentStatus.unpurchased].includes(status)) {
  //     // if(token) {
  //     if (isNotLoggedUser) {
  //       setIsNotLoggedUser(false);
  //       return setIsSubmitPurchaseModalNow(true);
  //     }
  //     setPurchaseModalShowed(true);
  //     // }

  //     // if ([PaymentStatus.requiresAction, PaymentStatus.requiresConfirmation].includes(status)) {
  //     //   setProceedModalShowed(true);
  //     // }

  //     // if (!status) {
  //     // if (!token) {
  //     //   dispatch(fetchClientContribution(id));
  //     // }
  //   },
  //   [setProceedModalShowed, dispatch, id, isNotLoggedUser],
  // );

  const handlePayment = useCallback(
    async (sessionId, { status }) => {
      if (sessionId && typeof sessionId === 'string') {
        if (isNotLoggedUser) {
          setIsNotLoggedUser(false);
          return setIsSubmitPurchaseModalNow(true);
        }
        setPurchaseModalShowed(true);
      } else {
        if ([PaymentStatus.requiresPaymentMethod, PaymentStatus.unpurchased].includes(status)) {
          if (isNotLoggedUser) {
            setIsNotLoggedUser(false);
            return setIsSubmitPurchaseModalNow(true);
          }
          setPurchaseModalShowed(true);
        }

        if ([PaymentStatus.requiresAction, PaymentStatus.requiresConfirmation].includes(status)) {
          setProceedModalShowed(true);
        }

        if (!status) {
          const userIp = await getIpGlobal();
          dispatch(fetchClientContribution(id, userIp));
        }
      }
    },
    [setProceedModalShowed, dispatch, id, isNotLoggedUser],
  );

  const handleBookModalOpen = useCallback(
    event => {
      setSessionPeriodSelected(Object.assign(sessionPeriodSelected, event));
      setBookModalShowed(true);
    },
    [setBookModalShowed, setSessionPeriodSelected, sessionPeriodSelected],
  );

  const handleBookModalSubmit = useCallback(
    async cod => {
      if (isEmpty(user)) {
        setIsNotLoggedUser(true);
        setPurchaseModalShowed(true);
        return;
      }

      setBookModalShowed(false);

      const { id: availiableId, offset, start, end } = sessionPeriodSelected;
      try {
        let requestParams;
        if (isInviteToJoin) {
          requestParams = {
            contributionId: id,
            AvailabilityTimeId: availiableId,
            offset,
            paymentOption: 'Free',
            accessCode: isInviteToJoin,
            createSingleSession: true,
            priceId: priceId,
          };
        } else if (isCodeToJoin) {
          if (sessionInviteData?.priceOption === 'Credit') {
            requestParams = {
              contributionId: sessionInviteData?.contributionId || contribution.id,
              AvailabilityTimeId: availiableId || sessionInviteData?.availabilityTimeId,
              offset: sessionInviteData?.offset || offset,
              couponId: couponCode,
              isPhoneNumberEnabledSession: contribution.isPhoneNumberAdded,
              isPhoneNumberAdded: contribution.isPhoneNumberAdded,
              isCoachNumberAdded: contribution.isCoachNumberAdded,
              priceId: priceId,
            };
          } else if (availiableId) {
            requestParams = {
              contributionId: sessionInviteData?.contributionId || contribution.id,
              AvailabilityTimeId: availiableId || sessionInviteData?.availabilityTimeId,
              offset: sessionInviteData?.offset || offset,
              paymentOption: sessionInviteData?.priceOption,
              accessCode: sessionInviteData?.accessCode,
              createSingleSession: true,
              couponId: couponCode,
              isPhoneNumberEnabledSession: contribution.isPhoneNumberAdded,
              priceId: priceId,
              isPhoneNumberAdded: contribution.isPhoneNumberAdded,
              isCoachNumberAdded: contribution.isCoachNumberAdded,
            };
          } else {
            requestParams = {
              contributionId: sessionInviteData?.contributionId || contribution.id,
              AvailabilityTimeId: availiableId || sessionInviteData?.availabilityTimeId,
              offset: sessionInviteData?.offset || offset,
              paymentOption: sessionInviteData?.priceOption,
              createSingleSession: true,
              accessCode: sessionInviteData?.accessCode,
              couponId: couponCode,
              isPhoneNumberEnabledSession: contribution.isPhoneNumberAdded,
              priceId: priceId,
              isPhoneNumberAdded: contribution.isPhoneNumberAdded,
              isCoachNumberAdded: contribution.isCoachNumberAdded,
            };
          }
        } else {
          if (paymentInfo.paymentOptions.includes('PerSession')) {
            requestParams = {
              contributionId: id,
              AvailabilityTimeId: availiableId,
              offset,
              isPhoneNumberEnabledSession: contribution.isPhoneNumberAdded,
              isPhoneNumberAdded: contribution.isPhoneNumberAdded,
              isCoachNumberAdded: contribution.isCoachNumberAdded,
              createSingleSession: true,
              priceId: priceId,
            };
          } else {
            requestParams = {
              contributionId: id,
              AvailabilityTimeId: availiableId,
              offset,
              isPhoneNumberEnabledSession: contribution.isPhoneNumberAdded,
              isPhoneNumberAdded: contribution.isPhoneNumberAdded,
              priceId: priceId,
              isCoachNumberAdded: contribution.isCoachNumberAdded,
            };
          }
        }

        const paymentDataResponse = await request('/api/purchase/one-to-one', 'POST', requestParams);
        if (paymentDataResponse === 'Free session joined successfully.') {
          setTimeout(() => {
            dispatch(setSingleCode(null));
            setLoading(false);
            setPurchaseModalShowed(false);
            redirectTo(`/contribution-view/${id}/sessions`);
          }, 3000);

          return;
        } else if (paymentDataResponse.includes('cs_') && paymentDataResponse.includes('checkout.stripe') === false) {
          return stripe.redirectToCheckout({
            sessionId: paymentDataResponse,
          });
        } else if (paymentDataResponse.includes('checkout.stripe')) {
          redirectTo(paymentDataResponse);
        } else if (
          paymentDataResponse?.bookedSessionNumbers ||
          paymentDataResponse?.sessionNumbers ||
          paymentDataResponse?.freeSessionNumbers
        ) {
          // window.location.replace(`/contribution-view/${id}/sessions`);
          // history.push(`/contribution-view/${id}/sessions`);
          if (contribution?.clientRedirectLink) {
            history.push(`/contribution-view/${id}/${contribution?.clientRedirectLink?.toLowerCase()}`);
          } else {
            history.push(`/contribution-view/${id}/sessions`);
          }
          dispatch(setSingleCode(null));
        } else if (typeof paymentDataResponse === 'string') {
          setPaymentData(requestParams);
        } else {
          setPaymentData(paymentDataResponse);
        }
        setBookModalShowed(false);
        setLoading(false);
        // handlePayment();
        handlePayment(paymentDataResponse, paymentDataResponse);
      } catch (e) {
        // window.location.replace(`/contribution-view/${id}/sessions`);
        // history.push(`/contribution-view/${id}/sessions`);
        if (contribution?.clientRedirectLink) {
          history.push(`/contribution-view/${id}/${contribution?.clientRedirectLink?.toLowerCase()}`);
        } else {
          history.push(`/contribution-view/${id}/sessions`);
        }
        if (e?.response?.data.includes('Your are not booked for this session or this session is already booked.')) {
          setErrorModal(true);
        }

        setErrorMessage(e?.response?.data?.message);
        setRefuseModalShowed(true);
      }
      dispatch(setSingleCode(null));
    },
    [user, sessionPeriodSelected, id, request, handlePayment, sessionInviteData],
  );

  const proseedHandlePurchase = useCallback(() => {
    handleBookModalSubmit();
  }, [handleBookModalSubmit]);

  const handleBookModalCancel = useCallback(() => {
    setBookModalShowed(false);
  }, [setBookModalShowed]);

  const handlePurchaseProceedModalCancel = useCallback(
    (releasePaymentIntent = true) => {
      setPurchaseModalShowed(false);
      setProceedModalShowed(false);
      if (isPackage) {
        setIsPackage(false);
      }

      if (!isPackage && paymentData && paymentData.bookedTimeIds && paymentData.bookedTimeIds.length > 0) {
        if (releasePaymentIntent) {
          request('/api/purchase/cancelOneToOneReservation', 'POST', {
            contributionId: id,
            bookedTimeId: paymentData.bookedTimeIds[0],
            created: paymentData.created,
          }).catch(console.dir);
        }
      }
    },
    [id, paymentData, setPurchaseModalShowed, setProceedModalShowed, isPackage, setIsPackage],
  );

  const handlePurchaseProceedModalSubmit = useCallback(() => {
    setPurchaseModalShowed(false);
    setProceedModalShowed(false);
    toggleSuccessPurchaseModal();
    if (isPackage) {
      setIsPackage(false);
    }
    setTimeout(async () => {
      const userIp = await getIpGlobal();
      dispatch(fetchClientContribution(id, userIp));
    }, 1500);
  }, [dispatch, isPackage, id]);

  const handleRefuseModal = useCallback(() => {
    setRefuseModalShowed(false);
  }, [setRefuseModalShowed]);

  const [selectedSessionTime, setSelectedSessionTime] = useState(null);

  const OnlyPackageBookingAllowedModalBody = useMemo(() => {
    return `${title} is available as a package of sessions. Please purchase a package prior to selecting your session time(s).`;
  }, [title]);

  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'));
  const downSm = useMediaQuery(theme.breakpoints.down('sm'));
  const [isSlotModalOpen, setSlotModalShowed] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const closeModalHandler = useCallback(() => {
    setSlotModalShowed(false);
    dispatch(setRescheduleModal(false));
  }, []);
  const onCalendarDrillDownHandler = useCallback(day => {
    const todayDate = moment(new Date()).startOf('day');
    const selectedDate = moment(day).startOf('day');
    const isPastDate = selectedDate.isBefore(todayDate);
    if (!isPastDate) {
      setSelectedDate(moment(day));
      setSlotModalShowed(true);
    }
  }, []);
  const onCalendarEventSelectHandler = useCallback(event => {
    setSelectedDate(moment(moment(event.start)));
    setSlotModalShowed(true);
  }, []);

  const onSelectEvent = useCallback(
    event => {
      const { isBooked, participantId, videoRoomInfo, start, bookedTimeId, offset } = event;
      const availableToReschedule = moment(start).isAfter(moment().add(24, 'h'));
      if (rescheduleMode && availableToReschedule && !isBooked) {
        setRescheduleMode(false);
        setCalendarRescheduleModal(true);
        setSlotModalShowed(false);
        setRescheduleTo(event);
        return;
      }
      if (rescheduleMode && availableToReschedule === false && !isBooked) {
        setRescheduleError(true);
      }
      if (!isBooked && rescheduleMode === false) {
        const isSessionPurchaseAllowed =
          paymentInfo &&
          paymentInfo.paymentOptions &&
          (paymentInfo.paymentOptions.includes('Free') ||
            paymentInfo.paymentOptions.includes('PerSession') ||
            paymentInfo.paymentOptions.includes('MonthlySessionSubscription'));
        const isPackageSessionsAvailable =
          packagePurchases && packagePurchases[0] && packagePurchases[0].freeSessionNumbers;

        if (isSessionPurchaseAllowed || isPackageSessionsAvailable) {
          if (oneToOneType && !isCoach && activeTemplate != TemplateType.TemplateThree) {
            setSessionPeriodSelected(Object.assign(sessionPeriodSelected, event));
            if (isInviteToJoin) {
              setLoading(true);
            } else if (isCodeToJoin) {
              // setLoading(true);
            }

            handleBookModalSubmit(isInviteToJoin ? isInviteToJoin : event);
          } else {
            handleBookModalOpen(event);
          }
        } else {
          setIsOnlyPackageBookingAllowedModalShowed(true);
        }
        setSlotModalShowed(false);
        return;
      }
      const isBookedByMe = participantId === user.id;
      const isLaunched = !!videoRoomInfo && videoRoomInfo.isRunning;
      if (!isBookedByMe || !isLaunched) {
        setRefuseModalShowed(true);

        return;
      }

      setSelectedSessionTime({
        id: bookedTimeId,
        title: event.title,
        start: event.start,
        end: event.end,
        serviceProviderName: event.serviceProviderName,
        offset,
      });
    },
    [paymentInfo, packagePurchases, rescheduleMode],
  );
  const [selectedFilterTab, setSelectedFilterTab] = useState(FILTER_TABS_OPTIONS[0].value);

  const isParticipant = participantsIds?.some(id => id === user.id);
  let headerText =
    availabilityTimes.length > 0 && !isEmpty(user) && isParticipant ? 'Book another session' : 'Single Session';
  headerText = rescheduleMode ? 'Reschedule your session' : headerText;
  const changeInvitedView = () => {
    setShowClientInvitedView(!showClientInvitedView);
  };

  return (
    <div className="main-container-session-8">
      <Grid container spacing={1} direction="row" justifyContent="flex-start">
        <Grid md={12} xs={12} className="">
          {/* <ProgressBar completed={50} bgColor="#116582" /> */}
          {loading && <Loader />}
          <div style={{ display: 'flex', justifyContent: 'space-between' }} className="d-flex">
            <div className="d-flex">
              <TabSelector
                textColor={textColor}
                options={FILTER_TABS_OPTIONS}
                selected={selectedFilterTab}
                onChange={setSelectedFilterTab}
                tabColor={tabColor}
                isCoach={isCoach && !clientPreviewMode}
                activeTemplate={activeTemplate}
                colorToUse={colorToUse}
              />
              {!mobileView && !isEmpty(clientPackage) && (
                // && bookedSessionNumbers < sessionNumbers
                <Tooltip
                  title="The progress bar charts the sessions booked by the client in relation to the count. It's a helpful visual tool to easily track the booked sessions for both coach and clients.  The bar will  continue to show the total booked sessions by the client irrespective of the deleted sessions by the coach."
                  arrow
                  placement="right"
                  enterTouchDelay={TOOLTIP.ENTER_DELAY}
                  leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      position: 'sticky',
                      top: '100px',
                      width: '100%',
                    }}
                  >
                    <StyledSessionRemaining mobileView={mobileView} width={'170px'} clr={colorToUse?.AccentColorCode}>
                      <span style={{ fontFamily: 'Avenir', fontSize: '16px', fontWeight: 800 }}>Sessions Booked</span>
                      <span style={{ marginLeft: '5px', fontFamily: 'Avenir', fontSize: '16px', fontWeight: 800 }}>
                        {`${
                          totalBookedSessionNumbers > totalSessionNumbers
                            ? totalSessionNumbers
                            : totalBookedSessionNumbers
                        }`}
                        /{`${totalSessionNumbers}`}
                      </span>
                    </StyledSessionRemaining>
                    <ProgressBar
                      completed={percentageCompleted}
                      bgColor={colorToUse?.AccentColorCode}
                      height="10px"
                      isLabelVisible={false}
                      width="350px"
                    />
                  </div>
                </Tooltip>
              )}
            </div>
            {oneToOneType && contribution?.isPurchased && CanClinetInviteFrieds(contribution) && (
              <>
                <S.StyledShareButton
                  themedColor={themedColor}
                  className="one-to-one-client-share-button"
                  onClick={() => {
                    setInviteModalOpened(true);
                  }}
                  isDarkModeEnabled={contribution?.isDarkModeEnabled}
                >
                  <S.StyledShareIcon />
                  Invite Friends
                </S.StyledShareButton>
                {InviteModalOpened && (
                  <ShareModalContainer
                    isOpen={InviteModalOpened}
                    onModalClose={() => setInviteModalOpened(false)}
                    paymentInfo={contribution?.paymentInfo}
                    isInvoiced={contribution?.isInvoiced}
                    contributionId={contribution?.id}
                    symbol={contribution?.defaultSymbol}
                    type={contribution.type}
                    IsModalForClient={!isCoach}
                    inviteClientType={contribution?.inviteClientType}
                  />
                )}
              </>
            )}
            {/* {!isEmpty(clientPackage) && ( */}

            {hideBookSessionButton() ? (
              <></>
            ) : (
              <Button
                textColor={textColor}
                backgroundColor={btnColor}
                autoWidth
                onClick={() => {
                  setShowModal(true);
                  setRescheduleSession(true);
                  dispatch(setPreviousFreeSession(freeSessionNumbers));
                  dispatch(setEasyBookingThankYouData.setData({ selectedSlot: null, selectedDate: moment() }));
                  dispatch(setEasyBookingData.setData({ selectedSlot: null, selectedDate: moment() }));
                  dispatch(setRescheduleFormerSessionDetail({}));
                }}
                mobileView={mobileView}
                style={{
                  fontFamily: 'Avenir',
                  fontSize: mobileView ? '10px' : '14px',
                  fontWeight: mobileView ? 600 : 900,
                  lineHeight: '16px',
                  padding: mobileView ? '10px 5px' : '',
                  minWidth: mobileView ? '87px' : '',
                  marginTop: mobileView ? '30px' : '',
                }}
              >
                {'Book Session'}
              </Button>
            )}

            {/* )} */}
          </div>
          {oneToOneType &&
            (!isCoach || clientPreviewMode) &&
            activeTemplate != TemplateType.TemplateThree &&
            mobileView &&
            !isEmpty(clientPackage) &&
            !isEmpty(user) && (
              // bookedSessionNumbers < sessionNumbers &&
              <Tooltip
                title="The progress bar charts the sessions booked by the client in relation to the count. It's a helpful visual tool to easily track the booked sessions for both coach and clients.  The bar will  continue to show the total booked sessions by the client irrespective of the deleted sessions by the coach."
                arrow
                placement="bottom"
                enterTouchDelay={TOOLTIP.ENTER_DELAY}
                leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
              >
                <div
                  style={
                    mobileView
                      ? {
                          display: 'flex',
                          flexDirection: mobileView ? '' : 'column-reverse',
                          justifyContent: 'space-between',
                          alignItems: mobileView ? 'center' : 'flex-end',
                          width: '100%',
                        }
                      : {
                          display: 'flex',
                          flexDirection: mobileView ? '' : 'column-reverse',
                          justifyContent: 'space-between',
                          alignItems: mobileView ? 'center' : 'flex-end',
                          marginLeft: mobileView ? '' : '15px',
                          width: '100%',
                        }
                  }
                >
                  <StyledSessionRemaining mobileView={mobileView} clr={colorToUse?.PrimaryColorCode}>
                    <span style={{ fontFamily: 'Avenir', fontSize: '16px', fontWeight: 800 }}>Sessions Booked</span>
                    <span style={{ marginLeft: '5px', fontFamily: 'Avenir', fontSize: '16px', fontWeight: 800 }}>
                      {`${
                        totalBookedSessionNumbers > totalSessionNumbers
                          ? totalSessionNumbers
                          : totalBookedSessionNumbers
                      }`}
                      /{`${totalSessionNumbers}`}
                    </span>
                  </StyledSessionRemaining>
                  <div style={mobileView ? { width: '100%', marginTop: '10px' } : { width: '100%' }}>
                    <ProgressBar
                      completed={percentageCompleted}
                      bgColor={colorToUse?.PrimaryColorCode}
                      height="10px"
                      isLabelVisible={false}
                      width="100%"
                    />
                  </div>
                </div>
              </Tooltip>
            )}
          {activeTemplate != TemplateType.TemplateThree && (!isCoach || clientPreviewMode) && (
            <div style={{ height: '1px', backgroundColor: 'rgb(223, 227, 228)', marginTop: '20px' }} />
          )}
          {!isEmpty(user) && (
            <SessionsContainer
              contribution={contribution}
              isCoach={isCoach}
              calendarEl={calendarEl}
              executeCalendarScroll={executeScroll}
              setRescheduleMode={setRescheduleMode}
              rescheduleMode={rescheduleMode}
              setCurrentRescheduleSession={setCurrentRescheduleSession}
              serviceProviderName={serviceProviderName}
              completionFilter={selectedFilterTab}
              user={user}
              renderActions={session => {
                const { videoRoomInfo } = session;
                const isLaunched = !!videoRoomInfo && videoRoomInfo.isRunning;
                const isHasRecordings = session.recordingInfos.length > 0;
                return (
                  <>
                    {!session.isCompleted && (
                      <div
                        style={!isCoach && mobileView ? { width: '100%' } : {}}
                        // className="client-course-session-time-actions"
                      >
                        <JoinSessionTime
                          user={user}
                          contribution={contribution}
                          sessionTime={session}
                          isLaunched={isLaunched}
                          mobileView={activeTemplate != TemplateType.TemplateThree && mobileView}
                        />
                      </div>
                    )}
                    {isHasRecordings && (
                      <LaunchRecordedSessionVideo
                        contribution={contribution}
                        session={null}
                        sessionTime={session}
                        mobileView={mobileView}
                        isOntoOneBottomPanel={mobileView}
                      />
                    )}
                  </>
                );
              }}
            />
          )}
          {activeTemplate === TemplateType.TemplateThree && !isCoach && (
            <div
              id="contribution-view-calendar"
              style={{
                pointerEvents: applicationRequiredButNotApproved ? 'none' : '',
                opacity: applicationRequiredButNotApproved ? '0.5' : '1',
              }}
              className="contribution-view-calendar"
              ref={calendarEl}
            >
              <CalendarContributionView
                events={events}
                onDrillDown={onCalendarDrillDownHandler}
                onSelectEvent={onCalendarEventSelectHandler}
                dateToShow={dateToShow}
                calendarRef={calendarRef}
                rescheduleMode={rescheduleMode}
                timeZoneId={contribution.timeZoneId}
                activeTemplate={activeTemplate}
              />
            </div>
          )}
          {isSlotModalOpen && activeTemplate === TemplateType.TemplateThree && !isCoach && (
            <SlotsModal
              isOpen={isSlotModalOpen}
              onClose={closeModalHandler}
              selectedDate={selectedDate}
              contributionId={id}
              contributionTimeZoneId={contribution.timeZoneId}
              title={title}
              serviceProviderName={serviceProviderName}
              duration={duration}
              onSubmit={onSelectEvent}
            />
          )}
          {isBookModalShowed && activeTemplate === TemplateType.TemplateThree && !isCoach && (
            <BookModal
              isOpen={isBookModalShowed}
              handleModalCancel={handleBookModalCancel}
              handleModalSubmit={handleBookModalSubmit}
              sessionInfo={sessionPeriodSelected}
              timeZoneId={contribution.timeZoneId}
            />
          )}

          {isPurchaseModalShowed && activeTemplate === TemplateType.TemplateThree && !isCoach && (
            <PurchaseModal
              isOpen={isPurchaseModalShowed}
              onClose={handlePurchaseProceedModalCancel}
              onSubmit={handlePurchaseProceedModalSubmit}
              isPackage={isPackage}
              oneToOne
              inviteToJoin={isInviteToJoin}
              paymentDataOneToOne={paymentData}
              proseedHandlePurchase={isNotLoggedUser && proseedHandlePurchase}
              submitNow={isSubmitPurchaseModalNow}
              isCouponAvailable={isCouponAvailable}
            />
          )}

          {isProceedModalShowed && activeTemplate === TemplateType.TemplateThree && !isCoach && (
            <ProceedModal
              isOpen={isProceedModalShowed}
              onClose={handlePurchaseProceedModalCancel}
              onSubmit={handlePurchaseProceedModalSubmit}
            />
          )}

          {isRefuseModalShowed && activeTemplate === TemplateType.TemplateThree && !isCoach && (
            <RefuseModal
              isOpen={isRefuseModalShowed}
              onClose={handleRefuseModal}
              onSubmit={handleRefuseModal}
              message={errorMessage}
            />
          )}

          {isOnlyPackageBookingAllowedModalShowed && activeTemplate === TemplateType.TemplateThree && !isCoach && (
            <Modal
              hiddenCancel
              isOpen={isOnlyPackageBookingAllowedModalShowed}
              onCancel={() => setIsOnlyPackageBookingAllowedModalShowed(false)}
              onSubmit={() => setIsOnlyPackageBookingAllowedModalShowed(false)}
              title="Please purchase package"
            >
              {OnlyPackageBookingAllowedModalBody}
            </Modal>
          )}

          {selectedSessionTime && activeTemplate === TemplateType.TemplateThree && !isCoach && (
            <JoinSessionTimeModal
              user={user}
              contribution={contribution}
              sessionTime={selectedSessionTime}
              open={!!selectedSessionTime}
              onOpenChange={open => setSelectedSessionTime(session => (open ? session : null))}
            />
          )}

          {isOpenSuccessPurchaseModal && activeTemplate === TemplateType.TemplateThree && !isCoach && (
            <SuccessfulPurchaseModal
              isOpen={isOpenSuccessPurchaseModal}
              handleOpen={toggleSuccessPurchaseModal}
              handleClose={toggleSuccessPurchaseModal}
            />
          )}
          {isCalendarRescheduleModalOpen && (
            <Modal
              isOpen={isCalendarRescheduleModalOpen}
              onCancel={handleCalendarRescheduleCancel}
              onSubmit={handleCalendarRescheduleSubmit}
              title="Confirm your new session time"
              hiddenCancel
              submitTitle="Confirm Reschedule"
            >
              <BodyText>
                By clicking Confirm, you will be updating the session time with {serviceProviderName}. It’s your
                responsibility to confirm with {serviceProviderName} that this new time also works for them.
              </BodyText>
              <StyledTextField
                placeholder="Optional. What is the reason for rescheduling? This information will be shared with your coach"
                multiline
                rowsMax={6}
                value={messageText}
                onChange={e => setMessageText(e.target.value)}
              />
            </Modal>
          )}
          {calendarRescheduleModalStatus && (
            <Modal
              isOpen={calendarRescheduleModalStatus}
              onCancel={() => {
                setCalendarRescheduleModalStatus(null);
              }}
              onSubmit={() => {
                setCalendarRescheduleModalStatus(null);
              }}
              title="Reschedule status"
              hiddenCancel
              submitTitle="Ok"
            >
              <BodyText>
                Your new session time with {serviceProviderName} on{' '}
                {moment(calendarRescheduleModalStatus?.start).format('LL [at] LT z')}
                {TIMEZONES[user?.timeZoneId]} is confirmed.
              </BodyText>
            </Modal>
          )}
          {errorModal && (
            <Modal
              isOpen={errorModal}
              onCancel={() => {
                setErrorModal(false);
              }}
              onSubmit={() => {
                setErrorModal(false);
              }}
              title=""
              hiddenCancel
              submitTitle="Ok"
            >
              <BodyText style={{ color: contribution?.isDarkModeEnabled ? 'white' : 'black' }}>
                You are not booked for this session or this session is already booked.
              </BodyText>
            </Modal>
          )}
        </Grid>

        {slotExpired && (
          <Modal
            title="Time Slot Expired"
            isOpen={true}
            onCloseText="OK"
            hiddenCancel
            onSubmit={toggleSlotPopup}
            onCancel={toggleSlotPopup}
            disableFooter
          >
            <div style={{ fontSize: '16px', marginTop: '13px', fontWeight: 450, fontFamily: 'Avenir' }}>
              This session is already booked or linked have been expired.
            </div>
          </Modal>
        )}
        {rescheduleError && (
          <Modal
            title="Reschedule Error"
            isOpen={true}
            onCloseText="OK"
            hiddenCancel
            onSubmit={toggleRescheduleSlotPopup}
            onCancel={toggleRescheduleSlotPopup}
            disableFooter
          >
            <div style={{ fontSize: '16px', marginTop: '13px', fontWeight: 450, fontFamily: 'Avenir' }}>
              You can not book a session with less than 24 hours notice. Please choose a time slot that is at least 24
              hours in advance.
            </div>
          </Modal>
        )}
        <Modal
          minWidth={mobileView ? '100%' : ''}
          height={'650px'}
          isOpen={showModal}
          onSubmit={() => {
            setShowModal(false);
          }}
          title={rescheduleSession || sessionReschuling ? 'Book your next session' : ''}
          disableFooter={true}
          onCancel={() => {
            setShowModal(false);
          }}
          noPadding={true}
          isBookingModal={true}
          CrossIconHide={!(rescheduleSession || sessionReschuling)}
        >
          {rescheduleSession ? (
            <EasyBooking
              clientPreviewMode={false}
              isSelfSchedule={false}
              rescheduleSession={sessionReschuling}
              setRescheduleSession={setSessionReschuling}
              isModal={true}
              packageSessionBooking={!sessionReschuling}
              setShowModal={setRescheduleSession}
            />
          ) : sessionReschuling ? (
            <EasyBooking
              clientPreviewMode={false}
              isSelfSchedule={false}
              rescheduleSession={sessionReschuling}
              setRescheduleSession={setSessionReschuling}
              isModal={true}
              packageSessionBooking={!sessionReschuling}
              setShowModal={setRescheduleSession}
            />
          ) : (
            <SessionBookingThankyou
              setRescheduleSession={setSessionReschuling}
              rescheduleSession={sessionReschuling}
              isModal={true}
              handleCancel={() => setShowModal(false)}
            />
          )}
        </Modal>
      </Grid>
    </div>
  );
};

ClientOneToOneSessions.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  contribution: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    availabilityTimes: PropTypes.arrayOf(PropTypes.object),
    durations: PropTypes.arrayOf(PropTypes.number),
    serviceProviderName: PropTypes.string,
    purchaseStatus: PropTypes.string.isRequired,
  }).isRequired,
  rescheduleChosenSession: PropTypes.func.isRequired,
  contributionTimes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const mapStateToProps = ({ contributions }) => ({
  contributionTimes: contributions?.contributionTimes,
  isCustomBrandingColorsActive: contributions?.activeContribution?.isCustomBrandingColorsActive,
  isBrandingColor: contributions?.activeContribution?.brandingColors,
});

const actions = {
  rescheduleChosenSession: rescheduleSessionForClient,
};

export default connect(mapStateToProps, actions)(ClientOneToOneSessions);
