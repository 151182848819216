import { createAction } from 'redux-actions';

export const SET_VIDEO_CHAT_RECORDING_STATUS = 'SET_VIDEO_CHAT_RECORDING_STATUS';

export const startVideoChat = createAction(
  'START_VIDEO_CHAT',
  ({ contributionId, type, sessionId, classId, title, roomId, chatId, token, deleteRoomOnVideoEnd }) => ({
    contributionId,
    type,
    sessionId,
    classId,
    title,
    roomId,
    chatId,
    token,
    deleteRoomOnVideoEnd,
  }),
);

export const endVideoChat = createAction('END_VIDEO_CHAT');

export const addVideoChatCreator = createAction('ADD_VIDEO_CHAT_CREATOR', ({ creatorId }) => ({ creatorId }));

export const setVideoChatRecordingStatus = createAction(SET_VIDEO_CHAT_RECORDING_STATUS);
