import React from 'react';

export const DraggableIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.5">
      <ellipse cx="9.0625" cy="5.07692" rx="2.0625" ry="2.07692" fill="#215C73" />
      <ellipse cx="15.9375" cy="5.07692" rx="2.0625" ry="2.07692" fill="#215C73" />
      <ellipse cx="9.0625" cy="11.9998" rx="2.0625" ry="2.07692" fill="#215C73" />
      <ellipse cx="15.9375" cy="11.9998" rx="2.0625" ry="2.07692" fill="#215C73" />
      <ellipse cx="9.0625" cy="18.9231" rx="2.0625" ry="2.07692" fill="#215C73" />
      <ellipse cx="15.9375" cy="18.9231" rx="2.0625" ry="2.07692" fill="#215C73" />
    </g>
  </svg>
);
