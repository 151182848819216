import styled from 'styled-components';

export const StyledBackground = styled.div`
  background-color: #fff;
  border-radius: 50%;
  padding: 8px;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  line-height: 0;
  ${({ padding }) => padding && `padding: ${padding}`}
`;
