import Modal from 'components/UI/Modal';
import React, { useState } from 'react';
import { InputLabel, MenuItem, Select } from '@material-ui/core';
import PropTypes from 'prop-types';
import useContribution from 'pages/ContributionView/hooks/useContribution';
import { determineColorToUse, getThemedColors } from 'services/contributions.service';
import './TestimonialModal.scss';
import { connect } from 'react-redux';
import styled from 'styled-components';

const StyledSelect = styled.div`
  .Mui-focused {
    .MuiOutlinedInput-notchedOutline {
      border-color: ${({ colorToUse }) => colorToUse.AccentColorCode};
    }
  }
`;
const DEFAULT_SELECETED_TESTIMONIAL = 'Select Prior Testimonial';

function TestimonialTemplateModal({ isOpen, handleClose, handleSubmit, testimonialTemplates = [] }) {
  const [selectedTestimonial, setSelectedTestimonial] = useState(DEFAULT_SELECETED_TESTIMONIAL);
  const [error, setError] = useState('');
  const contribution = useContribution();
  const { themedColor, themedCardBackgroundColor } = getThemedColors(contribution);
  let colorToUse = determineColorToUse(contribution);
  return (
    <Modal
      isOpen={isOpen}
      title="Choose a Prior Testimonial"
      onCancel={handleClose}
      submitTitle="Next"
      cancelTitle="Back"
      className="testimonial-template-modal"
      onSubmit={() => {
        setError(null);
        if (selectedTestimonial !== DEFAULT_SELECETED_TESTIMONIAL) {
          handleSubmit(selectedTestimonial);
        }
        setError('Please select a prior testimonial');
      }}
      applyTheming
      disableOverlayClick
    >
      <InputLabel htmlFor="template-slect">Select Prior Testimonial</InputLabel>
      <StyledSelect colorToUse={colorToUse}>
        <Select
          variant="outlined"
          id="template-select"
          displayEmpty
          fullWidth
          className={selectedTestimonial === DEFAULT_SELECETED_TESTIMONIAL ? 'select-default' : ''}
          MenuProps={{
            PaperProps: {
              style: {
                fontFamily: 'Avenir',
                backgroundColor: themedCardBackgroundColor,
                color: themedColor,
              },
            },
          }}
          value={selectedTestimonial}
          onChange={({ target: { value } }) => {
            setSelectedTestimonial(value);
          }}
        >
          <MenuItem value={DEFAULT_SELECETED_TESTIMONIAL} disabled>
            Select
          </MenuItem>
          {testimonialTemplates &&
            testimonialTemplates?.map(testimonial => {
              return (
                <MenuItem value={testimonial}>
                  {testimonial.name}, {testimonial.contributionName}
                </MenuItem>
              );
            })}
        </Select>
      </StyledSelect>
      {error && <span className="text-danger">{error}</span>}
    </Modal>
  );
}

TestimonialTemplateModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  testimonialTemplates: PropTypes.shape([]).isRequired,
};

const mapStateToProps = ({ testimonials: { testimonialTemplates } }) => ({
  testimonialTemplates,
});

export default connect(mapStateToProps, null)(TestimonialTemplateModal);
