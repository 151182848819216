import { updateMessages, clearMessages, addMessage, updateMessage, removeMessage } from './actions';

const initialMessages = {};

const messagesReducer = (state, { type, payload }) => {
  switch (type) {
    case updateMessages.toString(): {
      const { messages } = payload;

      return messages.reduce((newState, m) => ({ ...newState, [m.sid]: m }), state);
    }
    case clearMessages.toString():
      return { ...initialMessages };
    case addMessage.toString():
    case updateMessage.toString(): {
      const { message } = payload;

      return { ...state, [message.sid]: message };
    }
    case removeMessage.toString(): {
      const { messageSid } = payload;

      const newState = { ...state };
      delete newState[messageSid];

      return newState;
    }
    default:
      return state;
  }
};

export default messagesReducer;
export { initialMessages };
