import styled from 'styled-components';
import stars from '../../../../assets/stars.png';
import Button from 'components/FormUI/Button';
import { useTheme, useMediaQuery } from '@material-ui/core';
import { css } from 'styled-components';

export const TestimonialParagraph = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #282b2b;
  max-width: 44rem;
  margin: 0px;
  padding: 1rem 0rem;
`;
export const TestimonialStarts = styled.img`
  // background-image: url(${stars});
`;

export const CardTopHeadings = styled.div`
  display: flex;
`;

export const PageTitle1 = styled.div`
  color: #6999ca;
  padding: 1rem 0px;
`;

export const StyledPricTxt = styled.h4`
  text-align: center;
  padding: 0.7rem 0rem;
  font-size: 36px;
`;

export const PackegeFeeMainContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0rem 2rem;
`;

export const PackegeLeftText = styled.div``;
export const PackegeRightText = styled.div``;
export const EnrolDateTxt = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  max-width: 44rem;
  padding: 2rem 0rem 0rem 2rem;
`;
export const MonthDate = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  padding: 0rem 2rem;
`;

export const DividerDiv = styled.p`
  border: 1px solid;
  width: 21rem;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
`;

export const CompleteRegistrationTxt = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  padding: 2rem 2rem;
`;

// About Details Block Styles
export const MainDetailsTxt = styled.p`
  // font-family: 'Brandon Text';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 0.7rem;
  display: flex;
  align-items: center;
  letter-spacing: 0.02em;
  color: #282b2b;
`;
export const DetailsMainContainer = styled.div`
  display: flex;
  padding: 1rem 0rem;
`;
export const DetailSideIconDiv = styled.div``;
export const DetailSideIcon = styled.img``;
export const DetailTxtDiv = styled.div`
  padding: 0rem 0rem 0rem 1.5rem;
`;
export const DetailHeading = styled.h4``;
export const DetailParagraph = styled.p`
  max-width: 44rem;
`;

// General info block
export const BorderDiv = styled.div`
  border: ${({ color }) => (color ? `1px solid ${color}` : '1px solid #DBE7F2')};
  opacity: 0.05;
  margin: ${props => props.margin};
`;

export const StyledSideIconMainContainer = styled.div`
  display: flex;
  padding: 1rem 0px;
  justify-content: space-between;
`;
export const StyledSideIconMainContainer2 = styled.div`
  display: flex;
  padding: 1rem 0px;
  justify-content: space-between;
  width: 70%;
  margin: auto;
`;
export const SidebarDiv = styled.div`
  display: flex;
  align-items: flex-start;
`;
export const SideIcon = styled.img``;
export const SideTxt = styled.p`
  font-family: 'Brandon Text';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #282b2b;
  padding: 0px 0px 0px 10px;
`;

export const TestimonialMainContainer = styled.div`
  padding: 3.5rem 0rem 0rem 0rem;
`;
export const TestimonialTopImg = styled.img``;
export const TestimonialInnerMain = styled.div`
  padding: 2rem 0rem 0rem 0rem;
`;
export const TestimonialHeading = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 79px;
  align-items: center;
  letter-spacing: 0.02em;
  color: #282b2b;
`;

export const ProfileTxtLeft = styled.div`
  display: flex;
  ${({ mobileView }) =>
    mobileView &&
    ` justify-content: center;
    flex-direction: column; 
  `}
`;

export const ProfileTxtLeft2 = styled.div`
  display: flex;
  padding: 1rem 0rem;
  align-items: center;
  ${({ mobileView }) =>
    mobileView &&
    css`
      text-align: center;
    `}
`;
export const ProfileLeftImgCont = styled.div``;
export const ProfileRightTxt = styled.div``;
export const ProfileUserName = styled.h4`
  margin: 0px;
  font-style: normal;
  font-weight: 420;
  font-size: 14px;
  line-height: 19px;

  color: #282b2b;
`;

export const ProfileUserLocation = styled.p`
  font-style: normal;
  font-weight: 420;
  font-size: 12px;
  line-height: 17px;
  /* identical to box height */
  color: #282b2b;
  opacity: 0.4;
`;
export const ProfileLeftImg = styled.img`
  width: 3.625rem;
`;

// Meet your coach
export const MeetYourCoachContainer = styled.div``;
export const MeetCoachTxt = styled.h4`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  display: flex;
  align-items: center;
  letter-spacing: 0.02em;
  line-height: 38.4px;
  color: #282b2b;
  padding-bottom: 10px;

  // font-style: normal;
  // font-weight: ${props => (props ? props.fontWeight : '')};
  // font-size: ${props => (props ? props.fontSize : '')};
  // color: ${props => (props ? props.Color : '')};
`;
export const MeetCoachTxt2 = styled.h4`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: ${props => (props.fontWeight ? props.fontWeight : '')};
  font-size: ${props => (props.fontSize ? props.fontSize : '')};
  color: ${props => (props.Color ? props.Color : '')};

  ${({ mobileView }) =>
    mobileView &&
    css`
      font-weight: 600;
      font-size: 32px;
      color: #282c2c;
      text-align: center;
    `}
`;

export const CoachProfileMainContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  /* padding: 2rem 0px; */
  padding-bottom: 1rem;
  ${({ mobileView }) =>
    mobileView &&
    css`
      display: block;
    `};
`;

export const CoachProfileMainContainer2 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 0px;
  ${({ mobileView }) =>
    mobileView &&
    css`
      text-align: center;
      align-items: flex-start;
    `};
`;

// Template 2 Styling
export const Temp2MainContainer = styled.div`
  text-align: center;
`;
export const MainImgDiv = styled.div`
  padding: 1.5rem 0rem;
`;
