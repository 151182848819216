import React, { useCallback, useEffect, useRef, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';
import Select from '@material-ui/core/Select';
import { makeStyles, Tooltip } from '@material-ui/core';
import CommonErrorMessage from 'components/FormUI/CommonErrorMessage';
import { Card as CardUI, CardHeader as CardHeaderUI } from 'components/UI/Card';
import { TemplateType } from 'helpers/constants/templateType';
import * as userActions from 'actions/user';
import CustomEnrollmentBlock from '../CustomEnrollmentBlock';
import WorkshopDatesBlockTemplate from '../WorkshopDatesBlockTemplate';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import SignatureCanvas from 'react-signature-canvas';
import { lighten } from 'polished';
import { dataURLtoFile, getCountryName, getIpGlobal } from 'utils/utils';
import { lightOrDark } from 'utils/utils';
import { PhoneInput } from 'react-international-phone';
import Checkbox from '@material-ui/core/Checkbox';
import SignInWithOtpModal from 'components/Modals/SignInWithOtpModal';
import {
  determineColorToUse,
  getSingleContract,
  getThemedColors,
  setSignedContract,
} from 'services/contributions.service';
import {
  Button,
  Card,
  CardContent,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import * as purchaseService from 'services/purchase.service';
import styled, { css } from 'styled-components';
import { colors } from 'utils/styles';
import { ModalTermsAndConditions } from 'components/Modals/TermsAndConditions';
import Loader from 'components/UI/Loader';
import useContribution from 'pages/ContributionView/hooks/useContribution';
import { darken } from 'polished';
import { formatMoney } from 'utils/datesAndMoney';
import { useDispatch, useSelector } from 'react-redux';
import { errorSelector } from 'selectors/user';
import {
  ACCOUNT_FORM_FIELDS,
  ACCOUNT_FORM_FIELDS_LABELS,
  DISCOUNT_CODE_FIELDS,
  PURCHASE_MODAL_STEPS,
  RESTORE_PASS,
} from 'pages/ContributionView/components/PurchaseModal/PurchaseModal.constants';
import { Link } from 'react-router-dom';
import { fetchTimeZones } from 'actions/timeZone';
import * as countryActions from 'actions/country';
import { If, mapTo } from 'utils/fp';
import Input from 'components/FormUI/Input';
import { FormikScrollToError } from 'components/FormikScrollToError';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import { useAccount, useApplicationStatus, useHttp, useRouter, useClientPreview, useDefaultTimezone } from 'hooks';
import { getPurchaseValidationSchema } from 'utils/validation';
import { joinContribution, saveSignoffData } from 'services/contributions.service';
import {
  fetchClientContribution,
  fetchClientContributionAfterInterval,
  fetchContributionActions,
  setCouponCode,
  setInviteCode,
} from 'actions/contributions';
import {
  completeRegister,
  CONTINUE_REGISTER_FAILURE,
  LOG_IN_FAILURE,
  login,
  REGISTER_FAILURE,
  editProfile,
  getProfile,
  getOTP,
} from 'actions/user';
import { ContributionPurchaseType, ContributionStatus, ContributionType, UserRoles } from 'helpers/constants';
import { useIsEmailExist } from 'utils/useIsEmailExist';
import { CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { PageTitleSecond } from 'components/UI/Text/TextStyles';
import { PAYMENT_OPTIONS, JOIN_CONTRIBUTION_STATUS, ROUTES } from '../../../../constants';
import { useStyles } from './hooks/useStyles';
import '../AboutMainInfoBlock/aboutStyle.css';
import CreditCard from '../PurchaseModal/CreditCardOneToOne';
import CountryModal from 'pages/Dashboard/components/CohealerDashboard/CountrySelectionModal';
import { useShowGettingStartedGuide } from 'components/App/GettingStarted/hooks/useShowGettingStartedGuide';
import Modal from 'components/UI/Modal';
import { redirectToAppIfIsCoachDomain } from 'utils/subdomains';
import CreateCustomWaiver from 'pages/CreateContribution/components/CreateCustomWaiver';
import { updateUser } from 'actions/update-user';
import { editUserProfile } from 'services/user.service';
import { FORM_FIELDS } from 'pages/CreateContribution/Payment/components/Membership/constants';
import useApplyContributionView from 'hooks/useApplyContributionView';
import { redirectTo } from 'services/links';
/* styled components start */
const StyledLink = styled.a`
  color: ${props => (props.color ? props.color : colors.darkOceanBlue)};
  font-weight: 600;
`;
const StyledDiv = styled.span`
  color: ${props => (props.color ? props.color : colors.darkOceanBlue)};
  font-weight: 600;
`;
const StyledLink2 = styled.a`
  color: ${colors.lightBrown};
  margin: 1rem 1.25rem;
  font-size: 0.875rem;
  vertical-align: middle;
  display: inline-block;
`;

const StyledError = styled.div`
  color: ${({ template, PrimaryColor }) =>
    template === TemplateType.TemplateOne
      ? PrimaryColor
        ? PrimaryColor
        : '#6999CA'
      : PrimaryColor
      ? PrimaryColor
      : '#D08ACD'};
  font-size: 14px;
  margin-top: 2px;
  padding-left: 10px;
`;
const StyledPhoneInput = styled(PhoneInput)`
  width: ${({ mobileView }) => (mobileView ? '100%' : '60%')};
  gap: 10px;
  &.phone-error {
    .react-international-phone-input {
      border-color: red;
    }
  }
  .react-international-phone-country-selector {
    .react-international-phone-country-selector-button {
      padding: 10px 6px;
      height: auto;
      border-radius: 10px;
    }
  }
  .react-international-phone-input {
    width: 100%;
    padding: 10px 10px;
    height: auto;
    border-radius: 10px;
    font-size: 14px;
  }
`;
const StyledButton = styled.button`
  position: relative;
  padding: 1rem 1.25rem;
  font-size: 0.875rem;
  border-radius: 5px;
  border: none;
  color: #fff;
  fill: #fff; /* for svg */
  font-weight: 900;
  cursor: pointer;
  text-decoration: none;
  line-height: 1rem;
  letter-spacing: 1.25px;
  min-width: 18rem;
  width: 100%;
  transition: background 0.2s;
  font-family: 'Poppins';

  ${({ marginTop }) =>
    marginTop &&
    css`
      margin-top: 10px;
    `}

  ${({ variant, backgroundColor, activeTemplate, textColor }) => {
    if (backgroundColor) {
      return css`
        background-color: ${backgroundColor};
        color: ${textColor};
      `;
    }
    return activeTemplate === TemplateType.TemplateOne
      ? css`
          background-color: #6999ca;
        `
      : css`
          background-color: #b78aea;
        `;
  }}

  ${({ invert }) =>
    invert &&
    css`
      color: rgba(0, 0, 0, 0.87);
      background-color: white;
      border: 1px solid rgba(0, 0, 0, 0.87);
    `}

  &:hover {
    ${({ variant, backgroundColor, activeTemplate }) => {
      if (backgroundColor) {
        return css`
          background-color: ${darken(0.05, backgroundColor)};
        `;
      }
      return activeTemplate === TemplateType.TemplateOne
        ? css`
            background-color: ${darken(0.05, '#6999CA')};
          `
        : css`
            background-color: ${darken(0.05, '#B78AEA')};
          `;
    }}

    ${({ invert }) =>
      invert &&
      css`
        background-color: ${darken(0.05, 'white')};
      `}
  }

  :disabled {
    background-color: #9b9b9b;
    color: #fff;
    fill: #9b9b9b; /* for svg */
    cursor: not-allowed;
    border: none;
  }

  ${({ mobileView }) =>
    mobileView &&
    `
    font-size: 12px;
    padding: 8px 16px;
    min-width: 8rem;
  `}
`;
const PriceContainer = styled.div`
  background-color: #fafafa;
  padding: 2px 15px;
`;
const ApplyCouponContainer = styled.div`
  background-color: ${({ backgroundColor }) => backgroundColor};
  padding: 15px;
  // margin-top: 20px;
`;

const EnrollmentWrapper = styled.div`
  ${({ userNotLoggedIn }) => (userNotLoggedIn ? 'padding: 5px 5px 5px 25px' : '')}
`;

export const ForgotPasswordLink = styled(Link)`
  color: rgba(0, 0, 0, 0.87);
  font-family: 'Avenir';
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0.4px;
  line-height: 1.17;
  margin-top: 14px;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

export const SendOtpLink = styled(Link)`
  color: ${({ isDarkModeEnabled }) => (isDarkModeEnabled ? '#fff' : '#2b2b2b')};
  font-family: 'Avenir';
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0.4px;
  line-height: 1.17;
  margin-top: 14px;
  margin-left: 5px;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
  @media (max-width: 360px) {
    font-size: 12px;
  }
  @media (max-width: 390px) {
    font-size: 14px;
  }
  @media (max-width: 375px) {
    font-size: 12px;
  }
`;
export const SendOtpLinkContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;
/* styled components end */

const membershipPaymentOptions = [
  PAYMENT_OPTIONS.PER_DAY,
  PAYMENT_OPTIONS.PER_WEEK,
  PAYMENT_OPTIONS.PER_MONTH,
  PAYMENT_OPTIONS.PER_YEAR,
  PAYMENT_OPTIONS.PACKAGE,
];

function getCountrypopup(user) {
  if (user?.countryId === '60b8ddb57205057e7ce2b861' || user?.countryId === '60b8ddb57205057e7ce2b79d') {
    return user?.postalCode ?? null;
  }
  return user?.countryId;
}

function CustomPurchaseBlock(props) {
  // TODO: moved from purchase modal so as not to break the previous functionality
  const { isPackage, onJoin, isCustomBrandingColorsActive, brandingColors } = props;
  const [errorLocal, setErrorLocal] = useState(false);
  const { history, query, params, domain, location } = useRouter();
  const { clientPreviewMode } = useClientPreview();
  const [isShowWaiver, setIsShowWaiver] = useState(false);
  const contribution = useContribution();
  const dispatch = useDispatch();
  const { timeZones, loading: timeZoneLoading } = useSelector(state => state.timeZone);
  const [smsReminderCheckBox, setSmsReminderCheckBox] = useState(true);
  const [showPhoneNumberFields, setshowPhoneNumberFields] = useState(true);
  const [loginStep, setloginStep] = useState(false);
  const [phoneNumberField, setPhoneNumberField] = useState('');
  const [defaultCountry, setDefaultCountry] = useState();
  const [paramsforEditUser, setParamsforEditUser] = useState({
    countryId: null,
    TimeZoneId: null,
    stateCode: null,
  });
  const { states } = useSelector(state => state?.states);
  const { countries, loading: countriesLoading } = useSelector(state => state?.country);
  const {
    newThemedBackgroundColor: themedBackgroundColor,
    newThemedTextColor: themedColor,
    newThemedCardColor,
  } = getThemedColors(contribution, false);

  const activeTemplate = contribution?.activeTemplate || TemplateType.TemplateThree;
  const [ip, setIP] = useState('119.152.140.119');
  const [newUserSignedIn, setNewUserSignedIn] = useState(false);
  const [ipFetchedCountry, setIpFetchedCountry] = useState(null);
  const borderRad = contribution?.activeTemplate === TemplateType.TemplateOne ? '12px' : '5px';
  const formRef = useRef(null);
  const phoneInputRef = useRef(null);
  const textFieldRef = useRef(null);
  const styledProps = {
    cardBackgroundColor: newThemedCardColor,
    color: themedColor,
    backgroundColor: themedBackgroundColor,
  };
  const classNames = useStyles(styledProps);
  const [colorForSelect, setColorForSelect] = useState('darkgrey');
  const [showUnableJoin, setshowUnableJoin] = useState(false);
  const [splittedPrice, setSplittedPrice] = useState(null);
  const [isSigned, setIsSigned] = useState(null);
  const { fetchUserTimezone, fetchUserCountryCode } = useDefaultTimezone();
  const useSelectStyle = makeStyles(() => ({
    select: {
      width: '100%',
      color: colorForSelect,
      backgroundColor: 'white',
      borderRadius: '20px',
      border: '1px solid #f5f0f0',
      height: '53px',
    },
  }));

  const selectStyle = useSelectStyle();
  let colorToUse = determineColorToUse(contribution);
  let textColor =
    colorToUse?.TextColorCode === 'Auto'
      ? lightOrDark(colorToUse?.PrimaryColorCode)
      : colorToUse?.TextColorCode === '#000000'
      ? '#000000'
      : '#FFFFFF';
  const PrimaryColor = colorToUse?.PrimaryColorCode;
  const theme = useTheme();
  const downSm = useMediaQuery(theme.breakpoints.down('sm'));
  const downSmTab = useMediaQuery(theme.breakpoints.down(404));
  const [hide, setHide] = useState(false);
  const [allowpassword, setallowpassword] = useState(false);
  const [coachPhoneNumber, setCoachPhoneNumber] = useState('');
  const [coachPhoneNumberFocused, setCoachPhoneNumberFocused] = useState(false);
  const [phoneInputKey, setPhoneInputKey] = useState(0);
  const [showTerms, setShowTerms] = useState(false);
  const formValues = formRef.current?.values || {};
  const mobileView = useMediaQuery('(max-width:350px)');

  const {
    customToS,
    isPurchased,
    paymentInfo: {
      paymentOptions,
      splitNumbers,
      splitPeriod,
      packageSessionNumbers,
      monthlySessionSubscriptionInfo,
      splittedCost,
      membershipInfo,
      multiplePriceList,
    },
    paymentType,
    enrollment,
    serviceProviderName,
    type,
    status,
    defaultCurrency,
    defaultSymbol,
    title,
    id,
    isElectronicSignatureActive,
    customWaiverTemplateName,
    customWaiverId,
    isInvoiced,
    customTitleForMeetYourCoach,
    isCouponAvailable,
    isPriceHidden,
  } = contribution;

  const ClientPreviewSplitPaymentData = {
    dueNowWithCouponDiscountAmount: splittedCost,
    price: splittedCost * splitNumbers,
  };
  const [singleSession, setsingleSession] = useState(true);
  const [packegeClick, setpackegeClick] = useState(false);
  const [subscription, setsubscription] = useState(false);
  const [dailyStyled, setdailyStyled] = useState(false);
  const [weeklyStyled, setweeklyStyled] = useState(false);
  const [monthlyStyled, setmonthlyStyled] = useState(true);
  const [yearlyStyled, setyearlyStyled] = useState(false);
  const [packagedStyled, setpackagedStyled] = useState(false);
  const [perSessionStyled, setperSessionStyled] = useState(true);
  const [isPhoneNumberRequired, setisPhoneNumberRequired] = useState(false);
  const [sessionPackageStyled, setsessionPackageStyled] = useState(false);
  const [monthlySessionSubscription, SetMonthlySessionSubscription] = useState(false);
  const usereError = useSelector(errorSelector);
  const { user, currentRole } = useAccount();
  const { error: errorMessage } = useSelector(state => state.account);
  const userNotLoggedIn = isEmpty(user);
  const isInviteToJoin = useSelector(state => state.contributions.inviteCode);
  const couponCode = useSelector(state => state?.contributions?.couponCode);
  const onlyFreePaymentOption = paymentOptions?.length === 1 && paymentOptions?.includes(PAYMENT_OPTIONS.FREE);
  const priceId = useSelector(state => state.contributions.priceCode);
  const [timeZoneArray, setTimeZoneArray] = useState([]);
  const [statesArray, setStatesArray] = useState([]);
  const [typeOfPayment, setTypeOfPayment] = useState(
    paymentOptions.includes(PAYMENT_OPTIONS.PER_SESSION)
      ? PAYMENT_OPTIONS.PER_SESSION
      : paymentOptions.filter(p => isInviteToJoin?.length > 0 || p !== PAYMENT_OPTIONS.FREE).sort()[0],
  );
  const {
    applicationRequiredButNotApproved,
    isApplicationNeedsToBeSubmitted,
    isApplicationResponsePending,
    isLoadingApplicationStatus,
  } = useApplicationStatus({ contribution, user });
  const { isApplyContributionView } = useApplyContributionView();
  const showApplyButtonSesttings = isApplyContributionView && applicationRequiredButNotApproved;
  const showApplyButtonSesttingsPending = isApplyContributionView && isApplicationResponsePending;
  const ShowCompleteApplication =
    isApplyContributionView && (applicationRequiredButNotApproved || isApplicationResponsePending);

  const [summary, setSummary] = useState(null);
  const [showErrorPhoneNumber, setShowErrorPhoneNumber] = useState(false);
  const [showThreeFields, setshowThreeFields] = useState(false);
  const summaryRef = useRef();
  const canvasRef = useRef();
  const [signatureVal, setSignatureVal] = useState(null);
  const countrypopup = getCountrypopup(user);

  const [disableBtn, setDisableBtn] = useState(true);
  const [isOpenPopUp, setIsOpenPopUp] = useState(false);
  const [waiverData, setWaiverData] = useState(null);
  const [isOpenPasswordPopUp, setIsOpenPasswordPopUp] = useState(user?.isPasswordNotCreated);

  const [showOtpModal, setShowOtpModal] = useState(false);
  const closeModal = () => setShowOtpModal(false);
  const [step, setStep] = useState(() => {
    if (clientPreviewMode) {
      return PURCHASE_MODAL_STEPS.init;
    }
    if (!isEmpty(user) && !query?.email && !query?.phone_number) {
      return PURCHASE_MODAL_STEPS.loggedIn;
    }
    if (!isNil(isInviteToJoin)) {
      return PURCHASE_MODAL_STEPS.join;
    }

    return PURCHASE_MODAL_STEPS.init;
  });
  const handleShowOtpPopup = () => {
    setShowOtpModal(true);
    const email = formRef?.current?.values[ACCOUNT_FORM_FIELDS.email].trim();
    getOTP(email)(dispatch);
  };

  const saveSignature = async val => {
    let returnval = false;
    const formData = new FormData();
    const sign = dataURLtoFile(val);
    formData.append('file', sign);
    const userIP = await getIpGlobal();
    await saveSignoffData(formData, id, userIP)
      .then(res => {
        if (res === 'Signoff data succesfully saved.') {
          returnval = true;
        }
      })
      .catch(err => {});
    return returnval;
  };

  const clearSign = () => {
    if (canvasRef?.current && formRef?.current) {
      canvasRef.current.clear();
      setDisableBtn(true);
      formRef.current.setFieldValue(null);
    }
  };

  summaryRef.current = summary;
  const [isMonthlySessionSubscription, setIsMonthlySessionSubscription] = useState(false); // TODO: to be defined

  /* PRICE SECTION START */
  const { request, loading } = useHttp();
  const [couponValue, setCouponValue] = useState('');
  const [error, setError] = useState(null);
  const isCoach = currentRole == 'Cohealer';

  const computeSecondaryColors = () => {
    if (isCustomBrandingColorsActive) {
      return brandingColors?.AccentColorCode;
    } else if (activeTemplate === TemplateType.TemplateOne) {
      return '#6999CA';
    } else {
      return '#D08ACD';
    }
  };
  useEffect(() => {
    if (paymentOptions.includes(PAYMENT_OPTIONS.SPLIT_PAYMENTS) && paymentOptions.length === 1) {
      setpackagedStyled(false);
      setmonthlyStyled(false);
      setweeklyStyled(false);
      setdailyStyled(false);
      setyearlyStyled(false);
      setsingleSession(false);
      setpackegeClick(true);
      setsubscription(false);
      setTypeOfPayment(PAYMENT_OPTIONS.SPLIT_PAYMENTS);
    }
  }, [paymentOptions]);

  useEffect(() => {
    if (user?.phoneNo?.length > 0 && !isEmpty(user)) {
      setPhoneNumberField(user?.phoneNo);
    }
  }, [user]);

  const getSummary = useCallback(
    coupon => {
      let paymentType = `${isPackage ? 'SessionsPackage' : typeOfPayment}`;
      paymentType = `${isMonthlySessionSubscription ? 'MonthlySessionSubscription' : paymentType}`;

      const REQUEST_MAPPING = {
        [ContributionType.contributionOneToOne]: purchaseService.getOnToOnePaymentInfo,
        [ContributionType.contributionCourse]: purchaseService.getCoursePaymentInfo,
        [ContributionType.contributionMembership]: purchaseService.getMembershipPaymentInfo,
        [ContributionType.contributionCommunity]: purchaseService.getCommunityPaymentInfo,
      };

      const getPaymentData = REQUEST_MAPPING[contribution.type];
      const paymentFetch =
        paymentType == 'EntireCourse' ||
        paymentType == 'SplitPayments' ||
        paymentType == 'PerSession' ||
        paymentType == 'SessionsPackage' ||
        paymentType == 'MonthlyMembership' ||
        paymentType == 'YearlyMembership'
          ? priceId?.includes('p_')
            ? priceId
            : null
          : coupon?.id;
      if (coupon) {
        getPaymentData(contribution.id, paymentType, paymentFetch, coupon?.id)
          .then(data => {
            setSummary({ ...data, coupon });
          })
          .catch(responseError => {
            setError(responseError);
          });
      } else {
        getPaymentData(contribution.id, paymentType, paymentFetch, null)
          .then(data => {
            setSummary({ ...data, coupon });
          })
          .catch(responseError => {
            setError(responseError);
          });
      }
    },
    [contribution.id, contribution.type, isMonthlySessionSubscription, isPackage, typeOfPayment],
  );

  const handleOnChangeCoupon = useCallback(
    event => {
      const { value } = event.target;

      setCouponValue(value.trim());
    },
    [setCouponValue],
  );

  const handleRedeem = useCallback(() => {
    if (couponValue.length > 0 && !loading) {
      const endpoint = `/Coupons/ValidateByName/${couponValue}/${contribution.id}/${typeOfPayment}`;

      request(endpoint, 'GET')
        .then(response => {
          dispatch(setCouponCode(response.id));
          if (response?.percentAmount > 0 || response?.discountAmount > 0) {
            getSummary(response);
          }
        })
        .catch(setError);
    }
  }, [contribution.id, couponValue, getSummary, loading, request, typeOfPayment]);

  const setDefaultCountryCode = async () => {
    const countryCode = await fetchUserCountryCode(formValues[ACCOUNT_FORM_FIELDS.timeZoneId], timeZones, countries);
    setDefaultCountry(countryCode);
  };

  useEffect(() => {
    if (timeZones?.length > 0 && countries?.length > 0) setDefaultCountryCode();
  }, [formValues[ACCOUNT_FORM_FIELDS.timeZoneId], timeZones, countries]);

  const fetchAndSetTimezone = async () => {
    if (timeZones?.length > 0 && !formRef?.current?.values?.TimeZoneId && contribution) {
      const finalTimezone = await fetchUserTimezone(user?.timeZone, contribution?.timeZoneId, timeZones);
      formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.timeZoneId, finalTimezone);
    }
  };

  useEffect(() => {
    fetchAndSetTimezone();
  }, [timeZones, formRef.current, contribution]);

  const applyFocusEventOnPhonInput = () => {
    if (phoneInputRef.current) {
      const phoneInput = phoneInputRef.current.querySelector('.react-international-phone-input');
      if (phoneInput) {
        phoneInput.addEventListener('focus', () => {
          setCoachPhoneNumberFocused(true);
        });
      }
      return phoneInput;
    }
    return null;
  };

  useEffect(() => {
    if (phoneInputRef.current && !phoneNumberField) {
      const phoneInput = applyFocusEventOnPhonInput();
      return () => {
        if (phoneInput) {
          phoneInput.removeEventListener('focus', () => {
            setCoachPhoneNumberFocused(false);
            setCoachPhoneNumber('');
          });
        }
      };
    }
    if (phoneNumberField) {
      setCoachPhoneNumberFocused(true);
      setCoachPhoneNumber(phoneNumberField);
    }
  }, [phoneInputRef.current, showPhoneNumberFields, smsReminderCheckBox, step]);

  useEffect(() => {
    setPhoneInputKey(phoneInputKey + 1);
  }, [defaultCountry]);

  useEffect(() => {
    applyFocusEventOnPhonInput();
  }, [phoneInputKey]);

  useEffect(() => {
    if (coachPhoneNumberFocused && phoneInputRef.current) {
      const phoneInput = phoneInputRef.current.querySelector('.react-international-phone-input');
      if (phoneInput) {
        phoneInput.focus();
      }
    }
  }, [coachPhoneNumberFocused]);

  useEffect(() => {
    if (phoneInputRef.current && !phoneNumberField) {
      const phoneInput = phoneInputRef.current.querySelector('.react-international-phone-input');
      if (phoneInput) {
        phoneInput.addEventListener('focus', () => {
          setCoachPhoneNumberFocused(true);
        });
      }
      return () => {
        if (phoneInput) {
          phoneInput.removeEventListener('focus', () => {
            setCoachPhoneNumberFocused(false);
            setCoachPhoneNumber('');
          });
        }
      };
    }
    if (phoneNumberField) {
      setCoachPhoneNumberFocused(true);
      setCoachPhoneNumber(phoneNumberField);
    }
  }, [phoneInputRef.current, showPhoneNumberFields, smsReminderCheckBox, step]);

  useEffect(() => {
    if (coachPhoneNumberFocused && phoneInputRef.current) {
      const phoneInput = phoneInputRef.current.querySelector('.react-international-phone-input');
      if (phoneInput) {
        phoneInput.focus();
      }
    }
  }, [coachPhoneNumberFocused]);

  useEffect(() => {
    setError(null);
    getSummary();
  }, [getSummary, typeOfPayment]);
  useEffect(() => {
    if (isEmpty(user) === false) {
      if (contribution.paymentType === 'Simple' && contribution.taxType != 'No' && isInviteToJoin === null) {
        checkProceedStatus();
      }
    }
    if (!isEmpty(user)) {
      if (contribution?.smsPermissionsPerContribution?.SessionReminder === true) {
        checkPhoneNumber();
      } else {
        setshowPhoneNumberFields(false);
      }
    } else {
      if (contribution?.smsPermissionsPerContribution?.SessionReminder === true) {
        setshowPhoneNumberFields(true);
      } else {
        setshowPhoneNumberFields(false);
      }
    }
  }, []);

  // useEffect(() => {

  //   if (contribution.paymentType === 'Simple' && contribution.taxType != 'No' && isInviteToJoin === null) {

  //     let filteredObjects = [];
  //     if (user?.user?.countryId != undefined) {
  //       formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.country, user.user.countryId);
  //       formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.timeZoneId, user.user.timeZoneId);
  //       formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.state, user.user.stateCode);
  //       filteredObjects = states.filter(obj => obj.countryId === user.user.countryId);
  //     } else {
  //       formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.country, user.countryId);
  //       formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.timeZoneId, user.timeZoneId);
  //       formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.state, user.stateCode);
  //       filteredObjects = states.filter(obj => obj.countryId === user.countryId);
  //     }

  //     if (filteredObjects.length > 0) {
  //       setStatesArray(filteredObjects);
  //     } else {
  //       setTimeZoneArray(timeZones);
  //     }
  //     if (
  //       formRef.current.values.countryId != '60b8ddb57205057e7ce2b861' ||
  //       user.countryId != '60b8ddb57205057e7ce2b861'
  //     ) {
  //       const filteredTimezone = timeZones.filter(obj => obj.countryId === user.countryId);
  //       if (filteredTimezone.length > 0) {
  //         setTimeZoneArray(filteredTimezone);
  //         if (formRef?.current?.values?.TimeZoneId === null ?? formRef?.current?.values?.TimeZoneId.length === 0) {
  //           formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.timeZoneId, filteredTimezone[0]?.name);
  //         }
  //       } else {
  //         setTimeZoneArray(timeZones);
  //         if (formRef?.current?.values?.TimeZoneId === null ?? formRef?.current?.values?.TimeZoneId.length === 0) {
  //           formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.timeZoneId, timeZones[1]?.name);
  //         }
  //       }
  //     } else {
  //       setTimeZoneArray(timeZones);
  //     }
  //   }

  // }, [user, states, timeZones]);

  /* PRICE SECTION END */

  const [lastStep, setLastStep] = useState(step);

  const [loginErrorMessage, setLoginErrorMessage] = useState('');
  const isShowInput = (currentStep, input) => {
    const visible = {
      init: [ACCOUNT_FORM_FIELDS.email],
      join: [ACCOUNT_FORM_FIELDS.email],
      loggedIn: [
        ACCOUNT_FORM_FIELDS.signature,
        ACCOUNT_FORM_FIELDS.phone,
        ACCOUNT_FORM_FIELDS.sessionReminder,
        // ACCOUNT_FORM_FIELDS.timeZoneId,
        // ACCOUNT_FORM_FIELDS.country,
        // ACCOUNT_FORM_FIELDS.state,
      ],
      joinLogin:
        contribution.paymentType === 'Simple' && contribution.taxType != 'No' && isInviteToJoin === null
          ? [
              ACCOUNT_FORM_FIELDS.email,
              ACCOUNT_FORM_FIELDS.password,
              ACCOUNT_FORM_FIELDS.signature,
              RESTORE_PASS,
              ACCOUNT_FORM_FIELDS.phone,
              ACCOUNT_FORM_FIELDS.sessionReminder,
              ACCOUNT_FORM_FIELDS.timeZoneId,
              ACCOUNT_FORM_FIELDS.country,
              ACCOUNT_FORM_FIELDS.state,
            ]
          : [
              ACCOUNT_FORM_FIELDS.email,
              ACCOUNT_FORM_FIELDS.password,
              ACCOUNT_FORM_FIELDS.signature,
              RESTORE_PASS,
              ACCOUNT_FORM_FIELDS.phone,
              ACCOUNT_FORM_FIELDS.sessionReminder,
              // ACCOUNT_FORM_FIELDS.timeZoneId,
              // ACCOUNT_FORM_FIELDS.country,
              // ACCOUNT_FORM_FIELDS.state,
            ],
      login: [
        ACCOUNT_FORM_FIELDS.email,
        ACCOUNT_FORM_FIELDS.password,
        ACCOUNT_FORM_FIELDS.signature,
        RESTORE_PASS,
        ACCOUNT_FORM_FIELDS.phone,
      ],
      joinCreate: [
        ACCOUNT_FORM_FIELDS.email,
        ACCOUNT_FORM_FIELDS.confirmEmail,
        // ACCOUNT_FORM_FIELDS.password,
        ACCOUNT_FORM_FIELDS.birthDate,
        ACCOUNT_FORM_FIELDS.firstName,
        ACCOUNT_FORM_FIELDS.lastName,
        ACCOUNT_FORM_FIELDS.timeZoneId,
        ACCOUNT_FORM_FIELDS.country,
        ACCOUNT_FORM_FIELDS.state,
        ACCOUNT_FORM_FIELDS.signature,
        ACCOUNT_FORM_FIELDS.phone,
        ACCOUNT_FORM_FIELDS.sessionReminder,
      ],
      create: [
        ACCOUNT_FORM_FIELDS.email,
        ACCOUNT_FORM_FIELDS.confirmEmail,
        // ACCOUNT_FORM_FIELDS.password,
        ACCOUNT_FORM_FIELDS.birthDate,
        ACCOUNT_FORM_FIELDS.firstName,
        ACCOUNT_FORM_FIELDS.lastName,
        ACCOUNT_FORM_FIELDS.timeZoneId,
        ACCOUNT_FORM_FIELDS.country,
        ACCOUNT_FORM_FIELDS.state,
        ACCOUNT_FORM_FIELDS.signature,
        ACCOUNT_FORM_FIELDS.phone,
        ACCOUNT_FORM_FIELDS.sessionReminder,
      ],
    };

    return (isEmpty(user) || clientPreviewMode) && visible[currentStep]?.includes(input);
  };
  const confirmEmailRef = useRef(null);
  const emailRef = useRef(null);

  useEffect(() => {
    const disablePaste = e => {
      e.preventDefault();
    };

    if ((!timeZones || !timeZones.length) && !timeZoneLoading) {
      dispatch(fetchTimeZones());
    }
    if (!countries || (!countries?.length && !countriesLoading)) {
      dispatch(countryActions.fetchCountries());
    }
    if (!states || !states.length) {
      dispatch(countryActions.fetchStates());
    }
    const confirmEmailInput = confirmEmailRef.current;
    if (!confirmEmailInput) return;

    confirmEmailInput.addEventListener('paste', disablePaste);
    return () => {
      confirmEmailInput.removeEventListener('paste', disablePaste);
    };
  }, [step, timeZones, timeZoneLoading]);

  const { checkEmail, isLoadingEmail } = useIsEmailExist();
  const handleProceedPurchaseStatus = useCallback(async () => {
    // const action = await fetchClientContribution(id)(dispatch);
    const userIP = await getIpGlobal();
    await dispatch(fetchClientContribution(id, userIP, priceId)).then(() => {
      dispatch(
        fetchContributionActions.success({
          ...contribution,
          isPurchased: true,
          purchaseStatus: 'succeeded',
          subscriptionStatus: { status: 'active' },
        }),
      );
    });
    dispatch(setInviteCode(null));
    if (contribution?.clientRedirectLink) {
      history.push(`/contribution-view/${id}/${contribution?.clientRedirectLink?.toLowerCase()}`);
    } else {
      history.push(`/contribution-view/${id}/sessions`);
    }
    // history.push(`/contribution-view/${id}/sessions`);
    setTimeout(() => {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    }, 1000);
    if (!loading) document.body.scrollTop = document.documentElement.scrollTop = 0;
  }, [dispatch, history, id, loading]);

  const stepVerifier = useCallback(
    async values => {
      let { Signature, phoneNo, countryId, stateCode, TimeZoneId } = values;
      if (isSigned !== null) {
        Signature = isSigned;
      }
      if (!isEmpty(user)) {
        // if (contribution.paymentType === 'Simple' && contribution.taxType != 'No' && isInviteToJoin === null) {
        //   formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.country, user?.countryId);
        //   formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.timeZoneId, user?.timeZoneId);
        //   formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.state, user?.stateCode);
        // }
        if (user?.id === contribution?.userId) {
          history.push(ROUTES.ROLE_SWITCH, { path: location.pathname, domain: domain });
          // setshowUnableJoin(true);
          return false;
        }
        if (contribution?.type === ContributionType.contributionOneToOne) {
          setLastStep(step);
          setStep(PURCHASE_MODAL_STEPS.loggedIn);
          return false;
        }
        if (isElectronicSignatureActive && Signature && step === PURCHASE_MODAL_STEPS.loggedIn) {
          const signSaved = await isSignSaved(Signature);
          if (signSaved) {
            return true;
          } else {
            return false;
          }
        }
        return true;
      }

      if (step === PURCHASE_MODAL_STEPS.create) {
        setNewUserSignedIn(true);
        const returnedRegisterAction = await completeRegister({
          ...values,
          userView: UserRoles.client,
          PhoneNo: values.phoneNo,
          countryId,
          stateCode,
          TimeZoneId,
        })(dispatch);
        if (
          returnedRegisterAction?.type !== REGISTER_FAILURE &&
          returnedRegisterAction?.type !== CONTINUE_REGISTER_FAILURE
        ) {
          if (isElectronicSignatureActive && Signature) {
            const signSaved = await isSignSaved(Signature);
            if (signSaved && !isInvoiced) {
              return formRef.current?.handleSubmit();
            } else {
              return false;
            }
          }
          if (!isInvoiced) {
            return formRef.current?.handleSubmit();
          }
        }
        return false;
      }
      if (step === PURCHASE_MODAL_STEPS.joinCreate) {
        setNewUserSignedIn(true);
        dispatch({
          type: 'PURCHASE_DUMMY',
          payload: false,
        });
        const returnedRegisterAction = await completeRegister({
          ...values,
          userView: UserRoles.client,
          PhoneNo: values.phoneNo,
          countryId,
          stateCode,
          TimeZoneId,
        })(dispatch);
        if (
          returnedRegisterAction?.type !== REGISTER_FAILURE &&
          returnedRegisterAction?.type !== CONTINUE_REGISTER_FAILURE
        ) {
          if (customWaiverId != null && isElectronicSignatureActive) {
            getWaiverForm();
          } else if (isElectronicSignatureActive && Signature) {
            const signSaved = await isSignSaved(Signature);
            if (signSaved) {
              joinContribution({
                id,
                accessCode: isInviteToJoin,
              })
                .then(() => {
                  dispatch(
                    fetchContributionActions.success({
                      ...contribution,
                      isPurchased: true,
                      purchaseStatus: 'succeeded',
                      subscriptionStatus: { status: 'active' },
                    }),
                  );
                  dispatch({
                    type: 'PURCHASE_DUMMY',
                    payload: true,
                  });
                })
                .then(() => {
                  onJoin(true);
                });
            } else {
              return false;
            }
          }
          if (customWaiverId === null && isElectronicSignatureActive === false) {
            joinContribution({
              id,
              accessCode: isInviteToJoin,
            })
              .then(() => {
                dispatch(
                  fetchContributionActions.success({
                    ...contribution,
                    isPurchased: true,
                    purchaseStatus: 'succeeded',
                    subscriptionStatus: { status: 'active' },
                  }),
                );
                dispatch({
                  type: 'PURCHASE_DUMMY',
                  payload: true,
                });
              })
              .then(() => {
                onJoin(true);
              });
          }
        }
        return false;
      }
      if (step === PURCHASE_MODAL_STEPS.login) {
        const { Email, Password, phoneNo, otp } = values;
        const returnedLoginAction = await login(
          Email,
          Password,
          null,
          null,
          phoneNo,
          null,
          null,
          contribution?.userId,
          otp,
        )(dispatch);
        if (returnedLoginAction?.type !== LOG_IN_FAILURE) {
          // we shouldn't redirect in the login process, redirect only after purchasing
          // the return url for successful and unsuccessful payment is set in the api when creating stripe checkout
          // await handleProceedPurchaseStatus();
          if (isElectronicSignatureActive && Signature) {
            const signSaved = await isSignSaved(Signature);
            if (signSaved) {
              return formRef.current?.handleSubmit();
            } else {
              return false;
            }
          }

          return formRef.current?.handleSubmit();
        } else {
          const { payload } = returnedLoginAction || {};

          setLoginErrorMessage(payload?.message);
        }
        return false;
      }
      if (step === PURCHASE_MODAL_STEPS.joinLogin) {
        const { Email, Password, phoneNo, otp } = values;
        const returnedLoginAction = await login(
          Email,
          Password,
          null,
          null,
          phoneNo,
          true,
          null,
          contribution?.userId,
          otp,
        )(dispatch);

        if (returnedLoginAction?.type !== LOG_IN_FAILURE) {
          if (returnedLoginAction.user?.id === contribution?.userId) {
            history.push(ROUTES.ROLE_SWITCH, { path: location.pathname, domain: domain });
            // setshowUnableJoin(true);
            return false;
          } else {
            if (isElectronicSignatureActive && Signature) {
              const signSaved = await isSignSaved(Signature);
              if (signSaved) {
                joinContribution({
                  id,
                  accessCode: isInviteToJoin,
                })
                  .then(() => {
                    dispatch(
                      fetchContributionActions.success({
                        ...contribution,
                        isPurchased: true,
                        purchaseStatus: 'succeeded',
                        subscriptionStatus: { status: 'active' },
                      }),
                    );
                  })
                  .then(() => {
                    handleProceedPurchaseStatus();
                    return formRef.current?.handleSubmit();
                  });
              } else {
                return false;
              }
            }
          }
          if (customWaiverId != null && isElectronicSignatureActive) {
            if (step != PURCHASE_MODAL_STEPS.init && step != PURCHASE_MODAL_STEPS.join) {
              if (formRef.current.isValid) {
                getWaiverForm();
              }
            } else {
              stepVerifier(formRef.current.values);
            }
          } else {
            if (
              contribution?.smsPermissionsPerContribution?.SessionReminder === false ||
              contribution?.smsPermissionsPerContribution?.SessionReminder === undefined ||
              isPhoneNumberRequired === false
            ) {
              joinContribution({
                id,
                accessCode: isInviteToJoin,
              })
                .then(() => {
                  dispatch(
                    fetchContributionActions.success({
                      ...contribution,
                      isPurchased: true,
                      purchaseStatus: 'succeeded',
                      subscriptionStatus: { status: 'active' },
                    }),
                  );
                })
                .then(() => {
                  handleProceedPurchaseStatus();
                  return formRef.current?.handleSubmit();
                });
            } else {
              const phoneNumberAdded = checkPhoneNumber();
              if (!phoneNumberAdded) {
                setshowPhoneNumberFields(true);
                setLoadingPayment(false);
                return;
              }
              joinContribution({
                id,
                accessCode: isInviteToJoin,
              })
                .then(() => {
                  dispatch(
                    fetchContributionActions.success({
                      ...contribution,
                      isPurchased: true,
                      purchaseStatus: 'succeeded',
                      subscriptionStatus: { status: 'active' },
                    }),
                  );
                })
                .then(() => {
                  handleProceedPurchaseStatus();
                  return formRef.current?.handleSubmit();
                });
            }
          }
        }
        return false;
      }

      const isExistEmail = await checkEmail(values[ACCOUNT_FORM_FIELDS.email].trim());
      setisPhoneNumberRequired(isExistEmail.phNumberRequired);
      setallowpassword(isExistEmail);

      if (isExistEmail) {
        if (contribution?.smsPermissionsPerContribution?.SessionReminder === true) {
          setshowPhoneNumberFields(false);
        }
        if (
          isExistEmail.phNumberRequired === true &&
          contribution?.smsPermissionsPerContribution?.SessionReminder === true
        ) {
          setshowPhoneNumberFields(true);
        }
        if (isInviteToJoin) {
          setLastStep(step);
          setStep(PURCHASE_MODAL_STEPS.joinLogin);
        } else {
          setLastStep(step);
          setStep(PURCHASE_MODAL_STEPS.login);
        }
      } else if (isInviteToJoin) {
        setLastStep(step);
        setStep(PURCHASE_MODAL_STEPS.joinCreate);
      } else {
        setLastStep(step);
        setStep(PURCHASE_MODAL_STEPS.create);
      }
      return false;
    },
    [
      user,
      step,
      checkEmail,
      isInviteToJoin,
      dispatch,
      id,
      query.code,
      contribution,
      onJoin,
      handleProceedPurchaseStatus,
      showPhoneNumberFields,
    ],
  );

  const handlePaste = e => {
    e.preventDefault();
  };

  const isSignSaved = async signature => {
    let isSignedSubmitted = null;
    if (contribution?.customWaiverId != null) {
      isSignedSubmitted = await submitUserSignature(signature);
    } else {
      isSignedSubmitted = await saveSignature(signature);
    }
    return isSignedSubmitted;
  };

  const stripe = useStripe();
  const elements = useElements();
  const [isAlreadyPurchased, setIsAlreadyPurchased] = useState(false);
  const [loadingPayment, setLoadingPayment] = useState(false);
  const [purchaseSessionId, setPurchaseSessionId] = useState('');
  const [isInvoicePaid, setIsInvoicePaid] = useState(false);

  useEffect(() => {
    if (query?.email?.length > 0 && query?.id === '651c3048a97dbd45826376cb') {
      checkStep();
    }
  }, []);

  useEffect(() => {
    const sessionsRefresher = setInterval(async () => {
      if (!clientPreviewMode) {
        const userIP = await getIpGlobal();
        dispatch(fetchClientContributionAfterInterval(id, userIP, priceId));
      }
      setIsInvoicePaid(isPurchased);
    }, 10000);
    return () => {
      clearInterval(sessionsRefresher);
    };
  }, [isInvoicePaid, dispatch, id]);
  // buy contribution after login/signup check
  useEffect(() => {
    if (!loadingPayment && !isPurchased && purchaseSessionId) {
      const userHasAccessCode = isInviteToJoin;
      if (userHasAccessCode) {
        if (user?.id === contribution?.userId) {
          history.push(ROUTES.ROLE_SWITCH, { path: location.pathname, domain: domain });
          // setshowUnableJoin(true);
          return false;
        } else {
          joinContribution({
            id,
            accessCode: isInviteToJoin,
          }).then(() => {
            dispatch(
              fetchContributionActions.success({
                ...contribution,
                isPurchased: true,
                purchaseStatus: 'succeeded',
                subscriptionStatus: { status: 'active' },
              }),
            );
            onJoin(true);
          });
        }
      } else {
        if (paymentType === 'Advance' && !isInvoiced) {
          window.location.href = purchaseSessionId;
        } else if (isInvoiced) {
          if (contribution?.clientRedirectLink) {
            history.push(`/contribution-view/${id}/${contribution?.clientRedirectLink?.toLowerCase()}`);
          } else {
            history.push(`/contribution-view/${id}/sessions`);
          }
          // history.push(`/contribution-view/${id}/sessions`);
        } else {
          setLoadingPayment(true);
          return stripe
            .redirectToCheckout({
              sessionId: purchaseSessionId,
            })
            .catch(responseError => {
              console.dir(responseError);

              if (responseError?.response?.status === 400) {
                if (isInviteToJoin || query.code) {
                  window.location.reload();
                } else {
                  setIsAlreadyPurchased(true);
                }
              }
            })
            .finally(() => {
              setLoadingPayment(false);
              window.location.reload();
            });
        }
      }
    }

    // redirect to sessions tab if accessCode and isPurchased
    if (!loadingPayment && isPurchased && purchaseSessionId && isInviteToJoin) {
      setLoadingPayment(true);
      onJoin(true);
    }
  }, [isInviteToJoin, isPurchased, loadingPayment, onJoin, purchaseSessionId, query.code, stripe]);

  const getWaiverForm = async () => {
    setLoadingPayment(true);
    try {
      getSingleContract(contribution?.id).then(data => {
        setWaiverData({
          ...waiverData,
          formName: data?.formName,
          formDescription: data?.formDescription,
          formText: data?.formText,
          description: data?.formDescription,
          templateId: data.id,
          id: data.id,
          completeDescription: data?.formText,
        });
        setIsShowWaiver(true);
        setLoadingPayment(false);
      });
    } catch (e) {
      setLoadingPayment(false);
    }
    setLoadingPayment(false);
  };

  const saveUserSignature = async values => {
    setLoadingPayment(true);
    const formData = new FormData();
    const sign = dataURLtoFile(values.clientSignature);
    setWaiverData({
      ...waiverData,
      clientName: values.clientName,
    });
    formData.append('file', sign);
    setIsSigned(true);
    setIsShowWaiver(false);
    setLoadingPayment(false);
    submitUserSignature(values.clientSignature);
  };

  const submitUserSignature = async val => {
    let isSignedSubmitted = false;
    setLoadingPayment(true);
    const formData = new FormData();
    const sign = dataURLtoFile(val);
    formData.append('file', sign);
    const userIP = await getIpGlobal();
    const details = {
      contributionId: contribution.id,
      contractId: waiverData.id,
      ipAddress: userIP,
    };
    try {
      setSignedContract(formData, details);
      isSignedSubmitted = true;
    } catch (e) {
      isSignedSubmitted = false;
    }
    setIsShowWaiver(false);
    setLoadingPayment(false);
    return isSignedSubmitted;
  };

  const prepareEntire = useCallback(
    async data => {
      setLoadingPayment(true);
      const userHasAccessCode = isInviteToJoin;
      let checkOutResult;
      if (userHasAccessCode === null) {
        if (!isInvoiced) {
          checkOutResult = await request(
            '/api/purchase/course/checkout',
            'POST',
            location?.search?.length === 0
              ? data
              : { ...data, affiliateTrackingLinkId: location?.search?.substring(1) },
          );
        } else if (isInvoiced && countrypopup != null) {
          checkOutResult = await request('/api/purchase/course/invoice', 'POST', data);
          if (checkOutResult != 'Country is not saved.') {
            setIsOpenPopUp(true);
          }
        }
      }
      try {
        if (userHasAccessCode) {
          if (user?.id === contribution?.userId) {
            setshowUnableJoin(true);
            return false;
          } else {
            await joinContribution({
              id,
              accessCode: isInviteToJoin,
            }).then(() => {
              dispatch(
                fetchContributionActions.success({
                  ...contribution,
                  isPurchased: true,
                  purchaseStatus: 'succeeded',
                  subscriptionStatus: { status: 'active' },
                }),
              );
              onJoin(true);
            });
          }
        } else if (checkOutResult === '100discount' || checkOutResult === 'Free session joined successfully') {
          if (contribution?.thankYouPageRedirectUrl?.length > 0) {
            // commented since it can be blocked on mobile that treats it like a pop up
            // window.open(contribution.thankYouPageRedirectUrl);
            window.location.href = contribution.thankYouPageRedirectUrl;
          } else {
            fetchJoindedContribution();
            // setTimeout(() => {
            //   window.location.reload();
            // }, 1000);
          }
        } else {
          if (!isInvoiced) {
            setPurchaseSessionId(checkOutResult);
          }
        }
      } catch (checkoutError) {
        console.dir(checkoutError);
        setError(checkoutError);
      } finally {
        setLoadingPayment(false);
      }
    },
    [contribution, dispatch, id, isInviteToJoin, onJoin, query.code, request, user, paramsforEditUser],
  );
  const handlePayResponse = useCallback(
    async response => {
      setLoadingPayment(false);

      if (response.error) {
        setError(response.error.message);
      } else {
        const userIP = await getIpGlobal();
        dispatch(fetchClientContribution(id, userIP, priceId));
        dispatch(setInviteCode(null));
        history.push({
          pathname: `/contribution-view/${id}/sessions`,
        });
        setTimeout(() => window.scrollTo(0, 0), 1000);
      }
    },
    [dispatch, id],
  );
  const payWith3DSecure = useCallback(
    (...args) => {
      setLoadingPayment(true);

      stripe
        .confirmCardPayment(...args)
        .then(handlePayResponse)
        .catch(console.dir);
    },
    [stripe, handlePayResponse],
  );
  const subscribe = useCallback(
    ({ paymentMethodId }) => {
      const data = {
        paymentMethodId,
        paymentOptions: isMonthlySessionSubscription ? 'MonthlySessionSubscription' : 'SplitPayments',
        paymentOption: isMonthlySessionSubscription ? 'MonthlySessionSubscription' : 'SplitPayments',
        contributionId: contribution.id,
        couponId: summaryRef?.current?.coupon?.id,
      };

      const purchaseType = ContributionPurchaseType.course;
      const paymentOption = isMonthlySessionSubscription ? '/monthly-session-subscription' : '';
      const paymentUrl = `/api/purchase/${purchaseType}${paymentOption}`;

      request(paymentUrl, 'POST', data)
        .then(res => {
          if (isMonthlySessionSubscription) {
            payWith3DSecure(res.clientSecret);
          }
        })
        .catch(console.dir);
    },
    [request, contribution.id, payWith3DSecure, isMonthlySessionSubscription],
  );
  const attachPaymentMethodByToken = useCallback(
    cardToken => {
      request('/api/payment/attach-customer-payment-method-token', 'POST', {
        cardToken,
        contributionId: contribution.id,
      })
        .then(subscribe)
        .catch(console.dir);
    },
    [request, subscribe],
  );

  const fetchJoindedContribution = () => {
    dispatch(
      fetchContributionActions.success({
        ...contribution,
        isPurchased: true,
        purchaseStatus: 'succeeded',
        subscriptionStatus: { status: 'active' },
      }),
    );
    onJoin(true);
  };
  const createTokenForSplitPayments = useCallback(() => {
    const card = elements.getElement(CardNumberElement);
    stripe.createToken(card).then(res => {
      if (!res.error) {
        attachPaymentMethodByToken(res.token.id);
      }
    });
  }, [stripe, elements, attachPaymentMethodByToken]);
  function isCountryNameAvailable(countryName, arrayOfObjects) {
    return arrayOfObjects.some(obj => obj.countryName === countryName);
  }
  const checkCountry = async values => {
    const res = await fetch('https://geolocation-db.com/json/');
    const data = await res.json();
    const currentCountry = countries.find(obj => obj.name === data.country_name);
    // const currentCountry = { id: '60b8ddb57205057e7ce2b861' };

    if (currentCountry.id != formRef.current.values.countryId) {
      if (currentCountry.id === '60b8ddb57205057e7ce2b861') {
        const filteredStates = states.filter(obj => obj.countryId === formRef.current.values.countryId);
        formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.country, currentCountry.id);
        setStatesArray(filteredStates);
        setTimeZoneArray(timeZones);
        if (
          formRef?.current?.values?.TimeZoneId?.length > 0 &&
          formRef?.current?.values?.TimeZoneId != null &&
          formRef?.current?.values?.stateCode?.length > 0 &&
          formRef?.current?.values?.stateCode != null
        ) {
          formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.timeZoneId, values.TimeZoneId);
          formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.state, values.stateCode);
        } else {
          return false;
        }
        return true;
      } else {
        const filteredTimezone = timeZones.filter(obj => obj?.countryId === currentCountry.id);
        if (filteredTimezone.length === 1) {
          formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.country, currentCountry.id);
          formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.timeZoneId, filteredTimezone[0].countryName);
          formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.state, null);
          setTimeZoneArray(filteredTimezone);
          setLoadingPayment(false);
          setParamsforEditUser({
            countryId: formRef.current.values.countryId,
            TimeZoneId: filteredTimezone[0].countryName,
            stateCode: null,
          });
          return true;
        } else if (filteredTimezone.length > 1) {
          formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.country, currentCountry.id);
          formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.state, null);
          setTimeZoneArray(filteredTimezone);
          setParamsforEditUser({ countryId: currentCountry.id, TimeZoneId: null, stateCode: null });
          setLoadingPayment(false);
          if (formRef?.current?.values?.TimeZoneId?.length > 0 && formRef?.current?.values?.TimeZoneId != null) {
            let checkTimeZone = isCountryNameAvailable(formRef.current.values.TimeZoneId, filteredTimezone);
            if (checkTimeZone) {
              formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.timeZoneId, formRef.current.values?.TimeZoneId);
              return true;
            } else if (values.TimeZoneId.length > 0 && values.TimeZoneId != null) {
              let checkTimeZoneValue = isCountryNameAvailable(values.TimeZoneId, filteredTimezone);
              if (checkTimeZoneValue) {
                formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.timeZoneId, values.TimeZoneId);
                return true;
              } else {
                formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.timeZoneId, null);
                return false;
              }
            } else {
              formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.timeZoneId, null);
              return false;
            }
          } else {
            return false;
          }
          return true;
        } else if (filteredTimezone.length === 0) {
          formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.country, currentCountry.id);
          formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.state, null);
          setTimeZoneArray(timeZones);
          setParamsforEditUser({ countryId: currentCountry.id, TimeZoneId: null, stateCode: null });
          setLoadingPayment(false);
          if (formRef?.current?.values?.TimeZoneId?.length > 0 && formRef?.current?.values?.TimeZoneId != null) {
            formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.timeZoneId, formRef.current.values.TimeZoneId);
          } else {
            return false;
          }
          return true;
        }
      }
    } else {
      if (formRef.current.values.countryId === '60b8ddb57205057e7ce2b861') {
        formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.country, formRef.current.values.countryId);
        const filteredStates = states.filter(obj => obj.countryId === formRef.current.values.countryId);
        setStatesArray(filteredStates);
        if (formRef?.current?.values?.stateCode?.length > 0 && formRef?.current?.values?.stateCode != null) {
          formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.state, formRef.current.values.stateCode);
        } else {
          setLoadingPayment(false);
          return false;
        }
        if (formRef?.current?.values?.TimeZoneId?.length > 0 && formRef?.current?.values?.TimeZoneId != null) {
          formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.timeZoneId, formRef.current.values.TimeZoneId);
        } else {
          setLoadingPayment(false);
          return false;
        }
        return true;
      } else {
        const filteredTimezone = timeZones.filter(obj => obj?.countryId === formRef.current.values.countryId);
        if (filteredTimezone.length === 1) {
          formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.country, formRef.current.values?.countryId);
          formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.timeZoneId, filteredTimezone[0]?.countryName);
          formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.state, null);
          setTimeZoneArray(filteredTimezone);
          setLoadingPayment(false);
          setParamsforEditUser({
            countryId: formRef.current.values.countryId,
            TimeZoneId: filteredTimezone[0].countryName,
            stateCode: null,
          });
          return true;
        } else if (filteredTimezone.length > 1) {
          formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.country, formRef.current.values?.countryId);
          formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.state, null);
          setTimeZoneArray(filteredTimezone);
          setParamsforEditUser({ countryId: values?.countryId, TimeZoneId: null, stateCode: null });
          setLoadingPayment(false);

          if (formRef.current.values.TimeZoneId.length > 0 && formRef.current.values.TimeZoneId != null) {
            let checkTimeZone = isCountryNameAvailable(formRef.current.values.TimeZoneId, filteredTimezone);
            if (checkTimeZone) {
              formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.timeZoneId, formRef.current.values?.TimeZoneId);
              return true;
            } else if (values.TimeZoneId.length > 0 && values.TimeZoneId != null) {
              let checkTimeZoneValue = isCountryNameAvailable(values.TimeZoneId, filteredTimezone);
              if (checkTimeZoneValue) {
                formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.timeZoneId, values.TimeZoneId);
                return true;
              } else {
                formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.timeZoneId, null);
                return false;
              }
            } else {
              formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.timeZoneId, null);
              return false;
            }
          } else {
            return false;
          }
          return true;
        } else if (filteredTimezone.length === 0) {
          formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.country, currentCountry.id);
          formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.state, null);
          setTimeZoneArray(timeZones);
          setParamsforEditUser({ countryId: currentCountry.id, TimeZoneId: null, stateCode: null });
          setLoadingPayment(false);
          if (formRef?.current?.values?.TimeZoneId?.length > 0 && formRef?.current?.values?.TimeZoneId != null) {
            formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.timeZoneId, formRef.current.values.TimeZoneId);
          } else {
            return false;
          }
          return true;
        }
      }
    }
  };

  const checkPhoneNumber = () => {
    if (!isEmpty(user)) {
      if (showErrorPhoneNumber === false && isPhoneNumberRequired) {
        if (smsReminderCheckBox === true) {
          if (phoneNumberField.length < 9) {
            setshowPhoneNumberFields(true);
            setShowErrorPhoneNumber(true);
            return false;
          } else {
            updatePhoneNumber();
            setshowPhoneNumberFields(false);
            return true;
          }
        }
        if (!isEmpty(user.globalSmsPermissions) && user.globalSmsPermissions.SessionReminder === true) {
          if (
            !isEmpty(user.subCategorySmsPermissions) &&
            (user.subCategorySmsPermissions.OneHourSession === true ||
              user.subCategorySmsPermissions.TwentyFourHourSession === true)
          ) {
            if (step === 'login' && loginStep === false) {
              if (smsReminderCheckBox === false) {
                return true;
              }
              setshowPhoneNumberFields(true);
              setloginStep(true);
              return false;
            }
            if (smsReminderCheckBox === true) {
              if (user?.phoneNo === null || user.phoneNo?.length === 0) {
                if (phoneNumberField.length < 9) {
                  setshowPhoneNumberFields(true);
                  setShowErrorPhoneNumber(true);
                  return false;
                } else {
                  if (user?.phoneNo != phoneNumberField) {
                    updatePhoneNumber();
                  }
                  setshowPhoneNumberFields(false);
                  return true;
                }
              } else {
                if (user?.phoneNo != phoneNumberField) {
                  if (phoneNumberField.length < 9) {
                    setShowErrorPhoneNumber(true);
                    return false;
                  } else {
                    updatePhoneNumber();
                  }
                }
                setshowPhoneNumberFields(false);
                return true;
              }
            } else {
              return true;
            }
          } else {
            setshowPhoneNumberFields(false);
            return true;
          }
        } else {
          setshowPhoneNumberFields(false);
          return true;
        }
        return true;
      } else {
        if (showErrorPhoneNumber === false && isPhoneNumberRequired === false && phoneNumberField.length != 0) {
          return true;
        }
        if (!isEmpty(user.globalSmsPermissions) && user.globalSmsPermissions.SessionReminder === true) {
          if (
            !isEmpty(user.subCategorySmsPermissions) &&
            (user.subCategorySmsPermissions.OneHourSession === true ||
              user.subCategorySmsPermissions.TwentyFourHourSession === true)
          ) {
            if (step === 'login' && loginStep === false) {
              setshowPhoneNumberFields(true);
              setloginStep(true);
              return false;
            }
            if (smsReminderCheckBox === true) {
              if (user?.phoneNo === null || user.phoneNo?.length === 0) {
                if (phoneNumberField.length < 9) {
                  setshowPhoneNumberFields(true);
                  setShowErrorPhoneNumber(true);
                  return false;
                } else {
                  if (user?.phoneNo != phoneNumberField) {
                    updatePhoneNumber();
                  }
                  setshowPhoneNumberFields(false);
                  return true;
                }
              } else {
                if (user?.phoneNo != phoneNumberField) {
                  if (phoneNumberField.length === 0) {
                    setShowErrorPhoneNumber(true);
                    return false;
                  } else {
                    updatePhoneNumber();
                  }
                }
                setshowPhoneNumberFields(false);
                return true;
              }
            } else {
              return true;
            }
          } else {
            setshowPhoneNumberFields(false);
            return true;
          }
        } else {
          setshowPhoneNumberFields(false);
          return true;
        }
      }
    } else if (isEmpty(user)) {
      if (showErrorPhoneNumber === false && isPhoneNumberRequired === false && phoneNumberField.length != 0) {
        return true;
      } else {
        if (smsReminderCheckBox === false && formRef.current.values.phoneNo.length === 0) {
          setshowPhoneNumberFields(true);
          setShowErrorPhoneNumber(true);
          return true;
        }
        if (formRef.current.values.phoneNo.length === 0) {
          setshowPhoneNumberFields(true);
          setShowErrorPhoneNumber(true);
          return false;
        } else {
          if (user?.phoneNo != phoneNumberField) {
            updatePhoneNumber();
          }
          setshowPhoneNumberFields(false);
          return true;
        }
      }
    } else {
      if (contribution?.smsPermissionsPerContribution?.SessionReminder === true) {
        setshowPhoneNumberFields(true);
      } else {
        setshowPhoneNumberFields(false);
      }

      return true;
    }
  };
  const updatePhoneNumber = async () => {
    await request('/Account/UpdatePhoneNo', 'POST', {
      phoneNumber: phoneNumberField || formRef.current.values.phoneNo,
    }).then(res => {
      // let u = {
      //   ...user,
      //   phoneNo: phoneNumberField || formRef.current.values.phoneNo,
      // };
      // dispatch(updateUser(u));
    });
  };
  const checkCountryField = async () => {
    const res = await fetch('https://geolocation-db.com/json/');
    const data = await res.json();
    const currentCountry = countries.find(obj => obj.name === data.country_name);
    const filteredTimezone = timeZones.filter(obj => obj?.countryId === currentCountry?.id);
    const filteredObjects = states.filter(obj => obj.countryId === currentCountry?.id);
    setStatesArray(filteredObjects);
    if (formRef.current) {
      formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.country, currentCountry?.id);
    }
    if (filteredTimezone.length === 1) {
      setshowThreeFields(false);
    } else {
      if (currentCountry?.id === user.countryId && filteredTimezone.length === 1) {
        setshowThreeFields(false);
      } else {
        setshowThreeFields(true);
      }
    }
  };

  const checkProceedStatus = async () => {
    if (!isEmpty(user)) {
      if (formRef.current) {
        formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.country, user.countryId);
        formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.timeZoneId, user.timeZoneId);
        formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.state, user.stateCode);
      }
      const res = await fetch('https://geolocation-db.com/json/');
      const data = await res.json();
      const currentCountry = countries.find(obj => obj.name === data.country_name);
      const filteredTimezone = timeZones.filter(obj => obj?.countryId === currentCountry?.id);
      const filteredObjects = states.filter(obj => obj.countryId === currentCountry?.id);
      setStatesArray(filteredObjects);
      if (formRef.current) {
        formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.country, currentCountry?.id);
      }
      if (filteredTimezone.length === 1) {
        setshowThreeFields(false);
      } else {
        if (currentCountry?.id === user.countryId && filteredTimezone.length === 1) {
          setshowThreeFields(false);
        } else {
          setshowThreeFields(true);
        }
      }
    }
  };
  const updateUserCall = async () => {
    const updatedUser = {
      ...user,
      id: user.id,
      stateCode: formRef.current.values.stateCode,
      timeZoneId: formRef.current.values.TimeZoneId,
      countryId: formRef.current.values.countryId,
    };
    await editUserProfile(user.id, updatedUser).then(async res => {
      let u = {
        ...user,
        countryId: res.user.countryId,
        timeZoneId: res.user.timeZoneId,
        stateCode: res.user.stateCode,
      };
      dispatch(updateUser(u));
    });
  };
  const handleSubmit = useCallback(
    async values => {
      if (isApplyContributionView && applicationRequiredButNotApproved) {
        const code = isInviteToJoin || priceId;
        redirectTo(`/application-form/${id}${code ? `/${code}` : ''}`);
        return;
      }
      setLoadingPayment(true);
      formRef.current.setTouched({});
      const isCanProceed = await stepVerifier(values);
      if (!isCanProceed) {
        setLoadingPayment(false);
        return;
      }
      if (contribution?.smsPermissionsPerContribution?.SessionReminder === true && isPhoneNumberRequired === true) {
        const phoneNumberAdded = checkPhoneNumber();
        if (!phoneNumberAdded) {
          setshowPhoneNumberFields(true);
          setLoadingPayment(false);
          return;
        }
      }

      if (
        contribution.paymentType === 'Simple' &&
        contribution.taxType != 'No' &&
        isInviteToJoin === null &&
        newUserSignedIn === false &&
        (contribution.paymentInfo.paymentOptions.length === 1 &&
          contribution.paymentInfo.paymentOptions.includes('Free')) === false
      ) {
        const isCheckCountry = await checkCountry(values);
        if (!isCheckCountry) {
          setLoadingPayment(false);
          return;
        } else {
          await updateUserCall();
        }
      }

      if (customWaiverId != null && isElectronicSignatureActive) {
        if (step != PURCHASE_MODAL_STEPS.init && step != PURCHASE_MODAL_STEPS.join) {
          if (formRef.current.isValid) {
            getWaiverForm();
          }
        } else {
          stepVerifier(formRef.current.values);
        }
      } else {
        if (membershipPaymentOptions.includes(typeOfPayment)) {
          let data;
          if (location?.search?.length === 0) {
            data = {
              paymentOption: typeOfPayment,
              contributionId: id,
              priceId: priceId,
              couponId: summaryRef?.current?.coupon?.id,
            };
          } else {
            data = {
              paymentOption: typeOfPayment,
              contributionId: id,
              couponId: summaryRef?.current?.coupon?.id,
              priceId: priceId,
              affiliateTrackingLinkId: location?.search?.substring(1),
            };
          }

          const response =
            type === ContributionType.contributionCommunity
              ? await purchaseService.purchaseCommunitySubscription(data)
              : await purchaseService.purchaseMembershipSubscription(data);
          setTimeout(() => {
            setLoadingPayment(false);
          }, 4000);
          if (response === '100discount' || response === 'Free session joined successfully') {
            // quick solution:redirect; todo: update state with purchased contribution and hide purchase modal
            fetchJoindedContribution();
          } else {
            setPurchaseSessionId(response);
          }
          return;
        }
        If(
          typeOfPayment === 'EntireCourse' ||
            typeOfPayment === 'SplitPayments' ||
            type === ContributionType.contributionCourse ||
            type === ContributionType.contributionMembership ||
            type === ContributionType.contributionCommunity ||
            (type === ContributionType.contributionOneToOne && typeOfPayment === 'Free'),
        )
          .then(
            mapTo({
              contributionId: contribution.id,
              paymentOption: typeOfPayment,
              priceId: priceId,
              couponId: summaryRef?.current?.coupon?.id,
            }),
          )
          .then(prepareEntire)
          .else(createTokenForSplitPayments);
      }
    },
    [
      id,
      stepVerifier,
      typeOfPayment,
      contribution.id,
      prepareEntire,
      createTokenForSplitPayments,
      // payEntireCourse,
      paramsforEditUser,
      checkCountry,
      showPhoneNumberFields,
      isApplyContributionView,
      applicationRequiredButNotApproved,
    ],
  );

  const goToPrevStep = () => {
    setStep(lastStep);
  };
  const shouldHidePriceSection = () => {
    if (priceId?.includes('p_') === true) {
      setHide(false);
    } else if (isInviteToJoin) {
      setHide(true);
    } else if (isPriceHidden) {
      setHide(true);
    }
  };

  // const shouldHidePriceSection =
  //   contribution.type === ContributionType.contributionCourse && (query?.priceId.includes('p_') || query?.priceId)
  //     ? false
  //     : isInviteToJoin || query?.code;
  const currencyCode = defaultCurrency.toUpperCase();
  const disableJoinBtn =
    disableBtn &&
    isElectronicSignatureActive &&
    contribution.customWaiverId === null &&
    ((contribution.customWaiverId === null && isShowInput(step, ACCOUNT_FORM_FIELDS.signature)) ||
      step === PURCHASE_MODAL_STEPS.loggedIn);

  const AgreementText = useMemo(
    () =>
      isElectronicSignatureActive &&
      (isShowInput(step, ACCOUNT_FORM_FIELDS.signature) || step == PURCHASE_MODAL_STEPS.loggedIn)
        ? 'signing and clicking'
        : 'clicking',
    [step, isElectronicSignatureActive],
  );
  useEffect(() => {
    shouldHidePriceSection();
  }, []);

  useEffect(() => {
    if (query?.email?.length > 0 && query?.phone_number?.length > 0) {
      checkStep();
    }
  }, []);
  useEffect(() => {
    if (!isEmpty(user)) {
      checkCountryField();
    } else {
      setshowThreeFields(true);
    }
  }, [user, countries, timeZones]);
  useEffect(() => {
    if (textFieldRef.current) {
      textFieldRef.current.focus();
    }
  }, [textFieldRef.current, phoneInputRef.current, showPhoneNumberFields, smsReminderCheckBox, step]);

  let joinButtonText = `Join ${hide ? 'Now' : ''}`;

  if (!isCoach && applicationRequiredButNotApproved) {
    joinButtonText = 'Apply Before Joining';
  }

  const radeemClass =
    activeTemplate === TemplateType.TemplateOne
      ? classNames.redeemButtonTemp1
      : activeTemplate === TemplateType.TemplateTwo
      ? classNames.redeemButtonTemp2
      : classNames.redeemButton;
  const checkStep = async () => {
    const isExistEmail = await checkEmail(formRef?.current?.values[ACCOUNT_FORM_FIELDS.email].trim());
    setallowpassword(isExistEmail);

    if (isExistEmail) {
      if (isInviteToJoin) {
        setLastStep(step);
        setStep(PURCHASE_MODAL_STEPS.joinLogin);
      } else {
        setLastStep(step);
        setStep(PURCHASE_MODAL_STEPS.login);
      }
    } else if (isInviteToJoin) {
      setLastStep(step);
      setStep(PURCHASE_MODAL_STEPS.joinCreate);
    } else {
      setLastStep(step);
      setStep(PURCHASE_MODAL_STEPS.create);
    }
  };
  const getTypographyContent = () => {
    if (contribution?.id === '659443532dcb0f40762628d1') {
      return 'Enter Your Email to Access';
    } else if (id === '66968fb214bf732c19083465') {
      if (showApplyButtonSesttingsPending) {
        return ShowCompleteApplication
          ? 'Complete Your Application'
          : `Complete Your ${hide ? 'Free' : ''} Registration`;
      } else if (showApplyButtonSesttings) {
        return ShowCompleteApplication ? '' : `Complete Your ${hide ? 'Free' : ''} Registration`;
      } else {
        return ShowCompleteApplication
          ? 'Complete Your Application'
          : `Complete Your ${hide ? 'Free' : ''} Registration`;
      }
    } else {
      return `Complete Your ${hide ? 'Free' : ''} Registration`;
    }
  };

  const typographyContent = getTypographyContent();
  return (
    <Grid
      id="paymentCard"
      style={{ backgroundColor: themedBackgroundColor, marginTop: 35 }}
      className={classNames.wrapper}
      container
    >
      <Grid item md={12} xs={12}>
        <CardUI className={classNames.customSectionWrapper}>
          {!hide && (
            <CardHeaderUI
              CardBgCol="green"
              style={{
                backgroundColor: lighten(0.1, colorToUse?.PrimaryColorCode),
                // backgroundColor: `${colorToUse?.AccentColorCode}`,
                borderRadius: '10px 10px 0px 0px',
                padding: '0px',
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              {countrypopup == null && !userNotLoggedIn && isInvoiced ? (
                <CountryModal isOpen={true} forInvoiced={true} />
              ) : null}
              {/* Create password modal */}

              {countrypopup != null && isOpenPopUp && (
                <Modal
                  title="Pay the Invoice to get Access"
                  isOpen={isOpenPopUp}
                  submitTitle="Close"
                  hiddenCancel
                  onCancel={() => {
                    setIsOpenPopUp(false);
                  }}
                  dontCancelOnSideClick
                  onSubmit={() => {
                    setIsOpenPopUp(false);
                  }}
                  style={{ zIndex: '2000' }}
                >
                  <p>
                    An Invoice has been sent to your email. Please pay your invoice to get access to your contribution.
                  </p>
                </Modal>
              )}

              {paymentOptions.includes(PAYMENT_OPTIONS.ENTIRE_COURSE) &&
              showApplyButtonSesttingsPending &&
              id === '66968fb214bf732c19083465' ? (
                <div
                  style={{
                    color: textColor,
                    backgroundColor: packegeClick ? '' : colorToUse?.PrimaryColorCode,
                    borderRadius: '10px 10px 0px 0px',
                    fontSize: '12px',
                    textTransform: 'capitalize',
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                    fontFamily: 'Poppins',
                    padding: singleSession ? '12px 0px' : '12px',
                    fontWeight: 'bold',
                    flex: 1,
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: downSmTab ? '3.7rem' : '',
                  }}
                  // className={singleSession ? 'tabsStyle' : ''}
                  className={
                    singleSession && activeTemplate == TemplateType.TemplateOne
                      ? 'tabsStyle'
                      : singleSession && activeTemplate == TemplateType.TemplateTwo
                      ? 'tabsStyleTem2'
                      : ''
                  }
                  onClick={() => {
                    setpackagedStyled(false);
                    setmonthlyStyled(false);
                    setweeklyStyled(false);
                    setdailyStyled(false);
                    setyearlyStyled(false);
                    setsingleSession(true);
                    setpackegeClick(false);
                    setsubscription(false);
                    setTypeOfPayment(PAYMENT_OPTIONS.ENTIRE_COURSE);
                  }}
                >
                  Pay in Full
                </div>
              ) : paymentOptions.includes(PAYMENT_OPTIONS.ENTIRE_COURSE) &&
                showApplyButtonSesttings &&
                id === '66968fb214bf732c19083465' ? (
                <div
                  style={{
                    color: textColor,
                    backgroundColor: packegeClick ? '' : colorToUse?.PrimaryColorCode,
                    borderRadius: '10px 10px 0px 0px',
                    fontSize: '12px',
                    textTransform: 'capitalize',
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                    fontFamily: 'Poppins',
                    padding: singleSession ? '12px 0px' : '12px',
                    fontWeight: 'bold',
                    flex: 1,
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: downSmTab ? '3.7rem' : '',
                  }}
                  // className={singleSession ? 'tabsStyle' : ''}
                  className={
                    singleSession && activeTemplate == TemplateType.TemplateOne
                      ? 'tabsStyle'
                      : singleSession && activeTemplate == TemplateType.TemplateTwo
                      ? 'tabsStyleTem2'
                      : ''
                  }
                  onClick={() => {
                    setpackagedStyled(false);
                    setmonthlyStyled(false);
                    setweeklyStyled(false);
                    setdailyStyled(false);
                    setyearlyStyled(false);
                    setsingleSession(true);
                    setpackegeClick(false);
                    setsubscription(false);
                    setTypeOfPayment(PAYMENT_OPTIONS.ENTIRE_COURSE);
                  }}
                >
                  Complete Your Application
                </div>
              ) : (
                paymentOptions.includes(PAYMENT_OPTIONS.ENTIRE_COURSE) && (
                  <div
                    style={{
                      color: textColor,
                      backgroundColor: packegeClick ? '' : colorToUse?.PrimaryColorCode,
                      borderRadius: '10px 10px 0px 0px',
                      fontSize: '12px',
                      textTransform: 'capitalize',
                      display: 'flex',
                      alignItems: 'center',
                      cursor: 'pointer',
                      fontFamily: 'Poppins',
                      padding: singleSession ? '12px 0px' : '12px',
                      fontWeight: 'bold',
                      flex: 1,
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: downSmTab ? '3.7rem' : '',
                    }}
                    // className={singleSession ? 'tabsStyle' : ''}
                    className={
                      singleSession && activeTemplate == TemplateType.TemplateOne
                        ? 'tabsStyle'
                        : singleSession && activeTemplate == TemplateType.TemplateTwo
                        ? 'tabsStyleTem2'
                        : ''
                    }
                    onClick={() => {
                      setpackagedStyled(false);
                      setmonthlyStyled(false);
                      setweeklyStyled(false);
                      setdailyStyled(false);
                      setyearlyStyled(false);
                      setsingleSession(true);
                      setpackegeClick(false);
                      setsubscription(false);
                      setTypeOfPayment(PAYMENT_OPTIONS.ENTIRE_COURSE);
                    }}
                  >
                    Pay in Full
                  </div>
                )
              )}
              {paymentOptions.includes(PAYMENT_OPTIONS.SPLIT_PAYMENTS) && (
                // <FormControlLabel
                //   value={PAYMENT_OPTIONS.SPLIT_PAYMENTS}
                //   control={<Radio color="primary" />}
                //   label={`Split: ${splitNumbers} Payments, Billed ${splitPeriod}`}
                // />
                <div
                  style={{
                    color: textColor,
                    backgroundColor: packegeClick ? colorToUse?.PrimaryColorCode : '',
                    borderRadius: '10px 10px 0px 0px',
                    fontSize: '12px',
                    textTransform: 'capitalize',
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                    padding: '12px 0px',
                    fontWeight: 'bold',
                    flex: 1,
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: downSmTab ? '12px 0px 12px 12px' : '',
                  }}
                  className={
                    packegeClick && activeTemplate == TemplateType.TemplateOne
                      ? 'tabsStyle'
                      : packegeClick && activeTemplate == TemplateType.TemplateTwo
                      ? 'tabsStyleTem2'
                      : ''
                  }
                  // className={classNames.redeemButton}
                  onClick={e => {
                    setpackagedStyled(false);
                    setmonthlyStyled(false);
                    setweeklyStyled(false);
                    setdailyStyled(false);
                    setyearlyStyled(false);
                    setsingleSession(false);
                    setpackegeClick(true);
                    setsubscription(false);
                    setTypeOfPayment(PAYMENT_OPTIONS.SPLIT_PAYMENTS);
                  }}
                >
                  {`Split: ${splitNumbers} Payments, Billed ${splitPeriod}`}
                </div>
              )}
              {paymentOptions.includes(PAYMENT_OPTIONS.PER_DAY) && (
                // <FormControlLabel
                //   value={PAYMENT_OPTIONS.PER_DAY}
                //   control={<Radio color="primary" />}
                //   label="Daily subscription"
                // />
                <div
                  style={{
                    color: textColor,
                    backgroundColor: subscription ? colorToUse?.PrimaryColorCode : '',
                    borderRadius: '10px 10px 0px 0px',
                    fontSize: '12px',
                    textTransform: 'capitalize',
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                    padding: singleSession ? '12px' : '12px 0px',
                    fontWeight: 'bold',
                    flex: 1,
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                  className={
                    subscription && activeTemplate == TemplateType.TemplateOne
                      ? 'tabsStyle'
                      : subscription && activeTemplate == TemplateType.TemplateTwo
                      ? 'tabsStyleTem2'
                      : ''
                  }
                  onClick={() => {
                    setpackagedStyled(false);
                    setmonthlyStyled(false);
                    setweeklyStyled(false);
                    setdailyStyled(false);
                    setyearlyStyled(false);
                    setsingleSession(false);
                    setpackegeClick(false);
                    setsubscription(true);
                    setTypeOfPayment(PAYMENT_OPTIONS.PER_DAY);
                  }}
                  size={downSm ? 'medium' : 'large'}
                  variant="text"
                >
                  Daily
                </div>
              )}
              {paymentOptions.includes(PAYMENT_OPTIONS.PER_WEEK) && (
                // <FormControlLabel
                //   value={PAYMENT_OPTIONS.PER_WEEK}
                //   control={<Radio color="primary" />}
                //   label="Weekly subscription"
                // />
                <div
                  style={{
                    color: textColor,
                    backgroundColor: weeklyStyled ? colorToUse?.PrimaryColorCode : '',
                    borderRadius: '10px 10px 0px 0px',
                    fontSize: '12px',
                    textTransform: 'capitalize',
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                    padding: '12px',
                    fontWeight: 'bold',
                    flex: 1,
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                  className={
                    weeklyStyled && activeTemplate == TemplateType.TemplateOne
                      ? 'tabsStyle'
                      : weeklyStyled && activeTemplate == TemplateType.TemplateTwo
                      ? 'tabsStyleTem2'
                      : ''
                  }
                  onClick={() => {
                    setpackagedStyled(false);
                    setmonthlyStyled(false);
                    setweeklyStyled(true);
                    setdailyStyled(false);
                    setyearlyStyled(false);
                    setsingleSession(false);
                    setpackegeClick(false);
                    setsubscription(false);
                    setTypeOfPayment(PAYMENT_OPTIONS.PER_WEEK);
                  }}
                  size={downSm ? 'medium' : 'large'}
                  variant="text"
                >
                  Weekly
                </div>
              )}
              {paymentOptions.includes(PAYMENT_OPTIONS.PER_MONTH) && (
                // <FormControlLabel
                //   value={PAYMENT_OPTIONS.PER_MONTH}
                //   control={<Radio color="primary" />}
                //   label="Monthly subscription"
                // />
                <div
                  style={{
                    color: textColor,
                    backgroundColor: monthlyStyled ? colorToUse?.PrimaryColorCode : '',
                    borderRadius: '10px 10px 0px 0px',
                    fontSize: '12px',
                    textTransform: 'capitalize',
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                    padding: '12px',
                    fontWeight: 'bold',
                    flex: 1,
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                  className={
                    monthlyStyled && activeTemplate == TemplateType.TemplateOne
                      ? 'tabsStyle'
                      : monthlyStyled && activeTemplate == TemplateType.TemplateTwo
                      ? 'tabsStyleTem2'
                      : ''
                  }
                  onClick={() => {
                    setpackagedStyled(false);
                    setmonthlyStyled(true);
                    setweeklyStyled(false);
                    setdailyStyled(false);
                    setyearlyStyled(false);
                    setsingleSession(false);
                    setpackegeClick(false);
                    setsubscription(false);
                    setTypeOfPayment(PAYMENT_OPTIONS.PER_MONTH);
                  }}
                  size={downSm ? 'medium' : 'large'}
                  variant="text"
                >
                  Monthly
                </div>
              )}
              {paymentOptions.includes(PAYMENT_OPTIONS.PER_YEAR) && (
                // <FormControlLabel
                //   value={PAYMENT_OPTIONS.PER_YEAR}
                //   control={<Radio color="primary" />}
                //   label="Yearly subscription"
                // />
                <div
                  style={{
                    color: textColor,
                    backgroundColor: yearlyStyled ? colorToUse?.PrimaryColorCode : '',
                    borderRadius: '10px 10px 0px 0px',
                    fontSize: '12px',
                    textTransform: 'capitalize',
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                    padding: '12px',
                    fontWeight: 'bold',
                    flex: 1,
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                  className={
                    yearlyStyled && activeTemplate == TemplateType.TemplateOne
                      ? 'tabsStyle'
                      : yearlyStyled && activeTemplate == TemplateType.TemplateTwo
                      ? 'tabsStyleTem2'
                      : ''
                  }
                  onClick={() => {
                    setpackagedStyled(false);
                    setmonthlyStyled(false);
                    setweeklyStyled(false);
                    setdailyStyled(false);
                    setyearlyStyled(true);
                    setsingleSession(false);
                    setpackegeClick(false);
                    setsubscription(false);
                    setTypeOfPayment(PAYMENT_OPTIONS.PER_YEAR);
                  }}
                  size={downSm ? 'medium' : 'large'}
                  variant="text"
                >
                  Yearly
                </div>
              )}
              {paymentOptions.includes(PAYMENT_OPTIONS.PACKAGE) && (
                // <FormControlLabel
                //   value={PAYMENT_OPTIONS.PACKAGE}
                //   control={<Radio color="primary" />}
                //   label={`${membershipInfo.membershipPackage.duration} months subscription with ${membershipInfo.membershipPackage.period} payment`}
                // />
                <div
                  style={{
                    color: textColor,
                    backgroundColor: packagedStyled ? colorToUse?.PrimaryColorCode : '',
                    borderRadius: '10px 10px 0px 0px',
                    fontSize: '12px',
                    textTransform: 'capitalize',
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                    padding: '12px',
                    fontWeight: 'bold',
                    flex: 1,
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                  className={
                    packagedStyled && activeTemplate == TemplateType.TemplateOne
                      ? 'tabsStyle'
                      : packagedStyled && activeTemplate == TemplateType.TemplateTwo
                      ? 'tabsStyleTem2'
                      : ''
                  }
                  onClick={() => {
                    setpackagedStyled(true);
                    setmonthlyStyled(false);
                    setweeklyStyled(false);
                    setdailyStyled(false);
                    setyearlyStyled(false);
                    setsingleSession(false);
                    setpackegeClick(false);
                    setsubscription(false);
                    setTypeOfPayment(PAYMENT_OPTIONS.PACKAGE);
                  }}
                  size={downSm ? 'medium' : 'large'}
                  variant="text"
                >
                  {`${membershipInfo.membershipPackage.duration} months subscription with ${membershipInfo.membershipPackage.period} payment`}
                </div>
              )}

              {paymentOptions.includes(PAYMENT_OPTIONS.PER_SESSION) && (
                // <FormControlLabel
                //   value={PAYMENT_OPTIONS.PACKAGE}
                //   control={<Radio color="primary" />}
                //   label={`${membershipInfo.membershipPackage.duration} months subscription with ${membershipInfo.membershipPackage.period} payment`}
                // />
                <div
                  style={{
                    color: textColor,
                    backgroundColor: perSessionStyled ? colorToUse?.PrimaryColorCode : '',
                    borderRadius: '10px 10px 0px 0px',
                    fontSize: '12px',
                    textTransform: 'capitalize',
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                    padding: '12px',
                    fontWeight: 'bold',
                    flex: 1,
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                  className={
                    perSessionStyled && activeTemplate == TemplateType.TemplateOne
                      ? 'tabsStyle'
                      : perSessionStyled && activeTemplate == TemplateType.TemplateTwo
                      ? 'tabsStyleTem2'
                      : ''
                  }
                  onClick={() => {
                    setperSessionStyled(true);
                    SetMonthlySessionSubscription(false);
                    setpackagedStyled(false);
                    setmonthlyStyled(false);
                    setweeklyStyled(false);
                    setdailyStyled(false);
                    setyearlyStyled(false);
                    setsingleSession(false);
                    setpackegeClick(false);
                    setsubscription(false);
                    setsessionPackageStyled(false);
                    setTypeOfPayment(PAYMENT_OPTIONS.PER_SESSION);
                  }}
                  size={downSm ? 'medium' : 'large'}
                  variant="text"
                >
                  Single Session
                  {/* {`${membershipInfo.membershipPackage.duration} months subscription with ${membershipInfo.membershipPackage.period} payment`} */}
                </div>
              )}

              {paymentOptions.includes(PAYMENT_OPTIONS.SESSIONS_PACKAGE) && (
                // <FormControlLabel
                //   value={PAYMENT_OPTIONS.PER_YEAR}
                //   control={<Radio color="primary" />}
                //   label="Yearly subscription"
                // />
                <div
                  style={{
                    color: textColor,
                    backgroundColor: sessionPackageStyled ? colorToUse?.PrimaryColorCode : '',
                    borderRadius: '10px 10px 0px 0px',
                    fontSize: '12px',
                    textTransform: 'capitalize',
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                    padding: '12px',
                    fontWeight: 'bold',
                    flex: 1,
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                  className={
                    sessionPackageStyled && activeTemplate == TemplateType.TemplateOne
                      ? 'tabsStyle'
                      : sessionPackageStyled && activeTemplate == TemplateType.TemplateTwo
                      ? 'tabsStyleTem2'
                      : ''
                  }
                  onClick={() => {
                    setsessionPackageStyled(true);
                    SetMonthlySessionSubscription(false);
                    setpackagedStyled(false);
                    setmonthlyStyled(false);
                    setweeklyStyled(false);
                    setdailyStyled(false);
                    setyearlyStyled(false);
                    setsingleSession(false);
                    setpackegeClick(false);
                    setsubscription(false);
                    setperSessionStyled(false);

                    setTypeOfPayment(PAYMENT_OPTIONS.SESSIONS_PACKAGE);
                  }}
                  size={downSm ? 'medium' : 'large'}
                  variant="text"
                >
                  Package
                </div>
              )}
              {paymentOptions.includes(PAYMENT_OPTIONS.MONTHLY_SESSION_SUBSCRIPTION) && (
                // <FormControlLabel
                //   value={PAYMENT_OPTIONS.PER_YEAR}
                //   control={<Radio color="primary" />}
                //   label="Yearly subscription"
                // />
                <div
                  style={{
                    color: textColor,
                    backgroundColor: monthlySessionSubscription ? colorToUse?.PrimaryColorCode : '',
                    borderRadius: '10px 10px 0px 0px',
                    fontSize: '12px',
                    textTransform: 'capitalize',
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                    padding: '12px',
                    fontWeight: 'bold',
                    flex: 1,
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                  className={
                    monthlySessionSubscription && activeTemplate == TemplateType.TemplateOne
                      ? 'tabsStyle'
                      : monthlySessionSubscription && activeTemplate == TemplateType.TemplateTwo
                      ? 'tabsStyleTem2'
                      : ''
                  }
                  onClick={() => {
                    SetMonthlySessionSubscription(true);
                    setsessionPackageStyled(false);
                    setpackagedStyled(false);
                    setmonthlyStyled(false);
                    setweeklyStyled(false);
                    setdailyStyled(false);
                    setyearlyStyled(false);
                    setsingleSession(false);
                    setpackegeClick(false);
                    setsubscription(false);
                    setperSessionStyled(false);

                    setTypeOfPayment(PAYMENT_OPTIONS.MONTHLY_SESSION_SUBSCRIPTION);
                  }}
                  size={downSm ? 'medium' : 'large'}
                  variant="text"
                >
                  Subscription
                </div>
              )}
            </CardHeaderUI>
          )}
          <CardContent style={{ backgroundColor: newThemedCardColor }}>
            {(hide || (clientPreviewMode && onlyFreePaymentOption)) &&
              step != PURCHASE_MODAL_STEPS.create &&
              step != PURCHASE_MODAL_STEPS.joinCreate && (
                <Typography
                  variant="h5"
                  style={{ fontWeight: 'bold', textAlign: 'center', padding: ' 1rem 0px', color: themedColor }}
                >
                  Free Access
                </Typography>
              )}
            {error && <CommonErrorMessage message={error} />}
            {!hide &&
              summary &&
              !isMonthlySessionSubscription &&
              step != PURCHASE_MODAL_STEPS.create &&
              step != PURCHASE_MODAL_STEPS.joinCreate && (
                <>
                  {/* PRICE */}
                  <Typography
                    variant="h5"
                    style={{ fontWeight: 'bold', textAlign: 'center', padding: ' 1rem 0px', color: themedColor }}
                  >
                    {showApplyButtonSesttingsPending && id === '66968fb214bf732c19083465' ? (
                      <>
                        {defaultSymbol}
                        {status === ContributionStatus.unfinished && clientPreviewMode && packegeClick
                          ? formatMoney(ClientPreviewSplitPaymentData.price)
                          : formatMoney(summary?.dueNowWithCouponDiscountAmount - summary.platformFee)}{' '}
                        <span style={{ fontWeight: '300' }}> {currencyCode}</span>
                      </>
                    ) : showApplyButtonSesttings && id === '66968fb214bf732c19083465' ? (
                      <></>
                    ) : (
                      <>
                        {defaultSymbol}
                        {status === ContributionStatus.unfinished && clientPreviewMode && packegeClick
                          ? formatMoney(ClientPreviewSplitPaymentData.price)
                          : formatMoney(summary?.dueNowWithCouponDiscountAmount - summary.platformFee)}{' '}
                        <span style={{ fontWeight: '300' }}> {currencyCode}</span>
                      </>
                    )}
                  </Typography>
                  {/* <PriceContainer>
                    <Grid container justify="space-between">
                      <Grid item>
                        <Typography style={{ lineHeight: 2.1 }}>Price:</Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                          {defaultSymbol}
                          {formatMoney(summary.price)} <span style={{ fontWeight: '300' }}> {currencyCode}</span>
                        </Typography>
                      </Grid>
                    </Grid>
                  </PriceContainer> */}
                  {/* FEE */}
                  {summary.platformFee != 0 && (
                    <PriceContainer style={{ backgroundColor: newThemedCardColor }}>
                      <Grid container justify="space-between">
                        <Grid item>
                          <Typography style={{ lineHeight: 2.1, color: themedColor }}>Processing Fee:</Typography>
                        </Grid>
                        <Grid item>
                          <Typography variant="h6" style={{ fontWeight: 'bold', color: themedColor }}>
                            {defaultSymbol}
                            {formatMoney(summary.platformFee)}{' '}
                            <span style={{ fontWeight: '300', color: themedColor }}> {currencyCode}</span>
                          </Typography>
                        </Grid>
                      </Grid>
                    </PriceContainer>
                  )}
                  {/* DUE */}
                  {summary.platformFee != 0 && !packegeClick && (
                    <PriceContainer style={{ backgroundColor: newThemedCardColor }}>
                      <Grid container justify="space-between">
                        <Grid item>
                          <Typography style={{ lineHeight: 2.1, color: themedColor }}>Total Price:</Typography>
                        </Grid>
                        <Grid item>
                          <Typography variant="h6" style={{ fontWeight: 'bold', color: themedColor }}>
                            {defaultSymbol}
                            {formatMoney(summary.price + summary.platformFee)}{' '}
                            <span style={{ fontWeight: '300', color: themedColor }}> {currencyCode}</span>
                          </Typography>
                        </Grid>
                      </Grid>
                    </PriceContainer>
                  )}

                  {packegeClick && (
                    <>
                      <PriceContainer style={{ backgroundColor: newThemedCardColor }}>
                        <Grid container justify="space-between">
                          <Grid item>
                            <Typography style={{ lineHeight: 2.1, color: themedColor }}>Due Now:</Typography>
                          </Grid>
                          <Grid item>
                            <Typography variant="h6" style={{ fontWeight: 'bold', color: themedColor }}>
                              {defaultSymbol}
                              {status === ContributionStatus.unfinished && clientPreviewMode
                                ? formatMoney(ClientPreviewSplitPaymentData.dueNowWithCouponDiscountAmount)
                                : formatMoney(summary.dueNowWithCouponDiscountAmount)}{' '}
                              <span style={{ fontWeight: '300', color: themedColor }}> {currencyCode}</span>
                            </Typography>
                          </Grid>
                        </Grid>
                      </PriceContainer>
                      <PriceContainer style={{ backgroundColor: newThemedCardColor }}>
                        <Grid container justify="space-between">
                          <Grid item>
                            <Typography style={{ lineHeight: 2.1 }}>Total Price:</Typography>
                          </Grid>
                          <Grid item>
                            <Typography variant="h6" style={{ fontWeight: 'bold', color: themedColor }}>
                              {defaultSymbol}
                              {status === ContributionStatus.unfinished && clientPreviewMode
                                ? formatMoney(ClientPreviewSplitPaymentData.price + summary.platformFee)
                                : formatMoney(summary.dueNowWithCouponDiscountAmount * splitNumbers)}{' '}
                              <span style={{ fontWeight: '300', color: themedColor }}> {currencyCode}</span>
                            </Typography>
                          </Grid>
                        </Grid>
                      </PriceContainer>
                    </>
                  )}
                </>
              )}

            {!applicationRequiredButNotApproved &&
              !hide &&
              step != PURCHASE_MODAL_STEPS.create &&
              step != PURCHASE_MODAL_STEPS.joinCreate &&
              isCouponAvailable &&
              contribution?.id === '674e2e9d1242a6090670d7f7' && (
                <ApplyCouponContainer backgroundColor={newThemedCardColor}>
                  <Grid
                    alignItems="center"
                    container
                    justify="space-between"
                    wrap="nowrap"
                    style={{
                      backgroundColor: themedBackgroundColor,
                      borderRadius: '12px',
                      margin: '10px 10px 10px 0px',
                    }}
                  >
                    <Grid container md={9} xs={9}>
                      <TextField
                        fullWidth
                        className={[classNames.discountCodeTextFied, classNames.themedTextField].join(' ')}
                        placeholder={'Promotion Code'}
                        variant="outlined"
                        onChange={handleOnChangeCoupon}
                        value={couponValue}
                        InputProps={{
                          classes: { notchedOutline: classNames.noBorder },
                        }}
                      />
                    </Grid>
                    <Grid container justify="flex-end" md={2} xs={2}>
                      <Button
                        style={
                          activeTemplate === TemplateType.TemplateTwo
                            ? {
                                backgroundColor: colorToUse?.PrimaryColorCode,
                                color: textColor,
                              }
                            : {
                                color: colorToUse?.PrimaryColorCode,
                                marginRight: '10px',
                              }
                        }
                        className={radeemClass}
                        onClick={handleRedeem}
                        size={downSm ? 'medium' : 'large'}
                        variant="text"
                      >
                        Redeem
                      </Button>
                    </Grid>
                  </Grid>
                </ApplyCouponContainer>
              )}

            {!applicationRequiredButNotApproved &&
              !hide &&
              step != PURCHASE_MODAL_STEPS.create &&
              step != PURCHASE_MODAL_STEPS.joinCreate &&
              isCouponAvailable &&
              contribution?.id != '674e2e9d1242a6090670d7f7' && (
                <ApplyCouponContainer backgroundColor={newThemedCardColor}>
                  <Grid
                    alignItems="center"
                    container
                    justify="space-between"
                    wrap="nowrap"
                    style={{
                      backgroundColor: themedBackgroundColor,
                      borderRadius: '12px',
                      margin: '10px 10px 10px 0px',
                    }}
                  >
                    <Grid container md={9} xs={9}>
                      <TextField
                        fullWidth
                        className={[classNames.discountCodeTextFied, classNames.themedTextField].join(' ')}
                        placeholder={'Discount Code'}
                        variant="outlined"
                        onChange={handleOnChangeCoupon}
                        value={couponValue}
                        InputProps={{
                          classes: { notchedOutline: classNames.noBorder },
                        }}
                      />
                    </Grid>
                    <Grid container justify="flex-end" md={2} xs={2}>
                      <Button
                        style={
                          activeTemplate === TemplateType.TemplateTwo
                            ? {
                                backgroundColor: colorToUse?.PrimaryColorCode,
                                color: textColor,
                              }
                            : {
                                color: colorToUse?.PrimaryColorCode,
                                marginRight: '10px',
                              }
                        }
                        className={radeemClass}
                        onClick={handleRedeem}
                        size={downSm ? 'medium' : 'large'}
                        variant="text"
                      >
                        Redeem
                      </Button>
                    </Grid>
                  </Grid>
                </ApplyCouponContainer>
              )}

            {/* Enrollement Dates  */}
            {step != PURCHASE_MODAL_STEPS.create && step != PURCHASE_MODAL_STEPS.joinCreate && (
              <>
                <EnrollmentWrapper userNotLoggedIn={userNotLoggedIn}>
                  <WorkshopDatesBlockTemplate
                    themedColor={themedColor}
                    colorToUse={colorToUse}
                    userNotLoggedIn={userNotLoggedIn}
                  />
                  {!enrollment?.anyTime && (
                    <CustomEnrollmentBlock
                      themedColor={themedColor}
                      enrollment={enrollment}
                      userNotLoggedIn={userNotLoggedIn}
                      colorToUse={colorToUse}
                    />
                  )}
                </EnrollmentWrapper>
              </>
            )}

            {/* Horizental Line */}
            {step != PURCHASE_MODAL_STEPS.create && step != PURCHASE_MODAL_STEPS.joinCreate && <hr width="80%" />}
            {/* Login form starts from here  */}
            {(step === PURCHASE_MODAL_STEPS.create || step === PURCHASE_MODAL_STEPS.joinCreate) && (
              <div style={{ display: 'flex', justifyContent: 'flex-start', padding: '0px 0px 8px 19px' }}>
                <KeyboardBackspaceIcon style={{ cursor: 'pointer' }} onClick={goToPrevStep} />
              </div>
            )}
            {step === PURCHASE_MODAL_STEPS.memberCreate && (
              <div style={{ display: 'flex', justifyContent: 'flex-start', padding: '0px 0px 8px 19px' }}>
                <KeyboardBackspaceIcon style={{ cursor: 'pointer' }} onClick={goToPrevStep} />
              </div>
            )}

            <div style={{ padding: '0px 0px 0px 19px' }}>
              {step != PURCHASE_MODAL_STEPS.create &&
              step != PURCHASE_MODAL_STEPS.joinCreate &&
              contribution?.id === '659443532dcb0f40762628d1' ? (
                <Typography
                  variant="h6"
                  style={{
                    color: colorToUse?.AccentColorCode,
                    fontSize: '1.5rem',
                    fontWeight: '400',
                    fontWeight: 'bold',
                  }}
                >
                  Enter Your Email to Access
                </Typography>
              ) : step != PURCHASE_MODAL_STEPS.create &&
                step != PURCHASE_MODAL_STEPS.joinCreate &&
                contribution?.id != '659443532dcb0f40762628d1' ? (
                <Typography
                  variant="h6"
                  style={{
                    color: colorToUse?.AccentColorCode,
                    fontSize: '1.5rem',
                    fontWeight: '400',
                    fontWeight: 'bold',
                  }}
                >
                  {typographyContent}
                </Typography>
              ) : (
                <>
                  <Typography variant="h6" style={{ color: themedColor, fontSize: '20px' }}>
                    Create New Account
                  </Typography>
                  <Typography variant="h6" style={{ fontSize: '14px', color: 'darkgray' }}>
                    Enter your email address
                  </Typography>
                </>
              )}
            </div>

            <Formik
              initialValues={{
                [ACCOUNT_FORM_FIELDS.confirmEmail]: query?.email || '',
                [ACCOUNT_FORM_FIELDS.email]: query?.email || '',
                [ACCOUNT_FORM_FIELDS.password]: '',
                [ACCOUNT_FORM_FIELDS.firstName]: query?.first_name || '',
                [ACCOUNT_FORM_FIELDS.lastName]: query?.last_name || '',
                [ACCOUNT_FORM_FIELDS.timeZoneId]: '',
                [DISCOUNT_CODE_FIELDS.coupon]: '',
                [ACCOUNT_FORM_FIELDS.signature]: null,
                [ACCOUNT_FORM_FIELDS.phone]: query?.phone_number || '',
                [ACCOUNT_FORM_FIELDS.country]: '',
                [ACCOUNT_FORM_FIELDS.state]: '',
                [ACCOUNT_FORM_FIELDS.sessionReminder]: false,
              }}
              validationSchema={
                !isApplyContributionView && getPurchaseValidationSchema(step, isPackage || isMonthlySessionSubscription)
              }
              innerRef={formRef}
              onSubmit={handleSubmit}
            >
              {({ handleChange, handleBlur, values, errors, touched, setFieldValue, submitHandler }) => {
                return (
                  <>
                    <FormikScrollToError>
                      <Form>
                        {!applicationRequiredButNotApproved && (
                          <Grid container style={clientPreviewMode ? { pointerEvents: 'none' } : {}}>
                            {isShowInput(step, ACCOUNT_FORM_FIELDS.email) && (
                              <Grid
                                container
                                item
                                direction="column"
                                alignItems="flex-start"
                                md={12}
                                xs={12}
                                style={{ padding: '10px 15px' }}
                              >
                                <TextField
                                  fullWidth
                                  className={[classNames.inputField, classNames.themedTextField].join(' ')}
                                  name={ACCOUNT_FORM_FIELDS.email}
                                  placeholder={'Enter Email'}
                                  variant="outlined"
                                  type="text"
                                  onChange={e => {
                                    setFieldValue(ACCOUNT_FORM_FIELDS.email, e.target.value.trim());
                                    if (query?.email) {
                                      setFieldValue(ACCOUNT_FORM_FIELDS.confirmEmail, e.target.value.trim());
                                    }
                                  }}
                                  ref={emailRef}
                                  onBlur={handleBlur}
                                  value={values.Email}
                                  InputProps={{
                                    classes: { notchedOutline: classNames.noBorder },
                                  }}
                                />
                                {errors && errors[ACCOUNT_FORM_FIELDS.email] && touched[ACCOUNT_FORM_FIELDS.email] && (
                                  <StyledError PrimaryColor={PrimaryColor} template={activeTemplate}>
                                    {errors[ACCOUNT_FORM_FIELDS.email]}
                                  </StyledError>
                                )}
                              </Grid>
                            )}
                            {isShowInput(step, ACCOUNT_FORM_FIELDS.confirmEmail) && (
                              <Grid
                                container
                                item
                                direction="column"
                                alignItems="flex-start"
                                md={12}
                                xs={12}
                                style={{ padding: '5px 15px' }}
                              >
                                <TextField
                                  inputRef={textFieldRef}
                                  fullWidth
                                  className={[classNames.inputField, classNames.themedTextField].join(' ')}
                                  name={ACCOUNT_FORM_FIELDS.confirmEmail}
                                  placeholder="Confirm Email"
                                  variant="outlined"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  onPaste={handlePaste}
                                  ref={confirmEmailRef}
                                  type="email"
                                  defaultValue={values.ConfirmEmail}
                                  value={values.ConfirmEmail}
                                  InputProps={{
                                    classes: { notchedOutline: classNames.noBorder },
                                  }}
                                />
                                {errors &&
                                  errors[ACCOUNT_FORM_FIELDS.confirmEmail] &&
                                  touched[ACCOUNT_FORM_FIELDS.confirmEmail] && (
                                    <StyledError PrimaryColor={PrimaryColor} template={activeTemplate}>
                                      {errors[ACCOUNT_FORM_FIELDS.confirmEmail]}
                                    </StyledError>
                                  )}
                              </Grid>
                            )}
                            {isShowInput(step, ACCOUNT_FORM_FIELDS.password) && (
                              <Grid
                                container
                                item
                                direction="column"
                                alignItems="flex-end"
                                md={12}
                                xs={12}
                                style={{ padding: '5px 15px' }}
                              >
                                <TextField
                                  inputRef={textFieldRef}
                                  fullWidth
                                  className={[classNames.inputField, classNames.themedTextField].join(' ')}
                                  name={ACCOUNT_FORM_FIELDS.password}
                                  placeholder={
                                    allowpassword ? ACCOUNT_FORM_FIELDS_LABELS.password : 'Create a Password'
                                  }
                                  variant="outlined"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  type="password"
                                  value={values.password}
                                  InputProps={{
                                    classes: { notchedOutline: classNames.noBorder },
                                  }}
                                />
                                {isShowInput(step, RESTORE_PASS) && (
                                  <SendOtpLinkContainer>
                                    <SendOtpLink
                                      isDarkModeEnabled={contribution.isDarkModeEnabled}
                                      onClick={handleShowOtpPopup}
                                    >
                                      Sign in with email one-time code
                                    </SendOtpLink>
                                    <ForgotPasswordLink
                                      style={{ color: themedColor }}
                                      onClick={() => redirectToAppIfIsCoachDomain(domain, '/auth/restore-pass')}
                                    >
                                      Forgot Password?
                                    </ForgotPasswordLink>
                                  </SendOtpLinkContainer>
                                )}
                                {errors &&
                                  errors[ACCOUNT_FORM_FIELDS.password] &&
                                  touched[ACCOUNT_FORM_FIELDS.password] && (
                                    <StyledError PrimaryColor={PrimaryColor} template={activeTemplate}>
                                      {errors[ACCOUNT_FORM_FIELDS.password]}
                                    </StyledError>
                                  )}
                                {errorMessage && (
                                  <StyledError PrimaryColor={PrimaryColor} template={activeTemplate}>
                                    {errorMessage?.message != 'Sign in failed. Please check your email or password' &&
                                      errorMessage?.message != 'You have entered the wrong login code, try again' &&
                                      errorMessage?.message}
                                  </StyledError>
                                )}
                              </Grid>
                            )}
                            {isShowInput(step, ACCOUNT_FORM_FIELDS.firstName) && (
                              <Grid
                                container
                                item
                                direction="column"
                                alignItems="flex-start"
                                md={6}
                                xs={12}
                                style={{ padding: '5px 15px' }}
                              >
                                <TextField
                                  fullWidth
                                  className={[classNames.inputField, classNames.themedTextField].join(' ')}
                                  name={ACCOUNT_FORM_FIELDS.firstName}
                                  placeholder={query?.firstName ? query?.firstName : 'First Name'}
                                  variant="outlined"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  defaultValue={values.FirstName}
                                  value={values.FirstName}
                                  type="text"
                                  InputProps={{
                                    classes: { notchedOutline: classNames.noBorder },
                                  }}
                                />
                                {errors &&
                                  errors[ACCOUNT_FORM_FIELDS.firstName] &&
                                  touched[ACCOUNT_FORM_FIELDS.firstName] && (
                                    <StyledError PrimaryColor={PrimaryColor} template={activeTemplate}>
                                      {errors[ACCOUNT_FORM_FIELDS.firstName]}
                                    </StyledError>
                                  )}
                              </Grid>
                            )}
                            {isShowInput(step, ACCOUNT_FORM_FIELDS.lastName) && (
                              <Grid
                                container
                                item
                                direction="column"
                                alignItems="flex-start"
                                md={6}
                                xs={12}
                                style={{ padding: '5px 15px' }}
                              >
                                <TextField
                                  fullWidth
                                  className={[classNames.inputField, classNames.themedTextField].join(' ')}
                                  name={ACCOUNT_FORM_FIELDS.lastName}
                                  placeholder={query?.lastName ? query?.lastName : 'Last Name'}
                                  variant="outlined"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  defaultValue={values.LastName}
                                  value={values.lastName}
                                  type="text"
                                  InputProps={{
                                    classes: { notchedOutline: classNames.noBorder },
                                  }}
                                />
                                {errors &&
                                  errors[ACCOUNT_FORM_FIELDS?.lastName] &&
                                  touched[ACCOUNT_FORM_FIELDS?.lastName] && (
                                    <StyledError PrimaryColor={PrimaryColor} template={activeTemplate}>
                                      {errors[ACCOUNT_FORM_FIELDS?.lastName]}
                                    </StyledError>
                                  )}
                              </Grid>
                            )}
                            {(isShowInput(step, ACCOUNT_FORM_FIELDS.sessionReminder) ||
                              step == PURCHASE_MODAL_STEPS.loggedIn ||
                              step == PURCHASE_MODAL_STEPS.login ||
                              step == PURCHASE_MODAL_STEPS.joinLogin) &&
                              showPhoneNumberFields === true && (
                                <Grid
                                  item
                                  alignItems="flex-start"
                                  md={12}
                                  xs={12}
                                  style={{
                                    padding: '5px 15px',
                                    flexDirection: 'row',
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}
                                >
                                  <Checkbox
                                    style={{ color: themedColor }}
                                    id="create-contribution-agreement"
                                    color="primary"
                                    checked={smsReminderCheckBox}
                                    onChange={() => {
                                      if (smsReminderCheckBox === true) {
                                        setSmsReminderCheckBox(false);
                                        setisPhoneNumberRequired(false);
                                      } else {
                                        setSmsReminderCheckBox(true);
                                        setisPhoneNumberRequired(true);
                                      }
                                    }}
                                  />
                                  <div style={{ fontFamily: 'Avenir', fontWeight: '300', fontSize: '14px' }}>
                                    Receive SMS session reminders
                                  </div>
                                </Grid>
                              )}
                            {(isShowInput(step, ACCOUNT_FORM_FIELDS.phone) ||
                              step == PURCHASE_MODAL_STEPS.loggedIn ||
                              step == PURCHASE_MODAL_STEPS.login ||
                              step == PURCHASE_MODAL_STEPS.joinLogin) &&
                              smsReminderCheckBox === true &&
                              showPhoneNumberFields === true && (
                                <Grid
                                  container
                                  direction="column"
                                  alignItems="flex-start"
                                  md={12}
                                  xs={12}
                                  style={{ padding: '5px 15px' }}
                                >
                                  <div style={{ width: '100%' }} ref={phoneInputRef}>
                                    {defaultCountry && (
                                      <PhoneInput
                                        key={coachPhoneNumberFocused + phoneInputKey}
                                        value={coachPhoneNumber}
                                        defaultCountry={defaultCountry}
                                        {...(!coachPhoneNumberFocused && { disableDialCodePrefill: true })}
                                        {...(coachPhoneNumberFocused && { forceDialCode: true })}
                                        disableCountryGuess
                                        placeholder="Phone Number"
                                        onChange={phone => {
                                          setFieldValue(ACCOUNT_FORM_FIELDS?.phone, phone);
                                          setPhoneNumberField(phone);
                                          setShowErrorPhoneNumber(false);
                                          setCoachPhoneNumber(phone);
                                        }}
                                      />
                                    )}
                                    {((errors &&
                                      errors[ACCOUNT_FORM_FIELDS?.phone] &&
                                      touched[ACCOUNT_FORM_FIELDS?.phone]) ||
                                      showErrorPhoneNumber) && (
                                      <StyledError PrimaryColor={PrimaryColor} template={activeTemplate}>
                                        {showErrorPhoneNumber
                                          ? 'Please add a valid phone number'
                                          : errors[ACCOUNT_FORM_FIELDS?.phone]}
                                      </StyledError>
                                    )}
                                  </div>
                                </Grid>
                              )}
                            {contribution.paymentType === 'Simple' &&
                              contribution.taxType != 'No' &&
                              isInviteToJoin === null &&
                              showThreeFields &&
                              (isShowInput(step, ACCOUNT_FORM_FIELDS.country) ||
                                (formRef?.current?.values?.countryId?.length > 0 &&
                                  formRef?.current?.values?.countryId != null)) && (
                                <>
                                  <Grid
                                    container
                                    item
                                    direction="column"
                                    alignItems="flex-start"
                                    md={12}
                                    xs={12}
                                    style={{
                                      padding: '5px 15px',
                                      position: 'relative',
                                    }}
                                  >
                                    <Typography variant="h6" style={{ color: themedColor, fontSize: '20px' }}>
                                      Where are you located?
                                    </Typography>
                                  </Grid>
                                  <Grid
                                    container
                                    item
                                    direction="column"
                                    alignItems="flex-start"
                                    md={12}
                                    xs={12}
                                    style={{
                                      padding: '5px 15px',
                                      position: 'relative',
                                    }}
                                  >
                                    <Select
                                      label="Country of Residence"
                                      name={ACCOUNT_FORM_FIELDS.country}
                                      fullWidth
                                      required
                                      native
                                      labelId="country"
                                      value={formRef?.current?.values?.countryId}
                                      className={[classNames.select, classNames.themedTextField].join(' ')}
                                      onChange={e => {
                                        const filteredObjects = states.filter(obj => obj.countryId === e.target.value);
                                        const filteredCountry = countries.find(obj => obj.id === e.target.value);
                                        const filteredTimezone = timeZones.filter(
                                          obj => obj.countryId === e.target.value,
                                        );
                                        if (filteredObjects.length >= 1) {
                                          setStatesArray(filteredObjects);
                                          if (e.target.value != '60b8ddb57205057e7ce2b861') {
                                            setTimeZoneArray(filteredTimezone);
                                          }
                                        } else if (filteredObjects.length === 0) {
                                          setStatesArray(filteredObjects);
                                          if (filteredTimezone.length === 0) {
                                            setTimeZoneArray(timeZones);
                                          } else if (
                                            filteredTimezone.length === 1 &&
                                            filteredCountry.id != '60b8ddb57205057e7ce2b861'
                                          ) {
                                            setTimeZoneArray(filteredTimezone);
                                            setFieldValue(
                                              ACCOUNT_FORM_FIELDS.timeZoneId,
                                              filteredTimezone[0].countryName,
                                            );
                                          } else {
                                            setTimeZoneArray(filteredTimezone);
                                          }
                                        }
                                        if (filteredCountry.id === '60b8ddb57205057e7ce2b861') {
                                          setTimeZoneArray(filteredTimezone);
                                          setFieldValue(ACCOUNT_FORM_FIELDS.state, null);
                                          setFieldValue(ACCOUNT_FORM_FIELDS.country, filteredCountry.id);
                                          setFieldValue(ACCOUNT_FORM_FIELDS.timeZoneId, null);
                                        }
                                        setFieldValue(ACCOUNT_FORM_FIELDS.country, filteredCountry.id);
                                      }}
                                      disableUnderline
                                      style={{ padding: '15px', position: 'relative' }}
                                    >
                                      <option aria-label="Time Zone" value={null}>
                                        Select Country
                                      </option>
                                      {countries != null &&
                                        countries?.map(timeZone => (
                                          <option value={timeZone.id}>{timeZone.name}</option>
                                        ))}
                                    </Select>
                                    {errorLocal &&
                                      formRef?.current?.values?.countryId === null &&
                                      formRef?.current?.values?.countryId?.length === 0 && (
                                        <StyledError PrimaryColor={PrimaryColor} template={activeTemplate}>
                                          Please select country
                                        </StyledError>
                                      )}
                                  </Grid>
                                </>
                              )}
                            {contribution.taxType != 'No' &&
                              isInviteToJoin === null &&
                              formRef?.current?.values?.countryId === '60b8ddb57205057e7ce2b861' &&
                              statesArray.length > 1 && (
                                <Grid
                                  container
                                  item
                                  direction="column"
                                  alignItems="flex-start"
                                  md={12}
                                  xs={12}
                                  style={{
                                    padding: '5px 15px',
                                    position: 'relative',
                                  }}
                                >
                                  <Select
                                    label="State"
                                    name={ACCOUNT_FORM_FIELDS.state}
                                    fullWidth
                                    required
                                    native
                                    labelId="stateCode"
                                    value={formRef?.current?.values?.stateCode}
                                    className={[classNames.select, classNames.themedTextField].join(' ')}
                                    onChange={e => {
                                      const filteredState = states.find(obj => obj.alpha2Code === e.target.value);
                                      const filteredObjects = timeZones.filter(obj =>
                                        obj.statesIds.includes(filteredState.id),
                                      );
                                      if (filteredObjects.length >= 1) {
                                        if (filteredObjects.length === 1) {
                                          setTimeZoneArray(filteredObjects);
                                          setFieldValue(ACCOUNT_FORM_FIELDS.timeZoneId, filteredObjects[0].countryName);
                                        } else {
                                          setFieldValue(ACCOUNT_FORM_FIELDS.timeZoneId, filteredObjects[0].countryName);
                                          setTimeZoneArray(filteredObjects);
                                        }
                                      } else {
                                        setTimeZoneArray(timeZones);
                                      }
                                      setFieldValue(ACCOUNT_FORM_FIELDS.state, filteredState.alpha2Code);
                                    }}
                                    disableUnderline
                                    style={{ padding: '15px', position: 'relative' }}
                                  >
                                    <option aria-label="Time Zone" value={null}>
                                      Select State
                                    </option>
                                    {statesArray?.length > 0 &&
                                      statesArray?.map(timeZone => (
                                        <option value={timeZone.alpha2Code}>{timeZone.name}</option>
                                      ))}
                                  </Select>
                                  {errorLocal &&
                                    (formRef?.current?.values?.stateCode?.length < 1 ||
                                      formRef?.current?.values?.stateCode === null) && (
                                      <StyledError PrimaryColor={PrimaryColor} template={activeTemplate}>
                                        Please select state
                                      </StyledError>
                                    )}
                                </Grid>
                              )}
                            {contribution.paymentType === 'Simple' &&
                              contribution.taxType != 'No' &&
                              isInviteToJoin === null &&
                              timeZoneArray.length > 1 &&
                              formRef?.current?.values?.countryId != null && (
                                <>
                                  <Grid
                                    container
                                    item
                                    direction="column"
                                    alignItems="flex-start"
                                    md={12}
                                    xs={12}
                                    style={{
                                      padding: '5px 15px',
                                      position: 'relative',
                                    }}
                                  >
                                    <Select
                                      required
                                      native
                                      labelId="timezones"
                                      className={[classNames.select, classNames.themedTextField].join(' ')}
                                      name={ACCOUNT_FORM_FIELDS.timeZoneId}
                                      onChange={e => {
                                        setFieldValue(ACCOUNT_FORM_FIELDS.timeZoneId, e.target.value);
                                        const color = e.target.value === 'Select Timezone' ? 'darkgrey' : 'black';
                                        setColorForSelect(color);
                                      }}
                                      value={formRef?.current?.values?.TimeZoneId}
                                      onBlur={handleBlur}
                                      disableUnderline
                                      style={{ padding: '15px', position: 'relative' }}
                                    >
                                      <option aria-label="Time Zone" value={null}>
                                        Select Timezone
                                      </option>
                                      {timeZoneArray != null &&
                                        timeZoneArray?.map(timeZone => (
                                          <option value={timeZone.countryName}>{timeZone.name}</option>
                                        ))}
                                    </Select>
                                    {errorLocal && formRef?.current?.values?.TimeZoneId?.length < 1 && (
                                      <StyledError PrimaryColor={PrimaryColor} template={activeTemplate}>
                                        Please select timezone
                                      </StyledError>
                                    )}
                                  </Grid>
                                </>
                              )}
                            {((contribution.paymentType === 'Simple' && contribution.taxType === 'No') ||
                              contribution.paymentType === 'Advance' ||
                              isInviteToJoin != null) &&
                              timeZones.length > 1 &&
                              isShowInput(step, ACCOUNT_FORM_FIELDS.timeZoneId) && (
                                <>
                                  <Grid
                                    container
                                    item
                                    direction="column"
                                    alignItems="flex-start"
                                    md={12}
                                    xs={12}
                                    style={{
                                      padding: '5px 15px',
                                      position: 'relative',
                                    }}
                                  >
                                    <Typography variant="h6" style={{ color: themedColor, fontSize: '20px' }}>
                                      Save Your Time Zone Preference
                                    </Typography>
                                  </Grid>
                                  <Grid
                                    container
                                    item
                                    direction="column"
                                    alignItems="flex-start"
                                    md={12}
                                    xs={12}
                                    style={{
                                      padding: '5px 15px',
                                      position: 'relative',
                                    }}
                                  >
                                    <Select
                                      required
                                      native
                                      labelId="timezones"
                                      className={[classNames.select, classNames.themedTextField].join(' ')}
                                      name={ACCOUNT_FORM_FIELDS.timeZoneId}
                                      onChange={e => {
                                        setFieldValue(ACCOUNT_FORM_FIELDS.timeZoneId, e.target.value);
                                        const color = e.target.value === 'Select Timezone' ? 'darkgrey' : 'black';
                                        setColorForSelect(color);
                                      }}
                                      value={formRef?.current?.values?.TimeZoneId}
                                      onBlur={handleBlur}
                                      disableUnderline
                                      style={{ padding: '15px', position: 'relative' }}
                                    >
                                      <option aria-label="Time Zone" value={null}>
                                        Select Timezone
                                      </option>
                                      {timeZones?.length > 0 &&
                                        timeZones.map(timeZone => (
                                          <option value={timeZone.countryName}>{timeZone.name}</option>
                                        ))}
                                    </Select>
                                    {errorLocal && formRef?.current?.values?.TimeZoneId.length > 1 && (
                                      <StyledError PrimaryColor={PrimaryColor} template={activeTemplate}>
                                        Please select timezone
                                      </StyledError>
                                    )}
                                  </Grid>
                                </>
                              )}
                            {isElectronicSignatureActive &&
                              customWaiverId === null &&
                              (isShowInput(step, ACCOUNT_FORM_FIELDS.signature) ||
                                step == PURCHASE_MODAL_STEPS.loggedIn) && (
                                <Grid
                                  container
                                  item
                                  direction="column"
                                  alignItems="flex-start"
                                  md={12}
                                  xs={12}
                                  style={{ padding: '5px 15px' }}
                                >
                                  <div style={{ position: 'relative' }}>
                                    <div
                                      type="button"
                                      onClick={clearSign}
                                      style={{
                                        cursor: 'pointer',
                                        position: 'absolute',
                                        right: '10px',
                                        top: '8px',
                                        // color: colorToUse.PrimaryColorCode,
                                        fontWeight: 'bold',
                                      }}
                                    >
                                      Clear
                                    </div>
                                    <SignatureCanvas
                                      ref={canvasRef}
                                      clearOnResize={false}
                                      penColor="black"
                                      canvasProps={{ height: '270px', className: classNames.inputField }}
                                      onEnd={() => {
                                        setDisableBtn(false);
                                        if (canvasRef?.current) {
                                          const val = canvasRef?.current?.getTrimmedCanvas().toDataURL('image/png');
                                          setFieldValue(ACCOUNT_FORM_FIELDS.signature, val);
                                        }
                                      }}
                                    />
                                  </div>
                                  <p
                                    style={{
                                      marginBottom: '0px',
                                      fontSize: '12px',
                                      marginLeft: '5px',
                                      color: PrimaryColor,
                                    }}
                                  >
                                    Sign Here To Join
                                  </p>
                                </Grid>
                              )}
                          </Grid>
                        )}

                        {showOtpModal && (
                          <SignInWithOtpModal
                            isOpen={showOtpModal}
                            email={values.Email}
                            isDarkModeEnabled={contribution.isDarkModeEnabled}
                            formRef={formRef}
                            onClose={closeModal}
                            accentColorCode={colorToUse?.AccentColorCode}
                            primaryColor={PrimaryColor}
                            textColor={textColor}
                            activeTemplate={activeTemplate}
                            errorMsg={usereError?.message}
                          />
                        )}
                        {isApplicationResponsePending ? (
                          <Tooltip
                            title="When your application is approved, you will be able to complete your enrollment here."
                            arrow
                          >
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                              <StyledButton
                                style={{
                                  borderRadius: borderRad,
                                  // color: lightOrDark(colorToUse?.PrimaryColorCode),
                                  // backgroundColor: colorToUse?.PrimaryColorCode,
                                }}
                                textColor={textColor}
                                backgroundColor={PrimaryColor}
                                marginTop
                                type="submit"
                                activeTemplate={activeTemplate}
                                disabled={true}
                              >
                                Application Pending
                              </StyledButton>
                            </div>
                          </Tooltip>
                        ) : (
                          <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <StyledButton
                              style={{
                                borderRadius: borderRad,
                                // color: lightOrDark(colorToUse?.PrimaryColorCode),
                                // backgroundColor: colorToUse?.PrimaryColorCode,
                              }}
                              textColor={textColor}
                              backgroundColor={PrimaryColor}
                              marginTop
                              type="submit"
                              activeTemplate={activeTemplate}
                              disabled={disableJoinBtn || clientPreviewMode}
                            >
                              {contribution?.id === '646d143d28ca862ab8aab522'
                                ? 'Download Book'
                                : isApplyContributionView &&
                                  (isApplicationNeedsToBeSubmitted || applicationRequiredButNotApproved)
                                ? 'Apply Now'
                                : `Join ${shouldHidePriceSection ? 'Now' : ''}`}
                            </StyledButton>
                          </div>
                        )}
                        {!applicationRequiredButNotApproved &&
                          step !== PURCHASE_MODAL_STEPS.join &&
                          step !== PURCHASE_MODAL_STEPS.init &&
                          !isMonthlySessionSubscription && (
                            <Grid item md={12} xs={12} style={{ padding: '5px 15px', marginTop: '10px' }}>
                              {customToS ? (
                                isElectronicSignatureActive ? (
                                  <Typography variant="caption">
                                    By proceeding, I agree to the{' '}
                                    <span
                                      style={{ color: PrimaryColor, cursor: 'pointer', fontWeight: '600' }}
                                      onClick={e => {
                                        e.preventDefault();
                                        setShowTerms(true);
                                      }}
                                    >
                                      Terms and Conditions
                                    </span>
                                    , I also agree to {serviceProviderName}
                                    {"'"}s{' '}
                                    <StyledLink color={PrimaryColor} href={customToS}>
                                      Terms and Conditions
                                    </StyledLink>
                                    , and I'm at least 18 years old.
                                  </Typography>
                                ) : (
                                  <Typography variant="caption">
                                    By proceeding, I agree to the{' '}
                                    <span
                                      style={{ color: PrimaryColor, cursor: 'pointer', fontWeight: '600' }}
                                      onClick={e => {
                                        e.preventDefault();
                                        setShowTerms(true);
                                      }}
                                    >
                                      Terms and Conditions
                                    </span>
                                    , I also agree to {serviceProviderName}
                                    {"'"}s{' '}
                                    <StyledLink color={PrimaryColor} href={customToS}>
                                      Terms and Conditions
                                    </StyledLink>
                                    , and I'm at least 18 years old.
                                  </Typography>
                                )
                              ) : (
                                <>
                                  <Typography variant="caption">
                                    {isElectronicSignatureActive ? 'By signing and clicking join' : 'By proceeding'}, I
                                    agree to the{' '}
                                    <StyledLink
                                      href="/"
                                      color={PrimaryColor}
                                      onClick={e => {
                                        e.preventDefault();
                                        setShowTerms(true);
                                      }}
                                    >
                                      Terms and Conditions
                                    </StyledLink>{' '}
                                    {customWaiverId && (
                                      <>
                                        and {serviceProviderName}'s{' '}
                                        <StyledDiv color={PrimaryColor}>{customWaiverTemplateName?.trim()}</StyledDiv>
                                      </>
                                    )}
                                    , and I'm at least 18 years old.
                                  </Typography>
                                  <br />
                                </>
                              )}
                            </Grid>
                          )}

                        {!applicationRequiredButNotApproved &&
                          step === PURCHASE_MODAL_STEPS.loggedIn &&
                          type == ContributionType.contributionOneToOne && (
                            <Grid item md={12} xs={12} style={{ padding: '5px 15px' }}>
                              <div>
                                <CreditCard
                                  handleChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                  setShowTerms={setShowTerms}
                                />
                              </div>
                            </Grid>
                          )}
                        {/* {usereError?.message && <CommonErrorMessage message={usereError.message} />} */}
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                          {usereError?.message &&
                            usereError?.message !== 'You have entered the wrong login code, try again' && (
                              <StyledError PrimaryColor={PrimaryColor} template={activeTemplate}>
                                {usereError.message}
                              </StyledError>
                            )}
                        </div>
                        {isShowWaiver && (
                          <CreateCustomWaiver
                            isOpen={isShowWaiver}
                            onSubmit={values =>
                              saveUserSignature(values).then(async () => {
                                isSignSaved(formRef.current.values.Signature);
                                if (isInviteToJoin === null) {
                                  if (membershipPaymentOptions.includes(typeOfPayment)) {
                                    const data = {
                                      paymentOption: typeOfPayment,
                                      contributionId: id,
                                      couponId: summaryRef?.current?.coupon?.id,
                                      priceId: priceId,
                                    };

                                    const response =
                                      type === ContributionType.contributionCommunity
                                        ? await purchaseService.purchaseCommunitySubscription(data)
                                        : await purchaseService.purchaseMembershipSubscription(data);
                                    setTimeout(() => {
                                      setLoadingPayment(false);
                                    }, 4000);
                                    if (response === '100discount' || response === 'Free session joined successfully') {
                                      // quick solution:redirect; todo: update state with purchased contribution and hide purchase modal
                                      fetchJoindedContribution();
                                    } else {
                                      setPurchaseSessionId(response);
                                    }

                                    return;
                                  }
                                  If(
                                    typeOfPayment === 'EntireCourse' ||
                                      typeOfPayment === 'SplitPayments' ||
                                      type === ContributionType.contributionCourse ||
                                      type === ContributionType.contributionMembership ||
                                      type === ContributionType.contributionCommunity ||
                                      (type === ContributionType.contributionOneToOne && typeOfPayment === 'Free'),
                                  )
                                    .then(
                                      mapTo({
                                        contributionId: contribution.id,
                                        paymentOption: typeOfPayment,
                                        priceId: priceId,
                                        couponId: summaryRef?.current?.coupon?.id,
                                      }),
                                    )
                                    .then(prepareEntire)
                                    .else(createTokenForSplitPayments);
                                } else {
                                  if (values?.Email === contribution?.serviceProviderEmail) {
                                    setshowUnableJoin(true);
                                    return false;
                                  } else {
                                    joinContribution({
                                      id,
                                      accessCode: isInviteToJoin,
                                    })
                                      .then(() => {
                                        dispatch(
                                          fetchContributionActions.success({
                                            ...contribution,
                                            isPurchased: true,
                                            purchaseStatus: 'succeeded',
                                            subscriptionStatus: { status: 'active' },
                                          }),
                                        );
                                      })
                                      .then(() => {
                                        handleProceedPurchaseStatus();
                                        return formRef.current?.handleSubmit();
                                      });
                                  }
                                }
                              })
                            }
                            contribution={contribution}
                            waiverData={waiverData}
                            isDarkModeAllowed={true}
                            onCancel={() => setIsShowWaiver(false)}
                            isCreateContribution={false}
                          />
                        )}
                      </Form>
                    </FormikScrollToError>
                  </>
                );
              }}
            </Formik>

            <ModalTermsAndConditions applyTheming showTerms={showTerms} onCancel={() => setShowTerms(false)} />
            {/* Login form ends here  */}
          </CardContent>
        </CardUI>
      </Grid>
      {/* {loadingPayment && (
        <Grid className={classNames.loaderWrapper} container justify="center"> */}
      {loadingPayment && <Loader />}
      {showUnableJoin && (
        <Modal
          title="Unable to join"
          isOpen={showUnableJoin}
          submitTitle="Close"
          hiddenCancel
          onCancel={() => {
            setshowUnableJoin(false);
          }}
          dontCancelOnSideClick
          onSubmit={() => {
            setshowUnableJoin(false);
          }}
          style={{ zIndex: '2000' }}
        >
          <p>Please use another email - you entered the account holder email of this service.</p>
        </Modal>
      )}
      {/* </Grid> */}
      {/* )} */}
    </Grid>
  );
}

CustomPurchaseBlock.defaultProps = {
  isPackage: false,
  onJoin: () => {},
};

CustomPurchaseBlock.propTypes = {
  isPackage: PropTypes.bool,
  onJoin: PropTypes.func,
};

export default CustomPurchaseBlock;
