import { constants } from 'actions/page';

const initialState = {
  header: '',
  isMobileSidebarOpen: true,
  showCoachDashboard: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case constants.CHANGE_HEADER:
      return { ...state, header: payload };
    case constants.TOGGLE_MOBILE_SIDEBAR:
      return { ...state, isMobileSidebarOpen: !state.isMobileSidebarOpen };
    case constants.TOGGLE_COACH_DASHBOARD:
      return { ...state, showCoachDashboard: payload };
    default:
      return state;
  }
};
