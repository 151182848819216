import React, { useEffect, useMemo, useRef } from 'react'
import PropTypes from 'prop-types'

const GalleryViewParticipant = ({ participantComponent: ParticipantComponent, ...restProps }) => {
  const participantRef = useRef()

  const participantResizeObserver = useMemo(
    () =>
      new ResizeObserver(([target]) => {
        const { width } = target.contentRect

        participantRef.current.style.height = `${width / 2}px`
      }),
    []
  )
  useEffect(() => {
    const participantNode = participantRef.current
    if (participantNode) {
      participantResizeObserver.observe(participantNode)
    }

    return () => {
      if (participantNode) {
        participantResizeObserver.unobserve(participantNode)
      }
    }
  }, [participantResizeObserver])

  return <ParticipantComponent {...restProps} ref={participantRef} />
}

GalleryViewParticipant.propTypes = {
  participantComponent: PropTypes.elementType.isRequired,
}

export default GalleryViewParticipant
