import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { TextField } from '@material-ui/core';
import { ThumbnailAvatar } from 'components/ThumbnailAvatar/ThumbnailAvatar';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Switch, StyledInput, StyledSlider } from 'components/switch/style';
import { addhttp, isValidLink } from 'utils/utils';
import uploadImage from 'assets/uploadLinkImage.png';
import editImage from 'assets/editLinkImage.png';
import { DraggableIcon } from '../icons/Draggable';
import { LinkIcon } from '../icons/Link';
import { LinkChainIcon } from '../icons/LinkChainIcon';
import { DeleteIcon } from '../icons/Delete';
import { AddIcon } from '../icons/Add';
import TextArea from 'components/FormUI/TextArea';
import { isEmpty } from 'lodash';
import { getIn } from 'formik';

const genericMappedUrl = (url, domain) => {
  return !url.includes(domain);
};

const linkValidator = {
  facebook: url => genericMappedUrl(url, 'facebook.com'),
  linkedin: url => genericMappedUrl(url, 'linkedin.com'),
  youtube: url => genericMappedUrl(url, 'youtube.com'),
  tiktok: url => genericMappedUrl(url, 'tiktok.com'),
  instagram: url => genericMappedUrl(url, 'instagram.com'),
};

const OtherLinks = ({ setFieldValues, values, setIsValidLinks, errors, touched, setTouched, setErrors }) => {
  const [otherLinksErrors, setOtherLinksErrors] = useState({});
  const profilePageViewModel = (values && values?.profilePageViewModel) || {};
  const customLinks = profilePageViewModel?.customLinks || [];
  useEffect(() => {
    const error = Boolean(Object.values(otherLinksErrors).filter(Boolean).length);
    setIsValidLinks(!error);
  }, [otherLinksErrors]);

  const onDragEnd = result => {
    if (!result.destination) {
      return;
    }

    const newList = Array.from(customLinks);
    const [removed] = newList.splice(result.source.index, 1);
    newList.splice(result.destination.index, 0, removed);
    setFieldValues('profilePageViewModel', { ...profilePageViewModel, customLinks: newList });
  };

  const onAddPress = () => {
    setFieldValues(
      'profilePageViewModel',
      {
        ...profilePageViewModel,
        customLinks: [
          {
            id: (customLinks || []).length + 1,
            uniqueName: '',
            link: '',
            isVisible: true,
            imagePath: '',
            index: ((customLinks || []).length + 1).toString(),
          },
          ...(customLinks || []),
        ],
      },
      false,
    );

    const touchedCustomLinks = touched?.profilePageViewModel?.customLinks || [];
    const errorCustomLinks = errors?.profilePageViewModel?.customLinks || [];
    const newTouched = {
      ...touched,
      profilePageViewModel: { customLinks: [{}, ...touchedCustomLinks] },
    };
    const newErrors = {
      ...errors,
      profilePageViewModel: { customLinks: [{}, ...errorCustomLinks] },
    };
    setTouched(newTouched);
    setErrors(newErrors);
  };

  const onDelete = index => {
    setFieldValues('profilePageViewModel', {
      ...profilePageViewModel,
      customLinks: [
        ...customLinks.filter((links, index2) => {
          return index !== index2;
        }),
      ],
    });
  };

  const onChange = (index, property, value) => {
    const toBeModified = customLinks[index];
    const modifiedObject = {
      ...toBeModified,
      [property]: value,
    };
    let error = false;

    if (modifiedObject.uniqueName.toLowerCase() in linkValidator) {
      error = linkValidator[modifiedObject.uniqueName.toLowerCase()](modifiedObject.link);
    }
    setOtherLinksErrors({
      ...otherLinksErrors,
      [modifiedObject.uniqueName]: error ? 'invalid url' : undefined,
    });
    setFieldValues('profilePageViewModel', {
      ...profilePageViewModel,
      customLinks: [
        ...customLinks.map((links, index2) => {
          return index === index2
            ? {
                ...toBeModified,
                [property]: value,
              }
            : links;
        }),
      ],
    });
  };

  return (
    <Grid container className="contribution-section profile-section">
      <Grid container justifyContent="space-between" alignItems="center" className="section-title">
        <Grid item alignItems="center" style={{ display: 'flex' }}>
          {/* <LinkChainIcon /> */}
          <span className="heading">
            <TextArea
              fprops={values}
              image={values.additionalLinksLabel}
              label={values.additionalLinksLabel}
              isLabelEditable
              isTextAreaModeEditable
              initialMode={''}
              changeMode={e => {}}
              labelName="additionalLinksLabel"
              placeholder="This section is used to describe your client's ideal outcome and the goal for working together. Feel free to describe the mission of this specific program."
              name="additionalLinksLabel"
              rows="1"
              fullWidth
              counter={1000}
              helperTextPosition="right"
              isTextAreaVisible={false}
              onClickResize={() => {}}
              // handleUploadIconFile={e => {
              //   handleUploadIconFile(e, iconType.purpose, formProps.setFieldValue);
              // }}
              handleIconClick={() => {}}
              isCollapsed={'purposeCollapsed'}
              id="contribution-details-purpose-textarea"
              profileNoLogo={true}
            />{' '}
            {/* <p style={{ marginTop: '0px' }}>Additional Links for Website</p> */}
          </span>
        </Grid>
        <Grid item>
          {' '}
          <span className="add-action" role="button" tabIndex="0" onClick={onAddPress} onKeyUp={onAddPress}>
            <span className="add-icon">
              <AddIcon />
            </span>
            <div style={{ fontFamily: 'Avenir', fontSize: '24px', fontWeight: 500, marginLeft: '5px' }}>
              Add New Links
            </div>
          </span>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="list">
            {droppableProvided => (
              <div {...droppableProvided.droppableProps} ref={droppableProvided.innerRef}>
                {customLinks.map((item, index) => (
                  <Draggable key={item.id.toString()} draggableId={item.id.toString()} index={index}>
                    {provided => (
                      <div
                        className={`draggable-item ${item.imagePath ? 'link-image' : 'no-link-image'}`}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                      >
                        <Grid container className="draggable-item-grid" spacing={12}>
                          <Grid className="d-flex" item sm={12} md={12} xs={12}>
                            <span className="draggable-icon" {...provided.dragHandleProps}>
                              <DraggableIcon />
                            </span>
                            <span style={{ position: 'relative', display: 'flex' }}>
                              <ThumbnailAvatar
                                avatarUrl={item.imagePath}
                                avatarPlaceholder={uploadImage}
                                updateUserImg={url => onChange(index, 'imagePath', url)}
                              />
                            </span>
                            <Grid container className="other-links-inputs">
                              <Grid className="d-flex" item xs={12} sm={5} md={12} lg={5}>
                                <TextField
                                  className="input-field"
                                  id="outlined-basic"
                                  margin="normal"
                                  variant="outlined"
                                  fullWidth
                                  placeholder="Title"
                                  value={item.uniqueName}
                                  onChange={e => onChange(index, 'uniqueName', e.target.value)}
                                  error={
                                    getIn(errors, `profilePageViewModel.customLinks[${index}].uniqueName`) &&
                                    getIn(touched, `profilePageViewModel.customLinks[${index}].uniqueName`)
                                  }
                                  helperText={
                                    getIn(errors, `profilePageViewModel.customLinks[${index}].uniqueName`) &&
                                    getIn(touched, `profilePageViewModel.customLinks[${index}].uniqueName`)
                                      ? getIn(errors, `profilePageViewModel.customLinks[${index}].uniqueName`)
                                      : ''
                                  }
                                />
                              </Grid>
                              <Grid className="d-flex" item xs={12} sm={7} md={12} lg={7}>
                                <TextField
                                  name="values.profilePageViewModel.customLinks["
                                  className="input-field link-input"
                                  id="outlined-basic"
                                  margin="normal"
                                  variant="outlined"
                                  fullWidth
                                  InputProps={{
                                    endAdornment: <LinkIcon link={addhttp(item.link)} />,
                                  }}
                                  placeholder="URL"
                                  value={item.link}
                                  onChange={e => onChange(index, 'link', e.target.value)}
                                  error={
                                    getIn(errors, `profilePageViewModel.customLinks[${index}].link`) &&
                                    getIn(touched, `profilePageViewModel.customLinks[${index}].link`)
                                  }
                                  helperText={
                                    getIn(errors, `profilePageViewModel.customLinks[${index}].link`) &&
                                    getIn(touched, `profilePageViewModel.customLinks[${index}].link`)
                                      ? getIn(errors, `profilePageViewModel.customLinks[${index}].link`)
                                      : ''
                                  }
                                />
                              </Grid>
                            </Grid>
                            <Switch className="switch other-link-switch">
                              <StyledInput
                                type="checkbox"
                                onClick={() => onChange(index, 'isVisible', !item.isVisible)}
                                checked={item.isVisible}
                              />
                              <StyledSlider className="slider round" />
                            </Switch>
                            <span
                              role="button"
                              tabIndex="0"
                              className="delete-container"
                              onClick={() => onDelete(index)}
                              onKeyUp={() => onDelete(index)}
                            >
                              <DeleteIcon className="other-link-delete" />
                            </span>
                          </Grid>
                        </Grid>
                      </div>
                    )}
                  </Draggable>
                ))}
                {droppableProvided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </Grid>
    </Grid>
  );
};

OtherLinks.propTypes = {
  values: PropTypes.shape({
    profilePageViewModel: {
      customLinks: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          uniqueName: PropTypes.string,
          link: PropTypes.string,
          imagePath: PropTypes.string,
          isVisible: PropTypes.bool,
          Index: PropTypes.string,
        }),
      ),
    },
  }).isRequired,
  setFieldValues: PropTypes.func.isRequired,
  setIsValidLinks: PropTypes.func.isRequired,
  errors: PropTypes.shape([]).isRequired,
  touched: PropTypes.shape([]).isRequired,
  setTouched: PropTypes.func.isRequired,
  setErrors: PropTypes.func.isRequired,
};

export default OtherLinks;
