import React from 'react'
import PropTypes from 'prop-types'

import { useChatClientChannelsUnreadMessagesCount } from 'hooks'

import './UnreadMessagesCount.scss'

const UnreadMessagesCount = ({ conversationIds }) => {
  const unreadMessagesCount = useChatClientChannelsUnreadMessagesCount(conversationIds)

  if (unreadMessagesCount === 0) {
    return null
  }

  return <p className="conversations-unread-messages-count">{unreadMessagesCount}</p>
}

UnreadMessagesCount.propTypes = {
  conversationIds: PropTypes.arrayOf(PropTypes.string),
}

UnreadMessagesCount.defaultProps = {
  conversationIds: null,
}

export default UnreadMessagesCount
