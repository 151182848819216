import { useEffect, useState } from 'react';

import useHttp from './http.hook';

const useConversations = type => {
  const [conversationIds, setConversationIds] = useState(null);
  const { loading, request, error } = useHttp();

  useEffect(() => {
    request(`/Chat/GetChatsByType?type=${type}`).then(chatIds => setConversationIds(chatIds));
  }, [request, type]);

  return {
    loading,
    conversationIds,
    error,
  };
};

export default useConversations;
