import React from 'react';
import classes from './SetDefaultModal.module.scss';
import Modal from 'components/UI/Modal';
import styled from 'styled-components';
import Row from '../../DefaultRow/DefaultRow';

const StyledBodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  alignitems: center;
  justifycontent: center;
  padding: 10px 24px;
`;

const SetDefaultModal = ({ sender, onCheckedChange, ...restProps }) => {
  return (
    <>
      <Modal {...restProps}>
        <StyledBodyWrapper>
          <Row sender={sender} onCheckedChange={onCheckedChange} />
        </StyledBodyWrapper>
      </Modal>
    </>
  );
};

export default SetDefaultModal;
