import React from 'react';
import { Helmet } from 'react-helmet';

const GoogleAnalytics = () => {
  return (
    <Helmet>
      <script id="google-analytic-page" async src="https://www.googletagmanager.com/gtag/js?id=G-S8N3EDYWN4"></script>
      <script id="google-analytic-page1">
        {`
                    window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());

                    gtag('config', 'G-S8N3EDYWN4');
                `}
      </script>
    </Helmet>
  );
};

export default GoogleAnalytics;
