export function setCookie(name, value, domain, days) {
  let expires = '';
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = '; expires='.concat(date.toUTCString());
  }
  document.cookie = `${name}=${value || ''}domain=${domain}${expires};Domain=${domain};path=/`;
}

export function getCookie(key) {
  const cookies = document.cookie.split(';');
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    if (cookie.startsWith(key + '=')) {
      return cookie.substring(key.length + 1, cookie.length);
    }
  }
  return null;
}

export function getCookieOfDomain(key, domain) {
  const cookies = document.cookie.split(';');
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    if (cookie.startsWith(key + '=') && cookie.includes('domain=' + domain)) {
      return cookie.substring(key.length + 1, cookie.length).split('domain=' + domain)[0];
    }
  }
  return null;
}
