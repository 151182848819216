import React from 'react';
import styled, { keyframes } from 'styled-components';
import { colors } from 'utils/styles/styles';

const fade = keyframes`
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
`;

const StyledRound = styled.div`
  /* position: absolute; */

  width: 8px;
  height: 8px;

  border-radius: 50%;

  background-color: ${colors.red};
  margin: 10px;
`;

const StyledContainer = styled.div`
  position: absolute;

  display: flex;
  align-items: center;

  color: ${colors.red};
  font-size: 12px;

  z-index: 100;
  cursor: default;
`;

const Round = ({ top, right }) => {
  return (
    <StyledContainer style={{ top: top, right: right }}>
      Recording
      <StyledRound />
    </StyledContainer>
  );
};

export default Round;
