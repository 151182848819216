import { createMuiTheme } from '@material-ui/core/styles';
import { colors } from 'utils/styles';

export const theme = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 992,
      lg: 1200,
      xl: 1920,
    },
  },
  palette: {
    colors: {
      borderLightGray: colors.borderLightGray,
      darkOceanBlue2: colors.darkOceanBlue2,
      lightBrown: colors.lightBrown,
      lightGray: colors.lightGray,
      shadowDark: colors.shadowDark,
      whisper: colors.whisper,
    },
    primary: {
      main: colors.darkOceanBlue,
    },
    shadows: {
      primary: `0 2px 4px 0 ${colors.shadowDark}`,
    },
  },
  typography: {
    fontFamily: [
      'Avenir',
      'Inter',
      '-apple-system',
      'BlinkMacSystemFont',
      'Segoe UI',
      'Roboto',
      'Helvetica',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
      'Apple Color Emoji',
      'Segoe UI Emoji',
      'Segoe UI Symbol',
    ].join(','),
  },
});
