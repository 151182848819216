import React, { memo, useState, useCallback, useEffect, useLayoutEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { useAccount, useChangedProps, useClientPreview } from 'hooks';
import useContribution from '../../../../hooks/useContribution';
import { getIpGlobal } from 'utils/utils';
import './SessionTime.scss';
import { useCompleteSelfPacedSessionTime } from '../../ClientCourseSessions/hooks/useCompleteSelfPacedSession';
import {
  fetchClientContribution,
  setSelectedSessionAndSessionTimeId,
  setShowMobileDetailedView,
} from 'actions/contributions';
import { SESSION_TYPES } from 'pages/ContributionView/ContributionSessions/constants';
import { detectContentMediaType, pickContentItemCard } from 'pages/ContributionView/ContributionSessions/helpers';
import { cloneDeep, isEqual } from 'lodash';
import {
  activeContribSessTabViewSettingsSelector,
  getActiveContribSessionById,
  getActiveContribSessionTimeById,
} from 'selectors/contribution';

const SessionTime = props => {
  const { number, sessionId, sessionTimeId, sessionTimeIndex, sessionNumber, sessionType = null, dragProps } = props;
  const contribution = useContribution();
  const { isPrerecorded, sessionTimes, name } = useSelector(s => getActiveContribSessionById(s, sessionId));
  const sessionTime = useSelector(s => getActiveContribSessionTimeById(s, sessionId, sessionTimeId));
  const { startTime, ignoreDateAvailable } = sessionTime || {};
  const { sessions } = contribution;
  const { user } = useAccount();
  const { clientPreviewMode } = useClientPreview();
  const dispatch = useDispatch();
  const { selectedSessionId, selectedSessionTimeOrContentId } = useSelector(activeContribSessTabViewSettingsSelector);
  const [shouldVideoBeDisabled, setShouldVideoBeDisabled] = useState(false);
  const { isCoach: isCoachOnly } = useAccount();
  const isCoach = isCoachOnly && !clientPreviewMode;
  const startTimeMoment = moment(startTime);

  const checkIsFuture = date => {
    return moment(date).isAfter(moment(), 'm');
  };

  useEffect(() => {
    if (!isCoach) {
      isVideoViewable();
    }
  }, [sessionTime]);

  const isVideoViewable = () => {
    if (
      sessionTime?.prerecordedSession?.extension?.toLowerCase()?.includes('jpg') ||
      sessionTime?.prerecordedSession?.extension?.toLowerCase()?.includes('png') ||
      sessionTime?.prerecordedSession?.extension?.toLowerCase()?.includes('jpeg') ||
      sessionTime?.prerecordedSession?.extension?.toLowerCase()?.includes('pdf') ||
      sessionTime?.prerecordedSession?.extension?.toLowerCase()?.includes('doc') ||
      sessionTime?.prerecordedSession?.extension?.toLowerCase()?.includes('xls')
    ) {
      if (ignoreDateAvailable) {
        if (checkIsFuture(startTime)) {
          setShouldVideoBeDisabled(true);
        } else {
          setShouldVideoBeDisabled(false);
        }
      } else {
        setShouldVideoBeDisabled(false);
      }
    } else {
      if (sessionTime?.mustWatchPriorSelfPacedRecords) {
        if (sessionTime?.ignoreDateAvailable) {
          if (checkIsFuture(startTime)) {
            setShouldVideoBeDisabled(true);
          } else {
            setShouldVideoBeDisabled(false);
          }
        }
        if (sessionTimes.length > 1 && sessionTimeIndex > 0) {
          if (!sessionTimes[sessionTimeIndex - 1]?.usersWhoViewedRecording?.includes(user.id)) {
            setShouldVideoBeDisabled(true);
          } else {
            if (!(sessionTime?.ignoreDateAvailable && checkIsFuture(startTime))) setShouldVideoBeDisabled(false);
          }
        } else {
          if (sessionNumber > 0) {
            for (let i = sessionNumber - 1; i >= 0; i--) {
              if (sessions[i].isPrerecorded) {
                if (
                  !sessions[i]?.sessionTimes[sessions[i]?.sessionTimes.length - 1]?.usersWhoViewedRecording?.includes(
                    user.id,
                  )
                ) {
                  setShouldVideoBeDisabled(true);
                } else {
                  if (shouldVideoBeDisabled && !checkIsFuture(startTime)) {
                    setShouldVideoBeDisabled(false);
                  } else if (
                    sessions[i]?.sessionTimes[sessions[i]?.sessionTimes.length - 1]?.usersWhoViewedRecording?.includes(
                      user.id,
                    )
                  ) {
                    setShouldVideoBeDisabled(false);
                  }
                }
                break;
              }
            }
          }
        }
      } else if (sessionTime?.ignoreDateAvailable && checkIsFuture(startTime)) {
        setShouldVideoBeDisabled(true);
      }
    }
  };

  const isWatchedSelfPaced = useMemo(() => {
    return isPrerecorded ? sessionTime?.usersWhoViewedRecording?.includes(user?.id) : false;
  }, [isPrerecorded, sessionTime?.usersWhoViewedRecording]);
  useLayoutEffect(() => {
    if (isPrerecorded && isWatchedSelfPaced) {
      const idToScroll = selectedSessionTimeOrContentId || selectedSessionId;
      if (idToScroll) {
        setTimeout(() => {
          const element = document.getElementById(idToScroll);
          if (element) {
            // element.scrollIntoView({ behavior: 'auto', block: 'center' });
          }
        }, 50);
      }
    }
  }, [isWatchedSelfPaced]);

  const commonItemProps =
    // useMemo(() =>
    {
      id: sessionTime?.id,
      onClick: () => {
        dispatch(setSelectedSessionAndSessionTimeId(sessionId, sessionTimeId));
        dispatch(setShowMobileDetailedView(true));
      },
      selected: selectedSessionTimeOrContentId === sessionTimeId,
      isClient: !isCoach,
      timeline: {
        hideTail: false,
        // isCompleted: sessionTime?.isCompleted || false,
        isLocked: shouldVideoBeDisabled,
      },
    };
  //   [session?.id, sessionTime?.id, isCoach, shouldVideoBeDisabled, selectedSessionTimeOrContentId],
  // );

  if (sessionType === SESSION_TYPES.LIVE) {
    const itemProps = {
      ...commonItemProps,
      disableDrag: true || !isCoach,
      title: `${
        checkIsFuture(startTimeMoment) && !sessionTime?.isCompleted ? `Upcoming on ` : ''
      }${startTimeMoment.format('MMM Do YYYY')} at ${startTimeMoment.format('h:mma')}`,
      timeline: {
        ...commonItemProps.timeline,
        isCompleted: sessionTime?.isCompleted || false,
      },
    };
    const ItemCard = pickContentItemCard(sessionType);
    return ItemCard ? <ItemCard {...itemProps} /> : null;
  }
  if (sessionType === SESSION_TYPES.SELFPACED) {
    const itemProps = {
      ...commonItemProps,
      dragProps,
      disableDrag: false || !isCoach,
      // title: sessionTime?.subCategoryName || `Lesson ${number}`,
      title: sessionTime?.subCategoryName || name || '',
      timeline: {
        ...commonItemProps.timeline,
        isCompleted:
          // sessionTime?.isCompleted ||
          // isWatchedSelfPaced,
          sessionTime?.completedSelfPacedParticipantIds?.includes(user?.id) || false,
      },
    };
    const itemMediaType = detectContentMediaType(
      sessionTime?.embeddedVideoUrl,
      sessionTime?.prerecordedSession,
      sessionTime,
    );
    const ItemCard = pickContentItemCard(sessionType, itemMediaType);
    return ItemCard ? <ItemCard {...itemProps} /> : null;
  }

  return <></>;
};

SessionTime.propTypes = {
  number: PropTypes.number.isRequired,
  // sessionTime: PropTypes.object.isRequired,
  sessionTimeIndex: PropTypes.number,
  dragProps: PropTypes.shape({
    dragProvided: PropTypes.object,
    dragSnapshot: PropTypes.object,
  }),
};

SessionTime.defaultProps = {
  sessionTimeIndex: 0,
  dragProps: {},
};

export default memo(SessionTime, isEqual);
