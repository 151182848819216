import { useEffect, useState } from 'react';

const useNetworkQualityLevel = participant => {
  const [networkQualityLevel, setNetworkQualityLevel] = useState(null);

  const onNetworkQualityLevelChanged = (networkQualityLevel, networkQualityStats) => {
    setNetworkQualityLevel(networkQualityLevel);
  };

  useEffect(() => {
    if (participant.on) {
      participant.on('networkQualityLevelChanged', onNetworkQualityLevelChanged);
    }

    return () => {
      if (participant.off) {
        participant.off('networkQualityLevelChanged', onNetworkQualityLevelChanged);
      }
    };
  }, [participant]);

  return networkQualityLevel;
};

export default useNetworkQualityLevel;
