import React, { useRef, useState, Fragment, useEffect } from 'react';
import classes from '../ContentPage.module.scss';
import DropDownWithTitle from './DropDown/DropDown';
import SwitchTextField from './SwitchTextField/SwitchTextField';
import { DOCUMENT_TYPES } from '../constants';
import useEmailMarketing from 'pages/CreateCampaign/Hooks/useEmailMarketing';
import Button from 'components/FormUI/Button';
import ConfirmationEmailEditorModal from 'pages/CreateQuizes/Components/Modals/ConfirmationEmailEditorModal/ConfirmationEmailEditorModal';
import { getCustomTemplateByQuiztId, updateEmailTemplate } from 'pages/CreateQuizes/Services/CreateQuizes.service';
import useQuiz from 'hooks/useQuiz';
import Loader from 'components/UI/Loader';
import useScreenView from 'hooks/useScreenView';

export default function RightColumn({ formProps }) {
  const { activeQuiz } = useQuiz();
  const { verifiedSendingAddresses } = useEmailMarketing();
  const { mobileView } = useScreenView();
  const { values, setFieldValue } = formProps;
  const [isCustomEmailModalOpen, setIsCustomEmailModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [confirmationEmailDefaultData, setConfirmationEmailDefaultData] = useState(null);
  const {
    useCase,
    quizType,
    type,
    title,
    isPassingGradeRequired,
    passingPercentage,
    sendResultToClients,
    isBrandingColorEnabled,
    questions,
    isPublished,
    isConfirmationEmailEnabled,
  } = values;

  const isForm = type === DOCUMENT_TYPES.Form;
  const isQuiz = type === DOCUMENT_TYPES.Quiz;

  useEffect(() => {
    if (isForm && activeQuiz?.id) {
      setLoading(true);
      getCustomTemplateByQuiztId(activeQuiz.id)
        .then(res => {
          const data = res?.customTemplates?.find(t => t?.emailType === 'QuizConfirmationEmail');
          setConfirmationEmailDefaultData({
            ...data,
            attachedDomainEmail: res?.attachedDomainEmail,
            templateId: res?.templateId,
          });
        })
        .catch(err => {})
        .finally(() => {
          setLoading(false);
        });
    }
  }, [isForm, activeQuiz.id]);

  const updateTemplateData = data => {
    setLoading(true);
    updateEmailTemplate(data)
      .then(() => {
        setConfirmationEmailDefaultData(data);
      })
      .catch(err => {})
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      {loading && <Loader />}
      <div className={classes.header}>
        <div className={classes.header_text}>{isForm ? `Form Settings` : `Quiz Settings`}</div>
      </div>
      <div className={classes.container_right}>
        <DropDownWithTitle
          title={'Use Case'}
          data={[
            { title: 'Standalone', value: 'StandAlone' },
            { title: 'Integrate into a service (Upcoming)', value: 'integrate_into_service' },
          ]}
          val={useCase}
          setVal={val => {
            setFieldValue('useCase', val);
            setFieldValue('isSaved', false);
          }}
        />
        <DropDownWithTitle
          isPublished={isPublished}
          title={'Type'}
          data={[
            { title: 'Quiz', value: 'Quiz' },
            { title: 'Form', value: 'Form' },
          ]}
          val={type}
          setFieldValue={setFieldValue}
          name={title}
          questions={questions}
          setVal={val => {
            // setFieldValue('type', val);
            setFieldValue('isSaved', false);
          }}
        />
        {isQuiz && (
          <DropDownWithTitle
            title={'Quiz Type'}
            data={[
              { title: 'Scored Quiz', value: 'Scored' },
              { title: 'Personality Quiz (Upcoming)', value: 'Personality Quiz' },
            ]}
            val={quizType}
            setVal={val => {
              setFieldValue('quizType', val);
              setFieldValue('isSaved', false);
            }}
          />
        )}
        <DropDownWithTitle
          title={'Title'}
          val={title}
          setVal={val => {
            setFieldValue('title', val);
            setFieldValue('isSaved', false);
          }}
          textField={true}
        />
        <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
          {isQuiz && (
            <SwitchTextField
              val={isPassingGradeRequired}
              setVal={val => {
                if (val === true) {
                  setFieldValue('isPassingGradeRequired', true);
                } else {
                  setFieldValue('isPassingGradeRequired', false);
                }

                setFieldValue('isSaved', false);
              }}
              textFieldIncluded={true}
              passingGrade={passingPercentage}
              setPassingGrade={val => {
                setFieldValue('passingPercentage', val);
                setFieldValue('isPassingGradeRequired', true);
                setFieldValue('isSaved', false);
              }}
              text={'Define a passing grade'}
            />
          )}
          {isQuiz && (
            <SwitchTextField
              val={sendResultToClients}
              setVal={val => {
                setFieldValue('sendResultToClients', val);
                setFieldValue('isSaved', false);
              }}
              text={'Email results to clients'}
            />
          )}
          <SwitchTextField
            val={isBrandingColorEnabled}
            setVal={val => {
              setFieldValue('isBrandingColorEnabled', val);
              setFieldValue('isSaved', false);
            }}
            text={'Use profile branding colors'}
          />
          {isForm && (
            <SwitchTextField
              val={isConfirmationEmailEnabled}
              setVal={val => {
                setFieldValue('isConfirmationEmailEnabled', val);
                setFieldValue('isSaved', false);
                if (
                  val &&
                  (!confirmationEmailDefaultData?.attachedDomainEmail || !confirmationEmailDefaultData?.templateId)
                ) {
                  const data = {
                    ...confirmationEmailDefaultData,
                    attachedDomainEmail: verifiedSendingAddresses[0]?.email,
                    templateId: activeQuiz?.id,
                  };
                  updateTemplateData(data);
                }
              }}
              text={'Enable Confirmation Email'}
              disabled={verifiedSendingAddresses?.length === 0}
              disabledTooltipText="Connect your domain and sending address to enable it."
            />
          )}
          {isConfirmationEmailEnabled && (
            <Button
              className={classes.customize_email_btn}
              onClick={() => {
                setIsCustomEmailModalOpen(true);
              }}
            >
              Customize Confirmation Email
            </Button>
          )}

          {isCustomEmailModalOpen && (
            <ConfirmationEmailEditorModal
              isOpen={isCustomEmailModalOpen}
              onSubmit={() => {
                setIsCustomEmailModalOpen(false);
              }}
              onCancel={() => {
                setIsCustomEmailModalOpen(false);
              }}
              maxWidth="none"
              minWidth={!mobileView ? '1036px' : ''}
              hiddenCancel
              disableFooter
              CrossIconHide
              ignoreDisable
            />
          )}
        </div>
      </div>
    </>
  );
}
