import { Dialog, DialogContent } from '@material-ui/core';
import Popup from 'components/Popup/Popup';
import VideoPlayer from 'components/UI/VideoPlayer/VideoPlayer';
import { RECORDING_VIDEO_STATUS } from 'constants.js';
import useRouter from 'hooks/useRouter';
import React, { useCallback, useEffect, useState } from 'react';
import axiosInstance from 'utils/axiosInstance';
import { addhttp } from 'utils/utils';
import { redirectToAppIfIsCoachDomain } from 'utils/subdomains';
import './ContributionRecordings.scss';
import PasscodePopup from './components/PasscodePupop';

const ContributionRecordings = ({
  match: {
    params: { contributionId, sessionTimeId },
  },
}) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [recordingList, setRecordingList] = useState([]);
  const [loadingDomainName, setLoadingDomainName] = useState(true);
  const [isShowInfoPopup, setIsShowInfoPopup] = useState(false);
  const [isPlayVideo, setIsPlayVideo] = useState(false);
  const { subdomain, parentDomain, pathname } = useRouter();
  const [videoLink, setVideLink] = useState('');
  const [passcode, setPasscode] = useState('');
  const [isPasscodeWrong, setIsPasscodeWrong] = useState(false);
  const [isShowPasscodePopup, setIsShowPasscodePopup] = useState(false);
  const { domain } = useRouter();
  const [recordingCount, setRecordingCount] = useState(0);

  const getCoachSubdomain = useCallback(
    () => axiosInstance.get(`/ProfilePage/GetProfileLinkNameByContributionId?ContributionId=${contributionId}`),
    [contributionId],
  );

  const getPresignedURL = useCallback(
    recording =>
      axiosInstance.get(
        `/api/SharedRecording/GetSharedRecordingPresignedURL?contributionId=${contributionId}&sessionTimeId=${sessionTimeId}&roomId=${
          recording.roomId
        }${passcode ? `&passCode=${passcode}` : ''}`,
      ),
    [contributionId, sessionTimeId, passcode],
  );

  useEffect(() => {
    if (subdomain) {
      setLoadingDomainName(false);
      return;
    }
    getCoachSubdomain()
      .then(res => {
        if (res.data) {
          window.location.replace(addhttp(`${res.data}.${parentDomain}${pathname}`));
        }
      })
      .finally(() => {
        setLoadingDomainName(false);
      });
  }, [contributionId]);

  const getSharedRecordings = () => {
    axiosInstance
      .get(
        `/api/SharedRecording/GetSharedRecordingsInfo?contributionId=${contributionId}&sessionTimeId=${sessionTimeId}${
          passcode ? `&passCode=${passcode}` : ''
        }`,
      )
      .then(res => {
        setIsShowPasscodePopup(false);
        setIsPasscodeWrong(false);
        setRecordingList(res.data);
      })
      .catch(err => {
        if (
          err.response.status === 400 &&
          err.response.data === 'Correct PassCode is requeire to get the required recording information'
        ) {
          if (passcode) {
            setIsPasscodeWrong(true);
          }
          if (recordingCount > 0) {
            setIsPasscodeWrong(true);
          }
          setRecordingCount(count => count + 1);
          setIsShowPasscodePopup(true);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getSharedRecordings();
  }, []);

  const checkVideoStatus = status => {
    if (status !== RECORDING_VIDEO_STATUS.available) {
      setIsShowInfoPopup(true);
      return false;
    }
    return true;
  };

  const onSelectOption = option => {
    if (!checkVideoStatus(option.status)) return;
    getPresignedURL(option).then(res => {
      setVideLink(res.data);
      setIsPlayVideo(true);
    });
  };

  useEffect(() => {
    if (recordingList?.length === 1) {
      onSelectOption(recordingList[0]);
    }
  }, [recordingList]);

  const getDuration = sec => {
    const date = new Date(0);
    date.setSeconds(sec); // specify value for SECONDS here
    return date.toISOString().substring(11, 19);
  };

  return (
    <>
      {recordingList.length > 1 && (
        <Dialog fullWidth open={!isShowPasscodePopup && !loading && !loadingDomainName && !isPlayVideo}>
          <DialogContent>
            <div className="free-recording-container">
              <div>
                <p className="h4 mb-0 font-weight-light">Please select the video you would like to view</p>
              </div>
              <ol className="recording-list">
                {recordingList.map(recording => (
                  <li
                    onClick={() => {
                      onSelectOption(recording);
                    }}
                  >
                    {`${recording.compositionFileName} ${getDuration(recording.duration)}`}
                  </li>
                ))}
              </ol>
            </div>
          </DialogContent>
        </Dialog>
      )}

      {isPlayVideo && (
        <VideoPlayer
          onCloseVideo={() => redirectToAppIfIsCoachDomain(domain, '/dashboard')}
          link={videoLink}
          error={error}
          loading={loading}
          isCohealer={false}
          isPublicVideo
        />
      )}

      {isShowInfoPopup && (
        <Popup
          title="Video is processing"
          text="Video is processing. Please, try out later!"
          open={isShowInfoPopup}
          onCloseText="OK"
          onClose={() => setIsShowInfoPopup(false)}
        />
      )}
      <PasscodePopup
        isShowPasscodePopup={isShowPasscodePopup}
        passcode={passcode}
        setPasscode={setPasscode}
        error={error}
        isPasscodeWrong={isPasscodeWrong}
        onPasscodeSubmit={getSharedRecordings}
      />
    </>
  );
};

export default ContributionRecordings;
