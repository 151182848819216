import React from 'react';
import { useField } from 'formik';

import UISelect from '../UI/Select';

function Select(props) {
  const [field, meta] = useField(props);

  return <UISelect error={meta.touched ? meta.error : ''} {...field} {...props} />;
}

export default Select;
