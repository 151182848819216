import React from 'react';
import classes from './DeleteContactsModal.module.scss';
import Checkbox from '@mui/material/Checkbox';
import { useSelector } from 'react-redux';
const DeleteContactModal = ({ contacts = [], checkboxValue, onCheckboxChange }) => {
  const { contacts: LSContacts } = useSelector(state => state.contacts);

  return (
    <>
      <div className={classes.container}>
        <p className={classes.title}>
          Are you sure you want to delete this/these contact(s)? Your action cannot be undone.
        </p>
        <div className={classes.checkbox_container}>
          <Checkbox className={classes.checkbox} value={checkboxValue} onChange={onCheckboxChange} />
          <p className={classes.checkbox_label}>
            Please note: This action will remove the contact from all lists and services. They will need to re-enroll to
            be added back to your contacts.
          </p>
        </div>
        <div className={classes.selected_contacts_container}>
          <p className={classes.delete_title}>Following contact(s) will be deleted:</p>
          <p className={classes.selected_contacts}>
            {LSContacts?.filter(c => contacts.includes(c.id))?.map(item => {
              return <div>{`${item?.firstName} ${item?.lastName}`}</div>;
            })}
          </p>
        </div>
      </div>
    </>
  );
};

export default DeleteContactModal;
