import React, { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { endTestVideoChat } from 'actions/testVideoChart'
import { useVideoTrack } from './hooks/useVideoTrack'
import { useAudioTrack } from './hooks/useAudioTrack'
import { useScreenTrack } from './hooks/useScreenTrack'
import { VideoChartContent } from '../VideoChartContent'
import '../VideoChat.scss'

export const TestVideoChartContainer = () => {
  const dispatch = useDispatch()
  const {
    videoTrack: localVideoTrack,
    isEnabled: isLocalVideoEnabled,
    setIsEnabled: setIsLocalVideoEnabled,
    deviceLabel: videoDeviceLabel,
    switchDevice: switchVideoDevice,
  } = useVideoTrack()
  const {
    audioTrack: localAudioTrack,
    isEnabled: isLocalAudioEnabled,
    setIsEnabled: setIsLocalAudioEnabled,
    deviceLabel: audioDeviceLabel,
    switchDevice: switchAudioDevice,
    mediaStreamTrack: audioMediaStreamTrack,
  } = useAudioTrack()
  const {
    isEnabled: isLocalScreenEnabled,
    setIsEnabled: setIsLocalScreenEnabled,
    screenTrack: localScreenTrack,
  } = useScreenTrack()
  const user = useSelector((state) => state.account.user)

  const onLeaveChat = useCallback(() => {
    dispatch(endTestVideoChat())
  }, [dispatch])

  return (
    <div className="video-chat-container">
      <VideoChartContent
        className="video-chat-container__video-chat"
        chatId=""
        room={{
          localParticipant: {
            identity: `${user.lastName} ${user.firstName}`,
          },
        }}
        participants={[]}
        dominantSpeakerParticipant={null}
        screenShareParticipants={[]}
        onLeaveChat={onLeaveChat}
        isTest
        videoTrack={{
          localVideoTrack,
          isLocalVideoEnabled,
          setIsLocalVideoEnabled,
          videoDeviceLabel,
          switchVideoDevice,
        }}
        audioTrack={{
          isLocalAudioEnabled,
          setIsLocalAudioEnabled,
          audioDeviceLabel,
          switchAudioDevice,
          audioMediaStreamTrack,
          localAudioTrack,
        }}
        screenTrack={{
          isLocalScreenEnabled,
          setIsLocalScreenEnabled,
          localScreenTrack,
        }}
      />
    </div>
  )
}
