import PeopleIcon from '@material-ui/icons/People';
import PropTypes from 'prop-types';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Box, Container, Grid, useMediaQuery } from '@material-ui/core';
import { useSelector } from 'react-redux';
import * as R from 'ramda';
import * as paidTier from 'selectors/paidTier';

import { cohereAcademyIsEnableSelector } from 'selectors/user';

import SmallLoader from 'components/UI/SmallLoader';
import {
  useStyles,
  StyledContainer,
  PreviewImage,
  StyledTitle,
  StyledAuthor,
  StyledMembersCount,
  StyledUnreadPosts,
  StyledTheme,
  StyledRating,
  EnrollButton,
  DisabledEnrollButton,
  AvatarComponent,
  ExpiresLabel,
  DisabledFeatureButton,
  DisableFeatureButton,
} from './style';
import { useAccount, useShallowEqualSelector } from 'hooks/index';
import { SIGNUP_TYPES } from 'helpers/constants';
import { colors } from 'utils/styles';
import { PAID_TIER_TITLES } from '../../constants';

const getInitialSymbol = R.compose(R.toUpper, R.head);

const AvatarWrapper = props => {
  const { children, isLink, userId } = props;

  if (isLink) {
    return <Link to={`/cohealer-about/${userId}/about`}>{children}</Link>;
  }

  return <>{children}</>;
};

AvatarWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  isLink: PropTypes.bool.isRequired,
  userId: PropTypes.string.isRequired,
};

const getMessagesText = messagesCount => {
  if (messagesCount === 0) return 'No new messages';
  if (messagesCount === 1) return 'New message';
  return 'New messages';
};

const AdvertismentCard = ({
  img,
  title,
  userId,
  author,
  theme,
  handleEnrollOnclick,
  isActive,
  handleLeaveOnclick,
  isShowLoader,
  AvatarUrl,
  isShowEnrollButton,
  verticalView,
  unreadMessagesCount,
  membersCount,
  isBasicNotification,
  hideMembers,
  isCoach,
}) => {
  const mobileView = useMediaQuery('(max-width: 1100px)');
  const extramobileView = useMediaQuery('(max-width: 1200px)');
  const location = useLocation();
  const classes = useStyles();
  const isEnable = useSelector(cohereAcademyIsEnableSelector);
  const fullname = author.split(' ');
  const { signupType } = useSelector(state => state.account);
  const { user: loggedInUser } = useAccount();
  const ResponsivemobileView1500 = useMediaQuery('(max-width: 1550px)');
  const contributionViewCardStyle = isShowEnrollButton && classes.contributionView;
  const membershipCardStyle = verticalView && classes.academyMembershipVerticalMedia;
  const basicNotificationCardStyle = isBasicNotification && classes.basicNotification;
  // const activePaidTierCurrentPlan = useShallowEqualSelector(paidTier.activePaidTierCurrentPlanSelector);

  const styledContainerViews = basicNotificationCardStyle || membershipCardStyle || contributionViewCardStyle;

  const imageTopLevelStyle = !isShowEnrollButton ? classes.topLevel : classes.topLevelDashboard;
  const imageBasicNotificationStyle = isBasicNotification && classes.basicNotificationImage;

  const imageClassName = imageBasicNotificationStyle || imageTopLevelStyle;

  const infoMidLevelStyle = !isShowEnrollButton ? classes.midLevel : classes.midLevelDashboard;
  const infoBasicNotificationStyle = isBasicNotification && classes.basicNotificationInfo;

  const infoClassName = infoBasicNotificationStyle || infoMidLevelStyle;

  // const shouldBeDisabled =
  //   activePaidTierCurrentPlan?.displayName === PAID_TIER_TITLES.launch && loggedInUser.isCohealer;
  const shouldBeDisabled =
    signupType === SIGNUP_TYPES.TYPE_A ||
    signupType === SIGNUP_TYPES.TYPE_B ||
    loggedInUser.signupType === SIGNUP_TYPES.TYPE_B ||
    loggedInUser.signupType === SIGNUP_TYPES.TYPE_A;
  const messagesText = {
    0: 'Now new messages',
    1: 'New message',
  };

  if (loggedInUser?.id === '65cc390b6c651039c09ae644') {
    return null;
  }
  return (
    <>
      {location.pathname == '/account/billing/coach' ? (
        <StyledContainer style={{ marginBottom: '10px' }} className={styledContainerViews}>
          {/* <AvatarWrapper isLink={!isBasicNotification} userId={userId}>
      <AvatarComponent className="mx-2" alt={`${fullname[0]} ${fullname[1]}`} src={AvatarUrl}>
        {`${fullname[0] && getInitialSymbol(fullname[0])}${fullname[1] && getInitialSymbol(fullname[1])}`}
      </AvatarComponent>
    </AvatarWrapper> */}

          <Grid container className={classes.cardBox}>
            <Grid item xs={12} className={imageClassName}>
              <PreviewImage src={img} />
            </Grid>
            <Grid item lg={12} xs={6} className={infoClassName}>
              {!isShowEnrollButton && (
                <StyledRating borderRadius="5px">
                  <Grid container spacing={1}>
                    <Grid
                      container
                      item
                      xs={12}
                      justify="flex-end"
                      style={{
                        position: 'relative',
                        bottom: '109px',
                        right: mobileView ? '-150px' : '0px' && extramobileView ? '-250px' : '0px',
                      }}
                    >
                      {shouldBeDisabled ? (
                        <DisableFeatureButton>Featured</DisableFeatureButton>
                      ) : (
                        <button type="button" style={{ fontSize: '11px', padding: '3px 8px' }}>
                          Featured
                        </button>
                      )}
                    </Grid>
                  </Grid>
                </StyledRating>
              )}
              <StyledTitle>The Cohere Academy</StyledTitle>
              <StyledAuthor fontSize="12px">You'll be able to enroll free once you upgrade & join!</StyledAuthor>
              {isBasicNotification && (
                <Grid container alignItems="center">
                  <Grid item>
                    <PeopleIcon fontSize="small" />
                  </Grid>
                  <Grid item>
                    <StyledMembersCount>{membersCount} Members</StyledMembersCount>
                  </Grid>
                </Grid>
              )}
              {isBasicNotification && (
                <Grid container alignItems="center">
                  <Grid className={unreadMessagesCount ? classes.unreadPostsIcon : classes.noUnreadPostsIcon} item>
                    {unreadMessagesCount}
                  </Grid>
                  <Grid item>
                    <StyledMembersCount>{getMessagesText(unreadMessagesCount)}</StyledMembersCount>
                  </Grid>
                </Grid>
              )}
              {!isShowEnrollButton && <StyledTheme fontSize="12px">{theme}</StyledTheme>}
            </Grid>
            {!isShowEnrollButton && (
              <Grid container item lg={12} xs={6} className={classes.bottomLevel} justify="center" alignItems="center">
                {isEnable && !isShowLoader?.status && <DisabledEnrollButton>Enroll</DisabledEnrollButton>}
                {!isEnable && !isShowLoader?.status && !isActive && (
                  <EnrollButton
                    borderRadius="2px"
                    padding="5px 28px"
                    fontSize="13px"
                    fontWeight={600}
                    onClick={handleEnrollOnclick}
                  >
                    Enroll
                  </EnrollButton>
                )}

                {isActive && !isShowLoader?.status && !isEnable && (
                  <EnrollButton onClick={handleLeaveOnclick}>Leave</EnrollButton>
                )}
                {isShowLoader?.status && (
                  <div>
                    <ExpiresLabel>
                      Please wait a minute while we verify your account is eligable to join the Academy.
                    </ExpiresLabel>
                    <SmallLoader size={20} border={2} />
                  </div>
                )}
              </Grid>
            )}
          </Grid>
        </StyledContainer>
      ) : (
        <StyledContainer className={styledContainerViews}>
          <AvatarWrapper isLink={!isBasicNotification} userId={userId}>
            <AvatarComponent className="mx-2" alt={`${fullname[0]} ${fullname[1]}`} src={AvatarUrl}>
              {`${fullname[0] && getInitialSymbol(fullname[0])}${fullname[1] && getInitialSymbol(fullname[1])}`}
            </AvatarComponent>
          </AvatarWrapper>

          <Grid container className={classes.cardBox}>
            <Grid item xs={12} className={imageClassName}>
              <PreviewImage src={img} />
            </Grid>
            <Grid item xs={12} className={infoClassName}>
              <StyledTitle>{title}</StyledTitle>
              <StyledAuthor>{author}</StyledAuthor>
              {isBasicNotification &&
                (isCoach ? (
                  <Grid container alignItems="center">
                    <Grid item>
                      <PeopleIcon fontSize="small" />
                    </Grid>
                    <Grid item>
                      <StyledMembersCount>{membersCount} Members</StyledMembersCount>
                    </Grid>
                  </Grid>
                ) : (
                  hideMembers === false && (
                    <Grid container alignItems="center">
                      <Grid item>
                        <PeopleIcon fontSize="small" />
                      </Grid>
                      <Grid item>
                        <StyledMembersCount>{membersCount} Members</StyledMembersCount>
                      </Grid>
                    </Grid>
                  )
                ))}
              {isBasicNotification && (
                <Grid container alignItems="center">
                  <Grid className={unreadMessagesCount ? classes.unreadPostsIcon : classes.noUnreadPostsIcon} item>
                    {unreadMessagesCount}
                  </Grid>
                  <Grid item>
                    <StyledMembersCount>{getMessagesText(unreadMessagesCount)}</StyledMembersCount>
                  </Grid>
                </Grid>
              )}
              {!isShowEnrollButton && <StyledTheme>{theme}</StyledTheme>}
              {!isShowEnrollButton && (
                <StyledRating>
                  <Grid container spacing={1}>
                    <Grid container item xs={12} justify="flex-end" style={{ display: 'relative' }}>
                      {shouldBeDisabled ? (
                        <DisableFeatureButton>Featured </DisableFeatureButton>
                      ) : (
                        <button style={{ position: 'absolute', top: '20px', borderRadius: '5px' }} type="button">
                          Featured{' '}
                        </button>
                      )}
                    </Grid>
                  </Grid>
                </StyledRating>
              )}
            </Grid>
            {!isShowEnrollButton && (
              <Grid container item xs={12} className={classes.bottomLevel} justify="center" alignItems="center">
                {isEnable && !isShowLoader?.status && <DisabledEnrollButton>Enroll</DisabledEnrollButton>}
                {!isEnable && !isShowLoader?.status && !isActive && (
                  <EnrollButton onClick={handleEnrollOnclick}>Enroll</EnrollButton>
                )}

                {isActive && !isShowLoader?.status && !isEnable && (
                  <EnrollButton onClick={handleLeaveOnclick}>Leave</EnrollButton>
                )}
                {isShowLoader?.status && (
                  <div>
                    <ExpiresLabel>
                      Please wait a minute while we verify your account is eligable to join the Academy.
                    </ExpiresLabel>
                    <SmallLoader size={20} border={2} />
                  </div>
                )}
              </Grid>
            )}
          </Grid>
        </StyledContainer>
      )}
    </>
  );
};

export default AdvertismentCard;
