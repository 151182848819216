import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Icon } from '@mdi/react';
import { mdiCheck } from '@mdi/js';

const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  position: relative;
`;

// Hide checkbox visually but remain accessible to screen readers.
// Source: https://polished.js.org/docs/#hidevisually
const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  top: 0;
  left: 0;
  width: 100%;
  cursor: inherit;
  height: 100%;
  margin: 0;
  opacity: 0;
  padding: 0;
  z-index: 1;
  position: absolute;
`;

const StyledCheckbox = styled.div`
  display: inline-block;
  width: 16px;
  height: 16px;
  border: 2px solid;
  border-color: rgba(0, 0, 0, 0.54);
  border-radius: 3px;
  transition: all 150ms;

  svg {
    transition: opacity 0.2s;
    opacity: ${props => (props.checked ? '1' : '0')};
  }
`;

const Checkbox = ({ className, checked, ...props }) => {
  return (
    <CheckboxContainer className={className}>
      <HiddenCheckbox checked={checked} {...props} />
      <StyledCheckbox checked={checked}>
        <Icon path={mdiCheck} />
      </StyledCheckbox>
    </CheckboxContainer>
  );
};

Checkbox.propTypes = {
  className: PropTypes.string,
  checked: PropTypes.bool.isRequired,
};

Checkbox.defaultProps = {
  className: null,
};

export default Checkbox;
