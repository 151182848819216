import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import CardSession from './CardSession/CardSession';
import CardSales from './CardSales/CardSales';
import './Dashboard.scss';

const RenderCards = (cards, type) =>
  cards.map(card => (
    <Grid
      key={card.id}
      item
      xs={12}
      classes={{
        root: 'grid_vertical_item',
      }}
    >
      {type === 'sessions' ? <CardSession card={card} /> : <CardSales card={card} />}
    </Grid>
  ));

export default RenderCards;
