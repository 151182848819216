import { handleActions } from 'redux-actions';

import * as actions from 'actions/community';

const initialState = {
  loading: true,
  error: null,
  posts: [],
};

const communityPosts = handleActions(
  new Map([
    [
      actions.fetchAllPostsForContributionActions.request,
      state => ({
        ...state,
        error: null,
        loading: true,
        // commented so we can have a smooth refresh for posts
        // posts: [],
      }),
    ],
    [
      actions.fetchAllPostsForContributionActions.success,
      (state, action) => {
        const fetchedPosts = action.payload.posts;
        const d = {
          ...state,
          loading: false,
          error: null,
          fetchedPosts,
          ...action.payload,
        };
        return d;
      },
    ],
    [
      actions.fetchAllPostsForKeywordActions.success,
      (state, action) => {
        const fetchedPosts = action.payload.posts;
        const mergedPosts = state.posts.concat(fetchedPosts);
        const data = {
          ...state,
          loading: false,
          error: null,
          ...action.payload,
        };

        const updatedData = {
          ...data,
          posts: mergedPosts,
          fetchedPosts: fetchedPosts,
        };
        return updatedData;
      },
    ],
    [
      actions.fetchAllPostsForContributionActions.error,
      (state, action) => ({
        ...state,
        loading: false,
        error: action.payload,
        posts: [],
      }),
    ],
    [
      actions.fetchByPagePostsForContributionActions.request,
      state => ({
        ...state,
        error: null,
        loading: true,
      }),
    ],
    [
      actions.fetchByPagePostsForContributionActions.success,
      (state, action) => {
        return {
          ...state,
          loading: false,
          error: null,
          ...action.payload,
          posts: [...state.posts, ...action.payload.posts],
        };
      },
    ],
    [
      actions.fetchByPagePostsForContributionActions.error,
      (state, action) => ({
        ...state,
        loading: false,
        error: action.payload,
        posts: [],
      }),
    ],
  ]),
  initialState,
);

export default communityPosts;
