export const UPDATE_USER_ACTION = 'UPDATE_USER_ACTION';
export const UPDATE_PAIDTIEROPTIONS_PERIOD = 'UPDATE_PAIDTIEROPTIONS_PERIOD';
export const TEMPORARY_DATA_FOR_SWITCHING_ROLE = 'TEMPORARY_DATA_FOR_SWITCHING_ROLE';
export const COHERE_ACADEMY_IS_ENABLE = 'COHERE_ACADEMY_IS_ENABLE';

export const updateUser = user => ({
  type: UPDATE_USER_ACTION,
  payload: user,
});

export const updatePaidTierOptionsPeriod = status => ({
  type: UPDATE_PAIDTIEROPTIONS_PERIOD,
  payload: status,
});

export const setTemporaryDataForSwtichingRole = data => ({
  type: TEMPORARY_DATA_FOR_SWITCHING_ROLE,
  payload: data,
});

export const setCohereAcademyStatus = isEnable => ({
  type: COHERE_ACADEMY_IS_ENABLE,
  payload: isEnable,
});
