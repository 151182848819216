import React from 'react'
import PropTypes from 'prop-types'
import IconButton from '@material-ui/core/IconButton'
import AppsIcon from '@material-ui/icons/Apps'
import PersonIcon from '@material-ui/icons/Person'

import ToolbarAction from '../ToolbarAction'
import ViewModeType from './ViewModeType'

const ViewModeTypeIcons = {
  [ViewModeType.gallery]: AppsIcon,
  [ViewModeType.speaker]: PersonIcon,
}

const ViewModeTypeTitles = {
  [ViewModeType.gallery]: 'Gallery',
  [ViewModeType.speaker]: 'Speaker',
}

const ViewMode = ({ type, onTypeChange }) => {
  const targetViewModeTypes = type === ViewModeType.gallery ? ViewModeType.speaker : ViewModeType.gallery
  const ViewModeIcon = ViewModeTypeIcons[targetViewModeTypes]

  return (
    <ToolbarAction label={`${ViewModeTypeTitles[targetViewModeTypes]} view`}>
      <IconButton color="inherit" onClick={() => onTypeChange(targetViewModeTypes)}>
        <ViewModeIcon />
      </IconButton>
    </ToolbarAction>
  )
}

ViewMode.propTypes = {
  type: PropTypes.oneOf([ViewModeType.gallery, ViewModeType.speaker]).isRequired,
  onTypeChange: PropTypes.func.isRequired,
}

export default ViewMode
