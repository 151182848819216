import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Route, Redirect, Switch } from 'react-router-dom';
import { useTheme, useMediaQuery } from '@material-ui/core';
import MainContainer from './MainContainer';
import AllClientsPage from 'pages/AllClientsPage/AllClientsPage';
import AllConatcts from 'pages/Contacts/AllContacts/AllContacts';
import Clients from 'pages/Contacts/Clients/Clients';
import Leads from 'pages/Contacts/Leads/Leads';
import Tags from 'pages/Contacts/Tags/Tags';
import { useHeader } from 'hooks/usePageActions';
import { fetchAllContactsAsync, fetchAllTagsAsync } from 'actions/contacts';
import { useDispatch, useSelector } from 'react-redux';
import ClientDetailViewContainer from './ClientDetailViewContainer';
import DetailPage from 'pages/ClientPage/ClientPage';
import useRouter from 'hooks/useRouter';

const StyledMainSection = styled.div`
  padding: ${({ mobileView }) => (mobileView ? '30px 5px' : '30px 55px')};
`;

function ContactsContainer({ match: { path } }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    dispatch(fetchAllContactsAsync({}));
  }, []);

  useEffect(() => {
    dispatch(fetchAllTagsAsync());
  }, []);

  return (
    <Switch>
      <Route path={`${path}/all`} exact component={AllConatcts} />
      <Route
        path={`${path}/all/:id/:name?/:email?`}
        exact
        render={routeProps => {
          return (
            <MainContainer>
              <StyledMainSection mobileView={mobileView}>
                <DetailPage {...routeProps} />
              </StyledMainSection>
            </MainContainer>
          );
        }}
      />
      <Route path={`${path}/clients`} exact component={Clients} />
      <Route
        path={`${path}/clients/:id/:name?/:email?`}
        exact
        render={routeProps => {
          return (
            <MainContainer>
              <StyledMainSection mobileView={mobileView}>
                <DetailPage {...routeProps} />
              </StyledMainSection>
            </MainContainer>
          );
        }}
      />
      <Route path={`${path}/leads`} exact component={Leads} />
      <Route path={`${path}/tags`} exact component={Tags} />
      <Redirect to={`${path}/all`} />
    </Switch>
  );
}

export default ContactsContainer;
