import React from 'react';
import CustomPurchaseBlock from './PurchaseBlock/CustomPurchaseBlock';
import CustomPurchaseBlockOneToOne from './PurchaseBlock/CustomPurchaseBlockOneToOne';
import { ContributionType } from 'helpers/constants';
import PropTypes from 'prop-types';
import useClientPreview from 'hooks/useClientPreview';
import { PAYMENT_OPTIONS } from '../../../../src/constants';
import CustomPurchaseBlockOneToOneClientPreview from './PurchaseBlock/CustomPurchaseBlockOneToOneClientPreview';
import { useSelector } from 'react-redux';

const PaymentCard = ({
  contribution,
  easyBooking,
  type,
  isSelfSchedule = false,
  rescheduleSession = false,
  setRescheduleSession,
  packageSessionBooking = false,
  setShowModal = () => {},
  isModal = false,
  setRescheduleBookingmodal = () => {},
  ...restProps
}) => {
  const { clientPreviewMode } = useClientPreview();
  const paymentOptions = contribution?.paymentInfo?.paymentOptions;
  const isCodeToJoin = useSelector(state => state?.contributions?.singleCode);
  const onlyFreeSingleSession = paymentOptions?.length === 1 && paymentOptions?.includes(PAYMENT_OPTIONS.FREE);
  const onlyFreeSessionPackage =
    paymentOptions?.length === 1 && paymentOptions?.includes(PAYMENT_OPTIONS.FREESESSIONPACKAGE);
  const onlyFreeSingleAndFreeSessionPackage =
    paymentOptions?.length === 2 &&
    paymentOptions?.includes(PAYMENT_OPTIONS.FREE) &&
    paymentOptions?.includes(PAYMENT_OPTIONS.FREESESSIONPACKAGE);
  const onlyFreeOptionsAllowed = onlyFreeSingleSession || onlyFreeSessionPackage || onlyFreeSingleAndFreeSessionPackage;

  if (type === ContributionType.contributionOneToOne && clientPreviewMode && onlyFreeOptionsAllowed) {
    return <CustomPurchaseBlockOneToOneClientPreview />;
  }
  return type === ContributionType.contributionOneToOne ? (
    contribution?.isPurchased === false ? (
      <CustomPurchaseBlockOneToOne easyBooking={easyBooking} />
    ) : contribution?.isPurchased === true && contribution.purchaseStatus === 'unpurchased' ? (
      <CustomPurchaseBlockOneToOne
        easyBooking={easyBooking}
        rescheduleSession={rescheduleSession}
        setRescheduleSession={setRescheduleSession}
        isModal={isModal}
      />
    ) : contribution?.isPurchased === true &&
      contribution.purchaseStatus === 'succeeded' &&
      rescheduleSession === true ? (
      <CustomPurchaseBlockOneToOne
        easyBooking={easyBooking}
        rescheduleSession={rescheduleSession}
        setRescheduleSession={setRescheduleSession}
        isModal={isModal}
      />
    ) : contribution?.isPurchased === true &&
      contribution.purchaseStatus === 'succeeded' &&
      packageSessionBooking === true ? (
      <CustomPurchaseBlockOneToOne
        easyBooking={easyBooking}
        rescheduleSession={rescheduleSession}
        setRescheduleSession={setRescheduleSession}
        packageSessionBooking={packageSessionBooking}
        setShowModal={setShowModal}
        setRescheduleBookingmodal={setRescheduleBookingmodal}
        isModal={isModal}
      />
    ) : contribution?.isPurchased === true &&
      contribution.purchaseStatus === 'succeeded' &&
      packageSessionBooking === true ? (
      <CustomPurchaseBlockOneToOne
        easyBooking={easyBooking}
        rescheduleSession={rescheduleSession}
        setRescheduleSession={setRescheduleSession}
        packageSessionBooking={packageSessionBooking}
        setShowModal={setShowModal}
        isModal={isModal}
      />
    ) : contribution?.isPurchased === true && isCodeToJoin && isModal === false ? (
      <CustomPurchaseBlockOneToOne
        easyBooking={easyBooking}
        rescheduleSession={rescheduleSession}
        setRescheduleSession={setRescheduleSession}
        isModal={isModal}
      />
    ) : (
      <></>
    )
  ) : (
    <CustomPurchaseBlock {...restProps} />
  );
};

PaymentCard.propTypes = {
  type: PropTypes.string,
};

PaymentCard.defaultProps = {
  type: null,
};

export default PaymentCard;
