import React, { useEffect, useRef, useCallback, useState } from 'react';
import { useStripe } from '@stripe/react-stripe-js';
import { Grid, useMediaQuery } from '@material-ui/core';
import usePaidTier from 'hooks/usePaidTier';
import useAccount from 'hooks/useAccount';
import PlanCard from '../../../../components/PlanCard';
import { createPaidTierOptionsSession, upgradePaidTierOptions } from 'services/paidTierOptions.service';
import { setActivePaidTierPlan } from 'actions/paidTierOptions';
import useShallowEqualSelector from 'hooks/useShallowEqualSelector';
import { PAIDTIER_STATUSES, PAID_TIER_TITLES } from 'constants.js';
import OnePreviewLogo from 'assets/one_plan_view_academy.png';
import { Box } from '@material-ui/core';
import AdvantagesItem from 'components/PlanCard/AdvantagesItem';
import UpgradeButton from 'components/UI/UpgradeButton';
import Button from 'components/FormUI/Button';
import { ContributionType } from 'helpers/constants';
import {
  AdvantagesList,
  CohereAcademyP,
  CohereAcademyTitle,
  ExpiresLabel,
  FeeField,
  PaidTierExtended,
  PlanCardContainer,
  PlanColumn,
  PriceField,
  SecondaryP,
  useStyles,
} from '../Billing.styles';

import Dictionary from '../dictionary.json';
import { getUserProfile } from 'services/user.service';
import { getProfile } from 'actions/user';
import {
  allinoneplatformadvantages,
  thecohereacademyAdvantages,
  VIPAdvantages,
} from '../../CohealerBilling/OnePlanBillingView/CardContentLists';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import * as paidTierActions from 'actions/paidTierOptions';
import { setCohereAcademyStatus } from 'actions/update-user';
import * as paidTierService from 'services/paidTierOptions.service';
import { getBoughtByType } from 'services/contributions.service';
import * as paidTier from 'selectors/paidTier';
const monthCount = 6;

const advantages = [
  'Everything In-One-Place',
  'Cohere Academy (Annual Impact Plan Only)',
  'Onboarding and Implementation Call',
  'Create, Sell & Deliver Online Services',
  'Create Communities ',
  'Group Programs, Memberships and 1:1 Services',
  'Easy Landing Pages & Link Sharing',
  'Client Membership Portal ',
  'Scheduling for Groups And 1:1',
  'Cohere Live Video Sessions ',
  'Integrate with Other Video Platforms',
  'Automated Session Recordings',
  'Automated Session Cal Invites ',
  'Payments & Recurring Revenue',
  'Sales Dashboard and Bookkeeping',
  'Secure Chat',
  'Self-Paced Courses',
  'Add Team Members to Services ',
  'Discount Codes',
  'By-Pass Payment System (Annual Impact Plan Only)',
];

const academyAdvantages = [
  'Full access to monthly LIVE business coaching sessions.',
  'Learn how to start & scale your coaching business.',
  'Create and sell high-ticket offerings.',
  'Build community with other coaches & service providers.',
  'Join live workshops with guest speakers.',
  'Get coached from other leading coaches such as Scott Oldford & more on how to start and scale your business.',
];

const PaidTierBigView = ({ modalsApi }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const containerRef = useRef(null);

  const stripe = useStripe();
  const { user } = useAccount();

  const {
    paidTierPlans,
    // isLaunchPlan,
    // isImpactPlan,
    activePaidTierPlanEndDateTime,
    // isAnnualPlan,
    activePaidTierPlanStatus,
    activePaidTierCurrentPaymentPeriod,
    // getActivePaidTier,
  } = usePaidTier();
  const [isLoader, setIsLoader] = useState({
    paidTierPlans: false,
    activePaidTierPlan: false,
    academyMemberships: false,
    activeAcademyMemberships: false,
  });

  const activePaidTierCurrentPlan = useShallowEqualSelector(paidTier.activePaidTierCurrentPlanSelector);
  const academyContributions = useSelector(paidTier.cohereAcademyContributionsSelector);
  const isImpactPlan = activePaidTierCurrentPlan?.displayName === PAID_TIER_TITLES.impact;

  const getAllPaidTiers = useCallback(() => paidTierService.getPaidTierOptions(), []);

  const isLaunchPlan = activePaidTierCurrentPlan?.displayName === PAID_TIER_TITLES.launch;
  // Correct behavior - 100%
  const filterMembership = useCallback(
    res => {
      const filteredMemberships = [];

      academyContributions.forEach(item => {
        const matchedMembership = res.find(el => el.id === item.id);

        if (matchedMembership && matchedMembership.isPurchased) {
          filteredMemberships.push(item);
        }
        return true;
      });

      return filteredMemberships;
    },
    [academyContributions],
  );

  const updateActiveContributionsState = useCallback(() => {
    return getBoughtByType(ContributionType.contributionMembership)
      .then(filterMembership)
      .then(matchedMemberships => {
        dispatch(paidTierActions.setActivePaidTierContributions(matchedMemberships));
      });
  }, [filterMembership]);

  useEffect(() => {
    // this is a temp solution, we need to find a way to not repopulate it again if not needed
    // TODO: don't get paid tiers plans if the right version for existing user is already loaded
    // if (paidTierPlans.length) {
    //   return;
    // }
    setIsLoader(s => ({ ...s, paidTierPlans: true }));

    getAllPaidTiers().then(data => {
      dispatch(paidTierActions.setPaidTierPlans(data));
      setIsLoader(s => ({ ...s, paidTierPlans: false }));
    });
  }, []);

  useEffect(() => {
    setIsLoader(s => ({ ...s, activePaidTierPlan: true }));

    getActivePaidTier().then(data => {
      dispatch(paidTierActions.setActivePaidTierPlan(data));
      setIsLoader(s => ({ ...s, activePaidTierPlan: false }));
    });
  }, []);

  useEffect(() => {
    if (activePaidTierCurrentPlan?.default) {
      dispatch(setCohereAcademyStatus(true));
    } else {
      dispatch(setCohereAcademyStatus(false));
    }
  }, [activePaidTierCurrentPlan, dispatch]);

  useEffect(() => {
    setIsLoader(s => ({ ...s, academyMemberships: true }));

    paidTierService.getAcademy().then(data => {
      dispatch(paidTierActions.setPaidTierContribution(data));
      setIsLoader(s => ({ ...s, academyMemberships: false }));
    });
  }, []);

  useEffect(() => {
    setIsLoader(s => ({ ...s, activeAcademyMemberships: true }));

    updateActiveContributionsState().then(() => {
      setIsLoader(s => ({ ...s, activeAcademyMemberships: false }));
    });
  }, []);
  const getActivePaidTier = useCallback(() => paidTierService.getCurrentPlan(), []);
  const mobileView = useMediaQuery('(max-width: 700px)');

  const { title, description } = Dictionary.cohereAcademy;

  const impactPlan = paidTierPlans.find(el => el.displayName === PAID_TIER_TITLES.impact);
  const paymentPeriodLabel = activePaidTierCurrentPaymentPeriod === 'Annually' ? 'annual' : 'monthly';

  const currentButtonClass = isImpactPlan
    ? `${classes.selectPlanButton} ${classes.enable}`
    : `${classes.selectPlanButton} ${classes.disable}`;

  const buttonLabel = isImpactPlan ? 'Cancel' : 'Upgrade and Join';
  const formatDate = new Date(activePaidTierPlanEndDateTime).toLocaleDateString('en-US');
  const isCanceled = activePaidTierPlanStatus === PAIDTIER_STATUSES.CANCEL;
  const nextOption = isCanceled ? 'cancels' : 'renews';

  const fetchActivePaidTierPlanAndUpdateStore = () => {
    return getActivePaidTier().then(data => {
      dispatch(setActivePaidTierPlan(data));
      return data;
    });
  };

  const handleUpgrade = () => {
    const period = 'EverySixMonth';

    if (isLaunchPlan) {
      createPaidTierOptionsSession(impactPlan?.id, period, user.accountId).then(({ data }) => {
        stripe.redirectToCheckout({
          sessionId: data,
        });
      });
    } else {
      upgradePaidTierOptions(user.accountId, impactPlan?.id, period)
        .then(() => {
          fetchActivePaidTierPlanAndUpdateStore().then(data => {
            modalsApi.upgrade_success.setIsShowSuccessUpgradeModal({
              isShow: true,
              label: `Plan will upgrade to ${data.nextPaidTierOption?.displayName} when your current plan expires.`,
            });
          });
        })
        .catch(err => {
          console.dir(err);
          if (activePaidTierPlanStatus !== PAIDTIER_STATUSES.ACTIVE) {
            modalsApi.upgrade_impossible.setImpossibleToUpgradeModal({
              isShow: true,
              label: `You are currently subscribed to an annual plan. Your plan will renew on (${new Date(
                activePaidTierPlanEndDateTime,
              ).toLocaleDateString(
                'en-US',
              )}). If you wish to upgrade and cancel your annual plan, please email us at support@cohere.live.`,
            });
          }
        });
    }
  };

  const handleCancel = () => {
    modalsApi.warning_before_cancellation.setIsShowBeforeCancellationWarning({
      isShow: true,
      label: `Are you sure you wish to cancel your subscription?`,
      userId: user.accountId,
      itemId: impactPlan?.id,
      activePlanTitle: impactPlan?.displayName,
    });
  };

  const fixedPrice = priceToParse => {
    const parsedValue = parseFloat(priceToParse);

    let price = 0;

    if (parsedValue !== NaN && typeof parsedValue === 'number') {
      price = Number(parsedValue).toFixed();
    }

    return price;
  };

  const handleOnClick = isImpactPlan ? handleCancel : handleUpgrade;

  useEffect(() => {
    if (mobileView) {
      setTimeout(() => {
        const offsetTopContainer = containerRef.current.offsetTop;

        window.scrollTo({
          top: offsetTopContainer,
          behavior: 'smooth',
        });
      }, 350);
    }
  }, [mobileView]);

  return (
    <PlanCardContainer ref={containerRef}>
      {/* <PaidTierExtended className={classes.paidTierExtendedMedia}> */}
      <Box style={{ display: 'flex', flexGrow: 1, justifyContent: 'space-between' }} className={classes.purchase_div}>
        <div
          className={classes.purchase_div}
          style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        >
          {/* <div>
                        <Button
                          style={{
                            padding: '9px 8px',
                            maxWidth: '86px',
                            margin: 'auto',
                            fontSize: '12px',
                            fontWeight: '600',
                            borderRadius: '4px',
                          }}
                          autoWidth
                        >
                          Purchase Plan
                        </Button>
                      </div> */}
          <div>
            <Box
              style={{
                marginLeft: '6px',
                borderRadius: '3px',
                width: '286px',
                backgroundColor: '#215C73',
                color: 'white',
              }}
            >
              <h4
                style={{
                  fontWeight: 600,
                  fontSize: '20px',
                  paddingLeft: '8px',
                  paddingTop: '2px',
                  paddingBottom: '2px',
                }}
              >
                {/* TODO: revert the label when the event week concludes */}
                {/* Annual Impact Plan & Scale Your Coaching Bundle */}
                Annual {impactPlan?.displayName} Plan & Bundle
                {/* {impactPlan?.displayNameExtraText} */}
              </h4>
            </Box>
            <PriceField style={{ fontWeight: 800, fontSize: '14px', marginLeft: '6px' }}>
              ${fixedPrice(impactPlan?.pricePerSixMonth / monthCount)}
              <span
                style={{
                  fontSize: '13px',
                  fontWeight: '300',
                  color: '#000',
                }}
              >
                {' '}
                / month (
                <span style={{ fontWeight: 800, fontSize: '12px' }}>
                  ${fixedPrice(impactPlan?.pricePerSixMonth)}
                </span>{' '}
                billed every six months){' '}
              </span>
            </PriceField>

            {/* <FeeField>{impactPlan?.fee}% Fee</FeeField> */}
          </div>
        </div>
        <div>
          <div className={classes.purchase_div} style={{ marginTop: '3px', display: 'flex', justifyContent: 'end' }}>
            <SecondaryP
              onClick={() => window.open('https://calendly.com/anettecohere/20min', '_blank')}
              style={{
                cursor: 'pointer',
                color: '#AFAFAF',
                alignItems: 'center',
                display: 'flex',
                marginRight: '16px',
                fontWeight: 700,
              }}
            >
              Book Demo Call
            </SecondaryP>

            {activePaidTierPlanEndDateTime != null && !isLaunchPlan ? (
              <div style={{ display: 'flex', alignItems: 'end' }}>
                <ExpiresLabel className={classes.expiresLabelMedia} style={{ marginBottom: 0, lineHeight: 1.9 }}>
                  Your {paymentPeriodLabel} {impactPlan?.displayName} {nextOption} on {formatDate}.
                </ExpiresLabel>
              </div>
            ) : (
              buttonLabel != 'Cancel' && (
                <Button
                  autoWidth
                  disabled={isImpactPlan}
                  onClick={handleOnClick}
                  style={{
                    padding: '8px 24px',
                    fontSize: '12px',
                  }}
                >
                  {buttonLabel}
                </Button>
              )
            )}
          </div>
        </div>
      </Box>
      {/* <div className={classes.academyColumn}>
          <div className={classes.academyContainerPlan}>
            <div>
              <CohereAcademyTitle>{title}</CohereAcademyTitle>
              <CohereAcademyP>{description}</CohereAcademyP>
            </div>
            <AdvantagesList>
              {academyAdvantages.map(el => {
                return <AdvantagesItem label={el} key={el} />;
              })}
            </AdvantagesList>
          </div>
          <div className={classes.previewAcademyContainerPlan}>
            <img src={OnePreviewLogo} alt="" className={classes.academyImage} />
          </div>
        </div> */}

      {/* </PaidTierExtended> */}

      <div style={{ display: 'flex', justifyContent: 'space-between' }} className={classes.purchase_div}>
        <div style={{ display: 'flex', flex: 1, paddingLeft: '10px' }}>
          <PlanCard
            PrimaryAmount={impactPlan?.pricePerSixMonth}
            Primarytext="One Place to Sell, Schedule and Deliver your Online Services"
            fontSize="12px"
            title="All-In-One-Platform"
            advantages={allinoneplatformadvantages}
          />
        </div>
        <div style={{ display: 'flex', flex: 1, paddingLeft: '10px' }}>
          <PlanCard
            PrimaryAmount="2,997"
            Primarytext="Monthly LIVE Group Coaching & Community"
            fontSize="12px"
            title="The Cohere Academy"
            advantages={thecohereacademyAdvantages}
          />
        </div>
        <div style={{ display: 'flex', flex: 1, paddingLeft: '10px' }}>
          <PlanCard
            PrimaryAmount="597"
            Primarytext="Service Setup & Business Acceleration Support"
            fontSize="12px"
            title="VIP 1:1 Coaching"
            advantages={VIPAdvantages}
          />
        </div>
      </div>
      {activePaidTierPlanEndDateTime != null && isLaunchPlan && (
        <Grid container justify="center" className={classes.bottom_Heading}>
          <div>
            <ExpiresLabel className={classes.expiresLabelMedia}>
              Your {paymentPeriodLabel} {impactPlan?.displayName} {nextOption} on {formatDate}.
            </ExpiresLabel>
          </div>
        </Grid>
      )}
      {buttonLabel != 'Cancel' && !isCanceled && (
        <Grid container justify="center" style={{ marginTop: '20px' }}>
          <Button variant="secondary" disabled={isImpactPlan} onClick={handleOnClick}>
            {buttonLabel}
          </Button>
        </Grid>
      )}
    </PlanCardContainer>
  );
};

export default PaidTierBigView;
