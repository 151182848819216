import { useCallback, useState } from 'react';
import * as R from 'ramda';
import useNotifications from 'hooks/useNotifications';
import { useHttp } from '../../../hooks';
import * as contentService from 'services/content.service';
import humanizeDuration from 'humanize-duration';

/**
 * Max size limit on backend is 5GB
 */
const MAX_FILES_SIZE_IN_BYTES = 5368706371;

const isFilesSizeLimitOrLess = R.compose(sum => sum <= MAX_FILES_SIZE_IN_BYTES, R.sum, R.map(R.prop('size')));

export const useVideoDurationParse = () => {
  const [videoDuration, setVideoDuration] = useState('0h 0m');
  const { request, error, clearError } = useHttp();
  const { successMessage, errorMessage } = useNotifications();
  const [videoSrc, setVideoSrc] = useState({});
  const [isFileUpload, setIsFileUpload] = useState(false);
  const [progress, setProgress] = useState(null);
  const [loading, setLoading] = useState(false);
  const handleIsFileLoad = () => {
    setIsFileUpload(false);
    setProgress(null);
  };

  const handleUpdateProgress = (partNumber, totalParts) => progressData => {
    const percentPerChunk = 100 / totalParts;
    setProgress((progressData / 100) * percentPerChunk + percentPerChunk * (partNumber - 1));
  };

  const guid = () => {
    var d = new Date().getTime();
    var guid = 'xxxx-xxxx-xxxx-xxxx'.replace(/[xy]/g, function (c) {
      var r = (d + Math.random() * 16) % 16 | 0;
      d = Math.floor(d / 16);
      return (c == 'x' ? r : (r & 0x7) | 0x8).toString(16);
    });
    return guid;
  };

  const handleUploadFile = useCallback(
    async (files, leadMagnet) => {
      const chunkSize = 26214400;
      if (isFilesSizeLimitOrLess(files)) {
        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          const documentId = guid();
          const fileName = file.name;
          let uploadId = '';
          let prevETags = '';
          let partNumber = 1;
          for (let start = 0; start < file.size; start += chunkSize) {
            const isLastPart = start + chunkSize >= file.size;
            const chunk = file.slice(start, start + chunkSize);
            const totalParts = Math.ceil(file.size / chunkSize);
            const result = await uploadPartFile(
              chunk,
              leadMagnet?.id,
              partNumber,
              totalParts,
              isLastPart,
              documentId,
              fileName,
              uploadId,
              prevETags,
            );
            if (result) {
              if (isLastPart) {
                return result;
              }
              uploadId = result.uploadId;
              prevETags = result.prevETags;
            }
            partNumber++;
          }
        }
      }
    },
    [request],
  );

  const uploadPartFile = (
    chunk,
    leadMagnetId,
    partNumber,
    totalParts,
    isLastPart,
    documentId,
    fileName,
    uploadId,
    prevETags,
  ) =>
    new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append('file', chunk);
      setIsFileUpload(true);
      contentService
        .addLeadMagnetRecordedSession(
          formData,
          leadMagnetId,
          partNumber,
          isLastPart,
          documentId,
          fileName,
          uploadId,
          prevETags,
          handleUpdateProgress(partNumber, totalParts),
          videoDuration,
        )
        .then(data => {
          resolve(data);
        })
        .then(() => {
          if (isLastPart) {
            handleIsFileLoad();
          }
        })
        .catch(data => {
          handleIsFileLoad();
          console.dir(data);
          reject(data);
        });
    });

  // const uploadVideo = useCallback((files, leadMagnet) => {
  //   if (files.length >= 1) {
  //     const video = document.createElement('video');
  //     const regExVideoName = /[`!@#$%^&*()+\=\[\]{};':"\\|,<>\/?~]/;
  //     const incorrectVideoName = regExVideoName.test(files[0].name);
  //     let ext = files[0].path.split('.').pop();
  //     if (ext == 'pdf') {
  //       clearError();
  //       setLoading(true);
  //       handleUploadFile(files, leadMagnet)
  //         .then(data => {
  //           console.info('lead amgnet data1', data);
  //           return new Promise((resolve, reject) => {
  //             data
  //           });
  //         })
  //         .catch(err => {
  //           setLoading(false);
  //           errorMessage();
  //         });
  //     } else {
  //       video.onloadedmetadata = function () {
  //         window.URL.revokeObjectURL(video.src);
  //         setVideoDuration(humanizeDuration(video.duration, { maxDecimalPoints: 0 }));
  //         clearError();
  //         setLoading(true);
  //         handleUploadFile(files, leadMagnet)
  //           .then(data => {
  //             data.duration = video.duration * 1000; //duration in ms
  //             console.info('lead amgnet data1', data);
  //             return data;
  //             // if (typeof sessionTimeIndex === 'number') {
  //             //   form.setFieldValue(`sessions[${index}].prerecordedSession`, null);
  //             //   form.setFieldValue(`sessions[${index}].prerecordedSessions`, null);
  //             //   form.setFieldValue(`sessions[${index}].sessionTimes[${sessionTimeIndex}].prerecordedSession`, data);
  //             //   form.setFieldValue(
  //             //     `sessions[${index}].sessionTimes[${sessionTimeIndex}].videoSource`,
  //             //     URL.createObjectURL(files[0]),
  //             //   );
  //             // } else {
  //             //   form.setFieldValue(`sessions[${index}].prerecordedSession`, data);
  //             // }

  //             // successMessage('Success', 'Media file uploaded successfully');
  //             // setLoading(false);
  //             // setVideoSrc(prevState => ({
  //             //   ...prevState,
  //             //   [sessionTimeIndex]: URL.createObjectURL(files[0]),
  //             // }));
  //           })
  //           .catch(err => {
  //             setLoading(false);
  //             errorMessage();
  //           });
  //       };
  //       video.src = URL.createObjectURL(files[0]);
  //     }
  //   }
  // }, []);

  const uploadVideo = useCallback((files, leadMagnet) => {
    return new Promise((resolve, reject) => {
      if (files.length >= 1) {
        const video = document.createElement('video');
        const regExVideoName = /[`!@#$%^&*()+\=\[\]{};':"\\|,<>\/?~]/;
        const incorrectVideoName = regExVideoName.test(files[0].name);
        let ext = files[0].path.split('.').pop();
        if (ext == 'pdf') {
          clearError();
          setLoading(true);
          handleUploadFile(files, leadMagnet)
            .then(data => {
              resolve(data);
              successMessage('Success', 'Media file uploaded successfully');
              setLoading(false);
            })
            .catch(err => {
              setLoading(false);
              reject(err);
            });
        } else {
          video.onloadedmetadata = function () {
            window.URL.revokeObjectURL(video.src);
            setVideoDuration(humanizeDuration(video.duration, { maxDecimalPoints: 0 }));
            clearError();
            setLoading(true);
            handleUploadFile(files, leadMagnet)
              .then(data => {
                data.duration = video.duration * 1000; //duration in ms
                resolve(data);
                successMessage('Success', 'Media file uploaded successfully');
                setLoading(false);
              })
              .catch(err => {
                setLoading(false);
                reject(err);
              });
          };
          video.src = URL.createObjectURL(files[0]);
        }
      } else {
        reject('No files provided');
      }
    });
  }, []);

  return { uploadVideo, videoDuration, loading, progress, videoSrc, error };
};
