import React from 'react';

const ExportSvg = ({ width, height, color }) => {
  return (
    <svg width="20" height="13" viewBox="0 0 16 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.3335 5.40004L12.6668 2.73338V4.73338H6.66684V6.06671H12.6668V8.06671M0.66684 9.40004V1.40004C0.66684 1.04642 0.807315 0.707284 1.05736 0.457235C1.30741 0.207187 1.64655 0.0667114 2.00017 0.0667114H10.0002C10.3538 0.0667114 10.6929 0.207187 10.943 0.457235C11.193 0.707284 11.3335 1.04642 11.3335 1.40004V3.40004H10.0002V1.40004H2.00017V9.40004H10.0002V7.40004H11.3335V9.40004C11.3335 9.75366 11.193 10.0928 10.943 10.3428C10.6929 10.5929 10.3538 10.7334 10.0002 10.7334H2.00017C1.64655 10.7334 1.30741 10.5929 1.05736 10.3428C0.807315 10.0928 0.66684 9.75366 0.66684 9.40004Z"
        fill="white"
      />
    </svg>
  );
};

export default ExportSvg;
