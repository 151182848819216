import moment from 'moment';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { setSessionDurationError } from 'actions/oneToOneData';

const DATE_FORMAT = 'HH:mm:ss';

export const checkSessionDuration = (selectedWeeks, sessionDuration) =>
  selectedWeeks.some(sessionTime => {
    const startTime =
      typeof sessionTime.startTime === 'string'
        ? moment.utc(sessionTime.startTime).format(DATE_FORMAT)
        : sessionTime.startTime.format(DATE_FORMAT);
    const endTime =
      typeof sessionTime.endTime === 'string'
        ? moment.utc(sessionTime.endTime).format(DATE_FORMAT)
        : sessionTime.endTime.format(DATE_FORMAT);

    const duration = moment(endTime, DATE_FORMAT).diff(moment(startTime, DATE_FORMAT), 'minutes');

    const positiveDuration = duration < 0 ? duration + 24 * 60 : duration;

    return positiveDuration < sessionDuration;
  });

export const useCheckSessionDuration = () => {
  const dispatch = useDispatch();
  const selectedWeeks = useSelector(({ oneToOneData }) => oneToOneData?.selectedWeeks) || [];
  const sessionDuration = useSelector(({ oneToOneData }) => oneToOneData?.sessionDuration) || 0;
  const sessionDurationError = useSelector(({ oneToOneData }) => oneToOneData?.sessionDurationError);

  useEffect(() => {
    if (checkSessionDuration(selectedWeeks, sessionDuration)) {
      if (!sessionDurationError) {
        dispatch(
          setSessionDurationError(`Your time availability does not allow for ${sessionDuration} minute sessions.`),
        );
      }
    } else if (sessionDurationError) {
      dispatch(setSessionDurationError(undefined));
    }
  }, [selectedWeeks, sessionDuration, dispatch, sessionDurationError]);

  useEffect(() => () => dispatch(setSessionDurationError(undefined)), [dispatch]);
};
