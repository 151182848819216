import React from 'react';

export const PhoneDesktop = ({ fillColor, fillBackgroundColor }) => {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect
        x="0.105469"
        y="0.457031"
        width="24"
        height="24"
        rx="2"
        fill={fillBackgroundColor ? fillBackgroundColor : 'none'}
      />
      <path
        d="M18.3555 4.95703H5.85547C5.35819 4.95703 4.88127 5.15458 4.52964 5.50621C4.17801 5.85784 3.98047 6.33475 3.98047 6.83203V15.582C3.98047 16.0793 4.17801 16.5562 4.52964 16.9079C4.88127 17.2595 5.35819 17.457 5.85547 17.457H11.4805V18.707H9.60547C9.43971 18.707 9.28074 18.7729 9.16353 18.8901C9.04632 19.0073 8.98047 19.1663 8.98047 19.332C8.98047 19.4978 9.04632 19.6568 9.16353 19.774C9.28074 19.8912 9.43971 19.957 9.60547 19.957H14.6055C14.7712 19.957 14.9302 19.8912 15.0474 19.774C15.1646 19.6568 15.2305 19.4978 15.2305 19.332C15.2305 19.1663 15.1646 19.0073 15.0474 18.8901C14.9302 18.7729 14.7712 18.707 14.6055 18.707H12.7305V17.457H18.3555C18.8527 17.457 19.3297 17.2595 19.6813 16.9079C20.0329 16.5562 20.2305 16.0793 20.2305 15.582V6.83203C20.2305 6.33475 20.0329 5.85784 19.6813 5.50621C19.3297 5.15458 18.8527 4.95703 18.3555 4.95703ZM5.85547 6.20703H18.3555C18.5212 6.20703 18.6802 6.27288 18.7974 6.39009C18.9146 6.5073 18.9805 6.66627 18.9805 6.83203V13.082H5.23047V6.83203C5.23047 6.66627 5.29632 6.5073 5.41353 6.39009C5.53074 6.27288 5.68971 6.20703 5.85547 6.20703ZM18.3555 16.207H5.85547C5.68971 16.207 5.53074 16.1412 5.41353 16.024C5.29632 15.9068 5.23047 15.7478 5.23047 15.582V14.332H18.9805V15.582C18.9805 15.7478 18.9146 15.9068 18.7974 16.024C18.6802 16.1412 18.5212 16.207 18.3555 16.207Z"
        fill={fillColor ? fillColor : 'black'}
      />
    </svg>
  );
};

export const PhonePreviewVector = ({ fillColor, fillBackgroundColor }) => {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect
        x="0.105469"
        y="0.457031"
        width="24"
        height="24"
        rx="2"
        fill={fillBackgroundColor ? fillBackgroundColor : 'none'}
      />
      <path
        d="M15.1055 5.55703C15.3442 5.55703 15.5731 5.65185 15.7419 5.82064C15.9106 5.98942 16.0055 6.21834 16.0055 6.45703V18.457C16.0055 18.6957 15.9106 18.9246 15.7419 19.0934C15.5731 19.2622 15.3442 19.357 15.1055 19.357H9.10547C8.86677 19.357 8.63786 19.2622 8.46907 19.0934C8.30029 18.9246 8.20547 18.6957 8.20547 18.457V6.45703C8.20547 6.21834 8.30029 5.98942 8.46907 5.82064C8.63786 5.65185 8.86677 5.55703 9.10547 5.55703H15.1055ZM9.10547 4.35703C8.54851 4.35703 8.01437 4.57828 7.62054 4.97211C7.22672 5.36593 7.00547 5.90008 7.00547 6.45703V18.457C7.00547 19.014 7.22672 19.5481 7.62054 19.942C8.01437 20.3358 8.54851 20.557 9.10547 20.557H15.1055C15.6624 20.557 16.1966 20.3358 16.5904 19.942C16.9842 19.5481 17.2055 19.014 17.2055 18.457V6.45703C17.2055 5.90008 16.9842 5.36593 16.5904 4.97211C16.1966 4.57828 15.6624 4.35703 15.1055 4.35703H9.10547Z"
        fill="#4A4A4A"
        stroke="#4A4A4A"
        stroke-width="0.2"
      />
      <path
        d="M12.1055 18.457C12.3707 18.457 12.625 18.3517 12.8126 18.1641C13.0001 17.9766 13.1055 17.7222 13.1055 17.457C13.1055 17.1918 13.0001 16.9375 12.8126 16.7499C12.625 16.5624 12.3707 16.457 12.1055 16.457C11.8403 16.457 11.5859 16.5624 11.3984 16.7499C11.2108 16.9375 11.1055 17.1918 11.1055 17.457C11.1055 17.7222 11.2108 17.9766 11.3984 18.1641C11.5859 18.3517 11.8403 18.457 12.1055 18.457Z"
        fill={fillColor ? fillColor : '#4A4A4A'}
      />
    </svg>
  );
};

export const ArrowDropDownIcon = ({ width = '10', height = '10', onClickHandler = () => {} }) => {
  return (
    <div onClick={onClickHandler}>
      <svg width={width} height={height} viewBox="0 0 13 9" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M1.91 0.790039L6.5 5.38004L11.09 0.790039L12.5 2.21004L6.5 8.21004L0.5 2.21004L1.91 0.790039Z"
          fill="#8A8A8A"
        />
      </svg>
    </div>
  );
};
