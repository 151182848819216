const PREFIX = 'MEMBER_PODS/';

const RESET_STATE = `${PREFIX}RESET_STATE`;
const SET_CONTRIBUTION_MEMBER_PODS = `${PREFIX}SET_CONTRIBUTION_MEMBER_PODS`;
const SET_DATA = `${PREFIX}SET_DATA`;
const SET_ERROR = `${PREFIX}SET_ERROR`;
const SET_IS_FETCHING = `${PREFIX}SET_IS_FETCHING`;
const SET_NEW_POD_NAME = `${PREFIX}SET_NEW_POD_NAME`;

export const MEMBER_PODS_ACTION_TYPES = {
  RESET_STATE,
  SET_CONTRIBUTION_MEMBER_PODS,
  SET_DATA,
  SET_ERROR,
  SET_IS_FETCHING,
  SET_NEW_POD_NAME,
};
