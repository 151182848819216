import React from 'react'
import PropTypes from 'prop-types'

import './TypingMembers.scss'

const nbSp = '\u00A0'

const TypingMembers = ({ className, members }) => {
  const typingMembers = members.map((m) => m.attributes.Name)

  return (
    <div className={className}>
      <p className="chat-typing-members">
        {typingMembers.length > 0
          ? `${typingMembers.join(', ')} ${typingMembers.length > 1 ? 'are' : 'is'} typing...`
          : nbSp}
      </p>
    </div>
  )
}

TypingMembers.propTypes = {
  className: PropTypes.string.isRequired,
  members: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
}

export default TypingMembers
