import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect, Switch } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';

import * as userActions from 'actions/user';
import { useShallowEqualSelector, useHeader, useRouter } from 'hooks';
import { DashboardPage } from 'pages';
import { UserRoles } from 'helpers/constants';
import { parseQuery } from 'utils/parseData';
import Modal from 'components/UI/Modal';
import { LabelText } from 'components/UI/Text/TextStyles';
import MainContainer from './MainContainer';

const titles = {
  [UserRoles.cohealer]: 'Dashboard',
  [UserRoles.cohealerAssistant]: 'Dashboard',
  [UserRoles.client]: 'My Journey',
  [UserRoles.admin]: 'Admin',
  [UserRoles.superAdmin]: 'Admin',
};

function DashboardContainer({ userId, getUserProfile, match: { path } }) {
  const { location } = useRouter();
  const currentRole = useShallowEqualSelector(({ account }) => account?.currentRole);
  const [isShowPartnerModal, setIsShowPartnerModal] = useState(false);
  const [isAddedSuccessfully, setIsAddedSuccessfully] = useState(false);
  const [partnerContributionName, setPartnerContributionName] = useState(null);
  const dispatch = useDispatch();
  useHeader(titles[currentRole]);

  useEffect(() => {
    const parsedQuery = parseQuery(location.search);
    if (parsedQuery?.refreshUser) {
      getUserProfile(userId);
      userActions.getProfile(userId, dispatch);
    }
    if (parsedQuery?.success) {
      const isSuccessfully = parsedQuery.success === 'true';
      setIsAddedSuccessfully(isSuccessfully);
      setPartnerContributionName(parsedQuery.contributionName);
      setIsShowPartnerModal(true);
    }
  }, [location.search, getUserProfile, userId]);

  const handleClosePartnerMadal = () => {
    setIsShowPartnerModal(false);
  };

  return (
    <>
      <MainContainer>
        <Switch>
          <Route path={`${path}`} component={DashboardPage} />
          <Redirect to={`${path}`} />
        </Switch>
        {/* Temporarily disable this modal */}
        {/* <Can roleName="Client" yes={() => <ClientModalsPreferences />} /> */}
      </MainContainer>

      {isShowPartnerModal && (
        <Modal
          isOpen={isShowPartnerModal}
          onSubmit={handleClosePartnerMadal}
          title={isAddedSuccessfully ? 'You were added successfully' : 'Adding failed'}
          hiddenCancel
        >
          {isAddedSuccessfully ? (
            <LabelText>{`You were added to ${partnerContributionName}`}</LabelText>
          ) : (
            <LabelText>Adding you as a partner coach failed</LabelText>
          )}
        </Modal>
      )}
    </>
  );
}

DashboardContainer.propTypes = {
  userId: PropTypes.string,
  getUserProfile: PropTypes.func.isRequired,
  match: PropTypes.shape({
    path: PropTypes.string,
    url: PropTypes.string,
    isExact: PropTypes.bool,
    params: PropTypes.object,
  }).isRequired,
};

DashboardContainer.defaultProps = {
  userId: null,
};

const mapStateToProps = ({ account }) => ({
  userId: account?.user?.id,
});

const actions = {
  getUserProfile: userActions.getProfile,
};

export default connect(mapStateToProps, actions)(DashboardContainer);
