import { useCallback, useState } from 'react';
import axiosInstance from 'utils/axiosInstance';

export const useIsEmailExist = () => {
  const [isLoadingEmail, setIsLoadingEmail] = useState(false);
  const [error, setError] = useState(null);

  const checkEmail = useCallback(async email => {
    setIsLoadingEmail(true);
    try {
      const response = await axiosInstance.get(`/Account/CheckEmail/${email}`);
      if (response.data.emailExists === false) {
        return false;
      } else if (response.data.emailExists === true) {
        return response.data;
      }
    } catch (e) {
      if (e.response.status === 400) return false;
      setError(e);
    } finally {
      setIsLoadingEmail(false);
    }
  }, []);
  const clearError = useCallback(() => {
    setError(null);
  }, []);

  return {
    isLoadingEmail,
    error,
    clearError,
    checkEmail,
  };
};
