export const getPaymentOptionsView = paymentOption => {
  const optionsCollection = {
    EntireCourse: 'Entire Course',
    PerSession: 'Per Session',
    MonthlyMembership: 'Monthly Membership',
    SplitPayments: 'Split Payments',
    SessionsPackage: 'Sessions Package',
    MonthlySessionSubscription: 'Monthly Subscription',
    MembershipPackage: 'Membership package',
    YearlyMembership: 'Yearly Membership',
    Free: 'Free',
    FreeSessionsPackage: 'Free Package',
  };

  return optionsCollection[paymentOption] || 'Unknown';
};

export const getCurrency = contribution => {
  if (contribution.availableCurrencies !== null) {
    return contribution.availableCurrencies?.map(d => ({
      title: d.code.toUpperCase(),
      value: d.code,
    }));
  } else return [{ value: contribution.defaultCurrency, title: contribution.defaultCurrency.toUpperCase() }];
};
