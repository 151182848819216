import React from 'react';

export const MessageIcon = ({ color }) => (
  <svg width="32" height="32" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.632 22.013C11.2937 22.013 9.95539 21.7156 8.7658 21.1952C8.69145 21.1952 8.69145 21.1208 8.6171 21.1208C8.171 20.9721 7.65056 20.8978 6.98141 20.8978C5.42007 20.8978 3.41264 21.2695 2 21.5669C2.4461 20.8978 2.89219 20.1543 3.11524 19.6338C3.71004 18.4442 4.15613 17.329 4.15613 16.9572C4.15613 16.8829 4.15613 16.7342 4.08178 16.6599C3.48699 15.4703 3.18959 14.132 3.18959 12.7937C3.26394 7.66357 7.50186 3.5 12.632 3.5C17.7621 3.5 22 7.66357 22 12.7193C22 17.8494 17.8364 22.013 12.632 22.013Z"
      stroke={color}
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10.4015 11.084H15.1599"
      stroke={color}
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10.4015 14.4297H15.1599"
      stroke={color}
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
