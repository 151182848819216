import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'components/UI/Modal';
import BookModalView from './BookModalView';

const BookModalContainer = ({ isOpen, handleModalCancel, handleModalSubmit, sessionInfo, timeZoneId }) => {
  return (
    <Modal
      isOpen={isOpen}
      title="Confirm your session time"
      submitTitle="Confirm"
      onCancel={handleModalCancel}
      onSubmit={handleModalSubmit}
      applyTheming
    >
      <BookModalView session={sessionInfo} timeZoneId={timeZoneId} />
    </Modal>
  );
};

BookModalContainer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleModalCancel: PropTypes.func.isRequired,
  handleModalSubmit: PropTypes.func.isRequired,
  sessionInfo: PropTypes.objectOf(PropTypes.any).isRequired,
  timeZoneId: PropTypes.string,
};

BookModalContainer.defaultProps = {
  timeZoneId: '',
};

export default BookModalContainer;
