import axiosInstance from 'utils/axiosInstance';
import { getIpGlobal } from '../utils/utils';
import { get } from 'lodash/fp';

const loginUser = (email, password, phoneNo, otp, isSecondaryExisting) =>
  axiosInstance.post('/auth/signin', { email, password, phoneNo, otp, isSecondaryExisting }).then(res => ({
    account: { ...res.data.account, oAuthToken: res.headers['o-auth-token'] },
    user: res.data.user,
    userInfoMissing: res.data.userInfoMissing,
  }));

const restorePassword = email => axiosInstance.get(`/Account/RestorePassword/${email}/RequestLink`).then(get('data'));

const newPassword = data => axiosInstance.post('/Account/RestorePassword', data).then(get('data'));
const getOtp = data => axiosInstance.post(`/User/GenerateAndSendOtpToUser?email=${data}`).then(get('data'));

const finishGetStartedGuide = () => axiosInstance.post('/Account/FinishGettingStartedGuide').then(get('data'));

const registerUser = (
  email,
  password,
  inviteCode,
  userView,
  signupType,
  phoneNo,
  TimezoneId,
  countryId,
  stateCode,
  IsSecondaryAccount,
) => {
  const data = {
    email,
    password,
    invitedBy: inviteCode,
    inviteCode,
    userView,
    accountPreferences: { userView },
    phoneNo,
    TimezoneId,
    countryId,
    stateCode,
    IsSecondaryAccount,
  };
  if (signupType) {
    data.signupType = signupType;
  }
  return axiosInstance
    .post('/account', data)
    .then(resp => ({ ...resp.data, oAuthToken: resp.headers['o-auth-token'] }));
};

const continueRegisterUser = fields =>
  axiosInstance.post('/user', fields).then(resp => ({
    account: { ...resp.data.account, oAuthToken: resp.headers['o-auth-token'] },
    user: resp.data.user,
  }));

const editUserProfile = (id, fields) =>
  axiosInstance
    .put(`/user/${id}`, fields)
    .then(res => ({ account: { ...res.data.account, oAuthToken: res.headers['o-auth-token'] }, user: res.data.user }));

const editUserColors = fields => axiosInstance.put(`User/UpdateUserProfileColors?`, fields).then(get('data'));
const editEmailUserColors = fields =>
  axiosInstance.put(`Contribution/UpdateContributionColors?`, fields).then(get('data'));
const editUserSpecificAttribute = fields => axiosInstance.put(`User/UpdateUserAttributes?`, fields).then(get('data'));

// .then(res => ({ account: { ...res.data.account, oAuthToken: res.headers['o-auth-token'] }, user: res.data.user }));

const getUserProfile = id => axiosInstance.get(`/user/${id}`).then(get('data'));

const switchFromCoachToClient = () => axiosInstance.post(`/User/SwitchFromCoachToClient`).then(get('data'));

const switchFromClientToCoach = data => axiosInstance.post(`/User/SwitchFromClientToCoach`, data).then(get('data'));

const getCohealerIcon = id => axiosInstance.get(`/User/GetCohealerIcon/${id}`).then(get('data'));
const getAllContributionForClient = number =>
  axiosInstance
    .get(`/Contribution/GetAllContributionForClient${number >= 0 ? `?skip=${number * 5}&take=5` : ''}`)
    .then(get('data'));
const getAllContributionForCoach = number =>
  axiosInstance
    .get(`/Contribution/GetAllContributionForCoach${number >= 0 ? `?skip=${number * 5}&take=5` : ''}`)
    .then(get('data'));

const GetAllEnrollmentFormsByCoach = number =>
  axiosInstance
    .get(`/controller/GetAllEnrollmentFormsByCoach${number >= 0 ? `?pageNumber=${number}&pageSize=20` : ''}`)
    .then(get('data'));

const ExportEnrollmentFormResponses = enrollmentFormId =>
  axiosInstance.get(`/controller/ExportEnrollmentFormResponses/${enrollmentFormId}`).then(get('data'));
const updateSmsNotification = fields => axiosInstance.put(`User/UpdateSmsSetting?`, fields).then(get('data'));

const setPostsAsRead = data => {
  const { contributionId, userId } = data;
  const ENDPOINT = '/User/SaveUserSocialLastReadTime';

  return axiosInstance.post(ENDPOINT, { contributionId, userId });
};

const updateCoachLoginInfo = data => {
  const { accountId, coachLoggedTheFirstTime, coachLoggedTheSecondTime } = data;
  const ENDPOINT = `/Account/CoachLoginInfo?id=${accountId}`;

  return axiosInstance.patch(ENDPOINT, {
    coachLoggedTheFirstTime,
    coachLoggedTheSecondTime,
  });
};

const getAccountById = async accountId => {
  const endpoint = `/Account/${accountId}`;

  const response = await axiosInstance.get(endpoint);
  return {
    ...response.data,
    oAuthToken: response.headers['o-auth-token'],
  };
};

const UpdateUserProgressBarData = async data => {
  const updatedUser = await axiosInstance.put('/User/UpdateUserProgressBarData', data);
  return updatedUser.data;
};

export const sendUserNPS_Score = data => axiosInstance.post('/NPSFeedback/Submit', data).then(get('data'));

export const sendUserFeedback = data => axiosInstance.post('/NPSFeedback/Submit', data).then(get('data'));

export const getNPSFeedbackPopup = data =>
  axiosInstance
    .get(`/NPSFeedback/ShouldShowNPSFeedbackPopup`, data)
    .then(get('data'))
    .catch(() => ({}));

export const getNPSFeedbackExportFile = type =>
  axiosInstance
    .get('/NPSFeedback/ExportNPSFeedback', {
      params: { type: type },
    })
    .then(get('data'));

const fetchUserTimezoneInfoIP = async () => {
  try {
    const userIp = await getIpGlobal();
    const response = await axiosInstance.get(`User/GetTimeZoneInformation?userIP=${userIp}`);
    return response.data;
  } catch (error) {
    throw new Error(`Failed to fetch user timezone information: ${error.message}`);
  }
};

export {
  loginUser,
  restorePassword,
  newPassword,
  finishGetStartedGuide,
  registerUser,
  continueRegisterUser,
  editUserProfile,
  getUserProfile,
  switchFromCoachToClient,
  switchFromClientToCoach,
  updateSmsNotification,
  getCohealerIcon,
  setPostsAsRead,
  updateCoachLoginInfo,
  getAccountById,
  UpdateUserProgressBarData,
  editUserColors,
  editUserSpecificAttribute,
  getAllContributionForClient,
  getAllContributionForCoach,
  editEmailUserColors,
  GetAllEnrollmentFormsByCoach,
  ExportEnrollmentFormResponses,
  getOtp,
  fetchUserTimezoneInfoIP,
};
