import React, { useState } from 'react';
import classes from './RegisterDomainModal.module.scss';
import Modal from 'components/UI/Modal';
import { TextField, Tooltip } from '@material-ui/core';
import useAccount from 'hooks/useAccount';
import InfoIcon from '@material-ui/icons/Info';
import styled from 'styled-components';

const StyledToolTipWrapper = styled.div`
  display: flex;
  gap: 8px;
`;

const RegisterDomainModal = props => {
  const { email, domain, emailDomain, handleDomainChange, handleEmailChange, domainError, ...rest } = props;
  const { user } = useAccount();
  const isSendGridConnected = user?.isSendGridConnected;
  const domainRegex = /^(?:[a-zA-Z0-9](?:[a-zA-Z0-9\-]{0,61}[a-zA-Z0-9])?\.)+[a-zA-Z]{2,}$/;

  return (
    <Modal
      {...rest}
      disableSubmitClick={
        isSendGridConnected ? domainRegex.test(domain) === false || domainError : domainRegex.test(domain) === false
      }
    >
      <div className={classes.container}>
        <div className={classes.heading_text}>
          Email marketing tools like Cohere requires you have an existing domain, e.g. <strong>yourname.com.</strong>{' '}
          Over the next few steps we are going to help you get this set up so Cohere and your domain talk to each other
          and use your domain to send emails.
          <div>
            <strong>Please note:</strong> You cannot use your Cohere domain for this as this is a sub-domain. Also
            please ensure that you enter the domain without the "www" prefix.
          </div>
        </div>
        <div className={classes.domain_wrapper}>
          <StyledToolTipWrapper>
            <p className={classes.domain_label}>Enter Your Existing Domain</p>
            {/* <Tooltip title="text here" arrow>
              <InfoIcon htmlColor="#C9B382" />
            </Tooltip> */}
          </StyledToolTipWrapper>
          <TextField
            className={classes.domain_input}
            placeholder="e.g. yourname.com"
            variant="outlined"
            value={domain}
            onChange={handleDomainChange}
            InputProps={{
              classes: {
                root: classes.domain_root,
                notchedOutline: classes.domain_notchedOutline,
              },
            }}
          />
        </div>
        {domainError && <div style={{ color: 'red' }}>{domainError}</div>}
        {/* {!isSendGridConnected && (
          <div className={classes.email_wrapper}>
            <StyledToolTipWrapper>
              <p className={classes.email_label}>Email Address You Wish To Send From</p>
              <Tooltip
                title="Once you’ve purchased a domain, you typically have the option to create an associated email address through the same company you bought the domain from. If you require further assistance, feel free to contact us at support@cohere.live, and we’ll be happy to provide information on how we can assist you."
                arrow
              >
                <InfoIcon htmlColor="#C9B382" />
              </Tooltip>
            </StyledToolTipWrapper> */}
        {/* <TextField
              className={classes.email_input}
              placeholder="e.g. firstname@yourname.com"
              variant="outlined"
              value={email}
              onChange={handleEmailChange}
              InputProps={{
                classes: {
                  root: classes.email_root,
                  notchedOutline: classes.email_notchedOutline,
                  adornedEnd: classes.email_adornedEnd,
                },
                endAdornment: domain.length > 0 && <div className={classes.endAdornment}>@{emailDomain}</div>,
              }}
            /> */}
        {/* </div>
        )} */}
      </div>
    </Modal>
  );
};

export default RegisterDomainModal;
