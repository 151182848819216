import styled from 'styled-components';
import { makeStyles } from '@material-ui/core';
import { colors } from '../../utils/styles';

export const StyledResourceOuterContainer = styled.div`
  position: relative;
  background-color: ${colors.white};
  border-radius: 8px;
  width: 100%;
  height: 350px;
  box-shadow: 0px 0px 10px ${colors.shadowDark};
`;

export const PreviewResourceImage = styled.div`
  width: 100%;
  height: 100%;

  border-radius: 8px;
  background-color: #f5f5f5;
  object-position: center;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledResourceVideo = styled.video`
  width: 100%;
  height: 100%;
  border-radius: 5px;
  backgroundsize: 'cover';
`;

export const StyledFileOutline = styled.div`
  display: flex;
  cursor: pointer;
`;

export const StyledFileIconOutline = styled.div`
  background-image: url(${props => props.imageUrl || ''});
  background-size: cover;
  background-color: #f5f5f5;
  height: 70px;
  width: 70px;
`;

export const StyledAlignmentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledResourceDateTime = styled.p`
  margin: 0px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 16.5948px;
  line-height: 24px;

  color: #215c73;
`;

export const StyledResourcePartition = styled.p`
  margin: 0px;
  color: #b2cae0;
`;

export const StyledResourceActions = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const useStylesResource = makeStyles(theme => ({
  ResourceCardBox: {
    height: '100%',
    width: '100%',
    padding: 10,
  },
  basicResourceTextInfo: {
    height: 'auto',
    padding: '0px 15px 10px 15px',
  },
  basicResourceImage: {
    height: 170,
    width: 302,
    marginTop: '6px',
    padding: '10px 15px 10px 15px',
  },
  basicResourceCard: {
    height: '230px !important',
    width: '339px !important',
    [theme.breakpoints.down(500)]: {
      width: '300px !important',
      height: 230,
    },
  },
}));
