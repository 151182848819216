import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, Redirect } from 'react-router-dom';

import { useRouter } from 'hooks';
import { JOURNEY_ROUTES } from 'helpers/constants';

import { ClientJourneyContribution } from 'services/dataContracts/contributions';
import UpcomingContributions from '../pages/Dashboard/components/ClientDashboard/ClientJourney/Sessions/UpcomingContributions';
import PastContributions from '../pages/Dashboard/components/ClientDashboard/ClientJourney/Sessions/PastContributions';
import AllPurchasesContributions from '../pages/Dashboard/components/ClientDashboard/ClientJourney/Sessions/AllPurchasesContributions';
import PastContributionView from 'pages/Dashboard/components/ClientDashboard/ClientJourney/Sessions/PastContributionView';
import UpcomingContributionView from 'pages/Dashboard/components/ClientDashboard/ClientJourney/Sessions/UpcomingContributionView';

const Sessions = ({ upcomingContributions, pastContributions }) => {
  const {
    match: { path, url },
  } = useRouter();

  // const upcomingContributionsArray = useMemo(
  //   () => Object.keys(upcomingContributions).flatMap(key => upcomingContributions[key]),
  //   [upcomingContributions],
  // );
  // const pastContributionsArray = useMemo(() => Object.keys(pastContributions).flatMap(key => pastContributions[key]), [
  //   pastContributions,
  // ]);
  const allPurchasesContributionsArray = [...upcomingContributions, ...pastContributions];
  return (
    <Switch>
      <Route
        exact
        path={`${path}/upcoming`}
        // component={() => <UpcomingContributions contributions={upcomingContributions} />}
        component={() => <UpcomingContributionView contributions={upcomingContributions} />}
      />
      {/* <Route exact path={`${path}/past`} component={() => <PastContributions contributions={pastContributions} />} /> */}
      <Route exact path={`${path}/past`} component={() => <PastContributionView contributions={pastContributions} />} />
      {!path.includes(JOURNEY_ROUTES.SESSIONS) && (
        <Route
          exact
          path={`${path}/all`}
          component={() => <AllPurchasesContributions contributions={allPurchasesContributionsArray} />}
        />
      )}
      <Redirect to={path.includes(JOURNEY_ROUTES.SESSIONS) ? `${url}/allsessions` : `${url}/all`} />
    </Switch>
  );
};

const contributionsPropType = PropTypes.arrayOf(PropTypes.instanceOf(ClientJourneyContribution)).isRequired;

Sessions.propTypes = {
  upcomingContributions: PropTypes.shape({
    otherIncompleted: contributionsPropType,
    today: contributionsPropType,
    thisWeek: contributionsPropType,
    thisMonth: contributionsPropType,
    nextMonth: contributionsPropType,
    thisYear: contributionsPropType,
    afterThisYear: contributionsPropType,
  }).isRequired,
  pastContributions: PropTypes.shape({
    priorYears: contributionsPropType,
    thisYear: contributionsPropType,
    lastMonth: contributionsPropType,
    thisMonth: contributionsPropType,
    thisWeek: contributionsPropType,
  }).isRequired,
};

export default Sessions;
