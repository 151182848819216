import React from 'react';
import PropTypes from 'prop-types';

import Button from 'components/FormUI/Button';
import { useHttp } from 'hooks';
import { connect } from 'react-redux';
import useAccount from 'hooks/useAccount';
import { TemplateType } from 'helpers/constants/templateType';
import { ContributionType } from 'helpers/constants';
import { ColorSchemeService } from 'services/color.service';
import { determineColorToUse } from 'services/contributions.service';
import { lightOrDark } from 'utils/utils';
import { DarkEnabledPrimaryOutlineButton } from '../../component/styled';
const UnbookSessionTime = ({
  contribution,
  session,
  sessionTime,
  onSessionTimeUnbooked,
  mobileView,
  isSessionView = false,
}) => {
  const { request, loading } = useHttp();

  const onUnbookSession = () => {
    request('/Contribution/RevokeBookSessionTime', 'POST', {
      contributionId: contribution.id,
      sessionId: session.id,
      sessionTimeId: sessionTime.id,
    }).then(() => onSessionTimeUnbooked());
  };
  const { user, currentRole } = useAccount();
  const isCoach = currentRole == 'Cohealer';
  const activeTemplate = contribution?.activeTemplate || TemplateType.TemplateThree;
  const type = contribution?.type;
  // const brandingColors = contribution?.brandingColors;
  // const isCustomBrandingColorsActive = contribution?.isCustomBrandingColorsActive;
  // const shouldChangeColor = type != ContributionType.contributionOneToOne;
  // const customBtnColor = isCustomBrandingColorsActive
  //   ? brandingColors?.PrimaryColorCode
  //   : activeTemplate === TemplateType.TemplateOne
  //   ? '#6999CA'
  //   : '#D08ACD';
  let colorToUse = determineColorToUse(contribution);
  let textColor =
    colorToUse?.TextColorCode === 'Auto'
      ? lightOrDark(colorToUse?.PrimaryColorCode)
      : colorToUse?.TextColorCode === '#000000'
      ? '#000000'
      : '#FFFFFF';
  const btnColor = colorToUse.PrimaryColorCode;
  // const btnColor = ColorSchemeService().PrimaryColorCode;

  return isSessionView ? (
    <DarkEnabledPrimaryOutlineButton
      autoWidth
      textColor={colorToUse.PrimaryColorCode}
      borderColor={colorToUse.PrimaryColorCode}
      variant="outline"
      onClick={onUnbookSession}
      disabled={loading}
      mobileView={mobileView}
      backgroundColor={contribution?.isDarkModeEnabled ? 'transparent' : null}
      {...{ isDarkModeEnabled: contribution?.isDarkModeEnabled }}
    >
      Unbook Session Time
    </DarkEnabledPrimaryOutlineButton>
  ) : (
    <Button
      style={{ backgroundColor: btnColor }}
      textColor={textColor}
      autoWidth
      onClick={onUnbookSession}
      disabled={loading}
      mobileView={mobileView}
    >
      Unbook Session Time
    </Button>
  );
};

UnbookSessionTime.propTypes = {
  contribution: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  session: PropTypes.shape({
    id: PropTypes.string,
  }),
  sessionTime: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  onSessionTimeUnbooked: PropTypes.func.isRequired,
  mobileView: PropTypes.bool,
};

const mapStateToProps = ({ contributions }) => ({
  brandingColors: contributions?.brandingColors,
});

export default connect(mapStateToProps)(UnbookSessionTime);
