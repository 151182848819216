import axiosInstance from 'utils/axiosInstance';

const BASE_URL = '/Zoom';

const connectAccount = data => {
  const { authorizationCode, redirectUri } = data;

  const endpoint = `${BASE_URL}/CreateZoomRefreshToken`;

  return axiosInstance.post(endpoint, { authToken: authorizationCode, redirectUri });
};

const disconnectAccount = () => {
  const endpoint = `${BASE_URL}/DisconnectZoom`;

  return axiosInstance.post(endpoint);
};

export const ZoomIntegrationService = {
  connectAccount,
  disconnectAccount,
};
