import jwt from 'jsonwebtoken';

function getJson(res) {
  return res.json();
}

const authError = (dispatch, action) => error => {
  if (error?.response?.status === 401) {
    dispatch(action());
  }

  throw error;
};

const isComponentMounted = _isMounted => response => {
  if (_isMounted.current) {
    return response;
  }

  throw new Error('Component is unmounted now');
};

const checkToken = token => {
  if (!token) {
    return false;
  }

  const decoded = jwt.decode(token);
  return !(decoded?.exp < Date.now() / 1000);
};

export { getJson, authError, isComponentMounted, checkToken };
