const PREFIX = 'NAVIGATION/';

const RESET_STATE = `${PREFIX}RESET_STATE`;

const SET_IS_TRANSITIONED_TO_COACH_VIEW = `${PREFIX}SET_IS_TRANSITIONED_TO_COACH_VIEW`;

export const NAVIGATION_ACTION_TYPES = {
  RESET_STATE,
  SET_IS_TRANSITIONED_TO_COACH_VIEW,
};
