import React from 'react'
import PropTypes from 'prop-types'
import { mdiYahoo } from '@mdi/js'
import { Icon } from '@mdi/react'

import { StyledBackground } from './StyledBackground'

export const IconYahoo = ({ className }) => (
  <StyledBackground>
    <Icon className={className} path={mdiYahoo} color="#6A00DA" width={20} height={20} />
  </StyledBackground>
)

IconYahoo.propTypes = {
  className: PropTypes.string,
}

IconYahoo.defaultProps = {
  className: null,
}
