import React from 'react'

import Modal from 'components/UI/Modal.js'
import { LabelText } from 'components/UI/Text/TextStyles'
import { INFO_MODAL_TEXTS } from './constants'

export const InfoModal = ({ type, handleClose }) => {
  return (
    <Modal
      isOpen={type}
      onCancel={handleClose}
      onSubmit={handleClose}
      title={INFO_MODAL_TEXTS[type].title}
      hiddenCancel
    >
      <LabelText>{INFO_MODAL_TEXTS[type].text}</LabelText>
    </Modal>
  )
}
