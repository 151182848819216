import React, { useEffect, useRef, useState } from 'react';
import { Autocomplete, TextField, Typography, Box } from '@mui/material';
import { fetchContributionActions } from 'actions/contributions';
import { ContributionType } from 'helpers/constants';
import * as contributionService from 'services/contributions.service';
import { useDispatch, useSelector } from 'react-redux';
import Loader from 'components/UI/Loader';
import CommonErrorMessage from 'components/FormUI/CommonErrorMessage';
import { isEmpty } from 'lodash';
import { ClickAwayListener, Popper } from '@material-ui/core';
import Chip from '@mui/material/Chip';
import useContribution from 'pages/ContributionView/hooks/useContribution';
import NoImage from 'assets/NoImage.png';
import { useMediaQuery, useTheme } from '@material-ui/core';
import styled from 'styled-components';
import CustomButton from 'components/FormUI/Button';

const selector = state => ({
  contributionsForTable: state?.upcomingCreated?.upcomingCreated?.contributionsForTable || [],
  loadingContibutions: state?.upcomingCreated?.loading || false,
});

const StyledAutocomplete = styled(Autocomplete)`
  .MuiAutocomplete-inputRoot {
    padding-right: 20px !important;
  }
  .MuiAutocomplete-endAdornment {
    top: auto;
  }

  svg {
    color: black;
  }
`;

const StyledPopper = styled(Popper)`
  z-index: 1400 !important;
`;

function CustomOption({ label, selected }) {
  return (
    <Typography
      className="dropdownList"
      style={{ fontWeight: selected ? '600' : '500', fontFamily: 'Avenir', fontSize: '14px' }}
    >
      {label}
    </Typography>
  );
}

const ContributionForm = ({
  contributionSelectionDisable,
  setpricingOption,
  availabilityModal = false,
  setFieldValue,
  formErrors,
  touched,
  selectedContribution = null,
  fromMasterCalendar,
  hasActiveContribution,
}) => {
  const [loadingContribution, setLoadingContribution] = useState(false);
  const { contributionsForTable, loadingContibutions } = useSelector(selector);
  const [contributionLabel, setContributionLabel] = useState('initial');
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [selectedClient, setSelectedClient] = useState([]);
  const [selectedContributionId, setSelectedContributionId] = useState([]);
  const [contributionOptions, setContributionOptions] = useState([]);
  const [tagsNumber, setTagsNumber] = useState(0);
  const dispatch = useDispatch();
  const dropdownRef = useRef(null);
  const { id } = useContribution();
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down('xs'));
  const [contributionSelected, setContributionSelected] = useState(false);

  useEffect(() => {
    if (selectedContribution != null) {
      setSelectedClient([selectedContribution]);
      setTagsNumber(1);
      setFieldValue('contributions', [selectedContribution]);
    }

    contributionService
      .getContributionFromList('ContributionOneToOne', id)
      .then(res => {
        const contributions = res.map(contrib => {
          return {
            label: contrib?.title || '',
            id: contrib?.id || '',
            image: contrib?.contributionImage || '',
          };
        });
        setContributionOptions(contributions);

        const setInitialData = async () => {
          setLoadingContribution(true);
          setContributionLabel(contributions[0].label);
          setSelectedContributionId(contributions[0].id);
          setLoadingContribution(false);
        };
        setInitialData();
      })
      .catch(error => {});
  }, []);

  const handleOutsideClick = e => {
    if (!isDropdownVisible || !dropdownRef?.current) {
      return;
    }
    if (
      !e?.target?.className?.includes('dropdownList') &&
      !dropdownRef?.current?.contains(e.target) &&
      !e?.target?.className?.includes('MuiAutocomplete-option')
    ) {
      setIsDropdownVisible(false);
    }
  };
  function areAllObjectsPresent(childArray, parentArray) {
    return childArray.every(childObj =>
      parentArray.some(parentObj => Object.entries(childObj).every(([key, value]) => parentObj[key] === value)),
    );
  }
  function removeCommonObjects(childArray, parentArray) {
    const filteredParentArray = parentArray.filter(
      parentObj => !childArray.some(childObj => Object.keys(childObj).every(key => parentObj[key] === childObj[key])),
    );
    return filteredParentArray;
  }
  function isKeyWithValuePresent(key, value, arrayOfObjects) {
    return arrayOfObjects.some(obj =>
      Object.entries(obj).some(([objKey, objValue]) => objKey === key && objValue === value),
    );
  }

  const setContribution = async (selectedContributionId, selectedContributionLabel) => {
    if (!selectedContributionId) return;
    if (hasActiveContribution) {
      setLoadingContribution(true);
      const result = await contributionService.getCohealerContribution(selectedContributionId);
      setLoadingContribution(false);
      setpricingOption({ paymentInfo: result.paymentInfo, id: selectedContributionId });
      dispatch(fetchContributionActions.success(result));
    }
    setContributionLabel(selectedContributionLabel);
    setSelectedContributionId(selectedContributionId);
  };
  const handleNext = async () => {
    setLoadingContribution(true);
    const result = await contributionService.getCohealerContribution(selectedContributionId);
    setLoadingContribution(false);
    setpricingOption({ paymentInfo: result.paymentInfo, id: selectedContributionId });
    setContributionSelected(true);
    dispatch(fetchContributionActions.success(result));
  };
  const getItemLable = option => {
    if (isMobileView && option?.label?.length > 30) {
      return `${option?.label.substring(0, 30)}...`;
    }
    return option?.label;
  };

  const CustomTagDisplay = ({ option, onDelete, isDarkThemeApplied, numOfTags, availabilityModal }) => {
    const maxLength = 20; // Set the maximum length for the displayed text
    const displayText =
      option?.label?.length > maxLength
        ? option?.label?.substring(0, maxLength) + '...' // Truncate the text
        : option?.label;
    const chipStyle = {
      color: 'white',
      border: '1px solid white',
    };
    return availabilityModal ? (
      <Chip
        label={numOfTags > 1 ? displayText : option?.label}
        onDelete={null}
        style={isDarkThemeApplied ? chipStyle : { fontFamily: 'Avenir', marginBottom: '3px', marginRight: '3px' }}
      />
    ) : (
      <Chip
        label={numOfTags > 1 ? displayText : option?.label}
        onDelete={null}
        style={isDarkThemeApplied ? chipStyle : {}}
      />
    );
  };

  const FooterContainer = styled(Box)`
    margin: 10px 0px;
    display: flex;
    width: 100%;
    justify-content: right;
    gap: 16px;
    margin-top: 5px !important;
    padding: 0px 16px;
  `;

  const StyledLine = styled.div`
    width: 100%;
    height: 1px;
    margin-bottom: 10px;
    background-color: ${({ color }) => color};
    padding: 0px !important;
  `;
  const NextButton = styled(CustomButton)`
    height: 38px;
    width: 93px;
    gap: 0px;
    border-radius: 5px;
    opacity: 0px;
    display: flex;
    padding: 12px 28px;
    align-items: center;
    align-self: stretch;
    background: var(--Cohere-Secondary-Brown, #c9b382);
  `;
  return (
    <>
      {(loadingContibutions || loadingContribution) && <Loader zIndex={1300} />}
      <div
        style={{
          flexDirectio: 'row',
          justifyContent: 'space-between',
          display: 'flex',
          padding: !availabilityModal && !hasActiveContribution ? '0px 16px' : '',
        }}
      >
        <p
          style={
            availabilityModal
              ? { fontSize: '14px', fontFamily: 'Avenir', fontWeight: '500', lineHeight: '16px', margin: 0 }
              : {
                  fontSize: '16px',
                  fontFamily: 'Avenir',
                  margin: 0,
                  color: '#215C73',
                  fontWeight: '500',
                }
          }
        >
          {availabilityModal ? 'Contributions Availability' : 'Choose service to invite a client'}
        </p>
        {availabilityModal && (
          <p
            onClick={() => {
              if (selectedClient.length === contributionOptions.length) {
                setSelectedClient([]);
                setFieldValue('contributions', []);
              } else {
                setSelectedClient(contributionOptions);
                setFieldValue('contributions', contributionOptions);
              }
            }}
            style={{ fontSize: '14px', fontFamily: 'Avenir', margin: 0, fontWeight: '900', cursor: 'pointer' }}
          >
            {selectedClient.length === contributionOptions.length ? 'Deselect all' : 'Select all'}
          </p>
        )}
      </div>

      <ClickAwayListener onClickAway={handleOutsideClick}>
        <StyledAutocomplete
          multiple={availabilityModal ? true : false}
          name="contribution"
          fullWidth
          ref={dropdownRef}
          disabled={contributionSelectionDisable}
          style={{
            marginBottom: '10px',
            fontFamily: 'Avenir',
            whiteSpace: 'break-spaces',
            border: !availabilityModal && '1px solid #DFE3E4',
            borderRadius: !availabilityModal && '4px',
            borderBottom: availabilityModal ? '1px solid #979797' : '1px solid #DFE3E4',
            height: hasActiveContribution ? '40px' : '',
            width: 'unset',
            margin: !availabilityModal && !hasActiveContribution ? '4px 16px 16px 16px' : '',
          }}
          id="select-contribution-self-book"
          clearIcon={false}
          value={availabilityModal ? selectedClient : contributionLabel === 'initial' ? '' : contributionLabel}
          defaultValue={[]}
          open={isDropdownVisible}
          PopperComponent={StyledPopper}
          options={
            availabilityModal
              ? contributionOptions
              : contributionsForTable
                  ?.filter(
                    contrib => contrib.status === 'Approved' && contrib.type === ContributionType.contributionOneToOne,
                  )
                  .map(contrib => {
                    return {
                      label: contrib?.title || '',
                      id: contrib?.id || '',
                      image: contrib?.contributionImage || '',
                    };
                  }) || []
          }
          noOptionsText={
            <p
              className="dropdownList"
              style={{
                margin: '5px',
                color: 'rgba(0, 0, 0, 0.87)',
                fontSize: '0.875rem',
                fontFamily: 'Avenir',
                letterSpacing: '0.4px',
              }}
            >
              No Contribution Found
            </p>
          }
          renderTags={(value, getTagProps) => {
            const numOfTags = value.length;
            setTagsNumber(numOfTags);
            const limitTags = 4;
            return availabilityModal ? (
              <>
                {value.slice(0, limitTags).map((option, index) => (
                  <CustomTagDisplay
                    availabilityModal={availabilityModal}
                    key={option.title}
                    option={option}
                    numOfTags={numOfTags}
                    {...getTagProps({ index })}
                  />
                ))}
                <div>{numOfTags > limitTags && ` +${numOfTags - limitTags}`}</div>
              </>
            ) : null;
          }}
          renderOption={(props, option, { selected }) =>
            availabilityModal ? (
              <li key={option?.id} {...props} style={{ borderBottom: '1px solid #e7e7e7' }}>
                <CustomOption
                  label={option.label}
                  selected={selected || isKeyWithValuePresent('id', option.id, selectedClient)}
                />
              </li>
            ) : (
              <div
                className="dropdownList"
                onKeyDown={() => {}}
                role="button"
                tabIndex="0"
                onClick={() => {
                  if (!option) {
                    return;
                  }
                  setContribution(option.id, option.label);
                  setIsDropdownVisible(false);
                  const filterContribution = contributionsForTable?.filter(contrib => contrib.id === option.id);
                  if (availabilityModal === false) {
                    setpricingOption({ paymentInfo: filterContribution[0].paymentInfo, id: option.id });
                  }
                }}
                style={{
                  borderTop: '1px solid #f2f2f2',
                  cursor: 'pointer',
                }}
              >
                <p
                  className="dropdownList"
                  style={{
                    margin: '8px',
                    color: '#2B2B2B',
                    fontSize: '16px',
                    fontWeight: '400',
                    lineHeight: '20px',
                    wordWrap: 'break-word',
                    fontFamily: 'Avenir',
                    display: 'flex',
                    pointerEvents: 'none',
                    alignItems: 'center',
                  }}
                >
                  <img
                    style={{
                      width: isMobileView ? '113px' : '121px',
                      height: isMobileView ? '44px' : '48px',
                      marginRight: '8px',
                      borderRadius: '2px',
                      border: '1px',
                    }}
                    src={option.image.length === 0 ? NoImage : option?.image}
                    alt={option.label}
                  />
                  <span>{getItemLable(option)}</span>
                </p>
              </div>
            )
          }
          onChange={async (event, option) => {
            if (availabilityModal) {
              let values = option;
              if (values.length === 0) {
                if (areAllObjectsPresent(values, selectedClient)) {
                  const updatedArray = removeCommonObjects(selectedClient, values);
                  setSelectedClient(updatedArray);
                } else {
                  setContributionLabel('initial');
                  setSelectedClient([]);
                }
              } else {
                if (areAllObjectsPresent(values, selectedClient)) {
                  setSelectedClient(values);
                } else {
                  setSelectedClient(values);
                }
              }
              setFieldValue('contributions', values);
            }
          }}
          label="Contribution"
          renderInput={params => (
            <TextField
              {...params}
              class="contributionLabelText"
              required
              variant="standard"
              onClick={e => {
                if (contributionSelectionDisable) return;
                const isArrowIconClicked =
                  !e.target.className.length || !e.target.className.includes('MuiInputBase-input');
                if (isArrowIconClicked) {
                  setIsDropdownVisible(!isDropdownVisible);
                } else {
                  if (!isDropdownVisible) {
                    setIsDropdownVisible(true);
                  }
                }
              }}
              onChange={e => {
                setIsDropdownVisible(false);
                setTimeout(() => {
                  setIsDropdownVisible(true);
                }, 300);
              }}
              placeholder={
                tagsNumber == 0 && !availabilityModal
                  ? 'Select 1:1 Service'
                  : tagsNumber == 0
                  ? 'Select 1:1 Contribution'
                  : availabilityModal && formErrors?.contributions
                  ? 'Select 1:1 Contribution'
                  : ''
              }
              value={contributionLabel}
              style={{ margin: contributionSelected ? '4px 3px 4px 8px' : '4px 8px' }}
            />
          )}
        />
      </ClickAwayListener>
      {(isEmpty(contributionLabel) || (availabilityModal && formErrors?.contributions && touched.contributions)) && (
        <div>
          <CommonErrorMessage message={'Contribution is required'} align="left" />
        </div>
      )}

      {!availabilityModal && !hasActiveContribution && (
        <div>
          <StyledLine color="#e7e7e7" />
          <FooterContainer mt={2}>
            <NextButton onClick={handleNext}>Next</NextButton>
          </FooterContainer>
        </div>
      )}
    </>
  );
};

export default ContributionForm;
