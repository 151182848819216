import { useCallback, useState, useEffect } from 'react';
import TwilioVideo from 'twilio-video';

export const useAudioTrack = () => {
  const [audioTrack, setAudioTrack] = useState();
  const isEnabled = Boolean(audioTrack);

  const startLocalAudioTrack = useCallback(async (deviceId = null) => {
    try {
      const createdAudioTrack = await TwilioVideo.createLocalAudioTrack({
        //logLevel: "debug",
        deviceId: deviceId ? { exact: deviceId } : undefined,
      });
      setAudioTrack(createdAudioTrack);
    } catch (e) {
      console.log('Failed to turn on camera.', e);
    }
  }, []);

  const stopLocalAudioTrack = useCallback(() => {
    if (audioTrack) {
      audioTrack.stop();
      setAudioTrack(undefined);
    }
  }, [audioTrack, setAudioTrack]);

  const switchVideoDevice = useCallback(
    deviceId => {
      stopLocalAudioTrack();
      startLocalAudioTrack(deviceId);
    },
    [startLocalAudioTrack, stopLocalAudioTrack],
  );

  const setIsLocalVideoTrackEnabled = useCallback(
    enabled => {
      if (enabled) {
        startLocalAudioTrack();
      } else {
        stopLocalAudioTrack();
      }
    },
    [startLocalAudioTrack, stopLocalAudioTrack],
  );

  useEffect(
    () => () => {
      stopLocalAudioTrack();
    },
    [stopLocalAudioTrack],
  );

  return {
    audioTrack,
    isEnabled,
    setIsEnabled: setIsLocalVideoTrackEnabled,
    deviceLabel: isEnabled && audioTrack ? audioTrack.mediaStreamTrack.label : null,
    switchDevice: switchVideoDevice,
    mediaStreamTrack: audioTrack?.mediaStreamTrack,
  };
};
