import { makeStyles } from '@material-ui/core';

const stylesMemberPodsAssignment = () => ({
  loaderWrapper: {
    maxWidth: 75,
  },
  selectWrapper: {
    width: '100%',
  },
  wrapper: {
    marginRight: 12,
    minWidth: 220,
    maxWidth: 220,
  },
});

export const useStyles = makeStyles(stylesMemberPodsAssignment);
