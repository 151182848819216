import { Drawer, IconButton, makeStyles } from '@material-ui/core';
import React from 'react';
import PropTypes, { string } from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';
import RightSectionMenu from './RightSectionMenu';

function RightDrawer({
  isOpen,
  setIsOpen,
  currentRole,
  mobileView,
  setOpenLinks,
  setOpenResources,
  loading,
  setLoading,
  onDeleteResource,
  isDarkThemeEnabled,
  themedColors,
  hideRightPanel,
  successMessage,
}) {
  const useStyles = makeStyles(theme => ({
    drawer: {
      '& .MuiDrawer-paper': {
        backgroundColor: themedColors.themedBackgroundColor,
        color: themedColors.themedColor,
      },
    },
  }));
  const classes = useStyles();

  return (
    <Drawer anchor="right" open={isOpen} onClose={() => setIsOpen(false)} className={classes.drawer}>
      <div className="community-section-drawer">
        <div className="close-icon justify-content-start">
          <IconButton onClick={() => setIsOpen(false)} className="p-0">
            <CloseIcon style={{ color: isDarkThemeEnabled ? 'white' : 'black' }} />
          </IconButton>
        </div>
        <RightSectionMenu
          currentRole={currentRole}
          mobileView={mobileView}
          setOpenLinks={setOpenLinks}
          setOpenResources={setOpenResources}
          loading={loading}
          setLoading={setLoading}
          onDeleteResource={onDeleteResource}
          isDarkThemeEnabled={isDarkThemeEnabled}
          hideRightPanel={hideRightPanel}
          successMessage={successMessage}
        />
      </div>
    </Drawer>
  );
}

RightDrawer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  currentRole: PropTypes.string.isRequired,
  mobileView: PropTypes.bool.isRequired,
  setOpenLinks: PropTypes.func.isRequired,
  setOpenResources: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  setLoading: PropTypes.func.isRequired,
  onDeleteResource: PropTypes.func.isRequired,
  isDarkThemeEnabled: PropTypes.bool.isRequired,
  themedColors: PropTypes.shape({ themedBackgroundColor: string, themedColor: string }).isRequired,
  hideRightPanel: PropTypes.bool.isRequired,
  successMessage: PropTypes.func.isRequired,
};

export default RightDrawer;
