import styled from 'styled-components';

export const StyledButton = styled.button`
  background-color: ${props => props.bgColor};
  color: ${props => props.color};
  border-radius: 4px;
  margin: ${props => props.margin};
  padding: 10px 20px;
  padding: ${props => props.padding};
  border: ${props => `1px solid ${props.bgColor}`};
  &:hover {
    background-color: ${props => props.hoverBgColor};
    color: ${props => props.hoverColor};
    border: ${props => props.hoverBorder};
  }
`;

export const StyledSection = styled.section`
  color: ${props => props.color};
  width: 100%;
`;

export const StyledLinkDiv = styled.div`
  margin: 5px 0px;
  background-color: ${props => props.bgColor};
  border-radius: 10px;
  padding: 10px 20px;
  display: flex;
  align-items: center;

  &:hover {
    outline: 2px solid ${props => props.primaryColor};
    cursor: pointer;
  }
`;

export const StyledNav = styled.div`
  color: ${props => props.color};
  background-color: ${props => props.bgColor};
  overflow: auto;
  white-space: nowrap;

  a {
    display: inline-block;
    color: ${props => props.color};
    text-align: center;
    padding: 14px;
    text-decoration: none;
  }
  &::-webkit-scrollbar {
    display: none; /* for Chrome, Safari and Opera */
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  ${({ mobileView }) =>
    mobileView &&
    `
  a {
    padding: 7px;
    margin: 0px 3px;
  }
  .message-btn {
    padding: 2px !important;
    margin: 0px 10px !important;
  }
  `}

  ${({ desktop }) =>
    desktop &&
    `
  .message-btn {
    margin-right: 8%;
  }

  `}
`;

export const StyledIntroDiv = styled.div`
  background-image: url(${props => props.bioBannerUrl});
  background-color: #efefef;
  width: 100%;
  width: 100%;
  border-radius: ${props => (props.borderRadius ? props.borderRadius : 'none')};
  background-size: cover;
  background-repeat: no-repeat;
  text-align: center;
  padding: 100px 0px;
`;
