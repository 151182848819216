import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import * as actions from 'actions/chat';

const useChatActions = () => {
  const dispatch = useDispatch();

  const startChat = useCallback(({ token }) => dispatch(actions.startChat({ token })), [dispatch]);
  const updateChatToken = useCallback(token => dispatch(actions.updateChatToken(token)), [dispatch]);
  const endChat = useCallback(() => dispatch(actions.endChat()), [dispatch]);

  return {
    startChat,
    updateChatToken,
    endChat,
  };
};

export default useChatActions;
