import React from 'react';
import TableContainer from '@material-ui/core/TableContainer';
import withStyles from '@material-ui/core/styles/withStyles';
import { Paper, Table, TableHead } from '@material-ui/core';
import PropTypes, { node } from 'prop-types';
import { Pagination } from '@mui/material';

const StyledTableContainer = withStyles({
  root: {
    overflowX: 'auto',
    maxWidth: props => (props.mobileView ? '96vw' : '100%'),
    margin: '0 auto',
  },
})(TableContainer);

function ClientDetail({
  clientId,
  title,
  headers,
  mobileView,
  setData,
  updatePage,
  pagesCount,
  setLoading,
  page,
  setPage,
  pageSize,
  paginationClass = '',
  children,
}) {
  const getUpdateData = async pageNumber => {
    setLoading(true);
    const data = await updatePage(clientId, pageNumber, pageSize);
    setData([]);
    setData(data);
    setLoading(false);
  };

  const handlePageChange = (event, value) => {
    setPage(value);
    if (value !== page) {
      getUpdateData(value);
    }
  };

  if (!children || children.length === 0) {
    return null;
  }

  return (
    <div className="client-info-data-table-container">
      <div className="bordered-table">
        {title && <div className="title">{title}</div>}
        {mobileView ? (
          <>{children}</>
        ) : (
          <StyledTableContainer component={Paper}>
            <Table
              className="client-info-data-table"
              aria-label="client-info-data-table"
              style={{ tableLayout: 'fixed' }}
            >
              <TableHead>{headers}</TableHead>
              {children}
            </Table>
          </StyledTableContainer>
        )}
      </div>
      <Pagination
        page={page}
        count={pagesCount}
        onChange={handlePageChange}
        className={`client-info-table-pagination ${paginationClass}`}
        size="small"
        shape="rounded"
      />
    </div>
  );
}

ClientDetail.propTypes = {
  clientId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  headers: PropTypes.arrayOf(node).isRequired,
  setData: PropTypes.func.isRequired,
  pagesCount: PropTypes.number.isRequired,
  mobileView: PropTypes.bool.isRequired,
  updatePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  pageSize: PropTypes.number.isRequired,
  setLoading: PropTypes.func.isRequired,
  paginationClass: PropTypes.string.isRequired,
  children: PropTypes.arrayOf(node).isRequired,
};

export default ClientDetail;
