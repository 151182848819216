import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import PropTypes from 'prop-types';
import SettingsIcon from '@mui/icons-material/Settings';
import GoogleIcon from '@mui/icons-material/Google';

const ExternalCalendarVisibilityComponent = ({
  isPreviewMasterCalendarEvents,
  emailAddress,
  onPreviewIconClick,
  removeCalendar,
  style,
  provider,
  onSettingsClick,
}) => {
  const chooseProviderIcon = mailProvider => {
    switch (mailProvider) {
      case 'gmail':
        return GoogleIcon;
      default:
        return null;
    }
  };
  const ProviderIcon = chooseProviderIcon(provider);
  return (
    <div className="attached-calendar" style={style || {}}>
      {ProviderIcon && (
        <ProviderIcon style={!isPreviewMasterCalendarEvents ? { opacity: '0.5' } : {}} className="provider-logo" />
      )}
      <p style={!isPreviewMasterCalendarEvents ? { opacity: '0.5' } : {}}>{emailAddress}</p>
      <CloseIcon
        style={!isPreviewMasterCalendarEvents ? { opacity: '0.5' } : {}}
        onClick={() => removeCalendar(emailAddress)}
      />
      {isPreviewMasterCalendarEvents ? (
        <VisibilityIcon
          style={!isPreviewMasterCalendarEvents ? { opacity: '0.5' } : {}}
          onClick={() => {
            onPreviewIconClick(false);
          }}
          className="visibility-icon"
        />
      ) : (
        <VisibilityOffIcon
          style={!isPreviewMasterCalendarEvents ? { opacity: 1 } : {}}
          onClick={() => {
            onPreviewIconClick(true);
          }}
          className="visibility-icon"
        />
      )}
      <SettingsIcon
        style={!isPreviewMasterCalendarEvents ? { opacity: '0.5' } : {}}
        onClick={() => {
          if (onSettingsClick) onSettingsClick();
        }}
      />
    </div>
  );
};

ExternalCalendarVisibilityComponent.propTypes = {
  isPreviewMasterCalendarEvents: PropTypes.bool.isRequired,
  emailAddress: PropTypes.string.isRequired,
  onPreviewIconClick: PropTypes.func.isRequired,
  removeCalendar: PropTypes.func.isRequired,
  style: PropTypes.shape({}).isRequired,
  provider: PropTypes.string,
  onSettingsClick: PropTypes.func,
};

ExternalCalendarVisibilityComponent.defaultProps = {
  provider: null,
  onSettingsClick: () => {},
};

export default ExternalCalendarVisibilityComponent;
