import React from 'react';
import { Menu, MenuItem } from '@material-ui/core';
import PropTypes, { node } from 'prop-types';
import { SECTION_MENU_OPTIONS } from '../../../../constants';

const menuOptions = Object.values(SECTION_MENU_OPTIONS);

function SectionMenu({ anchorElement, labelledBy, handleClose, handleSelect, isDarkThemeEnabled }) {
  const open = Boolean(anchorElement);
  return (
    <Menu
      id="section-menu"
      anchorEl={anchorElement}
      MenuListProps={{
        'aria-labelledby': labelledBy,
      }}
      variant="menu"
      open={open}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      PaperProps={{
        style: {
          marginTop: '40px',
          color: isDarkThemeEnabled ? 'white' : 'black',
          backgroundColor: isDarkThemeEnabled ? 'rgb(37, 39, 40)' : 'white',
        },
      }}
    >
      {menuOptions.map(option => (
        <MenuItem style={{ borderBottom: '1px solid gray' }} key={option} onClick={() => handleSelect(option)}>
          {option}
        </MenuItem>
      ))}
    </Menu>
  );
}

SectionMenu.propTypes = {
  anchorElement: PropTypes.oneOf(node, null).isRequired,
  labelledBy: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSelect: PropTypes.func.isRequired,
  isDarkThemeEnabled: PropTypes.bool.isRequired,
};

export default SectionMenu;
