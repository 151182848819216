import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Grid from '@material-ui/core/Grid';
import { Field } from 'formik';

import { LabelText } from 'components/UI/Text/TextStyles';
import { colors } from 'utils/styles/styles';
import 'styles/creditCard.scss';

export const StyledInput = styled.input`
  padding: 6px 11px 10px 11px;
  border: 0;
  border-bottom: 1px solid #b3b3b3;
  outline: none;
  font-size: 18px;
  color: rgba(0, 0, 0, 0.65);
  transition: all 0.3s;
  width: 100%;
  box-sizing: border-box;

  &:hover {
    border-color: none;
  }

  &::placeholder {
    color: rgba(0, 0, 0, 0.3);
  }

  ${({ error }) =>
    error &&
    css`
      border-color: #ff4d4f;
    `}
`;

const StyledLink = styled.a`
  width: 100%;
  box-sizing: border-box;
  color: ${colors.darkOceanBlue};
  font-size: 12px;
  font-weight: 600;
`;

function ApplyCoupon({ handleChange, touched, errors, handleRedeem }) {
  return (
    <>
      <br />
      <br />
      <Grid container spacing={1}>
        <Grid item sm={9} xs={9}>
          <Field
            component={StyledInput}
            name="Coupon"
            placeholder="Discount Code"
            type="text"
            onChange={handleChange('Coupon')}
            error={touched.Coupon && errors.Coupon}
          />
        </Grid>
        <Grid item sm={1} xs={1}>
          <StyledLink
            href="/"
            onClick={e => {
              e.preventDefault();
              handleRedeem();
            }}
          >
            Redeem
          </StyledLink>
        </Grid>
      </Grid>
    </>
  );
}

ApplyCoupon.propTypes = {
  handleChange: PropTypes.func.isRequired,
  errors: PropTypes.shape().isRequired,
  touched: PropTypes.shape().isRequired,
  handleRedeem: PropTypes.func.isRequired,
};

export default ApplyCoupon;
