import React from 'react';

export const CopyIcon = props => {
  return (
    <svg
      onClick={props.onClick}
      width="32"
      height="32"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.75 7C4.75 5.20507 6.20507 3.75 8 3.75H13C14.7949 3.75 16.25 5.20507 16.25 7V12C16.25 13.7949 14.7949 15.25 13 15.25H8C6.20507 15.25 4.75 13.7949 4.75 12V7Z"
        stroke="#B2CAE0"
        stroke-width="1.5"
      />
      <path
        d="M18.5 8V8C19.3284 8 20 8.67157 20 9.5V16C20 18.2091 18.2091 20 16 20H10.5C9.11929 20 8 18.8807 8 17.5V17.5"
        stroke="#B2CAE0"
        stroke-width="1.5"
        stroke-linecap="round"
      />
    </svg>
  );
};
