export const JOURNEY_ROUTES = {
  SESSIONS: 'sessions',
  COACHES: 'coaches',
  MEMBERSHIP: 'membership',
  ONE_TO_ONE: 'one-to-one',
  LIVE: 'live',
  WORKSHOP: 'workshop',
};

export const SIGNUP_TYPES = {
  TYPE_A: 'SIGNUP_A',
  TYPE_B: 'SIGNUP_B',
};
