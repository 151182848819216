import { createAction } from 'redux-actions';
import { get, pipe } from 'lodash/fp';

import { getBalance } from 'services/payout.service';

export const GET_BALANCE_REQUEST = 'GET_BALANCE_REQUEST';
export const GET_BALANCE_SUCCESS = 'GET_BALANCE_SUCCESS';
export const GET_BALANCE_ERROR = 'GET_BALANCE_ERROR';

export const fetchBalanceActions = {
  request: createAction(GET_BALANCE_REQUEST),
  success: createAction(GET_BALANCE_SUCCESS),
  error: createAction(GET_BALANCE_ERROR),
};

export const fetchBalance = () => async dispatch => {
  dispatch(fetchBalanceActions.request());

  try {
    const response = await getBalance();
    dispatch(fetchBalanceActions.success(response));
  } catch (error) {
    pipe(get('response'), get('data'), fetchBalanceActions.error, dispatch)(error);
  }
};
