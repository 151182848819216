import { SvgIcon } from '@material-ui/core';
import React from 'react';
import { openInNewWindow } from 'services/links';

const LinkIcon = props => {
  return (
    <SvgIcon className={`${props?.className}`}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path
          d="M12 3C5.25049 3 3 5.25049 3 12C3 18.7495 5.25049 21 12 21C18.7495 21 21 18.7495 21 12"
          stroke={props?.color || '#213649'}
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M11.9978 11.8027L21.2155 2.68817"
          stroke={props?.color || '#213649'}
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M15.5614 2.96987L21.2188 2.68755L20.9798 8.38831"
          stroke={props?.color || '#213649'}
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </SvgIcon>
  );
};

export default LinkIcon;
