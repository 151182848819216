import React from 'react';
import classes from './MainContainer.module.scss';
import Header from 'components/UI/Header';
import styled from 'styled-components';

const StyledMainContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #fafafa;
`;

const StyledMainSection = styled.div`
  padding: ${({ mobileView }) => (mobileView ? '30px 16px' : '30px 35px')};
`;

const CreateCampaignMain = ({ headerProps, children }) => {
  return (
    <StyledMainContainer>
      <Header backButtonText="Back" isCustomPrevButton {...headerProps} />
      <StyledMainSection>{children}</StyledMainSection>
    </StyledMainContainer>
  );
};

export default CreateCampaignMain;
