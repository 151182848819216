import { MEMBER_PODS_ACTION_TYPES } from 'actions/MemberPods';

const initialState = {
  data: null,
  error: null,
  isFetching: false,
  newPodName: '',
  contributionMemberPods: null,
};

export const memberPodsReducer = (state = initialState, action) => {
  const { payload, type } = action;

  switch (type) {
    case MEMBER_PODS_ACTION_TYPES.RESET_STATE:
      return initialState;
    case MEMBER_PODS_ACTION_TYPES.SET_CONTRIBUTION_MEMBER_PODS:
      return {
        ...state,
        contributionMemberPods: {
          ...state.contributionMemberPods,
          [payload.contributionId]: payload.data,
        },
      };
    case MEMBER_PODS_ACTION_TYPES.SET_DATA:
      return {
        ...state,
        data: payload.data,
      };
    case MEMBER_PODS_ACTION_TYPES.SET_ERROR:
      return {
        ...state,
        error: payload.error,
      };
    case MEMBER_PODS_ACTION_TYPES.SET_IS_FETCHING:
      return {
        ...state,
        isFetching: payload.isFetching,
      };
    case MEMBER_PODS_ACTION_TYPES.SET_NEW_POD_NAME:
      return {
        ...state,
        newPodName: payload.newPodName,
      };
    default:
      return state;
  }
};
