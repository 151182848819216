import styled from 'styled-components';
import { colors } from '../../utils/styles';

export const Switch = styled.label`
  position: relative;
  display: inline-block;
  width: 40px;
  height: 25px;
`;

export const SlimSwitch = styled.label`
  position: relative;
  display: inline-block;
  width: 36px;
  height: 19.5px;
`;

export const StyledInput = styled.input`
  opacity: 0;
  width: 0;
  height: 0;

  &:checked + .slider {
    background-color: ${colors.darkOceanBlue};
  }

  &:focus + .slider {
    box-shadow: 0 0 1px ${colors.darkOceanBlue};
  }

  &:checked + .slider::before {
    transform: translateX(15px);
  }
`;

export const StyledSlider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${colors.switch};
  transition: 0.4s;

  &::before {
    position: absolute;
    content: '';
    height: 19px;
    width: 19px;
    left: 3px;
    bottom: 3px;
    background-color: ${colors.white};
    transition: 0.4s;
  }

  &.round {
    border-radius: 34px;
  }

  &.round::before {
    border-radius: 50%;
  }
`;

export const SlimStyledSlider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${colors.switch};
  transition: 0.4s;

  &::before {
    position: absolute;
    content: '';
    height: 14px;
    width: 14px;
    left: 3px;
    bottom: 3px;
    background-color: ${colors.white};
    transition: 0.4s;
  }

  &.round {
    border-radius: 17px;
  }

  &.round::before {
    border-radius: 50%;
  }
`;
