import React from 'react';
import { Menu, MenuItem } from '@material-ui/core';
import PropTypes, { bool, node } from 'prop-types';
import { GROUP_MENU_OPTIONS } from '../../../../constants';

function GroupMenu({
  anchorElement,
  labelledBy,
  handleClose,
  handleSelect,
  isCoach,
  groupToEdit,
  sections,
  isDarkThemeEnabled,
}) {
  const open = Boolean(anchorElement);
  const allowMoveSection = sections?.filter(section => !section.isAnonymous)?.length > 1;

  const menuOptions = Object.keys(GROUP_MENU_OPTIONS)
    .map(groupOption => {
      if (
        !isCoach &&
        (GROUP_MENU_OPTIONS[groupOption] === GROUP_MENU_OPTIONS.editGroup ||
          GROUP_MENU_OPTIONS[groupOption] === GROUP_MENU_OPTIONS.archiveGroup ||
          GROUP_MENU_OPTIONS[groupOption] === GROUP_MENU_OPTIONS.moveTosection)
      ) {
        return null;
      }
      if (
        groupToEdit?.isSuggested &&
        (GROUP_MENU_OPTIONS[groupOption] === GROUP_MENU_OPTIONS.editGroup ||
          GROUP_MENU_OPTIONS[groupOption] === GROUP_MENU_OPTIONS.archiveGroup ||
          GROUP_MENU_OPTIONS[groupOption] === GROUP_MENU_OPTIONS.archiveGroup)
      ) {
        return null;
      }
      if (!groupToEdit?.isArchived && GROUP_MENU_OPTIONS[groupOption] === GROUP_MENU_OPTIONS.unArchive) {
        return null;
      }
      if (groupToEdit?.isArchived && GROUP_MENU_OPTIONS[groupOption] !== GROUP_MENU_OPTIONS.unArchive) {
        return null;
      }
      if (
        !groupToEdit?.isNotificationsMuted &&
        GROUP_MENU_OPTIONS[groupOption] === GROUP_MENU_OPTIONS.unmuteNotifications
      ) {
        return null;
      }
      if (
        groupToEdit?.isNotificationsMuted &&
        GROUP_MENU_OPTIONS[groupOption] === GROUP_MENU_OPTIONS.muteNotifications
      ) {
        return null;
      }
      if (!allowMoveSection && GROUP_MENU_OPTIONS[groupOption] === GROUP_MENU_OPTIONS.moveTosection) {
        return null;
      }
      return GROUP_MENU_OPTIONS[groupOption];
    })
    .filter(Boolean);

  if (!groupToEdit) {
    return null;
  }

  return (
    <Menu
      id="section-menu"
      anchorEl={anchorElement}
      MenuListProps={{
        'aria-labelledby': labelledBy,
      }}
      variant="menu"
      open={open}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      PaperProps={{
        style: {
          marginTop: '40px',
          color: isDarkThemeEnabled ? 'white' : 'black',
          backgroundColor: isDarkThemeEnabled ? 'black' : 'white',
        },
      }}
    >
      {menuOptions.map(option => (
        <MenuItem key={option} onClick={() => handleSelect(option)}>
          {option}
        </MenuItem>
      ))}
    </Menu>
  );
}

GroupMenu.propTypes = {
  anchorElement: PropTypes.oneOf(node, null).isRequired,
  labelledBy: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSelect: PropTypes.func.isRequired,
  isCoach: PropTypes.bool.isRequired,
  groupToEdit: PropTypes.shape({ isSuggested: bool, isArchived: bool, isNotificationsMuted: true }).isRequired,
  sections: PropTypes.shape([]).isRequired,
  isDarkThemeEnabled: PropTypes.bool.isRequired,
};

export default GroupMenu;
