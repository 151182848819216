import React, { useCallback, useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { useAccount, useEndVideoChat, useRouter, useShallowEqualSelector } from 'hooks';
import * as contributionActions from 'actions/contributions';
import { PAYMENTS_STATUS } from 'pages/ContributionView/components/PurchaseMembershipModal/PurchaseModal.constants';
import { ContributionType } from 'helpers/constants';
import { UserRoles } from 'helpers/constants';
import { TemplateType } from 'helpers/constants/templateType';
import TemplateOne from './Templates/TemplateOne';
import TemplateTwo from './Templates/TemplateTwo';
import TemplateThree from './Templates/TemplateThree';

function AboutContribution({ currentRole, activeContribution }) {
  const { location, query, history } = useRouter();
  const reschedulingSession = query.code === 'GuestUserReschedule';
  const { sessionTimeId } = useSelector(state => state.contributions);
  const clientPreviewMode = location?.pathname.includes('clientpreview');
  const activeTemplate = activeContribution?.activeTemplate || TemplateType.TemplateThree;

  useEffect(() => {
    if (sessionTimeId === null && reschedulingSession) {
      history.replace(`/contribution-view/${activeContribution?.id}/about`);
    }
  }, []);

  const TEMPLATE_MAPPING = {
    [TemplateType.TemplateOne]: () => <TemplateOne />,
    [TemplateType.TemplateTwo]: () => <TemplateTwo />,
    [TemplateType.TemplateThree]: () => <TemplateThree />,
  };
  const coachView = TEMPLATE_MAPPING[TemplateType.TemplateThree]();
  const clientView = activeContribution?.type ? TEMPLATE_MAPPING[activeTemplate]() : coachView;
  if (clientPreviewMode) {
    return clientView;
  }
  return currentRole === UserRoles.cohealer ? coachView : clientView;
}

const mapStateToProps = ({ contributions: { activeContribution, loading }, account }) => ({
  currentRole: account?.currentRole,
  activeContribution: activeContribution,
});

export default connect(mapStateToProps)(AboutContribution);
