import React from 'react';

import Modal from 'components/UI/Modal';
import { LabelText } from 'components/UI/Text/TextStyles';

export const SuccessfulPurchaseModal = ({ isOpen, handleClose }) => {
  return (
    <Modal isOpen={isOpen} onCancel={handleClose} onSubmit={handleClose} title="Purchase success" hiddenCancel>
      <LabelText>Your purchase was successful</LabelText>
    </Modal>
  );
};
