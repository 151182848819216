import React, { useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import * as userActions from 'actions/user';
import { parseDate } from 'utils/datesAndMoney';
import styled from 'styled-components';
import moment from 'moment';
import * as Yup from 'yup';
import { Icon } from '@mdi/react';
import { mdiLoading, mdiWeatherSunny, mdiTimerSandEmpty, mdiEyeCircleOutline } from '@mdi/js';
import * as countryActions from 'actions/country';
import Select from 'components/FormUI/Select';
import { connect } from 'react-redux';
import { Formik, Field, Form, yupToFormErrors } from 'formik';
import InfoIcon from '@material-ui/icons/Info';
import Tooltip from '@material-ui/core/Tooltip';
import { TOOLTIP, ROUTES } from '../../../../../src/constants';
import { colors } from 'utils/styles';
import Loader from 'components/UI/Loader';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
  Box,
  TextField,
} from '@material-ui/core';
import Button from 'components/FormUI/Button';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { useAccount } from 'hooks/index';
import { useSelector } from 'react-redux';
import { SIGNUP_TYPES } from 'helpers/constants';
import { editUserSpecificAttribute } from 'services/user.service';

const useStyles = makeStyles(theme => ({
  actions: {
    justifyContent: 'center',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

const StyledStripeSupportedCountries = styled.a`
  color: ${colors.darkOceanBlue} !important;
`;

function CountryModal({
  isOpen,
  handleSubmit,
  handleClose,
  countries,
  countryLoading,
  loading,
  fetchCountries,
  editProfile,
  getProfile,
  forInvoiced,
}) {
  const classes = useStyles();
  const { user } = useAccount();
  const { signupType } = useSelector(state => state.account);
  const [isPostalCodeTouched, setIsPostalCodeTouched] = useState(false);

  const showDialogModal =
    user.signupType === SIGNUP_TYPES.TYPE_A ||
    user.signupType === SIGNUP_TYPES.TYPE_B ||
    signupType === SIGNUP_TYPES.TYPE_A ||
    signupType === SIGNUP_TYPES.TYPE_B;

  const initialValues = {
    FirstName: user.firstName,
    LastName: user.lastName,
    BirthDate: parseDate(user.birthDate),
    BusinessName: user.businessName,
    BusinessType: user.businessType,
    CustomBusinessType: user.customBusinessType || '',
    Certification: user.certification,
    Occupation: user.occupation,
    CustomerLabelPreference: user.customerLabelPreference || '',
    Bio: user.bio || '',
    TimeZoneId: user.timeZoneId || 'America/Los_Angeles',
    HasAgreedToTerms: true,
  };

  const editHandler = useCallback(
    values => {
      const { CountryId, PostalCode } = values;
      const userData = {
        id: user.id,
        CountryId,
        PostalCode,
      };
      editUserSpecificAttribute(userData).then(() => {
        setTimeout(getProfile(user.id), 3000);
      });
    },
    [editProfile, user],
  );

  useEffect(() => {
    if ((!countries || countries.length === 0) && !countryLoading) {
      fetchCountries();
    }
  }, [countries, fetchCountries]);

  function CountryTooltip() {
    return (
      <p>
        Country of Residence{' '}
        <Tooltip
          title="Please choose your country of residence. 
                  This is where you are eligible to receive payments and the location of your bank account."
          arrow
          enterTouchDelay={TOOLTIP.ENTER_DELAY}
          leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
        >
          <InfoIcon htmlColor={colors.lightBrown} />
        </Tooltip>
      </p>
    );
  }

  const isStripeSupportedCuntrySelected = countryId => {
    return countries?.find(t => t.id === countryId)?.stripeSupportedCountry === true;
  };

  function getFilteredCountriesList() {
    let newCountriesList = [];
    if (forInvoiced) {
      newCountriesList = countries.filter(
        country => country.id != '60b8ddb57205057e7ce2b861' && country.id != '60b8ddb57205057e7ce2b79d',
      );
    } else {
      newCountriesList = countries;
    }

    return newCountriesList;
  }
  return (
    <Dialog fullWidth open={showDialogModal ? false : isOpen}>
      <DialogTitle style={{ paddingBottom: '0px' }}>
        <Typography align="center" variant="h6">
          Please select your country
        </Typography>
      </DialogTitle>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        // initialValues={{
        //   CountryId: '',
        // }}
        validationSchema={Yup.object().shape({
          CountryId: Yup.mixed().required(),
          PostalCode: Yup.string().when(['CountryId'], (CountryId, schema) => {
            if (CountryId === '60b8ddb57205057e7ce2b79d' && forInvoiced) {
              return schema.required('Please enter a valid postal code').length(7, 'PostalCode must be like XXX XXX.');
            }
            if (CountryId === '60b8ddb57205057e7ce2b861') {
              if (forInvoiced) {
                return schema
                  .required('Please enter a valid postal code')
                  .test('postalCodeValidation', 'PostalCode must like XXXXX or XXXXX-XXXX.', value => {
                    if (!value) {
                      return true; // Skip validation if value is undefined or empty
                    }
                    return value.length === 5 || value.length === 10;
                  });
              }
              return schema.nullable();
            }
            return schema.nullable();
          }),
        })}
        onSubmit={editHandler}
      >
        {({ values, errors, handleBlur, touched, handleChange }) => (
          <Form>
            <DialogContent style={{ paddingTop: '5px' }}>
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  paddingBottom: '20px',
                }}
              >
                <Box style={{ width: '50%' }}>
                  <Select
                    // label="Country of Residence"
                    label={CountryTooltip()}
                    name="CountryId"
                    fullWidth
                    // disabled={isAddingCoachRole}
                    items={
                      countries?.length > 0
                        ? countries?.map(country => {
                            return { value: country.id, title: country.name };
                          })
                        : []
                    }
                    onChange={e => {
                      handleChange(e);
                    }}
                  />
                </Box>
                {values.CountryId?.length > 0 && !isStripeSupportedCuntrySelected(values.CountryId) && (
                  <span>
                    <center>
                      {countries?.find(c => c.id === values.CountryId)?.name} is not supported at the moment. see&nbsp;
                      <StyledStripeSupportedCountries href="https://stripe.com/global" target="_blank">
                        stripe supported countries
                      </StyledStripeSupportedCountries>
                      &nbsp;for more info
                    </center>
                  </span>
                )}
                {values.CountryId?.length > 0 &&
                  (values.CountryId == '60b8ddb57205057e7ce2b861' || values.CountryId == '60b8ddb57205057e7ce2b79d') &&
                  forInvoiced && (
                    <span>
                      <TextField
                        className="input-field"
                        id="outlined-basic"
                        margin="normal"
                        variant="outlined"
                        name="PostalCode"
                        fullWidth
                        placeholder="Postal Code"
                        PostalCode={values.PostalCode}
                        onChange={e => {
                          handleChange(e);
                        }}
                        onBlur={e => {
                          handleBlur(e);
                          setIsPostalCodeTouched(true);
                        }}
                        error={touched.PostalCode && Boolean(errors.PostalCode)}
                        helperText={errors.PostalCode}
                      />
                    </span>
                  )}
              </Box>
            </DialogContent>
            <Grid container item xs justifyContent="center" direction="column" alignItems="center">
              <DialogActions className={classes.actions}>
                <Button
                  autoWidth
                  type="submit"
                  disabled={!isStripeSupportedCuntrySelected(values.CountryId) || loading}
                >
                  {loading ? <Icon path={mdiLoading} size={1} spin={1} /> : 'Save'}
                </Button>
              </DialogActions>
            </Grid>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
}

CountryModal.propTypes = {
  isOpen: PropTypes.bool,
  forInvoiced: PropTypes.bool,
  handleSubmit: PropTypes.func,
  handleClose: PropTypes.func,
};

CountryModal.defaultProps = {
  isOpen: false,
  forInvoiced: false,
  handleSubmit: () => {},
  handleClose: () => {},
};

const actions = {
  fetchCountries: countryActions.fetchCountries,
  editProfile: userActions.editProfile,
  getProfile: userActions.getProfile,
};

const mapStateToProps = ({ country, account }) => ({
  countries: country?.countries,
  countryLoading: country?.loading,
  loading: account?.isLoading,
});

export default connect(mapStateToProps, actions)(CountryModal);
