import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import getInitials from 'utils/getInitials';

import './ParticipantsModal.scss';
import { connect } from 'react-redux';
import Loader from 'components/UI/Loader';

const ParticipantsModal = ({ memberIds, open, onOpenChange, participants, loadingParticipants }) => {
  const onClose = () => onOpenChange(false);
  const [members, setMembers] = useState([]);
  useEffect(() => {
    if (participants) {
      setMembers(participants.filter(participant => memberIds.includes(participant.id)));
    }
  }, [open]);

  return (
    <>
      {loadingParticipants && <Loader />}
      <Dialog classes={{ paper: 'chat-participants-modal' }} open={open} onClose={onClose}>
        <DialogTitle className="chat-participants-modal-header" disableTypography>
          <p className="chat-participants-modal-header__title">Participants</p>
          <IconButton className="chat-participants-modal-header__close" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <List>
          {members?.map(m => {
            const { avatarUrl, firstName, lastName, participantId } = m;

            return (
              <ListItem key={participantId}>
                <ListItemAvatar>
                  <Avatar src={avatarUrl}>{getInitials(`${firstName} ${lastName}`)}</Avatar>
                </ListItemAvatar>
                <ListItemText
                  disableTypography
                  primary={
                    <p className="chat-participants-modal-participant-name">
                      {firstName} {lastName}
                    </p>
                  }
                />
              </ListItem>
            );
          })}
        </List>
      </Dialog>
    </>
  );
};

ParticipantsModal.propTypes = {
  memberIds: PropTypes.arrayOf(PropTypes.shape({})),
  open: PropTypes.bool.isRequired,
  onOpenChange: PropTypes.func.isRequired,
  participants: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  loadingParticipants: PropTypes.bool.isRequired,
};
ParticipantsModal.defaultProps = {
  memberIds: [],
};

const mapStateToProps = ({
  contributions: {
    activeContribution: { participants },
    loadingParticipants,
  },
}) => ({
  participants: participants || [],
  loadingParticipants,
});

export default connect(mapStateToProps)(ParticipantsModal);
