import React from 'react';
const UnsubscribeIcon = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_1_2652)">
        <path
          d="M9 9.75L15 6V4.5L9 8.25L3 4.5V6L9 9.75ZM14.25 17.25C13.2125 17.25 12.3282 16.8843 11.5972 16.1528C10.8663 15.4213 10.5005 14.537 10.5 13.5C10.4995 12.463 10.8653 11.5788 11.5972 10.8472C12.3293 10.1158 13.2135 9.75 14.25 9.75C15.2865 9.75 16.171 10.1158 16.9035 10.8472C17.636 11.5788 18.0015 12.463 18 13.5C17.9985 14.537 17.6328 15.4215 16.9028 16.1535C16.1728 16.8855 15.2885 17.251 14.25 17.25ZM12 13.875H16.5V13.125H12V13.875ZM3 15C2.5875 15 2.2345 14.8533 1.941 14.5597C1.6475 14.2662 1.5005 13.913 1.5 13.5V4.5C1.5 4.0875 1.647 3.7345 1.941 3.441C2.235 3.1475 2.588 3.0005 3 3H15C15.4125 3 15.7657 3.147 16.0597 3.441C16.3538 3.735 16.5005 4.088 16.5 4.5V8.775C16.15 8.6 15.7875 8.46875 15.4125 8.38125C15.0375 8.29375 14.65 8.25 14.25 8.25C12.7875 8.25 11.547 8.75925 10.5285 9.77775C9.51 10.7962 9.0005 12.037 9 13.5C9 13.75 9.01875 14 9.05625 14.25C9.09375 14.5 9.15 14.75 9.225 15H3Z"
          fill="#215C73"
        />
      </g>
      <defs>
        <clipPath id="clip0_1_2652">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default UnsubscribeIcon;
