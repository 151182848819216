import React, { useState } from 'react';
import '../../ViewAsCoachPage.scss';
import Card from '@mui/material/Card';
import { Avatar, Dialog } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import CardContent from '@mui/material/CardContent';
import { StyledSection, StyledIntroDiv } from './StyledElements';

const maxLength = 400;

function Intro({ theme, firstName, bio, bioBannerUrl, profileImage, brandingColors, accountId, bioHeader }) {
  const color = theme === 'dark' ? 'white' : 'black';
  const cardBackgroundColor = theme === 'dark' ? '#2A2C2E' : 'white';
  const [isReadMoreOpen, setIsReadMoreOpen] = useState(false);
  const primaryColor = brandingColors?.PrimaryColorCode || '#D08ACD';
  const bioText = bio?.length > maxLength ? `${bio.substring(0, maxLength)}...` : bio;

  const hexToRgb = hex => {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? `rgba(${parseInt(result[1], 16)},${parseInt(result[2], 16)},${parseInt(result[3], 16)}, 0.2 )`
      : null;
  };

  const generateHTMLText = text => {
    if (text === '') {
      text = `</br>`;
    }
    return text;
  };

  return (
    <>
      <StyledSection
        className={`bio-section ${bioBannerUrl ? '' : 'no-image'}`}
        style={{ position: 'relative', backgroundColor: bioBannerUrl ? '' : hexToRgb(primaryColor) }}
        id="largeScreen"
        color={color}
      >
        <picture>
          <img className="bio-banner-image" width="100%" src={bioBannerUrl} alt="" />
        </picture>
        <Card
          className={`intro-card ${bioBannerUrl ? '' : 'no-image'}`}
          sx={{
            maxWidth: 375,
            backgroundColor: cardBackgroundColor,
            color,
            maxHeight: '300ch',
            borderRadius: ' 20px',
            top: '70px',
            marginLeft: '10%',
            marginRight: '10%',
          }}
        >
          <diV style={{ position: 'relative' }}>
            <div className="bio-profile-pic">
              <Avatar alt={firstName} src={profileImage} />
            </div>
            <CardContent className="bio-container">
              <h1 style={{ color }}>
                {accountId === '672a829340dd7a1ce7a46923'
                  ? 'Welcome to the Radical Collective'
                  : accountId === '618b588117ae9134cfbbf562'
                  ? `${firstName}`
                  : accountId === '64e100bccee5cf54d0be477b'
                  ? `Hi, We're ${firstName}`
                  : accountId === '658350465de2ac77d79ac293'
                  ? `Hi, We're ${firstName}`
                  : bioHeader != null
                  ? bioHeader
                  : `Hi, I'm ${firstName}`}
              </h1>
              <p className="profile-bio-paragraph">{bioText}</p>
              {bio?.length > maxLength ? (
                <a
                  onClick={() => setIsReadMoreOpen(!isReadMoreOpen)}
                  style={{ cursor: 'pointer', textDecoration: 'underline' }}
                >
                  Read More
                </a>
              ) : null}
            </CardContent>
          </diV>
        </Card>
      </StyledSection>
      <Dialog
        open={isReadMoreOpen}
        onClose={() => setIsReadMoreOpen(!isReadMoreOpen)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="domain-name-dialog dialog-custom-styling"
        classes={{
          paper: 'dialog-container bio-dialog-box',
        }}
      >
        <Card style={{ background: theme === 'dark' ? 'rgb(42, 44, 46)' : 'white' }}>
          <div style={{ marginTop: '15px' }}>
            <div className="bio-profile-pic">
              <Avatar
                alt={firstName}
                src={profileImage}
                style={{
                  width: '98px',
                  height: '98px',
                  position: 'absolute',
                  left: 'calc(50% - 49px)',
                  top: '-49px',
                }}
              />
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-end', cursor: 'pointer' }}>
              <CloseIcon style={{ color: color, marginRight: 20 }} onClick={() => setIsReadMoreOpen(!isReadMoreOpen)} />
            </div>
            <CardContent className="bio-container">
              <h1 style={{ color: color, textAlign: 'center' }}>
                {accountId === '618b588117ae9134cfbbf562'
                  ? `${firstName}`
                  : accountId === '64e100bccee5cf54d0be477b'
                  ? `Hi, We're ${firstName}`
                  : accountId === '658350465de2ac77d79ac293'
                  ? `Hi, We're ${firstName}`
                  : bioHeader != null
                  ? bioHeader
                  : `Hi, I'm ${firstName}`}
              </h1>
              <p style={{ color: color, textAlign: 'justify', padding: '20px', maxHeight: 300, overflow: 'auto' }}>
                {!!bio.length &&
                  bio.split('\n').map(str => <div dangerouslySetInnerHTML={{ __html: generateHTMLText(str) }} />)}
              </p>
            </CardContent>
          </div>
        </Card>
      </Dialog>
    </>
  );
}

export default Intro;
