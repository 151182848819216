import { makeStyles } from '@material-ui/core';

const styles = () => ({
  createButton: {
    marginTop: 12,
    marginBottom: 12,
  },
  podsList: {
    marginBottom: 32,
  },
});

export const useStyles = makeStyles(styles);
