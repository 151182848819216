import React from 'react'
import Icon from '@mdi/react'
import { mdiStarOutline } from '@mdi/js'
import { colors } from '../../utils/styles'

const IconStar = () => {
  return <Icon path={mdiStarOutline} title="Star" size={0.9} horizontal vertical rotate={36} color={colors.white} />
}

export default IconStar
