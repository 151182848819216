import { Avatar, Button } from '@material-ui/core';
import React, { useState } from 'react';
import ChatIcon from '@material-ui/icons/Chat';
import PropTypes, { string } from 'prop-types';
import NotesModal from 'components/Notes/NotesModal';
import { updateClientNotes } from 'services/clients.service';
import useClientNote from 'hooks/useClientNote';
import useAccount from 'hooks/useAccount';
import NoteIcon from './NoteIcon';
import classNames from 'classnames';
import styled from 'styled-components';
import { colors } from 'utils/styles';
import Modal from 'components/UI/Modal';
import moment from 'moment';
import UnsubscribeIcon from 'components/Icons/UnsubscribeIcon';
import { isEmpty } from 'lodash';

const StyledRow = styled.div`
  display: flex;
  gap: 5px;
`;
const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const StyledModalRow = styled.div`
  display: flex;
  gap: 25px;
`;
const StyledStatus = styled.div`
  text-decoration: underline;
  color: ${colors.darkOceanBlue2};
  display: flex;
  gap: 5px;
  cursor: pointer;
  ${props =>
    props.isClickDisabled &&
    `
    pointer-events: none;
  `}
`;

const ClientHeader = ({ client, openChat, response, isLeadPage }) => {
  const { user } = useAccount();
  const [clientId, setClientId] = useState(null);
  const [isStatusModalOpen, setIsStatusModalOpen] = useState(false);
  const {
    data: editorDataUpdated,
    setData: setEditorDataUpdated,
    showNotes,
    setShowNotes,
    loading: loadingNotes,
    setLoading: setLoadingNotes,
    isNoteExist,
  } = useClientNote(clientId);

  return (
    <div className="client-detail-header">
      <Avatar
        alt="logo"
        src={client?.image}
        style={{ margin: 'auto 10px auto 0', width: '60px', height: '60px', fontSize: '16px' }}
      />
      <div className="client-info">
        <div>{client?.name}</div>
        <div>{client?.email}</div>
        <StyledRow>
          <div>Status:</div>
          <StyledStatus
            onClick={() => {
              if (!client?.unsubscriptionDetails) {
                return;
              }
              setIsStatusModalOpen(true);
            }}
            isClickDisabled={!client?.unsubscriptionDetails}
          >
            {!isEmpty(client?.unsubscriptionDetails) && (
              <span>
                <UnsubscribeIcon />
              </span>
            )}
            {!client?.unsubscriptionDetails ? 'Subscribed' : 'Unsubscribed'}
          </StyledStatus>
        </StyledRow>
      </div>
      {response != null && (
        <div className="buttons-container">
          <Button
            variant="outlined"
            startIcon={<NoteIcon fillColor="#215C73" />}
            onClick={() => setClientId(client.id)}
          >
            {isLeadPage ? 'Notes' : 'Client Notes'}
          </Button>
          <Button variant="outlined" startIcon={<ChatIcon />} onClick={() => openChat(client?.id)}>
            {isLeadPage ? 'Chats' : 'Client Chats'}
          </Button>
        </div>
      )}

      <NotesModal
        editorData={editorDataUpdated}
        showNotes={showNotes}
        loading={loadingNotes}
        setLoading={setLoadingNotes}
        setShowNotes={setShowNotes}
        setEditorDataUpdated={setEditorDataUpdated}
        setNotesId={setClientId}
        onSave={() => {
          setLoadingNotes(true);
          updateClientNotes({ clientId, userId: user.id, note: editorDataUpdated || '' }).finally(() => {
            setLoadingNotes(false);
            setClientId(null);
            setShowNotes(false);
          });
        }}
        isNoteExist={isNoteExist}
      />
      <Modal
        isOpen={isStatusModalOpen}
        title="Contact Status"
        disableFooter
        onCancel={() => {
          setIsStatusModalOpen(false);
        }}
        widthRequired
      >
        <StyledColumn>
          <StyledModalRow>
            <div>Status:</div>
            <div>Unsubscribed</div>
          </StyledModalRow>
          <StyledModalRow>
            <div>Unsubscribe Date:</div>
            <div>{moment(client?.unsubscriptionDetails?.unsubscriptionDate).format('MMMM Do, YYYY')}</div>
          </StyledModalRow>
          <StyledModalRow>
            <div>Unsubscribe Reason:</div>
            <div>{client?.unsubscriptionDetails?.reason}</div>
          </StyledModalRow>
        </StyledColumn>
      </Modal>
    </div>
  );
};

ClientHeader.propTypes = {
  client: PropTypes.shape({ id: string, image: string, name: string, email: string }).isRequired,
  openChat: PropTypes.func.isRequired,
};

export default ClientHeader;
