import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import Loader from 'components/UI/Loader';

import { BasicInformationPage, ContributionDetailsPage, ReviewAndSubmitPage, SessionsPage, PaymentPage } from 'pages';
import { useHeader } from 'hooks';
import * as contributionActions from 'actions/contributions';
import * as oneToOneDataActions from 'actions/oneToOneData';
import ThankYouPreviewPage from './ThankYouPreviewPage';
import { startIncrementDurationsOneToOne } from 'pages/CreateContribution/components/OneToOneForm';

function EditContributionContainer({
  match: { path, params },
  getCohealerContribution,
  contribution,
  updateOneToOneData,
  updating,
  getEditContributionTimes,
  resetContribution,
}) {
  useHeader('Edit contribution');
  const { id } = params;

  useEffect(() => {
    getCohealerContribution(id);
  }, [id, getCohealerContribution, getEditContributionTimes]);

  // useEffect(() => {
  //   if (contribution?.oneToOneSessionDataUi) {
  //     const { oneToOneSessionDataUi } = contribution;
  //     getEditContributionTimes(id, contribution?.oneToOneSessionDataUi, true);
  //   }
  // }, [getEditContributionTimes, contribution?.oneToOneSessionDataUi, id]);

  useEffect(() => {
    if (contribution?.oneToOneSessionDataUi) {
      const preparedData = {
        ...contribution?.oneToOneSessionDataUi,
        ...{
          startTimeIncrementDuration: contribution?.sessionIncrements?.[0] || 0,
        },
      };
      const updatedWeeks = preparedData.selectedWeeks.map(week => {
        return {
          ...week,
          startTime: moment.utc(week.startTime),
          endTime: moment.utc(week.endTime),
        };
      });
      preparedData.selectedWeeks = updatedWeeks;
      updateOneToOneData(preparedData);
    }
  }, [contribution?.oneToOneSessionDataUi, updateOneToOneData]);

  useEffect(() => {
    return () => {
      resetContribution();
    };
  }, []);

  return (
    <>
      {updating && <Loader />}
      <Switch>
        <Route path={`${path}/basic`} exact component={BasicInformationPage} />
        <Route path={`${path}/details`} exact component={ContributionDetailsPage} />
        <Route path={`${path}/sessions/:sessionId?/:contentId?`} exact component={SessionsPage} />
        <Route path={`${path}/payment`} exact component={PaymentPage} />
        <Route path={`${path}/review`} exact component={ReviewAndSubmitPage} />
        <Route path={`${path}/thankyoupreviewpage`} exact component={ThankYouPreviewPage} />
        <Redirect to={`${path}/basic`} />
      </Switch>
    </>
  );
}

EditContributionContainer.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string,
    url: PropTypes.string,
    isExact: PropTypes.bool,
    params: PropTypes.object,
  }).isRequired,
  getCohealerContribution: PropTypes.func.isRequired,
  getEditContributionTimes: PropTypes.func.isRequired,
  updating: PropTypes.bool,
};

EditContributionContainer.defaultProps = {
  updating: false,
};

const mapStateToProps = ({ contributions: { activeContribution, updating, loading, editContributionTimes } }) => ({
  contribution: activeContribution,
  updating,
  loading,
});

const actions = {
  getCohealerContribution: contributionActions.fetchCohealerContribution,
  updateOneToOneData: oneToOneDataActions.updateOneToOneData,
  resetContribution: contributionActions.resetContribution,
  // getEditContributionTimes: contributionActions.fetchEditContributionTimes
};

export default connect(mapStateToProps, actions)(EditContributionContainer);
