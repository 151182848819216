import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import useAccount from 'hooks/useAccount';
import useRouter from 'hooks/useRouter';
import { useTheme, useMediaQuery, Select, MenuItem } from '@material-ui/core';
import { isApplicationFormSubmitted } from 'services/applicationForm.services';
import { redirectTo } from 'services/links';
import { determineColorToUse, getClientContribution } from 'services/contributions.service';
import Loader from 'components/UI/Loader';
import { LinearProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import LoginForm from './LoginForm';
import ApplicationForm from './ApplicationForm';

const useStyles = makeStyles({
  progressBar: {
    '& > div': {
      backgroundColor: props => props.color, // Use the color prop to set the background color
    },
  },
});

const ApplicationFormBody = ({ isDarkThemeEnabled, contribution }) => {
  const { user } = useAccount();
  const [questionNumber, setQuestionNumber] = useState(1);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [applicationFormViewModel, setApplicationFormViewModel] = useState(contribution?.applicationFormViewModel);
  const [loadingLastResponse, setLoadingLastResponse] = useState(false);
  const activeContribution = useSelector(state => state.contributions?.activeContribution);
  const colorToUse = determineColorToUse(activeContribution);
  const classes = useStyles({ color: colorToUse?.PrimaryColorCode });
  const { query } = useRouter();

  useEffect(() => {
    setIsLoggedIn(Boolean(user.id));
  }, [user.id]);

  useEffect(() => {
    if (isLoggedIn && contribution?.id) {
      setLoadingLastResponse(true);
      isApplicationFormSubmitted(contribution?.id || '').then(applicationData => {
        getClientContribution(contribution?.id).then(contriutionData => {
          setLoadingLastResponse(false);
          if (contriutionData?.isPurchased) {
            redirectTo(`/contribution-view/${contribution?.id}/about`);
            return;
          }
          if (applicationData?.status === 'Pending' || applicationData?.status === 'Approved') {
            redirectTo(`/apply-contribution-view/${contribution?.id}${query?.code ? `/${query.code}` : ''}/about`);
            return;
          }
          setApplicationFormViewModel({
            ...applicationData,
            id: undefined,
            updateTime: undefined,
            createTime: undefined,
            formLinkId: contribution?.applicationFormViewModel?.formLinkId,
            contributionId: contribution?.id,
            questions: applicationFormViewModel?.questions?.map(formQuestion => {
              return (
                applicationData?.questions?.find(responseQuestion => {
                  return formQuestion.id === responseQuestion.id;
                }) || formQuestion
              );
            }),
          });
        });
      });
    }
  }, [isLoggedIn, contribution?.id]);

  if (loadingLastResponse) {
    return <Loader />;
  }

  return (
    <>
      {isLoggedIn && (
        <LinearProgress
          className={`${classes.progressBar} application-progress-bar`}
          variant="determinate"
          value={(questionNumber / applicationFormViewModel?.questions?.length) * 100}
        />
      )}
      <div className={isMobile ? 'application-form-body-mobile' : 'application-form-body'}>
        {!isLoggedIn &&
          (isMobile ? (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div className="application-form-image-mobile">
                <img alt="" src={contribution?.previewContentUrls[0]} />
              </div>
              <div className="application-login-form-container">
                <p className="application-form-note">Please fill out this application form.</p>
                <LoginForm isDarkThemeEnabled={isDarkThemeEnabled} />
              </div>
            </div>
          ) : (
            <div className="application-login-form-container">
              <div className="application-form-image">
                <img alt="" src={contribution?.previewContentUrls[0]} />
              </div>
              <p className="application-form-note">Please fill out this application form.</p>
              <LoginForm isDarkThemeEnabled={isDarkThemeEnabled} />
            </div>
          ))}
        {isLoggedIn && applicationFormViewModel && (
          <>
            <div className={`application-form-body-container${isLoggedIn ? '-form' : ''}`}>
              <ApplicationForm
                isDarkThemeEnabled={isDarkThemeEnabled}
                applicationFormViewModel={applicationFormViewModel}
                contributionId={contribution?.id || ''}
                questionNumber={questionNumber}
                setQuestionNumber={setQuestionNumber}
                coachName={contribution?.serviceProviderName || ''}
                contributionTitle={contribution?.title || ''}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};

ApplicationFormBody.propTypes = {
  contribution: PropTypes.shape({
    id: PropTypes.string,
    previewContentUrls: PropTypes.arrayOf(PropTypes.string),
    applicationFormViewModel: PropTypes.shape({
      formLinkId: PropTypes.string,
      questions: PropTypes.arrayOf(PropTypes.string),
    }),
    serviceProviderName: PropTypes.string,
    title: PropTypes.string,
  }).isRequired,
  isDarkThemeEnabled: PropTypes.bool.isRequired,
};

export default ApplicationFormBody;
