import { handleActions } from 'redux-actions';

import * as actions from 'actions/bankAccounts';
import { LOG_OUT } from 'actions/user';

const initialState = {
  loading: false,
  error: null,
  balance: [],
};

const bankAccounts = handleActions(
  new Map([
    [
      actions.fetchBankAccountsActions.request,
      state => ({
        ...state,
        error: null,
        loading: true,
        bankAccounts: null,
      }),
    ],
    [
      actions.fetchBankAccountsActions.success,
      (state, action) => ({
        ...state,
        loading: false,
        error: null,
        bankAccounts: action.payload,
      }),
    ],
    [
      actions.fetchBankAccountsActions.error,
      (state, action) => ({
        ...state,
        loading: false,
        error: action.payload,
        bankAccounts: [],
      }),
    ],
    [[LOG_OUT], () => ({ ...initialState })],
  ]),
  initialState,
);

export default bankAccounts;
