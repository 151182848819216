import React, { useEffect, useState } from 'react';

import { useAccount, useHttp, useRouter } from 'hooks';
import { useTheme, useMediaQuery } from '@material-ui/core';

import Button from 'components/FormUI/Button';

import styled from 'styled-components';

import '../Banner.scss';
import { useDispatch } from 'react-redux';
import { hideTestButton } from 'actions/user';
import { UserRoles } from 'helpers/constants';

const StyledButton = styled(Button)`
  background-color: #0e536b;
  padding: 6px 14px;
  min-width: 95px;
  width: 95px;
  font-size: 12px;
  font-weight: 700;
`;

const TestVideo = () => {
  const { user, currentRole } = useAccount();
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const BannerButton = mobileView ? StyledButton : Button;
  const { history } = useRouter();
  const { request, loading } = useHttp();
  const dispatch = useDispatch();

  if (!user.isVideoTestFirstTime && !user.transfersEnable) {
    return null;
  }

  const onClick = async () => {
    if (!loading) {
      await request('/Account/CheckVideoTest', 'GET');
      dispatch(hideTestButton());
      history.push('/account/video');
    }
  };

  return (
    <div className={`banner-container ${mobileView && 'banner-container__mobile'}`}>
      <p className="banner-container__text">Test your video setup before your first live session</p>
      <BannerButton
        disabled={!user.transfersEnabled && currentRole === UserRoles.cohealer}
        variant="primary"
        className="banner-container__button"
        autoWidth
        onClick={onClick}
      >
        Test
      </BannerButton>
    </div>
  );
};

export default TestVideo;
