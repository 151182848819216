import React, { useCallback, useEffect, useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@material-ui/core';
import Button from 'components/FormUI/Button';
import PropTypes from 'prop-types';
import axiosInstance from 'utils/axiosInstance';
import { NOT_ALLOWED_DOMAINS } from 'utils/domainAndSubdomain';
import _ from 'lodash';
import useRouter from 'hooks/useRouter';

const SubdomainSelection = ({ isOpen, setIsOpen, currentDomain, setNewDomain }) => {
  const [domain, setDomain] = useState(currentDomain);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const { parentDomain: envDomain } = useRouter();

  useEffect(() => {
    setDomain(currentDomain);
  }, [isOpen]);

  const handleDebounceFunc = domainName => {
    setError(false);
    if (!domainName || NOT_ALLOWED_DOMAINS.includes(domainName)) {
      setError(true);
      return;
    }
    axiosInstance
      .post(`/User/checkProfileLinkName?profileName=${domainName}`)
      .then(res => setError(domainName !== currentDomain && !res.data))
      .catch(e => setError(true))
      .finally(() => setLoading(false));
  };
  const debounceFunc = useCallback(_.debounce(handleDebounceFunc, 2000), []);
  function handleChange(event) {
    const newVal = event.target.value;
    const pattern = /^[A-Za-z0-9\-]+([\-\.]{1}[a-z0-9]+)*\.[A-Za-z]{2,6}$/;
    if (newVal && (newVal.includes('.') || !pattern.test(newVal.concat('.', 'cohere.live')))) {
      return;
    }
    setLoading(true);
    setDomain(newVal);
    debounceFunc(newVal);
  }

  const onConfirmation = () => {
    setNewDomain(domain);
    setIsOpen(!isOpen);
    setShowConfirmation(!showConfirmation);
  };

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="domain-name-dialog"
        classes={{
          paper: 'dialog-container',
        }}
      >
        <DialogTitle id="alert-dialog-title">Domain Name</DialogTitle>
        <DialogContent>
          <TextField
            className="input-field"
            id="outlined-basic"
            margin="normal"
            variant="outlined"
            fullWidth
            value={domain}
            error={error}
            onChange={event => handleChange(event)}
            InputProps={{
              endAdornment: '.'.concat(envDomain),
            }}
          />
          <p style={{ color: '#47627B' }}>Example: mydomain.{envDomain}</p>
          {error && <span style={{ color: 'red' }}>domain already exists</span>}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsOpen(false)}>Close</Button>
          <Button
            onClick={() => {
              if (domain.length) {
                setShowConfirmation(!showConfirmation);
              }
              // setNewDomain(domain);
            }}
            disabled={loading || error || !domain.length}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={showConfirmation}
        onClose={() => setShowConfirmation(!showConfirmation)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="domain-name-dialog"
        classes={{
          paper: 'dialog-container',
        }}
      >
        <DialogContent>
          <p style={{ color: '#47627B' }}>Are you sure you want to change the domain? </p>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowConfirmation(!showConfirmation)}>No</Button>
          <Button onClick={onConfirmation} disabled={loading || error}>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

SubdomainSelection.propTypes = {
  currentDomain: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  setNewDomain: PropTypes.func.isRequired,
};

export default SubdomainSelection;
