import React from 'react';

const FacebookSvg = ({ color, style = {} }) => {
  return (
    <svg style={style} width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.4219 0H2.57812C1.15655 0 0 1.15655 0 2.57812V19.4219C0 20.8435 1.15655 22 2.57812 22H19.4219C20.8435 22 22 20.8435 22 19.4219V2.57812C22 1.15655 20.8435 0 19.4219 0ZM20.2812 19.4219C20.2812 19.8957 19.8957 20.2812 19.4219 20.2812H14.5234V13.2773H17.1785L17.6172 10.6133H14.5234V8.76562C14.5234 8.03623 15.0831 7.47656 15.8125 7.47656H17.5742V4.8125H15.8125C13.6244 4.8125 11.8603 6.58496 11.8603 8.7731V10.6133H9.28125V13.2773H11.8603V20.2812H2.57812C2.10427 20.2812 1.71875 19.8957 1.71875 19.4219V2.57812C1.71875 2.10427 2.10427 1.71875 2.57812 1.71875H19.4219C19.8957 1.71875 20.2812 2.10427 20.2812 2.57812V19.4219Z"
        fill={color}
      />
    </svg>
  );
};

export default FacebookSvg;
