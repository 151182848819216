import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { fetchTimeZones } from 'actions/timeZone';
import { orderBy } from 'lodash';
import * as countryActions from 'actions/country';
import { colors } from 'utils/styles';
import { useMediaQuery, useTheme, Tooltip, Select } from '@material-ui/core';
import Loader from 'components/UI/Loader';
import { DATE_FORMATS } from 'utils/datesAndMoney';
import { getThemedColors } from 'services/contributions.service';
import { lightOrDark } from 'utils/utils';
import useContribution from 'pages/ContributionView/hooks/useContribution';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import CommonErrorMessage from 'components/FormUI/CommonErrorMessage';
import InfoIcon from '@material-ui/icons/Info';

export const StyledSlotContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding-top: 10px;
  margin: 10px 0px;
`;

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FooterContainer = styled.div`
  margin-top: auto;
`;

const StyledTitle = styled.div`
  color: ${({ color }) => color || 'var(--Cohere-Primary-Blue, #215C73)'};
  font-style: normal;
  font-family: Brandon Text;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  text-align: left;
`;

export const StyledTimeSlots = styled.div`
  display: inline-block;
  width: 100px;
  height: 35px;
  border: 1px solid #fafafa;
  border-radius: 8px;
  margin: ${({ margin }) => (margin ? margin : '5px')};
  color: darkgrey;
  background-color: #fffff;

  &:hover {
    cursor: pointer;
  }

  ${({ mobileView }) =>
    mobileView &&
    `
      width: 80px;
  `}

  ${({ isSelected, colorForSelected }) =>
    isSelected &&
    `
    background-color: ${colorForSelected};
    border-color: ${colorForSelected};
    color: white;
  `}
  ${({ isSelected }) =>
    !isSelected &&
    `
    background-color: white;
  `}

  ${({ isBooked }) =>
    isBooked &&
    `
    color: ${colors.stroke};
    
    &:hover {
      cursor: default;
    }
  `}
`;
export const StyledTimeSlotValue = styled.div`
  font-weight: 500;
  font-size: 13px;
  margin: 7px 0;
  text-align: center;
`;

const StyledButton = styled.button`
  color: ${({ color }) => color};
  border: ${({ borderColor }) => `1px solid ${borderColor}`};
  background-color: ${({ backgroundColor }) => backgroundColor};

  ${({ mobileView }) =>
    mobileView &&
    `
    font-size: 12px;
    padding: 8px 16px;
    min-width: 8rem;
  `}
`;

const StyledTimezoneDropdownContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 0.625rem;
  gap: 10px;
`;

const StyledToggleButtonGroupContainer = styled.div`
  display: block;
  text-align: center;
`;

const StyledToggleButtonGroup = styled(ToggleButtonGroup)`
  &.MuiToggleButtonGroup-root {
    border-radius: 6px;
  }
`;

const StyledToggleButton = styled(ToggleButton)`
  width: 162px;
  align-items: center;

  &.MuiToggleButton-root {
    font-size: 14px;
    font-family: Roboto;
    font-weight: 500;
    text-transform: capitalize;
    line-height: 24.5px;
    letter-spacing: 0.4px;
    word-wrap: break-word;
    padding-top: 6.1px;
    padding-bottom: 7.4px;
    padding-left: 13.92px;
    padding-right: 13.93px;
    color: #0000008a !important;
    background-color: #dfe3e4 !important;
  }
  &.Mui-selected {
    color: white !important;
    ${({ background }) => background && `background-color: ${background} !important;`}
  }

  svg {
    height: 20px;
  }
`;

const StyledSelectTz = styled(Select)`
  position: relative;
  border-radius: 0.375rem;
  border: 1px solid var(--Cohere-Greys-Grey-Tint, #dfe3e4);
  .MuiSelect-select {
    padding: 0.63rem 0.95rem;
  }

  .MuiSelect-icon {
    color: ${({ iconColor }) => iconColor};
  }
`;

const SlotsView = React.memo(
  ({
    onClose,
    onSubmit,
    serviceProviderName,
    title,
    colorToUse,
    color,
    contribution,
    shouldHidePriceSection,
    userSelectedTimeZone,
    scheduleData,
    setScheduleData,
    tempTimeSlots,
    toggleAvailabilitySlots,
    setToggleAvailabilitySlots,
    isLoading,
    fromMasterCalendar,
    isDarkModeEnabled,
    selectedTimeZone,
  }) => {
    const theme = useTheme();
    const mobileView = useMediaQuery(theme.breakpoints.down('xs'));
    const currentContribution = useContribution();
    const dispatch = useDispatch();
    const { newThemedTextColor, newThemedBackgroundColor } = getThemedColors(currentContribution);
    const { timeZones, loading: timeZoneLoading } = useSelector(state => state.timeZone);
    const [selectedTimezone, setSelectedTimezone] = useState(userSelectedTimeZone || contribution?.timeZoneId);
    const { states } = useSelector(state => state?.states);
    const { countries } = useSelector(state => state?.country);
    const [slots, setSlots] = useState([]);
    const [colorForSelect, setColorForSelect] = useState('darkgrey');
    const [loading, setLoading] = useState(isLoading);
    const [selectedSlot, setSelectedSlot] = useState(scheduleData?.selectedSlot);
    const [btnDisabled, setbtnDisabled] = useState(false);
    const [showError, setShowError] = useState(false);

    useEffect(() => {
      setLoading(isLoading);
    }, [isLoading]);

    useEffect(() => {
      if ((!timeZones || timeZones.length === 0) && !timeZoneLoading) {
        fetchTimeZones();
      }
      if (!countries || !countries.length) {
        dispatch(countryActions.fetchCountries());
      }
      if (!states || !states.length) {
        dispatch(countryActions.fetchStates());
      }
    }, [timeZones]);

    const textColor =
      colorToUse?.TextColorCode === 'Auto'
        ? lightOrDark(colorToUse?.PrimaryColorCode)
        : colorToUse?.TextColorCode === '#000000'
        ? '#000000'
        : '#FFFFFF';

    function isCurrentDateTimeBeforeSelected(selectedDateTime) {
      const currentDateTime = moment();
      const selectedDate = moment(selectedDateTime);
      const currentDate = currentDateTime.isSame(selectedDate, 'day');
      if (currentDate) {
        return !currentDateTime.isBefore(selectedDate);
      }
      return false;
    }

    useEffect(() => {
      setSlots(tempTimeSlots);
      const slotExists = tempTimeSlots?.some(s => s.id === selectedSlot?.id);
      if (slotExists) return;
      const firstFreeSlot = tempTimeSlots?.find(s => !s.isBooked && isCurrentDateTimeBeforeSelected(s.start) === false);
      setSelectedSlot(firstFreeSlot);
    }, [tempTimeSlots, scheduleData?.selectedDate]);

    useEffect(() => {
      setScheduleData({ ...scheduleData, selectedSlot });
    }, [selectedSlot]);

    useEffect(() => {
      if (timeZones?.length > 0) {
        return;
      }
      if ((!timeZones || !timeZones.length) && !timeZoneLoading) {
        dispatch(fetchTimeZones());
      }
    }, [timeZones, timeZoneLoading]);

    useEffect(() => {
      if (slots.length === 0) {
        setbtnDisabled(true);
      } else {
        setbtnDisabled(false);
      }
    }, [slots]);

    const onConfirm = async () => {
      if (selectedSlot && !btnDisabled) {
        onSubmit(selectedSlot);
      } else {
        setShowError(true);
      }
    };

    return (
      <>
        <div
          className="one-one-session-slots-card"
          style={{
            backgroundColor: '#2d2f31',
            marginTop: '0px',
            padding: '0px 0px 0px 10px',
            justifyContent: 'space-between',
            minHeight: '65vh',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <MainContainer>
            <div>
              <div style={{ display: 'flex' }}>
                <p className="booking-title" style={{ color: colorToUse?.AccentColorCode, marginBottom: '0px' }}>
                  Select Your Session Time
                </p>
              </div>
              <div className="slot-selection-description">
                <p
                  style={{
                    fontSize: '14px',
                    marginTop: '15px',
                    marginBottom: '10px',
                    marginLeft: '10px',
                    textAlign: 'center',
                  }}
                >
                  Please select your session time for {title} with <b>{serviceProviderName}</b>
                </p>
                <StyledToggleButtonGroupContainer>
                  <StyledToggleButtonGroup
                    color="primary"
                    value={toggleAvailabilitySlots}
                    exclusive
                    onChange={(e, newVal) => {
                      if (newVal !== null) setToggleAvailabilitySlots(newVal);
                    }}
                  >
                    <StyledToggleButton value background={colorToUse?.AccentColorCode} textColor={newThemedTextColor}>
                      My Availability
                      <Tooltip
                        title="Only the specific time slots you’ve chosen for your one-on-one availability will be displayed here. If you have the 'Prevent Double Booking' setting enabled, these slots will automatically account for any conflicts with other appointments."
                        arrow
                      >
                        <InfoIcon style={{ marginLeft: '10px' }} htmlColor="" />
                      </Tooltip>
                    </StyledToggleButton>
                    <StyledToggleButton
                      value={false}
                      background={colorToUse?.AccentColorCode}
                      textColor={newThemedTextColor}
                    >
                      All Times
                      <Tooltip
                        title="If you need to make an exception to the availability you’ve set for this service and want to override those settings, use this feature to pick any time on your calendar."
                        arrow
                      >
                        <InfoIcon style={{ marginLeft: '10px' }} htmlColor="" />
                      </Tooltip>
                    </StyledToggleButton>
                  </StyledToggleButtonGroup>
                </StyledToggleButtonGroupContainer>
              </div>
            </div>
            {loading && <Loader />}
            <StyledTimezoneDropdownContainer>
              <StyledTitle {...{ color: colorToUse?.AccentColorCode }}>Time Zone</StyledTitle>
              <StyledSelectTz
                required
                native
                labelId="timezones"
                iconColor={isDarkModeEnabled ? 'white' : 'black'}
                name="timezones"
                onChange={e => {
                  setSelectedTimezone(e.target.value);
                  selectedTimeZone(e.target.value);
                  setScheduleData({ ...scheduleData, timeZone: e.target.value });
                  const slotColor = e.target.value === 'Select Time Zone' ? 'darkgrey' : 'black';
                  setColorForSelect(slotColor);
                }}
                disableUnderline
                style={{
                  backgroundColor: !fromMasterCalendar && newThemedBackgroundColor,
                  color: !fromMasterCalendar && newThemedTextColor,
                }}
                value={selectedTimezone}
              >
                <option
                  aria-label="Time Zone"
                  value={null}
                  disabled
                  style={{
                    backgroundColor: isDarkModeEnabled ? '#696969' : 'white',
                    color: isDarkModeEnabled ? 'white' : 'black',
                  }}
                >
                  Select Timezone
                </option>
                {timeZones?.length > 0 &&
                  timeZones.map(timeZone => (
                    <option
                      style={{
                        backgroundColor: isDarkModeEnabled ? '#696969' : 'white',
                        color: isDarkModeEnabled ? 'white' : 'black',
                      }}
                      value={timeZone?.countryName}
                    >
                      {timeZone?.name}
                    </option>
                  ))}
              </StyledSelectTz>
            </StyledTimezoneDropdownContainer>
            <div className="easy-booking-timezone" style={{ display: 'flex', justifyContent: 'center' }}>
              <div className={isDarkModeEnabled ? 'darkThemeEnabled' : 'whiteThemeEnabled'} style={{ width: '70%' }}>
                <StyledSlotContainer>
                  {orderBy(slots, k => moment(k.start), 'asc').map((slot, index) => {
                    const isSelectedSlot = selectedSlot && selectedSlot.id === slot.id;
                    const isBefore = isCurrentDateTimeBeforeSelected(slot.start);
                    return (
                      !isBefore && (
                        <StyledTimeSlots
                          className="easy-booking-slots"
                          mobileView={mobileView}
                          key={slot.id}
                          isBooked={slot.isBooked}
                          onClick={() => {
                            if (!slot.isBooked) {
                              setSelectedSlot(slot);
                              if (slot) setShowError(false);
                            }
                          }}
                          style={
                            isSelectedSlot ? {} : { backgroundColor: !fromMasterCalendar && newThemedBackgroundColor }
                          }
                          isSelected={isSelectedSlot}
                          colorForSelected={colorToUse?.PrimaryColorCode}
                        >
                          <StyledTimeSlotValue
                            style={
                              isSelectedSlot
                                ? { color: textColor }
                                : { color: fromMasterCalendar ? 'rgb(40, 43, 43)' : newThemedTextColor }
                            }
                          >
                            {moment(slot.start).format(DATE_FORMATS.TIME_12)}
                          </StyledTimeSlotValue>
                        </StyledTimeSlots>
                      )
                    );
                  })}
                </StyledSlotContainer>
              </div>
            </div>
            {!loading && (btnDisabled || selectedSlot === null) && (
              <p className="no-slot-selected" style={{ fontSize: '14px', marginTop: '15px', marginBottom: '25px' }}>
                {toggleAvailabilitySlots ? 'For more options, select All Times' : 'No available time slots.'}
              </p>
            )}
            {!loading && (btnDisabled || selectedSlot === null) && showError && (
              <div>
                <CommonErrorMessage message="Select a time to continue" align="left" color="red" />
              </div>
            )}
          </MainContainer>
          <FooterContainer>
            <div className="easy-booking-slot-buttons" style={{ margin: '20px 0px' }}>
              <StyledButton
                backgroundColor="white"
                borderColor="black"
                color="black"
                isDarkModeEnabled={isDarkModeEnabled ?? false}
                onClick={onClose}
              >
                Back
              </StyledButton>
              <StyledButton
                backgroundColor={colorToUse?.PrimaryColorCode}
                borderColor={colorToUse?.PrimaryColorCode}
                color="white"
                isDarkModeEnabled={isDarkModeEnabled ?? false}
                onClick={onConfirm}
              >
                Next
              </StyledButton>
            </div>
          </FooterContainer>
        </div>
      </>
    );
  },
);

SlotsView.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  selectedDate: PropTypes.object,
  serviceProviderName: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  scheduleData: PropTypes.shape({
    selectedSlot: PropTypes.shape({}).isRequired,
  }).isRequired,
  colorToUse: PropTypes.shape({
    PrimaryColorCode: PropTypes.string.isRequired,
    AccentColorCode: PropTypes.string.isRequired,
  }).isRequired,
  fromMasterCalendar: PropTypes.bool.isRequired,
  isDarkModeEnabled: PropTypes.bool.isRequired,
  toggleAvailabilitySlots: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  setToggleAvailabilitySlots: PropTypes.func.isRequired,
  selectedTimeZone: PropTypes.func.isRequired,
};

export default SlotsView;
