import React from 'react';
import classes from './cohereaffiliatepayouts.module.scss';
import PayoutsTable from './Components/PayoutsTableContainer';
export default function CohereAffiliatePayouts() {
  return (
    <div className={classes.container}>
      <div className={classes.container_norefer}>
        <div className={classes.no_refrealls}>No payouts yet.</div>
      </div>
      <PayoutsTable />
    </div>
  );
}
