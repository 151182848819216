import { useCallback } from 'react';
import { LocalVideoTrack } from 'twilio-video';

import { LOCAL_TRACK_PRIORITIES } from 'constants.js';

import { useAccount } from 'hooks';
import { UserRoles } from 'helpers/constants';

import { tryGetFirefoxVersion } from 'utils/userAgent';
import useScreenTrack from './useScreenTrack';
import VideoChatVideoTrackType from '../../VideoChatVideoTrackType';

const oldFirefoxVersion = 65;

const stopScreenTrack = (localParticipant, screenTrack) => {
  if (screenTrack) {
    screenTrack.stop();
    console.info('COHERE: screen track unpublished handler. Screen track is not null');
    localParticipant.unpublishTrack(screenTrack);
  } else {
    console.info('COHERE: screen track unpublished handler. Screen track is NULL');
  }
};

const useLocalScreenTrack = (localParticipant, localScreenTrack) => {
  const { screenTrack, isEnabled } = useScreenTrack(localScreenTrack);
  const { currentRole } = useAccount();

  const changeCoachCameraTrackPriority = (participant, priority) => {
    if (currentRole == UserRoles.cohealer) {
      var cameraTrack;

      var videoTracks = participant?.videoTracks?.values();
      if (videoTracks) {
        for (var videoTrack of videoTracks) {
          if (videoTrack.trackName === VideoChatVideoTrackType.camera) {
            cameraTrack = videoTrack;
            break;
          }
        }
      }

      if (cameraTrack) {
        cameraTrack.setPriority(priority);
      }
    }
  };

  const setIsLocalScreenTrackEnabled = useCallback(
    enabled => {
      const startLocalScreenTrack = async () => {
        try {
          if (!screenTrack) {
            const firefoxVersion = tryGetFirefoxVersion();
            const screenStream =
              firefoxVersion && firefoxVersion <= oldFirefoxVersion
                ? await navigator.mediaDevices.getUserMedia({
                    mediaSource: 'window',
                  })
                : await navigator.mediaDevices.getDisplayMedia({ video: true });
            const screenStreamTrack = screenStream.getTracks()[0];

            const createdScreenTrack = new LocalVideoTrack(screenStreamTrack, {
              name: VideoChatVideoTrackType.screenShare,
              logLevel: 'debug',
            });
            screenStreamTrack.onended = () => {
              console.info('COHERE: screen track ended handler');
              stopScreenTrack(localParticipant, createdScreenTrack);
              changeCoachCameraTrackPriority(localParticipant, LOCAL_TRACK_PRIORITIES.high);
            };

            await localParticipant.publishTrack(createdScreenTrack, {
              priority: LOCAL_TRACK_PRIORITIES.high,
            });

            changeCoachCameraTrackPriority(localParticipant, LOCAL_TRACK_PRIORITIES.standard);
          }
        } catch (e) {
          console.log('Failed to start screen sharing.', e);
        }
      };
      const stopLocalScreenTrack = () => {
        console.info('COHERE: screen track stopped by user');
        changeCoachCameraTrackPriority(localParticipant, LOCAL_TRACK_PRIORITIES.high);
        stopScreenTrack(localParticipant, screenTrack);
      };

      if (enabled) {
        startLocalScreenTrack();
      } else {
        stopLocalScreenTrack();
      }
    },
    [localParticipant, screenTrack],
  );

  return {
    screenTrack,
    isEnabled,
    setIsEnabled: setIsLocalScreenTrackEnabled,
  };
};

export default useLocalScreenTrack;
