import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 10px;
  cursor: pointer;
`;

const IconWrapper = styled.div`
  padding: 12px;
`;

const ButtonWrapper = styled.button`
  color: white;
  background-color: transparent;
  border: 0;
  margin: 0;
`;

export const CloseVideoButton = ({ onCloseVideo }) => (
  <StyledWrapper onClick={onCloseVideo}>
    <ButtonWrapper>
      <IconWrapper>
        <CloseIcon />
      </IconWrapper>
    </ButtonWrapper>
    Close
  </StyledWrapper>
);

CloseVideoButton.propTypes = {
  onCloseVideo: PropTypes.bool.isRequired,
};
