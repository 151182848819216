import React from 'react';
import Modal from 'components/UI/Modal';
import CreatePost from '../CreatePost/CreatePost';

const EditModal = ({ isOpen, onModalClose, item, contribution, fetchPosts, isUnifiedCommunity }) => {
  return (
    <Modal
      className="edit-post-modal"
      applyTheming
      title="Edit post"
      isOpen={isOpen}
      onCancel={onModalClose}
      disableFooter={true}
      hiddenCancel={true}
      isUnifiedCommunity={isUnifiedCommunity}
      disableConfirm={true}
      brandingColor={isUnifiedCommunity ? false : true}
    >
      <CreatePost
        isUnifiedCommunity={isUnifiedCommunity}
        item={item}
        isEdit={true}
        onSubmit={onModalClose}
        contributionData={contribution}
        fetchPosts={fetchPosts}
        isEditing={true}
      />
    </Modal>
  );
};

export default EditModal;
