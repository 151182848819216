import React from 'react';
import CalendarSvg from 'pages/ContributionView/ContributionSessions/OneToOneSessions/EasyBooking/icons/CalendarIcon';
import CircleSvg from './CircleIcon';

const CustomEvent = ({ title, event: { isExternalEvent, color }, view }) => {
  return (
    <div>
      {view === 'month' && <CircleSvg fill={color} />}
      <div className="d-flex event-title" style={{ gap: '10px' }}>
        <span>{title}</span>
        {view !== 'month' && isExternalEvent && <CalendarSvg color={color} />}
      </div>
    </div>
  );
};

export default CustomEvent;
