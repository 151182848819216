import React, { useState, useEffect, useMemo, useCallback } from 'react';
import Button from 'components/FormUI/Button';
import { useHttp, useAccount } from 'hooks';
import { useStripe } from '@stripe/react-stripe-js';
import { redirectInNewTab } from 'services/links';
import { Typography, useMediaQuery, useTheme } from '@material-ui/core';
import MembershipItem from '../Memberships/components/MembershipItem';
import { useDispatch } from 'react-redux';
import { ContributionType } from 'helpers/constants';
import { setActivePaidTierContributions } from 'actions/paidTierOptions';
import { getAcademy } from 'services/paidTierOptions.service';
import * as purchaseService from '../../../services/purchase.service';
import * as contributionService from '../../../services/contributions.service';
import { Grid } from '@mui/material';
import { MEMBERSHIP_STATUSES } from '../../../constants';
const BillingPage = () => {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme?.breakpoints?.down(900));
  const { request } = useHttp();
  const stripe = useStripe();
  const { user } = useAccount();
  const [declinedSubs, setDeclinedSubs] = useState({});
  const [StripeData, setStripeData] = useState([]);
  const [memberships, setMemberships] = useState([]);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    contributionService
      .getBoughtByType(ContributionType.contributionMembership)
      .then(contributions => {
        setMemberships(contributions);
      })
      .then(() => {
        return getAcademy();
      })
      .then(res => {
        dispatch(setActivePaidTierContributions(res));
        setLoading(false);
      })
      .catch(console.error);
  }, []);

  const handleCancelMembershipClick = useCallback(
    id => {
      purchaseService
        .cancelMembership(id)
        .then(() =>
          setMemberships(prevState =>
            prevState.map(membership =>
              membership.id === id
                ? {
                    ...membership,
                    subscriptionStatus: { ...membership.subscriptionStatus, status: MEMBERSHIP_STATUSES.CANCEL },
                  }
                : membership,
            ),
          ),
        )
        .catch(console.error);
    },
    [memberships, setMemberships],
  );

  useEffect(() => {
    request(`/Contribution/GetAllBoughtByUserId/${user.id}`)
      .then(({ clientDeclinedSubscriptions }) => {
        setDeclinedSubs(clientDeclinedSubscriptions);
      })
      .catch(console.dir);
    request(`/api/Payment/GetAllStripeCustomerIds`, 'POST')
      .then(data => {
        setStripeData(data);
      })
      .catch(console.dir);
  }, []);

  const onClick = () => {
    request('/subscription/CreateCheckoutSession', 'POST')
      .then(checkoutSessionId =>
        stripe.redirectToCheckout({
          sessionId: checkoutSessionId,
        }),
      )
      .catch(console.dir);
  };

  var showUpdatePaymentsDetailsButton = useMemo(() => {
    return declinedSubs && declinedSubs.length > 0;
  }, [declinedSubs]);

  const openCustomerPortal = id => {
    request(`/api/Payment/CreateCustomerPortalLink?customerId=${id}`, 'POST')
      .then(customerPortalLink => redirectInNewTab(customerPortalLink))
      .catch(console.dir);
  };

  return (
    <>
      {/* {showUpdatePaymentsDetailsButton && (
        <>
          <p>There was a problem processing your payment. Please update your payment information.</p>
          <Button onClick={onClick}>Update payment information</Button>
        </>
      )} */}
      {
        // !showUpdatePaymentsDetailsButton &&
        <>
          <p>
            You can access your monthly subscriptions and split payment invoices here. For all other purchases
            (including paid-in-full, pay per session, etc), please check the email associated with your Cohere account
            for original receipts.
          </p>

          {StripeData?.length > 0 && (
            <div
              style={{
                width: StripeData.length === 2 && !mobileView ? '650px' : 'auto',
                display: 'flex',
                justifyContent: 'space-between',
                flexWrap: 'wrap',
              }}
            >
              {StripeData.map(item => {
                return (
                  item?.currency != null && (
                    <div style={{ marginTop: '10px' }}>
                      <p style={{ fontWeight: 500, fontSize: '22px' }}>
                        {item?.currency?.toUpperCase()} related purchases
                      </p>
                      <Button onClick={() => openCustomerPortal(item?.customerId)}>
                        Invoices &#38; Payment Method
                      </Button>
                    </div>
                  )
                );
              })}
            </div>
          )}
          <Grid container xs={12}>
            {memberships.length > 0 && (
              <p style={{ fontWeight: 500, fontSize: '22px', marginTop: '15px' }}>Memberships</p>
            )}
            {memberships.map(membership => (
              <MembershipItem
                key={membership.id}
                membership={membership}
                onCancelSubscription={handleCancelMembershipClick}
              />
            ))}
          </Grid>
        </>
      }
    </>
  );
};

export default BillingPage;
