import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import { checkToken } from 'utils/request';

const CommonRoute = ({ component: Component, authorized, onboardingStatus, children, ...rest }) => {
  return (
    <Route
      {...rest}
      render={p => {
        if (onboardingStatus === 'Registered') {
          return <Component {...p} />;
        }

        if (onboardingStatus === 'InfoAdded') {
          return rest.path === '/auth/signup/continue' || rest.path === '/auth/signup/client/continue' ? (
            <Redirect to="/dashboard" />
          ) : (
            <Component {...p} />
          );
        }

        return <Component {...p} />;
      }}
    />
  );
};

CommonRoute.propTypes = {
  children: PropTypes.node,
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.element, PropTypes.node, PropTypes.elementType]),
  authorized: PropTypes.bool.isRequired,
  onboardingStatus: PropTypes.string.isRequired,
};

CommonRoute.defaultProps = {
  children: null,
  component: null,
};

const mapStateToProps = ({ account }) => ({
  authorized: checkToken(account?.token),
  onboardingStatus: (account.user && account.user.onboardingStatus) || '',
});

export default connect(mapStateToProps)(CommonRoute);
