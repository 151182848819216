import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { CircularProgress } from '@material-ui/core';
import Button from './Button';

const StyledWrapper = styled.span`
  position: relative;
`;

const StyledCircularProgress = styled(CircularProgress)`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -10px;
  margin-left: -10px;
`;

const ButtonProgress = ({ children, pending, ...restProps }) => {
  return (
    <StyledWrapper>
      <Button {...restProps}>{children}</Button>
      {pending && <StyledCircularProgress size={20} />}
    </StyledWrapper>
  );
};

ButtonProgress.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  active: PropTypes.bool,
  variant: PropTypes.oneOf(['primary', 'secondary']),
  backgroundColor: PropTypes.string,
  autoWidth: PropTypes.bool,
  invert: PropTypes.bool,
  mobileView: PropTypes.bool,
};

ButtonProgress.defaultProps = {
  children: 'Default button',
  onClick: () => {},
  disabled: false,
  active: false,
  variant: 'secondary',
  backgroundColor: null,
  autoWidth: false,
  invert: false,
  mobileView: false,
};

export default ButtonProgress;
