import React from 'react';
import classes from './Input.module.scss';

const Input = ({ inputClassName, error, ...restProps }) => {
  const inputClass = `${classes.input} ${inputClassName}`;
  return (
    <div className={classes.mainContainer}>
      <input className={inputClass} {...restProps} />
      {error && <p className={classes.error}>{error}</p>}
    </div>
  );
};

export default Input;
