import React, { useCallback, useState } from 'react';

import PopupComp from 'components/Popup/Popup';
import Loader from 'components/UI/Loader';
import * as contributionService from 'services/contributions.service';
import { useDispatch } from 'react-redux';
import * as actions from 'actions/contributions';
import { useAccount } from 'hooks';

const CONFIRM_TEXT = {
  MESSAGE:
    'PLEASE NOTE, once you mark as complete you will not be able to undo this. If you click no, you can start and finish this session later.',
  NO_BUTTON: 'No',
  TITLE: 'Complete this session?',
};

const ERROR_TEXT = {
  TITLE: 'Retry?',
  MESSAGE: 'An error occurred while trying to complete the session.',
  NO_BUTTON: 'No',
};

const initialSessionTimeData = {
  contributionId: '',
  sessionTimeId: '',
  sessionId: '',
};

export const useCompleteSelfPacedSessionTime = (fetchContributions, onSuccessCallBack = () => {}) => {
  const [error, setError] = useState(null);
  const [isFetching, setIsFetching] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [sessionTimeData, setSessionTimeData] = useState(initialSessionTimeData);
  const { user } = useAccount();
  const dispatch = useDispatch();

  const onClosePopup = useCallback(() => {
    setError(null);
    setIsFetching(false);
    setIsOpen(false);
    setSessionTimeData(initialSessionTimeData);
  }, []);

  const onShowPopup = useCallback(data => {
    const { contributionId, sessionTimeId, sessionId } = data;
    setSessionTimeData({ contributionId, sessionTimeId, sessionId });
    setIsOpen(true);
  }, []);

  const onSubmit = useCallback(() => {
    const { contributionId, sessionTimeId, sessionId } = sessionTimeData;

    // return onSuccessCallBack && onSuccessCallBack();

    setIsFetching(true);
    contributionService
      .completeSelfPacedSessionTime({ contributionId, sessionTimeId })
      .then(result => {
        // onSuccessCallBack();
        dispatch(actions.updateSessionCompletedList({ sessionTimeId, sessionId, userId: user?.id }));
      })
      .then(() => setIsOpen(false))
      .then(() => onSuccessCallBack && onSuccessCallBack())
      .catch(setError)
      .finally(() => setIsFetching(false));
  }, [fetchContributions, sessionTimeData]);

  const Popup = props => {
    if (isFetching) {
      return <Loader />;
    }

    return (
      <PopupComp
        onClose={onClosePopup}
        onCloseText={error ? ERROR_TEXT.NO_BUTTON : CONFIRM_TEXT.NO_BUTTON}
        onSubmit={onSubmit}
        open={isOpen}
        text={error ? ERROR_TEXT.MESSAGE : CONFIRM_TEXT.MESSAGE}
        title={error ? ERROR_TEXT.TITLE : CONFIRM_TEXT.TITLE}
        {...props}
      />
    );
  };

  return {
    Popup,
    isOpen,
    onShowPopup,
  };
};
