import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { FieldArray, Form, Formik } from 'formik';
import { useAccount } from 'hooks';
import Modal from 'components/UI/Modal';
import Input from 'components/FormUI/Input';
import Select from 'components/FormUI/Select';
import { PAYMENT_OPTIONS, TOOLTIP } from 'constants.js';
import styled from 'styled-components';
import { formatMoney } from 'utils/datesAndMoney';
import InfoIcon from '@material-ui/icons/Info';
import Tooltip from '@material-ui/core/Tooltip';
import { ContributionStatus } from 'helpers/constants';
import { useRouter, useShallowEqualSelector } from 'hooks';
import { colors } from 'utils/styles';
import usePaidTier from 'hooks/usePaidTier';
import { communityPaymentValidationSchema } from '../../contributionValidations';
import {
  BlueCheckbox,
  BlueCheckboxForAccordianSummaryStyled,
  FormControlLabelStyled,
  InputStyled,
  SelectStyled,
  SelectWrapStyled,
  StyledHeader,
  StyledSummaryBodyContainer,
  StyledText,
  StyledTooltip,
  StyledTooltipWrap,
  SummaryPricingTextContainer,
  FormControlFullWidthStyled,
} from './Membership/styledComponents';
import { FORM_FIELDS, durationItems, sessionNumbersItems } from './Membership/constants';
import { getCurrency } from 'utils/getPaymentOptionsView';
import CustomExpansionPanel from 'pages/CreateContribution/components/ExpansionPanel';
import { isUndefined, toUpper } from 'lodash';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { setCohereAcademyStatus } from 'actions/update-user';
import { PAID_TIER_TITLES } from '../../../../constants';
import * as paidTier from 'selectors/paidTier';
// import useShallowEqualSelector from 'hooks';
const Community = ({ contribution, shouldBeDisabled, saveToStorageHandler, children }) => {
  const formatPackageSubscriptionPrice = ({ membershipPackage: { duration, period, cost } }) =>
    `Price for ${duration} months with ${period.toLowerCase()} payments: ${formatMoney(cost)}$`;
  const handleFormKeyDown = e => {
    if ((e.charCode || e.keyCode) === 13) {
      e.preventDefault();
    }
  };
  const {
    user: { transfersEnabled, isStandardAccount, defaultPaymentMethod, standardAccountTransfersEnabled, avatarUrl },
  } = useAccount();
  const userDetail = useSelector(state => state.account.user);
  const { isDev, query } = useRouter();
  const isFeeDisabled = !!query.id && contribution?.status !== ContributionStatus.unfinished;
  // const { isLaunchPlan } = usePaidTier();
  const dispatch = useDispatch();
  const activePaidTierCurrentPlan = useShallowEqualSelector(paidTier.activePaidTierCurrentPlanSelector);
  const isLaunchPlan = activePaidTierCurrentPlan?.displayName === PAID_TIER_TITLES.launch;

  // Correct behavior - 100%

  useEffect(() => {
    if (activePaidTierCurrentPlan?.default) {
      dispatch(setCohereAcademyStatus(true));
    } else {
      dispatch(setCohereAcademyStatus(false));
    }
  }, [activePaidTierCurrentPlan, dispatch]);

  const [supportPopup, setSupportPopup] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const { user } = useAccount();
  const serviceAgreementType = user?.serviceAgreementType;
  const isBetaUser = user?.isBetaUser;
  const showOnlyFreeOption = serviceAgreementType && isBetaUser ? true : false;
  // const contributionStatus = useSelector(s => s.contributions.activeContribution.status);
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'));
  const smView = useMediaQuery(theme.breakpoints.up('sm'));
  const isEditing = !!query.id;
  // const isApprovedContribution = contributionStatus === 'Approved';
  let formRef = useRef();
  useEffect(() => {
    formRef.current.setFieldValue('coachPaysStripeFee', contribution?.paymentInfo?.coachPaysStripeFee);
    if (formRef?.current?.values?.paymentType === 'Simple' && contribution?.taxType === 'No') {
      formRef.current.setFieldValue('taxType', 'Exclusive');
    } else if (formRef?.current?.values?.paymentType === 'Advance' && contribution?.taxType === 'No') {
      formRef.current.setFieldValue('taxType', 'No');
    }
  }, [formRef]);
  const handleChangeCurrency = ({ target: { value } }, formProps) => {
    const symbol = contribution.availableCurrencies?.find(c => c.code === value).symbol;
    formProps.setFieldValue('defaultCurrency', value);
    formProps.setFieldValue('defaultSymbol', symbol);
  };
  const handlePaymentProcessor = ({ target: { value } }, formProps) => {
    if (value === 'Advance') {
      if (!user.isStandardTaxEnabled) {
        setIsDisabled(true);
      } else {
        setIsDisabled(false);
      }
      setSupportPopup(true);
      formProps.setFieldValue('taxType', 'No');
      if (standardAccountTransfersEnabled === false) {
        formProps.setFieldValue('multiplePriceList', []);
        formProps.setFieldValue('cost', null);
        formProps.setFieldValue('splittedCost', null);
        formProps.setFieldValue('paymentOptions', ['Free']);
      }
    }
    if (value === 'Simple') {
      setIsDisabled(false);
      formProps.setFieldValue('taxType', 'Exclusive');
    }
    formProps.setFieldValue('paymentType', value);
  };
  const handleTaxType = ({ target: { value } }, formProps) => {
    formProps.setFieldValue('taxType', value);
  };
  return (
    <Formik
      innerRef={formRef}
      enableReinitialize
      initialValues={{
        [FORM_FIELDS.PAYMENT_OPTIONS]: contribution?.paymentInfo?.paymentOptions || [PAYMENT_OPTIONS.FREE],
        [FORM_FIELDS.PER_DAY_COST]: contribution?.paymentInfo?.membershipInfo?.costs?.DailyMembership || '',
        [FORM_FIELDS.PER_WEEK_COST]: contribution?.paymentInfo?.membershipInfo?.costs?.WeeklyMembership || '',
        [FORM_FIELDS.PER_MONTH_COST]: contribution?.paymentInfo?.membershipInfo?.costs?.MonthlyMembership || '',
        [FORM_FIELDS.PER_YEAR_COST]: contribution?.paymentInfo?.membershipInfo?.costs?.YearlyMembership || '',
        [FORM_FIELDS.MEMBERSHIP_PACKAGE]: contribution?.paymentInfo?.membershipInfo?.membershipPackage || {},
        coachPaysStripeFee: contribution?.paymentInfo?.coachPaysStripeFee,
        defaultSymbol: contribution.defaultSymbol,
        paymentType: contribution?.paymentType || user?.defaultPaymentMethod,
        taxType: contribution?.taxType || 'No',
        defaultCurrency: contribution.defaultCurrency,
      }}
      validationSchema={communityPaymentValidationSchema}
      onSubmit={saveToStorageHandler}
    >
      {formProps => {
        const renderCardBody = (
          <Form id="create-contribution-form" onKeyDown={handleFormKeyDown}>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={8} style={smView ? {} : { paddingBottom: '0.5rem' }}>
                <FieldArray name={FORM_FIELDS.PAYMENT_OPTIONS}>
                  {({ push, remove, form }) => {
                    // const changeHandler = ({ target: { value, checked } }) =>
                    //   checked ? push(value) : remove(form.values.paymentOptions.indexOf(value));
                    const changeHandler = ({ target: { value, checked } }) => {
                      if (checked) {
                        if (value === PAYMENT_OPTIONS.PACKAGE) {
                          form.setFieldValue(FORM_FIELDS.MEMBERSHIP_PACKAGE_DURATION, 3);
                          form.setFieldValue(FORM_FIELDS.MEMBERSHIP_PACKAGE_PERIOD, 'Monthly');
                          form.setFieldValue(FORM_FIELDS.MEMBERSHIP_PACKAGE_COST, '');
                        }
                        push(value);
                      } else {
                        remove(form.values.paymentOptions.indexOf(value));

                        if (value === PAYMENT_OPTIONS.PER_DAY) {
                          form.setFieldValue(FORM_FIELDS.PER_DAY_COST, '');
                        }
                        if (value === PAYMENT_OPTIONS.PER_WEEK) {
                          form.setFieldValue(FORM_FIELDS.PER_WEEK_COST, '');
                        }
                        if (value === PAYMENT_OPTIONS.PER_MONTH) {
                          form.setFieldValue(FORM_FIELDS.PER_MONTH_COST, '');
                        }
                        if (value === PAYMENT_OPTIONS.PER_YEAR) {
                          form.setFieldValue(FORM_FIELDS.PER_YEAR_COST, '');
                        }
                      }
                    };
                    const hasNoSelectionError =
                      !isUndefined(formProps.errors.paymentOptions) &&
                      typeof formProps.errors.paymentOptions === 'string' &&
                      form.values.paymentOptions?.length <= 0;
                    return (
                      <Grid container>
                        <FormControlFullWidthStyled error={hasNoSelectionError} fullWidth>
                          <Grid item sm={12}>
                            {hasNoSelectionError && (
                              <FormLabel className="mb-3" component="legend">
                                Pick at least one option
                              </FormLabel>
                            )}
                          </Grid>
                          <Grid item xs={12}>
                            <CustomExpansionPanel
                              bodyPadding={mobileView ? '20px 10px !important' : '20px 24px !important'}
                              headerPadding={mobileView ? '0px 2px !important' : '0px 15px !important'}
                              minHeight="30px"
                              summaryTitle={
                                <StyledTooltipWrap>
                                  <FormControlLabelStyled
                                    name={FORM_FIELDS.PAYMENT_OPTIONS}
                                    control={<BlueCheckboxForAccordianSummaryStyled color="primary" />}
                                    label="Free"
                                    disabled={isLaunchPlan || shouldBeDisabled}
                                    value={PAYMENT_OPTIONS.FREE}
                                    onChange={changeHandler}
                                    checked={form.values.paymentOptions.includes(PAYMENT_OPTIONS.FREE)}
                                    style={{
                                      fontSize: '18px',
                                      fontStyle: 'normal',
                                      fontWeight: 500,
                                      lineHeight: '30px',
                                      letterSpacing: '0.1px',
                                      margin: '0 1rem 0 0',
                                    }}
                                  />
                                  <StyledTooltip
                                    title="If you select this option, you will have a free link where clients can join session for free."
                                    arrow
                                    enterTouchDelay={TOOLTIP.ENTER_DELAY}
                                    leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                                  >
                                    <InfoIcon fontSize="small" htmlColor={colors.lightBrown} />
                                  </StyledTooltip>
                                </StyledTooltipWrap>
                              }
                              // mobileView={mobileView}
                              // expand={false}
                              disabledExpansion
                            />
                          </Grid>

                          {isDev && (
                            <>
                              <Grid item sm={12}>
                                <CustomExpansionPanel
                                  bodyPadding={mobileView ? '20px 10px !important' : '20px 24px !important'}
                                  headerPadding={mobileView ? '0px 2px !important' : '0px 15px !important'}
                                  minHeight="30px"
                                  summaryTitle={
                                    <StyledTooltipWrap>
                                      <FormControlLabelStyled
                                        name={FORM_FIELDS.PAYMENT_OPTIONS}
                                        control={<BlueCheckboxForAccordianSummaryStyled color="primary" />}
                                        label="Price Per Day"
                                        value={PAYMENT_OPTIONS.PER_DAY}
                                        onChange={changeHandler}
                                        disabled={
                                          isLaunchPlan ||
                                          shouldBeDisabled ||
                                          (formProps.values.paymentType === 'Simple' && transfersEnabled === false) ||
                                          (formProps.values.paymentType === 'Advance' &&
                                            standardAccountTransfersEnabled === false)
                                        }
                                        checked={form.values.paymentOptions.includes(PAYMENT_OPTIONS.PER_DAY)}
                                        style={{
                                          marginLeft: 0,
                                          marginBottom: 0,
                                        }}
                                      />
                                      <StyledTooltip
                                        title="Create a daily membership program where your clients are charged daily."
                                        arrow
                                        enterTouchDelay={TOOLTIP.ENTER_DELAY}
                                        leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                                      >
                                        <InfoIcon fontSize="small" htmlColor={colors.lightBrown} />
                                      </StyledTooltip>
                                    </StyledTooltipWrap>
                                  }
                                  summarySubTitle={
                                    <div style={{ paddingLeft: 9 }}>
                                      {form.values.paymentOptions.includes(PAYMENT_OPTIONS.PER_DAY) &&
                                        `Price per day: ${form.values.defaultSymbol || '$'}${formatMoney(
                                          form?.values?.[FORM_FIELDS.PER_DAY_COST],
                                        )}`}
                                    </div>
                                  }
                                  // mobileView={mobileView}
                                  expand={form.values.paymentOptions.includes(PAYMENT_OPTIONS.PER_DAY)}
                                  disabledExpansion={!form.values.paymentOptions.includes(PAYMENT_OPTIONS.PER_DAY)}
                                >
                                  <Grid container>
                                    <Grid item sm={6} xs={12} direction="column">
                                      {formProps.values.paymentOptions.includes(PAYMENT_OPTIONS.PER_DAY) && (
                                        <>
                                          <Grid item xs={12}>
                                            <StyledHeader id="lbl-input-price-per-day">Price Per Day</StyledHeader>
                                          </Grid>
                                          <Grid item sm={9} xs={12}>
                                            <InputStyled
                                              startAdornment={
                                                formProps.values.defaultSymbol ? formProps.values.defaultSymbol : '$'
                                              }
                                              labelId="lbl-input-price-per-day"
                                              variant="outlined"
                                              margin="dense"
                                              type="text"
                                              name={FORM_FIELDS.PER_DAY_COST}
                                              fullWidth
                                              label=""
                                              disabled={shouldBeDisabled}
                                              onChange={({ target: { value } }) => {
                                                if (!/\D/.test(value)) {
                                                  formProps.setFieldValue(FORM_FIELDS.PER_DAY_COST, value);
                                                }
                                              }}
                                            />
                                          </Grid>
                                        </>
                                      )}
                                    </Grid>
                                  </Grid>
                                </CustomExpansionPanel>
                              </Grid>

                              <Grid item sm={12}>
                                <CustomExpansionPanel
                                  bodyPadding={mobileView ? '20px 10px !important' : '20px 24px !important'}
                                  headerPadding={mobileView ? '0px 2px !important' : '0px 15px !important'}
                                  minHeight="30px"
                                  summaryTitle={
                                    <StyledTooltipWrap>
                                      <FormControlLabelStyled
                                        name={FORM_FIELDS.PAYMENT_OPTIONS}
                                        control={<BlueCheckboxForAccordianSummaryStyled color="primary" />}
                                        label="Price Per Week"
                                        value={PAYMENT_OPTIONS.PER_WEEK}
                                        onChange={changeHandler}
                                        disabled={
                                          isLaunchPlan ||
                                          shouldBeDisabled ||
                                          (formProps.values.paymentType === 'Simple' && transfersEnabled === false) ||
                                          (formProps.values.paymentType === 'Advance' &&
                                            standardAccountTransfersEnabled === false)
                                        }
                                        checked={form.values.paymentOptions.includes(PAYMENT_OPTIONS.PER_WEEK)}
                                        style={{
                                          marginLeft: 0,
                                          marginBottom: 0,
                                        }}
                                      />
                                      <StyledTooltip
                                        title="Create a weekly membership program where your clients are charged weekly."
                                        arrow
                                        enterTouchDelay={TOOLTIP.ENTER_DELAY}
                                        leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                                      >
                                        <InfoIcon fontSize="small" htmlColor={colors.lightBrown} />
                                      </StyledTooltip>
                                    </StyledTooltipWrap>
                                  }
                                  summarySubTitle={
                                    <div style={{ paddingLeft: 9 }}>
                                      {form.values.paymentOptions.includes(PAYMENT_OPTIONS.PER_MONTH) &&
                                        `Price per week: ${form.values.defaultSymbol || '$'}${formatMoney(
                                          form?.values?.[FORM_FIELDS.PER_WEEK_COST],
                                        )}`}
                                    </div>
                                  }
                                  // mobileView={mobileView}
                                  expand={form.values.paymentOptions.includes(PAYMENT_OPTIONS.PER_WEEK)}
                                  disabledExpansion={!form.values.paymentOptions.includes(PAYMENT_OPTIONS.PER_WEEK)}
                                >
                                  <Grid container>
                                    <Grid item sm={6} xs={12} direction="column">
                                      {formProps.values.paymentOptions.includes(PAYMENT_OPTIONS.PER_WEEK) && (
                                        <>
                                          <Grid item xs={12}>
                                            <StyledHeader id="lbl-input-price-per-week">Price Per Week</StyledHeader>
                                          </Grid>
                                          <Grid item sm={9} xs={12}>
                                            <InputStyled
                                              startAdornment={
                                                formProps.values.defaultSymbol ? formProps.values.defaultSymbol : '$'
                                              }
                                              labelId="lbl-input-price-per-week"
                                              variant="outlined"
                                              margin="dense"
                                              type="text"
                                              name={FORM_FIELDS.PER_WEEK_COST}
                                              fullWidth
                                              label=""
                                              disabled={shouldBeDisabled}
                                              onChange={({ target: { value } }) => {
                                                if (!/\D/.test(value)) {
                                                  formProps.setFieldValue(FORM_FIELDS.PER_WEEK_COST, value);
                                                }
                                              }}
                                            />
                                          </Grid>
                                        </>
                                      )}
                                    </Grid>
                                  </Grid>
                                </CustomExpansionPanel>
                              </Grid>
                            </>
                          )}

                          <Grid item sm={12}>
                            <CustomExpansionPanel
                              bodyPadding={mobileView ? '20px 10px !important' : '20px 24px !important'}
                              headerPadding={mobileView ? '0px 2px !important' : '0px 15px !important'}
                              minHeight="30px"
                              // asyncContainerPadding={mobileView}
                              summaryTitle={
                                <StyledTooltipWrap>
                                  <FormControlLabelStyled
                                    name={FORM_FIELDS.PAYMENT_OPTIONS}
                                    control={<BlueCheckboxForAccordianSummaryStyled color="primary" />}
                                    label="Price Per Month"
                                    value={PAYMENT_OPTIONS.PER_MONTH}
                                    onChange={changeHandler}
                                    disabled={
                                      isLaunchPlan ||
                                      shouldBeDisabled ||
                                      (formProps.values.paymentType === 'Simple' && transfersEnabled === false) ||
                                      (formProps.values.paymentType === 'Advance' &&
                                        standardAccountTransfersEnabled === false)
                                    }
                                    checked={form.values.paymentOptions.includes(PAYMENT_OPTIONS.PER_MONTH)}
                                    style={{
                                      marginLeft: 0,
                                      marginBottom: 0,
                                    }}
                                  />
                                  <StyledTooltip
                                    title="Create a monthly membership program where your clients are charged monthly."
                                    arrow
                                    enterTouchDelay={TOOLTIP.ENTER_DELAY}
                                    leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                                  >
                                    <InfoIcon fontSize="small" htmlColor={colors.lightBrown} />
                                  </StyledTooltip>
                                </StyledTooltipWrap>
                              }
                              summarySubTitle={
                                <div style={{ paddingLeft: 9 }}>
                                  {form.values.paymentOptions.includes(PAYMENT_OPTIONS.PER_MONTH) &&
                                    `Price per month: ${form.values.defaultSymbol || '$'}${formatMoney(
                                      form?.values?.[FORM_FIELDS.PER_MONTH_COST],
                                    )}`}
                                </div>
                              }
                              // mobileView={mobileView}
                              expand={form.values.paymentOptions.includes(PAYMENT_OPTIONS.PER_MONTH)}
                              disabledExpansion={!form.values.paymentOptions.includes(PAYMENT_OPTIONS.PER_MONTH)}
                            >
                              <Grid container>
                                <Grid item sm={6} xs={12} direction="column">
                                  {formProps.values.paymentOptions.includes(PAYMENT_OPTIONS.PER_MONTH) && (
                                    <>
                                      <Grid item xs={12}>
                                        <StyledHeader id="lbl-input-price-per-month">Price Per Month</StyledHeader>
                                      </Grid>
                                      <Grid item sm={9} xs={12}>
                                        <InputStyled
                                          startAdornment={
                                            formProps.values.defaultSymbol ? formProps.values.defaultSymbol : '$'
                                          }
                                          labelId="lbl-input-price-per-month"
                                          variant="outlined"
                                          margin="dense"
                                          type="text"
                                          name={FORM_FIELDS.PER_MONTH_COST}
                                          fullWidth
                                          label=""
                                          disabled={shouldBeDisabled}
                                          onChange={({ target: { value } }) => {
                                            if (!/\D/.test(value)) {
                                              formProps.setFieldValue(FORM_FIELDS.PER_MONTH_COST, value);
                                            }
                                          }}
                                        />
                                      </Grid>
                                    </>
                                  )}
                                </Grid>
                              </Grid>
                            </CustomExpansionPanel>
                          </Grid>

                          <Grid item sm={12}>
                            <CustomExpansionPanel
                              bodyPadding={mobileView ? '20px 10px !important' : '20px 24px !important'}
                              headerPadding={mobileView ? '0px 2px !important' : '0px 15px !important'}
                              minHeight="30px"
                              // asyncContainerPadding={mobileView}
                              summaryTitle={
                                <StyledTooltipWrap>
                                  <FormControlLabelStyled
                                    name={FORM_FIELDS.PAYMENT_OPTIONS}
                                    control={<BlueCheckboxForAccordianSummaryStyled color="primary" />}
                                    label="Price Per Year"
                                    value={PAYMENT_OPTIONS.PER_YEAR}
                                    onChange={changeHandler}
                                    disabled={
                                      isLaunchPlan ||
                                      shouldBeDisabled ||
                                      (formProps.values.paymentType === 'Simple' && transfersEnabled === false) ||
                                      (formProps.values.paymentType === 'Advance' &&
                                        standardAccountTransfersEnabled === false)
                                    }
                                    checked={form.values.paymentOptions.includes(PAYMENT_OPTIONS.PER_YEAR)}
                                    style={{
                                      marginLeft: 0,
                                      marginBottom: 0,
                                    }}
                                  />
                                  <StyledTooltip
                                    title="Create an annual membership program where your clients are charged annually."
                                    arrow
                                    enterTouchDelay={TOOLTIP.ENTER_DELAY}
                                    leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                                  >
                                    <InfoIcon fontSize="small" htmlColor={colors.lightBrown} />
                                  </StyledTooltip>
                                </StyledTooltipWrap>
                              }
                              summarySubTitle={
                                <div style={{ paddingLeft: 9 }}>
                                  {form.values.paymentOptions.includes(PAYMENT_OPTIONS.PER_YEAR) &&
                                    `Price per year: ${form.values.defaultSymbol || '$'}${formatMoney(
                                      form?.values?.[FORM_FIELDS.PER_YEAR_COST],
                                    )}`}
                                </div>
                              }
                              // mobileView={mobileView}
                              expand={form.values.paymentOptions.includes(PAYMENT_OPTIONS.PER_YEAR)}
                              disabledExpansion={!form.values.paymentOptions.includes(PAYMENT_OPTIONS.PER_YEAR)}
                            >
                              <Grid container>
                                <Grid item sm={6} xs={12} direction="column">
                                  {formProps.values.paymentOptions.includes(PAYMENT_OPTIONS.PER_YEAR) && (
                                    <>
                                      <Grid item xs={12}>
                                        <StyledHeader id="lbl-input-price-per-year">Price Per Year</StyledHeader>
                                      </Grid>
                                      <Grid item sm={9} xs={12}>
                                        <InputStyled
                                          startAdornment={
                                            formProps.values.defaultSymbol ? formProps.values.defaultSymbol : '$'
                                          }
                                          labelId="lbl-input-price-per-year"
                                          variant="outlined"
                                          margin="dense"
                                          type="text"
                                          name={FORM_FIELDS.PER_YEAR_COST}
                                          fullWidth
                                          label=""
                                          disabled={shouldBeDisabled}
                                          onChange={({ target: { value } }) => {
                                            if (!/\D/.test(value)) {
                                              formProps.setFieldValue(FORM_FIELDS.PER_YEAR_COST, value);
                                            }
                                          }}
                                        />
                                      </Grid>
                                    </>
                                  )}
                                </Grid>
                              </Grid>
                            </CustomExpansionPanel>
                          </Grid>

                          {false && (
                            <Grid item sm={12}>
                              <CustomExpansionPanel
                                bodyPadding={mobileView ? '20px 10px !important' : '20px 24px !important'}
                                headerPadding={mobileView ? '0px 2px !important' : '0px 15px !important'}
                                minHeight="30px"
                                summaryTitle={
                                  <StyledTooltipWrap>
                                    <FormControlLabelStyled
                                      name={FORM_FIELDS.PAYMENT_OPTIONS}
                                      control={<BlueCheckboxForAccordianSummaryStyled color="primary" />}
                                      label="Package"
                                      value={PAYMENT_OPTIONS.PACKAGE}
                                      onChange={changeHandler}
                                      disabled={
                                        isLaunchPlan ||
                                        shouldBeDisabled ||
                                        (formProps.values.paymentType === 'Simple' && transfersEnabled === false) ||
                                        (formProps.values.paymentType === 'Advance' &&
                                          standardAccountTransfersEnabled === false)
                                      }
                                      checked={form.values.paymentOptions.includes(PAYMENT_OPTIONS.PACKAGE)}
                                      style={{
                                        marginLeft: 0,
                                        marginBottom: 0,
                                      }}
                                    />
                                    <StyledTooltip
                                      title="Create an annual membership program where your clients are charged annually."
                                      arrow
                                      enterTouchDelay={TOOLTIP.ENTER_DELAY}
                                      leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                                    >
                                      <InfoIcon fontSize="small" htmlColor={colors.lightBrown} />
                                    </StyledTooltip>
                                  </StyledTooltipWrap>
                                }
                                summarySubTitle={
                                  <div style={{ paddingLeft: 9 }}>
                                    {form.values.paymentOptions.includes(PAYMENT_OPTIONS.PACKAGE) &&
                                      `Package price: ${form.values.defaultSymbol || '$'}${formatMoney(
                                        form?.values?.[FORM_FIELDS.MEMBERSHIP_PACKAGE_COST],
                                      )}`}
                                  </div>
                                }
                                // mobileView={mobileView}
                                expand={form.values.paymentOptions.includes(PAYMENT_OPTIONS.PACKAGE)}
                                disabledExpansion={!form.values.paymentOptions.includes(PAYMENT_OPTIONS.PACKAGE)}
                              >
                                <Grid container>
                                  <Grid item sm={6} xs={12} direction="column">
                                    {formProps.values.paymentOptions.includes(PAYMENT_OPTIONS.PACKAGE) && (
                                      <>
                                        <Grid item xs={12}>
                                          <StyledHeader id="lbl-input-price-per-package">
                                            Price Per Package
                                          </StyledHeader>
                                        </Grid>
                                        <Grid item sm={9} xs={12}>
                                          <InputStyled
                                            startAdornment={
                                              formProps.values.defaultSymbol ? formProps.values.defaultSymbol : '$'
                                            }
                                            labelId="lbl-input-price-per-package"
                                            variant="outlined"
                                            margin="dense"
                                            type="text"
                                            name={FORM_FIELDS.MEMBERSHIP_PACKAGE_COST}
                                            fullWidth
                                            label=""
                                            disabled={shouldBeDisabled}
                                            onChange={({ target: { value } }) => {
                                              if (!/\D/.test(value)) {
                                                formProps.setFieldValue(FORM_FIELDS.MEMBERSHIP_PACKAGE_COST, value);
                                              }
                                            }}
                                          />
                                        </Grid>
                                        <Grid item sm={8} xs={12}>
                                          <Grid item sm={8} xs={12}>
                                            <StyledHeader id="lbl-duration">Duration</StyledHeader>
                                          </Grid>
                                          <SelectWrapStyled>
                                            <SelectStyled
                                              style={{
                                                fontFamily: 'Avenir',
                                                border: '1px solid #DFE3E4',
                                                borderRadius: '4px',
                                                fontSize: '1rem',
                                                fontWeight: '400',
                                                lineHeight: '24px',
                                                letterSpacing: '0.15px',
                                              }}
                                              labelId="lbl-duration"
                                              variant="outlined"
                                              label=""
                                              name={FORM_FIELDS.MEMBERSHIP_PACKAGE_DURATION}
                                              fullWidth
                                              items={durationItems()}
                                            />
                                          </SelectWrapStyled>
                                        </Grid>
                                        <Grid item sm={8} xs={12}>
                                          <Grid item sm={8} xs={12}>
                                            <StyledHeader id="lbl-billing-frequency">Billing Frequency</StyledHeader>
                                          </Grid>
                                          <SelectWrapStyled>
                                            <SelectStyled
                                              style={{
                                                fontFamily: 'Avenir',
                                                border: '1px solid #DFE3E4',
                                                borderRadius: '4px',
                                                fontSize: '1rem',
                                                fontWeight: '400',
                                                lineHeight: '24px',
                                                letterSpacing: '0.15px',
                                              }}
                                              labelId="lbl-billing-frequency"
                                              variant="outlined"
                                              label=""
                                              name={FORM_FIELDS.MEMBERSHIP_PACKAGE_PERIOD}
                                              fullWidth
                                              items={sessionNumbersItems()}
                                            />
                                          </SelectWrapStyled>
                                        </Grid>
                                      </>
                                    )}
                                  </Grid>
                                </Grid>
                              </CustomExpansionPanel>
                            </Grid>
                          )}

                          <Grid item xs={12}>
                            <CustomExpansionPanel
                              padding="0px 17px 0px 24px"
                              bodyPadding="20px 24px !important"
                              // asyncContainerPadding={mobileView}
                              summaryTitle={
                                <div
                                  style={{
                                    fontSize: '1.125rem',
                                    fontStyle: 'normal',
                                    fontWeight: 500,
                                    lineHeight: '30px',
                                    letterSpacing: '0.1px',
                                    color: 'black',
                                    marginLeft: '-1px',
                                  }}
                                >
                                  Payment Settings
                                </div>
                              }
                              summarySubTitle={
                                formProps.values.paymentOptions?.length &&
                                (formProps.values.paymentOptions.includes(PAYMENT_OPTIONS.PER_DAY) ||
                                  formProps.values.paymentOptions.includes(PAYMENT_OPTIONS.PER_WEEK) ||
                                  formProps.values.paymentOptions.includes(PAYMENT_OPTIONS.PER_YEAR) ||
                                  formProps.values.paymentOptions.includes(PAYMENT_OPTIONS.PER_MONTH)) ? (
                                  <div>
                                    {[
                                      toUpper(formProps.values?.defaultCurrency),
                                      (formProps.values?.paymentType === 'Simple' && 'Cohere Payment Processor') ||
                                        (formProps.values?.paymentType === 'Advance' &&
                                          `${
                                            userDetail.firstName.charAt(0).toUpperCase() + userDetail.firstName.slice(1)
                                          }'s Stripe Account`),
                                      user?.isStandardAccount === true &&
                                        formProps?.values?.paymentType === 'Advance' &&
                                        (formProps.values?.taxType === 'No' ? 'No Tax' : formProps.values?.taxType),
                                      formProps.values?.coachPaysStripeFee ? 'Coach Pays Stripe Processing Fees' : '',
                                    ]
                                      .filter(k => !!k)
                                      .join(', ')}
                                  </div>
                                ) : (
                                  <></>
                                )
                              }
                              // mobileView={mobileView}
                              // onToggle={setIsSettingsAccordianOpen}
                              // expand={isSettingsAccordianOpen}
                              // disabledExpansion={!isSettingsAccordianOpen}
                            >
                              <Grid container spacing={2}>
                                {(showOnlyFreeOption || user?.isStandardAccount) &&
                                  (formProps.values.paymentOptions.includes(PAYMENT_OPTIONS.PER_DAY) ||
                                    formProps.values.paymentOptions.includes(PAYMENT_OPTIONS.PER_WEEK) ||
                                    formProps.values.paymentOptions.includes(PAYMENT_OPTIONS.PER_YEAR) ||
                                    formProps.values.paymentOptions.includes(PAYMENT_OPTIONS.PER_MONTH)) && (
                                    <>
                                      <Grid item sm={9} xs={12} direction="column">
                                        <Grid item sm={9} xs={12}>
                                          <StyledHeader id="lbl-currency">Currency</StyledHeader>
                                        </Grid>
                                        <SelectWrapStyled>
                                          <SelectStyled
                                            style={{
                                              fontFamily: 'Avenir',
                                              border: '1px solid #DFE3E4',
                                              borderRadius: '4px',
                                              fontSize: '1rem',
                                              fontWeight: '400',
                                              lineHeight: '24px',
                                              letterSpacing: '0.15px',
                                            }}
                                            labelId="lbl-currency"
                                            // label=""
                                            variant="outlined"
                                            name="defaultCurrency"
                                            fullWidth
                                            disabled={isFeeDisabled}
                                            onChange={event => handleChangeCurrency(event, formProps)}
                                            value={formProps.values?.defaultCurrency}
                                            items={getCurrency(contribution)}
                                          />
                                        </SelectWrapStyled>
                                      </Grid>
                                      {/* {!showOnlyFreeOption && (
                                        <Grid item container xs={12}>
                                          <StyledText>{contribution.defaultCurrency?.toUpperCase()}</StyledText>
                                        </Grid>
                                      )} */}
                                    </>
                                  )}

                                {(formProps.values.paymentOptions.includes(PAYMENT_OPTIONS.PER_DAY) ||
                                  formProps.values.paymentOptions.includes(PAYMENT_OPTIONS.PER_WEEK) ||
                                  formProps.values.paymentOptions.includes(PAYMENT_OPTIONS.PER_YEAR) ||
                                  formProps.values.paymentOptions.includes(PAYMENT_OPTIONS.PER_MONTH)) && (
                                  <>
                                    {user?.isStandardAccount && (
                                      <>
                                        <Grid item sm={9} xs={12} direction="column">
                                          <Grid item sm={9} xs={12}>
                                            <StyledHeader id="lbl-payment-processor">Payment Processor</StyledHeader>
                                          </Grid>
                                          <SelectWrapStyled>
                                            <SelectStyled
                                              style={{
                                                fontFamily: 'Avenir',
                                                border: '1px solid #DFE3E4',
                                                borderRadius: '4px',
                                                fontSize: '1rem',
                                                fontWeight: '400',
                                                lineHeight: '24px',
                                                letterSpacing: '0.15px',
                                              }}
                                              labelId="lbl-payment-processor"
                                              label=""
                                              variant="outlined"
                                              name="paymentType"
                                              fullWidth
                                              disabled={isFeeDisabled}
                                              onChange={event => handlePaymentProcessor(event, formProps)}
                                              value={formProps.values?.paymentType}
                                              items={
                                                user.connectedStripeAccountId === null
                                                  ? [
                                                      {
                                                        value: 'Advance',
                                                        title: `${
                                                          userDetail.firstName.charAt(0).toUpperCase() +
                                                          userDetail.firstName.slice(1)
                                                        }'s Stripe Account`,
                                                      },
                                                    ]
                                                  : [
                                                      { value: 'Simple', title: 'Cohere Payment Processor' },
                                                      {
                                                        value: 'Advance',
                                                        title: `${
                                                          userDetail.firstName.charAt(0).toUpperCase() +
                                                          userDetail.firstName.slice(1)
                                                        }'s Stripe Account`,
                                                      },
                                                    ]
                                              }
                                            />
                                          </SelectWrapStyled>
                                        </Grid>
                                      </>
                                    )}
                                    {/* {user?.isStandardAccount && formProps.values?.paymentType === 'Advance' && ( */}
                                    {
                                      <>
                                        <Grid item sm={9} xs={12} direction="column">
                                          <Grid item sm={9} xs={12}>
                                            <StyledHeader id="lbl-tax-type">Tax Type</StyledHeader>
                                          </Grid>
                                          <SelectWrapStyled>
                                            <SelectStyled
                                              style={{
                                                fontFamily: 'Avenir',
                                                border: '1px solid #DFE3E4',
                                                borderRadius: '4px',
                                                fontSize: '1rem',
                                                fontWeight: '400',
                                                lineHeight: '24px',
                                                letterSpacing: '0.15px',
                                              }}
                                              labelId="lbl-tax-type"
                                              label=""
                                              variant="outlined"
                                              name="taxType"
                                              fullWidth
                                              disabled={isFeeDisabled}
                                              defaultValue={formProps.values?.taxType}
                                              // isOptionDisabled={option => option.isdisabled}
                                              onChange={event => handleTaxType(event, formProps)}
                                              value={formProps.values?.taxType}
                                              items={
                                                formProps.values?.paymentType === 'Simple'
                                                  ? [
                                                      {
                                                        value: 'Exclusive',
                                                        title: 'Exclusive',
                                                        disabled: isDisabled,
                                                      },
                                                      {
                                                        value: 'Inclusive',
                                                        title: 'Inclusive',
                                                        disabled: isDisabled,
                                                      },
                                                    ]
                                                  : [
                                                      {
                                                        value: 'Exclusive',
                                                        title: 'Exclusive',
                                                        disabled: isDisabled,
                                                      },
                                                      {
                                                        value: 'Inclusive',
                                                        title: 'Inclusive',
                                                        disabled: isDisabled,
                                                      },
                                                      { value: 'No', title: 'No Tax' },
                                                    ]
                                              }
                                            />
                                          </SelectWrapStyled>
                                        </Grid>
                                      </>
                                    }
                                    {/* )} */}
                                  </>
                                )}

                                <Grid item sm={9} xs={12} direction="row">
                                  <StyledTooltipWrap>
                                    <FormControlLabelStyled
                                      name="coachPaysStripeFee"
                                      fontSize="16px"
                                      fontWeight="400"
                                      control={<BlueCheckbox color="primary" />}
                                      label="Coach Pays for Processing Fee"
                                      checked={
                                        formRef?.current?.values?.paymentOptions?.includes(PAYMENT_OPTIONS.FREE) &&
                                        formRef?.current?.values?.paymentOptions?.length === 1
                                          ? false
                                          : formProps.values.coachPaysStripeFee
                                      }
                                      onChange={formProps.handleChange('coachPaysStripeFee')}
                                      disabled={
                                        isFeeDisabled ||
                                        (formRef?.current?.values?.paymentOptions?.includes(PAYMENT_OPTIONS.FREE) &&
                                          formRef?.current?.values?.paymentOptions?.length === 1) ||
                                        (formProps.values.paymentType === 'Simple' && transfersEnabled === false)
                                      }
                                    />
                                    <StyledTooltip
                                      title="If the box is checked, you are choosing to pay the Stripe credit card processing fee. If left unchecked, your client will be charged the processing fee at checkout. The Stripe processing fee is a 2.9% plus 30 cents surcharge on top of your base pricing."
                                      arrow
                                      enterTouchDelay={TOOLTIP.ENTER_DELAY}
                                      leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                                      className="mb-2"
                                    >
                                      <InfoIcon fontSize="small" htmlColor={colors.lightBrown} />
                                    </StyledTooltip>
                                  </StyledTooltipWrap>
                                </Grid>
                              </Grid>
                            </CustomExpansionPanel>
                          </Grid>
                        </FormControlFullWidthStyled>
                      </Grid>
                    );
                  }}
                </FieldArray>
              </Grid>
              <Grid item xs={12} sm={4} style={smView ? { paddingLeft: 0 } : { paddingTop: 0 }}>
                <div
                  style={{
                    borderRadius: '4px',
                    border: '1px solid #E7E7E7',
                    height: '100%',
                  }}
                >
                  <div
                    style={{
                      fontSize: '18px',
                      fontStyle: 'normal',
                      fontWeight: 500,
                      lineHeight: '30px',
                      letterSpacing: '0.1px',
                      minHeight: '50px',
                      padding: '10px 24px',
                      borderBottom: '1px solid #E7E7E7',
                    }}
                  >
                    Payment Summary
                  </div>
                  <StyledSummaryBodyContainer>
                    {(formProps.values.paymentOptions.includes(PAYMENT_OPTIONS.FREE) ||
                      formProps.values.paymentOptions.includes(PAYMENT_OPTIONS.PER_DAY) ||
                      formProps.values.paymentOptions.includes(PAYMENT_OPTIONS.PER_WEEK) ||
                      formProps.values.paymentOptions.includes(PAYMENT_OPTIONS.PER_MONTH) ||
                      formProps.values.paymentOptions.includes(PAYMENT_OPTIONS.PER_YEAR) ||
                      formProps.values.paymentOptions.includes(PAYMENT_OPTIONS.PACKAGE)) && (
                      <StyledText>The following pricing links will be created for this contribution:</StyledText>
                    )}
                    {formProps.values.paymentOptions.includes(PAYMENT_OPTIONS.FREE) && (
                      <SummaryPricingTextContainer>
                        <StyledHeader className="value-title">Free:</StyledHeader>
                        <StyledText className="value-body">
                          A link will be created for clients to join for free
                        </StyledText>
                      </SummaryPricingTextContainer>
                    )}
                    {formProps.values.paymentOptions.includes(PAYMENT_OPTIONS.PER_MONTH) && (
                      <SummaryPricingTextContainer>
                        {/* <StyledHeader>Per Month Pricing Options:</StyledHeader> */}
                        <StyledHeader className="value-title">Price per month subscription:</StyledHeader>
                        <StyledText className="value-body">
                          {formProps.values.defaultSymbol ? formProps.values.defaultSymbol : '$'}
                          {formatMoney(formProps.values[FORM_FIELDS.PER_MONTH_COST])}
                        </StyledText>
                      </SummaryPricingTextContainer>
                    )}
                    {formProps.values.paymentOptions.includes(PAYMENT_OPTIONS.PER_YEAR) && (
                      <SummaryPricingTextContainer>
                        {/* <StyledHeader>Per Year Pricing Options:</StyledHeader> */}
                        <StyledHeader className="value-title">Price per year subscription:</StyledHeader>
                        <StyledText className="value-body">
                          {formProps.values.defaultSymbol ? formProps.values.defaultSymbol : '$'}
                          {formatMoney(formProps.values[FORM_FIELDS.PER_YEAR_COST])}
                        </StyledText>
                      </SummaryPricingTextContainer>
                    )}
                    {formProps.values.paymentOptions.includes(PAYMENT_OPTIONS.PACKAGE) && (
                      <SummaryPricingTextContainer>
                        {/* <StyledHeader>Package Pricing Options:</StyledHeader> */}
                        <StyledHeader className="value-title">Package price:</StyledHeader>
                        <StyledText className="value-body">
                          {formatPackageSubscriptionPrice(formProps.values)}
                        </StyledText>
                      </SummaryPricingTextContainer>
                    )}
                  </StyledSummaryBodyContainer>
                </div>
              </Grid>
            </Grid>
            {supportPopup && (
              <>
                <Modal
                  CrossIconHide
                  isOpen={supportPopup}
                  widthRequiredIs
                  handleOnCancel
                  disableConfirm
                  onCancel={() => {
                    setSupportPopup(false);
                  }}
                >
                  <p>
                    You are creating contribution on standard stripe account. Please make sure automatic tax calculation
                    is enabled from stripe settings otherwise client&apos;s won&apos;t be able to purchase the
                    contribution.
                  </p>
                </Modal>
              </>
            )}
          </Form>
        );

        return children(formProps, renderCardBody);
      }}
    </Formik>
  );
};

Community.propTypes = {
  contribution: PropTypes.shape(),
  saveToStorageHandler: PropTypes.func.isRequired,
  children: PropTypes.func.isRequired,
};

Community.defaultProps = {
  contribution: null,
};

export default Community;
