const getGalleryParticipantItemWidth = (
  participantsContainerWidth,
  participantsContainerHeight,
  participantsGap,
  totalParticipantsCount,
) => {
  const defaultParticipantWidth = 168;

  let participantItemWidth = defaultParticipantWidth;
  let columnsCount = totalParticipantsCount;
  while (columnsCount > 1) {
    const availableWidth = participantsContainerWidth - (columnsCount - 1) * participantsGap;
    const participantWidth = availableWidth / columnsCount;

    const rowsCount = Math.ceil(totalParticipantsCount / columnsCount);
    const participantHeight = participantWidth / 2;
    const availableHeight = participantsContainerHeight - (rowsCount - 1) * participantsGap;

    const canLayoutWithGivenSize = rowsCount * participantHeight <= availableHeight;
    if (!canLayoutWithGivenSize) {
      return participantItemWidth;
    }

    participantItemWidth = participantWidth;
    columnsCount -= 1;
  }

  return participantItemWidth;
};

export default getGalleryParticipantItemWidth;
