import { SvgIcon } from '@material-ui/core';
import React from 'react';

function GroupIcon({ fillColor = '#C9B382' }) {
  return (
    <SvgIcon width={24} height={24}>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <g clipPath="url(#clip0_18108_176779)">
          <path
            d="M6.91739 15.0093C6.91374 14.972 6.90202 14.9375 6.90202 14.9002V14.5801C6.90202 14.4278 6.92691 14.2776 6.97674 14.1348C6.97674 14.1348 7.77436 13.067 8.15157 11.8123C7.73846 10.9407 7.36346 9.85602 7.36346 8.7808C7.36346 8.53105 7.38178 8.28936 7.40742 8.05128C6.86911 7.48733 6.1418 7.16797 5.33832 7.16797C3.67936 7.16797 2.33321 8.52148 2.33321 10.625C2.33321 12.3081 3.70285 14.1348 3.70285 14.1348C3.75117 14.2776 3.77611 14.4277 3.77611 14.5801V14.9001C3.77611 15.2993 3.54319 15.6626 3.17991 15.8289L1.00533 16.7173C0.499924 16.9502 0.140299 17.4146 0.0399868 17.9609L0.00262746 18.4392C-0.0164038 18.6883 0.0685337 18.9336 0.238456 19.116C0.407627 19.2998 0.645706 19.4031 0.895456 19.4031H4.44989L4.59563 17.5303L4.60514 17.4798C4.78313 16.4976 5.43207 15.6626 6.33877 15.2451L6.91739 15.0093Z"
            fill={fillColor}
          />
          <path
            d="M23.96 17.9609C23.8604 17.4146 23.5008 16.9502 22.9954 16.7173L20.8194 15.8289C20.4561 15.6626 20.2246 15.2993 20.2246 14.9001V14.5801C20.2246 14.4277 20.2488 14.2776 20.2986 14.1348C20.2986 14.1348 21.6675 12.3081 21.6675 10.625C21.6675 8.52148 20.322 7.16797 18.6602 7.16797C17.8582 7.16797 17.1309 7.48733 16.5911 8.05273C16.6174 8.29006 16.6357 8.531 16.6357 8.78075C16.6357 9.85522 16.2615 10.94 15.8491 11.8115C16.2256 13.0662 17.0247 14.1348 17.0247 14.1348C17.073 14.2776 17.0986 14.4277 17.0986 14.5801V14.9001C17.0986 14.9375 17.0862 14.9719 17.0825 15.0093L17.6216 15.229C18.5664 15.6612 19.2153 16.4961 19.3955 17.4783L19.4043 17.5295L19.5493 19.4031H23.1052C23.355 19.4031 23.593 19.2998 23.7615 19.116C23.9314 18.9336 24.0164 18.6883 23.9973 18.4392L23.96 17.9609Z"
            fill={fillColor}
          />
          <path
            d="M17.2439 16.1538L14.6115 15.0779C14.1721 14.8757 13.8916 14.4363 13.8916 13.9543V13.5662C13.8916 13.3831 13.9209 13.2007 13.9817 13.0271C13.9817 13.0271 15.637 10.8181 15.637 8.7805C15.637 6.23458 14.0088 4.59766 11.9998 4.59766C9.99145 4.59766 8.36254 6.23462 8.36254 8.7805C8.36254 10.8181 10.0193 13.0271 10.0193 13.0271C10.0786 13.2007 10.1094 13.3831 10.1094 13.5662V13.9543C10.1094 14.4363 9.82668 14.8757 9.38793 15.0779L6.75562 16.1538C6.14404 16.4343 5.70825 16.9968 5.58815 17.6589L5.45264 19.4028H11.9998H18.5469L18.4129 17.6589C18.2912 16.9968 17.8554 16.4343 17.2439 16.1538Z"
            fill={fillColor}
          />
        </g>
        <defs>
          <clipPath id="clip0_18108_176779">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
}

export default GroupIcon;
