import React, { useEffect, useCallback, useState, useMemo, useRef } from 'react';
import styled, { css } from 'styled-components';
import isEmpty from 'lodash/isEmpty';
import { getIpGlobal } from 'utils/utils';
import { determineColorToUse, getThemedColors } from 'services/contributions.service';
import useContribution from 'pages/ContributionView/hooks/useContribution';
import { Video } from 'components/Video/Video';
import { useAccount, useScreenView } from 'hooks';
import { UserRoles } from 'helpers/constants';
import { lightOrDark } from 'utils/utils';
import VideoButton from 'components/FormUI/Button';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import SelfPacedPdf from './SelfPacedPdf';
import SelfPacedAudio from './SelfPacedAudio';
import SelfPacedVideo from './SelfPacedVideo';
import SelfPacedImage from './SelfPacedImage';
import SelfPacedDocuments from './SelfPacedDocuments';
import { useDispatch, useSelector } from 'react-redux';
import { useCompleteSelfPacedSessionTime } from '../../CourseSessions/ClientCourseSessions/hooks/useCompleteSelfPacedSession';
import { activeContribSessTabViewSettingsSelector } from 'selectors/contribution';
import {
  fetchClientContribution,
  fetchCohealerContribution,
  setSessionTimeEditMode,
  setSessionTimeEditModeData,
} from 'actions/contributions';
import SelfPacedLocked from './SelfPacedLocked';
import moment from 'moment';
import { faListSquares } from '@fortawesome/free-solid-svg-icons';
import { hexToRgba } from 'utils/utils';
import { DarkEnabledPrimaryFilledButton } from '../styled';
import SelfPacedText from './SelfpacedText';
import { CKEditor } from 'ckeditor4-react';
import Button from 'components/FormUI/Button';
import { updateSelfpacedContent, updateSelfpacedContentMoreInfo } from 'services/communicationTab.service ';
const StyledEditorWrapper = styled.div`
  .cke {
    ${({ mainBorder }) =>
      mainBorder
        ? css`
            border: ${mainBorder};
          `
        : css``}
  }
  .cke_chrome {
    border-radius: 0 0 4px 4px;
    /* border-bottom: none; */
  }
  .cke_inner {
    .cke_contents {
      border-radius: 0 0 4px 4px;
    }
    .cke_bottom {
      display: none;
    }
    background: ${({ isDarkThemeEnabled, colorToUse }) =>
      isDarkThemeEnabled ? colorToUse.newThemedCardColor : 'inherit'};
  }

  .cke_top {
    background: ${({ isDarkThemeEnabled, colorToUse }) =>
      isDarkThemeEnabled ? colorToUse.newThemedCardColor : 'inherit'};
  }

  .cke_reset_all * {
    color: ${({ isDarkThemeEnabled, colorToUse }) => (isDarkThemeEnabled ? colorToUse.themedTextColor : 'inherit')};
  }

  .cke_button_icon {
    color: ${({ isDarkThemeEnabled, colorToUse }) => (isDarkThemeEnabled ? colorToUse.themedTextColor : 'inherit')};
    ${({ isDarkThemeEnabled }) =>
      isDarkThemeEnabled &&
      css`
        filter: invert(100%);
      `}
  }

  .cke_button {
    &:hover {
      .cke_button_icon {
        ${({ isDarkThemeEnabled }) =>
          isDarkThemeEnabled &&
          css`
            filter: none;
          `}
      }
    }
  }

  .cke_combo_off a.cke_combo_button:hover {
    background: ${({ isDarkThemeEnabled, colorToUse }) =>
      isDarkThemeEnabled ? colorToUse.newThemedCardColor : 'inherit'};
  }
  .cke_combo_arrow {
    border-top-color: ${({ isDarkThemeEnabled, colorToUse }) =>
      isDarkThemeEnabled ? colorToUse.themedTextColor : 'inherit'};
  }
  .cke_editable {
    background: ${({ isDarkThemeEnabled, colorToUse }) =>
      isDarkThemeEnabled ? colorToUse.newThemedCardColor : 'inherit'};
    color: ${({ isDarkThemeEnabled, colorToUse }) => (isDarkThemeEnabled ? colorToUse.themedTextColor : 'inherit')};
  }
  .cke_bottom {
    /* visibility: hidden; */
    background: ${({ isDarkThemeEnabled, colorToUse }) =>
      isDarkThemeEnabled ? colorToUse.newThemedCardColor : 'inherit'};
  }
`;

const StyledHeader = styled.div`
  display: flex;
  height: 70px;
  padding: 16px 24px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  align-self: stretch;
  border-bottom: 1px solid var(--Cohere-Greys-Grey-Tint, #dfe3e4);
`;

const StyledBody = styled.div`
  padding: 0px 24px;
`;

const StyledTitle = styled.div`
  font-size: 30px;
  font-style: normal;
  font-weight: 800;
  line-height: 27px;
`;

const StyledText = styled.div`
  color: ${({ textColor }) => textColor || 'rgba(0, 0, 0, 0.87)'};
  // text-align: ${({ isCenterColumnFullScreenMode }) => (isCenterColumnFullScreenMode ? 'start' : 'center')};
  font-family: Avenir;
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem; /* 133.333% */

  width: 100%;
  word-break: break-word;
  outline: none;
`;

const StyledFooter = styled.div`
  display: flex;
  justify-content: center;
  bottom: 0;
  /* background-color: ${({ backgroundColor }) => backgroundColor || '#fff'}; */
  ${({ isDarkModeEnabled, backgroundColor, mobileView }) =>
    mobileView
      ? css`
          background: ${isDarkModeEnabled ? backgroundColor : '#ffffff'};
          box-shadow: 0px -1px 5.3px 0px ${isDarkModeEnabled ? 'rgba(0, 0, 0, 0.2)' : 'rgba(255, 255, 255, 0.1)'};
        `
      : css`
          background: ${isDarkModeEnabled
            ? `linear-gradient(to bottom, rgba(${hexToRgba(backgroundColor, 0.0)}) -100%, rgba(${hexToRgba(
                backgroundColor,
                1,
              )}) 50%)`
            : `linear-gradient(to bottom, rgba(${hexToRgba('#ffffff', 0.0)}) -100%, rgba(${hexToRgba(
                '#ffffff',
                1,
              )}) 50%)`};
        `};
  /* background: ${({ backgroundColor }) =>
    `linear-gradient(to bottom, rgba(${hexToRgba(backgroundColor, 0.0)}) -100%, rgba(${hexToRgba(
      backgroundColor,
      1,
    )}) 50%)`}; */
  text-align: center;
  /* width: 100%; */

  /* Below are for footer fixed */
  position: fixed;
  padding: ${({ mobileView }) => (mobileView ? '0.625rem 0' : '2rem 0')};
  z-index: 50;
  margin-left: -1.5rem;
  /* width: ${({ rightColCollapsed }) =>
    rightColCollapsed ? 'calc(100% - 4rem - 350px - 40px)' : 'calc(100% - 295px - 350px - 48px)'}; */
  width: ${({ rightColCollapsed }) => `calc(100% - 350px - ${rightColCollapsed ? '4rem' : '295px'} - 17px)`};
  /* width: calc(100% - 295px - 350px - 40px); */
  ${({ mobileView }) =>
    mobileView &&
    css`
      width: 100%;
    `}
`;

const SelfPacedSessionBody = ({
  session,
  sessionTime,
  sessionIndex,
  sessionTimeIndex,
  rightColCollapsed = false,
  selectNextSession,
}) => {
  const { isCenterColumnFullScreenMode } = useSelector(activeContribSessTabViewSettingsSelector);
  const { isPrerecorded } = session;
  const [isViewDocument, setIsViewDocument] = useState(false);
  const [buttonState, setButtonState] = useState(false);
  const [shouldVideoBeDisabled, setShouldVideoBeDisabled] = useState(false);
  const sessionTimeEditModeData = useSelector(state => state?.contributions?.editModeData);
  const [sessionMoreInfoData, setSessionMoreInfoData] = useState(sessionTime?.moreInfo);
  const [sessionTextHtmlData, setsessionTextHtmlData] = useState(sessionTime?.selfPacedContentAsHtml);
  const [disableSelfPaced, setDisableSelfPaced] = useState(false);
  const [disableSelfPacedMoreInfo, setDisableSelfPacedMoreInfo] = useState(false);
  const [isFuture, setIsFuture] = useState(false);
  const { currentRole, user } = useAccount();
  const contribution = useContribution();
  const { id, sessions, isDarkModeEnabled, isPurchased } = contribution;
  const [showCkEditor, setShowCkEditor] = useState(null);
  const [showCkEditorMoreInfo, setShowCkEditorMoreInfo] = useState(null);
  const isCoach = currentRole === UserRoles.cohealer;
  let colorToUse = determineColorToUse(contribution);
  const textColor =
    colorToUse?.TextColorCode === 'Auto'
      ? lightOrDark(colorToUse?.PrimaryColorCode)
      : colorToUse?.TextColorCode === '#000000'
      ? '#000000'
      : '#FFFFFF';
  const dispatch = useDispatch();
  const { mobileView } = useScreenView();
  const refreshContribution = useCallback(async () => {
    const userIp = await getIpGlobal();
    dispatch(fetchClientContribution(id, userIp));
  }, [dispatch, id]);
  const { Popup, isOpen, onShowPopup } = useCompleteSelfPacedSessionTime(refreshContribution, selectNextSession);
  const {
    themedCardBackgroundColor: oldThemedCardBackgroundColor,
    themedBackgroundColor,
    themedColor,
    newThemedTextColor,
  } = getThemedColors(contribution);
  const { themedColor: themedTextColor, newThemedCardColor } = getThemedColors(contribution);
  const ref = useRef();
  const extension = sessionTime?.prerecordedSession?.extension?.toLowerCase() || '';
  const isLockSession = sessionTime?.mustWatchPriorSelfPacedRecords;
  const handleEditorChange = event => {
    setDisableSelfPacedMoreInfo(false);
    setSessionMoreInfoData(event.editor.getData());
  };

  const handleEditorChangeSelfpaced = event => {
    setDisableSelfPaced(false);
    setsessionTextHtmlData(event.editor.getData());
  };

  const generateHTMLText = htmlText => {
    // Function to highlight the <a> tag
    const highlightAnchorTag = html => {
      return html.replace(
        /<a([^>]*)>(.*?)<\/a>/gi,
        `<span style="color: ${colorToUse?.AccentColorCode};"><a$1>$2</a></span>`,
      );
    };

    // Check if htmlText exists
    if (!htmlText) return '';

    // Highlight <a> tag
    const highlightedHtml = highlightAnchorTag(htmlText);

    return highlightedHtml;
  };

  const toolbarConfig = useMemo(
    () => [
      { name: 'insert', items: ['Image'] },
      {
        name: 'paragraph',
        items: ['Blockquote', 'CreateDiv', '-', 'BidiLtr', 'BidiRtl', 'Language'],
      },
      { name: 'undo', items: ['Undo', 'Redo'] },
      { name: 'styles', items: ['Font', 'FontSize', '-'] },
      { name: 'alignment', items: ['JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock'] },
      { name: 'indent', items: ['Indent', 'Outdent'] },
      { name: 'colors', items: ['TextColor', 'BGColor'] },
      { name: 'lists', items: ['NumberedList', 'BulletedList'] },
      { name: 'basicstyles', items: ['Bold', 'Italic', 'Underline', 'Strike'] },
      { name: 'links', items: ['Link', 'Unlink'] },
      { name: 'expand', items: ['Maximize'] },
    ],
    [],
  );

  const toolbarConfigMoreInfo = useMemo(
    () => [
      { name: 'undo', items: ['Undo', 'Redo', 'SelectAll'] },
      { name: 'styles', items: ['Styles'] },
      { name: 'format', items: ['Format'] },
      { name: 'font', items: ['Font'] },
      { name: 'fontSize', items: ['FontSize'] },
      { name: 'alignment', items: ['JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock'] },
      { name: 'indent', items: ['Indent', 'Outdent'] },
      { name: 'colors', items: ['TextColor', 'BGColor'] },
      { name: 'list', items: ['NumberedList', 'BulletedList'] },
      // { name: 'insert', items: ['Image'] },
      {
        name: 'basicstyles',
        items: ['Bold', 'Italic', 'Underline', 'Code', 'Strike'],
      },
      { name: 'link', items: ['Link', 'Unlink'] },
    ],
    [],
  );
  const checkIsFuture = date => {
    return moment(date).isAfter(moment(), 'day');
  };

  const checkCompleteBtnStatus = () => {
    if (checkIsFuture(sessionTime?.startTime) && sessionTime.ignoreDateAvailable) {
      setButtonState(true);
    } else if (sessionTime?.completedSelfPacedParticipantIds?.includes(user.id)) {
      setButtonState(true);
    } else if (
      sessionTime?.prerecordedSession?.extension?.toLowerCase()?.includes('jpg') ||
      sessionTime?.prerecordedSession?.extension?.toLowerCase()?.includes('png') ||
      sessionTime?.prerecordedSession?.extension?.toLowerCase()?.includes('jpeg') ||
      sessionTime?.prerecordedSession?.extension?.toLowerCase()?.includes('pdf') ||
      sessionTime?.prerecordedSession?.extension?.toLowerCase()?.includes('doc') ||
      sessionTime?.prerecordedSession?.extension?.toLowerCase()?.includes('txt') ||
      sessionTime?.prerecordedSession?.extension?.toLowerCase()?.includes('xls') ||
      sessionTime?.prerecordedSession?.extension?.toLowerCase()?.includes('xlsx')
    ) {
      setButtonState(false);
    } else {
      if (sessionTime?.usersWhoViewedRecording?.includes(user?.id)) {
        setButtonState(false);
      } else {
        setButtonState(true);
      }
    }
  };

  const isVideoViewable = () => {
    if (
      sessionTime?.prerecordedSession?.extension?.toLowerCase()?.includes('jpg') ||
      sessionTime?.prerecordedSession?.extension?.toLowerCase()?.includes('png') ||
      sessionTime?.prerecordedSession?.extension?.toLowerCase()?.includes('jpeg') ||
      sessionTime?.prerecordedSession?.extension?.toLowerCase()?.includes('pdf') ||
      sessionTime?.prerecordedSession?.extension?.toLowerCase()?.includes('doc') ||
      sessionTime?.prerecordedSession?.extension?.toLowerCase()?.includes('txt') ||
      sessionTime?.prerecordedSession?.extension?.toLowerCase()?.includes('xls') ||
      sessionTime?.prerecordedSession?.extension?.toLowerCase()?.includes('xlsx')
    ) {
      if (sessionTime?.ignoreDateAvailable) {
        if (checkIsFuture(sessionTime?.startTime)) {
          setIsFuture(true);
          setShouldVideoBeDisabled(true);
        } else {
          setIsFuture(false);
          setShouldVideoBeDisabled(false);
        }
      } else {
        setShouldVideoBeDisabled(false);
      }
    } else {
      if (sessionTime?.mustWatchPriorSelfPacedRecords) {
        if (sessionTime?.ignoreDateAvailable) {
          if (checkIsFuture(sessionTime?.startTime)) {
            setIsFuture(true);
            setShouldVideoBeDisabled(true);
          } else {
            setIsFuture(false);
            setShouldVideoBeDisabled(false);
          }
        }
        if (session.sessionTimes.length > 1 && sessionTimeIndex > 0) {
          if (!session.sessionTimes[sessionTimeIndex - 1]?.usersWhoViewedRecording?.includes(user.id)) {
            setShouldVideoBeDisabled(true);
          } else {
            if (!(sessionTime?.ignoreDateAvailable && checkIsFuture(sessionTime?.startTime)))
              setShouldVideoBeDisabled(false);
          }
        } else {
          if (sessionIndex > 0) {
            for (let i = sessionIndex - 1; i >= 0; i--) {
              if (sessions[i].isPrerecorded) {
                if (
                  !sessions[i]?.sessionTimes[sessions[i]?.sessionTimes.length - 1]?.usersWhoViewedRecording?.includes(
                    user.id,
                  )
                ) {
                  setShouldVideoBeDisabled(true);
                } else {
                  if (shouldVideoBeDisabled && !checkIsFuture(sessionTime?.startTime)) {
                    setShouldVideoBeDisabled(false);
                  } else if (
                    sessions[i]?.sessionTimes[sessions[i]?.sessionTimes.length - 1]?.usersWhoViewedRecording?.includes(
                      user.id,
                    ) &&
                    sessionTime?.ignoreDateAvailable &&
                    !checkIsFuture(sessionTime?.startTime)
                  ) {
                    setShouldVideoBeDisabled(false);
                  }
                }
                break;
              }
            }
          } else {
            setShouldVideoBeDisabled(false);
          }
        }
      } else if (sessionTime?.ignoreDateAvailable === true && checkIsFuture(sessionTime?.startTime)) {
        setIsFuture(true);
        setShouldVideoBeDisabled(true);
      } else {
        setShouldVideoBeDisabled(false);
        setIsFuture(false);
      }
    }
  };

  const refetch = () => {
    isVideoViewable();
    checkCompleteBtnStatus();
  };
  useEffect(() => {
    if (!isCoach && !isCenterColumnFullScreenMode) {
      isVideoViewable();
      checkCompleteBtnStatus();
    }
    setsessionTextHtmlData(sessionTime?.selfPacedContentAsHtml);
  }, [session, sessionTime, sessionIndex]);

  const renderMedia = () => {
    if (sessionTime?.embeddedVideoUrl) {
      return <SelfPacedVideo {...{ session, sessionTime, sessionIndex, sessionTimeIndex }} />; // for embed video, audio, link
    }
    if (isEmpty(extension)) {
      if (sessionTime?.selfPacedContentType?.includes('Text')) {
        if (shouldVideoBeDisabled) {
          return <SelfPacedLocked colorToUse={colorToUse} isFuture={isFuture} date={sessionTime?.startTime} />;
        } else {
          if (isCoach) {
            return (
              <>
                {sessionTextHtmlData != null && (
                  <StyledEditorWrapper
                    colorToUse={{
                      ...colorToUse,
                      themedColor,
                      themedBackgroundColor,
                      newThemedTextColor,
                      themedBackgroundColor,
                    }}
                    isDarkThemeEnabled={isDarkModeEnabled}
                  >
                    {showCkEditor === null || showCkEditor === 'moreInfo' ? (
                      <>
                        <StyledText
                          style={{ cursor: 'pointer', marginTop: '20px' }}
                          onClick={() => {
                            setShowCkEditor('textData');
                          }}
                          textColor={contribution?.isDarkModeEnabled ? themedColor : newThemedTextColor}
                          {...{ isCenterColumnFullScreenMode }}
                          dangerouslySetInnerHTML={{ __html: generateHTMLText(sessionTime?.selfPacedContentAsHtml) }}
                        />
                      </>
                    ) : (
                      showCkEditor === 'textData' && (
                        <StyledEditorWrapper
                          colorToUse={{
                            ...colorToUse,
                            themedTextColor,
                            newThemedCardColor,
                            newThemedTextColor,
                            oldThemedCardBackgroundColor,
                          }}
                          isDarkThemeEnabled={isDarkModeEnabled}
                        >
                          <CKEditor
                            ref={ref}
                            config={{
                              allowedContent: true,
                              height: '350px',
                              toolbar: toolbarConfig,
                              pasteImage: true,
                              extraPlugins: [
                                'font',
                                'colorbutton',
                                'justify',
                                'colordialog',
                                'uploadimage',
                                'filebrowser',
                                'image2',
                                'selectall',
                              ],
                            }}
                            initData={sessionTextHtmlData}
                            onChange={handleEditorChangeSelfpaced}
                          />
                        </StyledEditorWrapper>
                      )
                    )}
                  </StyledEditorWrapper>
                )}
                {showCkEditor === 'textData' && (
                  <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', margin: '10px' }}>
                    <Button
                      onClick={() => {
                        setShowCkEditor(null);
                        setsessionTextHtmlData(null);
                        setTimeout(() => {
                          setsessionTextHtmlData(sessionTime?.selfPacedContentAsHtml);
                        }, 1000);
                      }}
                      style={{ marginRight: '10px' }}
                      autoWidth
                      invert
                    >
                      Cancel
                    </Button>
                    <Button
                      disabled={sessionTextHtmlData === sessionTime?.selfPacedContentAsHtml || disableSelfPaced}
                      onClick={async () => {
                        await updateSelfpacedContent(
                          contribution.id,
                          sessionTime.id,
                          sessionTextHtmlData,
                          sessionTime?.moreInfo,
                        ).then(() => {
                          dispatch(fetchCohealerContribution(contribution.id));
                          setDisableSelfPaced(true);
                        });
                      }}
                      textColor={textColor}
                      backgroundColor={colorToUse.PrimaryColorCode}
                      autoWidth
                    >
                      Save
                    </Button>
                  </div>
                )}
              </>
            );
          } else {
            if (shouldVideoBeDisabled) {
              return <SelfPacedLocked colorToUse={colorToUse} isFuture={isFuture} date={sessionTime?.startTime} />;
            } else {
              return (
                <SelfPacedText
                  {...{ session, sessionTime, sessionTimeIndex, colorToUse, textColor, refetch, shouldVideoBeDisabled }}
                />
              );
            }
          }
        }
      } else {
        return <></>;
      }
    }
    if (shouldVideoBeDisabled) {
      return <SelfPacedLocked colorToUse={colorToUse} isFuture={isFuture} date={sessionTime?.startTime} />;
    }
    if (extension.includes('mp4') || extension.includes('webm') || extension.includes('mov')) {
      return <SelfPacedVideo {...{ session, sessionTime, sessionIndex, sessionTimeIndex, refetch }} />;
    }
    if (extension.includes('pdf')) {
      return <SelfPacedPdf {...{ session, sessionTime, sessionTimeIndex, colorToUse, textColor }} />;
    }
    if (
      extension.includes('mp3') ||
      extension.includes('wav') ||
      extension.includes('m4a') ||
      extension.includes('ogg')
    ) {
      return <SelfPacedAudio {...{ session, sessionTime, sessionTimeIndex, colorToUse, textColor, isCoach }} />;
    }
    if (extension.includes('png') || extension.includes('jpg') || extension.includes('jpeg')) {
      return <SelfPacedImage {...{ session, sessionTime, sessionTimeIndex }} />;
    }
    if (
      extension.includes('doc') ||
      extension.includes('docx') ||
      extension.includes('xls') ||
      extension.includes('xlsx') ||
      extension.includes('txt')
    ) {
      return <SelfPacedDocuments {...{ session, sessionTime, sessionTimeIndex, colorToUse, textColor }} />;
    }
    return (
      <Video
        style={{ width: '100%' }}
        isPreviewMode
        isSessionView
        session={session}
        sessionIndex={sessionIndex}
        sessionTime={sessionTime}
        sessionTimeIndex={sessionTimeIndex}
      />
    );
  };
  return (
    <div
      style={{
        width: '100%',
        position: 'relative',
      }}
    >
      {renderMedia()}
      {(isCoach || !shouldVideoBeDisabled) &&
        !isEmpty(sessionTime?.moreInfo) &&
        (isCoach ? (
          <>
            <div>
              {/* {sessionTime?.selfPacedContentType?.includes('Text') && (
                <div
                  style={{
                    backgroundColor: isDarkModeEnabled ? 'black' : 'rgb(250, 250, 250)',
                    height: isDarkModeEnabled ? '0px' : '30px',
                  }}
                />
              )} */}
              <div
                style={{
                  backgroundColor: themedBackgroundColor,
                  color: themedColor,
                  padding: '10px',
                  fontFamily: 'Avenir',
                  fontWeight: '900',
                  fontSize: '16px',
                }}
              >
                About this content
              </div>
              <div style={{ marginBottom: '20px' }}>
                {sessionMoreInfoData != null && (
                  <StyledEditorWrapper
                    colorToUse={{
                      ...colorToUse,
                      themedColor,
                      themedBackgroundColor,
                      newThemedTextColor,
                      themedBackgroundColor,
                    }}
                    isDarkThemeEnabled={isDarkModeEnabled}
                  >
                    {showCkEditorMoreInfo === null || showCkEditorMoreInfo != 'moreInfo' ? (
                      <>
                        <StyledText
                          style={{ cursor: 'pointer', marginTop: '20px' }}
                          onClick={() => {
                            setShowCkEditorMoreInfo('moreInfo');
                          }}
                          textColor={contribution?.isDarkModeEnabled ? themedColor : newThemedTextColor}
                          {...{ isCenterColumnFullScreenMode }}
                          dangerouslySetInnerHTML={{ __html: generateHTMLText(sessionTime?.moreInfo) }}
                        />
                      </>
                    ) : (
                      showCkEditorMoreInfo === 'moreInfo' && (
                        <StyledEditorWrapper
                          colorToUse={{
                            ...colorToUse,
                            themedTextColor,
                            newThemedCardColor,
                            newThemedTextColor,
                            oldThemedCardBackgroundColor,
                          }}
                          isDarkThemeEnabled={isDarkModeEnabled}
                        >
                          <CKEditor
                            ref={ref}
                            config={{
                              allowedContent: true,
                              height: '200px',
                              toolbar: toolbarConfigMoreInfo,
                              pasteImage: true,
                              extraPlugins: [
                                'font',
                                'colorbutton',
                                'justify',
                                'colordialog',
                                'uploadimage',
                                'filebrowser',
                                'image2',
                                'selectall',
                              ],
                            }}
                            initData={sessionMoreInfoData}
                            onChange={handleEditorChange}
                          />
                        </StyledEditorWrapper>
                      )
                    )}
                  </StyledEditorWrapper>
                )}
              </div>
              {showCkEditorMoreInfo === 'moreInfo' && (
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', marginRight: '10px' }}>
                  <Button
                    onClick={() => {
                      setShowCkEditorMoreInfo(null);
                      setSessionMoreInfoData(null);
                      setTimeout(() => {
                        setSessionMoreInfoData(sessionTime?.moreInfo);
                      }, 1000);
                    }}
                    style={{ marginRight: '10px' }}
                    autoWidth
                    invert
                  >
                    Cancel
                  </Button>
                  <Button
                    disabled={sessionMoreInfoData === sessionTime?.moreInfo || disableSelfPacedMoreInfo}
                    onClick={async () => {
                      await updateSelfpacedContentMoreInfo(
                        contribution.id,
                        sessionTime.id,
                        sessionMoreInfoData,
                        sessionTime?.selfPacedContentAsHtml,
                      ).then(() => {
                        dispatch(fetchCohealerContribution(contribution.id));
                        setDisableSelfPacedMoreInfo(true);
                      });
                    }}
                    textColor={textColor}
                    backgroundColor={colorToUse.PrimaryColorCode}
                    autoWidth
                  >
                    Save
                  </Button>
                </div>
              )}
            </div>
          </>
        ) : (
          <div>
            <div
              style={{
                backgroundColor: themedBackgroundColor,
                color: themedColor,
                padding: '10px',
                fontFamily: 'Avenir',
                fontWeight: '900',
                fontSize: '16px',
              }}
            >
              About this content
            </div>
            <StyledText
              style={{ marginTop: '20px' }}
              textColor={contribution?.isDarkModeEnabled ? themedColor : newThemedTextColor}
              {...{ isCenterColumnFullScreenMode }}
              dangerouslySetInnerHTML={{ __html: generateHTMLText(sessionTime?.moreInfo) }}
            />
          </div>
        ))}
      {!isCoach && !isEmpty(user) && !isCenterColumnFullScreenMode && isPurchased && (
        <>
          <StyledFooter
            {...{ rightColCollapsed, isDarkModeEnabled, backgroundColor: themedBackgroundColor, mobileView }}
          >
            <DarkEnabledPrimaryFilledButton
              textColor={
                textColor
                // contribution?.isDarkModeEnabled ? themedColor : textColor
              }
              backgroundColor={
                colorToUse?.PrimaryColorCode
                // contribution?.isDarkModeEnabled
                //   ? colorToUse?.PrimaryColorCode
                //   : 'var(--Cohere-Greys-Grey-Tint, #DFE3E4)'
              }
              autoWidth
              onClick={() => {
                onShowPopup({
                  contributionId: contribution.id,
                  sessionTimeId: sessionTime.id,
                  sessionId: session.id,
                });
              }}
              disabled={buttonState || shouldVideoBeDisabled}
              {...{ isDarkModeEnabled: contribution?.isDarkModeEnabled }}
            >
              Complete & Continue&nbsp;
              <ArrowForwardIcon style={{ height: '1rem', width: '1rem' }} />
            </DarkEnabledPrimaryFilledButton>
          </StyledFooter>
          {isPrerecorded && <Popup applyTheming />}
        </>
      )}
    </div>
  );
};

export default SelfPacedSessionBody;
