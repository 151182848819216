import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import * as R from 'ramda';

import { useShallowEqualSelector } from 'hooks';
import { ContributionType } from 'helpers/constants';
import { useTheme, useMediaQuery } from '@material-ui/core';

import './Services.scss';

const TITLES = {
  [ContributionType.contributionCourse]: 'Group Courses',
  [ContributionType.contributionOneToOne]: '1:1 Sessions',
};

const Services = () => {
  const {
    contributionInfos = [
      {
        type: ContributionType.contributionCourse,
        previewContentUrls: [],
      },
    ],
  } = useShallowEqualSelector(state => state?.cohealerInfo?.info);

  const memoizedContributionInfos = useMemo(() => R.groupBy(({ type }) => type, contributionInfos || []), [
    contributionInfos,
  ]);

  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'));
  const tabletView = useMediaQuery(theme.breakpoints.down('sm'));
  let itemClasses = 'w-25';
  if (tabletView) {
    itemClasses = mobileView ? 'w-100 service-item' : 'w-50';
  }

  return (
    <div className="bg-snow services-provider">
      {Object.entries(memoizedContributionInfos).map(([contributionType, item]) => (
        <div key={contributionType} className="mb-4">
          <div className="h4 py-2">{TITLES[contributionType]}</div>
          <GridList>
            {item.map(({ id, title, previewContentUrls }) => (
              <GridListTile
                key={id}
                classes={{
                  root: `${itemClasses} cursor-pointer`,
                }}
                component={Link}
                to={`/contribution-view/${id}/about`}
              >
                <img src={R.head(previewContentUrls)} alt={title} />
                <GridListTileBar
                  classes={{
                    root: 'tile-bar-root',
                  }}
                  title={
                    <div className="d-flex justify-content-between">
                      <span>{title}</span>
                    </div>
                  }
                />
              </GridListTile>
            ))}
          </GridList>
        </div>
      ))}
    </div>
  );
};

export default Services;
