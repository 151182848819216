import React from 'react';
import PropTypes from 'prop-types';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import './TimePickerField.scss';

function TimePickerField(props) {
  const { onChange, availabilityModal, ...restProps } = props;

  return (
    <TimePicker
      className={availabilityModal ? 'time-picker-availability-modal' : 'time-picker'}
      style={{ fontFamily: 'Brandon Text' }}
      onChange={onChange}
      popupClassName={`time-picker__pop-up ${availabilityModal ? 'position-fixed' : ''}`}
      onFocus={e => {
        e.target.blur();
      }}
      {...restProps}
    />
  );
}

TimePickerField.propTypes = {
  onChange: PropTypes.func.isRequired,
};

TimePickerField.defaultProps = {};

export default TimePickerField;
