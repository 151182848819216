import React from 'react';
import PropTypes from 'prop-types';
import ListItem from '@material-ui/core/ListItem';
import { Avatar, CircularProgress } from '@material-ui/core';
import ListItemText from '@material-ui/core/ListItemText';
import useContribution from 'pages/ContributionView/hooks/useContribution';
import { getThemedColors } from 'services/contributions.service';

const UserMentionSuggestion = ({ user }) => {
  const contribution = useContribution();

  const { themedCardBackgroundColor } = getThemedColors(contribution);

  return (
    <ListItem
      style={{ backgroundColor: themedCardBackgroundColor, borderColor: themedCardBackgroundColor }}
      key={user.id}
    >
      {user.display === 'Loading participants...' ? (
        <CircularProgress size={'20px'} color="primary" />
      ) : (
        <Avatar style={{ width: '1.75rem', height: '1.75rem', marginRight: '0.5rem' }} src={user.imageUrl} />
      )}
      {user.display != 'Loading participants...' && <ListItemText primary={user.display} />}
    </ListItem>
  );
};

UserMentionSuggestion.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string,
    display: PropTypes.string,
    imageUrl: PropTypes.string,
  }).isRequired,
};

export default UserMentionSuggestion;
