import useRouter from 'hooks/useRouter';
import React from 'react';
import { useSelector } from 'react-redux';

const useCampaign = () => {
  const { location } = useRouter();
  const userContacts = useSelector(state => state?.campaigns?.userContacts);
  const campaign = useSelector(state => state?.campaigns?.activeCampaign);
  const campaignNotification = useSelector(state => state?.campaigns?.campaignNotification);
  const campaignsCollection = useSelector(state => state?.campaigns?.campaignsCollection);
  const isLoading = useSelector(state => state?.campaigns?.isLoading);
  const sendEmailTo = useSelector(state => state?.campaigns?.sendEmailTo);
  const isCreateCampagnPage = location?.pathname?.includes('create-campaign');
  return {
    campaign,
    campaignsCollection,
    isLoading,
    isCreateCampagnPage,
    campaignNotification,
    userContacts,
    sendEmailTo,
  };
};

export default useCampaign;
