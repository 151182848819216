import { useCallback, useEffect, useState } from 'react';

const initialState = {
  initialValue: '',
  isEditPodName: false,
  isOpenModalDelete: false,
  isOpenModalUpdate: false,
  podId: '',
  value: '',
};

export const useLocalState = ({ initialValue }) => {
  const [state, updateState] = useState(initialState);

  const setState = useCallback(nextState => updateState(prevState => ({ ...prevState, ...nextState })), []);

  useEffect(() => setState({ value: initialValue }), [initialValue, setState]);

  return [
    {
      ...state,
      initialValue,
    },
    setState,
  ];
};
