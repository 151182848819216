import React from 'react';

import { UserRoles } from 'helpers/constants';
import Can from 'components/Auth/Can';
import CohealerDashboard from './components/CohealerDashboard';
import ClientDashboard from './components/ClientDashboard';

function DashboardPage() {
  return (
    <>
      <Can roleName={UserRoles.cohealer} yes={() => <CohealerDashboard />} />
      <Can roleName={UserRoles.client} yes={() => <ClientDashboard />} />
    </>
  );
}

export default DashboardPage;
