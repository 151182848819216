import { useState, useEffect } from 'react';

import ChatClientConnectionStatus from 'components/App/ChatProvider/ChatClientConnectionStatus';
import useChatClient from './useChatClient';
import ChatClientChannelConnectionStatus from './ChatClientChannelConnectionStatus';
import ChatClientChannelConnectionError from './ChatClientChannelConnectionError';

const useChatClientChannel = (channelSid, chatReload = false) => {
  const { connectionStatus: chatClientConnectionStatus, chatClient } = useChatClient();

  const [connectionStatus, setConnectionStatus] = useState(ChatClientChannelConnectionStatus.pending);
  const [channel, setChannel] = useState(null);
  const [error, setError] = useState(null);
  useEffect(() => {
    (async function getChannelAsync() {
      if (chatClientConnectionStatus === ChatClientConnectionStatus.connectionError) {
        setError(ChatClientChannelConnectionError.chatClientDisabled);
        setConnectionStatus(ChatClientChannelConnectionStatus.connectionError);
        setChannel(null);

        return;
      }
      if (chatClientConnectionStatus !== ChatClientConnectionStatus.connected) {
        return;
      }

      try {
        setError(null);
        setConnectionStatus(ChatClientChannelConnectionStatus.connecting);

        const chatClientChannel = await chatClient.getChannelBySid(channelSid);

        setChannel(chatClientChannel);
        setConnectionStatus(ChatClientChannelConnectionStatus.connected);
      } catch {
        setError(ChatClientChannelConnectionError.unknown);
        setConnectionStatus(ChatClientChannelConnectionStatus.connectionError);
        setChannel(null);
      }
    })();
  }, [channelSid, chatClientConnectionStatus, chatClient, chatReload]);
  useEffect(() => {
    if (channel) {
      const onChannelUpdated = ({ channel: updatedChannel }) => setChannel(updatedChannel);

      channel.on('updated', onChannelUpdated);

      return () => {
        channel.off('updated', onChannelUpdated);
      };
    }
  }, [channel]);

  return {
    connectionStatus,
    channel,
    error,
  };
};

export default useChatClientChannel;
