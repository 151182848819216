import { isEmpty } from 'lodash';
import moment from 'moment';
import { TypeNames } from './constants';

const getFilteredContactsByTags = (contacts = [], filters = {}) => {
  return contacts.filter(contact => contact.tags && contact.tags.some(tag => filters.tags.includes(tag.id)));
};

const getFilteredContactsByContrib = (contacts = [], filters = {}) => {
  return contacts.filter(contact => filters?.contributions?.includes(contact?.recenetContributionId));
};

const getFilteredContactsByFromDate = (contacts = [], fromDate) => {
  return contacts.filter(contact => moment(contact?.joinDate).isSameOrAfter(fromDate));
};

const getFilteredContactsByToDate = (contacts = [], toDate) => {
  return contacts.filter(contact => moment(contact?.joinDate).isSameOrBefore(toDate));
};

const getFilteredContactsByType = (contacts = [], filters = {}) => {
  //if only lead option selected
  if (filters?.type.length === 1 && filters?.type.includes(TypeNames.Lead)) {
    return contacts.filter(contact => contact.isLead);
  }

  //if only client option selected
  if (filters?.type.length === 1 && filters?.type.includes(TypeNames.Client)) {
    return contacts.filter(contact => !contact.isLead);
  }

  //if both client && lead options selected
  if (
    filters?.type.length === 2 &&
    filters?.type.includes(TypeNames.Client) &&
    filters?.type.includes(TypeNames.Lead)
  ) {
    return contacts;
  }
};

const getFilteredData = (data = [], filters = {}) => {
  const fromDate = moment(filters?.fromDate);
  const toDate = moment(filters?.toDate);
  let fetchedToDate = toDate.set({
    year: toDate.year(),
    month: toDate.month(),
    date: toDate.date(),
    hour: 23,
    minute: 59,
    second: 0,
  });
  let fetchedFromDate = fromDate.set({
    year: fromDate.year(),
    month: fromDate.month(),
    date: fromDate.date(),
    hour: 0,
    minute: 0,
    second: 0,
  });

  //Case#1: If only contributions selected in filters
  if (
    filters?.contributions.length > 0 &&
    filters?.tags.length === 0 &&
    filters?.toDate === null &&
    filters?.fromDate === null &&
    filters?.type.length === 0
  ) {
    return getFilteredContactsByContrib(data, filters);
  }

  //Case#2: If only tags selected in filters
  if (
    filters?.tags.length > 0 &&
    filters?.contributions.length === 0 &&
    filters?.toDate === null &&
    filters?.fromDate === null &&
    filters?.type.length === 0
  ) {
    return getFilteredContactsByTags(data, filters);
  }

  //Case#3: If only fromDate selected in filters
  if (
    filters?.tags.length === 0 &&
    filters?.contributions.length === 0 &&
    filters?.toDate === null &&
    filters?.fromDate != null &&
    filters?.type.length === 0
  ) {
    return getFilteredContactsByFromDate(data, fetchedFromDate);
  }

  //Case#4: If only toDate selected in filters
  if (
    filters?.tags.length === 0 &&
    filters?.contributions.length === 0 &&
    filters?.toDate != null &&
    filters?.fromDate === null &&
    filters?.type.length === 0
  ) {
    return getFilteredContactsByToDate(data, fetchedToDate);
  }

  //Case#5: If only type selected in filters
  if (
    filters?.tags.length === 0 &&
    filters?.contributions.length === 0 &&
    filters?.toDate === null &&
    filters?.fromDate === null &&
    filters?.type.length > 0
  ) {
    return getFilteredContactsByType(data, filters);
  }

  //Case#6: If only fromDate && toDate selected in filters
  if (
    filters?.tags.length === 0 &&
    filters?.contributions.length === 0 &&
    filters?.toDate != null &&
    filters?.fromDate != null &&
    filters?.type.length === 0
  ) {
    const filteredByFromDate = getFilteredContactsByFromDate(data, fetchedFromDate);
    const filteredByFromAndToDate = getFilteredContactsByToDate(filteredByFromDate, fetchedToDate);
    return filteredByFromAndToDate;
  }

  //Case#7: If only fromDate && contributions selected in filters
  if (
    filters?.contributions.length > 0 &&
    filters?.toDate === null &&
    filters?.fromDate != null &&
    filters?.tags.length === 0 &&
    filters?.type.length === 0
  ) {
    const filteredByFromDate = getFilteredContactsByFromDate(data, fetchedFromDate);
    return getFilteredContactsByContrib(filteredByFromDate, filters);
  }

  //Case#8: If only fromDate && tags selected in filters
  if (
    filters?.contributions.length === 0 &&
    filters?.toDate === null &&
    filters?.fromDate != null &&
    filters?.tags.length > 0 &&
    filters?.type.length === 0
  ) {
    const filteredByFromDate = getFilteredContactsByFromDate(data, fetchedFromDate);
    return getFilteredContactsByTags(filteredByFromDate, filters);
  }

  //Case#9: If only fromDate && type selected in filters
  if (
    filters?.contributions.length === 0 &&
    filters?.toDate === null &&
    filters?.fromDate != null &&
    filters?.tags.length === 0 &&
    filters?.type.length > 0
  ) {
    const filteredByFromDate = getFilteredContactsByFromDate(data, fetchedFromDate);
    return getFilteredContactsByType(filteredByFromDate, filters);
  }

  //Case#10: If only toDate && contributions are selected in filters
  if (
    filters?.contributions.length > 0 &&
    filters?.toDate != null &&
    filters?.fromDate === null &&
    filters?.tags.length === 0 &&
    filters?.type.length === 0
  ) {
    const filteredBytoDate = getFilteredContactsByToDate(data, fetchedToDate);
    return getFilteredContactsByContrib(filteredBytoDate, filters);
  }

  //Case#11: If only toDate && tags are selected in filters
  if (
    filters?.contributions.length === 0 &&
    filters?.toDate != null &&
    filters?.fromDate === null &&
    filters?.tags.length > 0 &&
    filters?.type.length === 0
  ) {
    const filteredBytoDate = getFilteredContactsByToDate(data, fetchedToDate);
    return getFilteredContactsByTags(filteredBytoDate, filters);
  }

  //Case#12: If only toDate && type are selected in filters
  if (
    filters?.contributions.length === 0 &&
    filters?.toDate != null &&
    filters?.fromDate === null &&
    filters?.tags.length === 0 &&
    filters?.type.length > 0
  ) {
    const filteredBytoDate = getFilteredContactsByToDate(data, fetchedToDate);
    return getFilteredContactsByType(filteredBytoDate, filters);
  }

  //Case#13: If only contributions && tags are selected in filters
  if (
    filters?.contributions.length > 0 &&
    filters?.toDate === null &&
    filters?.fromDate === null &&
    filters?.tags.length > 0 &&
    filters?.type.length === 0
  ) {
    const filteredByContrib = getFilteredContactsByContrib(data, filters);
    return getFilteredContactsByTags(filteredByContrib, filters);
  }

  //Case#14: If only contributions && type are selected in filters
  if (
    filters?.contributions.length > 0 &&
    filters?.toDate === null &&
    filters?.fromDate === null &&
    filters?.tags.length === 0 &&
    filters?.type.length > 0
  ) {
    const filteredByContrib = getFilteredContactsByContrib(data, filters);
    return getFilteredContactsByType(filteredByContrib, filters);
  }

  //Case#15: If only tags && type are selected in filters
  if (
    filters?.contributions.length === 0 &&
    filters?.toDate === null &&
    filters?.fromDate === null &&
    filters?.tags.length > 0 &&
    filters?.type.length > 0
  ) {
    const filteredByTags = getFilteredContactsByTags(data, filters);
    return getFilteredContactsByType(filteredByTags, filters);
  }

  //Case#16: If only fromDate && toDate && contributions selected in filters
  if (
    filters?.contributions.length > 0 &&
    filters?.toDate != null &&
    filters?.fromDate != null &&
    filters?.tags.length === 0 &&
    filters?.type.length === 0
  ) {
    const filteredByFromDate = getFilteredContactsByFromDate(data, fetchedFromDate);
    const filteredByFromAndToDate = getFilteredContactsByToDate(filteredByFromDate, fetchedToDate);
    return getFilteredContactsByContrib(filteredByFromAndToDate, filters);
  }

  //Case#17: If only fromDate && toDate && tags selected in filters
  if (
    filters?.contributions.length === 0 &&
    filters?.toDate != null &&
    filters?.fromDate != null &&
    filters?.tags.length > 0 &&
    filters?.type.length === 0
  ) {
    const filteredByFromDate = getFilteredContactsByFromDate(data, fetchedFromDate);
    const filteredByFromAndToDate = getFilteredContactsByToDate(filteredByFromDate, fetchedToDate);
    return getFilteredContactsByTags(filteredByFromAndToDate, filters);
  }

  //Case#18: If only fromDate && toDate && type selected in filters
  if (
    filters?.contributions.length === 0 &&
    filters?.toDate != null &&
    filters?.fromDate != null &&
    filters?.tags.length === 0 &&
    filters?.type.length > 0
  ) {
    const filteredByFromDate = getFilteredContactsByFromDate(data, fetchedFromDate);
    const filteredByFromAndToDate = getFilteredContactsByToDate(filteredByFromDate, fetchedToDate);
    return getFilteredContactsByType(filteredByFromAndToDate, filters);
  }

  //Case#19: If only fromDate && contributions && tags selected in filters
  if (
    filters?.contributions.length > 0 &&
    filters?.toDate === null &&
    filters?.fromDate != null &&
    filters?.tags.length > 0 &&
    filters?.type.length === 0
  ) {
    const filteredByFromDate = getFilteredContactsByFromDate(data, fetchedFromDate);
    const filteredByFromAndContrib = getFilteredContactsByContrib(filteredByFromDate, filters);
    return getFilteredContactsByTags(filteredByFromAndContrib, filters);
  }

  //Case#20: If only fromDate && contributions && type selected in filters
  if (
    filters?.contributions.length > 0 &&
    filters?.toDate === null &&
    filters?.fromDate != null &&
    filters?.tags.length === 0 &&
    filters?.type.length > 0
  ) {
    const filteredByFromDate = getFilteredContactsByFromDate(data, fetchedFromDate);
    const filteredByFromAndContrib = getFilteredContactsByContrib(filteredByFromDate, filters);
    return getFilteredContactsByType(filteredByFromAndContrib, filters);
  }

  //Case#21: If only fromDate && tags && type selected in filters
  if (
    filters?.contributions.length === 0 &&
    filters?.toDate === null &&
    filters?.fromDate != null &&
    filters?.tags.length > 0 &&
    filters?.type.length > 0
  ) {
    const filteredByFromDate = getFilteredContactsByFromDate(data, fetchedFromDate);
    const filteredByFromAndTags = getFilteredContactsByTags(filteredByFromDate, filters);
    return getFilteredContactsByType(filteredByFromAndTags, filters);
  }

  //Case#22: If only toDate && contributions && tags selected in filters
  if (
    filters?.contributions.length > 0 &&
    filters?.toDate != null &&
    filters?.fromDate === null &&
    filters?.tags.length > 0 &&
    filters?.type.length === 0
  ) {
    const filteredByToDate = getFilteredContactsByToDate(data, fetchedToDate);
    const filteredByToDateAndTags = getFilteredContactsByTags(filteredByToDate, filters);
    return getFilteredContactsByContrib(filteredByToDateAndTags, filters);
  }

  //Case#23: If only toDate && contributions && type selected in filters
  if (
    filters?.contributions.length > 0 &&
    filters?.toDate != null &&
    filters?.fromDate === null &&
    filters?.tags.length === 0 &&
    filters?.type.length > 0
  ) {
    const filteredByToDate = getFilteredContactsByToDate(data, fetchedToDate);
    const filteredByToDateAndType = getFilteredContactsByType(filteredByToDate, filters);
    return getFilteredContactsByContrib(filteredByToDateAndType, filters);
  }

  //Case#24: If only toDate && tags && type selected in filters
  if (
    filters?.contributions.length === 0 &&
    filters?.toDate != null &&
    filters?.fromDate === null &&
    filters?.tags.length > 0 &&
    filters?.type.length > 0
  ) {
    const filteredByToDate = getFilteredContactsByToDate(data, fetchedToDate);
    const filteredByToDateAndType = getFilteredContactsByType(filteredByToDate, filters);
    return getFilteredContactsByTags(filteredByToDateAndType, filters);
  }

  //Case#25: If only contributions && tags && type selected in filters
  if (
    filters?.contributions.length > 0 &&
    filters?.toDate === null &&
    filters?.fromDate === null &&
    filters?.tags.length > 0 &&
    filters?.type.length > 0
  ) {
    const filteredByToContrib = getFilteredContactsByContrib(data, fetchedToDate);
    const filteredByContribAndTags = getFilteredContactsByTags(filteredByToContrib, filters);
    return getFilteredContactsByType(filteredByContribAndTags, filters);
  }

  //Case#26:  If only fromDate && toDate && contributions && tags are selected in filters
  if (
    filters?.contributions.length > 0 &&
    filters?.toDate != null &&
    filters?.fromDate != null &&
    filters?.tags.length > 0 &&
    filters?.type.length === 0
  ) {
    const filteredByFromDate = getFilteredContactsByFromDate(data, fetchedFromDate);
    const filteredByFromAndToDate = getFilteredContactsByToDate(filteredByFromDate, fetchedToDate);
    const filteredByFromDateAndToDateAndTags = getFilteredContactsByTags(filteredByFromAndToDate, filters);
    return getFilteredContactsByContrib(filteredByFromDateAndToDateAndTags, filters);
  }

  //Case#27:  If only fromDate && toDate && contributions && type are selected in filters
  if (
    filters?.contributions.length > 0 &&
    filters?.toDate != null &&
    filters?.fromDate != null &&
    filters?.tags.length === 0 &&
    filters?.type.length > 0
  ) {
    const filteredByFromDate = getFilteredContactsByFromDate(data, fetchedFromDate);
    const filteredByFromAndToDate = getFilteredContactsByToDate(filteredByFromDate, fetchedToDate);
    const filteredByFromDateAndToDateAndType = getFilteredContactsByType(filteredByFromAndToDate, filters);
    return getFilteredContactsByContrib(filteredByFromDateAndToDateAndType, filters);
  }

  //Case#28:  If only fromDate && toDate && tags && type are selected in filters
  if (
    filters?.contributions.length === 0 &&
    filters?.toDate != null &&
    filters?.fromDate != null &&
    filters?.tags.length > 0 &&
    filters?.type.length > 0
  ) {
    const filteredByFromDate = getFilteredContactsByFromDate(data, fetchedFromDate);
    const filteredByFromAndToDate = getFilteredContactsByToDate(filteredByFromDate, fetchedToDate);
    const filteredByFromDateAndToDateAndType = getFilteredContactsByType(filteredByFromAndToDate, filters);
    return getFilteredContactsByTags(filteredByFromDateAndToDateAndType, filters);
  }

  //Case#29:  If only fromDate && contribution && tags && type are selected in filters
  if (
    filters?.contributions.length > 0 &&
    filters?.toDate === null &&
    filters?.fromDate != null &&
    filters?.tags.length > 0 &&
    filters?.type.length > 0
  ) {
    const filteredByFromDate = getFilteredContactsByFromDate(data, fetchedFromDate);
    const filteredByFromAndContrib = getFilteredContactsByContrib(filteredByFromDate, fetchedToDate);
    const filteredByFromDateAndContribAndType = getFilteredContactsByType(filteredByFromAndContrib, filters);
    return getFilteredContactsByTags(filteredByFromDateAndContribAndType, filters);
  }

  //Case#30:  If only toDate && contribution && tags && type are selected in filters
  if (
    filters?.contributions.length > 0 &&
    filters?.toDate != null &&
    filters?.fromDate === null &&
    filters?.tags.length > 0 &&
    filters?.type.length > 0
  ) {
    const filteredByToDate = getFilteredContactsByToDate(data, fetchedToDate);
    const filteredByToDateAndContrib = getFilteredContactsByContrib(filteredByToDate, fetchedToDate);
    const filteredByToDateAndContribAndType = getFilteredContactsByType(filteredByToDateAndContrib, filters);
    return getFilteredContactsByTags(filteredByToDateAndContribAndType, filters);
  }

  //Case#31:  If all are selected in filters
  if (
    filters?.contributions.length > 0 &&
    filters?.toDate != null &&
    filters?.fromDate != null &&
    filters?.tags.length > 0 &&
    filters?.type.length > 0
  ) {
    const filteredByToDate = getFilteredContactsByToDate(data, fetchedToDate);
    const filteredByToDateAndFromDate = getFilteredContactsByFromDate(filteredByToDate, fetchedFromDate);
    const filteredByToDateAndFromDateAndContrib = getFilteredContactsByContrib(
      filteredByToDateAndFromDate,
      fetchedToDate,
    );
    const filteredByToDateAndFromDateAndContribAndType = getFilteredContactsByType(
      filteredByToDateAndFromDateAndContrib,
      filters,
    );
    return getFilteredContactsByTags(filteredByToDateAndFromDateAndContribAndType, filters);
  }
};

const getSearchedData = (data = [], keyword = '') => {
  return data?.filter(
    item =>
      item?.firstName?.toLowerCase()?.includes(keyword) ||
      item?.lastName?.toLowerCase()?.includes(keyword) ||
      `${item?.firstName?.toLowerCase()} ${item?.lastName?.toLowerCase()}`?.includes(keyword) ||
      item?.email?.toLowerCase()?.includes(keyword) ||
      item?.source?.toLowerCase()?.includes(keyword) ||
      item?.contributionTitle?.toLowerCase()?.includes(keyword) ||
      item?.tags?.some(item => item.name.toLowerCase().includes(keyword)),
  );
};

export const getSearchedAndFilteredData = (data, keyword, filters) => {
  if (data?.length === 0 || (keyword?.length === 0 && isEmpty(filters))) {
    return data;
  } else if (keyword?.length > 0 && !isEmpty(filters)) {
    const searchedData = getSearchedData(data, keyword);
    return getFilteredData(searchedData, filters);
  } else if (keyword?.length > 0) {
    return getSearchedData(data, keyword);
  } else {
    return getFilteredData(data, filters);
  }
};
