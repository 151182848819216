import React from 'react';
import styled from 'styled-components';

import Signal0Icon from 'assets/network-indicator/signal-0.svg';
import Signal1Icon from 'assets/network-indicator/signal-1.svg';
import Signal2Icon from 'assets/network-indicator/signal-2.svg';

const StyledImg = styled.img`
  width: 16px;
  margin-right: 6px;
  margin-bottom: 6px;
`;

// TODO: If customer wants to show in all cases uncomment icon and comment 'return'
const NetworkQualityIndicator = ({ level }) => {
  const renderSwitch = param => {
    switch (param) {
      case 5:
        return;
      // return (<StyledImg src={Signal4Icon} />);
      case 4:
        return;
      // return (<StyledImg src={Signal3Icon} />);
      case 3:
        return <StyledImg src={Signal2Icon} />;
      case 2:
        return <StyledImg src={Signal1Icon} />;
      case 1:
        return <StyledImg src={Signal0Icon} />;
      default:
        return;
      // return (<StyledImg src={Signal0Icon} />);
    }
  };

  return <div style={{ position: 'absolute', bottom: 0, right: 0 }}>{renderSwitch(level)}</div>;
};

export default NetworkQualityIndicator;
