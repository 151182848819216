import React from 'react'
import { Switch, Route } from 'react-router-dom'

import { useRouter } from 'hooks'
import ClientChatsList from './ClientChatsList'
import ClientChat from './ClientChat'

const CohealerOneToOneDiscussionContainer = (props) => {
  const {
    match: { path },
  } = useRouter()

  return (
    <Switch>
      <Route path={`${path}/:userId`} component={() => <ClientChat {...props} />} />
      <Route component={() => <ClientChatsList {...props} />} />
    </Switch>
  )
}

export default CohealerOneToOneDiscussionContainer
