import React from 'react';
import Avatar from '@mui/material/Avatar';
import '../../ViewAsCoachPage.scss';
import OtherLinkBar from './OtherLinksBar';
import { StyledSection } from './StyledElements';

function OtherLinks({
  firstName,
  customLinks,
  theme,
  primaryColor,
  profileImage,
  data,
  label = 'Additional Links and Resources',
}) {
  const allCustomLinks = customLinks && customLinks.length > 0 ? customLinks : [];
  const color = theme === 'dark' ? 'white' : 'black';
  const themebg = theme === 'dark' ? '#2A2C2E' : '#FAFAFA';

  return (
    <>
      {allCustomLinks.length > 0 && (
        <StyledSection id="webView" className="section about-new-text" color={color}>
          <h1 className="section-header-text " style={{ color }}>
            {data?.additionalLinksLabel?.length === 0 || data?.additionalLinksLabel === null
              ? label
              : data?.additionalLinksLabel}
          </h1>
          <div className="other-links-profile-pic">
            <Avatar alt={firstName} src={profileImage} />
          </div>
          <div className="container">
            {allCustomLinks
              .filter(function (link) {
                if (link?.isVisible) {
                  return true; // skip
                }
                return false;
              })
              .map((current, index) => {
                return (
                  <OtherLinkBar
                    key={index}
                    themebg={themebg}
                    link={current?.link}
                    text={current?.uniqueName}
                    avatar={current?.imagePath}
                    primaryColor={primaryColor}
                  />
                );
              })}
          </div>
        </StyledSection>
      )}
    </>
  );
}

export default OtherLinks;
