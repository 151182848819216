import { handleActions } from 'redux-actions';

import * as actions from 'actions/cohealerIncome';

const initialState = {
  loading: false,
  error: null,
  incomes: {},
};

const cohealerIncome = handleActions(
  new Map([
    [
      actions.fetchCohealerIncomeActions.request,
      state => ({
        ...state,
        error: null,
        loading: true,
        incomes: {},
      }),
    ],
    [
      actions.fetchCohealerIncomeActions.success,
      (state, action) => ({
        ...state,
        loading: false,
        error: null,
        incomes: action.payload,
      }),
    ],
    [
      actions.fetchCohealerIncomeActions.error,
      (state, action) => ({
        ...state,
        loading: false,
        error: action.payload,
        incomes: {},
      }),
    ],
  ]),
  initialState,
);

export default cohealerIncome;
