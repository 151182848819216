import React from 'react';

const TimeSvg = ({ width, height, color = '#C9B382' }) => {
  return (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0 8.59998C0 4.18158 3.5816 0.599976 8 0.599976C12.4184 0.599976 16 4.18158 16 8.59998C16 13.0184 12.4184 16.6 8 16.6C3.5816 16.6 0 13.0184 0 8.59998ZM1.6 8.59998C1.6 10.2974 2.27428 11.9252 3.47452 13.1255C4.67475 14.3257 6.30261 15 8 15C9.69739 15 11.3253 14.3257 12.5255 13.1255C13.7257 11.9252 14.4 10.2974 14.4 8.59998C14.4 6.90259 13.7257 5.27472 12.5255 4.07449C11.3253 2.87426 9.69739 2.19998 8 2.19998C6.30261 2.19998 4.67475 2.87426 3.47452 4.07449C2.27428 5.27472 1.6 6.90259 1.6 8.59998ZM5.0016 7.35838C5.196 6.95198 5.516 5.79998 5.7472 5.79998C5.9776 5.79998 5.8936 6.37838 6.1 6.42878C6.2736 6.47118 6.14 6.17038 6.6224 6.11678C7.1048 6.06318 7.6304 6.35838 7.6304 6.35838C7.6304 6.35838 8.2 6.49998 8.7256 6.35838C8.7256 6.35838 8.448 5.93758 8.788 5.79998C9.1272 5.66238 9.5896 6.17118 9.6064 6.49998C9.6232 6.82878 8.8512 7.04958 8.8512 7.04958L9.6064 7.50318C9.6064 7.50318 9.828 6.80078 10.3 6.79358C10.72 6.78558 11.2464 7.55358 11 7.89998C10.7536 8.24638 10.5952 8.00798 10.5952 8.00798C10.5952 8.00798 9.8432 8.76958 9.6064 8.90638C9.3704 9.04238 9.04 8.90638 9.04 8.90638C9.04 8.90638 8.9 9.16558 9.04 9.28478C9.324 9.57758 10.0616 9.86878 10.0616 9.86878C10.0616 9.86878 12.3296 10.2376 12.4 10.7C12.4704 11.1624 10.5952 13.5 10.3 13.5H9.6C9.3144 12.996 10.0616 11.3848 10.0616 11.3848C10.0616 11.3848 9.6776 10.9592 9.6 10.7C9.5232 10.4408 9.7488 9.98478 9.7488 9.98478L8.7256 9.51358C8.7256 9.51358 8.1816 9.51358 7.912 9.29998C7.6424 9.08638 7.5 7.89998 7.5 7.89998L6.524 7.18478C6.524 7.18478 5.676 8.26158 5.4 8.12398C5.1232 7.98638 4.808 7.76398 5.0016 7.35838ZM10.2576 5.67998C9.5824 5.67998 7.9576 4.82078 8.2304 4.35998C8.2304 3.69918 9.9384 3.79838 10.3048 3.79838C10.6712 3.79838 11.5688 4.08158 12.0672 4.47838C12.5656 4.87438 11.8568 5.44078 11.6096 5.67998C11.3616 5.91998 10.9336 5.67998 10.2576 5.67998Z"
        fill={color}
      />
    </svg>
  );
};

export default TimeSvg;
