import { RESET_CLIENTS, FETCH_CLIENTS_SUCCESS, FETCH_CLIENTS_REQUEST, FETCH_CLIENTS_ERROR } from 'actions/clients';

const initialState = {
  error: null,
  isLoading: false,
  clients: [],
  totalCount: 0,
};
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_CLIENTS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case RESET_CLIENTS:
      return {
        ...state,
        error: null,
        clients: [],
        totalClients: 0,
      };

    case FETCH_CLIENTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        clients: [...state.clients, ...payload.clients],
        totalCount: payload.totalCount,
      };
    case FETCH_CLIENTS_ERROR:
      return {
        ...state,
        isLoading: false,
        error: payload.error,
      };
    default:
      return state;
  }
};
