import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { useRemoteParticipant, useRemoteAudioTrack, useRemoteVideoTrack, useRemoteScreenTrack } from '../../../hooks'
import { useParticipantAudioVolume } from '../../ParticipantsAudioVolumeProvider'
import Participant from './Participant'
import { useNetworkQualityLevel } from 'components/UI/VideoChat/hooks/participants'

const RemoteParticipant = React.forwardRef(({ participant, selectedAudioOutputDevice, ...restProps }, ref) => {
  const {
    audioTrack: remoteAudioTrack,
    videoTrack: remoteVideoTrack,
    screenTrack: remoteScreenTrack,
  } = useRemoteParticipant(participant)
  const { audioTrack, isEnabled: isAudioEnabled } = useRemoteAudioTrack(remoteAudioTrack)
  const [audioVolume] = useParticipantAudioVolume(participant)
  const { videoTrack, isEnabled: isVideoEnabled } = useRemoteVideoTrack(remoteVideoTrack)
  const { screenTrack, isEnabled: isScreenEnabled } = useRemoteScreenTrack(remoteScreenTrack)

  const networkQualityLevel = useNetworkQualityLevel(participant)

  return (
    <Participant
      {...restProps}
      ref={ref}
      identity={participant.identity}
      networkQualityIndicator={networkQualityLevel}
      audioTrack={audioTrack}
      isAudioEnabled={isAudioEnabled}
      audioVolume={audioVolume}
      videoTrack={videoTrack}
      isVideoEnabled={isVideoEnabled}
      isVideoMirrored={false}
      screenTrack={screenTrack}
      isScreenEnabled={isScreenEnabled}
      selectedAudioOutputDevice={selectedAudioOutputDevice}
    />
  )
})

RemoteParticipant.propTypes = {
  participant: PropTypes.shape({
    identity: PropTypes.string,
  }).isRequired,
}

export default RemoteParticipant
