import React, { useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { usePlaidLink } from 'react-plaid-link'
import { useHttp } from 'hooks'
import * as userActions from 'actions/user'

import '../Banner.scss'

const BankInfoPlaid = ({ userId, getUserProfile, getOpen, getReady }) => {
  const { request } = useHttp()

  const getUser = useCallback(() => {
    if (userId) {
      getUserProfile(userId)
    }
  }, [userId, getUserProfile])

  const fetchStripeToken = useCallback(
    (accessToken, accountId) =>
      request(
        '/api/payment/fetch-stripe-token',
        'POST',
        { accessToken, accountId },
        {
          'Content-Type': 'application/json',
        }
      ),
    [request]
  )

  const attachExternalAccount = useCallback(
    (stripeToken) =>
      request(
        '/api/Payment/attach-external-account',
        'POST',
        { stripeToken },
        {
          'Content-Type': 'application/json',
        }
      ),
    [request]
  )

  const onSuccess = useCallback(
    (token, metadata) => {
      request(
        '/api/payment/exchange',
        'POST',
        { publicToken: token },
        {
          'Content-Type': 'application/json',
        }
      ).then(({ accessToken }) => {
        fetchStripeToken(accessToken, metadata.account_id)
          .then(({ stripeToken }) => attachExternalAccount(stripeToken))
          .then(getUser)
      })
    },
    [request, fetchStripeToken, attachExternalAccount, getUser]
  )

  const config = {
    clientName: 'Cohere Inc.',
    env: process.env.REACT_APP_PLAID_ENVIRONMENT,
    product: ['auth', 'transactions'],
    publicKey: process.env.REACT_APP_PLAID_PUBLIC_KEY,
    onSuccess,
  }

  const { open, ready } = usePlaidLink(config)

  useEffect(() => {
    getOpen(open)
  }, [open])
  useEffect(() => {
    getReady(ready)
  }, [ready])
  return null
}

BankInfoPlaid.propTypes = {
  userId: PropTypes.string,
  getUserProfile: PropTypes.func.isRequired,
}

BankInfoPlaid.defaultProps = {
  userId: null,
}

const mapStateToProps = ({ account }) => ({
  userId: account?.user?.id,
})

const actions = {
  getUserProfile: userActions.getProfile,
}

export default connect(mapStateToProps, actions)(BankInfoPlaid)
