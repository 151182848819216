import { createAction } from 'redux-actions';

export const updateChannels = createAction('UPDATE_CHANNELS', channels => ({
  channels,
}));

export const clearChannels = createAction('CLEAR_CHANNELS');

export const addChannel = createAction('ADD_CHANNEL', channel => ({ channel }));

export const updateChannel = createAction('UPDATE_CHANNEL', channel => ({
  channel,
}));

export const removeChannel = createAction('REMOVE_CHANNEL', channelSid => ({
  channelSid,
}));
