import React, { useMemo } from 'react';
import ProgressBar from '@ramonak/react-progress-bar';
import PropTypes from 'prop-types';
import { determineColorToUse, getThemedColors } from 'services/contributions.service';
import { useContribution } from 'pages/ContributionView/hooks';
import { ProgressBarContainer, ProgressBarRow, ProgressBarTitleText } from './styled';

const ContributionSessionsProgressBar = props => {
  const { percentageCompleted } = props;
  const contribution = useContribution();
  const colorToUse = determineColorToUse(contribution);
  const progressbarColor = colorToUse?.AccentColorCode || '#116582';
  const { newThemedBackgroundColor } = getThemedColors(contribution);

  const progressBarConfig = useMemo(
    () => ({
      height: '0.4375rem',
      baseBgColor: contribution?.isDarkModeEnabled ? newThemedBackgroundColor : '#E0E0DE',
      width: '100%',
      isLabelVisible: false,
    }),
    [contribution?.isDarkModeEnabled],
  );

  return (
    <ProgressBarContainer>
      <ProgressBarRow>
        <ProgressBarTitleText
          textColor={progressbarColor}
          // style={{ display: 'flex', justifyContent: 'center', color: progressbarColor, fontWeight: '900' }}
        >
          Completion Progress
        </ProgressBarTitleText>
        <ProgressBarTitleText textColor={progressbarColor}>{percentageCompleted || 0}%</ProgressBarTitleText>
      </ProgressBarRow>
      {/* <ProgressBarRow> */}
      <ProgressBar {...progressBarConfig} completed={percentageCompleted} bgColor={progressbarColor} />
      {/* </ProgressBarRow> */}
    </ProgressBarContainer>
  );
};
ContributionSessionsProgressBar.propTypes = {
  percentageCompleted: PropTypes.number,
};
ContributionSessionsProgressBar.defaultProps = {
  percentageCompleted: 0,
};
export default ContributionSessionsProgressBar;
