import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import CopyToClipboardIcon from 'assets/ic-content-copy.svg';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import { IconButton } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import { colors } from 'utils/styles';

import InputEmailsComponent from 'components/UI/InputEmailsComponent';
import copyToClipboard from 'utils/copyToClipboard';
import useAccount from 'hooks/useAccount';

const StyledShareModalMain = styled.div`
  width: 100%;
  height: 405px;
  box-sizing: border-box;
  padding: ${mobileView => (mobileView ? '30px 30px' : '55px 70px')};
`;

const StyledCopyToClipboardBlock = styled.div`
  cursor: pointer;
  display: inline-block;
  margin-bottom: 30px;
`;

const StyledSendEmailBlock = styled.div`
  width: 100%;
`;

const Icon = styled.img`
  vertical-align: middle;
  object-fit: contain;
  width: 100%;
  height: auto;
  margin-right: 8px;
  width: 22px;
  height: 22px;
`;

const StyledIconButton = styled(IconButton)`
  width: 20px;
  height: 20px;
`;

const StyledMailOutlineIcon = styled(MailOutlineIcon)`
  color: ${colors.white};
`;

const StyledIconBlack = styled.span`
  margin: 0 8px 0 4px;
  display: inline-block;
  margin-top: 18px;
  vertical-align: middle;
  width: 40px;
  height: 40px;
  box-sizing: border-box;
  padding-top: 7px;
  border-radius: 50%;
  text-align: center;
  background-color: ${colors.black};
`;

const InviteCoachModalView = ({
  emails,
  onSetEmails,
  emailsError,
  setEmailsError,
  mobileView,
  tooltipOpen,
  setTooltipOpen,
}) => {
  const { origin } = window.location;
  const { user } = useAccount();
  // copyToClipboard(`${origin}/auth/signup?inviteCode=${user.accountId}`);
  const handleCopyClick = () => {
    copyToClipboard(
      origin.includes('local') || origin.includes('test') || origin.includes('dev')
        ? `https://www.cohere.live/affiliate-stg?inviteCode=${user.accountId}`
        : `https://www.cohere.live/affiliate?inviteCode=${user.accountId}`,
    );
    handleTooltip();
  };
  const handleTooltip = () => setTooltipOpen(!tooltipOpen);

  return (
    <StyledShareModalMain mobileView={mobileView}>
      <Tooltip
        className="cursor-pointer"
        title="Copied to clipboard"
        open={tooltipOpen}
        arrow
        onClose={() => {
          setTooltipOpen(false);
        }}
      >
        <StyledCopyToClipboardBlock onClick={handleCopyClick}>
          <StyledIconButton aria-label="close modal" component="span">
            <Icon src={CopyToClipboardIcon} />
          </StyledIconButton>
          <span>Copy To Clipboard</span>
        </StyledCopyToClipboardBlock>
      </Tooltip>
      {/* <StyledSendEmailBlock>
        <StyledIconBlack>
          <StyledMailOutlineIcon />
        </StyledIconBlack>
        <InputEmailsComponent
          emails={emails}
          handleSetEmails={onSetEmails}
          emailsError={emailsError}
          setEmailsError={setEmailsError}
        />
      </StyledSendEmailBlock> */}
    </StyledShareModalMain>
  );
};

InviteCoachModalView.propTypes = {
  emails: PropTypes.arrayOf(PropTypes.string).isRequired,
  onSetEmails: PropTypes.func.isRequired,
  emailsError: PropTypes.shape({
    isError: PropTypes.bool,
    errorMessage: PropTypes.string,
  }).isRequired,
  setEmailsError: PropTypes.func.isRequired,
  setTooltipOpen: PropTypes.func.isRequired,
  mobileView: PropTypes.bool,
  tooltipOpen: PropTypes.bool,
};

InviteCoachModalView.defaultProps = {
  mobileView: false,
  tooltipOpen: false,
};

export default InviteCoachModalView;
