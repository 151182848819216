import { SvgIcon } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

function UploadThumbnailIcon({ fillColor = '#DFE3E4', width = '36px', height = '37px', ...rest }) {
  return (
    <SvgIcon viewBox="0 0 36 37" style={{ width, height, ...rest }}>
      <svg width="36" height="37" viewBox="0 0 36 37" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.415385" y="1.29527" width="35.1692" height="35.1692" rx="3.58462" fill="white" />
        <path
          d="M11.0768 28.5721C10.3153 28.5721 9.66316 28.3007 9.12039 27.758C8.57762 27.2152 8.3067 26.5635 8.30762 25.8029V11.9567C8.30762 11.1952 8.579 10.543 9.12177 10.0003C9.66454 9.4575 10.3162 9.18658 11.0768 9.1875H24.923C25.6845 9.1875 26.3367 9.45889 26.8795 10.0017C27.4222 10.5444 27.6932 11.1961 27.6922 11.9567V25.8029C27.6922 26.5644 27.4208 27.2166 26.8781 27.7593C26.3353 28.3021 25.6836 28.573 24.923 28.5721H11.0768ZM11.0768 25.8029H24.923V11.9567H11.0768V25.8029ZM12.4615 23.0337H23.5384L19.9038 18.1875L17.3076 21.649L15.4038 19.1221L12.4615 23.0337Z"
          fill="black"
        />
        <rect
          x="0.415385"
          y="1.29527"
          width="35.1692"
          height="35.1692"
          rx="3.58462"
          stroke={fillColor}
          stroke-width="0.830769"
        />
      </svg>
    </SvgIcon>
  );
}

UploadThumbnailIcon.propTypes = {
  fillColor: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
};

export default UploadThumbnailIcon;
