import moment from 'moment';

import DateFilter from '../../DateFilter';

const checkIfSatisfiesFavoriteFilter = ({ isFavorite }, { showFavoriteOnly }) => !showFavoriteOnly || isFavorite;

const checkIfSatisfiesDateFilter = ({ hasLastMessage, lastMessageTimestamp }, { dateFilter }) => {
  if (!hasLastMessage) {
    return true;
  }

  switch (dateFilter) {
    case DateFilter.thisWeek:
      return lastMessageTimestamp.isSame(moment(), 'week');
    case DateFilter.all:
    default:
      return true;
  }
};

const checkIfSatisfiesSearchFilter = ({ title, hasLastMessage, lastMessage }, { searchFilter }) => {
  const searchData = [title, hasLastMessage ? lastMessage.body : ''].filter(sd => !!sd).map(sd => sd.toLowerCase());
  const actualSearchFilter = searchFilter.toLowerCase();

  return searchData.some(sd => sd.includes(actualSearchFilter));
};

const channelFilterPredicates = [
  checkIfSatisfiesFavoriteFilter,
  checkIfSatisfiesDateFilter,
  checkIfSatisfiesSearchFilter,
];

const checkIfChannelSatisfiesFilters = (channelData, filters) => {
  return channelFilterPredicates.every(p => p(channelData, filters));
};

export default checkIfChannelSatisfiesFilters;
