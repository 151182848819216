import React from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';

import styled from 'styled-components';
import { CircularProgressWithLabel } from 'components/UI/CircularProgressWithLabel/CircularProgressWithLabel';
import { colors } from 'utils/styles';

const getColor = props => {
  if (props.isDragAccept) {
    return colors.darkOceanBlue;
  }
  if (props.isDragReject) {
    return '#eeeeee';
  }
  if (props.isDragActive) {
    return colors.darkOceanBlue;
  }
  return '#bdbdbd';
};

const Container = styled.div`
  cursor: pointer;
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background-color: #f5f5f5;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
  height: 200px;
  margin: 0px 0 20px;
`;

const StyledIconWrap = styled.span`
  font-size: 60px;
  transition: color 0.24s ease-in-out;
  color: ${props => getColor(props)};
`;

const Dropzone = ({
  multiple,
  action,
  isDisabled,
  type,
  icon,
  progress,
  placeholder,
  loading,
  className,
  style,
  placeholderStyle,
  stopPropagation = false,
}) => {
  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    onDrop: files => action(files),
    accept: type,
    multiple,
    disabled: isDisabled,
  });

  const onClick = event => {
    event.stopPropagation();
  };

  const getAdditionalProps = () => {
    if (stopPropagation) {
      return {
        onClick,
      };
    }
  };

  return (
    <Container
      className={className || ''}
      style={style || {}}
      {...getRootProps({ isDragActive, isDragAccept, isDragReject })}
      {...getAdditionalProps()} // avoid of triggering onClick twice https://github.com/react-dropzone/react-dropzone/issues/252
    >
      <>
        <input {...getInputProps()} />
        {/* <StyledIconWrap {...getRootProps({ isDragActive, isDragAccept, isDragReject })}>{icon}</StyledIconWrap> */}
        <p style={{ color: 'black', ...placeholderStyle }}>{placeholder}</p>
      </>
    </Container>
  );
};

Dropzone.defaultProps = {
  multiple: false,
  progress: 0,
  isDisabled: false,
};

Dropzone.propTypes = {
  isDisabled: PropTypes.bool,
};

export default Dropzone;
