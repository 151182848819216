import { useSelector } from 'react-redux';
import useShallowEqualSelector from './useShallowEqualSelector';
import useClientPreview from './useClientPreview';
import { UserRoles } from 'helpers/constants';
import { isEmpty } from 'lodash';

const useAccount = () => {
  const coachLoginInfo = useSelector(state => state?.account?.user?.coachLoginInfo || {});
  const user = useShallowEqualSelector(state => state?.account?.user || {});
  const currentRole = useShallowEqualSelector(state => state?.account?.currentRole);
  const roles = useShallowEqualSelector(state => state?.account?.roles);
  const signupType = useShallowEqualSelector(state => state?.account?.signupType);

  const { clientPreviewMode } = useClientPreview();
  const isGuest = isEmpty(user);
  const isLoggedIn = !isGuest;
  const isCoach = isLoggedIn && currentRole === UserRoles.cohealer;
  const isCoachMode = isCoach && !clientPreviewMode;
  const isCoachInPreviewMode = isCoach && clientPreviewMode;
  const isClient = isLoggedIn && currentRole === UserRoles.client;

  return {
    coachLoginInfo,
    user,
    currentRole,
    roles,
    signupType,
    isGuest,
    isLoggedIn,
    isCoach,
    isCoachMode,
    isCoachInPreviewMode,
    isClient,
  };
};

export default useAccount;
