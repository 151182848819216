import styled from 'styled-components';
import CrossIcon from '@material-ui/icons/Clear';
import Carousel from 'react-material-ui-carousel';
import Button from 'components/FormUI/Button';
import { colors } from 'utils/styles';

const StyledBackground = styled.div`
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  background: ${colors.black};
  opacity: 0.4;
  z-index: 10;
`;

const StyledContainer = styled.div`
  position: absolute;
  top: 5%;
  left: 10%;
  z-index: 11;
  width: 80%;
  background: white;
  height: 90%;
  box-shadow: 0 2px 8px 1px ${colors.shadow};
  border: 1px solid ${colors.stroke};
  border-radius: 8px;

  ${({ mobileView }) =>
    mobileView &&
    `
      width: 96%;
      height: 98%;
      top: 1%;
      left: 2%;
    `}
`;

const StyledCloseIcon = styled(CrossIcon)`
  margin: 10px;
  margin-right: 0px;

  &:hover {
    cursor: pointer;
  }
`;

const StyledHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  position: relative;

  ${({ mobileView }) =>
    mobileView &&
    `
height: 80px;
`}
`;

const StyledCarousel = styled(Carousel)`
  height: calc(100% - 45px);

  .CarouselItem {
    height: calc(100% - 70px);
    overflow-y: auto;
    overflow-x: hidden;
    ${({ mobileView }) =>
      mobileView &&
      `
      height: calc(100% - 110px);
      margin-top: 40px;
    `}
    div:only-child {
      height: 100%;
    }
  }
`;

const StyledCarouselItem = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

const StyledLogo = styled.img`
  position: absolute;
  width: 10rem;
  height: 90px;
  top: 20px;
  left: 20px;

  ${({ mobileView }) =>
    mobileView &&
    `
top: 10 px;
width: 7 rem;
height: 60 px;
`}
`;

const StyledTitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 100px;
  margin: 0 !important;
  font-size: 1.8rem;

  ${({ mobileView }) =>
    mobileView &&
    `
padding: 10px 5px 0px 5px;
font - size: 1.5 rem;
`}
`;

const StyledButton = styled(Button)`
  width: 150px;
`;

const StyledDescription = styled.h3`
  padding: 0 100px;

  ${({ mobileView }) =>
    mobileView &&
    `
padding: 10px 5px 0px 5px;
`}
`;

const StyledVideo = styled.video`
  max-width: 650px;
  width: 650px;
  border-radius: 8px;
  height: 360px;
  margin: 10px
    ${({ mobileView }) =>
      mobileView &&
      `
    width: 300px;
    max-width: 300px;
    height: auto;
  `};
  align-self: center;
`;
const StyledTitleForVideo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 10px;
  margin: 0 !important;
  font-size: 1.2rem;
  line-height: 20px;
  width: 80%;
  text-align: center
    ${({ mobileView }) =>
      mobileView &&
      `
padding: 10px 5px 0px 5px;
font - size: 1.5 rem;
`};
`;

export {
  StyledBackground,
  StyledContainer,
  StyledCloseIcon,
  StyledHeader,
  StyledCarousel,
  StyledCarouselItem,
  StyledLogo,
  StyledTitle,
  StyledButton,
  StyledDescription,
  StyledVideo,
  StyledTitleForVideo,
};
