import React from 'react'
import PropTypes from 'prop-types'

import './ToolbarAction.scss'

const ToolbarAction = ({ label, children, endAdornment }) => (
  <div className="video-chat-toolbar-action">
    <div className="video-chat-toolbar-action-content">
      {children}
      {endAdornment && <div className="video-chat-toolbar-action-content__end-adornment">{endAdornment}</div>}
    </div>
    <p className="video-chat-toolbar-action__label">{label}</p>
  </div>
)

ToolbarAction.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  endAdornment: PropTypes.node,
}

ToolbarAction.defaultProps = {
  endAdornment: null,
}

export default ToolbarAction
