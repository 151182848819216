import React from 'react'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import Paper from '@material-ui/core/Paper'
import Icon from 'components/UI/Icon'
import { mdiApps, mdiMessageProcessing } from '@mdi/js'
import { useTheme, useMediaQuery } from '@material-ui/core'

import { useShallowEqualSelector } from 'hooks'
import { LANGUAGE_CODES } from '../../../constants'

import './About.scss'

const About = () => {
  const {
    occupation = '',
    businessName = '',
    businessType = '',
    bio = '',
    avatarUrl = null,
    contributionCategories = [],
    languageCode = 'en',
  } = useShallowEqualSelector((state) => state?.cohealerInfo?.info)

  const theme = useTheme()
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'))

  return (
    <div className="bg-snow about-provider">
      <Grid container spacing={5} direction={mobileView ? 'column' : 'row'}>
        <Grid item xs={mobileView ? 12 : 6}>
          <Paper className="h-100 h4 bs-nobel">
            <div
              className={`w-100 ${mobileView ? '' : 'h-100'} provider-avatar`}
              style={{ backgroundImage: `url(${avatarUrl})` }}
            />
          </Paper>
        </Grid>

        <Grid item xs={mobileView ? 12 : 6}>
          <Card
            classes={{
              root: 'h-100 bs-nobel',
            }}
          >
            <CardHeader title="About me" className="boder-b-whisper" />
            <CardContent
              classes={{
                root: 'regular-text',
              }}
            >
              {bio}
            </CardContent>
            <CardContent
              classes={{
                root: 'about-provider-info',
              }}
            >
              <Grid container direction="column">
                <span className="h5">Occupation</span>
                <span className="regular-text">{occupation}</span>
              </Grid>
              {!!businessName?.length && (
                <Grid container direction="column">
                  <span className="h5">Business Name</span>
                  <span className="regular-text">{businessName}</span>
                </Grid>
              )}
              <Grid container direction="column">
                <span className="h5">Business Type</span>
                <span className="regular-text">{businessType}</span>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <div className="d-flex mt-4 bs-nobel bg-white extra-info">
        {/* <div className="d-flex extra-info-item">
          <Icon path={mdiMapMarker} right="4" />
          <span className="h5">{city || "Los Angeles"}</span>
        </div> */}
        <div className={`${mobileView ? 'w-100' : 'w-25'} d-flex extra-info-item`}>
          <Icon className="extra-info-item_icon" path={mdiMessageProcessing} right="4" />
          <span className={mobileView}>{LANGUAGE_CODES[languageCode]}</span>
        </div>
        {contributionCategories && (
          <div className="d-flex extra-info-item">
            <Icon className=" extra-info-item_icon" path={mdiApps} right="4" />
            <span className="h5">{contributionCategories.join(', ')}</span>
          </div>
        )}
      </div>
    </div>
  )
}

export default About
