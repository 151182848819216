import React from 'react';
import ContributionHeaderView, { StyledNavLink } from 'components/UI/ContributionHeaderView';

const ContributionsHeader = () => {
  return (
    <ContributionHeaderView>
      <>
        <StyledNavLink to="/admin/contributions/review">Review</StyledNavLink>
        <StyledNavLink to="/admin/contributions/updated">Updated</StyledNavLink>
        <StyledNavLink to="/admin/contributions/approved">Approved</StyledNavLink>
        <StyledNavLink to="/admin/contributions/rejected">Rejected</StyledNavLink>
      </>
    </ContributionHeaderView>
  );
};

ContributionsHeader.propTypes = {};

export default ContributionsHeader;
