import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { mdiHeart, mdiTabletIpad, mdiRun, mdiLightbulb, mdiAccountOutline } from '@mdi/js';
import { useTheme, useMediaQuery } from '@material-ui/core';

import { replaceBreakToBr, replaceHtmlEscapedSpaceToNormal, wrapText, wrapTextWithColoredLinks } from 'utils/utils';
import { Card, CardBody, CardHeader } from 'components/UI/Card';
import { PageTitleSecond } from 'components/UI/Text/TextStyles';
import useContribution from 'pages/ContributionView/hooks/useContribution';
import TextBlock from './TextBlock';
import { getThemedColors } from 'services/contributions.service';
import { determineColorToUse } from 'services/contributions.service';
import { colors } from 'utils/styles';

const AboutDetailsBlock = ({
  whoIAm,
  whatWeDo,
  purpose,
  preparation,
  whoIAmLabel,
  whatWeDoLabel,
  purposeLabel,
  preparationLabel,
  viewPreparation,
  viewPurpose,
  viewWhatWeDo,
  viewWhoIAm,
  whoIAmIcon,
  whatWeDoIcon,
  purposeIcon,
  preparationIcon,
  brandingColors,
}) => {
  const theme = useTheme();
  const contribution = useContribution();
  const colorToUse = determineColorToUse(contribution);
  const { themedColor, themedCardBackgroundColor } = getThemedColors(contribution);
  const isCustomWhoIAmIcon = whoIAmIcon?.includes('coherepublic') && whoIAmIcon?.includes('s3.amazonaws.com');
  const isCustomwhatWeDoIcon = whatWeDoIcon?.includes('coherepublic') && whatWeDoIcon?.includes('s3.amazonaws.com');
  const isCustomPurposeIcon = purposeIcon?.includes('coherepublic') && purposeIcon?.includes('s3.amazonaws.com');
  const isCustomPreparationIcon =
    preparationIcon?.includes('coherepublic') && preparationIcon?.includes('s3.amazonaws.com');

  const mobileView = useMediaQuery(theme.breakpoints.down('xs'));
  if (!whoIAm && !whatWeDo && !purpose && !preparation) {
    return;
  }
  return (
    <Card contribution={contribution} style={{ color: themedColor, backgroundColor: themedCardBackgroundColor }}>
      <CardHeader mobileView={mobileView}>
        <PageTitleSecond style={{ color: themedColor }} mobileView={mobileView}>
          Details
        </PageTitleSecond>
      </CardHeader>
      <CardBody mobileView={mobileView}>
        <Grid container spacing={4}>
          {!viewPurpose && !!purpose && (
            <Grid item md={6} sm={12} xs={12}>
              <TextBlock
                image={mdiHeart}
                text={wrapTextWithColoredLinks(
                  replaceBreakToBr(replaceHtmlEscapedSpaceToNormal(purpose)),
                  colors.darkOceanBlue2,
                )}
                title={purposeLabel}
                mobileView={mobileView}
                style={{ color: themedColor }}
                customIcon={purposeIcon}
                isCustomIconAdded={isCustomPurposeIcon}
                colorToUse={colorToUse}
              />
            </Grid>
          )}
          {!viewWhoIAm && !!whoIAm && (
            <Grid item md={6} sm={12} xs={12}>
              <TextBlock
                image={mdiAccountOutline}
                text={wrapTextWithColoredLinks(
                  replaceBreakToBr(replaceHtmlEscapedSpaceToNormal(whoIAm)),
                  colors.darkOceanBlue2,
                )}
                title={whoIAmLabel}
                mobileView={mobileView}
                style={{ color: themedColor }}
                customIcon={whoIAmIcon}
                isCustomIconAdded={isCustomWhoIAmIcon}
                colorToUse={colorToUse}
              />
            </Grid>
          )}

          {!viewWhatWeDo && !!whatWeDo && (
            <Grid item md={6} sm={12} xs={12}>
              <TextBlock
                image={mdiLightbulb}
                text={wrapTextWithColoredLinks(
                  replaceBreakToBr(replaceHtmlEscapedSpaceToNormal(whatWeDo)),
                  colors.darkOceanBlue2,
                )}
                title={whatWeDoLabel}
                mobileView={mobileView}
                style={{ color: themedColor }}
                customIcon={whatWeDoIcon}
                isCustomIconAdded={isCustomwhatWeDoIcon}
                colorToUse={colorToUse}
              />
            </Grid>
          )}
          {!viewPreparation && !!preparation && (
            <Grid item md={6} sm={12} xs={12}>
              <TextBlock
                image={mdiTabletIpad}
                title={preparationLabel}
                text={wrapTextWithColoredLinks(
                  replaceBreakToBr(replaceHtmlEscapedSpaceToNormal(preparation)),
                  colors.darkOceanBlue2,
                )}
                mobileView={mobileView}
                style={{ color: themedColor }}
                customIcon={preparationIcon}
                isCustomIconAdded={isCustomPreparationIcon}
                colorToUse={colorToUse}
              />
            </Grid>
          )}
        </Grid>
      </CardBody>
    </Card>
  );
};

AboutDetailsBlock.propTypes = {
  whoIAm: PropTypes.string,
  whatWeDo: PropTypes.string,
  purpose: PropTypes.string,
  preparation: PropTypes.string,
  whoIAmLabel: PropTypes.string,
  whatWeDoLabel: PropTypes.string,
  purposeLabel: PropTypes.string,
  preparationLabel: PropTypes.string,
};

AboutDetailsBlock.defaultProps = {
  whoIAm: 'None',
  whatWeDo: 'None',
  purpose: 'None',
  preparation: 'None',
  whoIAmLabel: 'Who this is for',
  whatWeDoLabel: 'What to expect',
  purposeLabel: 'Why this was ceated',
  preparationLabel: 'Additional details',
};

export default AboutDetailsBlock;
