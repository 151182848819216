import React, { useState, useCallback, useEffect, useRef } from 'react';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import styled, { css } from 'styled-components';
import { toRem, colors } from 'utils/styles';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { isUndefined } from 'lodash';

const summarystyles = {
  root: {
    minheight: 7 * 4,
    '&$expanded': {
      minheight: 7 * 4,
    },
  },
  content: {
    margin: '4px 0',
    '&$expanded': {
      margin: '0px',
    },
  },
  expandicon: {
    padding: 3,
  },
  expanded: {},
};
const CompactExpansionPanelSummary = withStyles(summarystyles)(ExpansionPanelSummary);
CompactExpansionPanelSummary.muiName = 'ExpansionPanelSummary';

const StyledSummaryContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

const StyledSummaryTitle = styled.p`
  font-family: 'Avenir';
  font-size: ${({ mobileView }) => (mobileView ? '16px' : '18px')};
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0.1px;
  margin-bottom: 0px;
`;

const StyledSummarySubTitle = styled.p`
  font-family: 'Avenir';
  font-size: ${({ mobileView, noBorder }) => (mobileView ? (noBorder ? '14px' : '15px') : '16px')};
  font-weight: 350;
  line-height: 24px;
  letter-spacing: 0.1px;
  margin-bottom: 0px;
`;

const ExpansionPanelDetailsStyled = styled(ExpansionPanelDetails)`
  padding: ${({ bodyPadding, noBorder, asyncContainerPadding, noTopPadding }) =>
    !isUndefined(bodyPadding)
      ? bodyPadding
      : asyncContainerPadding
      ? '1.25rem 0.62rem'
      : noBorder
      ? '0 !important'
      : noTopPadding
      ? '0px 24px 10px 24px !important'
      : '20px 24px !important'};
`;

const useStyles = makeStyles(theme => ({
  customExpansionPanel: {
    '&.Mui-expanded': {
      '&:before': {
        opacity: 0,
      },
    },
  },
}));
const StyledExpansionPanel = styled(ExpansionPanel)`
  ${({ $rootPanelStyles = {} }) =>
    // css`
    //   ${rootPanelStyles}
    // `
    $rootPanelStyles}
`;
// const StyledExpansionPanel = ExpansionPanel;
const CustomExpansionPanel = ({
  summaryTitle,
  summarySubTitle,
  expand = false,
  noBorder = false,
  mobileView,
  children,
  setMainExpand,
  disabledExpansion = false,
  disabled = false,
  panelHeight = '50px',
  subTitleBelowTheTitle,
  customStyle,
  asyncContainerPadding = false,
  noTopPadding,
  bodyPadding = undefined,
  headerPadding = undefined,
  minHeight = undefined,
  rootPanelStyles = {},
  expandIcon = null,
  rootStyles = {},
  rootProps = {},
  padding,
}) => {
  const [expanded, setExpanded] = useState(expand);
  useEffect(() => {
    setExpanded(expand);
  }, [expand]);

  useEffect(() => {
    if (setMainExpand) {
      setMainExpand(expanded);
    }
  }, [expanded]);

  return (
    <>
      <StyledExpansionPanel
        expanded={expanded}
        onChange={
          disabledExpansion
            ? null
            : (e, newExpanded) => {
                e.stopPropagation();
                setExpanded(newExpanded);
              }
        }
        TransitionProps={{ unmountOnExit: true }}
        style={{
          marginBottom: noBorder ? '' : '10px',
          borderRadius: noBorder ? '' : '4px',
          border: noBorder ? '' : '1px solid #E7E7E7',
          boxShadow: 'none',
          position: 'static',
          ...(rootStyles || {}),
        }}
        disabled={disabled}
        $rootPanelStyles={rootPanelStyles}
        {...(rootProps || {})}
      >
        <CompactExpansionPanelSummary
          expandIcon={disabledExpansion ? null : expandIcon || <ExpandMoreIcon htmlColor={colors.lightBrown} />}
          style={{
            borderBottom: noBorder ? '' : '1px solid #e7e7e7',
            padding: !isUndefined(headerPadding) ? headerPadding : noBorder ? '0px' : padding ? padding : '0px 24px',
            minHeight: !isUndefined(minHeight) ? minHeight : '50px',
          }}
        >
          <StyledSummaryContent>
            {noBorder ? (
              <>
                <div
                  className={subTitleBelowTheTitle && mobileView ? 'd-flex' : 'd-flex align-items-center'}
                  style={
                    subTitleBelowTheTitle && mobileView
                      ? { flexDirection: 'column', alignItems: 'start !important' }
                      : {}
                  }
                >
                  {summaryTitle && (
                    <StyledSummaryTitle mobileView={mobileView} noBorder={noBorder}>
                      {summaryTitle}
                    </StyledSummaryTitle>
                  )}
                  {summarySubTitle && !expanded && (
                    <StyledSummarySubTitle
                      style={subTitleBelowTheTitle && mobileView ? {} : { marginLeft: '5px' }}
                      mobileView={mobileView}
                      noBorder={noBorder}
                    >
                      {summarySubTitle}
                    </StyledSummarySubTitle>
                  )}
                </div>
              </>
            ) : (
              <>
                {summaryTitle && (
                  <div className="d-flex align-items-center summary-title-wrapper">
                    <StyledSummaryTitle mobileView={mobileView}>{summaryTitle}</StyledSummaryTitle>
                  </div>
                )}
                {summarySubTitle && !expanded && (
                  <StyledSummarySubTitle mobileView={mobileView}>{summarySubTitle}</StyledSummarySubTitle>
                )}
              </>
            )}
          </StyledSummaryContent>
        </CompactExpansionPanelSummary>
        {!disabledExpansion && children ? (
          <ExpansionPanelDetailsStyled
            asyncContainerPadding={asyncContainerPadding}
            noTopPadding={noTopPadding}
            style={customStyle}
            noBorder={noBorder}
            bodyPadding={bodyPadding}
          >
            {typeof children === 'function' ? children(expanded) : children}
          </ExpansionPanelDetailsStyled>
        ) : null}
      </StyledExpansionPanel>
    </>
  );
};

export default CustomExpansionPanel;
