import React from 'react';
import styled, { keyframes } from 'styled-components';
import PropTypes from 'prop-types';

import { colors } from 'utils/styles';

const rotate = keyframes`
  from {
    transform: rotate(-45deg);
  }
  to {
    transform: rotate(315deg);
  }
`;

const rotate2 = keyframes`
  to {
    transform: rotate(-360deg);
  }
`;

const rotate3 = keyframes`
  from {
    transform: rotate(-70deg);
  }
  to {
    transform: rotate(290deg);
  }
`;

const StyledOverlayRelative = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const StyledCircle = styled.div`
  position: absolute;
  border-radius: 50%;

  ${({ border }) => border && `border: ${border}px solid transparent;`}
`;

const StyledCircleOne = styled(StyledCircle)`
  border-top-color: ${colors.lightBrown};
  border-bottom-color: ${colors.lightBrown};
  animation: ${rotate} 3s linear infinite;

  ${({ size }) => size && `width: ${size * 1.8}px;`}
  ${({ size }) => size && `height: ${size * 1.8}px;`}
`;

const StyledCircleTwo = styled(StyledCircle)`
  border-left-color: ${colors.lightBrown};
  border-top-color: ${colors.lightBrown};
  border-bottom-color: ${colors.lightBrown};
  animation: ${rotate2} 2s linear infinite;
  ${({ size }) => size && `width: ${size * 1.4}px;`}
  ${({ size }) => size && `height: ${size * 1.4}px;`}
`;

const StyledCircleThree = styled(StyledCircle)`
  border-top-color: ${colors.lightBrown};
  border-left-color: ${colors.lightBrown};
  animation: ${rotate3} 2.5s linear infinite;

  ${({ size }) => size && `width: ${size * 1}px;`}
  ${({ size }) => size && `height: ${size * 1}px;`}
`;

const Loader = React.memo(({ relative, size, border }) => {
  return (
    <StyledOverlayRelative>
      <StyledCircleOne size={size} border={border} />
      <StyledCircleTwo size={size} border={border} />
      <StyledCircleThree size={size} border={border} />
    </StyledOverlayRelative>
  );
});

Loader.propTypes = {
  relative: PropTypes.bool,
  withoutTopOffset: PropTypes.bool,
  size: PropTypes.number,
  border: PropTypes.number,
};

Loader.defaultProps = {
  relative: false,
  withoutTopOffset: false,
  border: 3,
};

export default Loader;
