import React from 'react';
import PropTypes from 'prop-types';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';

import TextField from 'components/UI/TextField';

import useContribution from 'pages/ContributionView/hooks/useContribution';
import { getThemedColors } from 'services/contributions.service';

const SearchFilter = ({ className, searchFilter, onSearchFilterChange }) => {
  const contribution = useContribution();
  const { themedColor } = getThemedColors(contribution);
  return (
    <TextField
      className={className}
      placeholder="Search"
      value={searchFilter}
      onChange={e => onSearchFilterChange(e.target.value)}
      onFocus={e => e.stopPropagation()}
      InputProps={{
        style: { color: themedColor },
      }}
      startAdornment={
        <InputAdornment position="start">
          <SearchIcon />
        </InputAdornment>
      }
      endAdornment={
        !!searchFilter && (
          <InputAdornment position="end">
            <IconButton onClick={() => onSearchFilterChange('')} onMouseDown={e => e.preventDefault()}>
              <CloseIcon style={{ color: 'pink' }} />
            </IconButton>
          </InputAdornment>
        )
      }
    />
  );
};

SearchFilter.propTypes = {
  className: PropTypes.string.isRequired,
  searchFilter: PropTypes.string.isRequired,
  onSearchFilterChange: PropTypes.func.isRequired,
};

export default SearchFilter;
