import React, { useEffect, useState } from 'react';
import moment from 'moment';

import { useAccount } from 'hooks';
import Loader from 'components/UI/Loader';
import { createClientJourneyContributionFrom } from 'services/dataContracts/factories';
import * as contributionService from 'services/contributions.service';
import * as purchaseService from 'services/purchase.service';

import { isEmpty } from 'ramda';
import IntroScreenClient from './IntroScreenClient';
import ClientJourney from './ClientJourney';
import { useSelector } from 'react-redux';

const ClientDashboardContainer = () => {
  const { user } = useAccount();
  const [purchasedContributions, setPurchasedContributions] = useState(null);
  const [allContributions, setAllContributions] = useState([]);
  const [coaches, setCoaches] = useState([]);
  const refetchContributons = useSelector(state => state?.contributions?.refetchContributons);
  const [data, setData] = useState(null);

  useEffect(() => {
    (async () => {
      const res = await contributionService.GetAllBoughtByUserIdUpdated(user.id);
      setData(res);
    })();
  }, [user.id]);

  // useEffect(() => {
  //   Promise.all([contributionService.GetAllBoughtByUserIdUpdated(user.id), purchaseService.getCoaches()])
  //     .then(
  //       ([
  //         { upcoming, upcomingTotalCount, past, pastTotalCount, closestClassForBanner, clientDeclinedSubscriptions, modules, inCompletetd },
  //         myCoaches,
  //       ]) => {
  //         setCoaches(myCoaches);
  //         const now = moment();

  //         const checkIfContributionsStartToday = c => moment(c.sessionTime).isSame(now, 'day');
  //         const todayContributions = upcoming.thisWeek.filter(checkIfContributionsStartToday);
  //         const laterThisWeekContributions = upcoming.thisWeek.filter(c => !checkIfContributionsStartToday(c));
  //         const totalContributionsCount = upcomingTotalCount + pastTotalCount;

  //         setPurchasedContributions({
  //           hasContributions: totalContributionsCount > 0,
  //           upcoming: {
  //             notBooked: upcoming.notBooked.map(createClientJourneyContributionFrom),
  //             otherIncompleted: upcoming.otherIncompleted.map(createClientJourneyContributionFrom),
  //             today: todayContributions.map(createClientJourneyContributionFrom),
  //             thisWeek: laterThisWeekContributions.map(createClientJourneyContributionFrom),
  //             thisMonth: upcoming.thisMonth.map(createClientJourneyContributionFrom),
  //             nextMonth: upcoming.nextMonth.map(createClientJourneyContributionFrom),
  //             thisYear: upcoming.thisYear.map(createClientJourneyContributionFrom),
  //             afterThisYear: upcoming.afterThisYear.map(createClientJourneyContributionFrom),
  //           },
  //           liveNotBooked: {
  //             notBooked: upcoming.notBooked.filter(s => !s.isPrerecorded).map(createClientJourneyContributionFrom),
  //             otherIncompleted: upcoming.otherIncompleted
  //               .filter(s => !s.isPrerecorded)
  //               .map(createClientJourneyContributionFrom),
  //             today: todayContributions
  //               .filter(s => !s.isPrerecorded && !s.isCompleted)
  //               .map(createClientJourneyContributionFrom),
  //             thisWeek: laterThisWeekContributions
  //               .filter(s => !s.isPrerecorded && !s.isCompleted)
  //               .map(createClientJourneyContributionFrom),
  //             thisMonth: upcoming.thisMonth
  //               .filter(s => !s.isPrerecorded && !s.isCompleted)
  //               .map(createClientJourneyContributionFrom),
  //             nextMonth: upcoming.nextMonth
  //               .filter(s => !s.isPrerecorded && !s.isCompleted)
  //               .map(createClientJourneyContributionFrom),
  //             thisYear: upcoming.thisYear
  //               .filter(s => !s.isPrerecorded && !s.isCompleted)
  //               .map(createClientJourneyContributionFrom),
  //             afterThisYear: upcoming.afterThisYear
  //               .filter(s => !s.isPrerecorded && !s.isCompleted)
  //               .map(createClientJourneyContributionFrom),
  //           },
  //           liveupcoming: {
  //             notBooked: [],
  //             otherIncompleted: [],
  //             today: todayContributions.filter(s => !s.isPrerecorded).map(createClientJourneyContributionFrom),
  //             thisWeek: laterThisWeekContributions
  //               .filter(s => !s.isPrerecorded)
  //               .map(createClientJourneyContributionFrom),
  //             thisMonth: upcoming.thisMonth.filter(s => !s.isPrerecorded).map(createClientJourneyContributionFrom),
  //             nextMonth: upcoming.nextMonth.filter(s => !s.isPrerecorded).map(createClientJourneyContributionFrom),
  //             thisYear: upcoming.thisYear.filter(s => !s.isPrerecorded).map(createClientJourneyContributionFrom),
  //             afterThisYear: upcoming.afterThisYear
  //               .filter(s => !s.isPrerecorded)
  //               .map(createClientJourneyContributionFrom),
  //           },
  //           selfpacedupcoming: {
  //             notBooked: upcoming.notBooked.filter(s => s.isPrerecorded).map(createClientJourneyContributionFrom),
  //             otherIncompleted: upcoming.otherIncompleted
  //               .filter(s => s.isPrerecorded)
  //               .map(createClientJourneyContributionFrom),
  //             today: todayContributions.filter(s => s.isPrerecorded).map(createClientJourneyContributionFrom),
  //             thisWeek: laterThisWeekContributions
  //               .filter(s => s.isPrerecorded)
  //               .map(createClientJourneyContributionFrom),
  //             thisMonth: upcoming.thisMonth.filter(s => s.isPrerecorded).map(createClientJourneyContributionFrom),
  //             nextMonth: upcoming.nextMonth.filter(s => s.isPrerecorded).map(createClientJourneyContributionFrom),
  //             thisYear: upcoming.thisYear.filter(s => s.isPrerecorded).map(createClientJourneyContributionFrom),
  //             afterThisYear: upcoming.afterThisYear
  //               .filter(s => s.isPrerecorded)
  //               .map(createClientJourneyContributionFrom),
  //           },
  //           past: {
  //             priorYears: past.priorYears.filter(s => s.isCompleted).map(createClientJourneyContributionFrom),
  //             thisYear: past.thisYear.filter(s => s.isCompleted).map(createClientJourneyContributionFrom),
  //             lastMonth: past.lastMonth.filter(s => s.isCompleted).map(createClientJourneyContributionFrom),
  //             thisMonth: past.thisMonth.filter(s => s.isCompleted).map(createClientJourneyContributionFrom),
  //             thisWeek: past.thisWeek.filter(s => s.isCompleted).map(createClientJourneyContributionFrom),
  //           },
  //           livepast: {
  //             priorYears: past.priorYears
  //               // .filter(s => !s.isPrerecorded && s.isCompleted)
  //               .filter(s => !s.isPrerecorded)
  //               .map(createClientJourneyContributionFrom),
  //             thisYear: past.thisYear
  //               // .filter(s => !s.isPrerecorded && s.isCompleted)
  //               .filter(s => !s.isPrerecorded)
  //               .map(createClientJourneyContributionFrom),
  //             lastMonth: past.lastMonth
  //               // .filter(s => !s.isPrerecorded && s.isCompleted)
  //               .filter(s => !s.isPrerecorded)
  //               .map(createClientJourneyContributionFrom),
  //             thisMonth: past.thisMonth
  //               // .filter(s => !s.isPrerecorded && s.isCompleted)
  //               .filter(s => !s.isPrerecorded)
  //               .map(createClientJourneyContributionFrom),
  //             thisWeek: past.thisWeek
  //               .filter(s => !s.isPrerecorded)
  //               // .filter(s => !s.isPrerecorded && s.isCompleted)
  //               .map(createClientJourneyContributionFrom),
  //           },
  //           selfpacedpast: {
  //             priorYears: past.priorYears.filter(s => s.isPrerecorded).map(createClientJourneyContributionFrom),
  //             thisYear: past.thisYear.filter(s => s.isPrerecorded).map(createClientJourneyContributionFrom),
  //             lastMonth: past.lastMonth.filter(s => s.isPrerecorded).map(createClientJourneyContributionFrom),
  //             thisMonth: past.thisMonth.filter(s => s.isPrerecorded).map(createClientJourneyContributionFrom),
  //             thisWeek: past.thisWeek.filter(s => s.isPrerecorded).map(createClientJourneyContributionFrom),
  //           },
  //           closestClassForBanner,
  //           clientDeclinedSubscriptions,
  //         });
  //       },
  //     )
  //     .catch(() =>
  //       setPurchasedContributions({
  //         hasContributions: false,
  //         upcoming: null,
  //         liveupcoming: null,
  //         selfpacedupcoming: null,
  //         past: null,
  //         livepast: null,
  //         selfpacedpast: null,
  //         closestClassForBanner: null,
  //         clientDeclinedSubscriptions: null,
  //       }),
  //     );
  // }, [user.id]);

  useEffect(() => {
    Promise.all([
      contributionService.GetAllBoughtByUserIdUpdated(user.id),
      purchaseService.getCoaches(),
      contributionService.getClientPurchases(user.id, false),
    ])
      .then(
        ([
          {
            upcoming,
            upcomingTotalCount,
            past,
            pastTotalCount,
            closestClassForBanner,
            clientDeclinedSubscriptions,
            modules,
            inCompletetd,
            bookable,
          },
          myCoaches,
          contributions,
        ]) => {
          setAllContributions(contributions);
          setCoaches(myCoaches);
          const totalContributionsCount = upcomingTotalCount + pastTotalCount;
          setPurchasedContributions({
            hasContributions: totalContributionsCount > 0,
            upcoming,
            past,
            modules,
            inCompleted: inCompletetd,
            inCompletedlive: inCompletetd?.filter(c => c.isPrerecorded === false),
            inCompletedselfpaced: inCompletetd?.filter(c => c.isPrerecorded === true),
            upcominglive: upcoming?.filter(c => c.isPrerecorded === false),
            upcomingselfpaced: upcoming?.filter(c => c.isPrerecorded === true),
            closestClassForBanner,
            clientDeclinedSubscriptions,
            bookable,
          });
        },
      )
      .catch(() =>
        setPurchasedContributions({
          hasContributions: false,
          upcoming: null,
          past: null,
          modules: null,
          inCompleted: null,
          inCompletedlive: null,
          inCompletedselfpaced: null,
          upcominglive: null,
          upcomingselfpaced: null,
          closestClassForBanner: null,
          clientDeclinedSubscriptions: null,
          bookable: null,
        }),
      );
  }, [user.id, refetchContributons]);

  if (!purchasedContributions) {
    return <Loader />;
  }
  if (isEmpty(coaches) && !purchasedContributions.hasContributions && allContributions.length === 0) {
    return (
      <IntroScreenClient
        onExploreCohere={() => {}}
        closestClassForBanner={purchasedContributions.closestClassForBanner}
      />
    );
  }

  // return (
  //   <ClientJourney
  //     upcomingNotBooked={purchasedContributions.liveNotBooked}
  //     upcomingContributions={purchasedContributions.upcoming}
  //     liveupcomingContributions={purchasedContributions.liveupcoming}
  //     selfpacedupcomingContributions={purchasedContributions.selfpacedupcoming}
  //     pastContributions={purchasedContributions.past}
  //     livepastContributions={purchasedContributions.livepast}
  //     selfpacedpastContributions={purchasedContributions.selfpacedpast}
  //     clientDeclinedSubscriptions={purchasedContributions.clientDeclinedSubscriptions}
  //     hasContributions={purchasedContributions.hasContributions}
  //     coaches={coaches}
  //   />
  // );

  return (
    <ClientJourney
      hasContributions={purchasedContributions.hasContributions}
      bookable={purchasedContributions.bookable}
      upcoming={purchasedContributions.upcoming}
      past={purchasedContributions.past}
      modules={purchasedContributions.modules}
      inCompleted={purchasedContributions.inCompleted}
      inCompletedlive={purchasedContributions.inCompletedlive}
      inCompletedselfpaced={purchasedContributions.inCompletedselfpaced}
      upcominglive={purchasedContributions.upcominglive}
      upcomingselfpaced={purchasedContributions.upcomingselfpaced}
      closestClassForBanner={purchasedContributions.closestClassForBanner}
      clientDeclinedSubscriptions={purchasedContributions.clientDeclinedSubscriptions}
      coaches={coaches}
    />
  );
};

export default ClientDashboardContainer;
