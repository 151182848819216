import React from 'react';
import moment from 'moment';
import { TIMEZONES } from 'constants.js';
import styled from 'styled-components';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

const Label = styled.span``;

const StyledDateAndTime = styled.div`
  display: flex;
  font-weight: 600;
  margin-top: 5px;
`;

const sessionDateFormat = 'MMM Do, YYYY hh:mmA';
const sessionTimeFormat = 'hh:mmA';

const SessionDateAndTime = ({ session: { title, start, end, serviceProviderName }, timeZoneId }) => {
  return (
    <>
      <Label>
        Session Date and Time: <b>{serviceProviderName}</b>
      </Label>
      <StyledDateAndTime>
        <CalendarMonthIcon />
        {moment(start).format(sessionDateFormat)} - {moment(end).format(sessionTimeFormat)}
      </StyledDateAndTime>
    </>
  );
};

export default SessionDateAndTime;
