import { isNil } from 'lodash';
import { Monad } from './core';

const Then = data => ({
  ...Monad(data),
  map: fn => Then(fn(data)),
  then: fn => Then(fn(data)),
  else: () => Then(data),
});

const Else = {
  ...Monad(),
  map: () => Else,
  then: () => Else,
  else: fn => Then(fn()),
};

function If(data) {
  return !isNil(data) && data !== false ? Then(data) : Else;
}

export { If as default };
