export const handleLeadMagnetDownload = (videoUrl, fileName) => {
  if (!fileName || !videoUrl) {
    return;
  }
  const parsedUrl = new URL(videoUrl);
  // const filename = parsedUrl.searchParams?.get('response-content-disposition');
  // const newFilename = `attachment; filename=${fileName}`;
  // parsedUrl.searchParams.set('response-content-disposition', newFilename);
  // console.info('filename:', fileName, videoUrl, parsedUrl);
  const anchorElement = document.createElement('a');
  // anchorElement.setAttribute('target', '_blank');
  anchorElement.href = videoUrl;
  anchorElement.download = fileName;
  anchorElement.click();
};
