import React from 'react';
import SvgHandler from 'pages/ContributionView/components/ContributionHeader/ApplicationForm/SvgHandler';
import PropTypes from 'prop-types';

const AddCircleOutlineIcon = ({ fillColor }) => {
  return (
    <SvgHandler
      fill={fillColor}
      viewBox="0 0 46 46"
      className="top-15 cursor-pointer MuiSvgIcon-root"
      style={{ margin: '' }}
      pathD="M0.500012 22.9744C0.512796 10.6122 10.6506 0.487228 23.0256 0.500012C35.4006 0.512796 45.5 10.6122 45.5 23C45.5 35.3878 35.4006 45.5 23.0256 45.5C10.6378 45.5128 0.487228 35.3494 0.500012 22.9744ZM41.4219 23.0256C41.4602 12.9006 33.2656 4.65484 23.0895 4.59092C12.9389 4.527 4.65484 12.7216 4.59092 22.8977C4.527 33.0867 12.7727 41.3835 22.9872 41.4091C33.1378 41.4475 41.3708 33.2273 41.4219 23.0256Z M25.0582 20.9545C27.1165 20.9545 29.1108 20.9545 31.1051 20.9545C31.9872 20.9545 32.6392 21.338 33.0227 22.1306C33.6747 23.4985 32.6903 25.0326 31.1307 25.0454C29.3026 25.071 27.4616 25.0454 25.6335 25.0454C25.4673 25.0454 25.2883 25.0454 25.0582 25.0454C25.0582 25.2628 25.0582 25.4289 25.0582 25.5951C25.0582 27.4233 25.071 29.2642 25.0582 31.0923C25.0582 32.0383 24.5213 32.7926 23.6903 33.0866C22.8977 33.3679 22.0156 33.1378 21.4659 32.4985C21.108 32.0895 20.9545 31.5909 20.9545 31.0412C20.9673 29.2258 20.9545 27.4105 20.9545 25.5824C20.9545 25.4162 20.9545 25.2372 20.9545 25.0199C20.7372 25.0199 20.5582 25.0199 20.392 25.0199C18.5639 25.0199 16.723 25.0199 14.8949 25.0199C13.6037 25.0199 12.6705 24.1505 12.6832 22.9616C12.696 21.7727 13.6165 20.9289 14.9205 20.9289C16.9148 20.9289 18.9091 20.9289 20.9673 20.9289C20.9673 20.7372 20.9673 20.571 20.9673 20.4048C20.9673 18.5767 20.9673 16.7358 20.9673 14.9076C20.9673 13.642 21.8366 12.7216 23.0128 12.7216C24.1761 12.7216 25.0582 13.642 25.071 14.9076C25.0838 16.723 25.071 18.5383 25.071 20.3664C25.0582 20.5454 25.0582 20.7244 25.0582 20.9545Z"
    />
  );
};

AddCircleOutlineIcon.propTypes = {
  fillColor: PropTypes.string.isRequired,
};

export default AddCircleOutlineIcon;
