import React, { useState } from 'react';
import classes from './UnsubCard.module.scss';
import Card from '../Card/Card';
import styled from 'styled-components';
import Button from 'components/FormUI/Button';
import Select from 'components/UI/Select';
import { TextField } from '@material-ui/core';
import { EMAIL_SUBSCRIPTION_STATUS } from 'pages/Unsubscribe/Unsubscribe';
import { unsubscribeEmailCampaign } from 'pages/CreateCampaign/Services/CreateCampaign.service';
import Loader from 'components/UI/Loader';
const ReasonsList = [
  {
    title: 'I am no longer interested in receiving these emails',
    value: 'I am no longer interested in receiving these emails',
  },
  {
    title: 'I have never subscribed to this mailing list',
    value: 'I have never subscribed to this mailing list',
  },
  {
    title: 'The emails are too frequent',
    value: 'The emails are too frequent',
  },
  {
    title: 'Other (fill in the reason below)',
    value: 'Other',
  },
];
const UnsubCard = ({ clientEmail, coachId, onSubmit }) => {
  const [selectedReason, setSelectedReason] = useState(ReasonsList[0].value);
  const [otherReason, setOtherReason] = useState('');
  const isSubmitDisabled = selectedReason === 'Other' && otherReason?.length === 0;
  const handleSubmitClick = () => {
    const data = {
      coachId: coachId,
      clientEmail: clientEmail,
      unsubscribeReason: selectedReason === 'Other' ? otherReason : selectedReason,
    };
    onSubmit(data);
  };
  return (
    <Card header={clientEmail}>
      <div className={classes.body}>
        <div className={classes.status}>Unsubscribe</div>
        <div className={classes.msg}>We are sorry to see you go.</div>
        <div className={classes.final_email}>You will receive one final email confirming your unsubscribe request.</div>
        <div className={classes.reason}>Please let us know why you choose to unsubscribe</div>
        <Select
          className={classes.select_field}
          variant="outlined"
          input=""
          fullWidth
          items={ReasonsList}
          value={selectedReason}
          onChange={e => {
            setSelectedReason(e.target.value);
            setOtherReason('');
          }}
        />
        {selectedReason === 'Other' && (
          <TextField
            className={classes.input_field}
            name="otherReason"
            id="outlined-basic"
            margin="normal"
            variant="outlined"
            fullWidth
            value={otherReason}
            multiline
            minRows={6}
            onChange={e => {
              setOtherReason(e.target.value);
            }}
            FormHelperTextProps={{
              style: {
                textAlign: 'right',
                marginRight: 0,
              },
            }}
            inputProps={{ maxLength: 1000 }}
            helperText={`${otherReason?.length}/1000`}
            onPaste={e => {
              e.preventDefault();
            }}
          />
        )}
        <Button className={classes.submit_btn} autoWidth onClick={handleSubmitClick} disabled={isSubmitDisabled}>
          Submit
        </Button>
      </div>
    </Card>
  );
};
export default UnsubCard;
