import React, { useRef, useCallback, useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { Form, Formik } from 'formik';
import { useAccount, useRouter } from 'hooks/index';
import { connect, useDispatch, useSelector } from 'react-redux';
import { parseDate } from 'utils/datesAndMoney';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { addhttp, isValidLink } from 'utils/utils';
import moment from 'moment';
import BusinessInformation from 'pages/Profile/components/sections/BusinessInformation';
import Contributions from 'pages/Profile/components/sections/Contributions';
import GeneralSettings from 'pages/Profile/components/sections/GeneralSettings';
import { CopyIcon } from 'pages/Profile/components/icons/Copy';
import Settings from 'pages/Profile/components/sections/Settings';
import './WebsitePage.scss';
import OtherLinks from 'pages/Profile/components/sections/OtherLinks';
import Button from 'components/FormUI/Button';
import * as userActions from 'actions/user';
import * as timeZoneActions from 'actions/timeZone';
import * as countryActions from 'actions/country';
import { useMediaQuery, useTheme } from '@material-ui/core';

const DEFAULT_CONTRIBUTION_LABEL = 'Let’s work together';
const DEFAULT_LINKS_LABEL = 'Additional Links and Resources';
const MIN_STRING_LENGTH = 3;
const MAX_STRING_LENGTH = 100;
export const BIO_MAX_LENGTH = 1000;

const WebsitePage = ({
  user,
  editProfile,
  loading,
  error,
  timeZoneLoading,
  timeZones,
  fetchTimeZones,
  countryLoading,
  countries,
  fetchCountries,
}) => {
  const formRef = useRef(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(993));

  const { user: loggedInUser } = useAccount();
  const dispatch = useDispatch();
  const [profileLinkName, setProfileLinkName] = useState((user && user.profileLinkName) || '');
  const [isValidLinks, setIsValidLinks] = useState(true);
  const { parentDomain } = useRouter();

  const [copiedSuccess, setCopiedSuccess] = useState({ copied: '' });
  const editHandler = useCallback(
    values => {
      const { profilePageViewModel, ...rest } = values;
      if (
        values.additionalLinksLabel?.length <= 2 ||
        values.additionalLinksLabel?.length > 50 ||
        values.contributionLabel?.length <= 2 ||
        values.contributionLabel?.length > 50
      ) {
        return;
      }
      const userData = {
        ...rest,
        profilePageViewModel: {
          ...profilePageViewModel,
          additionalLinksLabel: values.additionalLinksLabel,
          contributionLabel: values.contributionLabel,
          userId: user.id,
        },
        id: user.id,
        accountId: user.accountId,
        BirthDate: moment.utc(values.BirthDate).format(),
        serviceAgreementType: user.serviceAgreementType,
        isBetaUser: user.isBetaUser,
        isStandardAccount: user.isStandardAccount,
        defaultPaymentMethod: user.defaultPaymentMethod,
      };
      if (userData.additionalLinksLabel || userData.contributionLabel) {
        delete userData.additionalLinksLabel;
        delete userData.contributionLabel;
      }
      if (!userData.CustomerLabelPreference) {
        delete userData.CustomerLabelPreference;
      }

      editProfile(user.id, userData);
      userActions.getProfile(user.id, dispatch);
    },
    [editProfile, user.accountId, user.avatarUrl, user.id],
  );

  const initialValues = {
    FirstName: user.firstName,
    LastName: user.lastName,
    BirthDate: parseDate(user.birthDate, 'YYYY-MM-DD'),
    BusinessName: user.businessName,
    BusinessType: user.businessType,
    CustomBusinessType: user.customBusinessType || '',
    Email: user.email,
    Address: user.address || '',
    Certification: user.certification,
    Occupation: user.occupation,
    AvatarUrl: user.avatarUrl || '',
    profileLinkName: user.profileLinkName,
    CustomerLabelPreference: user.customerLabelPreference || '',
    Bio: user.bio || '',
    profession: user?.profession || 'coach',
    TimeZoneId: user.timeZoneId || 'America/Los_Angeles',
    CountryId: user.countryId || timeZones?.find(t => t.countryName === user.timeZoneId)?.countryId || '',
    HasAgreedToTerms: true,
    brandingColors: {
      PrimaryColorCode: user?.brandingColors?.PrimaryColorCode || '#CDBA8F',
      AccentColorCode: user?.brandingColors?.AccentColorCode || '#116582',
      TertiaryColorCode: user?.brandingColors?.TertiaryColorCode || '#F6E8BO',
      TextColorCode: user?.brandingColors?.TextColorCode || '#FFFFFF',
    },
    contributionLabel:
      user?.profilePageViewModel &&
      !!user?.profilePageViewModel?.contributionLabel?.length &&
      user?.profilePageViewModel?.contributionLabel !== null
        ? user?.profilePageViewModel?.contributionLabel
        : DEFAULT_CONTRIBUTION_LABEL,
    additionalLinksLabel:
      user?.profilePageViewModel &&
      !!user?.profilePageViewModel?.additionalLinksLabel?.length &&
      user?.profilePageViewModel?.additionalLinksLabel !== null
        ? user?.profilePageViewModel?.additionalLinksLabel
        : DEFAULT_LINKS_LABEL,
    customLogo: user.customLogo || null,
    profilePageViewModel: {
      ...(user?.profilePageViewModel || {}),
      UpdationAllowed: true,
    },
    isProfileActive: user?.isProfileActive || false,
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setCopiedSuccess({ copied: '' });
    }, 2000);

    return () => {
      clearTimeout(timer);
    };
  }, [copiedSuccess]);

  return (
    <>
      <Grid container className="website-form">
        <Formik
          innerRef={formRef}
          initialValues={initialValues}
          enableReinitialize
          validationSchema={Yup.object().shape({
            FirstName: Yup.string().strict(true).min(MIN_STRING_LENGTH).max(MAX_STRING_LENGTH).required(),
            LastName: Yup.string().strict(true).min(MIN_STRING_LENGTH).max(MAX_STRING_LENGTH).required(),
            TimeZoneId: Yup.mixed().required(),
            CountryId: Yup.mixed().required(),
            profession: Yup.mixed().required(),
            Address: Yup.mixed(),
            Email: Yup.mixed().required(),
            contributionLabel: Yup.string()
              .strict(true)
              .min(2, 'Must be at least 2 characters')
              .max(50, 'Cannot exceed 50 characters')
              .required('This is a required field'),
            additionalLinksLabel: Yup.string()
              .strict(true)
              .min(2, 'Must be at least 2 characters')
              .max(50, 'Cannot exceed 50 characters')
              .required('This is a required field'),
            Bio: Yup.string().strict(true).max(BIO_MAX_LENGTH),
            profilePageViewModel: Yup.object().shape({
              customLinks: Yup.array().of(
                Yup.object().shape({
                  uniqueName: Yup.string().required('Unique Name is required'),
                  link: Yup.string()
                    .required('Link is required')
                    .test('isValid', 'Invalid Link', value => {
                      return isValidLink(value);
                    }),
                }),
              ),
            }),
          })}
          onSubmit={editHandler}
        >
          {({ setFieldValue, handleChange, values, errors, touched, setTouched, setErrors }) => {
            return (
              <>
                <Form>
                  <Grid style={{ padding: '30px 15px 30px 25px' }} container className="website-form-container">
                    <Grid item className="website-info" xl={9} lg={8} md={7} sm={12} xs={12}>
                      <BusinessInformation
                        user={user}
                        setFieldValues={setFieldValue}
                        values={values}
                        profileLinkName={profileLinkName}
                        setProfileLinkName={setProfileLinkName}
                      />
                      <Contributions user={user} timeZones={timeZones} values={values} setFieldValues={setFieldValue} />
                      <OtherLinks
                        user={user}
                        values={values}
                        errors={errors}
                        touched={touched}
                        setFieldValues={setFieldValue}
                        setIsValidLinks={setIsValidLinks}
                        setTouched={setTouched}
                        setErrors={setErrors}
                      />
                    </Grid>

                    <Grid item className="general-settings-column" xl={3} lg={4} md={5} sm={12} xs={12}>
                      <GeneralSettings values={values} setFieldValue={setFieldValue} />
                      <Grid item xs={12} className="branding-container profile-section">
                        {profileLinkName && (
                          <Grid item xs={12} style={{ marginTop: '20px' }}>
                            <div className="heading">
                              <p
                                style={{ fontFamily: 'Avenir', fontSize: '24px', fontWeight: 500, lineHeight: '24px' }}
                              >
                                Your Page Link
                              </p>
                            </div>
                          </Grid>
                        )}
                        {profileLinkName && (
                          <Grid item xs={12} className="message-settings">
                            <p className="website-url">
                              {profileLinkName}.{parentDomain}
                            </p>
                            <CopyIcon
                              onClick={() => {
                                window.navigator.clipboard.writeText(profileLinkName.concat('.', parentDomain));
                                setCopiedSuccess({ copied: 'Copied!' });
                              }}
                            />
                            <p>{copiedSuccess.copied}</p>
                          </Grid>
                        )}
                      </Grid>
                      <Settings values={values} setFieldValue={setFieldValue} />
                    </Grid>
                  </Grid>
                  <Grid container className="page-footer-buttons">
                    <Button className="submit-button" type="submit" disabled={loading}>
                      Save & Update
                    </Button>
                    <Button
                      className="preview-button"
                      disabled={loading || !profileLinkName}
                      onClick={event => {
                        event.preventDefault();
                        if (profileLinkName) {
                          window.open(addhttp(profileLinkName.concat('.', parentDomain)), '_blank').focus();
                        }
                      }}
                      title={
                        !profileLinkName
                          ? 'Please confirm your website URL in the below Website and Business Information section to preview your website'
                          : ''
                      }
                    >
                      Preview Website
                    </Button>
                  </Grid>
                </Form>
              </>
            );
          }}
        </Formik>
      </Grid>
    </>
  );
};
WebsitePage.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string.isRequired,
    accountId: PropTypes.string.isRequired,
    lastName: PropTypes.string,
    firstName: PropTypes.string,
    birthDate: PropTypes.string,
    businessType: PropTypes.string,
    occupation: PropTypes.string,
    certification: PropTypes.string,
    customBusinessType: PropTypes.string,
    timeZoneId: PropTypes.string,
    profileLinkName: PropTypes.string,
    timeZone: PropTypes.shape({
      timeZones: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    timeZoneLoading: PropTypes.bool.isRequired,
    fetchTimeZones: PropTypes.func.isRequired,
    avatarUrl: PropTypes.string,
    customerLabelPreference: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    businessName: PropTypes.string,
    bio: PropTypes.string,
  }).isRequired,
  loading: PropTypes.bool.isRequired,
  editProfile: PropTypes.func.isRequired,
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      message: PropTypes.string,
    }),
  ]),
  country: PropTypes.shape({
    countries: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  countryLoading: PropTypes.bool.isRequired,
  fetchCountries: PropTypes.func.isRequired,
};

WebsitePage.defaultProps = {
  error: null,
  fetchTimeZones: () => null,
};

const mapStateToProps = ({ account, timeZone, country }) => ({
  user: account?.user || {},
  loading: account?.isLoading,
  error: account?.error,
  timeZones: timeZone?.timeZones,
  timeZoneLoading: timeZone?.loading,
  countries: country?.countries,
  countryLoading: country?.loading,
});

const actions = {
  getUser: userActions.getProfile,
  editProfile: userActions.editProfile,
  fetchTimeZones: timeZoneActions.fetchTimeZones,
  fetchCountries: countryActions.fetchCountries,
};

export default connect(mapStateToProps, actions)(WebsitePage);
