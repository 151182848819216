export const ContributionThemedColors = {
  DarkThemedColor: 'white',
  DarkThemedColorBtn: 'white',
  DarkThemedBackgroundColor: '#252728',
  DarkThemedBackgroundColorBtn: '#C9B382',
  DarkThemedCardBackgroundColor: '#2D2F31',
  DarkThemedCardOutlineColor: '#696969',
  LightThemedColor: '#282b2b',
  LightThemedColorBtn: '#ffffff',
  LightThemedBackgroundColor: '#fafafa',
  LightThemedBackgroundColorBtn: '#C9B382',
  LightThemedCardBackgroundColor: 'white',
  LightThemedCardOutlineColor: '#e7e7e7',
  NewDarkThemedBackgroundColor: '#252728', //#252728
  NewLightThemedBackgroundColor: '#FFF', //#FFF
  NewDarkThemedTextColor: '#FFF', //#FFF
  NewLightThemedTextColor: '#282B2B', //#282B2B
  NewDarkThemedCardColor: '#2D2F31', //#2D2F31
  NewLightThemedCardColor: '#F7F9F9', //#F7F9F9
};
