import { LeadMagnetType } from '../helpers/constants';

const list = {
  [LeadMagnetType.LeadMagnetAudioVideo]: 'Video/Audio',
  [LeadMagnetType.LeadMagnetPDF]: 'PDF',
  [LeadMagnetType.LeadMagnetQuiz]: 'Quiz',
};

export const LeadMagnetTypesMap = list;

export const getLeadMagnetTypeView = rawStatus => list[rawStatus] || 'Unknown';
