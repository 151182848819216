import React from 'react'
import PropTypes from 'prop-types'
import IconButton from '@material-ui/core/IconButton'
import CallEndRoundedIcon from '@material-ui/icons/CallEndRounded'

import ToolbarAction from './ToolbarAction'

const EndCall = ({ enabled, handleClick }) => {
  const EndCallIcon = enabled ? CallEndRoundedIcon : CallEndRoundedIcon
  const label = enabled ? 'Leave session' : ''

  return (
    <ToolbarAction label={label}>
      <IconButton color="inherit" onClick={handleClick}>
        <EndCallIcon style={{ transform: 'rotate(320deg)' }} />
      </IconButton>
    </ToolbarAction>
  )
}

EndCall.propTypes = {
  enabled: PropTypes.bool.isRequired,
  handleClick: PropTypes.func.isRequired,
}

export default EndCall
