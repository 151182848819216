import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import Loader from 'components/UI/Loader';
import Card from '@material-ui/core/Card';
import useRouter from 'hooks/useRouter';
import { addhttp } from 'utils/utils';
import ChatButton from 'components/UI/ListButtons/ChatButton';
import { getInitialSymbol } from 'components/UI/Header';
import { Avatar, IconButton } from '@material-ui/core';
import NoteIcon from 'pages/ClientPage/components/NoteIcon';

export const ClientResponsive = ({ index, row, setClientId }) => {
  const { id, name, image, recentContributionId, recentContributionName, revenueEarned } = row;
  const contributionURL = `/contribution-view/${recentContributionId}/about`;
  const { domain } = useRouter();
  const [loader, setLoader] = useState(false);
  const currencies = Object.keys(revenueEarned);
  const clientURL = `/client/${id}`;

  return (
    <>
      <Grid container className="m-2 applications-table" key={index}>
        <Grid item xs={12}>
          <Card className="p-3 m-1 card-radius">
            <Grid container spacing={2}>
              <Grid item xs={12} className="p-1">
                <div style={{ display: 'flex' }}>
                  <Avatar
                    alt={name}
                    src={image}
                    style={{
                      margin: 'auto 10px auto 0',
                      width: '30px',
                      height: '30px',
                      fontSize: '16px',
                    }}
                  >
                    {getInitialSymbol(name)}
                  </Avatar>
                  <Link to={clientURL} className="d-flex">
                    <p style={{ color: '#4A4A4A', margin: 'auto 0', fontSize: '14px' }}>{name}</p>
                  </Link>
                </div>
              </Grid>
              <Grid item xs={12} className="p-1">
                <Link to={contributionURL}>
                  <p style={{ fontSize: '14px', fontWeight: 'bold' }} className="link p-0 mb-0">
                    {recentContributionName}
                  </p>
                </Link>
              </Grid>
              <Grid item xs={12} className="p-1">
                <p style={{ color: '#4A4A4A', margin: '0', fontSize: '14px' }} className="p-0 mb-0">
                  Revenue Earned:{' '}
                  {currencies.reduce((accumulatedVal, currentVal) => {
                    return accumulatedVal
                      .concat(accumulatedVal ? ',' : '')
                      .concat(revenueEarned[currentVal])
                      .concat(` ${currentVal?.toUpperCase()}`);
                  }, '')}
                </p>
              </Grid>
              <Grid item xs={12} className="p-1">
                <span className="float-right">
                  <IconButton className="p-1 icon-button" onClick={() => setClientId(id)}>
                    <NoteIcon fillColor="#215C73" />
                  </IconButton>
                  <ChatButton
                    className="p-1 icon-button"
                    handleClick={() => {
                      window.location.href = addhttp(domain.concat(`/conversations/all?chatId=${id}`));
                    }}
                  />
                </span>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
      {loader && <Loader />}
    </>
  );
};
ClientResponsive.propTypes = {
  index: PropTypes.string.isRequired,
  row: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    recentContributionId: PropTypes.string.isRequired,
    recentContributionName: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    revenueEarned: PropTypes.string.isRequired,
  }),
  setClientId: PropTypes.func.isRequired,
};

ClientResponsive.defaultProps = {
  row: {
    id: '',
    name: '',
    recentContributionId: '',
    recentContributionName: '',
    image: '',
    revenueEarned: {
      usd: '$0.0',
    },
  },
};
