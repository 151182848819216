export const CLIENTPREVIEWMODE_ON = 'CLIENTPREVIEW_ON';
export const CLIENTPREVIEWMODE_OFF = 'CLIENTPREVIEW_OFF';

export const ClientPreviewModeOn = backLink => ({
  type: CLIENTPREVIEWMODE_ON,
  payload: backLink,
});
export const ClientPreviewModeOff = backLink => ({
  type: CLIENTPREVIEWMODE_OFF,
  payload: backLink,
});
