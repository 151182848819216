import React from 'react';
import { Card } from 'components/UI/Card';
import Logo from 'components/FormUI/Logo';
import styled, { css } from 'styled-components';
import { IconStudent } from 'components/Icons/IconStudent';
import { colors } from 'utils/styles';
import { IconCoach } from 'components/Icons/IconCoach';
import { useTheme, useMediaQuery } from '@material-ui/core';
import { LinkButton } from 'components/FormUI/LinkButton';

const CONTAINER_WIDTH = 770;
const Container = styled.div`
  max-width: ${CONTAINER_WIDTH}px;
  display: flex;
  align-items: stretch;
  padding: 40px 0;
  box-sizing: border-box;
  div:first-child {
    border-right: 1px solid #ededed;
  }
  ${({ mobileView }) =>
    mobileView &&
    css`
      flex-direction: column;
      div:first-child {
        border: none;
        margin-bottom: 2rem;
      }
    `}
`;

const StyledCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  padding: 0 40px;
  ${({ mobileView }) =>
    mobileView &&
    css`
      padding: 0 20px;
      width: 100%;
    `}
`;

const AccountType = styled.h1`
  font-size: 1.5rem;
  text-align: center;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 1.5rem;
`;

const AccountDescription = styled.h2`
  font-size: 1rem;
  text-align: center;
  font-weight: 300;
  line-height: 24px;
  margin-bottom: 1.5rem;
`;

const LinkButtonContainer = styled.div`
  margin-top: auto;
`;

function SignUpSelectType() {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'));
  return (
    <>
      <Logo />
      <Card>
        <Container mobileView={mobileView}>
          <StyledCard mobileView={mobileView}>
            <IconCoach
              style={{
                height: '80px',
                color: `${colors.lightBrown}`,
                padding: '10px 0',
              }}
            />
            <AccountType>Service Provider</AccountType>
            <AccountDescription>
              Select if you are an online coach or service provider. You will be able to create, sell and deliver 1:1
              sessions and live group courses.
            </AccountDescription>
            <LinkButtonContainer>
              <LinkButton autoWidth to="/auth/signup">
                I’m a Service Provider
              </LinkButton>
            </LinkButtonContainer>
          </StyledCard>

          <StyledCard mobileView={mobileView}>
            <IconStudent style={{ height: '80px', color: `${colors.lightBrown}` }} />
            <AccountType>Client</AccountType>
            <AccountDescription>
              Select if have been invited by a coach or service provider. You will be able to purchase, schedule, join
              live sessions and more.
            </AccountDescription>
            <LinkButtonContainer>
              <LinkButton autoWidth to="/auth/signup/client">
                I’m a Client
              </LinkButton>
            </LinkButtonContainer>
          </StyledCard>
        </Container>
      </Card>
    </>
  );
}

export { SignUpSelectType };
