import { createAction } from 'redux-actions';

export const SET_ACTIVE_AUDIO_OUTPUT_DEVICES = 'SET_ACTIVE_AUDIO_OUTPUT_DEVICES';

export const audioOutputDevicesActions = {
  setActiveAudioOutputDevice: createAction(SET_ACTIVE_AUDIO_OUTPUT_DEVICES),
};

export const changeAudioDevice = activeDevice => dispatch => {
  dispatch(audioOutputDevicesActions.setActiveAudioOutputDevice(activeDevice));
};
