import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Route, Redirect, Switch } from 'react-router-dom';
import { useTheme, useMediaQuery } from '@material-ui/core';

import { LiveCoursesPage, OneToOneSessionsPage, MembershipsPage } from 'pages';
import AllCoursesPage from 'pages/AllCoursesPage/AllCoursesPage';
import ArchivedPage from 'pages/ArchivedPage/ArchivedPage';
import MainContainer from './MainContainer';
import WorkshopPage from 'pages/WorkshopPage/WorkshopPage';

const StyledMainSection = styled.div`
  padding: ${({ mobileView }) => (mobileView ? '30px 5px' : '30px 55px')};
`;

function ContributionsContainer({ match: { path } }) {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <MainContainer>
      <StyledMainSection mobileView={mobileView}>
        <Switch>
          <Route path={`${path}/all`} exact component={AllCoursesPage} />
          <Route path={`${path}/group-courses`} exact component={LiveCoursesPage} />
          <Route path={`${path}/1-to-1-sessions`} exact component={OneToOneSessionsPage} />
          <Route path={`${path}/memberships`} exact component={MembershipsPage} />
          <Route path={`${path}/archived`} exact component={ArchivedPage} />
          <Route path={`${path}/workshop`} exact component={WorkshopPage} />
          <Redirect to={`${path}/all`} />
        </Switch>
      </StyledMainSection>
    </MainContainer>
  );
}

ContributionsContainer.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string,
    url: PropTypes.string,
    isExact: PropTypes.bool,
    params: PropTypes.object,
  }).isRequired,
};

export default ContributionsContainer;
