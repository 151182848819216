import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery, useTheme } from '@material-ui/core';

import logo from 'assets/logo.png';
import Verification from './components/Verification';
import Services from './components/Services';
import BankConnect from './components/BankConnect';
import TestVideo from './components/TestVideo';
import Invitation from './components/Invitation';
import Affiliate from './components/Affiliate';
import DemoVideo from './components/DemoVideo';
import {
  StyledContainer,
  StyledCloseIcon,
  StyledHeader,
  StyledCarousel,
  StyledCarouselItem,
  StyledLogo,
  StyledBackground,
} from './GettingStarted.styles';

const GettingStartedGuide = ({ isOpen, closeHandler }) => {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'));

  const handleCrossClick = useCallback(() => {
    closeHandler();
  }, [closeHandler]);

  if (!isOpen) {
    return null;
  }
  window.scrollTo(0, 0);

  const items = [DemoVideo, Verification, Services, Invitation, BankConnect, TestVideo, Affiliate];

  return (
    <>
      <StyledContainer mobileView={mobileView}>
        <StyledHeader mobileView={mobileView}>
          <StyledCloseIcon onClick={handleCrossClick} />
          <StyledLogo src={logo} mobileView={mobileView} />
        </StyledHeader>

        <StyledCarousel
          autoPlay={false}
          navButtonsAlwaysVisible={!mobileView}
          animation="slide"
          mobileView={mobileView}
        >
          {items.map(CarouselItem => (
            <StyledCarouselItem>
              <CarouselItem mobileView={mobileView} />
            </StyledCarouselItem>
          ))}
        </StyledCarousel>
      </StyledContainer>
      <StyledBackground />
    </>
  );
};

GettingStartedGuide.propTypes = {
  isOpen: PropTypes.bool,
  closeHandler: PropTypes.func.isRequired,
};

GettingStartedGuide.defaultProps = {
  isOpen: false,
};

export default GettingStartedGuide;
