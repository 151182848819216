import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import useContribution from 'pages/ContributionView/hooks/useContribution';
import { getThemedColors, determineDarkThemedColorToUse } from 'services/contributions.service';

import SortOrder from '../../SortOrder';

import './SortOrderToggle.scss';

const SortOrderToggle = ({ sortOrder, onSortOrderChange }) => {
  const contribution = useContribution();
  const { themedColor } = getThemedColors(contribution);
  const isDarkThemeEnabled = determineDarkThemedColorToUse(contribution);

  const onSortOrderToggleClick = () =>
    onSortOrderChange(currentSortOrder =>
      currentSortOrder === SortOrder.newestFirst ? SortOrder.oldestFirst : SortOrder.newestFirst,
    );

  return (
    <div className="chats-list-header-sort-order-toggle" onClick={onSortOrderToggleClick}>
      <IconButton
        style={{ color: isDarkThemeEnabled ? themedColor : {} }}
        className="chats-list-header-sort-order-toggle__button"
        onMouseDown={e => e.preventDefault()}
      >
        {sortOrder === SortOrder.newestFirst ? <ArrowDownwardIcon /> : <ArrowUpwardIcon />}
      </IconButton>
      <p
        style={{ color: isDarkThemeEnabled ? themedColor : {} }}
        className="chats-list-header-sort-order-toggle__title"
      >
        {sortOrder === SortOrder.newestFirst ? 'Newest first' : 'Oldest first'}
      </p>
    </div>
  );
};

SortOrderToggle.propTypes = {
  sortOrder: PropTypes.oneOf([SortOrder.newestFirst, SortOrder.oldestFirst]).isRequired,
  onSortOrderChange: PropTypes.func.isRequired,
};

export default SortOrderToggle;
