import React, { useState, useCallback, useEffect } from 'react';
import Modal from 'components/UI/Modal';
import { editComment } from 'services/community.service';
import {
  StyledContainerComment,
  AvatarComponentComment,
  StyledInputWrapper,
  StyledTextAreaWrap,
  StyledTextArea,
  StyledTextError,
  StyledButton,
} from '../Post/Post.styled';
import Grid from '@material-ui/core/Grid';
import { Box, CircularProgress, useMediaQuery, useTheme } from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import { mdiHeart, mdiMessage, mdiDeleteOutline, mdiDotsVertical, mdiPencilOutline } from '@mdi/js';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { addComment } from 'services/community.service';

const ROW_HEIGHT = 20;

const EditCommentModal = ({
  isOpen,
  onModalClose,
  comment,
  fetchPosts,
  user,
  sendIconColor,
  textColor,
  setComment,
}) => {
  const [commentPostPending, setCommentPostPending] = useState(false);
  const theme = useTheme();
  const [rows, setRows] = useState(1);
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'));

  const handleKeyUp = useCallback(
    ({ target: { value } }) => {
      setRows((value.match(/\n/g) || []).length);
    },
    [rows, setRows],
  );
  function countLines(text) {
    const words = text.split(' ');
    let line = '';
    let lineCount = 0;

    for (let word of words) {
      if ((line + word).length <= 100) {
        line += word + ' ';
      } else {
        lineCount++;
        line = word + ' ';
      }
    }

    // If there are remaining characters on the last line, count it as one more line
    if (line.trim().length > 0) {
      lineCount++;
    }

    return lineCount;
  }
  useEffect(() => {
    setRows(countLines(comment?.text || ''));
  }, [comment]);

  const handleCommentEdit = (e, { resetForm }) => {
    if (comment) {
      setCommentPostPending(true);
      editComment({
        ...comment,
        text: e.text,
        userId: comment.userInfo?.id,
      }).then(data => {
        const editedComment = {
          ...data,
          childComments: comment?.childComments,
          ident: comment?.ident,
        };
        setComment(editedComment);
        resetForm({});
        setRows(0);
        setCommentPostPending(false);
        onModalClose();
        // fetchPosts();
      });
    }
  };

  return (
    <Modal
      applyTheming
      title="Edit Comment"
      isOpen={isOpen}
      onCancel={onModalClose}
      disableFooter={true}
      hiddenCancel={true}
      disableConfirm={true}
    >
      <Formik
        enableReinitialize
        initialValues={{
          text: comment?.text || '',
        }}
        validateOnMount
        isInitialValid={false}
        onSubmit={handleCommentEdit}
        validationSchema={Yup.object().shape({
          text: Yup.string()
            .strict(true)
            .min(0)
            .max(1000, 'Cannot exceed 1000 characters')
            .required('This is a required field'),
        })}
        id={`edit-comment-form-${comment?.id}`}
      >
        {formProps => {
          return (
            <StyledContainerComment
              style={{ marginBottom: '10px' }}
              additionalHeight={rows * ROW_HEIGHT}
              container
              direction="row"
            >
              <AvatarComponentComment
                alt={`${user.firstName} ${user.lastName}`}
                src={user.avatarUrl}
                mobileView={mobileView}
              >
                {`${user.firstName && user.firstName[0]}${user.lastName && user.lastName[0]}`}
              </AvatarComponentComment>
              <Grid container direction="column">
                <Grid container direction="row">
                  <StyledInputWrapper
                    container
                    direction="row"
                    justify="space-between"
                    additionalHeight={rows * ROW_HEIGHT}
                  >
                    <StyledTextAreaWrap item xs={12} className="ml-3" style={{ height: '100%', marginBottom: '10px' }}>
                      <StyledTextArea
                        placeholder="What’s on your mind..."
                        component="textarea"
                        name="text"
                        fullWidth
                        onKeyUp={handleKeyUp}
                      />
                    </StyledTextAreaWrap>
                  </StyledInputWrapper>
                  <StyledButton
                    disabled={commentPostPending || !formProps.isValid}
                    autoWidth
                    type="submit"
                    id={`post-create-form-${comment?.id}`}
                    onClick={formProps.handleSubmit}
                    style={{ backgroundColor: sendIconColor }}
                  >
                    {commentPostPending ? (
                      <CircularProgress size={36} className="chat-messages-history-list__load-more-messages-progress" />
                    ) : (
                      <SendIcon fontSize="small" style={{ color: textColor }} className="smoked-gary-color hoverable" />
                    )}
                  </StyledButton>
                </Grid>
                {formProps.errors.text && formProps.dirty && (
                  <StyledTextError mobileView={mobileView}>{formProps.errors.text}</StyledTextError>
                )}
              </Grid>
            </StyledContainerComment>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default EditCommentModal;
