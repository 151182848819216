import React, { useState } from 'react';

import usePaidTier from 'hooks/usePaidTier';
import useRouter from 'hooks/useRouter';

import { ROUTES } from 'constants.js';

import DefaultBillingView from './DefaultBillingView';
import OnePlanBillingView from './OnePlanBillingView';
import ChatAndGetStartedButtons from 'pages/Dashboard/components/CohealerDashboard/LiveChat/LiveChatContainer';

const ConditionalRenderBillingPage = () => {
  // const { isScalePlan } = usePaidTier();
  const { pathname } = useRouter();

  const isDefaultBillingView = pathname.includes(ROUTES.ACCOUNT_BILLING_EXTENDED);

  const [isShowBeforeCancellationWarning, setIsShowBeforeCancellationWarning] = useState({
    isShow: false,
    label: '',
    isConfirm: false,
    userId: null,
    itemId: null,
  });
  const [isShowSuccessCancellationModal, setIsShowSuccessCancellationModal] = useState({
    isShow: false,
    label: '',
  });
  const [impossibleToUpgradeModal, setImpossibleToUpgradeModal] = useState({
    isShow: false,
    label: '',
  });
  const [isShowSuccessUpgradeModal, setIsShowSuccessUpgradeModal] = useState({
    isShow: false,
    label: '',
  });

  const MODALS_API = {
    warning_before_cancellation: {
      isShowBeforeCancellationWarning,
      setIsShowBeforeCancellationWarning,
    },
    cancelletion_success: {
      isShowSuccessCancellationModal,
      setIsShowSuccessCancellationModal,
    },
    upgrade_impossible: {
      impossibleToUpgradeModal,
      setImpossibleToUpgradeModal,
    },
    upgrade_success: {
      isShowSuccessUpgradeModal,
      setIsShowSuccessUpgradeModal,
    },
  };
  // if (isScalePlan || isDefaultBillingView) {

  if (isDefaultBillingView) {
    return (
      <>
        <DefaultBillingView modalsApi={MODALS_API} />

        <ChatAndGetStartedButtons page={'ProfilePage'} />
      </>
    );
  }
  return (
    <>
      {' '}
      <OnePlanBillingView modalsApi={MODALS_API} />
      <ChatAndGetStartedButtons page={'ProfilePage'} />
    </>
  );
};

export default ConditionalRenderBillingPage;
