import React from 'react'
import PropTypes from 'prop-types'

import { useLocalParticipant, useLocalAudioTrack, useLocalVideoTrack, useLocalScreenTrack } from '../../../hooks'
import { useNetworkQualityLevel } from '../../../hooks/participants'

import Participant from './Participant'
import ParticipantDisplayMode from './ParticipantDisplayMode'

const RoomLocalParticipant = React.forwardRef(({ participant, selectedAudioOutputDevice, ...restProps }, ref) => {
  const {
    audioTrack: localAudioTrack,
    videoTrack: localVideoTrack,
    screenTrack: localScreenTrack,
  } = useLocalParticipant(participant)
  const { isEnabled: isAudioEnabled } = useLocalAudioTrack(participant, localAudioTrack)
  const { videoTrack, isEnabled: isVideoEnabled } = useLocalVideoTrack(participant, localVideoTrack)
  const { screenTrack, isEnabled: isScreenEnabled } = useLocalScreenTrack(participant, localScreenTrack)

  const networkQualityLevel = useNetworkQualityLevel(participant)

  return (
    <Participant
      {...restProps}
      ref={ref}
      identity={participant.identity}
      networkQualityIndicator={networkQualityLevel}
      audioTrack={null}
      isAudioEnabled={isAudioEnabled}
      audioVolume={0}
      videoTrack={videoTrack}
      isVideoEnabled={isVideoEnabled}
      isVideoMirrored
      screenTrack={screenTrack}
      isScreenEnabled={isScreenEnabled}
      displayMode={ParticipantDisplayMode.video}
      selectedAudioOutputDevice={selectedAudioOutputDevice}
    />
  )
})

RoomLocalParticipant.propTypes = {
  participant: PropTypes.shape({
    identity: PropTypes.string,
  }).isRequired,
}

export default RoomLocalParticipant
