import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import useContribution from 'pages/ContributionView/hooks/useContribution';
import { memberPodsSelector } from 'selectors/MemberPods';

export const useSetInitialSelectedValues = ({ memberId, setState, state }) => {
  const { id: contributionId } = useContribution();
  const { contributionMemberPods } = useSelector(memberPodsSelector);
  const { initialSelectedValues, isFeatureAllowed } = state;

  useEffect(() => {
    if (!isFeatureAllowed) {
      return;
    }

    if (!contributionMemberPods?.[contributionId] && initialSelectedValues) {
      setState({
        initialSelectedValues: null,
        selectedValues: [],
      });

      return;
    }

    if (initialSelectedValues) {
      return;
    }

    if (Array.isArray(contributionMemberPods?.[contributionId])) {
      const result = contributionMemberPods?.[contributionId].reduce((acc, next) => {
        const { clientIds, id } = next;

        return clientIds?.includes(memberId) ? [...acc, id] : acc;
      }, []);

      setState({
        initialSelectedValues: result,
        selectedValues: result,
      });
    }
  }, [contributionId, contributionMemberPods, initialSelectedValues, isFeatureAllowed, memberId, setState]);
};
