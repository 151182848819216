import Modal from 'components/UI/Modal';
import { Formik, getIn } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import PropTypes, { bool, string } from 'prop-types';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Checkbox, List, ListItem } from '@material-ui/core';
import styled from 'styled-components';

const StyledListItem = styled(ListItem)`
  display: flex !important;
  padding: 12px !important;
  align-items: center;
  justify-content: space-between !important;
  border: 1px solid ${props => (props.$error ? 'red' : '#f5f5f5')} !important;
  border-radius: 4px !important;
  ${props => (props.$active ? `background: ${props.$darkMode ? '#121314' : '#F1F8FF'};` : '')}
  font-size: 18px !important;
  user-select: none;
  margin-bottom: ${props => `${props.$margin}px`};
`;

function MoveToSectionModal({ isOpen, onCancel, onSubmit, sections, groupToEdit, isDarkThemeEnabled }) {
  const [sectionOptions, setSectionOptions] = useState([]);
  useEffect(() => {
    if (groupToEdit) {
      const sectionsToShow = sections?.filter(section => {
        if (section.isAnonymous) {
          return false;
        }
        if (groupToEdit.isSuggested) {
          return section?.groups?.filter(group => group.isSuggested && group.title === groupToEdit.title).length === 0;
        }
        if (section.sectionId !== groupToEdit.sectionId) {
          return true;
        }
        return false;
      });
      setSectionOptions(sectionsToShow);
    }
  }, [groupToEdit]);

  const formRef = useRef(null);

  const validationSchema = Yup.object().shape({
    sectionId:
      sectionOptions && sectionOptions.length === 0
        ? Yup.string()
        : Yup.string().required('Please select a section to move the group into'),
  });

  return (
    <Modal
      className="create-section-popup"
      isOpen={isOpen}
      title="Move to another section"
      applyTheming
      submitTitle="Move"
      hiddenCancel
      onCancel={onCancel}
      onSubmit={() => formRef.current.handleSubmit()}
    >
      <Formik
        innerRef={formRef}
        initialValues={{ sectionId: '' }}
        validationSchema={validationSchema}
        onSubmit={values => {
          onSubmit(values);
        }}
      >
        {({ setFieldValue, values, errors, setFieldTouched }) => (
          <>
            <List>
              {sectionOptions && sectionOptions.length === 0 && (
                <p>
                  Can't be moved to other{' '}
                  {sections?.filter(section => section.isAnonymous).length > 0 ? 'available' : ''} section
                </p>
              )}
              {sectionOptions &&
                sectionOptions.length > 0 &&
                sectionOptions.map((section, index) => (
                  <>
                    <StyledListItem
                      disableGutters
                      onClick={() => setFieldValue('sectionId', section.sectionId)}
                      $error={errors?.sectionId}
                      $margin={index < sectionOptions.length - 1 ? 10 : 0}
                      $active={section.sectionId === values.sectionId}
                      $darkMode={isDarkThemeEnabled}
                    >
                      {section.sectionTitle}
                      {section.sectionId === values.sectionId && (
                        <Checkbox
                          className="p-0"
                          icon={<CheckCircleOutlineOutlinedIcon />}
                          checkedIcon={<CheckCircleIcon style={{ color: '#215C73' }} />}
                          color="success"
                          disabled
                          checked
                        />
                      )}
                    </StyledListItem>
                  </>
                ))}
            </List>
            <span className="modal-error-message">{getIn(errors, 'sectionId')}</span>
          </>
        )}
      </Formik>
    </Modal>
  );
}

MoveToSectionModal.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  sections: PropTypes.shape([]).isRequired,
  groupToEdit: PropTypes.shape({ sectionId: string, isSuggested: bool, title: string }).isRequired,
  isDarkThemeEnabled: PropTypes.bool.isRequired,
};

export default MoveToSectionModal;
