import React from 'react';
import classesNames from './ConfirmationEmailModalContent.module.scss';
import * as leadMagnetActions from 'actions/leadMagnets';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect, useCallback } from 'react';
import { Grid, TextField, useTheme, useMediaQuery } from '@material-ui/core';
import Select from 'components/FormUI/Select';
import InfoIcon from '@material-ui/icons/Info';
import { Formik, Form } from 'formik';
import { Tooltip } from '@material-ui/core';
import { TOOLTIP } from '../../../../../../constants';
import Modal from 'components/UI/Modal';
import { Box } from '@mui/material';
import Button from 'components/FormUI/Button';
import {
  ClientContriP,
  DraftBtn,
  ClientMainDvi,
  BtnDiv,
  StyledButton,
  StyledButtonSaved,
  useStyles,
  SubjectHeading,
  GropLeadMagnetHeading,
  PurchaseGroupContainer,
  DropDownMainContainer,
  StyledGridContent,
} from '../../../../../LeadMagnetView/components/SharedStyledComponents';
import styled from 'styled-components';
import { CKEditor, useCKEditor } from 'ckeditor4-react';
import { useLeadMagnet } from '../../../../../../pages/LeadMagnetView/hooks';
import useLeadMagnetTheme from 'hooks/useLeadMagnetTheme';
import { StyledInput, StyledSlider } from 'components/switch/style';
import Loader from 'components/UI/Loader';
import useEmailMarketing from 'pages/CreateCampaign/Hooks/useEmailMarketing';
import useQuiz from 'hooks/useQuiz';
import {
  getCustomTemplateByQuiztId,
  updateEmailTemplate,
  resetEmailTemplate,
  sendTestQuizCustomEmailNotification,
} from 'pages/CreateQuizes/Services/CreateQuizes.service';
import classNames from 'classnames';

const useBrandingText =
  'On choosing custom your emails will be branded as per your customized lead magnet colors and logo.';

const StyledSelect = styled(Select)`
  width: calc(100% - 33px);
  color: ${props => (props.disabled ? '#9b9b9b !important' : props.labelColor)};
  label {
    color: ${props => (props.disabled ? '#9b9b9b !important' : props.labelColor)};
  }

  svg {
    color: ${props => props.labelColor};

    path {
      fill: ${props => props.labelColor} !important;
    }
  }
`;

const StyledSendingAddressSelect = styled(StyledSelect)`
  label {
    font-family: Avenir;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: ${props => (props.disabled ? '#9b9b9b !important' : '')};
  }

  .MuiSelect-select {
    font-family: Avenir;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: ${props => (props.disabled ? '#9b9b9b' : props.labelColor)};
  }

  .MuiPopover-paper {
    top: 140px;
  }
`;

const SubmitButton = styled(Button)`
  float: right;
  width: 75px;
  height: 40px;
  line-height: 10px;
  margin-top: 10px;
  opacity: '1';
`;

const EmailForm = styled(Form)`
  width: 250px;
`;

const StyledInfoIcon = styled(InfoIcon)`
  margin-left: 20px;
`;
export const EmailHeading = styled.p`
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 850;
  font-size: 14px;
  display: flex;
  ${({ mobileView }) =>
    mobileView &&
    `
  margin: 0px;
  `}
`;
const EnableEmailHeading = styled(EmailHeading)`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  gap: 16px;
`;
export const Switch = styled.label`
  position: relative;
  display: inline-block;
  width: 40px;
  height: 25px;
  margin: 0;
`;

const ConfirmationEmailModalContent = ({ onCancel }) => {
  const dispatch = useDispatch();
  const { verifiedSendingAddresses } = useEmailMarketing();
  const [emailEnableDisableStatus, setEmailEnableDisableStatus] = useState(null);
  const [sendingAddressState, setSendingAddressState] = useState(verifiedSendingAddresses[0]?.email);
  const leadMagnet = useLeadMagnet();
  const { activeQuiz } = useQuiz();
  const { isDarkModeEnabled, userId } = leadMagnet;
  const [emailObj, setEmailObj] = useState(null);
  const [responseTemplateObj, setResponseTemplateObj] = useState(null);
  const { user } = useSelector(state => state.account);
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'));
  const [modalVisible, setModalVisible] = useState(false);
  const [keywordState, setkeywordState] = useState('');
  const [element, setElement] = useState();
  const [isCKEditorVisible, setIsCKEditorVisible] = useState(true);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [resetModalVisible, setResetModalVisible] = useState(false);
  const classes = useStyles();
  const [data, setData] = useState(emailObj?.emailText);
  const [EditorDataUpdated, setEditorDataUpdated] = useState(emailObj?.emailText);
  const { colorToUse, themedColors, textColor, themedCardBackgroundColor } = useLeadMagnetTheme();
  const [emailCategory, setEmailCategory] = useState(emailObj?.category);
  const { themedColor } = themedColors;
  const [emailTemplateBrandingColor, setEmailTemplateBrandingColor] = useState(emailObj?.isCustomBrandingColorsEnabled);
  const [emailSubject, setEmailSubject] = useState(emailObj?.emailSubject);
  const [currentColors, setCurrentColors] = useState(emailObj?.currentColors);
  const [emailText, setEmailText] = useState(emailObj?.emailText);
  const [keywordsList, setKeywordsList] = useState(emailObj?.uniqueKeyWords);
  const [diableButton, setdiableButton] = useState(false);
  const tooltipText = `The listed words placement can be updated but these words can't be changed.`;
  const TOOLTIP_TITLE = 'Copied to clipboard';
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const onCloseTooltip = useCallback(() => setTooltipOpen(false), [setTooltipOpen]);

  const handleEditorChange = event => {
    setEditorDataUpdated(event.editor.getData());
  };

  const handleClosePartnerMadal = () => {
    setModalVisible(false);
  };

  const { editor } = useCKEditor({
    element,
    config: {
      allowedContent: true,
      height: '500px',
      removePlugins: 'elementspath',
    },
    dispatchEvent: e => console.log('Displaying dispatch event', e),
    subscribeTo: ['change'],
    initContent: emailObj?.emailText,
  });

  useEffect(() => {
    if (activeQuiz?.id) {
      setLoading(true);
      getCustomTemplateByQuiztId(activeQuiz?.id)
        .then(res => {
          const data = res?.customTemplates?.find(t => t?.emailType === 'QuizConfirmationEmail');
          setResponseTemplateObj({
            ...data,
            attachedDomainEmail: res?.attachedDomainEmail,
            templateId: res?.templateId,
          });
          setEmailObj(data);
          // setEmailEnableDisableStatus(res?.customTemplates[0]?.sendEmailAllowed === 'true');
        })
        .catch(console.dir)
        .finally(() => {
          setLoading(false);
        });
    }
  }, [activeQuiz.id]);

  useEffect(() => {
    if (emailObj) {
      setData(emailObj?.emailText);
      setEditorDataUpdated(emailObj?.emailText);
      setEmailCategory(emailObj?.category);
      setEmailTemplateBrandingColor(emailObj?.isCustomBrandingColorsEnabled);
      setEmailSubject(emailObj?.emailSubject);
      setCurrentColors(emailObj?.currentColors);
      setEmailText(emailObj?.emailText);
      if (responseTemplateObj?.attachedDomainEmail === null) {
        setSendingAddressState(verifiedSendingAddresses[0]?.email);
      } else {
        setSendingAddressState(responseTemplateObj?.attachedDomainEmail);
      }
      setKeywordsList(emailObj?.uniqueKeyWords);
    }
  }, [emailObj]);

  const sendTestNotification = body => {
    if (EditorDataUpdated?.length == 0 || emailSubject?.length == 0) {
      setModalVisible(true);
    } else {
      setdiableButton(true);
      let testData = {
        ...emailObj,
        templateId: activeQuiz?.id,
        emailText: EditorDataUpdated,
        emailSubject: emailSubject,
        isCustomBrandingColorsEnabled: emailTemplateBrandingColor,
        attachedDomainEmail: sendingAddressState,
      };
      setLoading(true);
      sendTestQuizCustomEmailNotification(testData)
        .then(res => {
          setdiableButton(false);
          setLoading(false);
        })
        .catch(console.dir);
    }
  };

  const updateCustomBranding = e => {
    const params = {
      attachedDomainEmail: sendingAddressState,
      emailText: EditorDataUpdated,
      contributionId: null,
      templateId: activeQuiz.id,
      name: emailObj.name,
      emailType: emailObj.emailType,
      emailSubject: emailSubject,
      isEmailEnabled: emailObj.isEmailEnabled,
      category: emailObj.category,
      uniqueKeyWords: emailObj.uniqueKeyWords,
      isCustomBrandingColorsEnabled: e,
      currentColors: currentColors,
      sendEmailAllowed: emailObj.sendEmailAllowed,
      isCustomEmailUpdated: true,
    };

    setLoading(true);
    updateEmailTemplate(params).then(res => {
      setEmailText(null);
      setEmailSubject(res?.emailSubject);
      setEmailTemplateBrandingColor(res?.isCustomBrandingColorsEnabled);
      setEditorDataUpdated(res?.emailText);
      setKeywordsList(res?.uniqueKeyWords);
      setEmailCategory(res?.category);
      setData(res?.emailText);
      setIsCKEditorVisible(true);
      setResetModalVisible(false);
      setEmailText(res?.emailText);
      setCurrentColors(res.currentColors);
      setLoading(false);
    });
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <StyledGridContent isForm>
          {saving && <Loader />}
          <Grid container item md={12} xs={12} justifyContent="space-between" alignItems="center">
            <Grid
              container
              item
              md={12}
              xs={12}
              justifyContent="space-between"
              alignItems="center"
              style={{ ...(mobileView ? { flexDirection: 'column-reverse', gap: '20px' } : {}) }}
            >
              <Box style={{ position: 'relative', width: mobileView ? '100%' : '33%' }}>
                <StyledSendingAddressSelect
                  label="Sending Address"
                  name="sendingAddress"
                  labelColor={themedColor}
                  value={sendingAddressState}
                  fullWidth
                  items={verifiedSendingAddresses?.map(data => ({
                    title: data.email,
                    value: data.email,
                  }))}
                  onChange={e => {
                    setSendingAddressState(e.target.value);
                  }}
                  style={{
                    color: activeQuiz?.isDarkModeEnabled ? 'white' : '#9b9b9b',
                  }}
                  MenuProps={{
                    disablePortal: true,
                    classes: {
                      paper: classesNames.select_paper,
                    },
                  }}
                />
              </Box>
              <BtnDiv style={{ width: mobileView ? '100%' : '' }}>
                <StyledButton
                  style={{
                    minWidth: 'fit-content',
                    marginRight: '10px',
                    borderColor: '#CDBA8F',
                    cursor: 'pointer',
                    color: '#CDBA8F',
                    backgroundColor: 'white',
                    border: '1px solid #CDBA8F',
                  }}
                  onClick={() => {
                    sendTestNotification(emailObj);
                  }}
                  mobileView={mobileView}
                  Width="16px"
                  disabled={diableButton}
                >
                  Send Test
                  <span>
                    <InfoIcon style={{ paddingLeft: '4px', color: '#CDBA8F' }} htmlColor="#CDBA8F" />
                  </span>
                </StyledButton>
                <StyledButtonSaved
                  style={{
                    minWidth: 'fit-content',
                    borderWidth:
                      emailObj?.emailText != EditorDataUpdated || emailObj?.emailSubject != emailSubject ? 0 : '',
                    color: 'white',
                    backgroundColor: '#CDBA8F',
                    border: '1px solid #CDBA8F',
                  }}
                  // disabled={
                  //   emailObj?.emailText != EditorDataUpdated ||
                  //   emailObj?.emailSubject != emailSubject ||
                  //   responseTemplateObj?.attachedDomainEmail != sendingAddressState
                  //     ? false
                  //     : true || !emailEnableDisableStatus
                  // }
                  onClick={() => {
                    if (EditorDataUpdated.length == 0 || emailSubject.length == 0 || sendingAddressState == null) {
                      setModalVisible(true);
                    } else {
                      const params = {
                        emailText: EditorDataUpdated,
                        contributionId: null,
                        templateId: activeQuiz.id,
                        name: emailObj.name,
                        emailType: emailObj.emailType,
                        emailSubject: emailSubject,
                        isEmailEnabled: emailObj.isEmailEnabled,
                        attachedDomainEmail: sendingAddressState,
                        category: emailObj.category,
                        uniqueKeyWords: emailObj.uniqueKeyWords,
                        currentColors: emailObj.currentColors,
                        isCustomBrandingColorsEnabled: emailTemplateBrandingColor,
                        sendEmailAllowed: emailObj.sendEmailAllowed,
                        isCustomEmailUpdated: true,
                      };
                      setSaving(true);
                      updateEmailTemplate(params).then(res => {
                        responseTemplateObj.attachedDomainEmail = sendingAddressState;
                        setEmailCategory(res.category);
                        setEmailObj(res);
                        setSaving(false);
                        onCancel();
                      });
                    }
                  }}
                  mobileView={mobileView}
                  Width="16px"
                >
                  Save & Exit
                </StyledButtonSaved>
              </BtnDiv>
            </Grid>
          </Grid>

          <Grid container style={{ width: '100%', margin: 'auto', color: 'red' }}>
            <Grid item md={12} xs={12}>
              <ClientMainDvi mobileView={mobileView} isForm>
                <ClientContriP
                  style={{
                    color: 'black',
                    margin: '0px',
                  }}
                >
                  {emailObj?.name}
                </ClientContriP>
                <DraftBtn
                  style={{
                    color: '#4A4A4A',
                    backgroundColor: '#DBE7F2',
                    cursor: 'pointer',
                  }}
                >
                  Draft
                </DraftBtn>
              </ClientMainDvi>
            </Grid>
            <Grid item md={6}></Grid>
          </Grid>

          <Grid container className={classes.CkEditorMainDiv}>
            <Grid item md={12} style={{ width: mobileView ? '100%' : '' }}>
              <PurchaseGroupContainer mobileView={mobileView}>
                <Grid item md={12} style={{ border: 'none' }} className={classes.CkEditorContainerForm}>
                  <GropLeadMagnetHeading style={{ color: themedColor }} mobileView={mobileView}>
                    <Formik initialValues={{ emailSubject: '' }}>
                      <Form>
                        <SubjectHeading style={{ color: themedColor }}>Email Subject:</SubjectHeading>
                        <TextField
                          id="outlined-basic"
                          onChange={e => {
                            setEmailSubject(e.target.value);
                          }}
                          name="emailSubject"
                          value={emailSubject}
                          fullWidth
                          variant="outlined"
                        />
                      </Form>
                    </Formik>
                  </GropLeadMagnetHeading>
                </Grid>
              </PurchaseGroupContainer>

              <DropDownMainContainer style={{}} mobileView={mobileView}>
                <Grid
                  item
                  xs={12}
                  container
                  wrap="nowrap"
                  style={{
                    marginLeft: '0px',
                    justifyContent: 'space-between',
                    padding: mobileView ? '0px 15px' : '',
                  }}
                >
                  <Grid item md={6} xs={12}>
                    {!loading && (
                      <div>
                        <Tooltip title={TOOLTIP_TITLE} open={tooltipOpen} onClose={onCloseTooltip} arrow>
                          <StyledSelect
                            label="Keywords"
                            name="keywords"
                            labelColor={themedColor}
                            onChange={e => {
                              const val = e?.nativeEvent?.target?.innerText;
                              setkeywordState(val);
                              navigator.clipboard.writeText(val);
                              setTooltipOpen(true);
                              setTimeout(() => {
                                setTooltipOpen(false);
                              }, 1000);
                            }}
                            sx={{
                              width: 100,
                              height: 40,
                              marginRight: 15,
                              border: '1px solid darkgrey',
                              color: 'red',
                              '& .MuiSvgIcon-root': {
                                color: 'white',
                              },
                            }}
                            MenuProps={{
                              PaperProps: {
                                sx: {
                                  bgcolor: 'pink',
                                  '& .MuiMenuItem-root': {
                                    padding: 2,
                                  },
                                },
                              },
                            }}
                            value={keywordState}
                            items={keywordsList?.map(data => ({
                              title: `${data?.value}`,
                              value: `${data?.value}`,
                              // icon: true,
                            }))}
                            // disabled={!emailEnableDisableStatus}
                          />
                        </Tooltip>
                        <Tooltip title={tooltipText} arrow enterTouchDelay={TOOLTIP.ENTER_DELAY}>
                          <InfoIcon
                            style={{
                              marginTop: '30px',
                              marginLeft: '5px',
                              color: '#CDBA8F',
                            }}
                            htmlColor="#CDBA8F"
                          />
                        </Tooltip>
                      </div>
                    )}
                  </Grid>
                </Grid>
              </DropDownMainContainer>
            </Grid>
            <Grid
              container
              justifyContent="space-between"
              style={mobileView ? { flexDirection: 'column', alignItems: 'flex-start' } : {}}
            >
              {emailTemplateBrandingColor != null && (
                <Grid item md={4} style={{ margin: '20px 0px 0px 0px', padding: mobileView ? '0px 15px' : '' }}>
                  <div style={{ display: 'flex' }}>
                    <EmailHeading
                      style={{
                        margin: '0px',
                        color: 'black',
                      }}
                    >
                      Use my branding
                      <Switch className="switch" style={{ marginLeft: '8px' }}>
                        <StyledInput
                          defaultChecked={emailTemplateBrandingColor}
                          type="checkbox"
                          onChange={e => {
                            updateCustomBranding(e.target.checked);
                          }}
                          // disabled={!emailEnableDisableStatus}
                        />
                        <StyledSlider className="slider round" />
                      </Switch>
                      <Tooltip title={useBrandingText} arrow enterTouchDelay={TOOLTIP.ENTER_DELAY}>
                        <StyledInfoIcon
                          htmlColor="#CDBA8F"
                          style={{ margin: '0px', marginLeft: '8px', color: '#CDBA8F' }}
                        />
                      </Tooltip>
                    </EmailHeading>
                  </div>
                </Grid>
              )}
              <Grid
                item
                md={4}
                style={{
                  margin: '20px 0px 0px 0px',
                  display: 'flex',
                  justifyContent: 'flex-end',
                  color: 'gray',
                }}
              >
                <p
                  style={{
                    margin: '0px',
                    cursor: 'pointer',
                    color: activeQuiz?.isDarkModeEnabled ? 'white' : '#4A4A4A',
                    padding: mobileView ? '0px 15px' : '',
                  }}
                  onClick={() => {
                    setResetModalVisible(true);
                  }}
                >
                  Revert to Default
                </p>
              </Grid>
            </Grid>
            <Grid item md={12} className={classes.CkEditorContainerForm} style={{ marginTop: '30px' }}>
              <div className="App" style={{ width: '100%' }}>
                {emailText && (
                  <CKEditor
                    config={{
                      allowedContent: true,
                      height: '500px',
                      removePlugins: 'elementspath,dragdrop',
                    }}
                    // readOnly={!emailEnableDisableStatus}
                    // disabled={!emailEnableDisableStatus}
                    initData={emailText}
                    onChange={handleEditorChange}
                    onDragOver={e => {
                      e.preventDefault();
                    }}
                    onDrop={(event, editor) => {
                      event.cancel();
                    }}
                  />
                )}
              </div>
            </Grid>
            <Modal
              isOpen={modalVisible}
              minHeight={'170px'}
              className={`${activeQuiz?.isDarkModeEnabled ? 'dark-mode' : ''}`}
              style={activeQuiz?.isDarkModeEnabled ? { backgroundColor: 'rgb(45, 47, 49)' } : {}}
              title={''}
              hiddenCancel
              disableFooter={true}
              onCancel={handleClosePartnerMadal}
            >
              <p style={activeQuiz?.isDarkModeEnabled ? { color: 'white' } : {}}>
                {EditorDataUpdated?.length === 0 && emailSubject?.length === 0 && sendingAddressState == null ? (
                  <p>Email subject, body, and sending address cannot be empty.</p>
                ) : EditorDataUpdated?.length === 0 && emailSubject?.length === 0 ? (
                  <p>Email subject and body cannot be empty.</p>
                ) : emailSubject?.length === 0 && sendingAddressState == null ? (
                  <p>Email subject and sending address cannot be empty.</p>
                ) : EditorDataUpdated?.length === 0 && sendingAddressState == null ? (
                  <p>Email body and sending address cannot be empty.</p>
                ) : emailSubject?.length === 0 ? (
                  <p>Email subject cannot be empty.</p>
                ) : EditorDataUpdated?.length === 0 ? (
                  <p>Email body cannot be empty.</p>
                ) : sendingAddressState == null ? (
                  <p>Sending address cannot be empty.</p>
                ) : null}
              </p>
              <SubmitButton
                backgroundColor={colorToUse?.PrimaryColorCode}
                textColor={textColor}
                autoWidth
                {...{ isDarkModeEnabled }}
                onClick={handleClosePartnerMadal}
              >
                OK
              </SubmitButton>
            </Modal>
            <Modal
              isOpen={resetModalVisible}
              hiddenCancel
              minHeight={'170px'}
              disableFooter={true}
              className={`${activeQuiz?.isDarkModeEnabled ? 'dark-mode' : ''}`}
              style={activeQuiz?.isDarkModeEnabled ? { backgroundColor: 'rgb(45, 47, 49)' } : {}}
              onCancel={() => {
                setResetModalVisible(false);
                setLoading(false);
              }}
              title={
                <div style={{ ...(activeQuiz?.isDarkModeEnabled ? { color: 'white' } : {}) }}>
                  <div>Reset Template</div>
                </div>
              }
            >
              <p style={activeQuiz?.isDarkModeEnabled ? { color: 'white' } : {}}>
                Are you sure you want to reset the template?
              </p>
              <SubmitButton
                backgroundColor={colorToUse?.PrimaryColorCode}
                textColor={textColor}
                autoWidth
                {...{ isDarkModeEnabled }}
                onClick={() => {
                  setIsCKEditorVisible(false);
                  setLoading(true);
                  const params = {
                    attachedDomainEmail: sendingAddressState,
                    emailText: EditorDataUpdated,
                    contributionId: null,
                    templateId: activeQuiz?.id,
                    name: emailObj?.name,
                    emailType: emailObj?.emailType,
                    emailSubject: emailSubject,
                    isEmailEnabled: emailObj?.isEmailEnabled,
                    category: emailCategory,
                    uniqueKeyWords: emailObj?.uniqueKeyWords,
                    isCustomBrandingColorsEnabled: emailTemplateBrandingColor,
                  };
                  resetEmailTemplate(params)
                    .then(res => {
                      setEmailText(null);
                      setEmailSubject(res?.emailSubject);
                      setSendingAddressState(null);
                      setEmailTemplateBrandingColor(null);
                      setEditorDataUpdated(res?.emailText);
                      setKeywordsList(res?.uniqueKeyWords);
                      setEmailCategory(res?.category);
                      setData(res?.emailText);
                      setIsCKEditorVisible(true);
                      setResetModalVisible(false);
                      setEmailText(res?.emailText);
                      setEmailTemplateBrandingColor(res.isCustomBrandingColorsEnabled);
                      setEmailObj(res);

                      setTimeout(() => {
                        setLoading(false);
                      }, 500);
                    })
                    .catch(err => {
                      setIsCKEditorVisible(true);
                    });
                }}
              >
                OK
              </SubmitButton>
            </Modal>
          </Grid>
        </StyledGridContent>
      )}
    </>
  );
};

export default ConfirmationEmailModalContent;
