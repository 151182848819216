import React from 'react';
import { useAccount } from 'hooks';
import useContribution from 'pages/ContributionView/hooks/useContribution';
import OneToOneSessions from 'pages/ContributionView/ContributionSessions/OneToOneSessions';
import { UserRoles } from 'helpers/constants';
import ClientOneToOneSessionsForPaymentCard from './ClientOneToOneSessionsForPaymentCard';

const BookSingleSession = ({
  singleSessionData,
  anyPaymentOptionEnabled,
  isInviteToJoin,
  isCodeToJoin,
  easyBooking = false,
  rescheduleSession = false,
  setRescheduleSession,
  packageSessionBooking = false,
  setShowModal = () => {},
  setRescheduleBookingmodal = () => {},
  isModal = false,
  isCoach = false,
}) => {
  const { user, currentRole } = useAccount();
  const contribution = useContribution();

  return (
    <ClientOneToOneSessionsForPaymentCard
      user={user}
      currentRole={UserRoles.client}
      contribution={contribution}
      singleSessionData={singleSessionData}
      anyPaymentOptionEnabled={anyPaymentOptionEnabled}
      isInviteToJoin={isInviteToJoin}
      isCodeToJoin={isCodeToJoin}
      easyBooking={easyBooking}
      rescheduleSession={rescheduleSession}
      setRescheduleSession={setRescheduleSession}
      packageSessionBooking={packageSessionBooking}
      setShowModal={setShowModal}
      setRescheduleBookingmodal={setRescheduleBookingmodal}
      isModal={isModal}
      isCoach={isCoach}
    />
  );
};

export default BookSingleSession;
