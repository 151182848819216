import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import * as R from 'ramda';
import { TOOLTIP } from '../../../../src/constants';
import { makeStyles, Tooltip } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import { colors } from 'utils/styles';
import { get } from 'lodash/fp';
import { FieldArray } from 'formik';

import NoteModal from 'pages/ContributionView/components/NoteModal/NoteModalContainer';
import { useSessionAttachments } from 'hooks/useSessionAttachments';
import useContribution from '../../ContributionView/hooks/useContribution';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';

import { isEmptyOrNil } from 'utils/utils';
import './BottomPanel.scss';
import { CircularProgressWithLabel } from 'components/UI/CircularProgressWithLabel/CircularProgressWithLabel';
import styled from 'styled-components';
import { Box } from '@material-ui/core';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import Axios from 'axios';
import { useRouter } from 'hooks';
import axiosInstance from 'utils/axiosInstance';
import Password from 'antd/lib/input/Password';
const useStyles = makeStyles(theme => ({
  display_control: {
    display: 'grid',
    // gridTemplateColumns: '1fr 1fr 1fr',
    gridTemplateColumns: '1fr',
    gridRowGap: '15px',
    [theme.breakpoints.down('600')]: {
      display: 'block',
    },
  },
}));
const StyledProgressWrap = styled(Box)`
  width: 40px;
  height: 40px;
  margin-right: 10px;
  margin-top: -8px;
  margin-bottom: -8px;
`;
const StyledAttachText = styled.span`
  text-transform: initial;
`;

const StyledFileLink = styled.a`
  color: ${colors.darkOceanBlue};
  font-weight: 600;
  font-size: 14px;
  display: flex;
  align-items: center;
  margin-left: -6px;
  padding: 6px 6px 6px 0;
  // max-width: 230px;

  &:hover {
    color: ${colors.darkOceanBlue};
  }
`;

const ZERO = 0;

export const BottomPanel = ({
  title,
  sessionTimeId,
  session,
  contributionId,
  attachments,
  sessionIndex,
  sessionTimeIndex,
  push,
  remove,
  form,
}) => {
  const classes = useStyles();
  const [modalOpened, setModalOpened] = useState(false);
  const { sessions } = form.values;
  const { query } = useRouter();
  const isEditing = Boolean(query.id);
  const {
    progress,
    isFileUpload,
    isCoach,
    handleUploadFile,
    handleDownloadFile,
    handleRemoveFile,
  } = useSessionAttachments(sessionTimeId, contributionId);

  const handleOpenModal = () => {
    setModalOpened(true);
  };

  const handleCloseModal = () => {
    setModalOpened(false);
  };

  const deleteIt = idx => {
    const file = attachments[idx];
    const data = {
      DocumentKeyWithExtension: file.documentKeyWithExtension,
      DocumentId: file.id,
      ContributionId: isEditing ? contributionId : '0',
      SessionId: '0',
    };

    if (isEditing) {
      axiosInstance
        .post('/Content/DeleteAttachment-Selfpaced', data)
        .then(res => {
          remove(idx);
        })
        .catch(() => {
          remove(idx);
        });
    } else {
      axiosInstance.post('/Content/DeleteSelfpacedVideoOnContributionCreation', data).then(res => {
        remove(idx);
      });
    }
  };

  const uploadIt = ({ target: { files } }) => {
    if (attachments.length < 5) {
      const formData = new FormData();
      const file = files[0];
      formData.append('file', file);
      axiosInstance
        .post('/Content/AddAttachmentOnCreate', formData, {
          'Content-Type': 'multipart/form-data',
        })
        .then(res => {
          if (res.status == 200) {
            push(res.data[0]);
          }
        });
    }
    // }
  };

  return (
    <div className="session-attachments position-relative mt-4">
      <span className="h4">Attachments</span>
      {/* <span tabIndex="0" role="button" className="h4 notes" onClick={handleOpenModal} onKeyPress={handleOpenModal}>
              <span>Personal Notes</span>
              <span className="ml-3 session-empty-panel-content">Open Notes</span>
            </span> */}
      <div className={classes.display_control}>
        {isEmptyOrNil(attachments) ? (
          <span className="session-empty-panel-content">None</span>
        ) : (
          attachments?.map(
            (
              {
                id: documentId,
                contentType,
                attachementUrl,
                documentKeyWithExtension,
                documentOriginalNameWithExtension,
              },
              idx,
            ) => (
              <div style={{ maxWidth: '230px' }} key={documentId} className="d-flex align-items-center">
                {/* <Button
                  classes={{
                    root: 'text-lowercase regular-tex download-attach',
                  }}
                  onClick={handleDownloadFile}
                  disableRipple
                  data-document-id={documentId}
                  data-document-name={documentOriginalNameWithExtension}
                  data-content-type={contentType}
                >
                  <span>{`${R.inc(idx)}.`}</span>
                  <span className="ml-2">{documentOriginalNameWithExtension}</span>
                  
                </Button> */}
                <Tooltip
                  title={documentOriginalNameWithExtension}
                  arrow
                  enterTouchDelay={TOOLTIP.ENTER_DELAY}
                  leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                >
                  <StyledFileLink href={attachementUrl} download target="_blank">
                    <DescriptionOutlinedIcon style={{ fontSize: 40 }} />
                    {/* {documentOriginalNameWithExtension?.length > 15
                      ? documentOriginalNameWithExtension.substr(0, 7) +
                      '...' +
                      documentOriginalNameWithExtension.substr(-10)
                      : documentOriginalNameWithExtension} */}
                    {documentOriginalNameWithExtension}
                  </StyledFileLink>
                </Tooltip>
                {isCoach && (
                  <CloseIcon
                    fontSize="small"
                    className="cursor-pointer"
                    onClick={() => {
                      deleteIt(idx);
                    }}
                    data-document-id={documentId}
                    data-document-extension={documentKeyWithExtension}
                  />
                )}
              </div>
            ),
          )
        )}
      </div>
      {isCoach && (
        <>
          <input
            accept="*"
            className="d-none"
            id={`attach-file-${sessionIndex}-${sessionTimeIndex}`}
            type="file"
            // onChange={handleUploadFile}
            onChange={uploadIt}
            multiple
          />
          <div>
            <label htmlFor={`attach-file-${sessionIndex}-${sessionTimeIndex}`}>
              <Button
                disabled={attachments?.length >= 5}
                classes={{
                  root: 'position-absolute text-capitalize attach-button',
                }}
                disableRipple
                component="span"
              >
                {isFileUpload ? (
                  <StyledProgressWrap position="relative">
                    <CircularProgressWithLabel value={progress} />
                  </StyledProgressWrap>
                ) : (
                  <AttachFileIcon className="mr-3" />
                )}
                <StyledAttachText className="regular-text">Attach a File</StyledAttachText>
                <Tooltip
                  title="You can attach up to 5 files per content per module"
                  arrow
                  enterTouchDelay={TOOLTIP.ENTER_DELAY}
                  leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                  style={{ marginLeft: '5px' }}
                >
                  <InfoIcon htmlColor={colors.lightBrown} />
                </Tooltip>
              </Button>
            </label>
          </div>
        </>
      )}
      {/* {modalOpened && (
              <NoteModal
                noteProps={{
                  title,
                  classId: sessionId,
                  contributionId,
                }}
                isOpen={modalOpened}
                onModalClose={handleCloseModal}
                onConfirm={handleCloseModal}
              />
            )} */}
    </div>
  );
};

BottomPanel.propTypes = {
  //   title: PropTypes.string.isRequired,
  sessionTimeId: PropTypes.string.isRequired,
  contributionId: PropTypes.string.isRequired,
  attachments: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};
