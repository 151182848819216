import { useEffect, useState } from 'react';
import { getClientNotes } from 'services/clients.service';

const useClientNote = clientId => {
  const [loading, setLoading] = useState(false);
  const [showNotes, setShowNotes] = useState(false);
  const [data, setData] = useState('');
  const [isNoteExist, setIsNoteExist] = useState(false);
  useEffect(() => {
    if (clientId) {
      setLoading(true);
      getClientNotes(clientId)
        .then(response => {
          setData(response?.note || '');
          setShowNotes(true);
          setIsNoteExist(!!response?.id);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [clientId, setShowNotes]);

  return { loading, showNotes, data, isNoteExist, setShowNotes, setLoading, setData, setIsNoteExist };
};

export default useClientNote;
