import React from 'react'
import PropTypes from 'prop-types'
import IconButton from '@material-ui/core/IconButton'
import PeopleIcon from '@material-ui/icons/People'
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline'

import ToolbarAction from './ToolbarAction'

const Participants = ({ enabled, onEnabledChange }) => {
  const ParticipantsIcon = enabled ? PeopleOutlineIcon : PeopleIcon
  const label = enabled ? 'Hide Participants' : 'Participants'

  return (
    <ToolbarAction label={label}>
      <IconButton color="inherit" onClick={() => onEnabledChange(!enabled)}>
        <ParticipantsIcon />
      </IconButton>
    </ToolbarAction>
  )
}

Participants.propTypes = {
  enabled: PropTypes.bool.isRequired,
  onEnabledChange: PropTypes.func.isRequired,
}

export default Participants
