import { NAVIGATION_ACTION_TYPES } from './navigation.actionTypes';

const resetState = () => {
  return {
    type: NAVIGATION_ACTION_TYPES.RESET_STATE,
  };
};

const setIsTransitionedToCoachView = isTransitionedToCoachView => {
  return {
    payload: { isTransitionedToCoachView },
    type: NAVIGATION_ACTION_TYPES.SET_IS_TRANSITIONED_TO_COACH_VIEW,
  };
};

export const NAVIGATION_ACTIONS = {
  resetState,
  setIsTransitionedToCoachView,
};
