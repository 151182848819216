export const videoChatSelector = state => state?.videoChat;

export const titleSelector = state => videoChatSelector(state)?.title;

export const contributionIdSelector = state => videoChatSelector(state)?.contributionId;

export const classIdSelector = state => videoChatSelector(state)?.classId;

export const sessionIdSelector = state => videoChatSelector(state)?.sessionId;

export const roomIdSelector = state => videoChatSelector(state)?.roomId;

export const chatIdSelector = state => videoChatSelector(state)?.chatId;

export const typeSelector = state => videoChatSelector(state)?.type;

export const tokenSelector = state => videoChatSelector(state)?.token;

export const deleteRoomSelector = state => videoChatSelector(state)?.deleteRoomOnVideoEnd;

export const creatorIdSelector = state => videoChatSelector(state)?.creatorId;

export const recordStatusSelector = state => videoChatSelector(state)?.recordingStatus;
