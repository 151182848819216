import React, { useEffect } from 'react';
import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { Card as CardUI, CardHeader as CardHeaderUI } from 'components/UI/Card';
import useContribution from 'pages/ContributionView/hooks/useContribution';
import { getThemedColors } from 'services/contributions.service';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTestimonials } from 'actions/testimonials';
import { addedToShowcase } from 'selectors/testimonials';
import TestimonialIcon from 'assets/testimonial.svg';
import Carousel from 'react-material-ui-carousel';
import { PageTitleSecond } from 'components/UI/Text/TextStyles';

const useStyles = makeStyles(theme => ({
  root: {
    width: 335,
    height: 135,
    overflow: 'visible',
    backgroundColor: '#fafafa',
    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.25)',
    borderRadius: 16,
  },
  container: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridGap: 50,
    padding: '15px 0 15px 70px',
    height: 350,
    position: 'relative',
  },
  mobileContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(1, 1fr)',
    gridGap: 0,
    padding: '20px 50px 10px 50px',
    height: 540,
    position: 'relative',
  },
  avatar: {
    backgroundColor: '#808080',
    width: '87px',
    height: '87px',
    left: '-33px',
    top: '15px',
    border: '2px solid white',
    position: 'absolute',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.25)',
  },
  header: {
    position: 'relative',
    paddingLeft: 46,
    paddingBottom: 0,
    paddingTop: 10,
    '& .MuiCardHeader-content': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  title: {
    color: '#000',
    fontSize: 16,
    fontWeight: 500,
  },
  subTitle: {
    fontSize: 14,
  },
  content: {
    paddingLeft: 63,
    paddingTop: 0,
    height: 60,
    overflow: 'hidden',
  },
  createReviewContainer: {
    marginBottom: 25,
  },
}));

function TestimonialsBlock(props) {
  const classes = useStyles();
  const { id: contributionId } = useContribution();
  const dispatch = useDispatch();
  const testimonials = useSelector(addedToShowcase);
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));

  const contribution = useContribution();

  const { themedColor, themedCardBackgroundColor, themedBackgroundColor } = getThemedColors(contribution);

  const perChunk = mobileView ? 3 : 6; // items per chunk

  const items = testimonials.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / perChunk);

    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = []; // start a new chunk
    }

    resultArray[chunkIndex].push(item);

    return resultArray;
  }, []);

  useEffect(() => {
    dispatch(fetchTestimonials(contributionId));
  }, [dispatch, contributionId]);

  return (
    <CardUI style={{ color: themedColor, backgroundColor: themedCardBackgroundColor }}>
      <CardHeaderUI mobileView={mobileView}>
        <PageTitleSecond style={{ color: themedColor }} mobileView={mobileView}>
          Testimonials
        </PageTitleSecond>
      </CardHeaderUI>
      <CardContent>
        <Carousel
          autoPlay={false}
          navButtonsAlwaysInvisible
          indicatorIconButtonProps={{
            style: {
              color: '#808080',
            },
          }}
          activeIndicatorIconButtonProps={{
            style: {
              color: '#116582',
            },
          }}
        >
          {items.map(item => (
            <div key={item} className={mobileView ? classes.mobileContainer : classes.container}>
              {item.map(({ avatarUrl, name, role, description, id }) => (
                <div key={id}>
                  <Card style={{ color: themedColor, backgroundColor: themedBackgroundColor }} className={classes.root}>
                    <CardHeader
                      className={classes.header}
                      avatar={
                        <Avatar src={avatarUrl} className={classes.avatar}>
                          {name.charAt()}
                        </Avatar>
                      }
                      title={
                        <Typography style={{ color: themedColor }} className={classes.title} variant="subtitle1">
                          {name}
                        </Typography>
                      }
                      subheader={
                        <Typography
                          style={{ color: themedColor }}
                          className={classes.subTitle}
                          variant="subtitle1"
                          color="textSecondary"
                        >
                          {role}
                        </Typography>
                      }
                      action={<img src={TestimonialIcon} alt="" />}
                    />
                    <CardContent className={classes.content}>
                      <Typography style={{ color: themedColor }} variant="body2" color="advantagesColor" component="p">
                        {description}
                      </Typography>
                    </CardContent>
                  </Card>
                </div>
              ))}
            </div>
          ))}
        </Carousel>
      </CardContent>
    </CardUI>
  );
}

export default TestimonialsBlock;
