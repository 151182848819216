import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { Video } from 'components/Video/Video';
import useVideoPlayer from 'hooks/useVideoPlayer';
import useContribution from 'pages/ContributionView/hooks/useContribution';
import { handleSelfpacedMediaDownload } from '../../helpers';
import ButtonWraper from 'components/FormUI/Button';
import { getThemedColors } from 'services/contributions.service';

const StyledPdfContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 2.232px;
  /* border: 1.018px solid ${({ borderColor }) => borderColor || '#dadada'};
  padding: 8px 20px; */
  min-height: 90px;
  gap: 0.625rem;
  border: none;
  padding: 0;

  .MuiGrid-container {
    width: auto;
  }
`;

const StyledPdfContent = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const StyledPdfButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
`;

const StyledBody = styled.div`
  padding: 20px 0px;
`;

const SelfPacedDocuments = ({ session, sessionTime, sessionTimeIndex, colorToUse, textColor }) => {
  const contribution = useContribution();
  const { generatePreRecordedVideoLink } = useVideoPlayer({
    sessionTime,
  });
  const { isDarkModeEnabled } = contribution;
  const { themedCardOutlineColor } = getThemedColors(contribution);
  const handleDownload = () =>
    generatePreRecordedVideoLink({
      contributionId: contribution.id,
      sessionId: session.id,
    }).then(({ videoUrl, fileName }) => handleSelfpacedMediaDownload(videoUrl, fileName));
  return (
    <StyledBody>
      <StyledPdfContainer borderColor={isDarkModeEnabled && themedCardOutlineColor}>
        <StyledPdfContent>
          <Video
            style={{ width: '100%' }}
            isPreviewMode
            isSessionView
            session={session}
            sessionTime={sessionTime}
            sessionTimeIndex={sessionTimeIndex}
          />
          <div>{sessionTime?.prerecordedSession?.documentOriginalNameWithExtension}</div>
        </StyledPdfContent>
        <StyledPdfButtons>
          <ButtonWraper
            textColor={colorToUse.PrimaryColorCode}
            borderColor={colorToUse.PrimaryColorCode}
            variant="outline"
            autoWidth
            onClick={handleDownload}
            backgroundColor={isDarkModeEnabled ? 'transparent' : null}
          >
            Download
          </ButtonWraper>
        </StyledPdfButtons>
      </StyledPdfContainer>
      {/* {isViewDocument && <PdfViewer {...{ videoSource, setIsViewDocument }} />} */}
    </StyledBody>
  );
};

export default SelfPacedDocuments;
