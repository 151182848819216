import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import withStyles from '@material-ui/core/styles/withStyles'

import { useHttp, useAccount, useVideoChatActions } from 'hooks'
import { useTheme, useMediaQuery } from '@material-ui/core'
import { Session } from './Session'

const BoldTableCell = withStyles({
  root: {
    fontWeight: 800,
  },
})(TableCell)

const StyledTableContainer = withStyles({
  root: {
    overflowX: 'auto',
    maxWidth: (props) => (props.mobileView ? '96vw' : '100%'),
    margin: '0 auto',
  },
})(TableContainer)

const SessionsTable = ({ rows }) => {
  const { request } = useHttp()
  const { user } = useAccount()
  const { startVideoChat } = useVideoChatActions()
  const theme = useTheme()
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'))

  const onLaunchSession = (
    contributionId,
    type,
    classGroupId,
    classId,
    chatSid,
    title,
    { RecordParticipantsOnConnect }
  ) => {
    request('/Video/CreateRoomAndGetToken', 'POST', {
      contributionId,
      classId,
      RecordParticipantsOnConnect,
      identityName: `${user.firstName} ${user.lastName}`,
    }).then(({ room: { sid: roomId }, token }) =>
      startVideoChat({
        contributionId,
        type,
        sessionId: classGroupId,
        classId,
        title,
        roomId,
        chatId: chatSid,
        token,
        deleteRoomOnVideoEnd: true,
      })
    )
  }

  return (
    <StyledTableContainer component={Paper} mobileView={mobileView}>
      <Table className="" aria-label="sessions table">
        <TableHead>
          <TableRow>
            <BoldTableCell>Contribution</BoldTableCell>
            <BoldTableCell>Next Session</BoldTableCell>
            <BoldTableCell>Date</BoldTableCell>
            <BoldTableCell>Time</BoldTableCell>
            <BoldTableCell>Chat</BoldTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <Session key={row.contributionId} {...row} onLaunchSession={onLaunchSession} />
          ))}
        </TableBody>
      </Table>
    </StyledTableContainer>
  )
}

SessionsTable.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.object),
}

SessionsTable.defaultProps = {
  rows: [],
}

export default SessionsTable
