import { handleActions } from 'redux-actions';

import { startVideoChat, endVideoChat, addVideoChatCreator, setVideoChatRecordingStatus } from 'actions/videoChat';
import { LOG_OUT } from 'actions/user';

const initialState = {
  contributionId: null,
  sessionId: null,
  classId: null,
  title: null,
  roomId: null,
  chatId: null,
  token: null,
  deleteRoomOnVideoEnd: false,
  creatorId: null,
  recordingStatus: false,
};

export default handleActions(
  {
    [startVideoChat.toString()]: (
      state,
      { payload: { contributionId, type, sessionId, classId, title, roomId, chatId, token, deleteRoomOnVideoEnd } },
    ) => ({
      ...state,
      contributionId,
      type,
      sessionId,
      classId,
      title,
      roomId,
      chatId,
      token,
      deleteRoomOnVideoEnd,
    }),
    [endVideoChat.toString()]: state => ({
      ...state,
      contributionId: null,
      type: null,
      sessionId: null,
      title: null,
      roomId: null,
      chatId: null,
      token: null,
      deleteRoomOnVideoEnd: false,
      creatorId: null,
    }),
    [addVideoChatCreator.toString()]: (state, { payload: { creatorId } }) => ({
      ...state,
      creatorId,
    }),
    [setVideoChatRecordingStatus.toString()]: (state, { payload }) => ({
      ...state,
      recordingStatus: payload,
    }),
    [LOG_OUT]: () => ({ ...initialState }),
  },
  initialState,
);
