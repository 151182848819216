import { createAction } from 'redux-actions';
import * as clientService from 'services/clients.service';
import { get, pipe } from 'lodash/fp';

export const FETCH_CLIENTS_REQUEST = 'FETCH_CLIENTS_REQUEST';
export const FETCH_CLIENTS_SUCCESS = 'FETCH_CLIENTS_SUCCESS';
export const FETCH_CLIENTS_ERROR = 'FETCH_CLIENTS_ERROR';
export const RESET_CLIENTS = 'RESET_CLIENTS';

export const fetchClientsActions = {
  reset: createAction(RESET_CLIENTS),
  request: createAction(FETCH_CLIENTS_REQUEST),
  success: createAction(FETCH_CLIENTS_SUCCESS),
  error: createAction(FETCH_CLIENTS_ERROR),
};

export const fetchClients = ({ pageNumber, limit, clientName }) => async dispatch => {
  dispatch(fetchClientsActions.request());
  try {
    if (pageNumber === 1) {
      dispatch(fetchClientsActions.reset());
    }
    const response = await clientService.getClients({ pageNumber, pageSize: limit, clientName });

    dispatch(fetchClientsActions.success({ clients: response.clientsDTO, totalCount: response.totalCount }));
  } catch (error) {
    pipe(get('response'), get('data'), fetchClientsActions.error, dispatch)(error);
  }
};
