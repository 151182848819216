import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { StyledContainerLink, StyledImg, StyledTitle, StyledDescription } from './LinkPreview.styled'
import isEqual from 'lodash/isEqual'

const LinkPreview = ({ item }) => {
  if (!item?.imageUrl && !item?.title && !item?.description) {
    return false
  }
  return (
    <StyledContainerLink href={item.url} target="_blank" alt={item.title}>
      {item?.imageUrl && <StyledImg bg={item.imageUrl} />}
      {item?.title && <StyledTitle component="p">{item.title}</StyledTitle>}
      {item?.description && <StyledDescription component="p">{item.description}</StyledDescription>}
    </StyledContainerLink>
  )
}
LinkPreview.propTypes = {
  item: PropTypes.shape({
    description: PropTypes.string,
    imageUrl: PropTypes.string,
    postId: PropTypes.string,
    title: PropTypes.string,
    url: PropTypes.string,
  }),
}
const areEqual = (prevProps, nextProps) => {
  return isEqual(prevProps, nextProps)
}
export default memo(LinkPreview, areEqual)
