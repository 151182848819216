import React, { useState } from 'react';
import PropTypes from 'prop-types';
import PersonIcon from '@material-ui/icons/Person';

import ParticipantsModal from './ParticipantsModal';
import { useSelector } from 'react-redux';
import { CircularProgress } from '@material-ui/core';
import './Participants.scss';

const Participants = ({ members, themedColor, themedCardBackgroundColor }) => {
  const [isParticipantsModalOpen, setIsParticipantsModalOpen] = useState(false);
  const participantsLoader = useSelector(state => state.contributions?.loadingParticipants);
  return (
    <>
      <div className="chat-header-participants" onClick={() => setIsParticipantsModalOpen(true)}>
        <p className="chat-header-participants__title">
          {participantsLoader ? (
            <CircularProgress />
          ) : (
            <p className="chat-header-participants__title">
              <PersonIcon /> {members.length} participants
            </p>
          )}
        </p>
      </div>
      <ParticipantsModal
        themedColor={themedColor}
        themedCardBackgroundColor={themedCardBackgroundColor}
        members={members}
        open={isParticipantsModalOpen}
        onOpenChange={setIsParticipantsModalOpen}
      />
    </>
  );
};

Participants.propTypes = {
  members: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default Participants;
