import useAccount from 'hooks/useAccount';
import { useEffect, useState } from 'react';
import { getTotalUnreadPosts } from 'services/community.service';
import { useLoadContributions } from './useLoadContributions';

export const useLoadAllUnreadPosts = () => {
  const { data, error, isLoading } = useLoadContributions();
  const { user } = useAccount();
  const [unreadPostsCount, setUnreadPostsCount] = useState(0);
  const [unreadPostsError, setUnreadPostsError] = useState(null);

  useEffect(() => {
    if (Array.isArray(data) && !error && !isLoading) {
      const contributionIdArray = data.map(item => item.id);
      getTotalUnreadPosts({
        userId: user.id,
        contributionIds: contributionIdArray,
      }).then(res => {
        setUnreadPostsCount(Object.values(res));
      });
    }
  }, [data, error, isLoading, user.id]);

  return {
    unreadPostsCount,
    unreadPostsError,
  };
};
