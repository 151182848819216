import { checkIsFuture } from 'utils/datesAndMoney';
import useContribution from 'pages/ContributionView/hooks/useContribution';
import { useEffect, useState } from 'react';
import { useStyles } from './useStyles';

export const useOverlay = props => {
  const {
    session,
    sessionTime,
    isPreviewMode,
    isPlayVideo,
    isCohealer,
    onPlayVideo,
    user,
    sessionTimeIndex,
    shouldBeDisabled,
  } = props;
  const classNames = useStyles();
  const [isMustWatchPriorSelfPacedRecords, setIsMustWatchPriorSelfPacedRecords] = useState(false);
  const { sessions, type } = useContribution();

  useEffect(() => {
    if (session?.isPrerecorded) {
      const firstSession = sessions.filter(el => el.isPrerecorded === true);
      const isPriorRecordsWatched = session.sessionTimes
        .slice(0, sessionTimeIndex)
        .every(el => el.usersWhoViewedRecording.includes(user.id));
      setIsMustWatchPriorSelfPacedRecords(!isPriorRecordsWatched);

      if (firstSession[0]?.id === session?.id && sessionTime.mustWatchPriorSelfPacedRecords) {
        setIsMustWatchPriorSelfPacedRecords(!isPriorRecordsWatched);
      } else if (sessionTime.mustWatchPriorSelfPacedRecords) {
        setIsMustWatchPriorSelfPacedRecords(isPriorRecordsWatched);
      }
    }
  }, [sessionTime, session, user, setIsMustWatchPriorSelfPacedRecords, sessionTimeIndex]);

  const preRecordedSession =
    sessionTime?.prerecordedSession || session?.prerecordedSession || sessionTime?.embeddedVideoUrl;
  let isDisabledVideoForClient;
  if (shouldBeDisabled != null) {
    isDisabledVideoForClient = !isCohealer && shouldBeDisabled;
  } else {
    isDisabledVideoForClient =
      !isCohealer &&
      preRecordedSession &&
      (isMustWatchPriorSelfPacedRecords || (sessionTime?.ignoreDateAvailable && checkIsFuture(sessionTime?.startTime)));
  }

  // let isDisabledVideoForClient =
  //   !isCohealer &&
  //   preRecordedSession &&
  //   (shouldBeDisabled ||
  //     isMustWatchPriorSelfPacedRecords ||
  //     (sessionTime?.ignoreDateAvailable && checkIsFuture(sessionTime?.startTime)));

  const isOverlay = isPreviewMode && !isPlayVideo && session?.isPrerecorded;
  const overlayProps = {
    className: classNames.overlay,
    onClick: isDisabledVideoForClient ? null : onPlayVideo,
    style: { cursor: isDisabledVideoForClient ? 'not-allowed' : 'pointer' },
  };

  return isOverlay ? overlayProps : {};
};
