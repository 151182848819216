import jwt from 'jsonwebtoken';

import axiosInstance from './axiosInstance';

function setAuthorizationHeader(token = null) {
  if (token) {
    axiosInstance.defaults.headers.common.Authorization = `Bearer ${token}`;
  } else {
    delete axiosInstance.defaults.headers.common.Authorization;
  }
}

function getRolesFromToken(token) {
  const ROLE_KEY = 'http://schemas.microsoft.com/ws/2008/06/identity/claims/role';
  let roles;

  if (token) {
    const decoded = jwt.decode(token);
    const rolesCandidate = decoded[ROLE_KEY];

    if (Array.isArray(rolesCandidate)) {
      roles = [...rolesCandidate];
    } else if (typeof rolesCandidate === 'string') {
      roles = [rolesCandidate];
    }
  }

  return roles;
}

function getCurrentRole(roles = []) {
  if (!roles || !roles.length) {
    return 'Client';
  }

  const priority = {
    SuperAdmin: 1,
    Admin: 2,
    Cohealer: 3,
    CohealerAssistant: 4,
    Client: 5,
  };

  return roles
    .map(role => ({
      role,
      position: priority[role] || 100,
    }))
    .reduce((p, c) => (p.position < c.position ? p : c)).role;
}

export { setAuthorizationHeader, getRolesFromToken, getCurrentRole };
