import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import AddBankAccountPopup from '../../../pages/Account/Payment/components/BankAccountCard/AddBankAccountPopup';
import { useHttp, useAccount } from 'hooks';
import { useTheme, useMediaQuery } from '@material-ui/core';
import { redirectTo } from 'services/links';
import * as userActions from 'actions/user';

import Button from 'components/FormUI/Button';

import styled from 'styled-components';

import '../Banner.scss';
// import StripeRequestBannerPlaid from './StripeRequestBannerPlaid';

const StyledButton = styled(Button)`
  background-color: #0e536b;
  padding: 6px 14px;
  min-width: 95px;
  width: 95px;
  font-size: 12px;
  font-weight: 700;
`;

const StripeRequestBanner = ({ userId, getUserProfile }) => {
  const { request } = useHttp();
  const { user } = useAccount();
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const BannerButton = mobileView ? StyledButton : Button;
  const [isClick, setIsClick] = useState(false);
  const [ready, setReady] = useState(true);
  let opensFunc = () => null;

  const getUser = useCallback(() => {
    if (userId) {
      getUserProfile(userId);
    }
  }, [userId, getUserProfile]);

  const onVerificate = useCallback(() => {
    request('api/Payment/generate-account-verification-link?forStandardAccount=true', 'POST').then(({ link }) => {
      redirectTo(link);
    });
  }, [request]);
  const handleGetOpen = opens => {
    opensFunc = opens;
    opens();
  };
  const handleGetReady = ready => {
    setReady(ready);
  };
  const checkOnClick = () => {};

  const [addStripeBankAccountDataPopup, setAddStripeBankAccountDataPopup] = useState(false);
  return (
    <>
      <div className={`banner-container ${mobileView && 'banner-container__mobile'}`}>
        <p className="banner-container__text">
          IMPORTANT: Stripe requires you to enter additional business details in order to transfer payments into your
          bank account.
        </p>
        <div>
          <BannerButton
            style={{ minWidth: '13rem' }}
            variant="primary"
            className="banner-container__button"
            // autoWidth
            onClick={() => (window.location = 'mailto:support@cohere.live')}
          >
            Contact Support
          </BannerButton>
        </div>
      </div>
    </>
  );
};

StripeRequestBanner.propTypes = {
  userId: PropTypes.string,
  getUserProfile: PropTypes.func.isRequired,
};

StripeRequestBanner.defaultProps = {
  userId: null,
};

const mapStateToProps = ({ account }) => ({
  userId: account?.user?.id,
});

const actions = {
  getUserProfile: userActions.getProfile,
};

export default connect(mapStateToProps, actions)(StripeRequestBanner);
