import { ContributionType } from 'helpers/constants';

const getCourseType = rawType => {
  switch (rawType) {
    case ContributionType.contributionOneToOne:
      return 'One To One Sessions';
    case ContributionType.contributionCourse:
      return 'Course';
    default:
      return '';
  }
};

export default getCourseType;
