import { CONTRIBUTION_COLORS } from '../constants';
import { useSelector } from 'react-redux';
import { useAccount } from 'hooks';
import { ContributionType } from 'helpers/constants';
import { TemplateType } from 'helpers/constants/templateType';
export const ColorSchemeService = () => {
  const activeContribution = useSelector(state => state.contributions?.activeContribution);
  const { currentRole } = useAccount();
  const isCoach = currentRole == 'Cohealer';
  const activeTemplate = activeContribution?.activeTemplate;
  const shouldChangeColor = !isCoach;

  if (shouldChangeColor) {
    if (activeContribution?.isCustomBrandingColorsActive) {
      let res = activeContribution?.coachSelectedBrandingColors
        ? activeContribution?.coachSelectedBrandingColors
        : activeContribution?.brandingColors;
      return res;
    } else {
      return CONTRIBUTION_COLORS;
    }
  } else {
    return CONTRIBUTION_COLORS;
  }
};
