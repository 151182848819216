import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';

import { useStyles, PlansTitle } from '../Billing.styles';
import ToggleSwitch from '../../../../components/switch';
import SelectingPlanTemplate from '../../../../templates/selectingPlan';

import Dictionary from '../dictionary.json';

const BillingPlans = ({ isModalView, modalsApi }) => {
  const classes = useStyles();

  return (
    <Grid container className={classes.root}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <PlansTitle>
            {Dictionary.plans.AnnualPlan} <span>{Dictionary.plans.Save}</span> <ToggleSwitch />
          </PlansTitle>
        </Grid>
      </Grid>

      <SelectingPlanTemplate isModalView={isModalView} modalsApi={modalsApi} />
    </Grid>
  );
};

BillingPlans.propTypes = {
  isModalView: PropTypes.bool.isRequired,
};

export default BillingPlans;
