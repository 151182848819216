import { fetchDomains } from 'actions/emailMarketing.actions';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const useEmailMarketing = () => {
  const dispatch = useDispatch();
  const { domains, isLoading } = useSelector(state => state?.emailMarketing);
  const verifiedDomains = domains?.filter(domain => domain?.isVerified === true);
  const verifiedSendingAddresses = verifiedDomains?.flatMap(domain => domain?.senderIdentities);
  const defaultVerifiedSendingAddress = verifiedSendingAddresses?.find(s => s.isDefault === true);

  useEffect(() => {
    dispatch(fetchDomains());
  }, []);

  return {
    domains,
    isLoading,
    verifiedDomains,
    verifiedSendingAddresses,
    defaultVerifiedSendingAddress,
  };
};

export default useEmailMarketing;
