import React from 'react';

const ColonIcon = ({ color = 'white' }) => {
  return (
    <svg width="24" height="18" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.6">
        <path
          d="M5.85275 8.38518C4.97456 8.38802 4.11191 8.60681 3.34782 9.0205C4.42358 6.35956 6.20779 4.01317 8.52389 2.21355C8.65641 2.10926 8.76613 1.98105 8.8468 1.83624C8.92746 1.69143 8.97749 1.53285 8.99403 1.36956C9.01056 1.20627 8.99328 1.04147 8.94317 0.884565C8.89306 0.727659 8.8111 0.581722 8.70197 0.455086C8.59283 0.32845 8.45867 0.223594 8.30714 0.146507C8.1556 0.0694201 7.98966 0.021611 7.81879 0.00580889C7.64792 -0.0099932 7.47546 0.00652203 7.31127 0.0544105C7.14708 0.102299 6.99437 0.180623 6.86185 0.284911C2.11317 3.97201 0 9.07722 0 12.2425C0.00818204 13.3616 0.357184 14.4543 1.00439 15.3871C1.6516 16.3198 2.56902 17.0523 3.64462 17.4951C4.34073 17.8174 5.10304 17.9879 5.8765 17.9943C6.55729 18.026 7.23772 17.9251 7.87651 17.6979C8.51531 17.4708 9.09917 17.122 9.5927 16.6727C10.0862 16.2234 10.4791 15.6831 10.7476 15.0844C11.0161 14.4857 11.1545 13.8411 11.1545 13.1898C11.1545 12.5384 11.0161 11.8938 10.7476 11.2951C10.4791 10.6964 10.0862 10.1561 9.5927 9.70681C9.09917 9.25755 8.51531 8.90876 7.87651 8.68158C7.23772 8.45439 6.55729 8.35356 5.8765 8.38518H5.85275Z"
          fill={color}
        />
        <path
          d="M18.8711 8.27031C18.0176 8.27209 17.179 8.48675 16.4369 8.89345C17.4819 6.28624 19.2111 3.9856 21.4553 2.21697C21.5957 2.11931 21.714 1.99496 21.8028 1.85158C21.8915 1.70819 21.949 1.54879 21.9715 1.3832C21.994 1.21761 21.9811 1.04932 21.9337 0.8887C21.8863 0.728079 21.8053 0.57852 21.6957 0.449228C21.5861 0.319935 21.4502 0.213644 21.2964 0.136896C21.1426 0.0601487 20.9742 0.0145689 20.8014 0.00296048C20.6287 -0.00864793 20.4553 0.013961 20.292 0.0693967C20.1287 0.124832 19.9788 0.211921 19.8517 0.325304C15.2371 3.94173 13.1836 8.94908 13.1836 12.0536C13.1891 13.1389 13.5183 14.1997 14.132 15.1094C14.7458 16.019 15.618 16.739 16.6445 17.1834C17.3381 17.5087 18.0999 17.6762 18.8711 17.673C19.5327 17.704 20.1939 17.6051 20.8146 17.3823C21.4354 17.1595 22.0028 16.8174 22.4824 16.3767C22.962 15.9361 23.3438 15.4061 23.6047 14.8188C23.8655 14.2316 24.0001 13.5994 24.0001 12.9605C24.0001 12.3217 23.8655 11.6894 23.6047 11.1022C23.3438 10.515 22.962 9.98499 22.4824 9.54435C22.0028 9.1037 21.4354 8.76159 20.8146 8.53877C20.1939 8.31594 19.5327 8.21704 18.8711 8.24805V8.27031Z"
          fill={color}
        />
      </g>
    </svg>
  );
};

export default ColonIcon;
