import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import ConversationsList from './ConversationsList';
import Conversation from './Conversation';

const ConversationsListContainer = ({ preferredConversationId, conversationIds }) => {
  const [conversationId, setConversationId] = useState(preferredConversationId);

  useEffect(() => {
    setConversationId(preferredConversationId);
  }, [preferredConversationId]);

  if (conversationId) {
    return <Conversation conversationId={conversationId} onShowConversations={() => setConversationId(null)} />;
  }

  return <ConversationsList conversationIds={conversationIds} onSelectConversation={setConversationId} />;
};

ConversationsListContainer.propTypes = {
  preferredConversationId: PropTypes.string,
  conversationIds: PropTypes.arrayOf(PropTypes.string),
};

ConversationsListContainer.defaultProps = {
  preferredConversationId: null,
  conversationIds: null,
};

export default ConversationsListContainer;
