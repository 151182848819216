import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as R from 'ramda';

import { ROUTES } from 'constants.js';
import { NAVIGATION_ACTIONS } from 'actions/Navigation';
import * as userActions from 'actions/user';
import { UserRoles } from 'helpers/constants';
import { useAccount, useHttp, useRouter } from 'hooks';
import * as userService from 'services/user.service';

export const useShowGettingStartedGuide = () => {
  const dispatch = useDispatch();
  const { request, loading } = useHttp();
  const { pathname } = useRouter();
  const [error, setError] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const { coachLoginInfo, currentRole, user } = useAccount();
  const { coachLoggedTheFirstTime } = coachLoginInfo || {};

  const onClose = useCallback(() => {
    if (loading) {
      return;
    }

    dispatch(NAVIGATION_ACTIONS.resetState());

    userService
      .updateCoachLoginInfo({ accountId: user.accountId, coachLoggedTheFirstTime: true })
      .then(() => request(`/Account/${user.accountId}`))
      .then(R.compose(dispatch, userActions.getAccount))
      .catch(setError)
      .finally(() => setIsOpen(false));
  }, [dispatch, loading, request, user.accountId]);

  useEffect(() => {
    // CMP1-3126 open Getting Started Guide only when redirected to /dashboard
    const isPathToDashboard = pathname === ROUTES.DASHBOARD;
    const isCohealer = currentRole === UserRoles.cohealer;
    const isFirstAttemptToLoginAsCoach = !coachLoggedTheFirstTime;

    if (isPathToDashboard && isCohealer && isFirstAttemptToLoginAsCoach) {
      setIsOpen(true);
    }
  }, [coachLoggedTheFirstTime, currentRole, pathname]);

  return {
    error,
    isOpen,
    onClose,
    setIsOpen,
  };
};
