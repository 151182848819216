export const SET_INVITED_CLIENT_DATA = 'SET_INVITED_CLIENT_DATA';
export const SET_INVITED_CLIENT_DATA_LOADING = 'SET_INVITED_CLIENT_DATA_LOADING';

export const setInvitedClientDataLoading = () => ({
  type: SET_INVITED_CLIENT_DATA_LOADING,
});
export const setInvitedClientData = invitedClientData => ({
  type: SET_INVITED_CLIENT_DATA,
  payload: invitedClientData,
});
