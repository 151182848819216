import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';

import { useRouter } from 'hooks';

import { ROUTES } from '../../../constants';

function BillingRoleSwitch() {
  const {
    history,
    match: { url },
  } = useRouter();
  useEffect(() => {
    history.push(ROUTES.ROLE_SWITCH, { path: url });
  }, [history]);
  return <Redirect to={ROUTES.ROLE_SWITCH} />;
}

export default BillingRoleSwitch;
