import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import { Form } from 'formik';
import moment from 'moment';
import * as R from 'ramda';
import { getDomainIcon } from 'utils/calendar';
import Tooltip from '@material-ui/core/Tooltip';
import Button from 'components/FormUI/Button';
import DateTimePicker from 'components/FormUI/DateTimePicker';
import { PageTitleSecond } from 'components/UI/Text/TextStyles';
import { colors, toRem } from 'utils/styles';
import { useShallowEqualSelector, useRouter } from 'hooks';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { Card, CardBody, CardHeader } from 'components/UI/Card';
import { LiveVideoProvider } from 'constants.js';
import Select from 'components/FormUI/Select';
import AddCalendarModal from 'components/Modals/AddCalendarModal/AddCalendarModal';
import { RemoveCalendarModal } from 'components/Modals/AddCalendarModal/RemoveCalendar';
import SendInvitesFromModal from 'components/Modals/AddCalendarModal/SendInvitesFromModal.jsx';
import Input from 'components/FormUI/Input';
import InfoIcon from '@material-ui/icons/Info';
import * as calendarsActions from 'actions/calendars';
import { ContributionType } from 'helpers/constants';
import { TOOLTIP } from '../../../constants.js';
import Sessions from './MembershipForm/components/Sessions';
import LiveVideo from './MembershipForm/components/LiveVideo';
import Checkbox from '@material-ui/core/Checkbox';
import { Field } from 'formik';
import { getCalendars } from 'services/calendars.service.js';
import CloseIcon from '@material-ui/icons/Close';
import { valuesIn } from 'lodash';
import EnrollmentDates from './MembershipForm/components/EnrollmentDates.js';

const StyledCheckbox = styled(Checkbox)`
  padding-left: 0 !important;
`;

const StyledLabel = styled.div`
  font-weight: bold;
`;
const StyledTimeZoneLabel = styled.p`
  display: inline-block;
  font-size: ${toRem(13)};
  background: #116582;
  padding: 4px 8px;
  border-radius: 4px;
  margin: 0;
  margin-left: 10px;
  cursor: pointer;
  color: white;
  font-weight: bold;
`;

const StyledButton = styled(Button)`
  margin: ${({ mobileView }) => (mobileView ? '10px 0' : '0 10px')};
  min-width: 180px;
`;

const StyledTooltip = styled(Tooltip)`
  cursor: pointer;
`;

const StyledFlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledCalendar = styled(StyledFlexContainer)`
  width: ${({ mobileView }) => (mobileView ? '120px' : '140px')};
  justify-content: flex-start;
  align-items: center;
  border: 1px solid #dfdfdf;
  border-radius: 4px;
  padding: 6px;
  margin-right: 2px;
`;
const StyledCloseIcon = styled(CloseIcon)`
  cursor: pointer;
`;
const StyledEmail = styled.span`
  white-space: nowrap;
  width: 70px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledInfoIcon = styled(InfoIcon)`
  cursor: pointer;
  margin: -5px 0 0 5px;
`;
const StyledFullWidthFlexContainer = styled(StyledFlexContainer)`
  width: 100%;
`;
const StyledCalendarTabContainer = styled(StyledFullWidthFlexContainer)`
  flex-direction: ${({ mobileView }) => (mobileView ? 'column' : 'row')};
  align-items: ${({ mobileView }) => (mobileView ? 'flex-start' : 'center')};
  justify-content: flex-end;
  overflow: hidden;
`;

const initialLiveVideProviders = [
  LiveVideoProvider.Cohere,
  LiveVideoProvider.Custom,
  LiveVideoProvider.InPersonSession,
];

const LiveForm = ({
  values,
  errors,
  touched,
  setFieldValue,
  calendars,
  fetchCalendars,
  removeCalendar,
  addCalendar,
  mysession,
}) => {
  const defaultCalendar = calendars && calendars.filter(c => c.isDefault);
  // const Icon = getDomainIcon(values.ExternalCalendarEmail, null);

  const initialarray = [values.externalCalendarEmail];
  const [removedCalendarEmail, setRemovedCalendarEmail] = useState(false);
  const [liveVideoProviders, setLiveVideoProviders] = useState(initialLiveVideProviders);
  const user = useShallowEqualSelector(state => state?.account?.user);
  const [isSendInvitesFromModalOpen, setIsSendInvitesFromModalOpen] = useState(false);
  // const [calendararray, setCalendarArray] = useState([]);
  const contribution = useShallowEqualSelector(state => state?.contributions?.activeContribution);
  const theme = useTheme();
  const { query, pathname } = useRouter();
  const isCreate = pathname === '/create-contribution/sessions';

  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));

  const Itemcallback = useCallback(data => {
    mysession(data);
  }, []);

  useEffect(() => {
    if (user?.isZoomEnabled) {
      setLiveVideoProviders(prevState => [...prevState, LiveVideoProvider.Zoom]);
    }
  }, [user.isZoomEnabled]);

  // useEffect(() => {
  //   fetchCalendars();
  // }, []);

  const handleOpenSendInvitesFromModalOpen = e => {
    e.preventDefault();
    setIsSendInvitesFromModalOpen(true);
  };

  const handleCloseSendInvitesFromModalOpen = () => {
    setIsSendInvitesFromModalOpen(false);
  };

  const handleModalSubmit = useCallback(async () => {
    setIsSendInvitesFromModalOpen(false);
  }, []);

  const onRemoveCalendar = () => {
    setFieldValue('externalCalendarEmail', undefined);
  };

  const handleCloseRemoveCalendarModal = () => {
    setRemovedCalendarEmail(null);
  };

  const onRemovecalendarSubmit = () => {
    removeCalendar(removedCalendarEmail);
    setRemovedCalendarEmail(null);
  };

  return (
    <Form id="create-contribution-form">
      <Grid container spacing={4}>
        <EnrollmentDates
          removeCalendar={removeCalendar}
          setFieldValue={setFieldValue}
          values={values}
          enrollment={values.enrollment}
          calendars={calendars}
        />
        <Sessions
          values={values}
          enrollment={values.enrollment}
          providerName={values.liveVideoServiceProvider.providerName}
          sessions={values.sessions}
          errors={errors}
          mysession={Itemcallback}
          touched={touched}
          type={ContributionType.contributionCourse}
          setFieldValue={setFieldValue}
        />

        <LiveVideo liveVideoServiceProvider={values.liveVideoServiceProvider} setFieldValue={setFieldValue} />
      </Grid>

      {/* {isSendInvitesFromModalOpen && (
        <SendInvitesFromModal
          isOpen
          onModalClose={handleCloseSendInvitesFromModalOpen}
          onSubmit={handleModalSubmit}
          setFieldValue={setFieldValue}
          values={values}
          // calendars={calendars}
          addCalendar={addCalendar}
          contributionId={query.id}
          isCreate={isCreate}
        // lastAddedMail={lastAddedMail}
        // loadingLastEmail={loadingLastEmail}
        />
      )}

      {removedCalendarEmail && (
        <RemoveCalendarModal
          isOpen={removedCalendarEmail}
          onCancel={handleCloseRemoveCalendarModal}
          onSubmit={onRemovecalendarSubmit}
        />
      )} */}
    </Form>
  );
};

// const mapStateToProps = ({ oneToOneData, contributions, calendars }) => ({
//   calendars: calendars.calendarsAccounts,
//   lastAddedMail: calendars.lastAddedEmail,
//   loadingLastEmail: calendars.loadingLastEmail,
//   accountsForConflictsCheck: calendars.accountsForConflictsCheck,
//   contribution: contributions.activeContribution,
//   loadingTimes: contributions.loadingTimes,
// });
// const actions = {
//   fetchCalendars: calendarsActions.fetchCalendars,
//   addCalendar: calendarsActions.addCalendar,
//   getAcсountsForConflictsCheck: calendarsActions.getAcсountsForConflictsCheck,
//   getAcсountForSendReminders: calendarsActions.getAcсountForSendReminders,
//   removeCalendar: calendarsActions.removeCalendar,
// };

LiveForm.propTypes = {
  values: PropTypes.shape({
    sessions: PropTypes.arrayOf(),
    enrollment: PropTypes.shape({
      anyTime: PropTypes.bool,
      toDate: PropTypes.string,
      fromDate: PropTypes.string,
    }).isRequired,
  }).isRequired,
  errors: PropTypes.shape().isRequired,
  touched: PropTypes.shape().isRequired,
};

export default LiveForm;
