import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { UserRoles } from 'helpers/constants';
import PlaceholderImage from 'assets/placeholder.jpg';
import AdvertismentCard from 'components/AdvertismentCard';
import Loader from 'components/UI/Loader';
import { useAccount } from 'hooks';
import { useLoadUnreadPosts } from './hooks/useLoadUnreadPosts';
import { useStyles } from './hooks/useStyles';

const NotificationCard = props => {
  const { user, currentRole } = useAccount();
  const { contribution } = props;
  const isCommunityHidden = contribution?.isCommunityFeatureHiddden;

  const { unreadPostsCount, userId } = useLoadUnreadPosts({ contributionId: contribution?.id });
  const classNames = useStyles();
  const isCohealer = currentRole === UserRoles.cohealer;

  const getRedirectedTo = (isCohealer, isCommunityHidden) => {
    if (isCohealer) {
      return isCommunityHidden
        ? `/contribution-view/${contribution?.id}/about`
        : `/contribution-view/${contribution?.id}/community`;
    } else {
      return isCommunityHidden
        ? `/contribution-view/${contribution?.contributionId}/about`
        : `/contribution-view/${contribution?.contributionId}/community`;
    }
  };
  if (!contribution) {
    return (
      <Grid className={classNames.wrapper}>
        <Loader relative />
      </Grid>
    );
  }
  return (
    <Link to={getRedirectedTo(isCohealer, isCommunityHidden)}>
      <AdvertismentCard
        AvatarUrl={contribution?.authorAvatarUrl}
        author={contribution?.serviceProviderName || ''}
        img={contribution?.previewContentUrls?.[0] || PlaceholderImage}
        isBasicNotification
        isShowEnrollButton
        membersCount={
          contribution?.participants?.length === 0
            ? contribution.participantsCount > 100
              ? '100+'
              : contribution.participantsCount
            : contribution?.participants?.length || 0
        }
        title={contribution?.title || ''}
        unreadMessagesCount={contribution?.unReadPostCount || 0}
        hideMembers={contribution?.isMembersHiddenInCommunity}
        isCoach={isCohealer}
        // userId={userId}
      />
    </Link>
  );
};

NotificationCard.propTypes = {
  contribution: PropTypes.shape({
    authorAvatarUrl: PropTypes.string,
    id: PropTypes.string,
    image: PropTypes.string,
    participants: PropTypes.arrayOf(PropTypes.any),
    previewContentUrls: PropTypes.arrayOf(PropTypes.string),
    serviceProviderName: PropTypes.string,
    title: PropTypes.string,
  }).isRequired,
};

export default NotificationCard;
