import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import { ChatsList } from 'components/UI/chats'

const ClientChatsList = ({ contribution: { id, chat } }) => {
  const clientChatIds = useMemo(() => chat?.cohealerPeerChatSids && Object.values(chat.cohealerPeerChatSids), [chat])

  const getClientChatLink = (chatId) => {
    if (chat?.cohealerPeerChatSids) {
      const [userId] = Object.entries(chat.cohealerPeerChatSids).find(([_, userChatId]) => userChatId === chatId)

      return `/contribution-view/${id}/discussion/${userId}`
    }
  }

  return <ChatsList isContributionPage channelIds={clientChatIds} channelLinkProvider={getClientChatLink} />
}

ClientChatsList.propTypes = {
  contribution: PropTypes.shape({
    id: PropTypes.string,
    chat: PropTypes.shape({
      cohealerPeerChatSids: PropTypes.shape({}),
    }),
  }).isRequired,
}

export default ClientChatsList
