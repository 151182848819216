import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { useTheme, useMediaQuery } from '@material-ui/core';
import styled from 'styled-components';
// import * as purchaseService from 'services/purchase.service';
import { SuccessfulPurchaseModal } from 'components/Modals/SuccessfulPurchaseModal';
import * as contributionActions from 'actions/contributions';
import Button from 'components/FormUI/Button';
import { fetchClientContribution } from 'actions/contributions';
import { useAccount, useApplicationStatus, useHttp } from 'hooks';
import { UserRoles, PaymentStatus } from 'helpers/constants';
import PurchaseModal from '../PurchaseModal';
import ProceedModal from '../ProceedModal';
import RefuseModal from '../RefuseModal';
import useContribution from '../../hooks/useContribution';
import MobileButton from 'components/FormUI/MobileButton';
import { determineColorToUse } from 'services/contributions.service';
import { getIpGlobal, lightOrDark } from 'utils/utils';
import { JOIN_CONTRIBUTION_STATUS } from '../../../../constants';

const StyledButton = styled(Button)`
  margin-left: 20px;
  min-width: 128px;
  // max-width: 150px;
  max-width: 140px;

  ${({ mobileView, hideBtn }) =>
    mobileView &&
    hideBtn &&
    `
  margin-left:0px;
  min-width:   246px;
  max-width: 350px ;
  `}
  ${({ backgroundColor, color }) =>
    backgroundColor &&
    `
  color: ${color};
  background-color: ${backgroundColor};
`}
`;
const StyledMobileButton = styled(MobileButton)`
  margin-left: 20px;
  min-width: 128px;
  // max-width: 150px;
  max-width: 140px;

  ${({ mobileView, hideBtn }) =>
    mobileView &&
    hideBtn &&
    `
  margin-left:0px;
  min-width:   246px;
  max-width: 350px ;
  `}

  ${({ headerMobile }) =>
    headerMobile &&
    `
  margin-left: 10px;
  min-width:   100px;
`}

  ${({ backgroundColor, color }) =>
    backgroundColor &&
    `
  color: ${color};
  background-color: ${backgroundColor};
`}
`;

const PurchaseModalsAndButtonsComponent = ({ hideBtn, headerMobile, PaymentBlock, clientPreviewMode }) => {
  const contribution = useSelector(s => s.contributions.activeContribution);
  const { paymentInfo, clientPackages, isPurchased, isCouponAvailable } = contribution || {};
  const { user, currentRole } = useAccount();
  const { id, type, purchaseStatus } = useContribution();
  const { request } = useHttp();
  const dispatch = useDispatch();
  const [isPurchaseModalShowed, setPurchaseModalShowed] = useState(false);
  const [isProceedModalShowed, setProceedModalShowed] = useState(false);
  const [isPackage, setIsPackage] = useState(false);
  const [isMonthlySessionSubscription, setIsMonthlySessionSubscription] = useState(false);
  const [paymentData, setPaymentData] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [isRefuseModalShowed, setRefuseModalShowed] = useState(false);
  const [isOpenSuccessPurchaseModal, setSuccessPurchaseModal] = useState(false);
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const [isNotLoggedUser, setIsNotLoggedUser] = useState(false);
  const [isSubmitPurchaseModalNow, setIsSubmitPurchaseModalNow] = useState(false);
  const isCoach = currentRole === UserRoles.cohealer;
  const isProceeded = [PaymentStatus.requiresAction, PaymentStatus.requiresConfirmation].includes(purchaseStatus);
  const isInviteToJoin = useSelector(state => state.contributions.inviteCode);
  let colorToUse = determineColorToUse(contribution);
  const { applicationRequiredButNotApproved } = useApplicationStatus({ contribution, user });

  const applicationFormAttachedTitle = isProceeded ? 'Proceed Package Purchasing' : 'Purchase Package';
  let textColor =
    colorToUse?.TextColorCode === 'Auto'
      ? lightOrDark(colorToUse?.PrimaryColorCode)
      : colorToUse?.TextColorCode === '#000000'
      ? '#000000'
      : '#FFFFFF';
  const handlePayment = async ({ status }) => {
    if ([PaymentStatus.requiresPaymentMethod, PaymentStatus.unpurchased].includes(status)) {
      if (isNotLoggedUser) {
        setIsNotLoggedUser(false);
        return setIsSubmitPurchaseModalNow(true);
      }
      setPurchaseModalShowed(true);
    }
    if ([PaymentStatus.requiresAction, PaymentStatus.requiresConfirmation].includes(status)) {
      setPurchaseModalShowed(false);
      setProceedModalShowed(true);
    }
    if (!status) {
      const userIp = await getIpGlobal();
      dispatch(fetchClientContribution(id, userIp));
      setPurchaseModalShowed(false);
    }
  };

  const handlePurchasePackage = async couponid => {
    //   // setIsMonthlySessionSubscription(false);
    if (isEmpty(user)) {
      setIsNotLoggedUser(true);
      setPurchaseModalShowed(true);
      setIsPackage(true);
      return;
    }

    if (isPackage || !isMonthlySessionSubscription) {
      setIsPackage(true);
      setPurchaseModalShowed(true);
    } else {
      if (isProceeded) {
        setProceedModalShowed(true);
        return;
      }

      if (typeof couponid === 'string' || couponid === 'number') {
        couponid = couponid;
      } else {
        couponid = null;
      }
      setIsPackage(true);

      try {
        // const paymentDataResponse = await request('/api/purchase/one-to-one/package', 'POST', {
        //   contributionId: id,
        //   couponId: couponid,
        // });

        const paymentDataResponse = await createPackageIntentOnStripe({
          contributionId: id,
          couponId: couponid,
        });

        // setPaymentData(paymentDataResponse);
        handlePayment(paymentDataResponse);
      } catch (e) {
        setErrorMessage(e.response.data.message);
        setRefuseModalShowed(true);
      }
    }
  };

  const createPackageIntentOnStripe = params => {
    return new Promise(async resolve => {
      const paymentDataResponse = await request('/api/purchase/one-to-one/package', 'POST', params);
      setPaymentData(paymentDataResponse);
      resolve(paymentDataResponse);
    });
  };

  const proseedHandlePurchase = useCallback(
    id => {
      handlePurchasePackage(id);
    },
    [handlePurchasePackage],
  );

  const handlePurchaseProceedModalCancel = useCallback(
    (releasePaymentIntent = true) => {
      setPurchaseModalShowed(false);
      setProceedModalShowed(false);
      if (isPackage) {
        setIsPackage(false);
        if (releasePaymentIntent) {
          request('/api/purchase/cancleOneToOnePackagePayment', 'POST', {
            contributionId: id,
            created: paymentData?.created,
          }).catch(console.dir);
        }
      }
    },
    [id, paymentData, setPurchaseModalShowed, setProceedModalShowed, isPackage, setIsPackage],
  );

  const toggleSuccessPurchaseModal = () => setSuccessPurchaseModal(prevState => !prevState);

  const handlePurchaseProceedModalSubmit = () => {
    setPurchaseModalShowed(false);
    setProceedModalShowed(false);
    toggleSuccessPurchaseModal();
    if (isPackage) {
      setIsPackage(false);
    }
    setTimeout(async () => {
      const userIp = await getIpGlobal();
      dispatch(fetchClientContribution(id, userIp));
    }, 2000);

    setTimeout(async () => {
      const userIp = await getIpGlobal();
      dispatch(fetchClientContribution(id, userIp));
    }, 4000);
  };

  const handleRefuseModal = useCallback(() => {
    setRefuseModalShowed(false);
  }, [setRefuseModalShowed]);

  const handleMonthlySessionSubscription = useCallback(async () => {
    setIsPackage(false);
    if (isEmpty(user)) {
      setIsNotLoggedUser(true);
      setPurchaseModalShowed(true);
      setIsMonthlySessionSubscription(true);
      return;
    }

    if (isProceeded) {
      setProceedModalShowed(true);
      return;
    }

    setIsMonthlySessionSubscription(true);
    setPurchaseModalShowed(true);
  }, [isProceeded, id, handlePayment, user]);

  const allPurchasedPackagesAreCompleted =
    isEmpty(clientPackages) ||
    clientPackages.filter(item => !item.isMonthlySessionSubscription).every(item => item.isCompleted);

  const allPurchasedMonthlySessionSubscriptionAreCompleted =
    isEmpty(clientPackages) ||
    clientPackages
      .filter(item => item.isMonthlySessionSubscription)
      .every(item => item.ssMonthlySessionSubscriptionCompleted);

  const isPackagePurchaseEnabled = paymentInfo?.paymentOptions.includes('SessionsPackage');
  const isFreePackagePurchaseEnabled = paymentInfo?.paymentOptions.includes('FreeSessionsPackage');

  const isMonthlySessionSubscriptionEnabled = paymentInfo?.paymentOptions.includes('MonthlySessionSubscription');

  const isPackagePurchaseAllowed = isPackagePurchaseEnabled && allPurchasedPackagesAreCompleted;
  const isFreePackagePurchaseAllowed = isFreePackagePurchaseEnabled && allPurchasedPackagesAreCompleted;
  const isMonthlySessionSubscriptionPurchaseAllowed =
    isMonthlySessionSubscriptionEnabled && allPurchasedMonthlySessionSubscriptionAreCompleted;

  const showPackagePurchaseButton =
    isPackagePurchaseAllowed && allPurchasedMonthlySessionSubscriptionAreCompleted && isInviteToJoin === null;
  const showFreePackagePurchaseButton =
    isFreePackagePurchaseAllowed && allPurchasedMonthlySessionSubscriptionAreCompleted;

  const showMontlySessionSubscriptionPurchaseButton =
    !isPurchased && isMonthlySessionSubscriptionEnabled && isInviteToJoin === null;

  if (applicationRequiredButNotApproved) {
    return <></>;
  }
  return (
    <>
      {(!isCoach || clientPreviewMode) && (
        <>
          <div className="Purchase_package_btn" style={clientPreviewMode ? { pointerEvents: 'none' } : {}}>
            {showPackagePurchaseButton && (
              <>
                {mobileView ? (
                  <StyledMobileButton
                    hideBtn={hideBtn}
                    autoWidth
                    onClick={handlePurchasePackage}
                    disabled={false}
                    mobileView={mobileView}
                    headerMobile={headerMobile}
                    backgroundColor={colorToUse?.PrimaryColorCode}
                    color={textColor}
                  >
                    {!applicationRequiredButNotApproved ? applicationFormAttachedTitle : JOIN_CONTRIBUTION_STATUS.apply}
                  </StyledMobileButton>
                ) : (
                  <StyledButton
                    hideBtn={hideBtn}
                    autoWidth
                    onClick={handlePurchasePackage}
                    mobileView={mobileView}
                    backgroundColor={colorToUse?.PrimaryColorCode}
                    color={textColor}
                  >
                    {!applicationRequiredButNotApproved ? applicationFormAttachedTitle : JOIN_CONTRIBUTION_STATUS.apply}
                  </StyledButton>
                )}
              </>
            )}
            {showFreePackagePurchaseButton && isInviteToJoin && (
              <>
                {mobileView ? (
                  <StyledMobileButton
                    hideBtn={hideBtn}
                    autoWidth
                    onClick={handlePurchasePackage}
                    disabled={false}
                    mobileView={mobileView}
                    headerMobile={headerMobile}
                    backgroundColor={colorToUse?.PrimaryColorCode}
                    color={textColor}
                  >
                    {isProceeded ? 'Proceed Package Purchasing' : 'Free Package'}
                  </StyledMobileButton>
                ) : (
                  <StyledButton
                    hideBtn={hideBtn}
                    autoWidth
                    onClick={handlePurchasePackage}
                    disabled={false}
                    mobileView={mobileView}
                    backgroundColor={colorToUse?.PrimaryColorCode}
                    color={textColor}
                  >
                    {isProceeded ? 'Proceed Package Purchasing' : 'Free Package'}
                  </StyledButton>
                )}
              </>
            )}
          </div>
          {showMontlySessionSubscriptionPurchaseButton && (
            <div style={clientPreviewMode ? { pointerEvents: 'none' } : {}}>
              {mobileView ? (
                <StyledMobileButton
                  autoWidth
                  onClick={handleMonthlySessionSubscription}
                  disabled={false}
                  mobileView={mobileView}
                  hideBtn={hideBtn}
                  headerMobile={headerMobile}
                  backgroundColor={colorToUse?.PrimaryColorCode}
                  color={textColor}
                >
                  Purchase Subscription
                </StyledMobileButton>
              ) : (
                <StyledButton
                  autoWidth
                  onClick={handleMonthlySessionSubscription}
                  disabled={false}
                  mobileView={mobileView}
                  hideBtn={hideBtn}
                  backgroundColor={colorToUse?.PrimaryColorCode}
                  color={textColor}
                >
                  Purchase Subscription
                </StyledButton>
              )}
            </div>
          )}
        </>
      )}
      {isPurchaseModalShowed && (
        <PurchaseModal
          isOpen={isPurchaseModalShowed}
          onClose={handlePurchaseProceedModalCancel}
          onSubmit={handlePurchaseProceedModalSubmit}
          isPackage={isPackage}
          isMonthlySessionSubscription={isMonthlySessionSubscription}
          oneToOne
          paymentDataOneToOne={paymentData}
          updatePackageIntent={createPackageIntentOnStripe}
          proseedHandlePurchase={isNotLoggedUser && proseedHandlePurchase}
          submitNow={isSubmitPurchaseModalNow}
          isCouponAvailable={isCouponAvailable}
        />
      )}
      {isProceedModalShowed && (
        <ProceedModal
          isOpen={isProceedModalShowed}
          onClose={handlePurchaseProceedModalCancel}
          onSubmit={handlePurchaseProceedModalSubmit}
        />
      )}
      {isRefuseModalShowed && (
        <RefuseModal
          isOpen={isRefuseModalShowed}
          onClose={handleRefuseModal}
          onSubmit={handleRefuseModal}
          message={errorMessage}
        />
      )}
      {isOpenSuccessPurchaseModal && (
        <SuccessfulPurchaseModal
          isOpen={isOpenSuccessPurchaseModal}
          handleOpen={toggleSuccessPurchaseModal}
          handleClose={toggleSuccessPurchaseModal}
        />
      )}
    </>
  );
};

const actions = {
  getClientContribution: contributionActions.fetchClientContribution,
  getCohealerContribution: contributionActions.fetchCohealerContribution,
};

PurchaseModalsAndButtonsComponent.propTypes = {
  contribution: PropTypes.objectOf(PropTypes.any),
};

PurchaseModalsAndButtonsComponent.defaultProps = {
  contribution: {},
};

export default PurchaseModalsAndButtonsComponent;
