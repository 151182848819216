import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

export const IconUserHeadset = ({ className, style }) => (
  <svg
    style={style}
    className={classnames('svg-inline--fa fa-user-headset fa-w-14 fa-7x', className)}
    ariaHidden="true"
    dataPrefix="fas"
    dataIcon="user-headset"
    viewBox="0 0 448 512"
  >
    <path
      fill="currentColor"
      d="M320 352h-23.1a174.08 174.08 0 0 1-145.8 0H128A128 128 0 0 0 0 480a32 32 0 0 0 32 32h384a32 32 0 0 0 32-32 128 128 0 0 0-128-128zM48 224a16 16 0 0 0 16-16v-16c0-88.22 71.78-160 160-160s160 71.78 160 160v16a80.09 80.09 0 0 1-80 80h-32a32 32 0 0 0-32-32h-32a32 32 0 0 0 0 64h96a112.14 112.14 0 0 0 112-112v-16C416 86.13 329.87 0 224 0S32 86.13 32 192v16a16 16 0 0 0 16 16zm160 0h32a64 64 0 0 1 55.41 32H304a48.05 48.05 0 0 0 48-48v-16a128 128 0 0 0-256 0c0 40.42 19.1 76 48.35 99.47-.06-1.17-.35-2.28-.35-3.47a64.07 64.07 0 0 1 64-64z"
    />
  </svg>
)

IconUserHeadset.propTypes = {
  className: PropTypes.string,
  style: PropTypes.string,
}

IconUserHeadset.defaultProps = {
  className: null,
  style: {},
}
