import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { LabelText } from 'components/UI/Text/TextStyles';
import { ClientJourneyContribution } from 'services/dataContracts/contributions';
import ContributionsList from '../../ContributionsList';
import { Box, Container, Grid } from '@material-ui/core';
import SessionCard from '../../SessionCard/SessionCard';
import styled from 'styled-components';
import { toRem } from 'utils/styles';
import { JourneySection } from 'helpers/constants/sections';
import { isEmpty } from 'ramda';
const Heading = styled.p`
  font-size: ${toRem(22)};
  font-weight: 900;
  line-height: 1.5;
  letter-spacing: 0.12px;
  color: #116582;

  ${({ mobileView }) => mobileView && `font-size: ${toRem(16)};`}
`;

const StyledSessionsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
`;

const PastContributions = ({ past }) => {
  const [pastSessions, setPastSessions] = useState(past);
  const deleteIndexPast = indexData => {
    const data1 = pastSessions.filter((item, index) => index != indexData);
    setPastSessions(data1);
  };
  if (isEmpty(past)) {
    return <Grid container>You don't have any active sessions yet.</Grid>;
  }
  return (
    <Grid container direction="column">
      <Grid item style={{ marginBottom: '70px' }}>
        <Heading>Past Sessions</Heading>
        <StyledSessionsContainer>
          {pastSessions?.length > 0 ? (
            pastSessions?.map((s, i) => {
              return (
                <SessionCard
                  key={i}
                  index={i}
                  section={JourneySection.past}
                  sessioninfo={s}
                  removeIndexFunction={() => {
                    deleteIndexPast(i);
                  }}
                />
              );
            })
          ) : (
            <Grid container>No past sessions available.</Grid>
          )}
        </StyledSessionsContainer>
      </Grid>
    </Grid>
  );
};

PastContributions.propTypes = {
  contributions: PropTypes.arrayOf(PropTypes.instanceOf(ClientJourneyContribution)).isRequired,
};

export default PastContributions;
