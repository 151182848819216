import React from 'react';
import SvgHandler from 'pages/ContributionView/components/ContributionHeader/ApplicationForm/SvgHandler';
import PropTypes from 'prop-types';

const NoteIcon = ({ fillColor }) => {
  return (
    <SvgHandler
      fill={fillColor}
      height="28px"
      width="28px"
      viewBox="0 0 24 24"
      className="top-15 cursor-pointer MuiSvgIcon-root"
      pathD="M13.7811 0.300049H3.28113C2.48574 0.300917 1.72319 0.617267 1.16077 1.17969C0.598346 1.74211 0.281996 2.50467 0.281128 3.30005V18.3C0.281996 19.0954 0.598346 19.858 1.16077 20.4204C1.72319 20.9828 2.48574 21.2992 3.28113 21.3H13.7811C14.5765 21.2992 15.3391 20.9828 15.9015 20.4204C16.4639 19.858 16.7803 19.0954 16.7811 18.3V3.30005C16.7803 2.50467 16.4639 1.74211 15.9015 1.17969C15.3391 0.617267 14.5765 0.300917 13.7811 0.300049ZM8.53113 13.05H4.78113C4.58222 13.05 4.39145 12.971 4.2508 12.8304C4.11015 12.6897 4.03113 12.499 4.03113 12.3C4.03113 12.1011 4.11015 11.9104 4.2508 11.7697C4.39145 11.6291 4.58222 11.55 4.78113 11.55H8.53113C8.73004 11.55 8.92081 11.6291 9.06146 11.7697C9.20211 11.9104 9.28113 12.1011 9.28113 12.3C9.28113 12.499 9.20211 12.6897 9.06146 12.8304C8.92081 12.971 8.73004 13.05 8.53113 13.05ZM12.2811 9.30005H4.78113C4.58222 9.30005 4.39145 9.22103 4.2508 9.08038C4.11015 8.93973 4.03113 8.74896 4.03113 8.55005C4.03113 8.35114 4.11015 8.16037 4.2508 8.01972C4.39145 7.87907 4.58222 7.80005 4.78113 7.80005H12.2811C12.48 7.80005 12.6708 7.87907 12.8115 8.01972C12.9521 8.16037 13.0311 8.35114 13.0311 8.55005C13.0311 8.74896 12.9521 8.93973 12.8115 9.08038C12.6708 9.22103 12.48 9.30005 12.2811 9.30005ZM12.2811 5.55005H4.78113C4.58222 5.55005 4.39145 5.47103 4.2508 5.33038C4.11015 5.18973 4.03113 4.99896 4.03113 4.80005C4.03113 4.60114 4.11015 4.41037 4.2508 4.26972C4.39145 4.12907 4.58222 4.05005 4.78113 4.05005H12.2811C12.48 4.05005 12.6708 4.12907 12.8115 4.26972C12.9521 4.41037 13.0311 4.60114 13.0311 4.80005C13.0311 4.99896 12.9521 5.18973 12.8115 5.33038C12.6708 5.47103 12.48 5.55005 12.2811 5.55005Z"
    />
  );
};

NoteIcon.propTypes = {
  fillColor: PropTypes.string.isRequired,
};

export default NoteIcon;
