import React, { useEffect, useState } from 'react';
import { Grid, Paper } from '@material-ui/core';
import styled from 'styled-components';
import Calendars from 'pages/Account/Calendars/Calendars';
import Button from 'components/FormUI/Button';
// import { ZoomIntegration } from '../../../integrations/Zoom';
import { useZoom } from '../../../integrations/Zoom/hooks/useZoom';
import PaymentProcessors from '../Calendars/PaymentProcessors';
import * as contributionActions from 'actions/contributions';
import { EmailMarketing } from 'integrations/EmailMarketing/components/EmailMarketing';
import { EmailMarketingIntegrationService } from 'integrations/EmailMarketing/service/marketingAccountIntegration.service';
import Joyride, {
  CallBackProps,
  STATUS,
  Step,
  EVENTS,
  StoreHelpers,
  BeaconRenderProps,
  TooltipRenderProps,
} from 'react-joyride';
import { useHistory } from 'react-router-dom';
import CoachImage from 'assets/chatlogo.png';
import { UpdateUserProgressBarData } from 'services/user.service';
import { updateUser } from 'actions/update-user';
import { useLocation, useParams } from 'react-router-dom';
import { getLatestApproved, getUpcomingCreated } from 'services/contributions.service';
import Modal from 'components/UI/Modal';
import useAccount from 'hooks/useAccount';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery, useTheme } from '@material-ui/core';
import ChatAndGetStartedButtons from 'pages/Dashboard/components/CohealerDashboard/LiveChat/LiveChatContainer';
import TemplateOne from 'assets/congratulations.png';
import { fetchDomains } from 'actions/emailMarketing.actions';
import useRouter from 'hooks/useRouter';
const StyledImg = styled.div`
  background-image: url(${({ previewImg }) => previewImg});
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  display: inline-block;
  border-radius: 40px;
  align-items: center;
  z-index: 1;
`;
const StyledImgCongrats = styled.div`
  background-image: url(${({ previewImg }) => previewImg});
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100px;
  height: 100px;
  display: inline-block;
  // border-radius: 40px;
  align-items: center;
  z-index: 1;
`;

const style = { marginBottom: 24 };
const emailMarketingstyle = { marginBottom: 70 };

export const IntegrationsTab = () => {
  const { email } = useZoom();
  const { user } = useAccount();
  const { location } = useRouter();
  const isComingFromConnectDomain = location?.state?.from === 'connectdomain';
  const dispatch = useDispatch();
  const [stepIndex, setStepIndex] = useState(4);
  const { state } = useLocation();
  const [emailMarketingAccounts, setEmailMarketingAccounts] = useState([]);
  const initialStepBankAccount = state?.from === 'bankAccount';
  const initialStepVerify = state?.from === 'verify';
  const showCompletedGetStart = useSelector(state => state.contributions.showCompletedGetStart);

  const refreshStep = showCompletedGetStart === 'bankAccountRefresh';
  const refreshStep1 = showCompletedGetStart === 'verifyRefresh';
  const [run, setRun] = useState(false);
  const history = useHistory();
  const [contibId, setContibId] = useState(null);
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  useEffect(() => {
    getLatestApproved().then(res => {
      setContibId(res);
    });
  }, []);

  useEffect(() => {
    dispatch(fetchDomains());
  }, []);

  const [steps, setSteps] = useState([
    {
      spotlightClicks: true,
      disableBeacon: true,
      hideFooter: true,
      // floaterProps: { disableFlip: true },
      content: (
        <div className="step4">
          <div
            className="image"
            style={{
              height: '70px',
              width: '70px',
              backgroundColor: 'white',
              position: 'absolute',
              borderRadius: '100px',
              top: -45,
              left: 150,
              borderColor: '#215C73',
              borderWidth: '5px',
            }}
          >
            <StyledImg previewImg={CoachImage} />
          </div>
          <div
            style={{
              fontSize: '18px',
              color: '#215C73',
              display: 'flex',
              alignSelf: 'flex-start',
              fontWeight: 850,
              fontFamily: 'Avenir',
            }}
          >
            Verify Your Information
          </div>
          <div
            style={{
              color: 'black',
              fontSize: '16px',
              marginTop: '13px',
              fontWeight: 350,
              fontFamily: 'Avenir',
              textAlign: 'left',
            }}
          >
            Verify your information to create a Contribution and collect revenue.
          </div>
          <div className="buttons" style={{ flexDirection: 'row', display: 'flex' }}>
            <Button
              className="btn-left"
              autoWidth
              backgroundColor={'white'}
              invert
              style={{
                marginTop: '40px',
                paddingTop: '10px',
                paddingBottom: '10px',
                width: '100%',
                borderColor: '#A58B62',
                borderWidth: '1px',
                borderRadius: '5px',
                color: '#A58B62',
              }}
              onClick={() => {
                updateUserF('isPlan');
                // setRun(false);
              }}
            >
              Mark as Complete
            </Button>

            <Button
              className="btn-right"
              autoWidth
              invert
              style={{
                marginTop: '40px',
                paddingTop: '10px',
                paddingBottom: '10px',
                width: '30%',
                borderColor: '#215C73',
                borderWidth: '1px',
                borderRadius: '5px',
                color: '#215C73',
                marginLeft: '10px',
              }}
              onClick={() => {
                joyRideHelpers.next();
              }}
            >
              Next
            </Button>
          </div>
        </div>
      ),
      placement: 'left',
      target: '#integration10999',
    },
    {
      spotlightClicks: true,
      disableBeacon: true,
      hideFooter: true,
      floaterProps: { disableFlip: true },
      content: (
        <div className="step4">
          <div
            className="image"
            style={{
              height: '70px',
              width: '70px',
              backgroundColor: 'white',
              position: 'absolute',
              borderRadius: '100px',
              top: -45,
              left: 150,
              borderColor: '#215C73',
              borderWidth: '5px',
            }}
          >
            <StyledImg previewImg={CoachImage} />
          </div>
          <div
            style={{
              fontSize: '18px',
              color: '#215C73',
              display: 'flex',
              alignSelf: 'flex-start',
              fontWeight: 850,
              fontFamily: 'Avenir',
            }}
          >
            Verify Your Information
          </div>
          <div
            style={{
              color: 'black',
              fontSize: '16px',
              marginTop: '13px',
              fontWeight: 350,
              fontFamily: 'Avenir',
              textAlign: 'left',
            }}
          >
            Verify your information to create a Contribution and collect revenue.
          </div>
          <div className="buttons" style={{ flexDirection: 'row', display: 'flex' }}>
            <Button
              className="btn-left"
              autoWidth
              backgroundColor={'white'}
              invert
              style={{
                marginTop: '40px',
                paddingTop: '10px',
                paddingBottom: '10px',
                width: '100%',
                borderColor: '#A58B62',
                borderWidth: '1px',
                borderRadius: '5px',
                color: '#A58B62',
              }}
              onClick={() => {
                updateUserF('isPlan');
                // setRun(false);
              }}
            >
              Mark as Complete
            </Button>

            <Button
              className="btn-right"
              autoWidth
              invert
              style={{
                marginTop: '40px',
                paddingTop: '10px',
                paddingBottom: '10px',
                width: '30%',
                borderColor: '#215C73',
                borderWidth: '1px',
                borderRadius: '5px',
                color: '#215C73',
                marginLeft: '10px',
              }}
              onClick={() => {
                joyRideHelpers.next();
              }}
            >
              Next
            </Button>
          </div>
        </div>
      ),
      placement: 'left',
      target: '#integration1222',
    },
    {
      spotlightClicks: true,
      disableBeacon: true,
      hideFooter: true,
      floaterProps: { disableFlip: true },
      content: (
        <div className="step4">
          <div
            className="image"
            style={{
              height: '70px',
              width: '70px',
              backgroundColor: 'white',
              position: 'absolute',
              borderRadius: '100px',
              top: -45,
              left: 150,
              borderColor: '#215C73',
              borderWidth: '5px',
            }}
          >
            <StyledImg previewImg={CoachImage} />
          </div>
          <div
            style={{
              fontSize: '18px',
              color: '#215C73',
              display: 'flex',
              alignSelf: 'flex-start',
              fontWeight: 850,
              fontFamily: 'Avenir',
            }}
          >
            Verify Your Information
          </div>
          <div
            style={{
              color: 'black',
              fontSize: '16px',
              marginTop: '13px',
              fontWeight: 350,
              fontFamily: 'Avenir',
              textAlign: 'left',
            }}
          >
            Verify your information to create a Contribution and collect revenue.
          </div>
          <div className="buttons" style={{ flexDirection: 'row', display: 'flex' }}>
            <Button
              className="btn-left"
              autoWidth
              backgroundColor={'white'}
              invert
              style={{
                marginTop: '40px',
                paddingTop: '10px',
                paddingBottom: '10px',
                width: '100%',
                borderColor: '#A58B62',
                borderWidth: '1px',
                borderRadius: '5px',
                color: '#A58B62',
              }}
              onClick={() => {
                updateUserF('isPlan');
                // setRun(false);
              }}
            >
              Mark as Complete
            </Button>

            <Button
              className="btn-right"
              autoWidth
              invert
              style={{
                marginTop: '40px',
                paddingTop: '10px',
                paddingBottom: '10px',
                width: '30%',
                borderColor: '#215C73',
                borderWidth: '1px',
                borderRadius: '5px',
                color: '#215C73',
                marginLeft: '10px',
              }}
              onClick={() => {
                joyRideHelpers.next();
              }}
            >
              Next
            </Button>
          </div>
        </div>
      ),
      placement: 'left',
      target: '#integration111',
    },
    {
      spotlightClicks: true,
      disableBeacon: true,
      hideFooter: true,
      // floaterProps: { disableFlip: true },
      content: (
        <div className="step4">
          <div
            className="image"
            style={{
              height: '70px',
              width: '70px',
              backgroundColor: 'white',
              position: 'absolute',
              borderRadius: '100px',
              top: -45,
              left: 150,
              borderColor: '#215C73',
              borderWidth: '5px',
            }}
          >
            <StyledImg previewImg={CoachImage} />
          </div>
          <div
            style={{
              fontSize: '18px',
              color: '#215C73',
              display: 'flex',
              alignSelf: 'flex-start',
              fontWeight: 850,
              fontFamily: 'Avenir',
            }}
          >
            Verify Your Information
          </div>
          <div
            style={{
              color: 'black',
              fontSize: '16px',
              marginTop: '13px',
              fontWeight: 350,
              fontFamily: 'Avenir',
              textAlign: 'left',
            }}
          >
            Verify your information to create a Contribution and collect revenue.
          </div>
          <div className="buttons" style={{ flexDirection: 'row', display: 'flex' }}>
            <Button
              className="btn-left"
              autoWidth
              backgroundColor={'white'}
              invert
              style={{
                marginTop: '40px',
                paddingTop: '10px',
                paddingBottom: '10px',
                width: '100%',
                borderColor: '#A58B62',
                borderWidth: '1px',
                borderRadius: '5px',
                color: '#A58B62',
              }}
              onClick={() => {
                updateUserF('isPlan');
                // setRun(false);
              }}
            >
              Mark as Complete
            </Button>

            <Button
              className="btn-right"
              autoWidth
              invert
              style={{
                marginTop: '40px',
                paddingTop: '10px',
                paddingBottom: '10px',
                width: '30%',
                borderColor: '#215C73',
                borderWidth: '1px',
                borderRadius: '5px',
                color: '#215C73',
                marginLeft: '10px',
              }}
              onClick={() => {
                joyRideHelpers.next();
              }}
            >
              Next
            </Button>
          </div>
        </div>
      ),
      placement: 'left',
      target: '#integration100',
    },
    {
      spotlightClicks: true,
      disableBeacon: true,
      hideFooter: true,
      // floaterProps: { disableFlip: true },
      content: (
        <div className="step4">
          <div
            className="image"
            style={{
              height: '70px',
              width: '70px',
              backgroundColor: 'white',
              position: 'absolute',
              borderRadius: '100px',
              top: -45,
              left: 150,
              borderColor: '#215C73',
              borderWidth: '5px',
            }}
          >
            <StyledImg previewImg={CoachImage} />
          </div>
          <div
            style={{
              fontSize: '18px',
              color: '#215C73',
              display: 'flex',
              alignSelf: 'flex-start',
              fontWeight: 850,
              fontFamily: 'Avenir',
            }}
          >
            Calendar Connections
          </div>
          <div
            style={{
              color: 'black',
              fontSize: '16px',
              marginTop: '13px',
              fontWeight: 350,
              fontFamily: 'Avenir',
              textAlign: 'left',
            }}
          >
            The default calendar you choose is the email that your cal invites will come from. Further, you can prevent
            double booking by connecting your calendars if you provide 1:1 services.
          </div>
          <div className="buttons" style={{ flexDirection: 'row', display: 'flex' }}>
            {/* <Button
              className="btn-left"
              autoWidth
              backgroundColor={'white'}
              invert
              style={{
                marginTop: '40px',
                paddingTop: '10px',
                paddingBottom: '10px',
                width: '100%',
                borderColor: '#A58B62',
                borderWidth: '1px',
                borderRadius: '5px',
                color: '#A58B62',
              }}
              onClick={() => {
                updateUserF('isPlan');
                // setRun(false);
              }}
            >
              Mark as Complete
            </Button> */}

            <Button
              className="btn-right"
              autoWidth
              invert
              style={{
                marginTop: '40px',
                paddingTop: '10px',
                paddingBottom: '10px',
                width: '30%',
                borderColor: '#215C73',
                borderWidth: '1px',
                borderRadius: '5px',
                color: '#215C73',
                marginLeft: '10px',
                display: 'flex',
                justifyContent: 'center',
              }}
              onClick={() => {
                joyRideHelpers.next();
              }}
            >
              Next
            </Button>
          </div>
        </div>
      ),
      placement: 'left',
      target: '#integration1',
    },
    {
      spotlightClicks: true,
      disableBeacon: true,
      hideFooter: true,
      // floaterProps: { disableFlip: true },
      content: (
        <div className="step4">
          <div
            className="image"
            style={{
              height: '70px',
              width: '70px',
              backgroundColor: 'white',
              position: 'absolute',
              borderRadius: '100px',
              top: -45,
              left: 150,
              borderColor: '#215C73',
              borderWidth: '5px',
            }}
          >
            <StyledImg previewImg={CoachImage} />
          </div>
          <div
            style={{
              fontSize: '18px',
              color: '#215C73',
              display: 'flex',
              alignSelf: 'flex-start',
              fontWeight: 850,
              fontFamily: 'Avenir',
            }}
          >
            Connect Your Stripe
          </div>
          <div
            style={{
              color: 'black',
              fontSize: '16px',
              marginTop: '13px',
              fontWeight: 350,
              fontFamily: 'Avenir',
              textAlign: 'left',
            }}
          >
            Please connect your existing Stripe account or verify your information on the simple setup so that you can
            receive payments on the platform.If you have any questions, please email support@cohere.live.
          </div>
          <div className="buttons" style={{ flexDirection: 'row', display: 'flex' }}>
            {/* <Button
              className="btn-left"
              autoWidth
              backgroundColor={'white'}
              invert
              style={{
                marginTop: '40px',
                paddingTop: '10px',
                paddingBottom: '10px',
                width: '100%',
                borderColor: '#A58B62',
                borderWidth: '1px',
                borderRadius: '5px',
                color: '#A58B62',
              }}
              onClick={() => {
                updateUserF();
                // setRun(false);
              }}
            >
              Mark as Complete
            </Button> */}
            <Button
              className="btn-right"
              autoWidth
              invert
              style={{
                marginTop: '40px',
                paddingTop: '10px',
                paddingBottom: '10px',
                width: '30%',
                borderColor: '#215C73',
                borderWidth: '1px',
                borderRadius: '5px',
                color: '#215C73',
                marginLeft: '10px',
                display: 'flex',
                justifyContent: 'center',
              }}
              onClick={() => {
                // joyRideHelpers.next();
                history.push('/account/video', { from: 'integration3' });
              }}
            >
              Next
            </Button>
          </div>
        </div>
      ),
      placement: 'left',
      target: '#integration2',
    },
    {
      spotlightClicks: true,
      disableBeacon: true,
      hideFooter: true,
      // floaterProps: { disableFlip: true },
      content: (
        <div className="step4">
          <div
            className="image"
            style={{
              height: '70px',
              width: '70px',
              backgroundColor: 'white',
              position: 'absolute',
              borderRadius: '100px',
              top: -45,
              left: 150,
              borderColor: '#215C73',
              borderWidth: '5px',
            }}
          >
            <StyledImg previewImg={CoachImage} />
          </div>
          <div
            style={{
              fontSize: '18px',
              color: '#215C73',
              display: 'flex',
              alignSelf: 'flex-start',
              fontWeight: 850,
              fontFamily: 'Avenir',
            }}
          >
            Connect Your Zoom Account
          </div>
          <div
            style={{
              color: 'black',
              fontSize: '16px',
              marginTop: '13px',
              fontWeight: 350,
              fontFamily: 'Avenir',
              textAlign: 'left',
            }}
          >
            If you have an upgraded Zoom account that you wish to use for your sessions, please connect it here.
          </div>
          <div className="buttons" style={{ flexDirection: 'row', display: 'flex' }}>
            {refreshStep === false && (
              <div>
                <Button
                  className="btn-left"
                  autoWidth
                  backgroundColor={'white'}
                  invert
                  style={{
                    marginTop: '40px',
                    paddingTop: '10px',
                    paddingBottom: '10px',
                    width: '100%',
                    borderColor: '#A58B62',
                    borderWidth: '1px',
                    borderRadius: '5px',
                    color: '#A58B62',
                  }}
                  onClick={() => {
                    updateUserF();
                    // setRun(false);
                  }}
                >
                  Mark as Complete
                </Button>
                <Button
                  className="btn-left"
                  autoWidth
                  backgroundColor={'white'}
                  invert
                  style={{
                    marginTop: '10px',
                    paddingTop: '10px',
                    paddingBottom: '10px',
                    width: '100%',
                    borderColor: '#215C73',
                    borderWidth: '1px',
                    borderRadius: '5px',
                    color: '#215C73',
                  }}
                  onClick={() => {
                    updateUserF('', true);
                    // setRun(false);
                  }}
                >
                  Complete & Next
                </Button>
              </div>
            )}

            {/* <Button
              className="btn-right"
              autoWidth
              invert
              style={{
                marginTop: '40px',
                paddingTop: '10px',
                paddingBottom: '10px',
                width: '30%',
                borderColor: '#215C73',
                borderWidth: '1px',
                borderRadius: '5px',
                color: '#215C73',
                marginLeft: '10px',
              }}
              onClick={() => {
                joyRideHelpers.skip();
                setRun(false);
              }}
            >
              Next
            </Button> */}
          </div>
        </div>
      ),
      placement: 'left',
      target: '#integration3',
    },
  ]);

  let joyRideHelpers;
  const getHelpers = helpers => {
    joyRideHelpers = helpers;
  };
  const [showModal, setShowModal] = useState(false);

  const togglePopup = () => {
    setShowModal(!showModal);
  };

  useEffect(() => {
    if (mobileView && (user.userProgressbarData.IntegrationsDone === false || refreshStep)) {
      setRun(true);
    } else if (user.userProgressbarData.IntegrationsDone === false || refreshStep) {
      setRun(true);
    }
  }, [mobileView, joyRideHelpers]);

  useEffect(() => {
    if (isComingFromConnectDomain) {
      const element = document.getElementById('integration3');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  }, []);

  const updateUserF = async (item, goToNextStep = false) => {
    let obj;
    obj = {
      id: user.id,
      userProgressbarData: {
        ...user.userProgressbarData,
        IntegrationsDone: true,
      },
      progressBarPercentage: user.progressBarPercentage,
    };

    await UpdateUserProgressBarData(obj).then(res => {
      let u = {
        ...user,
        userProgressbarData: { ...res.userProgressbarData },
        progressBarPercentage: res.progressBarPercentage,
      };
      dispatch(updateUser(u));
      if (res.progressBarPercentage == 100) {
        setShowModal(true);
      }
      if (goToNextStep) {
        if (res.userProgressbarData?.IsPlanPurchased === false) {
          history.push('/account/payment', { from: 'verify' });
        } else if (res?.userProgressbarData?.IsBankAccountConnected === false) {
          history.push('/account/payment', { from: 'bankAccount' });
        } else if (res?.userProgressbarData?.IntegrationsDone === false) {
          history.push('/account/integrations', { from: 'bankAccount' });
        } else if (res?.userProgressbarData?.IsProfileUploaded === false) {
          history.push('/account/profile');
        } else if (res?.userProgressbarData?.FirstContributionDone === false) {
          dispatch(contributionActions.setPopUp(true));
          dispatch(contributionActions.setShowCompletedGetStart('create'));
          history.push('/dashboard');
        } else if (res?.userProgressbarData?.IsWebsiteAdded === false) {
          history.push('/account/profile', { from: 'Manage Your Website Information' });
        } else if (res?.userProgressbarData?.InviteLink === false) {
          contibId === null ? console.log('Helloo') : history.push(`contribution-view/${contibId}/about`);
        }
      }
    });
    joyRideHelpers.skip();
  };

  const handleJoyrideCallback = data => {
    const { status, type, index } = data;
    const nextStepIndex = index + 1;

    const finishedStatuses = ['finished', 'skipped'];
    if (finishedStatuses.includes(status)) {
      setRun(false);
    }
    if ([EVENTS.STEP_AFTER].includes(type)) {
      setStepIndex(nextStepIndex);
    }
    if (data.action === 'close') {
      setRun(false);
    }

    console.groupCollapsed(type);
    console.groupEnd();
  };

  const isZoomAvailable =
    email === 'pbriznktrrtedazuuv@mrvpm.net' ||
    email === 'testkirill1313@gmail.com' ||
    email === 'numan@cohere.live' ||
    email === 'mckenze@yopmail.com' ||
    email === 'bruno@cohere.live' ||
    email === 'jonathan.tarith@gmail.com';
  const closeJoyRide = () => {
    setRun(false);
    setStepIndex(100);
  };
  return (
    <Grid container spacing={2}>
      <Joyride
        // tooltipComponent={Tooltip}
        callback={handleJoyrideCallback}
        continuous={true}
        getHelpers={getHelpers}
        run={run}
        stepIndex={stepIndex}
        showProgress={false}
        showSkipButton={false}
        steps={isComingFromConnectDomain ? [] : steps}
        styles={{
          options: {
            primaryColor: '#CDBA8F',
            zIndex: 10000000,
          },
        }}
      />
      <Grid item style={style} xs={12} sm={6} md={12}>
        <Calendars
          isComingFromConnectDomain={isComingFromConnectDomain}
          integrationPage={true}
          closeJoyRide={closeJoyRide}
        />
      </Grid>
      <Grid item style={style} xs={12} sm={12} md={12}>
        <PaymentProcessors />
      </Grid>
      {/* <Grid item style={style} xs={12} sm={6} md={12}>
        <ZoomIntegration />
      </Grid> */}
      <Grid item style={emailMarketingstyle} xs={12} sm={6} md={12}>
        <EmailMarketing accounts={emailMarketingAccounts} />
      </Grid>

      <ChatAndGetStartedButtons page={'ProfilePage'} />
    </Grid>
  );
};
