const KILOBYTE = 1024;
const MEGABYTE = 1024 * KILOBYTE;
const GIGABYTE = 1024 * MEGABYTE;

const DisplaySizeUnits = {
  byte: 'byte',
  kiloByte: 'kiloByte',
  megaByte: 'megaByte',
  gigaByte: 'gigaByte',
};

const getDocumentDisplaySize = sizeInBytes => {
  let units = DisplaySizeUnits.byte;
  let unitsValue = 1;

  switch (true) {
    case sizeInBytes >= GIGABYTE: {
      units = DisplaySizeUnits.gigaByte;
      unitsValue = GIGABYTE;
      break;
    }
    case sizeInBytes >= MEGABYTE: {
      units = DisplaySizeUnits.megaByte;
      unitsValue = MEGABYTE;
      break;
    }
    case sizeInBytes >= KILOBYTE: {
      units = DisplaySizeUnits.kiloByte;
      unitsValue = KILOBYTE;
      break;
    }
  }

  return {
    size: sizeInBytes / unitsValue,
    units,
  };
};

export default getDocumentDisplaySize;
export { DisplaySizeUnits };
