import { Grid } from '@material-ui/core';
import React from 'react';

function ClientOneToOneSessionsCard() {
  return (
    <Grid md={4}>
      <div>ClientOneToOneSessionsCard</div>
    </Grid>
  );
}

export default ClientOneToOneSessionsCard;
