import React, { useCallback, useState, useRef, useEffect, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { mdiHelpCircleOutline } from '@mdi/js';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core';
import { useField } from 'formik';
// import Switch from '@mui/material/Switch';
import { Switch, StyledInput, StyledSlider } from '../switch/style';

import Icon from 'components/UI/Icon';
import Tooltip from '@material-ui/core/Tooltip';
import { TOOLTIP } from '../../constants';
import EditButton from 'components/UI/ListButtons/EditButton';
import Input from 'components/FormUI/Input';
import CollapseIcon from 'assets/ic-collapse-arrows.svg';
import ExpandIcon from 'assets/ic-expand-arrows.svg';

import { colors } from 'utils/styles';
import { style } from '@mui/system';
import { filledInputClasses } from '@mui/material';
import { CKEditor } from 'ckeditor4-react';
import './TextArea.scss';
import { convertToPlainText } from 'utils/utils';
import RichTextPlaceholder from './RichTextPlaceholder';
import Logo from 'components/FormUI/Logo';
import Button from 'components/FormUI/Button';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import UndoIcon from '@mui/icons-material/Undo';
import RedoIcon from '@mui/icons-material/Redo';

const useStyles = makeStyles(() => ({
  labelRoot: {
    fontFamily: 'Avenir',
    lineHeight: '1rem',
    letterSpacing: '0.4px',
    fontSize: `${14 / 16}rem`,
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 300,
  },
}));

const StyledSwitch = withStyles({
  switchBase: {
    color: colors.darkOceanBlue,
    '&$checked': {
      color: colors.darkOceanBlue,
    },
    '&$checked + $track': {
      backgroundColor: colors.darkOceanBlue,
    },
  },
  checked: {},
  track: {},
})(Switch);

const StyledInputLabel = styled(InputLabel)`
  margin-top: 10px;
  ${({ profile }) =>
    !profile &&
    css`
      margin-left: 10px;
    `}
`;

const StyledTextArea = styled.textarea`
  ${({ affiliateRow }) =>
    affiliateRow === true
      ? css`
          border: solid 1px #dbe7f2;
          border-radius: 4px;
        `
      : css`
          border: solid 1px #e4e4e4;
          border-radius: 3px;
        `}
  font-size: 1rem;
  color: rgba(0, 0, 0, 0.87);
  line-height: 1.27;
  letter-spacing: 0.25px;
  resize: none;
  padding: 0.85rem;
  font-family: 'Avenir';
  font-weight: normal;
  outline: none;

  ::-webkit-input-placeholder {
    font-size: 0.875rem;
    color: #2b2b2b;
  }
  ::-moz-placeholder {
    font-size: 0.875rem;
    color: #2b2b2b;
  } /* Firefox 19+ */
  :-moz-placeholder {
    font-size: 0.875rem;
    color: #2b2b2b;
  } /* Firefox 18- */
  :-ms-input-placeholder {
    font-size: 0.875rem;
    color: #2b2b2b;
  }

  ${({ error }) =>
    error &&
    css`
      border-color: ${colors.fadedPurple};
    `}

  ${({ isCollapsed }) =>
    !isCollapsed &&
    `
    height: 360px;
  `}
`;

const HelperText = styled.p`
  color: rgba(0, 0, 0, 0.87);
  margin: 0;
  font-size: 0.75rem;
  margin-top: 3px;
  font-weight: normal;
  line-height: 2rem;
  letter-spacing: 0.08px;
  font-family: 'Avenir';
  text-align: ${({ align }) => align || 'right'};
`;

const StyledErrorHelper = styled(HelperText)`
  text-align: left;
  color: ${colors.fadedPurple};
  font-size: 1rem;
  text-align: left;
  letter-spacing: 0.1px;
`;

const TextFieldContainer = styled.div`
  display: inline-flex;
  border: 0;
  padding: 0;
  position: relative;
  min-width: 0;
  flex-direction: column;
  vertical-align: top;

  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 100%;
    `}
`;

const StyledLabel = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledIcon = styled.img`
  position: absolute;
  right: 7px;
  top: 55px;
  width: 12px;
  height: 12px;
  transition: margin ease-in-out 50ms;

  ${({ initialMode }) =>
    !initialMode &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `}
  ${({ styleOverrides }) => styleOverrides && styleOverrides}

  &:hover {
    cursor: pointer;
  }
`;

const EditorWrapWithMagicWrite = styled.span`
  .magic-write-button {
    min-width: 128px;
    width: 128px;
    height: 32px;
    min-height: 32px;
    font-family: Avenir;
    font-size: 14px;
    font-weight: 800;
    line-height: 22px;
    padding: 0px;
    border-radius: 4px;
    opacity: 0px;
    background: rgba(255, 255, 255, 1);
    z-index: 1;

    &:hover {
      background: #f5f5f5;
    }

    img {
      height: 24px;
      width: 24px;
      margin-right: 5px;
      margin-bottom: 3px;
    }
  }

  &:hover,
  &.loading {
    .improve-text-actions {
      display: flex !important;

      div {
        display: flex !important;
      }
    }
  }
`;

const ImproveTextActions = styled.div`
  display: none;
  gap: 10px;
  align-items: center;
  bottom: 32px;
  height: 0px;
  justify-content: right;
  right: 15px;
  z-index: 1;
  position: relative;

  .actions-container {
    box-shadow: 0px 2px 12px 0px rgba(53, 71, 90, 0.2);
    background: rgba(255, 255, 255, 1);
    height: 40px;
    padding: 5px;
    gap: 10px;
    align-items: center;
    display: none;
    right: 1px;
    border-radius: 4px;
    opacity: 0px;
    min-width: 136px;
    justify-content: center;
  }

  img {
    height: 24px;
    width: 24px;
    margin-right: 5px;
    margin-bottom: 3px;
  }

  .disabled-icon {
    opacity: 30%;
    cursor: default;
    pointer-events: none;
  }

  .typing-animation {
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 20px;
    color: #888;
    display: flex;
    align-items: center;
    background: inherit;
    background-color: inherit;
  }

  .typing-dot {
    animation: blink 1.5s infinite step-start;
    margin: 0 2px;
    opacity: 0;
  }

  .typing-dot:nth-child(1) {
    animation-delay: 0s;
  }

  .typing-dot:nth-child(2) {
    animation-delay: 0.3s;
  }

  .typing-dot:nth-child(3) {
    animation-delay: 0.6s;
  }

  @keyframes blink {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`;

function TextArea({
  image,
  fullWidth,
  helperText,
  optional,
  placeholder,
  helperTextPosition,
  counter,
  label,
  isLabelEditable,
  isTextAreaModeEditable,
  initialMode,
  changeMode,
  setMode,
  labelName,
  onClickResize,
  isCollapsed,
  isTextAreaVisible,
  textAreaClassNames,
  onChange,
  onClick,
  value,
  disabled,
  profile,
  handleIconClick,
  fprops,
  isckEditorForEditContribution,
  bios,
  setBios,
  currentBioIndex,
  setCurrentBioIndex,
  currentFieldUpdated,
  setCurrentFieldUpdated,
  isMagicWrite,
  showBulletAndEmoji,
  isMagicWriteLoading,
  onMagicWriteClick,
  noLabel,
  affiliateRow = false,
  profileNoLogo = true,
  ...props
}) {
  const editorRef = useRef(null);
  const [isFocused, setIsFocused] = useState(false);
  const classes = useStyles();
  let formHook = useField;
  const iconRef = useRef(null);
  const mainContainerRef = useRef(null);
  const [applyExpansionIconStyles, setApplyExpansionIconStyles] = useState(false);
  const toolbarItems = [
    {
      name: 'basicstyles',
      items: ['Bold', 'Italic', 'Underline'],
    },
    { name: 'colors', items: ['TextColor'] },
    { name: 'link', items: ['Link', 'Unlink'] },
  ];

  if (showBulletAndEmoji) {
    toolbarItems.push({ name: 'list', items: ['BulletedList'] }, { name: 'insert', items: ['EmojiPanel'] });
  }
  if (disabled) {
    formHook = () => [{}, {}];
  }
  const [field, meta] = formHook(props);

  let privateHelperText = helperText || (optional ? 'optional' : '');
  const privateHelperTextPosition = optional ? 'right' : helperTextPosition || 'left';
  // here check if container-ref has scroll before rendering to screen

  if (counter) {
    privateHelperText = `${convertToPlainText(field?.value || '')?.length}/${counter}`;
  }
  const [isEditLabelMode, setIsEditLabelMode] = useState(false);
  const [ckEditorLoaded, setCkEditorLoaded] = useState(false);
  const [isAction, setIsAction] = useState(false);
  const [isActionClicked, setIsActionClicked] = useState(false);
  const [magicWriteClicked, setMagicWriteClicked] = useState(false);
  const editLabelToggle = useCallback(() => setIsEditLabelMode(labelMode => !labelMode), [setIsEditLabelMode]);

  const onChangeHandler = onChange ?? field.onChange;
  const onClickHandler = onClick ?? field.onClick;
  const changeModeHandler = changeMode ?? field.changeMode;

  const defaultValue = value ?? field.value;
  const testStr = 'coherepublic.s3.amazonaws.com';
  // const regExForIcon = /^data:?[a-z]{5}\/[a-z]*;.*/;
  // const shouldShowImg = regExForIcon.test(image);
  const isImage = image?.includes('coherepublic') && image?.includes('s3.amazonaws.com');

  useEffect(() => {
    if (editorRef.current && isMagicWrite && (magicWriteClicked || currentFieldUpdated)) {
      setIsAction(true);
      setIsActionClicked(true);
      setDataWithoutReload(defaultValue || ' ', true);
      setCurrentFieldUpdated(false);
      setMagicWriteClicked(false);
    }
  }, [bios?.length, bios[currentBioIndex]?.text]);

  useEffect(() => {
    if (isMagicWrite && editorRef.current && !isAction && !isActionClicked && !magicWriteClicked) {
      const newText = editorRef.current.getData().trim();
      const currentBio = bios[currentBioIndex];
      const newUndoStack = [...currentBio?.undoStack, currentBio.text];
      const newBios = [
        ...bios.slice(0, currentBioIndex),
        { ...currentBio, text: newText, undoStack: newUndoStack, redoStack: [] },
        ...bios.slice(currentBioIndex + 1),
      ];
      setBios(newBios);
    }
    if (isAction) {
      setIsAction(false);
      return;
    }
    if (isActionClicked) {
      setIsActionClicked(false);
      return;
    }
    if (magicWriteClicked) setMagicWriteClicked(false);
  }, [defaultValue]);

  useEffect(() => {
    const updateEditorHeight = () => {
      if (mainContainerRef.current) {
        setApplyExpansionIconStyles(
          mainContainerRef.current.scrollHeight > mainContainerRef.current.clientHeight && !isFocused,
        );
        const iframe = mainContainerRef.current.querySelector('iframe');
        if (iframe) {
          const iframeDocument = iframe.contentDocument || iframe.contentWindow.document;
          const iframeScrollHeight = iframeDocument.documentElement.scrollHeight;
          const iframeClientHeight = iframeDocument.documentElement.clientHeight;

          setApplyExpansionIconStyles(iframeScrollHeight > iframeClientHeight && !isFocused);
        }
      }
    };
    setTimeout(updateEditorHeight, 0);

    return clearTimeout(updateEditorHeight);
  }, [isFocused, isCollapsed, mainContainerRef.current]);

  const ckEditorVisible = () => {
    if (
      props?.name === 'purpose' ||
      props?.name === 'whoIAm' ||
      props?.name === 'whatWeDo' ||
      props?.name === 'preparation'
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleCKEditor = event => {
    fprops.setFieldValue(field?.name, event.editor.getData().trim());
  };

  useEffect(() => {
    const handlePaste = event => {
      // Get pasted content
      const clipboardData = event?.clipboardData || window?.clipboardData;
      const pastedText = clipboardData.getData('text/html');

      // Modify the pasted content to set default color
      const modifiedPastedText = pastedText.replace(/<span style="color:([^;"]+);?">/g, '<span>');

      // Set the modified content to clipboard
      event.clipboardData.setData('text/html', modifiedPastedText);
    };

    document.addEventListener('paste', handlePaste);

    return () => {
      document.removeEventListener('paste', handlePaste);
    };
  }, []);

  const handleForward = () => {
    setIsAction(true);
    setIsActionClicked(true);
    if (currentBioIndex < bios.length - 1) {
      setDataWithoutReload(bios[currentBioIndex + 1].text.trim() || ' ', true);
      setCurrentBioIndex(currentBioIndex + 1);
    }
  };

  const handleBackward = () => {
    setIsAction(true);
    setIsActionClicked(true);
    if (currentBioIndex > 0) {
      setDataWithoutReload(bios[currentBioIndex - 1].text.trim() || ' ', true);
      setCurrentBioIndex(currentBioIndex - 1);
    }
  };

  const handleUndo = () => {
    setIsAction(true);
    setIsActionClicked(true);
    if (currentBioIndex >= 0 && bios[currentBioIndex]?.undoStack.length > 0) {
      const currentUndoStack = [...bios[currentBioIndex]?.undoStack];
      const lastAction = currentUndoStack.pop();
      const newRedoStack = [...bios[currentBioIndex]?.redoStack, bios[currentBioIndex].text];
      const newBios = [
        ...bios.slice(0, currentBioIndex),
        {
          ...bios[currentBioIndex],
          text: lastAction,
          undoStack: currentUndoStack,
          redoStack: newRedoStack,
        },
        ...bios.slice(currentBioIndex + 1),
      ];
      setBios(newBios);
      setDataWithoutReload(lastAction.trim() || ' ', false);
    }
  };

  const handleRedo = () => {
    setIsAction(true);
    setIsActionClicked(true);
    if (currentBioIndex >= 0 && bios[currentBioIndex]?.redoStack.length > 0) {
      const currentRedoStack = [...bios[currentBioIndex]?.redoStack];
      const lastAction = currentRedoStack.pop();
      const newUndoStack = [...bios[currentBioIndex]?.undoStack, bios[currentBioIndex].text];
      const newBios = [
        ...bios.slice(0, currentBioIndex),
        {
          ...bios[currentBioIndex],
          text: lastAction,
          redoStack: currentRedoStack,
          undoStack: newUndoStack,
        },
        ...bios.slice(currentBioIndex + 1),
      ];
      setBios(newBios);
      setDataWithoutReload(lastAction.trim() || ' ', false);
    }
  };

  const setDataWithoutReload = (text, refreshScroll) => {
    const editorBody = editorRef.current.document.getBody().$;
    const editorIframe = editorRef.current.ui.contentsElement.$.querySelector('iframe');
    const scrollPosition = refreshScroll
      ? { top: 0, left: 0 }
      : {
          top: editorIframe.contentWindow.scrollY,
          left: editorIframe.contentWindow.scrollX,
        };

    editorBody.innerHTML = '';
    editorRef.current.insertHtml(text);

    if (refreshScroll) {
      editorIframe.contentWindow.scrollTo(0, 0);
    } else {
      editorIframe.contentWindow.scrollTo(scrollPosition.left, scrollPosition.top);
    }
  };
  return (
    <TextFieldContainer
      ref={mainContainerRef}
      fullWidth={fullWidth}
      className={`custom-text-area-container ${isFocused || !isCollapsed ? 'custom-text-area-focused' : ''} ${
        !isCollapsed ? 'custom-text-area-expanded' : ''
      }`}
    >
      {!noLabel && (
        <StyledLabel style={isTextAreaModeEditable && { justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
            {profileNoLogo === true ? (
              <></>
            ) : (label === 'Bio' || label === 'What to expect') && profile === true ? (
              <></>
            ) : Boolean(image) && isImage ? (
              <img
                src={image}
                style={{ marginRight: '5px', cursor: 'pointer', height: '35px', width: '35px', borderRadius: '20px' }}
              />
            ) : (
              affiliateRow === false && <Icon path={image} right="5" valign="top" />
            )}
            {labelName === 'preparationLabel' && (
              <CloudUploadIcon
                onClick={handleIconClick}
                htmlColor={colors.darkOceanBlue}
                style={{ cursor: 'pointer' }}
              />
            )}
            {labelName === 'whatWeDoLabel' && (
              <CloudUploadIcon
                onClick={handleIconClick}
                htmlColor={colors.darkOceanBlue}
                style={{ cursor: 'pointer' }}
              />
            )}

            {labelName === 'whoIAmLabel' && (
              <CloudUploadIcon
                onClick={handleIconClick}
                htmlColor={colors.darkOceanBlue}
                style={{ cursor: 'pointer' }}
              />
            )}

            {labelName === 'purposeLabel' && (
              <CloudUploadIcon
                onClick={handleIconClick}
                htmlColor={colors.darkOceanBlue}
                style={{ cursor: 'pointer' }}
              />
            )}

            {Boolean(label) &&
              !isEditLabelMode &&
              (isTextAreaVisible ? (
                <StyledInputLabel
                  profile={profile}
                  classes={{
                    root: classes.labelRoot,
                  }}
                >
                  {label}
                </StyledInputLabel>
              ) : affiliateRow ? (
                <p style={{ margin: '0px' }}>{label}</p>
              ) : profileNoLogo ? (
                // <span className="heading">
                <div
                  style={{
                    // marginTop: '0px',
                    // fontFamily: 'Inter',
                    // fontWeight: 400,
                    // fontSize: '20px',
                    // color: '#000000',
                    // lineHeight: '24px',
                    fontFamily: 'Avenir',
                    fontSize: '24px',
                    fontWeight: 500,
                  }}
                >
                  {label}
                </div>
              ) : (
                // </span>
                <span className="heading">
                  <p style={{ marginTop: '0px' }}>{label}</p>
                </span>
              ))}
            {isEditLabelMode && (
              <div style={{ marginLeft: '10px' }}>
                {' '}
                <Input type="text" name={labelName} value={label} />
              </div>
            )}
            {isLabelEditable && (
              <div onClick={editLabelToggle}>
                <EditButton />
              </div>
            )}
            {/* <Tooltip
            title="it is tooltip"
            arrow
            enterTouchDelay={TOOLTIP.ENTER_DELAY}
            leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
          > */}

            {/* </Tooltip> */}
          </div>
          {isTextAreaVisible && isTextAreaModeEditable && (
            <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
              {!initialMode ? (
                <div>
                  <p style={{ marginTop: '0px', marginBottom: '5px', marginRight: '5px', fontSize: '1rem' }}>Hidden</p>
                </div>
              ) : (
                <div>
                  <p style={{ marginTop: '0px', marginBottom: '5px', marginRight: '5px', fontSize: '1rem' }}>Visible</p>
                </div>
              )}
              <div>
                {/* <StyledSwitch color="default" checked={initialMode} onChange={changeModeHandler} /> */}
                <Switch className="switch">
                  <StyledInput type="checkbox" onClick={changeMode} checked={initialMode} />
                  <StyledSlider className="slider round" />
                </Switch>
              </div>
            </div>
          )}
        </StyledLabel>
      )}

      {isTextAreaVisible && !isckEditorForEditContribution && (
        <StyledTextArea
          affiliateRow={affiliateRow}
          disabled={
            props.name === 'purpose' ||
            props.name === 'whoIAm' ||
            props.name === 'whatWeDo' ||
            props.name === 'preparation'
              ? !initialMode
              : initialMode
          }
          placeholder={placeholder}
          error={meta.touched && meta.error && affiliateRow === false}
          isCollapsed={isCollapsed}
          className={textAreaClassNames}
          {...field}
          {...props}
          onChange={onChangeHandler}
          value={typeof field.value === 'string' ? field.value : defaultValue}
        />
      )}

      {isTextAreaModeEditable && isckEditorForEditContribution && (
        <>
          {!ckEditorLoaded && <RichTextPlaceholder content={defaultValue} width="100%" height="162px" />}

          <EditorWrapWithMagicWrite
            className={`${initialMode ? 'ckeditor-custom-textarea' : 'disabled-ck-editor'} ${
              isMagicWrite && isMagicWriteLoading ? 'loading' : ''
            }`}
          >
            <CKEditor
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
              initData={defaultValue || ' '}
              config={{
                editorplaceholder: placeholder,
                allowedContent: true,
                height: '150px',
                dialog_noConfirmCancel: true,
                toolbar: toolbarItems,
                removeButtons: [],
                extraPlugins: ['font', 'editorplaceholder', 'list', 'emoji'],
              }}
              onChange={handleCKEditor}
              onLoaded={({ editor }) => {
                editorRef.current = editor;
                const customCss =
                  ".cke_editable { font-size: 16px; font-family: 'Avenir', sans-serif; } .cke_editable::before { font-size: 13px; font-family: 'Avenir', sans-serif; }";
                editor.addContentsCss(customCss);
                setCkEditorLoaded(true);
              }}
            />
            {isMagicWrite && (
              <ImproveTextActions
                className={
                  bios?.length >= 2 ||
                  bios[currentBioIndex]?.undoStack?.length > 0 ||
                  bios[currentBioIndex]?.redoStack?.length > 0
                    ? 'improve-text-actions expanded'
                    : 'improve-text-actions'
                }
              >
                <div className="actions-container">
                  {bios?.length >= 2 && (
                    <>
                      <ArrowBackIosIcon
                        onClick={e => {
                          e.preventDefault();
                          handleBackward();
                        }}
                        className={currentBioIndex === 0 ? 'disabled-icon' : ''}
                        style={{ cursor: 'pointer', width: '14px', height: '14px' }}
                      />
                      <label style={{ marginBottom: '0px' }}>
                        {currentBioIndex + 1}/{bios.length}
                      </label>
                      <ArrowForwardIosIcon
                        onClick={e => {
                          e.preventDefault();
                          handleForward();
                        }}
                        className={currentBioIndex === bios.length - 1 ? 'disabled-icon' : ''}
                        style={{ cursor: 'pointer', width: '14px', height: '14px' }}
                      />
                    </>
                  )}
                  {(bios[currentBioIndex]?.undoStack?.length > 0 || bios[currentBioIndex]?.redoStack?.length > 0) && (
                    <>
                      <UndoIcon
                        onClick={e => {
                          e.preventDefault();
                          handleUndo();
                        }}
                        className={bios[currentBioIndex]?.undoStack.length === 0 ? 'disabled-icon' : ''}
                        style={{ cursor: 'pointer', paddingLeft: '2px', marginBottom: '2px' }}
                      />
                      <RedoIcon
                        onClick={e => {
                          e.preventDefault();
                          handleRedo();
                        }}
                        className={bios[currentBioIndex]?.redoStack.length === 0 ? 'disabled-icon' : ''}
                        style={{ cursor: 'pointer', marginBottom: '2px' }}
                      />
                    </>
                  )}
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={isMagicWriteLoading}
                    className={
                      bios?.length > 1 ||
                      bios[currentBioIndex]?.undoStack?.length > 0 ||
                      bios[currentBioIndex]?.redoStack?.length > 0
                        ? 'expanded magic-write-button'
                        : 'magic-write-button'
                    }
                    style={{
                      color: 'black',
                      letterSpacing: '0.5px',
                      cursor: isMagicWriteLoading ? 'default' : 'pointer',
                    }}
                    onClick={e => {
                      e.preventDefault();
                      setIsAction(true);
                      setMagicWriteClicked(true);
                      onMagicWriteClick();
                    }}
                  >
                    {isMagicWriteLoading ? (
                      <>
                        <div className="typing-animation">
                          <span className="typing-dot">.</span>
                          <span className="typing-dot">.</span>
                          <span className="typing-dot">.</span>
                        </div>
                      </>
                    ) : (
                      <>
                        <Logo isai />
                        CohereCopy
                      </>
                    )}
                  </Button>
                </div>
              </ImproveTextActions>
            )}
          </EditorWrapWithMagicWrite>
        </>
      )}
      {isTextAreaVisible && onClickResize && (
        <StyledIcon
          initialMode={initialMode}
          src={isCollapsed ? ExpandIcon : CollapseIcon}
          onClick={onClickResize}
          styleOverrides={applyExpansionIconStyles ? { marginRight: '1rem' } : { marginRight: 'initial' }}
        />
      )}
      {isTextAreaVisible && meta.touched && meta.error && affiliateRow === false ? (
        <StyledErrorHelper>{meta.error}</StyledErrorHelper>
      ) : (
        isTextAreaVisible &&
        privateHelperText && <HelperText align={privateHelperTextPosition}>{privateHelperText}</HelperText>
      )}
    </TextFieldContainer>
  );
}

TextArea.propTypes = {
  image: PropTypes.string,
  placeholder: PropTypes.string,
  fullWidth: PropTypes.bool,
  noLabel: PropTypes.bool,
  helperText: PropTypes.string,
  optional: PropTypes.bool,
  helperTextPosition: PropTypes.oneOf(['left', 'right']),
  counter: PropTypes.number,
  label: PropTypes.string,
  isLabelEditable: PropTypes.bool,
  labelName: PropTypes.string,
  onClickResize: PropTypes.func,
  handleIconClick: PropTypes.func,
  isCollapsed: PropTypes.bool,
  isTextAreaVisible: PropTypes.bool,
  textAreaClassNames: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
  isckEditorForEditContribution: PropTypes.bool,
  bios: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      undoStack: PropTypes.arrayOf(PropTypes.string),
      redoStack: PropTypes.arrayOf(PropTypes.string),
    }),
  ),
  currentBioIndex: PropTypes.number,
  isMagicWrite: PropTypes.bool,
  showBulletAndEmoji: PropTypes.bool,
  isMagicWriteLoading: PropTypes.bool,
  onMagicWriteClick: PropTypes.func,
  setBios: PropTypes.func,
  setCurrentBioIndex: PropTypes.func,
  currentFieldUpdated: PropTypes.bool,
  setCurrentFieldUpdated: PropTypes.func,
};

TextArea.defaultProps = {
  image: null,
  placeholder: null,
  fullWidth: false,
  helperText: null,
  optional: false,
  helperTextPosition: 'left',
  counter: null,
  label: '',
  isLabelEditable: false,
  noLabel: false,
  labelName: '',
  isCollapsed: true,
  isTextAreaVisible: true,
  textAreaClassNames: null,
  handleIconClick: () => {},
  onChange: null,
  value: null,
  isckEditorForEditContribution: false,
  bios: [],
  currentBioIndex: 0,
  isMagicWrite: false,
  showBulletAndEmoji: false,
  isMagicWriteLoading: false,
  onMagicWriteClick: () => {},
  setBios: () => {},
  setCurrentBioIndex: () => {},
  currentFieldUpdated: false,
  setCurrentFieldUpdated: () => {},
};

export default TextArea;
