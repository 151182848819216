import React from 'react';

export const PauseIcon = ({ color }) => (
  <svg width="24" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d_12018_51385)">
      <circle cx="12" cy="10" r="10" fill="white" />
      <rect x="8.54956" y="5" width="2.05593" height="10.133" rx="0.293707" fill={color} />
      <rect x="13.3947" y="5" width="2.05593" height="10.133" rx="0.293707" fill={color} />
    </g>
    <defs>
      <filter
        id="filter0_d_12018_51385"
        x="0.82735"
        y="0"
        width="22.3453"
        height="22.3453"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1.17265" />
        <feGaussianBlur stdDeviation="0.586325" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_12018_51385" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_12018_51385" result="shape" />
      </filter>
    </defs>
  </svg>
);
