import range from 'lodash/range';

const FORM_FIELDS = {
  PAYMENT_OPTIONS: 'paymentOptions',
  FREE: 'Free',
  PER_DAY_COST: 'perDayCost',
  PER_WEEK_COST: 'perWeekCost',
  PER_MONTH_COST: 'perMonthCost',
  PER_YEAR_COST: 'perYearCost',
  MEMBERSHIP_PACKAGE: 'membershipPackage',
  MEMBERSHIP_PACKAGE_COST: 'membershipPackage[cost]',
  MEMBERSHIP_PACKAGE_PERIOD: 'membershipPackage[period]',
  MEMBERSHIP_PACKAGE_DURATION: 'membershipPackage[duration]',
};

function sessionNumbersItems() {
  return [{ title: 'Monthly', value: 'Monthly' }];
}

function durationItems() {
  return range(1, 13).map(i => ({ title: `${i} month(s)`, value: i }));
}

export { sessionNumbersItems, durationItems, FORM_FIELDS };
