import React from 'react';

const YoutubeSvg = ({ color, style = {} }) => {
  return (
    <svg style={style} width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0)">
        <path
          d="M10.9992 18.9919C10.9992 18.9919 10.9954 18.9919 10.9933 18.9919C10.3295 18.9873 4.46091 18.9338 2.79822 18.4841C1.63621 18.1718 0.71893 17.2555 0.405728 16.0933C-0.0293314 14.46 -0.00163662 11.3154 0.00138462 11.0643C-0.00146877 10.8144 -0.0294992 7.64375 0.404385 5.997L0.405728 5.9923C0.715406 4.84339 1.65333 3.89874 2.79537 3.58554C2.79822 3.5847 2.80124 3.58403 2.80409 3.58319C4.44799 3.15115 10.3282 3.09828 10.9933 3.09375H11.0053C11.6708 3.09828 17.555 3.15183 19.2024 3.60216C20.3614 3.91368 21.278 4.82878 21.592 5.98945C22.0434 7.63737 22.0016 10.8149 21.9971 11.0831C22.0002 11.3473 22.0266 14.4637 21.5941 16.1052L21.5929 16.1099C21.2795 17.2721 20.3624 18.1884 19.199 18.5011L19.1943 18.5024C17.5506 18.9343 11.6703 18.9872 11.0053 18.9919C11.0033 18.9919 11.0012 18.9919 10.9992 18.9919ZM2.06573 6.43776C1.68371 7.89132 1.7198 11.0215 1.72014 11.0531V11.0757C1.70872 11.9438 1.74884 14.4578 2.0659 15.6485C2.21965 16.2187 2.672 16.6704 3.2457 16.8246C4.47249 17.1565 9.25227 17.2609 10.9992 17.2731C12.7507 17.2609 17.5375 17.1593 18.7553 16.8408C19.3271 16.686 19.778 16.2358 19.9327 15.665C20.2501 14.4568 20.2899 11.9551 20.2783 11.0925C20.2783 11.0834 20.2783 11.0744 20.2785 11.0653C20.2943 10.1868 20.2632 7.64341 19.9342 6.44314L19.9334 6.43961C19.779 5.86692 19.3264 5.41524 18.7527 5.26099C17.5379 4.92882 12.7504 4.82476 10.9992 4.8125C9.24891 4.82476 4.46679 4.92614 3.24536 5.24437C2.68257 5.40047 2.21999 5.86826 2.06573 6.43776ZM9.25888 14.2626C9.05888 14.3784 8.80865 14.234 8.80865 14.003V8.0825C8.80865 7.85144 9.05889 7.70712 9.25889 7.82283L14.3755 10.7831C14.5751 10.8987 14.5751 11.187 14.3755 11.3025L9.25888 14.2626Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="22" height="22" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default YoutubeSvg;
