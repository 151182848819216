import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { Icon } from '@mdi/react';
import { mdiChevronDown, mdiChevronUp } from '@mdi/js';

const StyledLi = styled.li`
  padding: ${({ menubarItem }) => (menubarItem ? '10px 10px 10px 30px' : '10px 10px 10px 0')};
  display: flex;
  justify-content: space-between;

  ${({ isList }) => isList && `cursor: pointer;`}
  ${({ disabled }) => disabled && `pointer-events: none;`}
  ${({ isActive }) =>
    isActive &&
    `
   padding: 10px 10px 10px 26px;
   border-left: 4px solid #215C73;
   background-color: #EFF3F5;
  ;`}
`;

const StyledUl = styled.ul`
  list-style: none;
  padding-left: ${({ menubar }) => (menubar ? '0px' : '2rem')};
`;

const StyledLink = styled(NavLink)`
  text-decoration: none;
  font-size: 1.1125rem;
  font-weight: 500;
  line-height: 1.35;
  letter-spacing: 0.15px;
  color: rgba(0, 0, 0, 0.87);

  &.active {
    font-weight: 500;
  }

  &:hover {
    text-decoration: underline;
  }

  ${StyledUl} > ${StyledUl} & {
    font-weight: normal;
  }
`;

function List({ menubar, children }) {
  return <StyledUl menubar={menubar}>{children}</StyledUl>;
}

List.propTypes = {
  children: PropTypes.node.isRequired,
};

function Item({ children, isList, to, title, disabled, onClick, id, isActive, menubarItem }) {
  const [show, setShow] = useState(false);

  return (
    <>
      <StyledLi
        disabled={disabled}
        onClick={() => setShow(prev => !prev)}
        isList={isList}
        isActive={isActive}
        menubarItem={menubarItem}
      >
        <StyledLink id={id} disabled={disabled} to={to || {}} onClick={onClick}>
          {title}
        </StyledLink>
        {isList && <Icon size={1} path={show ? mdiChevronUp : mdiChevronDown} />}
      </StyledLi>
      {isList && show && children}
    </>
  );
}

Item.propTypes = {
  id: PropTypes.string,
  children: PropTypes.element,
  isList: PropTypes.bool,
  to: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};

Item.defaultProps = {
  id: '',
  children: null,
  isList: false,
  to: null,
  disabled: false,
  onClick: undefined,
};

export { List, Item };
