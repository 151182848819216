import React from 'react';

import Can from 'components/Auth/Can';

import { UserRoles } from 'helpers/constants';

import AdminMenu from './AdminMenu';
import CohealerMenu from './CohealerMenu';
import ClientMenu from './ClientMenu';

const SidebarDropDown = () => {
  return (
    <>
      <Can roleName={UserRoles.client} yes={ClientMenu} />
      <Can roleName={UserRoles.cohealer} yes={CohealerMenu} />
      <Can roleName={UserRoles.admin} yes={AdminMenu} />
    </>
  );
};

export default SidebarDropDown;
