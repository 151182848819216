import React from 'react';
import classes from './ErrorModal.module.scss';
import Modal from 'components/UI/Modal';
import styled from 'styled-components';

const StyledError = styled.div`
  color: black;
`;

const ErrorModal = ({ error, ...restProps }) => {
  return (
    <>
      <Modal {...restProps}>
        <StyledError>{error}</StyledError>
      </Modal>
    </>
  );
};

export default ErrorModal;
