import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'components/UI/Modal';
import BookModalView from '../BookModal/BookModalView';

const BookModalContainerForPaymentCard = ({
  isOpen,
  handleModalCancel,
  handleModalSubmit,
  sessionInfo,
  timeZoneId,
}) => {
  return (
    <>
      <BookModalView session={sessionInfo} timeZoneId={timeZoneId} />
      <div style={{ display: 'flex', justifyContent: 'space-around' }}>
        <button onClick={handleModalCancel}>Cancel</button>
        <button onClick={handleModalSubmit}>Confirm</button>
      </div>
    </>
  );
};

BookModalContainerForPaymentCard.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleModalCancel: PropTypes.func.isRequired,
  handleModalSubmit: PropTypes.func.isRequired,
  sessionInfo: PropTypes.objectOf(PropTypes.any).isRequired,
  timeZoneId: PropTypes.string,
};

BookModalContainerForPaymentCard.defaultProps = {
  timeZoneId: '',
};

export default BookModalContainerForPaymentCard;
