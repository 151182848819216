import React from 'react';

import { useDropzone } from 'react-dropzone';

import { useVideoDurationParse } from 'pages/CreateContribution/hooks/useVideoDurationParse';
import { Container, MainDiv, StyledIconWrap } from './StyleComponents';
import { AddIcon } from 'pages/Profile/components/icons/Add';
import { CircularProgressWithLabel } from 'components/UI/CircularProgressWithLabel/CircularProgressWithLabel';

const MediaDropZone = ({
  isDisabled,
  type,
  multiple,
  placeholder,
  action,
  loading,
  progress,
  stopPropagation = false,
}) => {
  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    onDrop: files => action(files),
    accept: type,
    multiple,
    disabled: isDisabled || loading,
  });
  return (
    <Container
      showVideo
      // {...getRootProps({ isDragActive, isDragAccept, isDragReject })}
      {...(stopPropagation && { onClick: event => event.stopPropagation() })} // avoid of triggering onClick twice https://github.com/react-dropzone/react-dropzone/issues/252
      //   style={previewVideo && videoStyle}
      isDisabled={isDisabled}
    >
      {loading ? (
        <CircularProgressWithLabel value={progress} />
      ) : (
        <>
          <input {...getInputProps()} />
          <MainDiv {...getRootProps({ isDragActive, isDragAccept, isDragReject })}>
            <StyledIconWrap>
              <span className="add-upload-icon">
                <AddIcon />
              </span>
            </StyledIconWrap>
            <div>{placeholder}</div>
          </MainDiv>
        </>
      )}
    </Container>
  );
};

export default MediaDropZone;
