import React, { useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import classnames from 'classnames';

import avatarPlaceholder from 'assets/placeholder.jpg';
import newPlaceholder from 'assets/placeholdenew.png';
import Loader from 'components/UI/Loader';
import { useHttp } from 'hooks';
import * as userActions from 'actions/user';
import { UploadImageIcon } from '../../../src/pages/Profile/components/icons/UploadImage';
import CloseIcon from '@material-ui/icons/Close';
import axiosInstance from 'utils/axiosInstance';
import { useAccount } from 'hooks';

import './ProfileAvatar.scss';

export const ProfileAvatar = ({
  avatarUrl,
  className,
  updateUserImg,
  isCoach = false,
  contribution = false,
  thankyoupage = false,
}) => {
  const dispatch = useDispatch();
  const { request, loading } = useHttp();
  const inputRef = React.createRef();
  const { user } = useAccount();
  const UserId = user?.id;

  const handleUploadFile = useCallback(
    ({ target: { files } }) => {
      const formData = new FormData();

      formData.append('file', R.head(files));

      request('/Content/AddAvatar', 'POST', formData, {
        'Content-Type': 'multipart/form-data',
      })
        .then(res => {
          if (thankyoupage === false && !isCoach) {
            dispatch(userActions.setProfileAvatar(res));
          }
          updateUserImg(res);
        })
        .catch(console.dir);
      // .finally(updateUserImg);
    },
    [dispatch, request],
  );

  let emptyString = '';
  const fieldAvatarUrl = 'AvatarUrl';
  const fieldValue = null;
  const fieldinUserCollection = true;

  const onDeleteClick = useCallback(async () => {
    try {
      const res = await axiosInstance.post(
        `Content/RemoveImagesFromS3BucketAndAccountPage?imageUrl=${avatarUrl}&field=${fieldAvatarUrl}&fieldValue=${fieldValue} &userId=${UserId}&inUserCollection=${fieldinUserCollection}`,
      );
      updateUserImg(emptyString);
    } catch (error) {
      console.error(error);
    }
  });

  if (loading) {
    return <Loader />;
  }

  return (
    <div
      style={
        contribution
          ? {
              alignItems: 'center',
              justifyContent: 'center',
              display: 'flex',
              flexDirection: 'column',
            }
          : {}
      }
      className="profile-avatar-container"
    >
      {!contribution && <p className="profile-avatar-text">Add Photo</p>}
      {/* <div className="profile-avatar-container"> */}
      {/* <p className="profile-avatar-text">Add Photo</p> */}
      {avatarUrl !== '' && (
        <span
          className="remove-image-button"
          onClick={onDeleteClick}
          onKeyUp={onDeleteClick}
          role="button"
          tabIndex="0"
          style={{ marginLeft: 'auto', float: 'unset' }}
        >
          <CloseIcon style={{ color: 'white', width: '16px', height: '16px' }} />
        </span>
      )}
      <div
        style={{
          backgroundImage: `url(${avatarUrl || newPlaceholder})`,
        }}
        className={classnames(
          !thankyoupage ? 'mr-4 mb-4 br-50 profile-avatar' : 'mb-4 br-50 profile-avatar',
          className,
        )}
      >
        <input
          type="file"
          accept="image/*"
          className="w-100 h-100 cursor-pointer profile-input-avatar"
          onChange={handleUploadFile}
          ref={inputRef}
        />
        {isCoach && (
          <span
            className="upload-image-button"
            style={
              !thankyoupage
                ? {}
                : {
                    position: 'relative',
                    float: 'right',
                    right: '0',
                    bottom: '47px',
                    borderRadius: '48px',
                    padding: '8px',
                    display: 'flex',
                    width: '34px',
                    height: '34px',
                    background: '#cdba8f',
                  }
            }
            role="button"
            tabIndex="0"
            onKeyUp={() => inputRef.current.click()}
            onClick={() => inputRef.current.click()}
          >
            <UploadImageIcon />
          </span>
        )}
      </div>
      {isCoach && (
        <span
          className="profile-avatar-description"
          style={
            !thankyoupage
              ? {}
              : {
                  background: 'none',
                  display: 'inline-block',
                  textAlign: 'center',
                  color: '#797A7B',
                  marginTop: '-20px',
                  width: '100%',
                  textAlign: 'center',
                }
          }
        >
          Photo should be atleast 500x500px and upto 2 mb.
        </span>
      )}
    </div>
  );
};

ProfileAvatar.propTypes = {
  avatarUrl: PropTypes.string,
  className: PropTypes.string,
};

ProfileAvatar.defaultProps = {
  avatarUrl: avatarPlaceholder,
  className: null,
};
