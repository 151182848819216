const PROD_DOMAIN = 'cohere.live';
const PROD_PREFIX = 'app';
const DOMAINS = [
  'localhost:3000',
  'local.cohere.live:3000',
  'dev.cohere.live',
  'test.cohere.live',
  'aqa.cohere.live',
  'app.cohere.live',
];
const NON_PROD_DOMAINS = [
  'localhost:3000',
  'local.cohere.live:3000',
  'dev.cohere.live',
  'test.cohere.live',
  'aqa.cohere.live',
];

export const NOT_ALLOWED_DOMAINS = ['app', 'test', 'aqa', 'dev', 'local', 'localhost'];
export const NOT_ALLOWED_SUBDOMAINS = ['.app', '.test', '.dev'];

/**
 * Finds the domain and subdomain
 * @param {current url} host
 * @returns {domain: string, subdomain: string}
 */
export const findDomainAndSubdoamin = host => {
  //for localhost
  if (host.split(':')[0] === 'localhost') {
    return {
      domain: host,
      subdomain: '',
      parentDomain: host,
    };
  }
  //for exact domain match
  if (DOMAINS.includes(host)) {
    return {
      domain: host,
      subdomain: '',
      parentDomain: PROD_PREFIX.concat('.', PROD_DOMAIN) === host ? PROD_DOMAIN : host,
    };
  }
  //for non prodcution websites e.g. abdul.test.cohere.live
  const nonProdDomain = NON_PROD_DOMAINS.find(domain => {
    return host.includes('.'.concat(domain));
  });
  if (nonProdDomain) {
    const subdomain = host.split('.'.concat(nonProdDomain))[0];
    return {
      domain: nonProdDomain,
      subdomain,
      parentDomain: nonProdDomain,
    };
  }
  //for production websites e.g. abdul.cohere.live
  const subdomain = host.split('.'.concat(PROD_DOMAIN))[0];
  return {
    domain: PROD_PREFIX.concat('.', PROD_DOMAIN),
    subdomain,
    parentDomain: PROD_DOMAIN,
  };
};
