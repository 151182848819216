import React from 'react'
import PropTypes from 'prop-types'

export const IconStudent = ({ style }) => (
  <svg style={style} ariaHidden="true" dataIcon="student" viewBox="0 0 180 145" role="img">
    <path
      d="M90.9373 116.623C88.6733 116.623 86.8386 114.788 86.8386 112.524C86.8386 110.26 88.6733 108.425 90.9373 108.425C93.2013 108.425 95.036 110.26 95.036 112.524C95.036 114.788 93.2013 116.623 90.9373 116.623ZM168.271 3.66533H146.589C150.521 7.01599 153.679 11.0947 155.785 15.6653H167.136V102.332H12.4693V15.6653H84.1786C86.2853 11.0947 89.4426 7.01599 93.3746 3.66533H13.6039C7.00393 3.66533 0.469299 8.59067 0.469299 15.1907V112.524C0.469299 119.124 7.00393 124.999 13.6039 124.999H73.8026V138.332H65.6039C63.7626 138.332 62.2706 139.824 62.2706 141.665C62.2706 143.506 63.7626 144.999 65.6039 144.999H116.271C118.112 144.999 119.604 143.506 119.604 141.665C119.604 139.824 118.112 138.332 116.271 138.332H105.803V124.999H168.271C174.871 124.999 179.136 119.124 179.136 112.524V15.1907C179.136 8.59067 174.871 3.66533 168.271 3.66533Z"
      fill="currentColor"
    />
    <path
      d="M119.983 0.767967C102.263 0.767967 87.8973 13.4106 87.8973 29.0053C87.8973 35.308 93.2079 44.4533 94.2119 45.8268C95.2159 47.2012 95.532 48.5934 95.532 50.809C95.532 53.0268 93.5533 56.6773 93.5533 56.6773C93.5533 56.6773 103.285 53.6626 105.076 54.009C106.867 54.356 114.6 57.2424 119.983 57.2424C137.703 57.2424 152.067 44.6012 152.067 29.0053C152.067 13.4106 137.703 0.767967 119.983 0.767967Z"
      fill="currentColor"
    />
    <path
      d="M96.4693 95.6653V91.6653C96.4693 85.0653 91.6387 79.6653 85.0387 79.6653H47.7053C41.1053 79.6653 35.136 85.0653 35.136 91.6653V95.6653H96.4693Z"
      fill="currentColor"
    />
    <path
      d="M48.72 59.332C48.72 49.2148 56.9213 41.0132 67.0387 41.0132C77.1547 41.0132 85.356 49.2148 85.356 59.332C85.356 69.4492 77.1547 77.6506 67.0387 77.6506C56.9213 77.6506 48.72 69.4492 48.72 59.332Z"
      fill="currentColor"
    />
  </svg>
)

IconStudent.propTypes = {
  style: PropTypes.string,
}

IconStudent.defaultProps = {
  style: {},
}
