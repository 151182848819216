import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { usePlaidLink } from 'react-plaid-link';
import * as bankAccountsService from 'services/bankAccounts.service';
import { StyledTitle, StyledButton } from '../GettingStarted.styles';

const StyledImage = styled.img`
  margin-top: 20px;
  height: 60%;

  ${({ mobileView }) =>
    mobileView &&
    `
    width: 80%;
    height: auto;
  `}
`;

const BankConnect = ({ mobileView }) => {
  const onSuccess = useCallback((token, metadata) => {
    bankAccountsService
      .getExchangeAccessToken({ publicToken: token })
      .then(({ accessToken }) =>
        bankAccountsService.fetchStripeToken({
          accessToken,
          accountId: metadata.account_id,
        }),
      )
      .then(({ stripeToken }) => bankAccountsService.attachExternalAccount({ stripeToken }));
  }, []);

  const config = {
    clientName: 'Cohere Inc.',
    env: process.env.REACT_APP_PLAID_ENVIRONMENT,
    product: ['auth', 'transactions'],
    publicKey: process.env.REACT_APP_PLAID_PUBLIC_KEY,
    onSuccess,
  };

  const { open, ready } = usePlaidLink(config);
  return (
    <>
      <StyledImage src="/get-started-bank-connect.png" mobileView={mobileView} />

      <StyledTitle mobileView={mobileView}>Connect your bank account to transfer earned revenue.</StyledTitle>

      <StyledButton onClick={open} variant="primary" disabled={!ready}>
        Connect Bank
      </StyledButton>
    </>
  );
};

BankConnect.propTypes = {
  mobileView: PropTypes.bool,
};

BankConnect.defaultProps = {
  mobileView: false,
};

export default BankConnect;
