import styled from 'styled-components';
import CrossIcon from '@material-ui/icons/Clear';
import Button from 'components/FormUI/Button';
import { colors } from '../../utils/styles';

export const StyledButtonContainer = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: space-between;

  @media (max-width: 425px) {
    flex-direction: column-reverse;
    gap: 20px;
    & > div {
      display: flex;
      flex-direction: column-reverse;
      gap: 20px;
      & button {
        min-width: 100%;
      }
    }
  }
`;

export const StyleLabel = styled.label`
  display: block;
  cursor: pointer;
  text-align: center;
  margin: 32px;
  ${({ mobileView }) => mobileView && 'margin: 0'};
  ${({ mobileView }) => mobileView && 'text-align: left'}
`;

export const StyleLabelSignature = styled.label`
  display: flex;
  align-items: center;
  text-align: left;
  ${({ disabled }) => (disabled ? 'color: #9b9b9b' : 'color: black')};
`;

export const HiddenFileInput = styled.input.attrs({ type: 'file' })`
  display: none;
`;

export const StyledCustomTerms = styled.div`
display: flex;
align - items: center;
flex - direction: column;
margin - top: 0px;
`;

export const StyledFileName = styled.div`
display: flex;
flex - direction: row;
  ${({ mobileView }) => mobileView && 'margin-bottom: 15px'};
`;

export const StyledCrossIcon = styled(CrossIcon)`
margin - left: 5px;

  &:hover {
  cursor: pointer;
}
`;

export const StyledAttachTermsButton = styled(Button)`
  border: none;
`;

export const StyledLink = styled.a`
color: ${colors.darkOceanBlue};
font - weight: 600;

  &:hover {
  color: ${colors.darkOceanBlue};
}
`;

export const StyledButtonAsLink = styled.button`
color: ${colors.darkOceanBlue};
font - weight: 600;
border: none;
outline: none;
background - color: rgba(0, 0, 0, 0);

  &:hover {
  color: ${colors.darkOceanBlue};
  text - decoration: underline;
}
`;
