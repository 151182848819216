import React from 'react';
import classes from './Toggle.module.scss';

const TOGGLE_VALUES = {
  And: 'And',
  Or: 'Or',
};
const Toggle = ({ value, onChange }) => {
  const andValueClass = `${classes.toggle_value} ${value === 'And' ? classes.selected : ''}`;
  const orValueClass = `${classes.toggle_value} ${value === 'Or' ? classes.selected : ''}`;
  return (
    <div className={classes.toggle_container}>
      <div
        className={andValueClass}
        onClick={() => {
          onChange(TOGGLE_VALUES.And);
        }}
      >
        And
      </div>
      <div
        className={orValueClass}
        onClick={() => {
          onChange(TOGGLE_VALUES.Or);
        }}
      >
        Or
      </div>
    </div>
  );
};

export default Toggle;
