import { handleActions } from 'redux-actions';

import { startChat, updateChatToken, endChat } from 'actions/chat';
import { LOG_OUT } from 'actions/user';

const initialState = {
  token: null,
};

export default handleActions(
  {
    [startChat.toString()]: (state, { payload: { token } }) => ({
      ...state,
      token,
    }),
    [updateChatToken.toString()]: (state, { payload: { token } }) => ({
      ...state,
      token,
    }),
    [endChat.toString()]: state => ({
      ...state,
      token: null,
    }),
    [LOG_OUT]: () => ({ ...initialState }),
  },
  initialState,
);
