import { handleActions } from 'redux-actions';

import { setDashboardSessions, clearDashboardSessions } from 'actions/dashboardSessions';

const initialState = {
  closestClassForBanner: {},
  contributionsForDashboard: [],
  coachDeclinedSubscriptions: null,
};

const dashboardSessions = handleActions(
  {
    [setDashboardSessions]: (
      state,
      { payload: { closestClassForBanner, contributionsForDashboard, coachDeclinedSubscriptions } },
    ) => ({
      ...state,
      closestClassForBanner,
      contributionsForDashboard,
      coachDeclinedSubscriptions,
    }),
    [clearDashboardSessions]: () => ({ ...initialState }),
  },
  initialState,
);

export default dashboardSessions;
