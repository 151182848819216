import { Drawer, IconButton, makeStyles } from '@material-ui/core';
import React from 'react';
import PropTypes, { bool, string } from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';
import LeftSectionMenu from './LeftSectionMenu';

function LeftDrawer({
  isOpen,
  setIsOpen,
  contribution,
  currentRole,
  themedColors,
  mobileView,
  pathname,
  createSection,
  sections,
  editSection,
  deleteSection,
  archivedGroups,
  createGroup,
  handleGroupActions,
  currentGroup,
  isDarkThemeEnabled,
}) {
  const useStyles = makeStyles(theme => ({
    drawer: {
      '& .MuiDrawer-paper': {
        backgroundColor: themedColors.themedBackgroundColor,
        color: themedColors.themedColor,
      },
    },
  }));
  const classes = useStyles();

  return (
    <Drawer open={isOpen} anchor="left" onClose={() => setIsOpen(false)} className={classes.drawer}>
      <div className="community-section-drawer">
        <div className="close-icon">
          <IconButton onClick={() => setIsOpen(false)} className="p-0">
            <CloseIcon style={{ color: isDarkThemeEnabled ? 'white' : 'black' }} />
          </IconButton>
        </div>
        <LeftSectionMenu
          contribution={contribution}
          currentRole={currentRole}
          themedColors={themedColors}
          mobileView={mobileView}
          pathname={pathname}
          createSection={createSection}
          sections={sections}
          editSection={editSection}
          deleteSection={deleteSection}
          archivedGroups={archivedGroups}
          createGroup={createGroup}
          handleGroupActions={handleGroupActions}
          currentGroup={currentGroup}
          isDarkThemeEnabled={isDarkThemeEnabled}
        />
      </div>
    </Drawer>
  );
}

LeftDrawer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  contribution: PropTypes.shape({
    type: string,
    isMembersHiddenInCommunity: bool,
    id: string,
    isGroupChatHidden: bool,
    isPurchased: bool,
  }).isRequired,
  currentRole: PropTypes.string.isRequired,
  themedColors: PropTypes.shape({ themedBackgroundColor: string, themedColor: string }).isRequired,
  mobileView: PropTypes.string.isRequired,
  pathname: PropTypes.string.isRequired,
  createSection: PropTypes.func.isRequired,
  sections: PropTypes.shape([]).isRequired,
  editSection: PropTypes.func.isRequired,
  deleteSection: PropTypes.func.isRequired,
  archivedGroups: PropTypes.shape([]).isRequired,
  createGroup: PropTypes.func.isRequired,
  handleGroupActions: PropTypes.func.isRequired,
  currentGroup: PropTypes.shape({}).isRequired,
  isDarkThemeEnabled: PropTypes.bool.isRequired,
};

export default LeftDrawer;
