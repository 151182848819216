import useRouter from 'hooks/useRouter';
import React from 'react';
import { addhttp, lightOrDark } from 'utils/utils';
import '../../ViewAsCoachPage.scss';
import { StyledSection, StyledButton } from './StyledElements';

function Questions({ theme, data, brandingColors }) {
  const { domain } = useRouter();
  const color = theme === 'dark' ? 'white' : 'black';
  const defaultBgColor = theme === 'dark' ? '#2A2C2E' : 'white';
  const primaryColor = brandingColors.PrimaryColorCode || defaultBgColor;
  const textColor = brandingColors.TextColorCode || '#FFFFFF';

  return (
    <>
      <StyledSection color={color} style={{ marginTop: '80px' }} className="text-center">
        <div className="container">
          <div id="webViewQuestionSection">
            <div className="question-section-header-text1">Have any questions?</div>
            <div className="question-section-header-text2">Let's Connect</div>
          </div>
          <StyledButton
            bgColor={primaryColor}
            id="sendMessage"
            margin="0 0 30px 0"
            color={textColor === 'Auto' ? lightOrDark(primaryColor) : textColor}
            className="btn btn-default btn-small"
            // onClick={() => {
            //   window.location.href = addhttp(domain.concat(`/conversations/all?chatId=${data?.userId}`));
            // }}
            onClick={() => {
              if (data?.messageRedirection?.redirectionType === 'email') {
                if (data?.messageRedirection?.email != null) {
                  window.location.href = `mailto:${data?.messageRedirection?.email}`;
                }
              } else if (data?.messageRedirection?.redirectionType === 'coherechat') {
                window.location.href = addhttp(domain.concat(`/conversations/all?chatId=${data?.userId}`));
              } else if (data?.messageRedirection?.redirectionType === 'otherlink') {
                window.location.href = addhttp(data?.messageRedirection?.externalLink);
              } else {
                window.location.href = addhttp(domain.concat(`/conversations/all?chatId=${data?.userId}`));
              }
            }}
          >
            {data?.messageRedirection?.buttonText ? data?.messageRedirection?.buttonText : 'Message'}
          </StyledButton>
        </div>
      </StyledSection>
    </>
  );
}

export default Questions;
