import React, { memo, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { useContribution } from 'pages/ContributionView/hooks';
import useClientPreview from 'hooks/useClientPreview';
import useAccount from 'hooks/useAccount';
import { useSelector } from 'react-redux';
import { TemplateType } from 'helpers/constants/templateType';
import { debounce, isEqual } from 'lodash';
import { activeContribSessTabViewSettingsSelector } from 'selectors/contribution';
import { useChangedProps, useRouter, useScreenView } from 'hooks/index';
import { styleOverrideStyledFn } from 'pages/ContributionView/ContributionSessions/component/styled';
import { makeStyles } from '@material-ui/core';

const ColumnHead = styled.div`
  transition: all 0.3s ease-in-out;
  display: flex;
  height: 4.375rem;
  padding: 0.9375rem 0.625rem;
  flex-direction: column;
  align-items: center;
  align-self: stretch;

  border-bottom: 1px solid var(--Cohere-Greys-Grey-Tint, #dfe3e4);
  background: #fff;

  ${styleOverrideStyledFn}
`;
const ColumnBody = styled.div`
  transition: all 0.3s ease-in-out;
  display: flex;
  // padding: 0rem 1.5rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.625rem;
  align-self: stretch;

  ${styleOverrideStyledFn}
`;
const ColumnContainer = styled.div`
  transition: all 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.625rem;
  align-self: stretch;

  ${styleOverrideStyledFn}
`;

const LeftContainer = styled(ColumnContainer)`
  ${({ mobileView }) =>
    mobileView
      ? css`
          flex: 1;
        `
      : css`
          flex-basis: 320px;
          flex-grow: 320;
          max-width: 320px;
          min-width: 320px;
        `}
  border-radius: 0rem 0rem 0.25rem 0.25rem;
  border: solid ${({ borderColor }) => borderColor || '#dfe3e4'};
  border-width: 0 1px 0 0;
  background: ${({ bodyBackgroundColor, backgroundColor }) => bodyBackgroundColor || backgroundColor || '#fff'};
  height: calc(100vh - 116px);
  overflow-y: scroll;
  position: sticky;
  top: 115px;
  ${({ isCenterColumnFullScreenMode }) =>
    isCenterColumnFullScreenMode &&
    css`
      // display: none !important;
    `};

  ${({ mobileView }) =>
    mobileView
      ? css`
          // padding-bottom: 5.5rem;
        `
      : css`
          // padding-bottom: 7.04rem;
        `};
  ${({
    useInternalLeftColumnScroll,
    isClient,
    isCoach,
    activeTemplate,
    isApplicationFormBannerDisplayed,
    isBannerInViewport,
  }) =>
    useInternalLeftColumnScroll &&
    css`
      overflow-y: scroll;
      height: calc(
        100vh -
          ${isClient && (activeTemplate === TemplateType.TemplateTwo || activeTemplate === TemplateType.TemplateOne)
            ? 177
            : 242}px - ${isCoach && isApplicationFormBannerDisplayed && isBannerInViewport ? 68 : 0}px
      );

      position: sticky;
      top: ${isClient && (activeTemplate === TemplateType.TemplateTwo || activeTemplate === TemplateType.TemplateOne)
        ? 177
        : 242}px;
    `}
  ${props => props.containerStyles && props.containerStyles}
`;

const LeftBody = styled(ColumnBody)`
  .session-group-container {
    // copied from "ColumnBody" styles
    display: flex;
    /* padding: 0rem 1.5rem; */
    flex-direction: column;
    align-items: flex-start;
    gap: 0.625rem;
    align-self: stretch;
  }
  ${props =>
    props.bodyBackgroundColor &&
    css`
      background-color: ${props.bodyBackgroundColor};
    `};
  ${props =>
    props.bodyBorderColor &&
    css`
      border-color: ${props.bodyBorderColor};
    `};
  ${props => props.bodyStyles && props.bodyStyles}
`;
const Container = styled(ColumnContainer)`
  flex-basis: 295px;
  flex-grow: 295px;
  max-width: ${({ mobileView }) => (mobileView ? '100%' : '295px')};
  min-width: 295px;
  border: solid #dfe3e4;
  border-width: 0 0 0 1px;
  background: #fff;
  position: sticky;
  top: 115px;
  height: calc(100vh - 116px);
  overflow-y: ${({ mobileView }) => (mobileView ? '' : 'scroll')};
  ${({ mobileView, isCreateOrEditFormContentPage }) =>
    mobileView && isCreateOrEditFormContentPage && `min-height: calc(100vh - 196px)`}
`;
const CenterContainer = styled(ColumnContainer)`
  flex-basis: 874px;
  flex-grow: 874;
  min-width: calc(100vw - 350px - 295px -17px);
  max-width: ${({ mobileView }) => (mobileView ? '' : 'calc(100vw-350px-295px -17px)')};
  width: ${({ mobileView }) => (mobileView ? '100%' : '65.5%')};
  border: solid #dfe3e4;
  border-width: 0;
  background: #fff;
  position: sticky;
  top: 115px;
  height: calc(100vh - 116px);
  overflow-y: ${({ mobileView }) => (mobileView ? '' : 'Scroll')};
`;
const Body = styled(ColumnBody)``;

const StickyPortion = styled.div`
  ${({ isSticky }) =>
    isSticky &&
    `
  position: sticky;
  top: 115px;
  z-index: 10;  
`}
`;
export const LeftSidebarColumn = memo(props => {
  const {
    children = null,
    borderColor,
    backgroundColor,
    bodyBackgroundColor,
    bodyBorderColor,
    mobileView = false,
  } = props;
  const { isCoach, isClient } = useAccount();

  const bodyStyles = {
    height: 'calc(100vh - 196px)',
  };

  return (
    <LeftContainer
      className="column--sessions-contents"
      {...{
        mobileView,
        borderColor,
        bodyBackgroundColor,
        backgroundColor,
        isClient,
        isCoach,
      }}
    >
      <LeftBody className="column--sessions-contents--body" {...{ bodyBackgroundColor, bodyBorderColor, bodyStyles }}>
        {children}
      </LeftBody>
    </LeftContainer>
  );
}, isEqual);
export const RightSidebarColumn = props => {
  const { children = null } = props;
  const { mobileView } = useScreenView();
  const { location } = useRouter();
  const isCreateOrEditFormContentPage =
    (location.pathname.includes('create-form') || location.pathname.includes('edit-form')) &&
    location.pathname.includes('content');

  return (
    <Container
      className="column--attachments-notes"
      mobileView={mobileView}
      isCreateOrEditFormContentPage={isCreateOrEditFormContentPage}
    >
      {children && <Body className="column--attachments-notes--body">{children}</Body>}
    </Container>
  );
};
export const MainContentColumn = props => {
  const { children = null } = props;
  const { mobileView } = useScreenView();

  return (
    <CenterContainer className="column--main-content" mobileView={mobileView}>
      {children && <Body className="column--main-content--body">{children}</Body>}
    </CenterContainer>
  );
};
