import React from 'react'
import PropTypes from 'prop-types'
import { Switch, StyledInput, StyledSlider } from './style'
import { useDispatch, useSelector } from 'react-redux'
import { updatePaidTierOptionsPeriod } from '../../actions/update-user'

const ToggleSwitch = () => {
  const dispatch = useDispatch()
  const status = useSelector((s) => s.account.isPaidTierOptionsMonthly)

  const sendStatusToCallback = (e) => {
    const status = e.target.checked
    dispatch(updatePaidTierOptionsPeriod(status))
  }
  return (
    <Switch className="switch">
      <StyledInput type="checkbox" onClick={sendStatusToCallback} checked={status} />
      <StyledSlider className="slider round" />
    </Switch>
  )
}

ToggleSwitch.propTypes = {
  cb: PropTypes.func.isRequired,
}

export default ToggleSwitch
