import React from 'react';
import './Attachment.scss';
import { Wrapper } from './Attachments';

const TwoAttachments = ({ attachments = [], removeAttachment, handleRemoveAttachment, handleImageClick }) => {
  return (
    <Wrapper index={0} removeAttachment={removeAttachment} handleRemoveAttachment={handleRemoveAttachment}>
      <div className="image-container">
        <div
          className="image-wrap-two-attachments"
          style={{
            backgroundImage: `url(${attachments[0]?.item?.s3Link || ''})`,
            backgroundSize: 'cover',
          }}
          onClick={() => handleImageClick(attachments[0]?.item || {})}
        ></div>
        <div
          className="image-wrap-two-attachments"
          style={{
            backgroundImage: `url(${attachments[1]?.item?.s3Link || ''})`,
            backgroundSize: 'cover',
          }}
          onClick={() => handleImageClick(attachments[1]?.item || {})}
        ></div>
      </div>
    </Wrapper>
  );
};

export default TwoAttachments;
