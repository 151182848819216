import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Grid, useMediaQuery, useTheme } from '@material-ui/core';

import { fetchClientContribution, fetchClientContributionAfterInterval } from 'actions/contributions';
import Button from 'components/FormUI/Button';
import JoinSessionTime from 'components/SessionButtons/JoinSessionTime';
import LaunchRecordedSessionVideo from 'components/SessionButtons/LaunchRecordedSessionVideo';
import { Video } from 'components/Video/Video';
import { useAccount, useClientPreview, useRouter, useVideoChat } from 'hooks';
import { checkIsFuture } from 'utils/datesAndMoney';

import CourseSessions from '../CourseSessions';
import CourseSessionsRedesign from '../CourseSessions.redesign';
import BookSessionTime from './BookSessionTime';
import UnbookSessionTime from './UnbookSessionTime';
import { useCompleteSelfPacedSessionTime } from './hooks/useCompleteSelfPacedSession';
import Loader from 'components/UI/Loader';
import './ClientCourseSessions.scss';
import { getIpGlobal } from 'utils/utils';
import { isEmpty } from 'lodash';

const checkIfSessionTimeBooked = (sessionTime, userId) => {
  const { participantsIds } = sessionTime;

  if (!participantsIds || participantsIds.length === 0) {
    return false;
  }

  return participantsIds.includes(userId);
};

const ClientCourseSessions = ({ user, contribution, contribution: { id, isPurchased } }) => {
  const dispatch = useDispatch();
  const { clientPreviewMode } = useClientPreview();
  const { token } = useVideoChat();
  const [showPopUp, setShowPopUp] = useState(false);
  const [isMustWatchPriorSelfPacedRecords, setIsMustWatchPriorSelfPacedRecords] = useState(false);
  const [isSessionLoading, setIsSessionLoading] = useState(false);
  const { pathname } = useRouter();
  const { isLoggedIn } = useAccount();
  const devModeSessionsTab = true && isLoggedIn && isPurchased; // pathname?.includes('/sessions/dev') || false;

  const refreshContribution = useCallback(async () => {
    const userIp = await getIpGlobal();
    dispatch(fetchClientContribution(id, userIp));
  }, [dispatch, id]);

  const { Popup, isOpen, onShowPopup } = useCompleteSelfPacedSessionTime(refreshContribution);

  useEffect(() => {
    // if (!isEmpty(user) && !isPurchased) {
    const sessionsRefresher = setInterval(async () => {
      if (!showPopUp && !token && !clientPreviewMode) {
        const userIp = await getIpGlobal();
        dispatch(fetchClientContributionAfterInterval(id, userIp));
      }
    }, 10000);

    return () => {
      clearInterval(sessionsRefresher);
    };
    // }
  }, [token, dispatch, id, showPopUp]);

  useEffect(() => {
    if (token) {
      return () => refreshContribution();
    }
  }, [token, refreshContribution]);
  // const columnsContainerRef = useRef();
  // const [heightOffset, setHeightOffset] = useState(250 + 20);

  // useEffect(() => {
  //   if (columnsContainerRef?.current) {
  //     // setHeightOffset(columnsContainerRef?.current.offsetTop + 20);
  //   }
  // }, [columnsContainerRef, columnsContainerRef?.current]);

  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'));
  const SessionsComponent = devModeSessionsTab ? CourseSessionsRedesign : CourseSessions;
  return (
    <SessionsComponent
      // layout={{ heightOffset, columnsContainerRef }}
      {...(!devModeSessionsTab && { contribution })}
      changeShowPopUp={value => {
        setShowPopUp(value);
      }}
      renderSessionTimeActions={(session, sessionTime, sessionTimeIndex) => {
        if (!isPurchased) {
          return null;
        }
        const { maxParticipantsNumber, sessionTimes } = session;
        const { participantsIds, videoRoomInfo } = sessionTime;

        const isOtherSessionTimeBooked = sessionTimes
          .filter(st => st.id !== sessionTime.id)
          .some(st => checkIfSessionTimeBooked(st, user.id));
        const isBooked = checkIfSessionTimeBooked(sessionTime, user.id);
        const hasAvailableSeats = participantsIds.length < maxParticipantsNumber;
        const isLaunched = !!videoRoomInfo && videoRoomInfo.isRunning;

        const isHasRecordings =
          sessionTime.recordingInfos.length > 0 || sessionTime?.zoomMeetingData?.recordingFileName;
        const isHasPreRecordings = session.prerecordedSession || sessionTime?.prerecordedSession;
        const isSelfPaced = session?.isPrerecorded;
        const isViewRecordingButton = (isHasPreRecordings && isBooked) || isHasRecordings;
        const isBookButton = !isSelfPaced && !isBooked && !isOtherSessionTimeBooked && hasAvailableSeats;
        const isDisabledCompleteButton =
          checkIsFuture(sessionTime.startTime) || sessionTime?.completedSelfPacedParticipantIds?.includes(user.id);

        const isSelfPacedRecording = isSelfPaced && (sessionTime?.prerecordedSession || session.prerecordedSession);

        return (
          <>
            {!isSelfPacedRecording && !sessionTime.isCompleted && (
              <div className="client-course-session-time-actions">
                {isBookButton && (
                  <BookSessionTime
                    contribution={contribution}
                    session={session}
                    sessionTime={sessionTime}
                    onSessionTimeBooked={refreshContribution}
                    mobileView={mobileView}
                  />
                )}
                {isBooked && (
                  <>
                    {!isSelfPaced && (
                      <UnbookSessionTime
                        contribution={contribution}
                        session={session}
                        sessionTime={sessionTime}
                        onSessionTimeUnbooked={refreshContribution}
                        mobileView={mobileView}
                      />
                    )}
                    {!isHasPreRecordings && (
                      <JoinSessionTime
                        user={user}
                        contribution={contribution}
                        session={session}
                        sessionTime={sessionTime}
                        isLaunched={isLaunched}
                        liveVideoServiceProvider={contribution?.liveVideoServiceProvider}
                        mobileView={mobileView}
                      />
                    )}
                  </>
                )}
                {/* {isSelfPaced && (
                  <Button
                    autoWidth
                    disabled={isDisabledCompleteButton}
                    onClick={() =>
                      onShowPopup({
                        contributionId: contribution.id,
                        sessionTimeId: sessionTime.id,
                      })
                    }
                  >
                    Complete
                  </Button>
                )} */}
              </div>
            )}

            {/* {isSelfPacedRecording && (
              <Grid container direction="column">
                <Grid style={{ position: 'relative' }}>
                  <Video
                    isPreviewMode
                    session={session}
                    sessionTime={sessionTime}
                    sessionTimeIndex={sessionTimeIndex}
                  />
                  <LaunchRecordedSessionVideo
                    contribution={contribution}
                    session={session}
                    sessionTime={sessionTime}
                    mobileView={mobileView}
                    sessionTimeIndex={sessionTimeIndex}
                    isPreviewMode
                  />
                </Grid>
                <Button
                  autoWidth
                  disabled={isDisabledCompleteButton}
                  onClick={() =>
                    onShowPopup({
                      contributionId: contribution.id,
                      sessionTimeId: sessionTime.id,
                    })
                  }
                  style={{ alignSelf: 'flex-end', marginBottom: 8 }}
                >
                  Complete
                </Button>
              </Grid>
            )} */}

            {isViewRecordingButton && !isSelfPacedRecording && (
              <LaunchRecordedSessionVideo
                contribution={contribution}
                session={session}
                sessionTime={sessionTime}
                mobileView={mobileView}
              />
            )}

            {/* {isSelfPaced && <Popup />} */}
          </>
        );
      }}
    />
  );
};

ClientCourseSessions.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }).isRequired,
  contribution: PropTypes.shape({
    id: PropTypes.string,
    isPurchased: PropTypes.bool,
    liveVideoServiceProvider: PropTypes.shape({
      customLink: PropTypes.string,
      providerName: PropTypes.string,
    }),
  }).isRequired,
};

export default ClientCourseSessions;
