import React from 'react';

const HappyEmoji = ({ width = '18px', height = '18px', fill = 'black', onClick }) => {
  return (
    <div onClick={onClick} style={{ cursor: 'pointer' }}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={width} height={height} fill={fill}>
        <path d="M12,1A11,11,0,1,0,23,12,11.013,11.013,0,0,0,12,1Zm0,20a9,9,0,1,1,9-9A9.01,9.01,0,0,1,12,21ZM8,11V9a1,1,0,0,1,2,0v2a1,1,0,0,1-2,0Zm8-2v2a1,1,0,0,1-2,0V9a1,1,0,0,1,2,0ZM8,14h8a4,4,0,0,1-8,0Z" />
      </svg>
    </div>
  );
};

export default HappyEmoji;
