import { isUndefined } from 'lodash';
import { CARDS, FILTERTYPES, ITEM_CARDS, MEDIA_TYPES, SELFPACED_CONTENT_TYPES, SESSION_TYPES } from './constants';
import axiosInstance from 'utils/axiosInstance';
import * as contributionService from 'services/contributions.service';
import { isEmbeddedAudioPlatform, isEmbeddedVideoPlatform } from 'utils/utils';

export const FilteredSessions = (sessions = [], type = null, isCoach = true) => {
  switch (type) {
    case FILTERTYPES.ALL:
      return sessions;
    case FILTERTYPES.LIVE:
      return sessions.filter(s => !s.isPrerecorded);
    case FILTERTYPES.SELFPACED:
      return sessions.filter(s => s.isPrerecorded);
    case FILTERTYPES.PAST:
      return isCoach
        ? sessions.filter(s => !s.isPrerecorded && s.isCompleted).reverse()
        : sessions.filter(s => s.isCompleted).reverse();
    case FILTERTYPES.UPCOMING: {
      // const allLiveSessions = sessions.filter(s => !s.isPrerecorded);
      // const allSelfpacedSessions = sessions.filter(s => s.isPrerecorded);
      // const allLiveSessionsUpcoming = allLiveSessions.filter(s => !s.isCompleted); // For coach / client all those sessions which are not completed will be considered as upcoming sessions
      // let AllUpcomingSessions = [];
      // if (isCoach) {
      //   const allSelfpacedSessionsUpcoming = allSelfpacedSessions; // For coach all self paced module will be considered as upcoming sessions bcz coach have to authority to complete the selfpaced modules
      //   AllUpcomingSessions = allLiveSessionsUpcoming.concat(allSelfpacedSessionsUpcoming);
      // } else {
      //   const allSelfpacedSessionsUpcoming = allSelfpacedSessions.filter(s => !s.isCompleted); // For client all those self paced modules which are not completed will be considered as upcoming modules
      //   AllUpcomingSessions = allLiveSessionsUpcoming.concat(allSelfpacedSessionsUpcoming);
      // }
      // return AllUpcomingSessions || [];

      let AllUpcomingSessions = [];
      if (isCoach) {
        AllUpcomingSessions = sessions.filter(
          session => !(session.isPrerecorded === false && session.isCompleted === true),
        );
      } else {
        AllUpcomingSessions = sessions.filter(session => !(session.isCompleted === true));
      }
      return AllUpcomingSessions || [];
    }
    default:
      return sessions;
  }
};

export const pickSessionCard = (sessionType = null) => {
  return CARDS[sessionType] || null;
};
export const pickContentItemCard = (
  // session = null,
  sessionType = null,
  contentType = null,
) => {
  if (
    // isUndefined(session) ||
    isUndefined(sessionType)
  ) {
    return null;
  }
  const item = ITEM_CARDS[sessionType];
  switch (sessionType) {
    case SESSION_TYPES.LIVE:
      return item;
    // return {
    //   ContentCard: item,
    //   contentProps: {
    //     // title: ``
    //   }
    // };
    case SESSION_TYPES.SELFPACED:
      switch (contentType) {
        case SELFPACED_CONTENT_TYPES.VIDEO:
        case SELFPACED_CONTENT_TYPES.IMAGE:
        case SELFPACED_CONTENT_TYPES.DOCUMENT:
        case SELFPACED_CONTENT_TYPES.PDF_DOCUMENT:
        case SELFPACED_CONTENT_TYPES.DOC_DOCUMENT:
        case SELFPACED_CONTENT_TYPES.XLS_DOCUMENT:
        case SELFPACED_CONTENT_TYPES.TXT_DOCUMENT:
        case SELFPACED_CONTENT_TYPES.MUSIC:
        case SELFPACED_CONTENT_TYPES.QUIZ:
        case SELFPACED_CONTENT_TYPES.EMBEDDED_LINK:
        case SELFPACED_CONTENT_TYPES.EMBEDDED_AUDIO_LINK:
        case SELFPACED_CONTENT_TYPES.EMBEDDED_VIDEO_LINK:
          return item[contentType];
        default:
          return null;
      }
    // return {
    //   ContentCard: item[contentType],
    //   contentProps: {
    //     // title: ``
    //   }
    // };
    default:
      return null;
  }
};
export const pickContentItemCardList = (
  // session = null,
  sessionType = null,
  contentType = null,
) => {
  if (
    // !session ||
    !sessionType
  ) {
    return null;
  }
  const item = ITEM_CARDS[sessionType];
  switch (sessionType) {
    case SESSION_TYPES.LIVE:
      return item;
    // return {
    //   ContentCard: item,
    //   contentProps: {
    //     // title: ``
    //   }
    // };
    case SESSION_TYPES.SELFPACED:
      return item[contentType];
    // return {
    //   ContentCard: item[contentType],
    //   contentProps: {
    //     // title: ``
    //   }
    // };
    default:
      return null;
  }
};
export const renderSessionContentList = (sessionType = null, sessionTimes = null) => {
  switch (sessionType) {
    case SESSION_TYPES.LIVE:
      return null;
    // return {
    //   ContentCard: item,
    //   contentProps: {
    //     // title: ``
    //   }
    // };
    case SESSION_TYPES.SELFPACED:
      return null;
    // return {
    //   ContentCard: item[contentType],
    //   contentProps: {
    //     // title: ``
    //   }
    // };
    default:
      return null;
  }
};
export const detectContentMediaType = (embeddedVideoUrl = null, prerecordedSession = null, sessionTime) => {
  if (
    !prerecordedSession &&
    !embeddedVideoUrl &&
    sessionTime &&
    sessionTime.selfPacedContentAsHtml &&
    sessionTime.selfPacedContentAsHtml.length === 0
  ) {
    return null;
  }
  if (embeddedVideoUrl) {
    if (isEmbeddedAudioPlatform(embeddedVideoUrl)) {
      return SELFPACED_CONTENT_TYPES.EMBEDDED_AUDIO_LINK;
    }
    if (isEmbeddedVideoPlatform(embeddedVideoUrl)) {
      return SELFPACED_CONTENT_TYPES.EMBEDDED_VIDEO_LINK;
    }
    return SELFPACED_CONTENT_TYPES.EMBEDDED_LINK;
  }
  if (sessionTime?.selfPacedContentAsHtml?.length > 0 && sessionTime?.selfPacedContentAsHtml != undefined) {
    return MEDIA_TYPES['text'];
  }
  const { extension = null } = prerecordedSession || {};
  if (!extension) {
    return null;
  }

  const extLower = extension?.toLowerCase();
  return MEDIA_TYPES[extLower];
};

export const handleSelfpacedMediaDownload = (videoUrl, fileName) => {
  if (!fileName || !videoUrl) {
    return;
  }
  const parsedUrl = new URL(videoUrl);
  // const filename = parsedUrl.searchParams?.get('response-content-disposition');
  // const newFilename = `attachment; filename=${fileName}`;
  // parsedUrl.searchParams.set('response-content-disposition', newFilename);
  // console.info('filename:', fileName, videoUrl, parsedUrl);
  const anchorElement = document.createElement('a');
  // anchorElement.setAttribute('target', '_blank');
  anchorElement.href = videoUrl;
  anchorElement.download = fileName;
  anchorElement.click();
};

export const handleSelfpacedMediaDelete = (videoData, contributionId) => {
  return axiosInstance
    .post('/Content/DeleteAttachment-Selfpaced?isVideo=true', videoData)
    .then(res => contributionService.getCohealerContribution(contributionId));
};

export const isElementInViewport = (element = null) => {
  if (!element) {
    return false;
  }

  // check if element is hidden behind other elements due to overflow or z-index
  const rect = element.getBoundingClientRect();
  const style = window.getComputedStyle(element);
  const isOverflowHidden = style.overflow === 'hidden' || style.overflowX === 'hidden' || style.overflowY === 'hidden';
  const isZIndexHidden = style.zIndex === 'auto' ? false : parseInt(style.zIndex, 10) < 0;

  const x =
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth) &&
    !isOverflowHidden &&
    !isZIndexHidden;
  console.info('isElementInViewport:', x);
  return x;
};
