import axiosInstance from 'utils/axiosInstance';
import { get } from 'lodash/fp';

const BaseURL = '/EmailMarketing';

const deleteCampaigns = data => axiosInstance.post(`${BaseURL}/DeleteCampaigns`, data).then(get('data'));

export const CampaignServices = {
  deleteCampaigns,
};
