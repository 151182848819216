import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { usePendingApplications } from 'pages/AllApplicationsPage/hooks/usePendingApplications';

const useStyles = makeStyles(theme => ({
  wrapper: {
    backgroundColor: theme.palette.colors.darkOceanBlue2,
    borderRadius: 10,
    color: theme.palette.common.white,
    fontSize: 11,
    fontWeight: theme.typography.fontWeightBold,
    height: 20,
    padding: '3px 8px',
  },
}));

export const PendingApplicationsCount = () => {
  const classNames = useStyles();
  const { pendingCount } = usePendingApplications();
  if (!pendingCount || pendingCount === 0) {
    return null;
  }

  return <Typography className={classNames.wrapper}>{pendingCount}</Typography>;
};
