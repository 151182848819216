import React from 'react';
import classes from './CrossableTag.module.scss';
import CrossIcon from '../Filter/SVG/CrossIcon';

const CrossableTag = ({ tagName, onCross, isReadOnlyView = false }) => {
  return (
    <div className={classes.main}>
      <div className={classes.tag_name}>{tagName}</div>
      {isReadOnlyView === false && (
        <div className={classes.cross_icon} onClick={onCross}>
          <CrossIcon width="10px" height="10px" color="#ffff" />
        </div>
      )}
    </div>
  );
};

export default CrossableTag;
