import React from 'react';

import { ContributionType } from 'helpers/constants';
import { useAccount } from 'hooks';
import useContribution from '../hooks/useContribution';
import CourseDiscussion from './CourseDiscussion';
import OneToOneDiscussion from './OneToOneDiscussion';
import MembershipDiscussion from './MembershipDiscussion';

const ContributionDiscussionContainer = () => {
  const { user, currentRole } = useAccount();
  const contribution = useContribution();
  const DISCUSSION_MAPPING = {
    [ContributionType.contributionCourse]: (user, currentRole, contribution) => (
      <CourseDiscussion user={user} currentRole={currentRole} contribution={contribution} />
    ),
    [ContributionType.contributionOneToOne]: (user, currentRole, contribution) => (
      <OneToOneDiscussion user={user} currentRole={currentRole} contribution={contribution} />
    ),
    [ContributionType.contributionMembership]: (user, currentRole, contribution) => (
      <MembershipDiscussion user={user} currentRole={currentRole} contribution={contribution} />
    ),
    [ContributionType.contributionCommunity]: (user, currentRole, contribution) => (
      <MembershipDiscussion user={user} currentRole={currentRole} contribution={contribution} />
    ),
  };

  return DISCUSSION_MAPPING[contribution.type] ? (
    DISCUSSION_MAPPING[contribution.type](user, currentRole, contribution)
  ) : (
    <div>No discussions yet.</div>
  );
};

export default ContributionDiscussionContainer;
