import React, { useState } from 'react';
import PropTypes from 'prop-types';
import UpcomingContributions from '../UpcomingContributions';
import PastContributions from '../PastContributions';
import { Box, Container, Grid } from '@material-ui/core';
import { ClientJourneyContribution } from 'services/dataContracts/contributions';
import ContributionsList from '../UpcomingContributions/ContributionsList';
import SessionCard from '../../SessionCard/SessionCard';
import styled from 'styled-components';
import { toRem } from 'utils/styles';
import { JourneySection } from 'helpers/constants/sections';
import { isEmpty } from 'ramda';
const Heading = styled.p`
  font-size: ${toRem(22)};
  font-weight: 900;
  line-height: 1.5;
  letter-spacing: 0.12px;
  color: #116582;

  ${({ mobileView }) => mobileView && `font-size: ${toRem(16)};`}
`;

const StyledSessionsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
`;

const checkIfHasContributions = contributions => contributions?.length > 0;

const SelfpacedConstributions = ({ modules }) => {
  const [modulesSessions, setModulesSessions] = useState(modules);
  const deleteIndexModules = indexData => {
    const data1 = modulesSessions.filter((item, index) => index != indexData);
    setModulesSessions(data1);
  };
  if (isEmpty(modules)) {
    return <Grid container>You don't have any active sessions yet.</Grid>;
  }
  return (
    <Grid container direction="column">
      <Grid item style={{ marginBottom: '70px' }}>
        <Heading>Modules</Heading>
        <StyledSessionsContainer>
          {modulesSessions?.length > 0 ? (
            modulesSessions?.map((s, i) => {
              return (
                <SessionCard
                  key={i}
                  index={i}
                  section={JourneySection.modules}
                  sessioninfo={s}
                  removeIndexFunction={() => {
                    deleteIndexModules(i);
                  }}
                />
              );
            })
          ) : (
            <Grid container>No modules available.</Grid>
          )}
        </StyledSessionsContainer>
      </Grid>
    </Grid>
  );
};

const contributionsPropType = PropTypes.arrayOf(PropTypes.instanceOf(ClientJourneyContribution)).isRequired;

SelfpacedConstributions.propTypes = {
  upcomingcontributions: PropTypes.shape({
    otherIncompleted: contributionsPropType,
    today: contributionsPropType,
    thisWeek: contributionsPropType,
    thisMonth: contributionsPropType,
    nextMonth: contributionsPropType,
    thisYear: contributionsPropType,
    afterThisYear: contributionsPropType,
    notBooked: contributionsPropType,
  }).isRequired,
  // pastcontributions: contributionsPropType,
};

export default SelfpacedConstributions;
