const rules = {
  Visitor: {
    static: [],
  },
  Cohealer: {
    static: [
      'contributions:visit',
      'contributions:see-create-button',
      'contribution:share',
      'contribution:edit',
      //   "posts:list",
      //   "posts:create",
      //   "users:getSelf",
      //   "home-page:visit",
      //   "dashboard-page:visit"
    ],
    // dynamic: {
    //   "posts:edit": ({ userId, postOwnerId }) => {
    //     if (!userId || !postOwnerId) return false;
    //     return userId === postOwnerId;
    //   }
    // }
  },
  Client: {
    static: [],
  },
  Admin: {
    static: [],
  },
};

export default rules;
