import React from 'react';

import Modal from 'components/UI/Modal';
import { LabelText } from 'components/UI/Text/TextStyles';

const PaidTierModal = ({ isOpen, handleClose, handleSubmit, label, hiddenCancel, title, submitTitle }) => {
  return (
    <Modal
      title={title}
      isOpen={isOpen}
      onCancel={handleClose}
      onSubmit={handleSubmit}
      hiddenCancel={hiddenCancel}
      submitTitle={submitTitle}
    >
      <LabelText>{label}</LabelText>
    </Modal>
  );
};

export default PaidTierModal;
