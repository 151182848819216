import useAccount from 'hooks/useAccount';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Moment from 'moment';

import { fetchUpcomingCreated } from 'actions/contributions';
import { UserRoles } from 'helpers/constants';
import { getClientContribution, getClientPurchases, getCohealerContribution } from 'services/contributions.service';

const mapClientsContributions = data => data.map(item => ({ ...item, id: item.contributionId }));

const sortContributionsByRecentFirst = data => {
  return data.sort((a, b) => {
    return new Moment(b.createTime).valueOf() - new Moment(a.createTime).valueOf();
  });
};

const addAvatarToContributions = ({ data, source }) => {
  return data.map(item => {
    const sourceOfAvatar = source.find(sourceItem => sourceItem.id === item.id);

    return {
      ...item,
      authorAvatarUrl: sourceOfAvatar?.authorAvatarUrl,
    };
  });
};

const selector = state => ({
  authorAvatarUrl: state?.upcomingCreated?.upcomingCreated?.authorAvatarUrl || '',
  contributionsForTable: state?.upcomingCreated?.upcomingCreated?.contributionsForTable || [],
});

const loadContributionByUserRole = {
  [UserRoles.client]: getClientContribution,
  [UserRoles.cohealer]: getCohealerContribution,
};

export const useLoadContributions = () => {
  const [contributions, setContributions] = useState(null);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isMappedContributionsData, setIsMappedContributionsData] = useState(false);

  const { authorAvatarUrl, contributionsForTable } = useSelector(selector);
  const { currentRole, user } = useAccount();
  const { id: userId } = user;

  const dispatch = useDispatch();

  // Step 1: load contributions by role
  useEffect(() => {
    if (contributions) {
      return;
    }

    setIsLoading(true);

    if (currentRole === UserRoles.cohealer) {
      dispatch(fetchUpcomingCreated(userId));
    }

    if (currentRole === UserRoles.client) {
      getClientPurchases(userId, false)
        // Step 2 (for client): map contributions for a client
        .then(mapClientsContributions)
        .then(mappedContributions => {
          setContributions(mappedContributions);
          setIsMappedContributionsData(true);
          setIsLoading(false);
        })
        .catch(responseError => setError(responseError));
    }
  }, [contributions, currentRole, data, dispatch, isMappedContributionsData, userId]);

  // Step 2 (for coach): map contributions for a coach
  useEffect(() => {
    if (currentRole === UserRoles.cohealer && contributionsForTable?.length) {
      setContributions(contributionsForTable.map(item => ({ ...item })));
      setData(contributionsForTable.map(item => ({ ...item })));
      setIsMappedContributionsData(true);
      setIsLoading(false);
    }
  }, [authorAvatarUrl, contributionsForTable, currentRole, setContributions]);

  // Step 3: load contribution details
  useEffect(() => {
    if (Array.isArray(contributions) && !data && !error && isMappedContributionsData) {
      const promises = contributions.map(item => item);

      setData(sortContributionsByRecentFirst(contributions));

      // .then(setData)
    }
  }, [contributions, currentRole, data, userId, error, isMappedContributionsData]);

  // Step 4: finish preparing data
  useEffect(() => {
    if (isLoading && data) {
      setIsLoading(false);
    }
  }, [data, isLoading]);

  // reset state on unmount
  useEffect(() => {
    return () => {
      setContributions(null);
      setData(null);
      setError(null);
      setIsMappedContributionsData(false);
    };
  }, []);

  return { data, error, isLoading };
};
