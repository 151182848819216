import React from 'react'
import PropTypes from 'prop-types'

export const IconCoach = ({ style }) => (
  <svg style={style} ariaHidden="true" dataIcon="coach" role="img" viewBox="0 0 190 133">
    <path
      d="M19.5773 36.064C19.5773 16.6627 35.3039 0.936028 54.7053 0.936028C74.1053 0.936028 89.8333 16.6627 89.8333 36.064C89.8333 55.464 74.1053 71.192 54.7053 71.192C35.3039 71.192 19.5773 55.464 19.5773 36.064Z"
      fill="currentColor"
    />
    <path
      d="M187.588 109.999C186.444 109.999 115.772 109.999 115.772 109.999C115.005 111.332 113.599 112.665 111.979 112.665H111.469V119.332C147.185 119.332 178.679 119.332 180.019 119.332C184.624 119.332 187.553 114.707 188.884 111.889C189.444 110.705 188.733 109.999 187.588 109.999Z"
      fill="currentColor"
    />
    <path
      d="M104.803 132.665V101.447C104.803 86.4013 94.6453 75.332 79.5999 75.332H29.8093C14.7639 75.332 0.802612 86.4013 0.802612 101.447V132.665H104.803Z"
      fill="currentColor"
    />
    <path
      d="M124.592 62.68C124.592 56.7267 129.419 51.9013 135.372 51.9013C141.325 51.9013 146.151 56.7267 146.151 62.68C146.151 68.6333 141.325 73.46 135.372 73.46C129.419 73.46 124.592 68.6333 124.592 62.68Z"
      fill="currentColor"
    />
    <path
      d="M152.803 88.6653V84.0133C152.803 78.88 149.172 75.332 144.039 75.332H126.705C121.572 75.332 116.803 78.88 116.803 84.0133V88.6653H152.803Z"
      fill="currentColor"
    />
    <path
      d="M101.301 12.944C100.272 12.944 99.4373 12.1094 99.4373 11.08C99.4373 10.0507 100.272 9.21605 101.301 9.21605C102.331 9.21605 103.165 10.0507 103.165 11.08C103.165 12.1094 102.331 12.944 101.301 12.944ZM159.459 7.33199H85.0146C87.6506 10.1107 89.9093 13.248 91.708 16.6653H162.136V95.332H110.976C111.295 97.3 111.469 99.3387 111.469 101.447V105.999H159.459C166.417 105.999 171.469 99.7054 171.469 92.7467V19.28C171.469 12.3214 166.417 7.33199 159.459 7.33199Z"
      fill="currentColor"
    />
  </svg>
)

IconCoach.propTypes = {
  style: PropTypes.string,
}

IconCoach.defaultProps = {
  style: {},
}
