import moment from 'moment';

import ChatClientChannelType from './ChatClientChannelType';

const useChatClientChannelAttributes = (channel, members, myMember) => {
  if (!channel || !myMember) {
    return {
      title: '',
      iconUrl: '',
      type: null,
      contributionId: null,
      lastMessageIndex: 0,
      lastMessageTimestamp: null,
    };
  }

  const {
    friendlyName: title,
    attributes: { PreviewImage: iconUrl, Type: type, ContributionId: contributionId },
    lastMessage,
  } = channel;
  const oppositeMember = members.find(m => m.sid !== myMember.sid);
  const showOppositeMember = type === ChatClientChannelType.peer && !!oppositeMember;
  const actualTitle = showOppositeMember ? oppositeMember.attributes.Name : title;
  const actualIconUrl = showOppositeMember ? oppositeMember.attributes.PreviewImage : iconUrl;

  return {
    title: actualTitle,
    iconUrl: actualIconUrl,
    type,
    contributionId: type === ChatClientChannelType.contribution ? contributionId : null,
    lastMessageIndex: lastMessage ? lastMessage.index : 0,
    lastMessageTimestamp: lastMessage ? moment(lastMessage.timestamp) : null,
  };
};

export default useChatClientChannelAttributes;
