import { TextField } from '@material-ui/core';
import Modal from 'components/UI/Modal';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { DeleteIcon } from 'pages/Profile/components/icons/Delete';
import { addLinksInGroup } from 'services/communitySections.service';
import { connect } from 'react-redux';
import { updateCurrentGroupData, updateSectionsMetaOnly } from 'actions/communitySections';
import Loader from 'components/UI/Loader';
import * as Yup from 'yup';
import { FieldArray, Formik, getIn } from 'formik';

const TITLE_LIMIT = 50;
const DEFAULT_LINK_VALUE = {
  title: '',
  url: '',
};

const validationSchema = Yup.object().shape({
  links: Yup.array().of(
    Yup.object().shape({
      title: Yup.string().max(50, "Title can't have more than 50 characters").required('Title is a required field'),
      url: Yup.string()
        .matches(/^(?:(?:(?:https?|ftp):)?\/\/)?((?:[\w-]+\.)+[a-z]{2,})(:\d{1,5})?(\/\S*)?$/i, 'Enter a valid url')
        .required('Url is required'),
    }),
  ),
});

function LinksModal({
  contributionId,
  title,
  onCancel,
  isOpen,
  darkThemeEnabled,
  currentGroup,
  updateCurrentGroup,
  successMessage,
}) {
  const [submitTitle, setSubmitTitle] = useState('');
  const [loading, setLoading] = useState(false);
  const formRef = useRef(null);

  useEffect(() => {
    setSubmitTitle(title?.toLowerCase().includes('create') ? 'Create' : 'Done');
  }, [title]);

  const onSubmit = () => {
    setLoading(true);
    addLinksInGroup({
      id: currentGroup?.id || contributionId,
      contributionId,
      links: formRef?.current?.values?.links,
    })
      .then(res => {
        updateCurrentGroup({
          group: {
            ...currentGroup,
            links: res,
          },
        });
        successMessage(`link${formRef?.current?.values?.links?.length > 1 ? 's' : ''} Added`);
        onCancel();
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      {loading && <Loader />}
      <Modal
        className="add-community-links-popup"
        isOpen={isOpen}
        title="Add Links"
        applyTheming
        submitTitle={submitTitle}
        hiddenCancel
        onCancel={onCancel}
        onSubmit={() => formRef.current.handleSubmit()}
      >
        <Formik
          innerRef={formRef}
          initialValues={{ links: [DEFAULT_LINK_VALUE] }}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          validateOnBlur={false}
        >
          {({ values, errors, touched, setFieldTouched, setFieldValue }) => (
            <>
              <FieldArray name="links">
                {fieldArrayProps => {
                  const { push, remove } = fieldArrayProps;
                  return (
                    <>
                      {values?.links.map((link, index) => (
                        <>
                          <span className="add-link-header">
                            <p className="add-link-heading">Add Link</p>
                            {values?.links?.length > 1 && (
                              <span
                                className="delete-link-community-modal"
                                tabIndex="0"
                                role="button"
                                onClick={() => remove(index)}
                                onKeyPress={() => {}}
                              >
                                <DeleteIcon color={darkThemeEnabled ? 'white' : 'black'} />
                              </span>
                            )}
                          </span>
                          <TextField
                            name="title"
                            className="input-field community-links-input-field"
                            id="outlined-basic"
                            margin="normal"
                            variant="outlined"
                            fullWidth
                            autoFocus
                            value={link?.title || ''}
                            placeholder="Title"
                            onChange={e => setFieldValue(`links.[${index}].title`, e.target.value)}
                            onBlur={() => setFieldTouched(`links.[${index}].title`)}
                            FormHelperTextProps={{
                              style: {
                                textAlign: 'right',
                                marginRight: 0,
                              },
                            }}
                            error={Boolean(
                              getIn(errors, `links[${index}].title`) && getIn(touched, `links[${index}].title`),
                            )}
                            inputProps={{ maxLength: TITLE_LIMIT }}
                          />
                          {getIn(errors, `links[${index}].title`) && getIn(touched, `links[${index}].title`) && (
                            <div className="modal-error-message">{errors?.links[index]?.title}</div>
                          )}
                          <TextField
                            name="title"
                            className="input-field community-links-input-field"
                            id="outlined-basic"
                            margin="normal"
                            variant="outlined"
                            fullWidth
                            value={link?.url || ''}
                            placeholder="Link"
                            onChange={e => setFieldValue(`links.[${index}].url`, e.target.value)}
                            onBlur={() => setFieldTouched(`links.[${index}].url`)}
                            FormHelperTextProps={{
                              style: {
                                textAlign: 'right',
                                marginRight: 0,
                              },
                            }}
                            error={Boolean(
                              getIn(errors, `links[${index}].url`) && getIn(touched, `links[${index}].url`),
                            )}
                          />
                          {getIn(errors, `links[${index}].url`) && getIn(touched, `links[${index}].url`) && (
                            <div className="modal-error-message">{errors?.links[index]?.url}</div>
                          )}
                        </>
                      ))}
                      <span
                        className="modal-add-link-button"
                        tabIndex="0"
                        role="button"
                        onClick={() => push(DEFAULT_LINK_VALUE)}
                        onKeyPress={() => {}}
                      >
                        <AddCircleOutlineIcon />
                        <span className="modal-add-link-button-text">Add Links</span>
                      </span>
                    </>
                  );
                }}
              </FieldArray>
            </>
          )}
        </Formik>
      </Modal>
    </>
  );
}

const actions = {
  getSectionsMeta: updateSectionsMetaOnly,
  updateCurrentGroup: updateCurrentGroupData,
};

const mapStateToProps = ({ communitySections: { currentGroup } }) => ({
  currentGroup,
});
LinksModal.propTypes = {
  contributionId: PropTypes.string.isRequired,
  darkThemeEnabled: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  currentGroup: PropTypes.shape({ id: PropTypes.string }).isRequired,
  updateCurrentGroup: PropTypes.func.isRequired,
  successMessage: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, actions)(LinksModal);
