import * as actions from 'actions/communitySections';
import { handleActions } from 'redux-actions';
import { pluckKeys } from 'utils/utils';

const initialState = {
  error: null,
  isLoading: false,
  isFetching: false,
  currentGroup: null,
  contributionId: null,
  archivedGroups: [],
  anonymousGroups: [],
  subSections: null,
  mainGroup: null,
  suggestedGroups: [],
};

const traversePostCount = (postCount, mainGroup, subSections, archivedGroups, contributionId) => {
  const keys = Object.keys(postCount);
  keys.forEach(postKey => {
    const [sectionId, groupId] = postKey.split('_');
    if (contributionId === groupId && mainGroup.sectionId === sectionId) {
      mainGroup.unreadCount = postCount[postKey];
    } else {
      let found = false;
      subSections = subSections.map(section => {
        if (section.sectionId === sectionId) {
          const sectionToEdit = { ...section };
          sectionToEdit.groups = sectionToEdit?.groups.map(group => {
            if (group.id === groupId) {
              found = true;
              const groupToEdit = { ...group };
              groupToEdit.unreadCount = postCount[postKey];
              return groupToEdit;
            }
            return group;
          });
          return sectionToEdit;
        }
        return section;
      });
      if (!found) {
        archivedGroups = archivedGroups.map(group => {
          if (group.id === groupId) {
            const groupToEdit = { ...group };
            groupToEdit.unreadCount = postCount[postKey];
            return groupToEdit;
          }
          return group;
        });
      }
    }
  });

  return { mainGroup, subSections, archivedGroups };
};

const initSectionsAndGroups = (payload, contributionId) => {
  let { subSections, archivedGroups, mainGroup } = payload;
  const { anonymousGroups } = payload;
  mainGroup = { ...mainGroup, links: [], resources: [] };

  let anonymousArchived = [];

  if (anonymousGroups && anonymousGroups.length > 0) {
    const anonymousSection = {
      sectionId: anonymousGroups[0].sectionId,
      sectionTitle: 'Anonymous Section',
      isAnonymous: true,
      groupLimit: 5,
      groups: anonymousGroups.filter(group => !group.isArchived),
    };
    subSections = [...subSections, anonymousSection];
    anonymousArchived = anonymousGroups.filter(group => group.isArchived) ?? [];
  }

  archivedGroups = [...(archivedGroups ?? []), ...anonymousArchived];

  subSections = subSections.map(section => {
    return { ...section, groupLimit: 5 };
  });

  if (payload.postCount) {
    const { postCount } = payload;
    const data = traversePostCount(postCount, mainGroup, subSections, archivedGroups, contributionId);
    mainGroup = data.mainGroup;
    subSections = data.subSections;
    archivedGroups = data.archivedGroups;
  }
  return { subSections, archivedGroups, mainGroup, anonymousGroups };
};

const reducerCommunitySections = handleActions(
  new Map([
    [
      actions.FETCH_COMMUNITY_SECTIONS_REQUEST,
      state => ({
        ...state,
        error: null,
        isLoading: true,
        isFetching: true,
        archivedGroups: [],
        anonymousGroups: [],
        subSections: null,
        mainGroup: {},
        suggestedGroups: [],
      }),
    ],
    [
      actions.UPDATE_SECTIONS_META_REQUEST,
      state => ({
        ...state,
        isLoading: true,
        isFetching: false,
        error: null,
      }),
    ],
    [
      actions.FETCH_COMMUNITY_SECTIONS_ERROR,
      (state, { payload }) => ({
        ...state,
        isLoading: false,
        isFetching: false,
        error: payload.error,
      }),
    ],
    [
      actions.FETCH_COMMUNITY_SECTIONS_SUCCESS,
      (state, { payload }) => {
        const { subSections, mainGroup, anonymousGroups, archivedGroups } = initSectionsAndGroups(
          payload,
          state.contributionId,
        );

        return {
          ...state,
          isLoading: false,
          isFetching: false,
          error: null,
          subSections,
          mainGroup,
          anonymousGroups,
          archivedGroups,
        };
      },
    ],
    [
      actions.CREATE_OR_EDIT_COMMUNITY_SECTION_REQUEST,
      state => ({
        ...state,
        error: null,
        isLoading: true,
      }),
    ],
    [
      actions.CREATE_OR_EDIT_COMMUNITY_SECTION_ERROR,
      (state, { payload }) => ({
        ...state,
        isLoading: false,
        error: payload.error,
      }),
    ],
    [
      actions.CREATE_OR_EDIT_COMMUNITY_SECTION_SUCCESS,
      (state, { payload }) => {
        let { section } = payload;
        if (section.orderNo) {
          section = { ...section, sectionOrder: section.orderNo };
        }
        if (!section?.groups) {
          section = { ...section };
        }
        const existingItem = state.subSections.find(subSection => subSection.sectionId === section.id);
        const currentSections = state.subSections.filter(sectionItem => !sectionItem?.isAnonymous);
        let subSections = existingItem
          ? currentSections.map(subSection =>
              subSection.sectionId === section.id ? { ...subSection, ...section } : subSection,
            )
          : [...currentSections, { ...section, groups: [], groupLimit: 5 }];

        if (state.anonymousGroups && state.anonymousGroups.length > 0) {
          const anonymousSection = {
            sectionId: state.anonymousGroups[0].sectionId,
            sectionTitle: 'Anonymous Section',
            isAnonymous: true,
            groupLimit: 5,
            groups: state.anonymousGroups.filter(group => !group.isArchived),
          };
          subSections = [...subSections, anonymousSection];
        }

        return {
          ...state,
          isLoading: false,
          error: null,
          subSections,
        };
      },
    ],
    [
      actions.UPDATE_SECTION_ORDER,
      (state, { payload }) => ({
        ...state,
        subSections: payload.sections,
      }),
    ],
    [
      actions.DELETE_COMMUNITY_SECTION_REQUEST,
      state => ({
        ...state,
        isLoading: true,
        error: null,
      }),
    ],
    [
      actions.DELETE_COMMUNITY_SECTION_ERROR,
      (state, { payload }) => ({
        ...state,
        isLoading: false,
        error: payload.error,
      }),
    ],
    [
      actions.DELETE_COMMUNITY_SECTION_SUCCESS,
      (state, { payload }) => {
        const { subSections, mainGroup, anonymousGroups, archivedGroups } = initSectionsAndGroups(
          payload,
          state.contributionId,
        );

        return {
          ...state,
          isLoading: false,
          error: null,
          subSections,
          anonymousGroups,
          archivedGroups,
          mainGroup,
        };
      },
    ],
    [
      actions.UPDATE_GROUP_ORDER,
      (state, { payload }) => {
        const sections = state.subSections.map(section => {
          if (section.sectionId === payload.sectionId) {
            section.groups = payload.groups;
          }
          return section;
        });

        return {
          ...state,
          isLoading: false,
          error: null,
          subSections: sections,
        };
      },
    ],
    [
      actions.MOVE_GROUP_FROM_SECTION_TO_SECTION,
      (state, { payload }) => {
        let { anonymousGroups } = state;
        const { toSection, toSectionGroups, fromSection, fromSectionGroups } = payload;
        const sections = state.subSections.map(section => {
          const sectionToEdit = { ...section };
          if (section.sectionId === toSection) {
            sectionToEdit.groups = toSectionGroups;
          }

          if (section.sectionId === fromSection) {
            sectionToEdit.groups = fromSectionGroups;
          }

          return sectionToEdit;
        });

        const sourceSection = state.subSections.find(section => section.sectionId === fromSection);
        if (sourceSection.isAnonymous) {
          anonymousGroups = fromSectionGroups;
        }

        return {
          ...state,
          isLoading: false,
          error: null,
          subSections: sections,
          anonymousGroups,
        };
      },
    ],
    [
      actions.CREATE_OR_EDIT_COMMUNITY_GROUP_REQUEST,
      state => ({
        ...state,
        isLoading: true,
        error: null,
      }),
    ],
    [
      actions.CREATE_OR_EDIT_COMMUNITY_GROUP_ERROR,
      (state, { payload }) => ({
        ...state,
        isLoading: false,
        error: payload.error,
      }),
    ],
    [
      actions.CREATE_OR_EDIT_COMMUNITY_GROUP_SUCCESS,
      (state, { payload }) => {
        let archivedGroups = state.archivedGroups ?? [];
        let anonymousGroups = state.anonymousGroups ?? [];
        let subSections = state?.subSections;

        let currentGroup = state?.currentGroup;
        const sectionToEdit = subSections?.find(section => section.sectionId === payload.sectionId);
        let sectionGroups = sectionToEdit?.groups ?? [];

        const groupIds = pluckKeys(
          payload.groups.filter(sectionGroup => !sectionGroup.isArchived),
          'id',
        );
        archivedGroups = archivedGroups?.filter(archivedGroup => !groupIds.includes(archivedGroup.id));

        payload.groups.forEach(group => {
          let groupToEdit = { ...group };
          const existingItem = sectionGroups?.find(sectionGroup => groupToEdit.id === sectionGroup.id);
          delete groupToEdit.links;

          if (groupToEdit.isArchived) {
            groupToEdit = { ...existingItem, ...groupToEdit };
          } else {
            groupToEdit = {
              ...state.archivedGroups?.find(archiveGroup => archiveGroup.id === groupToEdit.id),
              ...groupToEdit,
            };
          }

          if (payload.sectionId) {
            groupToEdit.sectionId = payload.sectionId;
          }

          if (!groupToEdit.isArchived) {
            groupToEdit.isArchived = false;
            if (existingItem) {
              sectionGroups = sectionGroups?.map(sectionGroup =>
                groupToEdit.id === sectionGroup.id ? { ...sectionGroup, ...groupToEdit } : sectionGroup,
              );
            } else {
              sectionGroups = [...sectionGroups, groupToEdit];
            }
          } else {
            groupToEdit.isArchived = true;

            if (groupToEdit.isAnonymous) {
              anonymousGroups = anonymousGroups.filter(anonymousGroup => anonymousGroup.id !== groupToEdit.id);
            } else {
              sectionGroups = sectionGroups.filter(sectionGroup => sectionGroup.id !== groupToEdit.id);
            }
            archivedGroups = [...archivedGroups, groupToEdit];
          }

          if (currentGroup?.id === groupToEdit.id) {
            currentGroup = groupToEdit;
          }
        });

        let order = 1;

        sectionGroups = sectionGroups.map(sectionGroup => {
          const groupToEdit = { ...sectionGroup };
          groupToEdit.orderNo = order++;
          return groupToEdit;
        });

        sectionGroups = sectionGroups.filter(sectionGroup => !sectionGroup.isArchived);

        subSections = subSections.map(subSection =>
          subSection.sectionId === sectionToEdit.sectionId ? { ...subSection, groups: sectionGroups } : subSection,
        );

        return {
          ...state,
          isLoading: false,
          error: null,
          subSections,
          archivedGroups,
          currentGroup,
          anonymousGroups,
        };
      },
    ],
    [
      actions.FETCH_COMMUNITY_SUGGESTED_GROUPS_REQUEST,
      state => ({
        ...state,
        error: null,
        isLoading: false,
      }),
    ],
    [
      actions.FETCH_COMMUNITY_SUGGESTED_GROUPS_ERROR,
      (state, { payload }) => ({
        ...state,
        isLoading: false,
        error: payload.error,
      }),
    ],
    [
      actions.FETCH_COMMUNITY_SUGGESTED_GROUPS_SUCCESS,
      (state, { payload }) => ({
        ...state,
        isLoading: false,
        error: null,
        suggestedGroups: payload.suggestedGroups,
      }),
    ],
    [
      actions.UPDATE_SECTION_GROUPS_LIMIT,
      (state, { payload }) => ({
        ...state,
        isLoading: false,
        error: null,
        subSections: state.subSections.map(section => {
          if (section.sectionId === payload.sectionId) {
            return { ...section, groupLimit: payload.limit };
          }
          return section;
        }),
      }),
    ],
    [
      actions.UPDATE_CURRENT_GROUP,
      (state, { payload }) => {
        const { group } = payload;
        let { subSections, archivedGroups, mainGroup } = state;
        const currentGroup = group;
        if (group) {
          if (group.isArchived && archivedGroups && archivedGroups.length > 0) {
            archivedGroups = archivedGroups.map(archiveGroup => {
              if (archiveGroup.id === group.id) {
                return { ...archiveGroup, ...group };
              }
              return archiveGroup;
            });
          } else if (group.orderNo === -1) {
            mainGroup = group;
          } else if (subSections) {
            subSections = subSections?.map(section => {
              if (section.sectionId === group.sectionId) {
                const sectionToEdit = { ...section };
                sectionToEdit.groups =
                  sectionToEdit?.groups?.map(sectionGroup => {
                    if (sectionGroup.id === group.id) {
                      return { ...sectionGroup, ...group };
                    }
                    return sectionGroup;
                  }) ?? [];
                return sectionToEdit;
              }
              return section;
            });
          }
        }

        return {
          ...state,
          isLoading: false,
          error: null,
          subSections,
          mainGroup,
          archivedGroups,
          currentGroup,
        };
      },
    ],
    [
      actions.UPDATE_GROUP_UNREAD_COUNT,
      (state, { payload }) => {
        const { group, count } = payload;
        let { subSections } = state;
        let { mainGroup, currentGroup } = state;
        if (group.id === currentGroup?.id) {
          currentGroup = { ...currentGroup, unreadCount: count };
        }
        if (group.orderNo === -1) {
          mainGroup = { ...mainGroup, unreadCount: count };
        } else if (subSections) {
          subSections = subSections?.map(section => {
            if (section.sectionId === group.sectionId) {
              const sectionToEdit = { ...section };
              sectionToEdit.groups =
                sectionToEdit?.groups?.map(sectionGroup => {
                  if (sectionGroup.id === group.id) {
                    return { ...sectionGroup, unreadCount: count };
                  }
                  return sectionGroup;
                }) ?? [];
              return sectionToEdit;
            }
            return section;
          });
        }

        return {
          ...state,
          isLoading: false,
          error: null,
          subSections,
          mainGroup,
          currentGroup,
        };
      },
    ],
    [
      actions.MUTE_OR_UNMUTE_GROUP_NOTIFICATIONS,
      (state, { payload: { group } }) => {
        let { subSections } = state;
        subSections = subSections.map(section => {
          if (section.sectionId === group.sectionId) {
            const sectionToEdit = section;
            sectionToEdit.groups = sectionToEdit.groups.map(sectionGroup => {
              if (sectionGroup.id === group.id) {
                return { ...sectionGroup, ...group };
              }
              return sectionGroup;
            });
          }
          return section;
        });
        return {
          ...state,
          subSections,
        };
      },
    ],
    [
      actions.SET_CONTRIBUTION_ID,
      (state, { payload }) => ({
        ...state,
        contributionId: payload.contributionId,
      }),
    ],
  ]),
  initialState,
);

export default reducerCommunitySections;
